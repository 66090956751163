import React, { useMemo, useState } from "react";
import {
  Button,
  Checkbox,
  SwipeableDrawer,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import {
  EnhancedTableHead,
  ManualEnhancedTableHead,
} from "../../components/TableDependencies/TableDependencies";
import cancelIcon from "../../assets/icons/cancel.svg";
import ringSmall from "../../assets/images/ringSmall.svg";
import {
  useGetAllProductsQuery,
  useGetProductsByIdsQuery,
} from "../../features/products/product/productApiSlice";
import { TableSearchSecondary } from "../../components/TableSearch/TableSearch";
import AsyncImage from "../AsyncImage/AsyncImage";
import NoDataFound from "../NoDataFound/NoDataFound";
import moment from "moment/moment";
import TableLoader from "../Loader/TableLoader";
import { Link } from "react-router-dom";

const headCells = [
  {
    id: "productName",
    numeric: false,
    disablePadding: false,
    label: "Product Name",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "price",
    numeric: false,
    disablePadding: false,
    label: "Price",
  },
];

export default function AddManualProducts({
  productIds = [],
  onProductsChange = () => {},
  showStatusColumn = true,
}) {
  const [showAddSideDrawer, setShowAddSideDrawer] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [debSearchKey, setDebSearchKey] = useState("");
  const [tablePageSize, setTablePageSize] = useState(10);
  const [tablePageNo, setTablePageNo] = useState(0);
  const [drawerPageSize, setDrawerPageSize] = useState(10);
  const [drawerPageNo, setDrawerPageNo] = useState(1);

  const { data: drawerProductData, isSuccess: drawerProductIsSuccess } = useGetAllProductsQuery(
    {
      queries: {
        createdAt: -1,
        pageNo: drawerPageNo,
        pageSize: drawerPageSize,
        title: debSearchKey,
        status: ["active", "in-active", "scheduled"],
      },
    }
  );
  const drawerProducts = useMemo(() => drawerProductData?.data ?? [], [drawerProductData]);
  const drawerProductIds = useMemo(
    () => drawerProductData?.data?.map?.((p) => p._id) ?? [],
    [drawerProductData]
  );
  const totalDrawerProducts = useMemo(
    () => drawerProductData?.totalCount ?? 0,
    [drawerProductData]
  );
  // console.log("drawerProducts", drawerProducts);

  const {
    data: tableProductData,
    isSuccess: tableProductIsSuccess,
    isLoading: tableProductIsLoading,
    isFetching: tableProductIsFetching,
  } = useGetProductsByIdsQuery(
    productIds.slice(tablePageSize * tablePageNo, tablePageSize + tablePageSize * tablePageNo),
    { skip: productIds.length === 0 }
  );
  const tableProducts = useMemo(() => tableProductData ?? [], [tableProductData]);
  // const totalTableProducts = useMemo(
  //   () => tableProductData?.totalCount ?? 0,
  //   [tableProductData]
  // );
  // console.log("tableProducts", tableProducts);

  return (
    <div className="row">
      <div className="col-12 my-2">
        <div className="bg-black-11 rounded-8 p-3 shadow-sm">
          <div className="d-flex justify-content-center">
            <button
              type="button"
              className="button-gradient py-1 px-4"
              onClick={() => setShowAddSideDrawer(true)}>
              <p>Add Products</p>
            </button>
          </div>
        </div>
        <SwipeableDrawer
          anchor="right"
          open={showAddSideDrawer}
          onOpen={() => setShowAddSideDrawer(true)}
          onClose={() => setShowAddSideDrawer(false)}>
          <div className="d-flex justify-content-between py-3 ps-3 pe-2 me-1 align-items-center">
            <h6 className="text-lightBlue">Select Products</h6>
            <img
              src={cancelIcon}
              alt="cancel"
              className="c-pointer add-product-padding"
              onClick={() => setShowAddSideDrawer(false)}
            />
          </div>
          <hr className="hr-grey-6 mt-3 mb-3" />
          <div className="px-3">
            <TableSearchSecondary
              value={searchKey}
              onChange={(val) => setDebSearchKey(val)}
              onSearchValueChange={(val) => setSearchKey(val)}
            />
          </div>
          {drawerProductIsSuccess && (
            <TableContainer className="mt-3">
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size="medium">
                <ManualEnhancedTableHead
                  headCells={headCells}
                  current={drawerProductIds}
                  selected={productIds}
                  onSelectAllClick={(e) =>
                    onProductsChange(
                      e.target.checked
                        ? [
                            ...drawerProductIds.filter((pid) => !productIds.includes(pid)),
                            ...productIds,
                          ]
                        : productIds.filter((pid) => !drawerProductIds.includes(pid))
                    )
                  }
                />
                <TableBody>
                  {drawerProducts.map((row) => {
                    const isItemSelected = productIds.includes(row._id);
                    const defaultImg = row?.mediaUrl?.find((media) => media.isDefault);
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row._id}
                        selected={isItemSelected}>
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            size="small"
                            onClick={(event) =>
                              onProductsChange(
                                event.target.checked
                                  ? [row._id, ...productIds]
                                  : productIds.filter((pid) => pid !== row._id)
                              )
                            }
                            style={{
                              color: "#5C6D8E",
                              marginRight: 0,
                            }}
                          />
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          padding="none">
                          <div className="d-flex align-items-center my-2">
                            <AsyncImage
                              src={defaultImg?.image}
                              placeholder={ringSmall}
                              alt="product"
                              className="me-2 rounded-4"
                              height={45}
                              width={45}
                            />
                            <div>
                              <p className="text-lightBlue fw-600">{row?.title}</p>
                              <small className="mt-2 text-grey-6">
                                {row?.inventory?.skuId}
                              </small>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell style={{ width: 180, padding: 0 }}>
                          <div className="d-block">
                            <div
                              className="rounded-pill d-flex px-2 py-1 statusBoxWidth"
                              style={{
                                background:
                                  row?.status === "active"
                                    ? "#A6FAAF"
                                    : row?.status === "in-active"
                                    ? "#F67476"
                                    : row?.status === "archived"
                                    ? "#C8D8FF"
                                    : "#FEE1A3",
                              }}>
                              <small className="text-black fw-500">
                                {row?.status === "active"
                                  ? "Active"
                                  : row?.status === "in-active"
                                  ? "In-Active"
                                  : row?.status === "archived"
                                  ? "Archived"
                                  : "Scheduled"}
                              </small>
                            </div>
                            {row?.status === "scheduled" && (
                              <div>
                                <small className="text-blue-2">
                                  {row?.startDate && (
                                    <>for {moment(row?.startDate).format("DD/MM/YYYY")}</>
                                  )}
                                  {row?.startDate && row?.endDate && " "}
                                  {row?.endDate && (
                                    <>till {moment(row?.endDate).format("DD/MM/YYYY")}</>
                                  )}
                                </small>
                              </div>
                            )}
                          </div>
                        </TableCell>
                        <TableCell>
                          <p className="text-lightBlue">{row?.productType?.category?.name}</p>
                        </TableCell>
                        <TableCell>
                          <div className="d-flex align-items-center">
                            <p className="text-lightBlue">
                              {row?.price?.price ? row?.price?.price : "Depends on variant"}
                            </p>
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          <TablePagination
            component="div"
            page={drawerPageNo - 1}
            count={totalDrawerProducts}
            rowsPerPage={drawerPageSize}
            className="table-pagination"
            rowsPerPageOptions={[10, 20, 30]}
            onPageChange={(_, page) => setDrawerPageNo(page + 1)}
            onRowsPerPageChange={(e) => {
              setDrawerPageNo(1);
              setDrawerPageSize(e.target.value);
            }}
          />
        </SwipeableDrawer>
      </div>
      {tableProductIsSuccess &&
        (tableProducts.length > 0 ? (
          <div className="col-12 my-2">
            <TableContainer>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size="medium">
                <TableHead>
                  <TableRow>
                    {[
                      ...(showStatusColumn
                        ? headCells
                        : headCells.filter((e) => e.id !== "status")),
                      {
                        id: "action",
                        numeric: false,
                        disablePadding: false,
                        label: "Action",
                      },
                    ].map((headCell) => {
                      return (
                        <TableCell
                          key={headCell.id}
                          align={headCell.numeric ? "right" : "left"}
                          padding={headCell.disablePadding ? "none" : "normal"}>
                          <p className="text-lightBlue">{headCell.label}</p>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableProductIsLoading || tableProductIsFetching ? (
                    <span className="d-flex justify-content-center m-3">
                      <TableLoader />
                    </span>
                  ) : (
                    tableProducts.map((row) => {
                      const defaultImg = row?.mediaUrl?.find((media) => media.isDefault);
                      console.log("row", row);
                      return (
                        <TableRow
                          hover
                          tabIndex={-1}
                          key={row._id}>
                          <TableCell
                            scope="row"
                            component="th"
                            padding="normal"
                            className="py-0">
                            <Link
                              to={`/products/allProducts/editProduct/${row?._id}`}
                              className="text-decoration-none">
                              <div className="d-flex align-items-center my-2">
                                <AsyncImage
                                  src={defaultImg?.image}
                                  placeholder={ringSmall}
                                  alt="product"
                                  className="me-2 rounded-4"
                                  height={45}
                                  width={45}
                                />
                                <div>
                                  <p className="text-lightBlue fw-600">{row?.title}</p>
                                  <small className="mt-2 text-grey-6">
                                    {row?.inventory?.skuId}
                                  </small>
                                </div>
                              </div>
                            </Link>
                          </TableCell>
                          {showStatusColumn && (
                            <TableCell style={{ width: 180, padding: 0 }}>
                              <div className="d-block">
                                <div
                                  className="rounded-pill d-flex px-2 py-1 statusBoxWidth"
                                  style={{
                                    background:
                                      row?.status === "active"
                                        ? "#A6FAAF"
                                        : row?.status === "in-active"
                                        ? "#F67476"
                                        : row?.status === "archived"
                                        ? "#C8D8FF"
                                        : "#FEE1A3",
                                  }}>
                                  <small className="text-black fw-500">
                                    {row?.status === "active"
                                      ? "Active"
                                      : row?.status === "in-active"
                                      ? "In-Active"
                                      : row?.status === "archived"
                                      ? "Archived"
                                      : "Scheduled"}
                                  </small>
                                </div>
                                {row?.status === "scheduled" && (
                                  <div>
                                    <small className="text-blue-2">
                                      {row?.startDate && (
                                        <>for {moment(row?.startDate).format("DD/MM/YYYY")}</>
                                      )}
                                      {row?.startDate && row?.endDate && " "}
                                      {row?.endDate && (
                                        <>till {moment(row?.endDate).format("DD/MM/YYYY")}</>
                                      )}
                                    </small>
                                  </div>
                                )}
                              </div>
                            </TableCell>
                          )}
                          <TableCell>
                            <div className="d-flex align-items-center">
                              <p className="text-lightBlue">
                                {row?.price?.price ?? row?.price?.price ?? "Depends on variant"}
                              </p>
                            </div>
                          </TableCell>
                          <TableCell>
                            <Button
                              color="primary"
                              variant="text"
                              onClick={() =>
                                onProductsChange(productIds.filter((p) => row._id !== p))
                              }>
                              <span className="text-red-5">Remove</span>
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              page={tablePageNo}
              count={productIds.length}
              rowsPerPage={tablePageSize}
              className="table-pagination"
              rowsPerPageOptions={[10, 20, 30]}
              onPageChange={(_, page) => setTablePageNo(page)}
              onRowsPerPageChange={(e) => {
                setTablePageNo(0);
                setTablePageSize(e.target.value);
              }}
            />
          </div>
        ) : (
          <div className="col-12 my-2">
            <NoDataFound />
          </div>
        ))}
    </div>
  );
}
