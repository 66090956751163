import apiSlice from "../../../app/api/apiSlice";

export const customerApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createCustomer: builder.mutation({
      query: (customerDetails) => ({
        url: "/customer",
        method: "POST",
        body: customerDetails,
      }),
      invalidatesTags: ["Customers", "Logs"],
    }),

    getAllCustomers: builder.query({
      query: (queries) => {
        let queryString = "";
        for (const key in queries) {
          if (queries[key]) {
            queryString = `${queryString}${queryString ? "&" : "?"}${key}=${queries[key]}`;
          }
        }
        return {
          url: `/customer${queryString}`,
        };
      },
      providesTags: ["Customers"],
    }),

    getSingleCustomer: builder.query({
      query: (cid) => ({
        url: `/customer/getSingle/${cid}`,
      }),
      providesTags: ["Customers"],
    }),

    getCustomersByIds: builder.query({
      query: (idsData) => ({
        url: "/customer/getCustomerData",
        method: "POST",
        body: idsData,
      }),
      transformResponse: (res) => res.data,
      providesTags: ["Customers"],
    }),

    getCustomersCount: builder.query({
      query: (queries) => {
        let queryString = "";
        for (const key in queries) {
          if (queries[key]) {
            queryString = `${queryString}${queryString ? "&" : "?"}${key}=${queries[key]}`;
          }
        }

        return {
          url: `/customer/count${queryString}`,
        };
      },
      providesTags: ["Customers"],
    }),

    getCustomerRanges: builder.query({
      query: () => ({ url: "/customer/getMax" }),
      providesTags: ["Customers"],
    }),

    editCustomer: builder.mutation({
      query: ({ id, details }) => ({
        url: `/customer/${id}`,
        method: "PUT",
        body: details,
      }),
      invalidatesTags: ["Customers", "Logs"],
    }),

    bulkEditCustomer: builder.mutation({
      query: (updates) => ({
        url: `/customer/bulkUpdate`,
        method: "PUT",
        body: updates,
      }),
      invalidatesTags: ["Customers", "Logs"],
    }),

    deleteCustomer: builder.mutation({
      query: (customerId) => ({
        url: `/customer/${customerId}`,
        method: "DELETE",
        body: customerId,
      }),
      invalidatesTags: ["Customers", "Logs"],
    }),

    bulkDeleteCustomer: builder.mutation({
      query: (deleteCusotomer) => ({
        url: `/customer/bulkDelete`,
        method: "DELETE",
        body: deleteCusotomer,
      }),
      invalidatesTags: ["Customers", "Logs"],
    }),

    getCustomerAnalytics: builder.query({
      query: (queries) => {
        let queryString = "";
        for (const key in queries) {
          if (queries[key]) {
            queryString = `${queryString}${queryString ? "&" : "?"}${key}=${queries[key]}`;
          }
        }
        return {
          url: `/customer/analytics/${queryString}`,
        };
      },
      providesTags: ["Customers", "Logs"],
    }),

    bulkAddCustomer: builder.mutation({
      query: (updates) => ({
        url: `/customerGroup/bulkAddCustomer`,
        method: "PUT",
        body: updates,
      }),
      invalidatesTags: ["Customers", "Logs"],
    }),

    bulkAddTag: builder.mutation({
      query: (updates) => ({
        url: `/parameters/tagManager/bulkAddCustomer`,
        method: "PUT",
        body: updates,
      }),
      invalidatesTags: ["Customers", "Logs"],
    }),
  }),
});

export const {
  useCreateCustomerMutation,
  useGetAllCustomersQuery,
  useGetSingleCustomerQuery,
  useGetCustomersByIdsQuery,
  useGetCustomersCountQuery,
  useGetCustomerRangesQuery,
  useEditCustomerMutation,
  useBulkEditCustomerMutation,
  useDeleteCustomerMutation,
  useBulkDeleteCustomerMutation,
  useGetCustomerAnalyticsQuery,
  useBulkAddCustomerMutation,
  useBulkAddTagMutation,
} = customerApiSlice;
