import { useState } from 'react';
import { Popover } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

import SettingDrawer from '../SettingDrawer/SettingDrawer';
import TableSearch from '../../../../components/TableSearch/TableSearch';
import HomeToggleOption from './HomeToggleOption';
import TopThemeSideBar from './TopThemeSide/TopThemeSide';
import Homepage from './HomePage';
import HomeCountry from './HomeCountry';
import MiddleThemeSide from './MiddleTheme/MiddleThemSide';

import './ThemeSideNav.scss';

import globe from '../../../../assets/icons/theme/globe.svg';
import grid from '../../../../assets/icons/theme/grid.svg';

const bottomData = [{ name: 'Kisna' }, { name: 'Footer' }];

const ThemeSideNav = () => {
  return (
    <div className='bg-black-13 sidenav px-3 my-4'>
      <div className='d-flex'>
        <Homepage />
        <SettingDrawer />
      </div>

      <div className='d-flex align-items-center justify-content-between mt-4 mb-4'>
        <h6 className='text-lightBlue fw-500'>Homepage</h6>
        <HomeCountry />
      </div>

      {/* <TopThemeSideBar /> */}

      {/* <div className='bg-black-20 p-3 rounded-8 my-4'>
        { middleData.map((item) => (
          <div className='d-flex justify-content-between align-items-center theme-hover my-2'>
            <small className='text-lightBlue fw-500'>{item.name}</small>
            <span className='edit-icon'>
              <EditOutlinedIcon sx={{color: "#c8d8ff", fontSize: 14}} />
            </span>
          </div>
        ))}
      </div> */}

      <MiddleThemeSide />

      {/* <div className='bg-black-20 p-3 rounded-8'>
        {bottomData.map((item) => (
          <div className='d-flex justify-content-between align-items-center theme-hover my-2'>
            <small className='text-lightBlue fw-500'>{item.name}</small>
            <span className='edit-icon'>
              <EditOutlinedIcon sx={{ color: "#c8d8ff", fontSize: 14 }} />
            </span>
          </div>
        ))}
      </div>

      <div className='mt-4 d-flex justify-content-between align-items-center'>
        <small className='bg-black-20 px-4 py-2 rounded-8 c-pointer'>
          <img src={globe} width={16} />
        </small>
        <small className='bg-black-20 px-4 py-2 rounded-8 c-pointer'>
          <img src={grid} width={16} />
        </small>
        <small className='bg-black-20 text-lightBlue px-4 py-2 rounded-8 c-pointer'>
          {"</>"}
        </small>
      </div> */}
    </div>
  );
};

export default ThemeSideNav;
