import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { logoutHandler } from "../../features/auth/authAction";

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BASE_URL,
  // baseUrl: "",
  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth.accessToken;
    token && headers.set("authorization", `Bearer ${token}`);
    return headers;
  },
});

const baseQueryWithReAuth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result?.error?.status === 401) {
    api.dispatch(logoutHandler());
  }

  return result;
};

const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: baseQueryWithReAuth,
  tagTypes: [
    "Categories",
    "SubCategories",
    "Products",
    "Reviews",
    "Vendors",
    "Collections",
    "TagsManager",
    "ProductTabs",
    "Options",
    "OptionsCount",
    "OptionSets",
    "OptionSetsCount",
    "Attributes",
    "Inventory",
    "SubOptions",
    "SubAttributes",
    "Customers",
    "CustomerGroup",
    "CustomersAddress",
    "CustomerDetail",
    "Country",
    "State",
    "City",
    "Seos",
    "Orders",
    "FileManager",
    "PriceMaster",
    "PriceManager",
    "PriceMasterCount",
    "SizeChart",
    "SizeChartSetting",
    "LabelsBadges",
    "LabelsBadgesSetting",
    "StoreInfo",
    "Variants",
    "Teams",
    "DynamicTabs",
    "ProfileInfo",
    "MoreFeatures",
    "StoneAttr",
    "InventoryProducts",
    "MembersCount",
    "RolesCount",
    "SingleProduct",
    "TaxManager",
    "Banner",
    "Subject",
    "Policy",
    "NearStore",
    "WebsiteAttr",
    "Logs",
  ],
  endpoints: (builder) => ({}),
});

export default apiSlice;
