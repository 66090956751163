import React, { useEffect, useReducer, useState } from "react";
import "../../../Products/AllProducts/AllProducts.scss";
// ! COMPONENT IMPORTS
import UserOrdersTable from "./UserOrdersTable";
import TableSearch, {
  TableSearchSecondary,
} from "../../../../components/TableSearch/TableSearch";
import FilterUsers from "../../../../components/FilterUsers/FilterUsers";
// ! IMAGES IMPORTS
import sort from "../../../../assets/icons/sort.svg";
// ! MATERIAL IMPORTS
import { FormControl, FormControlLabel, Popover, Radio, RadioGroup } from "@mui/material";
// ! MATERIAL ICONS IMPORTS
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useGetAllOrdersQuery } from "../../../../features/orders/ordersApiSlice";
import FilterOrders from "../../../Orders/AllOrders/FilterOrders";
import OrderSortFilter from "../../../Orders/AllOrders/OrderSortFilter";

const initialQuery = {
  customer: "",
  pageSize: 10,
  pageNo: 1,
  search: "",
  minPrice: 0,
  maxPrice: 0,
  tagManager: "",
  status: [],
  paymentStatus: [],
  orderDate: "",
  location: "",
  vendor: "",
  noofItem: "",
  srNo: "",
  itemSort: "",
  priceSort: "",
  createdAt: "-1",
  updatedAt: "",
  state: "create",
};

const queryDispatch = (state, action) => {
  switch (action.type) {
    case "SET_CUSTID":
      return {
        ...state,
        pageNo: initialQuery.pageNo,
        customer: action.customer,
      };
    case "SET_PAGE_SIZE":
      return {
        ...state,
        pageNo: initialQuery.pageNo,
        pageSize: +action.pageSize,
      };
    case "CHANGE_PAGE":
      return {
        ...state,
        pageNo: +action.pageNo,
      };
    case "SEARCH":
      return {
        ...state,
        pageNo: initialQuery.pageNo,
        search: action.search,
      };
    case "SEARCH_TAG":
      return {
        ...state,
        pageNo: initialQuery.pageNo,
        tagManager: action.tag,
      };
    case "SET_SORTING":
      const {
        srNo = "",
        itemSort = "",
        priceSort = "",
        createdAt = "",
        updatedAt = "",
      } = action;
      return {
        ...state,
        pageNo: initialQuery.pageNo,
        srNo: srNo,
        itemSort: itemSort,
        priceSort: priceSort,
        createdAt: createdAt,
        updatedAt: updatedAt,
      };
    case "SET_STATUS":
      return {
        ...state,
        pageNo: initialQuery.pageNo,
        status: action.status,
      };
    case "SET_PAYMENT_STATUS":
      return {
        ...state,
        pageNo: initialQuery.pageNo,
        paymentStatus: action.paymentStatus,
      };
    case "SET_MIN_PRICE":
      return {
        ...state,
        pageNo: initialQuery.pageNo,
        minPrice: action.value,
      };
    case "SET_MAX_PRICE":
      return {
        ...state,
        pageNo: initialQuery.pageNo,
        maxPrice: action.value,
      };
    case "SET_RANGE_PRICE":
      return {
        ...state,
        pageNo: initialQuery.pageNo,
        minPrice: action.minPrice,
        maxPrice: action.maxPrice,
      };
    case "SET_LOCATION":
      return {
        ...state,
        pageNo: initialQuery.pageNo,
        location: action.location,
      };
    case "SET_VENDOR":
      return {
        ...state,
        pageNo: initialQuery.pageNo,
        vendor: action.vendor,
      };
    case "SET_ITEMS":
      return {
        ...state,
        pageNo: initialQuery.pageNo,
        noofItem: action.noofItem,
      };
    case "SET_ORDER_DATE":
      return {
        ...state,
        pageNo: initialQuery.pageNo,
        orderDate: action.orderDate,
      };
    case "SET_ALL_FILTERS":
      return {
        ...initialQuery,
        ...state,
        ...action.filter,
      };
    default:
      return initialQuery;
  }
};

const UserOrders = ({ customerId = "" }) => {
  const [queryFilterState, dispatchQueryFilter] = useReducer(queryDispatch, initialQuery);

  const {
    data: orderData,
    isLoading: ordersLoading,
    isSuccess: ordersSuccess,
    isError: orderError,
  } = useGetAllOrdersQuery(queryFilterState, { skip: !queryFilterState.customer });
  const allOrders = orderData?.data?.data ?? [];
  const totalOrderCount = orderData?.data?.totalCount ?? 0;

  const [searchValue, setSearchValue] = useState("");

  const onChangeRowsPerPage = (event) =>
    dispatchQueryFilter({ type: "SET_PAGE_SIZE", pageSize: event.target.value });
  const onChangePage = (_, pageNo) =>
    dispatchQueryFilter({ type: "CHANGE_PAGE", pageNo: pageNo + 1 });

  const clearAllFilter = () => {
    const {
      pageNo,
      minPrice,
      maxPrice,
      search,
      tagManager,
      status,
      paymentStatus,
      orderDate,
      location,
      noofItem,
      srNo,
      createdAt,
      updatedAt,
    } = initialQuery;
    setSearchValue("");
    dispatchQueryFilter({
      type: "SET_ALL_FILTERS",
      filter: {
        pageNo,
        minPrice,
        maxPrice,
        search,
        tagManager,
        status,
        paymentStatus,
        orderDate,
        location,
        noofItem,
        srNo,
        createdAt,
        updatedAt,
      },
    });
  };

  useEffect(
    () => dispatchQueryFilter({ type: "SET_CUSTID", customer: customerId }),
    [customerId]
  );

  return (
    <div className="bg-black-15 border-grey-5 rounded-8 row productInfo">
      <div className="d-flex px-3 py-3 align-items-center">
        <h6 className="text-lightBlue me-2">Orders</h6>
        {/* <button
          className="button-grey ps-3 pe-2 rounded-pill"
          aria-describedby={idOrderType}
          variant="contained"
          onClick={handleOrderTypeClick}>
          <p className="text-lightBlue">Online</p>
          <KeyboardArrowDownIcon sx={{ color: "#c8d8ff", fontWeight: 300 }} />
        </button>
        <Popover
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          id={idOrderType}
          open={openOrderType}
          anchorEl={anchorOrderTypeEl}
          onClose={handleOrderTypeClose}>
          <div className="py-2 px-1">
            <small className="p-2 rounded-3 text-lightBlue c-pointer font2 d-block hover-back">
              Online Orders
            </small>
            <small className="p-2 rounded-3 text-lightBlue c-pointer font2 d-block hover-back">
              Offline Orders
            </small>
          </div>
        </Popover> */}
      </div>
      <div className="d-flex justify-content-center px-2">
        <hr className="hr-grey-6 w-100 m-0" />
      </div>
      <div className="d-flex align-items-center mt-3 mb-3 px-2 justify-content-between">
        <TableSearchSecondary
          value={searchValue}
          onSearchValueChange={(val) => setSearchValue(val)}
          onChange={(val) => dispatchQueryFilter({ type: "SEARCH", search: val })}
        />
        <div className="d-flex ms-2">
          <FilterOrders
            queryFilters={queryFilterState}
            onOrderStatusChange={(val) =>
              dispatchQueryFilter({ type: "SET_STATUS", status: val })
            }
            onPaymentStatusChange={(val) =>
              dispatchQueryFilter({ type: "SET_PAYMENT_STATUS", paymentStatus: val })
            }
            onPriceRangeChange={(min, max) =>
              dispatchQueryFilter({
                type: "SET_RANGE_PRICE",
                minPrice: min,
                maxPrice: max,
              })
            }
            onItemsChange={(val) => dispatchQueryFilter({ type: "SET_ITEMS", noofItem: val })}
            onTagChange={(val) => dispatchQueryFilter({ type: "SEARCH_TAG", tag: val })}
            onOrderDateChange={(val) =>
              dispatchQueryFilter({ type: "SET_ORDER_DATE", orderDate: val })
            }
            onLocationChange={(val) =>
              dispatchQueryFilter({ type: "SET_LOCATION", location: val })
            }
            onVendorChange={(val) => dispatchQueryFilter({ type: "SET_VENDOR", vendor: val })}
            onResetAllFilters={clearAllFilter}
          />
          <OrderSortFilter
            queryFilters={queryFilterState}
            onSortChange={(change) => dispatchQueryFilter({ type: "SET_SORTING", ...change })}
          />
        </div>
      </div>
      <div className="col-12 px-0">
        <UserOrdersTable
          // tabIndex={queryFilterState.tabIndex}
          // updateOrder={updateOrder}
          page={queryFilterState.pageNo}
          changePage={onChangePage}
          changeRowsPerPage={onChangeRowsPerPage}
          rowsPerPage={queryFilterState.pageSize}
          totalCount={totalOrderCount}
          orderList={allOrders}
          orderLoading={ordersLoading}
          hasError={orderError || !ordersSuccess}
        />
      </div>
    </div>
  );
};

export default UserOrders;
