import React, { useCallback, useEffect, useReducer, useState } from "react";
// ! IMAGES IMPORTS
import cancel from "../../assets/icons/cancel.svg";
import filter from "../../assets/icons/filter.svg";
// ! MATERIAL IMPORTS
import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  FormGroup,
  SwipeableDrawer,
  TextField,
  styled,
  FormControl,
  OutlinedInput,
  Slider,
} from "@mui/material";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
// ! MATERIAL ICONS IMPORTS
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import { useSearchParams } from "react-router-dom";
import { omitNullishKeys } from "../../utils/helper";
import { useGetAllTagsQuery } from "../../features/parameters/tagsManager/tagsManagerApiSlice";

// ? FILTER ACCORDIAN STARTS HERE
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  // border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem", color: "#c8d8ff" }} />
    }
    {...props}
  />
))(({ theme }) => ({
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    padding: "0px",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: "0 16px ",
}));
// ? FILTER ACCORDIAN ENDS HERE

const initialQueryFilterState = {
  status: null,
  location: null,
  paymentStatus: null,
  deliveryStatus: null,
  orderDate: null,
  amountSpent: null,
  itemType: null,
  itemValue: null,
  taggedWith: null,
  abandonedCheckout: null,
  minPrice: null,
  maxPrice: null,
};

const queryFilterReducer = (state, action) => {
  switch (action.type) {
    case "CHANGE_STATUS":
      return { ...state, status: action.value };
    case "PAYMENT_STATUS":
      return { ...state, paymentStatus: action.value };
    case "SET_LOCATION":
      return { ...state, location: action.value };
    case "DELIVERY_STATUS":
      return { ...state, deliveryStatus: action.value };
    case "ORDER_DATE":
      return { ...state, orderDate: action.value };
    case "AMOUNT_SPENT":
      return { ...state, amountSpent: action.value };
    case "AMOUNT_VALUE":
      return { ...state, amountValue: action.value };
    case "ITEM_TYPE":
      return { ...state, itemType: action.value };
    case "ITEM_VALUE":
      return { ...state, itemValue: action.value };
    case "TAGGED_WITH":
      return { ...state, taggedWith: action.value };
    case "ABANDONED_CHECKOUT":
      return { ...state, abandonedCheckout: action.value };
    case "SET_RANGE_PRICE":
      return {
        ...state,
        minPrice: action.minPrice,
        maxPrice: action.maxPrice,
      };
    case "RESET":
      return {
        status: "",
        paymentStatus: "",
        deliveryStatus: "",
        orderDate: "",
        amountSpent: "",
        itemType: "",
        location: "",
        itemValue: "",
        taggedWith: "",
        abandonedCheckout: "",
        minPrice: 0,
        maxPrice: 0,
      };
    case "ALL_SET":
      return {
        status: action?.value?.status || "",
        paymentStatus: action?.value?.paymentStatus || "",
        deliveryStatus: action?.value?.deliveryStatus || "",
        orderDate: action?.value?.orderDate || "",
        amountSpent: action?.value?.amountSpent || "",
        itemType: action?.value?.itemType || "",
        itemValue: action?.value?.itemValue || "",
        taggedWith: action?.value?.taggedWith || "",
        abandonedCheckout: action?.value?.abandonedCheckout || "",
        minPrice: action?.value?.minPrice || 0,
        maxPrice: action?.value?.maxPrice || 0,
        location: action?.value?.location || "",
      };
    default:
      return state;
  }
};

const FilterOrders = ({ buttonName, pMaxPrice = 0, cityData }) => {
  const [locationData, setLocationData] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();

  // ? FILTER DRAWER STARTS HERE
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const [queryFilterState, dispatchQueryFilter] = useReducer(
    queryFilterReducer,
    initialQueryFilterState
  );

  const toggleDrawer = useCallback(
    (anchor, open) => (event) => {
      if (
        event &&
        event.type === "keydown" &&
        (event.key === "Tab" || event.key === "Shift")
      ) {
        return;
      }

      if (open) {
        dispatchQueryFilter({ type: "ALL_SET", value: searchParams });
      }

      setState((prev) => ({ ...prev, [anchor]: open }));
    },
    [searchParams]
  );

  const handlePriceRangeChange = (e, newValue, activeThumb) => {
    console.log("called", newValue);
    const minDistance = 1000;
    if (!Array.isArray(newValue)) {
      return;
    }

    const filter = {
      type: "SET_RANGE_PRICE",
      minPrice: Math.max(
        Math.min(newValue[0], queryFilterState.maxPrice - minDistance),
        0
      ),
      maxPrice: Math.max(newValue[1], queryFilterState.minPrice + minDistance),
    };

    dispatchQueryFilter(filter);
  };

  const onLocationChange = useCallback((e, val) => {
    dispatchQueryFilter({ type: "SET_LOCATION", value: val?._id || "" });
  }, []);

  // ? FILTER DRAWER ENDS HERE

  // ? FILTER ACCORDIAN STARTS HERE
  const [expanded, setExpanded] = React.useState("panel1");

  const handleAccordianChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  // ? FILTER ACCORDIAN ENDS HERE

  const changeRoute = useCallback(
    (e) => {
      const pr = {};
      searchParams.forEach((v, k) => {
        pr[k] = v;
      });

      setSearchParams(
        omitNullishKeys({
          ...pr,
          ...queryFilterState,
        })
      );
      toggleDrawer("right", false)(e);
    },
    [queryFilterState, toggleDrawer, searchParams, setSearchParams]
  );

  const onItemOrderValueChange = useCallback((val) => {
    dispatchQueryFilter({ type: "ITEM_VALUE", value: val });
  }, []);

  const onOrderStatusChange = useCallback(
    (val) => {
      const dt = queryFilterState?.status || "";

      let temp = dt === "" ? [] : dt.split(",");

      if (temp.includes(val)) {
        temp = temp.filter((e) => e !== val);
      } else {
        temp.push(val);
      }
      dispatchQueryFilter({ type: "CHANGE_STATUS", value: temp.join(",") });
    },
    [queryFilterState]
  );
  const onPaymentStatusChange = useCallback(
    (val) => {
      const dt = queryFilterState?.paymentStatus || "";

      let temp = dt === "" ? [] : dt.split(",");

      if (temp.includes(val)) {
        temp = temp.filter((e) => e !== val);
      } else {
        temp.push(val);
      }
      dispatchQueryFilter({ type: "PAYMENT_STATUS", value: temp.join(",") });
    },
    [queryFilterState]
  );
  const onDeliveryStatusChange = useCallback(
    (val) => {
      const dt = queryFilterState?.deliveryStatus || "";

      let temp = dt === "" ? [] : dt.split(",");

      if (temp.includes(val)) {
        temp = temp.filter((e) => e !== val);
      } else {
        temp.push(val);
      }
      dispatchQueryFilter({ type: "DELIVERY_STATUS", value: temp.join(",") });
    },
    [queryFilterState]
  );
  const onTaggedWithChange = useCallback((val) => {
    dispatchQueryFilter({ type: "TAGGED_WITH", value: val });
  }, []);

  const onOrderDateChange = useCallback(
    (val) => {
      const dt = queryFilterState?.orderDate || "";

      let temp = dt === "" ? [] : dt.split(",");

      if (temp.includes(val)) {
        temp = temp.filter((e) => e !== val);
      } else {
        temp.push(val);
      }
      dispatchQueryFilter({ type: "ORDER_DATE", value: temp.join(",") });
    },
    [queryFilterState]
  );

  const onCheckoutDateChange = useCallback((val) => {
    dispatchQueryFilter({ type: "ABANDONED_CHECKOUT", value: val });
  }, []);

  const { data: tagsData } = useGetAllTagsQuery();
  const allTags = tagsData?.data?.data ?? [];

  useEffect(() => {
    const city = (cityData?.data?.data || [])?.find(
      (e) => e._id === queryFilterState?.location
    );
    setLocationData(city || "");
  }, [cityData, queryFilterState]);

  return (
    <React.Fragment key="right">
      <button
        className="button-grey py-2 px-3"
        onClick={toggleDrawer("right", true)}
      >
        <small className="text-lightBlue">{buttonName}</small>
        <img src={filter} alt="filter" className="ms-2" />
      </button>
      <SwipeableDrawer
        anchor="right"
        open={state["right"]}
        onClose={toggleDrawer("right", false)}
        onOpen={toggleDrawer("right", true)}
      >
        <div className="d-flex justify-content-between py-3 px-3 ms-2 me-1">
          <h6 className="text-lightBlue">Filters</h6>
          <img
            src={cancel}
            alt="cancel"
            className="c-pointer filter-icon"
            onClick={toggleDrawer("right", false)}
          />
        </div>

        <div className="px-2">
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleAccordianChange("panel1")}
          >
            <AccordionSummary
              aria-controls="panel4d-content"
              id="panel4d-header"
            >
              <p className="text-lightBlue">Order Status</p>
            </AccordionSummary>
            <AccordionDetails>
              <FormGroup className="tags-checkbox">
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={queryFilterState.status?.includes("processing")}
                      onChange={(e) => onOrderStatusChange("processing")}
                      style={{
                        color: "#5C6D8E",
                        marginRight: 0,
                      }}
                    />
                  }
                  label="Processing"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={queryFilterState.status?.includes("complete")}
                      onChange={(e) => onOrderStatusChange("complete")}
                      style={{
                        color: "#5C6D8E",
                        marginRight: 0,
                      }}
                    />
                  }
                  label="Complete"
                />
              </FormGroup>

              <small className="text-grey-6 mt-2 c-pointer">Clear All</small>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleAccordianChange("panel2")}
          >
            <AccordionSummary
              aria-controls="panel4d-content"
              id="panel4d-header"
            >
              <p className="text-lightBlue">Payment Status</p>
            </AccordionSummary>
            <AccordionDetails>
              <FormGroup className="tags-checkbox">
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={queryFilterState.paymentStatus?.includes(
                        "processing"
                      )}
                      onChange={(e) => onPaymentStatusChange("processing")}
                      style={{
                        color: "#5C6D8E",
                        marginRight: 0,
                      }}
                    />
                  }
                  label="Processing"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={queryFilterState.paymentStatus?.includes(
                        "complete"
                      )}
                      onChange={(e) => onPaymentStatusChange("complete")}
                      style={{
                        color: "#5C6D8E",
                        marginRight: 0,
                      }}
                    />
                  }
                  label="Complete"
                />
              </FormGroup>
              <small className="text-grey-6 mt-2 c-pointer">Clear All</small>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel3"}
            onChange={handleAccordianChange("panel3")}
          >
            <AccordionSummary
              aria-controls="panel4d-content"
              id="panel4d-header"
            >
              <p className="text-lightBlue">Delivery Status</p>
            </AccordionSummary>
            <AccordionDetails>
              <FormGroup className="tags-checkbox">
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={queryFilterState.deliveryStatus?.includes(
                        "processing"
                      )}
                      onChange={(e) => onDeliveryStatusChange("processing")}
                      style={{
                        color: "#5C6D8E",
                        marginRight: 0,
                      }}
                    />
                  }
                  label="Processing"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={queryFilterState.deliveryStatus?.includes(
                        "complete"
                      )}
                      onChange={(e) => onDeliveryStatusChange("complete")}
                      style={{
                        color: "#5C6D8E",
                        marginRight: 0,
                      }}
                    />
                  }
                  label="Complete"
                />
              </FormGroup>
              <small className="text-grey-6 mt-2 c-pointer">Clear All</small>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel4"}
            onChange={handleAccordianChange("panel4")}
          >
            <AccordionSummary
              aria-controls="panel3d-content"
              id="panel3d-header"
            >
              <p className="text-lightBlue">Amount Spent</p>
            </AccordionSummary>
            <AccordionDetails>
              <FormControl fullWidth>
                <div className="row text-lightBlue text-center">
                  <div className="col-2">
                    {/* <span>{queryFilterState.minPrice}</span> */}
                  </div>
                  <div className="col-8">
                    <Slider
                      step={1}
                      value={[
                        queryFilterState.minPrice || 0,
                        queryFilterState.maxPrice || 0,
                      ]}
                      min={0}
                      max={Math.ceil(pMaxPrice / 1000) * 1000}
                      // onChange={handleChange1}
                      onChange={handlePriceRangeChange}
                      valueLabelDisplay="auto"
                      getAriaValueText={(v) => v}
                      disableSwap
                    />
                  </div>
                  <div className="col-2">
                    <span>{queryFilterState.maxPrice}</span>
                  </div>
                </div>
              </FormControl>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel5"}
            onChange={handleAccordianChange("panel5")}
          >
            <AccordionSummary
              aria-controls="panel3d-content"
              id="panel3d-header"
            >
              <p className="text-lightBlue">No. of Items in Order</p>
            </AccordionSummary>
            <AccordionDetails>
              <FormControl className="w-100 px-0">
                <OutlinedInput
                  placeholder="Enter Value"
                  size="small"
                  type="number"
                  value={queryFilterState.itemValue}
                  onChange={(e) => onItemOrderValueChange(e.target.value)}
                />
              </FormControl>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel6"}
            onChange={handleAccordianChange("panel6")}
          >
            <AccordionSummary
              aria-controls="panel2d-content"
              id="panel2d-header"
            >
              <p className="text-lightBlue">Tagged With</p>
            </AccordionSummary>
            <AccordionDetails>
              <Autocomplete
                sx={{ width: "100%" }}
                id="free-solo-demo"
                freeSolo
                size="small"
                fullWidth
                value={queryFilterState?.taggedWith || ""}
                isOptionEqualToValue={(option, value) => option.name === value}
                options={allTags}
                onChange={(e, val) => {
                  onTaggedWithChange(val?.name);
                }}
                getOptionLabel={(option) => option.name ?? option}
                renderOption={(props, option) => (
                  <li {...props}>
                    <small className="text-lightBlue my-1">{option.name}</small>
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Search"
                    inputRef={(input) => input?.focus()}
                  />
                )}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel7"}
            onChange={handleAccordianChange("panel7")}
          >
            <AccordionSummary
              aria-controls="panel5d-content"
              id="panel5d-header"
            >
              <p className="text-lightBlue">Date of Order</p>
            </AccordionSummary>
            <AccordionDetails>
              <FormGroup className="tags-checkbox">
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={queryFilterState.orderDate?.includes("0d")}
                      onChange={() => onOrderDateChange("0d")}
                      style={{
                        color: "#5C6D8E",
                        marginRight: 0,
                      }}
                    />
                  }
                  label="Today"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={queryFilterState.orderDate?.includes("1d")}
                      onChange={() => onOrderDateChange("1d")}
                      style={{
                        color: "#5C6D8E",
                        marginRight: 0,
                      }}
                    />
                  }
                  label="Yesterday"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={queryFilterState.orderDate?.includes("3d")}
                      onChange={() => onOrderDateChange("3d")}
                      style={{
                        color: "#5C6D8E",
                        marginRight: 0,
                      }}
                    />
                  }
                  label="3 days ago"
                />
              </FormGroup>
              <small className="text-blue-gradient ps-3 ms-2 pt-2 fw-500 c-pointer">
                Custom Date
              </small>
              {/* <small className="text-grey-6 mt-2 c-pointer">Clear All</small> */}
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel8"}
            onChange={handleAccordianChange("panel8")}
          >
            <AccordionSummary
              aria-controls="panel6d-content"
              id="panel6d-header"
            >
              <p className="text-lightBlue">Abandoned Checkouts</p>
            </AccordionSummary>
            <AccordionDetails>
              <FormGroup className="tags-checkbox">
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={queryFilterState.checkoutDate?.includes("0d")}
                      onChange={() => onCheckoutDateChange("0d")}
                      style={{
                        color: "#5C6D8E",
                        marginRight: 0,
                      }}
                    />
                  }
                  label="Today"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={queryFilterState.checkoutDate?.includes("1d")}
                      onChange={() => onCheckoutDateChange("1d")}
                      style={{
                        color: "#5C6D8E",
                        marginRight: 0,
                      }}
                    />
                  }
                  label="Yesterday"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={queryFilterState.checkoutDate?.includes("3d")}
                      onChange={() => onCheckoutDateChange("3d")}
                      style={{
                        color: "#5C6D8E",
                        marginRight: 0,
                      }}
                    />
                  }
                  label="3 days ago"
                />
              </FormGroup>
              <small className="text-blue-gradient ps-3 ms-2 pt-2 fw-500 c-pointer">
                Custom Date
              </small>
              {/* <small className="text-grey-6 mt-2 c-pointer">Clear All</small> */}
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel9"}
            onChange={handleAccordianChange("panel9")}
          >
            <AccordionSummary
              aria-controls="panel7d-content"
              id="panel7d-header"
            >
              <p className="text-lightBlue">Location</p>
            </AccordionSummary>
            <AccordionDetails>
              <Autocomplete
                id="free-solo-demo"
                freeSolo
                size="small"
                options={cityData?.data?.data || []}
                getOptionLabel={(option) => option?.name}
                onChange={onLocationChange}
                value={locationData}
                sx={{
                  width: 200,
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Search"
                    inputRef={(input) => input?.focus()}
                  />
                )}
              />
            </AccordionDetails>
          </Accordion>
        </div>

        <div className="d-flex flex-column py-3 px-4 filter-buttons">
          <hr className="hr-grey-6 my-3 w-100" />
          <div className="d-flex justify-content-between">
            <button
              className="button-lightBlue-outline py-2 px-3"
              onClick={() =>
                dispatchQueryFilter({
                  type: "RESET",
                })
              }
            >
              <p>Clear all Filters</p>
            </button>
            <button
              onClick={changeRoute}
              className="button-gradient py-2 px-5 w-auto "
            >
              <p>Done</p>
            </button>
          </div>
        </div>
      </SwipeableDrawer>
    </React.Fragment>
  );
};

export default FilterOrders;
