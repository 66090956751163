import { useState, forwardRef, useEffect, useReducer } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import _ from "lodash";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputAdornment,
  OutlinedInput,
  Checkbox,
  FormControlLabel,
  Chip,
  Slide,
} from "@mui/material";

import { useEditCustomerAddressMutation } from "../../../features/customers/customerAddress/customerAddressApiSlice";
import { showSuccess, showError } from "../../../features/snackbar/snackbarAction";

import AppCountrySelect from "../../../components/AppCountrySelect/AppCountrySelect";
import AppStateSelect from "../../../components/AppStateSelect/AppStateSelect";
import AppMobileCodeSelect from "../../../components/AppMobileCodeSelect/AppMobileCodeSelect";
import AppCitySelect from "../../../components/AddCitySelect/AddCitySelect";

import cancel from "../../../assets/icons/cancel.svg";
import ZipCodeComponent from "../../../components/ZipCodeCityComponent";

const Transition = forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="up"
      ref={ref}
      {...props}
    />
  );
});

const customerAddressValidation = Yup.object({
  name: Yup.string().trim().min(3).required("Required"),
  firstName: Yup.string().trim().min(3).required("Required"),
  lastName: Yup.string().trim().min(3).required("Required"),
  countryCode: Yup.string().required("Required"),
  phone: Yup.string()
    .matches(/^\d{10}$/, "Mobile Number must be 10 digits")
    .required("Required"),
  country: Yup.string().required("Required"),
  line1: Yup.string().required("Required"),
  city: Yup.string().required("Required"),
  pinCode: Yup.string()
    .matches(/^\d{6}$/, "Pin code must be 6 digits")
    .required("Required"),
  state: Yup.string().required("Required"),
});

const AddAddress = ({ addresses, openUser, handleUserClose, addressId }) => {
  const dispatch = useDispatch();

  const [
    editCustomerAddress,
    {
      data: editAddressData,
      isLoading: editAddressIsLoading,
      isSuccess: editAddressIsSuccess,
      error: editAddressError,
    },
  ] = useEditCustomerAddressMutation();

  const addressFormik = useFormik({
    initialValues: {
      id: addresses?._id || "",
      name: addresses?.name || "",
      firstName: addresses?.firstName || "",
      lastName: addresses?.lastName || "",
      companyName: addresses?.companyName || "",
      countryCode: addresses?.countryCode || "",
      country: addresses?.country?._id || "",
      phone: addresses?.phone || "",
      line1: addresses?.line1 || "",
      line2: addresses?.line2 || "",
      city: addresses?.city?._id || "",
      pinCode: addresses?.pinCode || "",
      state: addresses?.state?._id || "",
      isDefaultAddress: addresses?.isDefaultAddress || false,
      customerId: addressId || "",
    },
    enableReinitialize: true,
    validationSchema: customerAddressValidation,
    onSubmit: (values) => {
      for (const key in values) {
        if (values[key] === "" || values[key] === null) {
          delete values[key];
        }
      }
      editCustomerAddress({
        id: addresses?._id,
        details: values,
      });
      dispatch(
        showSuccess({
          message: `${
            addressFormik.values.firstName + " " + addressFormik.values.lastName
          } updated successfully`,
        })
      );
      handleUserClose();
    },
  });

  const handleCode = (event, value) => {
    addressFormik.setFieldValue("countryCode", value?._id);
  };

  const selectCountryName = (event, value) => {
    addressFormik.setFieldValue("country", value?._id);
  };

  const getStateName = (event, value) => {
    addressFormik.setFieldValue("state", value?._id);
  };

  const SelectCityName = (event, value) => {
    addressFormik.setFieldValue("city", value?._id);
  };

  return (
    <Dialog
      open={openUser}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleUserClose}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="sm"
      fullWidth={true}>
      <form
        noValidate
        onSubmit={addressFormik.handleSubmit}>
        <DialogTitle>
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex flex-column ">
              <h5 className="text-lightBlue fw-500">Edit Address</h5>

              <small className="text-grey-6 mt-1 d-block">
                ⓘ Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </small>
            </div>
            <img
              src={cancel}
              alt="cancel"
              width={30}
              onClick={handleUserClose}
              className="c-pointer"
            />
          </div>
        </DialogTitle>
        <hr className="hr-grey-6 my-0" />
        <DialogContent className="pb-4 px-4">
          <div className="row py-3 rounded-8">
            <div className="col-md-12">
              <p className="text-lightBlue mb-1">
                Name <span style={{ color: "red" }}>*</span>
              </p>
              <FormControl className="w-100 px-0">
                <OutlinedInput
                  placeholder="Office Address, Home Address"
                  size="small"
                  name="name"
                  value={addressFormik.values.name}
                  onChange={addressFormik.handleChange}
                />
              </FormControl>
              {!!addressFormik.touched.name && addressFormik.errors.name && (
                <FormHelperText error>{addressFormik.errors.name}</FormHelperText>
              )}
              <small className="text-grey-6">
                Name this address Ex. Office Address, Home Address
              </small>
            </div>
            <div className="col-12">
              <FormControlLabel
                control={
                  <Checkbox
                    name="isDefaultAddress"
                    value={addressFormik.values.isDefaultAddress}
                    onChange={addressFormik.handleChange}
                    inputProps={{ "aria-label": "controlled" }}
                    size="small"
                    style={{
                      color: "#5C6D8E",
                    }}
                  />
                }
                label="Set as Default Address"
                sx={{
                  "& .MuiTypography-root": {
                    fontSize: 13,
                    color: "#c8d8ff",
                  },
                }}
              />
            </div>
            <div className="col-md-6 mt-3">
              <p className="text-lightBlue mb-1">
                First Name <span style={{ color: "red" }}>*</span>
              </p>
              <FormControl className="w-100 px-0">
                <OutlinedInput
                  placeholder="Enter First Name"
                  size="small"
                  name="firstName"
                  value={addressFormik.values.firstName}
                  onChange={addressFormik.handleChange}
                />
              </FormControl>
              {!!addressFormik.touched.firstName && addressFormik.errors.firstName && (
                <FormHelperText error>{addressFormik.errors.firstName}</FormHelperText>
              )}
            </div>
            <div className="col-md-6 mt-3">
              <p className="text-lightBlue mb-1">
                Last Name <span style={{ color: "red" }}>*</span>
              </p>
              <FormControl className="w-100 px-0">
                <OutlinedInput
                  placeholder="Enter Last Name"
                  size="small"
                  name="lastName"
                  value={addressFormik.values.lastName}
                  onChange={addressFormik.handleChange}
                />
              </FormControl>
              {!!addressFormik.touched.lastName && addressFormik.errors.lastName && (
                <FormHelperText error>{addressFormik.errors.lastName}</FormHelperText>
              )}
            </div>
            <div className="col-md-12 mt-3">
              <p className="text-lightBlue mb-1">Company Name</p>
              <FormControl className="w-100 px-0">
                <OutlinedInput
                  placeholder="Enter Email ID"
                  size="small"
                  name="companyName"
                  value={addressFormik.values.companyName}
                  onChange={addressFormik.handleChange}
                />
              </FormControl>
            </div>
            <div className="col-md-12 mt-3">
              <p className="text-lightBlue mb-1">
                Mobile Number <span style={{ color: "red" }}>*</span>
              </p>
              <FormControl className="w-100 px-0">
                <OutlinedInput
                  placeholder="Enter Mobile Number"
                  size="small"
                  sx={{ paddingLeft: 0 }}
                  name="phone"
                  value={addressFormik.values.phone}
                  onChange={addressFormik.handleChange}
                  startAdornment={
                    <InputAdornment position="start">
                      <AppMobileCodeSelect
                        formik={addressFormik}
                        handleCode={handleCode}
                      />
                    </InputAdornment>
                  }
                />
              </FormControl>
              {(!!addressFormik.touched.countryCode || !!addressFormik.touched.phone) &&
                (addressFormik.errors.countryCode || addressFormik.errors.phone) && (
                  <FormHelperText error>
                    {addressFormik.errors.countryCode || addressFormik.errors.phone}
                  </FormHelperText>
                )}
            </div>
            <ZipCodeComponent
              cityVal={addressFormik.values.city}
              onchangeCity={SelectCityName}
              errorCity={addressFormik.errors.city}
              touchedCity={addressFormik.touched.city}
              stateVal={addressFormik.values.state}
              onchangeState={getStateName}
              errorState={addressFormik.errors.state}
              touchedState={addressFormik.touched.state}
              countryVal={addressFormik.values.country}
              onchangeCountry={selectCountryName}
              errorCountry={addressFormik.errors.country}
              touchedCountry={addressFormik.touched.country}
              zipVal={addressFormik.values.pinCode}
              onchangeZip={addressFormik.handleChange}
              errorZip={addressFormik.errors.pinCode}
              touchedZip={addressFormik.touched.pinCode}
              onchangeLine1={(line) => addressFormik.setFieldValue("line1", line)}
              onchangeLine2={(line) => addressFormik.setFieldValue("line2", line.target.value)}
              touchedLine1={addressFormik.touched.line1}
              errorLine1={addressFormik.errors.line1}
              nameLine2="line2"
              namePincode="pinCode"
              valueLine2={addressFormik.values.line2}
              onchangePincode={(code) => addressFormik.setFieldValue("pinCode", code)}
              line1Val={addressFormik.values.line1}
            />
            {/* <div className="col-md-6 mt-3">
              <p className="text-lightBlue mb-1">
                Address Line 1 <span style={{ color: "red" }}>*</span>
              </p>
              <FormControl className="w-100 px-0">
                <OutlinedInput
                  placeholder="Enter Address Line 1"
                  size="small"
                  name="line1"
                  value={addressFormik.values.line1}
                  onChange={addressFormik.handleChange}
                />
              </FormControl>
              {!!addressFormik.touched.line1 && addressFormik.errors.line1 && (
                <FormHelperText error>{addressFormik.errors.line1}</FormHelperText>
              )}
            </div>
            <div className="col-md-6 mt-3">
              <p className="text-lightBlue mb-1">Address Line 2</p>
              <FormControl className="w-100 px-0">
                <OutlinedInput
                  placeholder="Enter Address Line 2"
                  size="small"
                  name="line2"
                  value={addressFormik.values.line2}
                  onChange={addressFormik.handleChange}
                />
              </FormControl>
            </div>
            <div className="col-md-6 mt-3">
              <p className="text-lightBlue mb-1">
                Town/City <span style={{ color: "red" }}>*</span>
              </p>
              <AppCitySelect
                initVal={addressFormik.values.city}
                selectCityName={SelectCityName}
              />
              {!!addressFormik.touched.city && addressFormik.errors.city && (
                <FormHelperText error>{addressFormik.errors.city}</FormHelperText>
              )}
            </div>
            <div className="col-md-6 mt-3">
              <p className="text-lightBlue mb-1">
                Zipcode/Postalcode <span style={{ color: "red" }}>*</span>
              </p>
              <FormControl className="w-100 px-0">
                <OutlinedInput
                  placeholder="Enter Zipcode/Postalcode"
                  size="small"
                  name="pinCode"
                  value={addressFormik.values.pinCode}
                  onChange={addressFormik.handleChange}
                />
              </FormControl>
              {(!!addressFormik.touched.city || !!addressFormik.touched.pinCode) &&
                (addressFormik.errors.city || addressFormik.errors.pinCode) && (
                  <FormHelperText error>
                    {addressFormik.errors.city || addressFormik.errors.pinCode}
                  </FormHelperText>
                )}
            </div>
            <div className="col-md-12 mt-3  add-user-country">
              <div className="d-flex align-items-center justify-content-between">
                <p className="text-lightBlue mb-1">
                  State or Region <span style={{ color: "red" }}>*</span>
                </p>
                <small className="text-grey-6 mb-1">(Optional)</small>
              </div>
              <AppStateSelect
                initValue={addressFormik.values.state}
                getStateName={getStateName}
              />
              {!!addressFormik.touched.state && addressFormik.errors.state && (
                <FormHelperText error>{addressFormik.errors.state}</FormHelperText>
              )}
            </div>
            <div className="col-md-12 mt-3 add-user-country">
              <p className="text-lightBlue mb-1">
                Country <span style={{ color: "red" }}>*</span>
              </p>
              <AppCountrySelect
                selectCountryName={selectCountryName}
                initValue={addressFormik.values.country}
              />
              {!!addressFormik.touched.country && addressFormik.errors.country && (
                <FormHelperText error>{addressFormik.errors.country}</FormHelperText>
              )}
            </div> */}
          </div>
        </DialogContent>
        <hr className="hr-grey-6 my-0" />
        <DialogActions className="d-flex justify-content-between px-4 py-3">
          <button
            className="button-grey py-2 px-5"
            onClick={handleUserClose}>
            <p className="text-lightBlue">Cancel</p>
          </button>
          <button
            className="button-gradient py-2 px-5"
            type="submit">
            <p>Save</p>
          </button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddAddress;
