import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ImageUpload from '../../Component/ImageUpload';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import _ from 'lodash';
import {
  useGetAllBannerQuery,
  useUpdateBannerMutation,
  useUpdateWebsiteAttrMutation,
} from '../../../../../features/theme/content/bannerApiSlice';
import {
  showSuccess,
  showError,
} from '../../../../../features/snackbar/snackbarAction';
import SnackbarUtils from '../../../../../features/snackbar/useSnackbar';
import InputBox from '../../../../../components/Setting/InputBox';
import { LoadingButton } from '@mui/lab';
import { FormHelperText, Switch } from '@mui/material';
// import CuratedImage from './CuratedImage';

const bannerValidationSchema = Yup.object({
  id: Yup.string().trim(),
  name: Yup.string().trim().required('Required'),
  active: Yup.boolean().required('Required'),
});

const AnnouncementBar = () => {
  const dispatch = useDispatch();
  const [selectedBanner, setSelectedBanner] = useState(null);
  const { data: bannersData } = useGetAllBannerQuery({});
  const [updateWebsiteAttr, { isLoading: updateWebsiteAttrIsLoading }] =
    useUpdateWebsiteAttrMutation();

  const collectionData = bannersData?.data?.[0]?.attributes.find(
    (item) => item._id === '65b0e2b67b56580008c7feac'
  );

  const bannerFormik = useFormik({
    initialValues: {
      id: selectedBanner?.id || '',
      name: selectedBanner?.name || '',
      active: selectedBanner?.active || false,
    },
    enableReinitialize: true,
    validationSchema: bannerValidationSchema,
    onSubmit: (values) => {
      console.log(values);
      SnackbarUtils.savingToast();
      updateWebsiteAttr({
        details: values,
        attributeId: collectionData._id,
        layoutId: bannersData?.data?.[0]?._id,
      })
        .unwrap()
        .then(() => {
          SnackbarUtils.hideToast();
          dispatch(
            showSuccess({
              message: 'Updated successfully',
            })
          );
        })
        .catch((error) => {
          SnackbarUtils.hideToast();
          if (error?.data?.message) {
            dispatch(showError({ message: error.data.message }));
          } else {
            dispatch(
              showError({
                message: 'Something went wrong!, please try again',
              })
            );
          }
        });
    },
  });

  const selectedBannerHandler = (banner) => {
    setSelectedBanner({
      id: banner?._id || '',
      name: banner?.name || '',
      active: banner?.active || true,
    });
    bannerFormik.setFieldValue('id', banner?._id);
    bannerFormik.setFieldValue('name', banner?.name);
    bannerFormik.setFieldValue('active', banner?.active);
  };
  const closeHandler = () => {
    setSelectedBanner(null);
  };

  useEffect(() => {
    return () => {
      setSelectedBanner(null);
    };
  }, []);

  return (
    <div className='py-3 px-3 rounded-8 mb-3'>
      {bannersData && (
        <div className='my-2'>
          <div className='bg-black-20 py-3 px-3 rounded-8 mb-3'>
            <div
              className='d-flex align-items-center justify-content-between c-pointer'
              onClick={
                selectedBanner
                  ? closeHandler
                  : selectedBannerHandler.bind(null, collectionData)
              }
            >
              <div className='d-flex align-items-center'>
                <p className='text-lightBlue fw-500'>{collectionData.name}</p>
              </div>
              <ArrowDropDownIcon sx={{ color: '#C8D8FF' }} />
            </div>
            {selectedBanner && (
              <div className='mt-2' style={{ display: 'grid', gap: '1rem' }}>
                <div>
                  <InputBox
                    heading='name'
                    name='name'
                    value={bannerFormik.values.name}
                    onBlur={bannerFormik.handleBlur}
                    onChange={bannerFormik.handleChange}
                  />
                  {!!bannerFormik.touched.name && bannerFormik.errors.name && (
                    <FormHelperText error>
                      {bannerFormik.errors.name}
                    </FormHelperText>
                  )}
                </div>
                <div>
                  <p className='text-lightBlue fw-500'>Active Status</p>
                  <Switch
                    checked={bannerFormik.values.active}
                    onChange={bannerFormik.handleChange}
                    name='active'
                    inputProps={{ 'aria-label': 'controlled' }}
                    size='small'
                    style={{
                      color: '#5C6D8E',
                    }}
                  />
                </div>
                <LoadingButton
                  className='button-gradient py-2 px-4 w-auto'
                  type='button'
                  loading={updateWebsiteAttrIsLoading}
                  disabled={updateWebsiteAttrIsLoading}
                  onClick={bannerFormik.handleSubmit}
                >
                  <p>Save</p>
                </LoadingButton>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default AnnouncementBar;
