import React, { useEffect, useReducer, useState } from "react";
import "./CreateRoles.scss";
import {
  Link,
  createSearchParams,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import * as Yup from "yup";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import _ from "lodash";
import { useFormik } from "formik";
// ! COMPONENT IMPORTS
import SearchIcon from "@mui/icons-material/Search";
import AppTextEditor from "../../../components/AppTextEditor/AppTextEditor";
import UploadMediaBox from "../../../components/UploadMediaBox/UploadMediaBox";
import TabPanel from "../../../components/TabPanel/TabPanel";
// ! IMAGES IMPORTS
import arrowLeft from "../../../assets/icons/arrowLeft.svg";
import info from "../../../assets/icons/info.svg";
import addMedia from "../../../assets/icons/addMedia.svg";
import profile from "../../../assets/profile/profile.svg";
import deleteRed from "../../../assets/icons/delete.svg";

// import userRoles from "../../../assets/icons/userRoles.svg";
// ! MATERIAL IMPORTS
import {
  Avatar,
  Badge,
  Box,
  FormControl,
  OutlinedInput,
  Checkbox,
  FormControlLabel,
  styled,
  Popover,
  FormGroup,
  Tab,
  Tabs,
  Tooltip,
  FormHelperText,
  CircularProgress,
  Autocomplete,
  MenuItem,
  TextField,
} from "@mui/material";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
// ! MATERIAL ICONS IMPORTS
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import { useDispatch } from "react-redux";
import {
  useCreateRoleMutation,
  useEditRoleMutation,
  useGetAllRolesQuery,
} from "../../../features/team/roleApiSlice";
import { showError, showSuccess } from "../../../features/snackbar/snackbarAction";
import { DiscardModalSecondary } from "../../../components/Discard/DiscardModal";
import { LoadingButton } from "@mui/lab";
import {
  useGetAllModelsQuery,
  useGetAllPermissionsQuery,
} from "../../../features/team/permissionApiSlice";
import chat from "../../../assets/icons/theme/chat.svg";
import userLarge from "../../../assets/images/users/userLarge.svg";
import { useGetAllProfileDetailsQuery } from "../../../features/myProfile/myProfileApiSlice";
import { useBulkUpdateMemberMutation } from "../../../features/team/membersApiSlice";
import { omitEmptyKeys } from "../../../utils/helper";
import AddMembers from "../Members/AddMembers";
import { SaveFooterTertiary } from "../../../components/SaveFooter/SaveFooter";
import { Close } from "@mui/icons-material";
import TableLoader from "../../../components/Loader/TableLoader";
import AsyncImage from "../../../components/AsyncImage/AsyncImage";

// ? PERMISSIONS ACCORDIAN STARTS HERE
const Accordion = styled((props) => (
  <MuiAccordion
    disableGutters
    elevation={0}
    square
    {...props}
  />
))(({ theme }) => ({
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowForwardIosSharpIcon
        sx={{
          fontSize: "0.9rem",
          color: "#c8d8ff",
        }}
      />
    }
    {...props}
  />
))(({ theme }) => ({
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    padding: "0px",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: "0 16px 16px 28px",
}));

const validationSchema = Yup.object({
  name: Yup.string().trim().required("required"),
  status: Yup.string().oneOf(["active", "in-active"]).required("required"),
  imageUrl: Yup.string().url(),
  description: Yup.string().trim(),
});

const initialRolesState = {
  isEditing: false,
  edited: false,
};
const rolesReducer = (state, action) => {
  if (action.type === "ENABLE_EDIT") {
    return {
      ...state,
      isEditing: true,
    };
  }
  if (action.type === "DISABLE_EDIT") {
    return {
      ...state,
      isEditing: false,
    };
  }
  if (action.type === "EDITED_ENABLE") {
    return {
      ...state,
      edited: true,
    };
  }
  if (action.type === "EDITED_DISABLE") {
    return {
      ...state,
      edited: false,
    };
  }
  return initialRolesState;
};

export default function CreateRoles() {
  const [rolesState, dispatchRoles] = React.useReducer(rolesReducer, initialRolesState);

  const [tabIndex, setTabIndex] = React.useState(0);
  const [expanded, setExpanded] = React.useState("");

  const [addProductDrawer, setAddProductDrawer] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const { id } = useParams();

  const [searchParams] = useSearchParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const backHandler = () => {
    navigate({
      pathname: "/teams/roles",
      search: `?${createSearchParams({ filter: searchParams.get("filter") })}`,
    });
  };

  const { data: allModels, isLoading: modelsIsLoading } = useGetAllModelsQuery(
    {
      pageNo: 1,
      pageSize: 1000,
    },
    { skip: !!id }
  );

  const { data: allPermissions, isLoading: permissionsIsLoading } = useGetAllPermissionsQuery(
    {
      roleId: id ?? "",
      pageNo: 1,
      pageSize: 1000,
    },
    { skip: !id }
  );

  const [createRole, { isLoading: createRoleIsLoading }] = useCreateRoleMutation();
  const [editRole, { isLoading: editRoleIsLoading, isSuccess: editRoleIsSuccess }] =
    useEditRoleMutation();
  const [bulkUpdateMember] = useBulkUpdateMemberMutation();

  const { data: membersData } = useGetAllProfileDetailsQuery({ role: id }, { skip: !id });

  const { data: membersSearchData } = useGetAllProfileDetailsQuery({
    pageNo: 1,
    pageSize: 1000000,
    status: "active",
  });

  const {
    data: rolesData,
    isSuccess: rolesIsSuccess,
    error: rolesError,
    isError: rolesIsError,
  } = useGetAllRolesQuery({ id: id }, { skip: !id });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: rolesData?.data?.data[0]?.name || "",
      status: rolesData?.data?.data[0]?.status || "active",
      imageUrl: rolesData?.data?.data[0]?.imageUrl || "",
      description: rolesData?.data?.data[0]?.description || "",
      updates: id ? membersData?.data?.data : [],
      permissions: !id
        ? allModels?.map(({ _id, isAllowed, module }) => ({
            _id,
            model: module,
            modelId: _id,
            isAllowed: isAllowed ?? false,
          }))
        : allPermissions?.map(({ _id, isAllowed, model }) => ({
            _id,
            model: model?.module,
            modelId: model?._id,
            isAllowed: isAllowed ?? false,
          })) ?? [],
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatchRoles({ type: "EDITED_DISABLE" });
      const { updates, ...rolesValues } = values;
      const rolesObj = omitEmptyKeys(rolesValues);
      rolesObj.permissions = rolesObj.permissions.map(({ _id, isAllowed, modelId }) => ({
        _id,
        isAllowed,
        modelId,
      }));
      if (id) {
        editRole({
          id: rolesData?.data?.data[0]?._id,
          details: rolesObj,
        })
          .unwrap()
          .then((res) => {
            console.log(res?.data?._id);
            dispatchRoles({ type: "DISABLE_EDIT" });
            bulkUpdateMember({
              updates: updates.map((item) => ({
                ...item,
                roles: [res?.data?._id],
              })),
            })
              .unwrap()
              .then(() => {
                backHandler();
                dispatch(showSuccess({ message: "Role edit successfully" }));
              });
          })
          .catch((e) =>
            dispatch(
              showError({
                message: e?.data?.message ?? e?.message ?? "Something went wrong",
              })
            )
          );
      } else {
        createRole(rolesObj)
          .unwrap()
          .then((res) => {
            if (updates?.length > 0) {
              return bulkUpdateMember({
                updates: updates.map((item) => ({
                  ...item,
                  roles: [res?.data?._id],
                })),
              }).unwrap();
            } else {
              return Promise.resolve();
            }
          })
          .then(() => {
            backHandler();
            dispatch(showSuccess({ message: "Role created successfully" }));
          })
          .catch((e) =>
            dispatch(
              showError({
                message: e?.data?.message ?? e?.message ?? "Something went wrong",
              })
            )
          );
      }
    },
  });

  console.log(formik.values);

  const [autoCompleteText, setAutoCompleteText] = useState("");

  const changeMediaUrl = (value) => {
    if (value) {
      formik.setFieldTouched("imageUrl", true);
      formik.setFieldValue("imageUrl", value ?? "");
    }
  };
  const deleteMediaUrl = () => formik.setFieldValue("imageUrl", "");

  const handleClick = (member) => {
    const isMemberAlreadySelected = formik.values.updates?.some(
      (existingMember) => existingMember._id === member._id
    );
    if (!isMemberAlreadySelected) {
      formik.setValues((prev) => ({
        ...prev,
        updates: [...prev.updates, member],
      }));
      // setAddedMembers((prev) => [...prev, member._id]);
    } else {
      formik.setValues((prev) => ({
        ...prev,
        updates: prev.updates.filter((e) => e._id !== member._id),
      }));
      // setAddedMembers((prev) => prev.filter((e) => e !== member._id));
    }
  };

  const handleRevokeClick = (item) => {
    const updatedSelectedMembersData = formik.values.updates.filter(
      (member) => member._id !== item._id
    );
    formik.setFieldValue("updates", updatedSelectedMembersData);
  };

  const toggleAddMemberDrawer = (anchor, open) => (event) => {
    if (event && event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }

    setAddProductDrawer({ ...addProductDrawer, [anchor]: open });
  };

  const handleAddMember = () => {
    setAddProductDrawer({ ...addProductDrawer, right: true });
  };

  useEffect(() => {
    if (rolesIsSuccess) {
      dispatchRoles({ type: "EDITED_DISABLE" });
    }
    if (rolesIsError) {
      if (rolesError?.data?.message) {
        dispatch(showError({ message: rolesError?.data?.message }));
      } else {
        dispatch(showError({ message: "Something went wrong, please try again" }));
      }
    }
  }, [rolesIsSuccess, rolesIsError, rolesError, editRoleIsSuccess, id, rolesData, dispatch]);

  useEffect(() => {
    if (id && !_.isEqual(formik.values, formik.initialValues)) {
      dispatchRoles({ type: "ENABLE_EDIT" });
      dispatchRoles({ type: "EDITED_ENABLE" });
    } else if (id && _.isEqual(formik.values, formik.initialValues)) {
      dispatchRoles({ type: "EDITED_DISABLE" });
      dispatchRoles({ type: "DISABLE_EDIT" });
    }
  }, [formik.initialValues, formik.values, id]);

  // console.log({
  //   formikValues: formik?.values,
  //   formikValuesInitial: formik?.initialValues,
  //   edited: rolesState.edited,
  // });

  return (
    <div className="page container-fluid position-relative user-group">
      <div className="row justify-content-between">
        <div className="d-flex align-items-center w-auto ps-0">
          <Link
            to="/teams/roles"
            className="d-flex">
            <img
              src={arrowLeft}
              alt="arrowLeft"
              width={9}
              className="c-pointer"
            />
          </Link>
          <h5 className="page-heading ms-2 ps-1">
            {id ? formik?.values?.name : `Create a Role`}
          </h5>
        </div>
      </div>
      <form
        noValidate
        onSubmit={formik.handleSubmit}>
        <div className="row mt-3">
          <div className="col-lg-9 mt-3">
            <div className="bg-black-15 border-grey-5 rounded-8 p-3 row attributes">
              <div className="col-12 px-0">
                <div className="d-flex mb-1">
                  <p className="text-lightBlue">Role Name</p>
                  <Tooltip
                    title="Lorem ipsum"
                    placement="top">
                    <img
                      src={info}
                      alt="info"
                      className="c-pointer ms-2"
                      width={13.5}
                    />
                  </Tooltip>
                </div>
                <FormControl className="w-100 px-0">
                  <OutlinedInput
                    placeholder="Enter Role Name"
                    size="small"
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.onBlur}
                  />
                  <FormHelperText error>
                    {formik.touched.name && formik.errors.name}
                  </FormHelperText>
                </FormControl>
              </div>
              <div className="col-12 mt-3 px-0">
                <div className="d-flex  mb-1">
                  <p className="text-lightBlue">Description</p>
                  <Tooltip
                    title="Lorem ipsum"
                    placement="top">
                    <img
                      src={info}
                      alt="info"
                      className="c-pointer ms-2"
                      width={13.5}
                    />
                  </Tooltip>
                </div>
                <AppTextEditor
                  config={{
                    toolbar: {
                      items: [
                        "undo",
                        "redo",
                        "|",
                        "heading",
                        "|",
                        "bold",
                        "italic",
                        "|",
                        "bulletedList",
                        "numberedList",
                        "outdent",
                        "indent",
                      ],
                    },
                  }}
                  value={formik.values.description}
                  setFieldValue={(v) => {
                    formik.setFieldTouched("description", true);
                    formik.setFieldValue("description", v);
                  }}
                />
              </div>
            </div>

            <div className="bg-black-9 border-grey-5 rounded-8 p-3 row features mt-4">
              <Box
                sx={{ width: "100%" }}
                className="col-lg-12 d-flex justify-content-between tabs-header-box">
                <Tabs
                  className="tabs col-lg-4"
                  value={tabIndex}
                  onChange={(_, index) => setTabIndex(index)}>
                  <Tab
                    label="Permissions"
                    className="tabs-head"
                  />
                  <Tab
                    label="Members"
                    className="tabs-head"
                  />
                </Tabs>
              </Box>
              <TabPanel
                value={tabIndex}
                index={0}>
                <div className="bg-black-15 border-grey-5 rounded-8 p-3 row features mt-4">
                  <div className="d-flex justify-content-between mb-2 px-0 col-12 align-items-center">
                    <h6 className="text-lightBlue me-auto text-lightBlue col-auto ps-0 fw-500">
                      Permissions
                    </h6>
                  </div>

                  {modelsIsLoading || permissionsIsLoading ? (
                    <TableLoader />
                  ) : (
                    formik.values.permissions?.map((perm, ix) => {
                      return (
                        <Accordion
                          key={perm._id}
                          expanded={expanded === perm._id}
                          className="create-teams-accordion px-0"
                          onChange={(e, v) => setExpanded(v ? perm._id : "")}>
                          <AccordionSummary>
                            <div className="d-flex justify-content-between w-100 pe-4">
                              <FormControlLabel
                                label={perm?.model}
                                onClick={(e) => e.stopPropagation()}
                                control={
                                  <Checkbox
                                    checked={perm.isAllowed}
                                    size="small"
                                    name={`permissions.${ix}.isAllowed`}
                                    // onChange={formik.handleChange}
                                    onChange={(e) => {
                                      formik.setFieldValue(
                                        `permissions.${ix}.isAllowed`,
                                        e.target.checked
                                      );
                                      // checkWholePermission(e.target.checked, ix);
                                    }}
                                    style={{
                                      color: "#5C6D8E",
                                      marginRight: 0,
                                    }}
                                  />
                                }
                                sx={{
                                  "& .MuiTypography-root": {
                                    fontSize: 14,
                                    color: "#c8d8ff",
                                  },
                                }}
                              />
                            </div>
                          </AccordionSummary>
                          {/* <AccordionDetails>
                            <FormGroup className="tags-checkbox">
                              {Object.keys(perm.permissions).map((pr) => (
                                <FormControlLabel
                                  key={pr}
                                  label={pr}
                                  onChange={(e) =>
                                    checkSinglePermission(e.target.checked, ix, pr)
                                  }
                                  control={
                                    <Checkbox
                                      checked={perm.permissions[pr].isAllowed}
                                      size="small"
                                      style={{
                                        color: "#5C6D8E",
                                        marginRight: 0,
                                      }}
                                    />
                                  }
                                />
                              ))}
                            </FormGroup>
                          </AccordionDetails> */}
                        </Accordion>
                      );
                    })
                  )}
                </div>
              </TabPanel>
              <TabPanel
                value={tabIndex}
                index={1}>
                <div className="bg-black-15 border-grey-5 rounded-8 p-3 row features mt-4">
                  <div className="d-flex justify-content-between mb-2 px-0 col-12 align-items-center">
                    <h6 className="text-lightBlue me-auto text-lightBlue col-auto ps-0 fw-500">
                      Members
                    </h6>
                    {!!id && (
                      <div
                        className="py-2 px-3 rounded-4 button-grey-outline"
                        onClick={handleAddMember}>
                        <small>+ Add New Member</small>
                      </div>
                    )}
                  </div>

                  <Autocomplete
                    // freeSolo
                    multiple
                    onInputChange={(e, v) => {
                      setAutoCompleteText(v);
                    }}
                    clearIcon={<Close />}
                    onClose={() => {
                      setAutoCompleteText("");
                    }}
                    // value={Array.from(formik?.values?.updates)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="w-100 px-0 mb-4 mt-2"
                        size="small"
                        placeholder="Add Members"
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <SearchIcon
                              sx={{
                                color: "#C8D8FF",
                                fontSize: "16px",
                                marginRight: "5px",
                              }}
                            />
                          ),
                        }}
                      />
                    )}
                    getOptionLabel={(option) => option?.fullName}
                    options={
                      Array.isArray(membersSearchData?.data?.data)
                        ? membersSearchData?.data?.data?.filter((e) =>
                            e.fullName.toLowerCase().includes(autoCompleteText.toLowerCase())
                          )
                        : []
                    }
                    renderOption={(prop, item) => (
                      <div
                        className="d-flex align-items-center justify-content-between mt-1 p-3"
                        {...prop}
                        onClick={() => {}}>
                        <div className="d-flex align-items-center me-5">
                          <Checkbox
                            onChange={() => handleClick(item)}
                            checked={formik?.values?.updates?.some((i) => i?._id === item?._id)}
                          />
                          <AsyncImage
                            src={item?.imageUrl}
                            placeholder={userLarge}
                            alt="product"
                            className="me-2 rounded-4"
                            height={52}
                            width={52}
                          />
                          <div className="text-lightBlue ms-2">
                            <p>{item.fullName}</p>
                            <small>
                              {item.email}
                              <Tooltip
                                title="copy"
                                placement="top">
                                <ContentCopyIcon
                                  sx={{
                                    color: "#5c6d8e",
                                    fontSize: 12,
                                    cursor: "pointer",
                                    marginLeft: 1,
                                  }}
                                />
                              </Tooltip>
                            </small>
                          </div>
                        </div>
                      </div>
                    )}
                  />

                  <div style={{ height: "20rem", overflow: "scroll" }}>
                    {formik.values.updates?.map((item) => (
                      <div className="d-flex align-items-center justify-content-between mb-4 mt-1">
                        <div className="d-flex align-items-center">
                          <Badge
                            badgeContent="."
                            variant="dot"
                            color="success"
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}>
                            <AsyncImage
                              src={item?.imageUrl}
                              placeholder={userLarge}
                              alt="product"
                              className="me-2 rounded-4"
                              height={52}
                              width={52}
                            />
                          </Badge>
                          <div className="text-lightBlue ms-2">
                            <p>{item.fullName}</p>
                            <small>
                              {item.email}
                              <Tooltip
                                title="copy"
                                placement="top">
                                <ContentCopyIcon
                                  sx={{
                                    color: "#5c6d8e",
                                    fontSize: 12,
                                    cursor: "pointer",
                                    marginLeft: 1,
                                  }}
                                />
                              </Tooltip>
                            </small>
                            <div className="ex-small text-grey-6">
                              Access given on 23/09/21 at 9:03 pm
                            </div>
                          </div>
                        </div>
                        <div className="d-flex">
                          <div>
                            <img src={chat} />
                            <small className="ms-1 me-5">Message</small>
                          </div>
                          <div
                            className="c-pointer"
                            onClick={() => handleRevokeClick(item)}>
                            <img src={deleteRed} />
                            <small className="ms-1">Revoke</small>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </TabPanel>
            </div>
          </div>

          <div className="col-lg-3 mt-3 pe-0 ps-0 ps-lg-3">
            <UploadMediaBox
              name="imageUrl"
              // module="store"
              noteText="Recommended Size: 1000px x 1000px"
              imageName={addMedia}
              headingName="Media"
              UploadChange={changeMediaUrl}
              previousImage={formik.values.imageUrl}
              setDelete={deleteMediaUrl}
            />
          </div>
        </div>

        <SaveFooterTertiary
          show={id ? rolesState.isEditing : true}
          onDiscard={backHandler}
          isLoading={createRoleIsLoading || editRoleIsLoading}
        />
        <DiscardModalSecondary
          when={rolesState?.edited}
          message="role tab"
        />
      </form>
      <AddMembers
        toggleAddMemberDrawer={toggleAddMemberDrawer}
        addProductDrawer={addProductDrawer}
        setAddProductDrawer={setAddProductDrawer}
        id=""
        reset
      />
    </div>
  );
}
