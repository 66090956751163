const Label = (props) => {
  const { children, className, required, style } = props;
  return (
    <>
      <label
        className={`small text-lightBlue ${
          required ? "me-1" : "me-2"
        } ${className}`}
        style={style}
      >
        {children}
      </label>
      {required && (
        <span style={{ color: "#F67476" }} className="me-2">
          *
        </span>
      )}
    </>
  );
};

export default Label;
