import { useNavigate } from "react-router-dom";
import {
    Autocomplete,
    Box,
    TextField,
} from "@mui/material";
import OnBoard from "./OnBoard";
import market from "../../../assets/images/market.svg";
import india from "../../../assets/flag/india.svg";

const countries = [
    { code: "IN", label: "India", phone: "91", imageUrl: india }
];

export default function StepThree() {
    const navigate = useNavigate();

    const handleContinue = () => {
        navigate("/auth/stepFour");
    }

    return (
        <OnBoard image={market} buttonName={"Create Store"} onClick={handleContinue}>
            <p className="d-flex text-grey-6 mb-5">
                <p className="text-lightBlue">03&nbsp;</p>
                /&nbsp;04
            </p>
            <h2 className="text-blue-gradient fw-600 mb-2">
                Select your Primary Market
            </h2>
            <small className="d-flex text-grey-6 w-75 mb-4">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lacus nulla eget adipiscing faucibus platea. Eu ultrices ipsum
            </small>
            <div className="onBoardDrop">
                <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    size="small"
                    options={countries ?? []}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            placeholder={"Select Industry"}
                        />
                    )}
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            <small className="text-lightBlue">{option?.label}</small>
                        </li>
                    )}
                />
            </div>
        </OnBoard>
    );
}