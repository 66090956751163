import LinearProgressBar from "../../components/ProgressBar/LinearProgressBar";

const SalesRatio = ({ percent, image, name, amount, ratio }) => {
  return (
    <div className="mb-3 my-auto">
      <div className="d-flex justify-content-between mb-2">
        <div className="d-flex">
          <img
            alt="country"
            src={image}
            width={20}
          />
          <p className="text-lightBlue ms-3">{name}</p>
        </div>
        <div className="d-flex">
          <p className="text-lightBlue">{amount}</p>
          <small className="text-green-2 ms-3 fw-400">{ratio}</small>
        </div>
      </div>
      <LinearProgressBar percent={percent} />
    </div>
  );
};

export default SalesRatio;
