import React, { useCallback, useEffect, useState } from "react";
import "./CreateLabels.scss";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import _ from "lodash";
import { useFormik } from "formik";
// ! COMPONENT IMPORTS
import AddProductCondition from "../../../components/AddProductCondition/AddProductCondition";
import { AntSwitch } from "../../../components/AntSwitch/AntSwitch";
import { AppReactLivePreview } from "../../../components/AppReactImageGallery/AppReactImageGallery";
// ! IMAGES IMPORTS
import arrowLeft from "../../../assets/icons/arrowLeft.svg";
import info from "../../../assets/icons/info.svg";
// import paginationRight from "../../../assets/icons/paginationRight.svg";
// import paginationLeft from "../../../assets/icons/paginationLeft.svg";
import textAdd from "../../../assets/icons/text.svg";
import imageAdd from "../../../assets/icons/imageAdd.svg";
// import uploadBadge from "../../../assets/icons/uploadBadge.svg";
// ! MATERIAL IMPORTS
import {
  FormControl,
  Tooltip,
  ToggleButton,
  ToggleButtonGroup,
  OutlinedInput,
  TextField,
  FormHelperText,
} from "@mui/material";
import { DesktopDateTimePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useDispatch } from "react-redux";
import { useCreateLabelsBadgesMutation } from "../../../features/functionality/labelsBadges/labelsBadges";
import {
  showError,
  showSuccess,
} from "../../../features/snackbar/snackbarAction";
import { UploadMediaLarge } from "../../../components/UploadMediaBox/UploadMedia";
import { LoadingButton } from "@mui/lab";
import { DiscardModalSecondary } from "../../../components/Discard/DiscardModal";
import ColorInput from "../../../components/ColorInput/ColorInput";
import moment from "moment";
import { useGetAllLabelsBadgesSettingsQuery } from "../../../features/functionality/labelsBadgesSetting/labelsBadgesSettingApiSlice";
import { StatusBoxSecondary } from "../../../components/StatusBox/StatusBox";

const initialValues = {
  searchValue: '',
  type: "",
  name: "",
  status: "in-active",
  contentType: "text",
  image: "",
  text: "",
  scheduleVisibility: false,
  startDate: "",
  endDate: "",
  color: {
    backgroundColor: "#0e0d21",
    textColor: "#c8d8ff",
  },
  addProductType: "automated",
  products: [],
  filters: [
    {
      type: "",
      operator: "",
      value: [],
      dropDownData: [],
    },
  ],
};

const filterSchema = Yup.object().shape({
  type: Yup.string().required("Type is required"),
  operator: Yup.string().when(["type"], ([type], schema) => {
    return type === "allProducts" ? schema : schema.required("required");
  }),
  value: Yup.array().when(["type"], ([type], schema) => {
    return type === "allProducts"
      ? schema
      : schema.required("required").min(1, "At least one value is required");
  }),
});

const validationSchema = Yup.object({
  type: Yup.string().oneOf(["label", "badge"]).required("required"),
  name: Yup.string().trim().required("required"),
  status: Yup.string()
    .oneOf(["scheduled", "active", "in-active"])
    .required("required"),
  contentType: Yup.string().oneOf(["text", "image"]).required("required"),
  image: Yup.string().when("contentType", {
    is: "image",
    then: (schema) => schema.required("required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  text: Yup.string().when("contentType", {
    is: "text",
    then: (schema) => schema.required("required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  scheduleVisibility: Yup.boolean().default(false),

  startDate: Yup.date().when("status", {
    is: "scheduled",
    then: (schema) => schema.required("required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  endDate: Yup.date().when(
    ["status", "startDate"],
    ([status, startDate], schema) => {
      if (status === "scheduled" && startDate) {
        return schema.min(
          startDate,
          "End Date must be greater than Start Date"
        );
      }
    }
  ),
  color: Yup.object({
    backgroundColor: Yup.string(),
    textColor: Yup.string(),
  }),
  addProductType: Yup.string()
    .oneOf(["automated", "manual"])
    .required("required"),
  products: Yup.array().of(Yup.string()), //.min(1, "Min 1 Product Required"),
  filters: Yup.mixed().when(["addProductType"], ([addProductType], schema) => {
    if (addProductType === "automated") {
      return Yup.array().of(filterSchema);
    }
    return schema;
  }),
});

const CreateLabels = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { type } = useParams();

  const itemString =
    (type?.at(0)?.toUpperCase() ?? "") + (type?.slice(1) ?? "");

  const [discardable, setDiscardable] = useState(false);
  const onDiscarded = () => navigate(-1);

  const { data: labelsBadgesSettingData } = useGetAllLabelsBadgesSettingsQuery(
    {},
    { skip: type !== "label" }
  );
  const labelSettings = labelsBadgesSettingData?.data?.data?.[0] ?? null;

  const [createLabelsBadges, { isLoading: createLabelsBadgesIsLoading }] =
    useCreateLabelsBadgesMutation();

  const formik = useFormik({
    initialValues: { ...initialValues, type },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (values.contentType === "text") delete values["image"];
      if (values.contentType === "image") delete values["text"];
      if (values.status !== "scheduled") {
        delete values["startDate"];
        delete values["endDate"];
      } else if (!values.endDate) delete values["endDate"];
      if (!values.color.backgroundColor) delete values.color["backgroundColor"];
      if (!values.color.textColor) delete values.color["textColor"];
      // const productsVal = {
      //   ...values,
      //   products: values.products.map((e) => e?.product),
      // };
      createLabelsBadges(values)
        .unwrap()
        .then(() => {
          setDiscardable(false);
          onDiscarded();
          dispatch(
            showSuccess({ message: "Label/Badge created successfully" })
          );
        })
        .catch((e) =>
          dispatch(
            showError({ message: e?.data?.message ?? "Something went wrong" })
          )
        );
    },
  });

  // console.log(
  //   JSON.stringify(
  //     {
  //       values: formik.values,
  //       errors: formik.errors,
  //       touched: formik.touched,
  //     },
  //     null,
  //     1
  //   )
  // );

  useEffect(() => {
    const check = _.isEqual(formik.values, formik.initialValues);
    setDiscardable(!check);
  }, [formik.initialValues, formik.values]);

  const uploadImage = useCallback(
    (value) => {
      formik.setFieldTouched("image", true);
      !!value && formik.setFieldValue("image", value ?? "");
    },
    // eslint-disable-next-line
    []
  );
  const statusHandler = (status) => {
    formik.setFieldValue("status", status);
    formik.setFieldValue("startDate", "");
    formik.setFieldValue("endDate", "");
  };

  const scheduleProductHandler = ({ startDate, endDate }) => {
    formik.setFieldValue("startDate", startDate);
    formik.setFieldValue("endDate", endDate);
    formik.setFieldValue("status", "scheduled");
  };

  console.log(formik.values, "fomrikValues");
  return (
    <div className="page container-fluid position-relative">
      <div className="row justify-content-between">
        <div className="d-flex align-items-center w-auto ps-0">
          <Link to="/functionality/LabelsBadges" className="d-flex">
            <img
              src={arrowLeft}
              alt="arrowLeft"
              width={9}
              className="c-pointer"
            />
          </Link>

          <h5 className="page-heading ms-2 ps-1">Create {itemString}</h5>
        </div>
      </div>
      <form noValidate onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-lg-9 mt-3">
            <div className="bg-black-15 border-grey-5 rounded-8 p-3 row productInfo">
              <div className="col-12 px-0">
                <div className="row">
                  <div className="col-8">
                    <div className="d-flex mb-1">
                      <p className="text-lightBlue px-0 me-2">
                        {itemString} Name
                      </p>
                      <Tooltip title="Lorem ipsum" placement="top">
                        <img
                          src={info}
                          alt="info"
                          className="c-pointer"
                          width={13.5}
                        />
                      </Tooltip>
                    </div>
                    <FormControl className="w-100 px-0">
                      <OutlinedInput
                        placeholder={`Enter ${itemString} Name`}
                        size="small"
                        name="name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <FormHelperText error>
                        {formik.touched.name && formik.errors.name}
                      </FormHelperText>
                    </FormControl>
                    <small className="mt-1 text-grey-6 font1">
                      Note: User can't see this, its for your reference
                    </small>
                  </div>
                  <div className="col-4">
                    <StatusBoxSecondary
                      title="status"
                      status={formik.values.status}
                      onStatus={statusHandler}
                      startDate={formik.values.startDate}
                      endDate={formik.values.endDate}
                      onSchedule={scheduleProductHandler}
                      containerStyles="status-container"
                      titleStyles="status-title"
                      actionStyles="status-action"
                      activeText="active"
                      inActiveText="in-active"
                    />
                    {/* <div className="d-flex mb-1">
                      <p className="text-lightBlue me-2">Status</p>
                      <Tooltip title="Lorem ipsum" placement="top">
                        <img
                          src={info}
                          alt="info"
                          className="c-pointer"
                          width={13.5}
                        />
                      </Tooltip>
                    </div>
                    <ToggleButtonGroup
                      value={formik.values.status}
                      onChange={(_, status) => {
                        formik.setFieldTouched("status", true);
                        if (status) formik.setFieldValue("status", status);
                      }}
                      disabled={formik.values.scheduleVisibility}
                      aria-label="text formatting"
                      className="row d-flex px-2 productInfo-toggle"
                      size="small"
                      exclusive
                    >
                      <ToggleButton
                        value="active"
                        aria-label="active"
                        style={{ width: "50%" }}
                        className="productInfo-toggle__active"
                      >
                        <div className="d-flex">
                          <p className="text-grey-6">Active</p>
                        </div>
                      </ToggleButton>
                      <ToggleButton
                        value="in-active"
                        aria-label="in-active"
                        style={{ width: "50%" }}
                        className="productInfo-toggle__draft"
                      >
                        <div className="d-flex">
                          <p className="text-grey-6">In-Active</p>
                        </div>
                      </ToggleButton>
                    </ToggleButtonGroup>
                    <FormHelperText error>
                      {formik.touched.status && formik.errors.status}
                    </FormHelperText> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-black-15 border-grey-5 rounded-8 p-3 row mt-4">
              <div className="col-12 px-0 d-flex align-items-center">
                <h6 className="text-lightBlue me-2 text-lightBlue fw-500">
                  {itemString} Content
                </h6>
                <Tooltip title="Lorem ipsum" placement="top">
                  <img
                    src={info}
                    alt="info"
                    className="c-pointer"
                    width={13.5}
                  />
                </Tooltip>
              </div>

              <p className="text-lightBlue px-0 me-2 mt-3">Type</p>
              <ToggleButtonGroup
                exclusive
                value={formik.values.contentType}
                onChange={(_, contentType) => {
                  formik.setFieldTouched("contentType", true);
                  formik.setFieldValue("contentType", contentType);
                  formik.setFieldTouched("image", false);
                  formik.setFieldValue("image", "");
                  formik.setFieldTouched("text", false);
                  formik.setFieldValue("text", "");
                }}
                aria-label="text alignment"
                className="productDetails-toggle px-0 mt-2"
              >
                <ToggleButton value="image" aria-label="image">
                  <div className="d-flex">
                    <img src={imageAdd} alt="imageAdd" width={25} />
                    <div className="ms-3 text-start">
                      <p className="text-lightBlue">Image</p>
                      <small className="text-grey-6 d-block">
                        Add Image {itemString}
                      </small>
                    </div>
                  </div>
                </ToggleButton>
                <ToggleButton value="text" aria-label="text">
                  <div className="d-flex">
                    <img src={textAdd} alt="text" width={25} />
                    <div className="ms-3 text-start">
                      <p className="text-lightBlue">Text</p>
                      <small className="text-grey-6 d-block">
                        Add custom text {itemString}
                      </small>
                    </div>
                  </div>
                </ToggleButton>
              </ToggleButtonGroup>

              {formik.values.contentType === "image" && (
                <div className="col-12 px-0 mt-3 mb-1">
                  <UploadMediaLarge
                    fileSrc={formik.values.image}
                    error={formik.errors.image}
                    onUpload={uploadImage}
                    onBlur={formik.handleBlur}
                    name="image"
                    module="sizeChart"
                    isSubmitting={formik.isSubmitting}
                    touched={!!formik.touched.image}
                  />
                  <FormHelperText error>
                    {formik.touched.image && formik.errors.image}
                  </FormHelperText>
                </div>
              )}

              {formik.values.contentType === "text" && (
                <>
                  <div className="d-flex col-12 px-0 mt-3 justify-content-between mb-1">
                    <div className="d-flex align-items-center">
                      <p className="text-lightBlue me-auto">Enter Text</p>
                    </div>
                    <p className="text-blue-2">+ Add Variables</p>
                  </div>
                  <FormControl className="w-100 px-0">
                    <OutlinedInput
                      placeholder="Enter Text"
                      size="small"
                      name="text"
                      value={formik.values.text}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <FormHelperText error>
                      {formik.touched.text && formik.errors.text}
                    </FormHelperText>
                  </FormControl>

                  <div className="row align-items-center my-3">
                    <div className="col-3">
                      <h6 className="text-lightBlue fw-500">
                        {itemString} Color
                      </h6>
                    </div>
                    <div className="col-9">
                      <div className="row align-items-center">
                        <div className="col-6">
                          <div className="d-flex mb-1">
                            <p className="text-lightBlue me-2">Background</p>
                            <Tooltip title="Lorem ipsum" placement="top">
                              <img
                                src={info}
                                alt="info"
                                className="c-pointer"
                                width={13.5}
                              />
                            </Tooltip>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="d-flex mb-1">
                            <p className="text-lightBlue me-2">Text</p>
                            <Tooltip title="Lorem ipsum" placement="top">
                              <img
                                src={info}
                                alt="info"
                                className="c-pointer"
                                width={13.5}
                              />
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <FormControl className="w-100 px-0">
                            <ColorInput
                              inputProps={{
                                name: "color.backgroundColor",
                                value: formik.values.color?.backgroundColor,
                                onChange: formik.handleChange,
                                onBlur: formik.handleBlur,
                              }}
                            />
                          </FormControl>
                        </div>
                        <div className="col-6">
                          <FormControl className="w-100 px-0">
                            <ColorInput
                              inputProps={{
                                name: "color.textColor",
                                value: formik.values.color?.textColor,
                                onChange: formik.handleChange,
                                onBlur: formik.handleBlur,
                              }}
                            />
                          </FormControl>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {/* <div className="d-flex align-items-center mt-4 justify-content-between px-0">
                <p className="text-lightBlue px-0 me-2">Schedule Visibility</p>
                <div>
                  <AntSwitch
                    inputProps={{ "aria-label": "ant design" }}
                    name="scheduleVisibility"
                    checked={formik.values.scheduleVisibility}
                    onChange={(e) => {
                      formik.setFieldValue(
                        "status",
                        e.target.checked ? "scheduled" : "in-active"
                      );
                      formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur}
                  />
                  <FormHelperText error>
                    {formik.touched.scheduleVisibility &&
                      formik.errors.scheduleVisibility}
                  </FormHelperText>
                </div>
              </div>
              {formik.values.scheduleVisibility && (
                <div className="col-12 px-0">
                  <div className="row align-items-start">
                    <div className="col-md-6 mt-3">
                      <div className="d-flex mb-1">
                        <p className="text-lightBlue">Start Date</p>
                        <Tooltip title="Lorem ipsum" placement="top">
                          <img
                            src={info}
                            alt="info"
                            className="ms-2 c-pointer"
                            width={13.5}
                          />
                        </Tooltip>
                      </div>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DesktopDateTimePicker
                          className="w-100"
                          value={formik.values.startDate}
                          onChange={(mt, kt) =>
                            formik.setFieldValue(
                              "startDate",
                              mt?.format() ?? ""
                            )
                          }
                          onClose={() =>
                            formik.setFieldTouched("startDate", true)
                          }
                          minDateTime={moment()}
                          maxDateTime={moment(formik.values.endDate)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size="small"
                              onBlur={() =>
                                formik.setFieldTouched("startDate", true)
                              }
                            />
                          )}
                        />
                      </LocalizationProvider>
                      <FormHelperText error>
                        {formik.touched.startDate && formik.errors.startDate}
                      </FormHelperText>
                    </div>
                    <div className="col-md-6 mt-3">
                      <div className="d-flex mb-1 justify-content-between">
                        <div className="d-flex">
                          <p className="text-lightBlue">End Date</p>
                          <Tooltip title="Lorem ipsum" placement="top">
                            <img
                              src={info}
                              alt="info"
                              className="ms-2 c-pointer"
                              width={13.5}
                            />
                          </Tooltip>
                        </div>
                        <small className="text-grey-6">(optional)</small>
                      </div>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DesktopDateTimePicker
                          className="w-100"
                          disabled={!formik.values.startDate}
                          value={formik.values.endDate}
                          onChange={(mt, kt) =>
                            formik.setFieldValue("endDate", mt?.format() ?? "")
                          }
                          onClose={() =>
                            formik.setFieldTouched("endDate", true)
                          }
                          minDateTime={moment(formik.values.startDate)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size="small"
                              onBlur={() =>
                                formik.setFieldTouched("endDate", true)
                              }
                            />
                          )}
                        />
                      </LocalizationProvider>
                      <FormHelperText error>
                        {formik.touched.endDate && formik.errors.endDate}
                      </FormHelperText>
                    </div>
                  </div>
                </div>
              )} */}
            </div>
            <AddProductCondition formik={formik} />
          </div>
          <div className="col-lg-3 mt-3 pe-0 ps-0 ps-lg-3">
            <div className="bg-black-15 border-grey-5 rounded-8 p-3">
              <h6 className="text-grey-6 mb-3">Preview:</h6>
              <AppReactLivePreview
                values={formik.values}
                labelSettings={labelSettings}
              />
              <p className="mt-3 text-lightBlue">The Fringe Diamond Ring</p>
              <small className="text-grey-6 my-2">
                SKU123456&nbsp;|&nbsp;JWLellers
              </small>
              <div>
                <small className="text-grey-6">Diamond Ring</small>
              </div>
            </div>
          </div>
        </div>
        <div className="row bottom-buttons pt-5 pb-3 justify-content-between">
          <div className="d-flex w-auto px-0">
            <button
              type="button"
              onClick={onDiscarded}
              className="button-red-outline py-2 px-4"
            >
              <p>Discard</p>
            </button>
          </div>
          <LoadingButton
            type="submit"
            loading={createLabelsBadgesIsLoading}
            disabled={createLabelsBadgesIsLoading}
            className="button-gradient ms-3 py-2 px-4 w-auto"
          >
            <p>Save</p>
          </LoadingButton>
        </div>
        <DiscardModalSecondary when={discardable} message="size chart tab" />
      </form>
    </div>
  );
};

export default CreateLabels;
