import { useState } from "react";
import { Link } from "react-router-dom";
import { Popover } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import arrowLeft from "../../../assets/icons/arrowLeft.svg";
import currencyRupee from "../../../assets/icons/currencyRupee.svg";
import currencyDollar from "../../../assets/icons/currencyDollar.svg";
import currencyPound from "../../../assets/icons/currencyPound.svg";
import addMarkets from "../../../assets/icons/addMarkets.svg";

export default function CreateOrderHeader() {
  const [anchorCurrencyEl, setAnchorCurrencyEl] = useState(null);

  return (
    <div className="row justify-content-between">
      <div className="d-flex align-items-center w-auto ps-0">
        <Link
          to="/orders/allOrders"
          className="d-flex">
          <img
            src={arrowLeft}
            alt="arrowLeft"
            width={9}
            className="c-pointer"
          />
        </Link>

        <div className="d-flex align-items-center ms-2">
          <div>
            <h5 className="text-lightBlue fw-500">Create Order</h5>
          </div>
        </div>
      </div>

      {/* <div className="d-flex align-items-center w-auto pe-0">
        <div
          onClick={(e) => setAnchorCurrencyEl(e.currentTarget)}
          className="d-flex button-transparent px-2 py-2 c-pointer border-grey-5 rounded-3 hover-back">
          <div className="d-flex align-items-center">
            <img
              src={currencyRupee}
              alt="currencyRupee"
              width={18}
              className="me-2"
            />
            <small className="text-lightBlue d-block">
              Indian Rupees
              <KeyboardArrowDownIcon
                sx={{
                  fontSize: 14,
                  marginLeft: 1,
                }}
              />
            </small>
          </div>
        </div>
        <Popover
          anchorEl={anchorCurrencyEl}
          open={Boolean(anchorCurrencyEl)}
          onClose={() => setAnchorCurrencyEl(null)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}>
          <div className="py-2 px-1">
            <div className="d-flex align-items-center rounded-3 p-2 hover-back c-pointer">
              <img
                src={currencyRupee}
                alt="currencyRupee"
                width={18}
                className="me-2"
              />
              <small className="text-lightBlue d-block">Indian Currency</small>
            </div>
            <div className="d-flex align-items-center rounded-3 p-2 hover-back c-pointer">
              <img
                src={currencyDollar}
                alt="currencyDollar"
                width={18}
                className="me-2"
              />
              <small className="text-lightBlue d-block">USA Currency</small>
            </div>
            <div className="d-flex align-items-center rounded-3 p-2 hover-back c-pointer">
              <img
                src={currencyPound}
                alt="currencyPound"
                width={18}
                className="me-2"
              />
              <small className="text-lightBlue d-block">UK Currency</small>
            </div>
            <div className="d-flex align-items-center rounded-3 p-2 hover-back c-pointer">
              <img
                src={addMarkets}
                alt="addMarkets"
                width={18}
                className="me-2"
              />
              <small className="text-lightBlue d-block">Add Markets</small>
            </div>
          </div>
        </Popover>
      </div> */}
    </div>
  );
}
