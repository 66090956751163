import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
// ! COMPONENT IMPORTS
import {
  EnhancedTableHead,
  stableSort,
  getComparator,
} from "../../../components/TableDependencies/TableDependencies";
import TabPanel from "../../../components/TabPanel/TabPanel";
// ! IMAGES IMPORTS
import rolesOwner from "../../../assets/images/teams/rolesOwner.svg";
import rolesSuperAdmin from "../../../assets/images/teams/rolesSuperAdmin.svg";
import rolesAdmin from "../../../assets/images/teams/rolesAdmin.svg";
import rolesStaff from "../../../assets/images/teams/rolesStaff.svg";
import rolesFreelance from "../../../assets/images/teams/rolesFreelance.svg";
import user from "../../../assets/images/users/user.svg";
import teamMember1 from "../../../assets/images/products/teamMember1.svg";
import teamMember2 from "../../../assets/images/products/teamMember2.svg";
import teamMember3 from "../../../assets/images/products/teamMember3.svg";
// ! MATERIAL IMPORTS
import {
  Checkbox,
  Chip,
  Popover,
  SwipeableDrawer,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Tooltip,
  Paper,
  Box,
  Tabs,
  Tab,
  FormControlLabel,
  FormGroup,
  styled,
} from "@mui/material";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
// ! MATERIAL ICONS IMPORTS
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import InventoryIcon from "@mui/icons-material/Inventory";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import ChatIcon from "@mui/icons-material/Chat";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import TableMassActionButton from "../../../components/TableMassActionButton/TableMassActionButton";
import TableLoader from "../../../components/Loader/TableLoader";
import NoDataFound from "../../../components/NoDataFound/NoDataFound";
import AsyncImage from "../../../components/AsyncImage/AsyncImage";
import moment from "moment";
import ArchiveModal, {
  MultipleArchiveModal,
} from "../../../components/ArchiveModal/ArchiveModal";
import { useDispatch, useStore } from "react-redux";
import { showError, showSuccess } from "../../../features/snackbar/snackbarAction";
import {
  UnArchiveModalSecondary,
  UnMultipleArchiveModalSecondary,
} from "../../../components/UnArchiveModal/UnArchiveModal";
import { DeleteModalSecondary } from "../../../components/DeleteModal/DeleteModal";
import { useGetAllProfileDetailsQuery } from "../../../features/myProfile/myProfileApiSlice";
import CopyToClipboard from "react-copy-to-clipboard";
import defaultUser from "../../../assets/images/users/user_defauldp.svg";
import { useGetAllPermissionsQuery } from "../../../features/team/permissionApiSlice";

// ? PERMISSIONS ACCORDIAN STARTS HERE
const Accordion = styled((props) => (
  <MuiAccordion
    disableGutters
    elevation={0}
    square
    {...props}
  />
))(({ theme }) => ({
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowForwardIosSharpIcon
        sx={{
          fontSize: "0.9rem",
          color: "#c8d8ff",
        }}
      />
    }
    {...props}
  />
))(({ theme }) => ({
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    padding: "0px",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: "0 16px 16px 28px",
}));

const permissionAccordionData = [
  {
    id: 1,
    name: "Dashboard",
    accDetails: [
      { id: 101, name: "Content 1" },
      { id: 102, name: "Content 2" },
      { id: 103, name: "Content 3" },
    ],
  },
  {
    id: 2,
    name: "Orders",
    accDetails: [
      { id: 101, name: "Content 1" },
      { id: 102, name: "Content 2" },
      { id: 103, name: "Content 3" },
    ],
  },
  {
    id: 3,
    name: "Products",
    accDetails: [
      { id: 101, name: "Content 1" },
      { id: 102, name: "Content 2" },
      { id: 103, name: "Content 3" },
    ],
  },
  {
    id: 4,
    name: "Parameters",
    accDetails: [
      { id: 101, name: "Content 1" },
      { id: 102, name: "Content 2" },
      { id: 103, name: "Content 3" },
    ],
  },
  {
    id: 5,
    name: "Users",
    accDetails: [
      { id: 101, name: "Content 1" },
      { id: 102, name: "Content 2" },
      { id: 103, name: "Content 3" },
    ],
  },
  {
    id: 6,
    name: "Analytics",
    accDetails: [
      { id: 101, name: "Content 1" },
      { id: 102, name: "Content 2" },
      { id: 103, name: "Content 3" },
    ],
  },
  {
    id: 7,
    name: "Functionality",
    accDetails: [
      { id: 101, name: "Content 1" },
      { id: 102, name: "Content 2" },
      { id: 103, name: "Content 3" },
    ],
  },
  {
    id: 8,
    name: "Offers",
    accDetails: [
      { id: 101, name: "Content 1" },
      { id: 102, name: "Content 2" },
      { id: 103, name: "Content 3" },
    ],
  },
  {
    id: 9,
    name: "Emailers",
    accDetails: [
      { id: 101, name: "Content 1" },
      { id: 102, name: "Content 2" },
      { id: 103, name: "Content 3" },
    ],
  },
  {
    id: 10,
    name: "Teams",
    accDetails: [
      { id: 101, name: "Content 1" },
      { id: 102, name: "Content 2" },
      { id: 103, name: "Content 3" },
    ],
  },
  {
    id: 11,
    name: "Website",
    accDetails: [
      { id: 101, name: "Content 1" },
      { id: 102, name: "Content 2" },
      { id: 103, name: "Content 3" },
    ],
  },
];
// ? PERMISSIONS ACCORDIAN ENDS HERE

// ? TABLE STARTS HERE
function createData(rId, roles, members, permissions, createdOn, action) {
  return { rId, roles, members, permissions, createdOn, action };
}

const rows = [
  createData(1, "Owner", "SS", "All Permissions", "23/09/21 at 09:23am"),
  createData(2, "Super Admin", "SS, SB, +2", "Limited", "23/09/21 at 09:23am"),
  createData(3, "Admin", "", "Limited", "23/09/21 at 09:23am"),
  createData(4, "Staff", "SS, SB, +2", "Limited", "23/09/21 at 09:23am"),
  createData(5, "Freelance", "SS, SB, +2", "Limited", "23/09/21 at 09:23am"),
];

const headCells = [
  {
    id: "roles",
    numeric: false,
    disablePadding: true,
    label: "Roles",
  },
  {
    id: "members",
    numeric: false,
    disablePadding: false,
    label: "Members",
  },
  {
    id: "permissions",
    numeric: false,
    disablePadding: false,
    label: "Permissions",
  },
  {
    id: "createdOn",
    numeric: false,
    disablePadding: false,
    label: "Modified On",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    label: "Actions",
  },
];
// ? TABLE ENDS HERE

const RolesTable = ({
  isLoading,
  list,
  totalCount,
  rowsPerPage,
  changeRowsPerPage,
  changePage,
  page,
  rolesType,
  edit,
  editRoles,
  bulkEdit,
  deleteData,
  bulkDelete,
}) => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // ? PERMISSIONS ACCORDIAN STARTS HERE
  const [expanded, setExpanded] = React.useState("");

  const handleAccordianChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  // ? PERMISSIONS ACCORDIAN ENDS HERE

  // ? TABLE STARTS HERE
  const dispatch = useDispatch();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("rId");
  const [selected, setSelected] = useState([]);
  const [showArchivedModal, setShowArchivedModal] = useState(false);
  const [showMultipleArchivedModal, setShowMultipleArchivedModal] = useState(false);
  const [showUnArchivedModal, setShowUnArhcivedModal] = useState(false);
  const [showMultipleUnArhcivedModal, setShowMultipleUnArhcivedModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showMultipleDeleteModal, setShowMultipleDeleteModal] = useState(false);
  const [isMassDelete, setIsMassDelete] = useState();
  const [role, setRole] = useState(null);
  const [roleId, setRoleId] = useState("");
  const [roleName, setRoleName] = useState("");
  const [copied, setCopied] = useState(false);

  const { data: membersData, isFetching: membersDataIsLoading } = useGetAllProfileDetailsQuery(
    { role: roleId, pageNo: 1, pageSize: 1000 },
    { skip: !roleId }
  );

  const { data: allPermissions, isFetching: permissionsIsLoading } = useGetAllPermissionsQuery(
    { roleId: roleId, pageNo: 1, pageSize: 1000 },
    { skip: !roleId }
  );

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // const handleSelectAllClick = (event) => {
  //   if (event.target.checked) {
  //     const newSelected = list.map((n) => n.rId);
  //     setSelected(newSelected);
  //     return;
  //   }
  //   setSelected([]);
  // };
  const handleSelectAllClick = (event) => {
    if (selected.length > 0) {
      setSelected([]);
    } else if (event.target.checked) {
      const newSelected = list.slice(0, rowsPerPage).map((n) => n._id);
      setSelected(newSelected);
    } else {
      setSelected([]);
    }
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  const isSelected = (name) => selected.indexOf(name) !== -1;
  // ? TABLE ENDS HERE

  // ? ACTIVITY DRAWER STARTS HERE
  const [activityDrawer, setActivityDrawer] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleActivityDrawer = (anchor, open, row) => (event) => {
    setRole(row);
    setRoleId(row?._id);
    setRoleName(row?.name);
    if (event && event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }

    setActivityDrawer({ ...activityDrawer, [anchor]: open });
  };
  // ? ACTIVITY DRAWER ENDS HERE

  // * ACCESS POPOVERS STARTS
  const [anchorAccessEl, setAnchorAccessEl] = React.useState(null);
  const handleAccessClick = (event) => {
    setAnchorAccessEl(event.currentTarget);
  };
  const handleAccessClose = () => {
    setAnchorAccessEl(null);
  };
  const openAccess = Boolean(anchorAccessEl);
  const idAccess = openAccess ? "simple-popover" : undefined;
  // * ACTIVITY POPOVERS ENDS

  const handleMassAction = (status) => {
    if (status === "Mass Un-Archive") {
      setShowMultipleUnArhcivedModal(true);
    }
    if (status === "Archive") {
      setShowMultipleArchivedModal(true);
    }
    if (status === "Delete") {
      setShowMultipleDeleteModal(true);
      setIsMassDelete(true);
    }
  };

  const handleArchive = (row) => {
    setShowArchivedModal(true);
    setRole(row);
  };

  const handleArchiveModalOnSave = () => {
    editRoles({
      id: role?._id,
      details: {
        status: "archieved",
      },
    })
      .unwrap()
      .then(() => dispatch(showSuccess({ message: "Role Archived Successfully" })))
      .catch((e) =>
        dispatch(
          showError({
            message: e?.data?.message ?? e?.message ?? "Something went wrong",
          })
        )
      );
    setShowArchivedModal(false);
    setRole("");
    setSelected([]);
  };

  const handleArchiveModalClose = () => {
    setShowArchivedModal(false);
    setShowMultipleArchivedModal(false);
  };

  const handleMultipleArchiveModalOnSave = () => {
    const newState = selected.map((id) => {
      return {
        id,
        status: "archieved",
      };
    });
    bulkEdit({ updates: newState })
      .unwrap()
      .then(() =>
        dispatch(
          showSuccess({
            message: `${selected.length === 1 ? "Role" : "Roles"} Archived Successfully`,
          })
        )
      )
      .catch((e) =>
        dispatch(
          showError({
            message: e?.data?.message ?? e?.message ?? "Something went wrong",
          })
        )
      );
    setSelected([]);
    setShowMultipleArchivedModal(false);
  };

  const handleUnArchive = (row) => {
    setShowUnArhcivedModal(true);
    setRole(row);
  };

  const handleUnArchiveModalOnSave = () => {
    editRoles({
      id: role?._id,
      details: {
        status: "active",
      },
    })
      .unwrap()
      .then(() => dispatch(showSuccess({ message: "Role Un-Archived successfully" })))
      .catch((e) =>
        dispatch(
          showError({
            message: e?.data?.message ?? e?.message ?? "Something went wrong",
          })
        )
      );
    setShowUnArhcivedModal(false);
    setSelected([]);
  };

  const handleUnArchiveModalClose = () => {
    setShowUnArhcivedModal(false);
    setShowMultipleUnArhcivedModal(false);
  };

  const handleMultipleUnArchiveModalOnSave = () => {
    const newState = selected.map((id) => {
      return {
        id,
        status: "active",
      };
    });
    bulkEdit({ updates: newState })
      .unwrap()
      .then(() =>
        dispatch(
          showSuccess({
            message: `${selected.length === 1 ? "Role" : "Roles"} Un-Archived Successfully`,
          })
        )
      )
      .catch((e) =>
        dispatch(
          showError({
            message: e?.data?.message ?? e?.message ?? "Something went wrong",
          })
        )
      );
    setShowMultipleUnArhcivedModal(false);
    setSelected([]);
  };

  const handleDelete = (row) => {
    setIsMassDelete(false);
    setShowDeleteModal(true);
    setRole(row);
  };

  const handleDeleteModalOnSave = () => {
    deleteData(role?._id)
      .unwrap()
      .then(() => dispatch(showSuccess({ message: "Role Deleted successfully" })))
      .catch((e) =>
        dispatch(
          showError({
            message: e?.data?.message ?? e?.message ?? "Something went wrong",
          })
        )
      );
    setShowDeleteModal(false);
    setSelected([]);
  };

  const handleDeleteModalClose = () => {
    setShowDeleteModal(false);
    setShowMultipleDeleteModal(false);
  };

  const handleMultipleDeleteOnSave = () => {
    bulkDelete({ deletes: selected })
      .unwrap()
      .then(() =>
        dispatch(
          showSuccess({
            message: `${selected.length === 1 ? "Role" : "Roles"} Deleted Successfully`,
          })
        )
      )
      .catch((e) =>
        dispatch(
          showError({
            message: e?.data?.message ?? e?.message ?? "Something went wrong",
          })
        )
      );
    setShowMultipleDeleteModal(false);
    setIsMassDelete(false);
    setSelected([]);
  };

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <React.Fragment>
      {selected.length > 0 && (
        <div className="d-flex align-items-center px-2 mb-3">
          <button className="button-grey py-2 px-3">
            <small className="text-lightBlue">
              {selected.length} users are selected&nbsp;
              <span
                className="text-blue-2 c-pointer"
                onClick={() => setSelected([])}>
                (Clear Selection)
              </span>
            </small>
          </button>
          {rolesType === 1 ? (
            <TableMassActionButton
              headingName="Mass Action"
              onSelect={handleMassAction}
              defaultValue={["Delete", "Mass Un-Archive"]}
            />
          ) : (
            <button
              className="button-grey py-2 px-3 ms-2 c-pointers"
              variant="contained"
              onClick={() => {
                handleMassAction("Archive");
              }}>
              <small className="text-lightBlue">Mass Archive</small>
            </button>
          )}
        </div>
      )}
      {list.length ? (
        <>
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size="medium">
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={list.length}
                headCells={headCells}
              />
              <TableBody>
                {stableSort(list, getComparator(order, orderBy)).map((row, index) => {
                  const isItemSelected = isSelected(row._id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row._id}
                      selected={isItemSelected}
                      className="table-rows">
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                          onClick={(event) => handleClick(event, row._id)}
                          size="small"
                          style={{
                            color: "#5C6D8E",
                          }}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                        onClick={toggleActivityDrawer("right", true, row)}
                        className="c-pointer">
                        <div className="d-flex align-items-center py-2 my-1">
                          <AsyncImage
                            src={row.imageUrl}
                            placeholder={defaultUser}
                            alt="role"
                            className="me-2 rounded-4 c-pointer"
                            height={45}
                            width={45}
                            style={{ borderRadius: "50%" }}
                            // style={{ objectFit: "cover" }}
                          />
                          {/* {row.roles.toLowerCase() === "owner" && (
                          <img
                            src={rolesOwner}
                            alt="user"
                            className="me-2 rounded-circle"
                            height={45}
                            width={45}
                          />
                        )}
                        {row.roles.toLowerCase() === "super admin" && (
                          <img
                            src={rolesSuperAdmin}
                            alt="user"
                            className="me-2 rounded-circle"
                            height={45}
                            width={45}
                          />
                        )}
                        {row.roles.toLowerCase() === "admin" && (
                          <img
                            src={rolesAdmin}
                            alt="user"
                            className="me-2 rounded-circle"
                            height={45}
                            width={45}
                          />
                        )}
                        {row.roles.toLowerCase() === "staff" && (
                          <img
                            src={rolesStaff}
                            alt="user"
                            className="me-2 rounded-circle"
                            height={45}
                            width={45}
                          />
                        )}
                        {row.roles.toLowerCase() === "freelance" && (
                          <img
                            src={rolesFreelance}
                            alt="user"
                            className="me-2 rounded-circle"
                            height={45}
                            width={45}
                          />
                        )} */}
                          <div>
                            <p className="text-lightBlue rounded-circle fw-500 c-pointer">
                              {row?.name}
                            </p>
                            {/* <small className="mt-2 text-grey-6">
                                {row?.totalRoles ? row?.totalRoles : `No Members`}
                              </small> */}
                          </div>
                        </div>
                      </TableCell>
                      {/* <TableCell style={{ width: 200 }}>
                          <div className="d-flex align-items-center c-pointer">
                            {row.members ? (
                              <React.Fragment>
                                <img
                                  src={teamMember1}
                                  alt="teamMember1"
                                  onClick={toggleActivityDrawer("right", true)}
                                />
                                <img
                                  src={teamMember3}
                                  alt="teamMember2"
                                  onClick={toggleActivityDrawer("right", true)}
                                />
                                <img
                                  src={teamMember2}
                                  alt="teamMember3"
                                  onClick={toggleActivityDrawer("right", true)}
                                />
                              </React.Fragment>
                            ) : (
                              <Link
                                to="/teams/roles/create"
                                className="text-decoration-none"
                              >
                                <p className="text-blue-2 text-decoration-underline">
                                  + Add Members
                                </p>
                              </Link>
                            )}
                          </div>
                        </TableCell> */}
                      <TableCell>
                        <p className="text-lightBlue">
                          {row?.totalRoles ? row?.totalRoles : `No Members`}
                        </p>
                      </TableCell>
                      <TableCell style={{ width: 200 }}>
                        <div className="d-flex align-items-center">
                          {!row.isSuperAdmin ? (
                            <Chip
                              label="Partial Permissons"
                              size="small"
                              className="px-1"
                              variant="outlined"
                            />
                          ) : (
                            <Chip
                              label="All Permissons"
                              size="small"
                              className="px-1"
                            />
                          )}
                        </div>
                      </TableCell>
                      <TableCell style={{ width: 240 }}>
                        <div className="d-flex align-items-center">
                          <p className="text-lightBlue me-2">
                            {moment(row.updatedAt).format("DD/MM/YY [at] hh:mm A")}
                          </p>
                          <img
                            src={teamMember1}
                            alt="teamMember1"
                          />
                        </div>
                      </TableCell>

                      <TableCell style={{ width: 140, padding: 0 }}>
                        <div className="d-flex align-items-center">
                          <Tooltip
                            title="View"
                            placement="top">
                            <div className="table-edit-icon rounded-4 p-2">
                              <VisibilityOutlinedIcon
                                sx={{
                                  color: "#5c6d8e",
                                  fontSize: 18,
                                  cursor: "pointer",
                                }}
                                onClick={toggleActivityDrawer("right", true, row)}
                              />
                            </div>
                          </Tooltip>
                          {row?.status === "active" ? (
                            <React.Fragment>
                              <Tooltip
                                title="Edit"
                                placement="top">
                                <div
                                  className="table-edit-icon rounded-4 p-2"
                                  onClick={(e) => {
                                    edit(row, rolesType);
                                  }}>
                                  <EditOutlinedIcon
                                    sx={{
                                      color: "#5c6d8e",
                                      fontSize: 18,
                                      cursor: "pointer",
                                    }}
                                  />
                                </div>
                              </Tooltip>
                              <Tooltip
                                title="Archive"
                                placement="top"
                                onClick={() => handleArchive(row)}>
                                <div className="table-edit-icon rounded-4 p-2">
                                  <InventoryIcon
                                    sx={{
                                      color: "#5c6d8e",
                                      fontSize: 18,
                                      cursor: "pointer",
                                    }}
                                  />
                                </div>
                              </Tooltip>
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <Tooltip
                                title="Un-Archive"
                                placement="top"
                                onClick={() => handleUnArchive(row)}>
                                <div className="table-edit-icon rounded-4 p-2">
                                  <InventoryIcon
                                    sx={{
                                      color: "#5c6d8e",
                                      fontSize: 18,
                                      cursor: "pointer",
                                    }}
                                  />
                                </div>
                              </Tooltip>
                              <Tooltip
                                title="Delete"
                                placement="top">
                                <div
                                  className="table-edit-icon rounded-4 p-2"
                                  onClick={(e) => {
                                    handleDelete(row);
                                  }}>
                                  <DeleteIcon
                                    sx={{
                                      color: "#5c6d8e",
                                      fontSize: 18,
                                      cursor: "pointer",
                                    }}
                                  />
                                </div>
                              </Tooltip>
                            </React.Fragment>
                          )}
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page - 1}
            onPageChange={changePage}
            onRowsPerPageChange={changeRowsPerPage}
            className="table-pagination"
          />
        </>
      ) : isLoading ? (
        <span className="d-flex justify-content-center m-3">
          <TableLoader />
        </span>
      ) : (
        <span className="d-flex justify-content-center m-3">
          <NoDataFound />
        </span>
      )}
      <SwipeableDrawer
        anchor="right"
        open={activityDrawer["right"]}
        onClose={toggleActivityDrawer("right", false)}
        onOpen={toggleActivityDrawer("right", true)}
        className="drawer-width">
        <div className="px-3 activity-top bg-black-13 pb-3">
          <div className="d-flex justify-content-between py-3 px-0">
            <div className="d-flex align-items-center">
              <KeyboardArrowLeftOutlinedIcon
                sx={{ fontSize: 25, color: "#c8d8ff" }}
                onClick={toggleActivityDrawer("right", false)}
                className="c-pointer"
              />

              <AsyncImage
                src={role?.imageUrl}
                placeholder={defaultUser}
                alt="role"
                className="me-2 ms-2"
                height={40}
                width={40}
                style={{ borderRadius: "50%" }}
                // style={{ objectFit: "cover" }}
              />
              <div>
                <h5 className="text-lightBlue fw-500">{role?.name}</h5>
                <small className="mt-2 text-grey-6">{membersData?.data?.totalCount}</small>
              </div>
            </div>
            {/* {role?.status === "active" && (
              <NavLink
                to="/teams/roles/create"
                className="c-pointer d-flex filter-icon me-1 align-items-center text-decoration-none"
              >
                <EditOutlinedIcon sx={{ color: "#658DED", fontSize: 14 }} />
                <small className="text-blue-2 ms-1">Edit</small>
              </NavLink>
            )} */}
          </div>

          <div className="d-flex align-items-center">
            <Paper
              sx={{ width: "100%", mb: 0, mt: 2, p: 0 }}
              className="shadow-none">
              <Box
                sx={{ width: "100%" }}
                className="d-flex justify-content-between tabs-header-box">
                {/* variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile */}
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="scrollable force tabs example"
                  className="tabs">
                  <Tab
                    label="Members"
                    className="tabs-head"
                  />
                  <Tab
                    label="Permissions"
                    className="tabs-head"
                  />
                </Tabs>
              </Box>
              <TabPanel
                value={value}
                index={0}>
                <div className="d-flex flex-column">
                  {membersData?.data?.data?.map((item, index) => (
                    <div className="d-flex justify-content-between my-3">
                      <div className="d-flex align-items-center ps-3">
                        <img
                          src={user}
                          alt="user"
                          className="me-2 rounded-circle"
                          height={45}
                          width={45}
                        />
                        <div>
                          <p className="text-lightBlue rounded-circle fw-500">
                            {item?.fullName}
                          </p>
                          <div className="d-flex align-items-center">
                            <small className="text-grey-6 me-2">saniya@mydesignar.com</small>
                            <CopyToClipboard
                              text="saniya@mydesignar.com"
                              onCopy={handleCopy}>
                              <Tooltip
                                title={copied ? "Copied to clipboard" : "Copy"}
                                placement="top">
                                <ContentCopyIcon
                                  sx={{ fontSize: 12, color: "#c8d8ff" }}
                                  className="c-pointer"
                                />
                              </Tooltip>
                            </CopyToClipboard>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex align-items-center hover-back c-pointer px-3 rounded-4">
                        <ChatIcon sx={{ fontSize: 18, color: "#c8d8ff" }} />
                        <small className="text-lightBlue ms-2">Message</small>
                      </div>
                    </div>
                  ))}
                </div>
              </TabPanel>
              <TabPanel
                value={value}
                index={1}>
                <div className="">
                  <div className="d-flex justify-content-between align-items-center mt-3">
                    <h6 className="text-lightBlue ps-3">Permissions</h6>
                  </div>
                  {allPermissions?.map((perm) => {
                    return (
                      <Accordion
                        key={perm._id}
                        expanded={expanded === perm._id}
                        className="create-teams-accordion px-0"
                        onChange={(e, v) => setExpanded(v ? perm._id : "")}>
                        <AccordionSummary>
                          <div className="d-flex justify-content-between w-100 pe-4">
                            <FormControlLabel
                              label={perm?.model?.name}
                              control={
                                <Checkbox
                                  checked={perm.isAllowed}
                                  size="small"
                                  style={{
                                    color: "#5C6D8E",
                                    marginRight: 0,
                                  }}
                                />
                              }
                              sx={{
                                "& .MuiTypography-root": {
                                  fontSize: 14,
                                  color: "#c8d8ff",
                                },
                              }}
                            />
                          </div>
                        </AccordionSummary>
                        {/* <AccordionDetails>
                          <FormGroup className="tags-checkbox">
                            {Object.keys(perm.permissions).map((pr) => (
                              <FormControlLabel
                                key={pr}
                                label={pr}
                                control={
                                  <Checkbox
                                    checked={perm.permissions[pr].isAllowed}
                                    size="small"
                                    style={{
                                      color: "#5C6D8E",
                                      marginRight: 0,
                                    }}
                                  />
                                }
                              />
                            ))}
                          </FormGroup>
                        </AccordionDetails> */}
                      </Accordion>
                    );
                  })}
                </div>
              </TabPanel>
            </Paper>
          </div>
        </div>
      </SwipeableDrawer>
      <ArchiveModal
        onConfirm={handleArchiveModalOnSave}
        onCancel={handleArchiveModalClose}
        show={showArchivedModal}
        title={"Role"}
        message={role?.name}
        products={"25 members"}
        archiveType=""
        // primaryMessage = {`<span className='text-blue-2'>${role?.totalRoles ? role?.totalRoles : `No`}</span> members have been assigned to this role ? Would you like to Archive it ? ` }
        primaryMessage={`${
          role?.totalRoles ? `<span className='text-blue-2'>${role?.totalRoles}</span>` : "No"
        } members have been assigned to this role. Would you like to Archive it?`}
      />
      <MultipleArchiveModal
        onConfirm={handleMultipleArchiveModalOnSave}
        onCancel={handleArchiveModalClose}
        show={showMultipleArchivedModal}
        title={"Roles"}
        message={`${
          selected.length === 1 ? `${selected.length} role` : `${selected.length} roles`
        }`}
        pronoun={`${selected.length === 1 ? "this" : `these`}`}
      />

      <UnArchiveModalSecondary
        onConfirm={handleUnArchiveModalOnSave}
        onCancel={handleUnArchiveModalClose}
        show={showUnArchivedModal}
        title={"Role"}
        message={role?.name}
      />
      <UnMultipleArchiveModalSecondary
        onConfirm={handleMultipleUnArchiveModalOnSave}
        onCancel={handleUnArchiveModalClose}
        show={showMultipleUnArhcivedModal}
        title={"roles"}
        message={`${
          selected.length === 1 ? `${selected.length} role` : `${selected.length} roles`
        }`}
        pronoun={`${selected.length === 1 ? "this" : `these`}`}
      />
      <DeleteModalSecondary
        onConfirm={isMassDelete ? handleMultipleDeleteOnSave : handleDeleteModalOnSave}
        onCancel={handleDeleteModalClose}
        show={isMassDelete ? showMultipleDeleteModal : showDeleteModal}
        title={isMassDelete ? "multiple role" : "Role"}
        message={
          isMassDelete
            ? `${
                selected.length === 1 ? `${selected.length} role` : `${selected.length} roles`
              }`
            : role?.name
        }
      />
    </React.Fragment>
  );
};

export default RolesTable;

/* <div className="d-flex justify-content-between my-3">
                    <div className="d-flex align-items-center ps-3">
                      <img
                        src={user}
                        alt="user"
                        className="me-2 rounded-circle"
                        height={45}
                        width={45}
                      />

                      <div>
                        <p className="text-lightBlue rounded-circle fw-500">
                          Saniya Shaikh
                        </p>
                        <div className="d-flex align-items-center">
                          <small className=" text-grey-6 me-2">
                            saniya@mydesignar.com
                          </small>

                          <Tooltip title="Copy" placement="top">
                            <ContentCopyIcon
                              sx={{ fontSize: 12, color: "#c8d8ff" }}
                              className="c-pointer"
                            />
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex align-items-center hover-back c-pointer px-3 rounded-4">
                      <ChatIcon sx={{ fontSize: 18, color: "#c8d8ff" }} />
                      <small className="text-lightBlue ms-2">Message</small>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between my-3">
                    <div className="d-flex align-items-center ps-3">
                      <img
                        src={user}
                        alt="user"
                        className="me-2 rounded-circle"
                        height={45}
                        width={45}
                      />

                      <div>
                        <p className="text-lightBlue rounded-circle fw-500">
                          Saniya Shaikh
                        </p>
                        <div className="d-flex align-items-center">
                          <small className=" text-grey-6 me-2">
                            saniya@mydesignar.com
                          </small>
                          <Tooltip title="Copy" placement="top">
                            <ContentCopyIcon
                              sx={{ fontSize: 12, color: "#c8d8ff" }}
                              className="c-pointer"
                            />
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex align-items-center hover-back c-pointer px-3 rounded-4">
                      <ChatIcon sx={{ fontSize: 18, color: "#c8d8ff" }} />
                      <small className="text-lightBlue ms-2">Message</small>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between my-3">
                    <div className="d-flex align-items-center ps-3">
                      <img
                        src={user}
                        alt="user"
                        className="me-2 rounded-circle"
                        height={45}
                        width={45}
                      />

                      <div>
                        <p className="text-lightBlue rounded-circle fw-500">
                          Saniya Shaikh
                        </p>
                        <div className="d-flex align-items-center">
                          <small className=" text-grey-6 me-2">
                            saniya@mydesignar.com
                          </small>
                          <Tooltip title="Copy" placement="top">
                            <ContentCopyIcon
                              sx={{ fontSize: 12, color: "#c8d8ff" }}
                              className="c-pointer"
                            />
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex align-items-center hover-back c-pointer px-3 rounded-4">
                      <ChatIcon sx={{ fontSize: 18, color: "#c8d8ff" }} />
                      <small className="text-lightBlue ms-2">Message</small>
                    </div>
                  </div> */

/*permissionAccordionData.map((e, i) => (
                    <Accordion
                      expanded={expanded === `panel${i}`}
                      onChange={handleAccordianChange(`panel${i}`)}
                      key={i}
                      className="teams-accordion">
                      <AccordionSummary
                        aria-controls={`panel${i}d-content`}
                        id={`panel${i}d-header`}>
                        <div className="d-flex justify-content-between w-100 pe-4">
                          <FormControlLabel
                            control={
                              <Checkbox
                                size="small"
                                style={{
                                  color: "#5C6D8E",
                                  marginRight: 0,
                                }}
                              />
                            }
                            label={e.name}
                            sx={{
                              "& .MuiTypography-root": {
                                fontSize: 14,
                                color: "#c8d8ff",
                              },
                            }}
                          />

                          <button
                            className="button-grey py-2 px-3"
                            aria-describedby={idAccess}
                            variant="contained"
                            onClick={handleAccessClick}>
                            <small className="text-lightBlue">View Access</small>
                          </button>

                          <Popover
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                            id={idAccess}
                            open={openAccess}
                            anchorEl={anchorAccessEl}
                            onClose={handleAccessClose}>
                            <div className="py-2 px-1">
                              <small className="p-2 rounded-3 text-lightBlue c-pointer font2 d-block hover-back">
                                View Access Only
                              </small>
                              <small className="p-2 rounded-3 text-lightBlue c-pointer font2 d-block hover-back">
                                View and Edit Access
                              </small>
                            </div>
                          </Popover>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <FormGroup className="tags-checkbox">
                          {e.accDetails.map((sub, i) => (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  size="small"
                                  style={{
                                    color: "#5C6D8E",
                                    marginRight: 0,
                                  }}
                                />
                              }
                              key={i}
                              label={sub.name}
                            />
                          ))}
                        </FormGroup>
                      </AccordionDetails>
                    </Accordion>
                            ))*/
