import React from "react";
import Heading from "../../components/Headers/DashboardHeader";
import {
  ResponsiveContainer,
  ComposedChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
} from "recharts";

const ChannelChart = () => {
  const data = [
    { name: "Direct", visitors: 100 },
    { name: "Referral", visitors: 150 },
    { name: "Organic", visitors: 50 },
    { name: "Email", visitors: 40 },
  ];

  return (
    <div className="bg-button-3 border-grey-5 rounded-8 px-4 pt-4 pb-3 mt-4" style={{ height: '500px', width: "760px" }}>
    <p
      className="text-lightBlue fw-500 me-2 my-1"
      style={{ fontSize: '12px', textTransform: 'uppercase' }}
    >
      Trends • Last 14 days
    </p>

    <Heading
      heading={"Channels"}
      tooltipContent={"Channels are the different sources that bring traffic to your website, e.g. Paid Search, Organic Social, Direct, etc."}
    />
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart
          layout="vertical"
          width={500}
          height={400}
          data={data}
          margin={{
            top: 30,
            right: 20,
            left: 20,
            bottom: 60,
          }}
        >
          <XAxis type="number" tick={{ fontSize: 12, fill: "#ffffff" }}/>
          <YAxis dataKey="name" type="category" scale="band" tick={{ fontSize: 12, fill: "#ffffff" }}/>
          <Tooltip contentStyle={{
                backgroundColor: '#18132E',
                border: '#8b68ed',
                fontSize: '12px',
                borderRadius: "4px",
              }} />
          <Legend />
          <Bar dataKey="visitors" barSize={30} fill="#8b68ed" />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ChannelChart;
