import apiSlice from "../../app/api/apiSlice";

import { omitNullishKeys } from "../../utils/helper";

export const priceMasterApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllMasters: builder.query({
      query: (queries) => {
        return {
          url: "/parameters/priceMaster/master",
          params: omitNullishKeys(queries),
        };
      },
      transformResponse: (res) => res.data,
      providesTags: ["PriceMaster"],
    }),
    getMastersCount: builder.query({
      query: (queries) => {
        return {
          url: "/parameters/priceMaster/count",
          params: omitNullishKeys(queries),
        };
      },
      transformResponse: (res) => res.data,
      providesTags: ["PriceMasterCount"],
    }),
    createMaster: builder.mutation({
      query: (masterDetails) => ({
        url: "/parameters/priceMaster/master",
        method: "POST",
        body: masterDetails,
      }),
      transformResponse: (res) => res.data,
      invalidatesTags: ["PriceMaster", "PriceMasterCount", "Logs"],
    }),
    updateMaster: builder.mutation({
      query: ({ details, id }) => ({
        url: `/parameters/priceMaster/master/${id}`,
        method: "PUT",
        body: details,
      }),
      transformResponse: (res) => res.data,
      invalidatesTags: ["PriceMaster", "PriceManager", "PriceMasterCount", "Logs"],
    }),
    deleteMaster: builder.mutation({
      query: (id) => ({
        url: `/parameters/priceMaster/master/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["PriceMaster", "PriceManager", "PriceMasterCount", "Logs"],
    }),
  }),
});

export const {
  useGetAllMastersQuery,
  useGetMastersCountQuery,
  useCreateMasterMutation,
  useUpdateMasterMutation,
  useDeleteMasterMutation,
} = priceMasterApiSlice;
