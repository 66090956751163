import apiSlice from "../../../app/api/apiSlice";

export const sizeChartSettingApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllSizeChartSettings: builder.query({
      query: (queries = {}) => {
        const queryString = new URLSearchParams(queries).toString();
        return {
          url: `/functionality/settingSizeChart?${queryString}`,
        };
      },
      providesTags: ["SizeChartSetting"],
    }),

    // getSizeChartSettingCount: builder.query({
    //   query: (queries = {}) => {
    //     const queryString = new URLSearchParams(queries).toString();

    //     return {
    //       url: `/store/count?${queryString}`,
    //     };
    //   },
    //   providesTags: ["SizeChartSetting"],
    // }),

    createSizeChartSetting: builder.mutation({
      query: (data) => ({
        url: "/functionality/settingSizeChart",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["SizeChartSetting", "Logs"],
    }),

    editSizeChartSetting: builder.mutation({
      query: ({ id, details }) => ({
        url: `/functionality/settingSizeChart/${id}`,
        method: "PUT",
        body: details,
      }),
      invalidatesTags: ["SizeChartSetting", "Logs"],
    }),

    // bulkEditSizeChartSetting: builder.mutation({
    //   query: (updates) => ({
    //     url: "/functionality/settingSizeChart/bulkUpdate",
    //     method: "PUT",
    //     body: updates,
    //   }),
    //   invalidatesTags: ["SizeChartSetting","Logs"],
    // }),

    // deleteSizeChartSetting: builder.mutation({
    //   query: (id) => ({
    //     url: `/functionality/settingSizeChart/${id}`,
    //     method: "DELETE",
    //   }),
    //   invalidatesTags: ["SizeChartSetting","Logs"],
    // }),

    // bulkDeleteSizeChartSetting: builder.mutation({
    //   query: (deletes) => ({
    //     url: "/functionality/settingSizeChart/bulkDelete",
    //     method: "DELETE",
    //     body: deletes,
    //   }),
    //   invalidatesTags: ["SizeChartSetting","Logs"],
    // }),
  }),
});

export const {
  useGetAllSizeChartSettingsQuery,
  // useGetSizeChartSettingCountQuery,
  useCreateSizeChartSettingMutation,
  useEditSizeChartSettingMutation,
  // useBulkEditSizeChartSettingMutation,
  // useDeleteSizeChartSettingMutation,
  // useBulkDeleteSizeChartSettingMutation,
} = sizeChartSettingApiSlice;
