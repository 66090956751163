import { useCallback, useEffect, useState } from "react";
import {
  Autocomplete,
  Avatar,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import userIcon from "../../../assets/icons/userIcon.svg";
import userLarge from "../../../assets/images/users/userLarge.svg";
import indiaFlagIcon from "../../../assets/images/products/indiaFlag.svg";
import { useGetAllCustomersQuery } from "../../../features/customers/customer/customerApiSlice";
import NewUserDialog from "./NewUserDialog";
import AsyncImage from "../../../components/AsyncImage/AsyncImage";
import NewAddressDialog from "./NewAddressDialog";
import { useCreateCustomerAddressMutation } from "../../../features/customers/customerAddress/customerAddressApiSlice";
import { omitEmptyKeys } from "../../../utils/helper";
import { useDispatch } from "react-redux";
import { showError } from "../../../features/snackbar/snackbarAction";

export default function CreateOrderUser({
  onChange = () => {},
  handleBlur = () => {},
  fieldsTouched = {},
  fieldsErrors = {},
}) {
  const dispatch = useDispatch();

  const { data: customersData, isSuccess: customersIsSuccess } = useGetAllCustomersQuery({
    pageNo: 1,
    pageSize: 1000000000,
  });

  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedShippingAddress, setSelectedShippingAddress] = useState(null);
  const [selectedBillingAddress, setSelectedBillingAddress] = useState(null);

  useEffect(() => {
    onChange({
      customer: selectedCustomer?._id ?? "",
      shipping: selectedShippingAddress?._id ?? "",
      billing: selectedBillingAddress?._id ?? "",
    });
  }, [onChange, selectedBillingAddress, selectedCustomer, selectedShippingAddress]);

  const [openNewUserDialog, setOpenNewUserDialog] = useState(false);
  const [newUserId, setNewUserId] = useState("");

  useEffect(() => {
    if (newUserId) {
      const cust = customersData?.data?.data?.find((cx) => cx._id === newUserId);
      if (cust) {
        setNewUserId("");
        setSelectedCustomer(cust);
        setOpenNewUserDialog(false);
      }
    }
  }, [customersData, newUserId]);

  const [openAddressDialog, setOpenAddressDialog] = useState("");
  const [addressType, setAddressType] = useState("");
  const [newAddressId, setNewAddressId] = useState("");

  useEffect(() => {
    console.log("Effecting...");
    if (selectedCustomer && newAddressId) {
      const cust = customersData?.data?.data?.find((cx) => cx._id === selectedCustomer?._id);

      if (cust) {
        setNewUserId("");
        setSelectedCustomer(cust);
        setOpenNewUserDialog(false);

        const addr = cust?.addresses?.find((ad) => ad._id === newAddressId);

        if (addr) {
          setNewAddressId("");
          if (addressType === "shipping") setSelectedShippingAddress(addr);
          if (addressType === "billing") setSelectedBillingAddress(addr);
          setOpenAddressDialog(false);
          setAddressType("");
        }
      }
    }
  }, [customersData, newAddressId, addressType, selectedCustomer]);

  const [createCustomerAddress, { isLoading }] = useCreateCustomerAddressMutation();

  const [sameAddress, setSameAddress] = useState(false);

  const setSameBillingAsShipping = (e) => {
    setSameAddress(e.target.checked);
    if (e.target.checked) {
      createCustomerAddress(
        omitEmptyKeys({
          ...selectedShippingAddress,
          countryCode: selectedShippingAddress.countryCode._id,
          city: selectedShippingAddress.city._id,
          state: selectedShippingAddress.state._id,
          country: selectedShippingAddress.country._id,
        })
      )
        .unwrap()
        .then((data) => {
          setAddressType("billing");
          setNewAddressId(data?.data?._id ?? "");
        })
        .catch((e) => {
          console.log(e);
          dispatch(
            showError({
              message: e?.data?.message ?? e?.message ?? "Something went wrong!",
            })
          );
        });
    } else {
      setNewAddressId("");
      setSelectedBillingAddress(null);
      setAddressType("");
    }
  };

  return (
    <div className="bg-black-15 border-grey-5 rounded-8 p-3 row attributes">
      <div className="col-12 px-0">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <img
              src={userIcon}
              alt="userIcon"
              width={16}
            />

            <h6 className="text-lightBlue fw-500 ms-2">Select User</h6>
          </div>
          <small
            className="text-blue-2 c-pointer"
            onClick={() => setOpenNewUserDialog(true)}>
            + Add New
          </small>
        </div>

        <div className="d-flex justify-content-center">
          <hr className="hr-grey-6 w-100 my-3" />
        </div>

        <div className="col-12">
          <FormControl
            size="small"
            className="w-100 px-0">
            <Autocomplete
              fullWidth
              disableClearable
              size="small"
              onBlur={handleBlur}
              value={selectedCustomer ?? ""}
              isOptionEqualToValue={(option, value) => Object.is(option, value)}
              options={customersData?.data?.data ?? []}
              onChange={(_, cx) => {
                setSelectedCustomer(cx);
                setSelectedShippingAddress(null);
                setSelectedBillingAddress(null);
              }}
              getOptionLabel={(option) =>
                ((option?.firstName || "") + " " + (option?.lastName || "")).trim()
              }
              filterOptions={(props, { inputValue }) =>
                props.filter(
                  (cx) =>
                    cx.firstName?.toLowerCase().includes(inputValue.toLowerCase()) ||
                    cx.lastName?.toLowerCase().includes(inputValue.toLowerCase()) ||
                    cx.phone?.toLowerCase().includes(inputValue.toLowerCase()) ||
                    cx.email?.toLowerCase().includes(inputValue.toLowerCase())
                )
              }
              renderOption={(props, item) => (
                <div
                  {...props}
                  key={item._id}>
                  <div className="d-flex align-items-center me-5">
                    <AsyncImage
                      src={item?.imageUrl}
                      placeholder={userLarge}
                      alt="product"
                      className="me-2 rounded-4"
                      height={52}
                      width={52}
                    />
                    <div className="text-lightBlue ms-2">
                      <p>
                        {item?.firstName} {item?.lastName}
                      </p>
                      <small>{item?.phone}</small> - <small>{item?.email}</small>
                    </div>
                  </div>
                </div>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="customer"
                  placeholder="Search"
                />
              )}
            />
            <FormHelperText error>
              {fieldsTouched.customer && fieldsErrors.customer}
            </FormHelperText>
          </FormControl>
        </div>

        {/* {console.log(selectedCustomer)} */}
        {selectedCustomer && (
          <div className="col-12 mt-3">
            <div className="row">
              <div className="col-4">
                <div className="d-flex align-items-center mt-1 mb-3">
                  <h6 className="fw-500 text-blue-2 me-3">
                    {selectedCustomer?.firstName} {selectedCustomer?.lastName}
                  </h6>
                  <img
                    src={indiaFlagIcon}
                    alt="indiaFlag"
                    height={15}
                  />
                </div>
                <p className="text-lightBlue fs-7 my-1">{selectedCustomer?.email}</p>
                <p className="text-lightBlue fs-7 my-1">
                  {selectedCustomer?.countryCode?.countryCode}-{selectedCustomer?.phone}
                </p>
              </div>
              <div className="col-4">
                <div className="d-flex justify-content-between mb-3">
                  <small className="text-grey-6 me-2">
                    {!selectedShippingAddress && "Select"} Shipping Address
                  </small>
                  <small
                    className="text-blue-2 c-pointer"
                    onClick={() => {
                      setOpenAddressDialog(true);
                      setAddressType("shipping");
                    }}>
                    + Add New
                  </small>
                </div>
                <FormControl
                  size="small"
                  className="w-100 px-0">
                  <Select
                    name="address.shipping"
                    size="small"
                    onBlur={handleBlur}
                    value={selectedShippingAddress ?? ""}
                    onChange={(e) => setSelectedShippingAddress(e.target.value)}>
                    {selectedCustomer.addresses.filter((add) => add.type === "shipping")
                      .length === 0 && (
                      <MenuItem
                        value=""
                        disabled>
                        No Address
                      </MenuItem>
                    )}
                    {selectedCustomer.addresses
                      .filter((add) => add.type === "shipping")
                      .map((add) => (
                        <MenuItem
                          key={add._id}
                          value={add}>
                          {add?.firstName} {add?.lastName} - {add?.line1} {add?.line2}{" "}
                          {add?.state?.name} {add?.country?.name} {add?.pinCode}
                        </MenuItem>
                      ))}
                  </Select>
                  <FormHelperText error>
                    {fieldsTouched.address?.shipping && fieldsErrors.address?.shipping}
                  </FormHelperText>
                </FormControl>
                {!!selectedShippingAddress && (
                  <div className="mt-3">
                    <p className="text-lightBlue fs-7 my-1">
                      {selectedShippingAddress?.firstName} {selectedShippingAddress?.lastName}
                    </p>
                    <p className="text-lightBlue fs-7 my-1">
                      {selectedShippingAddress?.line1} {selectedShippingAddress?.line2}
                    </p>
                    <p className="text-lightBlue fs-7 my-1">
                      {selectedShippingAddress?.state?.name}{" "}
                      {selectedShippingAddress?.country?.name}{" "}
                      {selectedShippingAddress?.pinCode}
                    </p>
                  </div>
                )}
              </div>
              <div className="col-4">
                <div className="d-flex justify-content-between mb-3">
                  <small className="text-grey-6 me-2">
                    {!selectedBillingAddress && "Select"} Billing Address
                  </small>
                  {!sameAddress && (
                    <small
                      className="text-blue-2 c-pointer"
                      onClick={() => {
                        setOpenAddressDialog(true);
                        setAddressType("billing");
                      }}>
                      + Add New
                    </small>
                  )}
                </div>
                {!sameAddress && (
                  <FormControl
                    size="small"
                    className="w-100 px-0">
                    <Select
                      name="address.billing"
                      size="small"
                      disabled={sameAddress}
                      onBlur={handleBlur}
                      value={selectedBillingAddress ?? ""}
                      onChange={(e) => setSelectedBillingAddress(e.target.value)}>
                      {selectedCustomer.addresses.filter((add) => add.type === "billing")
                        .length === 0 && (
                        <MenuItem
                          value=""
                          disabled>
                          No Address
                        </MenuItem>
                      )}
                      {selectedCustomer.addresses
                        .filter((add) => add.type === "billing")
                        .map((add) => (
                          <MenuItem
                            key={add._id}
                            value={add}>
                            {add?.firstName} {add?.lastName} - {add?.line1} {add?.line2}{" "}
                            {add?.state?.name} {add?.country?.name} {add?.pinCode}
                          </MenuItem>
                        ))}
                    </Select>
                    <FormHelperText error>
                      {fieldsTouched.address?.billing && fieldsErrors.address?.billing}
                    </FormHelperText>
                  </FormControl>
                )}

                <FormControlLabel
                  sx={{ color: "#5C6D8E" }}
                  control={
                    <Checkbox
                      size="small"
                      value={sameAddress}
                      onChange={setSameBillingAsShipping}
                      disabled={!(selectedCustomer && selectedShippingAddress)}
                      // style={{ color: "#5C6D8E" }}
                    />
                  }
                  label="Same As Shipping Address"
                  // sx={{
                  //   "& .MuiTypography-root": {
                  //     fontSize: 13,
                  //     color: "#c8d8ff",
                  //   },
                  // }}
                />

                {!!selectedBillingAddress && (
                  <div className="mt-3">
                    <p className="text-lightBlue fs-7 my-1">
                      {selectedBillingAddress?.firstName} {selectedBillingAddress?.lastName}
                    </p>
                    <p className="text-lightBlue fs-7 my-1">
                      {selectedBillingAddress?.line1} {selectedBillingAddress?.line2}
                    </p>
                    <p className="text-lightBlue fs-7 my-1">
                      {selectedBillingAddress?.state?.name}{" "}
                      {selectedBillingAddress?.country?.name} {selectedBillingAddress?.pinCode}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        <NewUserDialog
          setSelectedUser={setNewUserId}
          show={openNewUserDialog}
          onClose={setOpenNewUserDialog}
        />

        <NewAddressDialog
          show={openAddressDialog}
          type={addressType}
          customerId={selectedCustomer?._id ?? ""}
          onClose={() => {
            setOpenAddressDialog(false);
            setAddressType("");
          }}
          setSelectedAddress={setNewAddressId}
        />
      </div>
    </div>
  );
}
