import React from "react";
import "./AppReactImageGallery.scss";
import ImageGallery from "react-image-gallery";
import product2 from "../../assets/images/products/product2.jpg";
import "../../../node_modules/react-image-gallery/styles/scss/image-gallery.scss";

const AppReactImageGallery = (props) => {
  const { images } = props;

  const reactImageGallery = images?.map((img) => {
    return {
      original: img.image,
      thumbnail: img.image,
    };
  });

  if (!reactImageGallery) {
    return <></>;
  }

  return (
    <ImageGallery
      items={reactImageGallery}
      // thumbnailPosition="left"
      showBullets={true}
      showPlayButton={false}
      showFullscreenButton={true}
      disableKeyDown={true}
    />
  );
};

export function AppReactLivePreview({ values = {}, labelSettings = {} }) {
  const reactImageGallery = [
    {
      original: product2,
      thumbnail: product2,
    },
    {
      original: product2,
      thumbnail: product2,
    },
    {
      original: product2,
      thumbnail: product2,
    },
    {
      original: product2,
      thumbnail: product2,
    },
    {
      original: product2,
      thumbnail: product2,
    },
    {
      original: product2,
      thumbnail: product2,
    },
  ];

  const style = {
    zIndex: 999,
    display: "inline-block",
  };

  if (values.type === "label") {
    style.position = "absolute";

    if (labelSettings) {
      const { size, position, margin = {} } = labelSettings;
      const { top, bottom, right, left } = margin;

      // TODO Size how to manage for text as well as image
      console.log(size);

      style.marginTop = top;
      style.marginBottom = bottom;
      style.marginRight = right;
      style.marginLeft = left;

      switch (position) {
        case "topLeft":
          style.top = 0;
          style.left = 0;
          break;
        case "topRight":
          style.top = 0;
          style.right = 0;
          break;
        case "bottomLeft":
          style.bottom = 0;
          style.left = 0;
          break;
        case "bottomRight":
          style.bottom = 0;
          style.right = 0;
          break;
        case "centerLeft":
          style.transformY = "-50%";
          style.top = "50%";
          style.left = 0;
          break;
        case "centerRight":
          style.transformY = "-50%";
          style.top = "50%";
          style.right = 0;
          break;
        default:
          break;
      }
    }
  }

  if (values.type === "badge") {
    style.marginTop = "10px";
  }

  if (values.contentType === "text") {
    style.padding = "5px 10px";
    style.color = values.color.textColor;
    style.backgroundColor = values.color.backgroundColor;
  }

  if (values.contentType === "image") {
    style.height = 50;
  }

  return (
    <div className="position-relative">
      {/*type === "label" && (
        <>
          {values.contentType === "text" && !!values.text && (
            <span style={style}>{values.text}</span>
          )}
          {values.contentType === "image" && !!values.image && (
            <img
              style={style}
              src={values.image}
              alt="label"
            />
          )}
        </>
      )*/}
      <ImageGallery
        items={reactImageGallery}
        // thumbnailPosition="left"
        showBullets={true}
        showPlayButton={false}
        showFullscreenButton={true}
        disableKeyDown={true}
      />
      {values.contentType === "text" && !!values.text && (
        <span style={style}>{values.text}</span>
      )}
      {values.contentType === "image" && !!values.image && (
        <img
          style={style}
          src={values.image}
          alt="badge"
        />
      )}
    </div>
  );
}

export default AppReactImageGallery;
