import { useState } from "react";
import info from "../../../assets/icons/info.svg";
import { Box, Tooltip, Tab, Tabs } from "@mui/material";

import StoreInfo from "./StoreInfo";
import BillingPlans from "./BillingPlans";
import DashboardSetting from "./DashboardSettings";
import TabPanel from "../../../components/TabPanel/TabPanel";

const StoreDetails = () => {
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (_, index) => {
    setTabIndex(index);
  };

  return (
    <div className="border-grey-5 rounded-8 px-4 pt-4 bg-black-15">
      <div className="my-2">
        <div className="row mb-2">
          <div className="col d-flex align-items-center">
            <h4 className="page-heading w-auto ps-0 mr-2">Store Details</h4>
            <Tooltip
              title="Lorem ipsum"
              placement="top">
              <img
                src={info}
                alt="info"
                className="c-pointer"
                width={13.5}
              />
            </Tooltip>
          </div>

          <div className="mt-4">
            <Box
              sx={{ width: "100%" }}
              className="d-flex justify-content-between tabs-header-box"
            >
                <Tabs className="tabs" value={tabIndex} onChange={handleTabChange}>
                  <Tab
                    label="Store Information"
                    className="tabs-head"
                  />
                  <Tab
                    label="Billing and Plans"
                    className="tabs-head cursor-disabled"
                  />
                  <Tab
                    label="Dashboard Settings"
                    className="tabs-head"
                  />
                </Tabs>
              </Box>
                <TabPanel value={tabIndex} index={0}>
                  <StoreInfo />
                </TabPanel>
                <TabPanel value={tabIndex} index={1}>
                  <BillingPlans />
                </TabPanel>
                <TabPanel value={tabIndex} index={2}>
                  <DashboardSetting />
                </TabPanel>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StoreDetails;
