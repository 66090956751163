import { useEffect, useReducer } from 'react';
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { DeleteModalSecondary } from '../../../components/DeleteModal/DeleteModal';
import SettingHeader from '../../../components/Setting/SettingHeader';
import SettingTable from '../../../components/Setting/SettingTable';
import arrowLeft from '../../../assets/icons/arrowLeft.svg';
import {
  showSuccess,
  showError,
} from '../../../features/snackbar/snackbarAction';
import {
  useGetAllPoliciesQuery,
  useDeletePolicyMutation,
} from '../../../features/policies/policiesApiSlice';

import { omitEmptyKeys, pickExactObjKeys } from '../../../utils/helper';

const initialQueryFilterState = {
  pageSize: 10,
  pageNo: 1,
  title: '',
};

const initialPoliciesState = {
  data: null,
  totalCount: 0,
  deleteId: null,
  confirmationMessage: '',
  showDeleteModal: false,
  search: '',
  firstRender: true,
};

const queryFilterReducer = (state, action) => {
  if (action.type === 'SET_PAGE_SIZE') {
    return {
      ...state,
      pageNo: initialQueryFilterState.pageNo,
      pageSize: action.size,
    };
  }
  if (action.type === 'CHANGE_PAGE') {
    return {
      ...state,
      pageNo: action.pageNo + 1,
    };
  }
  if (action.type === 'SEARCH_TITLE') {
    return {
      ...state,
      pageNo: initialQueryFilterState.pageNo,
      title: action.title,
    };
  }
  if (action.type === 'SET_FILTERS') {
    return {
      ...state,
      ...action.filters,
    };
  }
  return initialQueryFilterState;
};

const policiesReducer = (state, action) => {
  if (action.type === 'SET_DATA') {
    return {
      ...initialPoliciesState,
      search: state.search,
      data: action.data,
      totalCount: action.totalCount,
      firstRender: false,
    };
  }
  if (action.type === 'SORT_DATA') {
    return {
      ...initialPoliciesState,
      search: state.search,
      totalCount: state.totalCount,
      data: action.data,
    };
  }
  if (action.type === 'SET_DELETE') {
    return {
      ...state,
      search: state.search,
      deleteId: action.id,
      confirmationMessage: action.message || '',
      showDeleteModal: true,
    };
  }
  if (action.type === 'REMOVE_DELETE') {
    return {
      ...initialPoliciesState,
      search: state.search,
      totalCount: state.totalCount,
      data: state.data,
      firstRender: false,
    };
  }
  if (action.type === 'SEARCH_VALUE') {
    return {
      ...state,
      search: action.search,
    };
  }
  if (action.type === 'DISABLE_FIRST_RENDER') {
    return {
      ...state,
      firstRender: false,
    };
  }
  return initialPoliciesState;
};

const Guides = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams('');
  const [queryFilterState, dispatchQueryFilter] = useReducer(
    queryFilterReducer,
    initialQueryFilterState
  );
  const [policiesState, dispatchPolicies] = useReducer(
    policiesReducer,
    initialPoliciesState
  );

  const {
    data: policiesData,
    isLoading: policiesIsLoading,
    error: policiesError,
    isError: policiesIsError,
    isSuccess: policiesIsSuccess,
    isFetching: policiesDataIsFetching,
  } = useGetAllPoliciesQuery(
    { ...queryFilterState, type: 'guides' },
    {
      skip: policiesState.firstRender,
    }
  );

  const [
    deletePolicy,
    {
      isSuccess: deletePolicyIsSuccess,
      error: deletePolicyError,
      isError: deletePolicyIsError,
      isLoading: deletePolicyIsLoading,
    },
  ] = useDeletePolicyMutation();

  const pageChangeHandler = (_, pageNo) => {
    dispatchQueryFilter({ type: 'CHANGE_PAGE', pageNo });
  };

  const pageSizeHandler = (size) => {
    dispatchQueryFilter({ type: 'SET_PAGE_SIZE', size });
  };

  const searchHandler = (value) => {
    dispatchQueryFilter({ type: 'SEARCH_TITLE', title: value });
  };

  const searchValueHandler = (value) => {
    dispatchPolicies({ type: 'SEARCH_VALUE', search: value });
  };

  const sortHandler = (sortedData) => {
    dispatchPolicies({
      type: 'SORT_DATA',
      data: sortedData,
    });
  };

  const editHandler = (id) => {
    navigate({
      pathname: `./edit/${id}`,
      search: `?${createSearchParams({
        search: JSON.stringify({
          ...queryFilterState,
        }),
      })}`,
    });
  };

  const backHandler = () => {
    navigate('/website/themes');
    return;
  };

  const addPolicy = () => {
    navigate('./create');
  };

  const deleteHandler = ({ id, message }) => {
    dispatchPolicies({ type: 'SET_DELETE', id, message });
  };

  const cancelDeleteHandler = () => {
    dispatchPolicies({ type: 'REMOVE_DELETE' });
  };

  const deleteConfirmationHandler = () => {
    deletePolicy(policiesState.deleteId)
      .unwrap()
      .then(() => {
        dispatch(
          showSuccess({
            message: 'Guide deleted successfully',
          })
        );
      })
      .catch((error) => {
        if (error?.data?.message) {
          dispatch(showError({ message: error.data.message }));
        } else {
          dispatch(
            showError({
              message: 'Something went wrong!, please try again',
            })
          );
        }
      });
  };

  useEffect(() => {
    if (policiesError) {
      if (policiesError?.data?.message) {
        dispatch(showError({ message: policiesError.data.message }));
      } else {
        dispatch(
          showError({ message: 'Something went wrong!, please try again' })
        );
      }
    }
    if (policiesIsSuccess) {
      dispatchPolicies({
        type: 'SET_DATA',
        data: policiesData.data,
        totalCount: policiesData.totalCount,
      });
    }
  }, [policiesError, policiesIsSuccess, policiesData, dispatch]);

  useEffect(() => {
    if (policiesState.firstRender) {
      const search = omitEmptyKeys(JSON.parse(searchParams.get('search')));
      const filters = pickExactObjKeys(queryFilterState, search);
      dispatchQueryFilter({
        type: 'SET_FILTERS',
        filters,
      });
      filters.title &&
        dispatchPolicies({
          type: 'SEARCH_VALUE',
          search: filters.title,
        });

      dispatchPolicies({ type: 'DISABLE_FIRST_RENDER' });
    }
  }, [searchParams, policiesState.firstRender, queryFilterState]);

  useEffect(() => {
    if (!policiesState.firstRender) {
      setSearchParams(
        {
          search: JSON.stringify({
            ...queryFilterState,
          }),
        },
        { replace: true }
      );
    }
  }, [queryFilterState, setSearchParams, policiesState.firstRender]);

  return (
    <div className='page container-fluid position-relative user-group product-tab-page'>
      <div className='border-grey-5 mt-4 rounded-8 px-4 pt-4 bg-black-15 fit-Content'>
        <div className='my-2 px-1'>
          <div className='row mb-2'>
            <SettingHeader
              heading={'Guides'}
              buttonName={'+ Add New Guide'}
              handleButton={addPolicy}
              backHandler={backHandler}
            />
            <div className='px-2'>
              <hr className='hr-grey-6 my-4 w-100' />
            </div>
            <SettingTable
              editItem={addPolicy}
              error={policiesIsError}
              isLoading={policiesIsLoading || policiesDataIsFetching}
              data={policiesData?.data}
              totalCount={policiesData?.totalCount}
              onPageChange={pageChangeHandler}
              onPageSize={pageSizeHandler}
              pageSize={queryFilterState.pageSize}
              page={queryFilterState.pageNo}
              onEdit={editHandler}
              onDelete={deleteHandler}
            />
          </div>
        </div>
      </div>
      <DeleteModalSecondary
        onConfirm={deleteConfirmationHandler}
        onCancel={cancelDeleteHandler}
        show={policiesState.showDeleteModal}
        isLoading={deletePolicyIsLoading}
        message={policiesState.confirmationMessage}
        title='Guide'
      />
    </div>
  );
};

export default Guides;
