const Container = ({ children, bgLevel }) => {
  let bgColor;

  switch (bgLevel) {
    case 1:
      bgColor = "bg-black-15";
      break;
    case 2:
      bgColor = "bg-black-13";
      break;
    case 3:
      bgColor = "bg-black-20";
      break;
    default:
      bgColor = "bg-black-15";
  }  
  
  return(
    <div className={`border-grey-5 rounded-8 p-3 ${bgColor} fit-Content my-3`}>
      {children}
    </div>
  )
};
  
export default Container;