import apiSlice from "../../../../app/api/apiSlice";
import { omitEmptyKeys, omitNullishKeys } from "../../../../utils/helper";

export const enquiriesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllEnquiries: builder.query({
      query: (queries) => {
        let queryString = "";
        for (const key in queries) {
          if (queries[key]) {
            queryString = `${queryString}${queryString ? "&" : "?"}${key}=${queries[key]}`;
          }
        }
        return {
          url: `/tickets/list${queryString}`,
        };
      },
      providesTags: ["Enquiries"],
    }),
    getEnquiryList: builder.query({
      query: (queries) => {
        return {
          url: `/products/enquiry`,
          params: omitNullishKeys(queries),
        };
      },
      providesTags: ["Enquiries"],
    }),
    downloadExcel: builder.mutation({
      query: (queries) => {
        return {
          url: `/products/enquiry`,
          method: "GET",
          params: omitNullishKeys(queries),
        };
      },
      providesTags: ["Enquiries"],
    }),
    addSubject: builder.mutation({
      query: (queries) => {
        return {
          url: `/enquiry/subjects`,
          method: "POST",
          body: omitNullishKeys(queries),
        };
      },
      invalidatesTags: ["Subject", "Logs"],
    }),
    editSubject: builder.mutation({
      query: (queries) => {
        const { id, ...rest } = queries;
        return {
          url: `/enquiry/subjects/${id}`,
          method: "PUT",
          body: omitNullishKeys(rest),
        };
      },
      invalidatesTags: ["Subject", "Logs"],
    }),
    editSubjectBulk: builder.mutation({
      query: (queries) => {
        return {
          url: `/enquiry/subjects/bulkUpsert`,
          method: "PUT",
          body: queries,
        };
      },
      invalidatesTags: ["Subject", "Logs"],
    }),
    deleteSubject: builder.mutation({
      query: (queries) => {
        return {
          url: `/enquiry/subjects/${queries?.id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Subject", "Logs"],
    }),
    getSubjectList: builder.query({
      query: (queries) => {
        return {
          url: `/enquiry/subjects`,
          params: omitNullishKeys(queries),
        };
      },
      providesTags: ["Subject"],
    }),
    getNewsletterList: builder.query({
      query: (queries) => {
        return {
          url: `/newsletter`,
          params: omitNullishKeys(queries),
        };
      },
      providesTags: ["Newsletter"],
    }),
    getNewsletterDownload: builder.mutation({
      query: (queries) => {
        return {
          url: `/newsletter`,
          method: "GET",
          params: omitNullishKeys(queries),
        };
      },
      invalidatesTags: ["Newsletter", "Logs"],
    }),
    getAppointments: builder.query({
      query: (queries) => {
        return {
          url: `/enquiry/appointments`,
          params: omitNullishKeys(queries),
        };
      },
    }),
  }),
});

export const {
  useGetAllEnquiriesQuery,
  useGetEnquiryListQuery,
  useGetNewsletterListQuery,
  useAddSubjectMutation,
  useDeleteSubjectMutation,
  useEditSubjectMutation,
  useGetSubjectListQuery,
  useEditSubjectBulkMutation,
  useDownloadExcelMutation,
  useGetNewsletterDownloadMutation,
  useGetAppointmentsQuery,
} = enquiriesApiSlice;
