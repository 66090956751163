import { Link, NavLink, useNavigate } from "react-router-dom";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import NavImage from "../../../components/ThemeComponent/NavImage/NavImage";
import tablet from "../../../assets/icons/theme/tablet.svg";
import mobile from "../../../assets/icons/theme/mobile.svg";
import PCJWeb from "../../../components/ThemeComponent/PCJ/PCJWeb";
import React, { useState } from "react";
import setting from "../../../assets/icons/settings.svg";
import file from "../../../assets/icons/file-text.svg";
import pen from "../../../assets/icons/pen-tool.svg";
import policy from "../../../assets/setting/icons/policy.svg";
import store from "../../../assets/setting/icons/store.svg";

import "./Themes.scss";

const webData = [
  {
    id: 1,
    icon: pen,
    name: "Customise Website",
    subName: "Add blocks, customize and change the content in your website.",
    navigate: "/theme-editor",
  },
  // {
  //   id: 2,
  //   icon: file,
  //   name: 'Pages',
  //   subName: 'Add pages to your website.',
  //   navigate: '#',
  // },
  // {
  //   id: 3,
  //   icon: setting,
  //   name: 'Settings',
  //   subName: 'Change your preferences according to your need.',
  //   navigate: '#',
  // },
  {
    id: 4,
    icon: store,
    name: "Near by Stores",
    subName: "Add your near by stores according to your need.",
    navigate: "/website/store",
  },
  {
    id: 5,
    icon: policy,
    name: "Policies",
    subName: "Add your policies according to your need",
    navigate: "/website/policies",
  },
  {
    id: 6,
    icon: policy,
    name: "Guides",
    subName: "Add your guides according to your need",
    navigate: "/website/guides",
  },
  {
    id: 7,
    icon: policy,
    name: "Customer Delights",
    subName: "Add your customer delights according to your need",
    navigate: "/website/customer-delight",
  },
];

const Logistics = () => {
  const navigate = useNavigate();
  const [isDesktopView, setIsDesktopView] = useState(true);

  // const handleArrow = (id) => {
  //   if (id == 1) {
  //     navigate("/theme-editor");
  //   }
  // };

  return (
    <div>
      <h1 className="text-center fw-600 text-lightBlue mt-4">Tweak Website in your way</h1>

      <h6 className="text-center fw-500 my-4 text-blue-3">
        Create a unique look and feel for your online store.
      </h6>

      <div className="d-flex justify-content-center">
        <div
          className="border-grey-5 rounded-8 bg-black-15 fit-Content p-4"
          style={{ width: "85%" }}>
          <div className="d-flex justify-content-between">
            <div className="d-flex align-items-end">
              <h6 className="fw-500 text-lightBlue me-2">Kisna Theme</h6>
              <small
                className="ex-small text-grey-6 me-1 fw-500"
                style={{ fontStyle: "italic" }}>
                Last edit by Sanjay Chauhan
              </small>
              <small
                className="ex-small text-blue-2 fw-500"
                style={{ fontStyle: "italic" }}>
                (View Logs)
              </small>
            </div>

            {/* <div
              className="button-gradient py-1 px-4 "
              onClick={() => navigate("/theme-editor")}>
              <small>Edit Site</small>
            </div> */}
             <div
              className="button-gradient opacity-50 py-1 px-4 cursor-disabled"
              >
              <small>Edit Site</small>
            </div>
          </div>

          {/* <div
            className="flex justify-content-center my-5 gap-5"
            >
            <div style={{ height: "750px" }} className="border-grey-5 rounded-8 bg-black-13 px-3 pb-4">
              <PCJWeb />
            </div>

            <div style={{ height: "650px" }} className="col-lg-3 border-grey-5 rounded-8 bg-black-13 px-3 pb-4">
              <PCJWeb />
            </div>
          </div> */}
          <div className="flex flex-col items-center my-5 gap-5">
            <div className="d-flex items-center justify-center gap-4 mb-4">
              <div
                className="flex flex-col-4 items-center gap-2 rounded-8 bg-black-13 py-1 px-3 button-gradient cursor-pointer hover:bg-black-20 transition-all"
                style={{ width: "fit-content" }}
                onClick={() => setIsDesktopView(!isDesktopView)}
              >
                <NavImage
                  tooltip={isDesktopView ? "Desktop" : "Mobile"}
                  image={isDesktopView ? tablet : mobile}
                />
                <p className="text-[8px] font-medium text-lightBlue">
                  {isDesktopView ? "Desktop" : "Mobile"}
                </p>


              </div>
              <small
                className="ex-small my-auto text-blue-2 fw-500"
                style={{ fontStyle: "italic" }}>
                {isDesktopView ? "Click to switch into mobile view" : "Click to switch into desktop view"}
              </small>
            </div>


            {isDesktopView ? (
              <div className="flex justify-center items-center gap-5 mx-auto">
                <div
                  style={{ height: "750px" }}
                  className="border-grey-5 justofy-center rounded-8 bg-black-13 px-3 pb-4"
                >
                  <PCJWeb />
                </div>

              </div>
            ) : (
              <div className="flex justify-center items-center gap-4">
                <div
                  style={{ height: "650px" }}
                  className="col-lg-3 border-grey-5 rounded-8 bg-black-13 px-3 pb-4"
                >
                  <PCJWeb />
                </div>
              </div>
            )}
          </div>

          <div className="d-flex col-12 rounded-8 bg-black-13 p-4">
            <div className="d-grid col-lg-7">
              <p className="fw-500 text-lightBlue me-2">Website Analytics</p>
              <small className="text-grey-6 my-1">Some content to prove we are good</small>
              <small className="text-blue-2 text-decoration-underline c-pointer">
                Tips to improve
              </small>
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-center mt-4">
        <div
          className="border-grey-5 rounded-8 bg-black-15 fit-Content p-3"
          style={{ width: "85%" }}>
          {webData.map((item) => (
            <div
              className="d-flex justify-content-between align-items-center p-3 c-pointer"
              onClick={() => navigate(item.navigate)}>
              <div className="d-flex">
                <img
                  src={item.icon}
                  style={{ width: "2rem" }}
                />
                <div className="d-flex flex-column ms-4">
                  <p className="text-lightBlue text-start">{item.name}</p>
                  <small className="text-grey-6">{item.subName}</small>
                </div>
              </div>
              <ArrowForwardIosSharpIcon sx={{ color: "#c8d8ff", fontSize: "16px" }} />
            </div>
          ))}
          {/* <NavLink
            className="d-flex justify-content-between align-items-center p-3 c-pointer"
            to={"/website/store"}
            style={{ textDecoration: "none" }}
          >
            <div className="d-flex">
              <img src={policy} alt="policy" style={{ width: "2rem" }} />
              <div className="d-flex flex-column ms-4">
                <p className="text-lightBlue text-start">{"Stores"}</p>
              </div>
            </div>
          </NavLink>
          <NavLink
            className="d-flex justify-content-between align-items-center p-3 c-pointer"
            to={"/website/policies"}
            style={{ textDecoration: "none" }}
          >
            <div className="d-flex">
              <img src={policy} alt="policy" style={{ width: "2rem" }} />
              <div className="d-flex flex-column ms-4">
                <p className="text-lightBlue text-start">{"Policies"}</p>
              </div>
            </div>
          </NavLink> */}
        </div>
      </div>
    </div>
  );
};

export default Logistics;
