import apiSlice from "../../../app/api/apiSlice";

import { omitNullishKeys } from "../../../utils/helper";

export const optionSetsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllOptionSets: builder.query({
      query: (queries) => {
        return {
          url: "/parameters/optionSet/sets",
          params: omitNullishKeys(queries),
        };
      },
      transformResponse: (res) => res.data,
      providesTags: ["OptionSets"],
    }),
    getAllOptionSetList: builder.query({
      query: () => {
        return {
          url: "/parameters/optionSet/sets/list",
        };
      },
      transformResponse: (res) => res.data,
      providesTags: ["OptionSetList"],
    }),
    getOptionSetsCount: builder.query({
      query: (queries) => {
        return {
          url: "/parameters/optionSet/sets/count",
          params: omitNullishKeys(queries),
        };
      },
      transformResponse: (res) => res.data,
      providesTags: ["OptionSetsCount"],
    }),
    createOptionSet: builder.mutation({
      query: (optionSetDetails) => ({
        url: "/parameters/optionSet/sets/single",
        method: "POST",
        body: optionSetDetails,
      }),
      transformResponse: (res) => res.data,
      invalidatesTags: ["OptionSets", "OptionSetList", "OptionSetsCount", "Logs"],
    }),
    updateOptionSet: builder.mutation({
      query: ({ details, id }) => ({
        url: `/parameters/optionSet/sets/single/${id}`,
        method: "PUT",
        body: details,
      }),
      transformResponse: (res) => res.data,
      invalidatesTags: ["OptionSets", "OptionSetList", "OptionSetsCount", "Logs"],
    }),
    deleteOptionSet: builder.mutation({
      query: (id) => ({
        url: `/parameters/optionSet/sets/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["OptionSets", "OptionSetList", "OptionSetsCount", "Logs"],
    }),
  }),
});

export const {
  useGetAllOptionSetsQuery,
  useGetAllOptionSetListQuery,
  useGetOptionSetsCountQuery,
  useCreateOptionSetMutation,
  useUpdateOptionSetMutation,
  useDeleteOptionSetMutation,
} = optionSetsApiSlice;
