import React, { useEffect, useReducer, useState } from "react";
import { useDispatch } from "react-redux";
import {
  createSearchParams,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useLocation } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import "./CreateDiscount.scss";
import moment from "moment";
import _ from "lodash";

// ! COMPONENT IMPORTS
import ScheduleDiscountCode from "../ScheduleDiscountCode";
import MaximumDiscountUsers from "../MaximumDiscountUsers";
import CustomerEligibility from "../CustomerEligibility";
import ReturnAndExchangeCondition from "../ReturnAndExchangeCondition";
import DiscountCombination from "../DiscountCombination";
import { SaveFooterTertiary } from "../../../components/SaveFooter/SaveFooter";
import InfoHeader from "../../../components/Header/InfoHeader";
import DiscountFormat from "../../../components/DiscountFormat/DiscountFormat";
import MinimumRequirement from "../../../components/DiscountFormat/MinimumRequirement";
import DiscountValue from "../../../components/DiscountFormat/DiscountValue";
import Filters from "../../../components/DiscountFormat/Filters";
import CouponCode from "../../../components/DiscountFormat/CouponCode";
import BuyXGetY from "../../../components/DiscountFormat/BuyXGetY";
import DiscountRange from "../../../components/DiscountFormat/DiscountRange";
import LimitByLocation from "../../../components/DiscountFormat/LimitByLocation";
import { DiscardModalSecondary } from "../../../components/Discard/DiscardModal";
import {
  showError,
  showSuccess,
} from "../../../features/snackbar/snackbarAction";
import {
  useCreateDiscountMutation,
  useEditDiscountMutation,
  useGetAllDiscountsQuery,
} from "../../../features/offers/discounts/discountsApiSlice";

// !  ASSET IMPORTS
import info from "../../../assets/icons/info.svg";
import block from "../../../assets/images/users/block.svg";

// ! MATERIAL IMPORTS
import { useFormik } from "formik";
import * as Yup from "yup";

import { FormControl, OutlinedInput, Tooltip, Typography } from "@mui/material";

// ! MATERIAL ICONS IMPORTS
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import AlertDialog from "../../../components/AlertDialog/AlertDialog";
import { useFormikToastError } from "../../../hooks/error";
import MaximumRequirement from "../../../components/DiscountFormat/MaximumRequirement";

const initialQueryFilterState = {
  pageSize: 1,
  pageNo: null,
  totalCount: 0,
};
const queryFilterReducer = (state, action) => {
  if (action.type === "SET_PAGE_NO") {
    return {
      ...state,
      pageNo: +action.pageNo,
    };
  }
  if (action.type === "SET_TOTAL_COUNT") {
    return {
      ...state,
      totalCount: action.totalCount,
    };
  }
  return initialQueryFilterState;
};

const couponCodeSchema = Yup.object().shape({
  title: Yup.string()
    .required("Title is required")
    .max(40, "Title must not exceed 40 characters"),
  bodyText: Yup.string()
    .required("Body text is required")
    .max(100, "Body text must not exceed 100 characters"),
});

const discountCombinationValidationSchema = Yup.object().shape({
  allowCombineWithOthers: Yup.boolean().optional(),
  allowCombineWith: Yup.array().when(
    ["allowCombineWithOthers"],
    ([allowCombineWithOthers], schema) => {
      return allowCombineWithOthers
        ? schema.required("required").min(1, "Please select any one")
        : schema;
    }
  ),
});

const maximumDiscountValidationSchema = Yup.object().shape({
  limitDiscountNumber: Yup.boolean().optional(),
  limitUsagePerCustomer: Yup.boolean().optional(),
  total: Yup.number().when(
    ["limitDiscountNumber"],
    ([limitDiscountNumber], schema) => {
      return limitDiscountNumber
        ? schema
            .required("required")
            .min(1, "Minimum quantity must be at least 1")
        : schema;
    }
  ),
  perCustomer: Yup.number().when(
    ["limitUsagePerCustomer", "total", "limitDiscountNumber"],
    ([limitUsagePerCustomer, total, limitDiscountNumber], schema) => {
      if (limitDiscountNumber && limitUsagePerCustomer) {
        return schema
          .required("required")
          .max(
            Yup.ref("total"),
            "Maximum quantity must be greater than or equal to the total quantity"
          );
      } else if (limitUsagePerCustomer) {
        return schema.required("required");
      }
    }
  ),
});

const priceClippingRequirementSchema = Yup.object().shape({
  isInclude: Yup.boolean().optional(),
  priceRange: Yup.number().when(["isInclude"], ([isInclude], schema) => {
    return isInclude
      ? schema
          .required("required")
          .min(1, "Discount value must be greater than 0")
      : schema;
  }),
});

// const maximumDiscountValidationSchema = Yup.object().shape({
//   limitDiscountNumber: Yup.boolean().optional(),
//   limitUsagePerCustomer: Yup.boolean().optional(),
//   total: Yup.string().when(
//     ["limitDiscountNumber"],
//     ([limitDiscountNumber], schema) => {
//       return limitDiscountNumber
//         ? schema
//             .required("required")
//             .min(1, "Minimum quantity must be at least 1")
//         : schema;
//     }
//   ),
//   perCustomer: Yup.string().when(
//     ["limitUsagePerCustomer", "total", "limitDiscountNumber"],
//     ([
//       limitUsagePerCustomer,
//       total,
//       limitDiscountNumber,
//     ], schema) => {
//       return schema.test({
//         name: "perCustomerValidation",
//         test: function (value) {
//           if (limitDiscountNumber && limitUsagePerCustomer) {
//             const totalNumber = Number(total?.replace(/,/g, ""));
//             const perCustomerNumber = Number(value?.replace(/,/g, ""));
//             return (
//               perCustomerNumber >= 0 &&
//               perCustomerNumber <= totalNumber
//             );
//           }
//           return true;
//         },
//         message:
//           "Maximum quantity must be greater than or equal to the total quantity",
//       });
//     }
//   ),
// });

// const maximumDiscountValidationSchema = Yup.object().shape({
//   limitDiscountNumber: Yup.boolean().optional(),
//   limitUsagePerCustomer: Yup.boolean().optional(),
//   total: Yup.string().when(
//     ["limitDiscountNumber"],
//     (limitDiscountNumber, schema) => {
//       return limitDiscountNumber
//         ? schema
//             .required("required")
//         : schema;
//     }
//   ),
//   perCustomer: Yup.string().when(
//     ["limitUsagePerCustomer", "total", "limitDiscountNumber"],
//     ([
//       limitUsagePerCustomer,
//       total,
//       limitDiscountNumber,
//     ], schema) => {
//       return schema
//         .when(["limitUsagePerCustomer"], (limitUsagePerCustomer, schema) => {
//           return schema.required("required");
//         })
//         .when(
//           ["limitUsagePerCustomer", "total", "limitDiscountNumber"],
//           ([limitUsagePerCustomer, total, limitDiscountNumber], schema) => {
//             return schema.test({
//               name: "perCustomerValidation",
//               test: function (value) {
//                 if (limitDiscountNumber && limitUsagePerCustomer) {
//                   const totalNumber = Number(total?.replace(/,/g, ""));
//                   const perCustomerNumber = Number(value?.replace(/,/g, ""));
//                   return perCustomerNumber >= 0 && perCustomerNumber <= totalNumber;
//                 }
//                 return true;
//               },
//               message:
//                 "Maximum quantity must be greater than or equal to the total quantity",
//             });
//           }
//         );
//     }
//   ),
// });

const minimumRequirementValidationSchema = Yup.object().shape({
  requirement: Yup.string(),
  value: Yup.string().when(["requirement"], ([requirement], schema) => {
    return requirement === "amount" || requirement === "quantity"
      ? schema.required("required")
      : schema;
  }),
});

const discountFormatSchema = Yup.object().shape({
  discountFormat: Yup.string().oneOf(
    ["automatic", "code"],
    "Invalid Discount Format"
  ),
  discountCode: Yup.string().when(
    ["discountFormat"],
    ([discountFormat], schema) =>
      discountFormat === "code"
        ? schema
            .trim()
            // .matches(/^[a-zA-Z0-9]+$/, "Discount Code must be alphanumeric")
            .matches(/^[^\s]+$/, "Discount must not have space")
            // .max(6, "Discount Code cannot exceed 6 characters")
            .required("required")
        : schema.required("required")
  ),
});

const filterSchema = Yup.object().shape({
  type: Yup.string().required("Type is required"),
  operator: Yup.string().when(["type"], ([type], schema) => {
    return type === "allProducts" ? schema : schema.required("required");
  }),
  value: Yup.array().when(["type"], ([type], schema) => {
    return type === "allProducts"
      ? schema
      : schema.required("required").min(1, "At least one value is required");
  }),
});

const buyxGetySchema = Yup.object().shape({
  buy: Yup.number()
    .typeError("Type should be number")
    .required("Buy value is required"),
  selectBuyItem: Yup.string().required("Select buy item is required"),
  buyProduct: Yup.array()
    .required("Buy product is required")
    .min(1, "At least one value is required"),
  get: Yup.number()
    .typeError("Type should be number")
    .required("Get value is required"),
  selectGetItem: Yup.string().required("Select get item is required"),
  getProduct: Yup.array()
    .required("Get product is required")
    .min(1, "At least one value is required"),
  discountLabelType: Yup.string()
    .oneOf(["giveDiscount", "free"])
    .required("Discount mode is required"),

  discountValue: Yup.number().when(
    ["discountLabelType"],
    ([discountLabelType], schema) => {
      return discountLabelType === "giveDiscount"
        ? schema
            .required("required")
            .min(1, "Discount value must be greater than 0")
        : schema;
    }
  ),

  unit: Yup.string(),
  value: Yup.string().when(
    ["discountLabelType"],
    ([discountLabelType], schema) => {
      return discountLabelType === "giveDiscount"
        ? schema.required("required")
        : schema;
    }
  ),
});

const scheduleDateSchema = Yup.object().shape({
  startDateTime: Yup.date().required("Start date and time is required"),
  endDateTime: Yup.date().when(["neverExpire"], ([neverExpire], schema) => {
    return neverExpire ? schema : schema.required("required");
  }),
  neverExpire: Yup.boolean(),
});

const discountValueSchema = Yup.object().shape({
  discountValue: Yup.number()
    .typeError("Type should be number")
    .min(1, "Discount value must be greater than 0")
    .required("Discount value is required"),
  type: Yup.string()
    .oneOf(["percentage", "fixed"])
    .required("Type must be percentage or fixed"),
  // value: Yup.string().required("Value is required"),
});

const customerEligibilitySchema = Yup.object().shape({
  customer: Yup.string()
    .required("required")
    .oneOf(["specificCustomers", "customerGroups", "allCustomers"]),
  specificCustomers: Yup.array().when(["customer"], ([customer], schema) => {
    return customer === "specificCustomers"
      ? schema.required("required").min(1, "At least one value is required")
      : schema;
  }),
  customerGroups: Yup.array().when(["customer"], ([customer], schema) => {
    return customer === "customerGroups"
      ? schema.required("required").min(1, "At least one value is required")
      : schema;
  }),
});

const limitByLocationSchema = Yup.object().shape({
  locationType: Yup.string().required(),
  location: Yup.array().when(["locationType"], ([locationType], schema) => {
    return locationType !== "none"
      ? schema.required("required").min(1, "At least one value is required")
      : schema;
  }),
});

const discountRangeValidationSchema = Yup.object().shape({
  minQty: Yup.number()
    .required("Minimum quantity is required")
    .min(0, "Minimum quantity must be at least 0"),
  maxQty: Yup.number()
    .required("Maximum quantity is required")
    .min(
      Yup.ref("minQty"),
      "Maximum quantity must be greater than or equal to the minimum quantity"
    ),
  value: Yup.number().required("Discount value is required"),
  unit: Yup.string()
    .required("Type is required")
    .oneOf(
      ["fixed", "percentage"],
      "Unit must be either 'fixed' or 'percentage'"
    ),
  discountOn: Yup.string().required("Value is required"),
});

const discountValidationSchema = Yup.object().shape({
  discountName: Yup.string()
    .trim()
    .max(50, "Name cannot exceed 50 characters")
    .required("Name is required"),
  discountType: Yup.string()
    .oneOf(
      [
        "productDiscount",
        "cartDiscount",
        "freeShipping",
        "buyxGety",
        "bulk",
        "",
      ],
      "Invalid Discount Type"
    )
    .required("Discount Type is required"),
  discountFormat: discountFormatSchema,
  priceClippingRequirement: priceClippingRequirementSchema,
  minimumRequirement: minimumRequirementValidationSchema,
  filters: Yup.mixed().when(["discountType"], ([discountType], schema) => {
    if (discountType === "productDiscount") {
      return Yup.array().of(filterSchema);
    }
    return schema;
  }),
  returnExchange: Yup.string()
    .oneOf(["allowed", "notAllowed"], "Invalid")
    .required("required"),
  maximumDiscount: maximumDiscountValidationSchema,
  scheduledDiscount: scheduleDateSchema,
  discountValue: Yup.mixed().when(
    ["discountType"],
    ([discountType], schema) => {
      if (discountType === "productDiscount") {
        return discountValueSchema;
      } else if (discountType === "cartDiscount") {
        return Yup.object().shape({
          discountValue: Yup.number()
            .typeError("Type should be number")
            .min(1, "Discount value must be greater than 0")
            .required("Discount value is required"),
          type: Yup.string()
            .oneOf(["percentage", "fixed"])
            .required("Type must be percentage or fixed"),
          // value: Yup.string().required("Value is required"),
          // cartLabel: Yup.string().required("required"),
        });
      }
      return schema;
    }
  ),
  buyXGetY: Yup.mixed().when(["discountType"], ([discountType], schema) => {
    if (discountType === "buyxGety") {
      return buyxGetySchema.required("Buy X Get Y details are required");
    }
    return schema;
  }),
  customerEligibility: customerEligibilitySchema,
  discountRange: Yup.mixed().when(
    ["discountType"],
    ([discountType], schema) => {
      if (discountType === "bulk") {
        return Yup.array().of(discountRangeValidationSchema);
      }
      return schema;
    }
  ),
  limitByLocation: Yup.mixed().when(
    ["discountType"],
    ([discountType], schema) => {
      if (discountType === "freeShipping") {
        return limitByLocationSchema;
      }
      return schema;
    }
  ),
  discountCombination: discountCombinationValidationSchema,

  // couponCode: couponCodeSchema,
});
const initialDiscountsState = {
  data: [],
  totalCount: 0,
  discountType: 0,
  isEditing: false,
  edited: false,
};
const discountsReducer = (state, action) => {
  if (action.type === "SET_DATA") {
    return {
      ...state,
      data: action.data,
      totalCount: action.totalCount,
    };
  }
  if (action.type === "SET_DISCOUNT_TYPE") {
    return {
      ...state,
      discountType: action.discountType,
    };
  }
  if (action.type === "ENABLE_EDIT") {
    return {
      ...state,
      isEditing: true,
    };
  }
  if (action.type === "DISABLE_EDIT") {
    return {
      ...state,
      isEditing: false,
    };
  }
  if (action.type === "EDITED_ENABLE") {
    return {
      ...state,
      edited: true,
    };
  }
  if (action.type === "EDITED_DISABLE") {
    return {
      ...state,
      edited: false,
    };
  }
  return initialDiscountsState;
};
const CreateDiscount = () => {
  const [queryFilterState, dispatchQueryFilter] = useReducer(
    queryFilterReducer,
    initialQueryFilterState
  );
  const [discountsState, dispatchDiscounts] = useReducer(
    discountsReducer,
    initialDiscountsState
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [decodedObject, setDecodedObject] = useState(null);
  const [copied, setCopied] = useState(false);
  const [openBlock, setOpenBlock] = useState(false);
  const [deactivate, setDeactivate] = useState(false);
  let { id } = useParams();

  const [
    createDiscount,
    {
      isLoading: createDiscountIsLoading,
      isSuccess: createDiscountIsSuccess,
      error: createDiscountError,
    },
  ] = useCreateDiscountMutation();

  const {
    data: discountsData,
    isLoading: discountsIsLoading,
    isSuccess: discountsIsSuccess,
    error: discountsError,
    isError: discountsIsError,
  } = useGetAllDiscountsQuery(
    {
      id: id,
      alphabetical: decodedObject?.alphabetical,
      createdAt: decodedObject?.createdAt,
      name: decodedObject?.name,
      status: decodedObject?.status,
    },
    { skip: location.pathname === "/offers/discounts/create" }
  );

  const [
    editDiscount,
    {
      isLoading: editDicountIsLoading,
      isSuccess: editDicountIsSuccess,
      error: editDicountError,
      isError: editDicountIsError,
    },
  ] = useEditDiscountMutation();

  // ? USER ROLE SELECT STARTS HERE
  const [discountType, setDiscountType] = React.useState("");

  const handleDiscountType = (event) => {
    setDiscountType(event.target.value);
  };
  // ? USER ROLE SELECT ENDS HERE

  // ? DISCOUNT FORMAT SELECT STARTS HERE
  const [discountFormat, setDiscountFormat] = React.useState("");

  const handleDiscountFormatChange = (event) => {
    setDiscountFormat(event.target.value);
  };
  // ? DISCOUNT FORMAT SELECT ENDS HERE

  // ? FIELD SELECT STARTS HERE
  const [field, setField] = React.useState("");

  const handleFieldChange = (event) => {
    setField(event.target.value);
  };
  // ? FIELD SELECT ENDS HERE

  // ? OPERATOR SELECT STARTS HERE
  const [operator, setOperator] = React.useState("");

  const handleOperatorChange = (event) => {
    setOperator(event.target.value);
  };
  // ? OPERATOR SELECT ENDS HERE

  // ? ITEM SELECT STARTS HERE
  const [item, setItem] = React.useState("");

  const handleItemChange = (event) => {
    setItem(event.target.value);
  };
  // ? ITEM SELECT ENDS HERE

  // ? CHECKBOX STARTS HERE
  const [checkedDiscount, setCheckedDiscount] = React.useState(true);

  const handleDiscountCheckboxChange = (event) => {
    setCheckedDiscount(event.target.checked);
  };

  // ? CHECKBOX ENDS HERE

  // ? RADIO STARTS HERE
  const [minimumRequirement, setminimumRequirement] = React.useState(0);
  const handleMinimumRequirementChange = (event, newValue) => {
    setminimumRequirement(newValue);
  };

  const [limitByLocation, setLimitByLocation] = React.useState("");
  const handleLimitByLocationChange = (event, newValue) => {
    setLimitByLocation(newValue);
  };
  const [discountMode, setDiscountMode] = React.useState("giveDiscount");
  const handleDiscountModeChange = (event, newValue) => {
    setDiscountMode(newValue);
  };
  // ? RADIO ENDS HERE

  // * DISCOUNT PERCENT POPOVERS STARTS
  const [anchorDiscountPercentEl, setAnchorDiscountPercentEl] =
    React.useState(null);
  const handleDiscountPercent = (event) => {
    setAnchorDiscountPercentEl(event.currentTarget);
  };
  const handleDiscountPercentClose = () => {
    setAnchorDiscountPercentEl(null);
  };
  const openDiscountPercent = Boolean(anchorDiscountPercentEl);
  const idDiscountPercent = openDiscountPercent ? "simple-popover" : undefined;
  // * DICOUNT PERCENT POPOVERS ENDS

  const handleDelete = () => {};

  const nextPageHandler = () => {
    const { pageNo, totalCount } = queryFilterState;
    if (pageNo + 1 > totalCount) {
      return;
    }
    navigate(`/offers/discounts/edit/${pageNo + 1}`);
  };

  const prevPageHandler = () => {
    const { pageNo } = queryFilterState;
    if (pageNo - 1 === 0) {
      return;
    }
    navigate(`/offers/discounts/edit/${pageNo - 1}`);
  };

  const updateParams = () => {
    return JSON.stringify({
      ...JSON.parse(searchParams.get("filter")),
      format: formik?.values?.discountFormat?.discountFormat,
      discountType: formik?.values?.discountFormat?.discountFormat
        ? formik?.values?.discountFormat?.discountFormat === "code"
          ? 0
          : 1
        : 0,
    });
  };

  const backHandler = () => {
    if (editDicountIsSuccess) {
      navigate({
        pathname: "/offers/discounts",
        search: `?${createSearchParams({ filter: updateParams() })}`,
      });
    } else {
      navigate({
        pathname: "/offers/discounts",
        search: `?${createSearchParams({
          filter: searchParams.get("filter"),
        })}`,
      });
    }
  };

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  const formik = useFormik({
    initialValues: {
      discountName: discountsData?.data?.data[0]?.name || "",
      status: discountsData?.data?.data[0]?.status || "active",
      discountType:
        searchParams.get("discountType") ||
        discountsData?.data?.data[0]?.mainDiscount?.type ||
        "",
      discountFormat: {
        discountFormat:
          discountsData?.data.data[0].mainDiscount.format || "code",
        discountCode:
          discountsData?.data.data[0].mainDiscount.discountCode ||
          discountsData?.data.data[0].mainDiscount.discountName ||
          "",
      },
      minimumRequirement: {
        requirement:
          discountsData?.data.data[0].minimumRequirement.requirementType ||
          "none",
        value:
          discountsData?.data.data[0].minimumRequirement?.amount?.toLocaleString(
            "en-IN"
          ) ||
          discountsData?.data.data[0].minimumRequirement?.quantity?.toLocaleString(
            "en-IN"
          ) ||
          "",
      },
      returnExchange: "allowed",
      maximumDiscount: {
        limitDiscountNumber:
          discountsData?.data.data[0].maximumDiscountUse?.limitDiscountNumber ||
          false,
        limitUsagePerCustomer:
          discountsData?.data.data[0].maximumDiscountUse
            ?.limitUsagePerCustomer || false,
        total:
          discountsData?.data.data[0].maximumDiscountUse?.total?.toString() ||
          "",
        perCustomer:
          discountsData?.data.data[0].maximumDiscountUse?.perCustomer?.toString() ||
          "",
      },
      priceClippingRequirement: {
        isInclude:
          discountsData?.data.data?.[0]?.priceClippingRequirement?.isInclude ||
          false,
        priceRange:
          discountsData?.data.data?.[0]?.priceClippingRequirement?.priceRange ||
          1,
      },
      discountCombination: {
        allowCombineWithOthers:
          discountsData?.data.data[0].allowCombineWithOthers || false,
        allowCombineWith: discountsData?.data.data[0].allowCombineWith || [],
      },
      filters: discountsData?.data.data[0].mainDiscount.filter || [
        {
          type: "",
          operator: "",
          value: [],
          dropDownData: [],
        },
      ],
      couponCode: {
        showCouponCode: false,
        title: null,
        bodyText: null,
      },
      customerEligibility: {
        customer:
          discountsData?.data?.data[0]?.eligibility?.eligibilityType ||
          "allCustomers",
        customerGroups:
          discountsData?.data?.data[0]?.eligibility?.customerGroups || [],
        specificCustomers:
          discountsData?.data?.data[0]?.eligibility?.specificCustomers || [],
      },
      discountValue: {
        discountValue:
          discountsData?.data?.data[0]?.mainDiscount?.value?.toString() || "",
        type: discountsData?.data?.data[0]?.mainDiscount?.unit || "percentage",
        value: discountsData?.data?.data[0]?.mainDiscount?.discountOn || "",
        cartLabel: discountsData?.data?.data[0]?.mainDiscount?.cartLabel || "",
      },
      buyXGetY: {
        buy:
          discountsData?.data.data[0].mainDiscount?.buyField?.quantity?.toString() ||
          "",
        selectBuyItem:
          discountsData?.data.data[0].mainDiscount?.buyField?.selectItem || "",
        buyProduct:
          discountsData?.data.data[0].mainDiscount?.buyField?.selectItemValue ||
          [],
        get:
          discountsData?.data.data[0].mainDiscount?.getField?.quantity?.toString() ||
          "",
        selectGetItem:
          discountsData?.data.data[0].mainDiscount?.getField?.selectItem || "",
        getProduct:
          discountsData?.data.data[0].mainDiscount?.getField?.selectItemValue ||
          [],
        discountLabelType:
          discountsData?.data.data[0].mainDiscount?.discountLabelType ||
          "giveDiscount",
        discountValue: discountsData?.data.data[0].mainDiscount?.value || "",
        unit: discountsData?.data.data[0].mainDiscount?.unit || "percentage",
        value: discountsData?.data.data[0].mainDiscount?.discountOn || "",
      },
      discountRange: discountsData?.data.data[0].mainDiscount.rangeDiscount || [
        {
          minQty: null,
          maxQty: null,
          value: null,
          unit: "percentage",
          discountOn: "",
        },
      ],
      limitByLocation: {
        locationType:
          discountsData?.data.data[0].mainDiscount?.limitByLocation
            ?.locationType || "none",
        location:
          discountsData?.data.data[0].mainDiscount?.limitByLocation?.country
            .length > 0
            ? discountsData?.data.data[0].mainDiscount?.limitByLocation?.country
            : discountsData?.data.data[0].mainDiscount?.limitByLocation?.state
                .length > 0
            ? discountsData?.data.data[0].mainDiscount?.limitByLocation?.state
            : discountsData?.data.data[0].mainDiscount?.limitByLocation?.value
                .length > 0
            ? discountsData?.data.data[0].mainDiscount?.limitByLocation?.value
            : "",
        // location: discountsData?.data.data[0].mainDiscount?.limitByLocation?.country|| discountsData?.data.data[0].mainDiscount?.limitByLocation?.state || discountsData?.data.data[0].mainDiscount?.limitByLocation?.value || [],
      },
      scheduledDiscount: {
        startDateTime: discountsData?.data?.data[0]?.scheduledDiscount
          ?.startDateTime
          ? moment(
              discountsData?.data?.data[0]?.scheduledDiscount?.startDateTime,
              "YYYY-MM-DDTHH:mm:ss[Z]"
            ).format("MM/DD/YYYY hh:mm A")
          : moment(new Date().toISOString(), "YYYY-MM-DDTHH:mm:ss[Z]").format(
              "MM/DD/YYYY hh:mm A"
            ),
        endDateTime: discountsData?.data?.data[0]?.scheduledDiscount
          ?.endDateTime
          ? moment(
              discountsData?.data?.data[0]?.scheduledDiscount?.endDateTime,
              "YYYY-MM-DDTHH:mm:ss[Z]"
            ).format("MM/DD/YYYY hh:mm A")
          : moment(
              new Date(
                new Date().getTime() + 60 * 60 * 24 * 1000
              ).toISOString(),
              "YYYY-MM-DDTHH:mm:ss[Z]"
            ).format("MM/DD/YYYY hh:mm A"),
        neverExpire:
          discountsData?.data?.data[0]?.scheduledDiscount?.neverExpire || false,
      },
    },
    enableReinitialize: true,
    validationSchema: discountValidationSchema,
    onSubmit: (values) => {
      dispatchDiscounts({ type: "EDITED_DISABLE" });
      const test = {
        name: values?.discountName,
        status: "active",
        mainDiscount: {
          type: values?.discountType,
          format: values?.discountFormat?.discountFormat,
          ...(values?.discountType === "cartDiscount" ||
          values?.discountType === "productDiscount" ||
          (values?.discountType === "buyxGety" &&
            values?.buyXGetY?.discountLabelType === "giveDiscount")
            ? {
                value:
                  values?.discountValue?.discountValue ||
                  values?.buyXGetY?.discountValue,
                discountOn:
                  values?.discountValue?.value ||
                  values?.buyXGetY?.value ||
                  "totalPrice",
                unit:
                  values?.discountType === "buyxGety"
                    ? values?.buyXGetY?.unit
                    : values?.discountValue?.type,
              }
            : {}),

          ...(values?.discountFormat?.discountFormat === "code"
            ? { discountCode: values?.discountFormat?.discountCode }
            : { discountName: values?.discountFormat?.discountCode }),

          ...(values?.discountType === "cartDiscount"
            ? {
                cartLabel:
                  values?.discountValue?.cartLabel ||
                  values?.discountFormat?.discountCode ||
                  "cartLabel",
              }
            : {}),

          ...(values?.discountType !== "buyxGety"
            ? {
                filter: values?.filters.map((filter) => {
                  if (filter?.type && filter?.type !== "allProducts") {
                    return {
                      type: filter?.type,
                      operator: filter?.operator,
                      value: filter?.value.map((item) => item?._id),
                    };
                  } else {
                    return {
                      type: "allProducts",
                    };
                  }
                }) || [
                  {
                    type: "allProducts",
                  },
                ],
              }
            : {}),

          ...(values?.discountType === "buyxGety"
            ? {
                discountLabelType: values?.buyXGetY?.discountLabelType,
                buyField: {
                  quantity: values?.buyXGetY?.buy,
                  selectItem: values?.buyXGetY?.selectBuyItem,
                  selectItemValue: values?.buyXGetY?.buyProduct.map(
                    (item, index) => item?._id
                  ),
                },
                getField: {
                  quantity: values?.buyXGetY?.get,
                  selectItem: values?.buyXGetY?.selectGetItem,
                  selectItemValue: values?.buyXGetY?.getProduct.map(
                    (item, index) => item?._id
                  ),
                },
              }
            : {}),
          ...(values?.discountType === "freeShipping"
            ? {
                limitByLocation: {
                  locationType: values?.limitByLocation?.locationType,
                  ...(values?.limitByLocation?.locationType === "country"
                    ? {
                        country: values?.limitByLocation?.location.map(
                          (item, index) => item?._id
                        ),
                      }
                    : values?.limitByLocation?.locationType === "state"
                    ? {
                        state: values?.limitByLocation?.location.map(
                          (item, index) => item?._id
                        ),
                      }
                    : values?.limitByLocation?.locationType === "zip"
                    ? {
                        value: values?.limitByLocation?.location.map(
                          (item, index) => item?._id
                        ),
                      }
                    : {}),
                },
              }
            : {}),
          ...(values?.discountType === "bulk"
            ? {
                rangeDiscount: values?.discountRange.map((discount) => ({
                  value: discount?.value,
                  unit: discount?.unit,
                  minQty: discount?.minQty,
                  maxQty: discount?.maxQty,
                  discountOn: discount?.discountOn,
                })),
              }
            : {}),
        },
        minimumRequirement: {
          requirementType: values?.minimumRequirement?.requirement,
          ...(values?.minimumRequirement?.requirement === "amount"
            ? {
                amount: Number(
                  values?.minimumRequirement?.value.replace(/,/g, "")
                ),
              }
            : values?.minimumRequirement?.requirement === "quantity"
            ? {
                quantity: Number(
                  values?.minimumRequirement?.value.replace(/,/g, "")
                ),
              }
            : {}),
        },
        eligibility: {
          eligibilityType: values?.customerEligibility?.customer,
          ...(values?.customerEligibility?.customer === "allCustomers"
            ? { allCustomers: true }
            : values?.customerEligibility?.customer === "specificCustomers"
            ? {
                specificCustomers:
                  values?.customerEligibility?.specificCustomers.map(
                    (item, index) => item?._id
                  ),
              }
            : values?.customerEligibility?.customer === "customerGroups"
            ? {
                customerGroups: values?.customerEligibility?.customerGroups.map(
                  (item, index) => item?._id
                ),
              }
            : {}),
        },
        maximumDiscountUse: {
          ...(values?.maximumDiscount?.limitDiscountNumber === true &&
          values?.maximumDiscount?.limitUsagePerCustomer === true
            ? {
                limitDiscountNumber:
                  values?.maximumDiscount?.limitDiscountNumber,
                limitUsagePerCustomer:
                  values?.maximumDiscount?.limitUsagePerCustomer,
                total: values?.maximumDiscount?.total,
                perCustomer: values?.maximumDiscount?.perCustomer,
              }
            : values?.maximumDiscount?.limitDiscountNumber === true
            ? {
                total: values?.maximumDiscount?.total,
                limitDiscountNumber:
                  values?.maximumDiscount?.limitDiscountNumber,
              }
            : values?.maximumDiscount?.limitUsagePerCustomer === true
            ? {
                perCustomer: values?.maximumDiscount?.perCustomer,
                limitUsagePerCustomer:
                  values?.maximumDiscount?.limitUsagePerCustomer,
              }
            : { isUnlimited: true }),
        },
        priceClippingRequirement: {
          isInclude: values?.priceClippingRequirement?.isInclude || false,
          priceRange: Number(values?.priceClippingRequirement?.priceRange || 0),
        },
        returnExchangeCondition: values?.returnExchange,
        allowCombineWithOthers:
          values?.discountCombination?.allowCombineWithOthers,
        ...(values?.discountCombination?.allowCombineWithOthers === true
          ? { allowCombineWith: values?.discountCombination?.allowCombineWith }
          : {}),
        scheduledDiscount: {
          startDateTime: moment(
            values?.scheduledDiscount?.startDateTime
          ).format("YYYY-MM-DDTHH:mm:ss[Z]"),
          ...(values?.scheduledDiscount?.neverExpire
            ? { neverExpire: values?.scheduledDiscount?.neverExpire }
            : {
                endDateTime: moment(
                  values?.scheduledDiscount?.endDateTime
                ).format("YYYY-MM-DDTHH:mm:ss[Z]"),
              }),
        },
      };
      const editObject = {
        name: values?.discountName,
        status: values?.status,
        mainDiscount: {
          type: values?.discountType,
          format: values?.discountFormat?.discountFormat,
          ...(values?.discountType === "cartDiscount" ||
          values?.discountType === "productDiscount" ||
          (values?.discountType === "buyxGety" &&
            values?.buyXGetY?.discountLabelType === "giveDiscount")
            ? {
                value:
                  values?.discountValue?.discountValue ||
                  values?.buyXGetY?.discountValue,
                discountOn:
                  values?.discountValue?.value || values?.buyXGetY?.value,
                unit:
                  values?.discountType === "buyxGety"
                    ? values?.buyXGetY?.unit
                    : values?.discountValue?.type,
              }
            : {}),

          ...(values?.discountFormat?.discountFormat === "code" &&
          !_.isEqual(
            formik?.initialValues?.discountFormat?.discountCode,
            formik?.values?.discountFormat?.discountCode
          )
            ? {
                discountCode: values?.discountFormat?.discountCode,
                isEdit: true,
              }
            : values?.discountFormat?.discountFormat === "code" &&
              _.isEqual(
                formik?.initialValues?.discountFormat?.discountCode,
                formik?.values?.discountFormat?.discountCode
              )
            ? {
                discountCode: values?.discountFormat?.discountCode,
                isEdit: false,
              }
            : values?.discountFormat?.discountFormat === "automatic"
            ? { discountName: values?.discountFormat?.discountCode }
            : {}),

          // ...(values?.discountFormat?.discountFormat === "code"
          // ? { discountCode: values?.discountFormat?.discountCode, isEdit : true }
          // : { discountName: values?.discountFormat?.discountCode }),

          ...(values?.discountType === "cartDiscount"
            ? { cartLabel: values?.discountValue?.cartLabel }
            : {}),

          ...(values?.discountType !== "buyxGety"
            ? {
                filter: values?.filters.map((filter) => {
                  if (filter?.type !== "allProducts") {
                    return {
                      type: filter?.type,
                      operator: filter?.operator,
                      value: filter?.value.map((item) => item?._id),
                    };
                  } else {
                    return {
                      type: filter?.type,
                    };
                  }
                }),
              }
            : {}),

          ...(values?.discountType === "buyxGety"
            ? {
                discountLabelType: values?.buyXGetY?.discountLabelType,
                buyField: {
                  quantity: values?.buyXGetY?.buy,
                  selectItem: values?.buyXGetY?.selectBuyItem,
                  selectItemValue: values?.buyXGetY?.buyProduct.map(
                    (item, index) => item?._id
                  ),
                },
                getField: {
                  quantity: values?.buyXGetY?.get,
                  selectItem: values?.buyXGetY?.selectGetItem,
                  selectItemValue: values?.buyXGetY?.getProduct.map(
                    (item, index) => item?._id
                  ),
                },
              }
            : {}),
          ...(values?.discountType === "freeShipping"
            ? {
                limitByLocation: {
                  locationType: values?.limitByLocation?.locationType,
                  ...(values?.limitByLocation?.locationType === "country"
                    ? {
                        country: values?.limitByLocation?.location.map(
                          (item, index) => item?._id
                        ),
                      }
                    : values?.limitByLocation?.locationType === "state"
                    ? {
                        state: values?.limitByLocation?.location.map(
                          (item, index) => item?._id
                        ),
                      }
                    : values?.limitByLocation?.locationType === "zip"
                    ? {
                        value: values?.limitByLocation?.location.map(
                          (item, index) => item?._id
                        ),
                      }
                    : {}),
                },
              }
            : {}),
          ...(values?.discountType === "bulk"
            ? {
                rangeDiscount: values?.discountRange.map((discount) => ({
                  value: discount?.value,
                  unit: discount?.unit,
                  minQty: discount?.minQty,
                  maxQty: discount?.maxQty,
                  discountOn: discount?.discountOn,
                })),
              }
            : {}),
        },
        priceClippingRequirement: {
          isInclude: values?.priceClippingRequirement?.isInclude || false,
          priceRange: Number(values?.priceClippingRequirement?.priceRange || 0),
        },
        minimumRequirement: {
          requirementType: values?.minimumRequirement?.requirement,
          ...(values?.minimumRequirement?.requirement === "amount"
            ? {
                amount: Number(
                  values?.minimumRequirement?.value.replace(/,/g, "")
                ),
              }
            : values?.minimumRequirement?.requirement === "quantity"
            ? {
                quantity: Number(
                  values?.minimumRequirement?.value.replace(/,/g, "")
                ),
              }
            : {}),
        },
        eligibility: {
          eligibilityType: values?.customerEligibility?.customer,
          ...(values?.customerEligibility?.customer === "allCustomers"
            ? { allCustomers: true }
            : values?.customerEligibility?.customer === "specificCustomers"
            ? {
                specificCustomers:
                  values?.customerEligibility?.specificCustomers.map(
                    (item, index) => item?._id
                  ),
              }
            : values?.customerEligibility?.customer === "customerGroups"
            ? {
                customerGroups: values?.customerEligibility?.customerGroups.map(
                  (item, index) => item?._id
                ),
              }
            : {}),
        },
        maximumDiscountUse: {
          ...(values?.maximumDiscount?.limitDiscountNumber === true &&
          values?.maximumDiscount?.limitUsagePerCustomer === true
            ? {
                limitDiscountNumber:
                  values?.maximumDiscount?.limitDiscountNumber,
                limitUsagePerCustomer:
                  values?.maximumDiscount?.limitUsagePerCustomer,
                total: values?.maximumDiscount?.total,
                perCustomer: values?.maximumDiscount?.perCustomer,
              }
            : values?.maximumDiscount?.limitDiscountNumber === true
            ? {
                total: values?.maximumDiscount?.total,
                limitDiscountNumber:
                  values?.maximumDiscount?.limitDiscountNumber,
              }
            : values?.maximumDiscount?.limitUsagePerCustomer === true
            ? {
                perCustomer: values?.maximumDiscount?.perCustomer,
                limitUsagePerCustomer:
                  values?.maximumDiscount?.limitUsagePerCustomer,
              }
            : { isUnlimited: true }),
        },
        returnExchangeCondition: values?.returnExchange,
        allowCombineWithOthers:
          values?.discountCombination?.allowCombineWithOthers,
        ...(values?.discountCombination?.allowCombineWithOthers === true
          ? { allowCombineWith: values?.discountCombination?.allowCombineWith }
          : {}),
        scheduledDiscount: {
          startDateTime: moment(
            values?.scheduledDiscount?.startDateTime
          ).format("YYYY-MM-DDTHH:mm:ss[Z]"),
          ...(values?.scheduledDiscount?.neverExpire
            ? { neverExpire: values?.scheduledDiscount?.neverExpire }
            : {
                endDateTime: moment(
                  values?.scheduledDiscount?.endDateTime
                ).format("YYYY-MM-DDTHH:mm:ss[Z]"),
              }),
        },
      };
      if (id) {
        editDiscount({
          id: discountsData?.data?.data[0]._id,
          details: editObject,
        });
        // .unwrap()
        // .then(() => {
        //   dispatchDiscounts({ type: "DISABLE_EDIT" });
        //   dispatch(showSuccess({ message: "Discounts edited successfully" }));
        // });
      } else {
        createDiscount(test);
        //   .unwrap()
        //   .then(() => {
        //     if(createDiscountIsSuccess)
        //     {
        //       formik.resetForm();
        //       navigate("/offers/discounts");
        //       dispatch(showSuccess({ message: "Discount created successfully" }));
        //     }
        //  })
        //  .catch(()=>{createDiscountError ? dispatch(showError({ message: createDiscountError?.data?.message })):dispatch(
        //   showError({ message: "Something went wrong, please try again" }));});
      }
    },
  });

  console.log(formik.errors, "errorsFormik");

  useFormikToastError(formik);

  const addFilterHandler = () => {
    const newFilter = formik?.values?.filters.concat({
      type: "",
      operator: "",
      value: [],
      dropDownData: [],
    });
    formik.setFieldValue("filters", newFilter);
  };

  const deleteFilterHandler = ({ deleteIndex }) => {
    if (formik.values?.filters?.length === 1) {
      dispatch(showError({ message: "At least one filter is required" }));
      return;
    }
    const updatedFilter = formik?.values?.filters.filter(
      (_, index) => index !== deleteIndex
    );
    formik.setFieldValue("filters", updatedFilter);
  };

  const deleteDiscountRangeHandler = ({ deleteIndex }) => {
    const discountRange = formik.values?.discountRange;
    if (discountRange && discountRange.length === 1) {
      dispatch(
        showError({ message: "At least one discount Range is required" })
      );
    } else if (discountRange) {
      formik.setFieldValue(
        "discountRange",
        discountRange.filter((_, index) => index !== deleteIndex)
      );
    }
  };
  const addDiscountRangeHandler = () => {
    const newRange = formik?.values?.discountRange.concat({
      minQty:
        formik.values.discountRange.length > 0
          ? +formik.values.discountRange[formik.values.discountRange.length - 1]
              .maxQty + 1
          : null,
      maxQty: null,
      value: null,
      unit: "percentage",
      discountOn: null,
    });
    formik.setFieldValue("discountRange", newRange);
  };

  useEffect(() => {
    if (discountsIsSuccess) {
      dispatchDiscounts({ type: "EDITED_DISABLE" });
    }
    if (discountsIsError) {
      if (discountsError?.data?.message) {
        dispatch(showError({ message: discountsError?.data?.message }));
      } else {
        dispatch(
          showError({ message: "Something went wrong, please try again" })
        );
      }
    }
  }, [
    discountsIsSuccess,
    discountsIsError,
    discountsError,
    editDicountIsSuccess,
    id,
    discountValueSchema,
    dispatch,
  ]);

  useEffect(() => {
    if (createDiscountIsSuccess) {
      formik.resetForm();
      // navigate("/offers/discounts");
      navigate({
        pathname: "/offers/discounts",
        search: `?${createSearchParams({ filter: updateParams() })}`,
      });
      dispatch(
        showSuccess({
          message: `${formik?.values?.discountName} created successfully`,
        })
      );
    }
    if (createDiscountError) {
      if (createDiscountError?.data?.message) {
        dispatch(showError({ message: createDiscountError?.data?.message }));
      } else {
        dispatch(
          showError({ message: "Something went wrong, please try again" })
        );
      }
    }
  }, [createDiscountError, dispatch, createDiscountIsSuccess]);

  useEffect(() => {
    if (editDicountIsSuccess) {
      dispatch(
        showSuccess({
          message: `${formik?.values?.discountName} Edited successfully`,
        })
      );
    }
    if (editDicountIsError) {
      if (editDicountError?.data?.message) {
        dispatch(showError({ message: editDicountError?.data?.message }));
      } else {
        dispatch(
          showError({ message: "Something went wrong, please try again" })
        );
      }
    }
  }, [editDicountIsSuccess, dispatch, editDicountIsError, editDicountError]);

  useEffect(() => {
    const encodedString = searchParams.get("filter");
    const decodedString = decodeURIComponent(encodedString);
    const parsedObject = JSON.parse(decodedString);
    setDecodedObject(parsedObject);

    // dispatchDiscounts({type : "SET_DISCOUNT_TYPE", discountType : searchParams.get("discountType") }) ;
  }, [searchParams]);

  useEffect(() => {
    if (location.pathname !== "/offers/discounts/create") {
      if (id) {
        // Create copies of formik.values and formik.initialValues without the 'filters.dropDownData' field
        const formikValuesWithoutDropDown = {
          ...formik.values,
          filters: formik.values.filters.map((filter) => ({
            ...filter,
            dropDownData: undefined, // Set the dropDownData to undefined
          })),
        };

        const formikInitialValuesWithoutDropDown = {
          ...formik?.initialValues,
          filters: formik?.initialValues?.filters?.map((filter) => ({
            ...filter,
            dropDownData: undefined, // Set the dropDownData to undefined
          })),
        };

        // const formikValuesWithoutDropDown = {
        //   ...formik.values,
        //   filters: formik.values.filters.map(({ dropDownData, ...filter }) => filter),
        // };

        // const formikInitialValuesWithoutDropDown = {
        //   ...formik.initialValues,
        //   filters: formik.initialValues.filters.map(({ dropDownData, ...filter }) => filter),
        // };

        // Perform the comparison without considering 'filters.dropDownData'
        if (
          !_.isEqual(
            formikValuesWithoutDropDown,
            formikInitialValuesWithoutDropDown
          )
        ) {
          dispatchDiscounts({ type: "EDITED_ENABLE" });
          dispatchDiscounts({ type: "ENABLE_EDIT" });
        } else {
          dispatchDiscounts({ type: "EDITED_DISABLE" });
          dispatchDiscounts({ type: "DISABLE_EDIT" });
        }
      }

      // if (id && !_.isEqual(formik.values, formik.initialValues)) {
      //   dispatchDiscounts({ type: "EDITED_ENABLE" });
      //   dispatchDiscounts({ type: "ENABLE_EDIT" });
      // } else if (id && _.isEqual(formik.values, formik.initialValues)) {
      //   dispatchDiscounts({ type: "EDITED_DISABLE" });
      //   dispatchDiscounts({ type: "DISABLE_EDIT" });
      // }
    } else {
      if (!_.isEqual(formik.values, formik.initialValues)) {
        dispatchDiscounts({ type: "EDITED_ENABLE" });
        dispatchDiscounts({ type: "ENABLE_EDIT" });
      } else if (_.isEqual(formik.values, formik.initialValues)) {
        dispatchDiscounts({ type: "EDITED_DISABLE" });
        dispatchDiscounts({ type: "DISABLE_EDIT" });
      }
    }
  }, [formik.initialValues, formik.values, id]);

  // useEffect(() => {
  //   // if (formik.errors && Object.keys(formik.errors).length > 0) {
  //   //   Object.keys(formik.errors).forEach((key) => {
  //   //     const errorMessage = `formik.errors.${key}`;
  //   //     dispatch(showError({ message: errorMessage }));
  //   //   });
  //   // }
  //   if (Object.keys(formik.errors).length > 0) {
  //     dispatch(showError({ message: "Please fill required fields" }));
  //   }

  // }, [formik.errors])

  // const handleSubmit = (values, { setSubmitting }) => {
  //   if (!discountValidationSchema.isValidSync(values)) {
  //     dispatch(showError({ message: "Please fill required fields" }));
  //   } else {
  //   }

  //   setSubmitting(false);
  // };

  const handleBlock = () => {
    setOpenBlock(true);
  };
  const handleBlockClose = () => {
    setOpenBlock(false);
  };

  const handleConfirmDeactivate = () => {
    const discountStatus =
      formik?.values?.status === "in-active" ? "active" : "in-active";
    // formik?.values?.status === "active" ? setDeactivate(true) : setDeactivate(false);
    formik.setFieldValue("status", discountStatus);
    setOpenBlock(false);
    dispatch(
      showSuccess({
        message: `Status changed successfully`,
      })
    );
  };

  // useEffect(() => {
  //   if (formik.isSubmitting && Object.keys(formik.errors).length > 0) {
  //     dispatch(showError({ message: "Please fill required fields" }));
  //   }
  // }, [formik.isSubmitting, formik.errors, dispatch]);

  return (
    <div
      className="page container-fluid position-relative"
      style={{ display: "grid", gridTemplateRows: "auto 1fr" }}
    >
      <InfoHeader
        title={
          formik.values?.discountName ||
          (formik.values?.discountType === "productDiscount"
            ? "Create Product Discount"
            : formik.values?.discountType === "cartDiscount"
            ? "Create Cart Discount"
            : formik.values?.discountType === "freeShipping"
            ? "Create Free Shipping"
            : formik.values?.discountType === "buyxGety"
            ? "Create Buy X Get Y"
            : formik.values?.discountType === "bulk"
            ? "Create Bulk Discount"
            : "")
        }
        onBack={backHandler}
        onPreview={() => {}}
        onPrev={prevPageHandler}
        onNext={nextPageHandler}
        isEdit={false}
        isBlock={
          location.pathname !== "/offers/discounts/create" ? true : false
        }
        onBlock={handleBlock}
        deactivateStatus={formik?.values?.status}
      />
      <form
        className="offers-form"
        noValidate
        onSubmit={formik.handleSubmit}
        style={{ display: "grid", gridTemplateRows: "1fr auto" }}
      >
        <div className="row" style={{ marginBottom: "60px" }}>
          <div className="col-lg-9 mt-4">
            <div className="bg-black-15 border-grey-5 rounded-8 p-3 row attributes">
              {/* <div className="d-flex col-12 px-0 justify-content-between">
                <div className="d-flex align-items-center">
                  <h6 className="text-lightBlue me-auto text-lightBlue fw-500">
                    Discount Info
                  </h6>
                </div>
              </div> */}
              <div className="d-flex justify-content-between ps-0 ">
                <div className="d-flex">
                  <h6 className="text-lightBlue  text-lightBlue fw-500">
                    Discount Info
                  </h6>
                </div>
                {/* <small className="text-blue-2 c-pointer">
                                Connect new domain
                            </small> */}
                <div className="d-flex">
                  <h6 className="text-lightBlue" style={{ fontSize: 14 }}>
                    <span className="text-grey-6" style={{ fontSize: 14 }}>
                      {" "}
                      Discount Type :
                    </span>{" "}
                    {formik?.values?.discountType === "productDiscount"
                      ? "Product Discount"
                      : formik?.values?.discountType === "cartDiscount"
                      ? "Cart Discount"
                      : formik?.values?.discountType === "freeShipping"
                      ? "Free Shipping"
                      : formik?.values?.discountType === "buyxGety"
                      ? "Buy X Get Y"
                      : formik?.values?.discountType === "bulk"
                      ? "Bulk Discount"
                      : ""}
                  </h6>
                  <Tooltip
                    title={
                      formik?.values?.discountType === "productDiscount"
                        ? "Set discounts for specific products, driving sales by promoting items effectively."
                        : formik?.values?.discountType === "cartDiscount"
                        ? "Boost cart values and reduce abandonment with tailored discounts and conditions."
                        : formik?.values?.discountType === "freeShipping"
                        ? "Encourage purchases and increase order values by offering free shipping based on thresholds."
                        : formik?.values?.discountType === "buyxGety"
                        ? "Clear excess inventory and drive sales volume with targeted Buy X Get Y promotions."
                        : formik?.values?.discountType === "bulk"
                        ? "Drive larger orders and manage inventory with tiered discounts on quantity purchases."
                        : ""
                    }
                    placement="top"
                  >
                    <img
                      src={info}
                      alt="info"
                      className="ms-2 c-pointer"
                      width={13.5}
                    />
                  </Tooltip>
                </div>
              </div>
              <hr className="hr-grey-6 mt-3 mb-0" />
              <div className="col-12 px-0">
                <div className="row align-items-start">
                  <div className="col-md-6 mt-3">
                    <div className="d-flex mb-1">
                      <p className="text-lightBlue">Name of the Discount</p>{" "}
                      <span style={{ color: "#F67476", paddingLeft: 4 }}>
                        *
                      </span>
                      <Tooltip title="Lorem ipsum" placement="top">
                        <img
                          src={info}
                          alt="info"
                          className="ms-2 c-pointer"
                          width={13.5}
                        />
                      </Tooltip>
                    </div>
                    <FormControl className="w-100 px-0">
                      <OutlinedInput
                        placeholder="Discount Name"
                        size="small"
                        name="discountName"
                        value={formik.values?.discountName}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        autoFocus={true}
                      />
                    </FormControl>
                    {!!formik.touched.discountName &&
                    formik.errors.discountName ? (
                      <Typography variant="caption" color="#F67476">
                        {formik.errors.discountName}
                      </Typography>
                    ) : (
                      <small className="mt-1 text-grey-6 font1">
                        Note: User can't see this, its for your reference
                      </small>
                    )}
                  </div>
                  {/* <div className="col-md-6 mt-3">
                    <div className="d-flex mb-1">
                      <p className="text-lightBlue">Discount Type</p><span style={{ color: "#F67476", paddingLeft : 4 }}>*</span>
                      <Tooltip title="Lorem ipsum" placement="top">
                        <img
                          src={info}
                          alt="info"
                          className="ms-2 c-pointer"
                          width={13.5}
                        />
                      </Tooltip>
                    </div>
                    <FormControl
                      sx={{ m: 0, minWidth: 120, width: "100%" }}
                      size="small"
                    >
                      <Select
                        labelId="demo-select-small"
                        id="demo-select-small"
                        value={formik.values?.discountType}
                        onChange={(event) => {
                          formik.setFieldValue(
                            "discountType",
                            event.target.value
                          );
                        }}
                        onBlur={formik.handleBlur}
                        name={"discountType"}
                        size="small"
                        displayEmpty
                        // renderValue={
                        //   formik.values?.discountType !== ""
                        //     ? undefined
                        //     : () => (
                        //         <span
                        //           style={{ fontSize: "13px", color: "#5c6d8e" }}
                        //         >
                        //           Select
                        //         </span>
                        //       )
                        // }
                      >
                        <MenuItem
                          value="productDiscount"
                          sx={{ fontSize: 13, color: "#5c6d8e" }}
                        >
                          Product Discount
                        </MenuItem>
                        <MenuItem
                          value="cartDiscount"
                          sx={{ fontSize: 13, color: "#5c6d8e" }}
                        >
                          Cart Discount
                        </MenuItem>
                        <MenuItem
                          value="freeShipping"
                          sx={{ fontSize: 13, color: "#5c6d8e" }}
                        >
                          Free Shipping
                        </MenuItem>
                        <MenuItem
                          value="buyxGety"
                          sx={{ fontSize: 13, color: "#5c6d8e" }}
                        >
                          Buy X, Get Y
                        </MenuItem>
                        <MenuItem
                          value="bulk"
                          sx={{ fontSize: 13, color: "#5c6d8e" }}
                        >
                          Bulk/Tiered Discount Pricing
                        </MenuItem>
                      </Select>
                    </FormControl>
                    {!!formik.touched.discountType &&
                    formik.errors.discountType ? (
                      <Typography variant="caption" color="#F67476">
                        {formik.errors.discountType}
                      </Typography>
                    ) : null}
                  </div> */}
                </div>
              </div>
            </div>
            <AlertDialog
              onConfirm={handleConfirmDeactivate}
              onCancel={handleBlockClose}
              show={openBlock}
              title={
                formik?.values?.status === "active" ? "Deactivate" : "Activate"
              }
              primaryMessage={
                formik?.values?.status === "active"
                  ? "Are you sure you want to Deactivate ?"
                  : "Are you sure you want to Activate ?"
              }
              // confirmText = {formik?.values?.status === "active" ? "Deactivate" : "Activate"}
              deactivateStatus={formik?.values?.status}
              icon={block}
            />
            <DiscountFormat
              value={formik.values?.discountFormat}
              field="discountFormat"
              formik={formik}
              touched={formik?.touched?.discountFormat}
              error={formik?.errors?.discountFormat}
            />
            {(formik?.values?.discountType === "productDiscount" ||
              formik?.values?.discountType === "cartDiscount") && (
              <DiscountValue
                value={formik.values?.discountValue}
                field="discountValue"
                formik={formik}
                touched={formik?.touched?.discountValue}
                error={formik?.errors?.discountValue}
                priceClippingRequirement={
                  formik.values.priceClippingRequirement
                }
                priceClippingRequirementError={
                  formik.errors.priceClippingRequirement
                }
                priceClippingRequirementTouched={
                  formik.touched.priceClippingRequirement
                }
              />
            )}
            {/* {formik?.values?.discountType === "bulk" && (
              <DiscountRange
                value={formik.values?.discountRange}
                field="discountRange"
                formik={formik}
                touched={formik?.touched?.discountRange}
                error={formik?.errors?.discountRange}
                data={formik.values?.discountRange}
                onSort={() => {}}
                onDeleteField={deleteDiscountRangeHandler}
                onAdd={addDiscountRangeHandler}
              />
            )} */}
            {formik?.values?.discountType !== "buyxGety" &&
              formik?.values?.discountType !== "cartDiscount" && (
                <Filters
                  value={formik.values?.filters}
                  field="filters"
                  formik={formik}
                  touched={formik?.touched?.filters}
                  error={formik?.errors?.filters}
                  data={formik.values?.filters}
                  onSort={() => {}}
                  onDeleteField={deleteFilterHandler}
                  onAdd={addFilterHandler}
                />
              )}
            {formik?.values?.discountType === "freeShipping" && (
              <LimitByLocation
                value={formik.values?.limitByLocation}
                field="limitByLocation"
                formik={formik}
                touched={formik?.touched?.limitByLocation}
                error={formik?.errors?.limitByLocation}
              />
            )}
            {formik?.values?.discountType === "buyxGety" && (
              <BuyXGetY
                value={formik.values?.buyXGetY}
                field="buyXGetY"
                formik={formik}
                touched={formik?.touched?.buyXGetY}
                error={formik?.errors?.buyXGetY}
              />
            )}

            <MinimumRequirement
              value={formik.values?.minimumRequirement}
              field="minimumRequirement"
              formik={formik}
              touched={formik?.touched?.minimumRequirement}
              error={formik?.errors?.minimumRequirement}
            />
            <CustomerEligibility
              value={formik.values?.customerEligibility}
              field="customerEligibility"
              formik={formik}
              touched={formik?.touched?.customerEligibility}
              error={formik?.errors?.customerEligibility}
            />
            <ReturnAndExchangeCondition
              sectionHeading={"Return & Exchange Condition"}
              value={formik.values?.returnExchange}
              field="returnExchange"
              formik={formik}
            />
            <MaximumDiscountUsers
              value={formik.values?.maximumDiscount}
              field="maximumDiscount"
              formik={formik}
              touched={formik?.touched?.maximumDiscount}
              error={formik?.errors?.maximumDiscount}
            />
            <DiscountCombination
              value={formik.values?.discountCombination}
              field="discountCombination"
              formik={formik}
              touched={formik?.touched?.discountCombination}
              error={formik?.errors?.discountCombination}
            />
            <ScheduleDiscountCode
              value={formik.values?.scheduledDiscount}
              field="scheduledDiscount"
              formik={formik}
              touched={formik?.touched?.scheduledDiscount}
              error={formik?.errors?.scheduledDiscount}
              deactivate={formik?.values?.status === "active" ? false : true}
            />
            {/* <CouponCode
              value={formik.values?.couponCode}
              field="couponCode"
              formik={formik}
              touched={formik?.touched?.couponCode}
              error={formik?.errors?.couponCode}
            /> */}
          </div>
          <div className="col-lg-3 mt-4 pe-0 ps-0 ps-lg-3">
            <div className="bg-black-15 border-grey-5 rounded-8 p-3">
              <div className="d-flex justify-content-between">
                <small className="text-grey-6">Summary</small>
                <div
                  className="rounded-pill d-flex px-2 py-1 c-pointer ms-3"
                  style={{
                    background:
                      formik?.values?.status === "active"
                        ? "#A6FAAF"
                        : formik?.values?.status === "in-active"
                        ? "#F67476"
                        : formik?.values?.status === "scheduled"
                        ? "#FEE1A3"
                        : "#C8D8FF",
                    cursor: "context-menu",
                  }}
                >
                  <small className="text-black fw-400">
                    {formik?.values?.status === "active" ? "Active" : "Expired"}
                  </small>
                </div>
              </div>
              <div className="d-flex align-items-center mt-1">
                <h6 className="text-lightBlue fw-500">
                  {formik.values?.discountName}
                </h6>
                {/* <div className="rounded-pill d-flex table-status px-2 py-1 c-pointer ms-3">
                  <small className="text-black fw-400">Active</small>
                </div> */}
              </div>

              <hr className="hr-grey-6 my-3" />
              <small className="text-grey-6">
                {formik.values?.discountType === "productDiscount"
                  ? "Product Discount"
                  : formik.values?.discountType === "cartDiscount"
                  ? "Cart Discount"
                  : formik.values?.discountType === "freeShipping"
                  ? "Free Shipping"
                  : formik.values?.discountType === "buyxGety"
                  ? "Buy X Get Y"
                  : formik.values?.discountType === "bulk"
                  ? "Bulk Discount"
                  : ""}
              </small>

              {formik.values?.discountFormat?.discountCode && (
                <div className="d-flex align-items-center mt-1">
                  {formik.values?.discountFormat?.discountFormat === "code" ? (
                    <small className="text-blue-2 fw-500">
                      • Code&nbsp;&nbsp;|
                    </small>
                  ) : (
                    <small className="text-blue-2 fw-500">
                      • Automatic&nbsp;&nbsp;|
                    </small>
                  )}
                  {formik.values?.discountFormat?.discountCode ? (
                    <>
                      <h6
                        className="fw-500 ms-2 me-2 text-lightBlue"
                        style={{ overflowWrap: "anywhere" }}
                      >
                        {formik.values?.discountFormat?.discountCode}
                      </h6>
                      {formik.values?.discountFormat?.discountFormat ===
                        "code" && (
                        <CopyToClipboard
                          text={formik.values?.discountFormat?.discountCode}
                          onCopy={handleCopy}
                        >
                          <Tooltip
                            title={copied ? "Copied to clipboard" : "Copy"}
                            placement="top"
                          >
                            <ContentCopyIcon
                              sx={{
                                color: "#5c6d8e",
                                fontSize: 12,
                                cursor: "pointer",
                              }}
                            />
                          </Tooltip>
                        </CopyToClipboard>
                      )}
                    </>
                  ) : null}
                </div>
              )}

              {formik?.values?.discountType === "bulk" && (
                <React.Fragment>
                  <hr className="hr-grey-6 my-3" />
                  <small className="text-grey-6">Discount</small>
                  {formik?.values?.discountRange[0]?.minQty &&
                    formik?.values?.discountRange[0]?.maxQty &&
                    formik?.values?.discountRange[0]?.value &&
                    formik?.values?.discountRange[0]?.discountOn && (
                      <div className="d-flex flex-column align-items-start mt-1">
                        {formik?.values?.discountRange.map((item, index) => (
                          <small key={index} className="text-blue-2 fw-500">
                            • {item?.minQty} - {item?.maxQty} qty {item?.value}{" "}
                            {item?.unit === "percentage" ? `%` : `Rs`} Discount
                            on{" "}
                            {item?.discountOn === "diamondPrice"
                              ? `Diamond Price`
                              : item?.discountOn === "goldPrice"
                              ? `Gold Price`
                              : item?.discountOn === "makingCharge"
                              ? `Making Charges`
                              : item?.discountOn === "totalPrice"
                              ? `Total Product Price`
                              : ""}
                          </small>
                        ))}
                      </div>
                    )}
                </React.Fragment>
              )}

              {formik?.values?.discountType !== "buyxGety" &&
                formik?.values?.discountType !== "bulk" &&
                formik?.values?.discountType !== "freeShipping" && (
                  <React.Fragment>
                    <hr className="hr-grey-6 my-3" />
                    <small className="text-grey-6">Discount</small>
                    {formik?.values.discountValue?.discountValue &&
                      formik?.values.discountValue?.value && (
                        <div className="d-flex flex-column align-items-start mt-1">
                          <small className="text-blue-2 fw-500">
                            •{" "}
                            {formik?.values.discountValue?.discountValue ||
                              formik?.values?.discountRange?.discountValue}{" "}
                            {formik?.values.discountValue?.type === "percentage"
                              ? `%`
                              : `Rs`}{" "}
                            off on{" "}
                            {formik?.values.discountValue?.value ===
                            "diamondPrice"
                              ? `Diamond Price`
                              : formik?.values.discountValue?.value ===
                                "goldPrice"
                              ? `Gold Price`
                              : formik?.values.discountValue?.value ===
                                "makingCharge"
                              ? `Making Charges`
                              : formik?.values.discountValue?.value ===
                                "totalPrice"
                              ? `Total Product Price`
                              : ""}
                          </small>
                          {formik?.values?.discountType === "cartDiscount" && (
                            <small className="text-blue-2 fw-500">
                              • Cart Label :{" "}
                              {formik?.values?.discountValue?.cartLabel}
                            </small>
                          )}
                        </div>
                      )}
                  </React.Fragment>
                )}

              {formik?.values?.discountType !== "buyxGety" && (
                <React.Fragment>
                  <hr className="hr-grey-6 my-3" />
                  <small className="text-grey-6">Filters</small>

                  {formik?.values?.filters[0].type &&
                    {
                      /* formik?.values?.filters[0].operator &&
                    formik?.values?.filters[0].value.length > 0  */
                    } && (
                      <div className="d-flex flex-column align-items-start mt-1">
                        <small className="text-blue-2 fw-500 d-block">
                          Discount applies to{" "}
                        </small>
                        {formik?.values?.filters[0].type &&
                          {
                            /* formik?.values?.filters[0].operator &&
                          formik?.values?.filters[0].value  */
                          } &&
                          formik?.values?.filters?.map((item, index) =>
                            item?.type !== "allProducts" ? (
                              <small className="text-blue-2 fw-500" key={index}>
                                <ul
                                  style={{ marginBottom: 1, paddingLeft: 16 }}
                                >
                                  <li>
                                    {item?.type === "category"
                                      ? "Category"
                                      : item?.type === "collection"
                                      ? "Collection"
                                      : item?.type === "vendors"
                                      ? "Vendors"
                                      : item?.type === "subCategory"
                                      ? "Sub-Category"
                                      : item?.type === "Tags"
                                      ? "Tags"
                                      : item?.type === "products"
                                      ? "Products"
                                      : ""}{" "}
                                    {item?.operator === "equalTo"
                                      ? "Equal to"
                                      : item?.operator === "notEqualTo"
                                      ? "Not Equal To"
                                      : ""}{" "}
                                    {Array.isArray(item?.value)
                                      ? item?.value
                                          ?.map(
                                            (valueItem) =>
                                              valueItem?.name ||
                                              valueItem?.title
                                          )
                                          .join(", ")
                                      : ""}
                                  </li>
                                </ul>
                              </small>
                            ) : (
                              <small className="text-blue-2 fw-500" key={index}>
                                <ul
                                  style={{ marginBottom: 1, paddingLeft: 16 }}
                                >
                                  <li>All Products</li>
                                </ul>
                              </small>
                            )
                          )}
                      </div>
                    )}
                </React.Fragment>
              )}

              {formik?.values?.discountType === "freeShipping" && (
                <React.Fragment>
                  <hr className="hr-grey-6 my-3" />
                  <small className="text-grey-6">Limit Location</small>
                  <small className="text-blue-2 fw-500">
                    <ul style={{ marginBottom: 1, paddingLeft: 16 }}>
                      {formik?.values?.limitByLocation?.locationType ===
                      "none" ? (
                        <li>Don't Limit</li>
                      ) : formik?.values?.limitByLocation?.locationType ===
                          "country" &&
                        formik?.values?.limitByLocation?.location.length > 0 ? (
                        <li>
                          Country :{" "}
                          {formik?.values?.limitByLocation?.location
                            .map((item) => item?.name)
                            .join(" ,")}
                        </li>
                      ) : formik?.values?.limitByLocation?.locationType ===
                          "state" &&
                        formik?.values?.limitByLocation?.location.length > 0 ? (
                        <li>
                          State :{" "}
                          {formik?.values?.limitByLocation?.location
                            .map((item) => item?.name)
                            .join(" ,")}
                        </li>
                      ) : formik?.values?.limitByLocation?.locationType ===
                          "zip" &&
                        formik?.values?.limitByLocation?.location.length > 0 ? (
                        <li>
                          Zip :{" "}
                          {formik?.values?.limitByLocation?.location
                            .map((item) => item?.name)
                            .join(" ,")}
                        </li>
                      ) : (
                        ""
                      )}
                    </ul>
                  </small>
                </React.Fragment>
              )}

              {formik?.values?.discountType === "buyxGety" && (
                <React.Fragment>
                  <hr className="hr-grey-6 my-3" />
                  <small className="text-grey-6">Buy X Get Y</small>
                  <div className="d-flex mt-1 flex-column">
                    {formik?.values?.buyXGetY?.buy &&
                      formik?.values?.buyXGetY?.selectBuyItem &&
                      formik?.values?.buyXGetY?.buyProduct.length > 0 && (
                        <>
                          <small className="text-blue-2 fw-500 d-block">
                            Buy
                          </small>
                          <small className="text-blue-2 fw-500">
                            <ul style={{ marginBottom: 1, paddingLeft: 16 }}>
                              <li>
                                {formik?.values?.buyXGetY?.buy} qty from{" "}
                                {formik?.values?.buyXGetY?.selectBuyItem}{" "}
                                {formik?.values?.buyXGetY?.buyProduct
                                  ?.map(
                                    (item) =>
                                      item?.name ||
                                      item?.title ||
                                      item?.firstName
                                  )
                                  .join(" ,")}
                              </li>
                            </ul>
                          </small>
                        </>
                      )}

                    {formik?.values?.buyXGetY?.get &&
                      formik?.values?.buyXGetY?.selectGetItem &&
                      formik?.values?.buyXGetY?.getProduct.length > 0 && (
                        <>
                          <small className="text-blue-2 fw-500 d-block">
                            Get
                          </small>
                          <small className="text-blue-2 fw-500">
                            <ul style={{ marginBottom: 1, paddingLeft: 16 }}>
                              <li>
                                {formik?.values?.buyXGetY?.get} qty from{" "}
                                {formik?.values?.buyXGetY?.selectGetItem}{" "}
                                {formik?.values?.buyXGetY?.getProduct
                                  ?.map(
                                    (item) =>
                                      item?.name ||
                                      item?.title ||
                                      item?.firstName
                                  )
                                  .join(" ,")}
                              </li>
                            </ul>
                          </small>
                        </>
                      )}

                    {formik?.values?.buyXGetY?.discountLabelType === "free" ? (
                      <>
                        <small className="text-blue-2 fw-500 d-block">
                          For
                        </small>
                        <small className="text-blue-2 fw-500 d-block">
                          <ul style={{ marginBottom: 1, paddingLeft: 16 }}>
                            <li>Free</li>
                          </ul>
                        </small>
                      </>
                    ) : (
                      formik?.values?.buyXGetY?.discountValue &&
                      formik?.values?.buyXGetY?.value && (
                        <>
                          <small className="text-blue-2 fw-500 d-block">
                            For
                          </small>
                          <small className="text-blue-2 fw-500 d-block">
                            <ul style={{ marginBottom: 1, paddingLeft: 16 }}>
                              <li>
                                {formik?.values?.buyXGetY?.discountValue}{" "}
                                {formik?.values?.buyXGetY?.unit === "percentage"
                                  ? `%`
                                  : `Rs`}{" "}
                                on{" "}
                                {formik?.values?.buyXGetY?.value ===
                                "diamondPrice"
                                  ? `Diamond Price`
                                  : formik?.values?.buyXGetY?.value ===
                                    "goldPrice"
                                  ? `Gold Price`
                                  : formik?.values?.buyXGetY?.value ===
                                    "makingCharge"
                                  ? `Making Charges`
                                  : formik?.values?.buyXGetY?.value ===
                                    "totalPrice"
                                  ? `Total Product Price`
                                  : ""}
                              </li>
                            </ul>
                          </small>
                        </>
                      )
                    )}
                  </div>
                </React.Fragment>
              )}

              <hr className="hr-grey-6 my-3" />
              <small className="text-grey-6">Condition</small>
              <div className="d-flex mt-1 flex-column">
                <small className="text-blue-2 fw-500 d-block">
                  Apply Discount only if
                </small>
                <small className="text-blue-2 fw-500">
                  <ul style={{ marginBottom: 1, paddingLeft: 16 }}>
                    {formik?.values?.minimumRequirement?.requirement ===
                    "amount" ? (
                      <li>
                        Minimum Amount is equal to ₹{" "}
                        {formik?.values?.minimumRequirement?.value}{" "}
                      </li>
                    ) : formik?.values?.minimumRequirement?.requirement ===
                      "quantity" ? (
                      <li>
                        Minimum Quantity is equal to{" "}
                        {formik?.values?.minimumRequirement?.value}{" "}
                      </li>
                    ) : (
                      <li> Unlimited uses</li>
                    )}

                    {formik?.values?.customerEligibility?.customer ===
                      "allCustomers" && <li>It includes all customers</li>}
                    {formik?.values?.customerEligibility?.customer ===
                      "customerGroups" &&
                      formik?.values?.customerEligibility?.customerGroups
                        ?.length > 0 && (
                        <li>
                          Customer Group is equal to{" "}
                          {formik?.values?.customerEligibility?.customerGroups
                            .map((item, index) => item?.name)
                            .join(", ")}
                        </li>
                      )}
                    {formik?.values?.customerEligibility?.customer ===
                      "specificCustomers" &&
                      formik?.values?.customerEligibility?.specificCustomers
                        ?.length > 0 && (
                        <li>
                          Customer name is equal to{" "}
                          {formik?.values?.customerEligibility?.specificCustomers
                            .map((item, index) => item?.firstName)
                            .join(", ")}
                        </li>
                      )}
                  </ul>
                </small>
              </div>

              <hr className="hr-grey-6 my-3" />
              <small className="text-grey-6">Combine Discounts</small>
              <div className="d-flex mt-1 flex-column">
                {formik?.values?.discountCombination?.allowCombineWithOthers ? (
                  <small className="text-blue-2 fw-500 d-block">
                    Discount is combined with
                  </small>
                ) : (
                  <small className="text-blue-2 fw-500 d-block">
                    <ul style={{ marginBottom: 1, paddingLeft: 16 }}>
                      <li>No Combination</li>
                    </ul>
                  </small>
                )}
                <small className="text-blue-2 fw-500">
                  <ul style={{ marginBottom: 1, paddingLeft: 16 }}>
                    {formik?.values?.discountCombination?.allowCombineWith?.map(
                      (item) => (
                        <li>
                          {" "}
                          {item === "productDiscount"
                            ? "Product Discount"
                            : item === "cartDiscount"
                            ? "Cart Discount"
                            : item === "freeShipping"
                            ? "Free Shipping"
                            : item === "buyxGety"
                            ? "Buy X Get Y"
                            : item === "bulk"
                            ? "Bulk Discount"
                            : ""}
                        </li>
                      )
                    )}
                  </ul>
                </small>
              </div>

              {/* <div className="d-flex mt-1 flex-column">
                {formik?.values?.minimumRequirement?.requirement ===
                "amount" ? (
                  <>
                    <small className="text-blue-2 fw-500 d-block">
                      Apply Discount only to
                    </small>
                    <small className="text-blue-2 fw-500 ps-2 d-block mt-1">
                      • Minimum Amount is equal to ₹{" "}
                      {formik?.values?.minimumRequirement?.value}
                    </small>
                  </>
                ) : formik?.values?.minimumRequirement?.requirement ===
                  "quantity" ? (
                  <>
                    <small className="text-blue-2 fw-500 d-block">
                      Apply Discount only if
                    </small>
                    <small className="text-blue-2 fw-500 ps-2 d-block mt-1">
                      • Minimum Quantity is equal to{" "}
                      {formik?.values?.minimumRequirement?.value}
                    </small>
                  </>
                ) : (
                  <small className="text-blue-2 fw-500 ps-2 d-block mt-1">
                    • Unlimited uses
                  </small>
                )}
              </div> */}
              <hr className="hr-grey-6 my-3" />
              <small className="text-grey-6">Details</small>
              <div className="d-flex mt-1 flex-column">
                <small className="text-blue-2 fw-500 d-block">
                  <ul style={{ marginBottom: 1, paddingLeft: 16 }}>
                    <li>
                      Returns & Exchange{" "}
                      {formik?.values?.returnExchange === "allowed"
                        ? `Allowed`
                        : `Not Allowed`}
                    </li>
                  </ul>
                </small>

                {formik?.values?.scheduledDiscount?.startDateTime &&
                formik?.values?.scheduledDiscount?.endDateTime ? (
                  <small className="text-blue-2 fw-500 d-block mt-1">
                    <ul style={{ marginBottom: 1, paddingLeft: 16 }}>
                      <li>
                        Activated{" "}
                        {moment(
                          formik?.values?.scheduledDiscount?.startDateTime
                        ).format("Do MMMM, YYYY")}
                        , ends{" "}
                        {moment(
                          formik?.values?.scheduledDiscount?.endDateTime
                        ).format("Do MMMM, YYYY")}
                      </li>
                    </ul>
                  </small>
                ) : formik?.values?.scheduledDiscount?.startDateTime &&
                  formik?.values?.scheduledDiscount?.neverExpire ? (
                  <small className="text-blue-2 fw-500 d-block mt-1">
                    <ul style={{ marginBottom: 1, paddingLeft: 16 }}>
                      <li>
                        Activated{" "}
                        {moment(
                          formik?.values?.scheduledDiscount?.startDateTime
                        ).format("Do MMMM, YYYY")}
                        , Never Expires
                      </li>
                    </ul>
                  </small>
                ) : formik?.values?.scheduledDiscount?.startDateTime ? (
                  <small className="text-blue-2 fw-500 d-block mt-1">
                    <ul style={{ marginBottom: 1, paddingLeft: 16 }}>
                      <li>
                        Activated{" "}
                        {moment(
                          formik?.values?.scheduledDiscount?.startDateTime
                        ).format("Do MMMM, YYYY")}
                      </li>
                    </ul>
                  </small>
                ) : (
                  ""
                )}

                {formik?.values?.maximumDiscount.limitDiscountNumber &&
                formik?.values?.maximumDiscount.limitUsagePerCustomer ? (
                  <>
                    <small className="text-blue-2 fw-500 d-block  mt-1">
                      <ul style={{ marginBottom: 1, paddingLeft: 16 }}>
                        <li>
                          Total discount usage value is{" "}
                          {formik?.values?.maximumDiscount.total}
                        </li>
                      </ul>
                    </small>
                    <small className="text-blue-2 fw-500 d-block  mt-1">
                      <ul style={{ marginBottom: 1, paddingLeft: 16 }}>
                        <li>
                          Discount usage limit per customer is{" "}
                          {formik?.values?.maximumDiscount.perCustomer} times
                        </li>
                      </ul>
                    </small>
                  </>
                ) : formik?.values?.maximumDiscount.limitDiscountNumber ? (
                  <small className="text-blue-2 fw-500 d-block  mt-1">
                    <ul style={{ marginBottom: 1, paddingLeft: 16 }}>
                      <li>
                        Total discount usage value is{" "}
                        {formik?.values?.maximumDiscount.total}
                      </li>
                    </ul>
                  </small>
                ) : formik?.values?.maximumDiscount.limitUsagePerCustomer ? (
                  <small className="text-blue-2 fw-500 d-block  mt-1">
                    <ul style={{ marginBottom: 1, paddingLeft: 16 }}>
                      <li>
                        Discount usage limit per customer is times
                        {formik?.values?.maximumDiscount.perCustomer}
                      </li>
                    </ul>
                  </small>
                ) : (
                  <small className="text-blue-2 fw-500 d-block mt-1">
                    <ul style={{ marginBottom: 1, paddingLeft: 16 }}>
                      <li>Unlimited usage</li>
                    </ul>
                  </small>
                )}
              </div>
            </div>
          </div>
        </div>
        <SaveFooterTertiary
          show={id ? discountsState.isEditing : true}
          onDiscard={backHandler}
          isLoading={createDiscountIsLoading || editDicountIsLoading}
        />
        <DiscardModalSecondary
          when={discountsState.edited}
          message={`${formik?.values?.discountName} offer`}
        />
      </form>
    </div>
  );
};

export default CreateDiscount;
