export function getLabelsBadgesStatusColor(status) {
  switch (status) {
    case "active":
      return "#A6FAAF";
    case "scheduled":
      return "#FEE1A3";
    case "in-active":
      return "#F67476";
    default:
      return "inherit";
  }
}
export function getLabelsBadgesStatusFromTabIndex(ix) {
  switch (ix) {
    case 0:
      return "";
    case 1:
      return "active";
    case 2:
      // return "draft";
      return "in-active";
    default:
      return "";
  }
}
