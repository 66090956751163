import { forwardRef, useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Radio, RadioGroup, Slide } from "@mui/material";
import archiveIcon from "../../../assets/images/Components/Archived.png";
import cancel from "../../../assets/icons/cancel.svg";
import { getReviewStatusColor } from "./utils";
import { LoadingButton } from "@mui/lab";

const Transition = forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="up"
      ref={ref}
      {...props}
    />
  );
});

export default function UnArchivedModal({
  isOpen = false,
  isLoading = false,
  statuses = [""],
  title = "",
  message = "",
  buttonText = "",
  onClose = () => {},
  onAction = () => {},
}) {
  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={isOpen}
      onClose={onClose}
      TransitionComponent={Transition}>
      <Content
        isLoading={isLoading}
        statuses={statuses}
        title={title}
        message={message}
        buttonText={buttonText}
        onAction={onAction}
        onClose={onClose}
      />
    </Dialog>
  );
}

function Content({
  isLoading = false,
  statuses = [""],
  title = "",
  message = "",
  buttonText = "",
  onClose = () => {},
  onAction = () => {},
}) {
  const [status, setStatus] = useState(statuses[0]);

  return (
    <>
      <DialogTitle>
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex flex-column ">
            <h4 className="text-lightBlue fw-500">{title}</h4>
          </div>
          <button
            className="reset"
            onClick={onClose}>
            <img
              src={cancel}
              alt="cancel"
              width={30}
              className="c-pointer"
            />
          </button>
        </div>
      </DialogTitle>
      <hr className="hr-grey-6 my-0" />

      <DialogContent className="pt-3 pb-0 px-4">
        <div className="d-flex align-items-center">
          <img
            src={archiveIcon}
            alt="icon"
            style={{ width: "80px", height: "80px" }}
          />
          <div>
            <p
              style={{ fontSize: "16px" }}
              className="text-grey-6 mx-4">
              Before un-archiving {message},
            </p>
            <p
              style={{ fontSize: "16px" }}
              className="text-grey-6 mx-4">
              Please choose a status
            </p>
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <RadioGroup
            row
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            className="d-flex justify-content-between px-4 py-3">
            {statuses.map((st) => (
              <FormControlLabel
                key={st}
                value={st}
                label={st}
                className="text-lightBlue text-capitalize"
                control={<Radio size="small" />}
                // sx={{ color: getReviewStatusColor(st) }}
              />
            ))}
          </RadioGroup>
        </div>
      </DialogContent>

      <DialogActions className="d-flex justify-content-end px-4 py-3">
        <LoadingButton
          loading={isLoading}
          disabled={isLoading}
          onClick={() => onAction(status)}
          className="button-red-outline py-2 px-4"
          style={{
            lineHeight: 1.6,
            textTransform: "Capitalize",
          }}>
          <p>{buttonText}</p>
        </LoadingButton>
        <span className="pe-2" />
        <button
          onClick={onClose}
          className="button-grey-outline py-2 px-4"
          style={{ lineHeight: 1.6 }}>
          <p className="text-lightBlue">Cancel</p>
        </button>
      </DialogActions>
    </>
  );
}
