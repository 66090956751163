import { useState } from "react";
import PopUpBox from "../../../components/PopUp/PopUpBox";
import EnterPass from "./EnterPass";
import question from "../../../assets/profile/Question.svg";

const CloseProfile = ({ onConfirm, show }) => {
    const [showEnterPass, setEnterPass] = useState(false);

    const handleEnterPass = () => {
        setEnterPass(true);
    }

    const closeEnterPass = () => {
        setEnterPass(false);
    }

    return (
        <PopUpBox
            show={show}
            onConfirm={onConfirm}
            image={question}
        >
            <h4 className="d-flex justify-content-center text-lightBlue fw-600 me-2">
                Are you sure to close your account?
            </h4>

            <div className="d-flex justify-content-center px-3">
                <small className="text-grey-6 mt-2 d-block py-4 px-5">
                    Your free trial has now expired. To continue using our platform, you'll need to upgrade to one of our paid plans. Our paid plans offer more advanced features, increased storage, and priority support from our team.
                </small>
            </div>
            <div className="d-flex justify-content-center">
                <div className="button-gradient py-2 px-5 ms-3" onClick={onConfirm}>
                    <p>Cancel</p>
                </div>
            </div>
            <div className="d-flex justify-content-center">
                <p className="text-lightBlue py-4 c-pointer" onClick={handleEnterPass}>
                    {" "}Continue
                </p>
            </div>
            <EnterPass show={showEnterPass} onConfirm={closeEnterPass}/>
        </PopUpBox>
    );
};

export default CloseProfile;