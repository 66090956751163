import cancel from "../../../../assets/icons/cancel.svg";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  MenuItem,
  Select,
  Slide,
} from "@mui/material";
import { useState, forwardRef } from "react";
import {
  useGetAllOptionSetListQuery,
  useGetAllOptionSetsQuery,
} from "../../../../features/parameters/options/optionSetsApiSlice";

const Transition = forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="up"
      ref={ref}
      {...props}
    />
  );
});

export default function ImportOptionSetsDialog({
  show = false,
  onAction = () => undefined,
  onClose = () => undefined,
}) {
  const { data: optionSetsData, isLoading: optionSetsIsLoading } =
    useGetAllOptionSetListQuery();
  const optionSets = optionSetsData?.data ?? [];
  const [selected, setSelected] = useState("");
  const { data: optionSetData } = useGetAllOptionSetsQuery(
    { id: selected },
    { skip: !selected }
  );
  const optionSet = optionSetData?.data?.[0] ?? null;

  const onImport = () => {
    onAction(optionSet);
    onClose();
  };

  return (
    <Dialog
      open={show}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      maxWidth="sm"
      fullWidth>
      <DialogTitle>
        <div className="d-flex justify-content-between align-items-center">
          <h5 className="text-lightBlue fw-500">Import Option Sets</h5>
          <img
            src={cancel}
            alt="cancel"
            width={30}
            onClick={onClose}
            className="c-pointer"
          />
        </div>
      </DialogTitle>
      <hr className="hr-grey-6 my-0" />
      <DialogContent className="py-3 px-4">
        <p className="text-lightBlue mb-2">Select Option Sets</p>
        <div className="row mx-0">
          <FormControl
            sx={{
              m: 0,
              minWidth: 120,
            }}
            size="small"
            className="col-md-6">
            <Select
              labelId="demo-select-small"
              id="demo-select-small"
              value={selected}
              onChange={(e) => setSelected(e.target.value)}
              size="small">
              {optionSets.map((ops) => (
                <MenuItem
                  value={ops._id}
                  key={ops._id}
                  sx={{ fontSize: 13, color: "#5c6d8e" }}>
                  {ops.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </DialogContent>
      <hr className="hr-grey-6 my-0" />
      <DialogActions className="d-flex justify-content-between px-4 py-3">
        <button
          className="button-grey py-2 px-5"
          onClick={onClose}>
          <p className="text-lightBlue">Cancel</p>
        </button>
        <button
          className="button-gradient py-2 px-4"
          onClick={onImport}
          disabled={optionSetsIsLoading || !selected}>
          <p>Import</p>
        </button>
      </DialogActions>
    </Dialog>
  );
}
