import React, { useReducer, useState } from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputBase,
  MenuItem,
  OutlinedInput,
  Popover,
  Radio,
  RadioGroup,
  Select,
  SwipeableDrawer,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  FormLabel,
  FormGroup,
  FormHelperText,
} from "@mui/material";
import {
  EnhancedTableHead,
  getComparator,
  stableSort,
} from "../../../components/TableDependencies/TableDependencies";
import styled from "@emotion/styled";
import SearchIcon from "@mui/icons-material/Search";
import { useFormik } from "formik";
import cancel from "../../../assets/icons/cancel.svg";
import ringSmall from "../../../assets/images/ringSmall.svg";
import deleteButton from "../../../assets/icons/deleteButton.svg";
import arrowDown from "../../../assets/icons/arrowDown.svg";
import { useGetAllProductsQuery } from "../../../features/products/product/productApiSlice";
import featureUpload from "../../../assets/images/products/featureUpload.svg";
import { TableSearchSecondary } from "../../../components/TableSearch/TableSearch";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  "&:hover": {},
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    width: "auto",
  },
  height: "30.6px",
  border: "1px solid #38395c",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 1.5),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(0.8, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    borderRadius: "5px",
  },
}));

function createData(pId, productName, category, price) {
  return { pId, productName, category, price };
}

const rows = [
  createData(1, "The Fringe Diamond Ring", "Gold Products", "₹ 20,600 - ₹ 50,000"),
  createData(2, "The Fringe Diamond Ring", "Gold Products", "₹ 20,600 - ₹ 50,000"),
  createData(3, "The Fringe Diamond Ring", "Gold Products", "₹ 20,600 - ₹ 50,000"),
  createData(4, "The Fringe Diamond Ring", "Gold Products", "₹ 20,600 - ₹ 50,000"),
  createData(5, "The Fringe Diamond Ring", "Gold Products", "₹ 20,600 - ₹ 50,000"),
];

const drawerHeadCells = [
  {
    id: "productName",
    numeric: false,
    disablePadding: true,
    label: "Product Name",
  },
  {
    id: "category",
    numeric: false,
    disablePadding: false,
    label: "Category",
  },
  {
    id: "price",
    numeric: false,
    disablePadding: false,
    label: "Price",
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: "Action",
  },
];

function createLikeProductData(pId, productName, category, price) {
  return { pId, productName, category, price };
}

const likeHeadCells = [
  {
    id: "productName",
    numeric: false,
    disablePadding: true,
    label: "Product Name",
  },
  {
    id: "category",
    numeric: false,
    disablePadding: false,
    label: "Category",
  },
  {
    id: "price",
    numeric: false,
    disablePadding: false,
    label: "Price",
  },
];

const likeProductRows = [
  createLikeProductData(1, "The Fringe Diamond Ring", "Gold Products", "₹ 25,000"),
  createLikeProductData(2, "Fringe Diamond Ring", "Gold Products", "₹ 25,000"),
  createLikeProductData(3, "The Fringe Diamond Ring", "Gold Products", "₹ 25,000"),
];

const initialQueryFilterState = {
  pageSize: 5,
  pageNo: 1,
  title: "",
  searchValue: "",
};
const queryFilterReducer = (state, action) => {
  if (action.type === "SET_PAGE_SIZE") {
    return {
      ...state,
      pageNo: initialQueryFilterState.pageNo,
      pageSize: +action.value,
    };
  }
  if (action.type === "CHANGE_PAGE") {
    return {
      ...state,
      pageNo: action.pageNo + 1,
    };
  }
  if (action.type === "SEARCH") {
    return {
      ...state,
      pageNo: initialQueryFilterState.pageNo,
      title: action.title,
    };
  }
  if (action.type === "SET_SEARCH_VALUE") {
    return {
      ...state,
      pageNo: initialQueryFilterState.pageNo,
      searchValue: action.searchValue,
    };
  }
  return initialQueryFilterState;
};

const AddProducts = ({ productsList, formik, field, id, typeValue, typeField }) => {
  const [queryFilterState, dispatchQueryFilter] = useReducer(
    queryFilterReducer,
    initialQueryFilterState
  );
  const [showConditionBox, setShowConditionBox] = useState(false);
  const [selected, setSelected] = useState([]);
  const [anchorPriceEl, setAnchorPriceEl] = useState(null);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("productName");
  const [addProductDrawer, setAddProductDrawer] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const {
    data: productsData,
    isLoading: productsIsLoading,
    isSuccess: productsIsSuccess,
    error: productsError,
  } = useGetAllProductsQuery(queryFilterState);

  const productsFormik = useFormik({
    initialValues: {
      type: typeValue || "automated",
      condition: "all",
      field: "",
      operator: "",
      value: "",
    },
    enableReinitialize: true,
    // validationSchema: productsValidationSchema,
    onSubmit: (values) => {},
  });

  const onKeyDown = (keyEvent) => {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  };

  const handleAddCondition = () => {
    setShowConditionBox(true);
  };

  const handleApplyCondition = () => {
    // dispatchQueryFilter({ type: `SET_${operator}_${field}`, value });
    setShowConditionBox(false);
  };

  const toggleAddProductDrawer = (anchor, open) => (event) => {
    if (event && event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setAddProductDrawer({ ...addProductDrawer, [anchor]: open });
  };

  const handlePriceClick = (event) => {
    setAnchorPriceEl(event.currentTarget);
  };

  const handlePriceClose = () => {
    setAnchorPriceEl(null);
  };

  const openPrice = Boolean(anchorPriceEl);
  const idPrice = openPrice ? "simple-popover" : undefined;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleSelectAllClick = (event) => {
    if (selected.length > 0) {
      setSelected([]);
    } else if (event.target.checked) {
      const newSelected = productsData?.data
        ?.slice(0, queryFilterState?.pageSize)
        .map((n) => n);
      setSelected(newSelected);
    } else {
      setSelected([]);
    }
  };

  const handleLikeSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = likeProductRows?.map((n) => n.pId);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangeRowsPerPage = (event) => {
    dispatchQueryFilter({ type: "SET_PAGE_SIZE", value: event.target.value });
  };

  const handleChangePage = (_, pageNo) => {
    dispatchQueryFilter({ type: "CHANGE_PAGE", pageNo });
  };
  const handleDelete = (row) => {
    const selectedIndex = productsList.findIndex((item) => item?.product === row?._id);
    let newSelected = [];
    let newSelected2 = [];
    if (selectedIndex === -1) {
      return;
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(productsList.slice(1));
      newSelected2 = newSelected2.concat(selected.slice(1));
    } else if (selectedIndex === productsList.length - 1) {
      newSelected = newSelected.concat(productsList.slice(0, -1));
      newSelected2 = newSelected2.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        productsList.slice(0, selectedIndex),
        productsList.slice(selectedIndex + 1)
      );
      newSelected2 = newSelected2.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    console.log("newSelected", newSelected);
    formik.setFieldValue(`${field}`, newSelected);
    setSelected(newSelected2);
  };

  const handleSearchChange = (value) => {
    dispatchQueryFilter({ type: "SEARCH", title: value });
  };

  const handleSearchValue = (value) => {
    dispatchQueryFilter({ type: "SET_SEARCH_VALUE", searchValue: value });
  };
  const isSelected = (name) => selected.indexOf(name) !== -1;

  const findProducts = (id) => {
    return productsData?.data.find((item) => item?._id === id);
  };

  // console.log("selected", selected);
  // console.log("productsList", productsList);
  return (
    <div className="bg-black-9 border-grey-5 rounded-8 p-3 row features mt-4">
      <form
        noValidate
        onSubmit={productsFormik.handleSubmit}
        onKeyDown={onKeyDown}>
        <div className="d-flex justify-content-between mb-2 px-0">
          <h6
            className="text-lightBlue me-auto text-lightBlue col-auto ps-0 fw-500"
            type="button">
            Add Product
          </h6>
        </div>
        <div className="d-flex align-items-center col-12 px-0 mb-2">
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={typeValue || productsFormik?.values?.type}
            onChange={(e) => {
              productsFormik?.handleChange(e);
              productsFormik?.setFieldValue("type", e.target.value);
              formik?.setFieldValue(typeField, e.target.value);
            }}
            onBlur={productsFormik?.handleBlur}
            className="features-radio px-0">
            <FormControlLabel
              value="automated"
              control={<Radio size="small" />}
              label="Automated"
              sx={{
                "& .MuiTypography-root": {
                  fontSize: 13,
                  color: "#c8d8ff",
                  marginRight: 1,
                },
              }}
            />
            <FormControlLabel
              value="manual"
              control={<Radio size="small" />}
              label="Manual"
              sx={{
                "& .MuiTypography-root": {
                  fontSize: 13,
                  color: "#c8d8ff",
                  marginRight: 1,
                },
              }}
            />
          </RadioGroup>
        </div>
        {productsFormik?.values?.type !== "manual" && (
          <div className="bg-black-11 rounded-8 p-3 shadow-sm">
            {productsFormik?.values?.type === "automated" && (
              <>
                <div className="d-flex justify-content-between">
                  <div className="d-flex align-items-center">
                    <p className="text-lightBlue me-4">Should Match:</p>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={productsFormik?.values?.condition}
                      onChange={(e) => {
                        productsFormik?.handleChange(e);
                        productsFormik?.setFieldValue("condition", e.target.value);
                      }}
                      onBlur={productsFormik?.handleBlur}
                      className="features-radio">
                      <FormControlLabel
                        value="allCondition"
                        control={<Radio size="small" />}
                        label="All Condition"
                        sx={{
                          "& .MuiTypography-root": {
                            fontSize: 13,
                            color: "#c8d8ff",
                          },
                        }}
                      />
                      <FormControlLabel
                        value="anyCondition"
                        control={<Radio size="small" />}
                        label="Any Condition"
                        sx={{
                          "& .MuiTypography-root": {
                            fontSize: 13,
                            color: "#c8d8ff",
                          },
                        }}
                      />
                    </RadioGroup>
                  </div>
                  <button
                    type="button"
                    className="button-gradient py-1 px-4"
                    onClick={handleAddCondition}>
                    <p>Add Condition</p>
                  </button>
                </div>
                {/* <div className="bg-black-9 align-items-center rounded-8 py-2 px-3 d-flex justify-content-between mt-3 shadow-lg">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checked}
                      onChange={handleCheckboxChange}
                      inputProps={{ "aria-label": "controlled" }}
                      size="small"
                      style={{
                        color: "#5C6D8E",
                        marginRight: 0,
                        width: "auto",
                      }}
                    />
                  }
                  label="Summary"
                  sx={{
                    "& .MuiTypography-root": {
                      fontSize: "0.875rem",
                      color: "#c8d8ff",
                    },
                  }}
                  className=" px-0"
                />
                <p className="text-lightBlue c-pointer">Action</p>
              </div> */}
                {/* {conditionList.length > 0 && conditionList} */}
                {showConditionBox && (
                  <div className="row">
                    <div className="col-sm-6 col-md-3 mt-3 mb-1 ps-4">
                      <p className="text-lightBlue mb-1">Field</p>

                      <FormControl
                        className="w-100 px-0"
                        size="small">
                        <Select
                          labelId="demo-select-small"
                          id="demo-select-small"
                          name="field"
                          value={productsFormik?.values?.field}
                          onChange={productsFormik?.handleChange}
                          onBlur={productsFormik?.handleBlur}
                          size="small">
                          <MenuItem
                            value="PRICE"
                            sx={{ fontSize: 13, color: "#5c6d8e" }}>
                            Price
                          </MenuItem>
                          <MenuItem
                            value={"COLLECTION"}
                            sx={{ fontSize: 13, color: "#5c6d8e" }}>
                            Collection
                          </MenuItem>
                          <MenuItem
                            value={"TAGS"}
                            sx={{ fontSize: 13, color: "#5c6d8e" }}>
                            Tags
                          </MenuItem>
                          <MenuItem
                            value={"CATEGORY"}
                            sx={{ fontSize: 13, color: "#5c6d8e" }}>
                            Catagory
                          </MenuItem>
                          <MenuItem
                            value={"SUBCATEGORY"}
                            sx={{ fontSize: 13, color: "#5c6d8e" }}>
                            Sub Category
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <div className="col-sm-6 col-md-3 mt-3 mb-1">
                      <p className="text-lightBlue mb-1">Operator</p>

                      <FormControl
                        className="w-100 px-0"
                        size="small">
                        <Select
                          labelId="demo-select-small"
                          id="demo-select-small"
                          name="operator"
                          value={productsFormik?.values?.operator}
                          onChange={productsFormik?.handleChange}
                          onBlur={productsFormik?.handleBlur}
                          size="small">
                          <MenuItem
                            value="EQUAL"
                            sx={{ fontSize: 13, color: "#5c6d8e" }}>
                            Equals
                          </MenuItem>
                          <MenuItem
                            value={"NOTEQUAL"}
                            sx={{ fontSize: 13, color: "#5c6d8e" }}>
                            Not Equals
                          </MenuItem>
                          <MenuItem
                            value={"GREATER"}
                            sx={{ fontSize: 13, color: "#5c6d8e" }}>
                            Greater Than
                          </MenuItem>
                          <MenuItem
                            value={"LOWER"}
                            sx={{ fontSize: 13, color: "#5c6d8e" }}>
                            Less
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <div className="col-sm-6 col-md-3 mt-3 mb-1">
                      <p className="text-lightBlue mb-1">Value</p>

                      <FormControl className="w-100 px-0">
                        <OutlinedInput
                          placeholder="Enter Value"
                          size="small"
                          name="value"
                          value={productsFormik?.values?.value}
                          onChange={productsFormik?.handleChange}
                          onBlur={productsFormik?.handleBlur}
                          startAdornment={
                            <InputAdornment position="start">
                              <p className="text-lightBlue">₹</p>
                            </InputAdornment>
                          }
                        />
                      </FormControl>
                    </div>
                    <div className="col-sm-6 col-md-3 mt-3 mb-1">
                      <button
                        type="button"
                        className="button-gradient py-1 px-3 w-100 mb-2"
                        onClick={handleApplyCondition}>
                        <p>Apply</p>
                      </button>
                      <button
                        type="button"
                        className="button-lightBlue-outline py-1 px-3 w-100"
                        // onClick={handleApplyCondition}
                      >
                        <p>Cancel</p>
                      </button>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        )}
        {productsFormik?.values?.type === "automated" && showConditionBox && (
          <>
            <div className="col-12 mt-3">
              <div className="row align-items-center">
                <div className="col-md-9 px-md-0 py-2">
                  <Search className="mx-0">
                    <SearchIconWrapper>
                      <SearchIcon sx={{ color: "#c8d8ff" }} />
                    </SearchIconWrapper>
                    <StyledInputBase
                      placeholder="Search…"
                      inputProps={{ "aria-label": "search" }}
                    />
                  </Search>
                </div>
                <div className="col-md-3 pe-md-0 py-2">
                  <button
                    className="button-gradient w-100 py-1 px-3"
                    onClick={toggleAddProductDrawer("right", true)}>
                    <p>Add Products</p>
                  </button>
                </div>
              </div>
            </div>
            <div className="col-12 px-0">
              <TableContainer className="mt-3">
                <Table
                  sx={{ minWidth: 750 }}
                  aria-labelledby="tableTitle"
                  size="medium">
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleLikeSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={likeProductRows.length}
                    headCells={drawerHeadCells}
                  />
                  <TableBody>
                    {stableSort(likeProductRows, getComparator(order, orderBy))?.map(
                      (row, index) => {
                        const isItemSelected = isSelected(row.pId);
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.pId}
                            selected={isItemSelected}>
                            <TableCell padding="checkbox">
                              <Checkbox
                                color="primary"
                                checked={isItemSelected}
                                inputProps={{
                                  "aria-labelledby": labelId,
                                }}
                                size="small"
                                onClick={(event) => handleClick(event, row.pId)}
                                style={{
                                  color: "#5C6D8E",
                                  marginRight: 0,
                                }}
                              />
                            </TableCell>
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="none">
                              <div className="d-flex align-items-center my-2">
                                <img
                                  src={ringSmall}
                                  alt="ringSmall"
                                  className="me-2"
                                  height={45}
                                  width={45}
                                />
                                <div>
                                  <p className="text-lightBlue fw-600">{row.productName}</p>
                                  <small className="mt-2 text-grey-6">SKU: TFDR012345</small>
                                </div>
                              </div>
                            </TableCell>
                            <TableCell>
                              <p className="text-lightBlue">{row.category}</p>
                            </TableCell>
                            <TableCell>
                              <div className="d-flex align-items-center c-pointer ">
                                <p className="text-lightBlue">{row.price}</p>
                              </div>
                            </TableCell>
                            <TableCell>
                              <div className="d-flex align-items-center c-pointer ">
                                <img
                                  src={deleteButton}
                                  alt="deleteButton"
                                  width={75}
                                  className="c-pointer"
                                />
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                      }
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                // count={productsData?.totalCount}
                // rowsPerPage={queryFilterState1.pageSize}
                // page={queryFilterState1.pageNo - 1}
                // onPageChange={handleChangePage}
                // onRowsPerPageChange={handleChangeRowsPerPage}
                className="table-pagination"
              />
            </div>
          </>
        )}
        {productsFormik?.values?.type === "manual" && (
          <>
            {productsList?.length > 0 ? (
              <>
                <div className="col-12 mt-3">
                  <div className="row align-items-center">
                    <div className="col-md-9 px-md-0 py-2">
                      <Search className="mx-0">
                        <SearchIconWrapper>
                          <SearchIcon sx={{ color: "#c8d8ff" }} />
                        </SearchIconWrapper>
                        <StyledInputBase
                          placeholder="Search…"
                          inputProps={{ "aria-label": "search" }}
                        />
                      </Search>
                    </div>
                    <div className="col-md-3 pe-md-0 py-2">
                      <button
                        type="button"
                        className="button-gradient w-100 py-1 px-3"
                        onClick={toggleAddProductDrawer("right", true)}>
                        <p>Add Products</p>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-12 px-0">
                  <TableContainer className="mt-3">
                    <Table
                      sx={{ minWidth: 750 }}
                      aria-labelledby="tableTitle"
                      size="medium">
                      <EnhancedTableHead
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={handleLikeSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={productsList.length}
                        headCells={drawerHeadCells}
                      />
                      <TableBody>
                        {stableSort(productsList, getComparator(order, orderBy))?.map(
                          (row, index) => {
                            const isItemSelected = isSelected(row._id);
                            const labelId = `enhanced-table-checkbox-${index}`;

                            return (
                              <TableRow
                                hover
                                role="checkbox"
                                aria-checked={isItemSelected}
                                tabIndex={-1}
                                key={row._id}
                                selected={isItemSelected}>
                                {/* <TableCell padding="checkbox">
                                <Checkbox
                                  color="primary"
                                  checked={isItemSelected}
                                  inputProps={{
                                    "aria-labelledby": labelId,
                                  }}
                                  size="small"
                                  onClick={(event) =>
                                    handleClick(event, row._id)
                                  }
                                  style={{
                                    color: "#5C6D8E",
                                    marginRight: 0,
                                  }}
                                />
                              </TableCell> */}
                                <TableCell
                                  component="th"
                                  id={labelId}
                                  scope="row"
                                  padding="none">
                                  <div className="d-flex align-items-center my-2">
                                    <img
                                      src={ringSmall}
                                      alt="ringSmall"
                                      className="me-2"
                                      height={45}
                                      width={45}
                                    />
                                    <div>
                                      <p className="text-lightBlue fw-600">
                                        {row?.productName
                                          ? row?.productName
                                          : findProducts(row?.product)?.productName}
                                      </p>
                                      <small className="mt-2 text-grey-6">
                                        {row?.title
                                          ? row?.title
                                          : findProducts(row?.product)?.title}
                                      </small>
                                    </div>
                                  </div>
                                </TableCell>
                                <TableCell>
                                  <p className="text-lightBlue">
                                    {" "}
                                    {row?.productType?.category?.name
                                      ? row?.productType?.category?.name
                                      : findProducts(row?.product)?.productType?.category?.name}
                                  </p>
                                </TableCell>
                                <TableCell>
                                  <div className="d-flex align-items-center c-pointer ">
                                    <p className="text-lightBlue">
                                      {row?.price?.price ??
                                        findProducts(row?.product)?.price?.price ??
                                        `Depends on variant`}
                                    </p>
                                  </div>
                                </TableCell>
                                <TableCell>
                                  <div
                                    className="d-flex align-items-center c-pointer "
                                    onClick={() => handleDelete(findProducts(row?.product))}>
                                    <img
                                      src={deleteButton}
                                      alt="deleteButton"
                                      width={75}
                                      className="c-pointer"
                                    />
                                  </div>
                                </TableCell>
                              </TableRow>
                            );
                          }
                        )}
                        {/* {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 53 * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )} */}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={productsData?.totalCount}
                    rowsPerPage={queryFilterState.pageSize}
                    page={queryFilterState.pageNo - 1}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    className="table-pagination"
                  />
                </div>
              </>
            ) : (
              <img
                src={featureUpload}
                className="w-100 c-pointer px-0"
                alt=""
                onClick={toggleAddProductDrawer("right", true)}
              />
            )}
          </>
        )}
      </form>
      <SwipeableDrawer
        anchor="right"
        open={addProductDrawer["right"]}
        onClose={toggleAddProductDrawer("right", false)}
        onOpen={toggleAddProductDrawer("right", true)}>
        {/* {list()} */}
        <div className="d-flex justify-content-between py-3 ps-3 pe-2 me-1 align-items-center">
          <h6 className="text-lightBlue">Select Products</h6>
          <img
            src={cancel}
            alt="cancel"
            className="c-pointer add-product-padding"
            onClick={toggleAddProductDrawer("right", false)}
          />
        </div>
        <hr className="hr-grey-6 mt-3 mb-3" />
        <div className="px-3">
          {/* <Search>
            <SearchIconWrapper>
              <SearchIcon sx={{ color: "#c8d8ff" }} />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ "aria-label": "search" }}
            />
          </Search> */}
          <TableSearchSecondary
            onSearchValueChange={handleSearchValue}
            value={queryFilterState.searchValue}
            onChange={handleSearchChange}
          />
        </div>

        {productsIsSuccess && (
          <TableContainer className="mt-3">
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size="medium">
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={productsData?.data?.length}
                headCells={likeHeadCells}
              />
              <TableBody>
                {stableSort(productsData?.data, getComparator(order, orderBy))?.map(
                  (row, index) => {
                    const isItemSelected = isSelected(row);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={index}
                        selected={isItemSelected}>
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                            size="small"
                            onClick={(event) => handleClick(event, row)}
                            style={{
                              color: "#5C6D8E",
                              marginRight: 0,
                            }}
                          />
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none">
                          <div className="d-flex align-items-center my-2">
                            <img
                              src={ringSmall}
                              alt="ringSmall"
                              className="me-2"
                              height={45}
                              width={45}
                            />
                            <div>
                              <p className="text-lightBlue fw-600">{row?.productName}</p>
                              <small className="mt-2 text-grey-6">{row?.title}</small>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell>
                          <p className="text-lightBlue">{row?.productType?.category?.name}</p>
                        </TableCell>
                        <TableCell>
                          <div
                            className="d-flex align-items-center c-pointer "
                            aria-describedby={idPrice}
                            variant="contained"
                            onClick={handlePriceClick}>
                            <p className="text-lightBlue">
                              {row?.price?.price ? row?.price?.price : `Depends on variant`}
                            </p>
                            <img
                              className="ms-3"
                              src={arrowDown}
                              alt="arrowDown"
                            />
                          </div>
                          <Popover
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "center",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "center",
                            }}
                            id={idPrice}
                            open={openPrice}
                            anchorEl={anchorPriceEl}
                            onClose={handlePriceClose}>
                            <div className="px-3">
                              <small className="text-lightBlue">
                                Default : 12KT • Yellow • Gold • IJ-SI
                              </small>
                              <div className="d-flex align-items-center justify-content-between mb-2 mt-3 text-grey-6">
                                <small>Metal Price</small>
                                <small className="ms-2">₹&nbsp;15,000</small>
                              </div>
                              <div className="d-flex align-items-center justify-content-between mb-2 mt-2 text-grey-6">
                                <small>Diamond Price</small>
                                <small className="ms-2">₹&nbsp;4,000</small>
                              </div>
                              <div className="d-flex align-items-center justify-content-between mb-2 mt-2 text-grey-6">
                                <small>Making Charges</small>
                                <small className="ms-2">₹&nbsp;1,000</small>
                              </div>
                              <div className="d-flex align-items-center justify-content-between mb-3 mt-2 text-grey-6">
                                <small>GST</small>
                                <small className="ms-2">₹&nbsp;&nbsp;600</small>
                              </div>
                              <div className="d-flex align-items-center justify-content-between mb-2 mt-2">
                                <p className="text-lightBlue">Total</p>
                                <p className="ms-2 text-lightBlue fw-600">₹&nbsp;20,600</p>
                              </div>
                            </div>
                          </Popover>
                        </TableCell>
                      </TableRow>
                    );
                  }
                )}
                {/* {emptyRows > 0 && (
                      <TableRow
                        style={{
                          height: 53 * emptyRows,
                        }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )} */}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={productsData?.totalCount}
          rowsPerPage={queryFilterState.pageSize}
          page={queryFilterState.pageNo - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          className="table-pagination"
        />
        <div className="d-flex flex-column py-3 px-4 feature-buttons">
          <hr className="hr-grey-6 my-3 w-100" />
          <div className="d-flex justify-content-between">
            <button
              type="button"
              className="button-gradient py-2 px-5 w-auto"
              onClick={() => {
                formik.setFieldValue(
                  `${field}`,
                  selected?.map((item, index) => ({
                    product: item?._id,
                    collection: [id],
                    type: "add",
                  }))
                );
                toggleAddProductDrawer("right", false);
                setAddProductDrawer({
                  ...addProductDrawer,
                  right: false,
                });
              }}>
              <p>Add Products</p>
            </button>
            <button
              className="button-lightBlue-outline py-2 px-4"
              onClick={() => {
                toggleAddProductDrawer("right", false);
                setAddProductDrawer({
                  ...addProductDrawer,
                  right: false,
                });
              }}>
              <p>Cancel</p>
            </button>
          </div>
        </div>
      </SwipeableDrawer>
    </div>
  );
};

export default AddProducts;
