import { useState } from 'react';
import { Drawer, Box, AppBar } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

import ThemeNavbar from '../ThemeNavbar/ThemeNavbar';
import ThemeSideNav from '../ThemeSideNav/ThemeSideNav';
import PCJWeb from '../../../../components/ThemeComponent/PCJ/PCJWeb';

const DRAWER_WIDTH = 320;

const CustomWeb = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [iframeWidth, setIframeWidth] = useState('100%');
  const themeClass = mobileOpen ? 'themeHandler-0' : 'themeHandler';
  const handleArrow = mobileOpen ? 'rotate(180deg)' : '';

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const handleView = (value) => {
    if (value == 'mobile') {
      setIframeWidth('30%');
    }
    if (value == 'desktop') {
      setIframeWidth('100%');
    }
  };

  const handerDrawer = () => {
    handleDrawerToggle();
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar
        position='fixed'
        sx={{
          width: !mobileOpen
            ? { sm: `calc(100% - ${DRAWER_WIDTH}px)` }
            : { sm: '100%' },
          ml: { sm: `${DRAWER_WIDTH}px` },
        }}
      >
        <ThemeNavbar handleView={handleView} />
      </AppBar>
      <Box
        component='nav'
        sx={{
          width: !mobileOpen ? { sm: DRAWER_WIDTH } : { sm: 0 },
          flexShrink: { sm: 0 },
          marginBottom: 10,
        }}
        aria-label='mailbox folders'
      >
        <Drawer
          sx={{
            display: { xs: 'none', sm: 'block' },
            width: DRAWER_WIDTH,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: DRAWER_WIDTH,
              boxSizing: 'border-box',
            },
          }}
          variant='persistent'
          open={!mobileOpen}
          anchor='left'
        >
          <ThemeSideNav handerDrawer={handerDrawer} />
        </Drawer>
      </Box>

      <Box
        component='main'
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${DRAWER_WIDTH}px)` },
        }}
        className='main-box d-flex justify-content-center'
      >
        <div
          className='border-grey-5 rounded-19 bg-black-13 px-3 pb-3 pt-5 mt-4 h-100'
          style={{ width: iframeWidth }}
        >
          <div className='border-grey-8 rounded-19 bg-black-15 h-100'>
            <iframe
              src={process.env.REACT_APP_FE_BASE_URL}
              className='iFrame w-100 h-100 rounded-19'
            />
          </div>
        </div>
      </Box>
      <small
        className={`bg-black-20 c-pointer themeArrow text-lightBlue ${themeClass}`}
        onClick={handerDrawer}
      >
        <KeyboardArrowLeftIcon sx={{ transform: handleArrow }} />
      </small>
    </Box>
  );
};

export default CustomWeb;
