import apiSlice from "../../app/api/apiSlice";

import { omitNullishKeys } from "../../utils/helper";

export const fileUploadApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getS3UploadUrl: builder.query({
      query: (queries) => {
        return {
          url: "/uploads",
          params: omitNullishKeys(queries),
        };
      },
    }),
    uploadUrl: builder.mutation({
      query: (queries) => {
        return {
          url: "/uploads",
          params: omitNullishKeys(queries),
        };
      },
      transformResponse: (res) => res.data,
    }),
  }),
});

export const { useGetS3UploadUrlQuery, useUploadUrlMutation } =
  fileUploadApiSlice;
