// ! COMPONENT IMPORTS
// import { AntSwitch } from "../../../../components/AntSwitch/AntSwitch";
// ! IMAGES IMPORTS
import infoIcon from "../../../../assets/icons/info.svg";
// ! MATERIAL IMPORTS
import {
  MenuItem,
  OutlinedInput,
  Select,
  Tooltip,
  Button,
  FormHelperText,
} from "@mui/material";
// ! MATERIAL ICONS IMPORTS
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import {
  useGetAllMetaSubAttributesQuery,
  useGetAllSubAttributesQuery,
} from "../../../../features/parameters/options/optionsApiSlice";

export default function RingStones({ ix, icon, formik }) {
  const stoneData = formik.values[ix];

  const { data: allSubAttributesData } = useGetAllMetaSubAttributesQuery({
    attribute: stoneData.attribute,
  });
  const subAttributes = allSubAttributesData?.data ?? [];

  const shapes = subAttributes.filter((sattr) => sattr.stoneAttribute.title.endsWith("Shape"));
  const shapesId = shapes[0]?.stoneAttribute?._id ?? "";

  const settingTypes = subAttributes.filter((sattr) =>
    sattr.stoneAttribute.title.endsWith("Setting Type")
  );
  const settingTypesId = settingTypes[0]?.stoneAttribute?._id ?? "";

  const updateStoneData = (event, index) => {
    const { name, value } = event.target;
    // const num = Number(value);
    // console.log({ name, value, num });
    formik.setFieldValue(`${ix}.subAttributes.${index}`, {
      ...stoneData.subAttributes[index],
      // [name]: isNaN(num) ? value : num,
      [name]: value,
    });
  };

  const deleteStoneData = (index) => {
    formik.setFieldValue(
      `${ix}.subAttributes`,
      stoneData.subAttributes.filter((_, i) => i !== index)
    );
  };

  const addStoneData = () => {
    formik.setFieldValue(
      `${ix}.subAttributes`,
      stoneData.subAttributes.concat({
        metaSubAttribute1: shapesId,
        metaSubAttributeValue1: "",
        metaSubAttribute2: settingTypesId,
        metaSubAttributeValue2: "",
        size: "",
        peices: "",
        weight: "",
        // caratWeight: "",
      })
    );
  };

  return (
    <div className="row py-3 border-grey-5 rounded-8 align-items-center bg-black-13 mt-3">
      <div className="row pt-2 pb-2">
        <div className="col-9 d-flex align-items-center">
          <img
            src={icon}
            alt="icon"
            width={50}
          />

          <h6 className="text-capitalize text-lightBlue fw-500 me-2">
            {stoneData.attributeTitle} Information
          </h6>

          <Tooltip
            title="Lorem ipsum"
            placement="top">
            <img
              src={infoIcon}
              alt="info"
              className="c-pointer"
              width={13.5}
            />
          </Tooltip>
        </div>

        <div className="col-3 d-flex justify-content-end">
          <Button
            onClick={addStoneData}
            variant="text">
            <AddCircleOutlineOutlinedIcon
              sx={{
                color: "#5c6d8e",
                cursor: "pointer",
              }}
            />
            <span className="text-blue-2 ps-1">Add {stoneData.attributeTitle}</span>
          </Button>
        </div>
      </div>

      {stoneData.subAttributes.length > 0 && (
        <div className="row pt-4 pb-2">
          <div className="col-12">
            <div className="row mt-2 mb-3">
              <div className="col-1">
                <span className="text-lightBlue">No.</span>
              </div>

              <div className="col-2">
                <span className="text-lightBlue">Shape</span>
              </div>

              <div className="col-2">
                <span className="text-lightBlue">Setting Type</span>
              </div>

              <div className="col-2">
                <span className="text-lightBlue">Size (mm)</span>
              </div>
              {/* 
              <div className="col-2">
                <span className="text-lightBlue">Weight</span>
              </div>
              */}

              <div className="col-2">
                <span className="text-lightBlue">No of Pieces</span>
              </div>

              <div className="col-2">
                <span className="text-lightBlue">Carat Weight (ct)</span>
              </div>

              <div className="col-1">&nbsp;</div>
            </div>
          </div>

          {stoneData.subAttributes.map((item, index) => (
            <div
              key={index}
              className="row my-2">
              <div className="col-1">
                <span className="text-lightBlue">{index + 1}</span>
              </div>

              <div className="col-2">
                <Select
                  size="small"
                  className="w-100"
                  name="metaSubAttributeValue1"
                  value={item.metaSubAttributeValue1}
                  onChange={(e) => updateStoneData(e, index)}>
                  {shapes.map((shape) => (
                    <MenuItem
                      key={shape._id}
                      value={shape._id}>
                      {shape.title}
                    </MenuItem>
                  ))}
                </Select>

                <FormHelperText error>
                  {formik.touched?.[ix]?.subAttributes?.[index]?.metaSubAttributeValue1 &&
                    formik.errors?.[ix]?.subAttributes?.[index]?.metaSubAttributeValue1}
                </FormHelperText>
              </div>

              <div className="col-2">
                <Select
                  size="small"
                  className="w-100"
                  name="metaSubAttributeValue2"
                  value={item.metaSubAttributeValue2}
                  onChange={(e) => updateStoneData(e, index)}>
                  {settingTypes.map((setting) => (
                    <MenuItem
                      key={setting._id}
                      value={setting._id}>
                      {setting.title}
                    </MenuItem>
                  ))}
                </Select>

                <FormHelperText error>
                  {formik.touched?.[ix]?.subAttributes?.[index]?.metaSubAttributeValue2 &&
                    formik.errors?.[ix]?.subAttributes?.[index]?.metaSubAttributeValue2}
                </FormHelperText>
              </div>

              <div className="col-2">
                <OutlinedInput
                  className="w-100"
                  placeholder="Enter Size"
                  size="small"
                  name="size"
                  value={item.size}
                  onChange={(e) => updateStoneData(e, index)}
                  // endAdornment={<InputAdornment position="end">gm</InputAdornment>}
                />

                <FormHelperText error>
                  {formik.touched?.[ix]?.subAttributes?.[index]?.size &&
                    formik.errors?.[ix]?.subAttributes?.[index]?.size}
                </FormHelperText>
              </div>

              {/* <div className="col-2">
                  <OutlinedInput
                    className="w-100"
                    placeholder="Enter Size"
                    size="small"
                    name="weight"
                    value={item.weight}
                    onChange={(e) => updateStoneData(e, index)}
                    endAdornment={<InputAdornment position="end">gm</InputAdornment>}
                  />
                </div> */}

              <div className="col-2">
                <OutlinedInput
                  className="w-100"
                  placeholder="Enter Size"
                  size="small"
                  name="peices"
                  value={item.peices}
                  onChange={(e) => updateStoneData(e, index)}
                />

                <FormHelperText error>
                  {formik.touched?.[ix]?.subAttributes?.[index]?.peices &&
                    formik.errors?.[ix]?.subAttributes?.[index]?.peices}
                </FormHelperText>
              </div>

              <div className="col-2">
                <OutlinedInput
                  className="w-100"
                  placeholder="Enter Size"
                  size="small"
                  name="weight"
                  value={item.weight}
                  onChange={(e) => updateStoneData(e, index)}
                  // endAdornment={<InputAdornment position="end">gm</InputAdornment>}
                />

                <FormHelperText error>
                  {formik.touched?.[ix]?.subAttributes?.[index]?.weight &&
                    formik.errors?.[ix]?.subAttributes?.[index]?.weight}
                </FormHelperText>
              </div>

              <div className="col-1">
                <DeleteForeverOutlinedIcon
                  onClick={() => deleteStoneData(index)}
                  sx={{
                    color: "#5c6d8e",
                    cursor: "pointer",
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
