import apiSlice from "../../app/api/apiSlice";

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: "/user/login",
        method: "POST",
        body: credentials,
      }),
    }),
    loginOTP: builder.mutation({
      query: (credentials) => ({
        url: "/user/loginViaOtp",
        method: "POST",
        body: credentials,
      }),
    }),
    signUp: builder.mutation({
      query: (userDetails) => ({
        url: "/user/register",
        method: "POST",
        body: userDetails,
      }),
    }),
    inviteUp: builder.mutation({
      query: (userDetails) => ({
        url: "/user/updatePassword",
        method: "POST",
        body: userDetails,
      }),
    }),
    verify: builder.mutation({
      query: (details) => ({
        url: "/user/verify",
        method: "POST",
        body: details,
      }),
    }),
    resendOTP: builder.mutation({
      query: (details) => ({
        url: "/user/resendOtp",
        method: "POST",
        body: details,
      }),
    }),
    googleLogin: builder.query({
      query: () => ({
        url: "/connect/google",
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useLoginOTPMutation,
  useSignUpMutation,
  useInviteUpMutation,
  useVerifyMutation,
  useResendOTPMutation,
  useGoogleLoginQuery,
} = authApiSlice;
