import { useState } from "react";
// ! IMAGES IMPORTS
import info from "../../assets/icons/info.svg";
// import hanger1 from "../../assets/icons/hanger1.svg";
// import ruler1 from "../../assets/icons/ruler1.svg";
// import tape1 from "../../assets/icons/tape1.svg";
// import activity from "../../assets/icons/activity.svg";
// import teamMember1 from "../../assets/images/products/teamMember1.svg";
import cancel from "../../assets/icons/cancel.svg";
// import positionTopLeft from "../../assets/icons/positionTopLeft.svg";
// import positionTopRight from "../../assets/icons/positionTopRight.svg";
// import positionBottomLeft from "../../assets/icons/positionBottomLeft.svg";
// import positionBottomRight from "../../assets/icons/positionBottomRight.svg";
// import positionCenterLeft from "../../assets/icons/positionCenterLeft.svg";
// import positionCenterRight from "../../assets/icons/positionCenterRight.svg";
import monitorOnIcon from "../../assets/icons/monitorOn.svg";
import monitorOffIcon from "../../assets/icons/monitorOff.svg";
import smartphoneOnIcon from "../../assets/icons/smartphoneOn.svg";
import smartphoneOffIcon from "../../assets/icons/smartphoneOff.svg";
// ! MATERIAL IMPORTS
import {
  SwipeableDrawer,
  Box,
  Tooltip,
  FormControl,
  OutlinedInput,
  Select,
  MenuItem,
  FormHelperText,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
// ! MATERIAL ICONS IMPORTS
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  useEditLabelsBadgesSettingMutation,
  useGetAllLabelsBadgesSettingsQuery,
} from "../../features/functionality/labelsBadgesSetting/labelsBadgesSettingApiSlice";
import { LoadingButton } from "@mui/lab";
import { useDispatch } from "react-redux";
import { showError, showSuccess } from "../../features/snackbar/snackbarAction";

const validationSchema = Yup.object({
  device: Yup.string().oneOf(["mobile", "desktop"]).required("required"),
  position: Yup.string()
    .oneOf(["topLeft", "topRight", "bottomLeft", "bottomRight", "centerLeft", "centerRight"])
    .required("required"),
  margin: Yup.object({
    top: Yup.number().min(0),
    bottom: Yup.number().min(0),
    right: Yup.number().min(0),
    left: Yup.number().min(0),
  }),
  size: Yup.string().oneOf(["small", "medium", "large", "x-large"]).required("required"),
});

const ViewLabelsBadgesSettings = () => {
  const [isOpen, setIsOpen] = useState(false);
  const closeSwipeableDrawer = (e) => {
    if (e?.target?.classList?.contains("MuiBackdrop-root")) return;
    setIsOpen(false);
  };

  const dispatch = useDispatch();

  const [editLabelsBadgesSetting, { isLoading: editLabelsBadgesSettingIsLoading }] =
    useEditLabelsBadgesSettingMutation();
  const {
    data: labelsBadgesSettingData,
    refetch: labelsBadgesSettingRefetch,
    isSuccess: labelsBadgesSettingIsSuccess,
  } = useGetAllLabelsBadgesSettingsQuery({}, { skip: !isOpen });
  const {
    _id = "",
    device = "desktop",
    position = "topLeft",
    margin: { top = 0, bottom = 0, right = 0, left = 0 },
    size = "medium",
  } = labelsBadgesSettingData?.data?.data?.[0] ?? { margin: {} };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      device,
      position,
      margin: { top, bottom, right, left },
      size,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log(values);
      editLabelsBadgesSetting({ id: _id, details: values })
        .unwrap()
        .then(() => {
          closeSwipeableDrawer();
          dispatch(showSuccess({ message: "Size Chart Settings updated successfully" }));
        })
        .catch((e) =>
          dispatch(showError({ message: e?.data?.message ?? "Something went wrong" }))
        );
    },
  });

  // console.log(
  //   JSON.stringify(
  //     {
  //       values: formik.values,
  //       errors: formik.errors,
  //       touched: formik.touched,
  //     },
  //     null,
  //     1
  //   )
  // );

  const onOpenHandler = () => labelsBadgesSettingRefetch();

  return (
    <>
      <button
        onClick={() => setIsOpen(true)}
        className="button-transparent me-1 py-2 px-3">
        <p className="text-lightBlue">Settings</p>
      </button>

      <SwipeableDrawer
        open={isOpen}
        onClose={closeSwipeableDrawer}
        onOpen={onOpenHandler}
        PaperProps={{ style: { width: "45%" } }}
        anchor="right">
        <Box p={2}>
          <div className="row align-items-center">
            <div className="col">
              <h6 className="text-lightBlue">Labels Settings</h6>
            </div>
            <div className="col-auto">
              <img
                src={cancel}
                width={25}
                alt="cancel"
                onClick={closeSwipeableDrawer}
                className="c-pointer filter-icon me-1"
              />
            </div>
          </div>
          <hr className="hr-grey-6" />
          {labelsBadgesSettingIsSuccess && _id && (
            <form
              noValidate
              onReset={formik.handleReset}
              onSubmit={formik.handleSubmit}>
              <div className="row align-items-center my-4">
                <div className="col-4">
                  <h6 className="text-lightBlue fw-500">Appearance</h6>
                </div>
                <div className="col-8">
                  <ToggleButtonGroup
                    value={formik.values.device}
                    onChange={(_, device) => {
                      formik.setFieldTouched("device", true);
                      if (device) formik.setFieldValue("device", device);
                    }}
                    exclusive
                    aria-label="text alignment"
                    className="productDetails-toggle">
                    <ToggleButton
                      size="small"
                      value="desktop"
                      className="row"
                      aria-label="desktop">
                      <div className="col-auto px-1">
                        <img
                          src={
                            formik.values.device === "desktop" ? monitorOnIcon : monitorOffIcon
                          }
                          alt="desktop"
                        />
                      </div>
                      {formik.values.device === "desktop" && (
                        <div className="col px-1">
                          <small className="text-capitalize text-lightBlue">Desktop</small>
                        </div>
                      )}
                    </ToggleButton>
                    <ToggleButton
                      size="small"
                      value="mobile"
                      className="row"
                      aria-label="mobile">
                      <div className="col-auto px-1">
                        <img
                          src={
                            formik.values.device === "mobile"
                              ? smartphoneOnIcon
                              : smartphoneOffIcon
                          }
                          alt="mobile"
                        />
                      </div>
                      {formik.values.device === "mobile" && (
                        <div className="col px-1">
                          <small className="text-capitalize text-lightBlue">Mobile</small>
                        </div>
                      )}
                    </ToggleButton>
                  </ToggleButtonGroup>
                </div>
              </div>

              <div className="row align-items-center my-4">
                <div className="col-4">
                  <h6 className="text-lightBlue fw-500">Label Location</h6>
                </div>
                <div className="col-8">
                  <FormControl
                    size="small"
                    className="w-100 px-0">
                    <Select
                      size="small"
                      name="position"
                      value={formik.values.position}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}>
                      <MenuItem value="topLeft">Top Left</MenuItem>
                      <MenuItem value="topRight">Top Right</MenuItem>
                      <MenuItem value="bottomLeft">Bottom Left</MenuItem>
                      <MenuItem value="bottomRight">Bottom Right</MenuItem>
                      <MenuItem value="centerLeft">Center Left</MenuItem>
                      <MenuItem value="centerRight">Center Right</MenuItem>
                    </Select>
                    <FormHelperText error>
                      {formik.touched.position && formik.errors.position}
                    </FormHelperText>
                  </FormControl>
                </div>
              </div>

              <div className="row align-items-center my-4">
                <div className="col-12 mb-4">
                  <h6 className="text-lightBlue fw-500">Margins</h6>
                </div>
                <div className="col-3">
                  <div className="mb-2">
                    <span className="text-lightBlue me-2 text-lightBlue fw-500">Top</span>
                    <Tooltip
                      title="Lorem ipsum"
                      placement="top">
                      <img
                        src={info}
                        alt="info"
                        className="c-pointer me-3"
                        width={13.5}
                      />
                    </Tooltip>
                  </div>
                  <FormControl
                    size="small"
                    className="w-100 px-0">
                    <OutlinedInput
                      placeholder="Top"
                      name="margin.top"
                      type="number"
                      value={formik.values.margin?.top}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <FormHelperText error>
                      {formik.touched.margin?.top && formik.errors.margin?.top}
                    </FormHelperText>
                  </FormControl>
                </div>
                <div className="col-3">
                  <div className="mb-2">
                    <span className="text-lightBlue me-2 text-lightBlue fw-500">Bottom</span>
                    <Tooltip
                      title="Lorem ipsum"
                      placement="top">
                      <img
                        src={info}
                        alt="info"
                        className="c-pointer me-3"
                        width={13.5}
                      />
                    </Tooltip>
                  </div>
                  <FormControl
                    size="small"
                    className="w-100 px-0">
                    <OutlinedInput
                      placeholder="Bottom"
                      name="margin.bottom"
                      type="number"
                      value={formik.values.margin?.bottom}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <FormHelperText error>
                      {formik.touched.margin?.bottom && formik.errors.margin?.bottom}
                    </FormHelperText>
                  </FormControl>
                </div>
                <div className="col-3">
                  <div className="mb-2">
                    <span className="text-lightBlue me-2 text-lightBlue fw-500">Right</span>
                    <Tooltip
                      title="Lorem ipsum"
                      placement="top">
                      <img
                        src={info}
                        alt="info"
                        className="c-pointer me-3"
                        width={13.5}
                      />
                    </Tooltip>
                  </div>
                  <FormControl
                    size="small"
                    className="w-100 px-0">
                    <OutlinedInput
                      placeholder="Right"
                      name="margin.right"
                      type="number"
                      value={formik.values.margin?.right}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <FormHelperText error>
                      {formik.touched.margin?.right && formik.errors.margin?.right}
                    </FormHelperText>
                  </FormControl>
                </div>
                <div className="col-3">
                  <div className="mb-2">
                    <span className="text-lightBlue me-2 text-lightBlue fw-500">Left</span>
                    <Tooltip
                      title="Lorem ipsum"
                      placement="top">
                      <img
                        src={info}
                        alt="info"
                        className="c-pointer me-3"
                        width={13.5}
                      />
                    </Tooltip>
                  </div>
                  <FormControl
                    size="small"
                    className="w-100 px-0">
                    <OutlinedInput
                      placeholder="Right"
                      name="margin.left"
                      type="number"
                      value={formik.values.margin?.left}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <FormHelperText error>
                      {formik.touched.margin?.left && formik.errors.margin?.left}
                    </FormHelperText>
                  </FormControl>
                </div>
              </div>

              <div className="row align-items-center my-4">
                <div className="col-4">
                  <h6 className="text-lightBlue fw-500">Label Size</h6>
                </div>
                <div className="col-8">
                  <ToggleButtonGroup
                    exclusive
                    value={formik.values.size}
                    onChange={(_, size) => {
                      formik.setFieldTouched("size", true);
                      if (size) formik.setFieldValue("size", size);
                    }}
                    aria-label="text alignment"
                    className="productDetails-toggle">
                    <ToggleButton
                      value="small"
                      aria-label="small">
                      <small className="text-lightBlue">Small</small>
                    </ToggleButton>
                    <ToggleButton
                      value="medium"
                      aria-label="medium">
                      <small className="text-lightBlue">Medium</small>
                    </ToggleButton>
                    <ToggleButton
                      value="large"
                      aria-label="large">
                      <small className="text-lightBlue">Large</small>
                    </ToggleButton>
                    <ToggleButton
                      value="x-large"
                      aria-label="extraLarge">
                      <small className="text-lightBlue">Extra Large</small>
                    </ToggleButton>
                  </ToggleButtonGroup>
                </div>
              </div>

              <hr className="hr-grey-6" />
              <div className="row align-items-center">
                <div className="col-12 d-flex justify-content-between">
                  <button
                    type="reset"
                    onClick={closeSwipeableDrawer}
                    className="button-grey-outline py-2 px-4 me-auto">
                    Cancel
                  </button>
                  {/* <button className="button-gradient py-2 px-4 me-auto">Save</button> */}
                  <LoadingButton
                    type="submit"
                    loading={editLabelsBadgesSettingIsLoading}
                    disabled={editLabelsBadgesSettingIsLoading}
                    className="button-gradient ms-auto py-2 px-4 w-auto">
                    <p>Save</p>
                  </LoadingButton>
                </div>
              </div>
            </form>
          )}
        </Box>
      </SwipeableDrawer>
    </>
  );
};

export default ViewLabelsBadgesSettings;
