import msApiSlice from "../../../app/api/msApiSlice";
import { omitNullishKeys } from "../../../utils/helper";

export const orderApiSlice = msApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getOrderCsv: builder.mutation({
      query: (queries) => ({
        url: "/order/getCsv",
        method: "GET",
        params: omitNullishKeys(queries),
      }),
    }),

    getPriceMasterCsv: builder.mutation({
      query: (queries) => ({
        url: "/priceMaster/export",
        method: "GET",
        params: omitNullishKeys(queries),
      }),
    }),

    getOptionsCsv: builder.mutation({
      query: (queries) => ({
        url: "/options/export",
        method: "GET",
        params: omitNullishKeys(queries),
      }),
    }),

    getOptionSetsCsv: builder.mutation({
      query: (queries) => ({
        url: "/sets/export",
        method: "GET",
        params: omitNullishKeys(queries),
      }),
    }),

    getCollectionCsv: builder.mutation({
      query: (queries) => ({
        url: "/collection/export",
        method: "GET",
        params: omitNullishKeys(queries),
      }),
    }),

    getCategoryCsv: builder.mutation({
      query: (queries) => ({
        url: "/category/export",
        method: "GET",
        params: omitNullishKeys(queries),
      }),
    }),

    getSubCategoryCsv: builder.mutation({
      query: (queries) => ({
        url: "/subCategory/export",
        method: "GET",
        params: omitNullishKeys(queries),
      }),
    }),

    getTagsCsv: builder.mutation({
      query: (queries) => ({
        url: "/tags/export",
        method: "GET",
        params: omitNullishKeys(queries),
      }),
    }),

    getProductsCsv: builder.mutation({
      query: (queries) => ({
        url: "/products/export",
        method: "GET",
        params: omitNullishKeys(queries),
      }),
    }),

    getVariantsCsv: builder.mutation({
      query: (queries) => ({
        url: "/variants/export",
        method: "GET",
        params: omitNullishKeys(queries),
      }),
    }),

    getStoresCsv: builder.mutation({
      query: (queries) => ({
        url: "/store/export",
        method: "GET",
        params: omitNullishKeys(queries),
      }),
    }),

    getDerivationsExcel: builder.mutation({
      query: () => ({
        url: "/derivation/export",
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetOrderCsvMutation,
  useGetCategoryCsvMutation,
  useGetOptionsCsvMutation,
  useGetOptionSetsCsvMutation,
  useGetCollectionCsvMutation,
  useGetPriceMasterCsvMutation,
  useGetProductsCsvMutation,
  useGetVariantsCsvMutation,
  useGetSubCategoryCsvMutation,
  useGetTagsCsvMutation,
  useGetStoresCsvMutation,
  useGetDerivationsExcelMutation,
} = orderApiSlice;
