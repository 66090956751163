import store from "../../assets/setting/icons/store.svg";
import folderSmallGrey from "../../assets/icons/folderSmallGrey.svg";
import folder from "../../assets/setting/icons/FolderOpen.svg";
import layer from "../../assets/setting/icons/layers.svg";
import notice from "../../assets/setting/icons/light.svg";
import globe from "../../assets/setting/icons/globe.svg";
import payment from "../../assets/setting/icons/credit-card.svg";
import Logistics from "../../assets/setting/icons/truck.svg";
import checkout from "../../assets/setting/icons/checkout.svg";
import coin from "../../assets/setting/icons/Coins.svg";
import percent from "../../assets/setting/icons/percent.svg";
import policy from "../../assets/setting/icons/policy.svg";
import translate from "../../assets/setting/icons/Translate.svg";

export const SidebarData = [
  {
    title: "Store Details",
    path: "/settings/storeDetails",
    image: store,
  },
  {
    title: "File Manager",
    path: "/settings/filemanager",
    image: folder,
  },
  // {
  //   title: 'Integrations',
  //   path: '/settings/integration',
  //   image: layer,
  // },
  // {
  //   title: "Notifications/Emailers",
  //   path: "/settings/emailers",
  //   image: notice,
  // },
  // {
  //   title: "Domains",
  //   path: "/settings/domains",
  //   image: globe,
  // },
  // {
  //   title: "Payments",
  //   path: "/settings/payments",
  //   image: payment,
  // },
  // {
  //   title: "Logistics",
  //   path: "/settings/logistics",
  //   image: Logistics,
  // },
  {
    title: "Tax and Duties",
    path: "/settings/taxmanager",
    image: percent,
  },
  // {
  //   title: "Checkout",
  //   path: "/settings/checkout",
  //   image: checkout,
  // },
  // {
  //   title: "Currencies",
  //   path: "/settings/currency",
  //   image: coin,
  // },
  // {
  //   title: "Language",
  //   path: "/settings/langauge",
  //   image: translate,
  // },
  // {
  //   title: "Policies",
  //   path: "/settings/policies",
  //   image: policy,
  // },
];
