import { useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";

import Container from "../../../components/Container/Container";
import SideStoreBox from "../../../components/Setting/Store/SideBox";
import { SaveFooterTertiary } from "../../../components/SaveFooter/SaveFooter.js";
import ChangePass from "../PopUp/ChangePass";

import logout from "../../../assets/SocialImage/logout.svg";
import info from "../../../assets/icons/info.svg";
import window from "../../../assets/SocialImage/window.svg";
import laptop from "../../../assets/SocialImage/laptop.svg";
import chrome from "../../../assets/SocialImage/chrome.svg";

const headCells = [
  {
    id: "userName",
    numeric: false,
    disablePadding: true,
    label: "Recent Activities",
  },
  {
    id: "groups",
    numeric: false,
    disablePadding: false,
    label: "Browser",
  },
  {
    id: "location",
    numeric: false,
    disablePadding: false,
    label: "Location",
  },
  {
    id: "orders",
    numeric: false,
    disablePadding: false,
    label: "Action",
  },
];

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("01/09/22 at 9:23pm", "Mumbai, 11, India", 6.0, 24, 4.0),
  createData("01/09/22 at 9:23pm", "Mumbai, 11, India", 6.0, 24, 4.0),
  createData("01/09/22 at 9:23pm", "Mumbai, 11, India", 6.0, 24, 4.0),
  createData("01/09/22 at 9:23pm", "Mumbai, 11, India", 6.0, 24, 4.0),
];

const Notification = () => {
  const [showPop, setShowPop] = useState(false);

  const handleChangePass = () => {
    setShowPop(true);
  };

  const closePopUp = () => {
    setShowPop(false);
  };

  return (
    <div className="border-grey-5 rounded-8 px-4 pt-4 bg-black-15 mt-5">
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <h4 className="page-heading w-auto ps-0 mr-2">Security</h4>
          <Tooltip
            title="Lorem ipsum"
            placement="top">
            <img
              src={info}
              alt="info"
              className="c-pointer"
              width={13.5}
            />
          </Tooltip>
        </div>
      </div>
      <hr className="hr-grey-6 my-3 w-100" />

      <div className="row mb-2">
        <SideStoreBox
          heading={"Password"}
          details={`Lorem ipsum dolor sit amet consectetur adipisicing elit.`}
        />

        <div className="col-lg-8">
          <div className="d-flex justify-content-between align-items-center border-grey-5 rounded-8 bg-black-13 p-4 w-100">
            <small className="text-lightBlue mr-2">
              It has been more than two years since you last updated your password.
            </small>
            <div
              className="button-gradient py-2 px-3"
              onClick={handleChangePass}>
              <small>Change Password</small>
            </div>
          </div>
        </div>
      </div>
      <hr className="hr-grey-6 my-3 w-100" />

      <div className="row mb-2">
        <SideStoreBox
          heading={"Two Factor Authentication"}
          details={`Lorem ipsum dolor sit amet consectetur adipisicing elit.`}
        />
        <div className="col-lg-8">
          <div className="d-flex justify-content-between align-items-center border-grey-5 rounded-8 bg-black-13 p-4">
            <small className="text-lightBlue mr-2">
              Two-step authentication adds a layer of security to your account by using more
              than just your password to log in.
            </small>
            <div className="button-gradient py-2 px-3">
              <small>Turn on</small>
            </div>
          </div>
        </div>
      </div>
      <hr className="hr-grey-6 my-3 w-100" />

      <div className="row mb-3">
        <div className="d-flex col-lg-4 d-flex">
          <div className="col-lg-12 mt-3">
            <h6 className="text-lightBlue fw-500 me-2">Devices</h6>
            <small className="text-grey-6 mt-3 d-block">
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
            </small>
            <button className="button-grey-outline py-2 px-4 mt-4">
              <p>Logout all devices</p>
            </button>
          </div>
        </div>

        <div className="col-lg-8 mb-3">
          <div className="row align-items-start rounded-8 border-grey-5 bg-black-13">
            <TableContainer
              component={Paper}
              sx={{ padding: "25px" }}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow sx={{ background: "#252441" }}>
                    {headCells.map((item) => (
                      <TableCell>
                        <small className="text-lightBlue fs-10">{item.label}</small>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow
                      key={row.name}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                      <TableCell
                        component="th"
                        scope="row">
                        <small className="text-lightBlue fs-10">{row.name}</small>
                      </TableCell>
                      <TableCell>
                        <small className="text-lightBlue fs-10">{row.calories}</small>
                      </TableCell>
                      <TableCell>
                        <small className="text-lightBlue fs-10">
                          <Tooltip
                            title="Chrome"
                            placement="top">
                            <img
                              src={chrome}
                              width={20}
                            />
                          </Tooltip>
                          <img
                            src={laptop}
                            width={20}
                            className="mx-2"
                          />
                          <Tooltip
                            title="Window"
                            placement="top">
                            <img
                              src={window}
                              width={20}
                            />
                          </Tooltip>
                        </small>
                      </TableCell>
                      <TableCell>
                        <small className="text-blue-2 fs-10">
                          <img
                            src={logout}
                            width={15}
                            height={14}
                            className="me-2"
                          />
                          Logout
                        </small>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
      <SaveFooterTertiary />
      <ChangePass
        show={showPop}
        onConfirm={closePopUp}
      />
    </div>
  );
};

export default Notification;
