const NewUserDashboard = () => {

    const handleMigrate = () => {

    }

    return (
        <div className="dashboard-container">
            <div className="dashboard">
                <div className="dashBoard-box">
                    <div
                        className="d-flex justify-content-between align-items-center"
                        onClick={handleMigrate}
                    >
                        <div className="px-2">
                            <h3 className="d-flex align-items-baseline text-lightBlue">
                                Hey
                                <h1 className="text-blue-gradient fw-600 ms-2">Saniya</h1>
                            </h3>
                            <p className="text-lightBlue mt-4">
                                Hey there, great news! We've got updates on your store's performance today, including
                            </p>
                            <p className="d-flex text-lightBlue mt-0">
                                some helpful tips on how to make it even better.
                                <p className="fw-600">
                                    &nbsp;Let's dive in!
                                </p>
                            </p>
                        </div>
                        <div className="d-flex justify-content-end pe-4">
                            <div className="w-10">
                                <h5 className="text-blue-gradient fw-600">
                                    Free trial
                                </h5>
                                <small className="text-grey-6">
                                    When you’re ready, subscribe to publish your site.
                                </small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default NewUserDashboard;