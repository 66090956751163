import { useState } from 'react';
import { Popover } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

import SettingDrawer from '../SettingDrawer/SettingDrawer';
import TableSearch from "../../../../components/TableSearch/TableSearch";
import HomeToggleOption from './HomeToggleOption';
import TopThemeSideBar from './TopThemeSide/TopThemeSide';

import "./ThemeSideNav.scss";

import home from "../../../../assets/icons/theme/home.svg";
import india from "../../../../assets/flag/india.svg";
import globe from "../../../../assets/icons/theme/globe.svg";
import grid from "../../../../assets/icons/theme/grid.svg";
import indiaFlag from "../../../../assets/images/products/indiaFlag.svg";
import usaFlag from "../../../../assets/images/products/usaFlag.svg";
import ukFlag from "../../../../assets/images/products/ukFlag.svg";
import home2 from "../../../../assets/icons/theme/home-02.svg";
import landing from "../../../../assets/icons/theme/file.svg";
import category from "../../../../assets/icons/theme/file-branch.svg";
import collection from "../../../../assets/icons/theme/Outline.svg";
import products from "../../../../assets/icons/theme/Product Tag.svg";

const middleData = [
  { name: "Hero Banner"},
  { name: "Curated Collection"},
  { name: "Best Seller"},
  { name: "Dynamic Section"},
  { name: "Store Locater"},
  { name: "Latest Release"},
  { name: "Kisna Values"},
  { name: "Instagram"}
]

const bottomData = [
  { name: "Kisna" },
  { name: "Footer" }
]

const homeDropdownData = [
  { image: home2, head: "Homepage", subHead: "Primary", dropDown: false},
  { image: collection, head: "Collection Page", subHead: "3 Templates", dropDown: true},
  { image: category, head: "Category Page", subHead: "2 Page", dropDown: true},
  { image: products, head: "Product Page", subHead: "3 Templates", dropDown: true},
  { image: landing, head: "Landing Page", dropDown: false},
  { image: grid, head: "Blog Page", dropDown: false},
  { image: landing, head: "Static Page", dropDown: false},
  { image: grid, head: "Store Locator", dropDown: false},
  { image: grid, head: "Cart Page", dropDown: false},
  { image: grid, head: "Checkout Page", dropDown: false}
]

const flagData = [
  { flag: indiaFlag, name: "India (Default)" },
  { flag: usaFlag, name: "USA" },
  { flag: ukFlag, name: "UK" }
]

const Homepage = () => {
  const [anchorFlagEl, setAnchorFlagEl] = useState(null);
  const openFlag = Boolean(anchorFlagEl);
  const idFlag = openFlag ? "simple-popover" : undefined;

  const handleFlagClick = (event) => {
    setAnchorFlagEl(event.currentTarget);
  };

  const handleFlagClose = () => {
    setAnchorFlagEl(null);
  };

  return (
    <>
        <div
          className="d-flex align-items-center justify-content-between bg-black-20 py-2 px-3 rounded-8 c-pointer"
          style={{width: "80%"}}
          onClick={handleFlagClick}
        >
          <div className='d-flex align-items-center'>
            <img src={home} alt="indiaFlag" height={15} />
            <p className="text-lightBlue mx-2 fw-500">Homepage</p>
          </div>
          <ArrowDropDownIcon sx={{color: "#C8D8FF"}} />
        </div>
        <Popover
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          id={idFlag}
          open={openFlag}
          anchorEl={anchorFlagEl}
          onClose={handleFlagClose}
        >
          <div className="px-1 py-2">
            <div className='border-grey-5 rounded-4 p-1'>
              <TableSearch />
            </div>
            <div className='text-lightBlue p-2 hover-grey mt-1 rounded-4'>
              <KeyboardArrowLeftIcon sx={{fontSize: "16px"}}/>
              <small>Main Menu</small>
            </div>
            <hr className="hr-grey-6 my-1" />
            <div className='homepage-toggle'>
            {homeDropdownData.map((item) => (
              <HomeToggleOption 
                image={item.image} 
                head={item.head} 
                subHead={item.subHead} 
                dropDown={item.dropDown} 
              />
            ))}
            </div>
          </div>
        </Popover>
    </>
  );
};

export default Homepage;