import React, { forwardRef, useState } from "react";
import "../../Products/AllProducts/AllProducts.scss";
import { Link } from "react-router-dom";
// ! COMPONENT IMPORTS
import LabelsBadgesTable from "./LabelsBadgesTable";
import ViewTutorial from "../../../components/ViewTutorial/ViewTutorial";
import ViewLogsDrawer from "../../../components/ViewLogsDrawer/ViewLogsDrawer";
// ! IMAGES IMPORTS
import parameters from "../../../assets/icons/sidenav/parameters.svg";
import GenericVector from "../../../assets/icons/GenericVector.svg";
import cancel from "../../../assets/icons/cancel.svg";
import functionalitySizeChart from "../../../assets/images/functionality/functionalitySizeChart.svg";
// ! MATERIAL IMPORTS
import { Dialog, DialogContent, DialogTitle, Slide, Tooltip } from "@mui/material";
// ! MATERIAL ICONS IMPORT
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ViewLabelsBadgesSettings from "../../../components/ViewLabelsBadgesSettings/ViewLabelsBadgesSettings";

const Transition = forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="up"
      ref={ref}
      {...props}
    />
  );
});

const LabelsBadges = () => {
  const [isOpenCreateDialog, setIsOpenCreateDialog] = useState(false);
  const openCreateDialog = () => setIsOpenCreateDialog(true);
  const closeCreateDialog = () => setIsOpenCreateDialog(false);

  return (
    <div className="container-fluid page">
      <div className="row justify-content-between align-items-center">
        <div className="d-flex w-auto align-items-center px-0">
          <h4 className="page-heading w-auto ps-0 me-2">Labels & Badges</h4>

          <Tooltip
            title="Lorem ipsum"
            placement="top">
            <InfoOutlinedIcon
              sx={{ color: "#c8d8ff", fontSize: 20 }}
              className="c-pointer"
            />
          </Tooltip>
        </div>
        <div className="d-flex align-items-center w-auto pe-0">
          {/* <ViewTutorial /> */}
          <ViewLogsDrawer
            headingName={"Functionality / Labels & Badges"}
            icon={parameters}
          />
          <ViewLabelsBadgesSettings />
          <button
            onClick={openCreateDialog}
            className="button-gradient py-2 px-4 c-pointer">
            <p>+ Create New</p>
          </button>
        </div>
      </div>

      <Dialog
        open={isOpenCreateDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={closeCreateDialog}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="md"
        fullWidth>
        <DialogTitle>
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex flex-column ">
              <h5 className="text-lightBlue fw-500">Select Label or Badge to create</h5>
              {/* <small className="text-grey-6 mt-1 d-block">
                    ⓘ Some Dummy Content to explain
                  </small> */}
            </div>
            <img
              src={cancel}
              alt="cancel"
              width={30}
              onClick={closeCreateDialog}
              className="c-pointer"
            />
          </div>
        </DialogTitle>
        <hr className="hr-grey-6 my-0" />
        <DialogContent className="py-3 px-4">
          <div className="row">
            <div className="col-6 my-3">
              <Link
                to="/functionality/labelsBadges/create/label"
                className="d-flex p-3 rounded-8 hover-back c-pointer text-decoration-none">
                <img
                  src={functionalitySizeChart}
                  alt="productDiscount"
                  width={100}
                />
                <div className="d-flex ms-3 flex-column">
                  <p className="text-lightBlue">Create Label</p>
                  <small className="d-block mt-2 text-grey-6">
                    "New Label" is a label added to the product images
                  </small>
                </div>
              </Link>
            </div>
            <div className="col-6 my-3">
              <Link
                to="/functionality/labelsBadges/create/badge"
                className="d-flex p-3 rounded-8 hover-back c-pointer text-decoration-none">
                <img
                  src={functionalitySizeChart}
                  alt="productDiscount"
                  width={100}
                />
                <div className="d-flex ms-3 flex-column">
                  <p className="text-lightBlue">Create Bagde</p>
                  <small className="d-block mt-2 text-grey-6">
                    "New Bagde" is a bagde added outside the product images
                  </small>
                </div>
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="col-6 my-3">
              <div className="d-flex justify-content-center">
                <div className="position-relative">
                  <span className="position-absolute top-0 start-0 bg-radiant-store ms-2 mt-2 px-3 py-1 rounded-4">
                    Label
                  </span>
                  <img
                    src={GenericVector}
                    alt="generic-product"
                  />
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-start mt-2">
                <div className="ms-5 ps-5">
                  <p>Product Title</p>
                  <p>★ 4.5</p>
                </div>
                <div className="me-5 pe-5">
                  <p>₹2,000</p>
                </div>
              </div>
            </div>
            <div className="col-6 my-3">
              <div className="d-flex justify-content-center">
                <div className="position-relative">
                  <img
                    src={GenericVector}
                    alt="generic-product"
                  />
                </div>
              </div>
              <div className="ms-5 ps-5 my-2">
                <span className="bg-radiant-store ms-2 mt-2 px-3 py-1 rounded-4">Badge</span>
              </div>
              <div className="d-flex justify-content-between align-items-start mt-2">
                <div className="ms-5 ps-5">
                  <p>Product Title</p>
                  <p>★ 4.5</p>
                </div>
                <div className="me-5 pe-5">
                  <p>₹2,000</p>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <LabelsBadgesTable />
    </div>
  );
};

export default LabelsBadges;
