import React from "react";
import { Sankey, Tooltip } from "recharts";
import DemoSankeyLink from "./DemoSankeyLink";
import DemoSankeyNode from "./DemoSankeyNode";
import './sankey-charts.css'

function SankeyDemo({ data }) {
  const colorGradients = data.links.map((link) => {
    return {
      source: '#8b68ff',
      target: "#9faaff"
    };
  });

  return (
    !data?.nodes && !data?.links &&
    <div className="sankey-charts">
      <Sankey
        width={960}
        height={500}
        margin={{ top: 20, bottom: 20 }}
        data={data}
        nodeWidth={20}
        nodePadding={60}
        linkCurvature={0.61}
        iterations={0}
        link={<DemoSankeyLink colorGradients={colorGradients} />}
        node={<DemoSankeyNode containerWidth={960} />}
      >
        <Tooltip />
      </Sankey>
      <br />
    </div>
  );
}

export default SankeyDemo;