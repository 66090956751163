import React, { useEffect, useState } from "react";
import { FormHelperText, TextareaAutosize } from "@mui/material";
import productIcon from "../../../assets/icons/productIcon.svg";
import OrderProductCard from "./OrderProductCard";
import SelectProductSideDrawer from "./SelectProductSideDrawer";

export default function CreateOrderProducts({
  customerNote = "",
  onChange = () => [],
  handleChange = () => {},
  fieldsTouched = {},
  fieldsErrors = {},
}) {
  const [showProductSelection, setShowProductSelection] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);

  useEffect(() => {
    // console.log("selectedProducts", selectedProducts);
    onChange(selectedProducts);
  }, [selectedProducts, onChange]);

  return (
    <div className="bg-black-15 border-grey-5 rounded-8 p-3 row attributes mt-4">
      <div className="col-12 px-0">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <img
              src={productIcon}
              alt="productIcon"
              width={16}
            />
            <h6 className="text-lightBlue fw-500 ms-2">Select Products</h6>
          </div>
          <button
            type="button"
            className="button-gradient py-1 px-4"
            onClick={() => setShowProductSelection(true)}>
            <p>Select Products</p>
          </button>
        </div>
        <SelectProductSideDrawer
          show={showProductSelection}
          selectedProducts={selectedProducts}
          onClose={() => setShowProductSelection(false)}
          onSelection={(pvi) => setSelectedProducts(selectedProducts.concat(pvi))}
        />
      </div>

      <OrderProductCard
        products={selectedProducts}
        removeProduct={(ix) =>
          setSelectedProducts(selectedProducts.filter((_, jx) => ix !== jx))
        }
      />
      <FormHelperText error>{fieldsTouched.items && fieldsErrors.items}</FormHelperText>

      <div className="col-12 px-0 d-flex justify-content-center">
        <hr className="hr-grey-6 w-100 my-3" />
      </div>
      <div className="col-12 mt-2 px-0">
        <div className="d-flex  mb-1">
          <p className="text-lightBlue">Add Note to Customer:</p>
        </div>
        <TextareaAutosize
          minRows={3}
          name="customerNote"
          value={customerNote}
          onChange={handleChange}
          className="col-12"
          placeholder="Type Something"
          style={{
            background: "#15142A",
            color: "#c8d8ff",
            borderRadius: 5,
          }}
        />
      </div>
      {/* <FormControlLabel
        label="Want Gift Wrapping?"
        className="px-0"
        sx={{
          "& .MuiTypography-root": {
            fontSize: "0.75rem",
            color: "#c8d8ff",
          },
        }}
        control={
          <Checkbox
            size="small"
            style={{
              color: "#5C6D8E",
              marginRight: 0,
              width: "auto",
            }}
          />
        }
      />
      {checkedGiftWrap && (
        <div className="col-12 px-0">
          <SearchBorder />
          <div className="row mx-0">
            <div className="d-flex py-3 px-3 bg-black-21 w-auto rounded-8 mt-3">
              <img
                src={product2Icon}
                alt="product2"
                className="rounded-8"
                width={80}
              />
              <div className="d-flex flex-column justify-content-between ms-3">
                <div>
                  <p className="fw-500 text-lightBlue">JWL Exclusive Packaging</p>
                  <small className="text-grey-6 mt-2">#GIFTWRAPPER</small>
                </div>
                <small className="text-blue-2 text-decoration-underline c-pointer">
                  View Image
                </small>
              </div>
            </div>
          </div>
        </div>
      )} */}
    </div>
  );
}
