import { useState } from "react";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  SwipeableDrawer,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { EnhancedTableHead } from "../../components/TableDependencies/TableDependencies";
import { useFormik } from "formik";
import * as Yup from "yup";
import cancelIcon from "../../assets/icons/cancel.svg";
import ringSmall from "../../assets/images/ringSmall.svg";
// import deleteButton from "../../assets/icons/deleteButton.svg";
// import arrowDown from "../../assets/icons/arrowDown.svg";
import {
  useGetAllProductsQuery,
  useGetProductsByIdsQuery,
} from "../../features/products/product/productApiSlice";
// import featureUpload from "../../assets/images/products/featureUpload.svg";
import { TableSearchSecondary } from "../../components/TableSearch/TableSearch";
import editWhiteIcon from "../../assets/icons/editWhite.svg";
import deleteWhiteIcon from "../../assets/icons/deleteWhite.svg";
import AppGenericSelect from "../AppGenericSelect/AppGenericSelect";
import { useGetAllCollectionsQuery } from "../../features/parameters/collections/collectionsApiSlice";
import { useGetAllTagsQuery } from "../../features/parameters/tagsManager/tagsManagerApiSlice";
import {
  useGetAllCategoriesQuery,
  useGetAllSubCategoriesQuery,
} from "../../features/parameters/categories/categoriesApiSlice";
import AsyncImage from "../AsyncImage/AsyncImage";
import moment from "moment/moment";
import { Link } from "react-router-dom";
// import NoDataFound from "../NoDataFound/NoDataFound";

const headCells = [
  {
    id: "productName",
    numeric: false,
    disablePadding: false,
    label: "Product Name",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "price",
    numeric: false,
    disablePadding: false,
    label: "Price",
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: "Action",
  },
];

const initialFilterValue = {
  field: "price",
  operator: "equal",
  value: "",
  itemId: "",
  itemLabel: "",
};

const filterValidationSchema = Yup.object({
  field: Yup.string().required("required"),
  operator: Yup.string().required("required"),
  value: Yup.number()
    .typeError("Please Enter Number")
    .min(0)
    .when("field", {
      is: "price",
      then: (schema) => schema.required("required"),
      otherwise: (schema) => schema.nullable(),
    }),
  itemId: Yup.string().when("field", {
    is: "price",
    then: (schema) => schema.nullable(),
    otherwise: (schema) => schema.required("required"),
  }),
});

export default function AddLinkedProducts({
  inEditMode = false,
  collectionId = "",
  selection = "automated",
  condition = "all",
  filters = [],
  products = [],
  onSelectionChange = () => {},
  onConditionChange = () => {},
  onFiltersChange = () => {},
  onProductsChange = () => {},
}) {
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [searchKey, setSearchKey] = useState("");
  const [debSearchKey, setDebSearchKey] = useState("");

  const { data: productAutomatedData, isSuccess: productAutomatedIsSuccess } =
    useGetAllProductsQuery(
      {
        queries: {
          pageNo,
          pageSize,
          collectionId,
          createdAt: -1,
        },
      },
      { skip: selection === "manual" || !collectionId }
    );

  const automatedProducts = productAutomatedData?.data ?? [];
  const totalAutomatedProducts = productAutomatedData?.totalCount ?? 0;

  const { data: productData, isSuccess: productIsSuccess } = useGetAllProductsQuery(
    {
      queries: {
        pageNo,
        pageSize,
        title: debSearchKey,
        createdAt: -1,
        status: ["active", "in-active", "scheduled"],
      },
    },
    { skip: selection === "automated" }
  );

  const allProducts = productData?.data ?? [];
  const totalProducts = productData?.totalCount ?? 0;

  const { data: productIdsData, isSuccess: productIdIsSuccess } =
    useGetProductsByIdsQuery(products);

  const [showAddCondition, setShowAddCondition] = useState(false);
  const [editingFilterIndex, setEditingFilterIndex] = useState(-1);
  const [showAddSideDrawer, setShowAddSideDrawer] = useState(false);

  // For Filters add and edit validations
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: editingFilterIndex < 0 ? initialFilterValue : filters[editingFilterIndex],
    validationSchema: filterValidationSchema,
    onSubmit: (values, { resetForm }) => {
      if (editingFilterIndex < 0) onFiltersChange(filters.concat(values));
      else onFiltersChange(filters.map((sf, ix) => (editingFilterIndex === ix ? values : sf)));

      setShowAddCondition(false);
      setEditingFilterIndex(-1);
      resetForm();
    },
  });

  const switchSelectionType = (type) => {
    onSelectionChange(type);
    onConditionChange("all");
    onFiltersChange([]);
    onProductsChange([]);

    setEditingFilterIndex(-1);
    setShowAddCondition(false);

    setPageNo(1);
    setPageSize(10);
    setSearchKey("");
    setDebSearchKey("");
  };

  return (
    <div className="row">
      <div className="col-12 my-2">
        {!inEditMode && (
          <RadioGroup
            row
            className="features-radio"
            value={selection}
            onChange={(e) => switchSelectionType(e.target.value)}>
            <FormControlLabel
              value="manual"
              control={<Radio size="small" />}
              label="Manual"
              sx={{
                "& .MuiTypography-root": {
                  fontSize: 13,
                  color: "#c8d8ff",
                  marginRight: 1,
                },
              }}
            />
            <FormControlLabel
              value="automated"
              control={<Radio size="small" />}
              label="Automated"
              sx={{
                "& .MuiTypography-root": {
                  fontSize: 13,
                  color: "#c8d8ff",
                  marginRight: 1,
                },
              }}
            />
          </RadioGroup>
        )}
      </div>
      {selection === "automated" && (
        <>
          <div className="col-12 my-2">
            <div className="bg-black-11 rounded-8 p-3 shadow-sm">
              <div className="d-flex justify-content-between">
                <div className="d-flex align-items-center">
                  <p className="text-lightBlue me-4">Should Match:</p>
                  <RadioGroup
                    row
                    className="features-radio px-0"
                    value={condition}
                    onChange={(e) => onConditionChange(e.target.value)}>
                    <FormControlLabel
                      value="all"
                      control={<Radio size="small" />}
                      label="All Condition"
                      sx={{
                        "& .MuiTypography-root": {
                          fontSize: 13,
                          color: "#c8d8ff",
                        },
                      }}
                    />
                    <FormControlLabel
                      value="any"
                      control={<Radio size="small" />}
                      label="Any Condition"
                      sx={{
                        "& .MuiTypography-root": {
                          fontSize: 13,
                          color: "#c8d8ff",
                        },
                      }}
                    />
                  </RadioGroup>
                </div>
                {!showAddCondition && (
                  <button
                    type="button"
                    className="button-gradient py-1 px-4"
                    onClick={() => setShowAddCondition(true)}>
                    <p>Add Condition</p>
                  </button>
                )}
              </div>
              {filters.length > 0 ? (
                filters.map((sf, ix) => (
                  <div
                    key={ix}
                    className="d-flex my-2 px-3 justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                      <small className="ms-0 text-lightBlue">
                        <span className="text-blue-2 text-capitalize">{sf.field}</span>&nbsp;
                        {getOperatorLabel(sf.operator)}&nbsp;
                        <span className="text-blue-2 text-capitalize">
                          {console.log(sf)}
                          {sf.value || sf.itemLabel}
                        </span>
                      </small>
                    </div>
                    {editingFilterIndex !== ix && (
                      <div className="d-flex align-items-center">
                        <img
                          width={30}
                          alt="editWhite"
                          src={editWhiteIcon}
                          className="mx-1 c-pointer"
                          onClick={() => {
                            setEditingFilterIndex(ix);
                            setShowAddCondition(true);
                          }}
                        />
                        <img
                          width={30}
                          alt="deleteWhite"
                          src={deleteWhiteIcon}
                          className="mx-1 c-pointer"
                          onClick={() => onFiltersChange(filters.filter((_, jx) => ix !== jx))}
                        />
                      </div>
                    )}
                  </div>
                ))
              ) : (
                <div className="d-flex my-2 px-3 justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    <small className="ms-0 text-lightBlue">No Condition Added</small>
                  </div>
                </div>
              )}
              {showAddCondition && (
                <form
                  noValidate
                  className="row">
                  <div className="col-sm-6 col-md-3 mt-3 mb-1 ps-4">
                    <p className="text-lightBlue mb-1">Field</p>
                    <FormControl
                      className="w-100 px-0"
                      size="small">
                      <Select
                        size="small"
                        name="field"
                        value={formik.values.field}
                        onBlur={formik.handleBlur}
                        onChange={(e) => {
                          formik.setFieldValue("field", e.target.value);
                          formik.setFieldValue("operator", "equal");
                          formik.setFieldValue("value", "");
                          formik.setFieldValue("itemId", "");
                          formik.setFieldValue("itemLabel", "");
                        }}>
                        <MenuItem
                          value="price"
                          sx={{ fontSize: 13, color: "#5c6d8e" }}>
                          Price
                        </MenuItem>
                        <MenuItem
                          value="collection"
                          sx={{ fontSize: 13, color: "#5c6d8e" }}>
                          Collection
                        </MenuItem>
                        <MenuItem
                          value="tags"
                          sx={{ fontSize: 13, color: "#5c6d8e" }}>
                          Tags
                        </MenuItem>
                        <MenuItem
                          value="category"
                          sx={{ fontSize: 13, color: "#5c6d8e" }}>
                          Catagory
                        </MenuItem>
                        <MenuItem
                          value="subcategory"
                          sx={{ fontSize: 13, color: "#5c6d8e" }}>
                          Sub Category
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="col-sm-6 col-md-3 mt-3 mb-1">
                    <p className="text-lightBlue mb-1">Operator</p>
                    <FormControl
                      className="w-100 px-0"
                      size="small">
                      <Select
                        name="operator"
                        size="small"
                        value={formik.values.operator}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}>
                        <MenuItem
                          value="equal"
                          sx={{ fontSize: 13, color: "#5c6d8e" }}>
                          Equals
                        </MenuItem>
                        <MenuItem
                          value="notEqual"
                          sx={{ fontSize: 13, color: "#5c6d8e" }}>
                          Not Equals
                        </MenuItem>
                        {formik.values.field === "price" && (
                          <>
                            <MenuItem
                              value="gt"
                              sx={{ fontSize: 13, color: "#5c6d8e" }}>
                              Greater Than
                            </MenuItem>
                            <MenuItem
                              value="lt"
                              sx={{ fontSize: 13, color: "#5c6d8e" }}>
                              Less Than
                            </MenuItem>
                          </>
                        )}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="col-sm-6 col-md-3 mt-3 mb-1">
                    <p className="text-lightBlue mb-1">Value</p>
                    <FormControl className="w-100 px-0">
                      {formik.values.field === "price" && (
                        <OutlinedInput
                          placeholder="Enter Value"
                          name="value"
                          size="small"
                          value={formik.values.value}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          startAdornment={
                            <InputAdornment position="start">
                              <p className="text-lightBlue">₹</p>
                            </InputAdornment>
                          }
                        />
                      )}
                      {formik.values.field === "collection" && (
                        <AppGenericSelect
                          name="itemId"
                          value={formik.values.itemId}
                          dataId="_id"
                          dataLabel="title"
                          onBlur={() => formik.setFieldTouched("itemId", true)}
                          onChange={(_, sel) => {
                            formik.setFieldValue("itemId", sel?._id ?? "");
                            formik.setFieldValue("itemLabel", sel?.title ?? "");
                          }}
                          useGetQuery={useGetAllCollectionsQuery}
                          getQueryFilters={{
                            createdAt: "-1",
                            status: "active,in-active,scheduled",
                          }}
                        />
                      )}
                      {formik.values.field === "tags" && (
                        <AppGenericSelect
                          name="itemId"
                          value={formik.values.itemId}
                          dataId="_id"
                          dataLabel="name"
                          onBlur={() => formik.setFieldTouched("itemId", true)}
                          onChange={(_, sel) => {
                            formik.setFieldValue("itemId", sel?._id ?? "");
                            formik.setFieldValue("itemLabel", sel?.name ?? "");
                          }}
                          useGetQuery={useGetAllTagsQuery}
                          getQueryFilters={{
                            createdAt: "-1",
                            status: "active",
                          }}
                        />
                      )}
                      {formik.values.field === "category" && (
                        <AppGenericSelect
                          name="itemId"
                          value={formik.values.itemId}
                          dataId="_id"
                          dataLabel="name"
                          onBlur={() => formik.setFieldTouched("itemId", true)}
                          onChange={(_, sel) => {
                            formik.setFieldValue("itemId", sel?._id ?? "");
                            formik.setFieldValue("itemLabel", sel?.name ?? "");
                          }}
                          useGetQuery={useGetAllCategoriesQuery}
                          getQueryFilters={{
                            createdAt: "-1",
                            status: "active,in-active,scheduled",
                          }}
                        />
                      )}
                      {formik.values.field === "subcategory" && (
                        <AppGenericSelect
                          name="itemId"
                          value={formik.values.itemId}
                          dataId="_id"
                          dataLabel="name"
                          onBlur={() => formik.setFieldTouched("itemId", true)}
                          onChange={(_, sel) => {
                            formik.setFieldValue("itemId", sel?._id ?? "");
                            formik.setFieldValue("itemLabel", sel?.name ?? "");
                          }}
                          useGetQuery={useGetAllSubCategoriesQuery}
                          getQueryFilters={{
                            createdAt: "-1",
                            status: "active,in-active,scheduled",
                          }}
                        />
                      )}
                    </FormControl>
                  </div>
                  <div className="col-sm-6 col-md-3 mt-3 mb-1">
                    <button
                      type="button"
                      onClick={formik.handleSubmit}
                      className="button-gradient py-1 px-3 w-100 mb-2">
                      <p>Apply</p>
                    </button>
                    <button
                      type="button"
                      className="button-lightBlue-outline py-1 px-3 w-100"
                      onClick={() => {
                        setShowAddCondition(false);
                        setEditingFilterIndex(-1);
                        formik.handleReset();
                      }}>
                      <p>Cancel</p>
                    </button>
                  </div>
                </form>
              )}
            </div>
          </div>
          {!!collectionId && (
            <div className="col-12 my-2">
              <div className="bg-black-11 rounded-8 p-3 shadow-sm">
                <TableContainer>
                  <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby="tableTitle"
                    size="medium">
                    <TableHead>
                      <TableRow>
                        {headCells.slice(0, -1).map((headCell) => {
                          return (
                            <TableCell
                              key={headCell.id}
                              align={headCell.numeric ? "right" : "left"}
                              padding={headCell.disablePadding ? "none" : "normal"}>
                              <p className="text-lightBlue">{headCell.label}</p>
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {automatedProducts.map((row) => {
                        // console.log(row);
                        const defaultImg = row?.mediaUrl?.find((media) => media.isDefault);
                        return (
                          <TableRow
                            hover
                            tabIndex={-1}
                            key={row._id}>
                            <TableCell
                              scope="row"
                              component="th"
                              padding="normal"
                              className="py-0">
                              <Link
                                to={`/products/allProducts/editProduct/${row?._id}`}
                                className="text-decoration-none">
                                <div className="d-flex align-items-center my-2">
                                  <AsyncImage
                                    src={defaultImg?.image}
                                    placeholder={ringSmall}
                                    alt="product"
                                    className="me-2 rounded-4"
                                    height={45}
                                    width={45}
                                  />
                                  <div>
                                    <p className="text-lightBlue fw-600">{row?.title}</p>
                                    <small className="mt-2 text-grey-6">
                                      {row?.inventory?.skuId}
                                    </small>
                                  </div>
                                </div>
                              </Link>
                            </TableCell>
                            <TableCell>
                              <div className="d-block">
                                <div
                                  className="rounded-pill d-flex px-2 py-1 statusBoxWidth"
                                  style={{
                                    background:
                                      row?.status === "active"
                                        ? "#A6FAAF"
                                        : row?.status === "in-active"
                                        ? "#F67476"
                                        : row?.status === "archived"
                                        ? "#C8D8FF"
                                        : "#FEE1A3",
                                  }}>
                                  <small className="text-black fw-500">
                                    {row?.status === "active"
                                      ? "Active"
                                      : row?.status === "in-active"
                                      ? "In-Active"
                                      : row?.status === "archived"
                                      ? "Archived"
                                      : "Scheduled"}
                                  </small>
                                </div>
                                {row?.status === "scheduled" && (
                                  <div>
                                    <small className="text-blue-2">
                                      {row?.startDate && (
                                        <>for {moment(row?.startDate).format("DD/MM/YYYY")}</>
                                      )}
                                      {row?.startDate && row?.endDate && " "}
                                      {row?.endDate && (
                                        <>till {moment(row?.endDate).format("DD/MM/YYYY")}</>
                                      )}
                                    </small>
                                  </div>
                                )}
                              </div>
                            </TableCell>
                            <TableCell>
                              <div className="d-flex align-items-center">
                                <p className="text-lightBlue">
                                  {row?.price?.price ?? "Depends on variant"}
                                </p>
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          )}
        </>
      )}
      {selection === "manual" && (
        <>
          <div className="col-12 my-2">
            <div className="bg-black-11 rounded-8 p-3 shadow-sm">
              <div className="d-flex justify-content-center">
                <button
                  type="button"
                  className="button-gradient py-1 px-4"
                  onClick={() => setShowAddSideDrawer(true)}>
                  <p>Add Products</p>
                </button>
              </div>
            </div>
            <SwipeableDrawer
              anchor="right"
              open={showAddSideDrawer}
              onOpen={() => setShowAddSideDrawer(true)}
              onClose={() => setShowAddSideDrawer(false)}>
              <div className="d-flex justify-content-between py-3 ps-3 pe-2 me-1 align-items-center">
                <h6 className="text-lightBlue">Select Products</h6>
                <img
                  src={cancelIcon}
                  alt="cancel"
                  className="c-pointer add-product-padding"
                  onClick={() => setShowAddSideDrawer(false)}
                />
              </div>
              <hr className="hr-grey-6 mt-3 mb-3" />
              <div className="px-3">
                <TableSearchSecondary
                  value={searchKey}
                  onChange={(val) => setDebSearchKey(val)}
                  onSearchValueChange={(val) => setSearchKey(val)}
                />
              </div>
              {productIsSuccess && (
                <TableContainer className="mt-3">
                  <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby="tableTitle"
                    size="medium">
                    <EnhancedTableHead
                      numSelected={products.length}
                      order={"asc"}
                      orderBy={"productName"}
                      onSelectAllClick={(e) =>
                        onProductsChange(e.target.checked ? allProducts.map((p) => p._id) : [])
                      }
                      onRequestSort={Date}
                      rowCount={allProducts.length}
                      headCells={headCells}
                    />
                    <TableBody>
                      {allProducts.map((row) => {
                        const isItemSelected = products.includes(row._id);
                        const defaultImg = row?.mediaUrl?.find((media) => media.isDefault);
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row._id}
                            selected={isItemSelected}>
                            <TableCell padding="checkbox">
                              <Checkbox
                                color="primary"
                                checked={isItemSelected}
                                size="small"
                                onClick={(event) =>
                                  onProductsChange(
                                    event.target.checked
                                      ? products.concat(row._id)
                                      : products.filter((id) => row._id !== id)
                                  )
                                }
                                style={{
                                  color: "#5C6D8E",
                                  marginRight: 0,
                                }}
                              />
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              padding="none">
                              <div className="d-flex align-items-center my-2">
                                <AsyncImage
                                  src={defaultImg?.image}
                                  placeholder={ringSmall}
                                  alt="product"
                                  className="me-2 rounded-4"
                                  height={45}
                                  width={45}
                                />
                                <div>
                                  <p className="text-lightBlue fw-600">{row?.title}</p>
                                  <small className="mt-2 text-grey-6">
                                    {row?.inventory?.skuId}
                                  </small>
                                </div>
                              </div>
                            </TableCell>
                            <TableCell>
                              <div className="d-block">
                                <div
                                  className="rounded-pill d-flex px-2 py-1 statusBoxWidth"
                                  style={{
                                    background:
                                      row?.status === "active"
                                        ? "#A6FAAF"
                                        : row?.status === "in-active"
                                        ? "#F67476"
                                        : row?.status === "archived"
                                        ? "#C8D8FF"
                                        : "#FEE1A3",
                                  }}>
                                  <small className="text-black fw-500">
                                    {row?.status === "active"
                                      ? "Active"
                                      : row?.status === "in-active"
                                      ? "In-Active"
                                      : row?.status === "archived"
                                      ? "Archived"
                                      : "Scheduled"}
                                  </small>
                                </div>
                                {row?.status === "scheduled" && (
                                  <div>
                                    <small className="text-blue-2">
                                      {row?.startDate && (
                                        <>for {moment(row?.startDate).format("DD/MM/YYYY")}</>
                                      )}
                                      {row?.startDate && row?.endDate && " "}
                                      {row?.endDate && (
                                        <>till {moment(row?.endDate).format("DD/MM/YYYY")}</>
                                      )}
                                    </small>
                                  </div>
                                )}
                              </div>
                            </TableCell>
                            <TableCell>
                              <div className="d-flex align-items-center">
                                <p className="text-lightBlue">
                                  {row?.price?.price ? row?.price?.price : "Depends on variant"}
                                </p>
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
              <TablePagination
                rowsPerPageOptions={[10, 20, 30]}
                component="div"
                count={totalProducts}
                rowsPerPage={pageSize}
                page={pageNo - 1}
                onPageChange={(_, page) => setPageNo(page + 1)}
                onRowsPerPageChange={(e) => setPageSize(e.target.value)}
                className="table-pagination"
              />
            </SwipeableDrawer>
          </div>
          <div className="col-12 my-2">
            <TableContainer>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size="medium">
                <TableHead>
                  <TableRow>
                    {headCells.map((headCell) => {
                      return (
                        <TableCell
                          key={headCell.id}
                          align={headCell.numeric ? "right" : "left"}
                          padding={headCell.disablePadding ? "none" : "normal"}>
                          <p className="text-lightBlue">{headCell.label}</p>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {productIdsData.map((row) => {
                    // console.log(row);
                    const defaultImg = row?.mediaUrl?.find((media) => media.isDefault);
                    return (
                      <TableRow
                        hover
                        tabIndex={-1}
                        key={row._id}>
                        <TableCell
                          scope="row"
                          component="th"
                          padding="normal"
                          className="py-0">
                          <Link
                            to={`/products/allProducts/editProduct/${row?._id}`}
                            className="text-decoration-none">
                            <div className="d-flex align-items-center my-2">
                              <AsyncImage
                                src={defaultImg?.image}
                                placeholder={ringSmall}
                                alt="product"
                                className="me-2 rounded-4"
                                height={45}
                                width={45}
                              />
                              <div>
                                <p className="text-lightBlue fw-600">{row?.title}</p>
                                <small className="mt-2 text-grey-6">
                                  {row?.inventory?.skuId}
                                </small>
                              </div>
                            </div>
                          </Link>
                        </TableCell>
                        <TableCell>
                          <div className="d-block">
                            <div
                              className="rounded-pill d-flex px-2 py-1 statusBoxWidth"
                              style={{
                                background:
                                  row?.status === "active"
                                    ? "#A6FAAF"
                                    : row?.status === "in-active"
                                    ? "#F67476"
                                    : row?.status === "archived"
                                    ? "#C8D8FF"
                                    : "#FEE1A3",
                              }}>
                              <small className="text-black fw-500">
                                {row?.status === "active"
                                  ? "Active"
                                  : row?.status === "in-active"
                                  ? "In-Active"
                                  : row?.status === "archived"
                                  ? "Archived"
                                  : "Scheduled"}
                              </small>
                            </div>
                            {row?.status === "scheduled" && (
                              <div>
                                <small className="text-blue-2">
                                  {row?.startDate && (
                                    <>for {moment(row?.startDate).format("DD/MM/YYYY")}</>
                                  )}
                                  {row?.startDate && row?.endDate && " "}
                                  {row?.endDate && (
                                    <>till {moment(row?.endDate).format("DD/MM/YYYY")}</>
                                  )}
                                </small>
                              </div>
                            )}
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="d-flex align-items-center">
                            <p className="text-lightBlue">
                              {row?.price?.price ?? "Depends on variant"}
                            </p>
                          </div>
                        </TableCell>
                        <TableCell>
                          <Button
                            color="primary"
                            variant="text"
                            onClick={() =>
                              onProductsChange(products.filter((id) => row._id !== id))
                            }>
                            <span className="text-red-5">Remove</span>
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </>
      )}
    </div>
  );
}
// price,
// greaterPrice,
// lowerPrice,
// notEqualPrice,
// collectionId,
// subCategoryId,
// vendorId,
// categoryId,
// tagManagerId;
// function conditionQueriesConverter(conditions = []) {
//   const queries = { status: ["active", "in-active", "scheduled"] };
//   for (const { field, operator, value, itemId } of conditions) {
//     switch (field) {
//       case "price":
//         switch (operator) {
//           case "equal":
//             queries["price"] = value;
//             break;
//           case "notEqual":
//             queries["notEqualPrice"] = value;
//             break;
//           case "gt":
//             queries["greaterPrice"] = value;
//             break;
//           case "lt":
//             queries["lowerPrice"] = value;
//             break;
//           default:
//             break;
//         }
//         break;
//       case "collection":
//         queries["collectionId"] = itemId;
//         break;
//       case "tags":
//         queries["tagManagerId"] = itemId;
//         break;
//       case "category":
//         queries["categoryId"] = itemId;
//         break;
//       case "subcategory":
//         queries["subCategoryId"] = itemId;
//         break;
//       default:
//         break;
//     }
//   }
//   return queries;
// }

function getOperatorLabel(op = "") {
  switch (op) {
    case "equal":
      return "is equal to";

    case "notEqual":
      return "is not equal to";

    case "gt":
      return "is greater than";

    case "lt":
      return "is less than";

    default:
      return "";
  }
}
