import apiSlice from "../../../app/api/apiSlice";
import { omitEmptyKeys } from "../../../utils/helper";

export const customerTimelineApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createCustomerTimeline: builder.mutation({
      query: (data) => ({
        url: "/customerTimeline",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["CustomerTimeLine", "Logs"],
    }),
    getAllCustomerTimeline: builder.query({
      query: (queries) => ({
        url: "/customerTimeline",
        params: omitEmptyKeys(queries),
      }),
      providesTags: ["CustomerTimeLine"],
    }),
  }),
});

export const { useCreateCustomerTimelineMutation, useGetAllCustomerTimelineQuery } =
  customerTimelineApiSlice;
