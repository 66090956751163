import apiSlice from "../../../app/api/apiSlice";

export const emailerApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    sendInvoice: builder.mutation({
      query: (data) => ({
        url: "/emailers/invoice",
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const { useSendInvoiceMutation } = emailerApiSlice;
