import { forwardRef } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  Tooltip
} from "@mui/material";

// import "./PopUpBox.scss";
import cancel from "../../../../assets/icons/closeModal.svg";
import info from "../../../../assets/icons/info.svg";
import contact from "../../../../assets/setting/icons/contact.png"
import email from "../../../../assets/setting/icons/email.svg"
import phone from "../../../../assets/setting/icons/phone.svg"

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ContactStore = ({ onConfirm, show, extensionClick }) => {
    const saveGroups = () => {
        onConfirm()
    }

    return (
        <Dialog
            open={show}
            TransitionComponent={Transition}
            keepMounted
            onClose={onConfirm}
            aria-describedby="alert-dialog-slide-description"
            maxWidth="sm"
            fullWidth={true}
        >
            <div className="bg-contact py-3">
                <DialogTitle className="d-flex flex-row-reverse">
                    <img
                        src={cancel}
                        alt="cancel"
                        width={45}
                        onClick={onConfirm}
                        className="c-pointer"
                    />
                </DialogTitle>
                <DialogContent className="d-flex justify-content-center">
                    <img
                        src={contact}
                        alt="cancel"
                        width={200}
                    />
                </DialogContent>
                <div className="d-flex justify-content-center">
                    <h4 className="text-lightBlue fw-600 me-2">
                        Contact Support
                    </h4>
                </div>
                <div className="d-flex justify-content-center px-3">
                    <small className="text-grey-6 mt-2 d-block py-4 px-5">
                        Your free trial has now expired. To continue using our platform, you'll need to upgrade to one of our paid plans. Our paid plans offer more advanced features, increased storage, and priority support from our team.
                    </small>
                </div>
                <div className="d-flex justify-content-center px-3 mb-4">
                    <div className="mr-2">
                        <img src={phone} width={16}/>
                        <small className="text-lightBlue mr-1">
                            +91 9838837242
                        </small>
                    </div>
                    <div>
                        <img src={email} width={16}/>
                        <small className="text-lightBlue mr-1">
                            email@unthread.com
                        </small>
                    </div>
                </div>
                <div className="d-flex justify-content-center">
                    <div className="button-gradient py-2 px-5 ms-3" onClick={extensionClick}>
                        <p>Request for extention</p>
                    </div>
                </div>
                <div className="d-flex justify-content-center" onClick={onConfirm}>
                    <small className="text-lightBlue py-4 c-pointer">
                        {" "}Back
                    </small>
                </div>
            </div>
        </Dialog>
    );
};

export default ContactStore;