import { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  OutlinedInput,
} from "@mui/material";
import cancel from "../../../../assets/icons/cancel.svg";
import { useCheckFolderNameQuery } from "../../../../features/settings/filemanager/filemanagerApiSlice";

export default function NameRenameDialog({
  isOpen = false,
  isLoading = false,
  headingText = "",
  subText = "",
  labelText = "",
  folderName = "",
  buttonText = "",
  imageSrc = "",
  onClose = () => {},
  onAction = () => {},
}) {
  return (
    <Dialog fullWidth maxWidth="sm" open={isOpen} onClose={onClose}>
      <InnerContent
        isLoading={isLoading}
        headingText={headingText}
        subText={subText}
        labelText={labelText}
        folderName={folderName}
        buttonText={buttonText}
        imageSrc={imageSrc}
        onClose={onClose}
        onAction={onAction}
      />
    </Dialog>
  );
}

function InnerContent({
  isLoading = false,
  headingText = "",
  subText = "",
  labelText = "",
  folderName = "",
  buttonText = "",
  imageSrc = "",
  onClose = () => {},
  onAction = () => {},
}) {
  const [fname, setFname] = useState(folderName);
  const { data: folderExists } = useCheckFolderNameQuery(fname);

  return (
    <>
      <DialogTitle>
        <div className="d-flex justify-content-between align-items-center">
          <div className="m-0 p-0">
            {!!headingText && (
              <h4 className="text-lightBlue fw-500">{headingText}</h4>
            )}
            {!!subText && (
              <small className="text-grey-6 fw-200">{subText}</small>
            )}
          </div>
          <img
            src={cancel}
            alt="cancel"
            width={24}
            onClick={onClose}
            className="c-pointer"
          />
        </div>
      </DialogTitle>
      <hr className="hr-grey-6 my-0" />
      <DialogContent className="p-3">
        <div className="row align-items-center">
          <div className="col-auto">
            <div className="folder-icon rounded-8 p-4 m-3">
              <img
                // src={folderLargePurple}
                src={imageSrc}
                alt="icon"
                style={{ objectFit: "contain", overflow: "hidden" }}
                className="me-2 rounded-4"
                height={66}
                width={66}
              />
            </div>
          </div>
          <div className="col">
            <label className="text-lightBlue mb-2">{labelText}</label>
            <OutlinedInput
              fullWidth
              size="small"
              value={fname}
              onChange={(e) => setFname(e.target.value)}
            />
            {(fname === "" || Boolean(folderExists)) && (
              <FormHelperText error>
                {!fname ? "Name is Empty" : "Folder Name already exist"}
              </FormHelperText>
            )}
          </div>
        </div>
      </DialogContent>
      <hr className="hr-grey-6 my-0" />
      <DialogActions className="d-flex justify-content-between px-4 py-3">
        <button
          onClick={onClose}
          disabled={isLoading}
          className="button-grey-outline py-2 px-4"
        >
          <p className="text-lightBlue">Cancel</p>
        </button>
        {/* <button
          className="button-lightBlue-outline py-2 px-4"
          onClick={onClose}>
          <p className="text-lightBlue">Cancel</p>
        </button> */}
        <button
          disabled={!fname || !!folderExists || isLoading}
          onClick={() => onAction(fname)}
          className="button-gradient py-2 px-4"
        >
          <p>{buttonText}</p>
        </button>
      </DialogActions>
    </>
  );
}
