import { useEffect, useMemo, useState } from "react";
import {
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
} from "@mui/material";
import Heading from "../../components/Headers/DashboardHeader";
import {
  useGetTopSellingCategoryAnalyticsQuery,
  useGetTopSellingCollectionAnalyticsQuery,
} from "../../features/analytics/insightsApiSlice";
import {
  useGetPageTrafficQuery,
  useGetTopDevicesUsedQuery,
} from "../../features/analytics/analyticsApiSlice";
import moment from "moment/moment";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import NoDataFound from "../../components/NoDataFound/NoDataFound";
import { useGetInsitesAnalyticsQuery } from "../../features/posthogAnalytics/insightsApiSlice";
import { Layer, Rectangle, Sankey, Tooltip } from "recharts";
import SankeyDemo from "./sankey";

const timeData = [
  { value: "week", name: "This Week" },
  { value: "month", name: "This Month" },
  { value: "year", name: "This Year" },
  { value: "", name: "Custom" },
];

const nowMoment = moment();

export default function TopPageVisit() {
  const {
    data: insitesData,
    isSuccess: insitesIsSuccess,
    isLoading: insitesIsLoading,
    error: insitesError,
  } = useGetInsitesAnalyticsQuery({
    limit: 10,
    short_id: "iklEcOmw"
  })
  const [topUrls, setTopUrls] = useState([]);
  const [timeFrame, setTimeFrame] = useState("week");
  const [fromDate, setFromDate] = useState(moment().subtract(1, "week").format("YYYY-MM-DD"));
  const [toDate, setToDate] = useState(nowMoment.format("YYYY-MM-DD"));

  const filter = useMemo(() => {
    if (!timeFrame) return { to: toDate, from: fromDate };
    else return { type: timeFrame };
  }, [fromDate, timeFrame, toDate]);

  const { data = [] } = useGetPageTrafficQuery(filter);

  const onTimeFrameChange = (val) => {
    switch (val) {
      case "week":
        setTimeFrame("week");
        setFromDate(moment().subtract(1, "week").format("YYYY-MM-DD"));
        setToDate(moment().format("YYYY-MM-DD"));
        break;
      case "month":
        setTimeFrame("month");
        setFromDate(moment().subtract(1, "month").format("YYYY-MM-DD"));
        setToDate(moment().format("YYYY-MM-DD"));
        break;
      case "year":
        setTimeFrame("year");
        setFromDate(moment().subtract(1, "year").format("YYYY-MM-DD"));
        setToDate(moment().format("YYYY-MM-DD"));
        break;

      default:
        setTimeFrame("");
        break;
    }
  };

  // function filterPathname(data) {
  //   // Define the base URL prefix (this part is common for all base URLs)
  //   const baseUrl = "https://kisna-prod-kisna-web.xzmowh.easypanel.host";

  //   // Object to accumulate results by URL
  //   const urlMap = {};

  //   data.result.forEach((item) => {
  //     const targetUrl = item.target;
  //     let trimmedUrl = targetUrl;

  //     // Check if the URL starts with any of the base URL prefixes like "2_", "3_", "4_", "5_"
  //     const prefixRegex = /^\d+_/;  // Matches "2_", "3_", "4_", etc.
  //     const match = targetUrl.match(prefixRegex);

  //     if (match) {
  //       // If there's a prefix (like "2_", "3_", "4_"), remove it
  //       trimmedUrl = targetUrl.replace(match[0], '');  // Remove the numeric prefix
  //     }

  //     // Remove the base URL part if it exists
  //     if (trimmedUrl.startsWith(baseUrl)) {
  //       trimmedUrl = trimmedUrl.substring(baseUrl.length);
  //     }

  //     // If nothing is left after removing the base URL, set it to "/"
  //     if (trimmedUrl === "") {
  //       trimmedUrl = "/";
  //     }

  //     // If the URL is already in the map, accumulate the value
  //     if (urlMap[trimmedUrl]) {
  //       urlMap[trimmedUrl] += item.value;
  //     } else {
  //       // Otherwise, add a new entry with the value
  //       urlMap[trimmedUrl] = item.value;
  //     }
  //   });

  //   // Convert the accumulated object into an array of results
  //   return Object.keys(urlMap).map((page) => ({
  //     page,
  //     currentValue: urlMap[page],
  //   }));
  // }

  function filterPathname(data) {
    if (!data || !data.result) {
      return { nodes: [], links: [] };
    }

    const baseUrl = "https://kisna-prod-kisna-web.xzmowh.easypanel.host";
    const urlMap = {};
    const urlMap2 = {};
    const nodes = [];
    const nodes2 = [];
    const links = [];
    let nodeIndex = 0;

    // Function to process URLs by removing prefixes, base URL, and query parameters
    const processUrl = (url) => {
      let trimmedUrl = url;
      const prefixRegex = /^\d+_/; // Matches prefixes like "2_", "3_", etc.

      // Remove numeric prefix
      const match = url.match(prefixRegex);
      if (match) {
        trimmedUrl = url.replace(match[0], ''); // Remove the numeric prefix
      }

      // Remove the base URL
      if (trimmedUrl.startsWith(baseUrl)) {
        trimmedUrl = trimmedUrl.substring(baseUrl.length);
      }

      // Remove query parameters
      if (trimmedUrl.includes("?")) {
        trimmedUrl = trimmedUrl.split("?")[0]; // Remove everything after "?"
      }

      // Replace empty paths with "/"
      if (trimmedUrl === "") {
        trimmedUrl = "/";
      }

      return trimmedUrl;
    };

    // Process data to collect unique nodes and map them to indices
    [...data.result.slice(0, 9)].forEach((item) => {
      const sourceUrl = processUrl(item.source);

      // Add source to nodes if not already present
      if (!(sourceUrl in urlMap)) {
        urlMap[sourceUrl] = nodeIndex;
        nodes.push({ name: sourceUrl });
        nodeIndex++;
      }
    });
    nodeIndex = 0;
    [...data.result.slice(0, 9)].forEach((item) => {
      const sourceUrl = processUrl(item.source);
      const targetUrl = processUrl(item.target);

      // Add target to nodes if not already present
      if (!(targetUrl in urlMap2)) {
        urlMap2[targetUrl] = (nodes.length) + nodeIndex;
        nodes2.push({ name: targetUrl });
        nodeIndex++;
      }

      console.log("🚀 ~ data.result.forEach ~ urlMap:", urlMap)
      console.log("🚀 ~ data.result.forEach ~ urlMap2:", urlMap2)
      // Add link between source and target
      const sourceIndex = urlMap[sourceUrl];
      const targetIndex = urlMap2[targetUrl];

      const existingLink = links.find(
        (link) => link.source === sourceIndex && link.target === targetIndex
      );

      if (existingLink) {
        // If a link already exists, sum up the values
        existingLink.value += item.value;
      } else {
        // Otherwise, create a new link
        links.push({
          source: sourceIndex,
          target: targetIndex,
          value: item.value,
        });
      }
    });

    return {
      nodes: [
        ...nodes,
        ...nodes2
      ], links
    };
  }

  useEffect(() => {
    if (insitesData?.results?.[0]) {
      const urlListData = insitesData?.results[0] && filterPathname(insitesData?.results[0])
      urlListData && setTopUrls([urlListData])
    }
  }, [insitesData, insitesIsLoading])

  return (
    <div className="col-12 mt-4">
      <div className="bg-button-3 border-grey-5 rounded-8 px-4 pt-4 pb-3">
        <Heading
          heading={"Top Pages"}
        // viewMore={"View More"}
        />
        <div className="d-flex align-items-center my-3">
          {timeData.map((item) => (
            <Chip
              key={item.value}
              onClick={() => onTimeFrameChange(item.value)}
              variant={timeFrame === item.value ? "filled" : "outlined"}
              label={item.name}
              className="mx-2"
            />
          ))}
          {!timeFrame && (
            <>
              <div className="mx-2">
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DesktopDatePicker
                    maxDate={moment()}
                    value={moment(fromDate)}
                    onChange={(newValue) => setFromDate(newValue.format("YYYY-MM-DD"))}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>
              <div className="mx-2">
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DesktopDatePicker
                    maxDate={moment()}
                    value={moment(toDate)}
                    onChange={(newValue) => setToDate(newValue.format("YYYY-MM-DD"))}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>
            </>
          )}
        </div>
        <TableContainer>
          {topUrls.length > 0 ? (
            // <Table>
            //   <TableBody>
            //     {topUrls.slice(0, 10).map((row) => (
            //       <TableRow key={row.page}>
            //         <TableCell
            //           component="th"
            //           scope="row">
            //           <small className="text-capitalize text-blue-gradient-2 fw-600">
            //             {row.page}
            //           </small>
            //         </TableCell>
            //         <TableCell align="right">
            //           <small className="text-lightBlue fw-400">{row.currentValue} Visits</small>
            //         </TableCell>
            //       </TableRow>
            //     ))}
            //   </TableBody>
            // </Table>
            // <Sankey
            //   width={960}
            //   height={500}
            //   data={topUrls[0]}
            //   margin={{ left: 50, right: 50, top: 20, bottom: 20 }}
            //   // node={{
            //   //   // Customize node appearance
            //   //   stroke: "#003f5c",
            //   //   strokeWidth: 2,
            //   // }}
            //   node={<MyCustomNode />}
            //   link={{
            //     // Customize link appearance
            //     stroke: "#bc5090",
            //     strokeOpacity: 0.3,
            //   }}
            // >
            //   <Tooltip />
            // </Sankey>
            <SankeyDemo data={topUrls[0]} />
          ) : (
            <NoDataFound />
          )}
        </TableContainer>
      </div>
    </div>
  );
}

const MyCustomNode = ({
  x,
  y,
  width,
  height,
  index,
  payload,
  containerWidth,
}) => {
  const isOut = x + width + 6 > containerWidth;
  return (
    <Layer key={`CustomNode${index}`}>
      <Rectangle
        x={x}
        y={y}
        width={width}
        height={height}
        fill="#5192ca"
        fillOpacity="1"
      />
      <text
        textAnchor={isOut ? 'end' : 'start'}
        x={isOut ? x - 6 : x + width + 6}
        y={y + height / 2}
        fontSize="14"
        stroke="#333"
      >
        {payload.name}
      </text>
      <text
        textAnchor={isOut ? 'end' : 'start'}
        x={isOut ? x - 6 : x + width + 6}
        y={y + height / 2 + 13}
        fontSize="12"
        stroke="#333"
        strokeOpacity="0.5"
      >
        {`${payload.value}k`}
      </text>
    </Layer>
  );
};