import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  TextareaAutosize,
  Tooltip,
} from "@mui/material";
import { forwardRef, useMemo, useState } from "react";
import cancel from "../../assets/icons/cancel.svg";
import bluedart from "../../assets/icons/bluedart.png";
import { EnhancedTableHead } from "../../components/TableDependencies/TableDependencies";
import AsyncImage from "../../components/AsyncImage/AsyncImage";
import ringSmall from "../../assets/images/ringSmall.svg";
import info from "../../assets/icons/info.svg";
import * as Yup from "yup";
import { useFormik } from "formik";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import {
  useCreateShipmentMutation,
  useGetShipmentPricingQuery,
} from "../../features/orders/shippingApiSlice";
import { useDispatch } from "react-redux";
import { showError } from "../../features/snackbar/snackbarAction";

const headCells = [
  {
    id: "product",
    numeric: false,
    disablePadding: false,
    label: "Product",
  },
  {
    id: "itemId",
    numeric: false,
    disablePadding: false,
    label: "Item ID",
  },
  {
    id: "location",
    numeric: false,
    disablePadding: false,
    label: "Location",
  },
];

const Transition = forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="up"
      ref={ref}
      {...props}
    />
  );
});

const DUMMY_PACKEGES = [];

const validationSchema = Yup.object({
  items: Yup.array().of(Yup.string()).min(1, "Min 1 Product Required"),
  height: Yup.number().typeError("Please Enter Number").moreThan(0).required("required"),
  width: Yup.number().typeError("Please Enter Number").moreThan(0).required("required"),
  length: Yup.number().typeError("Please Enter Number").moreThan(0).required("required"),
  weight: Yup.number().typeError("Please Enter Number").moreThan(0).required("required"),
  dropPincode: Yup.string().required(),
  orderId: Yup.string().required(),
  pickupDate: Yup.string().required(),
  note: Yup.string(),
});

export default function CreateShippingLabelDialog({
  order = null,
  item = null,
  show = false,
  onClose = () => {},
}) {
  const dispatch = useDispatch();
  const [labelData, setLabelData] = useState(null);
  // const [selected, setSelected] = useState([]);

  // const selectableItems = useMemo(
  //   () => order?.orders?.filter?.((o) => o?.status === "processing") ?? [],
  //   [order]
  // );

  const [createShipment, { isLoading }] = useCreateShipmentMutation();

  const formik = useFormik({
    initialValues: {
      items: [item?._id],
      dropPincode: order?.address?.shipping?.pinCode,
      orderId: order?._id,
      pickupDate: dayjs().format("YYYY-MM-DD"),
      note: "",
      length: "",
      width: "",
      height: "",
      weight: "",
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // console.log(values);
      createShipment(values)
        .unwrap()
        .then((data) => {
          console.log("label data", data);
          setLabelData(data?.data?.[0]);
        })
        .catch((e) => {
          dispatch(showError({ message: e?.data?.message ?? "Something went wrong" }));
        });
    },
  });

  const { data } = useGetShipmentPricingQuery(formik.values, { skip: !formik.isValid });
  const shippingCharge = data?.data?.[0]?.[0]?.preference_array?.[0]?.shipping_charge ?? 0;

  const closeDialog = () => {
    if (!isLoading) {
      formik.resetForm();
      setLabelData(null);
      onClose();
    }
  };

  const defaultImg = item?.item?.product?.mediaUrl?.find?.((media) => media.isDefault);

  return (
    <Dialog
      open={show}
      TransitionComponent={Transition}
      keepMounted
      onClose={closeDialog}
      maxWidth="md"
      fullWidth>
      {!labelData ? (
        <>
          <DialogTitle>
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex flex-column ">
                <h5 className="text-lightBlue fw-500">Create Shipment</h5>
                <small className="text-grey-6 mt-1 d-block">
                  {order?.orderID} - {item?.checkoutID}
                </small>
              </div>
              <img
                src={cancel}
                alt="cancel"
                width={30}
                onClick={closeDialog}
                className="c-pointer"
              />
            </div>
          </DialogTitle>

          <hr className="hr-grey-6 my-0" />

          <DialogContent className="py-3 px-4">
            <div className="row mb-4">
              <div className="col-12">
                <p className="text-grey-6 fw-800 mb-2">Shipping to</p>

                <p className="text-lightBlue fw-500 mb-1">
                  {order?.customer?.firstName ?? order?.address?.shipping?.firstName ?? "Guest"}{" "}
                  {order?.customer?.lastName ?? order?.address?.shipping?.lastName ?? "User"}
                </p>

                <small className="text-lightBlue mt-1">
                  {order?.address?.shipping?.line1} {order?.address?.shipping?.line2} -{" "}
                  {order?.address?.shipping?.city?.name},{" "}
                  {order?.address?.shipping?.state?.name} - {order?.address?.shipping?.pinCode},{" "}
                  {order?.address?.shipping?.country?.name}
                </small>
              </div>
            </div>

            <hr className="hr-grey-6 my-2" />

            <div className="row my-4">
              <div className="col-12">
                <p className="text-grey-6 fw-800 mb-2">Select Items</p>
              </div>
              <div className="col-12">
                <div className="d-flex align-items-center bg-black-10 border-grey-5 rounded-8 p-3">
                  <AsyncImage
                    src={defaultImg?.image}
                    placeholder={ringSmall}
                    alt="product"
                    className="me-2 rounded-4"
                    height={45}
                    width={45}
                  />
                  <div>
                    <p className="text-lightBlue fw-600">{item?.item?.product?.title}</p>
                    <small className="mt-2 text-grey-6">{item?.item?.variant?.title}</small>
                  </div>
                </div>
              </div>
            </div>

            <hr className="hr-grey-6 my-2" />

            <div className="row my-4">
              <div className="col-12">
                <p className="text-grey-6 fw-800 mb-2">Select Pickup Date</p>
              </div>
              <div className="col-12">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    minDate={dayjs()}
                    value={dayjs(formik.values.pickupDate)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        disabled
                        autoComplete="off"
                      />
                    )}
                    name="pickupDate"
                    onChange={(value) =>
                      formik.setFieldValue("pickupDate", dayjs(value).format("YYYY-MM-DD"))
                    }
                    onClose={() => formik.setFieldTouched("pickupDate", true)}
                  />
                  {!!formik.touched.pickupDate && formik.errors.pickupDate && (
                    <FormHelperText error>{formik.errors.pickupDate}</FormHelperText>
                  )}
                </LocalizationProvider>
              </div>
            </div>

            <hr className="hr-grey-6 my-2" />

            <div className="row my-4">
              <div className="col-12">
                <p className="text-grey-6 fw-800 mb-2">Package and Weight</p>
              </div>
              <div className="d-flex mt-3 align-items-center justify-content-around shipping-inputs col-12">
                <FormControl>
                  <OutlinedInput
                    placeholder="Enter Weight"
                    size="small"
                    endAdornment={<InputAdornment position="end">kg</InputAdornment>}
                    type="number"
                    name="weight"
                    value={formik.values.weight}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {!!formik.touched.weight && formik.errors.weight && (
                    <FormHelperText error>{formik.errors.weight}</FormHelperText>
                  )}
                </FormControl>
                <p className="text-lightBlue mx-2">-</p>
                <FormControl>
                  <OutlinedInput
                    placeholder="Enter Length"
                    size="small"
                    endAdornment={<InputAdornment position="end">cm</InputAdornment>}
                    type="number"
                    name="length"
                    value={formik.values.length}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {!!formik.touched.length && formik.errors.length && (
                    <FormHelperText error>{formik.errors.length}</FormHelperText>
                  )}
                </FormControl>
                <p className="text-lightBlue mx-2">x</p>
                <FormControl>
                  <OutlinedInput
                    placeholder="Enter Height"
                    size="small"
                    endAdornment={<InputAdornment position="end">cm</InputAdornment>}
                    type="number"
                    name="height"
                    value={formik.values.height}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {!!formik.touched.height && formik.errors.height && (
                    <FormHelperText error>{formik.errors.height}</FormHelperText>
                  )}
                </FormControl>
                <p className="text-lightBlue mx-2">x</p>
                <FormControl>
                  <OutlinedInput
                    placeholder="Enter Width"
                    size="small"
                    endAdornment={<InputAdornment position="end">cm</InputAdornment>}
                    type="number"
                    name="width"
                    value={formik.values.width}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {!!formik.touched.width && formik.errors.width && (
                    <FormHelperText error>{formik.errors.width}</FormHelperText>
                  )}
                </FormControl>
              </div>
            </div>

            <hr className="hr-grey-6 my-2" />

            <div className="row my-4">
              <div className="col-12">
                <p className="text-grey-6 fw-800 mb-2">Packaging Slip Note</p>
              </div>
              <div className="col-12">
                <FormControl className="w-100">
                  <TextareaAutosize
                    placeholder="Packaging Slip Note ..."
                    style={{
                      background: "#15142A",
                      color: "#c8d8ff",
                      borderRadius: 5,
                    }}
                    minRows={3}
                    className="mt-1 w-100"
                    name="note"
                    value={formik.values.note}
                    onChange={formik.handleChange}
                  />
                </FormControl>
              </div>
            </div>

            <hr className="hr-grey-6 my-2" />

            <div className="row mt-4">
              <div className="col-12">
                <p className="text-grey-6 fw-800 mb-2">Shipping</p>
              </div>
              <div className="col-12">
                <div className="d-flex align-items-center rounded-4 py-2 px-3">
                  <div className="me-3">
                    <img
                      src={bluedart}
                      alt="bluedart"
                      width={50}
                    />
                  </div>
                  <div className="w-100">
                    <p className="text-lightBlue fw-600">Blue Dart</p>
                    <small className="text-lightBlue">Blue Dart</small>
                  </div>
                  <div className="text-lightBlue text-nowrap fw-600 ms-2">
                    {shippingCharge ? `₹ ${shippingCharge}` : "N/A"}
                  </div>
                </div>
              </div>
            </div>
          </DialogContent>

          <hr className="hr-grey-6 my-0" />

          <DialogActions className="d-flex justify-content-between px-4 py-3">
            <button
              className="button-grey py-2 px-5"
              onClick={closeDialog}
              disabled={isLoading}>
              <p className="text-lightBlue">Cancel</p>
            </button>
            <button
              className="button-gradient py-2 px-5"
              onClick={formik.handleSubmit}
              disabled={isLoading}>
              <p>Create</p>
            </button>
          </DialogActions>
        </>
      ) : (
        <>
          <DialogTitle>
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex flex-column ">
                <h5 className="text-lightBlue fw-500">
                  {order?.orderID} - {item?.checkoutID}
                </h5>
              </div>
              <img
                src={cancel}
                alt="cancel"
                width={30}
                onClick={closeDialog}
                className="c-pointer"
              />
            </div>
          </DialogTitle>

          <hr className="hr-grey-6 my-0" />

          <DialogContent className="py-3 px-4">
            <div className="row mb-4">
              <div className="col-6">
                <p className="text-grey-6 fw-800 mb-2">Shipping to</p>

                <p className="text-lightBlue fw-500 mb-1">
                  {order?.customer?.firstName ?? order?.address?.shipping?.firstName ?? "Guest"}{" "}
                  {order?.customer?.lastName ?? order?.address?.shipping?.lastName ?? "User"}
                </p>

                <small className="text-lightBlue mt-1">
                  {order?.address?.shipping?.line1} {order?.address?.shipping?.line2} -{" "}
                  {order?.address?.shipping?.city?.name},{" "}
                  {order?.address?.shipping?.state?.name} - {order?.address?.shipping?.pinCode},{" "}
                  {order?.address?.shipping?.country?.name}
                </small>
              </div>
              <div className="col-6">
                <p className="text-grey-6 fw-800 mb-2">Pickup Address</p>

                <p className="text-lightBlue fw-500 mb-1">{item?.item?.store?.name}</p>
                <small className="text-lightBlue mt-1">
                  {item?.item?.store?.address?.city?.name}{" "}
                  {item?.item?.store?.address?.state?.name}{" "}
                  {item?.item?.store?.address?.pincode}
                </small>
              </div>
            </div>

            <hr className="hr-grey-6 my-2" />

            {console.log(labelData)}

            <div className="row my-4">
              <div className="col-12 mb-2">
                <p className="text-grey-6 fw-600 mb-2">Package and Weight</p>
              </div>
              <div className="col-6 mt-2">
                <p className="text-grey-6 fw-600 mb-2">Tracking ID</p>
                <p className="text-lightBlue fw-600">{labelData?.tracking_id}</p>
              </div>
              <div className="col-6 mb-2">
                <p className="text-grey-6 fw-600 mb-2">AWB No</p>
                <p className="text-lightBlue fw-600">{labelData?.result?.reference_number}</p>
              </div>
              <div className="col-6 my-2">
                <p className="text-grey-6 fw-600 mb-2">Delivery Service</p>
                <div className="d-flex align-items-center">
                  <div className="me-3">
                    <img
                      src={bluedart}
                      alt="bluedart"
                      width={40}
                    />
                  </div>
                  <p className="text-lightBlue fw-600">Blue Dart</p>
                </div>
              </div>
              <div className="col-6 my-2">
                <p className="text-grey-6 fw-600 mb-2">Delivery Expected</p>
                <p className="text-lightBlue fw-600">10-12 business days</p>
              </div>
              <div className="col-6 mt-2">
                <p className="text-grey-6 fw-600 mb-2">Expected Package</p>
                <p className="text-lightBlue fw-600">
                  {formik.values.length} x {formik.values.weight} x {formik.values.height} cm
                </p>
              </div>
              <div className="col-6 mt-2">
                <p className="text-grey-6 fw-600 mb-2">Expected Weight</p>
                <p className="text-lightBlue fw-600">{formik.values.weight} Kg</p>
              </div>
            </div>

            <hr className="hr-grey-6 my-2" />

            <div className="row my-4">
              <div className="col-12">
                <div className="d-flex align-items-center bg-black-10 border-grey-5 rounded-8 p-3">
                  <AsyncImage
                    src={defaultImg?.image}
                    placeholder={ringSmall}
                    alt="product"
                    className="me-2 rounded-4"
                    height={45}
                    width={45}
                  />
                  <div>
                    <p className="text-lightBlue fw-600">{item?.item?.product?.title}</p>
                    <small className="mt-2 text-grey-6">{item?.item?.variant?.title}</small>
                  </div>
                </div>
              </div>
            </div>

            <hr className="hr-grey-6 my-2" />

            <div className="row mt-4">
              <div className="col-12">
                <p className="text-grey-6 fw-800 mb-2">Packaging Slip Note</p>
              </div>
              <div className="col-12">
                <p className="text-lightBlue fw-600 mb-2">{formik.values.note}</p>
              </div>
            </div>
          </DialogContent>

          <hr className="hr-grey-6 my-0" />

          <DialogActions className="d-flex justify-content-between px-4 py-3">
            <button
              className="button-grey py-2 px-5"
              onClick={closeDialog}>
              <p className="text-lightBlue">Close</p>
            </button>
            <a
              href={labelData?.result?.label}
              target="_blank"
              rel="noreferrer"
              className="button-gradient py-2 px-5">
              <p>View Label</p>
            </a>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}
