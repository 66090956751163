import {
  FormControl,
  OutlinedInput,
  Popover,
  Tooltip,
  FormHelperText,
  CircularProgress,
} from '@mui/material';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import info from '../../assets/icons/info.svg';
import UseFileUpload from '../../features/fileUpload/fileUploadHook';
import CancelButton from '../CancelButton/CancelButton';
import AsyncImage from '../AsyncImage/AsyncImage';
import { ReactComponent as UploadIcon } from '../../assets/icons/upload.svg';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import {
  isHttpValid,
  isImgUrl,
  isVideoUrl,
  validateDropzoneSingleFile,
} from '../../utils/helper';
import {
  DROPZONE_IMAGE_FORMAT,
  DROPZONE_VIDEO_FORMAT,
  VIDEO_EXTENSIONS,
  MAX_FILE_SIZE,
} from '../../utils/defaults';
import { showError } from '../../features/snackbar/snackbarAction';
// ? FILE UPLOAD STARTS HERE
const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '0',
  borderWidth: 2,
  borderRadius: 8,
  borderColor: '#38395C',
  borderStyle: 'dashed',
  color: '#BDBDBD',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  cursor: 'pointer',
  justifyContent: 'center',
  backgroundColor: '#1A1932',
  position: 'relative',
};
const focusedStyle = {
  borderColor: '#2196F3',
};
const acceptStyle = {
  borderColor: '#00E676',
};
const rejectStyle = {
  borderColor: '#FF1744',
};
// ? FILE UPLOAD ENDS HERE
const UploadMediaBox = ({
  noteText,
  imageName,
  headingName,
  UploadChange = () => {},
  imageValue,
  previousImage,
  isUploaded = () => {},
  module,
  setDelete = () => {},
  multiple = false,
  maxFiles = 1,
  isMandatory,
}) => {
  const dispatch = useDispatch();
  const [inputUrl, setInputUrl] = useState('');
  const [uploadFile, uploadState] = UseFileUpload();
  const [srcImage, setSrcImage] = useState('');
  const [error, setError] = useState('');
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      accept: {
        ...DROPZONE_IMAGE_FORMAT,
      },
      onDrop: (acceptedFiles, rejectedFiles) => {
        if (!multiple) {
          validateDropzoneSingleFile(rejectedFiles, MAX_FILE_SIZE);
          if (acceptedFiles[0]) {
            uploadFile({ file: acceptedFiles[0], module, isMandatory });
          }
        }
      },
      disabled: uploadState.isLoading || srcImage,
      multiple,
      maxFiles,
      maxSize: MAX_FILE_SIZE,
    });
  const cancelHandler = (e) => {
    e.stopPropagation();
    setSrcImage('');
    setInputUrl('');
    setDelete('');
    UploadChange('');
  };
  useEffect(() => {
    if (uploadState.data?.url) {
      UploadChange(uploadState.data?.url);
      // setDelete(false);
      isUploaded(true);
    }
  }, [uploadState]);
  useEffect(() => {
    if (imageValue) {
      setInputUrl(imageValue);
    }
  }, [imageValue]);
  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );
  // ? FILE UPLOAD ENDS HERE
  // * SAVE FILTER POPOVERS STARTS
  const [anchorSaveFilterEl, setAnchorSaveFilterEl] = React.useState(null);
  const handleSaveFilterClick = (event) => {
    setAnchorSaveFilterEl(event.currentTarget);
  };
  const handleSaveFilterClose = () => {
    setAnchorSaveFilterEl(null);
  };
  const handleSaveUrl = async () => {
    const isValid = await isImgUrl(inputUrl);
    if (isValid) {
      UploadChange(inputUrl);
      isUploaded(true);
      setAnchorSaveFilterEl(null);
    } else {
      setError('Not Valid URL');
    }
  };
  const openSaveFilter = Boolean(anchorSaveFilterEl);
  const idSaveFilter = openSaveFilter ? 'simple-popover' : undefined;
  // * SAVE FILTER POPOVERS ENDS

  useEffect(() => {
    previousImage
      ? isImgUrl(previousImage).then((isValid) => {
          if (isValid) {
            setSrcImage(previousImage);
            return;
          }
        })
      : setSrcImage('');
  }, [previousImage]);

  return (
    <div className='bg-black-15 border-grey-5 rounded-8 p-3'>
      <div className='d-flex align-items-center justify-content-between'>
        <h6 className='text-lightBlue fw-500'>{headingName}</h6>
        {!srcImage && !uploadState.isLoading && (
          <small
            className='text-lightBlue text-blue-2 c-pointer'
            aria-describedby={idSaveFilter}
            variant='contained'
            onClick={handleSaveFilterClick}
          >
            Add Media from URL
          </small>
        )}
        <Popover
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          id={idSaveFilter}
          open={openSaveFilter}
          anchorEl={anchorSaveFilterEl}
          onClose={handleSaveFilterClose}
        >
          <div className='px-1 py-3'>
            <div className='d-flex mb-1'>
              <small className='text-lightBlue me-2'>Add URL</small>
              <Tooltip title='Lorem ipsum' placement='top'>
                <img
                  src={info}
                  alt='info'
                  className='c-pointer ms-2'
                  width={13.5}
                />
              </Tooltip>
            </div>
            <FormControl className='px-0'>
              <OutlinedInput
                value={inputUrl}
                onChange={(e) => setInputUrl(e.target.value)}
                placeholder='Enter URL Link'
                size='small'
              />
              {error && <FormHelperText error>{error}</FormHelperText>}
            </FormControl>
            {/* <div className="d-flex"> */}
            <button
              onClick={handleSaveUrl}
              className='ms-auto button-gradient py-1 px-4 mt-3'
            >
              <p>Add Media</p>
            </button>
            {/* </div> */}
          </div>
        </Popover>
      </div>
      <div
        {...getRootProps({
          style: {
            ...style,
            height: '150px',
            display: 'grid',
            placeContent: 'center',
          },
        })}
        className='mt-3'
      >
        {srcImage && !uploadState.isLoading && (
          <div
            style={{
              position: 'absolute',
              right: 0,
              top: 0,
              transform: 'translate(50%, -50%)',
            }}
          >
            <CancelButton
              style={{
                borderRadius: '50%',
                width: '6px',
                height: '6px',
                display: 'grid',
                placeContent: 'center',
                backgroundColor: '#C8D8FF',
              }}
              onClick={cancelHandler}
            />
          </div>
        )}
        <input
          id='primary'
          {...getInputProps()}
          // onChange={(event) => {
          //   uploadFileToCloud(event, "primary");
          //   event.target.value = null;
          // }}
        />

        {uploadState.isLoading ? (
          <>
            <CircularProgress
              style={{ display: 'block', margin: 'auto' }}
              color='secondary'
              size={20}
            />
            <span className='text-lightBlue mt-1'>Uploading...</span>
          </>
        ) : (
          <img
            src={srcImage || imageName}
            style={{
              objectFit: 'contain',
              height: '135px',
              width: '95%',
              margin: 'auto',
            }}
            alt=''
          />
        )}
      </div>
      {noteText && <div className='text-grey-6 font1 mt-3'>{noteText}</div>}
    </div>
  );
};

const UploadMediaBox2 = ({
  headingName,
  fileSrc,
  error,
  onUpload,
  onBlur,
  name,
  module = 'others',
  style,
  className,
  isSubmitting,
  touched,
  multiple = false,
  maxFiles = 1,
  isMandatory,
}) => {
  const [uploadFile, { data, isSuccess, isError, isLoading }] = UseFileUpload();
  const [blurred, setBlurred] = useState(false);
  const [fileSource, setFileSource] = useState(null);
  const [isVideo, setIsVideo] = useState(null);
  const errorRef = useRef(null);
  const dispatch = useDispatch();

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isFileDialogActive,
    inputRef,
    rootRef,
  } = useDropzone({
    accept: {
      ...DROPZONE_IMAGE_FORMAT,
      ...DROPZONE_VIDEO_FORMAT,
    },
    onDrop: (acceptedFiles, rejectedFiles) => {
      if (!multiple) {
        validateDropzoneSingleFile(rejectedFiles, MAX_FILE_SIZE);
        if (acceptedFiles[0]) {
          uploadFile({ file: acceptedFiles[0], module, isMandatory });
        }
      }
    },
    disabled: isLoading || fileSource,
    multiple,
    maxFiles,
    maxSize: MAX_FILE_SIZE,
  });

  const cancelHandler = (e) => {
    e.stopPropagation();
    inputRef.current.blur();
    rootRef.current.blur();
    setFileSource(null);
    setIsVideo(null);
    onUpload('');
    setBlurred(true);
  };

  useEffect(() => {
    if (isError) {
      dispatch(showError({ message: 'Failed to upload' }));
    }

    if (isSuccess && data) {
      onUpload(data?.url);
    }
  }, [isError, isSuccess, data, dispatch, onUpload]);

  useEffect(() => {
    console.log({ isFileDialogActive, blurred, ref: errorRef.current, error });
    if (isFocused) {
      setBlurred(true);
    }
    if (!isFileDialogActive && blurred && errorRef.current && error) {
      errorRef.current.textContent = error;
    }
  }, [errorRef, error, blurred, isFocused, isFileDialogActive]);

  useEffect(() => {
    if (isSubmitting || touched) {
      setBlurred(true);
    }
  }, [isSubmitting, touched]);

  useEffect(() => {
    if (fileSrc) {
      const fileNamePieces = fileSrc.split('.');
      setIsVideo(
        VIDEO_EXTENSIONS.includes(fileNamePieces[fileNamePieces.length - 1])
      );
    }
  }, [fileSrc]);

  useEffect(() => {
    if (isVideo !== null && fileSrc) {
      if (isVideo) {
        isVideoUrl(fileSrc).then((isValid) => {
          if (isValid) {
            setFileSource(fileSrc);
            return;
          }
          setFileSource(null);
        });
      } else {
        isImgUrl(fileSrc).then((isValid) => {
          if (isValid) {
            setFileSource(fileSrc);
            return;
          }
          setFileSource(null);
        });
      }
    }
  }, [fileSrc, isVideo]);

  return (
    <div>
      <div className='bg-black-15 border-grey-5 rounded-8 p-3'>
        <div className='d-flex align-items-center justify-content-between'>
          <h6 className='text-lightBlue fw-500'>{headingName}</h6>
          {/* {!srcImage && !uploadState.isLoading && (
          <small
            className='text-lightBlue text-blue-2 c-pointer'
            aria-describedby={idSaveFilter}
            variant='contained'
            onClick={handleSaveFilterClick}
          >
            Add Media from URL
          </small>
        )} */}
          {/* <Popover
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          id={idSaveFilter}
          open={openSaveFilter}
          anchorEl={anchorSaveFilterEl}
          onClose={handleSaveFilterClose}
        >
          <div className='px-1 py-3'>
            <div className='d-flex mb-1'>
              <small className='text-lightBlue me-2'>Add URL</small>
              <Tooltip title='Lorem ipsum' placement='top'>
                <img
                  src={info}
                  alt='info'
                  className='c-pointer ms-2'
                  width={13.5}
                />
              </Tooltip>
            </div>
            <FormControl className='px-0'>
              <OutlinedInput
                value={inputUrl}
                onChange={(e) => setInputUrl(e.target.value)}
                placeholder='Enter URL Link'
                size='small'
              />
              {error && <FormHelperText error>{error}</FormHelperText>}
            </FormControl>

            <button
              onClick={handleSaveUrl}
              className='ms-auto button-gradient py-1 px-4 mt-3'
            >
              <p>Add Media</p>
            </button>
          </div>
        </Popover> */}
        </div>
        <div
          {...getRootProps({
            style: {
              ...style,
              height: '150px',
              display: 'flex',
              position: 'relative',
              flexDirection: 'row',
            },
          })}
          // className='mt-3'
        >
          {!isLoading && fileSource && (
            <div
              style={{
                position: 'absolute',
                right: 0,
                top: 0,
                transform: 'translate(50%, -50%)',
              }}
            >
              <CancelButton
                style={{
                  borderRadius: '50%',
                  width: '6px',
                  height: '6px',
                  display: 'grid',
                  placeContent: 'center',
                  backgroundColor: '#C8D8FF',
                }}
                onClick={cancelHandler}
              />
            </div>
          )}

          {!fileSource || isLoading ? (
            isLoading ? (
              <div
                style={{
                  placeSelf: 'center',
                  display: 'grid',
                  justifyContent: 'center',
                  margin: 'auto',
                }}
              >
                <CircularProgress
                  style={{ display: 'block', margin: 'auto' }}
                  color='secondary'
                  size={20}
                />
                <span className='text-lightBlue mt-1'>Uploading...</span>
              </div>
            ) : (
              <div
                style={{
                  placeSelf: 'center',
                  display: 'grid',
                  justifyContent: 'center',
                  margin: 'auto',
                }}
              >
                <UploadIcon
                  style={{ margin: 'auto' }}
                  className='svg'
                  width={20}
                  height={20}
                />
                <span className='small text-lightBlue'>Add Image / Video</span>
              </div>
              // <img
              //   src={srcImage || imageName}
              //   style={{
              //     objectFit: 'contain',
              //     height: '135px',
              //     width: '95%',
              //     margin: 'auto',
              //   }}
              //   alt=''
              // />
            )
          ) : isVideo ? (
            <div style={{ position: 'relative' }}>
              <video
                className='media'
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                muted
                autoPlay
                preload='auto'
                playsInline
                loop
              >
                <source src={fileSource} type='video/mp4' />
              </video>
              <PlayCircleOutlineIcon
                fontSize='large'
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%,-50%)',
                }}
              />
            </div>
          ) : (
            <img
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              src={fileSource}
              className='media'
              alt='icon'
            />
          )}
        </div>
        <input
          name={name}
          onBlur={onBlur}
          id='file'
          {...getInputProps()}
          size='small'
        />
      </div>
      {!fileSource && !isLoading && (
        <FormHelperText ref={errorRef} error></FormHelperText>
      )}
    </div>
  );
};

const UploadMediaBox3 = ({
  noteText,
  imageName,
  headingName,
  UploadChange = () => {},
  imageValue,
  previousImage,
  isUploaded = () => {},
  module,
  setDelete = () => {},
  multiple = false,
  maxFiles = 1,
  isMandatory,
}) => {
  const dispatch = useDispatch();
  const [inputUrl, setInputUrl] = useState('');
  const [uploadFile, uploadState] = UseFileUpload();
  const [srcImage, setSrcImage] = useState('');
  const [error, setError] = useState('');
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      accept: {
        ...DROPZONE_IMAGE_FORMAT,
        ...DROPZONE_VIDEO_FORMAT,
      },
      onDrop: (acceptedFiles, rejectedFiles) => {
        if (!multiple) {
          validateDropzoneSingleFile(rejectedFiles, MAX_FILE_SIZE);
          if (acceptedFiles[0]) {
            uploadFile({ file: acceptedFiles[0], module, isMandatory });
          }
        }
      },
      disabled: uploadState.isLoading || srcImage,
      multiple,
      maxFiles,
      maxSize: MAX_FILE_SIZE,
    });
  const cancelHandler = (e) => {
    e.stopPropagation();
    setSrcImage('');
    setInputUrl('');
    setDelete('');
    UploadChange('');
  };
  useEffect(() => {
    if (uploadState.data?.url) {
      UploadChange(uploadState.data?.url);
      // setDelete(false);
      isUploaded(true);
    }
  }, [uploadState]);
  useEffect(() => {
    if (imageValue) {
      setInputUrl(imageValue);
    }
  }, [imageValue]);
  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );
  // ? FILE UPLOAD ENDS HERE
  // * SAVE FILTER POPOVERS STARTS
  const [anchorSaveFilterEl, setAnchorSaveFilterEl] = React.useState(null);
  const handleSaveFilterClick = (event) => {
    setAnchorSaveFilterEl(event.currentTarget);
  };
  const handleSaveFilterClose = () => {
    setAnchorSaveFilterEl(null);
  };
  const handleSaveUrl = async () => {
    const isValid = await isImgUrl(inputUrl);
    if (isValid) {
      UploadChange(inputUrl);
      isUploaded(true);
      setAnchorSaveFilterEl(null);
    } else {
      setError('Not Valid URL');
    }
  };
  const openSaveFilter = Boolean(anchorSaveFilterEl);
  const idSaveFilter = openSaveFilter ? 'simple-popover' : undefined;
  // * SAVE FILTER POPOVERS ENDS

  useEffect(() => {
    previousImage
      ? isImgUrl(previousImage).then((isValid) => {
          if (isValid) {
            setSrcImage(previousImage);
            return;
          }
        })
      : setSrcImage('');
  }, [previousImage]);

  return (
    <div className='bg-black-15 border-grey-5 rounded-8 p-3'>
      <div className='d-flex align-items-center justify-content-between'>
        <h6 className='text-lightBlue fw-500'>{headingName}</h6>
        {!srcImage && !uploadState.isLoading && (
          <small
            className='text-lightBlue text-blue-2 c-pointer'
            aria-describedby={idSaveFilter}
            variant='contained'
            onClick={handleSaveFilterClick}
          >
            Add Media from URL
          </small>
        )}
        <Popover
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          id={idSaveFilter}
          open={openSaveFilter}
          anchorEl={anchorSaveFilterEl}
          onClose={handleSaveFilterClose}
        >
          <div className='px-1 py-3'>
            <div className='d-flex mb-1'>
              <small className='text-lightBlue me-2'>Add URL</small>
              <Tooltip title='Lorem ipsum' placement='top'>
                <img
                  src={info}
                  alt='info'
                  className='c-pointer ms-2'
                  width={13.5}
                />
              </Tooltip>
            </div>
            <FormControl className='px-0'>
              <OutlinedInput
                value={inputUrl}
                onChange={(e) => setInputUrl(e.target.value)}
                placeholder='Enter URL Link'
                size='small'
              />
              {error && <FormHelperText error>{error}</FormHelperText>}
            </FormControl>
            {/* <div className="d-flex"> */}
            <button
              onClick={handleSaveUrl}
              className='ms-auto button-gradient py-1 px-4 mt-3'
            >
              <p>Add Media</p>
            </button>
            {/* </div> */}
          </div>
        </Popover>
      </div>
      <div
        {...getRootProps({
          style: {
            ...style,
            height: '150px',
            display: 'grid',
            placeContent: 'center',
          },
        })}
        className='mt-3'
      >
        {srcImage && !uploadState.isLoading && (
          <div
            style={{
              position: 'absolute',
              right: 0,
              top: 0,
              transform: 'translate(50%, -50%)',
            }}
          >
            <CancelButton
              style={{
                borderRadius: '50%',
                width: '6px',
                height: '6px',
                display: 'grid',
                placeContent: 'center',
                backgroundColor: '#C8D8FF',
              }}
              onClick={cancelHandler}
            />
          </div>
        )}
        <input
          id='primary'
          {...getInputProps()}
          // onChange={(event) => {
          //   uploadFileToCloud(event, "primary");
          //   event.target.value = null;
          // }}
        />

        {uploadState.isLoading ? (
          <>
            <CircularProgress
              style={{ display: 'block', margin: 'auto' }}
              color='secondary'
              size={20}
            />
            <span className='text-lightBlue mt-1'>Uploading...</span>
          </>
        ) : (
          <img
            src={srcImage || imageName}
            style={{
              objectFit: 'contain',
              height: '135px',
              width: '95%',
              margin: 'auto',
            }}
            alt=''
          />
        )}
      </div>
      {noteText && <div className='text-grey-6 font1 mt-3'>{noteText}</div>}
    </div>
  );
};

export default UploadMediaBox;
export { UploadMediaBox2, UploadMediaBox3 };
