import React, { useState } from "react";
import { Link } from "react-router-dom";
// ! COMPONENT IMPORTS
import {
  EnhancedTableHead,
  stableSort,
  getComparator,
} from "../../../components/TableDependencies/TableDependencies";
import TabPanel from "../../../components/TabPanel/TabPanel";
// ! IMAGES IMPORTS
import rolesAdmin from "../../../assets/images/teams/rolesAdmin.svg";
import user from "../../../assets/images/users/user.svg";
import verticalDots from "../../../assets/icons/verticalDots.svg";
import deleteRed from "../../../assets/icons/delete.svg";
import teamMember1 from "../../../assets/images/products/teamMember1.svg";
// ! MATERIAL IMPORTS
import {
  Checkbox,
  Popover,
  SwipeableDrawer,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Tooltip,
  Paper,
  Box,
  Tabs,
  Tab,
  FormControlLabel,
  FormGroup,
  styled,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
// ! MATERIAL ICONS IMPORTSe
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import InventoryIcon from "@mui/icons-material/Inventory";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import ChatIcon from "@mui/icons-material/Chat";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import TableEditStatusButton from "../../../components/TableEditStatusButton/TableEditStatusButton";
import TableMassActionButton from "../../../components/TableMassActionButton/TableMassActionButton";
import TableLoader from "../../../components/Loader/TableLoader";
import NoDataFound from "../../../components/NoDataFound/NoDataFound";
import CopyToClipboard from "react-copy-to-clipboard";
import AsyncImage from "../../../components/AsyncImage/AsyncImage";
import defaultUser from "../../../assets/images/users/user_defauldp.svg";
import DeleteIcon from "@mui/icons-material/Delete";

import ArchiveModal, {
  MultipleArchiveModal,
} from "../../../components/ArchiveModal/ArchiveModal";
import {
  UnArchiveModalSecondary,
  UnMultipleArchiveModalSecondary,
} from "../../../components/UnArchiveModal/UnArchiveModal";
import { DeleteModalSecondary } from "../../../components/DeleteModal/DeleteModal";
import { useDispatch } from "react-redux";
import { showError, showSuccess } from "../../../features/snackbar/snackbarAction";

// ? PERMISSIONS ACCORDIAN STARTS HERE
const Accordion = styled((props) => (
  <MuiAccordion
    disableGutters
    elevation={0}
    square
    {...props}
  />
))(({ theme }) => ({
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowForwardIosSharpIcon
        sx={{
          fontSize: "0.9rem",
          color: "#c8d8ff",
        }}
      />
    }
    {...props}
  />
))(({ theme }) => ({
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    padding: "0px",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: "0 16px 16px 28px",
}));

const permissionAccordionData = [
  {
    id: 1,
    name: "Dashboard",
    accDetails: [
      { id: 101, name: "Content 1" },
      { id: 102, name: "Content 2" },
      { id: 103, name: "Content 3" },
    ],
  },
  {
    id: 2,
    name: "Orders",
    accDetails: [
      { id: 101, name: "Content 1" },
      { id: 102, name: "Content 2" },
      { id: 103, name: "Content 3" },
    ],
  },
  {
    id: 3,
    name: "Products",
    accDetails: [
      { id: 101, name: "Content 1" },
      { id: 102, name: "Content 2" },
      { id: 103, name: "Content 3" },
    ],
  },
  {
    id: 4,
    name: "Parameters",
    accDetails: [
      { id: 101, name: "Content 1" },
      { id: 102, name: "Content 2" },
      { id: 103, name: "Content 3" },
    ],
  },
  {
    id: 5,
    name: "Users",
    accDetails: [
      { id: 101, name: "Content 1" },
      { id: 102, name: "Content 2" },
      { id: 103, name: "Content 3" },
    ],
  },
  {
    id: 6,
    name: "Analytics",
    accDetails: [
      { id: 101, name: "Content 1" },
      { id: 102, name: "Content 2" },
      { id: 103, name: "Content 3" },
    ],
  },
  {
    id: 7,
    name: "Functionality",
    accDetails: [
      { id: 101, name: "Content 1" },
      { id: 102, name: "Content 2" },
      { id: 103, name: "Content 3" },
    ],
  },
  {
    id: 8,
    name: "Offers",
    accDetails: [
      { id: 101, name: "Content 1" },
      { id: 102, name: "Content 2" },
      { id: 103, name: "Content 3" },
    ],
  },
  {
    id: 9,
    name: "Emailers",
    accDetails: [
      { id: 101, name: "Content 1" },
      { id: 102, name: "Content 2" },
      { id: 103, name: "Content 3" },
    ],
  },
  {
    id: 10,
    name: "Teams",
    accDetails: [
      { id: 101, name: "Content 1" },
      { id: 102, name: "Content 2" },
      { id: 103, name: "Content 3" },
    ],
  },
  {
    id: 11,
    name: "Website",
    accDetails: [
      { id: 101, name: "Content 1" },
      { id: 102, name: "Content 2" },
      { id: 103, name: "Content 3" },
    ],
  },
];
// ? PERMISSIONS ACCORDIAN ENDS HERE

// ? TABLE STARTS HERE
function createData(tId, userName, role, createdOn, actions) {
  return { tId, userName, role, createdOn, actions };
}

const rows = [
  createData(1, "Saniya Shaikh", "Owner", "23/09/21 at 09:23am"),
  createData(2, "Saniya Shaikh", "Owner", "23/09/21 at 09:23am"),
  createData(3, "Saniya Shaikh", "Owner", "23/09/21 at 09:23am"),
  createData(4, "Saniya Shaikh", "Owner", "23/09/21 at 09:23am"),
  createData(5, "Saniya Shaikh", "Owner", "23/09/21 at 09:23am"),
];

const headCells = [
  {
    id: "userName",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "role",
    numeric: false,
    disablePadding: false,
    label: "Role",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    label: "Actions",
  },
];
// ? TABLE ENDS HERE

const MembersTable = ({
  isLoading,
  list,
  totalCount,
  rowsPerPage,
  changeRowsPerPage,
  changePage,
  page,
  membersType,
  edit,
  editMember,
  bulkEdit,
  deleteData,
  bulkDelete,
  detailsPage,
}) => {
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // ? PERMISSIONS ACCORDIAN STARTS HERE
  const [expanded, setExpanded] = React.useState("panel0");

  const handleAccordianChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  // ? PERMISSIONS ACCORDIAN ENDS HERE

  // ? TABLE STARTS HERE
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("userName");
  const [selected, setSelected] = useState([]);
  const [copied, setCopied] = useState(false);
  const [showArchivedModal, setShowArchivedModal] = useState(false);
  const [showMultipleArchivedModal, setShowMultipleArchivedModal] = useState(false);
  const [showUnArchivedModal, setShowUnArhcivedModal] = useState(false);
  const [showMultipleUnArhcivedModal, setShowMultipleUnArhcivedModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showMultipleDeleteModal, setShowMultipleDeleteModal] = useState(false);
  const [isMassDelete, setIsMassDelete] = useState();
  const [member, setMember] = useState(null);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (selected.length > 0) {
      setSelected([]);
    } else if (event.target.checked) {
      const newSelected = list.slice(0, rowsPerPage).map((n) => n._id);
      setSelected(newSelected);
    } else {
      setSelected([]);
    }
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;
  // ? TABLE ENDS HERE

  // ? ACTIVITY DRAWER STARTS HERE
  const [activityDrawer, setActivityDrawer] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleActivityDrawer = (anchor, open) => (event) => {
    if (event && event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }

    setActivityDrawer({ ...activityDrawer, [anchor]: open });
  };
  // ? ACTIVITY DRAWER ENDS HERE

  // * ACCESS POPOVERS STARTS
  const [anchorAccessEl, setAnchorAccessEl] = React.useState(null);
  const handleAccessClick = (event) => {
    setAnchorAccessEl(event.currentTarget);
  };
  const handleAccessClose = () => {
    setAnchorAccessEl(null);
  };
  const openAccess = Boolean(anchorAccessEl);
  const idAccess = openAccess ? "simple-popover" : undefined;
  // * ACTIVITY POPOVERS ENDS

  // * ACTION POPOVERS STARTS
  const [anchorActionEl, setAnchorActionEl] = React.useState(null);

  const handleActionClick = (event) => {
    setAnchorActionEl(event.currentTarget);
  };

  const handleActionClose = () => {
    setAnchorActionEl(null);
  };

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  const handleMassAction = (status) => {
    if (status === "Mass Un-Archive") {
      setShowMultipleUnArhcivedModal(true);
    }
    if (status === "Archive") {
      setShowMultipleArchivedModal(true);
    }
    if (status === "Delete") {
      setShowMultipleDeleteModal(true);
      setIsMassDelete(true);
    }
  };

  const handleArchive = (row) => {
    setShowArchivedModal(true);
    setMember(row);
  };

  const handleArchiveModalOnSave = () => {
    editMember({
      id: member?._id,
      details: {
        status: "archieved",
      },
    })
      .unwrap()
      .then(() => dispatch(showSuccess({ message: "Member Archived Successfully" })))
      .catch((e) =>
        dispatch(
          showError({
            message: e?.data?.message ?? e?.message ?? "Something went wrong",
          })
        )
      );
    setShowArchivedModal(false);
    setMember("");
    setSelected([]);
  };

  const handleArchiveModalClose = () => {
    setShowArchivedModal(false);
    setShowMultipleArchivedModal(false);
  };

  const handleMultipleArchiveModalOnSave = () => {
    const newState = selected.map((_id) => {
      return {
        _id,
        status: "archieved",
      };
    });
    bulkEdit({ updates: newState })
      .unwrap()
      .then(() =>
        dispatch(
          showSuccess({
            message: `${selected.length === 1 ? "Member" : "Members"} Archived Successfully`,
          })
        )
      )
      .catch((e) =>
        dispatch(
          showError({
            message: e?.data?.message ?? e?.message ?? "Something went wrong",
          })
        )
      );
    setSelected([]);
    setShowMultipleArchivedModal(false);
  };

  const handleUnArchive = (row) => {
    setShowUnArhcivedModal(true);
    setMember(row);
  };

  const handleUnArchiveModalOnSave = () => {
    editMember({
      id: member?._id,
      details: {
        status: "active",
      },
    })
      .unwrap()
      .then(() => dispatch(showSuccess({ message: "Member Un-Archived successfully" })))
      .catch((e) =>
        dispatch(
          showError({
            message: e?.data?.message ?? e?.message ?? "Something went wrong",
          })
        )
      );
    setShowUnArhcivedModal(false);
    setSelected([]);
  };

  const handleUnArchiveModalClose = () => {
    setShowUnArhcivedModal(false);
    setShowMultipleUnArhcivedModal(false);
  };

  const handleMultipleUnArchiveModalOnSave = () => {
    const newState = selected.map((_id) => {
      return {
        _id,
        status: "active",
      };
    });
    bulkEdit({ updates: newState })
      .unwrap()
      .then(() =>
        dispatch(
          showSuccess({
            message: `${selected.length === 1 ? "Member" : "Members"} Un-Archived Successfully`,
          })
        )
      )
      .catch((e) =>
        dispatch(
          showError({
            message: e?.data?.message ?? e?.message ?? "Something went wrong",
          })
        )
      );
    setShowMultipleUnArhcivedModal(false);
    setSelected([]);
  };

  const handleDelete = (row) => {
    setIsMassDelete(false);
    setShowDeleteModal(true);
    setMember(row);
  };

  const handleDeleteModalOnSave = () => {
    // deleteData(member?._id)
    bulkDelete({ deletes: [member?._id] })
      .unwrap()
      .then(() => dispatch(showSuccess({ message: "Member Deleted successfully" })))
      .catch((e) =>
        dispatch(
          showError({
            message: e?.data?.message ?? e?.message ?? "Something went wrong",
          })
        )
      );
    setShowDeleteModal(false);
    setSelected([]);
  };

  const handleDeleteModalClose = () => {
    setShowDeleteModal(false);
    setShowMultipleDeleteModal(false);
  };

  const handleMultipleDeleteOnSave = () => {
    bulkDelete({ deletes: selected })
      .unwrap()
      .then(() =>
        dispatch(
          showSuccess({
            message: `${selected.length === 1 ? "Member" : "Members"} Deleted Successfully`,
          })
        )
      )
      .catch((e) =>
        dispatch(
          showError({
            message: e?.data?.message ?? e?.message ?? "Something went wrong",
          })
        )
      );
    setShowMultipleDeleteModal(false);
    setIsMassDelete(false);
    setSelected([]);
  };

  const openActions = Boolean(anchorActionEl);
  const idActions = openActions ? "simple-popover" : undefined;
  // * ACTION POPOVERS ENDS

  return (
    <React.Fragment>
      {selected.length > 0 && (
        <div className="d-flex align-items-center px-2 mb-3">
          <button className="button-grey py-2 px-3">
            <small className="text-lightBlue">
              {selected.length} users are selected&nbsp;
              <span
                className="text-blue-2 c-pointer"
                onClick={() => setSelected([])}>
                (Clear Selection)
              </span>
            </small>
          </button>
          {/* <TableEditStatusButton />
          <TableMassActionButton /> */}
          {membersType === 1 ? (
            <TableMassActionButton
              headingName="Mass Action"
              onSelect={handleMassAction}
              defaultValue={["Delete", "Mass Un-Archive"]}
            />
          ) : (
            <button
              className="button-grey py-2 px-3 ms-2 c-pointers"
              variant="contained"
              onClick={() => {
                handleMassAction("Archive");
              }}>
              <small className="text-lightBlue">Mass Archive</small>
            </button>
          )}
        </div>
      )}
      {list.length ? (
        <>
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size="medium">
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={list?.length}
                headCells={headCells}
              />
              <TableBody>
                {stableSort(list, getComparator(order, orderBy)).map((row, index) => {
                  const isItemSelected = isSelected(row?._id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row._id}
                      selected={isItemSelected}
                      className="table-rows">
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                          onClick={(event) => handleClick(event, row._id)}
                          size="small"
                          style={{
                            color: "#5C6D8E",
                          }}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none">
                        <div className="d-flex align-items-center pt-3 pb-3">
                          <AsyncImage
                            src={row?.imageUrl}
                            placeholder={defaultUser}
                            alt="member"
                            className="me-2 rounded-4"
                            height={45}
                            width={45}
                            style={{ borderRadius: "50%" }}
                            // style={{ objectFit: "cover" }}
                          />
                          <div>
                            {/* <Link
                                to="/teams/members/details"
                                className=" text-decoration-none"
                              > */}
                            <p
                              className="text-lightBlue rounded-circle fw-600 c-pointer"
                              onClick={() => detailsPage(row)}>
                              {row?.fullName}
                            </p>
                            {/* </Link> */}
                            <CopyToClipboard
                              text={row?.email}
                              onCopy={handleCopy}>
                              <small className="mt-2 text-grey-6">
                                {row?.email}
                                <Tooltip
                                  title={copied ? "Copied to clipboard" : "Copy"}
                                  placement="top">
                                  <ContentCopyIcon
                                    sx={{ fontSize: 12, color: "#c8d8ff" }}
                                    className="c-pointer ms-2"
                                  />
                                </Tooltip>
                              </small>
                            </CopyToClipboard>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell style={{ width: 120 }}>
                        <div className="d-flex align-items-center c-pointer">
                          <p className="text-lightBlue">
                            {row && row?.roles && row?.roles[0]
                              ? row?.roles[0]?.name?.charAt(0).toUpperCase() +
                                row?.roles[0]?.name?.slice(1)
                              : ""}
                          </p>
                        </div>
                      </TableCell>
                      {/* <TableCell style={{ width: 260 }}>
                          <div className="d-flex align-items-center">
                            <p className="text-lightBlue me-2">{row?.status}</p>
                            <img src={teamMember1} alt="teamMember1" />
                          </div>
                        </TableCell> */}
                      <TableCell style={{ width: 140, padding: 0 }}>
                        <div className="d-flex align-items-center">
                          <div
                            className="rounded-pill d-flex px-2 py-1 statusBoxWidth"
                            style={{
                              background:
                                row.status === "active"
                                  ? "#A6FAAF"
                                  : row.status === "invited"
                                  ? "#FEE1A3"
                                  : row.status === "archieved"
                                  ? "#C8D8FF"
                                  : "#F67476",
                              cursor: "context-menu",
                            }}>
                            <small className="text-black fw-400">
                              {row.status === "active"
                                ? "Active"
                                : row.status === "invited"
                                ? "Invited"
                                : row.status === "archieved"
                                ? "Archived"
                                : "Scheduled"}
                            </small>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell style={{ width: 110, padding: 0 }}>
                        <div className="d-flex align-items-center">
                          {/* <React.Fragment>
                                <Tooltip title="Edit" placement="top">
                                  <div className="table-edit-icon rounded-4 p-2" onClick={() => edit(row)}>
                                    <EditOutlinedIcon
                                      sx={{
                                        color: "#5c6d8e",
                                        fontSize: 18,
                                        cursor: "pointer",
                                      }}
                                    />
                                  </div>
                                </Tooltip>
                                <Tooltip
                                  title="Archive"
                                  placement="top"
                                  onClick={() => handleArchive(row)}
                                >
                                  <div className="table-edit-icon rounded-4 p-2">
                                    <InventoryIcon
                                      sx={{
                                        color: "#5c6d8e",
                                        fontSize: 18,
                                        cursor: "pointer",
                                      }}
                                    />
                                  </div>
                                </Tooltip>
                              </React.Fragment> */}
                          {row?.status === "active" ? (
                            <React.Fragment>
                              <Tooltip
                                title="Edit"
                                placement="top"
                                onClick={() => edit(row)}>
                                <div className="table-edit-icon rounded-4 p-2">
                                  <EditOutlinedIcon
                                    sx={{
                                      color: "#5c6d8e",
                                      fontSize: 18,
                                      cursor: "pointer",
                                    }}
                                  />
                                </div>
                              </Tooltip>
                              <Tooltip
                                title="Archive"
                                placement="top"
                                onClick={() => handleArchive(row)}>
                                <div className="table-edit-icon rounded-4 p-2">
                                  <InventoryIcon
                                    sx={{
                                      color: "#5c6d8e",
                                      fontSize: 18,
                                      cursor: "pointer",
                                    }}
                                  />
                                </div>
                              </Tooltip>
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <Tooltip
                                title="Un-Archive"
                                placement="top"
                                onClick={() => handleUnArchive(row)}>
                                <div className="table-edit-icon rounded-4 p-2">
                                  <InventoryIcon
                                    sx={{
                                      color: "#5c6d8e",
                                      fontSize: 18,
                                      cursor: "pointer",
                                    }}
                                  />
                                </div>
                              </Tooltip>
                              <Tooltip
                                title="Delete"
                                placement="top">
                                <div
                                  className="table-edit-icon rounded-4 p-2"
                                  onClick={(e) => {
                                    handleDelete(row);
                                  }}>
                                  <DeleteIcon
                                    sx={{
                                      color: "#5c6d8e",
                                      fontSize: 18,
                                      cursor: "pointer",
                                    }}
                                  />
                                </div>
                              </Tooltip>
                            </React.Fragment>
                          )}

                          {/* <img
                              src={verticalDots}
                              alt="verticalDots"
                              className="c-pointer"
                              aria-describedby={idActions}
                              variant="contained"
                              onClick={handleActionClick}
                            /> */}
                          <Popover
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "center",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "center",
                            }}
                            id={idActions}
                            open={openActions}
                            anchorEl={anchorActionEl}
                            onClose={handleActionClose}>
                            <div className="py-2 px-2">
                              <small className="text-grey-7 px-2">ACTIONS</small>
                              <hr className="hr-grey-6 my-2" />
                              <small className="p-2 rounded-3 text-lightBlue c-pointer font2 d-block hover-back">
                                Edit Member
                              </small>
                              <small className="p-2 rounded-3 text-lightBlue c-pointer font2 d-block hover-back">
                                Send Reset Password Link
                              </small>
                              <small className="p-2 rounded-3 text-lightBlue c-pointer font2 d-block hover-back">
                                Change Team Role
                              </small>
                              <div className="d-flex justify-content-between  hover-back rounded-3 p-2 c-pointer">
                                <small className="text-lightBlue font2 d-block">
                                  Block & Archieve
                                </small>
                                <img
                                  src={deleteRed}
                                  alt="delete"
                                  className=""
                                />
                              </div>
                            </div>
                          </Popover>
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page - 1}
            onPageChange={changePage}
            onRowsPerPageChange={changeRowsPerPage}
            className="table-pagination"
          />
        </>
      ) : isLoading ? (
        <span className="d-flex justify-content-center m-3">
          <TableLoader />
        </span>
      ) : (
        <span className="d-flex justify-content-center m-3">
          <NoDataFound />
        </span>
      )}
      <SwipeableDrawer
        anchor="right"
        open={activityDrawer["right"]}
        onClose={toggleActivityDrawer("right", false)}
        onOpen={toggleActivityDrawer("right", true)}>
        <div className="px-3 activity-top bg-black-13 pb-3">
          <div className="d-flex justify-content-between py-3 px-0">
            <div className="d-flex align-items-center">
              <KeyboardArrowLeftOutlinedIcon sx={{ fontSize: 25, color: "#c8d8ff" }} />
              <img
                src={rolesAdmin}
                alt="member"
                className="me-2 ms-2"
                height={40}
                width={40}
              />
              <div>
                <h5 className="text-lightBlue fw-500">Super Admin</h5>
                <small className="mt-2 text-grey-6">4 Members</small>
              </div>
            </div>
            <div className="c-pointer d-flex filter-icon me-1 align-items-center">
              <EditOutlinedIcon sx={{ color: "#658DED", fontSize: 14 }} />
              <small className="text-blue-2 ms-1">Edit</small>
            </div>
          </div>

          <div className="d-flex align-items-center">
            <Paper
              sx={{ width: "100%", mb: 0, mt: 2, p: 0 }}
              className="shadow-none">
              <Box
                sx={{ width: "100%" }}
                className="d-flex justify-content-between tabs-header-box">
                {/* variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile */}
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="scrollable force tabs example"
                  className="tabs">
                  <Tab
                    label="Members"
                    className="tabs-head"
                  />
                  <Tab
                    label="Permissions"
                    className="tabs-head"
                  />
                </Tabs>
              </Box>
              <TabPanel
                value={value}
                index={0}>
                <div className="d-flex flex-column">
                  <div className="d-flex justify-content-between my-3">
                    <div className="d-flex align-items-center">
                      <img
                        src={user}
                        alt="user"
                        className="me-2 rounded-circle"
                        height={45}
                        width={45}
                      />

                      <div>
                        <p className="text-lightBlue rounded-circle fw-500">Saniya Shaikh</p>
                        <div className="d-flex align-items-center">
                          <small className=" text-grey-6 me-2">4 Members</small>
                          <ContentCopyIcon sx={{ fontSize: 12, color: "#c8d8ff" }} />
                        </div>
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <ChatIcon sx={{ fontSize: 18, color: "#c8d8ff" }} />
                      <small className="text-lightBlue ms-2">Message</small>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between my-3">
                    <div className="d-flex align-items-center">
                      <img
                        src={user}
                        alt="user"
                        className="me-2 rounded-circle"
                        height={45}
                        width={45}
                      />

                      <div>
                        <p className="text-lightBlue rounded-circle fw-500">Saniya Shaikh</p>
                        <div className="d-flex align-items-center">
                          <small className=" text-grey-6 me-2">4 Members</small>
                          <ContentCopyIcon sx={{ fontSize: 12, color: "#c8d8ff" }} />
                        </div>
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <ChatIcon sx={{ fontSize: 18, color: "#c8d8ff" }} />
                      <small className="text-lightBlue ms-2">Message</small>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between my-3">
                    <div className="d-flex align-items-center">
                      <img
                        src={user}
                        alt="user"
                        className="me-2 rounded-circle"
                        height={45}
                        width={45}
                      />

                      <div>
                        <p className="text-lightBlue rounded-circle fw-500">Saniya Shaikh</p>
                        <div className="d-flex align-items-center">
                          <small className=" text-grey-6 me-2">4 Members</small>
                          <ContentCopyIcon sx={{ fontSize: 12, color: "#c8d8ff" }} />
                        </div>
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <ChatIcon sx={{ fontSize: 18, color: "#c8d8ff" }} />
                      <small className="text-lightBlue ms-2">Message</small>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between my-3">
                    <div className="d-flex align-items-center">
                      <img
                        src={user}
                        alt="user"
                        className="me-2 rounded-circle"
                        height={45}
                        width={45}
                      />

                      <div>
                        <p className="text-lightBlue rounded-circle fw-500">Saniya Shaikh</p>
                        <div className="d-flex align-items-center">
                          <small className=" text-grey-6 me-2">4 Members</small>
                          <ContentCopyIcon sx={{ fontSize: 12, color: "#c8d8ff" }} />
                        </div>
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <ChatIcon sx={{ fontSize: 18, color: "#c8d8ff" }} />
                      <small className="text-lightBlue ms-2">Message</small>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel
                value={value}
                index={1}>
                <div className="">
                  <div className="d-flex justify-content-between align-items-center mt-3">
                    <h6 className="text-lightBlue ps-3">Permissions</h6>
                    <div className="ps-3 rounded-4 border-lightBlue">
                      <FormControlLabel
                        control={
                          <Checkbox
                            size="small"
                            style={{
                              color: "#5C6D8E",
                              marginRight: 0,
                              // pointerEvents: "auto",
                            }}
                          />
                        }
                        label="Select All"
                        sx={{
                          "& .MuiTypography-root": {
                            fontSize: 14,
                            color: "#c8d8ff",
                            // pointerEvents: "auto",
                          },
                        }}
                      />
                    </div>
                  </div>
                  {permissionAccordionData.map((e, i) => (
                    <Accordion
                      expanded={expanded === `panel${i}`}
                      onChange={handleAccordianChange(`panel${i}`)}
                      key={i}
                      className="teams-accordion">
                      <AccordionSummary
                        aria-controls={`panel${i}d-content`}
                        id={`panel${i}d-header`}>
                        <div className="d-flex justify-content-between w-100 pe-4">
                          <FormControlLabel
                            control={
                              <Checkbox
                                size="small"
                                style={{
                                  color: "#5C6D8E",
                                  marginRight: 0,
                                }}
                              />
                            }
                            label={e.name}
                            sx={{
                              "& .MuiTypography-root": {
                                fontSize: 14,
                                color: "#c8d8ff",
                              },
                            }}
                          />

                          <button
                            className="button-grey py-2 px-3"
                            aria-describedby={idAccess}
                            variant="contained"
                            onClick={handleAccessClick}>
                            <small className="text-lightBlue">View Access</small>
                          </button>

                          <Popover
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                            id={idAccess}
                            open={openAccess}
                            anchorEl={anchorAccessEl}
                            onClose={handleAccessClose}>
                            <div className="py-2 px-1">
                              <small className="p-2 rounded-3 text-lightBlue c-pointer font2 d-block hover-back">
                                View Access Only
                              </small>
                              <small className="p-2 rounded-3 text-lightBlue c-pointer font2 d-block hover-back">
                                View and Edit Access
                              </small>
                            </div>
                          </Popover>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <FormGroup className="tags-checkbox">
                          {e.accDetails.map((sub, i) => (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  size="small"
                                  style={{
                                    color: "#5C6D8E",
                                    marginRight: 0,
                                  }}
                                />
                              }
                              key={i}
                              label={sub.name}
                            />
                          ))}
                        </FormGroup>
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </div>
              </TabPanel>
            </Paper>
          </div>
        </div>
      </SwipeableDrawer>
      <ArchiveModal
        onConfirm={handleArchiveModalOnSave}
        onCancel={handleArchiveModalClose}
        show={showArchivedModal}
        title={"Member"}
        message={member?.fullName}
        products={""}
        archiveType=""
        primaryMessage={`This will archive <span className='text-blue-2'>${member?.fullName}</span> ? Would you like to Archive this member ? `}
      />
      <MultipleArchiveModal
        onConfirm={handleMultipleArchiveModalOnSave}
        onCancel={handleArchiveModalClose}
        show={showMultipleArchivedModal}
        title={"Members"}
        message={`${
          selected.length === 1 ? `${selected.length} member` : `${selected.length} members`
        }`}
        pronoun={`${selected.length === 1 ? "this" : `these`}`}
      />

      <UnArchiveModalSecondary
        onConfirm={handleUnArchiveModalOnSave}
        onCancel={handleUnArchiveModalClose}
        show={showUnArchivedModal}
        title={"Member"}
        message={member?.fullName}
      />
      <UnMultipleArchiveModalSecondary
        onConfirm={handleMultipleUnArchiveModalOnSave}
        onCancel={handleUnArchiveModalClose}
        show={showMultipleUnArhcivedModal}
        title={"members"}
        message={`${
          selected.length === 1 ? `${selected.length} member` : `${selected.length} members`
        }`}
        pronoun={`${selected.length === 1 ? "this" : `these`}`}
      />
      <DeleteModalSecondary
        onConfirm={isMassDelete ? handleMultipleDeleteOnSave : handleDeleteModalOnSave}
        onCancel={handleDeleteModalClose}
        show={isMassDelete ? showMultipleDeleteModal : showDeleteModal}
        title={isMassDelete ? "multiple member" : "Member"}
        message={
          isMassDelete
            ? `${
                selected.length === 1
                  ? `${selected.length} member`
                  : `${selected.length} members`
              }`
            : member?.fullName
        }
      />
    </React.Fragment>
  );
};

export default MembersTable;
