import apiSlice from "../../../app/api/apiSlice";
import { omitNullishKeys } from "../../../utils/helper";

export const morefeaturesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllMoreFeatures: builder.query({
      query: (queries = {}) => ({
        url: "/maylike",
        params: omitNullishKeys(queries),
      }),
      transformResponse: (res) => res.data,
      providesTags: ["MoreFeatures"],
    }),

    createMoreFeatures: builder.mutation({
      query: (data) => ({
        url: "/maylike",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["MoreFeatures", "Logs"],
    }),

    editMoreFeatures: builder.mutation({
      query: ({ id, details }) => ({
        url: `/maylike/${id}`,
        method: "PUT",
        body: details,
      }),
      invalidatesTags: ["MoreFeatures", "Logs"],
    }),

    // deleteReview: builder.mutation({
    //   query: (id) => ({
    //     url: `/products/review/${id}`,
    //     method: "DELETE",
    //   }),
    //   invalidatesTags: ["Reviews","Logs"],
    // }),
  }),
});

export const {
  useGetAllMoreFeaturesQuery,
  useCreateMoreFeaturesMutation,
  useEditMoreFeaturesMutation,
} = morefeaturesApiSlice;
