import apiSlice from "../../../app/api/apiSlice";
import { omitEmptyKeys } from "../../../utils/helper";

export const inventoryApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllInventories: builder.query({
      query: (queries = {}) => ({
        url: "/products/inventory",
        params: omitEmptyKeys(queries),
      }),
      providesTags: ["Inventories"],
    }),

    // getAllInventoriesCount: builder.query({
    //   query: (queries = {}) => {
    //     const queryString = new URLSearchParams(queries);
    //     return {
    //       url: `/products/inventory/count?${queryString}`,
    //     };
    //   },
    //   providesTags: ["Inventories"],
    // }),
    getAllInventoryProducts: builder.query({
      query: ({ storeId = "", queries = {} }) => ({
        url: `/products/inventory/productList/${storeId}`,
        params: omitEmptyKeys(queries),
      }),
      transformResponse: (res) => res.data,
      providesTags: ["InventoryProducts"],
    }),
    getAllProductsInventory: builder.query({
      query: ({ storeId = "", queries = {} }) => ({
        url: `/products/inventory/productInventroy/${storeId}`,
        params: omitEmptyKeys(queries),
      }),
      transformResponse: (res) => res.data,
      providesTags: ["InventoryProducts"],
    }),

    // getInventoriesOnCall: builder.mutation({
    //   query: (queries = {}) => {
    //     return {
    //       url: "/products/inventory/list",
    //       params: omitNullishKeys(queries),
    //       method: "GET",
    //     };
    //   },
    // }),

    // createInventory: builder.mutation({
    //   query: (data) => ({
    //     url: "/products/inventory",
    //     method: "POST",
    //     body: data,
    //   }),
    //   invalidatesTags: ["Products", "Inventories","Logs"],
    // }),

    // createWithSetInventory: builder.mutation({
    //   query: (data) => ({
    //     url: "/products/inventory/create",
    //     method: "POST",
    //     body: data,
    //   }),
    //   invalidatesTags: ["Products", "Inventories","Logs"],
    // }),

    // createWithPriceMaster: builder.mutation({
    //   query: (data) => ({
    //     url: "/products/inventory/globalPrice",
    //     method: "POST",
    //     body: data,
    //   }),
    //   invalidatesTags: ["Products", "Inventories","Logs"],
    // }),

    // editInventory: builder.mutation({
    //   query: ({ id, details }) => ({
    //     url: `/products/inventory/${id}`,
    //     method: "PUT",
    //     body: details,
    //   }),
    //   invalidatesTags: ["Products", "Inventories","Logs"],
    // }),

    bulkAddOrUpdate: builder.mutation({
      query: (updates) => ({
        url: `/products/inventory/bulkUpdate`,
        method: "PUT",
        body: updates,
      }),
      invalidatesTags: [
        "Inventory",
        "InventoryProducts",
        "Variants",
        "Products",
        "SingleProduct",
        "Logs",
      ],
    }),

    bulkShippingCreate: builder.mutation({
      query: (updates) => ({
        url: `/products/shipping/bulkCreate`,
        method: "POST",
        body: updates,
      }),
      invalidatesTags: ["Shipping", "Variants", "Products", "SingleProduct", "Logs"],
    }),

    bulkShippingUpdate: builder.mutation({
      query: (updates) => ({
        url: `/products/shipping/bulkUpdate`,
        method: "PUT",
        body: updates,
      }),
      invalidatesTags: ["Shipping", "Variants", "Products", "SingleProduct", "Logs"],
    }),

    // bulkDeleteInventoryByProduct: builder.mutation({
    //   query: (pid) => ({
    //     url: `products/inventory/deleteByProduct/${pid}`,
    //     method: "DELETE",
    //   }),
    //   invalidatesTags: ["Products", "Inventories","Logs"],
    // }),
  }),
});

export const {
  useGetAllInventoriesQuery,
  useGetAllInventoryProductsQuery,
  useGetAllProductsInventoryQuery,
  useBulkAddOrUpdateMutation,
  useBulkShippingCreateMutation,
  useBulkShippingUpdateMutation,
} = inventoryApiSlice;
