import apiSlice from "../../../app/api/apiSlice";

import { omitNullishKeys } from "../../../utils/helper";

export const optionsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllOptions: builder.query({
      query: (queries) => {
        return {
          url: "/parameters/optionSet/attribute",
          params: omitNullishKeys(queries),
        };
      },
      transformResponse: (res) => res.data,
      providesTags: ["Options"],
    }),
    getOptionsCount: builder.query({
      query: (queries) => {
        return {
          url: "/parameters/optionSet/attribute/count",
          params: omitNullishKeys(queries),
        };
      },
      transformResponse: (res) => res.data,
      providesTags: ["OptionsCount"],
    }),
    createOption: builder.mutation({
      query: (optionDetails) => ({
        url: "/parameters/optionSet/attribute",
        method: "POST",
        body: optionDetails,
      }),
      transformResponse: (res) => res.data,
      invalidatesTags: [
        "Options",
        "OptionSets",
        "OptionsCount",
        "PriceMaster",
        "PriceManager",
        "Logs",
      ],
    }),
    updateOption: builder.mutation({
      query: ({ details, id }) => ({
        url: `/parameters/optionSet/attribute/${id}`,
        method: "PUT",
        body: details,
      }),
      transformResponse: (res) => res.data,
      invalidatesTags: [
        "Options",
        "OptionSets",
        "OptionsCount",
        "PriceMaster",
        "PriceManager",
        "Logs",
      ],
    }),
    deleteOption: builder.mutation({
      query: (id) => ({
        url: `/parameters/optionSet/attribute/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [
        "Options",
        "OptionSets",
        "OptionsCount",
        "PriceMaster",
        "PriceManager",
        "Logs",
      ],
    }),
    getAllAttributes: builder.query({
      query: (queries) => {
        return {
          url: "/parameters/optionSet/metaAttribute",
          params: omitNullishKeys(queries),
        };
      },
      transformResponse: (res) => res.data,
      providesTags: ["Attributes"],
    }),
    createAttribute: builder.mutation({
      query: (attributeDetails) => ({
        url: "/parameters/optionSet/metaAttribute",
        method: "POST",
        body: attributeDetails,
      }),
      transformResponse: (res) => res.data,
      invalidatesTags: ["Attributes", "OptionSets", "PriceMaster", "PriceManager", "Logs"],
    }),
    updateAttribute: builder.mutation({
      query: ({ details, id }) => ({
        url: `/parameters/optionSet/metaAttribute/${id}`,
        method: "PUT",
        body: details,
      }),
      transformResponse: (res) => res.data,
      invalidatesTags: ["Attributes", "OptionSets", "PriceMaster", "PriceManager", "Logs"],
    }),
    deleteAttribute: builder.mutation({
      query: (id) => ({
        url: `/parameters/optionSet/metaAttribute/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Attributes", "OptionSets", "PriceMaster", "PriceManager", "Logs"],
    }),
    getAllSubOptions: builder.query({
      query: (queries) => {
        return {
          url: "/parameters/optionSet/metaSubAttribute",
          params: omitNullishKeys(queries),
        };
      },
      transformResponse: (res) => res.data,
      providesTags: ["SubOptions"],
    }),
    createSubOption: builder.mutation({
      query: (subOptionDetails) => ({
        url: "/parameters/optionSet/metaSubAttribute",
        method: "POST",
        body: subOptionDetails,
      }),
      transformResponse: (res) => res.data,
      invalidatesTags: ["SubOptions", "OptionSets", "PriceMaster", "PriceManager", "Logs"],
    }),
    updateSubOption: builder.mutation({
      query: ({ details, id }) => ({
        url: `/parameters/optionSet/metaSubAttribute/${id}`,
        method: "PUT",
        body: details,
      }),
      transformResponse: (res) => res.data,
      invalidatesTags: ["SubOptions", "OptionSets", "PriceMaster", "PriceManager", "Logs"],
    }),
    deleteSubOption: builder.mutation({
      query: (id) => ({
        url: `/parameters/optionSet/metaSubAttribute/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["SubOptions", "OptionSets", "PriceMaster", "PriceManager", "Logs"],
    }),
    getAllSubAttributes: builder.query({
      query: (queries) => {
        return {
          url: "/parameters/optionSet/metaSubAttributeValue",
          params: omitNullishKeys(queries),
        };
      },
      transformResponse: (res) => res.data,
      providesTags: ["SubAttributes"],
    }),
    getAllMetaSubAttributes: builder.query({
      query: (queries) => {
        return {
          url: "/parameters/optionSet/stoneAttributeValue",
          params: omitNullishKeys(queries),
        };
      },
      transformResponse: (res) => res.data,
      providesTags: ["SubAttributes"],
    }),
    createSubAttribute: builder.mutation({
      query: (subAttributeDetails) => ({
        url: "/parameters/optionSet/metaSubAttributeValue",
        method: "POST",
        body: subAttributeDetails,
      }),
      transformResponse: (res) => res.data,
      invalidatesTags: ["SubAttributes", "OptionSets", "PriceMaster", "PriceManager", "Logs"],
    }),
    updateSubAttribute: builder.mutation({
      query: ({ details, id }) => ({
        url: `/parameters/optionSet/metaSubAttributeValue/${id}`,
        method: "PUT",
        body: details,
      }),
      transformResponse: (res) => res.data,
      invalidatesTags: ["SubAttributes", "OptionSets", "PriceMaster", "PriceManager", "Logs"],
    }),
    deleteSubAttribute: builder.mutation({
      query: (id) => ({
        url: `/parameters/optionSet/metaSubAttributeValue/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["SubAttributes", "OptionSets", "PriceMaster", "PriceManager", "Logs"],
    }),
    getStoneAttribute: builder.query({
      query: (queries) => {
        return {
          url: "/parameters/optionSet/stoneAttribute",
          params: omitNullishKeys(queries),
        };
      },
      transformResponse: (res) => res.data,
    }),
    getStoneAttributeValue: builder.query({
      query: (queries) => {
        return {
          url: "/parameters/optionSet/stoneAttributeValue",
          params: omitNullishKeys(queries),
        };
      },
      transformResponse: (res) => res.data,
      providesTags: ["StoneAttr"],
    }),
    createStoneAttribute: builder.mutation({
      query: (details) => ({
        url: "/parameters/optionSet/stoneAttributeValue",
        method: "POST",
        body: details,
      }),
      transformResponse: (res) => res.data,
      invalidatesTags: ["StoneAttr", "Logs"],
    }),
    updateStoneAttribute: builder.mutation({
      query: ({ details, id }) => ({
        url: `/parameters/optionSet/stoneAttributeValue/${id}`,
        method: "PUT",
        body: details,
      }),
      transformResponse: (res) => res.data,
      invalidatesTags: ["StoneAttr", "Logs"],
    }),
    getSizeAttribute: builder.query({
      query: (queries) => {
        return {
          url: "/parameters/optionSet/sizeAttribute",
          params: omitNullishKeys(queries),
        };
      },
      transformResponse: (res) => res.data,
    }),
    deleteStoneAttribute: builder.mutation({
      query: (id) => ({
        url: `/parameters/optionSet/stoneAttributeValue/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["StoneAttr", "Logs"],
    }),
  }),
});

export const {
  useGetAllOptionsQuery,
  useGetOptionsCountQuery,
  useCreateOptionMutation,
  useUpdateOptionMutation,
  useDeleteOptionMutation,
  useGetAllAttributesQuery,
  useCreateAttributeMutation,
  useUpdateAttributeMutation,
  useDeleteAttributeMutation,
  useGetAllSubOptionsQuery,
  useCreateSubOptionMutation,
  useUpdateSubOptionMutation,
  useDeleteSubOptionMutation,
  useGetAllSubAttributesQuery,
  useGetAllMetaSubAttributesQuery,
  useCreateSubAttributeMutation,
  useUpdateSubAttributeMutation,
  useDeleteSubAttributeMutation,
  useGetStoneAttributeQuery,
  useGetStoneAttributeValueQuery,
  useCreateStoneAttributeMutation,
  useUpdateStoneAttributeMutation,
  useGetSizeAttributeQuery,
  useDeleteStoneAttributeMutation,
} = optionsApiSlice;
