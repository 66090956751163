import { useMemo, useState } from "react";
// ! IMAGES IMPORTS
import arrowDown from "../../assets/icons/arrowDown.svg";
// ! MATERIAL IMPORTS
import { FormGroup, FormControlLabel, Popover, Checkbox, Slider } from "@mui/material";
import { PaymentStatuses } from "./AllOrders/FilterOrders";
import { useGetOrderRangeQuery } from "../../features/orders/ordersApiSlice";

const minDistance = 1000;

const AmountSpentFilter = ({ minQueryPrice = 0, maxQueryPrice = 0, onChange = () => {} }) => {
  // * STATUS POPOVERS STARTS
  const [anchorStatusEl, setAnchorStatusEl] = useState(null);

  const handleStatusClick = (event) => {
    setAnchorStatusEl(event.currentTarget);
  };

  const handleStatusClose = () => {
    setAnchorStatusEl(null);
  };

  const openStatus = Boolean(anchorStatusEl);
  // * STATUS POPOVERS ENDS

  const { data: orderRangeData } = useGetOrderRangeQuery();
  const [minPrice, maxPrice] = useMemo(() => {
    const { highestPrice = 0, lowestPrice = 0 } = orderRangeData?.data ?? {};
    return [
      Math.floor(lowestPrice / minDistance) * minDistance,
      Math.ceil(highestPrice / minDistance) * minDistance,
    ];
  }, [orderRangeData]);

  const onPriceChange = (e, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) return;
    onChange(
      Math.max(Math.min(newValue[0], maxQueryPrice - minDistance), 0),
      Math.max(newValue[1], minQueryPrice + minDistance)
    );
  };

  return (
    <>
      <button
        onClick={handleStatusClick}
        className="button-grey py-1 px-3 d-none d-md-block">
        <small className="text-lightBlue">Amount Spent</small>
        <img
          src={arrowDown}
          alt="arrowDown"
          className="ms-2"
        />
      </button>
      <Popover
        open={openStatus}
        anchorEl={anchorStatusEl}
        onClose={handleStatusClose}
        PaperProps={{ style: { width: "40%" }, className: "pt-2 px-1" }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}>
        <div className="row text-lightBlue text-center">
          <div className="col-2">
            <span>{minQueryPrice}</span>
          </div>
          <div className="col-8">
            <Slider
              step={minDistance}
              value={[minQueryPrice, maxQueryPrice]}
              min={minPrice}
              max={maxPrice}
              onChange={onPriceChange}
              valueLabelDisplay="auto"
              getAriaValueText={(v) => v}
              disableSwap
            />
          </div>
          <div className="col-2">
            <span>{maxQueryPrice}</span>
          </div>
        </div>
      </Popover>
    </>
  );
};

export default AmountSpentFilter;
