import { useState, useEffect } from "react";
import {
  FormControl,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormHelperText,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import YupPassword from "yup-password";
import { useFormik } from "formik";

import { showSuccess, showError } from "../../../features/snackbar/snackbarAction";
import { useInviteUpMutation, useSignUpMutation } from "../../../features/auth/authApiSlice";
import { loginHandler } from "../../../features/auth/authAction";
import { setUserHandler } from "../../../features/user/userAction";

import "./Signup.scss";

// YupPassword(Yup);

const signUpValidationSchema = Yup.object({
  email: Yup.string().trim().email("email is not valid").required("required"),
  password: Yup.string()
    .trim()
    // .password()
    // .min(8, "must be minimum 8 characters")
    // .minLowercase(1, "must include 1 lowercase letter")
    // .minUppercase(1, "must include 1 uppercase letter")
    // .minSymbols(1, "must include 1 special letter")
    // .minNumbers(1, "must include 1 number letter")
    .required("required"),
  // userName: Yup.string().trim().min(3).required("required"),
});

export default function Signup() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);

  const [searchParams] = useSearchParams();
  // console.log("searchParams", Object.fromEntries(searchParams.entries()));

  const [
    signUp,
    {
      data: signUpData,
      isSuccess: signUpIsSuccess,
      isLoading: signUpIsLoading,
      error: signUpError,
    },
  ] = useInviteUpMutation();

  console.log(searchParams.get("email"));

  const formik = useFormik({
    initialValues: {
      email: searchParams.get("email") || "",
      password: "",
      temporaryPassword: searchParams.get("hash") || "",
      // userName: "",
    },
    enableReinitialize: true,
    validationSchema: signUpValidationSchema,
    onSubmit: (values, { resetForm }) => {
      signUp(values)
        .unwrap()
        .then(() => resetForm())
        .catch((err) => console.log(err));
    },
  });

  const toggleShowPasswordHandler = () => setShowPassword((prevState) => !prevState);

  useEffect(() => {
    if (signUpError) {
      if (signUpError.data?.message) {
        dispatch(showError({ message: signUpError.data.message }));
      } else {
        dispatch(showError({ message: "Something went wrong!, please try again" }));
      }
    }

    if (signUpIsSuccess) {
      // const {
      //   data: {
      //     data: { userId, userName, email },
      //     Authorization: accessToken,
      //   },
      // } = signUpData;
      // dispatch(loginHandler({ accessToken, refreshToken: "" }));
      // dispatch(setUserHandler({ email, userId, userName }));
      dispatch(showSuccess({ message: "Check in successful" }));
      navigate("/login", { replace: true });
    }
  }, [signUpError, signUpIsSuccess, signUpData, dispatch, navigate]);

  return (
    <div className="container-fluid signup">
      <div className="row align-items-center justify-content-center py-5 align-items-center signup-row">
        <div className="col-auto signup-box border-grey-5 rounded-8 px-4 py-5 justify-content-center text-center">
          <h3 className="text-lightBlue fw-600 text-center">Invite Check In</h3>
          {/* <p className="text-grey-6 text-center mt-3">
            Already have an account?&nbsp;
            <span className="text-blue-gradient">
              <Link to="/auth/login"> Sign In</Link>
            </span>
          </p> */}

          <form
            noValidate
            onSubmit={formik.handleSubmit}>
            {/* <div className="mt-4">
              <p className="text-lightBlue mb-1 text-start">Username</p>
              <FormControl className="w-100 px-0">
                <OutlinedInput
                  placeholder="Enter Username"
                  size="small"
                  sx={{ paddingLeft: 0 }}
                  name="userName"
                  value={formik.values.userName}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                {!!formik.touched.userName && formik.errors.userName && (
                  <FormHelperText error>{formik.errors.userName}</FormHelperText>
                )}
              </FormControl>
            </div> */}

            <div className="mt-4">
              <p className="text-lightBlue mb-1 text-start">Enter Email</p>
              <FormControl className="w-100 px-0">
                <OutlinedInput
                  placeholder="Enter Email"
                  size="small"
                  sx={{ paddingLeft: 0 }}
                  type="email"
                  name="email"
                  value={formik.values.email}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                {!!formik.touched.email && formik.errors.email && (
                  <FormHelperText error>{formik.errors.email}</FormHelperText>
                )}
              </FormControl>
            </div>

            <div className="mt-4">
              <p className="text-lightBlue mb-1 text-start">Enter Password</p>
              <FormControl className="w-100 px-0">
                <OutlinedInput
                  placeholder="Enter Password"
                  size="small"
                  sx={{ paddingLeft: 0 }}
                  type={!showPassword ? "password" : "text"}
                  name="password"
                  value={formik.values.password}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={toggleShowPasswordHandler}
                        type="button"
                        edge="end">
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {!!formik.touched.password && formik.errors.password && (
                  <FormHelperText error>{formik.errors.password}</FormHelperText>
                )}
              </FormControl>
            </div>
            <LoadingButton
              loading={signUpIsLoading}
              disabled={signUpIsLoading || signUpIsSuccess}
              type="submit"
              className="button-gradient py-2 w-100 px-3 mt-4">
              <p>Check In</p>
            </LoadingButton>
          </form>
          <div className="d-flex row">
            <div className="col-12 mt-4 text-center d-flex justify-content-center">
              <small className="text-grey-6 w-75 d-block text-center">
                By creating an account means you agree to the Terms & Conditions and our Privacy
                Policy
              </small>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
