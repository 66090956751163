import { useState } from "react";
// ! IMAGES IMPORTS
import arrowDown from "../../assets/icons/arrowDown.svg";
// ! MATERIAL IMPORTS
import { FormGroup, FormControlLabel, Popover, Checkbox } from "@mui/material";
import { PaymentStatuses } from "./AllOrders/FilterOrders";

const PaymentStatusFilter = ({ list = [], onChange = () => {} }) => {
  // * STATUS POPOVERS STARTS
  const [anchorStatusEl, setAnchorStatusEl] = useState(null);

  const handleStatusClick = (event) => {
    setAnchorStatusEl(event.currentTarget);
  };

  const handleStatusClose = () => {
    setAnchorStatusEl(null);
  };

  const openStatus = Boolean(anchorStatusEl);
  // * STATUS POPOVERS ENDS

  const onStatusChange = (event) => {
    const { value, checked } = event.target;
    onChange(checked ? list.concat(value) : list.filter((v) => v !== value));
  };

  return (
    <>
      <button
        onClick={handleStatusClick}
        className="button-grey py-1 px-3 d-none d-md-block">
        <small className="text-lightBlue">Payment Status</small>
        <img
          src={arrowDown}
          alt="arrowDown"
          className="ms-2"
        />
      </button>
      <Popover
        open={openStatus}
        anchorEl={anchorStatusEl}
        onClose={handleStatusClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}>
        <div className=" px-1">
          <FormGroup
            sx={{ color: "#5C6D8E" }}
            className="tags-checkbox"
            onChange={onStatusChange}>
            {PaymentStatuses.map((ps) => (
              <FormControlLabel
                key={ps.value}
                value={ps.value}
                label={ps.text}
                checked={list.includes(ps.value)}
                control={<Checkbox size="small" />}
              />
            ))}
          </FormGroup>
        </div>
      </Popover>
    </>
  );
};

export default PaymentStatusFilter;
