import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import { useGetAllCityQuery } from "../../features/master/city/cityApiSlice";

export default function AppCitySelect({
  initVal,
  selectCityName,
  disabled = false,
  fullWidth = false,
  pincode = "",
  city,
}) {
  const {
    data: cityData,
    isLoading: cityIsLoading,
    isSuccess: cityIsSuccess,
    error: cityError,
  } = useGetAllCityQuery({ createdAt: -1, pincode });

  const cityList = React.useMemo(() => {
    const list = [];
    const cityList = Array.from(cityData?.data?.data || []);
    list.push(...cityList);
    if (city) {
      if (cityList?.findIndex((e) => e?._id === city?._id) === -1) {
        list.push(city);
      }
    }
    return list;
  }, [city, cityData]);

  const selectedCode = React.useMemo(
    () => cityList?.find((city) => city._id === initVal),
    [initVal, cityList]
  );

  return (
    <Autocomplete
      disabled={disabled}
      fullWidth={fullWidth}
      id="country-select-demo"
      sx={{ width: fullWidth ? null : 300 }}
      options={cityList}
      autoHighlight
      size="small"
      getOptionLabel={(option) => option?.name}
      value={selectedCode || null}
      onChange={selectCityName}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
          {...props}
        >
          <small className="text-lightBlue my-1">{option.name}</small>
        </Box>
      )}
      renderTags={(value) => (
        <small className="fw-400 text-lightBlue">{value.name}</small>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          //   label="Choose a country"
          placeholder="Select city"
          style={{ width: fullWidth ? null : "87%" }}
        />
      )}
    />
  );
}
