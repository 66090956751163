import React from "react";
// ! IMAGES IMPORTS
import cancel from "../../assets/icons/cancel.svg";
// ! MATERIAL IMPORTS
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormHelperText,
  Slide,
  TextareaAutosize,
} from "@mui/material";
import { useCreateCustomerTimelineMutation } from "../../features/customers/customer/customerTimelineApiSlice";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { showError, showSuccess } from "../../features/snackbar/snackbarAction";

// ? DIALOG TRANSITION STARTS HERE
const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="up"
      ref={ref}
      {...props}
    />
  );
});
// ? DIALOG TRANSITION ENDS HERE

const validationSchema = Yup.object().shape({
  notes: Yup.string().trim().max(128, "Max 128 Characters").required("Note is Required"),
  customer: Yup.string().required("Note is Required"),
  notifyUser: Yup.boolean().default(false),
});

const AddNotesDialog = ({ show = false, customerId = "", onClose = () => {} }) => {
  const dispatch = useDispatch();

  const [createTimeline, { isLoading: createTimelineIsLoading }] =
    useCreateCustomerTimelineMutation();

  const formik = useFormik({
    initialValues: {
      notes: "",
      customer: customerId,
      notifyUser: false,
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      createTimeline(values)
        .unwrap()
        .then(() => {
          closeDialog();
          dispatch(
            showSuccess({
              message: "Timeline added successfully",
            })
          );
        })
        .catch((e) => {
          console.log(e);
          dispatch(
            showError({
              message: e?.data?.message ?? e?.message ?? "Something went wrong!",
            })
          );
        });
    },
  });

  const closeDialog = () => {
    if (!createTimelineIsLoading) {
      formik.resetForm();
      onClose();
    }
  };

  return (
    <Dialog
      open={show}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      maxWidth="sm"
      fullWidth>
      <DialogTitle>
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex flex-column ">
            <h5 className="text-lightBlue fw-500">Add Notes</h5>
            {/* <small className="text-lightBlue mt-1 d-block">saniya@mydesignar.com</small> */}
          </div>
          <img
            src={cancel}
            alt="cancel"
            width={30}
            onClick={onClose}
            className="c-pointer"
          />
        </div>
      </DialogTitle>
      <hr className="hr-grey-6 my-0" />
      <DialogContent className="pt-3 pb-4 px-4">
        <FormControlLabel
          control={
            <Checkbox
              name="notifyUser"
              checked={formik.values.notifyUser}
              onChange={formik.handleChange}
              size="small"
              style={{
                color: "#5C6D8E",
                marginRight: 0,
                width: "auto",
              }}
            />
          }
          label="Notify User"
          sx={{
            "& .MuiTypography-root": {
              fontSize: "0.875rem",
              // color: "#c8d8ff",
              color: "#5c6d8e",
            },
          }}
          className=" px-0"
        />
        <TextareaAutosize
          name="notes"
          value={formik.values.notes}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder="Type Something"
          style={{
            background: "#15142A",
            color: "#c8d8ff",
            borderRadius: 5,
          }}
          minRows={3}
          className="col-12 mt-3"
        />
        <FormHelperText error>{formik.touched.notes && formik.touched.notes}</FormHelperText>
      </DialogContent>
      <hr className="hr-grey-6 my-0" />
      <DialogActions className="d-flex justify-content-between px-4 py-3">
        <button
          className="button-grey py-2 px-5"
          onClick={closeDialog}
          disabled={createTimelineIsLoading}>
          <p className="text-lightBlue">Cancel</p>
        </button>
        <button
          className="button-gradient py-2 px-5"
          onClick={formik.handleSubmit}
          disabled={createTimelineIsLoading}>
          <p>Save</p>
        </button>
      </DialogActions>
    </Dialog>
  );
};

export default AddNotesDialog;
