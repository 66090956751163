import React, { useMemo, useState } from "react";
// ! IMAGES IMPORTS
// import activity from "../../assets/icons/activity.svg";
import teamMember1 from "../../assets/images/products/teamMember1.svg";
import cancel from "../../assets/icons/cancel.svg";
// ! MATERIAL IMPORTS
import { SwipeableDrawer, TablePagination } from "@mui/material";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
// import { DesktopDateTimePicker } from "@mui/x-date-pickers";
// ! MATERIAL ICONS IMPORTS
// import SearchIcon from "@mui/icons-material/Search";
import { useGetAllLogsQuery } from "../../features/logs/logsApiSlice";
import moment from "moment";
import { TableSearchSecondary } from "../TableSearch/TableSearch";
import NoDataFound from "../NoDataFound/NoDataFound";

const ViewLogsDrawer = ({ headingName, icon, module = "" }) => {
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchValue, setSearchValue] = useState("");
  const [message, setMessage] = useState("");

  const { data, isLoading, isFetching } = useGetAllLogsQuery(
    { module, pageNo, pageSize, message },
    { skip: !module }
  );

  const { logs, totalCount } = useMemo(() => {
    const { data: logs = [], totalCount = 0 } = data ?? {};
    return { logs, totalCount };
  }, [data]);

  const [activityDrawer, setActivityDrawer] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const toggleActivityDrawer = (anchor, open) => (event) => {
    if (event && event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }

    setActivityDrawer({ ...activityDrawer, [anchor]: open });

    setPageNo(1);
    setPageSize(10);
    setSearchValue("");
    setMessage("");
  };

  return (
    <>
      <button
        className="button-transparent me-1 py-2 px-3"
        onClick={toggleActivityDrawer("right", true)}>
        <p className="text-lightBlue">View Logs</p>
      </button>

      <SwipeableDrawer
        anchor="right"
        open={activityDrawer["right"]}
        onClose={toggleActivityDrawer("right", false)}
        onOpen={toggleActivityDrawer("right", true)}>
        <div className="px-3 activity-top bg-black-13 pb-3">
          <div className="d-flex justify-content-between py-3 px-0">
            <h6 className="text-lightBlue">View Logs</h6>
            <img
              src={cancel}
              alt="cancel"
              className="c-pointer filter-icon me-1"
              onClick={toggleActivityDrawer("right", false)}
            />
          </div>

          <div className="d-flex align-items-center">
            <div className="d-flex align-items-center">
              <img
                src={icon}
                alt="user"
                className="me-2"
                height={30}
                width={30}
              />
              <div>
                <p className="text-lightBlue fw-600">{headingName}</p>
                <small className="mt-2 text-grey-6">
                  Last modified on {moment(logs[0]?.createdAt).format("DD-MM-YYYY [at] h:mm a")}{" "}
                  by {logs[0]?.user?.fullName}
                </small>
              </div>
            </div>
          </div>

          <div className="d-flex mt-3">
            <TableSearchSecondary
              value={searchValue}
              onSearchValueChange={(val) => setSearchValue(val)}
              onChange={(val) => {
                setMessage(val);
                setPageNo(1);
              }}
            />
          </div>
        </div>

        <div className="">
          {logs.length > 0 ? (
            <table className="table table-borderless activity-table">
              <thead className="">
                <tr className="bg-black-15">
                  <th scope="col">
                    <small className="text-lightBlue fw-400"></small>
                  </th>
                  <th scope="col">
                    <small className="text-lightBlue fw-400">User</small>
                  </th>
                  <th scope="col">
                    <small className="text-lightBlue fw-400">Activity</small>
                  </th>
                  <th scope="col">
                    <small className="text-lightBlue fw-400">Date and Time</small>
                  </th>
                  <th scope="col">
                    <small className="text-lightBlue fw-400"></small>
                  </th>
                </tr>
              </thead>
              <tbody>
                {logs.map((log) => (
                  <tr key={log._id}>
                    {/* DONOT REMOVE THIS BLANK COLUMN DONE FOR STYLING */}
                    <td>
                      <small className="text-grey-6 fw-400"></small>
                    </td>
                    {/* DONOT REMOVE THIS BLANK COLUMN DONE FOR STYLING */}
                    <th scope="row">
                      <div className="d-flex align-items-center">
                        <img
                          src={teamMember1}
                          alt="teamMember1"
                          className="me-2"
                        />
                        <small className="text-lightBlue fw-400">{log?.user?.fullName}</small>
                      </div>
                    </th>
                    <td>
                      <small className="text-lightBlue">{log?.message}</small>
                    </td>
                    <td>
                      <small className="text-grey-6 fw-400">
                        {moment(log.createdAt).format("DD-MM-YYYY [at] h:mm a")}
                      </small>
                    </td>
                    {/* DONOT REMOVE THIS BLANK COLUMN DONE FOR STYLING */}
                    <td>
                      <small className="text-grey-6 fw-400"></small>
                    </td>
                    {/* DONOT REMOVE THIS BLANK COLUMN DONE FOR STYLING */}
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <NoDataFound />
          )}
          <TablePagination
            rowsPerPageOptions={[10, 20, 30]}
            className="table-pagination mt-2"
            component="div"
            count={totalCount}
            rowsPerPage={pageSize}
            page={pageNo - 1}
            onPageChange={(_, p) => {
              setPageNo(p + 1);
            }}
            onRowsPerPageChange={(e) => {
              setPageSize(Number(e.target.value) || 10);
              setPageNo(1);
            }}
          />
        </div>
      </SwipeableDrawer>
    </>
  );
};

export default ViewLogsDrawer;
