import { NavLink, Outlet } from "react-router-dom";
import { SidebarData } from "./MyProfileNavData";
import { ListItem, ListItemIcon, ListItemText, ToggleButton } from "@mui/material";
import { Visibility } from "@mui/icons-material";

const MyProfile = () => {
  const SideNavData = SidebarData;

  return (
    <div className="page container-fluid position-relative">
      <div className="row">
        <div className="col-lg-3">
          <div className="row mx-0 mt-1">
            <div className="col-12 px-0">
              <div className="d-flex flex-column mb-3">
                <h3 className="text-lightBlue fw-600 mb-2">My Profile</h3>
              </div>

              <div className="settingNav px-2">
                {SideNavData.map((data, index) => (
                  <NavLink to={data.path}>
                    <ListItem 
                      key={data.title} 
                      className="list-item"
                    >
                      <div className="settingActive" />
                      <ListItemIcon className="mx-3 list-icon">
                        <img src={data.image} />
                      </ListItemIcon>
                      <ListItemText primary={data.title} className="list-text" />
                    </ListItem>
                  </NavLink>
                ))} 
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-9">
            <Outlet />
        </div>
      </div>
    </div>
  );
};

export default MyProfile;