import { forwardRef, useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Slide,
} from "@mui/material";
import { showError, showSuccess } from "../../features/snackbar/snackbarAction";
import cancel from "../../assets/icons/cancel.svg";
import {
  useGetProductsCsvMutation,
  useGetVariantsCsvMutation,
} from "../../features/importExport/order/orderApiSlice";

// ? DIALOG TRANSITION STARTS HERE
const Transition = forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="up"
      ref={ref}
      {...props}
    />
  );
});
// ? DIALOG TRANSITION ENDS HERE

export default function ExportDialogProduct({ queryFilters = {}, currentSelection = [] }) {
  const { downloadExcel, isLoading } = useDownLoadExcel(queryFilters, currentSelection);

  const [type, setType] = useState("products");
  const handleTypeChange = (_, typ) => setType(typ);
  const [fileType, setFileType] = useState("csv");
  const handleFileTypeChange = (_, typ) => setFileType(typ);
  // "all", "current", "selected"
  const [option, setOption] = useState("");
  const handleExportChange = (_, opt) => setOption(opt);

  const [openExport, setOpenExport] = useState(false);
  const handleExportOpen = () => setOpenExport(true);
  const handleExportClose = () => !isLoading && setOpenExport(false);
  const handleExportSubmit = () =>
    !isLoading &&
    !!fileType &&
    !!type &&
    !!option &&
    downloadExcel(type, fileType, option, () => setOpenExport(false));

  return (
    <>
      <button
        className="button-transparent py-2 px-3 me-1"
        onClick={handleExportOpen}
        disabled={openExport}>
        <p className="text-lightBlue">Export</p>
      </button>

      <Dialog
        open={openExport}
        TransitionComponent={Transition}
        onClose={handleExportClose}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="sm"
        fullWidth>
        <DialogTitle>
          <div className="d-flex justify-content-between align-items-center">
            <h5 className="text-lightBlue text-capitalize fw-500">Export&nbsp;{type}</h5>
            <img
              src={cancel}
              alt="cancel"
              width={30}
              onClick={handleExportClose}
              className="c-pointer"
            />
          </div>
        </DialogTitle>
        <hr className="hr-grey-6 my-0" />
        <DialogContent className="py-3 px-4">
          <p className="text-lightBlue mb-2">Export</p>
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={type}
              onChange={handleTypeChange}>
              <FormControlLabel
                value="products"
                control={<Radio size="small" />}
                label="Products"
                sx={{
                  "& .MuiTypography-root": {
                    fontSize: 13,
                    color: "#c8d8ff",
                  },
                }}
              />
              <FormControlLabel
                value="variants"
                control={<Radio size="small" />}
                label="Variants"
                sx={{
                  "& .MuiTypography-root": {
                    fontSize: 13,
                    color: "#c8d8ff",
                  },
                }}
              />
            </RadioGroup>
          </FormControl>
          <hr className="hr-grey-6 my-0" />
          <p className="text-lightBlue mb-2">Select</p>
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={option}
              onChange={handleExportChange}>
              {currentSelection.length > 0 && (
                <FormControlLabel
                  value="selected"
                  control={<Radio size="small" />}
                  label="Current Selection"
                  sx={{
                    "& .MuiTypography-root": {
                      fontSize: 13,
                      color: "#c8d8ff",
                    },
                  }}
                />
              )}
              <FormControlLabel
                value="current"
                control={<Radio size="small" />}
                label="Current Page"
                sx={{
                  "& .MuiTypography-root": {
                    fontSize: 13,
                    color: "#c8d8ff",
                  },
                }}
              />
              <FormControlLabel
                value="all"
                control={<Radio size="small" />}
                label={`All ${type}`}
                sx={{
                  "& .MuiTypography-root": {
                    fontSize: 13,
                    color: "#c8d8ff",
                  },
                }}
              />
            </RadioGroup>
          </FormControl>
          <hr className="hr-grey-6 my-0" />
          <p className="text-lightBlue mb-2">File Type</p>
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={fileType}
              onChange={handleFileTypeChange}>
              <FormControlLabel
                value="csv"
                control={<Radio size="small" />}
                label="CSV File"
                sx={{
                  "& .MuiTypography-root": {
                    fontSize: 13,
                    color: "#c8d8ff",
                  },
                }}
              />
              <FormControlLabel
                value="xlsx"
                control={<Radio size="small" />}
                label="Excel File"
                sx={{
                  "& .MuiTypography-root": {
                    fontSize: 13,
                    color: "#c8d8ff",
                  },
                }}
              />
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <hr className="hr-grey-6 my-0" />
        <DialogActions className="d-flex justify-content-between px-4 py-3">
          <button
            className="button-grey py-2 px-5"
            onClick={handleExportClose}
            disabled={isLoading}>
            <p className="text-lightBlue">Cancel</p>
          </button>
          <button
            className="button-gradient py-2 px-5"
            onClick={handleExportSubmit}
            disabled={isLoading || !fileType || !type || !option}>
            <p className="">Export</p>
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
}

const useDownLoadExcel = (queryFilters, currentSelection) => {
  const dispatch = useDispatch();

  const [getProductData, { isLoading: productsIsLoading }] = useGetProductsCsvMutation();
  const [getVariantData, { isLoading: variantsIsLoading }] = useGetVariantsCsvMutation();

  const functions = useMemo(
    () => ({
      products: getProductData,
      variants: getVariantData,
    }),
    [getProductData, getVariantData]
  );

  const downloadExcel = useCallback(
    async (type, fileType, option, cb) => {
      // "all", "current", "selected"
      try {
        let queries = { option, fileType };
        if (option === "current") queries = { ...queries, ...queryFilters };
        if (option === "selected")
          queries = { ...queries, ids: currentSelection.map((cs) => cs?._id ?? cs) };
        const data = await functions[type](queries).unwrap();
        const link = data?.data;
        if (!link) throw new Error(`Download Link Missing`);
        const res = await fetch(link);
        if (!res.ok)
          throw new Error(`Download Failed status:${res.status} message:${res.statusText}`);
        const blob = await res.blob();
        !!cb && cb();
        const objectUrl = URL.createObjectURL(blob);
        const ank = document.createElement("a");
        ank.setAttribute("href", objectUrl);
        ank.setAttribute("download", `${type}.${fileType}`);
        document.body.appendChild(ank);
        ank.click();
        document.body.removeChild(ank);
        URL.revokeObjectURL(objectUrl);
        dispatch(showSuccess({ message: "Sheet Downloaded successfully" }));
      } catch (error) {
        console.log(error);
        dispatch(showError({ message: "Unable to download sheet" }));
      }
    },
    [queryFilters, currentSelection, functions, dispatch]
  );

  return {
    downloadExcel,
    isLoading: productsIsLoading || variantsIsLoading,
  };
};
