import { useState } from "react";
// ! IMAGES IMPORTS
import arrowDown from "../../assets/icons/arrowDown.svg";
// ! MATERIAL IMPORTS
import { Autocomplete, Popover, TextField } from "@mui/material";
// ! MATERIAL ICONS IMPORTS
import { useGetAllTagsQuery } from "../../features/parameters/tagsManager/tagsManagerApiSlice";

const OrderTagsFilter = ({ value = "", onChange = () => {} }) => {
  const { data: tagsData } = useGetAllTagsQuery({ pageNo: 1, pageSize: 1e9 });
  const allTags = tagsData?.data?.data ?? [];

  const onTagChange = (e, val) => onChange(val?.name ?? "");

  // * TAGGED WITH POPOVERS STARTS
  const [anchorTaggedWithEl, setAnchorTaggedWithEl] = useState(null);

  const handleTaggedWithClick = (event) => {
    setAnchorTaggedWithEl(event.currentTarget);
  };

  const handleTaggedWithClose = () => {
    setAnchorTaggedWithEl(null);
  };

  const openTaggedWith = Boolean(anchorTaggedWithEl);
  // * TAGGED WITH POPOVERS ENDS

  return (
    <>
      <button
        onClick={handleTaggedWithClick}
        className="button-grey py-2 px-3 d-none d-md-block">
        <small className="text-lightBlue">Tagged With</small>
        <img
          src={arrowDown}
          alt="arrowDown"
          className="ms-2"
        />
      </button>
      <Popover
        open={openTaggedWith}
        anchorEl={anchorTaggedWithEl}
        onClose={handleTaggedWithClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}>
        <div className="py-2">
          <Autocomplete
            freeSolo
            size="small"
            options={allTags}
            getOptionLabel={(option) => option.name ?? option}
            onChange={onTagChange}
            value={value}
            sx={{ width: 200 }}
            renderOption={(props, option) => (
              <li {...props}>
                <small className="text-lightBlue my-1">{option.name}</small>
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Search"
                inputRef={(input) => input?.focus()}
              />
            )}
          />
        </div>
      </Popover>
    </>
  );
};

export default OrderTagsFilter;
