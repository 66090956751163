import React, { PureComponent } from 'react';
import { AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import Heading from "../../components/Headers/DashboardHeader";
export default class ActiveUserChart extends PureComponent {

  constructor(props) {
    super(props);
  }

  render() {
    // Use the data prop or default to dummy data
    const { data = [] } = this.props;

    return (
      <div className="bg-button-3 border-grey-5 rounded-8 px-4 pt-4 pb-3 mt-4" style={{ height: '500px', width: "760px" }}>
        <p
          className="text-lightBlue fw-500 me-2 my-1"
          style={{ fontSize: '12px', textTransform: 'uppercase' }}
        >
          Trends • Last 14 Weeks
        </p>

        <Heading
          heading={"Weekly Active Users (WAU)"}
          tooltipContent={"Shows the number of unique users that use your app every week."}
        />
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart
            data={data}
            margin={{
              top: 30,
              right: 20,
              left: 20,
              bottom: 60,
            }}
          >
            <defs>
              <linearGradient id="colorviews" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
              </linearGradient>
            </defs>
            <XAxis dataKey="name" tick={{ fontSize: 12, fill: "#ffffff" }} />
            <YAxis tick={{ fontSize: 12, fill: "#ffffff" }} />
            <Tooltip contentStyle={{
              backgroundColor: '#18132E',
              border: 'grey-500',
              fontSize: '12px',
              borderRadius: "4px",
            }} />
            <Area type="monotone" dataKey="views" stroke="#8884d8" fill="url(#colorviews)" />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    );
  }
}