import apiSlice from "../../../app/api/apiSlice";

import { omitNullishKeys } from "../../../utils/helper";

export const productTabsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllProductTabs: builder.query({
      query: (queries) => {
        return {
          url: "/parameters/productTab",
          params: omitNullishKeys(queries),
        };
      },
      transformResponse: (res) => res.data,
      providesTags: ["ProductTabs"],
    }),
    createProductTab: builder.mutation({
      query: (productTabDetails) => ({
        url: "/parameters/productTab",
        method: "POST",
        body: productTabDetails,
      }),
      invalidatesTags: ["ProductTabs", "Logs"],
    }),
    deleteProductTab: builder.mutation({
      query: (productTabId) => ({
        url: `/parameters/productTab/hardDelete/${productTabId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ProductTabs", "Logs"],
    }),
    editProductTab: builder.mutation({
      query: ({ id, details }) => ({
        url: `/parameters/productTab/${id}`,
        method: "PUT",
        body: details,
      }),
      invalidatesTags: ["ProductTabs", "Logs"],
    }),
  }),
});

export const {
  useGetAllProductTabsQuery,
  useCreateProductTabMutation,
  useDeleteProductTabMutation,
  useEditProductTabMutation,
} = productTabsApiSlice;
