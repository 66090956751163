const colorReg = /^#([0-9a-f]{3}){1,2}$/i;
const urlReg =
  /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
const objectIdReg = /^[0-9a-fA-F]{24}$/;
const stringNum = /^\d*(\.\d+)?$/;
const socialHandleUrlReg = (domain) => {
  return new RegExp(`^(?=(https://|https://www\\.|www\\.)${domain}\\.com/[^/]+).*$`);
};

export { colorReg, urlReg, objectIdReg, stringNum,socialHandleUrlReg };
