import { useEffect, useMemo, useState, useReducer, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams, useSearchParams, createSearchParams } from "react-router-dom";
import {
  Autocomplete,
  Avatar,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  InputBase,
  MenuItem,
  OutlinedInput,
  Popover,
  Radio,
  RadioGroup,
  Select,
  Slide,
  styled,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  FormHelperText,
  TextareaAutosize,
  Chip,
  Button,
} from "@mui/material";
import AddRounded from "@mui/icons-material/AddRounded";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import CropIcon from "@mui/icons-material/Crop";
import { useDropzone } from "react-dropzone";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import SearchIcon from "@mui/icons-material/Search";
import * as Yup from "yup";
import _ from "lodash";
import { useFormik } from "formik";

import AppTextEditor from "../../../../components/AppTextEditor/AppTextEditor";
import { StatusBoxSecondary } from "../../../../components/StatusBox/StatusBox";
import { DiscardModalSecondary } from "../../../../components/Discard/DiscardModal";
import { SaveFooterTertiary } from "../../../../components/SaveFooter/SaveFooter";
import { DeleteModalSecondary } from "../../../../components/DeleteModal/DeleteModal";
import InfoHeader from "../../../../components/Header/InfoHeader";
import PageLoader from "../../../../components/Loader/PageLoader";
import Label from "../../../../components/Label/Label";
import OptionChip from "../../../../components/Options/OptionChip/OptionChip";
import {
  UploadMediaLarge,
  UploadMultipleMediaLarge,
  UploadMediaSmall,
} from "../../../../components/UploadMediaBox/UploadMedia";

import info from "../../../../assets/icons/info.svg";
import clock from "../../../../assets/icons/clock.svg";
import cancel from "../../../../assets/icons/cancel.svg";
import arrowDown from "../../../../assets/icons/arrowDown.svg";
import productInfoMedia1 from "../../../../assets/images/products/productInfoMedia1.svg";
import productInfoMedia2 from "../../../../assets/images/products/productInfoMedia2.svg";
import image from "../../../../assets/images/users/userLarge.svg";
import image2 from "../../../../assets/images/unthreadLogo.png";
import product2 from "../../../../assets/images/products/product2.jpg";
import upload from "../../../../assets/icons/upload.svg";
import image3 from "../../../../assets/icons/image-03.svg";
import eye from "../../../../assets/icons/eyeClosed.svg";
import deleteMedia from "../../../../assets/icons/deleteMedia.svg";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import { showSuccess, showError } from "../../../../features/snackbar/snackbarAction";
import SnackbarUtils from "../../../../features/snackbar/useSnackbar";
import {
  useGetAllProductsQuery,
  useCreateProductMutation,
  useUpdateProductMutation,
  useCreateDynamicTabsMutation,
  useGetDynamicTabsQuery,
  useUpdateDynamicTabsMutation,
  useGetShippingQuery,
  useCreateShippingMutation,
  useUpdateShippingMutation,
} from "../../../../features/products/product/productApiSlice";
import { useGetAllVendorsQuery } from "../../../../features/parameters/vendors/vendorsApiSlice";
import {
  useGetAllCategoriesQuery,
  useGetAllSubCategoriesQuery,
} from "../../../../features/parameters/categories/categoriesApiSlice";
import { useGetAllTagsQuery } from "../../../../features/parameters/tagsManager/tagsManagerApiSlice";
import { useGetAllCollectionsQuery } from "../../../../features/parameters/collections/collectionsApiSlice";
import { useGetAllDiscountsQuery } from "../../../../features/offers/discounts/discountsApiSlice";
import { useGetAllProductTabsQuery } from "../../../../features/parameters/productTabs/productTabsApiSlice";
import { useGetAllStoresQuery } from "../../../../features/products/inventory/inventoryApiSlice";
import { convert } from "html-to-text";

import { useFormikToastError } from "../../../../hooks/error";

import { objectIdReg } from "../../../../utils/regex";
import { omitEmptyKeys } from "../../../../utils/helper";

import "./ProductInfo.scss";
import AppGenericSelect from "../../../../components/AppGenericSelect/AppGenericSelect";
import { useGetAllCountryQuery } from "../../../../features/master/country/countryApiSlice";
import {
  useCreateSeoMutation,
  useEditSeoMutation,
  useGetAllSeoQuery,
} from "../../../../features/seo/seoApiSlice";

const productValidationSchema = Yup.object().shape({
  title: Yup.string().trim().min(3, "Too short").required("Required"),
  description: Yup.string().trim(),
  status: Yup.string()
    .oneOf(["active", "in-active", "scheduled", "archived"])
    .required("Required"),
  mediaUrl: Yup.array().of(
    Yup.object({
      isDefault: Yup.boolean().required("Required"),
      image: Yup.string().trim().required("Required"),
    })
  ),
  startDate: Yup.date(),
  endDate: Yup.date(),
  productType: Yup.object({
    categoryId: Yup.string().trim().matches(objectIdReg, "Not valid").required("Required"),
    vendorId: Yup.string().trim().matches(objectIdReg, "Not valid"),
    tagManagerId: Yup.array().of(Yup.string().trim().matches(objectIdReg, "Not valid")),
    collectionId: Yup.array().of(Yup.string().trim().matches(objectIdReg, "Not valid")),
    subCategoryId: Yup.string().trim().matches(objectIdReg, "Not valid"),
  }),
  price: Yup.object({
    dynamicPricing: Yup.boolean().required("Required"),
    breakup: Yup.boolean().required("Required"),
    price: Yup.number().when(["dynamicPricing"], ([dynamicPricing], schema) => {
      if (!dynamicPricing) {
        return schema.required("Required");
      }
      return schema;
    }),
    discount: Yup.number().when(["dynamicPricing"], ([dynamicPricing], schema) => {
      if (!dynamicPricing) {
        return schema.required("Required");
      }
      return schema;
    }),
    type: Yup.string()
      .oneOf(["fixed", "percentage"])
      .when(["dynamicPricing"], ([dynamicPricing], schema) => {
        if (!dynamicPricing) {
          return schema.required("Required");
        }
        return schema;
      }),
    salePrice: Yup.number()
      // .test("is-greater", "Should not be greater than price", function (salePrice) {
      //   if (salePrice === "" || salePrice === null || salePrice === undefined) {
      //     return true;
      //   }
      //   if (
      //     this.from[0]?.value?.price === "" ||
      //     this.from[0]?.value?.price === null ||
      //     this.from[0]?.value?.price === undefined
      //   ) {
      //     return true;
      //   }
      //   return salePrice > this.from[0]?.value?.price
      //     ? this.createError({
      //         path: `${this.path}`,
      //         message: `Should not be greater than ${this.from[0]?.value?.price}`,
      //       })
      //     : true;
      // })
      .when(["dynamicPricing"], ([dynamicPricing], schema) => {
        if (!dynamicPricing) {
          return schema.required("Required");
        }
        return schema;
      }),
  }),
  inventory: Yup.object({
    skuId: Yup.string().trim().required("Required"),
    barCode: Yup.string().trim(),
    inventoryOn: Yup.string()
      .oneOf(["product", "variant"])
      .when(["isTrackInventory"], ([isTrackInventory], schema) => {
        if (isTrackInventory) {
          return schema.required("Required");
        }
        return schema;
      }),
    store: Yup.array().of(
      Yup.object({
        store: Yup.string().trim().matches(objectIdReg, "Not valid"),
        quantityCount: Yup.number()
          .integer()
          .test("min", `Not valid`, function (value) {
            return +value >= 0;
          })
          .required("Required"),
      })
    ),
    isNegativeSellAllowed: Yup.boolean(),
    isTrackInventory: Yup.boolean().required(),
  }),
  // availableFor: Yup.object({
  //   isReturnable: Yup.boolean().required("Required"),
  //   isCod: Yup.boolean().required("Required"),
  //   isLifeTimeExchange: Yup.boolean().required("Required"),
  //   isLifeTimeBuyBack: Yup.boolean().required("Required"),
  //   isNextDayShipping: Yup.boolean().required("Required"),
  //   isTryOn: Yup.boolean().required("Required"),
  //   isViewSimilarItem: Yup.boolean().required("Required"),
  //   returnValue: Yup.number().when(["isReturnable"], ([isReturnable], schema) => {
  //     if (isReturnable) {
  //       return schema.required("Required");
  //     }
  //     return schema;
  //   }),
  //   returnType: Yup.string()
  //     .trim()
  //     .oneOf(["days", "months", "years"])
  //     .when(["isReturnable"], ([isReturnable], schema) => {
  //       if (isReturnable) {
  //         return schema.required("Required");
  //       }
  //       return schema;
  //     }),
  // }),
  shipping: Yup.object().shape({
    productId: Yup.string().trim(),
    type: Yup.string().trim().oneOf(["digital", "physical"]),
    weight: Yup.number(),
    length: Yup.number(),
    height: Yup.number(),
    width: Yup.number(),
    isAbroadAvailable: Yup.boolean(),
    country: Yup.string().trim(),
    // .when(["isAbroadAvailable"], ([isAbroadAvailable], schema) => {
    //   if (isAbroadAvailable) {
    //     return schema.required("Required");
    //   }
    //   return schema;
    // }),
    hsCode: Yup.string().trim(),
    // .when(["isAbroadAvailable"], ([isAbroadAvailable], schema) => {
    //   if (isAbroadAvailable) {
    //     return schema.required("Required");
    //   }
    //   return schema;
    // }),
  }),
  seo: Yup.object().shape({
    title: Yup.string().max(50, "Title cannot exceed 50 characters"),
    description: Yup.string().max(500, "Description cannot exceed 500 characters"),
  }),
});

const dynamicTabValidationSchema = Yup.object().shape({
  dynamicTabs: Yup.object({}),
});

const initialProductQueryFilterState = {
  srNo: null,
  order: null,
  pageSize: 1,
  pageNo: 0,
};

const initialProductState = {
  totalCount: 0,
  nextCount: 0,
  prevCount: 0,
  isEditing: false,
  deleteId: null,
  confirmationMessage: "",
  deleteType: "",
  deleteTitle: "",
  showDeleteModal: false,
  isLoading: false,
  createdSuccess: false,
  showDiscountPopover: false,
  discountPopoverAnchor: null,
};

const productQueryFilterReducer = (state, action) => {
  if (action.type === "SET_SR_NO") {
    return {
      ...state,
      srNo: action.srNo,
      order: null,
    };
  }
  if (action.type === "SET_NEXT_ORDER") {
    return {
      ...state,
      order: 1,
    };
  }
  if (action.type === "SET_PREV_ORDER") {
    return {
      ...state,
      order: -1,
    };
  }
  return initialProductQueryFilterState;
};

const productReducer = (state, action) => {
  if (action.type === "SET_DELETE") {
    return {
      ...state,
      deleteId: action.deleteId,
      confirmationMessage: action.message || "",
      deleteType: action.deleteType || "",
      deleteTitle: action.deleteTitle || "",
      showDeleteModal: true,
    };
  }
  if (action.type === "REMOVE_DELETE") {
    return {
      ...state,
      deleteId: initialProductState.deleteId,
      confirmationMessage: initialProductState.confirmationMessage,
      deleteType: initialProductState.deleteType,
      deleteTitle: initialProductState.deleteTitle,
      showDeleteModal: initialProductState.showDeleteModal,
    };
  }
  if (action.type === "SET_PAGINATION") {
    return {
      ...state,
      totalCount: action.totalCount,
      nextCount: action.nextCount,
      prevCount: action.prevCount,
    };
  }
  if (action.type === "ENABLE_EDIT") {
    return {
      ...state,
      isEditing: true,
    };
  }
  if (action.type === "DISABLE_EDIT") {
    return {
      ...state,
      isEditing: false,
    };
  }
  if (action.type === "ENABLE_LOADING") {
    return {
      ...state,
      isLoading: true,
    };
  }
  if (action.type === "DISABLE_LOADING") {
    return {
      ...state,
      isLoading: false,
    };
  }
  if (action.type === "ENABLE_SUCCESS") {
    return {
      ...state,
      createdSuccess: true,
    };
  }
  if (action.type === "DISABLE_SUCCESS") {
    return {
      ...state,
      createdSuccess: false,
    };
  }
  if (action.type === "SHOW_DISCOUNT_POPOVER") {
    return {
      ...state,
      showDiscountPopover: true,
      discountPopoverAnchor: action.anchorEl,
    };
  }
  if (action.type === "HIDE_DISCOUNT_POPOVER") {
    return {
      ...state,
      showDiscountPopover: false,
      discountPopoverAnchor: null,
    };
  }
  return initialProductState;
};

const ProductInfo = (props) => {
  const {
    isCompleted,
    onProductInfo,
    onNext,
    step,
    enableFirstRender,
    onDisableEdit,
    onEnableEdit,
    isCreateMode,
  } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams("");
  let { id } = useParams();
  const [productQueryFilterState, dispatchProductQueryFilter] = useReducer(
    productQueryFilterReducer,
    initialProductQueryFilterState
  );
  const [productState, dispatchProduct] = useReducer(productReducer, initialProductState);

  const [viewAll, setViewAll] = useState(false);
  const [productType, setProductType] = useState("physical");
  const [isFieldTouched, setIsFieldTouched] = useState({
    collectionId: false,
    categoryId: false,
    vendorId: false,
    tagManagerId: false,
    subCategoryId: false,
    priceDiscount: false,
  });

  const [mediaList, setMediaList] = useState({
    default: {},
    other: [],
  });
  const [isInitialMediaSet, setIsInitialMediaSet] = useState(!id);
  const [responseData, setResponseData] = useState(null);
  const [activeDynamicTab, setActiveDynamicTab] = useState(null);
  const [adjustInventoryCount, setAdjustInventoryCount] = useState({
    qty: "",
    action: "",
  });

  const { data: storeData } = useGetAllStoresQuery({ createdAt: 1 });

  const {
    data: productsData,
    isLoading: productsIsLoading,
    error: productsError,
    isError: productsIsError,
    isSuccess: productsIsSuccess,
  } = useGetAllProductsQuery(
    { queries: { id } },
    {
      skip: id ? false : true,
    }
  );
  const [createProduct] = useCreateProductMutation();
  const [updateProduct] = useUpdateProductMutation();

  const { data: shippingData, isSuccess: shippingIsSuccess } = useGetShippingQuery(
    { productId: id },
    {
      skip: id ? false : true,
    }
  );
  const [createShipping] = useCreateShippingMutation();
  const [updateShipping] = useUpdateShippingMutation();

  const { data: seoData } = useGetAllSeoQuery(
    { refrenceId: id },
    {
      skip: id ? false : true,
    }
  );
  const [createSeo, { isLoading: createSeoIsLoading }] = useCreateSeoMutation();
  const [editSeo, { isLoading: editSeoIsLoading }] = useEditSeoMutation();

  const {
    data: dynamicTabsData,
    isLoading: dynamicTabsIsLoading,
    error: dynamicTabsError,
    isError: dynamicTabsIsError,
    isSuccess: dynamicTabsIsSuccess,
    isFetching: dynamicTabsIsFetching,
  } = useGetDynamicTabsQuery(
    { productId: productsData?.data[0]?._id },
    {
      skip: productsData?.data[0]?._id ? false : true,
    }
  );

  const [
    createDynamicTabs,
    {
      isLoading: createDynamicTabsIsLoading,
      isSuccess: createDynamicTabsIsSuccess,
      error: createDynamicTabsError,
      isError: createDynamicTabsIsError,
    },
  ] = useCreateDynamicTabsMutation();
  const [
    updateDynamicTabs,
    {
      isLoading: updateDynamicTabsIsLoading,
      isSuccess: updateDynamicTabsIsSuccess,
      error: updateDynamicTabsError,
      isError: updateDynamicTabsIsError,
    },
  ] = useUpdateDynamicTabsMutation();

  const {
    data: productsTabData,
    isLoading: productsTabIsLoading,
    error: productsTabError,
    isError: productsTabIsError,
    isSuccess: productsTabIsSuccess,
    isFetching: productsTabIsFetching,
  } = useGetAllProductTabsQuery();

  const productFormik = useFormik({
    initialValues: {
      title: productsData?.data[0]?.title || "",
      description: productsData?.data[0]?.description || "",
      status: productsData?.data[0]?.status || "active",
      mediaUrl: productsData?.data[0]?.mediaUrl || [],
      startDate: productsData?.data[0]?.startDate || "",
      endDate: productsData?.data[0]?.endDate || "",
      productType: {
        categoryId: productsData?.data[0]?.productType.categoryId || "",
        vendorId: productsData?.data[0]?.productType.vendorId || "",
        tagManagerId: productsData?.data[0]?.productType.tagManagerId || [],
        collectionId: productsData?.data[0]?.productType.collectionId || [],
        subCategoryId: productsData?.data[0]?.productType.subCategoryId || "",
      },
      price: {
        dynamicPricing: productsData?.data[0]?.price.dynamicPricing || false,
        breakup: productsData?.data[0]?.price.breakup || false,
        price: productsData?.data[0]?.price?.price?.toString() || "",
        discount: productsData?.data[0]?.price?.discount?.toString() || "",
        type: productsData?.data[0]?.price.type || "percentage",
        salePrice: productsData?.data[0]?.price?.salePrice?.toString() || "",
      },
      inventory: {
        skuId: productsData?.data[0]?.inventory.skuId || "",
        barCode: productsData?.data[0]?.inventory.barCode || "",
        inventoryOn: productsData?.data[0]?.inventory.inventoryOn || "",
        store: productsData?.data[0]?.inventories?.[0]?.store?.length
          ? productsData?.data[0]?.inventories?.[0]?.store?.map((store) => {
              return {
                store: store.store,
                quantityCount: store?.quantityCount || 0,
                id: store._id,
              };
            })
          : !!storeData?.data?.data?.length
          ? storeData?.data?.data.map((store) => {
              return {
                store: store._id,
                quantityCount: 0,
              };
            })
          : [],
        isNegativeSellAllowed:
          productsData?.data[0]?.inventory?.store?.isNegativeSellAllowed || false,
        isTrackInventory: productsData?.data[0]?.inventory.isTrackInventory || false,
      },
      // availableFor: {
      //   isReturnable: productsData?.data[0]?.availableFor?.isReturnable || false,
      //   isCod: productsData?.data[0]?.availableFor?.isCod || false,
      //   isLifeTimeExchange: productsData?.data[0]?.availableFor?.isLifeTimeExchange || false,
      //   isLifeTimeBuyBack: productsData?.data[0]?.availableFor?.isLifeTimeBuyBack || false,
      //   isNextDayShipping: productsData?.data[0]?.availableFor?.isNextDayShipping || false,
      //   isTryOn: productsData?.data[0]?.availableFor?.isTryOn || false,
      //   isViewSimilarItem: productsData?.data[0]?.availableFor?.isViewSimilarItem || false,
      //   returnValue: productsData?.data[0]?.availableFor?.returnValue || "",
      //   returnType: productsData?.data[0]?.availableFor?.returnType || "days",
      // },
      shipping: {
        productId: id || "",
        type: shippingData?.data?.[0]?.type || "physical",
        weight: shippingData?.data?.[0]?.weight || "",
        length: shippingData?.data?.[0]?.length || "",
        height: shippingData?.data?.[0]?.height || "",
        width: shippingData?.data?.[0]?.width || "",
        isAbroadAvailable: shippingData?.data?.[0]?.isAbroadAvailable || false,
        country: shippingData?.data?.[0]?.country || "",
        hsCode: shippingData?.data?.[0]?.hsCode || "",
      },
      seo: {
        title: seoData?.data?.data?.[0]?.title || productsData?.data[0]?.title || "",
        description: convert(
          seoData?.data?.data?.[0]?.description || productsData?.data[0]?.description || ""
        ),
        multipleMetaTags: seoData?.data?.data?.[0]?.metaKeywords || [],
        metaKeywords: "",
        refrenceId: id || "",
      },
    },
    enableReinitialize: true,
    validationSchema: productValidationSchema,
    onSubmit: (values) => {
      if (!mediaList?.default?.image) {
        return;
      }
      SnackbarUtils.savingToast();
      let clonedProduct = structuredClone(values);
      const shippingFormData = omitEmptyKeys(clonedProduct.shipping);
      const seoFormData = omitEmptyKeys({
        ...clonedProduct.seo,
        metaKeywords: clonedProduct.seo.multipleMetaTags,
      });
      clonedProduct.shipping = "";
      clonedProduct.seo = "";
      const clonedDynamicProductTabs = structuredClone(dynamicTabFormik.values);
      clonedProduct = omitEmptyKeys(clonedProduct);
      clonedProduct.productType = omitEmptyKeys(clonedProduct.productType);
      clonedProduct.price = omitEmptyKeys(clonedProduct.price);
      clonedProduct.inventory = omitEmptyKeys(clonedProduct.inventory);
      // clonedProduct.availableFor = omitEmptyKeys(clonedProduct.availableFor);
      if (!clonedProduct?.price?.dynamicPricing) {
        clonedProduct.price = {
          ...clonedProduct.price,
          discount: +clonedProduct?.price?.discount,
          price: +clonedProduct?.price?.price,
          salePrice: +clonedProduct?.price?.salePrice,
        };
      }
      if (id) {
        if (clonedProduct.title === productFormik.initialValues.title) {
          delete clonedProduct.title;
        }

        updateProduct({
          id: productsData?.data[0]._id,
          details: {
            ...clonedProduct,
            isCompleted,
            step:
              !isCompleted || productsData?.data?.[0]?.step <= step
                ? step.toString()
                : undefined,
          },
        })
          .unwrap()
          .then(() =>
            updateShipping({
              id: shippingData?.data?.[0]?._id,
              details: { ...shippingFormData },
            }).unwrap()
          )
          .then(() =>
            editSeo({
              id,
              details: { ...seoFormData },
            }).unwrap()
          )
          .then(() => {
            if (clonedDynamicProductTabs?.dynamicTabs?.length) {
              updateDynamicTabs(clonedDynamicProductTabs.dynamicTabs)
                .unwrap()
                .then(() => {
                  SnackbarUtils.hideToast();
                  dispatch(showSuccess({ message: "product edited successfully" }));
                  onNext(false);
                })
                .catch((error) => {
                  SnackbarUtils.hideToast();

                  if (error?.data?.message) {
                    dispatch(showError({ message: error.data.message }));
                  } else {
                    dispatch(
                      showError({
                        message: "Something went wrong!, please try again",
                      })
                    );
                  }
                });
            } else {
              SnackbarUtils.hideToast();
              dispatch(showSuccess({ message: "product edited successfully" }));
              onNext(false);
            }
          })
          .catch((error) => {
            SnackbarUtils.hideToast();

            if (error?.data?.message) {
              dispatch(showError({ message: error.data.message }));
            } else {
              dispatch(
                showError({
                  message: "Something went wrong!, please try again",
                })
              );
            }
          });
      } else {
        createProduct(clonedProduct)
          .unwrap()
          .then((data) => {
            setResponseData(data);
            return data;
          })
          .then((data) => {
            const promisses = [
              createShipping({
                ...shippingFormData,
                productId: data?._id,
              }).unwrap(),
              createSeo({
                ...seoFormData,
                refrenceId: data?._id,
                module: "products",
              }).unwrap(),
            ];

            if (clonedDynamicProductTabs?.dynamicTabs?.length) {
              clonedDynamicProductTabs?.dynamicTabs.forEach(
                (tab) => (tab.productId = data._id)
              );

              promisses.push(createDynamicTabs(clonedDynamicProductTabs.dynamicTabs).unwrap());

              Promise.all(promisses)
                .then(() => {
                  SnackbarUtils.hideToast();
                  dispatch(
                    showSuccess({
                      message: "product created successfully",
                    })
                  );
                  dispatchProduct({ type: "ENABLE_SUCCESS" });
                  // onNext(false);
                })
                .catch((error) => {
                  SnackbarUtils.hideToast();
                  if (error?.data?.message) {
                    dispatch(showError({ message: error.data.message }));
                  } else {
                    dispatch(
                      showError({
                        message: "Something went wrong!, please try again",
                      })
                    );
                  }
                });
            } else {
              SnackbarUtils.hideToast();
              dispatch(
                showSuccess({
                  message: "product created successfully",
                })
              );
              dispatchProduct({ type: "ENABLE_SUCCESS" });
              // onNext(false);
            }
          })
          .catch((error) => {
            SnackbarUtils.hideToast();
            if (error?.data?.message) {
              dispatch(showError({ message: error.data.message }));
            } else {
              dispatch(
                showError({
                  message: "Something went wrong!, please try again",
                })
              );
            }
          });
      }
    },
  });

  const dynamicTabFormik = useFormik({
    initialValues: {
      dynamicTabs:
        id && dynamicTabsData?.length
          ? dynamicTabsData
          : productsTabData?.data?.map((tab) => {
              return {
                data: [tab],
                tabId: tab._id,
                productId: productsData?.data?.[0]?._id || "",
              };
            }) || {},
    },
    enableReinitialize: true,
    validationSchema: dynamicTabValidationSchema,
  });
  useFormikToastError(productFormik);

  const {
    data: categoriesData,
    isLoading: categoriesIsLoading,
    error: categoriesError,
    isError: categoriesIsError,
    isSuccess: categoriesIsSuccess,
    isFetching: categoriesIsFetching,
  } = useGetAllCategoriesQuery({
    status: ["active", "in-active", "scheduled"],
    pageNo: 1,
    pageSize: 1e9,
  });
  const {
    data: subCategoriesData,
    isLoading: subCategoriesIsLoading,
    error: subCategoriesError,
    isError: subCategoriesIsError,
    isSuccess: subCategoriesIsSuccess,
    isFetching: subCategoriesIsFetching,
  } = useGetAllSubCategoriesQuery(
    {
      categoryId: productFormik.values.productType.categoryId,
      status: ["active", "in-active", "scheduled"],
      pageNo: 1,
      pageSize: 1e9,
    },
    {
      skip: productFormik.values.productType.categoryId ? false : true,
    }
  );
  const {
    data: vendorsData,
    isLoading: vendorsIsLoading,
    error: vendorsError,
    isError: vendorsIsError,
    isSuccess: vendorsIsSuccess,
    isFetching: vendorsIsFetching,
  } = useGetAllVendorsQuery({
    status: ["active", "in-active"],
    pageNo: 1,
    pageSize: 1e9,
  });
  const {
    data: collectionsData,
    isLoading: collectionsIsLoading,
    error: collectionsError,
    isError: collectionsIsError,
    isSuccess: collectionsIsSuccess,
    isFetching: collectionsIsFetching,
  } = useGetAllCollectionsQuery({
    type: "manual",
    status: ["active", "in-active", "scheduled"],
    pageNo: 1,
    pageSize: 1e9,
  });
  const {
    data: tagsData,
    isLoading: tagsIsLoading,
    error: tagsError,
    isError: tagsIsError,
    isSuccess: tagsIsSuccess,
    isFetching: tagsIsFetching,
  } = useGetAllTagsQuery({
    status: ["active"],
    type: "product",
    pageNo: 1,
    pageSize: 1e9,
  });
  const {
    data: discountData,
    isLoading: discountIsLoading,
    error: discountError,
    isError: discountIsError,
    isSuccess: discountIsSuccess,
    isFetching: discountIsFetching,
  } = useGetAllDiscountsQuery({});

  const backHandler = () => {
    navigate({
      pathname: "/products/allProducts",
      search: `?${createSearchParams({
        filter: searchParams.get("filter"),
      })}`,
    });
  };

  // const nextPageHandler = () => {
  //   dispatchProductQueryFilter({ type: "SET_NEXT_ORDER" });
  // };

  // const prevPageHandler = () => {
  //   dispatchProductQueryFilter({ type: "SET_PREV_ORDER" });
  // };

  // const deleteMediaHandler = ({ deleteId, message }) => {
  //   dispatchProduct({
  //     type: "SET_DELETE",
  //     deleteId,
  //     message,
  //     deleteType: "media",
  //     deleteTitle: "media",
  //   });
  // };

  // const CancelDeleteHandler = () => {
  //   dispatchProduct({ type: "REMOVE_DELETE" });
  // };

  // const deleteConfirmationHandler = () => {
  //   if (productState.deleteType === "media") {
  //     const updatedMedia = productFormik?.values?.mediaUrl.filter(
  //       (_, index) => index !== productState.deleteId
  //     );
  //     productFormik.setFieldValue("mediaUrl", updatedMedia);
  //   }
  //   dispatchProduct({ type: "REMOVE_DELETE" });
  // };

  const statusHandler = (status) => {
    productFormik.setFieldValue("status", status);
    productFormik.setFieldValue("startDate", "");
    productFormik.setFieldValue("endDate", "");
  };

  const scheduleProductHandler = ({ startDate, endDate }) => {
    productFormik.setFieldValue("startDate", startDate);
    productFormik.setFieldValue("endDate", endDate);
    productFormik.setFieldValue("status", "scheduled");
  };

  const productTitleHandler = (title) => {
    productFormik.setFieldValue("title", title);
    if (!id) productFormik.setFieldValue("seo.title", title);
  };

  const textEditorHandler = (des) => {
    if (des === "") {
      productFormik.setFieldValue("description", "<p></p>");
      if (!id) productFormik.setFieldValue("seo.description", "");
      return;
    }
    productFormik.setFieldValue("description", des);
    if (!id) productFormik.setFieldValue("seo.description", convert(des));
  };

  const addCollectionHandler = (_, colls) => {
    const collections = colls.map((coll) => coll._id);
    productFormik.setFieldValue("productType.collectionId", collections);
  };

  const addTagsHandler = (_, tags) => {
    const tagList = tags.map((tag) => tag._id);
    productFormik.setFieldValue("productType.tagManagerId", tagList);
  };

  const collectionBlurHandler = () => {
    setIsFieldTouched((prevState) => {
      return {
        ...prevState,
        collectionId: true,
      };
    });
  };
  const categoryBlurHandler = () => {
    setIsFieldTouched((prevState) => {
      return {
        ...prevState,
        categoryId: true,
      };
    });
  };
  // const discountBlurHandler = () => {
  //   setIsFieldTouched((prevState) => {
  //     return {
  //       ...prevState,
  //       priceDiscount: true,
  //     };
  //   });
  // };
  const vendorBlurHandler = () => {
    setIsFieldTouched((prevState) => {
      return {
        ...prevState,
        vendorId: true,
      };
    });
  };
  const tagManagerBlurHandler = () => {
    setIsFieldTouched((prevState) => {
      return {
        ...prevState,
        tagManagerId: true,
      };
    });
  };
  const subCategoryBlurHandler = () => {
    setIsFieldTouched((prevState) => {
      return {
        ...prevState,
        subCategoryId: true,
      };
    });
  };

  const defaultMediaHandler = useCallback((url) => {
    if (url) {
      setMediaList((prevState) => {
        return {
          default: {
            isDefault: true,
            image: url,
          },
          other: prevState.other,
        };
      });
      return;
    }
    setMediaList((prevState) => {
      return {
        default: {},
        other: prevState.other,
      };
    });
  }, []);

  const mediaHandler = useCallback((urls) => {
    const medias = urls.map((url) => {
      return {
        isDefault: false,
        image: url,
      };
    });
    setMediaList((prevState) => {
      return {
        default: prevState.default,
        other: [...prevState.other, ...medias],
      };
    });
  }, []);

  const removeMediaHandler = (index) => {
    const medias = mediaList.other.filter((media, i) => {
      return index !== i;
    });
    setMediaList((prevState) => {
      return {
        default: prevState.default,
        other: medias,
      };
    });
  };

  const toggleDiscountPopoverHandler = (e, show) => {
    dispatchProduct({
      type: show ? "SHOW_DISCOUNT_POPOVER" : "HIDE_DISCOUNT_POPOVER",
      anchorEl: e?.currentTarget,
    });
  };

  const handleAddMultiple = (event) => {
    if (event.key === "Enter" || event.type === "click") {
      event.preventDefault();
      productFormik.validateForm().then(() => {
        if (productFormik?.values?.seo?.metaKeywords.trim() !== "") {
          productFormik.setFieldTouched("seo.metaKeywords", true);
          const metaKeywordsExists = productFormik?.values?.seo?.multipleMetaTags?.some(
            (tag) =>
              tag.toLowerCase().trim() ===
              productFormik?.values?.seo?.metaKeywords.toLowerCase().trim()
          );
          if (!metaKeywordsExists) {
            productFormik?.setFieldValue("seo.multipleMetaTags", [
              ...productFormik?.values?.seo?.multipleMetaTags,
              productFormik?.values?.seo?.metaKeywords,
            ]);
            productFormik.setFieldValue("seo.metaKeywords", "");
          } else {
            dispatch(
              showError({
                message: `${productFormik?.values?.seo?.metaKeywords.trim()} already exists`,
              })
            );
          }
        }
      });
    }
  };

  const handleDelete = (data) => {
    const filterData = productFormik?.values?.seo?.multipleMetaTags?.filter((v) => v !== data);
    productFormik.setFieldValue("seo.multipleMetaTags", filterData);
  };

  // const returnableHandler = (e) => {
  //   productFormik.handleChange(e);
  //   productFormik.setFieldValue("availableFor.returnValue", "");
  // };

  const dynamicPricingHandler = (e) => {
    productFormik.handleChange(e);
    productFormik.setFieldValue("price.discount", "");
    productFormik.setFieldValue("price.type", "percentage");
    productFormik.setFieldValue("price.price", "");
    productFormik.setFieldValue("price.salePrice", "");
    productFormik.setFieldValue("price.breakup", false);
  };

  const discountTypeHandler = (value) => {
    productFormik.setFieldValue("price.type", value);

    dispatchProduct({
      type: "HIDE_DISCOUNT_POPOVER",
    });

    const price = productFormik.values.price.price;
    let discountPrice = productFormik.values.price.discount;

    if (!discountPrice) {
      return;
    }

    if (value === "percentage") {
      if (+discountPrice > 100) {
        discountPrice = 100;
      }
      if (price) {
        const discount = (+discountPrice / 100) * +price;
        const salePrice = +price - +discount;
        productFormik.setFieldValue("price.salePrice", salePrice.toString());
      }
      productFormik.setFieldValue("price.discount", discountPrice.toString());
      return;
    }
    if (value === "fixed") {
      if (price) {
        if (+discountPrice > +price) {
          discountPrice = +price;
        }

        const salePrice = +price - +discountPrice;
        productFormik.setFieldValue("price.salePrice", salePrice.toString());
      }
      productFormik.setFieldValue("price.discount", discountPrice.toString());
      return;
    }
  };

  const productPriceHandler = (e) => {
    if (e.target.value === "" || e.target.value === undefined || e.target.value === null) {
      productFormik.setFieldValue("price.salePrice", "");
      productFormik.setFieldValue("price.price", e.target.value.toString());
      return;
    }

    if (productFormik.values.price.discount) {
      let existingDiscount = +productFormik.values.price.discount;
      if (productFormik.values.price.type === "percentage") {
        const discount = (existingDiscount / 100) * +e.target.value;
        const salePrice = +e.target.value - discount;
        productFormik.setFieldValue("price.salePrice", salePrice.toString());
        productFormik.setFieldValue("price.price", e.target.value.toString());
        return;
      }
      if (productFormik.values.price.type === "fixed") {
        if (existingDiscount > +e.target.value) {
          existingDiscount = +e.target.value;
          productFormik.setFieldValue("price.discount", existingDiscount.toString());
        }

        const salePrice = +e.target.value - existingDiscount;

        productFormik.setFieldValue("price.salePrice", salePrice.toString());
        productFormik.setFieldValue("price.price", e.target.value.toString());
        return;
      }
    } else {
      productFormik.setFieldValue("price.price", e.target.value.toString());
      productFormik.setFieldValue("price.discount", "0");
      productFormik.setFieldValue("price.salePrice", e.target.value.toString());
    }
  };

  const discountHandler = (e) => {
    let discountPrice = +e.target.value;
    const price = productFormik.values.price.price;

    if (productFormik.values.price.type === "percentage") {
      if (discountPrice > 100) {
        discountPrice = 100;
      }
      productFormik.setFieldValue("price.discount", discountPrice.toString());
      if (price) {
        const discount = (discountPrice / 100) * +price;
        const salePrice = +price - discount;
        productFormik.setFieldValue("price.salePrice", salePrice.toString());
      }
      return;
    }
    if (productFormik.values.price.type === "fixed") {
      if (price) {
        if (discountPrice > +price) {
          discountPrice = +price;
        }
        productFormik.setFieldValue("price.discount", discountPrice.toString());
        const salePrice = +price - discountPrice;
        productFormik.setFieldValue("price.salePrice", salePrice.toString());
      } else {
        productFormik.setFieldValue("price.discount", discountPrice.toString());
      }
      return;
    }
  };

  const salePriceHandler = (e) => {
    let saleValue = +e.target.value;
    const price = productFormik.values.price.price;
    const discountType = productFormik.values.price.type;

    if (!price) {
      productFormik.setFieldValue("price.salePrice", saleValue.toString());
      return;
    }

    if (saleValue > +price) {
      saleValue = +price;
    }

    if (discountType === "percentage") {
      const discount = (saleValue / +price) * 100;
      productFormik.setFieldValue("price.discount", discount.toString());
    }
    if (discountType === "fixed") {
      const discount = +price - saleValue;
      productFormik.setFieldValue("price.discount", discount.toString());
    }
    productFormik.setFieldValue("price.salePrice", saleValue.toString());
  };

  const dynamicTabToggleHandler = (_, id) => {
    id && setActiveDynamicTab(id);
  };

  const dynamicTabCommonFieldMediaHandle = (url, index, fieldIndex) => {
    dynamicTabFormik.setFieldValue(
      `dynamicTabs[${index}].data[0].commonCustomFields[${fieldIndex}].value`,
      url
    );
  };

  const dynamicTabFieldMediaHandle = (url, index, fieldIndex) => {
    dynamicTabFormik.setFieldValue(
      `dynamicTabs[${index}].data[0].customFields[${fieldIndex}].value`,
      url
    );
  };

  const saveFormHandler = () => {
    onEnableEdit();
    productFormik.handleSubmit();
  };

  const nextFormHandler = () => {
    onDisableEdit();
    productFormik.handleSubmit();
  };

  useEffect(
    () => {
      if (isInitialMediaSet) {
        if (mediaList.default.image) {
          productFormik.setFieldValue("mediaUrl", [mediaList.default, ...mediaList.other]);
        } else {
          productFormik.setFieldValue("mediaUrl", mediaList.other);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mediaList, isInitialMediaSet]
  );

  useEffect(() => {
    if (id) {
      dispatchProductQueryFilter({ type: "SET_SR_NO", srNo: id });
    }
  }, [id]);

  useEffect(() => {
    if (productsIsError) {
      if (productsError?.data?.message) {
        dispatch(showError({ message: productsError.data.message }));
      } else {
        dispatch(showError({ message: "Something went wrong!, please try again" }));
      }
    }
    if (productsIsSuccess) {
      if (!!productsData?.data[0]?.mediaUrl?.length) {
        const isDefault = productsData?.data[0]?.mediaUrl.find((media) => media.isDefault);
        const otherMedia = productsData?.data[0]?.mediaUrl.filter((media) => !media.isDefault);
        setMediaList((prevState) => {
          return {
            default: isDefault || {},
            other: otherMedia,
          };
        });
      }
      setIsInitialMediaSet(true);

      dispatchProduct({
        type: "SET_PAGINATION",
        totalCount: productsData.totalCount,
        nextCount: productsData.nextCount,
        prevCount: productsData.prevCount,
      });
      // dispatchPriceMasterQueryFilter({
      //   type: "SET_SR_NO",
      //   srNo: mastersData.data[0].srNo,
      // });
    }
  }, [productsData, productsError, productsIsError, productsIsSuccess, dispatch]);

  useEffect(() => {
    if (productsTabIsSuccess) {
      const id = !!productsTabData?.data?.length && productsTabData?.data[0]?._id;
      id && setActiveDynamicTab(id);
    }
  }, [productsTabIsSuccess, productsTabData]);

  useEffect(() => {
    if (id && !_.isEqual(productFormik.values, productFormik.initialValues)) {
      dispatchProduct({ type: "ENABLE_EDIT" });
    } else if (id && _.isEqual(productFormik.values, productFormik.initialValues)) {
      dispatchProduct({ type: "DISABLE_EDIT" });
    }
  }, [productFormik.initialValues, productFormik.values, id]);

  useEffect(() => {
    if (productFormik.isSubmitting) {
      if (!mediaList?.default?.image && _.isEmpty(productFormik?.errors)) {
        dispatch(showError({ message: "Please upload primary media" }));
      }
      setIsFieldTouched((prevState) => {
        return _.mapValues(prevState, () => true);
      });
    }
  }, [productFormik.isSubmitting, mediaList.default, dispatch, productFormik?.errors]);

  useEffect(() => {
    if (productState.createdSuccess) {
      navigate({
        pathname: `/products/allProducts/editProduct/${responseData._id}`,
        search: `?${createSearchParams({
          filter: searchParams.get("filter"),
          activeStep: 1,
          create: true,
        })}`,
      });
      enableFirstRender();
    }
  }, [productState.createdSuccess, navigate, searchParams, responseData, enableFirstRender]);

  const categories = useMemo(() => categoriesData?.data?.data || [], [categoriesData]);
  const selectedCategory = categories.find((cat) => {
    return cat._id === productFormik.values.productType?.categoryId;
  });
  const subCategories = useMemo(() => subCategoriesData?.data?.data || [], [subCategoriesData]);
  const selectedSubCategory = subCategories.find((subCat) => {
    return subCat._id === productFormik.values.productType?.subCategoryId;
  });
  const vendors = useMemo(() => vendorsData?.data?.data || [], [vendorsData]);
  const selectedVendors = vendors.find(
    (ven) => ven._id === productFormik.values.productType.vendorId
  );
  const collections = collectionsData?.data?.data || [];
  const tags = tagsData?.data?.data || [];
  // const discounts = discountData?.data?.data || [];

  const selectedCollectionIds = productFormik.values.productType?.collectionId || [];

  const selectedCollection =
    selectedCollectionIds.length && collections?.length
      ? collections.filter((coll) => selectedCollectionIds.includes(coll._id))
      : [];

  const selectedTagIds = productFormik.values.productType?.tagManagerId || [];

  const selectedTags =
    selectedTagIds.length && tags?.length
      ? tags.filter((tag) => selectedTagIds.includes(tag._id))
      : [];

  const isPageLoading = (id && productsIsLoading) || (id && !productQueryFilterState.srNo);

  useEffect(() => {
    const data = structuredClone(productFormik.values);
    data.productType.category = {
      name: categories.find((cat) => cat._id === data.productType.categoryId)?.name || "",
    };
    data.productType.subCategory = {
      name:
        subCategories.find((subCat) => subCat._id === data.productType.subCategoryId)?.name ||
        "",
    };
    data.productType.vendor = {
      name: vendors.find((ven) => ven._id === data.productType.vendorId)?.name || "",
    };
    onProductInfo(data);
  }, [productFormik.values, onProductInfo, subCategories, categories, vendors]);

  useEffect(() => {
    if (shippingIsSuccess && shippingData?.data?.[0]?.type) {
      setProductType(shippingData?.data?.[0]?.type);
    }
  }, [shippingIsSuccess, shippingData]);

  return (
    <>
      {isPageLoading && <PageLoader />}
      {!isPageLoading && (
        <form
          noValidate
          onSubmit={productFormik.handleSubmit}>
          <div className="bg-black-15 border-grey-5 rounded-8 p-3 row productInfo">
            <div className="col-12 px-0">
              <div className="row">
                <div className="col-8">
                  <div className="d-flex mb-1">
                    <Label required={true}>Title</Label>
                    <Tooltip
                      title="Lorem ipsum"
                      placement="top">
                      <img
                        src={info}
                        alt="info"
                        className="c-pointer"
                        width={13.5}
                      />
                    </Tooltip>
                  </div>
                  <FormControl className="w-100 px-0">
                    <OutlinedInput
                      name="title"
                      placeholder="Enter Title"
                      size="small"
                      value={productFormik.values.title}
                      onChange={(e) => productTitleHandler(e.target.value)}
                      onBlur={productFormik.handleBlur}
                      autoFocus={!id && true}
                    />
                    {!!productFormik.touched.title && productFormik.errors.title && (
                      <FormHelperText error>{productFormik.errors.title}</FormHelperText>
                    )}
                  </FormControl>
                </div>
                <div className="col-4">
                  <StatusBoxSecondary
                    title="product status"
                    status={productFormik.values.status}
                    onStatus={statusHandler}
                    startDate={productFormik.values.startDate}
                    endDate={productFormik.values.endDate}
                    onSchedule={scheduleProductHandler}
                    containerStyles="status-container"
                    titleStyles="status-title"
                    actionStyles="status-action"
                    activeText="active"
                    inActiveText="draft"
                  />
                </div>
              </div>
            </div>
            <div className="col-12 px-0 mt-1">
              <div className="d-flex justify-content-between mb-2 align-items-center">
                <p className="text-lightBlue mb-1">Description</p>
              </div>
              <AppTextEditor
                value={productFormik.values.description}
                setFieldValue={textEditorHandler}
              />
            </div>

            <hr className="hr-grey-6 my-3" />
            <div className="col-12">
              <div className="row justify-content-between">
                <p className="text-lightBlue px-0 w-auto">Media</p>
                <small className="text-blue-2 w-auto px-0">{/* Link Variant Images */}</small>
              </div>
              <div className="row">
                <div className="col-3 ps-0 d-flex flex-column">
                  <UploadMediaLarge
                    fileSrc={mediaList.default?.image}
                    style={{ height: "20rem" }}
                    onUpload={defaultMediaHandler}
                    className="product-media"
                    onBlur={productFormik.handleBlur}
                    isMandatory={true}
                  />
                  <button
                    type="button"
                    className="primary-image py-2 w-100 mt-2">
                    <p className="me-2">Primary Image</p>
                    <span
                      style={{ color: "#F67476" }}
                      className="me-2">
                      *
                    </span>
                  </button>
                </div>
                <div className="col-9 pe-0">
                  <UploadMultipleMediaLarge
                    fileSrc={mediaList.other}
                    style={{ minHeight: "20rem" }}
                    onUpload={mediaHandler}
                    onRemove={removeMediaHandler}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="bg-black-15 border-grey-5 rounded-8 p-3 row productInfo mt-4">
            <div className="col-12">
              <div className="row mb-3">
                <h6 className="text-lightBlue col-12 px-0 fw-500">Product Type</h6>
              </div>
              <div className="row">
                <div
                  className={`${
                    productFormik.values.productType?.categoryId && !!subCategories?.length
                      ? "col-4"
                      : "col-6"
                  } ps-0 mt-2`}>
                  <div className="d-flex mb-1">
                    <Label required={true}>Category</Label>
                    <Tooltip
                      title="Lorem ipsum"
                      placement="top">
                      <img
                        src={info}
                        alt="info"
                        className="c-pointer"
                        width={13.5}
                      />
                    </Tooltip>
                  </div>
                  <FormControl
                    sx={{ width: "100%" }}
                    size="small">
                    <Select
                      labelId="demo-select-small"
                      id="demo-select-small"
                      name="productType.categoryId"
                      value={productFormik.values.productType?.categoryId || ""}
                      onChange={productFormik.handleChange}
                      onBlur={categoryBlurHandler}>
                      {!!categories?.length && <MenuItem value="">Select</MenuItem>}
                      {!!categories?.length ? (
                        categories?.map((category) => {
                          return (
                            <MenuItem
                              key={category?._id}
                              value={category?._id}>
                              {category?.name}
                            </MenuItem>
                          );
                        })
                      ) : (
                        <span
                          style={{
                            fontSize: 13,
                            color: "#5c6d8e",
                            padding: "0 16px",
                            display: "block",
                          }}>
                          No Options
                        </span>
                      )}
                    </Select>
                    {!!isFieldTouched.categoryId &&
                      productFormik.errors.productType?.categoryId && (
                        <FormHelperText error>
                          {productFormik.errors.productType?.categoryId}
                        </FormHelperText>
                      )}
                  </FormControl>
                </div>
                {productFormik.values.productType?.categoryId && !!subCategories?.length && (
                  <div className="col-4 ps-0 mt-2">
                    <div className="d-flex mb-1">
                      <Label>Sub-Category</Label>
                      <Tooltip
                        title="Lorem ipsum"
                        placement="top">
                        <img
                          src={info}
                          alt="info"
                          className="c-pointer"
                          width={13.5}
                        />
                      </Tooltip>
                    </div>
                    <FormControl
                      sx={{ width: "100%" }}
                      size="small">
                      <Select
                        labelId="demo-select-small"
                        id="demo-select-small"
                        name="productType.subCategoryId"
                        value={productFormik.values.productType?.subCategoryId || ""}
                        onChange={productFormik.handleChange}
                        onBlur={subCategoryBlurHandler}>
                        {!!subCategories?.length && <MenuItem value="">Select</MenuItem>}
                        {subCategories?.map((subCategory) => {
                          return (
                            <MenuItem
                              key={subCategory?._id}
                              value={subCategory?._id}>
                              {subCategory.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      {!!isFieldTouched.subCategoryId &&
                        productFormik.errors.productType?.subCategoryId && (
                          <FormHelperText error>
                            {productFormik.errors.productType?.subCategoryId}
                          </FormHelperText>
                        )}
                    </FormControl>
                  </div>
                )}
                <div
                  className={`${
                    productFormik.values.productType?.categoryId && !!subCategories?.length
                      ? "col-4"
                      : "col-6"
                  } ps-0 mt-2`}>
                  <div className="d-flex mb-1">
                    <Label>Vendor Name</Label>
                    <Tooltip
                      title="Lorem ipsum"
                      placement="top">
                      <img
                        src={info}
                        alt="info"
                        className="c-pointer"
                        width={13.5}
                      />
                    </Tooltip>
                  </div>
                  <FormControl
                    sx={{ width: "100%" }}
                    size="small">
                    <Select
                      labelId="demo-select-small"
                      id="demo-select-small"
                      name="productType.vendorId"
                      value={productFormik.values.productType?.vendorId || ""}
                      onChange={productFormik.handleChange}
                      onBlur={vendorBlurHandler}>
                      {!!vendors?.length && <MenuItem value="">Select</MenuItem>}
                      {vendors?.map((vendor) => {
                        return (
                          <MenuItem
                            key={vendor?._id}
                            value={vendor?._id}>
                            {vendor.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    {!!isFieldTouched.vendorId &&
                      productFormik.errors.productType?.vendorId && (
                        <FormHelperText error>
                          {productFormik.errors.productType?.vendorId}
                        </FormHelperText>
                      )}
                  </FormControl>
                </div>
                <div className="col-12 px-0 mt-4">
                  <div className="d-flex mb-1">
                    <Label>Collections</Label>
                    <Tooltip
                      title="Lorem ipsum"
                      placement="top">
                      <img
                        src={info}
                        alt="info"
                        className="c-pointer"
                        width={13.5}
                      />
                    </Tooltip>
                  </div>
                  <FormControl
                    sx={{
                      m: 0,
                      minWidth: 120,
                      width: "100%",
                    }}
                    size="small">
                    <Autocomplete
                      multiple
                      onChange={addCollectionHandler}
                      onBlur={collectionBlurHandler}
                      value={selectedCollection}
                      id="checkboxes-tags-demo"
                      sx={{ width: "100%" }}
                      options={collections || []}
                      disableCloseOnSelect
                      getOptionLabel={(coll) => coll.title}
                      size="small"
                      renderOption={(props, coll, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                            checked={selected}
                            size="small"
                            style={{
                              color: "#5C6D8E",
                              marginRight: 0,
                            }}
                          />
                          <small className="text-lightBlue">{coll.title}</small>
                        </li>
                      )}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <OptionChip
                            option={option}
                            {...getTagProps({ index })}
                            className="me-1"
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          {...params}
                        />
                      )}
                    />
                  </FormControl>
                </div>
                <div className="col-12 px-0 mt-4">
                  <div className="d-flex mb-1">
                    <Label>Tags</Label>
                    <Tooltip
                      title="Lorem ipsum"
                      placement="top">
                      <img
                        src={info}
                        alt="info"
                        className="c-pointer"
                        width={13.5}
                      />
                    </Tooltip>
                  </div>
                  <FormControl
                    sx={{
                      m: 0,
                      minWidth: 120,
                      width: "100%",
                    }}
                    size="small">
                    <Autocomplete
                      multiple
                      onChange={addTagsHandler}
                      onBlur={tagManagerBlurHandler}
                      value={selectedTags}
                      id="checkboxes-tags-demo"
                      sx={{ width: "100%" }}
                      options={tags || []}
                      disableCloseOnSelect
                      getOptionLabel={(tag) => tag.name}
                      size="small"
                      // inputValue={inputValue}
                      // onInputChange={(_, val) => setInputValue(val)}
                      // noOptionsText={
                      //   <Button
                      //     fullWidth
                      //     variant="outlined"
                      //     onClick={onClickTag}
                      //     startIcon={
                      //       <AddRounded
                      //         sx={{
                      //           bgcolor: "#648DED",
                      //           color: "#14122b",
                      //           fontSize: "18px",
                      //         }}
                      //       />
                      //     }>
                      //     <span
                      //       style={{ fontSize: "14px" }}
                      //       className="text-lightBlue">
                      //       Add {inputValue}
                      //     </span>
                      //   </Button>
                      // }
                      renderOption={(props, tag, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                            checked={selected}
                            size="small"
                            style={{
                              color: "#5C6D8E",
                              marginRight: 0,
                            }}
                          />
                          <small className="text-lightBlue">{tag.name}</small>
                        </li>
                      )}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <OptionChip
                            option={option}
                            {...getTagProps({ index })}
                            className="me-1"
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          {...params}
                        />
                      )}
                    />
                    {!!isFieldTouched.tagManagerId &&
                      productFormik.errors.productType?.tagManagerId && (
                        <FormHelperText error>
                          {productFormik.errors.productType?.tagManagerId}
                        </FormHelperText>
                      )}
                  </FormControl>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-black-15 border-grey-5 rounded-8 p-3 row productInfo mt-4">
            <div className="col-12 px-0">
              <div className="d-flex mb-3">
                <h6 className="text-lightBlue px-0 fw-500 me-2">Price</h6>
                <Tooltip
                  title="Lorem ipsum"
                  placement="top">
                  <img
                    src={info}
                    alt="info"
                    className="c-pointer"
                    width={13.5}
                  />
                </Tooltip>
              </div>
              <div className="row">
                {!(id && productsData?.data?.[0]?.setId) ? (
                  <>
                    <div className="col-4 mt-2">
                      <div className="d-flex mb-1">
                        <Label required={!productFormik.values.price.dynamicPricing}>
                          Total Price
                        </Label>
                        <Tooltip
                          title="Lorem ipsum"
                          placement="top">
                          <img
                            src={info}
                            alt="info"
                            className="c-pointer"
                            width={13.5}
                          />
                        </Tooltip>
                      </div>
                      <FormControl
                        sx={{ width: "100%" }}
                        className="col-7 px-0">
                        <OutlinedInput
                          type="number"
                          name="price.price"
                          value={productFormik.values.price.price || ""}
                          onChange={productPriceHandler}
                          onBlur={productFormik.handleBlur}
                          placeholder="Enter Price"
                          size="small"
                          disabled={!!productFormik.values.price.dynamicPricing}
                          startAdornment={
                            <InputAdornment position="start">
                              <p className="text-lightBlue">₹</p>
                            </InputAdornment>
                          }
                        />
                        {!productFormik.values.price.dynamicPricing &&
                          !!productFormik.touched.price?.price &&
                          productFormik.errors.price?.price && (
                            <FormHelperText error>
                              {productFormik.errors.price?.price}
                            </FormHelperText>
                          )}
                      </FormControl>
                    </div>
                    <div className="col-4 ps-0 mt-2">
                      <div className="d-flex mb-1">
                        <Label required={!productFormik.values.price.dynamicPricing}>
                          Discount
                        </Label>
                        <Tooltip
                          title="Lorem ipsum"
                          placement="top">
                          <img
                            src={info}
                            alt="info"
                            className="c-pointer"
                            width={13.5}
                          />
                        </Tooltip>
                      </div>
                      <FormControl
                        className="px-0"
                        style={{ width: "100%" }}>
                        <OutlinedInput
                          type="number"
                          value={productFormik.values.price?.discount || ""}
                          onChange={discountHandler}
                          onBlur={productFormik.handleBlur}
                          name="price.discount"
                          placeholder="Enter Discount"
                          size="small"
                          disabled={!!productFormik.values.price.dynamicPricing}
                          startAdornment={
                            productFormik.values.price?.type === "fixed" && (
                              <InputAdornment
                                position="start"
                                variant="standard">
                                <span className="text-lightBlue">₹</span>
                              </InputAdornment>
                            )
                          }
                          endAdornment={
                            <>
                              <InputAdornment
                                position="end"
                                variant="standard">
                                <span className="text-lightBlue">
                                  {productFormik.values.price?.type === "percentage" && "%"}
                                </span>
                              </InputAdornment>
                              <InputAdornment
                                position="end"
                                aria-describedby={
                                  productState.showDiscountPopover
                                    ? "discount-popover"
                                    : undefined
                                }
                                onClick={(e) => toggleDiscountPopoverHandler(e, true)}
                                className="c-pointer">
                                <span className="d-flex align-items-center">
                                  <p className="text-lightBlue">
                                    {productFormik.values.price?.type === "percentage"
                                      ? `Percentage`
                                      : `Fixed Amount`}
                                  </p>
                                  <img
                                    src={arrowDown}
                                    alt="arrow"
                                    className="ms-2"
                                  />
                                </span>
                              </InputAdornment>
                            </>
                          }
                        />
                        {!productFormik.values.price.dynamicPricing &&
                          !!productFormik.touched.price?.discount &&
                          productFormik.errors.price?.discount && (
                            <FormHelperText error>
                              {productFormik.errors.price?.discount}
                            </FormHelperText>
                          )}
                      </FormControl>
                      <Popover
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        id={productState.showDiscountPopover ? "discount-popover" : undefined}
                        open={productState.showDiscountPopover}
                        anchorEl={productState.discountPopoverAnchor}
                        onClose={(e) => toggleDiscountPopoverHandler(e, false)}>
                        <div className="py-2 px-1">
                          <small
                            className="text-lightBlue rounded-3 p-2 hover-back d-block"
                            onClick={discountTypeHandler.bind(null, "percentage")}
                            style={{ cursor: "pointer" }}>
                            Percentage Discount
                          </small>
                          <small
                            className="text-lightBlue rounded-3 p-2 hover-back d-block"
                            onClick={discountTypeHandler.bind(null, "fixed")}
                            style={{ cursor: "pointer" }}>
                            Fixed Amount
                          </small>
                        </div>
                      </Popover>
                    </div>
                    <div className="col-4 mt-2">
                      <div className="d-flex mb-1">
                        <Label required={!productFormik.values.price.dynamicPricing}>
                          Sell Price
                        </Label>
                        <Tooltip
                          title="Lorem ipsum"
                          placement="top">
                          <img
                            src={info}
                            alt="info"
                            className="c-pointer"
                            width={13.5}
                          />
                        </Tooltip>
                      </div>
                      <FormControl
                        sx={{ width: "100%" }}
                        className="col-7 px-0">
                        <OutlinedInput
                          type="number"
                          name="price.salePrice"
                          value={productFormik.values.price.salePrice || ""}
                          onChange={salePriceHandler}
                          onBlur={productFormik.handleBlur}
                          placeholder="Enter Total Sale Price"
                          size="small"
                          disabled={
                            !!productFormik.values.price.dynamicPricing ||
                            !productFormik.values.price.price
                          }
                          startAdornment={
                            <InputAdornment position="start">
                              <p className="text-lightBlue">₹</p>
                            </InputAdornment>
                          }
                        />
                        {!productFormik.values.price.dynamicPricing &&
                          !!productFormik.touched.price?.salePrice &&
                          productFormik.errors.price?.salePrice && (
                            <FormHelperText error>
                              {productFormik.errors.price?.salePrice}
                            </FormHelperText>
                          )}
                      </FormControl>
                    </div>
                  </>
                ) : (
                  <p className="text-lightBlue mt-3">
                    Add Variant options to create variants and manage pricing{" "}
                    {id && +(productsData?.data?.[0]?.step ?? 0) >= 2 && (
                      <span className="text-blue-2 c-pointer">(Add Variants)</span>
                    )}
                  </p>
                )}

                <div className="col-12">
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="price.dynamicPricing"
                        checked={productFormik.values.price.dynamicPricing}
                        onChange={dynamicPricingHandler}
                        inputProps={{ "aria-label": "controlled" }}
                        size="small"
                        style={{
                          color: "#5C6D8E",
                        }}
                      />
                    }
                    label="Enable Dynamic Pricing"
                    sx={{
                      "& .MuiTypography-root": {
                        fontSize: 13,
                        color: "#c8d8ff",
                      },
                    }}
                    className="me-0"
                  />
                  <Tooltip
                    title="Lorem ipsum"
                    placement="top">
                    <img
                      src={info}
                      alt="info"
                      className="c-pointer ms-2"
                      width={13.5}
                    />
                  </Tooltip>
                </div>

                {productFormik.values.price.dynamicPricing && (
                  <div className="col-12">
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="price.breakup"
                          checked={productFormik.values.price.breakup}
                          onChange={productFormik.handleChange}
                          inputProps={{ "aria-label": "controlled" }}
                          size="small"
                          style={{
                            color: "#5C6D8E",
                          }}
                        />
                      }
                      label="Show Price Breakup"
                      sx={{
                        "& .MuiTypography-root": {
                          fontSize: 13,
                          color: "#c8d8ff",
                        },
                      }}
                      className="me-0"
                    />
                    <Tooltip
                      title="Lorem ipsum"
                      placement="top">
                      <img
                        src={info}
                        alt="info"
                        className="c-pointer ms-2"
                        width={13.5}
                      />
                    </Tooltip>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="bg-black-15 border-grey-5 rounded-8 p-3 row productInfo mt-4">
            <div className="col-12 px-0">
              <div className="d-flex mb-3">
                <h6 className="text-lightBlue px-0 fw-500">Inventory</h6>
                <Tooltip
                  title="Lorem ipsum"
                  placement="top">
                  <img
                    src={info}
                    alt="info"
                    className="c-pointer ms-2"
                    width={13.5}
                  />
                </Tooltip>
              </div>
              <div className="row">
                <div className="col-6 mt-2">
                  <div className="d-flex mb-1">
                    <Label
                      required={true}
                      style={{ fontSize: "1rem" }}
                      className="text-lightBlue text-lightBlue fw-500">
                      SKU
                    </Label>

                    <Tooltip
                      title="Lorem ipsum"
                      placement="top">
                      <img
                        src={info}
                        alt="info"
                        className="c-pointer ms-2"
                        width={13.5}
                      />
                    </Tooltip>
                  </div>

                  <FormControl
                    sx={{ width: "100%" }}
                    className="col-7 px-0">
                    <OutlinedInput
                      // disabled={!!id}
                      placeholder="Enter SKU"
                      size="small"
                      name="inventory.skuId"
                      value={productFormik.values.inventory.skuId}
                      onBlur={productFormik.handleBlur}
                      onChange={productFormik.handleChange}
                    />

                    {!!productFormik.touched.inventory?.skuId &&
                      productFormik.errors.inventory?.skuId && (
                        <FormHelperText error>
                          {productFormik.errors.inventory?.skuId}
                        </FormHelperText>
                      )}
                  </FormControl>
                </div>
                <div className="col-6 mt-2">
                  <div className="d-flex mb-1">
                    <Label
                      required={false}
                      style={{ fontSize: "1rem" }}
                      className="text-lightBlue text-lightBlue fw-500">
                      Barcode (ISBN, UPC, GTIN etc.)
                    </Label>

                    <Tooltip
                      title="Lorem ipsum"
                      placement="top">
                      <img
                        src={info}
                        alt="info"
                        className="c-pointer ms-2"
                        width={13.5}
                      />
                    </Tooltip>
                  </div>

                  <FormControl
                    sx={{ width: "100%" }}
                    className="col-7 px-0">
                    <OutlinedInput
                      // disabled={!!id}
                      placeholder="Enter Barcode"
                      size="small"
                      name="inventory.barCode"
                      value={productFormik.values.inventory.barCode}
                      onBlur={productFormik.handleBlur}
                      onChange={productFormik.handleChange}
                    />
                    {!!productFormik.touched.inventory?.barCode &&
                      productFormik.errors.inventory?.barCode && (
                        <FormHelperText error>
                          {productFormik.errors.inventory?.barCode}
                        </FormHelperText>
                      )}
                  </FormControl>
                </div>
                {/* <div className="col-12 mt-2 d-flex align-items-center">
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="inventory.isTrackInventory"
                        checked={
                          productFormik.values.inventory?.isTrackInventory
                        }
                        onChange={productFormik.handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                        size="small"
                        style={{
                          color: "#5C6D8E",
                        }}
                      />
                    }
                    label="Track Inventory"
                    sx={{
                      "& .MuiTypography-root": {
                        fontSize: 13,
                        color: "#c8d8ff",
                      },
                    }}
                    className="me-0"
                  />
                  <Tooltip title="Lorem ipsum" placement="top">
                    <img
                      src={info}
                      alt="info"
                      className="c-pointer ms-2"
                      width={13.5}
                    />
                  </Tooltip>
                </div> */}

                <>
                  <div className="col-12">
                    <hr className="hr-grey-6 my-3 " />
                  </div>
                  {!id ? (
                    <div className="col-12 d-flex">
                      <div className="d-flex align-items-center me-4">
                        <div>
                          <Label
                            required={true}
                            className="text-lightBlue text-lightBlue fw-500">
                            <h6 className="text-lightBlue">Track Inventory on</h6>
                          </Label>
                          {!!productFormik.touched.inventory?.inventoryOn &&
                            productFormik.errors.inventory?.inventoryOn && (
                              <FormHelperText error>
                                {productFormik.errors.inventory?.inventoryOn}
                              </FormHelperText>
                            )}
                        </div>

                        <Tooltip
                          title="Lorem ipsum"
                          placement="top">
                          <img
                            src={info}
                            alt="info"
                            className="c-pointer ms-2"
                            width={13.5}
                          />
                        </Tooltip>
                      </div>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="inventory.inventoryOn"
                        value={productFormik.values.inventory?.inventoryOn}
                        onChange={productFormik.handleChange}>
                        <FormControlLabel
                          value="product"
                          control={
                            <Radio
                              disabled={!!id}
                              size="small"
                            />
                          }
                          label="Product Level"
                          sx={{
                            "& .MuiTypography-root": {
                              fontSize: 13,
                              color: "#c8d8ff",
                            },
                          }}
                        />
                        <FormControlLabel
                          value="variant"
                          control={
                            <Radio
                              disabled={!!id}
                              size="small"
                            />
                          }
                          label="Variant Level"
                          sx={{
                            "& .MuiTypography-root": {
                              fontSize: 13,
                              color: "#c8d8ff",
                            },
                          }}
                        />
                      </RadioGroup>
                    </div>
                  ) : (
                    <div className="col-12 d-flex">
                      <div className="d-flex align-items-center me-4">
                        <div>
                          <Label className="text-lightBlue text-lightBlue fw-500">
                            <h6 className="text-lightBlue">
                              Track Inventory on {productFormik.values.inventory?.inventoryOn}
                            </h6>
                          </Label>
                        </div>
                        <Tooltip
                          title="Lorem ipsum"
                          placement="top">
                          <img
                            src={info}
                            alt="info"
                            className="c-pointer ms-2"
                            width={13.5}
                          />
                        </Tooltip>
                      </div>
                    </div>
                  )}
                  {/* {productFormik.values.inventory?.inventoryOn === "variant" && ( */}
                  {!(id && productsData?.data?.[0]?.inventory?.isInventory) && (
                    <p className="text-lightBlue mt-3">
                      Add Variant options to create variants and manage inventory
                    </p>
                  )}

                  <div className="col-12 mt-2">
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="inventory.isNegativeSellAllowed"
                          checked={productFormik.values.inventory?.isNegativeSellAllowed}
                          onChange={productFormik.handleChange}
                          inputProps={{ "aria-label": "controlled" }}
                          size="small"
                          style={{
                            color: "#5C6D8E",
                          }}
                        />
                      }
                      label="Continue to sell when out of stock"
                      sx={{
                        "& .MuiTypography-root": {
                          fontSize: 13,
                          color: "#c8d8ff",
                        },
                      }}
                      className="me-0"
                    />
                    <Tooltip
                      title="Lorem ipsum"
                      placement="top">
                      <img
                        src={info}
                        alt="info"
                        className="c-pointer ms-2"
                        width={13.5}
                      />
                    </Tooltip>
                  </div>

                  {productFormik.values.inventory?.inventoryOn === "product" && (
                    <>
                      {/* <div className="col-12 d-flex justify-content-between mt-3">
                        <h6 className="text-grey-6">Manage Inventory</h6>
                        <p
                          className="text-blue-2 c-pointer"
                          // onClick={handleStoreOpen}
                        >
                          Edit Store
                        </p>
                      </div> */}

                      {/* <div className="col-12 d-flex align-items-center mt-3 mb-2">
                        <p className="text-lightBlue">Apply Quantity to all stores</p>
                        <Tooltip
                          title="Lorem ipsum"
                          placement="top">
                          <img
                            src={info}
                            alt="info"
                            className="c-pointer ms-2"
                            width={13.5}
                          />
                        </Tooltip>
                      </div> */}
                      {/* <div className="col-12 d-flex">
                        <div className="row w-100 align-items-center">
                          <div className="col-md-3">
                            <FormControl className="w-100">
                              <OutlinedInput
                                placeholder="Enter Quantity"
                                size="small"
                                type="number"
                                value={adjustInventoryCount.qty}
                                onChange={(e) => {
                                  if (e.target.value < 0) {
                                    setAdjustInventoryCount((prevState) => {
                                      return {
                                        ...prevState,
                                        qty: 0,
                                      };
                                    });
                                    return;
                                  }
                                  setAdjustInventoryCount((prevState) => {
                                    return {
                                      ...prevState,
                                      qty: e.target.value,
                                    };
                                  });
                                }}
                              />
                            </FormControl>
                          </div>
                          <div className="col-auto px-md-0">
                            <FormControl
                              sx={{ m: 0, width: 120 }}
                              size="small">
                              <Select
                                labelId="demo-select-small"
                                id="demo-select-small"
                                value={adjustInventoryCount.action}
                                placeholder="Fixed"
                                onChange={(e) => {
                                  setAdjustInventoryCount((prevState) => {
                                    return {
                                      ...prevState,
                                      action: e.target.value,
                                    };
                                  });
                                }}>
                                <MenuItem value="">Select</MenuItem>
                                <MenuItem value="fixed">Fixed</MenuItem>
                                <MenuItem value="increase">Increase</MenuItem>
                                <MenuItem value="decrease">Decrease</MenuItem>
                              </Select>
                            </FormControl>
                          </div>
                          <div className="col-md-3">
                            <button
                              onClick={() => {
                                switch (adjustInventoryCount.action) {
                                  case "fixed": {
                                    const store = productFormik.values.inventory.store.map(
                                      (store) => {
                                        return {
                                          ...store,
                                          quantityCount: +adjustInventoryCount.qty,
                                        };
                                      }
                                    );
                                    productFormik.setFieldValue("inventory.store", store);
                                    break;
                                  }
                                  case "increase": {
                                    const store = productFormik.values.inventory.store.map(
                                      (store) => {
                                        return {
                                          ...store,
                                          quantityCount:
                                            +store.quantityCount + +adjustInventoryCount.qty,
                                        };
                                      }
                                    );
                                    productFormik.setFieldValue("inventory.store", store);
                                    break;
                                  }
                                  case "decrease": {
                                    const store = productFormik.values.inventory.store.map(
                                      (store) => {
                                        return {
                                          ...store,
                                          quantityCount:
                                            +store.quantityCount - +adjustInventoryCount.qty,
                                        };
                                      }
                                    );
                                    productFormik.setFieldValue("inventory.store", store);
                                    break;
                                  }

                                  default: {
                                    break;
                                  }
                                }
                                setAdjustInventoryCount({
                                  qty: "",
                                  action: "",
                                });
                              }}
                              disabled={
                                adjustInventoryCount.qty && adjustInventoryCount.action
                                  ? false
                                  : true
                              }
                              type="button"
                              className="button-gradient py-2 px-5 w-auto">
                              <small>Apply</small>
                            </button>
                          </div>
                          {/* <p
                              className='text-blue-2 c-pointer col-auto ms-auto px-0'
                              // onClick={handleStoreOpen}
                            >
                              Edit Store
                            </p> /}
                        </div>
                      </div> */}
                      <div className="col-12 mt-4 mb-3">
                        <div className="d-flex justify-content-between align-items-center py-2 px-3 rounded-3 bg-black-13">
                          <div className="d-flex">
                            <p className="text-lightBlue py-1 me-2">Store</p>
                            {/* <p
                                className='text-blue-2 px-2 py-1 c-pointer hover-back-transparent rounded-3'
                                // aria-describedby={idMetalFilter}
                                variant='contained'
                                // onClick={handleMetalFilter}
                              >
                                Country
                              </p>
                              <p
                                className='text-blue-2 px-2 py-1 c-pointer hover-back-transparent rounded-3'
                                // aria-describedby={idMetalFilter}
                                variant='contained'
                                // onClick={handleMetalFilter}
                              >
                                State
                              </p> */}
                          </div>

                          {/* <Popover
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                              }}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                              }}
                              // id={idMetalFilter}
                              // open={openMetalFilter}
                              // anchorEl={anchorMetalFilterEl}
                              // onClose={handleMetalFilterClose}
                            >
                              <FormGroup className='tags-checkbox py-2'>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      size='small'
                                      style={{
                                        color: '#5C6D8E',
                                      }}
                                    />
                                  }
                                  label='Content 1'
                                  className='hover-back rounded-3 mx-0 pe-2'
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      size='small'
                                      style={{
                                        color: '#5C6D8E',
                                      }}
                                    />
                                  }
                                  label='Content 2'
                                  className='hover-back rounded-3 mx-0 pe-2'
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      size='small'
                                      style={{
                                        color: '#5C6D8E',
                                      }}
                                    />
                                  }
                                  label='Content 3'
                                  className='hover-back rounded-3 mx-0 pe-2'
                                />
                              </FormGroup>
                            </Popover> */}
                          <p className="text-lightBlue">{`${
                            productFormik.values.inventory?.store?.length || 0
                          } stores`}</p>
                        </div>
                      </div>
                      {!!productFormik.values.inventory?.store?.length &&
                        productFormik.values.inventory?.store?.map((store, index) => {
                          const currentStore = storeData?.data?.data?.find((data) => {
                            return data._id === store.store;
                          });

                          return currentStore ? (
                            <div
                              key={store.store}
                              className="col-12 d-flex justify-content-between my-2 align-items-center px-4">
                              <p className="text-lightBlue">
                                {" "}
                                {currentStore?.name}, {currentStore?.address.city.name}{" "}
                                {currentStore?.address.pincode}
                              </p>

                              <FormControl
                                sx={{ width: 100 }}
                                className="col-7 px-0 productInfo-inputs">
                                <OutlinedInput
                                  placeholder="Qty"
                                  size="small"
                                  type="number"
                                  name={`inventory.store[${index}].quantityCount`}
                                  value={
                                    productFormik.values.inventory.store?.[index].quantityCount
                                  }
                                  onBlur={productFormik.handleBlur}
                                  onChange={productFormik.handleChange}
                                />
                                {!!productFormik.touched.inventory?.store?.[index]
                                  ?.quantityCount &&
                                  productFormik.errors.inventory?.store?.[index]
                                    ?.quantityCount && (
                                    <FormHelperText error>
                                      {
                                        productFormik.errors.inventory?.store?.[index]
                                          ?.quantityCount
                                      }
                                    </FormHelperText>
                                  )}
                              </FormControl>
                            </div>
                          ) : null;
                        })}
                    </>
                  )}
                </>
              </div>
            </div>
          </div>
          {!!dynamicTabFormik.values.dynamicTabs?.length && (
            <div className="bg-black-15 border-grey-5 rounded-8 p-3 row productInfo mt-4">
              <div className="col-12">
                <div className="row mb-3">
                  <h6 className="text-lightBlue col-12 px-0 fw-500">
                    Product Tab
                    <Tooltip
                      title="Lorem ipsum"
                      placement="top">
                      <img
                        src={info}
                        alt="info"
                        className="c-pointer ms-2"
                        width={13.5}
                      />
                    </Tooltip>
                  </h6>
                </div>
                <div className="row">
                  <div className="col-4 ps-0 mt-2">
                    <div className="d-flex mb-1">
                      <small className="text-grey-6 mt-2">Note : Customer can see this</small>
                    </div>
                  </div>

                  <div className="col-12">
                    <ToggleButtonGroup
                      aria-label="text formatting"
                      className="row d-flex mt-1 toggle"
                      size="small"
                      exclusive
                      value={activeDynamicTab}
                      onChange={dynamicTabToggleHandler}>
                      {dynamicTabFormik.values.dynamicTabs?.map((tab) => {
                        return (
                          <ToggleButton
                            key={tab.tabId}
                            value={tab.tabId}
                            aria-label="physical"
                            style={{ width: 200 }}>
                            <div className="d-flex">
                              <p className="text-grey-6">{tab?.data[0]?.title}</p>
                            </div>
                          </ToggleButton>
                        );
                      })}
                    </ToggleButtonGroup>
                  </div>
                  {dynamicTabFormik.values.dynamicTabs?.map((tab, index) => {
                    if (tab.tabId === activeDynamicTab) {
                      return (
                        <div
                          key={tab.tabId}
                          style={{ background: "#15142A" }}>
                          {tab.data[0].commonCustomFields.map((field, fieldIndex) => {
                            const isDefaultHighlight = field.isDefaultHighlight;
                            const value = field.productValue;

                            let fieldInput = (
                              <FormControl className="col-7 px-0">
                                <OutlinedInput
                                  placeholder="Enter content"
                                  size="small"
                                  name={`dynamicTabs[${index}].data[0].commonCustomFields[${fieldIndex}].value`}
                                  disabled={!!isDefaultHighlight}
                                  value={
                                    isDefaultHighlight
                                      ? value
                                      : dynamicTabFormik.values.dynamicTabs[index].data[0]
                                          .commonCustomFields[fieldIndex].value || ""
                                  }
                                  onChange={
                                    !isDefaultHighlight
                                      ? dynamicTabFormik.handleChange
                                      : () => {}
                                  }
                                  className="py-1"
                                />
                              </FormControl>
                            );
                            switch (field?.fieldType) {
                              case "image": {
                                fieldInput = (
                                  <div
                                    style={{
                                      marginBottom: "-20px",
                                    }}
                                    className="col-7">
                                    <UploadMediaSmall
                                      disableLabel={true}
                                      disabled={!!isDefaultHighlight}
                                      style={{
                                        justifyContent: "flex-start",
                                        padding: "8.5px 14px",
                                      }}
                                      fileSrc={
                                        isDefaultHighlight
                                          ? value
                                          : dynamicTabFormik.values.dynamicTabs[index].data[0]
                                              .commonCustomFields[fieldIndex].value || ""
                                      }
                                      onUpload={
                                        !isDefaultHighlight
                                          ? (url) => {
                                              dynamicTabCommonFieldMediaHandle(
                                                url,
                                                index,
                                                fieldIndex
                                              );
                                            }
                                          : () => {}
                                      }
                                      name={`dynamicTabs[${index}].data[0].commonCustomFields[${fieldIndex}].value`}
                                      isSubmitting={dynamicTabFormik.isSubmitting}
                                    />
                                  </div>
                                );
                                break;
                              }
                              case "dimension": {
                                fieldInput = (
                                  <FormControl className="col-7 px-0">
                                    <OutlinedInput
                                      disabled={!!isDefaultHighlight}
                                      type="number"
                                      placeholder="Enter Content"
                                      size="small"
                                      name={`dynamicTabs[${index}].data[0].commonCustomFields[${fieldIndex}].value`}
                                      value={
                                        isDefaultHighlight
                                          ? value
                                          : dynamicTabFormik.values.dynamicTabs[index].data[0]
                                              .commonCustomFields[fieldIndex].value || ""
                                      }
                                      onChange={
                                        !isDefaultHighlight
                                          ? dynamicTabFormik.handleChange
                                          : () => {}
                                      }
                                      className="py-1"
                                      endAdornment={
                                        <InputAdornment
                                          position="start"
                                          sx={{ color: "#5C6D8E" }}>
                                          <span className="mr-4">|</span>
                                          mm
                                        </InputAdornment>
                                      }
                                    />
                                  </FormControl>
                                );
                                break;
                              }
                              case "weight": {
                                fieldInput = (
                                  <FormControl className="col-7 px-0">
                                    <OutlinedInput
                                      disabled={!!isDefaultHighlight}
                                      type="number"
                                      placeholder="Enter Content"
                                      size="small"
                                      name={`dynamicTabs[${index}].data[0].commonCustomFields[${fieldIndex}].value`}
                                      value={
                                        isDefaultHighlight
                                          ? value
                                          : dynamicTabFormik.values.dynamicTabs[index].data[0]
                                              .commonCustomFields[fieldIndex].value || ""
                                      }
                                      onChange={
                                        !isDefaultHighlight
                                          ? dynamicTabFormik.handleChange
                                          : () => {}
                                      }
                                      className="py-1"
                                      endAdornment={
                                        <InputAdornment
                                          position="start"
                                          sx={{ color: "#5C6D8E" }}>
                                          <span className="mr-4">|</span>
                                          mm
                                        </InputAdornment>
                                      }
                                    />
                                  </FormControl>
                                );
                                break;
                              }
                              case "productField": {
                                let value = "";

                                switch (field?.productValue) {
                                  case "title": {
                                    value = productFormik.values.title;
                                    break;
                                  }
                                  case "description": {
                                    value = productFormik.values.description;
                                    break;
                                  }
                                  case "category": {
                                    value = selectedCategory?.name || "";
                                    break;
                                  }
                                  case "subCategory": {
                                    value = selectedSubCategory?.name || "";
                                    break;
                                  }
                                  case "tagManager": {
                                    value = selectedTags[0]?.name || "";
                                    break;
                                  }
                                  case "vendor": {
                                    value = selectedVendors?.name || "";
                                    break;
                                  }
                                  case "collection": {
                                    value = selectedCollection[0]?.title || "";
                                    break;
                                  }

                                  default:
                                    break;
                                }

                                fieldInput = (
                                  <FormControl className="col-7 px-0 backgroundDisable">
                                    <OutlinedInput
                                      size="small"
                                      disabled
                                      value={value}
                                      className="py-1 linked-value"
                                      startAdornment={
                                        <InputAdornment position="start">
                                          <span className="linked-title me-1">{`${field?.productValue}:`}</span>
                                        </InputAdornment>
                                      }
                                    />
                                  </FormControl>
                                );
                                break;
                              }

                              default:
                                break;
                            }
                            return (
                              <div
                                key={field._id}
                                className="d-flex col-12 mt-3">
                                <div className="col-5">
                                  <p className="text-lightBlue">{field.title}</p>
                                  {field.visibility === "hide" ? (
                                    <small className="text-grey-6 mt-2">
                                      <img
                                        src={eye}
                                        alt="closed-eye-symbol"
                                        className="me-1"
                                      />
                                      Customer wont see this
                                    </small>
                                  ) : field?.productValue && !isDefaultHighlight ? (
                                    <small className="text-grey-6 mt-2">
                                      <InsertLinkIcon className="me-1" />
                                      {`Linked to Product ${field?.productValue}`}
                                    </small>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                                {fieldInput}
                              </div>
                            );
                          })}
                          {tab.data[0].customFields.map((field, fieldIndex) => {
                            let fieldInput = (
                              <FormControl className="col-7 px-0">
                                <OutlinedInput
                                  placeholder="Enter content"
                                  size="small"
                                  name={`dynamicTabs[${index}].data[0].customFields[${fieldIndex}].value`}
                                  value={
                                    dynamicTabFormik.values.dynamicTabs[index].data[0]
                                      .customFields[fieldIndex].value || ""
                                  }
                                  onChange={dynamicTabFormik.handleChange}
                                  className="py-1"
                                />
                              </FormControl>
                            );
                            switch (field?.fieldType) {
                              case "image": {
                                fieldInput = (
                                  <div
                                    style={{
                                      marginBottom: "-20px",
                                    }}
                                    className="col-7">
                                    <UploadMediaSmall
                                      disableLabel={true}
                                      style={{
                                        justifyContent: "flex-start",
                                        padding: "8.5px 14px",
                                      }}
                                      fileSrc={
                                        dynamicTabFormik.values.dynamicTabs[index].data[0]
                                          .customFields[fieldIndex].value || ""
                                      }
                                      onUpload={(url) => {
                                        dynamicTabFieldMediaHandle(url, index, fieldIndex);
                                      }}
                                      name={`dynamicTabs[${index}].data[0].customFields[${fieldIndex}].value`}
                                      isSubmitting={dynamicTabFormik.isSubmitting}
                                    />
                                  </div>
                                );
                                break;
                              }
                              case "dimension": {
                                fieldInput = (
                                  <FormControl className="col-7 px-0">
                                    <OutlinedInput
                                      type="number"
                                      placeholder="Enter Content"
                                      size="small"
                                      name={`dynamicTabs[${index}].data[0].customFields[${fieldIndex}].value`}
                                      value={
                                        dynamicTabFormik.values.dynamicTabs[index].data[0]
                                          .customFields[fieldIndex].value || ""
                                      }
                                      onChange={dynamicTabFormik.handleChange}
                                      className="py-1"
                                      endAdornment={
                                        <InputAdornment
                                          position="start"
                                          sx={{ color: "#5C6D8E" }}>
                                          <span className="mr-4">|</span>
                                          mm
                                        </InputAdornment>
                                      }
                                    />
                                  </FormControl>
                                );
                                break;
                              }
                              case "weight": {
                                fieldInput = (
                                  <FormControl className="col-7 px-0">
                                    <OutlinedInput
                                      type="number"
                                      placeholder="Enter Content"
                                      size="small"
                                      name={`dynamicTabs[${index}].data[0].customFields[${fieldIndex}].value`}
                                      value={
                                        dynamicTabFormik.values.dynamicTabs[index].data[0]
                                          .customFields[fieldIndex].value || ""
                                      }
                                      onChange={dynamicTabFormik.handleChange}
                                      className="py-1"
                                      endAdornment={
                                        <InputAdornment
                                          position="start"
                                          sx={{ color: "#5C6D8E" }}>
                                          <span className="mr-4">|</span>
                                          mm
                                        </InputAdornment>
                                      }
                                    />
                                  </FormControl>
                                );
                                break;
                              }
                              case "productField": {
                                let value = "";

                                switch (field?.productValue) {
                                  case "title": {
                                    value = productFormik.values.title;
                                    break;
                                  }
                                  case "description": {
                                    value = productFormik.values.description;
                                    break;
                                  }
                                  case "category": {
                                    value = selectedCategory?.name || "";
                                    break;
                                  }
                                  case "subCategory": {
                                    value = selectedSubCategory?.name || "";
                                    break;
                                  }
                                  case "tagManager": {
                                    value = selectedTags[0]?.name || "";
                                    break;
                                  }
                                  case "vendor": {
                                    value = selectedVendors?.name || "";
                                    break;
                                  }
                                  case "collection": {
                                    value = selectedCollection[0]?.title || "";
                                    break;
                                  }
                                  case "SKU": {
                                    value = productFormik?.values?.inventory?.skuId || "";
                                    break;
                                  }

                                  default:
                                    break;
                                }

                                fieldInput = (
                                  <FormControl className="col-7 px-0 backgroundDisable">
                                    <OutlinedInput
                                      size="small"
                                      disabled
                                      value={value}
                                      className="py-1 linked-value"
                                      startAdornment={
                                        <InputAdornment position="start">
                                          <span className="linked-title me-1">{`${field?.productValue}:`}</span>
                                        </InputAdornment>
                                      }
                                    />
                                  </FormControl>
                                );
                                break;
                              }

                              default:
                                break;
                            }
                            return (
                              <div
                                key={field._id}
                                className="d-flex col-12 mt-3">
                                <div className="col-5">
                                  <p className="text-lightBlue">{field.title}</p>
                                  {field.visibility === "hide" ? (
                                    <small className="text-grey-6 mt-2">
                                      <img
                                        src={eye}
                                        alt="closed-eye-symbol"
                                        className="me-1"
                                      />
                                      Customer wont see this
                                    </small>
                                  ) : field?.productValue ? (
                                    <small className="text-grey-6 mt-2">
                                      <InsertLinkIcon className="me-1" />
                                      {`Linked to Product ${field?.productValue}`}
                                    </small>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                                {fieldInput}
                              </div>
                            );
                          })}
                        </div>
                      );
                    }
                    return <div key={tab.tabId}></div>;
                  })}
                </div>
              </div>
            </div>
          )}
          {/* <div className="bg-black-15 border-grey-5 rounded-8 p-3 row productInfo mt-4">
            <div className="d-flex justify-content-between px-0">
              <div className="d-flex mb-3 px-0">
                <h6 className="text-lightBlue px-0 fw-500">Product Applicable For</h6>
                <Tooltip
                  title="Lorem Ipsum"
                  placement="top">
                  <img
                    src={info}
                    alt="info"
                    className="ms-2 c-pointer"
                    width={15}
                  />
                </Tooltip>
              </div>
              <p className="text-blue-2 c-pointer col-auto ms-auto px-0">{/* Manage /}</p>
            </div>
            <FormGroup className="tags-checkbox px-0">
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    name="availableFor.isReturnable"
                    checked={productFormik.values.availableFor.isReturnable}
                    onChange={returnableHandler}
                    style={{
                      color: "#5C6D8E",
                    }}
                  />
                }
                label="Returnable"
                className="me-0"
              />
              {productFormik.values.availableFor.isReturnable && (
                <div className="col-2 mt-2">
                  <div className="d-flex mb-1">
                    <Label required={productFormik.values.availableFor.isReturnable}>
                      Return Period
                    </Label>
                    <Tooltip
                      title="Lorem ipsum"
                      placement="top">
                      <img
                        src={info}
                        alt="info"
                        className="c-pointer"
                        width={13.5}
                      />
                    </Tooltip>
                  </div>

                  <FormControl
                    sx={{ width: "100%" }}
                    className="col-7 px-0">
                    <OutlinedInput
                      type="number"
                      name="availableFor.returnValue"
                      value={productFormik.values.availableFor.returnValue || ""}
                      onChange={productFormik.handleChange}
                      onBlur={productFormik.handleBlur}
                      size="small"
                      endAdornment={
                        <InputAdornment position="end">
                          <p className="text-lightBlue">days</p>
                        </InputAdornment>
                      }
                    />
                    {!!productFormik.touched.availableFor?.returnValue &&
                      productFormik.errors.availableFor?.returnValue && (
                        <FormHelperText error>
                          {productFormik.errors.availableFor?.returnValue}
                        </FormHelperText>
                      )}
                  </FormControl>
                </div>
              )}
              <FormControlLabel
                control={
                  <Checkbox
                    name="availableFor.isCod"
                    checked={productFormik.values.availableFor.isCod}
                    onChange={productFormik.handleChange}
                    size="small"
                    style={{
                      color: "#5C6D8E",
                    }}
                  />
                }
                label="COD Available"
                className="me-0"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    name="availableFor.isLifeTimeExchange"
                    checked={productFormik.values.availableFor.isLifeTimeExchange}
                    onChange={productFormik.handleChange}
                    style={{
                      color: "#5C6D8E",
                    }}
                  />
                }
                label="Lifetime Exchange"
                className="me-0"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    name="availableFor.isLifeTimeBuyBack"
                    checked={productFormik.values.availableFor.isLifeTimeBuyBack}
                    onChange={productFormik.handleChange}
                    style={{
                      color: "#5C6D8E",
                    }}
                  />
                }
                label="Lifetime Buyback"
                className="me-0"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    name="availableFor.isNextDayShipping"
                    checked={productFormik.values.availableFor.isNextDayShipping}
                    onChange={productFormik.handleChange}
                    style={{
                      color: "#5C6D8E",
                    }}
                  />
                }
                label="Next Day Shipping"
                className="me-0"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    name="availableFor.isTryOn"
                    checked={productFormik.values.availableFor.isTryOn}
                    onChange={productFormik.handleChange}
                    style={{
                      color: "#5C6D8E",
                    }}
                  />
                }
                label="Enable Try On"
                className="me-0"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    name="availableFor.isViewSimilarItem"
                    checked={productFormik.values.availableFor.isViewSimilarItem}
                    onChange={productFormik.handleChange}
                    style={{
                      color: "#5C6D8E",
                    }}
                  />
                }
                label="Enable to View Similar Items"
                className="me-0"
              />
            </FormGroup>
          </div> */}
          <div className="bg-black-15 border-grey-5 rounded-8 p-3 row shipping mt-4">
            <div className="d-flex col-12 px-0 justify-content-between">
              <div className="d-flex align-items-center">
                <h6 className="text-lightBlue me-auto text-lightBlue fw-500">Shipping</h6>
              </div>
              {/* <p className="text-blue-2">Settings</p> */}
            </div>

            {/* <div className="col-12">
          <ToggleButtonGroup
            value={productType}
            onChange={handleProductType}
            aria-label="text formatting"
            className="row d-flex mt-4 toggle"
            size="small"
            exclusive
          >
            <ToggleButton
              value="physical"
              aria-label="physical"
              style={{ width: 200 }}
            >
              <div className="d-flex">
                <p className="text-grey-6">Physical Product</p>
              </div>
            </ToggleButton>
            <ToggleButton
              value="digital"
              aria-label="digital"
              style={{ width: 200 }}
            >
              <div className="d-flex">
                <p className="text-grey-6">Digital Product</p>
              </div>
            </ToggleButton>
          </ToggleButtonGroup>
        </div> */}
            <hr className="hr-grey-6 mt-4 mb-3 col-12" />
            {productType === "physical" && (
              <>
                <p className="text-lightBlue col-12 px-0">
                  Enter Product's Weight and Dimensions
                </p>
                <small className="text-grey-6 mt-1 col-12 px-0">
                  Change Units in&nbsp;
                  <span className="text-blue-2">Regional settings</span>
                </small>
                <div className="d-flex my-3 align-items-center shipping-inputs col-12 px-0">
                  <FormControl className="me-5">
                    <OutlinedInput
                      placeholder="Enter Weight"
                      size="small"
                      endAdornment={<InputAdornment position="end">kg</InputAdornment>}
                      type="number"
                      name="shipping.weight"
                      value={productFormik.values.shipping.weight}
                      onChange={productFormik.handleChange}
                      onBlur={productFormik.handleBlur}
                    />
                    {!!productFormik.touched.weight && productFormik.errors.weight && (
                      <FormHelperText error>{productFormik.errors.weight}</FormHelperText>
                    )}
                  </FormControl>
                  <FormControl className="">
                    <OutlinedInput
                      placeholder="Enter Length"
                      size="small"
                      endAdornment={<InputAdornment position="end">cm</InputAdornment>}
                      type="number"
                      name="shipping.length"
                      value={productFormik.values.shipping.length}
                      onChange={productFormik.handleChange}
                      onBlur={productFormik.handleBlur}
                    />
                    {!!productFormik.touched.length && productFormik.errors.length && (
                      <FormHelperText error>{productFormik.errors.length}</FormHelperText>
                    )}
                  </FormControl>
                  <p className="text-lightBlue mx-2">x</p>
                  <FormControl className="">
                    <OutlinedInput
                      placeholder="Enter Height"
                      size="small"
                      endAdornment={<InputAdornment position="end">cm</InputAdornment>}
                      type="number"
                      name="shipping.height"
                      value={productFormik.values.shipping.height}
                      onChange={productFormik.handleChange}
                      onBlur={productFormik.handleBlur}
                    />
                    {!!productFormik.touched.height && productFormik.errors.height && (
                      <FormHelperText error>{productFormik.errors.height}</FormHelperText>
                    )}
                  </FormControl>
                  <p className="text-lightBlue mx-2">x</p>
                  <FormControl className="">
                    <OutlinedInput
                      placeholder="Enter Width"
                      size="small"
                      endAdornment={<InputAdornment position="end">cm</InputAdornment>}
                      name="shipping.width"
                      type="number"
                      value={productFormik.values.shipping.width}
                      onChange={productFormik.handleChange}
                      onBlur={productFormik.handleBlur}
                    />
                    {!!productFormik.touched.width && productFormik.errors.width && (
                      <FormHelperText error>{productFormik.errors.width}</FormHelperText>
                    )}
                  </FormControl>
                </div>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="shipping.isAbroadAvailable"
                      checked={productFormik.values.shipping.isAbroadAvailable}
                      onChange={productFormik.handleChange}
                      inputProps={{ "aria-label": "controlled" }}
                      size="small"
                      style={{
                        color: "#5C6D8E",
                        marginRight: 0,
                      }}
                    />
                  }
                  label="Is this item available for shipping abroad?"
                  sx={{
                    "& .MuiTypography-root": {
                      fontSize: 13,
                      color: "#c8d8ff",
                    },
                  }}
                  className=" col-12 px-0 my-3"
                />
                {productFormik.values.shipping.isAbroadAvailable && (
                  <>
                    <div className="col-12 px-0">
                      <p className="text-lightBlue">Custom Information</p>
                      <small className="text-grey-6 mt-2 w-50 d-block">
                        Customs authorities use this information to calculate duties when
                        shipping internationally. Shown on printed customs forms.
                      </small>
                    </div>
                    <div className="col-12 px-0 mt-4">
                      <p className="text-lightBlue mb-2">Country/Region of Origin</p>
                      <AppGenericSelect
                        sx={{ width: 300 }}
                        name="shipping.country"
                        value={productFormik.values.shipping.country}
                        dataId="_id"
                        dataLabel="name"
                        onBlur={() => productFormik.setFieldTouched("shipping.country", true)}
                        onChange={(_, co) =>
                          productFormik.setFieldValue("shipping.country", co?._id ?? "")
                        }
                        useGetQuery={useGetAllCountryQuery}
                        getQueryFilters={{ createdAt: -1 }}
                      />
                      <small className="text-grey-6 mt-1">
                        ⓘ Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      </small>
                      {!!productFormik.touched.country && productFormik.errors.country && (
                        <FormHelperText error>{productFormik.errors.country}</FormHelperText>
                      )}
                    </div>
                    <div className="col-12 px-0 mt-4 mb-3">
                      <p className="text-lightBlue mb-2">HS (Harmonized System) code</p>
                      <FormControl
                        className="px-0"
                        sx={{ width: 300 }}>
                        <OutlinedInput
                          placeholder="Enter HS Code"
                          size="small"
                          name="shipping.hsCode"
                          value={productFormik.values.shipping.hsCode}
                          onChange={productFormik.handleChange}
                          onBlur={productFormik.handleBlur}
                        />
                        {!!productFormik.touched.hsCode && productFormik.errors.hsCode && (
                          <FormHelperText error>{productFormik.errors.hsCode}</FormHelperText>
                        )}
                      </FormControl>
                      <small className="text-grey-6 mt-1 d-block">
                        ⓘ Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      </small>
                    </div>
                  </>
                )}
              </>
            )}
            {productType === "digital" && (
              <p className="text-lightBlue">
                No Shipping Details and Weight will be assign to Digital Products,&nbsp;
                <span className="text-blue-2">Learn More about Digital Products</span>
              </p>
            )}
          </div>
          <div className="bg-black-15 border-grey-5 rounded-8 p-3 row seo mt-4">
            <div className="d-flex col-12 px-0 justifu-content-between">
              <div className="d-flex align-items-center me-auto">
                <h6 className="text-lightBlue me-auto fw-500">Search Engine Optimisation</h6>
                <Tooltip
                  title="Lorem ipsum"
                  placement="top">
                  <img
                    src={info}
                    alt="info"
                    className="c-pointer ms-2"
                    width={13.5}
                  />
                </Tooltip>
              </div>
            </div>
            <div className="col-12 px-0 bg-black-13 border-grey-5 mt-3 rounded-8">
              <div className="d-flex flex-column p-3">
                <div className="d-flex justify-content-between">
                  <p className="text-lightBlue">Metadata Preview</p>
                  <small
                    className="text-lightBlue text-blue-2 c-pointer"
                    onClick={() => {
                      setViewAll((prevState) => !prevState);
                    }}>
                    {viewAll ? "Collapse" : "Edit"}
                  </small>
                </div>
                <small className="text-lightBlue mt-3 mb-2 fw-500">
                  Title : {productFormik?.values?.seo?.title}
                </small>
                <small
                  className="text-lightBlue mt-1 mb-2"
                  style={{ wordWrap: "break-word" }}>
                  Description :{" "}
                  {productFormik?.values?.seo?.description
                    ? productFormik?.values?.seo?.description
                    : ""}
                </small>
                {/* <small
                  className="text-blue-2"
                  style={{ wordWrap: "break-word" }}>
                  Url Handle : {formik?.values?.seo?.urlHandle}
                </small> */}
                <small className="mt-2 text-grey-6">
                  {productFormik?.values?.seo?.multipleMetaTags &&
                    productFormik?.values?.seo?.multipleMetaTags?.join(", ")}
                </small>
              </div>
            </div>

            {viewAll && (
              <>
                <div className="d-flex mb-1 mt-3 col-12 px-0">
                  <small className="text-lightBlue">Page Title</small>
                  <small
                    style={{
                      color: "#F67476",
                      paddingRight: 4,
                      paddingLeft: 2,
                    }}>
                    *
                  </small>
                </div>
                <FormControl className="col-12 px-0">
                  <OutlinedInput
                    name="seo.title"
                    placeholder="Please enter page title"
                    size="small"
                    value={productFormik?.values?.seo?.title}
                    onChange={productFormik.handleChange}
                    onBlur={productFormik.handleBlur}
                  />
                </FormControl>
                {!!productFormik?.touched?.seo?.title && productFormik?.errors?.seo?.title ? (
                  <FormHelperText
                    error
                    className="px-0">
                    {productFormik?.errors?.seo?.title}
                  </FormHelperText>
                ) : (
                  <small className="mt-1 text-grey-6 col-12 px-0">
                    {productFormik?.values?.seo?.title?.length} of 50 characters Used
                  </small>
                )}
                <small className="text-lightBlue mb-2 mt-3 col-12 px-0">Meta Description</small>
                <TextareaAutosize
                  name="seo.description"
                  aria-label="meta description"
                  placeholder="Please enter meta description"
                  style={{
                    background: "#15142A",
                    color: "#C8D8FF",
                    borderRadius: 5,
                  }}
                  minRows={5}
                  value={productFormik?.values?.seo?.description}
                  onChange={productFormik.handleChange}
                  onBlur={productFormik.handleBlur}
                  className="col-12"
                />
                {!!productFormik?.touched?.seo?.description &&
                productFormik?.errors?.seo?.description ? (
                  <FormHelperText
                    error
                    className="px-0">
                    {productFormik?.errors?.seo?.description}
                  </FormHelperText>
                ) : (
                  <small className="mt-1 text-grey-6 col-12 px-0">
                    {productFormik?.values?.seo?.description?.length} of 500 characters Used
                  </small>
                )}
                <small className="text-lightBlue mb-2 mt-3 col-12 px-0">Meta Keywords</small>
                <FormControl className="col-12 px-0">
                  <OutlinedInput
                    name="seo.metaKeywords"
                    value={productFormik?.values?.seo?.metaKeywords}
                    onChange={productFormik.handleChange}
                    onBlur={productFormik.handleBlur}
                    onKeyDown={handleAddMultiple}
                    placeholder="Enter keywords"
                    size="small"
                    endAdornment={
                      <InputAdornment position="end">
                        <Tooltip
                          title="Create Multiple Meta Tags"
                          placement="top">
                          <ChevronRightIcon
                            className="c-pointer"
                            onClick={handleAddMultiple}
                          />
                        </Tooltip>
                      </InputAdornment>
                    }
                  />
                  <div
                    className="d-flex flex-wrap"
                    style={{ wordWrap: "break-word" }}>
                    {productFormik?.values?.seo?.multipleMetaTags &&
                      productFormik?.values?.seo?.multipleMetaTags.map((data, index) => {
                        return (
                          <Chip
                            key={index}
                            label={data}
                            onDelete={() => handleDelete(data)}
                            onClick={() => {}}
                            size="small"
                            className="mt-3 me-2"></Chip>
                        );
                      })}
                  </div>
                </FormControl>
              </>
            )}
          </div>
          <SaveFooterTertiary
            show={
              id
                ? productsIsSuccess && dynamicTabsIsSuccess && productsTabIsSuccess
                  ? !isCreateMode
                    ? !_.isEqual(productFormik.values, productFormik.initialValues) ||
                      !_.isEqual(dynamicTabFormik.values, dynamicTabFormik.initialValues)
                    : true
                  : false
                : true
            }
            onDiscard={backHandler}
            isLoading={productState.isLoading}
            saveButtonName={"Next"}
            showSecondarySave={!!id && !isCreateMode}
            secondarySaveButtonName="Save"
            onSecondarySave={saveFormHandler}
            onSave={nextFormHandler}
            onSaveType="button"
          />
        </form>
      )}
      <DiscardModalSecondary
        when={
          productState.createdSuccess
            ? false
            : !_.isEqual(productFormik.values, productFormik.initialValues)
        }
        message="product"
      />
    </>
  );
};

export default ProductInfo;
