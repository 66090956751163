import React, { useCallback, useEffect, useReducer, useState } from "react";
import "../../EditVendor/EditVendor.scss";
import {
  Link,
  createSearchParams,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import * as Yup from "yup";

// ! COMPONENT IMPORTS
import AppTextEditor from "../../../../components/AppTextEditor/AppTextEditor";
import NotesBox from "../../../../components/NotesBox/NotesBox";
import StatusBox from "../../../../components/StatusBox/StatusBox";
import AddCategoriesProducts from "../../../../components/AddCategoriesProducts/AddCategoriesProducts";
import AddSubCategoriesProducts from "../../../../components/AddSubCategoriesProduct/AddSubCategoriesProduct";
import TabPanel from "../../../../components/TabPanel/TabPanel";
import UploadMediaBox from "../../../../components/UploadMediaBox/UploadMediaBox";
import SEO from "../../../Products/AddProduct/SEO/SEO";
import SaveFooter, { SaveFooterTertiary } from "../../../../components/SaveFooter/SaveFooter";
import { DiscardModalSecondary } from "../../../../components/Discard/DiscardModal";
import AddHeader from "../../../../components/AddHeader/AddHeader";
// ! IMAGES IMPORTS
import info from "../../../../assets/icons/info.svg";
import addMedia from "../../../../assets/icons/addMedia.svg";
import _ from "lodash";
// ! MATERIAL IMPORTS
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  OutlinedInput,
  Paper,
  Popover,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  FormHelperText,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  useCreateCategoryMutation,
  useEditCategoryMutation,
  useGetAllCategoriesQuery,
} from "../../../../features/parameters/categories/categoriesApiSlice";
import { showError, showSuccess } from "../../../../features/snackbar/snackbarAction";
import { useFormik } from "formik";
import InfoHeader from "../../../../components/Header/InfoHeader";
import SEOSecondary from "../../../Products/AddProduct/SEO/SEOSecondary";
import { omitEmptyKeys } from "../../../../utils/helper";
import { useFormikToastError } from "../../../../hooks/error";
import { SNACKBAR_DURATION } from "../../../../utils/defaults";
import AddManualProducts from "../../../../components/AddProducts/AddManualProducts";

let isThrottle1 = false;

const initialState = {
  confirmationMessage: "",
  isEditing: false,
  initialInfo: null,
  isSeoEditDone: false,
};

const initialQueryFilterState = {
  pageSize: 1,
  pageNo: null,
  totalCount: 0,
};

const categoryReducer = (state, action) => {
  if (action.type === "REMOVE_DELETE") {
    return {
      ...initialState,
    };
  }
  if (action.type === "ENABLE_EDIT") {
    return {
      ...initialState,
      isEditing: true,
    };
  }
  if (action.type === "DISABLE_EDIT") {
    return {
      ...initialState,
      isEditing: false,
    };
  }
  if (action.type === "DISABLE_SEO") {
    return {
      ...initialState,
      isSeoEditDone: false,
    };
  }

  return initialState;
};

const queryFilterReducer = (state, action) => {
  if (action.type === "SET_PAGE_NO") {
    return {
      ...state,
      pageNo: +action.pageNo,
    };
  }
  if (action.type === "SET_TOTAL_COUNT") {
    return {
      ...state,
      totalCount: action.totalCount,
    };
  }
  return initialQueryFilterState;
};

function isEmpty(obj) {
  for (var prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      return false;
    }
  }

  return true;
}
const seoValidationSchema = Yup.object().shape({
  title: Yup.string()
    .required("Title is required")
    .max(50, "Title cannot exceed 50 characters"),
  description: Yup.string().max(500, "Description cannot exceed 500 characters"),
  // urlHandle: Yup.string()
  //   .matches(/^\S*$/, "URL handle cannot contain white spaces")
  //   .test("is-url-handle", "URL handle is required", (urlHandle) => {
  //     if (!urlHandle) {
  //       return false;
  //     }
  //     const parts = urlHandle.split("/");
  //     const result = parts.pop();
  //     return !!result;
  //   })
  //   .required("URL handle is required"),
});

const categoriesValidationSchema = Yup.object({
  name: Yup.string().trim().min(3).max(50).required("Required"),
  seo: seoValidationSchema,
});

const EditCategories = () => {
  const [categoryType, setCategoryType] = React.useState(0);
  const [isThrottle, setIsThrottle] = useState(false);
  const [viewAll, setViewAll] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let { id, filter } = useParams();
  const [categoryState, dispatchCategory] = useReducer(categoryReducer, initialState);
  const [queryFilterState, dispatchQueryFilter] = useReducer(
    queryFilterReducer,
    initialQueryFilterState
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const [decodedObject, setDecodedObject] = useState(null);

  const {
    data: categoriesData,
    isLoading: categoriesIsLoading,
    isError: categoriesIsError,
    isSuccess: categoriesIsSuccess,
    error: categoriesError,
  } = useGetAllCategoriesQuery({ srNo: id, ...decodedObject, pageNo: 0 });

  const [
    editCategory,
    {
      data: editData,
      isLoading: editCategoryIsLoading,
      isSuccess: editCategoryIsSuccess,
      error: editCategoryError,
      isError: editCategoryIsError,
    },
  ] = useEditCategoryMutation();

  const categoryEditFormik = useFormik({
    initialValues: {
      name: categoriesData?.data?.data?.[0]?.name || "",
      description: categoriesData?.data?.data?.[0]?.description || "<p></p>",
      status: categoriesData?.data?.data?.[0]?.status,
      notes: categoriesData?.data?.data?.[0]?.notes,
      showFilter: categoriesData?.data?.data?.[0]?.showFilter || false,
      startDate: categoriesData?.data?.data?.[0]?.startDate || null,
      endDate: categoriesData?.data?.data?.[0]?.endDate || null,
      products: categoriesData?.data?.data?.[0]?.products || [],
      mediaUrl: categoriesData?.data?.data?.[0]?.mediaUrl || "",
      // seo: categoriesData?.data?.data?.[0]?.seos || {},
      seo: {
        title: categoriesData?.data?.data?.[0]?.seos?.title || "",
        description: categoriesData?.data?.data?.[0]?.seos?.description || "",
        urlHandle: categoriesData?.data?.data?.[0]?.seos?.slug || "",
        metaKeywords: "",
        multipleMetaTags: categoriesData?.data?.data?.[0]?.seos?.metaKeywords || [],
        isEditedUrlHandle: false,
        isEditedTitleHandle: false,
      },
    },
    enableReinitialize: true,
    validationSchema: categoriesValidationSchema,
    onSubmit: (values) => {
      const categoryValues = structuredClone(values);

      categoryValues.name =
        categoryEditFormik.values.name !== categoryEditFormik.initialValues.name
          ? categoryValues.name
          : "";
      categoryValues.status =
        categoryValues.startDate === null ? categoryValues.status : "scheduled";
      categoryValues.seo.metaKeywords = categoryValues?.seo?.multipleMetaTags;
      categoryValues.seo.slug = categoryValues?.seo?.urlHandle;
      categoryValues.startDate = categoryValues.startDate
        ? new Date(categoryValues.startDate)
        : "";
      categoryValues.endDate = categoryValues.endDate ? new Date(categoryValues.endDate) : "";
      const editObj = omitEmptyKeys(categoryValues, ["mediaUrl"]);
      editObj.seo = omitEmptyKeys(categoryValues.seo);

      console.log("editObj", editObj);

      editCategory({
        id: categoriesData?.data?.data?.[0]?._id, // ID of the category
        details: editObj,
      })
        .unwrap()
        .then(() => {
          dispatch(showSuccess({ message: "Category Updated Successfully" }));
          setViewAll(false);
          dispatchCategory({ type: "DISABLE_SEO" });
        });
    },
  });

  useEffect(() => {
    const encodedString = searchParams.get("filter"); // The encoded string from the URL or any source

    const decodedString = decodeURIComponent(encodedString);
    const parsedObject = JSON.parse(decodedString);
    setDecodedObject(parsedObject);
  }, [searchParams]);

  const clearDate = () => {
    categoryEditFormik.setFieldValue("startDate", null);
    categoryEditFormik.setFieldValue("endDate", null);
  };

  // const submitHandler = (e) => {
  //   e.preventDefault();
  //   categoryEditFormik.handleSubmit();
  // };

  const backHandler = () => {
    navigate(
      `/parameters/categories?filter=${JSON.stringify({
        categoryType: 0,
        status: decodedObject?.status,
      })}`
    );
  };

  const nextPageHandler = () => {
    const { pageNo } = queryFilterState;
    if (categoriesData?.data?.nextCount === 0) {
      return;
    }
    decodedObject.order = 1;
    navigate({
      pathname: `/parameters/categories/edit/${pageNo}`,
      search: `?${createSearchParams({
        filter: JSON.stringify(decodedObject),
      })}`,
    });
  };

  const prevPageHandler = () => {
    const { pageNo } = queryFilterState;
    if (categoriesData?.data?.prevCount === 0) {
      return;
    }
    decodedObject.order = -1;
    navigate({
      pathname: `/parameters/categories/edit/${pageNo}`,
      search: `?${createSearchParams({
        filter: JSON.stringify(decodedObject),
      })}`,
    });
  };

  useEffect(() => {
    if (categoriesData?.data?.data?.[0]?.srNo) {
      dispatchQueryFilter({
        type: "SET_PAGE_NO",
        pageNo: categoriesData?.data?.data?.[0]?.srNo,
      });
    }
  }, [categoriesData]);

  useEffect(() => {
    if (categoriesError) {
      if (categoriesError?.data?.message) {
        dispatch(showError({ message: categoriesError.data.message }));
      } else {
        dispatch(showError({ message: "Something went wrong!, please try again" }));
      }
    }
    if (categoriesIsSuccess) {
      dispatchQueryFilter({
        type: "SET_TOTAL_COUNT",
        totalCount: categoriesData?.data?.totalCount,
      });
    }
  }, [categoriesData, categoriesError, categoriesIsError, categoriesIsSuccess, dispatch]);

  useEffect(() => {
    if (editCategoryIsError) {
      if (editCategoryError?.data?.message) {
        dispatch(showError({ message: editCategoryError?.data?.message }));
      } else {
        dispatch(showError({ message: "Something went wrong, please try again" }));
      }
    }
  }, [editCategoryError, editCategoryIsError, dispatch]);

  useEffect(() => {
    const formikValuesWithoutSEOIsEdited = {
      ...categoryEditFormik.values,
      seo: {
        title: categoryEditFormik?.values?.seo?.title,
        description: categoryEditFormik?.values?.seo?.description,
        urlHandle: categoryEditFormik?.values?.seo?.urlHandle,
        metaKeywords: categoryEditFormik?.values?.seo?.metaKeywords,
        multipleMetaTags: categoryEditFormik?.values?.seo?.multipleMetaTags,
      },
    };

    const formikInitialValuesWithoutSEOIsEdited = {
      ...categoryEditFormik?.initialValues,
      seo: {
        title: categoryEditFormik?.initialValues?.seo?.title,
        description: categoryEditFormik?.initialValues?.seo?.description,
        urlHandle: categoryEditFormik?.initialValues?.seo?.urlHandle,
        metaKeywords: categoryEditFormik?.initialValues?.seo?.metaKeywords,
        multipleMetaTags: categoryEditFormik?.initialValues?.seo?.multipleMetaTags,
      },
    };
    if (
      id &&
      !_.isEqual(formikValuesWithoutSEOIsEdited, formikInitialValuesWithoutSEOIsEdited)
    ) {
      dispatchCategory({ type: "ENABLE_EDIT" });
    } else if (
      id &&
      _.isEqual(formikValuesWithoutSEOIsEdited, formikInitialValuesWithoutSEOIsEdited)
    ) {
      dispatchCategory({ type: "DISABLE_EDIT" });
    }
  }, [categoryEditFormik.initialValues, categoryEditFormik.values, id]);

  const changeCategoryTypeHandler = (event, tabIndex) => {
    setCategoryType(tabIndex);
    if (tabIndex === 0) {
    }
    if (tabIndex === 1) {
    }
  };

  const uploadMediaHandler = (url) => {
    categoryEditFormik.setFieldValue("mediaUrl", url);
  };
  const setDelete = (saveImage) => {
    categoryEditFormik.setFieldValue("unlinkMedia", saveImage);
  };

  const removeWhiteSpace = (value) => {
    return value?.toLowerCase().replace(/\s+/g, "-");
  };
  const handleTitleChange = (e) => {
    categoryEditFormik.handleChange(e);
    !categoryEditFormik?.values?.seo?.isEditedTitleHandle &&
      categoryEditFormik.setFieldValue("seo.title", e.target.value);
    // !categoryEditFormik?.values?.seo?.isEditedUrlHandle &&
    //   categoryEditFormik.setFieldValue(
    //     "seo.urlHandle",
    //     removeWhiteSpace(e.target.value)
    //   );
  };

  const onKeyDown = (keyEvent) => {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  };
  useFormikToastError(categoryEditFormik);

  const updateProducts = useCallback(
    (products) => categoryEditFormik.setFieldValue("products", products),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div className="page container-fluid position-relative user-group">
      <InfoHeader
        title={categoryEditFormik.values.name || "Edit category"}
        onBack={backHandler}
        onPreview={
          !id
            ? ""
            : `${process.env.REACT_APP_FE_BASE_URL}/products?categoryId=${categoriesData?.data?.data?.[0]?._id}`
        }
        onPrev={prevPageHandler}
        hasNext={categoriesData?.data?.nextCount}
        hasPrev={categoriesData?.data?.prevCount}
        onNext={nextPageHandler}
        isEdit={!!id}
      />
      <form
        noValidate
        onSubmit={categoryEditFormik.handleSubmit}
        onKeyDown={onKeyDown}
        className="row mt-3">
        <div className="col-lg-9 mt-3">
          <div className="bg-black-15 border-grey-5 rounded-8 p-3 row attributes">
            <div className="col-md-12 px-0">
              <div className="d-flex mb-1">
                <p className="text-lightBlue">Category Name</p>
                <span style={{ color: "#F67476", paddingRight: 4 }}>*</span>
                <Tooltip
                  title="Lorem ipsum"
                  placement="top">
                  <img
                    src={info}
                    alt="info"
                    className=" c-pointer"
                    width={13.5}
                  />
                </Tooltip>
              </div>
              <FormControl className="w-100 px-0">
                <OutlinedInput
                  name="name"
                  value={categoryEditFormik.values.name}
                  onChange={handleTitleChange}
                  placeholder="Category Name"
                  size="small"
                  autoFocus={true}
                />
              </FormControl>
              {!!categoryEditFormik.touched.name && categoryEditFormik.errors.name && (
                <FormHelperText error>{categoryEditFormik.errors.name}</FormHelperText>
              )}
            </div>
            <FormGroup>
              <div className="d-flex align-items-center col-12 px-0">
                <FormControlLabel
                  control={
                    <Checkbox
                      name="showFilter"
                      inputProps={{ "aria-label": "controlled" }}
                      checked={categoryEditFormik.values.showFilter}
                      onChange={categoryEditFormik.handleChange}
                      size="small"
                      style={{
                        color: "#5C6D8E",
                        marginRight: 0,
                      }}
                    />
                  }
                  label="Include in Filters"
                  sx={{
                    "& .MuiTypography-root": {
                      fontSize: 13,
                      color: "#c8d8ff",
                    },
                  }}
                />
                {/* <span className="text-blue-2 c-pointer">(manage)</span> */}
              </div>
            </FormGroup>
            <div className="col-12 mt-3 px-0">
              <div className="d-flex  mb-1">
                <p className="text-lightBlue me-2">Description</p>
                <Tooltip
                  title="Lorem ipsum"
                  placement="top">
                  <img
                    src={info}
                    alt="info"
                    className=" c-pointer"
                    width={13.5}
                  />
                </Tooltip>
              </div>
              <AppTextEditor
                value={categoryEditFormik.values.description}
                setFieldValue={(val) => {
                  if (val === "") {
                    categoryEditFormik.setFieldValue("description", "<p></p>");
                    return;
                  }
                  categoryEditFormik.setFieldValue("description", val);
                }}
              />
            </div>
          </div>

          <div className="border-grey-5 rounded-8 p-3 row bg-black-15 mt-4">
            <Box
              sx={{ width: "100%" }}
              className="d-flex justify-content-between tabs-header-box">
              <Tabs
                value={categoryType}
                onChange={changeCategoryTypeHandler}
                aria-label="scrollable force tabs example"
                className="tabs">
                <Tab
                  label="All Products"
                  className="tabs-head"
                />
                <Tab
                  label="Sub Categories"
                  className="tabs-head"
                />
              </Tabs>
            </Box>
            <div className="d-flex justify-content-between mb-2 px-0"></div>
            <TabPanel
              value={categoryType}
              index={0}>
              <AddManualProducts
                filter={{ categoryId: categoriesData?.data?.data?.[0]?._id ?? "" }}
                productIds={categoryEditFormik.values.products}
                onProductsChange={updateProducts}
              />
              {/* <AddCategoriesProducts /> */}
            </TabPanel>
            <TabPanel
              value={categoryType}
              index={1}>
              <AddSubCategoriesProducts
                id={id ? categoriesData?.data?.data?.[0]?._id : ""}
                name={id ? categoriesData?.data?.data?.[0]?.name : ""}
                e
              />
            </TabPanel>
          </div>
          <div className="mt-4">
            {/* <SEO
              seoName={categoryEditFormik.values.name || ""}
              seoValue={categoryEditFormik.values.seo}
              handleSeoChange={(val) =>
                categoryEditFormik.setFieldValue("seo", val)
              }
              refrenceId={id ? categoriesData?.data?.data?.[0]?._id : ""}
            /> */}
            <SEOSecondary
              formik={categoryEditFormik}
              viewAll={viewAll}
              setViewAll={setViewAll}
            />
          </div>
        </div>
        <div className="col-lg-3 mt-3 pe-0 ps-0 ps-lg-3">
          <StatusBox
            headingName={"Category Status"}
            value={categoryEditFormik.values.status}
            handleProductStatus={(_, val) => {
              if (val !== null) {
                categoryEditFormik.setFieldValue("status", val);
              }
            }}
            toggleData={["active", "in-active"]}
            showSchedule={true}
            startDate={categoryEditFormik.values.startDate}
            endDate={categoryEditFormik.values.endDate}
            handleStartDate={(val) => categoryEditFormik.setFieldValue("startDate", val)}
            handleEndDate={(val) => categoryEditFormik.setFieldValue("endDate", val)}
            clearDate={clearDate}
          />
          {/* <VisibilityBox value={categoryVisibility}
            onChange={(_, val) => setCategoryVisibility(val)}
            
          /> */}
          <div className="mt-4">
            <UploadMediaBox
              imageName={addMedia}
              headingName={"Media"}
              UploadChange={(url) => categoryEditFormik.setFieldValue("mediaUrl", url)}
              previousImage={categoryEditFormik.values.mediaUrl}
              isUploaded={() => {}}
              // setDelete={setDelete}
            />
            {/* <UploadMediaBox
              imageName={addMedia}
              headingName={"Media"}
              UploadChange={uploadMediaHandler}
              previousImage={categoryEditFormik.values.mediaUrl}
              module="categories"
              setDelete={setDelete}
            /> */}
          </div>
          <NotesBox
            name={"notes"}
            value={categoryEditFormik.values.notes}
            onChange={categoryEditFormik.handleChange}
          />
        </div>
        <SaveFooterTertiary
          show={id ? categoryState.isEditing : true}
          onDiscard={backHandler}
          isLoading={editCategoryIsLoading}
        />
      </form>
      <DiscardModalSecondary
        when={!_.isEqual(categoryEditFormik.values, categoryEditFormik.initialValues)}
        message="Category"
      />
    </div>
  );
};

export default EditCategories;
