import { useState } from "react";
import PopUpBox from "../../../components/PopUp/PopUpBox";
import EnterPass from "./EnterPass";

import contact from "../../../assets/setting/icons/contact.png"
import email from "../../../assets/setting/icons/email.svg"
import phone from "../../../assets/setting/icons/phone.svg"

const ContactSupport = ({ onConfirm, show }) => {

    return (
        <PopUpBox
            show={show}
            onConfirm={onConfirm}
            image={contact}
        >
            <div className="d-flex justify-content-center">
                    <h4 className="text-lightBlue fw-600 me-2">
                        To close profile contact support 
                    </h4>
                </div>
                <div className="d-flex justify-content-center px-3">
                    <small className="text-grey-6 mt-2 d-block py-4 px-5">
                        To close your profile, please contact our support team. They will guide you through the process and may ask you to verify your identity for security purposes. Once closed, all personal information will be permanently removed from our platform.
                        Thank you for understanding.
                    </small>
                </div>
                <div className="d-flex justify-content-center px-3 mb-4">
                    <div className="mr-2">
                        <img src={phone} width={16}/>
                        <small className="text-lightBlue mr-1">
                            +91 9838837242
                        </small>
                    </div>
                    <div>
                        <img src={email} width={16}/>
                        <small className="text-lightBlue mr-1">
                            email@unthread.com
                        </small>
                    </div>
                </div>
                <div className="d-flex justify-content-center mb-3">
                    <div className="button-gradient py-2 px-5 ms-3" onClick={onConfirm}>
                        <p>Chat with Team</p>
                    </div>
                </div>
        </PopUpBox>
    );
};

export default ContactSupport;