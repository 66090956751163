import { useEffect, useState } from "react";
import "./CreateReview.scss";
import { Link, useNavigate } from "react-router-dom";
import AppTextEditor from "../../../components/AppTextEditor/AppTextEditor";
import StatusBox from "../../../components/StatusBox/StatusBox";
import arrowLeft from "../../../assets/icons/arrowLeft.svg";
import info from "../../../assets/icons/info.svg";
import {
  FormControl,
  OutlinedInput,
  Tooltip,
  Rating,
  FormHelperText,
  Select,
  MenuItem,
} from "@mui/material";
import { useFormik } from "formik";
import { DiscardModalSecondary } from "../../../components/Discard/DiscardModal";
import { LoadingButton } from "@mui/lab";
import * as Yup from "yup";
import _ from "lodash";
import { useGetAllOrdersQuery } from "../../../features/orders/ordersApiSlice";
import AppGenericSelect from "../../../components/AppGenericSelect/AppGenericSelect";
import { useCreateReviewMutation } from "../../../features/products/reviews/reviewsApiSlice";
import { useDispatch } from "react-redux";
import { showError, showSuccess } from "../../../features/snackbar/snackbarAction";
import TagsBoxBox from "../../../components/TagsBox/TagsBoxBox";
import { UploadMultipleMediaLarge } from "../../../components/UploadMediaBox/UploadMedia";

const reviewInitialValue = {
  order: "",
  product: "",
  productTitle: "",
  customer: "",
  customerName: "",
  customerFrotnendName: "",
  star: 3,
  description: "",
  status: "approved",
  tags: [],
  imageUrl: [],
  state: "created",
};

const reviewValidationSchema = Yup.object({
  order: Yup.string().trim().required("required"),
  product: Yup.string().trim().required("required"),
  productName: Yup.string().trim(),
  customer: Yup.string().trim().required("required"),
  customerName: Yup.string().trim(),
  customerFrotnendName: Yup.string().trim().required("required"),
  star: Yup.number().integer().min(1).max(5).required("required"),
  description: Yup.string()
    .trim()
    .min(8, "Too short")
    .max(256, "Too long")
    .required("required"),
  status: Yup.string().oneOf(["approved", "pending"]),
  tags: Yup.array().of(Yup.string().trim().required("required")),
});

const CreateReview = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [productSelection, setProductSelection] = useState([]);

  const [createReview, { isLoading: createReviewIsLoading }] = useCreateReviewMutation();

  const formik = useFormik({
    initialValues: reviewInitialValue,
    validationSchema: reviewValidationSchema,
    onSubmit: (values, { resetForm }) => {
      createReview(values)
        .unwrap()
        .then(() => {
          dispatch(showSuccess({ message: "Review created successfully" }));
          if (addAnother) {
            setAddAnother(false);
            resetForm();
          } else {
            onDiscardClick();
          }
        })
        .catch((e) =>
          dispatch(showError({ message: e?.data?.message ?? "Something went wrong" }))
        );
    },
  });

  // console.log(formik.errors);

  const [discard, setDiscard] = useState(false);
  useEffect(() => {
    const check = _.isEqual(formik.values, formik.initialValues);
    setDiscard(!check);
  }, [formik.initialValues, formik.values]);

  const [addAnother, setAddAnother] = useState(false);
  const handleSetAddAnother = (another) => {
    setAddAnother(another);
    setDiscard(false);
  };

  const onDiscardClick = () => navigate(-1);

  const onOrderChange = (_, order) => {
    const { _id = "", customer = {}, items = [] } = order ?? {};
    setProductSelection(items);
    formik.setFieldValue("order", _id);
    if (customer._id) {
      const cname = `${customer.firstName} ${customer.lastName}`;
      formik.setFieldValue("customer", customer._id);
      formik.setFieldValue("customerName", cname);
      formik.setFieldValue("customerFrotnendName", cname);
    }
  };

  return (
    <div className="page container-fluid position-relative user-group">
      <div className="row justify-content-between">
        <div className="d-flex align-items-center w-auto ps-0">
          <Link
            to="/products/reviews"
            className="d-flex">
            <img
              src={arrowLeft}
              alt="arrowLeft"
              width={9}
              className="c-pointer"
            />

            <h5 className="page-heading ms-2 ps-1">Create Review</h5>
          </Link>
        </div>
      </div>
      <form
        noValidate
        onSubmit={formik.handleSubmit}>
        <div className="row mt-3">
          <div className="col-lg-9 mt-3">
            <div className="bg-black-15 border-grey-5 rounded-8 p-3 row attributes">
              <div className="col-12 px-0">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    <h6 className="text-lightBlue fw-500 ">Product and Customer Information</h6>
                  </div>
                </div>
                <div className="d-flex justify-content-center">
                  <hr className="hr-grey-6 w-100 my-3" />
                </div>
              </div>
              <div className="col-md-12 px-0">
                <div className="d-flex mb-1">
                  <p className="text-lightBlue me-2">Order ID</p>
                  <Tooltip
                    title="Lorem ipsum"
                    placement="top">
                    <img
                      src={info}
                      alt="info"
                      className=" c-pointer"
                      width={13.5}
                    />
                  </Tooltip>
                </div>
                <FormControl className="w-100 px-0">
                  <AppGenericSelect
                    name="order"
                    value={formik.values.order}
                    dataId="_id"
                    dataLabel="orderID"
                    onBlur={() => formik.setFieldTouched("order", true)}
                    onChange={onOrderChange}
                    useGetQuery={useGetAllOrdersQuery}
                    getQueryFilters={{
                      createdAt: -1,
                      state: "create",
                      isCustomer: "true",
                      pageNo: 1,
                      pageSize: 1000000000,
                    }}
                  />
                </FormControl>
                <FormHelperText error>
                  {formik.touched.order && formik.errors.order}
                </FormHelperText>
              </div>
              {formik.values.customer && (
                <div className="col-md-12 px-0 mt-3">
                  <div className="d-flex mb-1">
                    <p className="text-lightBlue me-2">Customer Name</p>
                    <Tooltip
                      title="Lorem ipsum"
                      placement="top">
                      <img
                        src={info}
                        alt="info"
                        className=" c-pointer"
                        width={13.5}
                      />
                    </Tooltip>
                  </div>
                  <FormControl className="w-100 px-0">
                    <OutlinedInput
                      size="small"
                      value={formik.values.customerName}
                      onChange={(e) => e}
                      disabled
                      readOnly
                    />
                  </FormControl>
                </div>
              )}
              {formik.values.customerFrotnendName && (
                <div className="col-md-12 px-0 mt-3">
                  <div className="d-flex mb-1">
                    <p className="text-lightBlue me-2">Customer Frontend</p>
                    <Tooltip
                      title="Lorem ipsum"
                      placement="top">
                      <img
                        src={info}
                        alt="info"
                        className=" c-pointer"
                        width={13.5}
                      />
                    </Tooltip>
                  </div>
                  <FormControl className="w-100 px-0">
                    <OutlinedInput
                      size="small"
                      name="customerFrotnendName"
                      placeholder="Enter Group Name"
                      value={formik.values.customerFrotnendName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </FormControl>
                </div>
              )}
              {formik.values.order && (
                <div className="col-md-12 px-0 mt-3">
                  <div className="d-flex mb-1">
                    <p className="text-lightBlue me-2">Product to Review</p>
                    <Tooltip
                      title="Lorem ipsum"
                      placement="top">
                      <img
                        src={info}
                        alt="info"
                        className=" c-pointer"
                        width={13.5}
                      />
                    </Tooltip>
                  </div>
                  <FormControl className="w-100 px-0">
                    <Select
                      name="product"
                      value={formik.values.product}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      size="small">
                      {productSelection.map((ps) => (
                        <MenuItem
                          key={ps._id}
                          value={ps.item.product}
                          sx={{ fontSize: 13, color: "#5c6d8e" }}>
                          {ps.item.productData.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormHelperText error>
                    {formik.touched.product && formik.errors.product}
                  </FormHelperText>
                </div>
              )}
            </div>

            <div className="bg-black-9 border-grey-5 rounded-8 p-3 row features mt-4">
              <div className="d-flex justify-content-between px-0">
                <h6 className="text-lightBlue me-auto col-auto ps-0 fw-500">
                  Add Rating & Reviews
                </h6>
              </div>

              <div className="d-flex justify-content-center col-12 px-0">
                <hr className="hr-grey-6 w-100 my-3" />
              </div>
              <div className="col-12 px-0">
                <p className="text-lightBlue">Add Rating</p>
                <Rating
                  name="star"
                  value={formik.values.star}
                  onBlur={formik.handleBlur}
                  onChange={(_, v) => formik.setFieldValue("star", v)}
                />
                <FormHelperText error>
                  {formik.touched.star && formik.errors.star}
                </FormHelperText>
              </div>

              <div className="col-12 px-0">
                <UploadMultipleMediaLarge
                  fileSrc={formik.values.imageUrl.map((url) => ({ image: url }))}
                  style={{ minHeight: "20rem" }}
                  onUpload={(urls) => formik.setFieldValue("imageUrl", urls)}
                  onRemove={(index) =>
                    formik.setFieldValue(
                      "imageUrl",
                      formik.values.imageUrl.filter((_, i) => index !== i)
                    )
                  }
                />
              </div>
              <div className="col-12 mt-3 px-0">
                <div className="d-flex  mb-1">
                  <p className="text-lightBlue me-2">Description</p>
                  <Tooltip
                    title="Lorem ipsum"
                    placement="top">
                    <img
                      src={info}
                      alt="info"
                      className=" c-pointer"
                      width={13.5}
                    />
                  </Tooltip>
                </div>
                {/* <AppTextEditor
                  config={{
                    toolbar: {
                      items: [
                        "undo",
                        "redo",
                        "|",
                        "heading",
                        "|",
                        "bold",
                        "italic",
                        "|",
                        "bulletedList",
                        "numberedList",
                        "outdent",
                        "indent",
                      ],
                    },
                  }}
                  value={formik.values.description}
                  setFieldValue={(v) => formik.setFieldValue("description", v)}
                /> */}
                <textarea
                  name="description"
                  value={formik.values.description}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                <FormHelperText error>
                  {formik.touched.description && formik.errors.description}
                </FormHelperText>
              </div>
            </div>
          </div>
          <div className="col-lg-3 mt-3 pe-0 ps-0 ps-lg-3">
            <StatusBox
              name="status"
              toggleData={["approved", "pending"]}
              value={formik.values.status}
              handleProductStatus={(_, s) => formik.setFieldValue("status", s)}
              headingName={"Review Status"}
            />
            <TagsBoxBox
              moduleName="Review"
              filterQuery="productReview"
              initTags={formik.values.tags}
              onTagsChange={(t) => formik.setFieldValue("tags", t)}
            />
          </div>
        </div>
        <div className="row create-buttons pt-5 pb-3 justify-content-between">
          <div className="d-flex w-auto px-0">
            <button
              type="button"
              onClick={onDiscardClick}
              className="button-red-outline py-2 px-4">
              <p>Discard</p>
            </button>
          </div>
          <div className="d-flex w-auto px-0">
            <button
              type="submit"
              onClick={() => handleSetAddAnother(true)}
              className="button-lightBlue-outline py-2 px-4">
              <p>Save & Add Another</p>
            </button>
            <LoadingButton
              type="submit"
              onClick={() => handleSetAddAnother(false)}
              className="button-gradient ms-3 py-2 px-4 w-auto"
              loading={createReviewIsLoading}
              disabled={createReviewIsLoading}>
              <p>Save</p>
            </LoadingButton>
          </div>
        </div>
        <DiscardModalSecondary
          when={discard}
          message="review tab"
        />
      </form>
    </div>
  );
};

export default CreateReview;
