import { useEffect, useMemo, useState } from "react";
import { useNavigate, Outlet, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box, CircularProgress } from "@mui/material";
import ScrollToTop from "../ScrollToTop";

import { checkUserStatus, setUserHandler } from "../features/user/userAction";
import { checkLoginStatus, loginHandler, logoutHandler } from "../features/auth/authAction";
import { showWarning } from "../features/snackbar/snackbarAction";

const ActionLayout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const loginStatus = useSelector((state) => state.auth.isLoggedIn);
  const permissions = useSelector((state) => state.auth.permissions);

  const isAuthScreen = useMemo(() => {
    const { pathname } = location;
    return pathname === "/auth/signup" || pathname === "/auth/login" || pathname === "/";
  }, [location]);

  const [firstRender, setFirstRender] = useState(true);
  useEffect(() => {
    if (firstRender) {
      setFirstRender(false);
      if (location.pathname === "google-login" && location.search) {
        const params = new URLSearchParams(location.search);
        const obj = Object.fromEntries(params.entries());

        const { userId, userEmail: email, userToken: accessToken } = obj;
        dispatch(loginHandler({ accessToken, refreshToken: "" }));
        dispatch(setUserHandler({ email, userId }));
        // }
      }
      if (location.pathname === "/auth/signup" && location.search) {
        const params = new URLSearchParams(location.search);
        const obj = Object.fromEntries(params.entries());
        if (!!obj.email && !!obj.hash) {
          dispatch(logoutHandler());
        }
      }
    }
    dispatch(checkLoginStatus());
  }, [location, dispatch, firstRender]);

  useEffect(() => {
    if (loginStatus) {
      dispatch(checkUserStatus());
      if (isAuthScreen) {
        navigate("/dashboard/homepage", { replace: true });
      } else {
        const perm =
          permissions?.find((p) => location.pathname.startsWith("/" + p.module))?.isAllowed ??
          true;
        if (!perm) {
          dispatch(showWarning({ message: "Permission Denied" }));
          navigate("/dashboard/homepage", { replace: true });
        }
      }
    }
    if (loginStatus === false && isAuthScreen === false) {
      navigate("/auth/login", { replace: true });
    }
  }, [loginStatus, dispatch, navigate, isAuthScreen, permissions, location]);

  return (
    <>
      <ScrollToTop />
      <Box className="main-box-secondary">
        {(loginStatus === false && isAuthScreen) || (loginStatus && isAuthScreen === false) ? (
          <Outlet />
        ) : (
          <div className="container-fluid loader">
            <CircularProgress
              color="secondary"
              size={60}
            />
          </div>
        )}
      </Box>
    </>
  );
};

export default ActionLayout;
