import { forwardRef } from "react";
import {
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  Tooltip
} from "@mui/material";

import SelectMarket from "../../../components/Setting/SelectMarket";
import SelectLanguage from "../../../components/SelectLanguage/SelectLanguage";

import cancel from "../../../assets/icons/cancel.svg";
import india from "../../../assets/flag/india.svg";
import usa from "../../../assets/flag/usa.svg";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddLanguage = ({ onConfirm, show }) => {
    const saveGroups = () => {
        onConfirm()
    }

    const handleDelte = () => {
        console.log("just for cross icon")
    }

    return (
        <Dialog
            open={show}
            TransitionComponent={Transition}
            keepMounted
            onClose={onConfirm}
            aria-describedby="alert-dialog-slide-description"
            maxWidth="sm"
            fullWidth={true}
        >
            <DialogTitle>
                <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex flex-column ">
                        <h5 className="text-lightBlue fw-500">Add Currencies</h5>

                        <small className="text-grey-6 mt-1 d-block">
                            ⓘ Lorem ipsum dolor sit amet consectetur.
                        </small>
                    </div>
                    <img
                        src={cancel}
                        alt="cancel"
                        width={30}
                        onClick={onConfirm}
                        className="c-pointer"
                    />
                </div>
            </DialogTitle>
            <hr className="hr-grey-6 my-0" />
            <DialogContent className="mx-2">

                <div className="d-flex col-lg-12">
                    <div className="col-lg-6 pl-3">
                        <SelectLanguage />
                    </div>
                </div>

                <div className="d-flex col-lg-12">
                    <div className="col-lg-6 pl-3">
                        <SelectMarket />
                        <div className="d-flex">
                            <Chip
                                avatar={<img className="rounded-circle" src={india} />}
                                label={["India"]}
                                size="small"
                                className="mt-3 me-2 px-1"
                                onDelete={() => handleDelte()}
                            />
                            <Chip
                                avatar={<img className="rounded-circle" src={usa} />}
                                label={["United States"]}
                                size="small"
                                className="mt-3 me-2 px-1"
                                onDelete={() => handleDelte()}
                            />
                        </div>
                    </div>
                </div>
            </DialogContent>
            <hr className="hr-grey-6 my-0" />
            <DialogActions className="d-flex justify-content-between px-4 py-3">
                <button
                    className="button-grey-outline py-2 px-5"
                >
                    <p className="text-lightBlue">Cancel</p>
                </button>
                <button
                    className="button-gradient py-2 px-5"
                    onClick={() => saveGroups()}
                >
                    <p>Add</p>
                </button>
            </DialogActions>
        </Dialog>
    );
};

export default AddLanguage;