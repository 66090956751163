import React, { useReducer } from "react";
import SettingHeader from "../../../components/Setting/SettingHeader";
import { useNavigate } from "react-router-dom";
import SettingTable from "../../../components/Setting/SettingTable";
import {
  useDeleteNearStoreMutation as useDeleteStoreMutation,
  useGetNearStoresQuery,
} from "../../../features/store/useStore";
import { DeleteModalSecondary } from "../../../components/DeleteModal/DeleteModal";
import { useDispatch } from "react-redux";
import {
  showError,
  showSuccess,
} from "../../../features/snackbar/snackbarAction";

const initialStoreState = {
  data: null,
  totalCount: 0,
  deleteId: null,
  confirmationMessage: "",
  showDeleteModal: false,
  search: "",
  firstRender: true,
};

const Store = () => {
  const navigate = useNavigate();

  const backHandler = () => {
    navigate("/website/themes");
    return;
  };
  const addStore = () => {
    navigate("/website/store/create");
    return;
  };

  const { data, isFetching, isError } = useGetNearStoresQuery();
  const [deleteStore, { isLoading: deleteStoreIsLoading }] =
    useDeleteStoreMutation();

  const func = (id) => {
    navigate(`/website/store/edit/${id}`);
    console.log(data, "getAllStores");
  };

  const policiesReducer = (state, action) => {
    if (action.type === "SET_DELETE") {
      return {
        ...state,
        search: state.search,
        deleteId: action.id,
        confirmationMessage: action.message || "",
        showDeleteModal: true,
      };
    }
    if (action.type === "REMOVE_DELETE") {
      return {
        ...initialStoreState,
        search: state.search,
        totalCount: state.totalCount,
        data: state.data,
        firstRender: false,
      };
    }
    return initialStoreState;
  };

  const dispatch = useDispatch();

  const [storeState, dispatchStore] = useReducer(
    policiesReducer,
    initialStoreState
  );
  const deleteHandler = ({ id, message }) => {
    dispatchStore({ type: "SET_DELETE", id, message });
  };

  const cancelDeleteHandler = () => {
    dispatchStore({ type: "REMOVE_DELETE" });
  };

  const deleteConfirmationHandler = () => {
    deleteStore(storeState.deleteId)
      .unwrap()
      .then(() => {
        dispatch(
          showSuccess({
            message: "Store deleted successfully",
          })
        );
      })
      .catch((error) => {
        if (error?.data?.message) {
          dispatch(showError({ message: error.data.message }));
        } else {
          dispatch(
            showError({
              message: "Something went wrong!, please try again",
            })
          );
        }
      });
  };

  return (
    <div className="page container-fluid position-relative user-group product-tab-page">
      <div className="border-grey-5 mt-4 rounded-8 px-4 pt-4 bg-black-15 fit-Content">
        <div className="my-2 px-1">
          <div className="row mb-2">
            <SettingHeader
              heading={"Stores"}
              buttonName={"+ Add New Store"}
              handleButton={addStore}
              backHandler={backHandler}
            />
            <div className="px-2">
              <hr className="hr-grey-6 my-4 w-100" />
            </div>
            <SettingTable
              tableTitle={"Stores"}
              editItem={addStore}
              error={isError}
              isLoading={isFetching}
              data={data?.data}
              totalCount={2}
              onPageChange={func}
              onPageSize={func}
              pageSize={2}
              page={2}
              onEdit={func}
              onDelete={deleteHandler}
            />
          </div>
        </div>
      </div>
      <DeleteModalSecondary
        onConfirm={deleteConfirmationHandler}
        onCancel={cancelDeleteHandler}
        show={storeState.showDeleteModal}
        isLoading={deleteStoreIsLoading}
        message={storeState.confirmationMessage}
        title="store"
      />
    </div>
  );
};

export default Store;
