import React, { useState } from "react";
import {
  FormControl,
  MenuItem,
  Select,
  InputAdornment,
  OutlinedInput,
  Tooltip,
  Popover,
  FormHelperText,
  FormControlLabel,
  RadioGroup,
  Radio,
  Checkbox,
} from "@mui/material";
import info from "../../assets/icons/info.svg";
import arrowDown from "../../assets/icons/arrowDown.svg";
import { Column } from "ag-grid-community";

function DiscountValue({
  value,
  field,
  formik,
  touched,
  error,
  children,
  priceClippingRequirement,
  priceClippingRequirementError,
  priceClippingRequirementTouched,
}) {
  // * DISCOUNT PERCENT POPOVERS STARTS
  const [anchorDiscountPercentEl, setAnchorDiscountPercentEl] =
    React.useState(null);
  const handleDiscountPercent = (event) => {
    setAnchorDiscountPercentEl(event.currentTarget);
  };
  const handleDiscountPercentClose = () => {
    setAnchorDiscountPercentEl(null);
  };
  const openDiscountPercent = Boolean(anchorDiscountPercentEl);
  const idDiscountPercent = openDiscountPercent ? "simple-popover" : undefined;
  // * DICOUNT PERCENT POPOVERS ENDS

  const handleChange = (newValue) => {
    formik.setFieldValue(`${field}.type`, newValue);
    setAnchorDiscountPercentEl(null);
  };
  return (
    <div className="bg-black-15 border-grey-5 rounded-8 p-3 row attributes mt-4">
      <div className="d-flex col-12 px-0 justify-content-between">
        <div className="d-flex align-items-center">
          <h6 className="text-lightBlue me-auto text-lightBlue fw-500">
            Discount Value
          </h6>
          <span style={{ color: "#F67476", paddingLeft: 4 }}>*</span>
          <Tooltip title="Lorem ipsum" placement="top">
            <img
              src={info}
              alt="info"
              className="ms-2 c-pointer"
              width={13.5}
            />
          </Tooltip>
        </div>
      </div>
      <hr className="hr-grey-6 mt-3 mb-0" />
      <div className="col-12 px-0">
        <div className="row mt-3">
          <div className="col-12">
            <div className="d-flex mb-1">
              <p className="text-lightBlue">Discount</p>
              <Tooltip title="Lorem ipsum" placement="top">
                <img
                  src={info}
                  alt="info"
                  className="ms-2 c-pointer"
                  width={13.5}
                />
              </Tooltip>
            </div>
          </div>
        </div>
        <div className="row align-items-center ">
          <div className="col-md-5 discount-inputs-two d-flex align-items-center">
            <div className="col">
              <FormControl className="px-0">
                <OutlinedInput
                  value={value?.discountValue}
                  onChange={formik?.handleChange}
                  onBlur={formik?.handleBlur}
                  name={`${field}.discountValue`}
                  placeholder="Enter Discount"
                  size="small"
                  startAdornment={
                    value?.type === "fixed" && (
                      <InputAdornment position="start" variant="standard">
                        <span className="text-lightBlue">₹</span>
                      </InputAdornment>
                    )
                  }
                  endAdornment={
                    <React.Fragment>
                      <InputAdornment position="end" variant="standard">
                        <span className="text-lightBlue">
                          {value?.type === "percentage" && "%"}
                        </span>
                      </InputAdornment>
                      <InputAdornment
                        position="end"
                        aria-describedby={idDiscountPercent}
                        onClick={handleDiscountPercent}
                        className="c-pointer"
                      >
                        <span className="d-flex align-items-center">
                          <p className="text-lightBlue">
                            {" "}
                            {value?.type === "percentage"
                              ? `Percentage`
                              : `Fixed Amount`}{" "}
                          </p>
                          <img src={arrowDown} alt="arrow" className="ms-2" />
                        </span>
                      </InputAdornment>
                    </React.Fragment>
                  }
                />
              </FormControl>
              <small>
                {!!touched?.discountValue && error?.discountValue && (
                  <FormHelperText error>{error?.discountValue}</FormHelperText>
                )}
              </small>
            </div>

            <Popover
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              id={idDiscountPercent}
              open={openDiscountPercent}
              anchorEl={anchorDiscountPercentEl}
              onClose={handleDiscountPercentClose}
            >
              <div className="py-2 px-1 c-pointer">
                <small
                  className="text-lightBlue rounded-3 p-2 hover-back d-block"
                  onClick={() => handleChange("percentage")}
                >
                  Percentage Discount
                </small>
                <small
                  className="text-lightBlue rounded-3 p-2 hover-back d-block"
                  onClick={() => handleChange("fixed")}
                >
                  Fixed Amount
                </small>
              </div>
            </Popover>

            {/* <div className="w-auto text-center ms-3">
              <p className="text-lightBlue">on</p>
            </div> */}
          </div>
          {/* upto limit */}
          <div className="col-md-7">
            <div className="d-flex mb-1">
              <FormControlLabel
                value="amount"
                control={
                  <Checkbox
                    size="small"
                    checked={priceClippingRequirement?.isInclude}
                    onChange={(e, c) =>
                      formik.setFieldValue(
                        "priceClippingRequirement.isInclude",
                        c
                      )
                    }
                  />
                }
                label="Maximum Discount"
                sx={{
                  "& .MuiTypography-root": {
                    fontSize: 13,
                    color: "#c8d8ff",
                    // color: "#5C6D8E",
                  },
                }}
              />
              {priceClippingRequirement?.isInclude && (
                <div className="discount-inputs ps-4 ms-1">
                  <div className="d-flex mb-1">
                    {/* <p className="text-lightBlue">Value</p>
                    <Tooltip title="Lorem ipsum" placement="top">
                      <img
                        src={info}
                        alt="info"
                        className="ms-2 c-pointer"
                        width={13.5}
                      />
                    </Tooltip> */}
                  </div>
                  <FormControl className="px-0">
                    <OutlinedInput
                      placeholder="Enter Value"
                      size="small"
                      value={priceClippingRequirement?.priceRange}
                      onChange={formik?.handleChange}
                      onBlur={formik?.handleBlur}
                      name={`priceClippingRequirement.priceRange`}
                      startAdornment={
                        <InputAdornment variant="standard" position="start">
                          ₹
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                  {!!priceClippingRequirementTouched?.priceRange &&
                    priceClippingRequirementError?.priceRange && (
                      <FormHelperText error>
                        {priceClippingRequirementError?.priceRange}
                      </FormHelperText>
                    )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DiscountValue;
