import { useCallback, useEffect, useReducer, useRef, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import * as Yup from "yup";
import _ from "lodash";
import {
  FormControl,
  FormHelperText,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Tooltip,
} from "@mui/material";

import SettingHeader from "../../../components/Setting/SettingHeader";
import SideStoreBox from "../../../components/Setting/Store/SideBox";
import InputBox from "../../../components/Setting/InputBox";
import DashboardBox from "../../../components/Setting/Store/DashBoardBox";
import ChangePass from "../PopUp/ChangePass";
import CloseProfile from "../PopUp/CloseProfile";
import VerificationPopUp from "../../Settings/StoreDetails/PopUp/VerificationPopUp";

import "./ProfileDetails.scss";

import info from "../../../assets/icons/info.svg";
import apple from "../../../assets/SocialImage/apple.svg";
import google from "../../../assets/SocialImage/google.svg";
import fb from "../../../assets/setting/icons/Facebook.svg";
import upload from "../../../assets/icons/upload.svg";
import image3 from "../../../assets/icons/image-03.svg";
import { UploadMediaSmall } from "../../../components/UploadMediaBox/UploadMedia";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import AppMobileCodeSelect from "../../../components/AppMobileCodeSelect/AppMobileCodeSelect";
import { useSendOtpMutation } from "../../../features/otp/otpApiSlice";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {
  useEditProfileMutation,
  useGetAllProfileDetailsQuery,
} from "../../../features/myProfile/myProfileApiSlice";
import { useDispatch } from "react-redux";
import { showError, showSuccess } from "../../../features/snackbar/snackbarAction";
import { SaveFooterTertiary } from "../../../components/SaveFooter/SaveFooter";
import { DiscardModalSecondary } from "../../../components/Discard/DiscardModal";
import { omitEmptyKeys } from "../../../utils/helper";
import { checkUserStatus, setUserHandler } from "../../../features/user/userAction";
import { getUserFromLocal, saveToLocal, saveUserToLocal } from "../../../utils/storage";
import { userActions } from "../../../features/user/userSlice";

const SocialData = [
  { image: google, tag: "Connect Google" },
  { image: apple, tag: "Connect Apple" },
  { image: fb, tag: "Connect Facebook" },
];

const top100Films = [
  // { label: "Danish", value: "danish" },
  // { label: "Dutch", value: "dutch" },
  { label: "English", value: "English" },
  // { label: "Hindi", value: "hindi" },
];

const timeZones = [
  {
    label: "(GMT-12:00) International Date Line West+-",
    value: "(GMT-12:00) International Date Line West+-",
  },
  { label: "(GMT-11:00) Midway Island, Samoa", value: "(GMT-11:00) Midway Island, Samoa" },
  { label: "(GMT-10:00) Hawaii", value: "(GMT-10:00) Hawaii" },
  { label: "(GMT-09:00) Alaska", value: "(GMT-09:00) Alaska" },
  {
    label: "(GMT-08:00) Pacific Time (US and Canada)",
    value: "(GMT-08:00) Pacific Time (US and Canada)",
  },
  {
    label: "(GMT-07:00) Mountain Time (US and Canada)",
    value: "(GMT-07:00) Mountain Time (US and Canada)",
  },
  {
    label: "(GMT-07:00) Chihuahua, La Paz, Mazatlan",
    value: "(GMT-07:00) Chihuahua, La Paz, Mazatlan",
  },
  { label: "(GMT-07:00) Arizona", value: "(GMT-07:00) Arizona" },
  {
    label: "(GMT-06:00) Central Time (US and Canada)",
    value: "(GMT-06:00) Central Time (US and Canada)",
  },
  { label: "(GMT-06:00) Saskatchewan", value: "(GMT-06:00) Saskatchewan" },
  {
    label: "(GMT-06:00) Guadalajara, Mexico City, Monterrey",
    value: "(GMT-06:00) Guadalajara, Mexico City, Monterrey",
  },
  { label: "(GMT-06:00) Central America", value: "(GMT-06:00) Central America" },
  {
    label: "(GMT-05:00) Eastern Time (US and Canada)",
    value: "(GMT-05:00) Eastern Time (US and Canada)",
  },
  { label: "(GMT-05:00) Indiana (East)", value: "(GMT-05:00) Indiana (East)" },
  { label: "(GMT-05:00) Bogota, Lima, Quito", value: "(GMT-05:00) Bogota, Lima, Quito" },
  { label: "(GMT-04:00) Atlantic Time (Canada)", value: "(GMT-04:00) Atlantic Time (Canada)" },
  { label: "(GMT-04:00) Caracas, La Paz", value: "(GMT-04:00) Caracas, La Paz" },
  { label: "(GMT-04:00) Santiago", value: "(GMT-04:00) Santiago" },
  {
    label: "(GMT-03:30) Newfoundland and Labrador",
    value: "(GMT-03:30) Newfoundland and Labrador",
  },
  { label: "(GMT-03:00) Brasilia", value: "(GMT-03:00) Brasilia" },
  {
    label: "(GMT-03:00) Buenos Aires, Georgetown",
    value: "(GMT-03:00) Buenos Aires, Georgetown",
  },
  { label: "(GMT-03:00) Greenland", value: "(GMT-03:00) Greenland" },
  { label: "(GMT-02:00) Mid-Atlantic", value: "(GMT-02:00) Mid-Atlantic" },
  { label: "(GMT-01:00) Azores", value: "(GMT-01:00) Azores" },
  { label: "(GMT-01:00) Cape Verde Islands", value: "(GMT-01:00) Cape Verde Islands" },
  {
    label: "(GMT) Greenwich Mean Time: Dublin, Edinburgh, Lisbon, London",
    value: "(GMT) Greenwich Mean Time: Dublin, Edinburgh, Lisbon, London",
  },
  { label: "(GMT) Casablanca, Monrovia", value: "(GMT) Casablanca, Monrovia" },
  {
    label: "(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague",
    value: "(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague",
  },
  {
    label: "(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb",
    value: "(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb",
  },
  {
    label: "(GMT+01:00) Brussels, Copenhagen, Madrid, Paris",
    value: "(GMT+01:00) Brussels, Copenhagen, Madrid, Paris",
  },
  {
    label: "(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
    value: "(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
  },
  { label: "(GMT+01:00) West Central Africa", value: "(GMT+01:00) West Central Africa" },
  { label: "(GMT+02:00) Bucharest", value: "(GMT+02:00) Bucharest" },
  { label: "(GMT+02:00) Cairo", value: "(GMT+02:00) Cairo" },
  {
    label: "(GMT+02:00) Helsinki, Kiev, Riga, Sofia, Tallinn, Vilnius",
    value: "(GMT+02:00) Helsinki, Kiev, Riga, Sofia, Tallinn, Vilnius",
  },
  {
    label: "(GMT+02:00) Athens, Istanbul, Minsk",
    value: "(GMT+02:00) Athens, Istanbul, Minsk",
  },
  { label: "(GMT+02:00) Jerusalem", value: "(GMT+02:00) Jerusalem" },
  { label: "(GMT+02:00) Harare, Pretoria", value: "(GMT+02:00) Harare, Pretoria" },
  {
    label: "(GMT+03:00) Moscow, St. Petersburg, Volgograd",
    value: "(GMT+03:00) Moscow, St. Petersburg, Volgograd",
  },
  { label: "(GMT+03:00) Kuwait, Riyadh", value: "(GMT+03:00) Kuwait, Riyadh" },
  { label: "(GMT+03:00) Nairobi", value: "(GMT+03:00) Nairobi" },
  { label: "(GMT+03:00) Baghdad", value: "(GMT+03:00) Baghdad" },
  { label: "(GMT+03:30) Tehran", value: "(GMT+03:30) Tehran" },
  { label: "(GMT+04:00) Abu Dhabi, Muscat", value: "(GMT+04:00) Abu Dhabi, Muscat" },
  { label: "(GMT+04:00) Baku, Tbilisi, Yerevan", value: "(GMT+04:00) Baku, Tbilisi, Yerevan" },
  { label: "(GMT+04:30) Kabul", value: "(GMT+04:30) Kabul" },
  { label: "(GMT+05:00) Ekaterinburg", value: "(GMT+05:00) Ekaterinburg" },
  {
    label: "(GMT+05:00) Islamabad, Karachi, Tashkent",
    value: "(GMT+05:00) Islamabad, Karachi, Tashkent",
  },
  {
    label: "(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi",
    value: "(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi",
  },
  { label: "(GMT+05:45) Kathmandu", value: "(GMT+05:45) Kathmandu" },
  { label: "(GMT+06:00) Astana, Dhaka", value: "(GMT+06:00) Astana, Dhaka" },
  { label: "(GMT+06:00) Sri Jayawardenepura", value: "(GMT+06:00) Sri Jayawardenepura" },
  { label: "(GMT+06:00) Almaty, Novosibirsk", value: "(GMT+06:00) Almaty, Novosibirsk" },
  { label: "(GMT+06:30) Yangon Rangoon", value: "(GMT+06:30) Yangon Rangoon" },
  {
    label: "(GMT+07:00) Bangkok, Hanoi, Jakarta",
    value: "(GMT+07:00) Bangkok, Hanoi, Jakarta",
  },
  { label: "(GMT+07:00) Krasnoyarsk", value: "(GMT+07:00) Krasnoyarsk" },
  {
    label: "(GMT+08:00) Beijing, Chongqing, Hong Kong SAR, Urumqi",
    value: "(GMT+08:00) Beijing, Chongqing, Hong Kong SAR, Urumqi",
  },
  {
    label: "(GMT+08:00) Kuala Lumpur, Singapore",
    value: "(GMT+08:00) Kuala Lumpur, Singapore",
  },
  { label: "(GMT+08:00) Taipei", value: "(GMT+08:00) Taipei" },
  { label: "(GMT+08:00) Perth", value: "(GMT+08:00) Perth" },
  { label: "(GMT+08:00) Irkutsk, Ulaanbaatar", value: "(GMT+08:00) Irkutsk, Ulaanbaatar" },
  { label: "(GMT+09:00) Seoul", value: "(GMT+09:00) Seoul" },
  { label: "(GMT+09:00) Osaka, Sapporo, Tokyo", value: "(GMT+09:00) Osaka, Sapporo, Tokyo" },
  { label: "(GMT+09:00) Yakutsk", value: "(GMT+09:00) Yakutsk" },
  { label: "(GMT+09:30) Darwin", value: "(GMT+09:30) Darwin" },
  { label: "(GMT+09:30) Adelaide", value: "(GMT+09:30) Adelaide" },
  {
    label: "(GMT+10:00) Canberra, Melbourne, Sydney",
    value: "(GMT+10:00) Canberra, Melbourne, Sydney",
  },
  { label: "(GMT+10:00) Brisbane", value: "(GMT+10:00) Brisbane" },
  { label: "(GMT+10:00) Hobart", value: "(GMT+10:00) Hobart" },
  { label: "(GMT+10:00) Vladivostok", value: "(GMT+10:00) Vladivostok" },
  { label: "(GMT+10:00) Guam, Port Moresby", value: "(GMT+10:00) Guam, Port Moresby" },
  {
    label: "(GMT+11:00) Magadan, Solomon Islands, New Caledonia",
    value: "(GMT+11:00) Magadan, Solomon Islands, New Caledonia",
  },
  {
    label: "(GMT+12:00) Fiji Islands, Kamchatka, Marshall Islands",
    value: "(GMT+12:00) Fiji Islands, Kamchatka, Marshall Islands",
  },
  { label: "(GMT+12:00) Auckland, Wellington", value: "(GMT+12:00) Auckland, Wellington" },
  { label: "(GMT+13:00) Nuku'alofa", value: "(GMT+13:00) Nuku'alofa" },
];

const profileValidationSchema = Yup.object().shape({
  // firstName: Yup.string().required("First name is required"),
  // lastName: Yup.string().required("Last name is required"),
  fullName: Yup.string().required("Name is required"),
  isPhoneVerified: Yup.boolean(),
  isEmailVerified: Yup.boolean(),
  isSecondaryEmailVerified: Yup.boolean(),
  countryCode: Yup.string().required("Required"),
  phone: Yup.string()
    .required("Phone number is required")
    .matches(/^[0-9]{10}$/, "Invalid phone number")
    .test("is-verified", "Please validate your email", function (value) {
      return (
        this.parent.isPhoneVerified === true ||
        this.createError({ message: "Please validate your phone" })
      );
    }),

  email: Yup.string()
    .required("Email is required")
    .email("Invalid email format")
    .test("is-verified", "Please validate your email", function (value) {
      return (
        this.parent.isEmailVerified === true ||
        this.createError({ message: "Please validate your email" })
      );
    }),
  secondaryEmail: Yup.string()
    .email("Invalid email format")
    .test("is-verified", "Please validate your email", function (value) {
      return (
        !value ||
        this.parent.isSecondaryEmailVerified === true ||
        this.createError({ message: "Please validate your email" })
      );
    }),
});

const initialQueryFilterState = {
  userData: null,
};
const queryFilterReducer = (state, action) => {
  if (action.type === "SET_USER_DATA") {
    return {
      ...state,
      userData: action.userData,
    };
  }
  return initialQueryFilterState;
};

const initialProfileState = {
  isEditing: false,
  edited: false,
};
const profileReducer = (state, action) => {
  if (action.type === "ENABLE_EDIT") {
    return {
      ...state,
      isEditing: true,
    };
  }
  if (action.type === "DISABLE_EDIT") {
    return {
      ...state,
      isEditing: false,
    };
  }
  if (action.type === "EDITED_ENABLE") {
    return {
      ...state,
      edited: true,
    };
  }
  if (action.type === "EDITED_DISABLE") {
    return {
      ...state,
      edited: false,
    };
  }
  return initialProfileState;
};

const ProfileDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [queryFilterState, dispatchQueryFilter] = useReducer(
    queryFilterReducer,
    initialQueryFilterState
  );
  const [profileState, dispatchProfile] = useReducer(profileReducer, initialProfileState);

  const contactRef = useRef(null);
  const phoneRef = useRef(null);
  const senderEmailRef = useRef(null);

  const [showPop, setShowPop] = useState(false);
  const [showCloseProfile, setCloseProfile] = useState(false);
  const [showVerifyPopup, setShowVerifyPopup] = useState(false);
  const [otpVerifyType, setOtpVerifyType] = useState("");
  const [showResend, setShowResend] = useState(false);
  const [countdown, setCountdown] = useState(5);
  const [verifyData, setVerifyData] = useState("");
  const [phoneVerifyData, setPhoneVerifyData] = useState("");
  // const [senderEmailVerifyData, setSenderEmailVerifyData] = useState("");
  const [showFooter, setShowFooter] = useState();

  const [
    sendOtp,
    {
      isLoading: sendOtpIsLoading,
      isSuccess: sendOtpIsSuccess,
      error: sendOtpError,
      isError: sendOtpIsError,
    },
  ] = useSendOtpMutation();

  const {
    data: profileData,
    isLoading: profileDataIsLoading,
    isSuccess: profileDataIsSuccess,
    error: profileDataError,
    isError: profileDataIsError,
  } = useGetAllProfileDetailsQuery({
    id: queryFilterState?.userData?.userId,
  });
  const [
    editProfile,
    {
      isLoading: editProfileDataIsLoading,
      isSuccess: editProfileDataIsSuccess,
      error: editProfileDataError,
      isError: editProfileDataIsError,
    },
  ] = useEditProfileMutation();

  const handleOpenVerifyPhonePopup = () => {
    sendOtp({
      phone: formik?.values?.phone,
      mode: "phone",
    });
    setShowVerifyPopup(true);
    setOtpVerifyType("phone");
    setShowResend(true);
  };
  const handleEditPhone = () => {
    formik.setFieldValue("isPhoneVerified", false);
    setTimeout(() => {
      if (phoneRef.current) {
        phoneRef.current.focus();
      }
    }, 10);
  };

  const handlePhoneBlur = (e) => {
    formik?.handleBlur(e);
    // if (formik?.values?.phone === formik?.initialValues?.phone) {
    //   formik.setFieldValue("isPhoneVerified", true);
    // }
  };

  const handleOpenVerifyContactEmailPopup = () => {
    sendOtp({
      email: formik?.values?.email,
      mode: "email",
    });
    setShowVerifyPopup(true);
    setOtpVerifyType("primaryEmail");
    setShowResend(true);
  };

  const handleEditContactEmail = () => {
    formik.setFieldValue("isEmailVerified", false);
    setTimeout(() => {
      if (contactRef.current) {
        contactRef.current.focus();
      }
    }, 10);
  };
  const handleBlurContactEmail = (e) => {
    formik?.handleBlur(e);
    // if (formik?.values?.email === formik?.initialValues?.email) {
    //   formik.setFieldValue("isEmailVerified", true);
    // }
  };
  const handleOpenVerifySenderEmailPopup = () => {
    sendOtp({
      email: formik?.values?.secondaryEmail,
      mode: "email",
    });
    setShowVerifyPopup(true);
    setShowResend(true);
    setOtpVerifyType("secondaryEmail");
  };

  const handleEditSenderEmail = () => {
    formik.setFieldValue("isSecondaryEmailVerified", false);
    setTimeout(() => {
      if (senderEmailRef.current) {
        senderEmailRef.current.focus();
      }
    }, 10);
  };
  const handleSenderEmailBlur = (e) => {
    formik?.handleBlur(e);
    // if (formik?.values?.secondaryEmail === formik?.initialValues?.secondaryEmail) {
    //   formik.setFieldValue("isSecondaryEmailVerified", true);
    // }
  };

  const selectedLanguageData = top100Films.find(
    (item) => item?.value === profileData?.data?.data[0]?.language
  );

  const selectedTimeZoneData = timeZones.find(
    (item) => item?.value === profileData?.data?.data[0]?.timeZone
  );

  const formik = useFormik({
    initialValues: {
      fullName: profileData?.data?.data[0]?.fullName || "",
      imageUrl: profileData?.data?.data[0]?.imageUrl || "",
      countryCode: profileData?.data?.data[0]?.countryCode || "",
      phone: phoneVerifyData || profileData?.data?.data[0]?.phone || "",
      isPhoneVerified: profileData?.data?.data[0]?.isPhoneVerified || false,
      email: verifyData || profileData?.data?.data[0]?.email || "",
      isEmailVerified: profileData?.data?.data[0]?.isEmailVerified || false,
      // secondaryEmail: senderEmailVerifyData || profileData?.data?.data[0]?.secondaryEmail || "",
      // isSecondaryEmailVerified: profileData?.data?.data[0]?.isSecondaryEmailVerified || false,
      language: profileData?.data?.data[0]?.language || "",
      timeZone: profileData?.data?.data[0]?.timeZone || "",
    },
    enableReinitialize: true,
    validationSchema: profileValidationSchema,
    onSubmit: (values) => {
      setShowFooter(false);
      dispatchProfile({ type: "EDITED_DISABLE" });
      const profileValues = structuredClone(values);
      profileValues.fullName =
        formik.values.fullName !== formik.initialValues.fullName ? profileValues.fullName : "";
      // profileValues.language = values.language.value;
      // profileValues.timeZone = values.timeZone.value;
      const editObj = omitEmptyKeys(profileValues, ["imageUrl"]);
      // createStoreInfo(editObj);
      editProfile({
        id: profileData?.data?.data[0]?._id,
        details: editObj,
      });
    },
  });
  console.log(formik.errors);

  const closeVerifyPopup = () => {
    setShowVerifyPopup(false);
    setCountdown(5);
  };

  const handleResendClick = () => {
    if (otpVerifyType === "phone") {
      sendOtp({
        phone: formik?.values?.phone,
        mode: "phone ",
      });
    } else if (otpVerifyType === "primaryEmail") {
      sendOtp({
        email: formik?.values?.email,
        mode: "email ",
      });
    } else if (otpVerifyType === "secondaryEmail") {
      sendOtp({
        email: formik?.values?.secondaryEmail,
        mode: "email ",
      });
    }
    setShowResend(true);
    setCountdown(5);
  };
  const backHandler = () => {
    navigate(`/myProfile`);
  };

  const closeProfile = () => {
    setCloseProfile(false);
  };
  const handleCloseProfile = () => {
    setCloseProfile(true);
  };

  const onKeyDown = (keyEvent) => {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  };

  const IconUploadHandler = useCallback((url) => {
    formik.setFieldValue("imageUrl", url);
  }, []);

  useEffect(() => {
    const data = localStorage.getItem("user");
    dispatchQueryFilter({ type: "SET_USER_DATA", userData: JSON.parse(data) });
  }, []);
  useEffect(() => {
    if (!_.isEqual(formik.values, formik.initialValues)) {
      dispatchProfile({ type: "ENABLE_EDIT" });
      dispatchProfile({ type: "EDITED_ENABLE" });
    } else if (_.isEqual(formik.values, formik.initialValues)) {
      dispatchProfile({ type: "EDITED_DISABLE" });
      dispatchProfile({ type: "DISABLE_EDIT" });
    }
  }, [formik.initialValues, formik.values]);
  useEffect(() => {
    if (profileDataIsSuccess) {
      dispatchProfile({ type: "EDITED_DISABLE" });
    }
    if (profileDataIsError) {
      if (profileDataError?.data?.message) {
        dispatch(showError({ message: profileDataError?.data?.message }));
      } else {
        dispatch(showError({ message: "Something went wrong, please try again" }));
      }
    }
  }, [
    profileDataIsSuccess,
    profileDataIsError,
    profileDataError,
    editProfileDataIsSuccess,
    dispatch,
  ]);

  useEffect(() => {
    if (editProfileDataIsSuccess) {
      const userDetails = getUserFromLocal();
      saveUserToLocal({
        ...userDetails,
        email: formik?.values?.email,
        fullName: formik?.values?.fullName,
        imageUrl: formik?.values?.imageUrl,
      });
      dispatch(
        userActions.set({
          ...userDetails,
          email: formik?.values?.email,
          fullName: formik?.values?.fullName,
          imageUrl: formik?.values?.imageUrl,
        })
      );
      dispatch(
        showSuccess({
          message: `Profile Edited successfully`,
        })
      );
    }
    if (editProfileDataIsError) {
      if (editProfileDataError?.data?.message) {
        dispatch(showError({ message: editProfileDataError?.data?.message }));
      } else {
        dispatch(showError({ message: "Something went wrong, please try again" }));
      }
    }
  }, [
    editProfileDataIsSuccess,
    dispatch,
    editProfileDataIsError,
    editProfileDataError,
    formik?.values?.email,
    formik?.values?.fullName,
    formik?.values?.imageUrl,
  ]);

  return (
    <div className="border-grey-5 rounded-8 px-4 pt-4 mt-5 bg-black-15">
      <form
        className="myProfile-form "
        noValidate
        onKeyDown={onKeyDown}
        onSubmit={formik.handleSubmit}
        style={{ display: "grid", gridTemplateRows: "1fr auto" }}>
        <div className="my-2">
          <div className="row mb-2">
            <SettingHeader heading={"Profile Details"} />
            <hr className="hr-grey-6 my-4 w-100" />

            <div className="row mb-2">
              <SideStoreBox
                heading={"Basic Information"}
                details={`Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Quia error quasi nihil expedita amet, officia sapiente. `}
              />

              <div className="col-lg-8 d-flex align-items-center">
                <div className="row align-items-start">
                  <div className="d-flex col-lg-12">
                    <InputBox
                      name="fullName"
                      value={formik?.values?.fullName}
                      onChange={formik?.handleChange}
                      onBlur={formik?.handleBlur}
                      heading="Name"
                      tooltip="Your Name"
                      placeholder="Your Name"
                      className="w-100"
                    />
                    {/* <div className="col-lg-6 pl-3">
                      <InputBox
                        name="firstName"
                        value={formik?.values?.firstName}
                        onChange={formik?.handleChange}
                        heading={"First Name"}
                        tooltip={"First Name"}
                        placeholder={"Saniya"}
                      />
                    </div>
                    <div className="col-lg-6">
                      <InputBox
                        name="lastName"
                        value={formik?.values?.lastName}
                        onChange={formik?.handleChange}
                        heading={"Last Name"}
                        tooltip={"Last Name"}
                        placeholder={"Shaikh"}
                      />
                    </div> */}
                  </div>
                  {!!formik?.touched?.fullName && formik?.errors?.fullName && (
                    <FormHelperText error>{formik?.errors?.fullName}</FormHelperText>
                  )}

                  <div className="d-flex justity-content-center col-lg-12 mt-4">
                    <div className="col-lg-10">
                      <div className="d-flex">
                        <p className="text-lightBlue fw-500 mb-1">Upload Profile Image</p>
                        <Tooltip
                          title="Some text"
                          placement="top">
                          <img
                            src={info}
                            alt="info"
                            className="ms-2 c-pointer"
                            width={13.5}
                          />
                        </Tooltip>
                      </div>
                      {/* <FormControl>
                      <OutlinedInput
                        size="small"
                        className="py-1"
                        startAdornment={
                          <InputAdornment
                            position="start"
                            sx={{ color: "#658DED", fontSize: "16px" }}
                          >
                            <img src={image3} className="me-2" />
                            <img src={upload} className="mr-3" />
                            <span>Upload Image</span>
                          </InputAdornment>
                        }
                      />
                    </FormControl> */}
                      <UploadMediaSmall
                        style={{ justifyContent: "flex-start" }}
                        fileSrc={formik.values?.imageUrl}
                        error={formik.errors.imageUrl}
                        onUpload={IconUploadHandler}
                        onBlur={formik.handleBlur}
                        name="imageUrl"
                        isSubmitting={formik.isSubmitting}
                        touched={!!formik.touched.imageUrl}
                        disableLabel={true}
                      />
                    </div>
                    <div className="d-flex justify-content-end col-lg-2 mt-2">
                      <div className="imagePreview ">
                        <input type="file" />
                        {formik.values?.imageUrl ? (
                          <img
                            className="d-flex justify-content-center mt-4 text-blue-2 profileImage"
                            src={formik.values?.imageUrl}
                            alt="profileImage"
                          />
                        ) : (
                          <small className="d-flex justify-content-center mt-4 text-blue-2">
                            Preview
                          </small>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr className="hr-grey-6 my-4 w-100" />

            <div className="row mb-2">
              <SideStoreBox
                heading={"Contact Information"}
                details={`Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Quia error quasi nihil expedita amet, officia sapiente. `}
              />

              <div className="col-lg-8 d-flex align-items-center">
                <div className="row align-items-start">
                  <div className="d-flex justify-content-between align-items-center mb-1">
                    <div className="d-flex">
                      <p className="text-lightBlue fw-500">Mobile Number</p>
                      <Tooltip
                        title="Some text"
                        placement="top">
                        <img
                          src={info}
                          alt="info"
                          className="ms-2 c-pointer"
                          width={13.5}
                        />
                      </Tooltip>
                    </div>
                    {formik?.values?.isPhoneVerified && (
                      <small
                        className="text-blue-2 c-pointer"
                        onClick={handleEditPhone}>
                        Edit
                      </small>
                    )}
                  </div>
                  <div className="col-lg-12 ">
                    <FormControl className="w-100 px-0">
                      <OutlinedInput
                        inputRef={phoneRef}
                        placeholder="Enter Mobile Number"
                        size="small"
                        sx={{ paddingLeft: 0 }}
                        disabled={formik?.values?.isPhoneVerified}
                        startAdornment={
                          <InputAdornment position="start">
                            <AppMobileCodeSelect
                              disabled={formik?.values?.isPhoneVerified}
                              formik={formik}
                              handleCode={(e, value) => {
                                formik.setFieldValue("countryCode", value?._id);
                              }}
                              countryCode={formik?.values?.countryCode}
                              handlePhoneBlur={handlePhoneBlur}
                            />
                          </InputAdornment>
                        }
                        endAdornment={
                          // formik?.values?.phone !== formik?.initialValues?.phone &&
                          !formik?.values?.isPhoneVerified &&
                          formik?.values?.phone &&
                          formik?.values?.countryCode ? (
                            <div
                              className="button-gradient"
                              onClick={handleOpenVerifyPhonePopup}>
                              <small className="px-2 py-1">Send OTP to verify</small>
                            </div>
                          ) : (
                            formik?.values?.isPhoneVerified && (
                              <CheckCircleOutlineIcon
                                style={{ color: "green", marginLeft: "4" }}
                                sx={{
                                  fontSize: "medium",
                                }}
                              />
                            )
                          )
                        }
                        value={formik.values.phone}
                        onBlur={handlePhoneBlur}
                        onChange={formik.handleChange}
                        name="phone"
                      />
                    </FormControl>
                    {(!!formik?.touched?.countryCode || !!formik?.touched?.phone) &&
                      (formik?.errors?.countryCode || formik?.errors?.phone) && (
                        <FormHelperText error>
                          {formik?.errors?.countryCode || formik?.errors?.phone}
                        </FormHelperText>
                      )}
                  </div>
                  <div className="mt-4">
                    <div className="d-flex justify-content-between align-items-center mb-1">
                      <div className="d-flex">
                        <p className="text-lightBlue fw-500">Email Address</p>
                        <Tooltip
                          title="Some text"
                          placement="top">
                          <img
                            src={info}
                            alt="info"
                            className="ms-2 c-pointer"
                            width={13.5}
                          />
                        </Tooltip>
                      </div>
                      {formik?.values?.isEmailVerified && (
                        <small
                          className="text-blue-2 c-pointer"
                          onClick={handleEditContactEmail}>
                          Edit
                        </small>
                      )}
                    </div>
                    <FormControl className="w-100 px-0">
                      <OutlinedInput
                        name="email"
                        inputRef={contactRef}
                        value={formik?.values?.email}
                        onChange={formik?.handleChange}
                        onBlur={handleBlurContactEmail}
                        disabled={formik?.values?.isEmailVerified}
                        placeholder="Enter Email ID"
                        size="small"
                        endAdornment={
                          formik?.values?.email !== formik?.initialValues?.email &&
                          !formik?.values?.isEmailVerified &&
                          formik?.values?.email ? (
                            <div
                              className="button-gradient"
                              onClick={handleOpenVerifyContactEmailPopup}>
                              <small className="px-2 py-1">Send OTP to verify</small>
                            </div>
                          ) : (
                            formik?.values?.isEmailVerified && (
                              <CheckCircleOutlineIcon
                                style={{ color: "green", marginLeft: "4" }}
                                sx={{
                                  fontSize: "medium",
                                }}
                              />
                            )
                          )
                        }
                      />
                    </FormControl>
                    {formik?.touched?.email && formik?.errors?.email && (
                      <FormHelperText error>{formik?.errors?.email}</FormHelperText>
                    )}
                  </div>
                  {/* <div className="mt-4">
                    <div className="d-flex justify-content-between align-items-center mb-1">
                      <div className="d-flex">
                        <p className="text-lightBlue fw-500">Secondary Email</p>
                        <Tooltip
                          title="Some text"
                          placement="top">
                          <img
                            src={info}
                            alt="info"
                            className="ms-2 c-pointer"
                            width={13.5}
                          />
                        </Tooltip>
                      </div>
                      {formik?.values?.isSecondaryEmailVerified && (
                        <small
                          className="text-blue-2 c-pointer"
                          onClick={handleEditSenderEmail}>
                          Edit
                        </small>
                      )}
                    </div>
                    <FormControl className="w-100 px-0">
                      <OutlinedInput
                        name="secondaryEmail"
                        inputRef={senderEmailRef}
                        value={formik?.values?.secondaryEmail}
                        disabled={formik?.values?.isSecondaryEmailVerified}
                        onChange={formik?.handleChange}
                        onBlur={handleSenderEmailBlur}
                        placeholder="Enter Email ID"
                        size="small"
                        endAdornment={
                          formik?.values?.secondaryEmail !==
                            profileData?.data?.data[0]?.secondaryEmail &&
                          !formik?.values?.isSecondaryEmailVerified &&
                          formik?.values?.secondaryEmail ? (
                            <div
                              className="button-gradient"
                              onClick={handleOpenVerifySenderEmailPopup}>
                              <small className="px-2 py-1">Send OTP to verify</small>
                            </div>
                          ) : (
                            formik?.values?.isSecondaryEmailVerified && (
                              <CheckCircleOutlineIcon
                                style={{ color: "green", marginLeft: "4" }}
                                sx={{
                                  fontSize: "medium",
                                }}
                              />
                            )
                          )
                        }
                      />
                    </FormControl>
                    {formik?.values?.secondaryEmail !== formik?.initialValues?.secondaryEmail &&
                      !!formik?.touched?.secondaryEmail &&
                      formik?.errors?.secondaryEmail && (
                        <FormHelperText error>{formik?.errors?.secondaryEmail}</FormHelperText>
                      )}
                  </div> */}
                </div>
              </div>
            </div>

            <hr className="hr-grey-6 my-4 w-100" />

            <div className="row mb-2">
              <SideStoreBox
                heading={"Language"}
                details={`Lorem ipsum dolor sit amet consectetur adipisicing elit.`}
              />
              <div className="col-lg-8 d-flex align-items-center">
                <DashboardBox
                  inputName="Default Language"
                  tooltip="Some text"
                  placeholder="EN - English"
                  optionList={top100Films}
                  name="language"
                  // value={selectedLanguageData || ''}
                  value={formik?.values?.language}
                  onChange={(_, newValue) => {
                    formik.setFieldValue("language", newValue?.value);
                  }}
                  // onBlur={formik?.handleBlur}
                />
              </div>
            </div>

            <hr className="hr-grey-6 my-4 w-100" />

            <div className="row mb-2">
              <SideStoreBox
                heading={"Time Zone"}
                details={`Lorem ipsum dolor sit amet consectetur adipisicing elit.`}
              />
              <div className="col-lg-8 d-flex align-items-center">
                <DashboardBox
                  inputName="Default Time Zone"
                  tooltip="Some text"
                  placeholder="EN - English"
                  optionList={timeZones}
                  name="timeZone"
                  // value={selectedTimeZoneData || ''}
                  value={formik?.values?.timeZone}
                  onChange={(_, newValue) => {
                    formik.setFieldValue(`timeZone`, newValue?.value);
                  }}
                  onBlur={formik?.handleBlur}
                />
              </div>
            </div>

            <hr className="hr-grey-6 my-4 w-100" />

            {/* <div className="row mb-2">
              <SideStoreBox
                heading={"Connect Login Service"}
                details={`Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
            `}
              />
              <div className="col-lg-8 mt-3">
                {SocialData.map((item) => (
                  <div className="align-items-center mb-4">
                    <img
                      src={item.image}
                      width={44}
                      height={44}
                    />
                    <small className="text-blue-2 ms-4">{item.tag}</small>
                  </div>
                ))}
              </div>
            </div> */}
            <div className="row mb-2">
              <SideStoreBox
                heading={"Password"}
                details={`Lorem ipsum dolor sit amet consectetur adipisicing elit.`}
              />

              <div className="col-lg-8">
                <div className="d-flex justify-content-between align-items-center border-grey-5 rounded-8 bg-black-13 p-4 w-100">
                  <small className="text-lightBlue mr-2">
                    It has been more than two years since you last updated your password.
                  </small>
                  <div
                    className="button-gradient py-2 px-3"
                    onClick={() => setShowPop(true)}>
                    <small>Change Password</small>
                  </div>
                </div>
              </div>
            </div>

            <hr className="hr-grey-6 my-4 w-100" />

            <div className="row mb-2">
              <SideStoreBox
                heading={"Close profile"}
                details={`Lorem ipsum dolor sit amet consectetur adipisicing elit.`}
              />
              <div className="col-lg-8 mt-4 mb-2">
                <div className="d-flex border-grey-5 rounded-8 bg-black-13 p-4 w-100">
                  <small className="text-lightBlue me-3">
                    Your account will be closed permanently. Your Unthread store will be shut
                    down.
                  </small>
                  <button
                    className="button-red-outline py-2 px-4"
                    onClick={handleCloseProfile}>
                    <p>Close Profile</p>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <SaveFooterTertiary
          show={showFooter ? true : profileState?.isEditing}
          onDiscard={backHandler}
          module="storeInfo"
          isLoading={editProfileDataIsLoading}
        />
        <DiscardModalSecondary
          when={profileState.edited}
          message={`${formik?.values?.name} store`}
        />
      </form>
      <ChangePass
        heading="Change Password"
        show={showPop}
        onConfirm={() => setShowPop(false)}
      />
      <VerificationPopUp
        show={showVerifyPopup}
        type={otpVerifyType}
        onClose={closeVerifyPopup}
        formik={formik}
        setShowVerifyPopup={setShowVerifyPopup}
        setShowResend={setShowResend}
        showResend={showResend}
        setCountdown={setCountdown}
        countdown={countdown}
        handleResendClick={handleResendClick}
        setVerifyData={setVerifyData}
        // setSenderEmailVerifyData={setSenderEmailVerifyData}
        setSenderEmailVerifyData={Date}
        setPhoneVerifyData={setPhoneVerifyData}
        setShowFooter={setShowFooter}
        isPhoneVerified="isPhoneVerified"
        isContactEmailVerified="isEmailVerified"
        isSenderEmailVerified="isSecondaryEmailVerified"
        phoneValue={formik?.values?.phone}
        primaryEmailValue={formik?.values?.email}
        secondaryEmailValue={formik?.values?.secondaryEmail}
      />
      <CloseProfile
        show={showCloseProfile}
        onConfirm={closeProfile}
      />
    </div>
  );
};

export default ProfileDetails;

/* <div className="col-lg-8 d-flex align-items-center">
              <div className="row align-items-start">
                <div className="d-flex justify-content-between align-items-center mb-1">
                  <div className="d-flex">
                    <p className="text-lightBlue fw-500">Mobile Number</p>
                    <Tooltip title="Some text" placement="top">
                      <img
                        src={info}
                        alt="info"
                        className="ms-2 c-pointer"
                        width={13.5}
                      />
                    </Tooltip>
                  </div>
                  <small className="text-blue-2 c-pointer">Verify</small>
                </div>
                <div className="col-lg-2">
                  <FormControl sx={{ m: 0, width: "100%" }} size="small">
                    <Select
                      labelId="demo-select-small"
                      id="demo-select-small"
                      name="gender"
                      size="small"
                      placeholder="Gender"
                    >
                      <MenuItem
                        value="male"
                        sx={{ fontSize: 13, color: "#5c6d8e" }}
                      >
                        + 91
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="col-lg-10">
                  <FormControl className="w-100 px-0">
                    <OutlinedInput
                      placeholder="Enter Mobile Number"
                      size="small"
                      name="firstName"
                    />
                  </FormControl>
                </div>
                <small className="text-grey-6 d-block">
                  <Checkbox
                    sx={{
                      "& .MuiSvgIcon-root": { fontSize: 18 },
                      color: "#C8D8FF",
                    }}
                  />
                  Recieve updates on whatsapp
                </small>
                <InputBox
                  heading={"Primary Email"}
                  tooltip={"Some text"}
                  placeholder={"Enter Email ID"}
                  rightHeading={"Verify"}
                />
                <InputBox
                  heading={"Secondary Email"}
                  tooltip={"Some text"}
                  placeholder={"Enter Email ID"}
                  rightHeading={"Verify"}
                />
              </div>
            </div> */
