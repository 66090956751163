const DROPZONE_IMAGE_FORMAT = {
  'image/jpeg': [],
  'image/jpg': [],
  'image/png': [],
  'image/svg': [],
  'image/webp': [],
  'image/gif': [],
};

const DROPZONE_VIDEO_FORMAT = {
  'video/mp4': [],
  'video/avi': [],
  'video/mkv': [],
  'video/quicktime': [],
  'video/webm': [],
};

const IMAGE_EXTENSIONS = ['jpeg', 'jpg', 'png', 'svg', 'gif', 'webp'];

const VIDEO_EXTENSIONS = ['mp4', 'avi', 'mkv', 'mov', 'webm'];

const SNACKBAR_DURATION = 5000;

const MAX_FILE_SIZE = 10 * 1024 * 1024;

const UNITS = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

export {
  DROPZONE_IMAGE_FORMAT,
  DROPZONE_VIDEO_FORMAT,
  IMAGE_EXTENSIONS,
  VIDEO_EXTENSIONS,
  SNACKBAR_DURATION,
  MAX_FILE_SIZE,
  UNITS,
};
