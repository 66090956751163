import { forwardRef } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Rating,
  Slide,
} from "@mui/material";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import BlockIcon from "@mui/icons-material/Block";
import infoIcon from "../../../assets/icons/info.svg";
import cancel from "../../../assets/icons/cancel.svg";
import moment from "moment/moment";
import { getReviewStatusColor } from "./utils";
import { sanitize } from "dompurify";
import { Link } from "react-router-dom";
import AsyncImage from "../../../components/AsyncImage/AsyncImage";

const Transition = forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="up"
      ref={ref}
      {...props}
    />
  );
});

export default function ReviewDialog({
  review = {},
  isOpen = false,
  onClose = () => {},
  onApprove = () => {},
  onReject = () => {},
}) {
  const {
    createdAt = "",
    star = 0,
    description = "",
    imageUrl = [],
    product: { _id: pid = "", title = "" } = {},
    order: { _id: oid = "", orderID = "" } = {},
    customer: { firstName = "", lastName = "", _id: cid = "" } = {},
    status = "",
  } = review ?? {};
  const dateObj = moment(createdAt, moment.ISO_8601);

  return (
    <Dialog
      open={isOpen}
      TransitionComponent={Transition}
      onClose={onClose}
      maxWidth="sm"
      fullWidth>
      <DialogTitle>
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex flex-column ">
            <h5 className="text-lightBlue fw-500">Rating Review</h5>
            <small className="text-grey-6 mt-1">{dateObj.format("DD/MM/YYYY at hh:mma")}</small>
          </div>
          <img
            src={cancel}
            alt="cancel"
            width={30}
            onClick={onClose}
            className="c-pointer"
          />
        </div>
      </DialogTitle>
      <hr className="hr-grey-6 my-0" />
      <DialogContent className="py-3 px-4">
        <p className="text-grey-6 mb-2">Rating</p>
        <Rating
          // size="small"
          defaultValue={star}
          readOnly
        />
        <p className="text-grey-6 mb-2">Media</p>
        <div className="d-flex align-items-center ">
          {imageUrl.map((url, ix) => (
            <AsyncImage
              key={ix}
              src={url}
              placeholder={infoIcon}
              alt="product"
              className="rounded-4 mx-2"
              height={60}
              width={60}
            />
          ))}
        </div>
        <p className="text-grey-6 mt-3">Review</p>
        {/* <p
          className="text-lightBlue mt-1"
          dangerouslySetInnerHTML={{ __html: sanitize(description) }}
        /> */}
        <p className="text-lightBlue mt-1">{description}</p>
        <div className="d-flex">
          <div>
            <p className="text-grey-6 mt-3">Product</p>
            <p className="mt-1">
              <Link
                className="text-blue-2 text-decoration-underline"
                to="/products/allProducts">
                {title}
              </Link>
            </p>
          </div>
          <div className="ms-5">
            <p className="text-grey-6 mt-3">Order ID</p>
            <p className="mt-1">
              <Link
                className="text-blue-2 text-decoration-underline"
                to="/orders/allOrders">
                {orderID}
              </Link>
            </p>
          </div>
        </div>
        <p className="text-grey-6 mt-3">Customer</p>
        <Link
          to="/users/allUsers"
          className="text-blue-2 text-decoration-underline">
          {firstName} {lastName}
        </Link>
        {/* <p className="text-blue-2 mt-1 text-decoration-underline">
          {firstName} {lastName}
        </p> */}
        <p className="text-grey-6 mt-3">Status</p>
        <div className="d-flex mt-1">
          <div
            className="rounded-pill d-flex table-status px-2 py-1"
            style={{ backgroundColor: getReviewStatusColor(status) }}>
            <small className="text-capitalize text-black fw-400">{status}</small>
          </div>
        </div>
      </DialogContent>
      <hr className="hr-grey-6 my-0" />
      <DialogActions className="d-flex justify-content-between align-items-center px-4 py-3">
        {status !== "approved" && (
          <button
            className="button-green-outline py-2 px-4"
            onClick={onApprove}>
            <TaskAltIcon
              sx={{
                fontSize: 18,
                cursor: "pointer",
              }}
            />
            <p className="ms-2">Approve Reviews</p>
          </button>
        )}
        {status !== "disapproved" && (
          <button
            className="button-red-outline py-2 px-4"
            onClick={onReject}>
            <BlockIcon
              sx={{
                fontSize: 18,
                cursor: "pointer",
              }}
            />
            <p className="ms-2">Reject Reviews</p>
          </button>
        )}
      </DialogActions>
    </Dialog>
  );
}
