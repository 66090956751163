import React, { useEffect, useState } from "react";
import { convert } from "html-to-text";
import "./SEO.scss";
// ! IMAGES IMPORTS
import info from "../../../../assets/icons/info.svg";
// ! COMPONENT IMPORTS
import { AntSwitch } from "../../../../components/AntSwitch/AntSwitch";
// ! MATERIAL IMPORTS
import {
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  InputAdornment,
  OutlinedInput,
  TextareaAutosize,
  Tooltip,
  FormHelperText,
} from "@mui/material";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { showError, showSuccess } from "../../../../features/snackbar/snackbarAction";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import _ from "lodash";

import {
  useGetAllSeoQuery,
  useCreateSeoMutation,
  useEditSeoMutation,
} from "../../../../features/seo/seoApiSlice";
import { useNavigate, useParams, useSearchParams, createSearchParams } from "react-router-dom";
import * as Yup from "yup";
import { SaveFooterTertiary } from "../../../../components/SaveFooter/SaveFooter";
import SnackbarUtils from "../../../../features/snackbar/useSnackbar";
import { useFormikToastError } from "../../../../hooks/error";
import { omitEmptyKeys } from "../../../../utils/helper";

function SEOSecondary({ formik, module, viewAll, setViewAll }) {
  const dispatch = useDispatch();
  // const [viewAll, setViewAll] = useState(false);
  const [multipleTags, setMultipleTags] = useState([]);
  const [firstRender, setFirstRender] = useState(true);

  const handleAddMultiple = (event) => {
    if (event.key === "Enter" || event.type === "click") {
      event.preventDefault();
      formik.validateForm().then(() => {
        if (formik?.values?.seo?.metaKeywords.trim() !== "") {
          formik.setFieldTouched(`seo.metaKeywords`, true);
          const metaKeywordsExists = formik?.values?.seo?.multipleMetaTags?.some(
            (tag) =>
              tag.toLowerCase().trim() ===
              formik?.values?.seo?.metaKeywords.toLowerCase().trim()
          );
          if (!metaKeywordsExists) {
            formik?.setFieldValue(`seo.multipleMetaTags`, [
              ...formik?.values?.seo?.multipleMetaTags,
              formik?.values?.seo?.metaKeywords,
            ]);
            formik.setFieldValue(`seo.metaKeywords`, "");
          } else {
            dispatch(
              showError({
                message: `${formik?.values?.seo?.metaKeywords.trim()} already exists`,
              })
            );
          }
        }
      });
    }
  };

  const handleDelete = (data) => {
    const filterData = formik?.values?.seo?.multipleMetaTags?.filter((v) => v !== data);
    formik?.setFieldValue(`seo.multipleMetaTags`, filterData);
  };

  const handleSeoTitleChange = (e) => {
    formik?.handleChange(e);
    formik.setFieldValue(`seo.isEditedTitleHandle`, true);
  };

  const handleSeoUrlHandleChange = (e) => {
    const newValue = e.target.value;
    let prefix = "";
    if (module === "subCategory") {
      const indexOfSecondSlash = formik?.values?.seo?.urlHandle.indexOf(
        "/",
        formik?.values?.seo?.urlHandle.indexOf("/") + 1
      );
      prefix = formik?.values?.seo?.urlHandle.substring(0, indexOfSecondSlash + 1);
    } else {
      prefix = formik?.values?.seo?.urlHandle.substring(
        0,
        formik?.values?.seo?.urlHandle.indexOf("/") + 1
      );
    }

    if (newValue.startsWith(prefix)) {
      formik.setFieldValue("seo.urlHandle", newValue);
    } else if (newValue === "") {
      // If the input is empty, set it to the prefix
      formik.setFieldValue("seo.urlHandle", prefix);
    } else {
      // Prevent removing the prefix by reverting to the previous value
      formik.setFieldValue("seo.urlHandle", formik?.values?.seo?.urlHandle);
    }
    formik.setFieldValue(`seo.isEditedUrlHandle`, true);
  };

  return (
    <div className="bg-black-15 border-grey-5 rounded-8 p-3 row">
      <div className="d-flex col-12 px-0 justifu-content-between">
        <div className="d-flex align-items-center me-auto">
          <h6 className="text-lightBlue me-auto fw-500">Search Engine Optimisation</h6>
          <Tooltip
            title="Lorem ipsum"
            placement="top">
            <img
              src={info}
              alt="info"
              className="c-pointer ms-2"
              width={13.5}
            />
          </Tooltip>
        </div>
      </div>
      <div className="col-12 px-0 bg-black-13 border-grey-5 mt-3 rounded-8">
        <div className="d-flex flex-column p-3">
          <div className="d-flex justify-content-between">
            <p className="text-lightBlue">Metadata Preview</p>
            {/* <small
              className="text-lightBlue text-blue-2 c-pointer"
              onClick={() => {
                setViewAll((prevState) => !prevState);
              }}>
              {viewAll ? "Collapse" : "Edit"}
            </small> */}
          </div>
          <small className="text-lightBlue mt-3 mb-2 fw-500">
            Title : {formik?.values?.seo?.title}
          </small>
          <small
            className="text-lightBlue mt-1 mb-2"
            style={{ wordWrap: "break-word" }}>
            Description : {formik?.values?.seo?.description}
          </small>
          {/* <small className="text-blue-2" style={{ wordWrap: "break-word" }}>
            Url Handle : {formik?.values?.seo?.urlHandle}
          </small> */}

          <small className="mt-2 text-grey-6">
            {formik?.values?.seo?.multipleMetaTags &&
              formik?.values?.seo?.multipleMetaTags.map((item) => item).join(", ")}
          </small>
        </div>
      </div>

      {/* {viewAll && (
        <React.Fragment> */}
      <div className="d-flex mb-1 mt-3 col-12 px-0">
        <small className="text-lightBlue">Page Title</small>
        <small style={{ color: "#F67476", paddingRight: 4, paddingLeft: 2 }}>*</small>
      </div>
      <FormControl className="col-12 px-0">
        <OutlinedInput
          name={`seo.title`}
          placeholder="Please enter page title"
          size="small"
          value={formik?.values?.seo?.title}
          onChange={handleSeoTitleChange}
          onBlur={formik?.handleBlur}
        />
      </FormControl>
      {!!formik?.touched?.seo?.title && formik?.errors?.seo?.title ? (
        <FormHelperText
          error
          className="px-0">
          {formik?.errors?.seo?.title}
        </FormHelperText>
      ) : (
        <small className="mt-1 text-grey-6 col-12 px-0">
          {formik?.values?.seo?.title?.length} of 50 characters Used
        </small>
      )}
      <small className="text-lightBlue mb-2 mt-3 col-12 px-0">Meta Description</small>
      <TextareaAutosize
        name={`seo.description`}
        aria-label="meta description"
        placeholder="Please enter meta description"
        style={{
          background: "#15142A",
          color: "#C8D8FF",
          borderRadius: 5,
        }}
        minRows={5}
        value={formik?.values?.seo?.description}
        onChange={formik?.handleChange}
        onBlur={formik?.handleBlur}
        className="col-12"
      />

      {!!formik?.touched?.seo?.description && formik?.errors?.seo?.description ? (
        <FormHelperText
          error
          className="px-0">
          {formik?.errors?.seo?.description}
        </FormHelperText>
      ) : (
        <small className="mt-1 text-grey-6 col-12 px-0">
          {formik?.values?.seo?.description?.length} of 500 characters Used
        </small>
      )}
      {/* <div className="d-flex mb-2 mt-3 col-12 px-0">
            <small className="text-lightBlue">URL Handle</small>
            <small
              style={{ color: "#F67476", paddingRight: 4, paddingLeft: 2 }}
            >
              *
            </small>
          </div>
          <FormControl className="col-12 px-0">
            <OutlinedInput
              name={`seo.urlHandle`}
              placeholder="Please enter Url"
              size="small"
              value={formik?.values?.seo?.urlHandle}
              onChange={handleSeoUrlHandleChange}
              onBlur={formik?.handleBlur}
              startAdornment={
                <InputAdornment position="start">
                  <p className="text-grey-6" style={{ marginRight: -6 }}>
                    {module === "collection"
                      ? "https://www.unthread.com/collection"
                      : "https://www.unthread.com"}
                    /
                  </p>
                </InputAdornment>
              }
            />
          </FormControl>
          {!!formik?.touched?.seo?.urlHandle &&
            formik?.errors?.seo?.urlHandle && (
              <FormHelperText error className="px-0">
                {formik?.errors?.seo?.urlHandle}
              </FormHelperText>
            )} */}
      <small className="text-lightBlue mb-2 mt-3 col-12 px-0">Meta Keywords</small>
      <FormControl className="col-12 px-0">
        <OutlinedInput
          name={`seo.metaKeywords`}
          value={formik?.values?.seo?.metaKeywords}
          onChange={formik?.handleChange}
          onBlur={formik?.handleBlur}
          onKeyDown={handleAddMultiple}
          placeholder="Enter keywords"
          size="small"
          endAdornment={
            <InputAdornment position="end">
              <Tooltip
                title="Create Multiple Meta Tags"
                placement="top">
                <ChevronRightIcon
                  className="c-pointer"
                  onClick={handleAddMultiple}
                />
              </Tooltip>
            </InputAdornment>
          }
        />
        <div
          className="d-flex flex-wrap"
          style={{ wordWrap: "break-word" }}>
          {formik?.values?.seo?.multipleMetaTags &&
            formik?.values?.seo?.multipleMetaTags.map((data, index) => {
              return (
                <Chip
                  key={index}
                  label={data}
                  onDelete={() => handleDelete(data)}
                  onClick={() => {}}
                  size="small"
                  className="mt-3 me-2"></Chip>
              );
            })}
        </div>
      </FormControl>
      {/* </React.Fragment>
      )} */}
    </div>
  );
}

const seoValidationSchema = Yup.object().shape({
  title: Yup.string()
    .required("Title is required")
    .max(50, "Title cannot exceed 50 characters"),
  description: Yup.string().max(500, "Description cannot exceed 500 characters"),
});

function SEOTertiary({
  formik,
  module,
  // viewAll,
  // setViewAll,
  title,
  description,
  onBack,
  onNext,
  step,
  onDisableEdit,
  onEnableEdit,
  isCreateMode,
  initialOpen,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { id } = useParams();
  const [viewAll, setViewAll] = useState(!!initialOpen || false);
  const [multipleTags, setMultipleTags] = useState([]);
  const [firstRender, setFirstRender] = useState(true);

  const {
    data: seoData,
    isLoading: seoIsLoading,
    error: seoError,
    isError: seoIsError,
    isSuccess: seoIsSuccess,
    isFetching: seoIsFetching,
  } = useGetAllSeoQuery(
    { refrenceId: id },
    {
      skip: id ? false : true,
    }
  );
  const [
    createSeo,
    {
      isLoading: createSeoIsLoading,
      isSuccess: createSeoIsSuccess,
      error: createSeoError,
      isError: createSeoIsError,
    },
  ] = useCreateSeoMutation();
  const [
    editSeo,
    {
      isLoading: editSeoIsLoading,
      isSuccess: editSeoIsSuccess,
      error: editSeoError,
      isError: editSeoIsError,
    },
  ] = useEditSeoMutation();

  const seoFormik = useFormik({
    initialValues: {
      title: seoData?.data?.data?.[0]?.title || title,
      description: seoData?.data?.data?.[0]?.description
        ? convert(seoData?.data?.data?.[0]?.description)
        : convert(description),
      multipleMetaTags: seoData?.data?.data?.[0]?.metaKeywords || [],
      metaKeywords: "",
    },
    enableReinitialize: true,
    validationSchema: seoValidationSchema,
    onSubmit: (values) => {
      SnackbarUtils.savingToast();
      let clonedSeo = structuredClone(omitEmptyKeys(values));
      clonedSeo.metaKeywords = clonedSeo.multipleMetaTags;

      if (seoData?.data?.data?.[0]?._id) {
        editSeo({
          id,
          details: { ...clonedSeo, step: step.toString() },
        })
          .unwrap()
          .then(() => {
            SnackbarUtils.hideToast();
            dispatch(showSuccess({ message: "Seo updated successfully" }));
            onNext();
          })
          .catch((error) => {
            SnackbarUtils.hideToast();

            if (error?.data?.message) {
              dispatch(showError({ message: error.data.message }));
            } else {
              dispatch(
                showError({
                  message: "Something went wrong!, please try again",
                })
              );
            }
          });
      } else {
        clonedSeo.refrenceId = id;
        createSeo({ ...clonedSeo, step: step.toString(), module: "products" })
          .unwrap()
          .then(() => {
            SnackbarUtils.hideToast();
            dispatch(
              showSuccess({
                message: "Seo added successfully",
              })
            );
            onNext();
          })

          .catch((error) => {
            SnackbarUtils.hideToast();
            if (error?.data?.message) {
              dispatch(showError({ message: error.data.message }));
            } else {
              dispatch(
                showError({
                  message: "Something went wrong!, please try again",
                })
              );
            }
          });
      }
    },
  });

  useFormikToastError(seoFormik);

  const handleAddMultiple = (event) => {
    if (event.key === "Enter" || event.type === "click") {
      event.preventDefault();
      seoFormik.validateForm().then(() => {
        if (seoFormik?.values?.metaKeywords.trim() !== "") {
          seoFormik.setFieldTouched(`metaKeywords`, true);
          const metaKeywordsExists = seoFormik?.values?.multipleMetaTags?.some(
            (tag) =>
              tag.toLowerCase().trim() === seoFormik?.values?.metaKeywords.toLowerCase().trim()
          );
          if (!metaKeywordsExists) {
            seoFormik?.setFieldValue(`multipleMetaTags`, [
              ...seoFormik?.values?.multipleMetaTags,
              seoFormik?.values?.metaKeywords,
            ]);
            seoFormik.setFieldValue(`metaKeywords`, "");
          } else {
            dispatch(
              showError({
                message: `${seoFormik?.values?.metaKeywords.trim()} already exists`,
              })
            );
          }
        }
      });
    }
  };

  const handleDelete = (data) => {
    const filterData = seoFormik?.values?.multipleMetaTags?.filter((v) => v !== data);
    seoFormik?.setFieldValue(`multipleMetaTags`, filterData);
  };

  const saveFormHandler = () => {
    onEnableEdit();
    seoFormik.handleSubmit();
  };

  const nextFormHandler = () => {
    onDisableEdit();
    seoFormik.handleSubmit();
  };

  return (
    <form
      noValidate
      onSubmit={seoFormik.handleSubmit}>
      <div className="bg-black-15 border-grey-5 rounded-8 p-3 row">
        <div className="d-flex col-12 px-0 justifu-content-between">
          <div className="d-flex align-items-center me-auto">
            <h6 className="text-lightBlue me-auto fw-500">Search Engine Optimisation</h6>
            <Tooltip
              title="Lorem ipsum"
              placement="top">
              <img
                src={info}
                alt="info"
                className="c-pointer ms-2"
                width={13.5}
              />
            </Tooltip>
          </div>
        </div>
        <div className="col-12 px-0 bg-black-13 border-grey-5 mt-3 rounded-8">
          <div className="d-flex flex-column p-3">
            <div className="d-flex justify-content-between">
              <p className="text-lightBlue">Metadata Preview</p>
              <small
                className="text-lightBlue text-blue-2 c-pointer"
                onClick={() => {
                  setViewAll((prevState) => !prevState);
                }}>
                {viewAll ? "Collapse" : "Edit"}
              </small>
            </div>
            <small className="text-lightBlue mt-3 mb-2 fw-500">
              Title : {seoFormik?.values?.title}
            </small>
            <small
              className="text-lightBlue mt-1 mb-2"
              style={{ wordWrap: "break-word" }}>
              Description :{" "}
              {seoFormik?.values?.description ? seoFormik?.values?.description : ""}
            </small>
            {/* <small
              className="text-blue-2"
              style={{ wordWrap: "break-word" }}>
              Url Handle : {formik?.values?.seo?.urlHandle}
            </small> */}
            <small className="mt-2 text-grey-6">
              {seoFormik?.values?.multipleMetaTags &&
                seoFormik?.values?.multipleMetaTags.map((item, index) => item).join(", ")}
            </small>
          </div>
        </div>

        {viewAll && (
          <React.Fragment>
            <div className="d-flex mb-1 mt-3 col-12 px-0">
              <small className="text-lightBlue">Page Title</small>
              <small style={{ color: "#F67476", paddingRight: 4, paddingLeft: 2 }}>*</small>
            </div>
            <FormControl className="col-12 px-0">
              <OutlinedInput
                name={`title`}
                placeholder="Please enter page title"
                size="small"
                value={seoFormik?.values?.title}
                onChange={seoFormik.handleChange}
                onBlur={seoFormik?.handleBlur}
              />
            </FormControl>
            {!!seoFormik?.touched?.title && seoFormik?.errors?.title ? (
              <FormHelperText
                error
                className="px-0">
                {seoFormik?.errors?.title}
              </FormHelperText>
            ) : (
              <small className="mt-1 text-grey-6 col-12 px-0">
                {seoFormik?.values?.title?.length} of 50 characters Used
              </small>
            )}
            <small className="text-lightBlue mb-2 mt-3 col-12 px-0">Meta Description</small>
            <TextareaAutosize
              name={`description`}
              aria-label="meta description"
              placeholder="Please enter meta description"
              style={{
                background: "#15142A",
                color: "#C8D8FF",
                borderRadius: 5,
              }}
              minRows={5}
              value={
                seoFormik?.values?.description
                  ? seoFormik?.values?.description
                  : seoFormik?.values?.description
              }
              onChange={seoFormik?.handleChange}
              onBlur={seoFormik?.handleBlur}
              className="col-12"
            />
            {!!seoFormik?.touched?.description && seoFormik?.errors?.description ? (
              <FormHelperText
                error
                className="px-0">
                {seoFormik?.errors?.description}
              </FormHelperText>
            ) : (
              <small className="mt-1 text-grey-6 col-12 px-0">
                {seoFormik?.values?.description?.length} of 500 characters Used
              </small>
            )}
            <small className="text-lightBlue mb-2 mt-3 col-12 px-0">Meta Keywords</small>
            <FormControl className="col-12 px-0">
              <OutlinedInput
                name={`metaKeywords`}
                value={seoFormik?.values?.metaKeywords}
                onChange={seoFormik?.handleChange}
                onBlur={seoFormik?.handleBlur}
                onKeyDown={handleAddMultiple}
                placeholder="Enter keywords"
                size="small"
                endAdornment={
                  <InputAdornment position="end">
                    <Tooltip
                      title="Create Multiple Meta Tags"
                      placement="top">
                      <ChevronRightIcon
                        className="c-pointer"
                        onClick={handleAddMultiple}
                      />
                    </Tooltip>
                  </InputAdornment>
                }
              />
              <div
                className="d-flex flex-wrap"
                style={{ wordWrap: "break-word" }}>
                {seoFormik?.values?.multipleMetaTags &&
                  seoFormik?.values?.multipleMetaTags.map((data, index) => {
                    return (
                      <Chip
                        key={index}
                        label={data}
                        onDelete={() => handleDelete(data)}
                        onClick={() => {}}
                        size="small"
                        className="mt-3 me-2"></Chip>
                    );
                  })}
              </div>
            </FormControl>
          </React.Fragment>
        )}
        <SaveFooterTertiary
          show={
            id
              ? !isCreateMode
                ? !_.isEqual(seoFormik.values, seoFormik.initialValues)
                : true
              : true
          }
          onDiscard={() => onBack(false)}
          discardButtonName="Previous"
          isLoading={createSeoIsLoading || editSeoIsLoading}
          saveButtonName="Save"
          // style={{ width: "105%" }}
        />
      </div>
    </form>
  );
}

export default SEOSecondary;
export { SEOTertiary };
