import apiSlice from "../../../app/api/apiSlice";

export const reviewApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getReviews: builder.query({
      query: (queries = {}) => {
        const queryString = new URLSearchParams(queries).toString();
        return { url: `/products/review?${queryString}` };
      },
      providesTags: ["Reviews"],
    }),

    createReview: builder.mutation({
      query: (data = {}) => ({
        url: "/products/review",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Reviews", "Logs"],
    }),

    editReview: builder.mutation({
      query: ({ id, details }) => ({
        url: `/products/review/${id}`,
        method: "PUT",
        body: details,
      }),
      invalidatesTags: ["Reviews", "Logs"],
    }),

    bulkEditReview: builder.mutation({
      query: (updates) => ({
        url: "/products/review/bulkUpdate",
        method: "PUT",
        body: updates,
      }),
      invalidatesTags: ["Reviews", "Logs"],
    }),

    deleteReview: builder.mutation({
      query: (id) => ({
        url: `/products/review/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Reviews", "Logs"],
    }),

    bulkDeleteReview: builder.mutation({
      query: (deletes) => ({
        url: "/products/review/bulkDelete",
        method: "DELETE",
        body: deletes,
      }),
      invalidatesTags: ["Reviews", "Logs"],
    }),
  }),
});

export const {
  useGetReviewsQuery,
  useCreateReviewMutation,
  useEditReviewMutation,
  useBulkEditReviewMutation,
  useDeleteReviewMutation,
  useBulkDeleteReviewMutation,
} = reviewApiSlice;
