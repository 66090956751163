import { useEffect, useRef, useState } from "react";
import { Tooltip, FormControl, FormHelperText, CircularProgress } from "@mui/material";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import { useDropzone } from "react-dropzone";
import { useDispatch } from "react-redux";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";

import {
  isHttpValid,
  isImgUrl,
  isVideoUrl,
  validateDropzoneSingleFile,
  validateDropzoneMultipleFiles,
} from "../../utils/helper";
import {
  DROPZONE_IMAGE_FORMAT,
  DROPZONE_VIDEO_FORMAT,
  VIDEO_EXTENSIONS,
  MAX_FILE_SIZE,
} from "../../utils/defaults";

import UseFileUpload from "../../features/fileUpload/fileUploadHook";
import UseMultipleFileUpload from "../../features/fileUpload/multipleFileUploadHook";
import { showError } from "../../features/snackbar/snackbarAction";

import CancelButton from "../CancelButton/CancelButton";

import "./uploadMedia.scss";

import info from "../../assets/icons/info.svg";
import { ReactComponent as UploadIcon } from "../../assets/icons/upload.svg";
import { ReactComponent as ImagePlaceHolder } from "../../assets/icons/imagePlaceHolder.svg";
import uploadProfile from "../../assets/icons/uploadProfile.svg";

const UploadMediaSmall = (props) => {
  const {
    disabled,
    fileSrc,
    error,
    onUpload,
    onBlur,
    name,
    module = "others",
    disableLabel,
    style,
    isSubmitting,
    touched,
    multiple = false,
    maxFiles = 1,
    isMandatory,
  } = props;
  const [blurred, setBlurred] = useState(false);
  const [uploadFile, { data, isSuccess, isError, isLoading }] = UseFileUpload();
  const [fileSource, setFileSource] = useState(null);
  const errorRef = useRef(null);
  const dispatch = useDispatch();

  const { getRootProps, getInputProps, isFocused, isFileDialogActive, inputRef, rootRef } =
    useDropzone({
      accept: {
        ...DROPZONE_IMAGE_FORMAT,
      },
      onDrop: (acceptedFiles, rejectedFiles) => {
        if (!multiple) {
          validateDropzoneSingleFile(rejectedFiles, MAX_FILE_SIZE);
          if (acceptedFiles[0] && !disabled) {
            uploadFile({ file: acceptedFiles[0], module, isMandatory });
          }
        }
      },
      disabled: disabled || isLoading,
      multiple,
      maxFiles,
      maxSize: MAX_FILE_SIZE,
    });

  const cancelHandler = (e) => {
    e.stopPropagation();
    inputRef.current.blur();
    rootRef.current.blur();
    setFileSource(null);
    onUpload("");
    setBlurred(true);
  };

  useEffect(
    () => {
      if (isError) {
        dispatch(showError({ message: "Failed to upload" }));
      }

      if (isSuccess && data) {
        onUpload(data?.url);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isError, isSuccess, data, dispatch]
  );

  useEffect(() => {
    if (isFocused) {
      setBlurred(true);
    }

    if (!isFileDialogActive && blurred && errorRef.current && error) {
      errorRef.current.textContent = error;
    }
  }, [errorRef, error, blurred, isFocused, isFileDialogActive]);

  useEffect(() => {
    if (isSubmitting || touched) {
      setBlurred(true);
    }
  }, [isSubmitting, touched]);

  useEffect(() => {
    isImgUrl(fileSrc).then((isValid) => {
      if (isValid) {
        setFileSource(fileSrc);
        return;
      }
      setFileSource(null);
    });
  }, [fileSrc]);

  return (
    <>
      {!disableLabel && (
        <div className="d-flex mb-1">
          <label
            htmlFor="file"
            className="small text-lightBlue me-2">
            Icon
          </label>
          <Tooltip
            title="Lorem ipsum"
            placement="top">
            <img
              src={info}
              alt="info"
              className=" c-pointer"
              width={13.5}
            />
          </Tooltip>
        </div>
      )}
      <div
        {...getRootProps({})}
        className={
          !disabled && document.activeElement === rootRef.current
            ? "small-upload-container focus"
            : "small-upload-container"
        }
        style={{ ...style, cursor: !disabled ? "pointer" : "" }}>
        {!disabled && fileSource && !isLoading && (
          <div className="cancel-button-container">
            <CancelButton
              tabIndex={-1}
              onClick={cancelHandler}
            />
          </div>
        )}
        <span className="icon-placeholder">
          {isLoading ? (
            <CircularProgress
              color="secondary"
              size={20}
            />
          ) : !fileSource ? (
            <ImagePlaceHolder
              className="svg"
              width={20}
              height={20}
            />
          ) : (
            <img
              src={fileSource}
              className="icon"
              alt="icon"
            />
          )}
        </span>
        <UploadIcon
          className="svg"
          width={20}
          height={20}
        />
        <span className="small text-lightBlue">
          {!disabled ? (isLoading ? "Uploading" : fileSource ? "Change" : "Upload Image") : ""}
        </span>
      </div>
      <FormControl className="w-100 px-0">
        <input
          name={name}
          onBlur={onBlur}
          id="file"
          {...getInputProps()}
          size="small"
        />
        {!fileSource && (
          <FormHelperText
            ref={errorRef}
            error></FormHelperText>
        )}
      </FormControl>
    </>
  );
};

const UploadMediaLarge = (props) => {
  const {
    fileSrc,
    error,
    onUpload,
    onBlur,
    name,
    module = "others",
    style,
    className,
    isSubmitting,
    touched,
    multiple = false,
    maxFiles = 1,
    isMandatory = false,
  } = props;
  const [uploadFile, { data, isSuccess, isError, isLoading }] = UseFileUpload();
  const [blurred, setBlurred] = useState(false);
  const [fileSource, setFileSource] = useState(null);
  const [isVideo, setIsVideo] = useState(null);
  const errorRef = useRef(null);
  const dispatch = useDispatch();

  const { getRootProps, getInputProps, isFocused, isFileDialogActive, inputRef, rootRef } =
    useDropzone({
      accept: {
        ...DROPZONE_IMAGE_FORMAT,
        ...DROPZONE_VIDEO_FORMAT,
      },
      onDrop: (acceptedFiles, rejectedFiles) => {
        if (!multiple) {
          validateDropzoneSingleFile(rejectedFiles, MAX_FILE_SIZE);
          if (acceptedFiles[0]) {
            uploadFile({ file: acceptedFiles[0], module, isMandatory });
          }
        }
      },
      disabled: isLoading || fileSource,
      multiple,
      maxFiles,
      maxSize: MAX_FILE_SIZE,
    });

  const cancelHandler = (e) => {
    e.stopPropagation();
    inputRef.current.blur();
    rootRef.current.blur();
    setFileSource(null);
    setIsVideo(null);
    onUpload("");
    setBlurred(true);
  };

  useEffect(
    () => {
      if (isError) {
        dispatch(showError({ message: "Failed to upload" }));
      }

      if (isSuccess && data) {
        onUpload(data?.url);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isError, isSuccess, data, dispatch]
  );

  useEffect(() => {
    if (isFocused) {
      setBlurred(true);
    }
    if (!isFileDialogActive && blurred && errorRef.current && error) {
      errorRef.current.textContent = error;
    }
  }, [errorRef, error, blurred, isFocused, isFileDialogActive]);

  useEffect(() => {
    if (isSubmitting || touched) {
      setBlurred(true);
    }
  }, [isSubmitting, touched]);

  useEffect(() => {
    if (fileSrc) {
      const fileNamePieces = fileSrc.split(".");
      setIsVideo(VIDEO_EXTENSIONS.includes(fileNamePieces[fileNamePieces.length - 1]));
    }
  }, [fileSrc]);

  useEffect(() => {
    if (isVideo !== null && fileSrc) {
      if (isVideo) {
        isVideoUrl(fileSrc).then((isValid) => {
          if (isValid) {
            setFileSource(fileSrc);
            return;
          }
          setFileSource(null);
        });
      } else {
        isImgUrl(fileSrc).then((isValid) => {
          if (isValid) {
            setFileSource(fileSrc);
            return;
          }
          setFileSource(null);
        });
      }
    }
  }, [fileSrc, isVideo]);

  return (
    <>
      <div
        {...getRootProps({})}
        className={
          document.activeElement === rootRef.current
            ? `large-upload-container focus ${className}`
            : `large-upload-container ${className}`
        }
        style={style}>
        {!isLoading && fileSource && (
          <div className="cancel-button-container">
            <CancelButton onClick={cancelHandler} />
          </div>
        )}
        {!fileSource || isLoading ? (
          <div className="upload-text">
            <div className="upload-icon">
              {isLoading ? (
                <CircularProgress
                  color="secondary"
                  size={30}
                />
              ) : (
                <>
                  <UploadIcon
                    className="svg"
                    width={20}
                    height={20}
                  />
                  <span className="small text-lightBlue">Add Image / Video</span>
                </>
              )}
            </div>
            <span className="small text-grey-6">
              {isLoading ? "uploading..." : "or drop files to upload"}
            </span>
          </div>
        ) : isVideo ? (
          <div style={{ position: "relative" }}>
            <video className="media">
              <source src={fileSource} />
            </video>
            <PlayCircleOutlineIcon
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%,-50%)",
              }}
            />
          </div>
        ) : (
          <img
            src={fileSource}
            className="media"
            alt="icon"
          />
        )}
      </div>
      <FormControl className="w-100 px-0">
        <input
          name={name}
          onBlur={onBlur}
          id="file"
          {...getInputProps()}
          size="small"
        />
        {!fileSource && !isLoading && (
          <FormHelperText
            ref={errorRef}
            error></FormHelperText>
        )}
      </FormControl>
    </>
  );
};

const UploadMultipleMediaLarge = (props) => {
  const {
    fileSrc,
    onUpload,
    onRemove,
    module = "others",
    style,
    className,
    multiple = true,
    maxFiles = 4,
  } = props;
  const [uploadFile, { data, isSuccess, isError, isLoading }] = UseMultipleFileUpload();
  const dispatch = useDispatch();

  const { getRootProps, getInputProps, inputRef, rootRef } = useDropzone({
    accept: {
      ...DROPZONE_IMAGE_FORMAT,
      ...DROPZONE_VIDEO_FORMAT,
    },
    onDrop: (acceptedFiles, rejectedFiles) => {
      validateDropzoneMultipleFiles(rejectedFiles, MAX_FILE_SIZE);
      uploadFile({ files: acceptedFiles });
    },
    disabled: isLoading,
    multiple,
    maxFiles,
    maxSize: MAX_FILE_SIZE,
  });

  useEffect(
    () => {
      if (isError) {
        dispatch(showError({ message: "Failed to upload" }));
      }

      if (isSuccess && data) {
        onUpload(data?.urls);
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isError, isSuccess, data, dispatch]
  );

  return (
    <>
      <div
        {...getRootProps({})}
        className={
          document.activeElement === rootRef.current
            ? `large-upload-container focus ${className}`
            : `large-upload-container ${className}`
        }
        style={style}>
        {!!fileSrc.length ? (
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr 1fr",
              gap: "1rem",
            }}>
            {fileSrc.map((file, index) => {
              const fileNamePieces = file.image.split(".");

              const isVideo = VIDEO_EXTENSIONS.includes(
                fileNamePieces[fileNamePieces.length - 1]
              );

              return (
                <div
                  key={index}
                  style={{ position: "relative" }}>
                  <div className="cancel-button-container">
                    <CancelButton
                      onClick={(e) => {
                        e.stopPropagation();
                        inputRef.current.blur();
                        rootRef.current.blur();
                        console.log(index, "called on Delete");
                        onRemove(index);
                      }}
                    />
                  </div>

                  {isVideo ? (
                    <div style={{ position: "relative" }}>
                      <video
                        className="media"
                        style={{
                          height: "15rem",
                          objectFit: "cover",
                          border: "1px dashed #38395c",
                          padding: "10px",
                        }}>
                        <source src={file.image} />
                      </video>
                      <PlayCircleOutlineIcon
                        fontSize="large"
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%,-50%)",
                        }}
                      />
                    </div>
                  ) : (
                    <img
                      src={file.image}
                      className="media"
                      style={{
                        height: "15rem",
                        objectFit: "cover",
                        border: "1px dashed #38395c",
                        padding: "10px",
                      }}
                      alt="icon"
                    />
                  )}
                </div>
              );
            })}
            <div
              className="media"
              style={{
                height: "15rem",
                objectFit: "cover",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                border: "1px dashed #38395c",
              }}>
              {isLoading ? (
                <CircularProgress
                  color="secondary"
                  size={30}
                />
              ) : (
                <>
                  <UploadIcon
                    className="svg"
                    width={20}
                    height={20}
                  />
                  <span
                    className="small text-lightBlue"
                    style={{ textAlign: "center" }}>
                    Add Image / Video
                  </span>
                </>
              )}
              <span
                className="small text-grey-6"
                style={{ textAlign: "center" }}>
                {isLoading ? "uploading..." : "or drop files to upload"}
              </span>
            </div>
          </div>
        ) : (
          <div className="upload-text">
            <div className="upload-icon">
              {isLoading ? (
                <CircularProgress
                  color="secondary"
                  size={30}
                />
              ) : (
                <>
                  <UploadIcon
                    className="svg"
                    width={20}
                    height={20}
                  />
                  <span className="small text-lightBlue">Add Image / Video</span>
                </>
              )}
            </div>
            <span className="small text-grey-6">
              {isLoading ? "uploading..." : "or drop files to upload"}
            </span>
          </div>
        )}
      </div>
      <FormControl className="w-100 px-0">
        <input
          // name={name}
          // onBlur={onBlur}
          id="file"
          {...getInputProps()}
          size="small"
        />
      </FormControl>
    </>
  );
};

const UploadMediaRound = (props) => {
  const {
    fileSrc,
    error,
    onUpload,
    onBlur,
    name,
    module = "others",
    style,
    isSubmitting,
    touched,
    multiple = false,
    maxFiles = 1,
    isMandatory,
  } = props;
  const [blurred, setBlurred] = useState(false);
  const [uploadFile, { data, isSuccess, isError, isLoading }] = UseFileUpload();
  const [fileSource, setFileSource] = useState(null);
  const errorRef = useRef(null);
  const dispatch = useDispatch();

  const { getRootProps, getInputProps, isFocused, isFileDialogActive, inputRef, rootRef } =
    useDropzone({
      accept: {
        ...DROPZONE_IMAGE_FORMAT,
      },
      onDrop: (acceptedFiles, rejectedFiles) => {
        if (!multiple) {
          validateDropzoneSingleFile(rejectedFiles, MAX_FILE_SIZE);
          if (acceptedFiles[0]) {
            uploadFile({ file: acceptedFiles[0], module, isMandatory });
          }
        }
      },
      disabled: isLoading,
      multiple,
      maxFiles,
      maxSize: MAX_FILE_SIZE,
    });

  const cancelHandler = (e) => {
    e.stopPropagation();
    inputRef.current.blur();
    rootRef.current.blur();
    setFileSource(null);
    onUpload("");
    setBlurred(true);
  };

  useEffect(() => {
    if (isError) {
      dispatch(showError({ message: "Failed to upload" }));
    }

    if (isSuccess && data) {
      onUpload(data?.url);
    }
  }, [isError, isSuccess, data, dispatch, onUpload]);

  useEffect(() => {
    if (isFocused) {
      setBlurred(true);
    }

    if (!isFileDialogActive && blurred && errorRef.current && error) {
      errorRef.current.textContent = error;
    }
  }, [errorRef, error, blurred, isFocused, isFileDialogActive]);

  useEffect(() => {
    if (isSubmitting || touched) {
      setBlurred(true);
    }
  }, [isSubmitting, touched]);

  useEffect(() => {
    isImgUrl(fileSrc).then((isValid) => {
      if (isValid) {
        setFileSource(fileSrc);
        return;
      }
      setFileSource(null);
    });
  }, [fileSrc]);

  return (
    <>
      <div
        {...getRootProps({})}
        className={
          document.activeElement === rootRef.current
            ? "round-upload-container focus"
            : "round-upload-container"
        }
        style={style}>
        {isLoading ? (
          <CircularProgress
            color="secondary"
            className=""
          />
        ) : (
          <img
            src={fileSource ? fileSource : uploadProfile}
            className="w-100 h-100 object-fit-cover rounded-circle"
            alt=""
          />
        )}
      </div>
    </>
  );
};

export { UploadMediaSmall, UploadMediaLarge, UploadMultipleMediaLarge, UploadMediaRound };
