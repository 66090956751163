import { FormControl, OutlinedInput,FormHelperText } from "@mui/material";

const StoreSocial = ({ image, placeholder, formik, field, values,errors,touched }) => {
  return (
    <div className="col-lg-12 d-flex align-items-center mb-4">
      <div className="col-lg-2 d-flex align-items-center">
        <img src={image} width={44} height={44} />
      </div>
      <div className="col-lg-10 align-items-center">
        <FormControl className="w-100 px-0">
          <OutlinedInput
            name={field}
            value={values}
            onChange={formik?.handleChange}
            onBlur={formik?.handleBlur}
            placeholder={placeholder}
            size="small"
          />
        </FormControl>
        {!!touched && errors && (
              <FormHelperText error>{errors}</FormHelperText>
            )}
      </div>
    </div>
  );
};

export default StoreSocial;
