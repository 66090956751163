import { useEffect, useReducer, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  OutlinedInput,
  Tooltip,
  FormControl,
  InputAdornment,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { LoadingButton } from '@mui/lab';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {
  SortableContainer,
  SortableHandle,
  SortableElement,
  arrayMove,
} from 'react-sortable-hoc';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import _ from 'lodash';
import {
  showSuccess,
  showError,
} from '../../../features/snackbar/snackbarAction';
import SnackbarUtils from '../../../features/snackbar/useSnackbar';

import { useFormikToastError } from '../../../hooks/error';

import { omitEmptyKeys, pickExactObjKeys } from '../../../utils/helper';
import { objectIdReg, stringNum } from '../../../utils/regex';

import SortDropdown from '../../../components/SortDropdown/SortDropdown';
import { TableSearchSecondary } from '../../../components/TableSearch/TableSearch';
import TableHeader from '../../../components/TableHeader/TableHeader';
import EditButton from '../../../components/EditButton/EditButton';
import RemoveIconButton from '../../../components/RemoveIconButton/RemoveIconButton';
import DeleteIconButton from '../../../components/DeleteIconButton/DeleteIconButton';
import NoDataFound from '../../../components/NoDataFound/NoDataFound';
import TableLoader from '../../../components/Loader/TableLoader';
import { DeleteModalSecondary } from '../../../components/DeleteModal/DeleteModal';

import cancel from '../../../assets/icons/cancel.svg';
import parameters from '../../../assets/icons/sidenav/parameters.svg';
import sort from '../../../assets/icons/sort.svg';
import arrowDown from '../../../assets/icons/arrowDown.svg';
import info from '../../../assets/icons/info.svg';

import {
  useGetStoneAttributeValueQuery,
  useCreateStoneAttributeMutation,
  useUpdateStoneAttributeMutation,
  useDeleteStoneAttributeMutation,
} from '../../../features/parameters/options/optionsApiSlice';

const TableBodySortable = SortableContainer(({ children }) => (
  <TableBody>{children}</TableBody>
));

TableBodySortable.muiName = 'TableBody';

const SortableRow = SortableElement(({ children }) => children);

const SORT_OPTIONS = [
  {
    id: 1,
    label: 'Newest to Oldest',
    value: -1,
    key: 'createdAt',
  },
  {
    id: 2,
    label: 'Oldest to Newest',
    value: 1,
    key: 'createdAt',
  },
  {
    id: 3,
    label: 'Alphabetical (A-Z)',
    value: 1,
    key: 'alphabetical',
  },
  {
    id: 4,
    label: 'Alphabetical (Z-A)',
    value: -1,
    key: 'alphabetical',
  },
];

const HEAD_CELLS = [
  {
    align: 'left',
    disablePadding: false,
    label: '',
    width: '1%',
  },
  {
    align: 'left',
    disablePadding: false,
    label: 'Title',
    width: '84%',
    style: {
      paddingLeft: 0,
    },
  },
  {
    align: 'right',
    disablePadding: false,
    label: 'Action',
    width: '15%',
  },
];

const PAGINATION_ROWS = [10, 20, 30];

const initialQueryFilterState = {
  pageSize: 10,
  pageNo: 1,
  attribute: null,
  title: '',
  createdAt: -1,
  alphabetical: null,
};

const initialPriceManagerState = {
  totalCount: 0,
  deleteId: null,
  editData: null,
  editId: '',
  confirmationMessage: '',
  showDeleteModal: false,
  showEditModal: false,
  showCreateModal: false,
  search: '',
};

const queryFilterReducer = (state, action) => {
  if (action.type === 'SET_PAGE_SIZE') {
    return {
      ...state,
      pageNo: initialQueryFilterState.pageNo,
      pageSize: action.size,
    };
  }
  if (action.type === 'CHANGE_PAGE') {
    return {
      ...state,
      pageNo: action.pageNo + 1,
    };
  }
  if (action.type === 'SEARCH_TITLE') {
    return {
      ...state,
      pageNo: initialQueryFilterState.pageNo,
      title: action.value,
    };
  }
  if (action.type === 'SORT') {
    return {
      ...state,
      createdAt: null,
      alphabetical: null,
      [action.name]: action.value,
    };
  }
  if (action.type === 'SET_ATTR') {
    return {
      ...state,
      attribute: action.attrId,
    };
  }
  return initialQueryFilterState;
};

const priceManagerReducer = (state, action) => {
  if (action.type === 'SET_TOTAL_COUNT') {
    return {
      ...state,
      totalCount: action.totalCount,
    };
  }
  if (action.type === 'SET_DELETE') {
    return {
      ...state,
      deleteId: action.id,
      confirmationMessage: action.message || '',
      showDeleteModal: true,
      editId: '',
      editData: null,
      showEditModal: false,
      showCreateModal: false,
    };
  }
  if (action.type === 'REMOVE_DELETE') {
    return {
      ...initialPriceManagerState,
      totalCount: state.totalCount,
      search: state.search,
    };
  }
  if (action.type === 'SEARCH_VALUE') {
    return {
      ...state,
      search: action.search,
    };
  }
  if (action.type === 'SET_ACTIVE_TAB') {
    return {
      ...state,
      activeTab: action.activeTab,
    };
  }
  if (action.type === 'SET_EDIT') {
    return {
      ...state,
      editData: action.data,
      editId: action.id,
      confirmationMessage: action.message || '',
      showDeleteModal: false,
      deleteId: '',
      showEditModal: true,
      showCreateModal: false,
    };
  }
  if (action.type === 'REMOVE_EDIT') {
    return {
      ...initialPriceManagerState,
      totalCount: state.totalCount,
      search: state.search,
    };
  }
  if (action.type === 'SET_CREATE') {
    return {
      ...state,
      editId: '',
      editData: null,
      confirmationMessage: '',
      showDeleteModal: false,
      deleteId: '',
      showEditModal: false,
      showCreateModal: true,
    };
  }
  if (action.type === 'REMOVE_CREATE') {
    return {
      ...initialPriceManagerState,
      totalCount: state.totalCount,
      search: state.search,
    };
  }

  return initialPriceManagerState;
};

const StoneAttrTable = (props) => {
  const { stoneAttribute, attr } = props;
  const dispatch = useDispatch();
  const [queryFilterState, dispatchQueryFilter] = useReducer(
    queryFilterReducer,
    initialQueryFilterState
  );
  const [priceManagerState, dispatchPriceManager] = useReducer(
    priceManagerReducer,
    initialPriceManagerState
  );

  const {
    data: stoneData,
    isLoading: stoneIsLoading,
    error: stoneError,
    isError: stoneIsError,
    isSuccess: stoneIsSuccess,
  } = useGetStoneAttributeValueQuery(
    { ...queryFilterState, stoneAttribute },
    {
      skip: stoneAttribute ? false : true,
    }
  );

  console.log({ ...queryFilterState, stoneAttribute });

  const [
    createStoneAttribute,
    {
      isLoading: createStoneAttributeIsLoading,
      isSuccess: createStoneAttributeIsSuccess,
      error: createStoneAttributeError,
      isError: createStoneAttributeIsError,
    },
  ] = useCreateStoneAttributeMutation();

  const [
    updateStoneAttribute,
    {
      isLoading: updateStoneAttributeIsLoading,
      isSuccess: updateStoneAttributeIsSuccess,
      error: updateStoneAttributeError,
      isError: updateStoneAttributeIsError,
    },
  ] = useUpdateStoneAttributeMutation();

  const [
    deleteStoneAttribute,
    {
      isLoading: deleteStoneAttributeIsLoading,
      isSuccess: deleteStoneAttributeIsSuccess,
      error: deleteStoneAttributeError,
      isError: deleteStoneAttributeIsError,
    },
  ] = useDeleteStoneAttributeMutation();

  const formik = useFormik({
    initialValues: {
      title: priceManagerState.showEditModal
        ? priceManagerState.editData.title
        : '',
      attribute: attr,
      stoneAttribute: stoneAttribute,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      title: Yup.string().trim().required('Required'),
    }),
    onSubmit: (values) => {
      const attrValues = omitEmptyKeys(values);
      SnackbarUtils.savingToast();
      if (priceManagerState.showEditModal) {
        updateStoneAttribute({
          id: priceManagerState.editId,
          details: attrValues,
        })
          .unwrap()
          .then(() => {
            SnackbarUtils.hideToast();
            formik.resetForm();
            dispatchPriceManager({ type: 'REMOVE_EDIT' });
            dispatch(
              showSuccess({
                message: 'Attribute created successfully',
              })
            );
          })
          .catch((error) => {
            SnackbarUtils.hideToast();
            if (error?.data?.message) {
              dispatch(showError({ message: error.data.message }));
            } else {
              dispatch(
                showError({
                  message: 'Something went wrong!, please try again',
                })
              );
            }
          });
        return;
      }
      createStoneAttribute(attrValues)
        .unwrap()
        .then(() => {
          SnackbarUtils.hideToast();
          formik.resetForm();
          dispatchPriceManager({ type: 'REMOVE_CREATE' });
          dispatch(
            showSuccess({
              message: 'Attribute created successfully',
            })
          );
        })
        .catch((error) => {
          SnackbarUtils.hideToast();
          if (error?.data?.message) {
            dispatch(showError({ message: error.data.message }));
          } else {
            dispatch(
              showError({
                message: 'Something went wrong!, please try again',
              })
            );
          }
        });
    },
  });

  //   const createAttrHandler = () => {
  //     createStoneAttribute(newAttr)
  //       .then(() => {
  //         dispatchPriceManager({ type: 'REMOVE_CREATE' });
  //         dispatch(
  //           showSuccess({
  //             message: 'Attribute created successfully',
  //           })
  //         );
  //       })
  //       .catch((error) => {
  //         if (error?.data?.message) {
  //           dispatch(showError({ message: error.data.message }));
  //         } else {
  //           dispatch(
  //             showError({
  //               message: 'Something went wrong!, please try again',
  //             })
  //           );
  //         }
  //       });
  //   };

  const onPageSize = (size) => {
    dispatchQueryFilter({ type: 'SET_PAGE_SIZE', size });
  };

  const onRowsPerPageChange = (e) => {
    onPageSize(e.target.value);
  };

  const searchHandler = (value) => {
    dispatchQueryFilter({ type: 'SEARCH_TITLE', value });
  };

  const searchValueHandler = (value) => {
    dispatchPriceManager({ type: 'SEARCH_VALUE', search: value });
  };

  const sortHandler = (e) => {
    const { name, value } = e.target;
    dispatchQueryFilter({ type: 'SORT', name, value });
  };

  const pageChangeHandler = (_, pageNo) => {
    dispatchQueryFilter({ type: 'CHANGE_PAGE', pageNo });
  };

  const sortEndHandler = ({ oldIndex, newIndex }) => {};

  const editHandler = ({ id, data }) => {
    dispatchPriceManager({
      type: 'SET_EDIT',
      id,
      message: '',
      data,
    });
  };

  const deleteHandler = ({ id }) => {
    dispatchPriceManager({
      type: 'SET_DELETE',
      id,
      message: '',
    });
  };

  const createHandler = () => {
    dispatchPriceManager({ type: 'SET_CREATE' });
  };

  const cancelCreateHandler = () => {
    dispatchPriceManager({ type: 'REMOVE_CREATE' });
  };

  const cancelEditHandler = () => {
    dispatchPriceManager({ type: 'REMOVE_EDIT' });
  };

  const cancelDeleteHandler = () => {
    dispatchPriceManager({ type: 'REMOVE_DELETE' });
  };

  const confirmDeleteHandler = () => {
    deleteStoneAttribute(priceManagerState.deleteId)
      .unwrap()
      .then(() => {
        dispatchPriceManager({
          type: 'REMOVE_DELETE',
        });
        dispatch(
          showSuccess({ message: 'Stone attribute deleted successfully' })
        );
      })
      .catch((error) => {
        if (error?.data?.message) {
          dispatch(showError({ message: error.data.message }));
        } else {
          dispatch(
            showError({
              message: 'Something went wrong!, please try again',
            })
          );
        }
      });
  };

  if (stoneIsError) {
    return <></>;
  }

  return (
    <>
      <div className='d-flex align-items-center mt-3 mb-3 px-2 justify-content-between'>
        <TableSearchSecondary
          onChange={searchHandler}
          onSearchValueChange={searchValueHandler}
          value={priceManagerState.search}
        />
        {/* <SortDropdown
          options={SORT_OPTIONS}
          onChange={sortHandler}
          query={queryFilterState}
        /> */}
        <button
          onClick={createHandler}
          className='button-gradient py-2 px-4 ms-2 c-pointer'
        >
          <p>Create</p>
        </button>
      </div>
      {stoneIsLoading ? (
        <TableLoader />
      ) : !stoneData?.data ? (
        <></>
      ) : stoneData?.data && !stoneData?.data.length ? (
        <NoDataFound />
      ) : (
        <>
          <TableContainer>
            <Table sx={{ minWidth: 750 }} size='medium'>
              <TableHeader headCells={HEAD_CELLS} />
              <TableBodySortable onSortEnd={sortEndHandler} useDragHandle>
                {stoneData?.data.map((item, index) => {
                  return (
                    <SortableRow index={index} key={item._id}>
                      <TableRow hover tabIndex={-1} className='table-rows'>
                        {/* <DragHandle /> */}
                        <TableCell />
                        <TableCell
                          //   onClick={onEdit.bind(null, item._id)}
                          sx={{
                            textTransform: 'capitalize',
                            cursor: 'pointer',
                            paddingLeft: 0,
                          }}
                        >
                          <p className='text-lightBlue fw-600'>{item.title}</p>
                        </TableCell>

                        <TableCell>
                          <div className='d-flex align-items-center justify-content-end'>
                            <EditButton
                              onClick={editHandler.bind(null, {
                                id: item._id,
                                data: item,
                              })}
                            />
                            <DeleteIconButton
                              onClick={deleteHandler.bind(null, {
                                id: item._id,
                              })}
                              title='Delete'
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                    </SortableRow>
                  );
                })}
              </TableBodySortable>
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={PAGINATION_ROWS}
            component='div'
            count={stoneData?.totalCount}
            rowsPerPage={+queryFilterState.pageSize}
            page={+queryFilterState.pageNo - 1}
            onPageChange={pageChangeHandler}
            onRowsPerPageChange={onRowsPerPageChange}
            className='table-pagination'
          />
        </>
      )}
      <Dialog
        // TransitionComponent={Transition}
        keepMounted
        aria-describedby='alert-dialog-slide-description'
        maxWidth='sm'
        fullWidth={true}
        open={
          priceManagerState.showEditModal || priceManagerState.showCreateModal
        }
        onClose={cancelCreateHandler}
      >
        <DialogTitle>
          <div className='d-flex justify-content-between align-items-center'>
            <div className='d-flex flex-column '>
              <h5 className='text-lightBlue fw-500'>
                {`${priceManagerState.showEditModal ? 'Edit' : 'Create'}`}
              </h5>

              <small className='text-grey-6 mt-1 d-block'>
                ⓘ Some Dummy Content to explain
              </small>
            </div>
            <img
              src={cancel}
              alt='cancel'
              width={30}
              onClick={cancelCreateHandler}
              className='c-pointer'
            />
          </div>
        </DialogTitle>
        <hr className='hr-grey-6 my-0' />

        <DialogContent className='py-3 px-4'>
          <div className='d-flex mb-2'>
            <p className='text-lightBlue '>Title</p>
            <span style={{ color: '#F67476', paddingRight: 4 }}>*</span>
            <Tooltip title='Enter Name' placement='top'>
              <img src={info} alt='info' className=' c-pointer' width={13.5} />
            </Tooltip>
          </div>
          <FormControl className='col-7 px-0'>
            <OutlinedInput
              sx={{ pr: 1 }}
              placeholder='Enter Title'
              size='small'
              name='title'
              value={formik.values.title}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              // onKeyDown={handleAddMultiple}
              //   endAdornment={
              //     <InputAdornment position='end'>
              //       <Tooltip title='Create Multiple' placement='top'>
              //         <ChevronRightIcon
              //           className='c-pointer'
              //           // onClick={handleAddMultiple}
              //         />
              //       </Tooltip>
              //     </InputAdornment>
              //   }
            />
            {!!formik.touched.title && formik.errors.title && (
              <FormHelperText error color='#F67476'>
                {formik.errors.title}
              </FormHelperText>
            )}
          </FormControl>
          <br />

          <div>
            {/* {multipleVendors &&
                multipleVendors.map((value, index) => (
                  <Chip
                    key={index}
                    label={value.name}
                    onDelete={() => handleDelete(value.name)}
                    size='small'
                    className='mt-3 me-2 px-1'
                  />
                ))} */}
          </div>
        </DialogContent>

        <hr className='hr-grey-6 my-0' />

        <DialogActions className='d-flex justify-content-between px-4 py-3'>
          <button
            className='button-grey py-2 px-5'
            onClick={
              priceManagerState.showEditModal
                ? cancelEditHandler
                : cancelCreateHandler
            }
            type='button'
          >
            <p className='text-lightBlue'>Cancel</p>
          </button>
          <LoadingButton
            onClick={formik.handleSubmit}
            loading={
              createStoneAttributeIsLoading || updateStoneAttributeIsLoading
            }
            disabled={
              createStoneAttributeIsLoading || updateStoneAttributeIsLoading
            }
            className='button-gradient py-2 px-5'
          >
            <p>Create</p>
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <DeleteModalSecondary
        onConfirm={confirmDeleteHandler}
        onCancel={cancelDeleteHandler}
        show={priceManagerState.showDeleteModal}
        isLoading={deleteStoneAttributeIsLoading}
      />
    </>
  );
};

export default StoneAttrTable;
