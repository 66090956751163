import React, { useEffect, useReducer, useState } from "react";
import "./Members.scss";
// ! COMPONENT IMPORTS
import MembersTable from "./MembersTable";
import TabPanel from "../../../components/TabPanel/TabPanel";
import ViewTutorial from "../../../components/ViewTutorial/ViewTutorial";
import ViewLogsDrawer from "../../../components/ViewLogsDrawer/ViewLogsDrawer";
import TableSearch, { TableSearchSecondary } from "../../../components/TableSearch/TableSearch";
import UploadFileRounded from "../../../components/UploadFileRounded/UploadFileRounded";
import AppMobileCodeSelect from "../../../components/AppMobileCodeSelect/AppMobileCodeSelect";
import ExportDialog from "../../../components/ExportDialog/ExportDialog";
import ImportSecondDialog from "../../../components/ImportSecondDialog/ImportSecondDialog";
// ! IMAGES IMPORTS
import info from "../../../assets/icons/info.svg";
import uploadProfile from "../../../assets/icons/uploadProfile.svg";
import teams from "../../../assets/icons/sidenav/teams.svg";
// ! MATERIAL IMPORTS
import {
  Box,
  Paper,
  Tab,
  Tabs,
  FormControl,
  InputAdornment,
  OutlinedInput,
  SwipeableDrawer,
  Tooltip,
  Select,
  MenuItem,
} from "@mui/material";
// ! MATERIAL ICONS IMPORT
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import {
  useEditProfileMutation,
  useGetAllProfileDetailsQuery,
} from "../../../features/myProfile/myProfileApiSlice";
import { useDispatch } from "react-redux";
import { showError } from "../../../features/snackbar/snackbarAction";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import AddMembers from "./AddMembers";
import {
  useBulkDeleteMemberMutation,
  useBulkUpdateMemberMutation,
  useDeleteMemberMutation,
  useGetAllMembersStatusCountQuery,
} from "../../../features/team/membersApiSlice";

const initialQueryFilterState = {
  pageSize: 10,
  pageNo: 1,
  fullName: "",
  searchValue: "",
  createdAt: "-1",
  status: ["active", "invited"],
};
const queryFilterReducer = (state, action) => {
  if (action.type === "SET_PAGE_SIZE") {
    return {
      ...state,
      pageNo: initialQueryFilterState.pageNo,
      pageSize: +action.value,
    };
  }
  if (action.type === "CHANGE_PAGE") {
    return {
      ...state,
      pageNo: action.pageNo + 1,
    };
  }
  if (action.type === "SEARCH") {
    return {
      ...state,
      pageNo: initialQueryFilterState.pageNo,
      fullName: action.fullName,
    };
  }
  if (action.type === "SET_SEARCH_VALUE") {
    return {
      ...state,
      pageNo: initialQueryFilterState.pageNo,
      searchValue: action.searchValue,
    };
  }
  if (action.type === "SET_ALL_FILTERS") {
    return {
      ...initialQueryFilterState,
      ...action.filters,
    };
  }

  if (action.type === "SET_STATUS") {
    return {
      ...state,
      pageNo: initialQueryFilterState.pageNo,
      status: action.status ? action.status : initialQueryFilterState.status,
    };
  }

  return initialQueryFilterState;
};
const initialMembersState = {
  data: [],
  totalCount: 0,
  membersType: 0,
};
const membersReducer = (state, action) => {
  if (action.type === "SET_DATA") {
    return {
      ...state,
      data: action.data,
      totalCount: action.totalCount,
    };
  }
  if (action.type === "SET_MEMBERS_TYPE") {
    return {
      ...state,
      membersType: action.membersType,
    };
  }
  return initialMembersState;
};

const Members = () => {
  const [value, setValue] = React.useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const Navigate = useNavigate();
  const [firstRender, setFirstRender] = useState(true);
  const dispatch = useDispatch();
  const [memberId, setMemberId] = useState("");

  const [queryFilterState, dispatchQueryFilter] = useReducer(
    queryFilterReducer,
    initialQueryFilterState
  );
  const [membersState, dispatchMembers] = useReducer(membersReducer, initialMembersState);
  const {
    data: membersData,
    isLoading: membersDataIsLoading,
    isSuccess: membersDataIsSuccess,
    error: membersDataError,
    isError: membersDataIsError,
  } = useGetAllProfileDetailsQuery(queryFilterState);

  const [editMember] = useEditProfileMutation();

  const [bulkUpdateMember] = useBulkUpdateMemberMutation();

  const [deleteMember] = useDeleteMemberMutation();

  const [bulkDeleteMember] = useBulkDeleteMemberMutation();

  const { data: membersStatusCount } = useGetAllMembersStatusCountQuery();

  const handleMembersTypeChangeHandler = (event, tabIndex) => {
    if (tabIndex === 0) {
      dispatchQueryFilter({
        type: "SET_STATUS",
        status: ["active", "invited"],
      });
    } else if (tabIndex === 1) {
      dispatchQueryFilter({
        type: "SET_STATUS",
        status: ["archieved"],
      });
    }
    dispatchMembers({
      type: "SET_MEMBERS_TYPE",
      membersType: tabIndex,
    });
    dispatchQueryFilter({ type: "SET_SEARCH_VALUE", searchValue: "" });
    dispatchQueryFilter({ type: "SEARCH", fullName: "" });
  };
  const handleChangeRowsPerPage = (event) => {
    dispatchQueryFilter({ type: "SET_PAGE_SIZE", value: event.target.value });
  };

  const handleChangePage = (_, pageNo) => {
    dispatchQueryFilter({ type: "CHANGE_PAGE", pageNo });
  };

  const handleSearchChange = (value) => {
    dispatchQueryFilter({ type: "SEARCH", fullName: value });
  };

  const handleSearchValue = (value) => {
    dispatchQueryFilter({ type: "SET_SEARCH_VALUE", searchValue: value });
  };

  const handleEditMember = (member) => {
    setAddProductDrawer({ ...addProductDrawer, right: true });
    setMemberId(member?._id);
  };

  const handleAddMember = () => {
    setAddProductDrawer({ ...addProductDrawer, right: true });
    setMemberId("");
  };

  const handleMemberDetailsNavigation = (data) => {
    Navigate({
      pathname: `./details/${data ? data._id : ""}`,
      search: `?${createSearchParams({
        filter: JSON.stringify({
          ...queryFilterState,
          membersType: membersState.membersType,
        }),
      })}`,
    });
  };

  useEffect(() => {
    if (membersDataIsSuccess) {
      if (membersState.membersType === 0) {
        dispatchMembers({
          type: "SET_DATA",
          data: membersData?.data?.data,
          totalCount: membersData?.data?.totalCount,
        });
      }
      if (membersState.membersType === 1) {
        dispatchMembers({
          type: "SET_DATA",
          data: membersData?.data?.data,
          totalCount: membersData?.data?.totalCount,
        });
      }
    }
    if (membersDataIsError) {
      if (membersDataError?.data?.message) {
        dispatch(showError({ message: membersDataError.data.message }));
      } else {
        dispatch(showError({ message: "Something went wrong!, please try again" }));
      }
    }
  }, [
    membersDataIsSuccess,
    membersDataIsError,
    membersDataError,
    dispatch,
    membersData,
    membersState.membersType,
    // deleteDiscountIsSuccess,
    // bulkDeleteDiscountIsSuccess,
  ]);

  useEffect(() => {
    const filterParams = JSON.parse(searchParams.get("filter")) || {
      membersType: membersState.membersType,
    };
    if (firstRender && Object.keys(filterParams).length) {
      let filters = {};
      for (let key in filterParams) {
        if (key !== "membersType") {
          if (filterParams[key] !== (null || "")) {
            filters = {
              ...filters,
              [key]: filterParams[key],
            };
          }
        } else {
          dispatchMembers({
            type: "SET_MEMBERS_TYPE",
            membersType: +filterParams[key],
          });
        }
      }
      if (filterParams.membersType === (null || "")) {
        dispatchMembers({ type: "SET_MEMBERS_TYPE", membersType: 0 });
      }
      dispatchQueryFilter({
        type: "SET_ALL_FILTERS",
        filters,
      });
      setFirstRender(false);
    }
  }, [searchParams]);

  useEffect(() => {
    if (!firstRender) {
      setSearchParams({
        filter: JSON.stringify({
          ...queryFilterState,
          membersType: membersState.membersType,
        }),
      });
    }
  }, [queryFilterState, setSearchParams, membersState.membersType, firstRender]);

  // ? ADD MEMBER DRAWER STARTS HERE

  const [addProductDrawer, setAddProductDrawer] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleAddMemberDrawer = (anchor, open) => (event) => {
    if (event && event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }

    setAddProductDrawer({ ...addProductDrawer, [anchor]: open });
  };
  // ? ADD MEMBER DRAWER ENDS HERE

  return (
    <div className="container-fluid page">
      <div className="row justify-content-between align-items-center">
        <div className="d-flex w-auto align-items-center px-0">
          <h4 className="page-heading w-auto ps-0 me-2">Team Members</h4>
          <InfoOutlinedIcon sx={{ color: "#c8d8ff", fontSize: 20 }} />
        </div>
        <div className="d-flex align-items-center w-auto pe-0">
          {/* <ViewTutorial /> */}
          <ViewLogsDrawer
            headingName={"Team Role Module"}
            icon={teams}
            module="teams"
          />
          <ExportDialog dialogName={"Team Members"} />
          <ImportSecondDialog dialogName={"Team Members"} />
          <button
            className="button-gradient py-2 px-4"
            onClick={handleAddMember}>
            <p>+ Add Team Member</p>
          </button>
          <AddMembers
            toggleAddMemberDrawer={toggleAddMemberDrawer}
            addProductDrawer={addProductDrawer}
            setAddProductDrawer={setAddProductDrawer}
            id={memberId}
            reset={true}
          />
        </div>
      </div>

      <div className="row">
        <Paper
          sx={{ width: "100%", mb: 0, mt: 3, p: 0 }}
          className="border-grey-5 bg-black-15">
          <Box
            sx={{ width: "100%" }}
            className="d-flex justify-content-between tabs-header-box">
            {/* variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile */}
            <Tabs
              value={membersState?.membersType}
              onChange={handleMembersTypeChangeHandler}
              aria-label="scrollable force tabs example"
              className="tabs">
              <Tab
                label={`All ${
                  membersStatusCount?.data[0]?.active
                    ? `(${membersStatusCount?.data[0]?.active})`
                    : ""
                }`}
                className="tabs-head"
              />
              <Tab
                label={`Archived ${
                  membersStatusCount?.data[0]?.archived
                    ? `(${membersStatusCount?.data[0]?.archived})`
                    : ""
                }`}
                className="tabs-head"
              />
            </Tabs>
          </Box>
          <div className="d-flex align-items-center mt-3 mb-3 px-2 justify-content-between">
            <TableSearchSecondary
              onSearchValueChange={handleSearchValue}
              value={queryFilterState.searchValue}
              onChange={handleSearchChange}
            />
          </div>
          <TabPanel
            value={membersState.membersType}
            index={0}>
            <MembersTable
              isLoading={membersDataIsLoading}
              list={membersState?.data}
              totalCount={membersState?.totalCount}
              changeRowsPerPage={handleChangeRowsPerPage}
              rowsPerPage={queryFilterState.pageSize}
              changePage={handleChangePage}
              page={queryFilterState.pageNo}
              membersType={membersState?.membersType}
              edit={handleEditMember}
              editMember={editMember}
              bulkEdit={bulkUpdateMember}
              detailsPage={handleMemberDetailsNavigation}
            />
          </TabPanel>
          <TabPanel
            value={membersState.membersType}
            index={1}>
            <MembersTable
              isLoading={membersDataIsLoading}
              list={membersState?.data}
              totalCount={membersState?.totalCount}
              changeRowsPerPage={handleChangeRowsPerPage}
              rowsPerPage={queryFilterState.pageSize}
              changePage={handleChangePage}
              page={queryFilterState.pageNo}
              membersType={membersState?.membersType}
              editMember={editMember}
              bulkEdit={bulkUpdateMember}
              deleteData={deleteMember}
              bulkDelete={bulkDeleteMember}
              detailsPage={handleMemberDetailsNavigation}
            />
          </TabPanel>
          <TabPanel
            value={value}
            index={2}>
            <MembersTable />
          </TabPanel>
        </Paper>
      </div>
    </div>
  );
};

export default Members;
