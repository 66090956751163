import { useMemo, useState } from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Popover,
  SwipeableDrawer,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import ProductDrawerTable from "../ProductDrawerTable";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import DownloadDoneIcon from "@mui/icons-material/DownloadDone";
import product2Icon from "../../../assets/images/products/product2.jpg";
import arrowDownIcon from "../../../assets/icons/arrowDown.svg";
import { useGetAllVariantsQuery } from "../../../features/products/product/varianceApiSlice";
import AsyncImage from "../../../components/AsyncImage/AsyncImage";
import { PriceBreakup } from "../AddProduct/Variants/Variants";
import { useBulkAddOrUpdateMutation } from "../../../features/products/product/inventoryAPISlice";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { showError, showSuccess } from "../../../features/snackbar/snackbarAction";
import _ from "lodash";
import { LoadingButton } from "@mui/lab";
import TableLoader from "../../../components/Loader/TableLoader";
import { TableSearchSecondary } from "../../../components/TableSearch/TableSearch";

const headCells = [
  {
    id: "variant",
    numeric: false,
    disablePadding: false,
    label: "Variant",
  },
  {
    id: "price",
    numeric: false,
    disablePadding: false,
    label: "Price",
  },
  {
    id: "addQuantity",
    numeric: false,
    disablePadding: false,
    label: "Add Quantity",
  },
];

export default function VariantsSideDrawer({
  storeId = "",
  inventory = {},
  isOpen = false,
  onClose = () => {},
}) {
  const dispatch = useDispatch();

  const [search, setSearch] = useState("");
  const [searchDeb, setSearchDeb] = useState("");

  const {
    _id: inventoryId = "",
    _id: productId = "",
    title = "",
    mediaUrl = [],
    inventories = [],
    inventory: { skuId = "", inventoryOn = "" } = {},
    // store: { _id: nestedStoreId, quantityCount = 0 } = {},
  } = inventory ?? {};

  // console.log("inventory", inventory);

  const { quantityCount = 0, store: nestedStoreId } = inventories?.[0]?.store ?? {};
  const imageUrl = mediaUrl?.find((mu) => mu.isDefault)?.image ?? "";

  const pageSize = 10;
  const [pageNo, setPageNo] = useState(1);

  const { data: allVariantsData, isFetching: allVariantsIsLoading } = useGetAllVariantsQuery(
    {
      store: storeId,
      productId,
      pageSize,
      pageNo,
      title: searchDeb,
    },
    { skip: !isOpen || inventoryOn !== "variant" }
  );
  const productVariants = allVariantsData?.data?.data ?? [];
  const totalVariants = allVariantsData?.data?.totalCount ?? 0;
  const maxPages = Math.ceil(totalVariants / pageSize);

  // console.log("productVariants", productVariants);

  const [bulkUpdateQuantity, { isLoading: bulkUpdateQuantityIsLoading }] =
    useBulkAddOrUpdateMutation();

  const formik = useFormik({
    initialValues:
      inventoryOn === "variant"
        ? productVariants.map((variant) => ({
            id: variant?.inventory?._id ?? "",
            updates: [
              {
                id: variant?.inventory?.store?._id,
                store: storeId,
                quantityCount: variant?.inventory?.store?.quantityCount ?? 0,
              },
            ],
          }))
        : [
            {
              id: inventoryId,
              updates: [
                {
                  id: nestedStoreId,
                  store: storeId,
                  quantityCount: quantityCount,
                },
              ],
            },
          ],
    enableReinitialize: true,
    onSubmit: (values) => {
      bulkUpdateQuantity(values)
        .unwrap()
        .then(() => {
          dispatch(showSuccess({ message: "Quantity updated successfully" }));
        })
        .catch((e) =>
          dispatch(
            showError({
              message: e?.data?.message ?? e?.message ?? "Something went wrong",
            })
          )
        );
    },
  });
  const isUnchanged = useMemo(
    () => _.isEqual(formik.values, formik.initialValues),
    [formik.values, formik.initialValues]
  );

  const [bulkQtyValue, setBulkQtyValue] = useState(0);

  const updateAllQtys = () => {
    formik.setValues(
      formik.values.map((val) => ({
        id: val.id,
        updates: [{ ...val.updates[0], quantityCount: bulkQtyValue }],
      }))
    );
    setBulkQtyValue(0);
  };

  const closeDrawer = () => {
    setSearch("");
    setSearchDeb("");
    onClose();
  };

  return (
    <SwipeableDrawer
      anchor="right"
      open={isOpen}
      onOpen={Date}
      onClose={closeDrawer}
      className="product-drawer">
      <div className="d-flex align-items-center pt-3 px-3">
        <KeyboardArrowLeftOutlinedIcon
          sx={{ fontSize: 25, color: "#c8d8ff" }}
          onClick={closeDrawer}
          className="c-pointer"
        />
        <div className="d-flex justify-content-between align-items-center w-100">
          <div className="d-flex">
            <AsyncImage
              src={imageUrl}
              placeholder={product2Icon}
              alt="product"
              className="me-2 rounded-4"
              height={45}
              width={45}
            />
            <div>
              <p className="text-lightBlue rounded-circle fw-600">{title}</p>
              <small className="text-grey-6 mt-1">SKU: {skuId}</small>
            </div>
          </div>
          <p className="text-lightBlue fw-600">
            {inventoryOn === "product" && "Quantity is managed at Product Level"}
            {inventoryOn === "variant" && "Quantity is managed at Variant Level"}
          </p>
        </div>
      </div>
      <div className="px-3">
        <hr className="hr-grey-6 mt-3 mb-3" />
      </div>

      {inventoryOn === "variant" && (
        <div className="d-flex px-3">
          <TableSearchSecondary
            value={search}
            onChange={(val) => setSearchDeb(val)}
            onSearchValueChange={(val) => setSearch(val)}
          />
          <FormControl className="mx-2">
            <OutlinedInput
              type="number"
              value={bulkQtyValue}
              onChange={(e) => setBulkQtyValue(e.target.value)}
              placeholder="Enter Bulk Quantity"
              size="small"
            />
          </FormControl>
          <IconButton
            onClick={updateAllQtys}
            size="small">
            <DownloadDoneIcon
              size="small"
              aria-label="punch"
              sx={{ color: "#c8d8ff" }}
            />
          </IconButton>
        </div>
      )}
      <div className="px-3">
        <hr className="hr-grey-6 mt-3 mb-3" />
      </div>
      <div className="px-3">
        <div className="row">
          {inventoryOn === "product" && (
            <div className="col-12 d-flex justify-content-between align-items-center">
              <p
                className="text-lightBlue fw-50"
                style={{ width: 200 }}>
                Update Quantity
              </p>
              <div className="d-flex discount-inputs-two">
                <FormControl
                  sx={{ width: 100 }}
                  className="col-7 px-0 productInfo-inputs">
                  <OutlinedInput
                    type="number"
                    name="0.updates.0.quantityCount"
                    value={formik.values?.[0]?.updates?.[0]?.quantityCount ?? 0}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Enter Quantity"
                    size="small"
                  />
                  <FormHelperText error>
                    {formik.touched?.[0]?.updates?.[0]?.quantityCount &&
                      formik.errors?.[0]?.updates?.[0]?.quantityCount}
                  </FormHelperText>
                </FormControl>
                <LoadingButton
                  onClick={() => formik.handleSubmit()}
                  loading={bulkUpdateQuantityIsLoading}
                  disabled={bulkUpdateQuantityIsLoading}
                  className="button-gradient py-2 px-4">
                  <p>Apply</p>
                </LoadingButton>
              </div>
            </div>
          )}
          {inventoryOn === "variant" && (
            <div className="col-12 px-0">
              <TableContainer>
                {allVariantsIsLoading ? (
                  <TableLoader />
                ) : (
                  <Table size="medium">
                    <TableHead>
                      <TableRow>
                        {headCells.map((headCell) => {
                          return (
                            <TableCell
                              key={headCell.id}
                              align={headCell.numeric ? "right" : "left"}
                              padding={headCell.disablePadding ? "none" : "normal"}>
                              <p className="text-lightBlue">{headCell.label}</p>
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {productVariants.map((variant, ix) => {
                        return (
                          <TableRow
                            hover
                            tabIndex={-1}
                            key={variant._id}
                            className="table-rows">
                            <TableCell
                              component="th"
                              scope="row"
                              padding="normal"
                              className="py-1">
                              <div className="d-flex align-items-center py-3">
                                <AsyncImage
                                  src={imageUrl}
                                  placeholder={product2Icon}
                                  alt="product"
                                  className="me-2 rounded-4"
                                  height={40}
                                  width={40}
                                />
                                <div>
                                  <p className="text-lightBlue rounded-circle fw-600">
                                    {[
                                      ...variant.attributes
                                        .filter(
                                          (at) =>
                                            at.title !== "metal" &&
                                            at.title !== "diamond" &&
                                            at.title !== "gemstone" &&
                                            at.title !== "pearl"
                                        )
                                        .flatMap((at) => {
                                          const optns = variant.options.filter(
                                            (op) => op.metaAttribute.data?.attribute === at._id
                                          );
                                          return optns.map((op) => op.metaAttribute.data.title);
                                        }),
                                      ...variant.attributes
                                        .filter((at) => at.title === "metal")
                                        .flatMap((at) => {
                                          const optns = variant.options.filter(
                                            (op) => op.metaAttribute.data?.attribute === at._id
                                          );
                                          return optns.flatMap((op) => [
                                            op.metaAttribute.data.title,
                                            op.metaAttribute.metaSubAttribute2
                                              .metaSubAttributeValue.title,
                                            op.metaAttribute.metaSubAttribute1
                                              .metaSubAttributeValue.title,
                                          ]);
                                        }),
                                      ...variant.attributes
                                        .filter((at) => at.title === "diamond")
                                        .flatMap((at) => {
                                          const optns = variant.options.filter(
                                            (op) => op.metaAttribute.data?.attribute === at._id
                                          );
                                          return optns.map((op) => op.metaAttribute.data.title);
                                        }),
                                      ...variant.attributes
                                        .filter((at) => at.title === "gemstone")
                                        .flatMap((at) => {
                                          const optns = variant.options.filter(
                                            (op) => op.metaAttribute.data?.attribute === at._id
                                          );
                                          return optns.map((op) => op.metaAttribute.data.title);
                                        }),
                                      ...variant.attributes
                                        .filter((at) => at.title === "pearl")
                                        .flatMap((at) => {
                                          const optns = variant.options.filter(
                                            (op) => op.metaAttribute.data?.attribute === at._id
                                          );
                                          return optns.map((op) => op.metaAttribute.data.title);
                                        }),
                                    ].join(" • ")}
                                  </p>
                                  <small className="text-grey-6 mt-1">SKU: {skuId}</small>
                                </div>
                              </div>
                            </TableCell>
                            <TableCell
                              style={{ width: 160 }}
                              padding="normal"
                              className="py-1">
                              <PriceBreakup variant={variant} />
                            </TableCell>
                            <TableCell
                              style={{ width: 140 }}
                              padding="normal"
                              className="py-1">
                              <div className="d-flex align-items-center py-2">
                                <FormControl
                                  sx={{ width: 100 }}
                                  className="col-7 px-0 productInfo-inputs">
                                  <OutlinedInput
                                    type="number"
                                    name={`${ix}.updates.0.quantityCount`}
                                    value={
                                      formik.values?.[ix]?.updates?.[0]?.quantityCount ?? 0
                                    }
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    placeholder="Enter Quantity"
                                    size="small"
                                  />
                                  <FormHelperText error>
                                    {formik.touched?.[ix]?.updates?.[0]?.quantityCount &&
                                      formik.errors?.[ix]?.updates?.[0]?.quantityCount}
                                  </FormHelperText>
                                </FormControl>
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                )}
                <p className="text-lightBlue text-center fw-500 mt-3">
                  {(pageNo - 1) * pageSize + 1} - {(pageNo - 1) * pageSize + pageSize} of{" "}
                  {totalVariants}
                </p>
              </TableContainer>
            </div>
          )}
        </div>
      </div>
      {inventoryOn === "variant" && (
        <div className="row align-items-center sidedrawer-buttons m-3">
          {isUnchanged ? (
            <div className="col-12 d-flex justify-content-between">
              <button
                disabled={pageNo <= 1}
                onClick={() => setPageNo(pageNo - 1)}
                className="button-red-outline py-2 px-4">
                <p>Previous</p>
              </button>
              <button
                disabled={pageNo >= maxPages}
                onClick={() => setPageNo(pageNo + 1)}
                className="button-gradient py-2 px-4">
                <p>Next</p>
              </button>
            </div>
          ) : (
            <div className="col-12 d-flex justify-content-center">
              <LoadingButton
                onClick={() => formik.handleSubmit()}
                loading={bulkUpdateQuantityIsLoading}
                disabled={bulkUpdateQuantityIsLoading}
                className="button-gradient py-2 px-4">
                <p>Save Changes</p>
              </LoadingButton>
            </div>
          )}
        </div>
      )}
    </SwipeableDrawer>
  );
}

/* <FormControl
                  sx={{ width: 100 }}
                  className="col-7 px-0 productInfo-inputs">
                  <OutlinedInput
                    placeholder="Enter Qty"
                    size="small"
                    value={formik.values?.[0]?.updates?.[0]?.quantityCount ?? 0}
                    endAdornment={
                      <InputAdornment
                        position="end"
                        className="c-pointer"
                        onClick={() =>
                          formik.setFieldValue(
                            "0.updates.0.quantityCount",
                            (formik.values?.[0]?.updates?.[0]?.quantityCount ?? 0) + 1
                          )
                        }>
                        +
                      </InputAdornment>
                    }
                    startAdornment={
                      <InputAdornment
                        position="start"
                        className="c-pointer"
                        onClick={() =>
                          formik.setFieldValue(
                            "0.updates.0.quantityCount",
                            Math.max(
                              0,
                              (formik.values?.[0]?.updates?.[0]?.quantityCount ?? 0) - 1
                            )
                          )
                        }>
                        -
                      </InputAdornment>
                    }
                  />
                </FormControl> */

/* <button
                  onClick={() => formik.handleSubmit()}
                  className="button-gradient py-1 px-3 w-auto ms-2">
                  <p>Apply</p>
                </button> */

/* <FormControl
                                  sx={{ width: 100 }}
                                  className="col-7 px-0 productInfo-inputs">
                                  <OutlinedInput
                                    placeholder="Enter Qty"
                                    size="small"
                                    value={quantity}
                                    endAdornment={
                                      <InputAdornment
                                        position="end"
                                        className="c-pointer"
                                        onClick={() =>
                                          formik.setFieldValue(
                                            `${ix}.updates.0.quantityCount`,
                                            quantity + 1
                                          )
                                        }>
                                        +
                                      </InputAdornment>
                                    }
                                    startAdornment={
                                      <InputAdornment
                                        position="start"
                                        className="c-pointer"
                                        onClick={() =>
                                          formik.setFieldValue(
                                            `${ix}.updates.0.quantityCount`,
                                            Math.max(0, quantity - 1)
                                          )
                                        }>
                                        -
                                      </InputAdornment>
                                    }
                                  />
                                </FormControl> */

/* <div className="d-flex flex-column py-3 px-4 product-buttons">
        <hr className="hr-grey-6 my-3 w-100" />
        <div className="d-flex justify-content-between">
          <button
            className="button-gradient py-2 px-5 w-auto"
            // onClick={toggleProductDrawer("right", false)}
          >
            <p>Save</p>
          </button>
          <button
            className="button-lightBlue-outline py-2 px-4"
            // onClick={toggleProductDrawer("right", false)}
          >
            <p>Cancel</p>
          </button>
        </div>
      </div> */
