import { useMemo, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import { useGetAllStateQuery } from "../../features/master/state/stateApiSlice";

export default function AppStateSelect({
  getStateName,
  initValue,
  disabled = false,
  pincode = "",
  state,
}) {
  const {
    data: stateData,
    isLoading: stateIsLoading,
    isSuccess: stateIsSuccess,
    error: stateError,
  } = useGetAllStateQuery({ createdAt: -1, pincode });

  const stateList = useMemo(() => {
    const list = [];
    const stateList = Array.from(stateData?.data?.data || []);
    list.push(...stateList);
    if (state) {
      if (stateList?.findIndex((e) => e?._id === state?._id) === -1) {
        list.push(state);
      }
    }
    return list;
  }, [state, stateData]);

  const selectedCode = useMemo(
    () => stateList?.find((state) => state._id === initValue),
    [initValue, stateList]
  );

  return (
    <Autocomplete
      disabled={disabled}
      sx={{ width: 300 }}
      options={stateList}
      autoHighlight
      size="small"
      getOptionLabel={(option) => option?.name}
      onChange={getStateName}
      value={selectedCode || null}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
          {...props}
        >
          <small className="text-lightBlue my-1">{option.name}</small>
        </Box>
      )}
      renderTags={(value) => (
        <small className="fw-400 text-lightBlue">{value.name}</small>
      )}
      renderInput={(params) => (
        <TextField {...params} label="" placeholder="Select State" />
      )}
    />
  );
}
