import { useState } from 'react';
import { Box, Popover, SwipeableDrawer, Tooltip, Tab, Tabs } from "@mui/material";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import TabPanel from "../../../../components/TabPanel/TabPanel";
import GeneralSetting from '../GeneralSetting';

import indiaFlag from "../../../../assets/images/products/indiaFlag.svg";
import usaFlag from "../../../../assets/images/products/usaFlag.svg";
import ukFlag from "../../../../assets/images/products/ukFlag.svg";
import add from "../../../../assets/icons/theme/add.svg";
import setting from "../../../../assets/icons/theme/settings.svg";

const SettingDrawer = () => {
  const [anchorFlagEl, setAnchorFlagEl] = useState(null);
  const openFlag = Boolean(anchorFlagEl);
  const idFlag = openFlag ? "simple-popover" : undefined;
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (_, index) => {
    setTabIndex(index);
  };
  
  const handleFlagClick = (event) => {
    setAnchorFlagEl(event.currentTarget);
  };

  const handleFlagClose = () => {
    setAnchorFlagEl(null);
  };

  const [activityDrawer, setActivityDrawer] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleActivityDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setActivityDrawer({ ...activityDrawer, [anchor]: open });
  };

  return (
    <>
        <div
          className="d-flex align-items-center bg-black-20 py-2 px-3 rounded-8 ms-2"
          onClick={toggleActivityDrawer("right", true)}
        >
          <Tooltip title="Homepage Setting" placement="bottom" className='c-pointer'>
            <img src={setting} alt="indiaFlag" height={20} />
          </Tooltip>
        </div>
        <SwipeableDrawer
            anchor="right"
            open={activityDrawer["right"]}
            onClose={toggleActivityDrawer("right", false)}
            onOpen={toggleActivityDrawer("right", true)}
        >
            <div className='p-4 bg-black-15 h-100'>
                <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                        <KeyboardArrowLeftIcon style={{color: "414C65", fontSize: "35px"}}/>
                        <div>
                            <p className="text-lightBlue fw-600">Page Settings</p>
                            <small className="ex-small mt-2 text-grey-6">
                                ⓘ Lorem ipsum dolor sit amet consectetur.
                            </small>
                        </div>
                    </div>
                    <div
                        className="d-flex align-items-center bg-black-20 py-2 px-3 rounded-8 c-pointer"
                        onClick={handleFlagClick}
                    >
                    <img src={indiaFlag} alt="indiaFlag" height={15} />
                        <p className="text-lightBlue mx-2">India</p>
                        <ArrowDropDownIcon sx={{color: "#C8D8FF"}} />
                    </div>
                    <Popover
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                    }}
                    id={idFlag}
                    open={openFlag}
                    anchorEl={anchorFlagEl}
                    onClose={handleFlagClose}
                    >
                    <div className="px-1 py-2">
                        <div className="d-flex align-items-center c-pointer hover-back px-3 py-1 my-1 rounded-3">
                        <img src={indiaFlag} alt="allFlag" height={15} />
                        <p className="ms-2 text-lightBlue">India</p>
                        </div>
                        <div className="d-flex align-items-center c-pointer hover-back px-3 py-1 my-1 rounded-3">
                        <img src={ukFlag} alt="usaFlag" height={15} />
                        <p className="ms-2 text-lightBlue">UK</p>
                        </div>
                        <div className="d-flex align-items-center c-pointer hover-back px-3 py-1 my-1 rounded-3">
                        <img src={usaFlag} alt="usaFlag" height={15} />
                        <p className="ms-2 text-lightBlue">USA</p>
                        </div>
                        <div className="d-flex align-items-center c-pointer hover-back px-3 py-1 my-1 rounded-3">
                        <img src={add} alt="usaFlag" height={15} />
                        <small className="ms-2 text-lightBlue">Add Markets</small>
                        </div>
                    </div>
                    </Popover>

                </div>    

                <div className='mt-2'>
                    <Box
                        sx={{ width: "100%" }}
                        className="d-flex justify-content-between tabs-header-box"
                    >
                        <Tabs className="tabs" value={tabIndex} onChange={handleTabChange}>
                        <Tab
                            label="General"
                            className="tabs-head"
                        />
                        <Tab
                            label="SEO"
                            className="tabs-head"
                        />
                        </Tabs>
                    </Box>
                    <TabPanel value={tabIndex} index={0}>
                        <GeneralSetting />
                    </TabPanel>
                    <TabPanel value={tabIndex} index={1}>
                    </TabPanel>
                </div>       
            </div>
        </SwipeableDrawer>
    </>
  );
};

export default SettingDrawer;