import { forwardRef, useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  InputAdornment,
  OutlinedInput,
  FormHelperText,
  Slide,
  Zoom
} from "@mui/material";

import AppMobileCodeSelect from "../../../../components/AppMobileCodeSelect/AppMobileCodeSelect";
import InputBox from "../../../../components/Setting/InputBox";
import * as Yup from "yup";
import cancel from "../../../../assets/icons/closeModal.svg";
import otpVerify from "../../../../assets/setting/OTPMessage.svg";
import { useFormik } from "formik";
import { useVerifyOtpMutation } from "../../../../features/otp/otpApiSlice";
import { useDispatch } from "react-redux";
import {
  showError,
  showSuccess,
} from "../../../../features/snackbar/snackbarAction";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const otpValidationSchema = Yup.object().shape({
  code: Yup.string()
    .matches(/^[0-9]+$/, "OTP must contain only numbers")
    .min(4, "OTP must be at least 6 characters long")
    .max(4, "OTP cannot be more than 6 characters long")
    .required("OTP is required"),
});

const PopUpBox = ({
  type,
  onClose,
  show,
  image,
  formik,
  setShowVerifyPopup,
  setShowResend,
  showResend,
  countdown,
  setCountdown,
  handleResendClick,
  setShowFooter,
  setVerifyData,
  setPhoneVerifyData,
  setSenderEmailVerifyData,
  isSenderEmailVerified,
  isContactEmailVerified,
  isPhoneVerified,
  phoneValue,
  primaryEmailValue,
  secondaryEmailValue,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    let timer;

    if (showResend && countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    } else if (countdown === 0) {
      setShowResend(false);
    }

    return () => {
      clearInterval(timer);
    };
  }, [showResend, countdown]);

  const [
    verifyOtp,
    {
      data : verifyOtpData,
      isLoading: verifyOtpIsLoading,
      isSuccess: verifyOtpIsSuccess,
      error: verifyOtpError,
      isError: verifyOtpIsError,
    },
  ] = useVerifyOtpMutation();

  const otpFormik = useFormik({
    initialValues: {
      code: "",
    },
    enableReinitialize: true,
    validationSchema: otpValidationSchema,
    onSubmit: (values) => {
      let obj = {};

      if (type === "phone") {
        obj = {
          phone: phoneValue,
          code: values.code,
        };
      }
      if (type === "primaryEmail") {
        obj = {
          email: primaryEmailValue,
          code: values.code,
        };
      }
      if (type === "secondaryEmail") {
        obj = {
          email: secondaryEmailValue,
          code: values.code,
        };
      }
      verifyOtp(obj);
    },
  });

  useEffect(() => {
    if (verifyOtpIsSuccess) {
        dispatch(
            showSuccess({
              message: `OTP Verified successfully`,
            })
          );
          
          setShowFooter(true);
          setShowVerifyPopup(false);
          otpFormik.resetForm();
        if(type==="phone")
        {
          console.log("cheb3jdefeoe", isPhoneVerified)
            formik.setFieldValue(`${isPhoneVerified}`, true);
            setPhoneVerifyData(phoneValue)
        }
        else if(type==="primaryEmail")
        {
            formik.setFieldValue(`${isContactEmailVerified}`, true);
            setVerifyData(primaryEmailValue)
        }
        else if(type==="secondaryEmail")
        {
            formik.setFieldValue(`${isSenderEmailVerified}`, true);
            setSenderEmailVerifyData(secondaryEmailValue)
        }    
    }
    if (verifyOtpIsError) {
      if (verifyOtpError?.data?.message) {
        dispatch(showError({ message: verifyOtpError?.data?.message }));
      } else {
        dispatch(
          showError({ message: "Something went wrong, please try again" })
        );
      }
    }
  }, [verifyOtpIsSuccess, verifyOtpIsError, verifyOtpError, dispatch]);

  

  return (
    <Dialog
      open={show}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="xs"
      fullWidth={true}
    >
      <form
        className="verification-form"
        noValidate
        onSubmit={otpFormik.handleSubmit}
      >
        <div className="bg-invite py-3">
          <DialogTitle className="d-flex flex-row-reverse">
            <img
              src={cancel}
              alt="cancel"
              width={45}
              onClick={onClose}
              className="c-pointer"
            />
          </DialogTitle>
          <DialogContent className="d-flex justify-content-center mb-3">
            <img src={otpVerify} alt="cancel" width={150} />
          </DialogContent>
          <div className="col-lg-12 px-4 my-4">
            <small className="d-flex text-lightBlue mb-1">
              A verification code has been sent to
              <small className="mx-1">
                {" "}
                {`${
                  type === "phone" ? phoneValue : type ==="primaryEmail" ? primaryEmailValue : type ==="secondaryEmail" ? secondaryEmailValue : ""
                }`}{" "}
              </small>
              <small className="text-blue-2 c-pointer" onClick={onClose}>
                change
              </small>
            </small>
          </div>
          <div className="col-lg-12 px-4">
            <p className="text-lightBlue fw-500 mb-1">Enter OTP</p>
            <FormControl className="w-100 px-0">
              <OutlinedInput
                placeholder="Enter four digit OTP"
                size="small"
                sx={{ paddingLeft: 0 }}
                name="code"
                value={otpFormik?.values?.code}
                onChange={otpFormik?.handleChange}
                onBlur={otpFormik?.handleBlur}
              />
              {!!otpFormik?.touched?.code && otpFormik?.errors?.code && (
                <FormHelperText error>{otpFormik?.errors?.code}</FormHelperText>
              )}
            </FormControl>
          </div>
          <small className="text-grey-6 ps-4 ex-small">
            Haven't recieved code?
            {showResend ? (
              <small className="text-blue-gradient px-2">
                {" "}
                Resend in {countdown} sec
              </small>
            ) : (
              <small
                className="text-blue-gradient px-2 c-pointer"
                onClick={handleResendClick}
              >
                Resend
              </small>
            )}
          </small>
          <button className="button-gradient p-2 m-4" type="submit">
            <p>Verify OTP</p>
          </button>
        </div>
      </form>
    </Dialog>
  );
};

export default PopUpBox;
