import { forwardRef, useCallback } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputAdornment,
  OutlinedInput,
  Checkbox,
  FormControlLabel,
  Slide,
  FormHelperText,
} from "@mui/material";

import AppCountrySelect from "../../components/AppCountrySelect/AppCountrySelect";
import AppStateSelect from "../../components/AppStateSelect/AppStateSelect";
import AppMobileCodeSelect from "../../components/AppMobileCodeSelect/AppMobileCodeSelect";
import AppCitySelect from "../../components/AddCitySelect/AddCitySelect";

import cancel from "../../assets/icons/cancel.svg";
import { useFormik } from "formik";
import ZipCodeComponent from "../ZipCodeCityComponent";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { showError } from "../../features/snackbar/snackbarAction";
import { omitNullishKeys } from "../../utils/helper";
import { useEditStoreInfoMutation } from "../../features/storeDetails/storeInfoApiSlice";

const Transition = forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="up"
      ref={ref}
      {...props}
    />
  );
});

const customerAddressValidation = Yup.object({
  name: Yup.string().trim().min(3, "Name must be at least 3 characters").required("Required"),
  firstName: Yup.string()
    .trim()
    .min(3, "First name must be at least 3 characters")
    .required("Required"),
  lastName: Yup.string()
    .trim()
    .min(3, "Last name must be at least 3 characters")
    .required("Required"),
  countryCode: Yup.string().required("Required"),
  email: Yup.string().required("Required").email("Invalid email"),
  phone: Yup.string()
    .matches(/^\d{10}$/, "Mobile Number must be 10 digits numeric value only.")
    .required("Required"),
  country: Yup.string().required("Required"),
  line1: Yup.string().required("Required"),
  city: Yup.string().required("Required"),
  pinCode: Yup.string()
    .matches(/^\d{6}$/, "Pincode must be 6 digits numeric value only.")
    .required("Required"),
  state: Yup.string().required("Required"),
});

const AddAddress = ({ openUser, handleAddressClose, billingAddress, idData }) => {
  const dispatch = useDispatch();
  const [editStoreInfo] = useEditStoreInfoMutation();

  const customerAddressFormik = useFormik({
    initialValues: {
      type: "billing",
      name: billingAddress?.name || "",
      firstName: billingAddress?.firstName || "",
      lastName: billingAddress?.lastName || "",
      companyName: billingAddress?.companyName || "",
      gstIn: billingAddress?.gstIn || "",
      countryCode: billingAddress?.countryCode?._id || billingAddress?.countryCode || "",
      country: billingAddress?.country?._id || billingAddress?.country || "",
      email: billingAddress?.email || "",
      phone: billingAddress?.phone || "",
      line1: billingAddress?.line1 || "",
      line2: billingAddress?.line2 || "",
      city: billingAddress?.city?._id || billingAddress?.city || "",
      pinCode: billingAddress?.pinCode || "",
      state: billingAddress?.state?._id || billingAddress?.state || "",
      isDefaultAddress: billingAddress?.isDefaultAddress || false,
      customerId: billingAddress?.customerId || "",
    },
    enableReinitialize: true,
    validationSchema: customerAddressValidation,
    onSubmit: (values, helper) => {
      try {
        const billingAddress = omitNullishKeys({
          countryCode: values.countryCode,
          type: "billing",
          name: values.name,
          email: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
          companyName: values.companyName,
          country: values.country,
          city: values.city,
          phone: values.phone,
          line1: values.line1,
          line2: values.line2,
          pinCode: values.pinCode,
          state: values.state,
          isDefaultAddress: values.isDefaultAddress,
          gstIn: values.gstIn,
        });
        const data = omitNullishKeys({
          billingAddress,
        });
        editStoreInfo({
          id: idData,
          details: data,
        });
        handleAddressClose();
        // add billing
      } catch (error) {
        dispatch(showError({ message: "Can not update address" }));
      }
    },
  });

  const selectCountryName = useCallback(
    (event, value) => {
      customerAddressFormik.setFieldValue("country", value?._id);
    },
    [customerAddressFormik]
  );

  const getStateName = useCallback(
    (event, value) => {
      customerAddressFormik.setFieldValue("state", value?._id);
    },
    [customerAddressFormik]
  );

  const SelectCityName = useCallback(
    (event, value) => {
      console.log("calledCity", value);
      customerAddressFormik.setFieldValue("city", value?._id);
    },
    [customerAddressFormik]
  );

  const handleCode = useCallback(
    (event, value) => {
      customerAddressFormik.setFieldValue("countryCode", value?._id);
    },
    [customerAddressFormik]
  );

  return (
    <Dialog
      open={openUser}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleAddressClose}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="sm"
      fullWidth={true}>
      <DialogTitle>
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex flex-column ">
            <h5 className="text-lightBlue fw-500">Edit Address</h5>

            <small className="text-grey-6 mt-1 d-block">
              ⓘ Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </small>
          </div>
          <img
            src={cancel}
            alt="cancel"
            width={30}
            onClick={handleAddressClose}
            className="c-pointer"
          />
        </div>
      </DialogTitle>
      <hr className="hr-grey-6 my-0" />
      <DialogContent className="pb-4 px-4">
        <div className="row py-3 rounded-8">
          <div className="col-md-12">
            <p className="text-lightBlue mb-1">
              Name <span style={{ color: "#F67476" }}>*</span>
            </p>
            <FormControl className="w-100 px-0">
              <OutlinedInput
                placeholder="Office Address, Home Address"
                size="small"
                name="name"
                value={customerAddressFormik.values.name}
                onChange={customerAddressFormik.handleChange}
              />
            </FormControl>
            {!!customerAddressFormik.touched.name && customerAddressFormik.errors.name && (
              <FormHelperText error>{customerAddressFormik.errors.name}</FormHelperText>
            )}
            <small className="text-grey-6">
              Name this address Ex. Office Address, Home Address
            </small>
          </div>
          <div className="col-12">
            <FormControlLabel
              control={
                <Checkbox
                  name="isDefaultAddress"
                  value={customerAddressFormik.values.isDefaultAddress}
                  onChange={customerAddressFormik.handleChange}
                  inputProps={{ "aria-label": "controlled" }}
                  size="small"
                  style={{
                    color: "#5C6D8E",
                  }}
                />
              }
              label="Set as Default Address"
              sx={{
                "& .MuiTypography-root": {
                  fontSize: 13,
                  color: "#c8d8ff",
                },
              }}
            />
          </div>
          <div className="col-md-6 mt-3">
            <p className="text-lightBlue mb-1">
              First Name <span style={{ color: "#F67476" }}>*</span>
            </p>
            <FormControl className="w-100 px-0">
              <OutlinedInput
                placeholder="Enter First Name"
                size="small"
                name="firstName"
                value={customerAddressFormik.values.firstName}
                onChange={customerAddressFormik.handleChange}
              />
            </FormControl>
            {!!customerAddressFormik.touched.firstName &&
              customerAddressFormik.errors.firstName && (
                <FormHelperText error>{customerAddressFormik.errors.firstName}</FormHelperText>
              )}
          </div>
          <div className="col-md-6 mt-3">
            <p className="text-lightBlue mb-1">
              Last Name <span style={{ color: "#F67476" }}>*</span>
            </p>
            <FormControl className="w-100 px-0">
              <OutlinedInput
                placeholder="Enter Last Name"
                size="small"
                name="lastName"
                value={customerAddressFormik.values.lastName}
                onChange={customerAddressFormik.handleChange}
              />
            </FormControl>
            {!!customerAddressFormik.touched.lastName &&
              customerAddressFormik.errors.lastName && (
                <FormHelperText error>{customerAddressFormik.errors.lastName}</FormHelperText>
              )}
          </div>
          <div className="col-md-12 mt-3">
            <p className="text-lightBlue mb-1">Company Name</p>
            <FormControl className="w-100 px-0">
              <OutlinedInput
                placeholder="Enter company name"
                size="small"
                name="companyName"
                value={customerAddressFormik.values.companyName}
                onChange={customerAddressFormik.handleChange}
              />
            </FormControl>
          </div>
          {customerAddressFormik.values.type === "billing" && (
            <div className="col-md-12 mt-3">
              <p className="text-lightBlue mb-1">GSTIN</p>
              <FormControl className="w-100 px-0">
                <OutlinedInput
                  placeholder="Enter GSTIN"
                  size="small"
                  name="gstIn"
                  value={customerAddressFormik.values.gstIn}
                  onChange={customerAddressFormik.handleChange}
                />
              </FormControl>
            </div>
          )}
          <div className="col-md-12 mt-3">
            <p className="text-lightBlue mb-1">
              Mobile Number <span style={{ color: "#F67476" }}>*</span>
            </p>
            <FormControl className="w-100 px-0">
              <OutlinedInput
                placeholder="Enter Mobile Number"
                size="small"
                sx={{ paddingLeft: 0 }}
                name="phone"
                value={customerAddressFormik.values.phone}
                onChange={customerAddressFormik.handleChange}
                startAdornment={
                  <InputAdornment position="start">
                    <AppMobileCodeSelect
                      formik={customerAddressFormik}
                      countryCode={customerAddressFormik.values.countryCode}
                      handleCode={handleCode}
                    />
                  </InputAdornment>
                }
              />
            </FormControl>
            {(!!customerAddressFormik.touched.countryCode ||
              !!customerAddressFormik.touched.phone) &&
              (customerAddressFormik.errors.countryCode ||
                customerAddressFormik.errors.phone) && (
                <FormHelperText error>
                  {customerAddressFormik.errors.countryCode ||
                    customerAddressFormik.errors.phone}
                </FormHelperText>
              )}
          </div>
          <div className="col-md-12 mt-3">
            <p className="text-lightBlue mb-1">Email</p>
            <FormControl className="w-100 px-0">
              <OutlinedInput
                placeholder="Enter email"
                size="small"
                name="email"
                value={customerAddressFormik.values.email}
                onChange={customerAddressFormik.handleChange}
              />
            </FormControl>
            {!!customerAddressFormik.touched.email && customerAddressFormik.errors.email && (
              <FormHelperText error>{customerAddressFormik.errors.email}</FormHelperText>
            )}
          </div>
          {/* <div className="col-md-6 mt-3">
            <p className="text-lightBlue mb-1">
              Address Line 1 <span style={{ color: "#F67476" }}>*</span>
            </p>
            <FormControl className="w-100 px-0">
              <OutlinedInput
                placeholder="Enter Address Line 1"
                size="small"
                name="line1"
                value={customerAddressFormik.values.line1}
                onChange={customerAddressFormik.handleChange}
              />
            </FormControl>
            {!!customerAddressFormik.touched.line1 &&
              customerAddressFormik.errors.line1 && (
                <FormHelperText error>
                  {customerAddressFormik.errors.line1}
                </FormHelperText>
              )}
          </div>
          <div className="col-md-6 mt-3">
            <p className="text-lightBlue mb-1">Address Line 2</p>
            <FormControl className="w-100 px-0">
              <OutlinedInput
                placeholder="Enter Address Line 2"
                size="small"
                name="line2"
                value={customerAddressFormik.values.line2}
                onChange={customerAddressFormik.handleChange}
              />
            </FormControl>
          </div> */}
          <ZipCodeComponent
            cityVal={customerAddressFormik.values.city}
            onchangeCity={SelectCityName}
            errorCity={customerAddressFormik.errors.city}
            touchedCity={customerAddressFormik.touched.city}
            stateVal={customerAddressFormik.values.state}
            onchangeState={getStateName}
            errorState={customerAddressFormik.errors.state}
            touchedState={customerAddressFormik.touched.state}
            countryVal={customerAddressFormik.values.country}
            onchangeCountry={selectCountryName}
            errorCountry={customerAddressFormik.errors.country}
            touchedCountry={customerAddressFormik.touched.country}
            zipVal={customerAddressFormik.values.pinCode}
            onchangeZip={(e) =>
              customerAddressFormik.setFieldValue("pinCode", e?.target?.value)
            }
            errorZip={customerAddressFormik.errors.pinCode}
            touchedZip={customerAddressFormik.touched.pinCode}
            onchangeLine1={(line) => customerAddressFormik.setFieldValue("line1", line)}
            touchedLine1={customerAddressFormik.touched.line1}
            errorLine1={customerAddressFormik.errors.line1}
            nameLine2="line2"
            namePincode="pinCode"
            valueLine2={customerAddressFormik.values.line2}
            onchangeLine2={(e) =>
              customerAddressFormik.setFieldValue("line2", e?.target?.value)
            }
            onchangePincode={(code) => customerAddressFormik.setFieldValue("pinCode", code)}
            line1Val={customerAddressFormik.values.line1}
          />
        </div>
      </DialogContent>
      <hr className="hr-grey-6 my-0" />
      <DialogActions className="d-flex justify-content-between px-4 py-3">
        <button
          className="button-grey py-2 px-5"
          onClick={handleAddressClose}>
          <p className="text-lightBlue">Cancel</p>
        </button>
        <button
          className="button-gradient py-2 px-5"
          type="button"
          onClick={customerAddressFormik.handleSubmit}>
          <p>Save</p>
        </button>
      </DialogActions>
    </Dialog>
  );
};

export default AddAddress;
