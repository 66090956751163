import apiSlice from "../../../app/api/apiSlice";
import { omitEmptyKeys } from "../../../utils/helper";

export const inventoryApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllStores: builder.query({
      query: (queries = {}) => ({
        url: "/store",
        params: omitEmptyKeys(queries),
      }),
      providesTags: ["Inventory"],
    }),

    getStoreCount: builder.query({
      query: (queries = {}) => ({
        url: "/store/count",
        params: omitEmptyKeys(queries),
      }),
      providesTags: ["Inventory"],
    }),

    createStore: builder.mutation({
      query: (storeData) => ({
        url: "/store",
        method: "POST",
        body: storeData,
      }),
      invalidatesTags: ["Inventory", "Logs"],
    }),

    editStore: builder.mutation({
      query: ({ id, details }) => ({
        url: `/store/${id}`,
        method: "PUT",
        body: details,
      }),
      invalidatesTags: ["Inventory", "Logs"],
    }),

    bulkEditStore: builder.mutation({
      query: (updates) => ({
        url: "/store/bulkUpdate",
        method: "PUT",
        body: updates,
      }),
      invalidatesTags: ["Inventory", "Logs"],
    }),

    deleteStore: builder.mutation({
      query: (storeId) => ({
        url: `/store/${storeId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Inventory", "Logs"],
    }),

    bulkDeleteStore: builder.mutation({
      query: (deletes) => ({
        url: "/store/bulkDelete",
        method: "DELETE",
        body: deletes,
      }),
      invalidatesTags: ["Inventory", "Logs"],
    }),
  }),
});

export const {
  useGetAllStoresQuery,
  useGetStoreCountQuery,
  useCreateStoreMutation,
  useEditStoreMutation,
  useBulkEditStoreMutation,
  useDeleteStoreMutation,
  useBulkDeleteStoreMutation,
} = inventoryApiSlice;
