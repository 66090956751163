import { Tooltip, FormControl, OutlinedInput } from "@mui/material";

import info from "../../assets/icons/info.svg";

const InputBox = ({ heading, tooltip, placeholder, rightHeading, ...props }) => {
  return (
    <div className="my-2 w-100">
      {heading && (
        <div className="d-flex justify-content-between align-items-center mb-1">
          <div className="d-flex">
            <p className="text-lightBlue fw-500">{heading}</p>
            {tooltip && (
              <Tooltip
                title={tooltip}
                placement="top">
                <img
                  src={info}
                  alt="info"
                  className="ms-2 c-pointer"
                  width={13.5}
                />
              </Tooltip>
            )}
          </div>
          {rightHeading && <small className="text-blue-2 c-pointer">{rightHeading}</small>}
        </div>
      )}
      <FormControl className="w-100 px-0">
        <OutlinedInput
          placeholder={placeholder}
          size="small"
          {...props}
        />
      </FormControl>
    </div>
  );
};

export default InputBox;
