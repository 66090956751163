import { useMemo, useState } from "react";
import {
  Button,
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  SwipeableDrawer,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from "@mui/material";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import product2Icon from "../../../assets/images/products/product2.jpg";
import { useGetAllVariantsQuery } from "../../../features/products/product/varianceApiSlice";
import AsyncImage from "../../../components/AsyncImage/AsyncImage";
import { useGetAllInventoriesQuery } from "../../../features/products/product/inventoryAPISlice";
import TableLoader from "../../../components/Loader/TableLoader";
import { useGetAllProductsQuery } from "../../../features/products/product/productApiSlice";
import TableSearch from "../../../components/TableSearch/TableSearch";
import { PriceBreakup } from "../../Products/AddProduct/Variants/Variants";

export default function SelectProductSideDrawer({
  show = false,
  selectedProducts = [],
  onClose = () => {},
  onSelection = () => {},
}) {
  const pageSize = 10;
  const [pageNo, setPageNo] = useState(1);
  const [title, setTitle] = useState("");
  const [product, setProduct] = useState(null);
  const [variant, setVariant] = useState(null);
  const [variantName, setVariantName] = useState("");
  const [inventory, setInventory] = useState(null);

  const { data: allProductsData, isLoading: allProductsIsLoading } = useGetAllProductsQuery(
    {
      queries: { status: ["active", "in-active", "scheduled"], title, pageNo, pageSize },
    },
    { skip: !show || !!product }
  );
  const allProducts = allProductsData?.data ?? [];
  const totalProducts = allProductsData?.totalCount ?? 0;

  const { data: allVariantsData, isLoading: allVariantsIsLoading } = useGetAllVariantsQuery(
    { productId: product?._id, pageSize, pageNo },
    { skip: !product }
  );
  const allVariants = allVariantsData?.data?.data ?? [];
  const totalVariants = allVariantsData?.data?.totalCount ?? 0;

  const {
    data: allInventoriesData,
    isLoading: allInventoriesIsLoading,
    isFetching: allInventoriesIsFetching,
  } = useGetAllInventoriesQuery(
    { product: product?._id ?? "", variant: variant?._id ?? "" },
    { skip: !product || (!variant && !!product?.setId) }
  );
  const allInventories = useMemo(() => {
    // const stores = allInventoriesData?.data?.data?.[0]?.store ?? [];
    const inventory = allInventoriesData?.data?.data?.[0];
    return !inventory ? [] : inventory?.store?.map((st) => ({ ...st, iid: inventory?._id }));
  }, [allInventoriesData]);

  const canMoveForward = useMemo(
    () =>
      !!product &&
      (!!variant || !product?.setId) &&
      !!inventory &&
      (inventory.quantityCount > 0 || false),
    [product, variant, inventory]
  );

  const onSideDrawerClose = () => {
    setProduct(null);
    setVariant(null);
    setVariantName("");
    setInventory(null);
    setTitle("");
    setPageNo(1);
    onClose();
  };

  const onSideDrawerSelection = () => {
    onSelection({ product, variant, variantName, inventory });
    onSideDrawerClose();
  };

  const onProductSelected = (p) => {
    setProduct(p);
    setTitle("");
    setPageNo(1);
  };

  const onProductChanged = () => {
    setProduct(null);
    setVariant(null);
    setVariantName("");
    setInventory(null);
    setTitle("");
    setPageNo(1);
  };

  const onVariantSelected = (v, n) => {
    setVariant(v);
    setVariantName(n);
    setTitle("");
    setPageNo(1);
  };

  const onVariantChanged = () => {
    setVariant(null);
    setVariantName("");
    setInventory(null);
    setTitle("");
    setPageNo(1);
  };

  // console.log({ product, variant, inventory });

  return (
    <SwipeableDrawer
      anchor="right"
      open={show}
      onOpen={Date}
      onClose={onSideDrawerClose}>
      <div
        className="container-fluid"
        style={{ maxWidth: "55vw" }}>
        <div className="row my-2">
          <div className="col-12 d-flex align-items-center">
            <KeyboardArrowLeftOutlinedIcon
              sx={{ fontSize: 30, color: "#c8d8ff" }}
              onClick={onSideDrawerClose}
              className="c-pointer"
            />
            <h4 className="text-lightBlue fw-600">Product Selection</h4>
          </div>
        </div>
        <hr className="hr-grey-6 my-0" />
        {!product && (
          <div className="row my-2">
            <div className="col-12 my-1">
              <p className="text-lightBlue fw-600">Select a Product</p>
            </div>
            <div className="col-12 my-1">
              <TableSearch
                searchValue={title}
                handleSearchChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <div className="col-12 my-1">
              <TableContainer>
                {allProductsIsLoading ? (
                  <TableLoader />
                ) : (
                  <Table size="medium">
                    <TableBody>
                      {allProducts.map((row) => {
                        const {
                          _id: rid = "",
                          title = "",
                          mediaUrl = [],
                          price: { dynamicPricing = false, salePrice = 0 } = {},
                          inventory: { skuId = "" } = {},
                        } = row ?? {};
                        const imageUrl = mediaUrl.find((mu) => mu.isDefault)?.image ?? "";
                        return (
                          <TableRow
                            hover
                            tabIndex={-1}
                            key={rid}
                            className="table-rows">
                            <TableCell
                              component="th"
                              scope="row"
                              padding="normal"
                              className="py-0">
                              <div className="d-flex align-items-center py-3">
                                <AsyncImage
                                  src={imageUrl}
                                  placeholder={product2Icon}
                                  alt="product"
                                  className="me-2 rounded-4"
                                  height={40}
                                  width={40}
                                />
                                <div>
                                  <p className="text-lightBlue rounded-circle fw-600">
                                    {title}
                                  </p>
                                  <small className="text-grey-6 mt-1">SKU: {skuId}</small>
                                </div>
                              </div>
                            </TableCell>
                            <TableCell
                              padding="normal"
                              className="py-0">
                              <div className="d-flex justify-content-center align-items-center">
                                <p className="text-lightBlue">
                                  {dynamicPricing ? "Depends on Variant" : salePrice}
                                </p>
                              </div>
                            </TableCell>
                            <TableCell
                              padding="normal"
                              className="py-0">
                              <div className="d-flex justify-content-center align-items-center">
                                <button
                                  onClick={() => onProductSelected(row)}
                                  className="button-transparent border-grey-5 py-2 px-3">
                                  <p className="text-lightBlue">Select</p>
                                </button>
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                )}
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[pageSize]}
                component="div"
                count={totalProducts}
                rowsPerPage={pageSize}
                page={pageNo - 1}
                onPageChange={(_, val) => setPageNo(val + 1)}
                onRowsPerPageChange={(e) => console.log(e.target.value)}
                className="table-pagination"
              />
            </div>
          </div>
        )}
        {!!product && !!product?.setId && !variant && (
          <div className="row my-2">
            <div className="col-12 my-1">
              <p className="text-lightBlue fw-600">Selected Product</p>
            </div>
            <div className="col-12 my-1 px-2">
              <div className="d-flex justify-content-between bg-black-15 border-grey-5 rounded-8 px-3">
                <div className="d-flex align-items-center py-3">
                  <AsyncImage
                    src={product?.mediaUrl?.find((mu) => mu.isDefault)?.image ?? ""}
                    placeholder={product2Icon}
                    alt="product"
                    className="me-2 rounded-4"
                    height={40}
                    width={40}
                  />
                  <div>
                    <p className="text-lightBlue rounded-circle fw-600">{product?.title}</p>
                    <small className="text-grey-6 mt-1">SKU: {product?.inventory?.skuId}</small>
                  </div>
                </div>
                <div className="d-flex align-items-center py-3">
                  <Button
                    size="small"
                    variant="text"
                    onClick={onProductChanged}>
                    <span className="text-blue-2">Change Product</span>
                  </Button>
                </div>
              </div>
            </div>
            <div className="col-12 mt-3 mb-1">
              <p className="text-lightBlue fw-600">Select a Variant</p>
            </div>
            <div className="col-12 my-1">
              <TableContainer>
                {allVariantsIsLoading ? (
                  <TableLoader />
                ) : (
                  <Table size="medium">
                    <TableBody>
                      {allVariants.map((variant) => {
                        const imageUrl =
                          product?.mediaUrl?.find((mu) => mu.isDefault)?.image ?? "";
                        const variantName = [
                          ...variant.attributes
                            .filter(
                              (at) =>
                                at.title !== "metal" &&
                                at.title !== "diamond" &&
                                at.title !== "gemstone" &&
                                at.title !== "pearl"
                            )
                            .flatMap((at) => {
                              const optns = variant.options.filter(
                                (op) => op.metaAttribute.data?.attribute === at._id
                              );
                              return optns.map((op) => op.metaAttribute.data.title);
                            }),
                          ...variant.attributes
                            .filter((at) => at.title === "metal")
                            .flatMap((at) => {
                              const optns = variant.options.filter(
                                (op) => op.metaAttribute.data?.attribute === at._id
                              );
                              return optns.flatMap((op) => [
                                op.metaAttribute.data.title,
                                op.metaAttribute.metaSubAttribute2.metaSubAttributeValue.title,
                                op.metaAttribute.metaSubAttribute1.metaSubAttributeValue.title,
                              ]);
                            }),
                          ...variant.attributes
                            .filter((at) => at.title === "diamond")
                            .flatMap((at) => {
                              const optns = variant.options.filter(
                                (op) => op.metaAttribute.data?.attribute === at._id
                              );
                              return optns.map((op) => op.metaAttribute.data.title);
                            }),
                          ...variant.attributes
                            .filter((at) => at.title === "gemstone")
                            .flatMap((at) => {
                              const optns = variant.options.filter(
                                (op) => op.metaAttribute.data?.attribute === at._id
                              );
                              return optns.map((op) => op.metaAttribute.data.title);
                            }),
                          ...variant.attributes
                            .filter((at) => at.title === "pearl")
                            .flatMap((at) => {
                              const optns = variant.options.filter(
                                (op) => op.metaAttribute.data?.attribute === at._id
                              );
                              return optns.map((op) => op.metaAttribute.data.title);
                            }),
                        ].join(" • ");
                        return (
                          <TableRow
                            hover
                            tabIndex={-1}
                            key={variant?._id}
                            className="table-rows">
                            <TableCell
                              component="th"
                              scope="row"
                              padding="normal"
                              className="py-0">
                              <div className="d-flex align-items-center py-3">
                                <AsyncImage
                                  src={imageUrl}
                                  placeholder={product2Icon}
                                  alt="product"
                                  className="me-2 rounded-4"
                                  height={40}
                                  width={40}
                                />
                                <div>
                                  <p className="text-lightBlue rounded-circle fw-600">
                                    {variantName}
                                  </p>
                                  <small className="text-grey-6 mt-1">
                                    SKU: {variant.barCode}
                                  </small>
                                </div>
                              </div>
                            </TableCell>
                            <TableCell
                              padding="normal"
                              className="py-0">
                              <div className="d-flex justify-content-center align-items-center">
                                <PriceBreakup variant={variant} />
                              </div>
                            </TableCell>
                            <TableCell
                              padding="normal"
                              className="py-0">
                              <div className="d-flex justify-content-center align-items-center">
                                <button
                                  onClick={() => onVariantSelected(variant, variantName)}
                                  className="button-transparent border-grey-5 py-2 px-3">
                                  <p className="text-lightBlue">Select</p>
                                </button>
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                )}
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[pageSize]}
                component="div"
                count={totalVariants}
                rowsPerPage={pageSize}
                page={pageNo - 1}
                onPageChange={(_, val) => setPageNo(val + 1)}
                onRowsPerPageChange={(e) => console.log(e.target.value)}
                className="table-pagination"
              />
            </div>
          </div>
        )}
        {!!product && (!!variant || !product?.setId) && (
          <div className="row my-2">
            <div className="col-12 my-1">
              <p className="text-lightBlue fw-600">Selected Product</p>
            </div>
            <div className="col-12 my-1 px-2">
              <div className="d-flex justify-content-between bg-black-15 border-grey-5 rounded-8 px-3">
                <div className="d-flex align-items-center py-3">
                  <AsyncImage
                    src={product?.mediaUrl?.find((mu) => mu.isDefault)?.image ?? ""}
                    placeholder={product2Icon}
                    alt="product"
                    className="me-2 rounded-4"
                    height={40}
                    width={40}
                  />
                  <div>
                    <p className="text-lightBlue rounded-circle fw-600">{product?.title}</p>
                    <small className="text-grey-6 mt-1">SKU: {product?.inventory?.skuId}</small>
                  </div>
                </div>
                <div className="d-flex align-items-center py-3">
                  <Button
                    size="small"
                    variant="text"
                    onClick={onProductChanged}>
                    <span className="text-blue-2">Change Product</span>
                  </Button>
                </div>
              </div>
            </div>
            {!!variant && (
              <>
                <div className="col-12 mt-3 mb-1">
                  <p className="text-lightBlue fw-600">Selected Variant</p>
                </div>
                <div className="col-12 my-1 px-2">
                  <div className="d-flex justify-content-between bg-black-15 border-grey-5 rounded-8 px-3">
                    <div className="d-flex align-items-center py-3">
                      <AsyncImage
                        src={product?.mediaUrl?.find((mu) => mu.isDefault)?.image ?? ""}
                        placeholder={product2Icon}
                        alt="product"
                        className="me-2 rounded-4"
                        height={40}
                        width={40}
                      />
                      <div>
                        <p className="text-lightBlue rounded-circle fw-600">
                          {[
                            ...variant.attributes
                              .filter(
                                (at) =>
                                  at.title !== "metal" &&
                                  at.title !== "diamond" &&
                                  at.title !== "gemstone" &&
                                  at.title !== "pearl"
                              )
                              .flatMap((at) => {
                                const optns = variant.options.filter(
                                  (op) => op.metaAttribute.data?.attribute === at._id
                                );
                                return optns.map((op) => op.metaAttribute.data.title);
                              }),
                            ...variant.attributes
                              .filter((at) => at.title === "metal")
                              .flatMap((at) => {
                                const optns = variant.options.filter(
                                  (op) => op.metaAttribute.data?.attribute === at._id
                                );
                                return optns.flatMap((op) => [
                                  op.metaAttribute.data.title,
                                  op.metaAttribute.metaSubAttribute2.metaSubAttributeValue
                                    .title,
                                  op.metaAttribute.metaSubAttribute1.metaSubAttributeValue
                                    .title,
                                ]);
                              }),
                            ...variant.attributes
                              .filter((at) => at.title === "diamond")
                              .flatMap((at) => {
                                const optns = variant.options.filter(
                                  (op) => op.metaAttribute.data?.attribute === at._id
                                );
                                return optns.map((op) => op.metaAttribute.data.title);
                              }),
                            ...variant.attributes
                              .filter((at) => at.title === "gemstone")
                              .flatMap((at) => {
                                const optns = variant.options.filter(
                                  (op) => op.metaAttribute.data?.attribute === at._id
                                );
                                return optns.map((op) => op.metaAttribute.data.title);
                              }),
                            ...variant.attributes
                              .filter((at) => at.title === "pearl")
                              .flatMap((at) => {
                                const optns = variant.options.filter(
                                  (op) => op.metaAttribute.data?.attribute === at._id
                                );
                                return optns.map((op) => op.metaAttribute.data.title);
                              }),
                          ].join(" • ")}
                        </p>
                        <small className="text-grey-6 mt-1">SKU: {variant.barCode}</small>
                      </div>
                    </div>
                    <div className="d-flex align-items-center py-3">
                      <Button
                        variant="text"
                        onClick={onVariantChanged}>
                        <span className="text-blue-2">Change Variant</span>
                      </Button>
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className="col-6 mt-3 mb-1">
              <p className="text-lightBlue fw-600">Select a Store</p>
            </div>
            <div className="col-3 mt-3 mb-1">
              <p className="text-lightBlue fw-600">Item Price</p>
            </div>
            <div className="col-3 mt-3 mb-1">
              <p className="text-lightBlue fw-600">Available Quantity</p>
            </div>
            <div className="col-6 my-1">
              {allInventoriesIsLoading || allInventoriesIsFetching ? (
                <CircularProgress
                  color="secondary"
                  size={20}
                />
              ) : (
                <FormControl
                  className="w-100 px-0"
                  size="small">
                  <Select
                    size="small"
                    value={inventory ?? ""}
                    placeholder="Select a Store"
                    onChange={(e) => setInventory(e.target.value)}>
                    {allInventories.map((iv) => (
                      <MenuItem
                        key={iv._id}
                        value={iv}
                        sx={{ fontSize: 13, color: "#5c6d8e" }}>
                        {iv.store.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </div>
            <div className="col-3 my-1">
              <p className="text-lightBlue">
                {/*console.log(
                  product?.price?.dynamicPricing,
                  product?.price?.salePrice,
                  variant?.isDynamicPricing,
                  variant?.priceBreakup
                )*/}
                {!product?.price?.dynamicPricing
                  ? product?.price?.salePrice
                  : !variant?.isDynamicPricing
                  ? variant?.priceBreakup?.[0]?.salePrice
                  : variant?.priceBreakup?.reduce(
                      (t, p) => t + ("discount" in p ? -p.discountAmount : p.price),
                      0
                    )}
              </p>
            </div>
            <div className="col-3 my-1">
              <p className="text-lightBlue fs-6 ps-2">{inventory?.quantityCount ?? "—"}</p>
            </div>
          </div>
        )}
        {canMoveForward && (
          <div className="row my-2">
            <div style={{ marginTop: "100%" }} />
            <div
              style={{ position: "sticky", bottom: "10px" }}
              className="col-12 d-flex justify-content-between my-1">
              <button
                type="button"
                onClick={() => onSideDrawerClose()}
                className="button-red-outline py-2 px-4">
                <p>Cancel</p>
              </button>
              <button
                type="button"
                onClick={() => onSideDrawerSelection()}
                className="button-gradient py-2 px-4">
                <p>Select</p>
              </button>
            </div>
          </div>
        )}
      </div>
    </SwipeableDrawer>
  );
}
