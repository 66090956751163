import { forwardRef } from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  OutlinedInput,
  Paper,
  Slide,
  Tab,
  Tabs,
  Select,
  MenuItem,
  Radio,
  FormControlLabel,
  Popover,
  RadioGroup,
  Tooltip,
  FormHelperText,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import DeleteIconButton from "../../../components/DeleteIconButton/DeleteIconButton";
import Label from "../../../components/Label/Label";

import cancel from "../../../assets/icons/cancel.svg";
import info from "../../../assets/icons/info.svg";

const Transition = forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="up"
      ref={ref}
      {...props}
    />
  );
});

const AddStandardModal = (props) => {
  const {
    onCancel,
    show,
    isLoading,
    formik,
    categories,
    subCategories,
    option,
    attributes,
    subOptions,
    subAttributes,
    onAddRange,
    onDeleteRange,
    isSubOptionSuccess,
    isUpdated,
    isEditing,
    rangeAdded,
    stoneAttributes,
    stoneAttributeValues,
    sizeStoneAttrId,
    isStone,
  } = props;

  const isOption = formik.values.isOption;

  const onKeyDown = (keyEvent) => {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
      onAddRange();
    }
  };

  const attributeHandler = (e) => {
    formik.handleChange(e);
    const updatedTouch = formik.touched;
    delete updatedTouch.values;
    formik.setTouched(updatedTouch);
  };
  const categoryHandler = (e) => {
    formik.handleChange(e);
    const updatedTouch = formik.touched;
    delete updatedTouch.subCategory;
    formik.setTouched(updatedTouch);
  };

  return (
    <Dialog
      open={show}
      TransitionComponent={Transition}
      keepMounted
      onClose={onCancel}
      maxWidth="md"
      fullWidth={true}>
      <form
        onKeyDown={onKeyDown}
        className="product-form"
        noValidate
        onSubmit={formik.handleSubmit}>
        <DialogTitle>
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex flex-column ">
              <h5 className="text-lightBlue fw-500">Add Standards</h5>

              <small className="text-grey-6 mt-1 d-block">
                ⓘ Some Dummy Content to explain
              </small>
            </div>
            <button
              type="button"
              className="reset"
              onClick={onCancel}>
              <img
                src={cancel}
                alt="cancel"
                width={30}
                className="c-pointer"
              />
            </button>
          </div>
        </DialogTitle>
        <hr className="hr-grey-6 my-0" />

        <DialogContent className="py-3 px-4">
          <div className="row mt-3">
            {isStone ? (
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: `repeat(${
                    stoneAttributes ? stoneAttributes?.length + 1 : 1
                  }, 1fr)`,
                  gap: "25px",
                }}>
                <div>
                  <Label required={true}>{option?.title}</Label>
                  <FormControl
                    sx={{ width: "100%" }}
                    size="small">
                    <Select
                      disabled
                      labelId="demo-select-small"
                      id="demo-select-small"
                      name="metaAttributeId"
                      value={formik.values.metaAttributeId}
                      // onBlur={formik.handleBlur}
                      // onChange={attributeHandler}
                      onChange={() => {}}>
                      {attributes?.map((attr) => {
                        return (
                          <MenuItem
                            key={attr?._id}
                            value={attr?._id}>
                            {attr?.title}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    {!!formik.touched.metaAttributeId && formik.errors.metaAttributeId && (
                      <FormHelperText error>{formik.errors.metaAttributeId}</FormHelperText>
                    )}
                  </FormControl>
                </div>
                {stoneAttributes?.length &&
                  !!formik.values?.values?.length &&
                  formik.values?.values?.map((value, index) => {
                    const stoneAttr = stoneAttributes.find(
                      (item) => item._id === value.metaSubAttributeId
                    );
                    if (!stoneAttr) {
                      return null;
                    }
                    return (
                      <div key={stoneAttr._id}>
                        <Label required={true}>{stoneAttr?.title}</Label>
                        <FormControl
                          sx={{ width: "100%" }}
                          size="small">
                          <Select
                            disabled
                            labelId="demo-select-small"
                            id="demo-select-small"
                            name={`values[${index}].subAttributeValueId`}
                            value={
                              formik.values.values?.length
                                ? formik.values.values[index]?.subAttributeValueId || ""
                                : ""
                            }
                            // onBlur={formik.handleBlur}
                            // onChange={formik.handleChange}>
                            onChange={() => {}}>
                            {!!stoneAttributeValues?.length &&
                              stoneAttributeValues?.map((subAttr) => {
                                return subAttr.stoneAttribute?._id === stoneAttr?._id ? (
                                  <MenuItem
                                    key={subAttr._id}
                                    value={subAttr._id}>
                                    {subAttr?.title}
                                  </MenuItem>
                                ) : null;
                              })}
                          </Select>
                          {formik.touched.values?.length &&
                          !!formik.touched.values[index]?.subAttributeValueId &&
                          formik.errors.values?.length &&
                          formik.errors.values[index]?.subAttributeValueId ? (
                            <FormHelperText error>
                              {formik.errors.values[index]?.subAttributeValueId}
                            </FormHelperText>
                          ) : null}
                        </FormControl>
                      </div>
                    );
                  })}
              </div>
            ) : isOption ? (
              option &&
              attributes && (
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: `repeat(${
                      formik.values.metaAttributeId && subOptions ? subOptions?.length + 1 : 1
                    }, 1fr)`,
                    gap: "25px",
                  }}>
                  <div>
                    <Label required={true}>{option?.title}</Label>
                    <FormControl
                      sx={{ width: "100%" }}
                      size="small">
                      <Select
                        disabled
                        labelId="demo-select-small"
                        id="demo-select-small"
                        name="metaAttributeId"
                        value={formik.values.metaAttributeId}
                        // onBlur={formik.handleBlur}
                        // onChange={attributeHandler}>
                        onChange={() => {}}>
                        {attributes?.map((attr) => {
                          return (
                            <MenuItem
                              key={attr?._id}
                              value={attr?._id}>
                              {attr?.title}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      {!!formik.touched.metaAttributeId && formik.errors.metaAttributeId && (
                        <FormHelperText error>{formik.errors.metaAttributeId}</FormHelperText>
                      )}
                    </FormControl>
                  </div>
                  {formik.values.metaAttributeId &&
                    isSubOptionSuccess &&
                    !!formik.values?.values?.length &&
                    formik.values?.values?.map((value, index) => {
                      const subOp = subOptions.find(
                        (item) => item._id === value.metaSubAttributeId
                      );
                      if (!subOp) {
                        return null;
                      }
                      return (
                        <div key={subOp._id}>
                          <Label required={true}>{subOp?.title}</Label>
                          <FormControl
                            sx={{ width: "100%" }}
                            size="small">
                            <Select
                              disabled
                              labelId="demo-select-small"
                              id="demo-select-small"
                              name={`values[${index}].subAttributeValueId`}
                              value={
                                formik.values.values?.length
                                  ? formik.values.values[index]?.subAttributeValueId || ""
                                  : ""
                              }
                              // onBlur={formik.handleBlur}
                              // onChange={formik.handleChange}>
                              onChange={() => {}}>
                              {!!subAttributes?.length &&
                                subAttributes?.map((subAttr) => {
                                  return subAttr.metaSubAttribute?._id === subOp?._id ? (
                                    <MenuItem
                                      key={subAttr._id}
                                      value={subAttr._id}>
                                      {subAttr?.title}
                                    </MenuItem>
                                  ) : null;
                                })}
                            </Select>
                            {formik.touched.values?.length &&
                            !!formik.touched.values[index]?.subAttributeValueId &&
                            formik.errors.values?.length &&
                            formik.errors.values[index]?.subAttributeValueId ? (
                              <FormHelperText error>
                                {formik.errors.values[index]?.subAttributeValueId}
                              </FormHelperText>
                            ) : null}
                          </FormControl>
                        </div>
                      );
                    })}
                </div>
              )
            ) : (
              <>
                <div
                  className={
                    !!formik.values.categoryId && !!subCategories?.length ? "col-6" : "col-12"
                  }>
                  <Label required={true}>Select Category</Label>
                  <FormControl
                    sx={{ width: "100%" }}
                    size="small">
                    <Select
                      // disabled
                      labelId="demo-select-small"
                      id="demo-select-small"
                      name="categoryId"
                      value={formik.values.categoryId}
                      onBlur={formik.handleBlur}
                      onChange={categoryHandler}>
                      {/* // onChange={() => {}}> */}
                      {!!categories?.length ? (
                        categories?.map((category) => {
                          return (
                            <MenuItem
                              key={category?._id}
                              value={category?._id}>
                              {category.name}
                            </MenuItem>
                          );
                        })
                      ) : (
                        <span
                          style={{
                            fontSize: 13,
                            color: "#5c6d8e",
                            padding: "0 16px",
                            display: "block",
                          }}>
                          No Options
                        </span>
                      )}
                    </Select>
                    {!!formik.touched.categoryId && formik.errors.categoryId && (
                      <FormHelperText error>{formik.errors.categoryId}</FormHelperText>
                    )}
                  </FormControl>
                </div>
                {!!formik.values.categoryId && !!subCategories?.length && (
                  <div className="col-6">
                    <Label>Select Sub Category</Label>
                    <FormControl
                      sx={{ width: "100%" }}
                      size="small">
                      <Select
                        labelId="demo-select-small"
                        id="demo-select-small"
                        name="subCategory"
                        value={formik.values.subCategory}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}>
                        {subCategories?.map((subCategory) => {
                          return (
                            <MenuItem
                              key={subCategory?._id}
                              value={subCategory?._id}>
                              {subCategory.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      {!!formik.touched.subCategory && formik.errors.subCategory && (
                        <FormHelperText error>{formik.errors.subCategory}</FormHelperText>
                      )}
                    </FormControl>
                  </div>
                )}
              </>
            )}
          </div>
          <div className="row mb-3 mt-4">
            <div className="col-12">
              {formik.values.priceType === "weightRange" ? (
                <div
                  className="row bg-black-21 rounded-8 mx-0 py-3"
                  style={{ maxHeight: "20rem", overflowY: "scroll" }}>
                  <div className="col-12 ">
                    <div className="d-flex">
                      <p className="text-lightBlue">Price according to weight range</p>
                      <Tooltip
                        title="Lorem ipsum"
                        placement="top">
                        <img
                          src={info}
                          alt="info"
                          className="c-pointer ms-2"
                          width={13.5}
                        />
                      </Tooltip>
                    </div>
                  </div>
                  {formik.values.price.map((_, index) => {
                    return (
                      <div
                        key={index}
                        style={{
                          display: "grid",
                          gridTemplateColumns: isStone
                            ? "1fr 1fr 1fr 1fr 1fr auto"
                            : "1fr 1fr 1fr 1fr auto",
                          gap: "25px",
                        }}
                        className="mt-3">
                        {isStone && (
                          <div>
                            <Label>Size</Label>
                            <FormControl
                              sx={{ width: "100%" }}
                              size="small">
                              <Select
                                labelId="demo-select-small"
                                id="demo-select-small"
                                placeholder="Size"
                                name={`price[${index}].subAttributeValueId`}
                                value={
                                  formik.values.price?.length
                                    ? formik.values.price[index]?.subAttributeValueId
                                    : ""
                                }
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}>
                                {stoneAttributeValues
                                  ?.filter(
                                    (attr) => attr?.stoneAttribute?._id === sizeStoneAttrId
                                  )
                                  ?.map((subAttr) => {
                                    return (
                                      <MenuItem
                                        key={subAttr._id}
                                        value={subAttr._id}>
                                        {subAttr?.title}
                                      </MenuItem>
                                    );
                                  })}
                              </Select>
                              {formik.touched.price?.length &&
                                !!formik.touched.price[index]?.subAttributeValueId &&
                                formik.errors.price?.length &&
                                formik.errors.price[index]?.subAttributeValueId && (
                                  <FormHelperText error>
                                    {formik.errors.price[index]?.subAttributeValueId}
                                  </FormHelperText>
                                )}
                            </FormControl>
                          </div>
                        )}
                        <div>
                          <Label required={true}>Min Wt.</Label>
                          <FormControl className="w-100 px-0">
                            <OutlinedInput
                              placeholder="Enter Min Weight"
                              size="small"
                              name={`price[${index}].minWeight`}
                              value={
                                formik.values.price?.length
                                  ? formik.values.price[index]?.minWeight
                                  : ""
                              }
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              autoFocus={rangeAdded && index ? true : false}
                            />
                            {formik.touched.price?.length &&
                              !!formik.touched.price[index]?.minWeight &&
                              formik.errors.price?.length &&
                              formik.errors.price[index]?.minWeight && (
                                <FormHelperText error>
                                  {formik.errors.price[index]?.minWeight}
                                </FormHelperText>
                              )}
                          </FormControl>
                        </div>
                        <div>
                          <Label required={true}>Max Wt.</Label>
                          <FormControl className="w-100 px-0">
                            <OutlinedInput
                              placeholder="Enter Max Weight"
                              size="small"
                              name={`price[${index}].maxWeight`}
                              value={
                                formik.values.price?.length
                                  ? formik.values.price[index]?.maxWeight
                                  : ""
                              }
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                            />
                            {formik.touched.price?.length &&
                              !!formik.touched.price[index]?.maxWeight &&
                              formik.errors.price?.length &&
                              formik.errors.price[index]?.maxWeight && (
                                <FormHelperText error>
                                  {formik.errors.price[index]?.maxWeight}
                                </FormHelperText>
                              )}
                          </FormControl>
                        </div>
                        <div>
                          <Label required={true}>Price</Label>
                          <FormControl className="w-100 px-0">
                            <OutlinedInput
                              placeholder="Enter Price"
                              size="small"
                              name={`price[${index}].price`}
                              value={
                                formik.values.price?.length
                                  ? formik.values.price[index]?.price
                                  : ""
                              }
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                            />
                            {formik.touched.price?.length &&
                              !!formik.touched.price[index]?.price &&
                              formik.errors.price?.length &&
                              formik.errors.price[index]?.price && (
                                <FormHelperText error>
                                  {formik.errors.price[index]?.price}
                                </FormHelperText>
                              )}
                          </FormControl>
                        </div>
                        <div>
                          <Label required={true}>Price Type</Label>
                          <FormControl
                            sx={{ width: "100%" }}
                            size="small">
                            <Select
                              labelId="demo-select-small"
                              id="demo-select-small"
                              placeholder="Price Type"
                              name={`price[${index}].priceType`}
                              value={
                                formik.values.price?.length
                                  ? formik.values.price[index]?.priceType
                                  : ""
                              }
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}>
                              <MenuItem value="perGram">
                                {isStone ? "Per Carat" : "Per Gram"}
                              </MenuItem>
                              <MenuItem value="fixedPricing">Fixed Price</MenuItem>
                            </Select>
                            {formik.touched.price?.length &&
                              !!formik.touched.price[index]?.priceType &&
                              formik.errors.price?.length &&
                              formik.errors.price[index]?.priceType && (
                                <FormHelperText error>
                                  {formik.errors.price[index]?.priceType}
                                </FormHelperText>
                              )}
                          </FormControl>
                        </div>
                        <div>
                          <DeleteIconButton
                            style={{ marginTop: "1.4rem" }}
                            title="Delete"
                            onClick={onDeleteRange.bind(null, {
                              id: index,
                              message: "<span className='text-blue-2'>Price Range</span>",
                            })}
                          />
                        </div>
                      </div>
                    );
                  })}
                  <div className="col-12 text-end mt-3">
                    <button
                      onClick={onAddRange}
                      type="button"
                      className="reset link">
                      <p>+ Add More Range</p>
                    </button>
                  </div>
                </div>
              ) : (
                <div className="row bg-black-21 rounded-8 mx-0 py-3">
                  <div className="col-12 ">
                    <div className="d-flex">
                      <p className="text-lightBlue">
                        Default Pricing (Selection between Fixed Pricing or Per Gram Pricing)
                      </p>
                      <Tooltip
                        title="Lorem ipsum"
                        placement="top">
                        <img
                          src={info}
                          alt="info"
                          className="c-pointer ms-2"
                          width={13.5}
                        />
                      </Tooltip>
                    </div>
                  </div>
                  <div className="col-6 col-md-9 mt-3">
                    <Label required={true}>Price</Label>
                    <FormControl className="w-100 px-0">
                      <OutlinedInput
                        placeholder="Enter Price"
                        size="small"
                        name="price.price"
                        value={formik.values?.price?.price}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                      />
                      {!!formik.touched?.price?.price && formik.errors?.price?.price && (
                        <FormHelperText error>{formik.errors?.price?.price}</FormHelperText>
                      )}
                    </FormControl>
                  </div>
                  <div className="col-6 col-md-3 mt-3">
                    <Label required={true}>Price Type</Label>
                    <FormControl
                      sx={{ width: "100%" }}
                      size="small">
                      <Select
                        labelId="demo-select-small"
                        id="demo-select-small"
                        placeholder="Price Type"
                        name={`price.priceType`}
                        value={formik.values.price?.priceType}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}>
                        <MenuItem value="perGram">Per Gram</MenuItem>
                        <MenuItem value="fixedPricing">Fixed Price</MenuItem>
                      </Select>
                      {!!formik.touched?.price?.priceType &&
                        formik.errors?.price?.priceType && (
                          <FormHelperText error>
                            {formik.errors?.price?.priceType}
                          </FormHelperText>
                        )}
                    </FormControl>
                  </div>
                </div>
              )}
            </div>
          </div>
        </DialogContent>
        <hr className="hr-grey-6 my-0" />
        <DialogActions className="d-flex justify-content-between px-4 py-3">
          <button
            className="button-grey py-2 px-5"
            type="button"
            onClick={onCancel}>
            <p className="text-lightBlue">Cancel</p>
          </button>
          {isEditing ? (
            isUpdated ? (
              <LoadingButton
                loading={isLoading}
                disabled={isLoading}
                type="submit"
                className="button-gradient py-2 px-5">
                <p>Update</p>
              </LoadingButton>
            ) : (
              <button
                onClick={onCancel}
                type="button"
                className="button-gradient py-2 px-5">
                <p>Close</p>
              </button>
            )
          ) : (
            <LoadingButton
              loading={isLoading}
              disabled={isLoading}
              type="submit"
              className="button-gradient py-2 px-5">
              <p>Save</p>
            </LoadingButton>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddStandardModal;
