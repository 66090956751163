import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
// ! COMPONENT IMPORTS
// import TagsBox from "../../../components/TagsBox/TagsBox";
import OrderUserDetails from "../OrderUserDetails";
import OrderCartDetails from "../OrderCartDetails";
import OrderTimelines from "../OrderTimelines";
import OrderPayments from "../OrderPayments";
import OrderActions from "../OrderActions";
import OrderReturnBox from "../OrderReturnBox";
import OrderMoreActionsButton from "../OrderMoreActionsButton";
// ! IMAGES IMPORTS
import arrowLeft from "../../../assets/icons/arrowLeft.svg";
import paginationRight from "../../../assets/icons/paginationRight.svg";
import paginationLeft from "../../../assets/icons/paginationLeft.svg";
import indiaFlag from "../../../assets/images/products/indiaFlag.svg";
import {
  useGetSingleOrderQuery,
  useUpdateOrderMutation,
} from "../../../features/orders/ordersApiSlice";
import moment from "moment";
import PageLoader from "../../../components/Loader/PageLoader";
import TagsBoxBox from "../../../components/TagsBox/TagsBoxBox";
import { useDispatch } from "react-redux";
import { showError, showSuccess } from "../../../features/snackbar/snackbarAction";

const OrderDetails = () => {
  const dispatch = useDispatch();
  const { oid } = useParams();

  const { data: orderData, isLoading: orderIsLoading } = useGetSingleOrderQuery({
    id: oid,
  });

  // const currentOrder = orderData?.data?.data?.[0];
  const currentOrder = orderData?.data?.data;
  // console.log(currentOrder);

  const [orderTags, setOrderTags] = useState([]);
  useEffect(() => {
    setOrderTags(currentOrder?.tags ?? []);
  }, [currentOrder?.tags]);

  // console.log(currentOrder?.tags, orderTags, Object.is(currentOrder?.tags, orderTags));

  const [updateOrder, { isLoading: updateOrderIsLoading }] = useUpdateOrderMutation();

  const updateOrderTags = () => {
    updateOrder({ id: currentOrder?._id, order: { tags: orderTags } })
      .unwrap()
      .then(() => {
        dispatch(showSuccess({ message: "Tags updated successfully!" }));
      })
      .catch((e) => {
        console.log(e);
        dispatch(
          showError({ message: e?.data?.message ?? e?.message ?? "Something went wrong!" })
        );
      });
  };

  const downloadInvoice = async (url) => {
    try {
      const data = await fetch(`${url}?t=${Date.now().toString(36)}`);
      if (!data.ok)
        throw new Error(`Download Failed status:${data.status} message:${data.statusText}`);
      const blob = await data.blob();
      const objectUrl = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.setAttribute("href", objectUrl);
      link.setAttribute("download", "invoices.zip");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(objectUrl);
    } catch (error) {
      console.log(error);
      dispatch(showError({ message: error.message ?? "Something went wrong" }));
    }
  };

  return orderIsLoading ? (
    <PageLoader />
  ) : (
    <div className="page container-fluid">
      <div className="row justify-content-between">
        <div className="d-flex align-items-center w-auto ps-0">
          <Link
            to="/orders/allOrders"
            className="d-flex">
            <img
              src={arrowLeft}
              alt="arrowLeft"
              width={9}
              className="c-pointer"
            />
          </Link>
          <div>
            <h5 className="page-heading ms-2 ps-1">#{currentOrder?.orderID || currentOrder?.centralID}</h5>
            <div className="d-flex ms-2 ps-1 mt-1">
              <small className="text-lightBlue me-2">
              {currentOrder.salesOrderDate ?
                          (
                            moment.utc(currentOrder.salesOrderDate).format("DD-MM-YYYY [at] h:mm a")
                          ): moment(currentOrder.createdAt).format("DD-MM-YYYY [at] h:mm a")}
              </small>
              <img
                src={indiaFlag}
                alt="indiaFlag"
                width={20}
              />
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center w-auto pe-0">
          {/* <button className="button-lightBlue-outline py-1 px-4 me-3">
            <p>Refund</p>
          </button> */}
          {!!currentOrder?.invoiceUrl && (
            <button
              onClick={() => downloadInvoice(currentOrder?.invoiceUrl)}
              className="button-lightBlue-outline py-1 px-4 me-3">
              <p>Download Invoice</p>
            </button>
          )}
          <OrderMoreActionsButton order={currentOrder} />
          {/* <img
            src={paginationLeft}
            alt="paginationLeft"
            className="c-pointer"
            width={30}
          />
          <img
            src={paginationRight}
            alt="paginationRight"
            className="c-pointer"
            width={30}
          /> */}
        </div>
      </div>
      <div className="row">
        <div className="col-lg-9">
          <OrderCartDetails
            showCartButton={false}
            showEditButton={false}
            showBasicDetail={true}
            showItemAvailable={true}
            showActionButton={true}
            showFulfillButton={false}
            showResolveButton={false}
            showQCButton={false}
            showTrackingBox={true}
            showReturnButton={false}
            showExchangeButton={false}
            showShippingLabelButton={false}
            showCreateLabelButton={false}
            showCreateExchangeButton={false}
            showRefundAmountButton={false}
            showCustomerNote={true}
            showMadeOrderChip={true}
            showOrderID={false}
            showDetails={true}
            showNoOfItems={true}
            showRestocking={false}
            showConfirm={true}
            showSelectCheckbox={false}
            data={currentOrder}
          />
          {/* <OrderReturnBox
            showBasicDetail={true}
            showItemAvailable={true}
            showActionButton={true}
            showQCButton={true}
            showProductReceived={true}
          /> */}
          <OrderPayments
            showMarkAsPaidButton={false}
            showCollectPaymentButton={false}
            data={currentOrder}
          />
          {/* <OrderActions /> */}
          <OrderTimelines oid={oid} />
        </div>
        <div className="col-lg-3 mt-4 pe-0 ps-0 ps-lg-3">
          <OrderUserDetails
            showGST={true}
            showMediaData={false}
            showReviewData={false}
            data={currentOrder}
          />
          <TagsBoxBox
            moduleName="Order"
            filterQuery="order"
            initTags={currentOrder?.tags ?? []}
            onTagsChange={setOrderTags}
          />
          {!Object.is(currentOrder?.tags, orderTags) && (
            <div className="d-flex justify-content-center mt-2">
              <button
                onClick={updateOrderTags}
                disabled={updateOrderIsLoading || orderIsLoading}
                className="button-gradient py-1 px-4 w-auto me-3">
                <p>Update</p>
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;
