import { Chip, TextField } from "@mui/material";
import Heading from "../../components/Headers/DashboardHeader";
// import SalesRatio from "./SalesRatio";
// import usa from "../../assets/flag/usa.svg";
// import germany from "../../assets/flag/germany.svg";
// import maps from "../../assets/dashboard/Maps.svg";
// import product from "../../assets/dashboard/sellingProducts.svg";
// import bharat from "../../assets/flag/india.svg";
import product from "../../assets/images/Components/collection.svg";
import { useMemo, useState } from "react";
import { useGetTopSellingProductsQuery } from "../../features/analytics/analyticsApiSlice";
import moment from "moment/moment";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import AsyncImage from "../../components/AsyncImage/AsyncImage";
import { Link } from "react-router-dom";
import NoDataFound from "../../components/NoDataFound/NoDataFound";

const timeData = [
  { value: "week", name: "This Week" },
  { value: "month", name: "This Month" },
  { value: "year", name: "This Year" },
  { value: "", name: "Custom" },
];

const nowMoment = moment();

export default function TopSellingProducts() {
  const [timeFrame, setTimeFrame] = useState("week");
  const [fromDate, setFromDate] = useState(moment().subtract(1, "week").format("YYYY-MM-DD"));
  const [toDate, setToDate] = useState(nowMoment.format("YYYY-MM-DD"));

  const filter = useMemo(() => {
    if (!timeFrame) return { to: toDate, from: fromDate };
    else return { type: timeFrame };
  }, [fromDate, timeFrame, toDate]);

  const { data } = useGetTopSellingProductsQuery(filter);

  const salesData = useMemo(
    () => data?.flatMap((wrap) => wrap?.top_products?.buckets ?? []) ?? [],
    [data]
  );

  const onTimeFrameChange = (val) => {
    switch (val) {
      case "week":
        setTimeFrame("week");
        setFromDate(moment().subtract(1, "week").format("YYYY-MM-DD"));
        setToDate(moment().format("YYYY-MM-DD"));
        break;
      case "month":
        setTimeFrame("month");
        setFromDate(moment().subtract(1, "month").format("YYYY-MM-DD"));
        setToDate(moment().format("YYYY-MM-DD"));
        break;
      case "year":
        setTimeFrame("year");
        setFromDate(moment().subtract(1, "year").format("YYYY-MM-DD"));
        setToDate(moment().format("YYYY-MM-DD"));
        break;

      default:
        setTimeFrame("");
        break;
    }
  };

  return (
    <div className="bg-button-3 border-grey-5 px-3 py-3 rounded-8 mt-4">
      <Heading
        heading={"Most Selling Products"}
        // viewMore={"View More"}
      />
      <div className="d-flex align-items-center my-3">
        {timeData.map((item) => (
          <Chip
            key={item.value}
            onClick={() => onTimeFrameChange(item.value)}
            variant={timeFrame === item.value ? "filled" : "outlined"}
            label={item.name}
            className="mx-2"
          />
        ))}
        {!timeFrame && (
          <>
            <div className="mx-2">
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DesktopDatePicker
                  maxDate={moment()}
                  value={moment(fromDate)}
                  onChange={(newValue) => setFromDate(newValue.format("YYYY-MM-DD"))}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
            <div className="mx-2">
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DesktopDatePicker
                  maxDate={moment()}
                  value={moment(toDate)}
                  onChange={(newValue) => setToDate(newValue.format("YYYY-MM-DD"))}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
          </>
        )}
      </div>
      <div className="d-flex row justify-content-between">
        <div className="mt-4 col-12 row gap-3">
          {salesData.length > 0 ? (
            <div className="image-container">
              {salesData.map((item) => (
                <div
                  key={item?.key}
                  className="col-lg-3">
                  <div className="d-flex justify-content-center">
                    <AsyncImage
                      src={item?.mediaUrl?.find((url) => url?.isDefault)?.image}
                      placeholder={product}
                      alt="product"
                      className="rounded-8"
                      height={160}
                      width={160}
                    />
                  </div>

                  <Link
                    to={`/products/allProducts/editProduct/${item?.key}`}
                    className="text-decoration-none">
                    <p
                      className="text-lightBlue text-center mt-3 mb-1"
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}>
                      {item?.product_name}
                    </p>
                  </Link>
                  <div className="d-flex align-items-center justify-content-center">
                    <p className="text-blue-gradient-2 mx-1">{item?.doc_count}</p>
                    <p className="text-lightBlue fw-400 mx-1">Orders</p>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <NoDataFound />
          )}
        </div>
      </div>
    </div>
  );
}
