import { useState } from "react";
import {
  Box,
  Paper,
  Tab,
  Tabs,
  Popover,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@mui/material";

import sort from "../../assets/icons/sort.svg";
import arrowDown from "../../assets/icons/arrowDown.svg";

const SortDropdown = (props) => {
  const { options, onChange, query } = props;
  const [anchorSortEl, setAnchorSortEl] = useState(null);

  const openHandler = (event) => {
    setAnchorSortEl(event.currentTarget);
  };

  const closeHandler = () => {
    setAnchorSortEl(null);
  };

  const sortHandler = (e) => {
    onChange(e);
    setAnchorSortEl(null);
  };
  return (
    <>
      <button
        className="button-grey py-2 px-3 ms-2"
        variant="contained"
        onClick={openHandler}
      >
        <small className="text-lightBlue me-2">Sort</small>
        <img src={sort} alt="sort" className="" />
      </button>
      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={!!anchorSortEl}
        anchorEl={anchorSortEl}
        onClose={closeHandler}
        className="columns"
      >
        <FormControl className="px-2 py-1">
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            onChange={sortHandler}
          >
            {options.map((option) => {
              return (
                <FormControlLabel
                  key={option.id}
                  value={option.value}
                  control={
                    <Radio
                      size="small"
                      name={option.key}
                      checked={
                        query[option.key]?.toString() ===
                        option.value.toString()
                      }
                    />
                  }
                  label={option.label}
                />
              );
            })}
          </RadioGroup>
        </FormControl>
      </Popover>
    </>
  );
};

export default SortDropdown;
