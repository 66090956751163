import { forwardRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  OutlinedInput,
  Slide,
} from "@mui/material";
import { showError, showSuccess } from "../../../features/snackbar/snackbarAction";
import cancel from "../../../assets/icons/cancel.svg";
import { LoadingButton } from "@mui/lab";
import { useCreateLabelsBadgesMutation } from "../../../features/functionality/labelsBadges/labelsBadges";

const Transition = forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="up"
      ref={ref}
      {...props}
    />
  );
});

export default function DuplicateDialog({ chart = {}, onClose = () => {} }) {
  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={!!chart?._id}
      onClose={onClose}
      TransitionComponent={Transition}>
      <Content
        chart={chart}
        onClose={onClose}
      />
    </Dialog>
  );
}

function Content({ chart = {}, onClose = () => {} }) {
  const {
    type,
    name,
    status,
    contentType,
    image,
    text,
    scheduleVisibility,
    startDate,
    endDate,
    color,
    addProductType,
    products,
  } = chart ?? {};
  const [item, setItem] = useState({
    type,
    name: `${name} Copy`,
    status,
    contentType,
    image,
    text,
    scheduleVisibility,
    startDate,
    endDate,
    color,
    addProductType,
    products,
  });
  const [copyContent, setCopyContent] = useState(true);
  const [copyProduct, setCopyProduct] = useState(true);
  const [createSizeContent, { isLoading: createSizeContentIsLoading }] =
    useCreateLabelsBadgesMutation();
  const dispatch = useDispatch();
  return (
    <>
      <DialogTitle>
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex flex-column ">
            <h4 className="text-capitalize text-lightBlue fw-500">Duplicate {type}</h4>
          </div>
          <button
            className="reset"
            onClick={onClose}>
            <img
              src={cancel}
              alt="cancel"
              width={30}
              className="c-pointer"
            />
          </button>
        </div>
      </DialogTitle>
      <hr className="hr-grey-6 my-0" />
      <DialogContent>
        <div className="row align-items-start">
          <div className="col-12">
            <div className="mb-1">
              <span className="text-lightBlue fw-400">Name</span>
            </div>
            <FormControl
              size="small"
              fullWidth
              className="">
              <OutlinedInput
                value={item.name}
                onChange={(e) => setItem((sc) => ({ ...sc, name: e.target.value }))}
                placeholder="Size Content Name"
              />
            </FormControl>
          </div>
        </div>
      </DialogContent>
      <hr className="hr-grey-6 my-0" />
      <DialogContent>
        <div className="row align-items-start">
          <div className="col-12">
            <div className="mb-3">
              <h5 className="text-lightBlue fw-500">
                What to include in this Duplicate
              </h5>
            </div>
            <FormGroup
              onChange={(e) => {
                switch (e.target.value) {
                  case "item":
                    setCopyContent(e.target.checked);
                    if (e.target.checked) {
                      setItem((sc) => ({
                        ...sc,
                        contentType,
                        image,
                        text,
                        scheduleVisibility,
                        startDate,
                        endDate,
                        color,
                      }));
                    } else {
                      setItem((sc) => ({
                        ...sc,
                        contentType: undefined,
                        image: undefined,
                        text: undefined,
                        scheduleVisibility: undefined,
                        startDate: undefined,
                        endDate: undefined,
                        color: undefined,
                      }));
                    }
                    break;
                  case "product":
                    setCopyProduct(e.target.checked);
                    if (e.target.checked) {
                      setItem((sc) => ({ ...sc, addProductType, products }));
                    } else {
                      setItem((sc) => ({
                        ...sc,
                        addProductType: undefined,
                        products: undefined,
                      }));
                    }
                    break;

                  default:
                    break;
                }
              }}>
              <FormControlLabel
                label="The Content"
                value="item"
                className="text-lightBlue"
                checked={copyContent}
                control={<Checkbox size="small" />}
              />
              <FormControlLabel
                label="The Products"
                value="product"
                className="text-lightBlue"
                checked={copyProduct}
                control={<Checkbox size="small" />}
              />
            </FormGroup>
          </div>
        </div>
      </DialogContent>
      <DialogActions className="d-flex justify-content-between px-4 py-3">
        <button
          onClick={onClose}
          className="button-grey-outline py-2 px-4"
          style={{ lineHeight: 1.6 }}>
          <p className="text-lightBlue">Cancel</p>
        </button>
        <LoadingButton
          loading={createSizeContentIsLoading}
          disabled={createSizeContentIsLoading}
          onClick={() => {
            createSizeContent(item)
              .unwrap()
              .then(() => {
                onClose();
                dispatch(showSuccess({ message: "Size Content created successfully" }));
              })
              .catch((e) =>
                dispatch(
                  showError({ message: e?.data?.message ?? "Something went wrong" })
                )
              );
          }}
          className="button-gradient py-2 px-4"
          style={{
            lineHeight: 1.6,
            textTransform: "Capitalize",
          }}>
          <p>Save</p>
        </LoadingButton>
      </DialogActions>
    </>
  );
}
