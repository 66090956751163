import { Tooltip } from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import Container from '../../../components/Container/Container';
import SideStoreBox from "../../../components/Setting/Store/SideBox";
import info from "../../../assets/icons/info.svg";

const orderData = [
  { name: "New Order Received" },
  { name: "Draft Order" },
  { name: "Return Request" },
  { name: "Exchange Request" }
]

const productData = [
  { name: "New Product Added" },
  { name: "Draft Order" },
  { name: "Return Request" },
  { name: "Exchange Request" }
]

const storeData = [
  { name: "Notify Change in settings" },
  { name: "Global Store Notification" }
]

const Notification = () => {

  return (
    <div className="border-grey-5 rounded-8 px-4 pt-4 bg-black-15 mt-5">
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <h4 className="page-heading w-auto ps-0 mr-2">Notification</h4>
          <Tooltip
            title="Lorem ipsum"
            placement="top"
          >
            <img
              src={info}
              alt="info"
              className="c-pointer"
              width={13.5}
            />
          </Tooltip>
        </div>
        <div className="d-flex align-items-center text-lightBlue">
          <small>Recieve Notification on:</small>
          <small className="mx-3">
            <Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 18 }, color: "#C8D8FF" }} />
            Email
          </small>
          <small>
            <Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 18 }, color: "#C8D8FF" }} />
            SMS
          </small>
          <small className="ms-3">
            <Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 18 }, color: "#C8D8FF" }} />
            Whatsapp
          </small>
        </div>
      </div>
      <hr className="hr-grey-6 my-3 w-100" />

      <div className="row mb-2">
        <SideStoreBox
          heading={"Order Notification"}
          details={`Lorem ipsum dolor sit amet consectetur adipisicing elit.`}
        />
        <div className="col-lg-8 d-grid text-lightBlue">
          {orderData.map((item) => (
            <small className="mx-3">
              <Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 18 }, color: "#C8D8FF" }} />
              {item.name}
            </small>
          ))}
        </div>
      </div>
      <hr className="hr-grey-6 my-3 w-100" />

      <div className="row mb-2">
        <SideStoreBox
          heading={"Product Notification"}
          details={`Lorem ipsum dolor sit amet consectetur adipisicing elit.`}
        />
        <div className="col-lg-8 d-grid text-lightBlue">
          {productData.map((item) => (
            <small className="mx-3">
              <Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 18 }, color: "#C8D8FF" }} />
              {item.name}
            </small>
          ))}
        </div>
      </div>
      <hr className="hr-grey-6 my-3 w-100" />

      <div className="row mb-3">
        <SideStoreBox
          heading={"Store Notification"}
          details={`Lorem ipsum dolor sit amet consectetur adipisicing elit.`}
        />
        <div className="col-lg-8 d-grid text-lightBlue">
          {storeData.map((item) => (
            <small className="mx-3">
              <Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 18 }, color: "#C8D8FF" }} />
              {item.name}
            </small>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Notification;