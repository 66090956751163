import { useState, forwardRef, useReducer } from "react";
import _ from "lodash";
import { useDispatch } from "react-redux";
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  Tab,
  Tabs
} from "@mui/material";

import { 
    useGetAllCustomersQuery, 
    useBulkAddCustomerMutation 
} from "../../features/customers/customer/customerApiSlice";
import { useGetAllCustomerGroupQuery } from "../../features/customers/customerGroup/customerGroupApiSlice";
import {
  showSuccess,
} from "../../features/snackbar/snackbarAction";

import TabPanel from "../../components/TabPanel/TabPanel";
import TableSearch from "../../components/TableSearch/TableSearch";
import { TableSearchSecondary } from "../../components/TableSearch/TableSearch";

import cancel from "../../assets/icons/cancel.svg";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const initialQueryFilterState = {
  searchValue: ""
};

const queryFilterReducer = (state, action) => {
  if (action.type === "SEARCH") {
    return {
      ...state,
      pageNo: initialQueryFilterState.pageNo,
      name: action.name,
    };
  }
  if (action.type === "SET_SEARCH_VALUE") {
    return {
      ...state,
      pageNo: initialQueryFilterState.pageNo,
      searchValue: action.searchValue,
    };
  }
  return initialQueryFilterState;
};

const AddCustomerGroup = ({ onConfirm, customerId, show, selected, singleCustomer }) => {
    const dispatch = useDispatch();
    const [showTab, setShowTab] = useState(0);
    const [selectedGroupIds, setSelectedGroupIds] = useState([]);
    const [queryFilterState, dispatchQueryFilter] = useReducer(
        queryFilterReducer,
        initialQueryFilterState
    );
    
    const {
        data: customerData,
        isLoading: customerIsLoading,
        error: customerIsError,
        isSuccess: customerIsSuccess,
    } = useGetAllCustomersQuery({id: customerId});

    const[
        bulkAddCustomer,
        {
            data: bulkAddCustomers,
            isLoading: bulkCustomerAddLoading,
            isSuccess: bulkCustomerAddIsSuccess,
            error: bulkCustomerAddError,
        }
    ] = useBulkAddCustomerMutation();

    const {
        data: customerGroupData,
        isLoading: customerGroupIsLoading,
        isSuccess: customerGroupIsSuccess,
        error: customerGroupError,
    } = useGetAllCustomerGroupQuery({...queryFilterState});

    const handleTab = (_, index) => {
        setShowTab(index)
    }

    const customerGroups = selected?.map((customerId) => ({
        customer: customerId,
        userGroup: selectedGroupIds,
        type: showTab == 0 ? "add" : "remove"
    }));

    const requestData = {
        customer: customerId,
        userGroup: selectedGroupIds,
        type: showTab == 0 ? "add" : "remove"
    };

    const saveGroups = () => {
        bulkAddCustomer({
                updates: singleCustomer == true ? [requestData] : customerGroups
            }).unwrap().then(()=> {
            dispatch(showSuccess({ message: "Customers updated successfully!" }));
            onConfirm()
            setSelectedGroupIds([])
        })
    }

    const handleSearchChange = (value) => {
        dispatchQueryFilter({ type: "SEARCH", name: value });
    };

    const handleSearchValue =(value)=>{
        dispatchQueryFilter({ type: "SET_SEARCH_VALUE", searchValue: value });
    }

    return (
        <Dialog
            open={show}
            TransitionComponent={Transition}
            keepMounted
            onClose={onConfirm}
            aria-describedby="alert-dialog-slide-description"
            maxWidth="md"
            fullWidth={true}
        >
            <DialogTitle>
                <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex flex-column ">
                        <h5 className="text-lightBlue fw-500">Manage Customer Group</h5>

                        <small className="text-grey-6 mt-1 d-block">
                            ⓘ Add or remove group from the customer.Manage tags in 
                        </small>
                    </div>
                    <img
                        src={cancel}
                        alt="cancel"
                        width={30}
                        onClick={onConfirm}
                        className="c-pointer"
                    />
                </div>
            </DialogTitle>
            <DialogContent className="pb-4 px-4">
                <Tabs
                    aria-label="scrollable force tabs example"
                    className="tabs"
                    value={showTab}
                    onChange={handleTab}
                >
                <Tab label="Add" className="tabs-head" />
                <Tab label="Remove" className="tabs-head" />
                </Tabs>
                <hr className="hr-grey-6 my-0" />
                <TabPanel value={showTab} index={0}>
                    <div className="mt-4 mb-3">
                        <TableSearchSecondary 
                            onSearchValueChange={handleSearchValue} 
                            value={queryFilterState.searchValue} 
                            onChange={handleSearchChange} 
                        />
                    </div>
                        {customerGroupData?.data?.data?.map((group) => (
                            <p className="mt-1 text-lightBlue" key={group._id}>
                                <Checkbox 
                                    size="small"
                                    style={{
                                        color: "#5C6D8E",
                                        marginRight: 0,
                                    }}
                                    checked={selectedGroupIds.includes(group._id)}
                                    onChange={() => {
                                        if (selectedGroupIds.includes(group._id)) {
                                            setSelectedGroupIds(prevIds => prevIds.filter(id => id !== group._id));
                                        } else {
                                            setSelectedGroupIds(prevIds => [...prevIds, group._id]);
                                        }
                                    }}
                                />
                                {group?.name}
                            </p>
                        ))}
                </TabPanel>
                <TabPanel value={showTab} index={1}>
                    <div className="mt-4 mb-3">
                        <TableSearchSecondary 
                            onSearchValueChange={handleSearchValue} 
                            value={queryFilterState.searchValue} 
                            onChange={handleSearchChange} 
                        />
                    </div>
                        {customerGroupData?.data?.data?.map((group) => (
                            <p className="mt-1 text-lightBlue" key={group._id}>
                                <Checkbox 
                                    size="small"
                                    style={{
                                        color: "#5C6D8E",
                                        marginRight: 0,
                                    }}
                                    checked={selectedGroupIds.includes(group._id)}
                                    onChange={() => {
                                        if (selectedGroupIds.includes(group._id)) {
                                            setSelectedGroupIds(prevIds => prevIds.filter(id => id !== group._id));
                                        } else {
                                            setSelectedGroupIds(prevIds => [...prevIds, group._id]);
                                        }
                                    }}
                                />
                                {group?.name}
                            </p>
                        ))}
                </TabPanel>
            </DialogContent>
            <hr className="hr-grey-6 my-0" />
            <DialogActions className="d-flex justify-content-between px-4 py-3">
                <button
                    className="button-grey py-2 px-5"
                    onClick={onConfirm}
                >
                    <p className="text-lightBlue">Cancel</p>
                </button>
                <button
                    className="button-gradient py-2 px-5"
                    onClick={() => saveGroups()}
                >
                    <p>Save</p>
                </button>
            </DialogActions>
        </Dialog>
    );
};

export default AddCustomerGroup;