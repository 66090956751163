import { Tooltip } from '@mui/material';
import info from '../../assets/icons/info.svg';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

const SettingHeader = ({
  heading,
  buttonName,
  handleButton,
  subHeading,
  backHandler,
}) => {
  return (
    <div className='col d-flex justify-content-between'>
      <div className='d-flex align-items-center'>
        <div className='d-flex c-pointer' onClick={backHandler}>
          {backHandler && <ChevronLeftIcon sx={{ color: '#c8d8ff' }} />}
          <h4 className='page-heading w-auto ps-0 mr-2'>{heading}</h4>
        </div>

        <Tooltip title='Lorem ipsum' placement='top'>
          <img src={info} alt='info' className='c-pointer' width={13.5} />
        </Tooltip>
      </div>

      <div className='d-flex'>
        {subHeading && (
          <div className='text-lightBlue py-2 px-4'>
            <p>{subHeading}</p>
          </div>
        )}
        {buttonName && (
          <div className='button-gradient py-2 px-4' onClick={handleButton}>
            <p>{buttonName}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default SettingHeader;
