import { useCallback, useState } from "react";
// ! IMAGES IMPORTS
import info from "../../assets/icons/info.svg";
// import hanger1 from "../../assets/icons/hanger1.svg";
// import ruler1 from "../../assets/icons/ruler1.svg";
// import tape1 from "../../assets/icons/tape1.svg";
// import activity from "../../assets/icons/activity.svg";
// import teamMember1 from "../../assets/images/products/teamMember1.svg";
import cancel from "../../assets/icons/cancel.svg";
// ! MATERIAL IMPORTS
import {
  SwipeableDrawer,
  Box,
  Button,
  Tooltip,
  FormControl,
  OutlinedInput,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
// ! MATERIAL ICONS IMPORTS
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  useEditSizeChartSettingMutation,
  useGetAllSizeChartSettingsQuery,
} from "../../features/functionality/sizeChartSetting/sizeChartSettingApiSlice";
import { LoadingButton } from "@mui/lab";
import { useDispatch } from "react-redux";
import { showError, showSuccess } from "../../features/snackbar/snackbarAction";
import { UploadMediaSmall } from "../../components/UploadMediaBox/UploadMedia";
import { useGetAllOptionsQuery } from "../../features/parameters/options/optionsApiSlice";

const validationSchema = Yup.object({
  name: Yup.string().trim().required("required"),
  icon: Yup.string().url(),
  labelPosition: Yup.object({
    horizontalPosition: Yup.string().oneOf(["top", "bottom"]).required("required"),
    verticalPosition: Yup.string().oneOf(["left", "right", "center"]).required("required"),
    onOption: Yup.string().trim(),
  }),
  openAs: Yup.object({
    type: Yup.string().oneOf(["center", "sideDrower"]).required("required"),
    position: Yup.string()
      .oneOf(["centerTop", "centerBottom", "right", "left"])
      .required("required"),
  }),
});

const ViewSizeChartSettings = () => {
  const [isOpen, setIsOpen] = useState(false);
  const closeSwipeableDrawer = (e) => {
    if (e?.target?.classList?.contains("MuiBackdrop-root")) return;
    setIsOpen(false);
  };

  const dispatch = useDispatch();

  const [editSizeChartSetting, { isLoading: editSizeChartSettingIsLoading }] =
    useEditSizeChartSettingMutation();
  const {
    data: sizeChartSettingData,
    refetch: sizeChartSettingRefetch,
    isSuccess: sizeChartSettingIsSuccess,
  } = useGetAllSizeChartSettingsQuery({}, { skip: !isOpen });
  const {
    _id = "",
    name = "",
    icon = "",
    labelPosition: { horizontalPosition = "top", verticalPosition = "right", onOption = "" },
    openAs: { type = "sideDrower", position = "centerBottom" },
  } = sizeChartSettingData?.data?.data?.[0] ?? { labelPosition: {}, openAs: {} };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name,
      icon,
      labelPosition: { horizontalPosition, verticalPosition, onOption },
      openAs: { type, position },
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      editSizeChartSetting({ id: _id, details: values })
        .unwrap()
        .then(() => {
          closeSwipeableDrawer();
          dispatch(showSuccess({ message: "Size Chart Settings updated successfully" }));
        })
        .catch((e) =>
          dispatch(showError({ message: e?.data?.message ?? "Something went wrong" }))
        );
    },
  });

  // console.log(
  //   JSON.stringify(
  //     {
  //       values: formik.values,
  //       errors: formik.errors,
  //       touched: formik.touched,
  //     },
  //     null,
  //     1
  //   )
  // );

  const {
    data: allOptionsData,
    refetch: allOptionsRefetch,
    isSuccess: allOptionsIsSuccess,
  } = useGetAllOptionsQuery({}, { skip: !isOpen });
  const allOptions =
    allOptionsData?.data?.map(({ _id, frontEndTitle }) => ({ _id, frontEndTitle })) ?? [];

  const IconUploadHandler = useCallback((url) => formik.setFieldValue("icon", url), [formik]);

  const onOpenHandler = () => {
    sizeChartSettingRefetch();
    allOptionsRefetch();
  };

  return (
    <>
      <button
        onClick={() => setIsOpen(true)}
        className="button-transparent me-1 py-2 px-3">
        <p className="text-lightBlue">Settings</p>
      </button>

      <SwipeableDrawer
        open={isOpen}
        onClose={closeSwipeableDrawer}
        onOpen={onOpenHandler}
        PaperProps={{ style: { width: "45%" } }}
        anchor="right">
        <Box p={2}>
          <div className="row align-items-center">
            <div className="col">
              <h6 className="text-lightBlue">Size Chart Settings</h6>
            </div>
            <div className="col-auto">
              <img
                src={cancel}
                width={25}
                alt="cancel"
                onClick={closeSwipeableDrawer}
                className="c-pointer filter-icon me-1"
              />
            </div>
          </div>
          <hr className="hr-grey-6" />
          {sizeChartSettingIsSuccess && _id && allOptionsIsSuccess && (
            <form
              noValidate
              onReset={formik.handleReset}
              onSubmit={formik.handleSubmit}>
              <div className="row align-items-start">
                <div className="col-12">
                  <h6 className="text-grey-6 fw-500 mb-3">Size Chart Button</h6>
                </div>
                <div className="col-8">
                  <div className="row align-items-start">
                    <div className="col-12">
                      <div className="mb-2">
                        <span className="text-lightBlue fw-400 text-3">Name</span>
                        <Tooltip
                          title="Some text"
                          placement="top">
                          <img
                            src={info}
                            alt="info"
                            className="ms-2 c-pointer"
                            width={13.5}
                          />
                        </Tooltip>
                      </div>
                      <FormControl
                        size="small"
                        className="w-100 px-0">
                        <OutlinedInput
                          placeholder="Find my size?"
                          name="name"
                          value={formik.values.name}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        <FormHelperText error>
                          {formik.touched.name && formik.errors.name}
                        </FormHelperText>
                      </FormControl>
                      <div>
                        <small className="text-grey-6 mt-2 d-block">
                          Customer can see this on frontend
                        </small>
                      </div>
                    </div>
                  </div>
                  <div className="row align-items-start mt-3">
                    <div className="col-12">
                      <div className="d-flex flex-column align-items-start">
                        <UploadMediaSmall
                          fileSrc={formik.values.icon}
                          error={formik.errors.icon}
                          onUpload={IconUploadHandler}
                          onBlur={formik.handleBlur}
                          name="icon"
                          module="sizeChart"
                          isSubmitting={formik.isSubmitting}
                          touched={formik.touched.icon}
                        />
                        <div>
                          <small className="text-grey-6 mt-2 d-block">
                            Recommended Size 100x100 or Ratio 1:1
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="border-grey-5 bg-black-15 rounded-4 w-100 m-0 py-2 px-3">
                    <p className="text-grey-6 fw-500 fs-7 mb-3">Preview:</p>
                    <Button
                      variant="text"
                      startIcon={
                        Boolean(formik.values.icon) ? (
                          <img
                            src={formik.values.icon}
                            alt="icon"
                            width={20}
                          />
                        ) : undefined
                      }>
                      <span>{formik.values.name}</span>
                    </Button>
                  </div>
                </div>
                <div className="col-12 mt-3">
                  <div className="row align-items-start">
                    <div className="col-12 mb-2">
                      <span className="text-lightBlue fw-400 text-3">Position</span>
                      <Tooltip
                        title="Some text"
                        placement="top">
                        <img
                          src={info}
                          alt="info"
                          className="ms-2 c-pointer"
                          width={13.5}
                        />
                      </Tooltip>
                    </div>
                    <div className="col">
                      <FormControl
                        size="small"
                        className="w-100 px-0">
                        <Select
                          size="small"
                          name="labelPosition.horizontalPosition"
                          value={formik.values.labelPosition?.horizontalPosition}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}>
                          <MenuItem value="top">Top</MenuItem>
                          <MenuItem value="bottom">Bottom</MenuItem>
                        </Select>
                        <FormHelperText error>
                          {formik.touched.labelPosition?.horizontalPosition &&
                            formik.errors.labelPosition?.horizontalPosition}
                        </FormHelperText>
                      </FormControl>
                    </div>
                    <div className="col">
                      <FormControl
                        size="small"
                        className="w-100 px-0">
                        <Select
                          size="small"
                          name="labelPosition.verticalPosition"
                          value={formik.values.labelPosition?.verticalPosition}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}>
                          <MenuItem value="left">Left</MenuItem>
                          <MenuItem value="right">Right</MenuItem>
                          <MenuItem value="center">Center</MenuItem>
                        </Select>
                        <FormHelperText error>
                          {formik.touched.labelPosition?.verticalPosition &&
                            formik.errors.labelPosition?.verticalPosition}
                        </FormHelperText>
                      </FormControl>
                    </div>
                    <div className="col-auto">
                      <span className="text-lightBlue mt-2 fw-400">to</span>
                    </div>
                    <div className="col-5">
                      <FormControl
                        size="small"
                        className="w-100 px-0">
                        <Select
                          size="small"
                          name="labelPosition.onOption"
                          value={formik.values.labelPosition?.onOption}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}>
                          {allOptions.map(({ _id, frontEndTitle }) => (
                            <MenuItem
                              key={_id}
                              value={_id}>
                              Option: {frontEndTitle}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText error>
                          {formik.touched.labelPosition?.onOption &&
                            formik.errors.labelPosition?.onOption}
                        </FormHelperText>
                      </FormControl>
                    </div>
                  </div>
                </div>
              </div>
              <hr className="hr-grey-6" />
              <div className="row align-items-start">
                <div className="col-12">
                  <h6 className="text-grey-6 fw-500 mb-3">Open Size Chart as</h6>
                </div>
                <div className="col-6">
                  <div className="mb-2">
                    <span className="text-lightBlue fw-400 text-3">Select type</span>
                    <Tooltip
                      title="Some text"
                      placement="top">
                      <img
                        src={info}
                        alt="info"
                        className="ms-2 c-pointer"
                        width={13.5}
                      />
                    </Tooltip>
                  </div>
                  <FormControl
                    size="small"
                    className="w-100 px-0">
                    <Select
                      size="small"
                      name="openAs.type"
                      value={formik.values.openAs?.type}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}>
                      <MenuItem value="center">Center Pop-up</MenuItem>
                      <MenuItem value="sideDrower">Side Drawer Pop-up</MenuItem>
                    </Select>
                    <FormHelperText error>
                      {formik.touched.openAs?.type && formik.errors.openAs?.type}
                    </FormHelperText>
                  </FormControl>
                  <div>
                    <small className="text-grey-6 mt-2 d-block">
                      <small className="text-blue-2">click here</small> to preview
                    </small>
                  </div>
                </div>
                <div className="col-6">
                  <div className="mb-2">
                    <span className="text-lightBlue fw-400 text-3">Side drawer position</span>
                    <Tooltip
                      title="Some text"
                      placement="top">
                      <img
                        alt="info"
                        src={info}
                        width={13.5}
                        className="ms-2 c-pointer"
                      />
                    </Tooltip>
                  </div>
                  <FormControl
                    size="small"
                    className="w-100 px-0">
                    <Select
                      size="small"
                      name="openAs.position"
                      value={formik.values.openAs?.position}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}>
                      <MenuItem value="centerTop">Center Top</MenuItem>
                      <MenuItem value="centerBottom">Center Bottom</MenuItem>
                      <MenuItem value="left">Left</MenuItem>
                      <MenuItem value="right">Right</MenuItem>
                    </Select>
                    <FormHelperText error>
                      {formik.touched.openAs?.position && formik.errors.openAs?.position}
                    </FormHelperText>
                  </FormControl>
                </div>
              </div>
              <hr className="hr-grey-6" />
              <div className="row align-items-start">
                <div className="col-12 d-flex justify-content-between">
                  <button
                    type="reset"
                    onClick={closeSwipeableDrawer}
                    className="button-grey-outline py-2 px-4 me-auto">
                    Cancel
                  </button>
                  <LoadingButton
                    type="submit"
                    loading={editSizeChartSettingIsLoading}
                    disabled={editSizeChartSettingIsLoading}
                    className="button-gradient ms-auto py-2 px-4 w-auto">
                    <p>Save</p>
                  </LoadingButton>
                  {/* <button className="button-gradient py-2 px-4 me-auto">Save</button> */}
                </div>
              </div>
            </form>
          )}
        </Box>
      </SwipeableDrawer>
    </>
  );
};

export default ViewSizeChartSettings;
