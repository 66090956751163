import { useState, useReducer, useEffect, useMemo } from "react";
import moment from "moment";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Link, useParams, useNavigate, useSearchParams } from "react-router-dom";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useDispatch } from "react-redux";
import {
  Box,
  Tab,
  Tabs,
  Popover,
  Chip,
  Tooltip,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Dialog,
  DialogTitle,
  Divider,
  DialogContent,
  DialogActions,
  TablePagination,
  IconButton,
  Paper,
  CircularProgress,
} from "@mui/material";

import {
  useGetAllCustomersQuery,
  useEditCustomerMutation,
  useGetSingleCustomerQuery,
} from "../../../features/customers/customer/customerApiSlice";
import { showSuccess } from "../../../features/snackbar/snackbarAction";

import TabPanel from "../../../components/TabPanel/TabPanel";
import UserOrders from "./UserOrders/UserOrders";
import UserInformation from "./UserInformation/UserInformation";
import NotesBox from "../../../components/NotesBox/NotesBox";
// import TagsBox from "../../../components/TagsBox/TagsBox";
import { ArchiveModal2 } from "../../../components/ArchiveModal/ArchiveModal";
import AlertDialog from "../../../components/AlertDialog/AlertDialog";
import AsyncImage from "../../../components/AsyncImage/AsyncImage";

import "./UserDetails.scss";

import arrowLeft from "../../../assets/icons/arrowLeft.svg";
// import paginationRight from "../../../assets/icons/paginationRight.svg";
// import paginationLeft from "../../../assets/icons/paginationLeft.svg";
import email from "../../../assets/icons/email.svg";
import phone from "../../../assets/icons/phone.svg";
import message from "../../../assets/icons/message.svg";
import indiaFlag from "../../../assets/images/products/indiaFlag.svg";
// import block from "../../../assets/images/users/block.svg";
import verified from "../../../assets/icons/verified.svg";
// import copy from "../../../assets/icons/copy.svg";
import customerImage from "../../../assets/images/users/user_defauldp.svg";
import verifiedCustomer from "../../../assets/icons/customerVerify.svg";
import archiveCustomer from "../../../assets/icons/archive.svg";
import blockUser from "../../../assets/images/users/user-block.svg";
import unblock from "../../../assets/images/users/user-active.svg";
import TableLoader from "../../../components/Loader/TableLoader";
import { EnhancedTableHead } from "../../../components/TableDependencies/TableDependencies";
import { Close, EmailOutlined, Reply, VisibilityOutlined } from "@mui/icons-material";
import NoDataFound from "../../../components/NoDataFound/NoDataFound";
import {
  useGetEnquiryListQuery,
  useGetNewsletterListQuery,
} from "../../../features/user/customer/enquiries/enquiriesApiSlice";
import AddNotesDialog from "../../../components/AddNotesDialog/AddNotesDialog";
import ProductReviews from "../../Products/ProductReviews/ProductReviews";

// const Transition = forwardRef(function Transition(props, ref) {
//   return (
//     <Slide
//       direction="up"
//       ref={ref}
//       {...props}
//     />
//   );
// });

const initialQueryFilterState = {
  id: null,
  pageSize: 1,
  pageNo: null,
  totalCount: 0,
};

const queryFilterReducer = (state, action) => {
  if (action.type === "SET_PAGE_NO") {
    return {
      ...state,
      pageNo: +action.pageNo,
    };
  }
  if (action.type === "SET_TOTAL_COUNT") {
    return {
      ...state,
      totalCount: action.totalCount,
    };
  }
  if (action.type === "SET_CUSTOMER") {
    return {
      ...state,
      id: action?.id,
    };
  }
  return initialQueryFilterState;
};

const UserDetails = () => {
  let { id } = useParams();
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const [paramsData, setParamsData] = useState(null);
  const [openBlock, setOpenBlock] = useState(false);
  const [anchorContactEl, setContactEl] = useState(null);
  const [openArchivedModal, setArchivedModal] = useState(false);
  const [openCustomerNoteDialog, setOpenCustomerNoteDialog] = useState(false);
  const [copied, setCopied] = useState(false);
  const [queryFilterState, dispatchQueryFilter] = useReducer(
    queryFilterReducer,
    initialQueryFilterState
  );

  const [editCustomer] = useEditCustomerMutation();

  const { data: customerData } = useGetSingleCustomerQuery(queryFilterState.id, {
    skip: !queryFilterState.id,
  });

  const customerDetails = customerData?.data?.customer;
  const totalOrder = customerData?.data?.totalOrder ?? 0;
  const totalAmount = customerData?.data?.totalAmount ?? 0;
  const avgPrice = customerData?.data?.avgPrice ?? 0;

  useEffect(() => {
    const paramsString = id;
    const decodedString = decodeURIComponent(paramsString);
    const parsedObject = JSON.parse(decodedString);
    setParamsData(parsedObject);
  }, [id]);

  const customerId = paramsData?.id;

  useEffect(() => {
    if (customerId) {
      dispatchQueryFilter({ type: "SET_CUSTOMER", id: customerId });
    }
  }, [customerId]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleContactClick = (event) => {
    setContactEl(event.currentTarget);
  };

  const handleContactClose = () => {
    setContactEl(null);
  };

  const openContact = Boolean(anchorContactEl);
  const idContact = openContact ? "simple-popover" : undefined;

  const handleBlock = () => {
    setOpenBlock(true);
  };

  const handleBlockClose = () => {
    setOpenBlock(false);
  };

  const handleArchivedModalClose = () => {
    editCustomer({
      id: customerDetails?._id,
      details: {
        status: "archieved",
      },
    });
    setArchivedModal(false);
    navigate("/users/allUsers");
    dispatch(
      showSuccess({
        message:
          customerDetails?.firstName +
          " " +
          customerDetails?.lastName +
          " " +
          "archived successfully!",
      })
    );
  };

  const handleBlockCustomer = () => {
    const customerStatus = customerDetails?.status === "blocked" ? "active" : "blocked";
    editCustomer({
      id: customerDetails?._id,
      details: {
        status: customerStatus,
      },
    });
    setOpenBlock(false);
    dispatch(
      showSuccess({
        message:
          customerDetails?.firstName +
          " " +
          customerDetails?.lastName +
          " " +
          "blocked successfully!",
      })
    );
  };

  const handleMarkAsVerified = (value) => {
    const status = value === true ? "unverified" : "verified";
    editCustomer({
      id: customerDetails?._id,
      details: {
        verifiedUser: !value,
      },
    });
    setContactEl(false);
    dispatch(
      showSuccess({
        message:
          customerDetails?.firstName +
          " " +
          customerDetails?.lastName +
          " " +
          status +
          " successfully!",
      })
    );
  };

  const handleModalClose = () => {
    setArchivedModal(false);
  };

  const handleArchive = () => {
    setContactEl(false);
    setArchivedModal(true);
  };

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  const editHandler = (id) => {
    navigate(`/users/allUsers/edit/${id}`);
  };

  const timeFormat = "h:mm:ss A";

  const parsedDateTime = moment(customerDetails?.createdAt);
  const formattedTime = parsedDateTime.format(timeFormat);
  const {
    data: newsLetterData, //
    isLoading: newsLetterLoading,
  } = useGetNewsletterListQuery();

  const customerDefaultAddress =
    customerDetails?.addresses?.find((add) => add.isDefaultAddress) ??
    customerDetails?.addresses?.[0];

  return (
    <div className="page container-fluid position-relative">
      <div className="row justify-content-between">
        <div className="d-flex align-items-center w-auto ps-0">
          <Link
            to="/users/allUsers"
            className="d-flex textDecoration">
            <img
              src={arrowLeft}
              alt="arrowLeft"
              width={9}
              className="c-pointer"
            />
            <div>
              <h5 className="page-heading ms-2 ps-1">
                {customerDetails?.firstName} {customerDetails?.lastName}
              </h5>
              {customerDetails?.addresses[0] && (
                <div className="d-flex ms-2 ps-1 mt-1">
                  <small className="text-lightBlue me-2">
                    {customerDefaultAddress?.city?.name}, {customerDefaultAddress?.state?.name},{" "}
                    {customerDefaultAddress?.country?.name}
                  </small>
                  <img
                    alt="country"
                    src={customerDefaultAddress?.country?.imageUrl}
                    className="rounded-3"
                    width={20}
                  />
                </div>
              )}
            </div>
          </Link>
        </div>
        <div className="d-flex align-items-center w-auto pe-0">
          <button
            className="button-red-outline py-1 px-3"
            onClick={handleBlock}>
            <p>{customerDetails?.status === "blocked" ? "Unblock" : "Block"}</p>
          </button>

          <AlertDialog
            onConfirm={handleBlockCustomer}
            onCancel={handleBlockClose}
            show={openBlock}
            title={
              customerDetails?.status === "blocked" ? "Unblock Customer" : "Block Customer"
            }
            primaryMessage={`Are you sure you want to ${
              customerDetails?.status === "blocked" ? "Unblock" : "Block"
            } ${
              customerDetails?.firstName +
              " " +
              (customerDetails?.lastName ? customerDetails?.lastName : "")
            }`}
            confirmText={customerDetails?.status === "blocked" ? "Unblock" : "Block"}
            icon={customerDetails?.status === "blocked" ? unblock : blockUser}
          />

          <button
            className="button-lightBlue-outline py-1 ps-2 pe-3 ms-3"
            onClick={() => editHandler(customerDetails._id)}>
            <EditOutlinedIcon
              sx={{
                color: "#5c6d8e",
                fontSize: 14,
                cursor: "pointer",
                margin: "0 8px 0 8px",
              }}
            />
            <p>Edit</p>
          </button>
          <button
            className="button-gradient py-1 px-3 w-auto ms-3 me-3"
            onClick={handleContactClick}>
            <p className="me-2">Actions</p>
            <KeyboardArrowDownIcon />
          </button>

          <Popover
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            id={idContact}
            open={openContact}
            anchorEl={anchorContactEl}
            onClose={handleContactClose}>
            <div className="py-2 px-1">
              {!!customerDetails?.phone && (
                <a
                  href={`tel:${customerDetails?.phone}`}
                  className="d-flex align-items-center hover-black">
                  <img
                    src={phone}
                    alt="phome"
                    width={16}
                  />
                  <small className="text-lightBlue rounded-3 p-2 d-block">Call</small>
                </a>
              )}
              {!!customerDetails?.email && (
                <a
                  href={`mailto:${customerDetails?.email}`}
                  className="d-flex align-items-center hover-black">
                  <img
                    src={email}
                    alt="email"
                    width={16}
                  />
                  <small className="text-lightBlue rounded-3 p-2 d-block">Send Email</small>
                </a>
              )}
              <div
                className="d-flex align-items-center c-pointer"
                onClick={() => setOpenCustomerNoteDialog(true)}>
                <img
                  src={message}
                  alt="message"
                  width={16}
                />
                <small className="text-lightBlue rounded-3 p-2 hover-back d-block">
                  Message
                </small>
              </div>
              <div className="d-flex align-items-center c-pointer">
                <img
                  src={verifiedCustomer}
                  alt="message"
                  width={16}
                />
                <small
                  className="text-lightBlue rounded-3 p-2 hover-back d-block"
                  onClick={() => {
                    handleMarkAsVerified(customerDetails?.verifiedUser);
                  }}>
                  Mark as {customerDetails?.verifiedUser === true ? "Unverified" : "Verified"}
                </small>
              </div>
              <div
                className="d-flex align-items-center c-pointer"
                onClick={() => {
                  handleArchive();
                }}>
                <img
                  src={archiveCustomer}
                  alt="message"
                  width={16}
                />
                <small className="text-lightBlue rounded-3 p-2 hover-back d-block">
                  Archive Customer
                </small>
              </div>
            </div>
          </Popover>
          {/* <img
            src={paginationLeft}
            alt="paginationLeft"
            className="c-pointer"
            width={30}
          />
          <img
            src={paginationRight}
            alt="paginationRight"
            className="c-pointer"
            width={30}
          /> */}
        </div>
      </div>
      <div className="row">
        <div className="col-lg-9 mt-4">
          <div className="row flex-column mb-2">
            <Box
              sx={{ width: "100%" }}
              className="d-flex justify-content-between tabs-header-box mb-4">
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="scrollable force tabs example"
                className="tabs">
                <Tab
                  label="Information"
                  className="tabs-head"
                />
                <Tab
                  label="Orders"
                  className="tabs-head"
                />
                <Tab
                  label="KMR (10+1 Scheme)"
                  className="tabs-head"
                />
                <Tab
                  label="Enquiries"
                  className="tabs-head"
                />
                <Tab
                  label="Reviews"
                  className="tabs-head"
                />
                <Tab
                  label="Appointments"
                  className="tabs-head"
                />
              </Tabs>
            </Box>
            <TabPanel
              value={value}
              index={0}>
              <UserInformation
                addresses={customerDetails?.addresses}
                addressId={customerDetails?._id}
                customerId={customerId}
                totalOrder={totalOrder}
                totalAmount={totalAmount}
                avgPrice={avgPrice}
                openCustomerNote={() => setOpenCustomerNoteDialog(true)}
              />
            </TabPanel>
            <TabPanel
              value={value}
              index={1}>
              <UserOrders customerId={customerId} />
            </TabPanel>
            <TabPanel
              value={value}
              index={2}>
              <p className="text-lightBlue italic">Kisna Meri Roshini</p>
            </TabPanel>
           
            <TabPanel
              value={value}
              index={3}>
              <UserEnquiries customerId={customerDetails?._id} />
              {/* <p className="text-lightBlue">Enquiries</p> */}
            </TabPanel>
            <TabPanel
              value={value}
              index={4}>
                <ProductReviews customerId={customerDetails?._id} />
              
            </TabPanel>
            <TabPanel
              value={value}
              index={5}>
              <p className="text-lightBlue">Appointments booked by the user</p>
            </TabPanel>
          </div>
        </div>
        <div className="col-lg-3 mt-4 pe-0 ps-0 ps-lg-3">
          <div className="bg-black-15 border-grey-5 rounded-8 p-3">
            <AsyncImage
              src={customerDetails?.imageUrl}
              placeholder={customerImage}
              alt="ringSmall"
              className="rounded-circle object-fit-cover"
              height={100}
              width={100}
              style={{ objectFit: "cover" }}
            />
            <div className="d-flex w-100 mt-3">
              <div className="d-flex w-100 align-items-center">
                <h6 className="text-lightBlue me-2">
                  {customerDetails?.firstName} {customerDetails?.lastName}
                </h6>
                {customerDetails?.verifiedUser === true && (
                  <img
                    src={verified}
                    alt="verified"
                    width={15}
                  />
                )}
              </div>
              <img
                src={indiaFlag}
                alt="indiaFlag"
                width={18}
              />
            </div>
            <small className="text-grey-6 my-2 d-block">
              #{customerDetails?.srNo?.toString().padStart(6, "0")} •{" "}
              {customerDetails?.gender === "male" ? "Male" : "Female"}
            </small>
            <div className="d-flex align-items-baseline flex-wrap">
              <small
                className="rounded-pill fw-400 table-status px-2 py-1 me-2"
                style={{
                  background:
                    customerDetails?.status === "active"
                      ? "#A6FAAF"
                      : customerDetails?.status === "blocked"
                      ? "#892628"
                      : "#F67476",
                  color: customerDetails?.status === "active" ? "#202837" : "#fff",
                }}>
                {customerDetails?.status === "blocked"
                  ? "Blocked"
                  : customerDetails?.status === "active"
                  ? "Active"
                  : "In-active"}
              </small>
              <small className="text-grey-6 my-2 d-block">
                Last session&nbsp;
                <span className="text-lightBlue">
                  {!customerDetails?.lastLoggedIn
                    ? "NA"
                    : moment(customerDetails?.lastLoggedIn).format("DD MMM, YYYY")}
                </span>
              </small>
            </div>
            <small className="text-grey-6 mt-3 d-block">Registered Date</small>
            <p className="text-lightBlue mt-1">
              {moment(customerDetails?.createdAt).format("DD MMM, YYYY")}
              <span className="text-grey-6"> at {formattedTime}</span>
            </p>
            <div className="d-flex justify-content-center ">
              <hr className="hr-grey-6 w-100" />
            </div>
            <small className="text-grey-6 mt- d-block">E-mail ID</small>
            <div className="d-flex mt-1">
              <p className="text-lightBlue me-2">{customerDetails?.email}</p>
              <CopyToClipboard
                text={customerDetails?.email}
                onCopy={handleCopy}>
                <Tooltip
                  title={copied ? "Copied to clipboard" : "Copy"}
                  placement="top">
                  <ContentCopyIcon
                    sx={{
                      color: "#5c6d8e",
                      fontSize: 12,
                      cursor: "pointer",
                    }}
                  />
                </Tooltip>
              </CopyToClipboard>
            </div>
            <small className="text-grey-6 mt-3 d-block">Mobile Number</small>
            <div className="d-flex mt-1">
              <p className="text-lightBlue me-2">
                {customerDetails?.countryCode?.countryCode} {customerDetails?.phone}
              </p>
              <CopyToClipboard
                text={customerDetails?.countryCode?.countryCode + customerDetails?.phone}
                onCopy={handleCopy}>
                <Tooltip
                  title={copied ? "Copied to clipboard" : "Copy"}
                  placement="top">
                  <ContentCopyIcon
                    sx={{
                      color: "#5c6d8e",
                      fontSize: 12,
                      cursor: "pointer",
                    }}
                  />
                </Tooltip>
              </CopyToClipboard>
            </div>
            <small className="text-grey-6 mt-3 d-block">Date of Birth</small>
            <div className="d-flex mt-1">
              <p className="text-lightBlue me-2">
                {moment(customerDetails?.dob).format("DD MMM, YYYY")}
              </p>
            </div>
            {customerDetails?.groups?.length > 0 && (
              <small className="text-grey-6 mt-3 mb-1 d-block">User Group</small>
            )}
            {customerDetails?.groups?.map((item) => (
              <Chip
                key={item?.id}
                label={item?.name}
                size="small"
                className="px-1 mr-1"
              />
            ))}
            {<small className="text-grey-6 mt-3 mb-1 d-block">Email subscription</small>}
            {newsLetterLoading ? (
              <CircularProgress />
            ) : newsLetterData?.data?.data.findIndex(
                (item) => item?.email === customerDetails?.email
              ) !== -1 ? (
              <Chip
                label={"Subscribed"}
                size="small"
                className="px-1 mr-1"
              />
            ) : (
              <Chip
                label={"Not subscribed"}
                size="small"
                className="px-1 mr-1"
                variant="outlined"
              />
            )}
            {customerDetails?.storeCode && <>
              <small className="text-grey-6 mt-3 d-block">Store ID</small>
              <div className="d-flex mt-1">
                <p className="text-lightBlue me-2">{customerDetails?.storeCode}</p>
              </div>
            </>}
            {customerDetails?.storeDetails?.storeName && <>
              <small className="text-grey-6 mt-3 d-block">Store Name</small>
              <div className="d-flex mt-1">
                <p className="text-lightBlue me-2">{customerDetails?.storeDetails?.storeName} - {customerDetails?.storeDetails?.address?.pincode}</p>
              </div>
            </>}
            {customerDetails?.tags?.length > 0 && (
              <small className="text-grey-6 mt-3 mb-1 d-block">Tags</small>
            )}
            {customerDetails?.tags?.map((item) => (
              <Chip
                key={item}
                label={item}
                size="small"
                className="px-1 mr-1"
              />
            ))}
          </div>
          <NotesBox
            value={customerDetails?.notes}
            disable={true}
          />
          {/* <TagsBox /> */}
        </div>
      </div>
      <AddNotesDialog
        show={openCustomerNoteDialog}
        customerId={customerId}
        onClose={() => setOpenCustomerNoteDialog(false)}
      />
      <ArchiveModal2
        onConfirm={handleArchivedModalClose}
        onCancel={handleModalClose}
        show={openArchivedModal}
        title={"customer"}
        message={
          customerDetails?.firstName +
          " " +
          (customerDetails?.lastName ? customerDetails?.lastName : "")
        }
        archiveType={"customer"}
      />
    </div>
  );
};

const initialQueries = {
  search: "",
  createdAt: "-1",
  pageNo: 1,
  pageSize: 10,
  type: "product",
};

const queriesReducer = (state, action) => {
  switch (action.type) {
    case "SET_KEY_VAL":
      return {
        ...state,
        ...action.val,
      };

    case "SET_SEARCH":
      return {
        ...state,
        pageNo: 1,
        search: action.search,
      };

    case "SET_PAGE_SIZE":
      return {
        ...state,
        pageNo: 1,
        pageSize: +action.pageSize,
      };

    case "SET_TYPE":
      return {
        ...state,
        type: action.tabType,
      };
    case "SET_PAGE_NO":
      return {
        ...state,
        pageNo: action.pageNo + 1,
      };

    case "SET_CREATED_AT":
      return {
        ...state,
        createdAt: action.createdAt,
      };

    case "SET_DEFAULT":
      return initialQueries;

    default:
      return state;
  }
};

const headCells = [
  {
    id: "createdAt",
    numeric: false,
    disablePadding: false,
    label: "Created On",
  },
  {
    id: "product",
    numeric: false,
    disablePadding: false,
    label: "Product",
  },
  // {
  //   id: "customer",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Customer",
  // },
  {
    id: "subject",
    numeric: false,
    disablePadding: false,
    label: "Subject",
  },
  {
    id: "message",
    numeric: false,
    disablePadding: false,
    label: "Message",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: true,
    label: "Action",
  },
];

const UserEnquiries = ({ customerId }) => {
  const [queries, dispatchQueries] = useReducer(queriesReducer, initialQueries);
  // const [searchParams, setSearchParams] = useSearchParams();
  // const [firstTime, setFirstTime] = useState(true);

  // const queryString = useMemo(() => JSON.stringify(queries), [queries]);

  // useEffect(() => {
  //   if (firstTime) {
  //     setFirstTime(false);
  //     const filter = searchParams.get("filter");
  //     if (filter) {
  //       const filterObj = JSON.parse(filter);
  //       if (filterObj) {
  //         dispatchQueries({ type: "SET_KEY_VAL", val: filterObj });
  //       }
  //     }
  //   } else {
  //     setSearchParams({ filter: queryString });
  //   }
  // }, [queryString, searchParams, setSearchParams, firstTime]);

  const [dialogueId, setDialogueId] = useState("");

  const {
    data: enquiriesData, //
    isLoading: enquiriesIsLoading,
  } = useGetEnquiryListQuery(
    {
      ...{ ...queries, customer: customerId },
    },
    { skip: queries?.type === "newsletter" }
  );

  const allReviews = useMemo(() => {
    return enquiriesData?.data?.data.filter((val) => val?.type !== "walkIn");
  }, [enquiriesData]);
  

  const allWalkIns = useMemo(() => {
    return enquiriesData?.data?.data.filter((val) => val?.type == "walkIn");
  }, [enquiriesData]);
  

  const reviewsCount = useMemo(
    () => enquiriesData?.data?.totalCount ?? 0,
    [enquiriesData?.data?.totalCount]
  );
  const allWalkInsCount = useMemo(
    () => enquiriesData?.data?.totalCount ?? 0,
    [enquiriesData?.data?.totalCount]
  );

  const handleChange = (event, newValue) => {
    // setValue(newValue);
    dispatchQueries({ type: "SET_TYPE", tabType: newValue });
  };
  return (
    <div className="row">
      <Paper
        sx={{ width: "100%", mb: 2, mt: 0, p: 0 }}
        className="border-grey-5 bg-black-15">
        <Box
          sx={{ width: "100%" }}
          className="d-flex justify-content-between tabs-header-box">
          <Tabs
            value={queries.type}
            onChange={handleChange}
            aria-label="scrollable force tabs example"
            className="tabs">
            <Tab
              label="Product"
              className="tabs-head"
              value={"product"}
            />
            <Tab
              label="Help & FAQ"
              className="tabs-head"
              value={"help-faq"}
            />
            <Tab
              label="Contact"
              className="tabs-head"
              value={"contact"}
            />
            <Tab
              label="Walk In"
              className="tabs-head"
              value={"walkin"}
            />
            {/* <Tab label="Newsletter" className="tabs-head" value={"newsletter"} /> */}
          </Tabs>
        </Box>
        {enquiriesIsLoading ? (
          <span className="d-flex justify-content-center m-3">
            <TableLoader />
          </span>
        ) : allReviews?.length > 0 ? (
          <>
            <TableContainer>
              <Table size="medium">
                <EnhancedTableHead
                  isCheckBox={false}
                  onSelectAllClick={(e) => {}}
                  numSelected={0}
                  rowCount={allReviews?.length}
                  headCells={headCells?.filter((e) =>
                    queries.type !== "product" ? e.label !== "Product" : true
                  )}
                  orderBy={"groupName"}
                  onRequestSort={Date}
                  order={"asc"}
                />
                <TableBody>
                  {[...allReviews].map((row) => {
                    const {
                      _id: rid = "",
                      enqId,
                      message: description = "",
                      product,
                      email = "",
                      phone = "",
                      firstName: customerFrotnendName = "",
                      subject = "",
                      countryCode = {},
                      createdAt = new Date().toISOString(),
                    } = row ?? {};
                    // const isItemSelected = selected.includes(row);
                    const today = moment();
                    const diffInDays = today.diff(moment(new Date(createdAt)), "days");
                    const formatedDate =
                      diffInDays === 0
                        ? moment(new Date(createdAt)).format("[Today], [at] hh:mm A")
                        : diffInDays === 1
                        ? moment(new Date(createdAt)).format("[Yesterday], [at] hh:mm A")
                        : moment(new Date(createdAt)).format("MMMM D, YYYY, [at] hh:mm A");
                    const formatedDay = moment(new Date(createdAt)).format("DD-MM-yyyy");
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        // aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={rid}
                        // selected={isItemSelected}
                        className="table-rows">
                        <TableCell>
                          <p className="text-blue-2 fw-600">{enqId ? `#${enqId}` : ""}</p>
                          <p className="text-lightBlue rounded-circle fw-500 mt-2 fw-bold">
                            {formatedDay}
                            {" at "}
                            {moment(createdAt).format("hh:mm A")}
                          </p>
                        </TableCell>
                        {queries.type === "product" ? (
                          <TableCell
                            component="th"
                            scope="row"
                            padding="none">
                            {product ? (
                              <Link
                                to={`/products/allProducts/editProduct/${product?._id}`}
                                className="text-decoration-none">
                                <div className="d-flex align-items-center c-pointer py-3">
                                  <AsyncImage
                                    src={
                                      product?.mediaUrl?.find((mu) => mu.isDefault)?.image ?? ""
                                    }
                                    placeholder={""}
                                    alt="product"
                                    className="me-2 rounded-4"
                                    height={45}
                                    width={45}
                                    style={{ objectFit: "cover" }}
                                  />
                                  <div>
                                    <p className="text-lightBlue fw-600">{product?.title}</p>
                                    <small className="mt-2 text-grey-6">
                                      SKU: {product?.inventory?.skuId}
                                    </small>
                                  </div>
                                </div>
                              </Link>
                            ) : (
                              "-"
                            )}
                          </TableCell>
                        ) : null}
                        {/* <TableCell style={{ width: 200 }}>
                        <div className="d-flex flex-column">
                          <p className="text-lightBlue fw-500">
                            {customerFrotnendName}
                          </p>

                          <div className="d-flex align-items-center mt-2">
                            <small className="text-grey-6 me-2">
                              {email || "email@gmail.com"}
                            </small>
                          </div>

                          <div className="d-flex align-items-center mt-2">
                            <small className="text-grey-6 me-2">
                              {` ${countryCode?.countryCode || "+91"}-`}
                              {phone || "8780808080"}
                            </small>
                          </div>
                        </div>
                      </TableCell> */}
                        <TableCell>
                          <p className="text-lightBlue">{subject?.subject || "-"}</p>
                        </TableCell>
                        <TableCell>
                          <p className="text-lightBlue">{description}</p>
                        </TableCell>
                        <TableCell style={{ width: 160, padding: 0 }}>
                          <div className="d-flex align-items-center">
                            <Tooltip
                              title="Details"
                              placement="top">
                              <div
                                className="table-edit-icon c-pointer rounded-4 p-2"
                                onClick={() => setDialogueId(rid)}>
                                <VisibilityOutlined
                                  sx={{
                                    fontSize: 18,
                                    color: "#5c6d8e",
                                  }}
                                />
                              </div>
                            </Tooltip>
                            <Tooltip
                              title="Send Email"
                              placement="top">
                              <div className="table-edit-icon c-pointer rounded-4 p-2">
                                <EmailOutlined
                                  sx={{
                                    fontSize: 18,
                                    color: "#5c6d8e",
                                  }}
                                />
                              </div>
                            </Tooltip>
                            <Tooltip
                              title="Reply"
                              placement="top">
                              <div className="table-edit-icon c-pointer rounded-4 p-2">
                                <Reply
                                  sx={{
                                    fontSize: 18,
                                    color: "#5c6d8e",
                                  }}
                                />
                              </div>
                            </Tooltip>
                            <Dialog
                              open={rid === dialogueId}
                              fullWidth>
                              <DialogTitle>
                                <h5 className="d-flex align-items-center">
                                  <span className="text-lightBlue fw-500">
                                    Enquiry ID: #{enqId}
                                    <span
                                      className="text-grey-6"
                                      style={{
                                        marginTop: "0.3rem",
                                        display: "block",
                                        fontSize: "0.8rem",
                                        fontWeight: "400",
                                      }}>
                                      {formatedDate}
                                    </span>
                                  </span>
                                  <IconButton
                                    onClick={() => setDialogueId("")}
                                    style={{
                                      marginLeft: "auto",
                                    }}>
                                    <Close />
                                  </IconButton>
                                </h5>
                              </DialogTitle>
                              <Divider />
                              <DialogContent>
                                <p className="text-grey-6 mb-1">Subject</p>
                                <p className="text-lightBlue mt-1">{subject?.subject}</p>
                                <p className="text-grey-6 mt-2 mb-1">Message</p>
                                <p className="text-lightBlue mt-1">{description}</p>
                                {product ? (
                                  <>
                                    <p className="text-grey-6 mt-2 mb-1">Product</p>
                                    <p className="text-lightBlue mt-1">
                                      <Link
                                        to={`/products/allProducts/editProduct/${
                                          product?._id || ""
                                        }`}>
                                        {product?.title}
                                      </Link>
                                    </p>
                                  </>
                                ) : null}
                                <div
                                  style={{ width: "100%", gap: "3rem" }}
                                  className="d-flex item-center">
                                  <div>
                                    <p className="text-grey-6 mt-2 mb-1">Customer Name</p>
                                    <p className="text-lightBlue mt-1">
                                      {customerFrotnendName}
                                    </p>
                                  </div>
                                  <div>
                                    <p className="text-grey-6 mt-2 mb-1">Mobile No.</p>
                                    <p className="text-lightBlue mt-1">
                                      {countryCode?.countryCode || "+91"}-{phone}
                                    </p>
                                  </div>
                                  <div>
                                    <p className="text-grey-6 mt-2 mb-1">Email ID</p>
                                    <p className="text-lightBlue mt-1">{email}</p>
                                  </div>
                                </div>
                              </DialogContent>
                              <Divider />
                              <DialogActions>
                                <button
                                  style={{
                                    marginRight: "auto",
                                    marginLeft: "1rem",
                                    marginBlock: "0.5rem",
                                  }}
                                  className="button-grey-outline py-2 px-4"
                                  // onClick={}
                                >
                                  <Reply
                                    sx={{
                                      fontSize: 18,
                                      cursor: "pointer",
                                      "& :hover": { color: "green" },
                                    }}
                                  />
                                  <p className="ms-2">Reply</p>
                                </button>
                                <button
                                  className="button-lightBlue-outline py-2 px-4"
                                  style={{
                                    marginRight: "1rem",
                                  }}>
                                  <EmailOutlined
                                    sx={{
                                      fontSize: 18,
                                      cursor: "pointer",
                                      "& :hover": { color: "green" },
                                    }}
                                  />
                                  <p className="ms-2">Send Email</p>
                                </button>
                              </DialogActions>
                            </Dialog>
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 20, 30]}
              component="div"
              count={reviewsCount}
              rowsPerPage={queries.pageSize}
              page={queries.pageNo - 1}
              onPageChange={(_, page) => dispatchQueries({ type: "SET_PAGE_NO", pageNo: page })}
              onRowsPerPageChange={(e) =>
                dispatchQueries({
                  type: "SET_PAGE_SIZE",
                  pageSize: e.target.value,
                })
              }
              className="table-pagination"
            />
          </>
        ) : (
          // <span className="d-flex justify-content-center m-3">
          //   <NoDataFound />
          // </span>
          null
        )}
        {allWalkIns?.length > 0 ? (
  <>
      <Table size="medium">
        <EnhancedTableHead
          isCheckBox={false}
          onSelectAllClick={() => {}}
          numSelected={0}
          rowCount={allWalkIns?.length}
          headCells={[
            { id: "enqId", label: "Enquiry ID" },
            { id: "firstName", label: "Customer Name" },
            { id: "message", label: "Message" },
            { id: "note", label: "Note" },
            { id: "phone", label: "Phone" },
            { id: "storeCode", label: "Store Code" },
          ]}
          orderBy={"enqId"}
          onRequestSort={() => {}}
          order={"asc"}
        />
        <TableBody>
          {allWalkIns.map((row) => {
            const {
              _id: rid = "",
              enqId = "",
              firstName = `${row?.customerData?.firstName || ""} ${row?.customerData?.lastName || ""}`.trim(),
              message = "",
              note = "",
              phone = "",
              storeCode = "",
              createdAt = new Date().toISOString(),
            } = row;
            

            const today = moment();
            const diffInDays = today.diff(moment(createdAt), "days");
            const formattedDate =
              diffInDays === 0
                ? moment(createdAt).format("[Today], [at] hh:mm A")
                : diffInDays === 1
                ? moment(createdAt).format("[Yesterday], [at] hh:mm A")
                : moment(createdAt).format("MMMM D, YYYY, [at] hh:mm A");

            return (
              <TableRow
                hover
                role="checkbox"
                tabIndex={-1}
                key={rid}
                className="table-rows"
              >
                <TableCell>
                  <p className="text-blue-2 fw-600">{`#${enqId}`}</p>
                  <p className="text-lightBlue rounded-circle fw-500 mt-2">
                    {formattedDate}
                  </p>
                </TableCell>
                <TableCell>
                  <p className="text-lightBlue">{firstName}</p>
                </TableCell>
                <TableCell>
                  <p className="text-lightBlue">{message}</p>
                </TableCell>
                <TableCell>
                  <p className="text-lightBlue">{note || "-"}</p>
                </TableCell>
                <TableCell>
                  <p className="text-lightBlue">{phone || "N/A"}</p>
                </TableCell>
                <TableCell>
                  <p className="text-lightBlue">{storeCode || "N/A"}</p>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    <TablePagination
      rowsPerPageOptions={[10, 20, 30]}
      component="div"
      count={allWalkInsCount}
      rowsPerPage={queries.pageSize}
      page={queries.pageNo - 1}
      onPageChange={(_, page) => dispatchQueries({ type: "SET_PAGE_NO", pageNo: page })}
      onRowsPerPageChange={(e) =>
        dispatchQueries({
          type: "SET_PAGE_SIZE",
          pageSize: e.target.value,
        })
      }
      className="table-pagination"
    />
  </>
) : (
  <span className="d-flex justify-content-center m-3">
    <NoDataFound />
  </span>
)}

      </Paper>
    </div>
  );
};

export default UserDetails;
