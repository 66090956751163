import { forwardRef } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, Slide } from "@mui/material";
import cancel from "../../assets/icons/cancel.svg";

const Transition = forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="up"
      ref={ref}
      {...props}
    />
  );
});

const PopUpForm = ({ children, onConfirm, onClose = () => {}, show, heading }) => {
  return (
    <Dialog
      open={show}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="sm"
      fullWidth={true}>
      <DialogTitle>
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex flex-column ">
            <h5 className="text-lightBlue fw-500">{heading}</h5>
            <small className="text-grey-6 mt-1 d-block">
              ⓘ Lorem ipsum dolor sit amet consectetur.
            </small>
          </div>
          <img
            src={cancel}
            alt="cancel"
            width={30}
            onClick={onClose}
            className="c-pointer"
          />
        </div>
      </DialogTitle>
      <hr className="hr-grey-6 my-0" />
      <DialogContent className="mx-2">{children}</DialogContent>
      <hr className="hr-grey-6 my-0" />
      <DialogActions className="d-flex justify-content-between px-4 py-3">
        <button
          className="button-grey-outline py-2 px-5"
          onClick={onClose}>
          <p className="text-lightBlue">Cancel</p>
        </button>
        <button
          className="button-gradient py-2 px-5"
          onClick={onConfirm}>
          <p>Save</p>
        </button>
      </DialogActions>
    </Dialog>
  );
};

export default PopUpForm;
