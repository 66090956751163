import { useNavigate } from "react-router-dom";
import {
    Autocomplete,
    TextField,
} from "@mui/material";
import OnBoard from "./OnBoard";
import industry from "../../../assets/images/industry.svg";
import "./OnBoard.scss";

const data = [
    { label: 'Services' }
];

export default function StepTwo() {
    const navigate = useNavigate();

    const handleContinue = () => {
        navigate("/auth/stepThree");
    }

    return (
        <OnBoard image={industry} buttonName={"Continue"} onClick={handleContinue}>
            <p className="d-flex text-grey-6 mb-5">
                <p className="text-lightBlue">02&nbsp;</p>
                /&nbsp;04
            </p>
            <h2 className="text-blue-gradient fw-600 mb-2">Select Industry</h2>
            <small className="d-flex text-grey-6 w-75 mb-4">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lacus nulla eget adipiscing faucibus platea. Eu ultrices ipsum
            </small>
            <div className="onBoardDrop">
                <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    size="small"
                    options={data ?? []}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            placeholder={"Select Industry"}
                        />
                    )}
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            <small className="text-lightBlue">{option?.label}</small>
                        </li>
                    )}
                />
            </div>
        </OnBoard>
    );
}