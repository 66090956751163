import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import developmentIcon from "../../assets/images/developmentIcon.svg";

const UnderDevelopment = () => {
  const [time, setTime] = useState(3);
  const navigate = useNavigate();

  useEffect(() => {
    const goBackTimer = setInterval(() => {
      setTime((prevState) => (prevState !== 0 ? prevState - 1 : prevState));
    }, 2000);

    return () => {
      clearInterval(goBackTimer);
    };
  }, []);

  useEffect(() => {
    if (time === 0) {
      navigate(-1);
    }
  }, [time, navigate]);

  return (
    <div
      style={{
        display: "grid",
        placeContent: "center",
        height: "100%",
        gap: "2rem",
      }}
    >
      <img
        src={developmentIcon}
        alt="development"
        style={{ justifySelf: "center", width: "15rem" }}
      />
      <h1
        style={{ justifySelf: "center", fontWeight: "600", color: "#C8D8FF" }}
      >
        Coming Soon
      </h1>
      <p
        style={{
          fontSize: "1.1rem",
          width: "70%",
          justifySelf: "center",
          textAlign: "center",
          color: "#8F97AC",
          marginTop: "-0.5rem",
        }}
      >
        We're actively working on this powerful new feature to enhance your
        experience. Keep an eye out for forthcoming updates!
      </p>
      <p
        style={{
          fontSize: "1.1rem",
          justifySelf: "center",
          textAlign: "center",
          color: "#8F97AC",
          marginBottom: "1rem",
        }}
      >
        {`Taking you back where you left off in ${time} sec.`}
      </p>
      <button
        onClick={() => navigate(-1)}
        className="button-gradient py-2 px-4 ms-3 c-pointer"
        style={{
          justifySelf: "center",
        }}
      >
        <p>Go Back</p>
      </button>
    </div>
  );
};

export default UnderDevelopment;
