import React from "react";
import AlertDialog from "../../components/AlertDialog/AlertDialog";
import archiveIcon from "../../assets/images/Components/Archived.png";

export default function OrderArchiveModal({
  show = false,
  onConfirm = () => {},
  onCancel = () => {},
  title = "",
  message = "",
  isLoading = false,
}) {
  return (
    <AlertDialog
      onConfirm={onConfirm}
      onCancel={onCancel}
      show={show}
      title={title}
      primaryMessage={message}
      confirmText="Archive"
      isLoading={isLoading}
      icon={archiveIcon}
    />
  );
}
