import apiSlice from "../../../app/api/apiSlice";
import { omitEmptyKeys, omitNullishKeys } from "../../../utils/helper";

export const variantApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllVariants: builder.query({
      query: (queries = {}) => ({
        url: "/products/variant/list",
        params: omitEmptyKeys(queries),
      }),
      providesTags: ["Variants"],
    }),

    getAllVariantsCount: builder.query({
      query: (queries = {}) => {
        const queryString = new URLSearchParams(queries);
        return {
          url: `/products/variant/count?${queryString}`,
        };
      },
      providesTags: ["Variants"],
    }),

    getVariantsOnCall: builder.mutation({
      query: (queries = {}) => {
        return {
          url: "/products/variant/list",
          params: omitNullishKeys(queries),
          method: "GET",
        };
      },
    }),

    getVariantBulkData: builder.query({
      query: ({ id, queries }) => ({
        url: `/products/variant/editor/${id}`,
        params: omitEmptyKeys(queries),
      }),
      transformResponse: (res) => res.data,
      providesTags: ["Variants"],
    }),

    updateVariantBulkData: builder.mutation({
      query: (data) => ({
        url: "/products/variant/bulkUpdateEditor",
        method: "PUT",
        body: data,
      }),
    }),

    createVariant: builder.mutation({
      query: (data) => ({
        url: "/products/variant",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Products", "Variants", "Logs"],
    }),

    createWithSetVariant: builder.mutation({
      query: (data) => ({
        url: "/products/variant/create",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Products", "SingleProduct", "Variants", "Logs"],
    }),

    createWithPriceMaster: builder.mutation({
      query: (data) => ({
        url: "/products/variant/globalPrice",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Products", "Variants", "Logs"],
    }),

    editVariant: builder.mutation({
      query: ({ id, details }) => ({
        url: `/products/variant/${id}`,
        method: "PUT",
        body: details,
      }),
      invalidatesTags: ["Products", "Variants", "Logs"],
    }),

    bulkEditVariant: builder.mutation({
      query: (updates) => ({
        url: `/products/variant/bulkUpdate`,
        method: "PUT",
        body: updates,
      }),
      invalidatesTags: ["Products", "Variants", "Logs"],
    }),

    bulkDeleteVariantByProduct: builder.mutation({
      query: (pid) => ({
        url: `products/variant/deleteByProduct/${pid}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Products", "Variants", "Logs"],
    }),
  }),
});

export const {
  useGetAllVariantsQuery,
  useGetAllVariantsCountQuery,
  useGetVariantsOnCallMutation,
  useGetVariantBulkDataQuery,
  useUpdateVariantBulkDataMutation,
  useCreateVariantMutation,
  useCreateWithSetVariantMutation,
  useCreateWithPriceMasterMutation,
  useEditVariantMutation,
  useBulkEditVariantMutation,
  useBulkDeleteVariantByProductMutation,
} = variantApiSlice;
