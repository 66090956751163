import { useReducer, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import * as Yup from "yup";
import _ from "lodash";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  FormControl,
  FormHelperText,
  IconButton,
  MenuItem,
  Select,
  InputAdornment,
  OutlinedInput,
  Checkbox,
  FormControlLabel,
  TextField,
  Autocomplete,
} from "@mui/material";

import { omitEmptyKeys } from "../../../utils/helper";
import {
  useCreateCustomerMutation,
  useGetAllCustomersQuery,
  useEditCustomerMutation,
} from "../../../features/customers/customer/customerApiSlice";
import { useGetAllTagsQuery } from "../../../features/parameters/tagsManager/tagsManagerApiSlice";
import { useGetAllCustomerGroupQuery } from "../../../features/customers/customerGroup/customerGroupApiSlice";
import { showSuccess, showError } from "../../../features/snackbar/snackbarAction";
import {
  useDeleteCustomerAddressMutation,
  useGetCustomerAddressQuery,
} from "../../../features/customers/customerAddress/customerAddressApiSlice";
import { useGetAllCountryQuery } from "../../../features/master/country/countryApiSlice";

import AppMobileCodeSelect from "../../../components/AppMobileCodeSelect/AppMobileCodeSelect";
import UploadMediaBox from "../../../components/UploadMediaBox/UploadMediaBox";
import NotesBox from "../../../components/NotesBox/NotesBox";
import TagsBox from "../../../components/TagsBox/TagsBox";
import { SaveFooterTertiary } from "../../../components/SaveFooter/SaveFooter";
import AddAddress from "./AddAddress";
import { DiscardModalSecondary } from "../../../components/Discard/DiscardModal";
import CustomerChip from "./CustomerChip";

import arrowLeft from "../../../assets/icons/arrowLeft.svg";
import addMedia from "../../../assets/icons/addMedia.svg";
import cancel from "../../../assets/icons/cancel.svg";

import "./AddUser.scss";

const initialQueryFilterState = {
  pageSize: 1,
  pageNo: null,
  totalCount: 0,
};

const initialCustomerState = {
  deleteIndex: null,
  confirmationMessage: "",
  showDeleteModal: false,
  isEditing: false,
};

const queryFilterReducer = (state, action) => {
  if (action.type === "SET_PAGE_NO") {
    return {
      ...state,
      pageNo: +action.pageNo,
    };
  }
  if (action.type === "SET_TOTAL_COUNT") {
    return {
      ...state,
      totalCount: action.totalCount,
    };
  }
  return initialQueryFilterState;
};

const customerTabReducer = (state, action) => {
  if (action.type === "SET_DELETE") {
    return {
      ...state,
      deleteIndex: action.deleteIndex,
      confirmationMessage: action.message || "",
      showDeleteModal: true,
    };
  }
  if (action.type === "REMOVE_DELETE") {
    return {
      ...initialCustomerState,
    };
  }
  if (action.type === "ENABLE_EDIT") {
    return {
      ...initialCustomerState,
      isEditing: true,
    };
  }
  if (action.type === "DISABLE_EDIT") {
    return {
      ...initialCustomerState,
      isEditing: false,
    };
  }

  return initialCustomerState;
};

export const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const AddUser = () => {
  let { id } = useParams();
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPass, setShowPass] = useState(false);
  const [editAddr, setEdit] = useState(false);
  // const [confirmPass, setConfirmPass] = useState("");
  const [queryFilterState, dispatchQueryFilter] = useReducer(
    queryFilterReducer,
    initialQueryFilterState
  );
  const [customerState, dispatchCustomer] = useReducer(
    customerTabReducer,
    initialCustomerState
  );

  const {
    data: customerData,
    isLoading: customerIsLoading,
    error: customerIsError,
    isSuccess: customerIsSuccess,
  } = useGetAllCustomersQuery({ id: id }, { skip: id ? false : true });

  const [
    editCustomer,
    {
      data: editData,
      isLoading: editCustomerIsLoading,
      isSuccess: editCustomerIsSuccess,
      error: editCustomerError,
    },
  ] = useEditCustomerMutation();

  const {
    data: customerAddressData,
    isLoading: customerAddressIsLoading,
    isSuccess: customerAddressIsSuccess,
    error: customerAddressError,
  } = useGetCustomerAddressQuery({ customer: id }, { skip: !id });

  // const {
  //   data: tagsData,
  //   isLoading: tagsIsLoading,
  //   isSuccess: tagsIsSuccess,
  //   error: tagsError,
  // } = useGetAllTagsQuery({ createdAt: -1 });

  const {
    data: customerGroupData,
    isLoading: customerGroupIsLoading,
    isSuccess: customerGroupIsSuccess,
    error: customerGroupError,
  } = useGetAllCustomerGroupQuery();

  const [
    createCustomer,
    {
      isLoading: createCustomerIsLoading,
      isSuccess: createCustomerIsSuccess,
      error: createCustomerError,
    },
  ] = useCreateCustomerMutation();

  const [
    deleteCustomerAddress,
    {
      isLoading: deleteAddressIsLoading,
      isSuccess: deleteAddressIsSuccess,
      error: deleteAddressError,
    },
  ] = useDeleteCustomerAddressMutation();

  const handleDOB = (event, value) => {
    customerFormik.setFieldValue("dob", event.format("YYYY-MM-DD"));
  };

  const backHandler = () => {
    navigate("/users/allUsers");
  };

  const currentDate = new Date();
  const maxDate = new Date(
    currentDate.getFullYear() - 18,
    currentDate.getMonth(),
    currentDate.getDate()
  );

  const customerValidationSchema = Yup.object({
    firstName: Yup.string()
      .trim()
      .min(3, "First name must be at least 3 characters")
      .required("Required"),
    lastName: Yup.string().trim().min(3, "Last name must be at least 3 characters"),
    dob: Yup.date(),
    gender: Yup.string().oneOf(["male", "female", "others"]).required("required"),
    email: Yup.string()
      .email("Please enter a valid email")
      .matches(emailRegex, "Please enter a valid email")
      .required("Required"),
    countryCode: Yup.string().required("Required"),
    phone: Yup.string()
      .matches(/^\d{10}$/, "Mobile Number must be 10 digits")
      .required("Required"),
    userGroup: Yup.array().of(Yup.string()),
    address: Yup.array().of(
      Yup.object().shape({
        pinCode: Yup.string()
          .matches(/^\d{6}$/, "Pin code must be 6 digits")
          .required("Required"),
      })
    )
  });

  const customerFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: customerData?.data?.data[0]?.firstName || "",
      lastName: customerData?.data?.data[0]?.lastName || "",
      dob: customerData?.data?.data[0]?.dob || "",
      gender: customerData?.data?.data[0]?.gender || "",
      countryCode: customerData?.data?.data[0]?.countryCode?._id || "",
      phone: customerData?.data?.data[0]?.phone || "",
      email: customerData?.data?.data[0]?.email || "",
      isSendEmail: customerData?.data?.data[0]?.isSendEmail || false,
      isSendMessage: customerData?.data?.data[0]?.isSendMessage || false,
      isTemporaryPassword: customerData?.data?.data[0]?.isTemporaryPassword || false,
      notes: customerData?.data?.data[0]?.notes || "",
      imageUrl: customerData?.data?.data[0]?.imageUrl || "",
      userGroup: customerData?.data?.data[0]?.userGroup || [],
      tags: customerData?.data?.data[0]?.tags || "",
      address: []
    },
    validationSchema: customerValidationSchema,
    onSubmit: (values) => {
      console.log(values, 'customerFormik values');
      
      if (!id) {
        const masterValues = omitEmptyKeys({
          ...values,
          address: values.address.map((add) => omitEmptyKeys(add)),
        });
        createCustomer(masterValues)
          .unwrap()
          .then((res) => {
            navigate("/users/allUsers");
            dispatch(
              showSuccess({
                message: `${
                  customerFormik.values.firstName + " " + customerFormik.values.lastName
                }
              created successfully`,
              })
            );
          })
          .catch((e) => {
            console.log(e, 'customerFormik e');
            dispatch(
              showError({
                message: e?.data?.message ?? e?.message ?? "Something went wrong!",
              })
            );
          });
      } else {
        const masterValues = omitEmptyKeys(
          { ...values, address: values.address.map((add) => omitEmptyKeys(add)) },
          ["imageUrl"]
        );
        editCustomer({
          id: id,
          details: masterValues,
        })
          .unwrap()
          .then(() => {
            navigate("/users/allUsers");
            dispatch(
              showSuccess({
                message: `${
                  customerFormik.values.firstName + " " + customerFormik.values.lastName
                } 
              edited successfully`,
              })
            );
          })
          .catch((e) => {
            console.log(e, 'customerFormik e');
            dispatch(
              showError({
                message: e?.data?.message ?? e?.message ?? "Something went wrong!",
              })
            );
          });
      }
    },
  });

  useEffect(
    () => {
      // console.log(customerAddressIsSuccess, customerAddressData);
      if (customerAddressIsSuccess) {
        const address =
          customerAddressData?.data?.data.map((add) => {
            const {
              country,
              city = "",
              name = "",
              firstName = "",
              lastName = "",
              companyName = "",
              countryCode = "",
              phone = "",
              line1 = "",
              line2 = "",
              pinCode = "",
              state = "",
              isDefaultAddress = false,
              _id = "",
              type = "shipping",
            } = add;
            return {
              country: country._id,
              city,
              name,
              firstName,
              lastName,
              companyName,
              countryCode,
              phone,
              line1,
              line2,
              pinCode,
              state,
              isDefaultAddress,
              id: _id,
              type,
            };
          }) ?? [];
        customerFormik.setFieldValue("address", address);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [customerAddressData, customerAddressIsSuccess]
  );

  useEffect(() => {
    if (createCustomerError) {
      if (createCustomerError?.data?.message) {
        dispatch(showError({ message: createCustomerError.data.message }));
      } else {
        dispatch(
          showError({
            message: "Oops! Failed to create customer. Please try again.",
          })
        );
      }
    }
  }, [createCustomerError, dispatch]);

  useEffect(() => {
    if (editCustomerError) {
      if (editCustomerError?.data?.message) {
        dispatch(showError({ message: editCustomerError.data.message }));
      } else {
        dispatch(
          showError({
            message: "Oops! Failed to create customer. Please try again.",
          })
        );
      }
    }
  }, [editCustomerError, dispatch]);

  const updateAddresses = (newAddresses) => {
    const updatedAddress =
      editAddr == false
        ? customerFormik.values.address.map((existingAddr) => {
            const matchingNewAddr = newAddresses.find(
              (newAddr) => newAddr.id === existingAddr.id
            );
            return matchingNewAddr || existingAddr;
          })
        : [...customerFormik.values.address, ...newAddresses];
    customerFormik.setFieldValue("address", updatedAddress);
  };

  const deleteAddress = (addressIndex, addressId) => {
    if (id) {
      deleteCustomerAddress(addressId);
    } else {
      const updatedAddresses = customerFormik.values.address.filter(
        (_, index) => index !== addressIndex
      );
      customerFormik.setFieldValue("address", updatedAddresses);
    }
  };

  useEffect(() => {
    if (id) {
      dispatchQueryFilter({ type: "SET_PAGE_NO", pageNo: id });
    }
  }, [id]);

  useEffect(() => {
    if (id && !_.isEqual(customerFormik.values, customerFormik.initialValues)) {
      dispatchCustomer({ type: "ENABLE_EDIT" });
    } else if (id && _.isEqual(customerFormik.values, customerFormik.initialValues)) {
      dispatchCustomer({ type: "DISABLE_EDIT" });
    }
  }, [customerFormik.initialValues, customerFormik.values, id]);

  const removeGroup = (groupIdToRemove) => {
    const updatedGroups = customerFormik.values.userGroup.filter(
      (groupId) => groupId !== groupIdToRemove
    );
    customerFormik.setFieldValue("userGroup", updatedGroups);
  };

  const selectedGroups = customerGroupData?.data?.data?.filter((group) =>
    customerFormik.values.userGroup.includes(group._id)
  );

  const handleGroupChange = (_, group) => {
    const groupIds = group?.map((group) => group?._id);
    customerFormik.setFieldValue("userGroup", groupIds);
  };

  const uploadMediaHandler = (url) => {
    customerFormik.setFieldValue("imageUrl", url);
  };

  useEffect(() => {
    if (customerFormik.isSubmitting && Object.keys(customerFormik.errors).length > 0) {
      dispatch(showError({ message: "Please fill required fields" }));
    }
  }, [customerFormik.isSubmitting, customerFormik.errors, dispatch]);

  console.log(customerFormik, 'customerFormik');
  
  return (
    <>
      <form
        noValidate
        onSubmit={customerFormik.handleSubmit}
      >
        <div className="page container-fluid position-relative user-group">
          <div className="row justify-content-between">
            <div className="d-flex align-items-center w-auto ps-0">
              <Link
                to="/users/allUsers"
                className="d-flex textDecoration">
                <img
                  src={arrowLeft}
                  alt="arrowLeft"
                  width={9}
                  className="c-pointer"
                />
                <h5 className="page-heading ms-2 ps-1">
                  {id
                    ? customerFormik.values.firstName + " " + customerFormik.values.lastName
                    : "Add New Users"}
                </h5>
              </Link>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-lg-9 mt-3">
              <div className="bg-black-15 border-grey-5 rounded-8 p-3 row attributes">
                <div className="d-flex col-12 px-0 justify-content-between">
                  <div className="d-flex align-items-center">
                    <h6 className="text-lightBlue me-auto text-lightBlue fw-500">
                      User Information
                    </h6>
                  </div>
                </div>
                <hr className="hr-grey-6 mt-3 mb-0" />
                <div className="col-12 px-0">
                  <div className="row align-items-start">
                    <div className="col-md-6 mt-3">
                      <p className="text-lightBlue mb-1">
                        First Name <span style={{ color: "#F67476" }}>*</span>
                      </p>
                      <FormControl className="w-100 px-0">
                        <OutlinedInput
                          placeholder="Enter First Name"
                          size="small"
                          value={customerFormik.values.firstName}
                          onBlur={customerFormik.handleBlur}
                          onChange={customerFormik.handleChange}
                          name="firstName"
                        />
                      </FormControl>
                      {!!customerFormik.touched.firstName &&
                        customerFormik.errors.firstName && (
                          <FormHelperText error>
                            {customerFormik.errors.firstName}
                          </FormHelperText>
                        )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <p className="text-lightBlue mb-1">Last Name</p>
                      <FormControl className="w-100 px-0">
                        <OutlinedInput
                          placeholder="Enter Last Name"
                          size="small"
                          value={customerFormik.values.lastName}
                          onChange={customerFormik.handleChange}
                          name="lastName"
                        />
                      </FormControl>
                      {!!customerFormik.touched.lastName && customerFormik.errors.lastName && (
                        <FormHelperText error>{customerFormik.errors.lastName}</FormHelperText>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <p className="text-lightBlue mb-1">
                        Date of Birth
                        {/* <span style={{ color: "#F67476" }}>*</span> */}
                      </p>
                      <FormControl className="w-100 px-0">
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <DatePicker
                            name="dob"
                            value={new Date(customerFormik.values.dob)}
                            onChange={handleDOB}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                size="small"
                              />
                            )}
                            maxDate={maxDate}
                          />
                        </LocalizationProvider>
                      </FormControl>
                      {!!customerFormik.touched.dob && customerFormik.errors.dob && (
                        <FormHelperText error>{customerFormik.errors.dob}</FormHelperText>
                      )}
                    </div>
                    <div className="col-md-6 mt-3">
                      <p className="text-lightBlue mb-1">
                        Gender <span style={{ color: "#F67476" }}>*</span>
                      </p>
                      <FormControl
                        sx={{ m: 0, minWidth: 120, width: "100%" }}
                        size="small">
                        <Select
                          labelId="demo-select-small"
                          id="demo-select-small"
                          name="gender"
                          value={customerFormik.values.gender}
                          onChange={customerFormik.handleChange}
                          size="small"
                          placeholder="Gender">
                          <MenuItem
                            value="male"
                            sx={{ fontSize: 13, color: "#5c6d8e" }}>
                            Male
                          </MenuItem>
                          <MenuItem
                            value="female"
                            sx={{ fontSize: 13, color: "#5c6d8e" }}>
                            Female
                          </MenuItem>
                          <MenuItem
                            value="others"
                            sx={{ fontSize: 13, color: "#5c6d8e" }}>
                            Rather Not Say
                          </MenuItem>
                        </Select>
                      </FormControl>
                      {!!customerFormik.touched.gender && customerFormik.errors.gender && (
                        <FormHelperText error>{customerFormik.errors.gender}</FormHelperText>
                      )}
                    </div>
                    <div className="col-md-12 mt-3">
                      <p className="text-lightBlue mb-1">
                        Mobile Number <span style={{ color: "#F67476" }}>*</span>
                      </p>
                      <FormControl className="w-100 px-0">
                        <OutlinedInput
                          placeholder="Enter Mobile Number"
                          size="small"
                          sx={{ paddingLeft: 0 }}
                          startAdornment={
                            <InputAdornment position="start">
                              {/* <AppMobileCodeSelect
                                formik={customerFormik}
                                handleCode={handleCode}
                              /> */}
                              <AppMobileCodeSelect
                                // disabled={customerFormik?.values?.isPhoneVerified}
                                formik={customerFormik}
                                handleCode={(e, value) => {
                                  customerFormik.setFieldValue("countryCode", value?._id);
                                }}
                                countryCode={customerFormik?.values?.countryCode}
                                // handlePhoneBlur={handlePhoneBlur}
                              />
                            </InputAdornment>
                          }
                          value={customerFormik.values.phone}
                          onBlur={customerFormik.handleBlur}
                          onChange={customerFormik.handleChange}
                          name="phone"
                        />
                      </FormControl>
                      {(!!customerFormik.touched.countryCode ||
                        !!customerFormik.touched.phone) &&
                        (customerFormik.errors.countryCode || customerFormik.errors.phone) && (
                          <FormHelperText error>
                            {customerFormik.errors.countryCode || customerFormik.errors.phone}
                          </FormHelperText>
                        )}
                    </div>
                    <div className="col-md-12">
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="isSendMessage"
                            value={customerFormik.values?.isSendMessage}
                            checked={customerFormik.values?.isSendMessage}
                            onChange={customerFormik.handleChange}
                            inputProps={{ "aria-label": "controlled" }}
                            size="small"
                            style={{
                              color: "#5C6D8E",
                            }}
                          />
                        }
                        label="User agreed to receive SMS marketing text messages."
                        sx={{
                          "& .MuiTypography-root": {
                            fontSize: 13,
                            color: "#5c6d8e",
                          },
                        }}
                      />
                    </div>
                    <div className="col-md-12 mt-3">
                      <p className="text-lightBlue mb-1">
                        Email ID <span style={{ color: "#F67476" }}>*</span>
                      </p>
                      <FormControl className="w-100 px-0">
                        <OutlinedInput
                          placeholder="Enter Email ID"
                          size="small"
                          value={customerFormik.values.email}
                          onChange={customerFormik.handleChange}
                          name="email"
                        />
                      </FormControl>
                      {!!customerFormik.touched.email && customerFormik.errors.email && (
                        <FormHelperText error>{customerFormik.errors.email}</FormHelperText>
                      )}
                    </div>
                    <div className="col-md-12">
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="isSendEmail"
                            value={customerFormik.values?.isSendEmail}
                            checked={customerFormik.values?.isSendEmail}
                            onChange={customerFormik.handleChange}
                            inputProps={{ "aria-label": "controlled" }}
                            size="small"
                            style={{
                              color: "#5C6D8E",
                            }}
                          />
                        }
                        label="User agreed to receive marketing emails."
                        sx={{
                          "& .MuiTypography-root": {
                            fontSize: 13,
                            color: "#5c6d8e",
                          },
                        }}
                      />
                    </div>
                    {/* <div className="col-md-12 mt-3">
                      <p className="text-lightBlue mb-1">
                        Password <span style={{ color: "#F67476" }}>*</span>
                      </p>
                      <FormControl className="w-100 px-0">
                        <OutlinedInput
                          placeholder="Enter Password"
                          size="small"
                          value={customerFormik.values.password}
                          onBlur={customerFormik.handleBlur}
                          onChange={customerFormik.handleChange}
                          name="password"
                          type={!showPass ? "text" : "password"}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={toggleShowPasswordHandler}
                                type="button"
                                edge="end">
                                {showPass ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      </FormControl>
                      {!!customerFormik.touched.password && customerFormik.errors.password && (
                        <FormHelperText error>{customerFormik.errors.password}</FormHelperText>
                      )}
                    </div> */}
                    {/* <div className="col-md-12 mt-3">
                      <p className="text-lightBlue mb-1">
                        Confirm Password <span style={{color: "#F67476"}}>*</span>
                      </p>
                      <FormControl className="w-100 px-0">
                        <OutlinedInput 
                          placeholder="Enter Password" 
                          size="small"
                          onChange={handleConfirmPass}
                          value={customerFormik.values.password ?? confirmPass}
                          type={!showPass ? "text" : "password"}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={toggleShowPasswordHandler}
                                type="button"
                                edge="end"
                              >
                                {showPass ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      </FormControl>
                      {(!passCheck && confirmPass.length > 2) ?
                        <span style={{ color: "#F67476" }}>Password mismatched</span>
                        : ""
                      }
                    </div> */}
                    {/* <div className="col-md-12">
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="isTemporaryPassword"
                            value={customerFormik.values.isTemporaryPassword}
                            onChange={customerFormik.handleChange}
                            inputProps={{ "aria-label": "controlled" }}
                            size="small"
                            style={{
                              color: "#5C6D8E",
                            }}
                          />
                        }
                        label="Use Temporary Password"
                        sx={{
                          "& .MuiTypography-root": {
                            fontSize: 13,
                            color: "#c8d8ff",
                          },
                        }}
                      />
                    </div> */}
                    <div className="col-md-12 mt-3">
                      <p className="text-lightBlue mb-1">User Group</p>
                      <Autocomplete
                        multiple
                        id="checkboxes-tags-demo"
                        sx={{ width: "100%" }}
                        options={customerGroupData?.data?.data || []}
                        value={selectedGroups || []}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option?.name}
                        size="small"
                        onChange={handleGroupChange}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                              checkedIcon={<CheckBoxIcon fontSize="small" />}
                              checked={selected}
                              size="small"
                              style={{
                                color: "#5C6D8E",
                                marginRight: 0,
                              }}
                            />
                            <small className="text-lightBlue">{option.name}</small>
                          </li>
                        )}
                        renderTags={(value) =>
                          value.map((option) => (
                            <div
                              key={option?._id}
                              className={`rounded-pill d-flex align-items-center c-pointer mr-1 mt-1 px-1`}
                              style={{
                                background:
                                  "linear-gradient(303.01deg, #2f2e69 -4.4%, #514969 111.29%)",
                              }}>
                              <small className="fw-400 text-lightBlue px-1">
                                {option?.name}
                              </small>
                              <button
                                type="button"
                                className="reset">
                                <CancelIcon
                                  src={cancel}
                                  alt="cancel"
                                  fontSize="15px"
                                  className="c-pointer text-lightBlue py-1"
                                  onClick={() => removeGroup(option?._id)}
                                />
                              </button>
                            </div>
                          ))
                        }
                        renderInput={(params) => (
                          <TextField
                            size="small"
                            {...params}
                            placeholder="Search"
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <AddAddress
                name="address"
                value={customerFormik.values.address}
                customerAddressDetails={updateAddresses}
                data={customerData?.data?.data[0]?.addresses || []}
                addressData={customerData?.data?.data || []}
                value2={customerFormik.values}
                deleteAddress={deleteAddress}
                deleteCustomerAddress={deleteCustomerAddress}
                customerAddressFormik={customerFormik}
                customerId={customerData?.data?.data[0]?._id}
                setEdit={setEdit}
              />
            </div>
            <div className="col-lg-3 mt-3 pe-0 ps-0 ps-lg-3">
              <UploadMediaBox
                imageName={addMedia}
                headingName={"Media"}
                UploadChange={uploadMediaHandler}
                previousImage={customerFormik.values.imageUrl}
                module="customer"
                // setDelete={setDelete}
              />
              {/* <TagsBox
                formik={customerFormik}
                name="tags"
                value={customerFormik.values.tags || []}
                tagsList={tagsData?.data?.data || []}
                selectedTagList={selectedTagList}
              /> */}
              <NotesBox
                name={"notes"}
                onChange={customerFormik.handleChange}
                value={customerFormik.values.notes}
              />
            </div>
          </div>
        </div>
        <SaveFooterTertiary
          show={id ? customerState.isEditing : true}
          onDiscard={backHandler}
          isLoading={createCustomerIsLoading || editCustomerIsLoading}
        />
      </form>
      <DiscardModalSecondary
        when={
          createCustomerIsSuccess || editCustomerIsSuccess
            ? false
            : !_.isEqual(customerFormik.values, customerFormik.initialValues)
        }
        message="customer tab"
      />
    </>
  );
};

export default AddUser;
