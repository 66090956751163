import { useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Table, TableBody, TableContainer, Tooltip } from "@mui/material";
import { showError, showSuccess } from "../../../../features/snackbar/snackbarAction";
import {
  useBulkDeleteFoldersMutation,
  useDeleteFolderMutation,
  useEditFolderMutation,
  useGetFoldersQuery,
} from "../../../../features/settings/filemanager/filemanagerApiSlice";
import info from "../../../../assets/icons/info.svg";
import folderLargePurple from "../../../../assets/icons/folderLargePurple.svg";
import NameRenameDialog from "../Dialogs/NameRenameDialog";
import DeleteAlertDialog from "../Dialogs/DeleteAlertDialog";
import OnlyFoldersIconView from "../FileManagerViews/OnlyFoldersIconView";
import { EnhancedTableHead } from "../../../../components/TableDependencies/TableDependencies";
import FolderListView from "../FileManagerViews/FolderListView";
import TableLoader from "../../../../components/Loader/TableLoader";
import NoDataFound from "../../../../components/NoDataFound/NoDataFound";
import apiSlice from "../../../../app/api/apiSlice";

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
  },
  {
    id: "type",
    numeric: false,
    disablePadding: false,
    label: "Type",
  },
  {
    id: "size",
    numeric: false,
    disablePadding: false,
    label: "Size",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    label: "Actions",
  },
];

export default function FoldersOnly({ views = "icon", queryFilters = {}, onExplore = () => {} }) {
  const dispatch = useDispatch();

  const [selected, setSelected] = useState([]);

  const clearSelected = () => setSelected([]);

  const handleFolderSelection = (check, folder) =>
    setSelected(check ? selected.concat(folder) : selected.filter((sl) => !Object.is(sl, folder)));

  const [pageSize, setPageSize] = useState(12);
  const { data: allFoldersData, isLoading: allFoldersIsLoading } = useGetFoldersQuery({ ...queryFilters, pageSize, pageNo: 1 });
  const allFolders = allFoldersData?.data?.data ?? [];
  const totalFolders = allFoldersData?.data?.totalCount ?? 0;

  const [renameEditFolder, { isLoading: renameEditFolderIsLoading }] = useEditFolderMutation();
  const [renamingFolder, setRenamingFolder] = useState(null);
  const handleRenameFolderSelect = (folder) => setRenamingFolder(folder);
  const handleRenameFolderClose = () => setRenamingFolder(null);
  const handleRenameFolder = (name = "") => {
    renameEditFolder({ id: renamingFolder._id, folderData: { name } })
      .unwrap()
      .then(() => {
        handleRenameFolderClose();
        dispatch(showSuccess({ message: "Folder renamed successfully" }));
      })
      .catch((e) => {
        console.log(e);
        dispatch(showError({ message: "Something went wrong" }));
      });
  };

  const [deleteFolder, { isLoading: deleteFolderIsLoading }] = useDeleteFolderMutation();
  const [deletingFolder, setDeletingFolder] = useState(null);
  const handleDeleteFolderSelect = (folder) => setDeletingFolder(folder);
  const handleDeleteFolderClose = () => setDeletingFolder(null);
  const handleDeleteFolder = () => {
    if (selected.length > 0) {
      bulkDeleteFolders({ deletes: selected.map((sl) => sl._id) })
        .unwrap()
        .then(() => {
          dispatch(apiSlice.util.resetApiState());
          dispatch(showSuccess({ message: `${selected.length} Folders Deleted successfully!` }));
        })
        .catch((e) => dispatch(showError({ message: e.message ?? "Something went wrong!" })))
        .finally(() => {
          clearSelected();
          setDeletingFolder(null);
        });
    } else {
      deleteFolder(deletingFolder._id)
        .unwrap()
        .then(() => {
          dispatch(apiSlice.util.resetApiState());
          dispatch(showSuccess({ message: "Folder Deleted successfully" }));
        })
        .catch((e) => {
          console.log(e);
          dispatch(showError({ message: e.message ?? "Something went wrong" }));
        })
        .finally(() => {
          clearSelected();
          setDeletingFolder(null);
        });
    }
  };

  const [bulkDeleteFolders, { isLoading: bulkDeleteFoldersIsLoading }] = useBulkDeleteFoldersMutation();

  // const handleBulkActionSelect = (action) => {
  //   if (action === "Delete") {
  //     handleDeleteFolderSelect({});
  //   }
  // };

  return (
    <div className="my-3">
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <h4 className="text-lightBlue fs-6 fw-500 me-2">All / Folders</h4>
          <Tooltip
            title="Lorem ipsum"
            placement="top">
            <img
              src={info}
              alt="info"
              className="c-pointer"
              width={13.5}
            />
          </Tooltip>
        </div>
        {selected.length > 0 && (
          <div className="d-flex align-items-center px-2 mt-3">
            <button className="button-grey py-2 px-3">
              <small className="text-lightBlue">
                {selected.length} folders are selected{" "}
                <span
                  className="text-blue-2 c-pointer"
                  onClick={clearSelected}>
                  (Clear Selection)
                </span>
              </small>
            </button>
            <button
              className="button-grey py-2 px-3 ms-2"
              variant="contained"
              onClick={() => handleDeleteFolderSelect({})}>
              <small className="text-lightBlue">Delete</small>
            </button>
            {/* <TableMassActionButton
              headingName="Mass Action"
              onSelect={handleBulkActionSelect}
              defaultValue={["Delete"]}
            /> */}
          </div>
        )}
      </div>

      {views === "icon" && (
        <div className="row align-items-center">
          {allFoldersIsLoading ? (
            <span className="d-flex justify-content-center m-3">
              <TableLoader />
            </span>
          ) : allFolders.length === 0 ? (
            <span className="d-flex justify-content-center m-3">
              <NoDataFound />
            </span>
          ) : (
            allFolders.map((folder) => (
              <div
                key={folder._id}
                className="col-2 my-2">
                <OnlyFoldersIconView
                  folder={folder}
                  isSelected={selected.includes(folder)}
                  onDoubleClick={onExplore}
                  onSelect={handleFolderSelection}
                  clearSelected={clearSelected}
                  onRename={handleRenameFolderSelect}
                  onDelete={handleDeleteFolderSelect}
                />
              </div>
            ))
          )}
        </div>
      )}

      {views === "list" && (
        <TableContainer>
          {allFoldersIsLoading ? (
            <span className="d-flex justify-content-center m-3">
              <TableLoader />
            </span>
          ) : allFolders.length === 0 ? (
            <span className="d-flex justify-content-center m-3">
              <NoDataFound />
            </span>
          ) : (
            <Table size="medium">
              <EnhancedTableHead
                numSelected={selected.length}
                onSelectAllClick={(e) => setSelected(e.target.checked ? [...allFolders] : [])}
                rowCount={allFolders.length}
                headCells={headCells}
              />
              <TableBody>
                {allFolders.map((folder) => (
                  <FolderListView
                    key={folder._id}
                    folder={folder}
                    isSelected={selected.includes(folder)}
                    onDoubleClick={onExplore}
                    onSelect={(check, folder) =>
                      setSelected(check ? selected.concat(folder) : selected.filter((sl) => !Object.is(sl, folder)))
                    }
                    clearSelected={clearSelected}
                    onRename={(folder) => setRenamingFolder(folder)}
                    onDelete={(folder) => setDeletingFolder(folder)}
                  />
                ))}
              </TableBody>
            </Table>
          )}
        </TableContainer>
      )}

      {allFolders.length < totalFolders && (
        <div className="d-flex justify-content-center">
          <Button
            variant="text"
            onClick={() => setPageSize((size) => size + 12)}>
            <span className="text-blue-2">Load More</span>
          </Button>
        </div>
      )}

      <NameRenameDialog
        isOpen={!!renamingFolder}
        isLoading={renameEditFolderIsLoading}
        headingText="Rename Folder"
        labelText="Folder Name"
        folderName={renamingFolder?.name ?? ""}
        buttonText="Rename"
        imageSrc={folderLargePurple}
        onClose={handleRenameFolderClose}
        onAction={handleRenameFolder}
      />

      <DeleteAlertDialog
        show={!!deletingFolder}
        isLoading={deleteFolderIsLoading || bulkDeleteFoldersIsLoading}
        title="Delete Folder"
        confirmText="Delete"
        message={
          <>
            Do you want to delete{" "}
            <span className="text-blue-2">{`${
              selected.length > 0 ? `${selected.length} folders` : deletingFolder?.name ?? ""
            }`}</span>{" "}
            permanently?
          </>
        }
        countMessage={
          <>
            This will also delete{" "}
            <span className="text-blue-2">{`${
              selected.length > 0
                ? `${selected.reduce((t, sl) => t + sl.result.length, 0)}`
                : deletingFolder?.result.length ?? ""
            }`}</span>{" "}
            files in it!
          </>
        }
        onConfirm={handleDeleteFolder}
        onCancel={handleDeleteFolderClose}
      />
    </div>
  );
}
