import { useState, useMemo } from "react";
import { useFormik } from "formik";
import { useNavigate, useSearchParams, createSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import _ from "lodash";
// import SearchIcon from "@mui/icons-material/Search";
import * as Yup from "yup";
import {
  FormControl,
  OutlinedInput,
  Checkbox,
  FormControlLabel,
  Tooltip,
  FormHelperText,
} from "@mui/material";
import { omitEmptyKeys } from "../../../../utils/helper";
import { showError, showSuccess } from "../../../../features/snackbar/snackbarAction";
import {
  useCreateCollectionMutation,
  useEditCollectionMutation,
} from "../../../../features/parameters/collections/collectionsApiSlice";
import AppTextEditor from "../../../../components/AppTextEditor/AppTextEditor";
// import SEO from "../../../Products/AddProduct/SEO/SEO";
import NotesBox from "../../../../components/NotesBox/NotesBox";
import UploadMediaBox from "../../../../components/UploadMediaBox/UploadMediaBox";
// import UploadBanner from "../../../../components/UploadBanner/UploadBanner";
import { StatusBoxSecondary } from "../../../../components/StatusBox/StatusBox";
// import VisibilityBox from "../../../../components/VisibilityBox/VisibilityBox";
// import DuplicateCollection from "../DuplicateCollection/DuplicateCollectionModal";
import { DiscardModalSecondary } from "../../../../components/Discard/DiscardModal";
import { SaveFooterTertiary } from "../../../../components/SaveFooter/SaveFooter";
import InfoHeader from "../../../../components/Header/InfoHeader";
import "../../CreateCollection/CreateCollection.scss";
import info from "../../../../assets/icons/info.svg";
// import cancel from "../../../../assets/icons/cancel.svg";
// import arrowDown from "../../../../assets/icons/arrowDown.svg";
// import featureUpload from "../../../../assets/images/products/featureUpload.svg";
// import ringSmall from "../../../../assets/images/ringSmall.svg";
// import deleteWhite from "../../../../assets/icons/deleteWhite.svg";
// import editWhite from "../../../../assets/icons/editWhite.svg";
// import deleteButton from "../../../../assets/icons/deleteButton.svg";
import addMedia from "../../../../assets/icons/addMedia.svg";
import SEOSecondary from "../../../Products/AddProduct/SEO/SEOSecondary";
// import { useFormikToastError } from "../../../../hooks/error";
// import AddProducts from "../../../../components/AddProducts/AddProducts";
// import { useGetAllProductsQuery } from "../../../../features/products/product/productApiSlice";
// import AddManualProducts from "../../../../components/AddProducts/AddManualProducts";
import { convert } from "html-to-text";
import AddLinkedProducts from "../../../../components/AddProducts/AddLinkedProducts";

const validationSchema = Yup.object({
  title: Yup.string()
    .trim()
    .min(3, "Title must be at least 3 characters")
    .max(50, "Title must be less than 50 characters")
    .required("Required"),
  filter: Yup.boolean().default(false),
  description: Yup.string().trim(),
  status: Yup.string()
    .oneOf(["active", "in-active", "scheduled"], "Must be one of active, in-active, scheduled")
    .required("Required"),
  startDate: Yup.date(),
  endDate: Yup.date(),
  mediaUrl: Yup.string().trim(),
  notes: Yup.string(),
  type: Yup.string()
    .oneOf(["automated", "manual"], "Must be one of automated, manual")
    .required("Required"),
  shouldMatch: Yup.string().trim(),
  conditions: Yup.array().of(
    Yup.object({
      field: Yup.string().trim(),
      operator: Yup.string().oneOf([
        "gt",
        "lt",
        "equal",
        "notEqual",
        "startWith",
        "endWith",
        "contains",
      ]),
      value: Yup.string().trim(),
      itemId: Yup.string().trim(),
    })
  ),
  seo: Yup.object({
    title: Yup.string().required("Required").max(50, "Title cannot exceed 50 characters"),
    description: Yup.string().trim(),
    metaKeywords: Yup.array().of(Yup.string().required("Required")),
  }),
});

const initValue = {
  title: "",
  filter: false,
  description: "",
  status: "active",
  startDate: "",
  endDate: "",
  mediaUrl: "",
  notes: "",
  type: "automated",
  shouldMatch: "all",
  conditions: [],
  seo: {
    title: "",
    description: "",
    metaKeywords: "",
    multipleMetaTags: [],
  },
  isVisibleFrontend: true,
};

export default function CreateCollection() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [seoViewAll, setSeoViewAll] = useState(false);

  const [editCollection, { isLoading: editCollectionIsLoading }] = useEditCollectionMutation();
  const [createCollection, { isLoading: createCollectionIsLoading }] =
    useCreateCollectionMutation();

  const [products, setProducts] = useState([]);

  const formik = useFormik({
    initialValues: initValue,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log(values);
      createCollection({
        ...omitEmptyKeys(values),
        seo: omitEmptyKeys({ ...values.seo, metaKeywords: values.seo.multipleMetaTags }),
        conditions: values.conditions.map((c) => omitEmptyKeys(c)),
      })
        .unwrap()
        .then((data) => {
          console.log(data);
          return values.type === "manual"
            ? editCollection({
                id: data?.data?._id,
                details: { products },
              }).unwrap()
            : Promise.resolve();
        })
        .then(() => {
          navigate({
            pathname: "/parameters/collections",
            search: `?${createSearchParams({ filter: searchParams.get("filter") })}`,
          });
          showSuccess({ message: "Collection created Succesfully" });
        })
        .catch((e) => {
          console.log(e);
          dispatch(
            showError({
              message: e?.data?.message ?? e?.message ?? "Something went wrong",
            })
          );
        });
    },
  });

  // useEffect(() => console.log({ values: formik.values, products }), [formik.values, products]);

  const discardable = useMemo(
    () => !_.isEqual(formik.initialValues, formik.values),
    [formik.initialValues, formik.values]
  );

  return (
    <div className="page container-fluid position-relative user-group">
      <InfoHeader
        title="Create Collection"
        onBack={() =>
          navigate({
            pathname: "/parameters/collections",
            search: `?${createSearchParams({ filter: searchParams.get("filter") })}`,
          })
        }
      />

      <form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-lg-9 mt-4">
            <div className="bg-black-15 border-grey-5 rounded-8 p-3 row attributes">
              <div className="col-md-12 px-0 mt-1">
                <div className="d-flex mb-1">
                  <p className="text-lightBlue">Collection Title</p>
                  <span style={{ color: "#F67476", paddingRight: 4 }}>*</span>
                  <Tooltip
                    title="Lorem ipsum"
                    placement="top">
                    <img
                      src={info}
                      alt="info"
                      className=" c-pointer"
                      width={13.5}
                    />
                  </Tooltip>
                </div>
                <FormControl className="w-100 px-0">
                  <OutlinedInput
                    placeholder="Collection Name"
                    size="small"
                    name="title"
                    value={formik.values.title}
                    onChange={(e) => {
                      formik.handleChange(e);
                      formik.setFieldValue("seo.title", e.target.value);
                    }}
                    onBlur={formik.handleBlur}
                    autoFocus
                  />
                </FormControl>
                <FormHelperText error>
                  {!!formik.touched.title && !!formik.errors.title && formik.errors.title}
                </FormHelperText>
                <div className="small">
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="filter"
                        value={formik.values.filter}
                        checked={formik.values.filter}
                        onChange={formik.handleChange}
                        size="small"
                        style={{
                          color: "#5C6D8E",
                          marginRight: 0,
                          width: "auto",
                        }}
                      />
                    }
                    label="Include in Filters"
                    sx={{
                      "& .MuiTypography-root": {
                        fontSize: "0.875rem",
                        color: "#c8d8ff",
                      },
                    }}
                    className=" px-0 me-1"
                  />
                  <span className="text-blue-2 c-pointer">(manage)</span>
                </div>
              </div>
              <div className="col-12 mt-3 px-0">
                <div className="d-flex  mb-1">
                  <p className="text-lightBlue me-2">Description</p>
                  <Tooltip
                    title="Lorem ipsum"
                    placement="top">
                    <img
                      src={info}
                      alt="info"
                      className=" c-pointer"
                      width={13.5}
                    />
                  </Tooltip>
                </div>
                <AppTextEditor
                  value={formik.values.description}
                  setFieldValue={(val) => {
                    formik.setFieldValue("description", val === "" ? "<p></p>" : val);
                    formik.setFieldValue("seo.description", val === "" ? "" : convert(val));
                  }}
                />
              </div>
            </div>

            <div className="bg-black-15 border-grey-5 rounded-8 p-3 mt-4 row">
              <div className="d-flex col-12 px-0 justifu-content-between">
                <div className="d-flex align-items-center me-auto">
                  <h6 className="text-lightBlue me-auto fw-500">Add Products</h6>
                  <Tooltip
                    title="Lorem ipsum"
                    placement="top">
                    <img
                      src={info}
                      alt="info"
                      className="c-pointer ms-2"
                      width={13.5}
                    />
                  </Tooltip>
                </div>
              </div>
              <AddLinkedProducts
                selection={formik.values.type}
                condition={formik.values.shouldMatch}
                filters={formik.values.conditions}
                products={products}
                onSelectionChange={(val) => formik.setFieldValue("type", val)}
                onConditionChange={(val) => formik.setFieldValue("shouldMatch", val)}
                onFiltersChange={(val) => formik.setFieldValue("conditions", val)}
                onProductsChange={setProducts}
              />
            </div>

            <div className="mt-4">
              <SEOSecondary
                formik={formik}
                viewAll={seoViewAll}
                setViewAll={setSeoViewAll}
              />
            </div>
          </div>
          <div className="col-lg-3 mt-4 pe-0 ps-0 ps-lg-3">
            <StatusBoxSecondary
              title="collection status"
              status={formik.values.status}
              onStatus={(status) => {
                formik.setFieldValue("status", status);
                formik.setFieldValue("startDate", "");
                formik.setFieldValue("endDate", "");
              }}
              startDate={formik.values.startDate}
              endDate={formik.values.endDate}
              onSchedule={({ startDate, endDate }) => {
                formik.setFieldValue("startDate", startDate);
                formik.setFieldValue("endDate", endDate);
                formik.setFieldValue("status", "scheduled");
              }}
              containerStyles="status-container"
              titleStyles="status-title"
              actionStyles="status-action"
              activeText="active"
              inActiveText="in-active"
            />
            <div className="mt-4">
              <UploadMediaBox
                imageName={addMedia}
                headingName="Media"
                UploadChange={(url) => formik.setFieldValue("mediaUrl", url)}
                previousImage={formik.values.mediaUrl}
                module="collection"
                // setDelete={setDelete}
              />
            </div>
            <NotesBox
              name="notes"
              value={formik.values.notes}
              onChange={formik.handleChange}
            />
          </div>
        </div>
        <SaveFooterTertiary
          show={discardable}
          isLoading={createCollectionIsLoading || editCollectionIsLoading}
          onDiscard={() =>
            navigate({
              pathname: "/parameters/collections",
              search: `?${createSearchParams({ filter: searchParams.get("filter") })}`,
            })
          }
        />
        <DiscardModalSecondary
          when={discardable && !formik.isSubmitting}
          message="collection tab"
        />
      </form>
    </div>
  );
}
