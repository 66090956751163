import React from "react";
import {
  XAxis,
  YAxis,
  // CartesianGrid,
  Tooltip,
  // Legend,
  ResponsiveContainer,
  Area,
  AreaChart,
} from "recharts";
import { Dashboard } from "@mui/icons-material";

const data = [
  {
    name: "Nov",
    uv: 4000,
    totalSpent: 1000,
  },
  {
    name: "Dec",
    uv: 3000,
    totalSpent: 2198,
  },
  {
    name: "Jan",
    uv: 2000,
    totalSpent: 2000,
  },
  {
    name: "Feb",
    uv: 2780,
    totalSpent: 3008,
  },
  {
    name: "Mar",
    uv: 2780,
    totalSpent: 2008,
  },
  {
    name: "Apr",
    uv: 2780,
    totalSpent: 4208,
  },
  {
    name: "May",
    uv: 2780,
    totalSpent: 4508,
  },
  {
    name: "Jun",
    uv: 2780,
    totalSpent: 5508,
  },
  {
    name: "Jul",
    uv: 2780,
    totalSpent: 5908,
  },
];

const DashBoardChart = ({ data = [] }) => {
  // const CustomTooltip = ({ active, payload, label, name }) => {
  //   if (active && payload && payload.length) {
  //     return (
  //       <div className="chart-tooltip d-flex flex-column align-items-center justify-content-center">
  //         <h6 className="text-lightBlue fw-500">{`₹ ${payload[0].value}`}</h6>
  //         <small className="text-grey-6 mt-1 fw-600">Total Spent</small>
  //       </div>
  //     );
  //   }

  //   return null;
  // };

  return (
    <ResponsiveContainer
      width={"100%"}
      height={150}>
      <AreaChart
        width={200}
        height={200}
        data={data}
        margin={{
          top: 24,
          right: 16,
          left: 0,
          bottom: 8,
        }}>
        <defs>
          <linearGradient
            id="colorTotalSpent"
            x1="0"
            y1="0"
            x2="0"
            y2="1">
            <stop
              offset="5%"
              stopColor="#8f5fe8"
              stopOpacity={0.8}
            />
            <stop
              offset="95%"
              stopColor="#8f5fe8"
              stopOpacity={0}
            />
          </linearGradient>
        </defs>
        <Area
          type="monotone"
          dataKey="totalSpent"
          stroke="#8f5fe8"
          fill="url(#colorTotalSpent)"
          fillOpacity={0.5}
          activeDot={{ r: 8 }}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default DashBoardChart;
