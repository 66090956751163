import { forwardRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  OutlinedInput,
  Slide,
} from "@mui/material";
import { showError, showSuccess } from "../../../features/snackbar/snackbarAction";
import cancel from "../../../assets/icons/cancel.svg";
import { LoadingButton } from "@mui/lab";
import {
  useCheckProductExistsQuery,
  useDuplicateProductMutation,
} from "../../../features/products/product/productApiSlice";

const Transition = forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="up"
      ref={ref}
      {...props}
    />
  );
});

export default function DuplicateDialog({ product = {}, onClose = () => {} }) {
  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={!!product?._id}
      onClose={() => onClose(false)}
      TransitionComponent={Transition}>
      <Content
        product={product}
        onClose={onClose}
      />
    </Dialog>
  );
}

function Content({ product = {}, onClose = () => {} }) {
  const dispatch = useDispatch();

  const { _id = "", title = "" } = product ?? {};

  const [checks, setChecks] = useState({
    title,
    description: true,
    media: true,
    productType: true,
    price: true,
    priceMasterData: true,
    inventory: true,
    setAndVariant: true,
    productTab: true,
    moreFeatures: true,
    seo: true,
    shipping: true,
  });

  const onSelectAll = () =>
    setChecks((chk) => ({
      ...chk,
      description: true,
      media: true,
      productType: true,
      price: true,
      priceMasterData: true,
      inventory: true,
      setAndVariant: true,
      productTab: true,
      moreFeatures: true,
      seo: true,
      shipping: true,
    }));

  const checksChanged = (e) => {
    const { value, checked } = e.target;
    setChecks((chk) => ({ ...chk, [value]: checked }));
  };

  const { data: checkProduct, isLoading: checkProductIsLoading } = useCheckProductExistsQuery(
    { title: checks.title },
    { skip: !checks.title }
  );

  const [duplicate, { isLoading }] = useDuplicateProductMutation();

  const duplicateProduct = () => {
    duplicate({ id: _id, details: checks })
      .unwrap()
      .then(() => {
        onClose(true);
        dispatch(showSuccess({ message: "Product Duplicated Successfully" }));
      })
      .catch((e) =>
        dispatch(
          showError({ message: e?.data?.message ?? e?.message ?? e ?? "Something went wrong" })
        )
      );
  };

  return (
    <>
      <DialogTitle>
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex flex-column ">
            <h4 className="text-lightBlue fw-500">Duplicate Product</h4>
            {/* <small className="text-grey-6 mt-2">Size Chart Button</small> */}
          </div>
          <button
            className="reset"
            onClick={() => onClose(false)}>
            <img
              src={cancel}
              alt="cancel"
              width={30}
              className="c-pointer"
            />
          </button>
        </div>
      </DialogTitle>
      <hr className="hr-grey-6 my-0" />
      <DialogContent>
        <div className="row align-items-start">
          <div className="col-12">
            <div className="mb-1">
              <span className="text-lightBlue fw-400">Title</span>
            </div>
            <FormControl
              size="small"
              fullWidth
              className="">
              <OutlinedInput
                value={checks.title}
                onChange={(e) => setChecks((chk) => ({ ...chk, title: e.target.value }))}
                placeholder="Product Title"
              />
              {checkProductIsLoading && <FormHelperText error>Please Wait</FormHelperText>}
              {!checks.title && <FormHelperText error>Product Title Required</FormHelperText>}
              {!checkProduct && (
                <FormHelperText error>Product Title Already Exist</FormHelperText>
              )}
            </FormControl>
          </div>
        </div>
      </DialogContent>
      <hr className="hr-grey-6 my-0" />
      <DialogContent>
        <div className="row align-items-start">
          <div className="col-12">
            <div className="d-flex justify-content-between mb-3">
              <h5 className="text-lightBlue fw-500">What to include in this Duplicate?</h5>
              <button
                className="button-gradient py-1 px-2 w-auto"
                onClick={onSelectAll}>
                <p>Select All</p>
              </button>
            </div>
            <FormGroup onChange={checksChanged}>
              {/* <FormControlLabel
                label="Product Name"
                value="title"
                className="text-lightBlue"
                checked={checks.title}
                control={<Checkbox size="small" />}
              /> */}
              <FormControlLabel
                label="Description"
                value="description"
                className="text-lightBlue"
                checked={checks.description}
                control={<Checkbox size="small" />}
              />
              <FormControlLabel
                label="Media"
                value="media"
                className="text-lightBlue"
                checked={checks.media}
                control={<Checkbox size="small" />}
              />
              <FormControlLabel
                label="Product Type"
                value="productType"
                className="text-lightBlue"
                checked={checks.productType}
                control={<Checkbox size="small" />}
              />
              <FormControlLabel
                label="Price"
                value="price"
                className="text-lightBlue"
                checked={checks.price}
                control={<Checkbox size="small" />}
              />
              <FormControlLabel
                label="Price Master Data"
                value="priceMasterData"
                className="text-lightBlue"
                checked={checks.priceMasterData}
                control={<Checkbox size="small" />}
              />
              <FormControlLabel
                label="Inventory"
                value="inventory"
                className="text-lightBlue"
                checked={checks.inventory}
                control={<Checkbox size="small" />}
              />
              <FormControlLabel
                label="Options and Variants"
                value="setAndVariant"
                className="text-lightBlue"
                checked={checks.setAndVariant}
                control={<Checkbox size="small" />}
              />
              <FormControlLabel
                label="Product Tabs"
                value="productTab"
                className="text-lightBlue"
                checked={checks.productTab}
                control={<Checkbox size="small" />}
              />
              <FormControlLabel
                label="More Features"
                value="moreFeatures"
                className="text-lightBlue"
                checked={checks.moreFeatures}
                control={<Checkbox size="small" />}
              />
              <FormControlLabel
                label="SEO"
                value="seo"
                className="text-lightBlue"
                checked={checks.seo}
                control={<Checkbox size="small" />}
              />
              <FormControlLabel
                label="Shipping"
                value="shipping"
                className="text-lightBlue"
                checked={checks.shipping}
                control={<Checkbox size="small" />}
              />
            </FormGroup>
          </div>
        </div>
      </DialogContent>
      <DialogActions className="d-flex justify-content-between px-4 py-3">
        <button
          onClick={() => onClose(false)}
          className="button-grey-outline py-2 px-4"
          style={{ lineHeight: 1.6 }}>
          <p className="text-lightBlue">Cancel</p>
        </button>
        <LoadingButton
          loading={isLoading || checkProductIsLoading}
          disabled={!checks.title || !checkProduct || isLoading || checkProductIsLoading}
          onClick={duplicateProduct}
          className="button-gradient py-2 px-4"
          style={{ lineHeight: 1.6 }}>
          <p>Save</p>
        </LoadingButton>
      </DialogActions>
    </>
  );
}
