import apiSlice from "../../app/api/apiSlice";

import { omitNullishKeys } from "../../utils/helper";

export const priceManagerApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllManagers: builder.query({
      query: (queries) => {
        return {
          url: "/parameters/priceMaster",
          params: omitNullishKeys(queries),
        };
      },
      transformResponse: (res) => res.data,
      providesTags: ["PriceManager"],
    }),
    createManager: builder.mutation({
      query: (managerDetails) => ({
        url: "/parameters/priceMaster/price",
        method: "POST",
        body: managerDetails,
      }),
      transformResponse: (res) => res.data,
      invalidatesTags: ["PriceManager", "Logs"],
    }),
    updateManager: builder.mutation({
      query: ({ details, id }) => ({
        url: `/parameters/priceMaster/price/${id}`,
        method: "PUT",
        body: details,
      }),
      transformResponse: (res) => res.data,
      invalidatesTags: ["PriceManager", "Logs"],
    }),
    deleteManager: builder.mutation({
      query: (id) => ({
        url: `/parameters/priceMaster/price/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["PriceManager", "Logs"],
    }),
  }),
});

export const {
  useGetAllManagersQuery,
  useCreateManagerMutation,
  useUpdateManagerMutation,
  useDeleteManagerMutation,
  useGetAllStonesQuery,
} = priceManagerApiSlice;
