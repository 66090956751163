import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FormControl, OutlinedInput } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

import OnBoard from "./OnBoard";
import OnBoardInput from "../../../components/Input/OnboardInput";
import storeName from "../../../assets/images/storeName.svg";

export default function StepOne() {
    const navigate = useNavigate();
    const [showInput, setShowInput] = useState(false);

    const handleContinue = () => {
        navigate("/auth/stepTwo");
    }

    const handleNewInput = () => {
        setShowInput(prevState => !prevState)
    }

    return (
        <OnBoard image={storeName} buttonName={"Continue"} onClick={handleContinue}>
            <p className="d-flex text-grey-6 mb-5">
                <p className="text-lightBlue">01&nbsp;</p>
                /&nbsp;04
            </p>
            <h2 className="text-blue-gradient fw-600 mb-2">Name your Store</h2>
            <small className="d-flex text-grey-6 w-75 mb-4">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lacus nulla eget adipiscing faucibus platea. Eu ultrices ipsum
            </small>
            <div className="mt-3">
                <OnBoardInput
                    placeholder={"Ex. Ginny Cake Shop"}
                />
                <div className="position-relative mt-1">
                    <div className="position-absolute top-0 end-0 c-pointer" onClick={handleNewInput}>
                        <small className="text-grey-6">
                            Not sure about the name,
                        </small>
                        <small className="text-blue-gradient">
                            Want us to suggest?
                        </small>
                    </div>
                </div>
            </div>
            {showInput && <div className="my-5">
                <div className="onBoardInput-2 w-100">
                    <FormControl className="w-100 px-0">
                        <OutlinedInput
                            placeholder={"Name"}
                            size="small"
                            startAdornment={
                                <div className="pe-2">
                                    <CheckCircleOutlineIcon
                                        style={{ color: "green" }}
                                        sx={{
                                            fontSize: "medium",
                                        }}
                                    />
                                </div>
                            }
                            endAdornment={
                                <small className="text-grey-6">
                                    .unthread.com
                                </small>
                            }
                        />
                    </FormControl>
                </div>
                <div className="position-relative mt-2">
                    <small className="position-absolute top-0 start-0 text-grey-6 w-50">
                        This is temporary domain, you can add custom domain in settings
                        <small className="text-blue-2">Learn more</small>
                    </small>
                    <small className="position-absolute top-0 end-0 text-blue-gradient">
                        Generate unique domain
                    </small>
                </div>
            </div>}
        </OnBoard>
    );
}