// import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";

import Heading from "../../components/Headers/DashboardHeader";
import VideoBox from "../../components/VideoBox/VideoBox";
import LiveVistor from "../../components/LiveVistor/LiveVistor";

import info from "../../assets/icons/info.svg";
import bharat from "../../assets/flag/india.svg";
import rocket from "../../assets/dashboard/rocket.svg";
import teamChat from "../../assets/dashboard/teamChat.svg";
import product from "../../assets/dashboard/sellingProducts.svg";
import boost from "../../assets/dashboard/boostSale.svg";
import report from "../../assets/dashboard/report.svg";
import upload from "../../assets/dashboard/upload.svg";

import "./Dashboard.scss";

import StorePerformance from "./StorePerformance";
import SalesPerformance from "./SalesPerformance";
import TopSellingParameters from "./TopSellingParameters";
import TopOrders from "./TopOrders";
import TopSellingProducts from "./TopSellingProducts";
import TopDevicesUsed from "./TopDevicesUsed";
import TopPageVisit from "./TopPageVisit";

const timeData = [{ name: "This Month" }, { name: "This Year" }, { name: "Custom" }];

const timeData2 = [{ name: "This Month" }, { name: "This Year" }];

const order = [
  { name: "Pending" },
  { name: "Completed" },
  { name: "Refunded" },
  { name: "RMA" },
];

const rightData = [
  {
    heading: "Boost Sales",
    subHead: "Boost your sale across whole channels",
    button: "Explore",
    image: boost,
  },
  {
    heading: "Reports",
    subHead: "No need to worry about reports, we got you",
    button: "Upload",
    image: report,
  },
  {
    heading: "Bulk Upload",
    subHead: "Uploading product was never that easy",
    button: "Upload",
    image: upload,
  },
  {
    heading: "Team Chats",
    subHead: "Experience the power of team communication with Team Chats.",
    button: "Manage",
    image: teamChat,
  },
];

const rows = [
  { name: "Rings", price: "$500k", product: "45k Products" },
  { name: "Earrings", price: "$300k", product: "35k Products" },
  { name: "Necklaces", price: "$100k", product: "20k Products" },
  { name: "Bangles", price: "$50K", product: "15k Products" },
  { name: "Others", price: "$5K", product: "4k Products" },
];

const rows2 = [
  { name: "Mirosa", price: "$500k", product: "45k Products" },
  { name: "Anant", price: "$300k", product: "35k Products" },
  { name: "Bridal Wear", price: "$100k", product: "20k Products" },
  { name: "BabyWear", price: "$50K", product: "15k Products" },
  { name: "Others", price: "$5K", product: "4k Products" },
];

const rows3 = [
  {
    orderId: "#1234",
    data: "Today at 5.25pm",
    customer: "Sahil Bhutani",
    country: "India",
    flag: bharat,
    total: "₹ 5,00,000",
  },
  {
    orderId: "#1234",
    data: "Today at 5.25pm",
    customer: "Sahil Bhutani",
    country: "India",
    flag: bharat,
    total: "₹ 5,00,000",
  },
  {
    orderId: "#1234",
    data: "Today at 5.25pm",
    customer: "Sahil Bhutani",
    country: "India",
    flag: bharat,
    total: "₹ 5,00,000",
  },
];

const headCells = [
  { name: "Order ID" },
  { name: "Date" },
  { name: "Customer" },
  { name: "Country" },
  { name: "Total" },
];

const Dashboard = () => {
  const userDetails = useSelector((state) => state.user);

  return (
    <div className="dashboard-container">
      <div className="dashboard">
        <div className="dashBoard-box">
          <div
            className="d-flex justify-content-between align-items-center"
            // onClick={handleMigrate}
          >
            <div className="px-2">
              <h3 className="d-flex align-items-baseline text-lightBlue">
                Hey
                <h1 className="text-blue-gradient fw-600 ms-2">
                  {userDetails?.fullName?.split?.(" ")?.[0] ?? "User Name"}
                </h1>
              </h3>
              <p className="text-lightBlue mt-4">
                Hey there, great news! We've got updates on your store's performance today,
                including
              </p>
              <p className="d-flex text-lightBlue mt-0">
                some helpful tips on how to make it even better.
                <p className="fw-600">&nbsp;Let's dive in!</p>
              </p>
            </div>
            {/* <div className="d-flex justify-content-end pe-4">
              <div className="w-10">
                <h5 className="text-blue-gradient fw-600">Free trial</h5>
                <small className="text-grey-6">
                  When you’re ready, subscribe to publish your site.
                </small>
              </div>
            </div> */}
          </div>

          <div className="d-flex justify-content-between mt-5">
            <div className="row col-12 d-flex justify-content-between">
              {/* <div className="col-lg-8"> */}
              <LiveVistor />

              <StorePerformance />

              <SalesPerformance />

              <TopSellingProducts />

              <TopSellingParameters />

              <TopOrders />

              <TopDevicesUsed />

              <TopPageVisit />

              {/* <hr className="hr-grey-6 my-4 w-100" /> */}

              {/* <div className="d-flex justify-content-between">
                <h5 className="text-lightBlue fw-500">Tutorials and Support</h5>
                <button className="button-grey-outline py-1 px-4">
                  <p className="text-lightBlue">see all</p>
                </button>
              </div> */}

              {/* <div className="col-12 d-flex flex-wrap mt-2">
                <div className="col-lg-6 pe-2">
                  <VideoBox />
                </div>
                <div className="col-lg-6 ps-2">
                  <VideoBox />
                </div>
              </div> */}
              {/* </div> */}
              {/* <div className="col-lg-4 row">
                <div>
                  <div
                    className="bg-button-5 border-grey-5 rounded-8 overflow-hidden"
                    style={{ height: "360px" }}>
                    <div
                      className="px-4"
                      style={{ zIndex: 2, position: "relative" }}>
                      <div className="d-flex mt-4 mb-2">
                        <h4 className="text-blue-gradient fw-600 me-2">Go Global</h4>
                        <Tooltip
                          title="Lorem ipsum"
                          placement="top">
                          <img
                            src={info}
                            alt="info"
                            className="c-pointer"
                            width={16}
                          />
                        </Tooltip>
                      </div>
                      <div className="w-75">
                        <small className="text-grey-7">
                          Sell your products globally with just a few clicks.
                        </small>
                      </div>
                    </div>
                    <div className="px-2">
                      <div className="button-gradient py-2 px-2 w-25 rocket-button">
                        <small>Explore</small>
                      </div>
                      <img
                        src={rocket}
                        className="rocket-img"
                        width={350}
                        height={350}
                      />
                    </div>
                  </div>
                  {rightData.map((item) => (
                    <div
                      className="bg-button-5 border-grey-5 rounded-8 mt-4 overflow-hidden"
                      style={{ height: "195px" }}>
                      <div
                        className="px-4"
                        style={{ zIndex: 2, position: "relative" }}>
                        <div className="d-flex mt-4 mb-2">
                          <h4 className="text-blue-gradient fw-600 me-2">{item.heading}</h4>
                          <Tooltip
                            title="Lorem ipsum"
                            placement="top">
                            <img
                              src={info}
                              alt="info"
                              className="c-pointer"
                              width={16}
                            />
                          </Tooltip>
                        </div>
                        <div className="w-50">
                          <div
                            className="text-grey-7"
                            style={{ fontSize: "12px" }}>
                            {item.subHead}
                          </div>
                        </div>
                        <div className="button-gradient py-2 px-3 rocket-button-3">
                          <small>{item.button}</small>
                        </div>
                      </div>
                      <div className="px-2">
                        <img
                          src={item.image}
                          className="rocket-img-2 rounded-8"
                          width={250}
                          height={250}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
