import { useState } from "react";
import { 
    Chip,
    Paper, 
    Popover
} from "@mui/material";
import { Visibility } from "@mui/icons-material";

import viewMore from "../../../assets/setting/viewmore.svg";
import store from "../../../assets/profile/newStore.svg";

const data = [
    {flag: store, title: "Super Sanjay", market: "Jewellary Industry" },
    {flag: store, title: "AROKA", market: "Fashion Industry" },
    {flag: store, title: "Kisna Store", market: "Jewellary Industry" }
]

const Domain = () => {  
  const [anchorSortEl, setAnchorSortEl] = useState(null);

  const handleSortClick = (event) => {
    setAnchorSortEl(event.currentTarget);
  };

   const handleSortClose = () => {
    setAnchorSortEl(null);
  };

  const openSort = Boolean(anchorSortEl);
  const idSort = openSort ? "simple-popover" : undefined;
  
  return (
    <>
        {data.map((item) => (
            <Paper className="border-grey-5 bg-black-13 px-4 py-4 my-4">
                <div className="row">
                    <div className="col-lg-1 d-flex align-items-center justify-content-center">
                        <img src={item.flag} width={30} height={30}/>
                    </div>
                    <div className="col-lg-9">
                        <div className="d-flex align-items-center">
                            <p className="text-lightBlue mr-2">{item.title}</p>
                        </div>
                        <small className="text-grey-6">
                            {item.market}
                        </small>
                    </div>
                    <div className="col-lg-2 d-flex justify-content-end align-items-center">
                        <div className="d-block">
                            <div 
                                className="rounded-pill d-flex py-1 px-3 me-5" 
                                style={{background: "#A6FAAF"}}
                            >
                                <small className="text-black fw-500">
                                    Active
                                </small>
                            </div>
                        </div>
                        <img 
                            src={viewMore} 
                            width={30} 
                            className="c-pointer" 
                            aria-describedby={idSort} 
                            onClick={handleSortClick}
                        />

                        <Popover
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "center",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "center",
                            }}
                            id={idSort}
                            open={openSort}
                            anchorEl={anchorSortEl}
                            onClose={handleSortClose}
                            className="columns"
                        >
                            <div className="py-2 px-2">
                            <small className="p-2 rounded-3 text-lightBlue c-pointer font2 d-block hover-back">
                                Edit
                            </small>
                            <small className="p-2 rounded-3 text-lightBlue c-pointer font2 d-block hover-back">
                                Preview
                            </small>
                            <small className="p-2 rounded-3 text-lightBlue c-pointer font2 d-block hover-back">
                                Disable
                            </small>
                            <small 
                                className="p-2 rounded-3 c-pointer font2 d-block hover-back" 
                                style={{color: "#F67E80"}}
                            >
                                Delete
                            </small>
                            </div>
                        </Popover>
                    </div>
                </div>
            </Paper>
        ))}
    </>
  );
};

export default Domain;