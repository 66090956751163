import React, { useState } from "react";
// ! COMPONENT IMPORTS
import {
  EnhancedTableHead,
  stableSort,
  getComparator,
} from "../../../../components/TableDependencies/TableDependencies";
// ! IMAGES IMPORTS
import indiaFlag from "../../../../assets/images/products/indiaFlag.svg";
import arrowDown from "../../../../assets/icons/arrowDown.svg";
import ringSmall from "../../../../assets/images/ringSmall.svg";
import arrowDownBlack from "../../../../assets/icons/arrowDownBlack.svg";
// ! MATERIAL IMPORTS
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Popover,
} from "@mui/material";
import TableEditStatusButton from "../../../../components/TableEditStatusButton/TableEditStatusButton";
import TableMassActionButton from "../../../../components/TableMassActionButton/TableMassActionButton";
import TableLoader from "../../../../components/Loader/TableLoader";
import NoDataFound from "../../../../components/NoDataFound/NoDataFound";
import moment from "moment/moment";
import AsyncImage from "../../../../components/AsyncImage/AsyncImage";

// ? TABLE STARTS HERE
function createData(oId, date, location, items, total, paymentStatus, orderStatus) {
  return { oId, date, location, items, total, paymentStatus, orderStatus };
}

const rows = [
  createData(
    "#12345",
    "Today at 09:23am",
    "Delhi, India",
    "3 Items",
    "₹ 50,000",
    "Paid",
    "Delivered"
  ),
  createData(
    "#67789",
    "Today at 09:23am",
    "Delhi, India",
    "3 Items",
    "₹ 50,000",
    "Paid",
    "Delivered"
  ),
  createData(
    "#23443",
    "Today at 09:23am",
    "Delhi, India",
    "3 Items",
    "₹ 50,000",
    "Paid",
    "Delivered"
  ),
];

const headCells = [
  {
    id: "oId",
    numeric: false,
    disablePadding: true,
    label: "Order ID",
  },
  {
    id: "date",
    numeric: false,
    disablePadding: false,
    label: "Date",
  },
  // {
  //   id: "location",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Location",
  // },
  {
    id: "items",
    numeric: false,
    disablePadding: false,
    label: "Items",
  },
  {
    id: "total",
    numeric: false,
    disablePadding: false,
    label: "Total",
  },
  {
    id: "paymentStatus",
    numeric: false,
    disablePadding: false,
    label: "Payment Status",
  },
  {
    id: "orderStatus",
    numeric: false,
    disablePadding: false,
    label: "Order Status",
  },
];
// ? TABLE ENDS HERE

const UserOrdersTable = ({
  // tabIndex = 0,
  orderList = [],
  orderLoading = false,
  hasError = false,
  totalCount = 0,
  rowsPerPage = 10,
  changeRowsPerPage = () => {},
  changePage = () => {},
  // updateOrder = () => {},
  page = 0,
}) => {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("oId");
  const [selected, setSelected] = useState([]);
  // const [page, setPage] = React.useState(0);
  // const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
  const handleChangePage = (event, newPage) => {
    changePage(newPage);
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n?._id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };
  const handleChangeRowsPerPage = (event) => {
    changeRowsPerPage(parseInt(event.target.value, 10));
    changePage(0);
  };
  const isSelected = (name) => selected.indexOf(name) !== -1;
  // * METAL FILTER POPOVERS STARTS
  const [anchorMetalFilterEl, setAnchorMetalFilterEl] = React.useState(null);
  const handleMetalFilter = (event) => {
    setAnchorMetalFilterEl(event.currentTarget);
  };
  const handleMetalFilterClose = () => {
    setAnchorMetalFilterEl(null);
  };
  const openMetalFilter = Boolean(anchorMetalFilterEl);
  const idMetalFilter = openMetalFilter ? "simple-popover" : undefined;
  // * METAL FILTER POPOVERS ENDS
  const [viewingItems, setViewingItems] = useState([]);
  const [anchorItemEl, setAnchorItemEl] = useState(null);
  const handleItemClick = (target, items) => {
    setAnchorItemEl(target);
    setViewingItems(items);
  };
  const handleItemClose = () => {
    setAnchorItemEl(null);
    setViewingItems([]);
  };

  return orderLoading ? (
    <span className="d-flex justify-content-center m-3">
      <TableLoader />
    </span>
  ) : orderList?.length === 0 || hasError ? (
    <span className="d-flex justify-content-center m-3">
      <NoDataFound />
    </span>
  ) : (
    <>
      {selected.length > 0 && (
        <div className="d-flex align-items-center px-2 mb-3">
          <button className="button-grey py-2 px-3">
            <small className="text-lightBlue">
              {selected.length} orders are selected&nbsp;
              <span
                className="text-blue-2 c-pointer"
                onClick={() => setSelected([])}>
                (Clear Selection)
              </span>
            </small>
          </button>
          <TableEditStatusButton />
          <TableMassActionButton />
        </div>
      )}
      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size="medium">
          <EnhancedTableHead
            numSelected={selected.length}
            order="asc"
            orderBy="oId"
            onSelectAllClick={(e) =>
              e.target.checked ? setSelected([...orderList]) : setSelected([])
            }
            onRequestSort={Date}
            rowCount={orderList.length}
            headCells={headCells}
          />
          <TableBody>
            {orderList.map((row) => {
              const isItemSelected = selected.includes(row);
              // console.log(row);
              return (
                <TableRow
                  hover
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row._id}
                  selected={isItemSelected}
                  className="table-rows">
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={isItemSelected}
                      onClick={(e) =>
                        setSelected((sel) =>
                          e.target.checked
                            ? sel.concat(row)
                            : sel.filter((s) => !Object.is(s, row))
                        )
                      }
                      size="small"
                      style={{ color: "#5C6D8E" }}
                    />
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    padding="none">
                    <div className="d-flex align-items-center">
                      <p className="text-lightBlue rounded-circle fw-600">{row.orderID}</p>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="d-flex align-items-center c-pointer">
                      <p className="text-lightBlue">
                        {moment(row.createdAt).format("DD-MM-YYYY [at] h:mm a")}
                      </p>
                    </div>
                  </TableCell>
                  {/* <TableCell>
                    <div className="d-flex align-items-center">
                      <img
                        src={indiaFlag}
                        alt="indiaFlag"
                        className="me-2"
                        width={18}
                      />
                      <p className="text-lightBlue">{row.location}</p>
                    </div>
                  </TableCell> */}
                  <TableCell>
                    <div className="d-flex align-items-center">
                      <p className="text-lightBlue">{row.items?.length}</p>
                      <img
                        onClick={(e) => handleItemClick(e.currentTarget, row.items)}
                        src={arrowDown}
                        alt="arrowDown"
                        className="c-pointer ms-3"
                      />
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="d-flex c-pointer">
                      <p className="text-lightBlue">
                        ₹{row.items.reduce((t, it) => t + it.item.price, 0)}
                      </p>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="d-flex align-items-center">
                      <div
                        className="rounded-pill d-flex table-status px-2 py-1 c-pointer"
                        style={{
                          background:
                            row?.paymentStatus === "success"
                              ? "#A6FAAF"
                              : row?.paymentStatus === "failure"
                              ? "#FAA6A6"
                              : row?.paymentStatus === "pending"
                              ? "#FAE7A6"
                              : "#C8D8FF",
                        }}>
                        <small className="text-black fw-400">{row.paymentStatus}</small>
                        {/* <img
                          src={arrowDownBlack}
                          alt="arrowDownBlack"
                          className="ms-2"
                        /> */}
                      </div>
                      {/* <Popover
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        id={idMetalFilter}
                        open={openMetalFilter}
                        anchorEl={anchorMetalFilterEl}
                        onClose={handleMetalFilterClose}>
                        <div className="py-2 px-1">
                          <small className="text-lightBlue rounded-3 p-2 hover-back d-block">
                            Draft
                          </small>
                          <small className="text-lightBlue rounded-3 p-2 hover-back d-block">
                            Archive
                          </small>
                        </div>
                      </Popover> */}
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="d-flex align-items-center">
                      <div className="rounded-pill d-flex table-status px-2 py-1 c-pointer">
                        <small className="text-black fw-400">{row.status}</small>
                        {/* <img
                          src={arrowDownBlack}
                          alt="arrowDownBlack"
                          className="ms-2"
                        /> */}
                      </div>
                      {/* <Popover
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        id={idMetalFilter}
                        open={openMetalFilter}
                        anchorEl={anchorMetalFilterEl}
                        onClose={handleMetalFilterClose}>
                        <div className="py-2 px-1">
                          <small className="text-lightBlue rounded-3 p-2 hover-back d-block">
                            Draft
                          </small>
                          <small className="text-lightBlue rounded-3 p-2 hover-back d-block">
                            Archive
                          </small>
                        </div>
                      </Popover> */}
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        component="div"
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        onPageChange={changePage}
        onRowsPerPageChange={changeRowsPerPage}
        className="table-pagination"
      />
      <Popover
        open={!!viewingItems.length}
        anchorEl={anchorItemEl}
        onClose={handleItemClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}>
        <Table size="small">
          <TableBody>
            {viewingItems.map((item) => {
              const imageUrl =
                item?.item?.product?.mediaUrl?.find((mu) => mu.isDefault)?.image ?? "";
              return (
                <TableRow key={item._id}>
                  <TableCell
                    padding="normal"
                    className="ps-1 pe-3">
                    <div className="row">
                      <div className="col-auto">
                        <AsyncImage
                          src={imageUrl}
                          placeholder={ringSmall}
                          alt="product"
                          height={40}
                          width={40}
                        />
                      </div>
                      <div className="col px-1">
                        <p className="text-lightBlue">{item?.item?.productName}</p>
                        <p className="text-grey-6 font0">₹ {item?.item?.price}</p>
                      </div>
                    </div>
                  </TableCell>
                  <TableCell padding="normal">
                    <div className="w-auto">
                      <small className="text-grey-6">
                        SNo:&nbsp;
                        <small className="text-blue-2">{item?.checkoutID}</small>
                      </small>
                      <br />
                      <small className="text-grey-6">
                        SKU:&nbsp;
                        <small className="text-blue-2">
                          {item?.item?.product?.inventory?.skuId}
                        </small>
                      </small>
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Popover>
    </>
  );
};

export default UserOrdersTable;
