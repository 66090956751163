import { forwardRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  OutlinedInput,
  Slide,
} from "@mui/material";
import { showError, showSuccess } from "../../../features/snackbar/snackbarAction";
import cancel from "../../../assets/icons/cancel.svg";
import { LoadingButton } from "@mui/lab";
import { useCreateSizeChartMutation } from "../../../features/functionality/sizeChart/sizeChartApiSlice";

const Transition = forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="up"
      ref={ref}
      {...props}
    />
  );
});

export default function DuplicateDialog({ chart = {}, onClose = () => {} }) {
  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={!!chart?._id}
      onClose={onClose}
      TransitionComponent={Transition}>
      <Content
        chart={chart}
        onClose={onClose}
      />
    </Dialog>
  );
}

function Content({ chart = {}, onClose = () => {} }) {
  const {
    name,
    status,
    sizeChartFor,
    sizeChartType,
    sizeChartImage,
    sizeChartOnCompany,
    addProductType,
    products,
  } = chart ?? {};
  const [sizeChart, setSizeChart] = useState({
    name: `${name} Copy`,
    status,
    sizeChartFor,
    sizeChartType,
    sizeChartImage,
    sizeChartOnCompany,
    addProductType,
    products,
  });
  const [copyChart, setCopyChart] = useState(true);
  const [copyProduct, setCopyProduct] = useState(true);
  const [createSizeChart, { isLoading: createSizeChartIsLoading }] =
    useCreateSizeChartMutation();
  const dispatch = useDispatch();
  return (
    <>
      <DialogTitle>
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex flex-column ">
            <h4 className="text-lightBlue fw-500">Duplicate Size Chart</h4>
            {/* <small className="text-grey-6 mt-2">Size Chart Button</small> */}
          </div>
          <button
            className="reset"
            onClick={onClose}>
            <img
              src={cancel}
              alt="cancel"
              width={30}
              className="c-pointer"
            />
          </button>
        </div>
      </DialogTitle>
      <hr className="hr-grey-6 my-0" />
      <DialogContent>
        <div className="row align-items-start">
          <div className="col-12">
            <div className="mb-1">
              <span className="text-lightBlue fw-400">Title</span>
            </div>
            <FormControl
              size="small"
              fullWidth
              className="">
              <OutlinedInput
                value={sizeChart.name}
                onChange={(e) =>
                  setSizeChart((sc) => ({ ...sc, name: e.target.value }))
                }
                placeholder="Size Chart Name"
              />
            </FormControl>
          </div>
        </div>
      </DialogContent>
      <hr className="hr-grey-6 my-0" />
      <DialogContent>
        <div className="row align-items-start">
          <div className="col-12">
            <div className="mb-3">
              <h5 className="text-lightBlue fw-500">
                What to include in this Duplicate
              </h5>
            </div>
            <FormGroup
              onChange={(e) => {
                // dispatchQueryFilters({ type: "SET_FILTER", checked: e.target.checked, value: e.target.value });
                switch (e.target.value) {
                  case "chart":
                    setCopyChart(e.target.checked);
                    if (e.target.checked) {
                      setSizeChart((sc) => ({
                        ...sc,
                        sizeChartType,
                        sizeChartImage,
                        sizeChartOnCompany,
                      }));
                    } else {
                      setSizeChart((sc) => ({
                        ...sc,
                        sizeChartType: undefined,
                        sizeChartImage: undefined,
                        sizeChartOnCompany: undefined,
                      }));
                    }
                    break;
                  case "product":
                    setCopyProduct(e.target.checked);
                    if (e.target.checked) {
                      setSizeChart((sc) => ({ ...sc, addProductType, products }));
                    } else {
                      setSizeChart((sc) => ({
                        ...sc,
                        addProductType: undefined,
                        products: undefined,
                      }));
                    }
                    break;

                  default:
                    break;
                }
              }}>
              <FormControlLabel
                label="The Chart"
                value="chart"
                className="text-lightBlue"
                checked={copyChart}
                control={<Checkbox size="small" />}
              />
              <FormControlLabel
                label="The Products"
                value="product"
                className="text-lightBlue"
                checked={copyProduct}
                control={<Checkbox size="small" />}
              />
            </FormGroup>
          </div>
        </div>
      </DialogContent>
      <DialogActions className="d-flex justify-content-between px-4 py-3">
        <button
          onClick={onClose}
          className="button-grey-outline py-2 px-4"
          style={{ lineHeight: 1.6 }}>
          <p className="text-lightBlue">Cancel</p>
        </button>
        <LoadingButton
          loading={createSizeChartIsLoading}
          disabled={createSizeChartIsLoading}
          onClick={() => {
            // console.log(JSON.stringify(sizeChart, null, 1));
            createSizeChart(sizeChart)
              .unwrap()
              .then(() => {
                onClose();
                dispatch(showSuccess({ message: "Size Chart created successfully" }));
              })
              .catch((e) =>
                dispatch(
                  showError({ message: e?.data?.message ?? "Something went wrong" })
                )
              );
          }}
          className="button-gradient py-2 px-4"
          style={{
            lineHeight: 1.6,
            textTransform: "Capitalize",
          }}>
          <p>Save</p>
        </LoadingButton>
      </DialogActions>
    </>
  );
}
