import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import _ from "lodash";
import { useFormik } from "formik";
// ! COMPONENT IMPORTS
import AddProductCondition from "../../../components/AddProductCondition/AddProductCondition";
import { AntSwitch } from "../../../components/AntSwitch/AntSwitch";
import { AppReactLivePreview } from "../../../components/AppReactImageGallery/AppReactImageGallery";
// ! IMAGES IMPORTS
import arrowLeft from "../../../assets/icons/arrowLeft.svg";
import info from "../../../assets/icons/info.svg";
// import paginationRight from "../../../assets/icons/paginationRight.svg";
// import paginationLeft from "../../../assets/icons/paginationLeft.svg";
import textAdd from "../../../assets/icons/text.svg";
import imageAdd from "../../../assets/icons/imageAdd.svg";
// import uploadBadge from "../../../assets/icons/uploadBadge.svg";
// ! MATERIAL IMPORTS
import {
  FormControl,
  Tooltip,
  ToggleButton,
  ToggleButtonGroup,
  OutlinedInput,
  TextField,
  FormHelperText,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useDispatch } from "react-redux";
import {
  useEditLabelsBadgesMutation,
  useGetAllLabelsBadgessQuery,
} from "../../../features/functionality/labelsBadges/labelsBadges";
import {
  showError,
  showSuccess,
} from "../../../features/snackbar/snackbarAction";
import { UploadMediaLarge } from "../../../components/UploadMediaBox/UploadMedia";
import { LoadingButton } from "@mui/lab";
import { DiscardModalSecondary } from "../../../components/Discard/DiscardModal";
import ColorInput from "../../../components/ColorInput/ColorInput";
import moment from "moment";
import { useGetAllLabelsBadgesSettingsQuery } from "../../../features/functionality/labelsBadgesSetting/labelsBadgesSettingApiSlice";
import { useGetAllProductsQuery } from "../../../features/products/product/productApiSlice";
import { StatusBoxSecondary } from "../../../components/StatusBox/StatusBox";

const filterSchema = Yup.object().shape({
  type: Yup.string().required("Type is required"),
  operator: Yup.string().when(["type"], ([type], schema) => {
    return type === "allProducts" ? schema : schema.required("required");
  }),
  value: Yup.array().when(["type"], ([type], schema) => {
    return type === "allProducts"
      ? schema
      : schema.required("required").min(1, "At least one value is required");
  }),
});

const validationSchema = Yup.object({
  type: Yup.string().oneOf(["label", "badge"]).required("required"),
  name: Yup.string().trim().required("required"),
  status: Yup.string()
    .oneOf(["scheduled", "active", "in-active"])
    .required("required"),
  contentType: Yup.string().oneOf(["text", "image"]).required("required"),
  image: Yup.string().when("contentType", {
    is: "image",
    then: (schema) => schema.required("required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  text: Yup.string().when("contentType", {
    is: "text",
    then: (schema) => schema.required("required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  scheduleVisibility: Yup.boolean().default(false),
  startDate: Yup.date().when("status", {
    is: "scheduled",
    then: (schema) => schema.required("required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  endDate: Yup.date().when(
    ["status", "startDate"],
    ([status, startDate], schema) => {
      if (status === "scheduled" && startDate) {
        return schema.min(
          startDate,
          "End Date must be greater than Start Date"
        );
      }
    }
  ),
  color: Yup.object({
    backgroundColor: Yup.string(),
    textColor: Yup.string(),
  }),
  addProductType: Yup.string()
    .oneOf(["automated", "manual"])
    .required("required"),
  products: Yup.array().of(Yup.string()), //.min(1, "Min 1 Product Required"),

  filters: Yup.mixed().when(["addProductType"], ([addProductType], schema) => {
    if (addProductType === "automated") {
      return Yup.array().of(filterSchema);
    }
    return schema;
  }),
});

const EditLabels = () => {
  const { sid = "" } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [discardable, setDiscardable] = useState(false);
  const onDiscarded = () => navigate(-1);

  const [editLabelsBadges, { isLoading: editLabelsBadgesIsLoading }] =
    useEditLabelsBadgesMutation();
  const { data: labelsBadgesData, isSuccess: labelsBadgesIsSuccess } =
    useGetAllLabelsBadgessQuery({ id: sid });

  const {
    type = "",
    name = "",
    status = "active",
    contentType = "text",
    image = "",
    text = "",
    startDate = "",
    endDate = "",
    color: { backgroundColor = "#0e0d21", textColor = "#c8d8ff" },
    addProductType = "automated",
    products = [],
    filters,
  } = labelsBadgesData?.data?.data?.[0] ?? { color: {} };

  const { data: labelsBadgesSettingData } = useGetAllLabelsBadgesSettingsQuery(
    {},
    { skip: type !== "label" }
  );
  const labelSettings = labelsBadgesSettingData?.data?.data?.[0] ?? null;

  const itemString =
    (type?.at(0)?.toUpperCase() ?? "") + (type?.slice(1) ?? "");

  // const productsList = useMemo(
  //   () =>
  //     products?.map((e) => {
  //       // const product = productsData?.data?.find((el) => el?._id === e);
  //       return {
  //         product: e,
  //         collection: ["products"],
  //         type: "add",
  //       };
  //     }) || [],
  //   [products]
  // );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      type,
      name,
      status,
      contentType,
      image,
      text,
      scheduleVisibility: status === "scheduled",
      startDate,
      endDate,
      color: {
        backgroundColor,
        textColor,
      },
      addProductType,
      products: products,
      filters: filters || [
        {
          type: "",
          operator: "",
          value: [],
          dropDownData: [],
        },
      ],
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (values.contentType === "text") delete values["image"];
      if (values.contentType === "image") delete values["text"];
      if (values.status !== "scheduled") {
        delete values["startDate"];
        delete values["endDate"];
      } else if (!values.endDate) delete values["endDate"];
      if (!values.color.backgroundColor) delete values.color["backgroundColor"];
      if (!values.color.textColor) delete values.color["textColor"];
      // const productsVal = {
      //   ...values,
      //   products: values.products.map((e) => e?.product),
      // };
      editLabelsBadges({ id: sid, details: values })
        .unwrap()
        .then(() => {
          setDiscardable(false);
          onDiscarded();
          dispatch(
            showSuccess({
              message: itemString + " updated successfully",
            })
          );
        })
        .catch((e) =>
          dispatch(
            showError({ message: e?.data?.message ?? "Something went wrong" })
          )
        );
    },
  });

  // console.log(
  //   JSON.stringify(
  //     {
  //       values: formik.values,
  //       errors: formik.errors,
  //       touched: formik.touched,
  //     },
  //     null,
  //     1
  //   )
  // );

  useEffect(() => {
    const check = _.isEqual(formik.values, formik.initialValues);
    setDiscardable(!check);
  }, [formik.initialValues, formik.values]);

  const uploadImage = useCallback(
    (value) => {
      formik.setFieldTouched("image", true);
      !!value && formik.setFieldValue("image", value ?? "");
    },
    // eslint-disable-next-line
    []
  );

  const statusHandler = (status) => {
    formik.setFieldValue("status", status);
    formik.setFieldValue("startDate", "");
    formik.setFieldValue("endDate", "");
  };

  const scheduleProductHandler = ({ startDate, endDate }) => {
    formik.setFieldValue("startDate", startDate);
    formik.setFieldValue("endDate", endDate);
    formik.setFieldValue("status", "scheduled");
  };

  return (
    <div className="page container-fluid position-relative">
      <div className="row justify-content-between">
        <div className="d-flex align-items-center w-auto ps-0">
          <Link to="/functionality/LabelsBadges" className="d-flex">
            <img
              src={arrowLeft}
              alt="arrowLeft"
              width={9}
              className="c-pointer"
            />
          </Link>

          <h5 className="page-heading ms-2 ps-1">Edit {itemString}</h5>
        </div>
        {/* <div className="d-flex align-items-center w-auto pe-0">
          <button className="button-transparent me-1 py-2 px-3">
            <p className="text-lightBlue">Duplicate</p>
          </button>
          <button className="button-transparent me-1 py-2 px-3">
            <p className="text-lightBlue">Preview</p>
          </button>
          <img
            src={paginationLeft}
            alt="paginationLeft"
            className="c-pointer"
            width={30}
          />
          <img
            src={paginationRight}
            alt="paginationRight"
            className="c-pointer"
            width={30}
          />
        </div> */}
      </div>
      {labelsBadgesIsSuccess && (
        <form noValidate onSubmit={formik.handleSubmit}>
          <div className="row">
            <div className="col-lg-9 mt-3">
              <div className="bg-black-15 border-grey-5 rounded-8 p-3 row productInfo">
                <div className="col-12 px-0">
                  <div className="row">
                    <div className="col-8">
                      <div className="d-flex mb-1">
                        <p className="text-lightBlue px-0 me-2">
                          {itemString} Name
                        </p>
                        <Tooltip title="Lorem ipsum" placement="top">
                          <img
                            src={info}
                            alt="info"
                            className="c-pointer"
                            width={13.5}
                          />
                        </Tooltip>
                      </div>
                      <FormControl className="w-100 px-0">
                        <OutlinedInput
                          placeholder="Enter Label Name"
                          size="small"
                          name="name"
                          value={formik.values.name}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        <FormHelperText error>
                          {formik.touched.name && formik.errors.name}
                        </FormHelperText>
                      </FormControl>
                      <small className="mt-1 text-grey-6 font1">
                        Note: User can't see this, its for your reference
                      </small>
                    </div>
                    <div className="col-4">
                      <StatusBoxSecondary
                        title="status"
                        status={formik.values.status}
                        onStatus={statusHandler}
                        startDate={formik.values.startDate}
                        endDate={formik.values.endDate}
                        onSchedule={scheduleProductHandler}
                        containerStyles="status-container"
                        titleStyles="status-title"
                        actionStyles="status-action"
                        activeText="active"
                        inActiveText="in-active"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-black-15 border-grey-5 rounded-8 p-3 row mt-4">
                <div className="col-12 px-0 d-flex align-items-center">
                  <h6 className="text-lightBlue me-2 text-lightBlue fw-500">
                    {itemString} Content
                  </h6>
                  <Tooltip title="Lorem ipsum" placement="top">
                    <img
                      src={info}
                      alt="info"
                      className="c-pointer"
                      width={13.5}
                    />
                  </Tooltip>
                </div>

                <p className="text-lightBlue px-0 me-2 mt-3">Type</p>
                <ToggleButtonGroup
                  exclusive
                  value={formik.values.contentType}
                  onChange={(_, contentType) => {
                    formik.setFieldTouched("contentType", true);
                    formik.setFieldValue("contentType", contentType);
                    formik.setFieldTouched("image", false);
                    formik.setFieldValue("image", "");
                    formik.setFieldTouched("text", false);
                    formik.setFieldValue("text", "");
                  }}
                  aria-label="text alignment"
                  className="productDetails-toggle px-0 mt-2"
                >
                  <ToggleButton value="image" aria-label="image">
                    <div className="d-flex">
                      <img src={imageAdd} alt="imageAdd" width={25} />
                      <div className="ms-3 text-start">
                        <p className="text-lightBlue">Image</p>
                        <small className="text-grey-6 d-block">
                          Add Image {itemString}
                        </small>
                      </div>
                    </div>
                  </ToggleButton>
                  <ToggleButton value="text" aria-label="text">
                    <div className="d-flex">
                      <img src={textAdd} alt="text" width={25} />
                      <div className="ms-3 text-start">
                        <p className="text-lightBlue">Text</p>
                        <small className="text-grey-6 d-block">
                          Add custom text {itemString}
                        </small>
                      </div>
                    </div>
                  </ToggleButton>
                </ToggleButtonGroup>

                {formik.values.contentType === "image" && (
                  <div className="col-12 px-0 mt-3 mb-1">
                    <UploadMediaLarge
                      fileSrc={formik.values.image}
                      error={formik.errors.image}
                      onUpload={uploadImage}
                      onBlur={formik.handleBlur}
                      name="image"
                      module="sizeChart"
                      isSubmitting={formik.isSubmitting}
                      touched={!!formik.touched.image}
                    />
                    <FormHelperText error>
                      {formik.touched.image && formik.errors.image}
                    </FormHelperText>
                  </div>
                )}

                {formik.values.contentType === "text" && (
                  <>
                    <div className="d-flex col-12 px-0 mt-3 justify-content-between mb-1">
                      <div className="d-flex align-items-center">
                        <p className="text-lightBlue me-auto">Enter Text</p>
                      </div>
                      <p className="text-blue-2">+ Add Variables</p>
                    </div>
                    <FormControl className="w-100 px-0">
                      <OutlinedInput
                        placeholder="Enter Text"
                        size="small"
                        name="text"
                        value={formik.values.text}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <FormHelperText error>
                        {formik.touched.text && formik.errors.text}
                      </FormHelperText>
                    </FormControl>

                    <div className="row align-items-center my-3">
                      <div className="col-3">
                        <h6 className="text-lightBlue fw-500">
                          {itemString} Color
                        </h6>
                      </div>
                      <div className="col-9">
                        <div className="row align-items-center">
                          <div className="col-6">
                            <div className="d-flex mb-1">
                              <p className="text-lightBlue me-2">Background</p>
                              <Tooltip title="Lorem ipsum" placement="top">
                                <img
                                  src={info}
                                  alt="info"
                                  className="c-pointer"
                                  width={13.5}
                                />
                              </Tooltip>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="d-flex mb-1">
                              <p className="text-lightBlue me-2">Text</p>
                              <Tooltip title="Lorem ipsum" placement="top">
                                <img
                                  src={info}
                                  alt="info"
                                  className="c-pointer"
                                  width={13.5}
                                />
                              </Tooltip>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6">
                            <FormControl className="w-100 px-0">
                              <ColorInput
                                inputProps={{
                                  name: "color.backgroundColor",
                                  value: formik.values.color?.backgroundColor,
                                  onChange: formik.handleChange,
                                  onBlur: formik.handleBlur,
                                }}
                              />
                            </FormControl>
                          </div>
                          <div className="col-6">
                            <FormControl className="w-100 px-0">
                              <ColorInput
                                inputProps={{
                                  name: "color.textColor",
                                  value: formik.values.color?.textColor,
                                  onChange: formik.handleChange,
                                  onBlur: formik.handleBlur,
                                }}
                              />
                            </FormControl>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
              {/* <div className="bg-black-15 border-grey-5 rounded-8 p-3 row mt-4">
            <div className="col-12 px-0 d-flex align-items-center">
              <h6 className="text-lightBlue me-2 text-lightBlue fw-500">Label Content</h6>
              <Tooltip
                title="Lorem ipsum"
                placement="top">
                <img
                  src={info}
                  alt="info"
                  className="c-pointer me-3"
                  width={13.5}
                />
              </Tooltip>

              <ToggleButtonGroup
                exclusive
                aria-label="text alignment"
                className="productDetails-toggle">
                <ToggleButton
                  value="desktopView"
                  aria-label="desktopView">
                  <small className="text-capitalize text-lightBlue">Desktop</small>
                </ToggleButton>
                <ToggleButton
                  value="mobileView"
                  aria-label="mobileView">
                  <small className="text-capitalize text-lightBlue">Mobile</small>
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
            <div className="col-12 px-0">
              <div className="row">
                <div className="col-md-3 mt-3">
                  <h6 className="text-lightBlue fw-500">Label Color</h6>
                </div>
                <div className="col-md-9 mt-3">
                  {/* <div className="row">
                    <div className="col-6"></div>
                    <div className="col-6"></div>
                  </div>
                  <div className="row">
                    <div className="col-auto">
                      <div className="d-flex mb-1">
                        <p className="text-lightBlue me-2">Background</p>
                        <Tooltip
                          title="Lorem ipsum"
                          placement="top">
                          <img
                            src={info}
                            alt="info"
                            className="c-pointer"
                            width={13.5}
                          />
                        </Tooltip>
                      </div>
                      <ColorPicker
                        height={150}
                        width={240}
                        // color={backgroundColor}
                        // onChange={setBackgroundColor}
                        hideHSV
                        dark
                        className="w-100"
                      />
                    </div>
                    <div className="col-auto">
                      <div className="d-flex mb-1">
                        <p className="text-lightBlue me-2">Text</p>
                        <Tooltip
                          title="Lorem ipsum"
                          placement="top">
                          <img
                            src={info}
                            alt="info"
                            className="c-pointer"
                            width={13.5}
                          />
                        </Tooltip>
                      </div>
                      <ColorPicker
                        height={150}
                        width={240}
                        // color={textColor}
                        // onChange={setTextColor}
                        hideHSV
                        dark
                        className="w-100"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3 mt-3">
                  <h6 className="text-lightBlue fw-500">Label Position</h6>
                </div>
                <div className="col-md-9 mt-3">
                  <div className="row">
                    <div className="col-12">
                      <ToggleButtonGroup
                        // value={labelPosition}
                        exclusive
                        // onChange={handleLabelPosition}
                        aria-label="text alignment"
                        className="productDetails-toggle">
                        <ToggleButton
                          value="topLeft"
                          aria-label="topLeft">
                          <img
                            src={positionTopLeft}
                            alt="positionTopLeft"
                            className="w-100"
                          />
                        </ToggleButton>
                        <ToggleButton
                          value="topRight"
                          aria-label="topRight">
                          <img
                            src={positionTopRight}
                            alt="positionTopRight"
                            className="w-100"
                          />
                        </ToggleButton>
                        <ToggleButton
                          value="bottomLeft"
                          aria-label="bottomLeft">
                          <img
                            src={positionBottomLeft}
                            alt="positionBottomLeft"
                            className="w-100"
                          />
                        </ToggleButton>
                        <ToggleButton
                          value="bottomRight"
                          aria-label="bottomRight">
                          <img
                            src={positionBottomRight}
                            alt="positionBottomRight"
                            className="w-100"
                          />
                        </ToggleButton>
                        <ToggleButton
                          value="centerLeft"
                          aria-label="centerLeft">
                          <img
                            src={positionCenterLeft}
                            alt="positionCenterLeft"
                            className="w-100"
                          />
                        </ToggleButton>
                        <ToggleButton
                          value="centerRight"
                          aria-label="centerRight">
                          <img
                            src={positionCenterRight}
                            alt="positionCenterRight"
                            className="w-100"
                          />
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-3 mt-3">
                  <h6 className="text-lightBlue fw-500">Label Color</h6>
                </div>
                <div className="col-md-9 mt-3">
                  <div className="row">
                    <div className="col-6">
                      <div className="d-flex mb-1">
                        <p className="text-lightBlue me-2">Top</p>
                        <Tooltip
                          title="Lorem ipsum"
                          placement="top">
                          <img
                            src={info}
                            alt="info"
                            className="c-pointer"
                            width={13.5}
                          />
                        </Tooltip>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="d-flex mb-1">
                        <p className="text-lightBlue me-2">Bottom</p>
                        <Tooltip
                          title="Lorem ipsum"
                          placement="top">
                          <img
                            src={info}
                            alt="info"
                            className="c-pointer"
                            width={13.5}
                          />
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <FormControl className="w-100 px-0">
                        <OutlinedInput
                          placeholder="Enter Top"
                          size="small"
                        />
                      </FormControl>
                    </div>
                    <div className="col-6">
                      <FormControl className="w-100 px-0">
                        <OutlinedInput
                          placeholder="Enter Bottom"
                          size="small"
                        />
                      </FormControl>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3 mt-3">
                  <h6 className="text-lightBlue fw-500">Label Position</h6>
                </div>
                <div className="col-md-9 mt-3">
                  <div className="row">
                    <div className="col-12">
                      <ToggleButtonGroup
                        // value={labelSize}
                        exclusive
                        // onChange={handleLabelSize}
                        aria-label="text alignment"
                        className="productDetails-toggle">
                        <ToggleButton
                          value="small"
                          aria-label="small">
                          <small className="text-lightBlue">Small</small>
                        </ToggleButton>
                        <ToggleButton
                          value="medium"
                          aria-label="medium">
                          <small className="text-lightBlue">Medium</small>
                        </ToggleButton>
                        <ToggleButton
                          value="large"
                          aria-label="large">
                          <small className="text-lightBlue">Large</small>
                        </ToggleButton>
                        <ToggleButton
                          value="extraLarge"
                          aria-label="extraLarge">
                          <small className="text-lightBlue">Extra Large</small>
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
              <AddProductCondition formik={formik} />
            </div>
            <div className="col-lg-3 mt-3 pe-0 ps-0 ps-lg-3">
              <div className="bg-black-15 border-grey-5 rounded-8 p-3">
                <h6 className="text-grey-6 mb-3">Preview:</h6>
                <AppReactLivePreview
                  values={formik.values}
                  labelSettings={labelSettings}
                />
                <p className="mt-3 text-lightBlue">The Fringe Diamond Ring</p>
                <small className="text-grey-6 my-2">
                  SKU123456&nbsp;|&nbsp;JWLellers
                </small>
                <div>
                  <small className="text-grey-6">Diamond Ring</small>
                </div>
              </div>
            </div>
          </div>
          <div className="row bottom-buttons pt-5 pb-3 justify-content-between">
            <div className="d-flex w-auto px-0">
              {/* <Link
            to="/products/allProducts"
            className="button-red-outline py-2 px-4">
            <p>Discard</p>
          </Link> */}
              <button
                type="button"
                onClick={onDiscarded}
                className="button-red-outline py-2 px-4"
              >
                <p>Discard</p>
              </button>

              {/* <Link
            to="/products/allProducts"
            className="button-lightBlue-outline py-2 px-4 ms-3">
            <p>Save as Draft</p>
          </Link> */}
            </div>

            {/* {value === 6 ? (
          <Link
            to="/products/allProducts"
            className="button-gradient py-2 px-4 w-auto"
          >
            <p>Save</p>
          </Link>
        ) : ( */}
            <LoadingButton
              type="submit"
              loading={editLabelsBadgesIsLoading}
              disabled={editLabelsBadgesIsLoading}
              className="button-gradient ms-3 py-2 px-4 w-auto"
            >
              <p>Save</p>
            </LoadingButton>
            {/* <button className="button-gradient py-2 px-4 w-auto">
          <p>Continue</p>
        </button> */}
            {/* )} */}
          </div>
          <DiscardModalSecondary when={discardable} message="size chart tab" />
        </form>
      )}
    </div>
  );
};

export default EditLabels;
