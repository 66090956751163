import apiSlice from "../../../app/api/apiSlice";
import { omitEmptyKeys } from "../../../utils/helper";

export const taxmanagerApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getGlobalGST: builder.query({
      query: (queries = {}) => ({
        url: "/globalGST",
        params: omitEmptyKeys(queries),
      }),
      providesTags: ["TaxManager"],
    }),

    createOrUpdateGlobalGST: builder.mutation({
      query: (data) => ({
        url: "/globalGST",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["TaxManager", "Products", "Variants", "Logs"],
    }),

    deleteGlobalGST: builder.mutation({
      query: (id) => ({
        url: `/globalGST/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["TaxManager", "Products", "Variants", "Logs"],
    }),
  }),
});

export const {
  useGetGlobalGSTQuery,
  useCreateOrUpdateGlobalGSTMutation,
  useDeleteGlobalGSTMutation,
} = taxmanagerApiSlice;
