import apiSlice from "../../app/api/apiSlice";
import { omitEmptyKeys } from "../../utils/helper";

export const notificationsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getNotifications: builder.query({
      query: (queries) => ({
        url: "/notifications",
        params: omitEmptyKeys(queries),
      }),
      providesTags: ["Notifications"],
      keepUnusedDataFor: 0,
      transformResponse: (res) => res.data,
    }),

    getNotificationCount: builder.query({
      query: () => ({ url: "/notifications/count" }),
      providesTags: ["NotificationCount"],
      keepUnusedDataFor: 0,
      transformResponse: (res) => res.data,
    }),

    updateNotification: builder.mutation({
      query: ({ id, details }) => ({
        url: `/notifications/${id}`,
        method: "PUT",
        body: details,
      }),
      invalidatesTags: ["Notifications", "NotificationCount", "Logs"],
    }),

    updateManyNotifications: builder.mutation({
      query: (updates) => ({
        url: "/notifications/bulkupdate",
        method: "PUT",
        body: updates,
      }),
      invalidatesTags: ["Notifications", "NotificationCount", "Logs"],
    }),
  }),
});

export const {
  useGetNotificationsQuery,
  useGetNotificationCountQuery,
  useUpdateNotificationMutation,
  useUpdateManyNotificationsMutation,
} = notificationsApiSlice;
