import { useCallback, useState } from "react";
import { Link } from "react-router-dom";
// ! COMPONENT IMPORTS
// import AppCountrySelect from "../../../components/AppCountrySelect/AppCountrySelect";
// import ProductDrawerTable from "../ProductDrawerTable";
import DeleteIcon from "@mui/icons-material/Delete";
import { EnhancedTableHead } from "../../../components/TableDependencies/TableDependencies";
// ! IMAGES IMPORTS
// import teamMember1 from "../../../assets/images/products/teamMember1.svg";
// import teamMember2 from "../../../assets/images/products/teamMember2.svg";
// import teamMember3 from "../../../assets/images/products/teamMember3.svg";
// import cancel from "../../../assets/icons/cancel.svg";
import verticalDots from "../../../assets/icons/verticalDots.svg";
// import ringSmall from "../../../assets/images/ringSmall.svg";
import ringSmall from "../../../assets/images/Components/collection.svg";
import arrowDown from "../../../assets/icons/arrowDown.svg";
// import activity from "../../../assets/icons/activity.svg";
// import editButton from "../../../assets/icons/editButton.svg";
// import duplicateButton from "../../../assets/icons/duplicateButton.svg";
import deleteRed from "../../../assets/icons/delete.svg";
// import products from "../../../assets/icons/sidenav/products.svg";
// import product2 from "../../../assets/images/products/product2.jpg";
// ! MATERIAL IMPORTS
import {
  Checkbox,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Tooltip,
  FormControl,
  DialogContent,
  DialogActions,
  Dialog,
  DialogTitle,
  FormHelperText,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
// import { DesktopDateTimePicker } from "@mui/x-date-pickers";
// ! MATERIAL ICONS IMPORTS
import InventoryIcon from "@mui/icons-material/Inventory";
// import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
// import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
// import TableSearch from "../../../components/TableSearch/TableSearch";
// import UnArchivedModal from "../../../components/UnArchivedModal/UnArchivedModal";
import { useDispatch } from "react-redux";
import { showError, showSuccess } from "../../../features/snackbar/snackbarAction";
import unArchived from "../../../assets/images/Components/Archived.png";
// import closeModal from "../../../assets/icons/closeModal.svg";
import TableEditStatusButton from "../../../components/TableEditStatusButton/TableEditStatusButton";
import TableMassActionButton from "../../../components/TableMassActionButton/TableMassActionButton";
import {
  ArchiveModalSecondary,
  MultipleArchiveModal,
} from "../../../components/ArchiveModal/ArchiveModal";
import UnArchiveModal, {
  MultipleUnArchiveModal,
} from "../../../components/UnArchiveModal/UnArchiveModal";
import moment from "moment";
import AsyncImage from "../../../components/AsyncImage/AsyncImage";
import {
  useBulkDeleteProductMutation,
  useBulkEditProductMutation,
  useDeleteProductMutation,
  useUpdateProductMutation,
  useUpdateSelectedDiscountMutation,
} from "../../../features/products/product/productApiSlice";
import { DeleteModalSecondary } from "../../../components/DeleteModal/DeleteModal";
import AddRemoveTags from "./AddRemoveTags";
import AddRemoveCollections from "./AddRemoveCollections";
import TableLoader from "../../../components/Loader/TableLoader";
import NoDataFound from "../../../components/NoDataFound/NoDataFound";
import DuplicateDialog from "./ProductDuplicateDialog";
import { useFormik } from "formik";
import * as Yup from "yup";
import { rupeeFormatWithSymbol } from "../../../utils/format";
import BulkLabelsBadgesDialog from "./BulkLabelsBadgesDialog";

const discountValueSchema = Yup.object().shape({
  discount: Yup.number()
    .typeError("Type should be number")
    .min(1, "Discount value must be greater than 0")
    .required("Discount value is required"),
  type: Yup.string()
    .oneOf(["percentage", "fixed"])
    .required("Type must be percentage or fixed"),
  // value: Yup.string().required("Value is required"),
});

// const activityData = [
//   {
//     id: 1,
//     dateAndTime: "25 July, 2022 at 12:00 pm",
//     user: "Saniya Shaikh",
//     activity: "Changed Images",
//   },
//   {
//     id: 2,
//     dateAndTime: "25 July, 2022 at 12:00 pm",
//     user: "Saniya Shaikh",
//     activity: "Changed Images",
//   },
//   {
//     id: 3,
//     dateAndTime: "25 July, 2022 at 12:00 pm",
//     user: "Saniya Shaikh",
//     activity: "Changed Images",
//   },
//   {
//     id: 4,
//     dateAndTime: "25 July, 2022 at 12:00 pm",
//     user: "Saniya Shaikh",
//     activity: "Changed Images",
//   },
//   {
//     id: 5,
//     dateAndTime: "25 July, 2022 at 12:00 pm",
//     user: "Saniya Shaikh",
//     activity: "Changed Images",
//   },
//   {
//     id: 6,
//     dateAndTime: "25 July, 2022 at 12:00 pm",
//     user: "Saniya Shaikh",
//     activity: "Changed Images",
//   },
//   {
//     id: 7,
//     dateAndTime: "25 July, 2022 at 12:00 pm",
//     user: "Saniya Shaikh",
//     activity: "Changed Images",
//   },
//   {
//     id: 8,
//     dateAndTime: "25 July, 2022 at 12:00 pm",
//     user: "Saniya Shaikh",
//     activity: "Changed Images",
//   },
//   {
//     id: 9,
//     dateAndTime: "25 July, 2022 at 12:00 pm",
//     user: "Saniya Shaikh",
//     activity: "Changed Images",
//   },
//   {
//     id: 10,
//     dateAndTime: "25 July, 2022 at 12:00 pm",
//     user: "Saniya Shaikh",
//     activity: "Changed Images",
//   },
//   {
//     id: 11,
//     dateAndTime: "25 July, 2022 at 12:00 pm",
//     user: "Saniya Shaikh",
//     activity: "Changed Images",
//   },
//   {
//     id: 12,
//     dateAndTime: "25 July, 2022 at 12:00 pm",
//     user: "Saniya Shaikh",
//     activity: "Changed Images",
//   },
//   {
//     id: 13,
//     dateAndTime: "25 July, 2022 at 12:00 pm",
//     user: "Saniya Shaikh",
//     activity: "Changed Images",
//   },
//   {
//     id: 14,
//     dateAndTime: "25 July, 2022 at 12:00 pm",
//     user: "Saniya Shaikh",
//     activity: "Changed Images",
//   },
//   {
//     id: 15,
//     dateAndTime: "25 July, 2022 at 12:00 pm",
//     user: "Saniya Shaikh",
//     activity: "Changed Images",
//   },
//   {
//     id: 16,
//     dateAndTime: "25 July, 2022 at 12:00 pm",
//     user: "Saniya Shaikh",
//     activity: "Changed Images",
//   },
//   {
//     id: 17,
//     dateAndTime: "25 July, 2022 at 12:00 pm",
//     user: "Saniya Shaikh",
//     activity: "Changed Images",
//   },
//   {
//     id: 18,
//     dateAndTime: "25 July, 2022 at 12:00 pm",
//     user: "Saniya Shaikh",
//     activity: "Changed Images",
//   },
//   {
//     id: 19,
//     dateAndTime: "25 July, 2022 at 12:00 pm",
//     user: "Saniya Shaikh",
//     activity: "Changed Images",
//   },
//   {
//     id: 20,
//     dateAndTime: "25 July, 2022 at 12:00 pm",
//     user: "Saniya Shaikh",
//     activity: "Changed Images",
//   },
//   {
//     id: 21,
//     dateAndTime: "25 July, 2022 at 12:00 pm",
//     user: "Saniya Shaikh",
//     activity: "Changed Images",
//   },
// ];

// ? TABLE STARTS HERE

const headCells = [
  {
    id: "productName",
    numeric: false,
    disablePadding: true,
    label: "Product Name",
  },
  {
    id: "category",
    numeric: false,
    disablePadding: false,
    label: "Category",
  },
  // {
  //   id: "qty",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Qty",
  // },
  {
    id: "price",
    numeric: false,
    disablePadding: false,
    label: "Price",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
  // {
  //   id: "activity",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Activity",
  // },
  // {
  //   id: "status",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Status",
  // },
  {
    id: "actions",
    numeric: false,
    disablePadding: true,
    label: "Actions",
  },
];

// ? TABLE ENDS HERE
// const Transition = forwardRef(function Transition(props, ref) {
//   return (
//     <Slide
//       direction="up"
//       ref={ref}
//       {...props}
//     />
//   );
// });

const AllProductsTable = ({
  isLoading = false,
  list = [],
  totalCount = 0,
  rowsPerPage = 10,
  changeRowsPerPage = () => {},
  changePage = () => {},
  page = 0,
  isArchivedTab = false,
  selected = [],
  setSelected = () => {},
}) => {
  const dispatch = useDispatch();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showArchivedModal, setShowArchivedModal] = useState(false);
  const [showUnArchivedModal, setShowUnArchivedModal] = useState(false);
  const [showMultipleArchivedModal, setShowMultipleArchivedModal] = useState(false);
  const [showMultipleUnArhcivedModal, setShowMultipleUnArhcivedModal] = useState(false);
  const [showLabelsBadgesDialog, setShowLabelsBadgesDialog] = useState(false);
  const [unArchiveStatus, setUnArchiveStatus] = useState("in-active");
  const [tagManagerProducts, setTagManagerProducts] = useState([]);
  const [collectionManagerProducts, setCollectionManagerProducts] = useState([]);

  const [duplicatingProduct, setDuplicatingProduct] = useState(null);
  const [productSelected, setProductSelected] = useState(null);
  const [productSelectedAnchorEl, setProductSelectedAnchorEl] = useState(null);
  const productSelectedIsOpen = Boolean(productSelectedAnchorEl);
  const onProductSelectedOpen = (e) => setProductSelectedAnchorEl(e.currentTarget);
  const onProductSelectedClose = () => setProductSelectedAnchorEl(null);

  // const [selected, setSelected] = useState([]);
  const clearSelected = () => setSelected([]);

  const [updateProduct, { isLoading: updateProductIsLoading }] = useUpdateProductMutation();
  const [updateSelectedDiscount] = useUpdateSelectedDiscountMutation();

  const [bulkUpdateProduct, { isLoading: bulkUpdateProductIsLoading }] =
    useBulkEditProductMutation();

  const onProductStatusChange = (status) => {
    if (selected.length > 0)
      bulkUpdateProduct({
        updates: selected.map((rv) => ({ id: rv._id, status })),
      })
        .unwrap()
        .then(() => dispatch(showSuccess({ message: `Products updated successfully!` })))
        .catch((e) =>
          dispatch(showError({ message: e?.data?.message ?? "Something went wrong!" }))
        )
        .finally(() => {
          clearSelected();
          setUnArchiveStatus("in-active");
          setProductSelected(null);
          setShowArchivedModal(false);
          setShowMultipleArchivedModal(false);
          setShowUnArchivedModal(false);
          setShowMultipleUnArhcivedModal(false);
        });
    else
      updateProduct({ id: productSelected?._id, details: { status } })
        .unwrap()
        .then(() => dispatch(showSuccess({ message: "Product updated successfully!" })))
        .catch((e) =>
          dispatch(showError({ message: e?.data?.message ?? "Something went wrong!" }))
        )
        .finally(() => {
          clearSelected();
          setUnArchiveStatus("in-active");
          setProductSelected(null);
          setShowArchivedModal(false);
          setShowMultipleArchivedModal(false);
          setShowUnArchivedModal(false);
          setShowMultipleUnArhcivedModal(false);
        });
  };

  const [deleteProduct, { isLoading: deleteProductIsLoading }] = useDeleteProductMutation();
  const [bulkDeleteProduct, { isLoading: bulkDeleteProductIsLoading }] =
    useBulkDeleteProductMutation();

  const onProductDelete = () => {
    if (selected.length > 0)
      bulkDeleteProduct({ deletes: selected.map((rv) => rv._id) })
        .unwrap()
        .then(() => dispatch(showSuccess({ message: "Products deleted successfully!" })))
        .catch((e) =>
          dispatch(showError({ message: e?.data?.message ?? "Something went wrong!" }))
        )
        .finally(() => {
          clearSelected();
          setProductSelected(null);
          setShowDeleteModal(false);
        });
    else
      deleteProduct(productSelected?._id)
        .unwrap()
        .then(() => dispatch(showSuccess({ message: "Product deleted successfully!" })))
        .catch((e) =>
          dispatch(showError({ message: e?.data?.message ?? "Something went wrong!" }))
        )
        .finally(() => {
          clearSelected();
          setProductSelected(null);
          setShowDeleteModal(false);
        });
  };

  const [openBulkDiscountDialog, setOpenBulkDiscountDialog] = useState(false);

  const onBulkActionSelected = (action) => {
    switch (action) {
      case "Set as Active":
        onProductStatusChange("active");
        break;
      case "Set as Draft":
        onProductStatusChange("in-active");
        break;
      case "Un-Archive":
        setShowMultipleUnArhcivedModal(true);
        break;
      case "Delete":
        setShowDeleteModal(true);
        break;
      case "Archive":
        setShowMultipleArchivedModal(true);
        break;
      case "Manage Tags":
        setTagManagerProducts(selected.map((sel) => sel._id));
        break;
      case "Manage Collections":
        setCollectionManagerProducts(selected.map((sel) => sel._id));
        break;
      case "Bulk Discount":
        setOpenBulkDiscountDialog(true);
        break;
      case "Bulk Labels & Badges":
        setShowLabelsBadgesDialog(true);
        break;

      default:
        break;
    }
  };
  const handleAddBulkDiscount = useCallback(
    async (values, helper) => {
      try {
        const productIds = selected?.map((e) => e?._id);
        const preparedValues = { ...values, productIds };
        // api call
        console.log(preparedValues, "prepared values productIds");
        await updateSelectedDiscount(preparedValues).unwrap();
        console.log(preparedValues);
        helper?.resetForm();
        setOpenBulkDiscountDialog(false);
        dispatch(
          showSuccess({
            message: "Discount updated successfully",
          })
        );
      } catch (error) {
        dispatch(
          showError({
            message: error?.data?.message || "Something went wrong!",
          })
        );
        console.log(error);
      }
    },
    [dispatch, selected, updateSelectedDiscount]
  );

  // * DISCOUNT PERCENT POPOVERS STARTS
  const [anchorDiscountPercentEl, setAnchorDiscountPercentEl] = useState(null);
  const handleDiscountPercent = (event) => {
    setAnchorDiscountPercentEl(event.currentTarget);
  };
  const handleDiscountPercentClose = () => {
    setAnchorDiscountPercentEl(null);
  };
  const openDiscountPercent = Boolean(anchorDiscountPercentEl);
  const idDiscountPercent = openDiscountPercent ? "simple-popover" : undefined;

  const formik = useFormik({
    initialValues: {
      discount: "",
      type: "percentage",
    },
    validationSchema: discountValueSchema,
    onSubmit: handleAddBulkDiscount,
  });

  return isLoading ? (
    <span className="d-flex justify-content-center m-3">
      <TableLoader />
    </span>
  ) : list.length === 0 ? (
    <span className="d-flex justify-content-center m-3">
      <NoDataFound />
    </span>
  ) : (
    <>
      {selected.length > 0 && (
        <div className="d-flex justify-content-between align-items-center px-2 mb-3">
          <div className="d-flex">
            <button className="button-grey py-2 px-3">
              <small className="text-lightBlue">
                {selected.length} products are selected&nbsp;
                <span
                  className="text-blue-2 c-pointer"
                  onClick={clearSelected}>
                  (Clear Selection)
                </span>
              </small>
            </button>

            {/* <button className="button-grey py-2 px-3 ms-2">
              <small className="text-lightBlue">Edit Products</small>
            </button> */}

            {isArchivedTab ? (
              <TableMassActionButton
                onSelect={onBulkActionSelected}
                defaultValue={["Un-Archive", "Delete"]}
                headingName="Mass Action"
              />
            ) : (
              <>
                <TableEditStatusButton
                  onSelect={onBulkActionSelected}
                  defaultValue={["Set as Active", "Set as Draft"]}
                  headingName="Edit Status"
                />
                <TableMassActionButton
                  onSelect={onBulkActionSelected}
                  defaultValue={[
                    "Manage Tags",
                    "Manage Collections",
                    "Bulk Discount",
                    "Bulk Labels & Badges",
                  ]}
                  headingName="Manage Action"
                />
                <button
                  className="button-grey py-2 px-3 ms-2"
                  variant="contained"
                  onClick={() => onBulkActionSelected("Archive")}>
                  <small className="text-lightBlue">Archive</small>
                </button>
              </>
            )}
          </div>
        </div>
      )}
      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size="medium">
          <EnhancedTableHead
            onSelectAllClick={(e) => setSelected(e.target.checked ? [...list] : [])}
            numSelected={selected.length}
            rowCount={list.length}
            headCells={headCells}
            orderBy={"groupName"}
            onRequestSort={Date}
            order={"asc"}
          />
          <TableBody>
            {list.map((row) => {
              // console.log(row);
              const isItemSelected = selected.includes(row);
              const {
                _id: rid = "",
                mediaUrl = [],
                title = "",
                productType: { category: { name: categoryName = "" } = {} } = {},
                price: {
                  // dynamicPricing = false,
                  isGstInclude = true,
                  gstPrice = 0,
                  salePrice = 0,
                } = {},
                inventory: { skuId = "" } = {},
                setId = "",
                status = "",
                startDate = "",
                endDate = "",
                step = "",
                isCompleted = false,
              } = row ?? {};
              const imageUrl = mediaUrl.find((mu) => mu.isDefault)?.image ?? "";
              let whatInc = "";
              switch (step) {
                case "1":
                  whatInc = "Options";
                  break;
                case "2": // "Price Master "
                case "3":
                  whatInc = "Variant";
                  break;
                case "4":
                  whatInc = "More Fetures";
                  break;
                case "5":
                  whatInc = "Complete";
                  break;

                default:
                  break;
              }
              return (
                <TableRow
                  hover
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={rid}
                  selected={isItemSelected}
                  className="table-rows">
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={isItemSelected}
                      onClick={(e) =>
                        setSelected((sel) =>
                          e.target.checked
                            ? sel.concat(row)
                            : sel.filter((s) => !Object.is(s, row))
                        )
                      }
                      size="small"
                      style={{ color: "#5C6D8E" }}
                    />
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    padding="none">
                    <Link
                      to={`./editProduct/${rid}${window.location.search}`}
                      className="text-decoration-none">
                      <div className="d-flex align-items-center c-pointer py-3">
                        <AsyncImage
                          src={imageUrl}
                          placeholder={ringSmall}
                          alt="product"
                          className="me-2 rounded-4"
                          height={45}
                          width={45}
                          style={{ objectFit: "cover" }}
                        />
                        <div>
                          <p className="text-lightBlue fw-600">{title}</p>
                          <small className="mt-2 text-grey-6">SKU: {skuId}</small>
                        </div>
                      </div>
                    </Link>
                  </TableCell>
                  <TableCell>
                    <p className="text-lightBlue">{categoryName}</p>
                  </TableCell>
                  {/* <TableCell>
                    <div className="d-flex align-items-center">
                      <p className="text-decoration-underline text-blue-2">{row?.qty ?? 0}</p>
                    </div>
                  </TableCell> */}
                  <TableCell>
                    <div className="d-flex align-items-center">
                      <p className="text-lightBlue">
                        {!setId
                          ? isGstInclude
                            ? rupeeFormatWithSymbol(Math.round(salePrice + gstPrice))
                            : rupeeFormatWithSymbol(Math.round(salePrice))
                          : "Depends on Variant"}
                      </p>
                    </div>
                  </TableCell>
                  {/* <TableCell>
                    <div
                      className="d-flex"
                      // className="d-flex c-pointer"
                      // onClick={toggleActivityDrawer("right", true)}
                      // onMouseEnter={handleActivityPopoverOpen}
                      // onMouseLeave={handleActivityPopoverClose}
                    >
                      <img
                        src={teamMember1}
                        alt="teamMember1"
                      />
                      <img
                        src={teamMember3}
                        alt="teamMember2"
                      />
                      <img
                        src={teamMember2}
                        alt="teamMember3"
                      />
                    </div>
                  </TableCell> */}
                  <TableCell style={{ width: 180, padding: 0 }}>
                    <div className="d-block">
                      {!isCompleted ? (
                        <Tooltip
                          title={whatInc}
                          placement="top">
                          <div
                            className="rounded-pill d-flex c-pointer px-2 py-1 statusBoxWidth"
                            style={{ background: "#C8D8FF" }}>
                            <small className="text-black fw-500">Incomplete</small>
                          </div>
                        </Tooltip>
                      ) : (
                        <>
                          <div
                            className="rounded-pill d-flex px-2 py-1 statusBoxWidth"
                            style={{
                              background:
                                status === "active"
                                  ? "#A6FAAF"
                                  : status === "in-active"
                                  ? "#F67476"
                                  : status === "archived"
                                  ? "#C8D8FF"
                                  : "#FEE1A3",
                            }}>
                            <small className="text-black fw-500">
                              {status === "active"
                                ? "Active"
                                : status === "in-active"
                                ? "Draft"
                                : status === "archived"
                                ? "Archived"
                                : "Scheduled"}
                            </small>
                          </div>
                          {status === "scheduled" && (
                            <div>
                              <small className="text-blue-2">
                                {startDate && <>for {moment(startDate).format("DD/MM/YYYY")}</>}
                                {startDate && endDate && " "}
                                {endDate && <>till {moment(endDate).format("DD/MM/YYYY")}</>}
                              </small>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </TableCell>
                  <TableCell style={{ width: 80, padding: 0 }}>
                    {isArchivedTab ? (
                      <div className="d-flex align-items-center">
                        <Tooltip
                          title="Un-Archive"
                          placement="top">
                          <div
                            className="table-edit-icon c-pointer rounded-4 p-2"
                            onClick={() => {
                              setProductSelected(row);
                              setShowUnArchivedModal(true);
                            }}>
                            <InventoryIcon
                              sx={{
                                color: "#5c6d8e",
                                fontSize: 18,
                                cursor: "pointer",
                              }}
                            />
                            {/* <VisibilityOutlinedIcon
                              sx={{
                                color: "#5c6d8e",
                                fontSize: 18,
                              }}
                              // onClick={toggleProductDrawer("right", true)}
                            /> */}
                          </div>
                        </Tooltip>
                        <Tooltip
                          title="Edit"
                          placement="top">
                          <div
                            className="table-edit-icon c-pointer rounded-4 p-2"
                            onClick={() => {
                              setProductSelected(row);
                              setShowDeleteModal(true);
                            }}>
                            <DeleteIcon
                              sx={{
                                color: "#5c6d8e",
                                fontSize: 18,
                                cursor: "pointer",
                              }}
                            />
                          </div>
                          {/* <Link
                            to={`./editProduct/${rid}`}
                            className="table-edit-icon rounded-4 p-2">
                            <EditOutlinedIcon
                              sx={{
                                color: "#5c6d8e",
                                fontSize: 18,
                              }}
                            />
                          </Link> */}
                        </Tooltip>
                      </div>
                    ) : (
                      <div className="d-flex align-items-center">
                        {/* <Tooltip
                          title="View"
                          placement="top">
                          <div className="table-edit-icon c-pointer rounded-4 p-2">
                            <VisibilityOutlinedIcon
                              sx={{
                                color: "#5c6d8e",
                                fontSize: 18,
                              }}
                              // onClick={toggleProductDrawer("right", true)}
                            />
                          </div>
                        </Tooltip> */}
                        <Tooltip
                          title="Edit"
                          placement="top">
                          <Link
                            to={`./editProduct/${rid}${window.location.search}`}
                            className="table-edit-icon rounded-4 p-2">
                            <EditOutlinedIcon
                              sx={{
                                color: "#5c6d8e",
                                fontSize: 18,
                              }}
                            />
                          </Link>
                        </Tooltip>
                        <img
                          src={verticalDots}
                          alt="verticalDots"
                          className="c-pointer mx-2"
                          onClick={(e) => {
                            setProductSelected(row);
                            onProductSelectedOpen(e);
                          }}
                        />
                      </div>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
            <Popover
              open={productSelectedIsOpen}
              anchorEl={productSelectedAnchorEl}
              onClose={onProductSelectedClose}
              onClick={onProductSelectedClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}>
              <div className="py-2 px-2">
                {/* <small className="text-grey-7 px-2">ACTIONS</small>
                <hr className="hr-grey-6 my-2" />
                <div className="d-flex align-items-center justify-content-between mb-2 mt-2 text-blue-1">
                  <img
                    src={editButton}
                    alt="editButton"
                    height={36}
                    className="c-pointer me-2"
                    onClick={() => {
                      navigate(`./editProduct/${rowData._id}`);
                    }}
                  />
                  <img
                    src={duplicateButton}
                    alt="duplicateButton"
                    height={36}
                    className="c-pointer"
                  />
                </div> */}
                <small
                  onClick={() => onProductStatusChange("active")}
                  className="p-2 rounded-3 text-lightBlue c-pointer font2 d-block hover-back">
                  Make it Active
                </small>
                <small
                  onClick={() => onProductStatusChange("in-active")}
                  className="p-2 rounded-3 text-lightBlue c-pointer font2 d-block hover-back">
                  Make it Draft
                </small>
                {!isArchivedTab && (
                  <small
                    onClick={() => setDuplicatingProduct(productSelected)}
                    className="p-2 rounded-3 text-lightBlue c-pointer font2 d-block hover-back">
                    Duplicate Product
                  </small>
                )}
                {/* <small className="p-2 rounded-3 text-lightBlue c-pointer font2 d-block hover-back">
                  Edit SKU
                </small>
                <small className="p-2 rounded-3 text-lightBlue c-pointer font2 d-block hover-back">
                  Edit Quantity
                </small> */}
                <small
                  onClick={() => setTagManagerProducts([productSelected?._id ?? ""])}
                  className="p-2 rounded-3 text-lightBlue c-pointer font2 d-block hover-back">
                  Add or Remove Tags
                </small>
                <small
                  onClick={() => setCollectionManagerProducts([productSelected?._id ?? ""])}
                  className="p-2 rounded-3 text-lightBlue c-pointer font2 d-block hover-back">
                  Add or Remove Collections
                </small>
                {isArchivedTab ? (
                  <>
                    <div className="d-flex justify-content-between  hover-back rounded-3 p-2 c-pointer">
                      <small
                        className="text-lightBlue font2 d-block"
                        onClick={() => setShowUnArchivedModal(true)}>
                        Un-Archive Product
                      </small>
                      <img
                        src={deleteRed}
                        alt="un-archive"
                        className=""
                      />
                    </div>
                    <div className="d-flex justify-content-between  hover-back rounded-3 p-2 c-pointer">
                      <small
                        className="text-lightBlue font2 d-block"
                        onClick={() => setShowDeleteModal(true)}>
                        Delete Product
                      </small>
                      <img
                        src={deleteRed}
                        alt="delete"
                        className=""
                      />
                    </div>
                  </>
                ) : (
                  <div className="d-flex justify-content-between  hover-back rounded-3 p-2 c-pointer">
                    <small
                      className="text-lightBlue font2 d-block"
                      onClick={() => setShowArchivedModal(true)}>
                      Archive Product
                    </small>
                    <img
                      src={deleteRed}
                      alt="delete"
                      className=""
                    />
                  </div>
                )}
              </div>
            </Popover>
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30, 50]}
        component="div"
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        onPageChange={changePage}
        onRowsPerPageChange={changeRowsPerPage}
        className="table-pagination"
      />

      <Dialog
        open={openBulkDiscountDialog}
        onClose={() => {
          formik.handleReset();
          setOpenBulkDiscountDialog(false);
        }}>
        <DialogTitle>Bulk Discount</DialogTitle>
        <DialogContent>
          <div className="row align-items-center">
            <div
              className="col-md-5 discount-inputs-two d-flex align-items-center"
              style={{ width: "100%" }}>
              <div className="col">
                <FormControl className="px-0">
                  <OutlinedInput
                    value={formik.values?.discount}
                    onChange={formik?.handleChange}
                    onBlur={formik?.handleBlur}
                    name={`discount`}
                    placeholder="Enter Discount"
                    size="small"
                    startAdornment={
                      formik.values?.type === "fixed" && (
                        <InputAdornment
                          position="start"
                          variant="standard">
                          <span className="text-lightBlue">₹</span>
                        </InputAdornment>
                      )
                    }
                    endAdornment={
                      <>
                        <InputAdornment
                          position="end"
                          variant="standard">
                          <span className="text-lightBlue">
                            {formik.values?.type === "percentage" && "%"}
                          </span>
                        </InputAdornment>
                        <InputAdornment
                          position="end"
                          aria-describedby={idDiscountPercent}
                          onClick={handleDiscountPercent}
                          className="c-pointer">
                          <span className="d-flex align-items-center">
                            <p className="text-lightBlue">
                              {" "}
                              {formik.values?.type === "percentage"
                                ? `Percentage`
                                : `Fixed Amount`}{" "}
                            </p>
                            <img
                              src={arrowDown}
                              alt="arrow"
                              className="ms-2"
                            />
                          </span>
                        </InputAdornment>
                      </>
                    }
                  />
                </FormControl>
                <small>
                  {!!formik.touched?.discount && formik.errors?.discount && (
                    <FormHelperText error>{formik.errors?.discount}</FormHelperText>
                  )}
                </small>

                <Popover
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  id={idDiscountPercent}
                  open={openDiscountPercent}
                  anchorEl={anchorDiscountPercentEl}
                  onClose={handleDiscountPercentClose}>
                  <div className="py-2 px-1 c-pointer">
                    <small
                      className="text-lightBlue rounded-3 p-2 hover-back d-block"
                      onClick={() => {
                        handleDiscountPercentClose();
                        formik.setFieldValue("type", "percentage");
                      }}>
                      Percentage Discount
                    </small>
                    <small
                      className="text-lightBlue rounded-3 p-2 hover-back d-block"
                      onClick={() => {
                        handleDiscountPercentClose();
                        formik.setFieldValue("type", "fixed");
                      }}>
                      Fixed Amount
                    </small>
                  </div>
                </Popover>
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <button
            className="button-grey py-2 px-3"
            onClick={() => {
              formik.handleReset();
              setOpenBulkDiscountDialog(false);
            }}>
            Cancel
          </button>
          <button
            className="button-gradient py-2 px-4"
            onClick={formik.handleSubmit}>
            Apply
          </button>
        </DialogActions>
      </Dialog>

      <BulkLabelsBadgesDialog
        selected={selected}
        setSelected={setSelected}
        show={showLabelsBadgesDialog}
        onClose={() => setShowLabelsBadgesDialog(false)}
      />

      <AddRemoveTags
        productIds={tagManagerProducts}
        show={!!tagManagerProducts.length}
        onClose={() => setTagManagerProducts([])}
      />

      <AddRemoveCollections
        productIds={collectionManagerProducts}
        show={!!collectionManagerProducts.length}
        onClose={() => setCollectionManagerProducts([])}
      />

      <DeleteModalSecondary
        onConfirm={() => onProductDelete()}
        onCancel={() => setShowDeleteModal(false)}
        show={showDeleteModal}
        isLoading={deleteProductIsLoading || bulkDeleteProductIsLoading}
        message={selected.length > 1 ? `${selected.length} products` : "the product"}
        title={selected.length > 1 ? "Products" : "Product"}
      />

      <DuplicateDialog
        product={duplicatingProduct}
        onClose={() => setDuplicatingProduct(null)}
      />

      <ArchiveModalSecondary
        onConfirm={() => onProductStatusChange("archived")}
        onCancel={() => setShowArchivedModal(false)}
        show={showArchivedModal}
        isLoading={updateProductIsLoading || bulkUpdateProductIsLoading}
        message={productSelected?.title}
        title="Product"
        afterText="from the list"
      />

      <UnArchiveModal
        onConfirm={() => onProductStatusChange(unArchiveStatus)}
        onCancel={() => setShowUnArchivedModal(false)}
        show={showUnArchivedModal}
        title={"Un-Archive Product ?"}
        primaryMessage={`Before un-archiving <span className='text-blue-1'>${productSelected?.title}</span> Product,
        `}
        secondaryMessage={"Please set its status"}
        confirmText={"Un-Archive"}
        handleStatusValue={setUnArchiveStatus}
        icon={unArchived}
      />

      <MultipleArchiveModal
        onConfirm={() => onProductStatusChange("archived")}
        onCancel={() => setShowMultipleArchivedModal(false)}
        show={showMultipleArchivedModal}
        title={"Product"}
        message={`${
          selected.length === 1 ? `${selected.length} Product` : `${selected.length} Products`
        }`}
        pronoun={`${selected.length === 1 ? "this" : `these`}`}
      />
      <MultipleUnArchiveModal
        onConfirm={() => onProductStatusChange(unArchiveStatus)}
        onCancel={() => setShowMultipleUnArhcivedModal(false)}
        show={showMultipleUnArhcivedModal}
        title={"Un-Archive Products ?"}
        primaryMessage={`Before un-archiving <span className='text-blue-1'>${
          selected.length
        }</span> ${selected.length === 1 ? " Product" : "Products"},
        `}
        secondaryMessage={"Please set its status"}
        confirmText={"Un-Archive"}
        handleStatusValue={setUnArchiveStatus}
        icon={unArchived}
      />
    </>
  );
};

export default AllProductsTable;

/* <ArchiveModal
        onConfirm={() => onProductStatusChange("archived")}
        onCancel={() => setShowArchivedModal(false)}
        show={showArchivedModal}
        title={"Product"}
        products={productSelected?.title}
        message={"it"}
      /> */

/* <SwipeableDrawer
        anchor="right"
        open={activityDrawer["right"]}
        onClose={toggleActivityDrawer("right", false)}
        onOpen={toggleActivityDrawer("right", true)}>
        <div className="px-3 activity-top bg-black-13 pb-3">
          <div className="d-flex justify-content-between py-3 px-0">
            <h6 className="text-lightBlue">View Logs</h6>
            <img
              src={cancel}
              alt="cancel"
              className="c-pointer filter-icon me-1"
              onClick={toggleActivityDrawer("right", false)}
            />
          </div>

          <div className="d-flex align-items-center">
            <div className="d-flex align-items-center">
              <img
                src={products}
                alt="user"
                className="me-2"
                height={30}
                width={30}
              />
              <div>
                <p className="text-lightBlue fw-600">Product Module</p>
                <small className="mt-2 text-grey-6">
                  Last modified on 10 Dec, 2022 by Saniya Shaikh
                </small>
              </div>
            </div>
            <div className="d-flex ms-5">
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DesktopDateTimePicker
                  value={activityDateValue}
                  onChange={(newValue) => {
                    handleActivityDateChange(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      placeholder="Enter Date & Time"
                      sx={{ width: 210 }}
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
          </div>

          <div className="d-flex mt-3 ">
            <TableSearch />

            <button
              className="button-grey py-2 px-3"
              aria-describedby={idActivity}
              variant="contained"
              onClick={handleActivityClick}>
              <small className="text-lightBlue">Activity</small>
              <img
                src={activity}
                alt="activity"
                className="ms-2"
              />
            </button>

            <Popover
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              id={idActivity}
              open={openActivity}
              anchorEl={anchorActivityEl}
              onClose={handleActivityClose}>
              <div className="py-2 px-1">
                <small className="p-2 rounded-3 text-lightBlue c-pointer font2 d-block hover-back">
                  Viewed User
                </small>
                <small className="p-2 rounded-3 text-lightBlue c-pointer font2 d-block hover-back">
                  Edited User
                </small>
                <small className="p-2 rounded-3 text-lightBlue c-pointer font2 d-block hover-back">
                  Updated User Status
                </small>
                <small className="p-2 rounded-3 text-lightBlue c-pointer font2 d-block hover-back">
                  Archive User
                </small>
                <small className="p-2 rounded-3 text-lightBlue c-pointer font2 d-block hover-back">
                  Added Comments
                </small>
              </div>
            </Popover>
          </div>
        </div>

        <div className="">
          <table className="table table-borderless activity-table">
            <thead className="">
              <tr className="bg-black-15">
                <th scope="col">
                  <small className="text-lightBlue fw-400"></small>
                </th>
                <th scope="col">
                  <small className="text-lightBlue fw-400">User</small>
                </th>
                <th scope="col">
                  <small className="text-lightBlue fw-400">Activity</small>
                </th>
                <th scope="col">
                  <small className="text-lightBlue fw-400">Date and Time</small>
                </th>
                <th scope="col">
                  <small className="text-lightBlue fw-400"></small>
                </th>
              </tr>
            </thead>
            <tbody>
              {activityData.map((data) => (
                <tr key={data.id}>
                  {/ DONOT REMOVE THIS BLANK COLUMN DONE FOR STYLING /}
                  <td>
                    <small className="text-grey-6 fw-400"></small>
                  </td>
                  {/ DONOT REMOVE THIS BLANK COLUMN DONE FOR STYLING /}
                  <th scope="row">
                    <div className="d-flex align-items-center">
                      <img
                        src={teamMember1}
                        alt="teamMember1"
                        className="me-2"
                      />
                      <small className="text-lightBlue fw-400">{data.user}</small>
                    </div>
                  </th>
                  <td>
                    <small className="text-lightBlue">{data.activity}</small>
                  </td>
                  <td>
                    <small className="text-grey-6 fw-400">{data.dateAndTime}</small>
                  </td>
                  {/ DONOT REMOVE THIS BLANK COLUMN DONE FOR STYLING /}
                  <td>
                    <small className="text-grey-6 fw-400"></small>
                  </td>
                  {/ DONOT REMOVE THIS BLANK COLUMN DONE FOR STYLING /}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </SwipeableDrawer> */

/* <SwipeableDrawer
        anchor="right"
        open={addProductDrawer["right"]}
        onClose={toggleProductDrawer("right", false)}
        onOpen={toggleProductDrawer("right", true)}
        className="product-drawer">
        <div className="d-flex align-items-center pt-3 px-3">
          <KeyboardArrowLeftOutlinedIcon
            sx={{ fontSize: 25, color: "#c8d8ff" }}
            onClick={toggleProductDrawer("right", false)}
            className="c-pointer"
          />
          {/ <div>
            <h5 className="text-lightBlue fw-500 ms-2">Add Team Member</h5>
          </div> /}
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex">
              <img
                src={product2}
                alt="product2"
                className="me-2 rounded-8 ms-2"
                height={45}
                width={45}
              />
              <div>
                <p className="text-lightBlue rounded-circle fw-600">The Fringe Diamond Ring</p>
                <small className="text-grey-6 mt-1">Style Code: TFDR012345</small>
              </div>
            </div>
            {/ <button
              className="button-transparent py-2 px-3 border-grey-5"
            > /}
            <Tooltip
              title="Edit"
              placement="top">
              <div className="table-edit-icon rounded-4 p-2 ms-3">
                <EditOutlinedIcon
                  sx={{
                    color: "#6e8dd7",
                    fontSize: 18,
                    cursor: "pointer",
                  }}
                />
              </div>
            </Tooltip>
            {/ <p className="text-lightBlue">Open Bulk Editor</p> /}
            {/ </button> /}
          </div>
        </div>
        <div className="px-3">
          <hr className="hr-grey-6 mt-3 mb-3" />
        </div>
        <div className="px-3">
          <div className="row">
            <div className="col-4">
              <AppCountrySelect />
            </div>
            <div className="col-8">
              <FormControl
                sx={{ width: "100%" }}
                size="small">
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  defaultValue="1"
                  placeholder="Fixed"
                  // onChange={handleMetalChange}
                >
                  <MenuItem value="1">Store 1</MenuItem>
                  <MenuItem value="2">Store 2</MenuItem>
                  <MenuItem value="3">Store 3</MenuItem>
                  <MenuItem value="4">Store 4</MenuItem>
                  <MenuItem value="5">Store 5</MenuItem>
                </Select>
              </FormControl>
            </div>

            <div className="d-flex col-12 justify-content-between align-items-center mt-3">
              <div className="d-flex">
                <p className="text-lightBlue py-1 me-2">Filter:</p>
                <p
                  className="text-blue-2 px-2 py-1 c-pointer hover-back-transparent rounded-3"
                  aria-describedby={idMetalFilter}
                  variant="contained"
                  onClick={handleMetalFilter}>
                  Size
                </p>
                <p
                  className="text-blue-2 px-2 py-1 c-pointer hover-back-transparent rounded-3"
                  aria-describedby={idMetalFilter}
                  variant="contained"
                  onClick={handleMetalFilter}>
                  Metal
                </p>
                <p
                  className="text-blue-2 px-2 py-1 c-pointer hover-back-transparent rounded-3"
                  aria-describedby={idMetalFilter}
                  variant="contained"
                  onClick={handleMetalFilter}>
                  Metal Purity
                </p>
                <p
                  className="text-blue-2 px-2 py-1 c-pointer hover-back-transparent rounded-3"
                  aria-describedby={idMetalFilter}
                  variant="contained"
                  onClick={handleMetalFilter}>
                  Diamond
                </p>

                <Popover
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  id={idMetalFilter}
                  open={openMetalFilter}
                  anchorEl={anchorMetalFilterEl}
                  onClose={handleMetalFilterClose}>
                  <FormGroup className="tags-checkbox py-2">
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          style={{
                            color: "#5C6D8E",
                          }}
                        />
                      }
                      label="Content 1"
                      className="hover-back rounded-3 mx-0 pe-2"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          style={{
                            color: "#5C6D8E",
                          }}
                        />
                      }
                      label="Content 2"
                      className="hover-back rounded-3 mx-0 pe-2"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          style={{
                            color: "#5C6D8E",
                          }}
                        />
                      }
                      label="Content 3"
                      className="hover-back rounded-3 mx-0 pe-2"
                    />
                  </FormGroup>
                </Popover>
              </div>
            </div>
            <div className="col-12 mt-3 px-0">
              <ProductDrawerTable />
            </div>
          </div>
        </div>
        <div className="d-flex flex-column py-3 px-4 product-buttons">
          <hr className="hr-grey-6 my-3 w-100" />
          <div className="d-flex justify-content-between">
            {/ <button
              className="button-gradient py-2 px-5 w-auto"
              onClick={toggleProductDrawer("right", false)}
            >
              <p>Add</p>
            </button> /}
            <button
              className="button-lightBlue-outline py-2 px-4"
              onClick={toggleProductDrawer("right", false)}>
              <p>Cancel</p>
            </button>
          </div>
        </div>
      </SwipeableDrawer> */
