import React, { useState } from "react";
// ! COMPONENT IMPORTS
import UploadFile from "../../components/UploadFile/UploadFile";
// ! IMAGES IMPORTS
import indiaFlag from "../../assets/images/products/indiaFlag.svg";
import product2 from "../../assets/images/products/product2.jpg";
import userIcon from "../../assets/icons/userIcon.svg";
import cancel from "../../assets/icons/cancel.svg";
import locationGradient from "../../assets/icons/locationGradient.svg";
import productInfoMedia2 from "../../assets/images/products/productInfoMedia2.svg";
// ! MATERIAL IMPORTS
import {
  Dialog,
  DialogActions,
  DialogContent,
  Slide,
  DialogTitle,
  Rating,
  Tooltip,
  Button,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
// ! MATERIAL ICONS IMPORTS
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import BlockIcon from "@mui/icons-material/Block";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { useUpdateOrderMutation } from "../../features/orders/ordersApiSlice";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { showError, showSuccess } from "../../features/snackbar/snackbarAction";
import EditOrderAddress from "./EditOrderAddress";

// ? DIALOG TRANSITION STARTS HERE
const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="up"
      ref={ref}
      {...props}
    />
  );
});
// ? DIALOG TRANSITION ENDS HERE

const OrderUserDetails = ({ showGST, showReviewData, showMediaData, data }) => {
  // ? IMPORT SECOND DIALOG STARTS HERE
  const [openImportSecond, setOpenImportSecond] = React.useState(false);

  const handleImportSecondOpen = () => {
    setOpenImportSecond(true);
  };

  const handleImportSecondClose = () => {
    setOpenImportSecond(false);
  };
  // ? IMPORT SECOND DIALOG ENDS HERE

  // ? REVIEW DIALOG STARTS HERE
  const [openReview, setOpenReview] = React.useState(false);

  const handleOpenReview = () => {
    setOpenReview(true);
  };

  const handleOpenReviewClose = () => {
    setOpenReview(false);
  };
  // ? REVIEW DIALOG ENDS HERE
  // console.log(data, "usedata");

  const [showCxInfo, setShowCxInfo] = useState(true);
  const [showShipInfo, setShowShipInfo] = useState(true);
  const [showBillInfo, setShowBillInfo] = useState(true);

  const dispatch = useDispatch();

  const [selectedAddress, setSelectedAddress] = useState(null);

  const [updateOrder, { isLoading: isLoadingUpdateOrder }] = useUpdateOrderMutation();

  const onUpdateAddress = (type = "", _id = "") => {
    const address = {
      shipping:
        data?.address?.shipping?._id ||
        (data?.address?.store?.address?.line1 && data?.address?.store?.address?.line2
          ? `${data.address.store.address.line1} ${data.address.store.address.line2}`
          : "") ||
        "",
      billing: data?.address?.billing?._id ?? "",
      [type]: _id,
    };

    console.log(type, _id, address);
    updateOrder({ id: data?._id, order: { address } })
      .unwrap()
      .then(() => {
        setSelectedAddress(null);
        dispatch(showSuccess({ message: "Address updated successfully!" }));
      })
      .catch((e) => {
        console.log(e);
        dispatch(
          showError({ message: e?.data?.message ?? e?.message ?? "Something went wrong!" })
        );
      });
  };

  return (
    <div className="bg-black-15 border-grey-5 rounded-8 p-3">
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center">
          <img
            src={userIcon}
            alt="userIcon"
            width={16}
          />
          <h6 className="text-lightBlue fw-500 ms-2">{!data?.customer ? "Guest" : "User"}</h6>
        </div>
        {/* <small className="text-blue-2 c-pointer">Edit</small> */}
      </div>

      <div className="d-flex justify-content-center">
        <hr className="hr-grey-6 w-100 my-3" />
      </div>
      <div className="d-flex align-items-center">
        <h6 className="fw-500 me-3">
          {!data?.customer ? (
            <span className="text-blue-2">
              {data?.address?.shipping?.firstName} {data?.address?.shipping?.lastName}
            </span>
          ) : (
            <Link
              className="text-blue-2"
              to={`/users/allUsers/details/%7B"id"%3A"${data?.customer?._id}"%7D`}>
              {data?.customer?.firstName} {data?.customer?.lastName}
            </Link>
          )}
        </h6>
        <img
          src={indiaFlag}
          alt="indiaFlag"
          height={15}
        />
      </div>
      {!!data?.customer &&
        (!showCxInfo ? (
          <div className="d-flex align-items-center justify-content-center mt-2">
            <Button
              size="small"
              variant="text"
              onClick={() => setShowCxInfo(false)}>
              <small className="text-blue-2">Hide</small>
            </Button>
          </div>
        ) : (
          <>
            <div className="d-flex align-items-center mt-2">
              <small className="text-lightBlue me-2">{data?.customer?.email}</small>
            </div>
            <div className="d-flex align-items-center mt-2">
              <small className="text-lightBlue me-2">
                +91 {data?.customer?.phone}
              </small>
            </div>
          </>
        ))}

      <div className="d-flex justify-content-center">
        <hr className="hr-grey-6 w-100 my-3" />
      </div>
      <div className="d-flex justify-content-between mb-3">
        <small className="text-grey-6 me-2">Shipping Address</small>
        {!showShipInfo ? (
          <Button
            size="small"
            variant="text"
            onClick={() => setShowShipInfo(true)}>
            <small className="text-blue-2">Show</small>
          </Button>
        ) : (
          <Button
            size="small"
            variant="text"
            onClick={() => setShowShipInfo(false)}>
            <small className="text-blue-2">Hide</small>
          </Button>
        )}
      </div>
      {showShipInfo && (
        <>
          {data?.address?.shipping ? (
            <>
              <small className="text-lightBlue me-3 d-block">
                {data.address.shipping.firstName} {data.address.shipping.lastName}
              </small>
              <div className="d-flex align-items-center">
                <small className="text-lightBlue me-3">
                  {data.address.shipping.line1} {data.address.shipping.line2} -{" "}
                  {data.address.shipping.city?.name}, {data.address.shipping.state?.name} -{" "}
                  {data.address.shipping.pinCode}, {data.address.shipping.country?.name}
                </small>
              </div>
              <div className="d-flex mt-2 align-items-center">
                <small className="text-grey-6 me-3 d-block me-3">
                  Email ID:&nbsp;
                  <span className="text-lightBlue">{data.address.shipping.email}</span>
                </small>
              </div>
              <div className="d-flex mt-2 align-items-center">
                <small className="text-grey-6 me-3">
                  Mobile No:&nbsp;
                  <span className="text-lightBlue">
                    {data.address.shipping.countryCode?.countryCode}-{data.address.shipping.phone}
                  </span>
                </small>
              </div>
            </>
          ) : (
            <>

              <small className="text-grey-6 fw-400">In-Store Pickup</small>

              <small className="text-lightBlue me-3 d-block">
                {data?.address?.store?.name}
              </small>
              <div className="d-flex align-items-center">
                <small className="text-lightBlue me-3">
                  {data?.address?.store?.address?.line1} {data?.address?.store?.address?.line2} -{" "}
                  {data?.address?.store?.address?.city?.name}, {data?.address?.store?.address?.state?.name} -{" "}
                  {data?.address?.store?.address?.pincode}, India
                </small>
              </div>
              <div className="d-flex mt-2 align-items-center">
                <small className="text-grey-6 me-3 d-block me-3">
                  Branch Code:&nbsp;
                  <span className="text-lightBlue">{data?.address?.store?.branchCode}</span>
                </small>
              </div>
              <div className="d-flex mt-2 align-items-center">
                <small className="text-grey-6 me-3">
                  Mobile No:&nbsp;
                  <span className="text-lightBlue">
+91 {data?.address?.store?.phone}
                  </span>
                </small>
              </div>
            </>
          )}
        </>
      )}


      <div className="d-flex justify-content-center">
        <hr className="hr-grey-6 w-100 my-3" />
      </div>
      <div className="d-flex justify-content-between mb-3">
        <small className="text-grey-6 me-2">Billing Address</small>
        {!showBillInfo ? (
          <Button
            size="small"
            variant="text"
            onClick={() => setShowBillInfo(true)}>
            <small className="text-blue-2">Show</small>
          </Button>
        ) : (
          <Button
            size="small"
            variant="text"
            onClick={() => setShowBillInfo(false)}>
            <small className="text-blue-2">Hide</small>
          </Button>
        )}
      </div>
      {showBillInfo && (
        <>
          <small className="text-lightBlue me-3 d-block">
            {data?.address?.billing?.firstName} {data?.address?.billing?.lastName}
          </small>
          <div className="d-flex align-items-center justify-content-between">
            <small className="text-lightBlue me-3">
              {data?.address?.billing?.line1} {data?.address?.billing?.line2} -{" "}
              {data?.address?.billing?.city?.name}, {data?.address?.billing?.state?.name} -{" "}
              {data?.address?.billing?.pinCode}, {data?.address?.billing?.country?.name}
            </small>
          </div>
          {showGST && data?.address?.billing?.gstIn && (
  <div className="d-flex mt-3">
    <small className="text-grey-6 me-3 d-block me-3">
      GSTIN:&nbsp;
      <span className="text-lightBlue">{data.address.billing.gstIn}</span>
    </small>
  </div>
)}

          <div className="d-flex mt-2 align-items-center">
            <small className="text-grey-6 me-3 d-block me-3">
              Email ID:&nbsp;
              <span className="text-lightBlue">{data?.address?.billing?.email}</span>
            </small>
          </div>
          <div className="d-flex mt-2 align-items-center">
            <small className="text-grey-6 me-3">
              Mobile No:&nbsp;
              <span className="text-lightBlue">
                +91 {data?.address?.billing?.phone}
              </span>
            </small>
          </div>
        </>
      )}

      {showReviewData && (
        <React.Fragment>
          <div className="d-flex justify-content-center">
            <hr className="hr-grey-6 w-100 my-3" />
          </div>
          <div className="d-flex justify-content-between mb-2">
            <small className="text-grey-6 me-2">Reviews</small>
            {/* <small className="text-blue-2">ask for feedback?</small> */}
            <small className="text-blue-2 c-pointer">Send Email</small>
          </div>
          <div className="d-flex flex-column">
            <small className="text-grey-6 d-block mt-2 ">
              <span
                className="text-blue-2 c-pointer"
                onClick={handleOpenReview}>
                #REV12345&nbsp;
              </span>
              •&nbsp;13/07/20222&nbsp;•&nbsp;09:23 am
            </small>
            <small className="text-grey-6 d-block mt-2 ">
              <span
                className="text-blue-2 c-pointer"
                onClick={handleOpenReview}>
                #REV12345&nbsp;
              </span>
              •&nbsp;13/07/20222&nbsp;•&nbsp;09:23 am
            </small>
          </div>

          {/* <Dialog
            open={openReview}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleOpenReviewClose}
            aria-describedby="alert-dialog-slide-description"
            maxWidth="sm"
            fullWidth={true}
          >
            <DialogTitle>
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex flex-column ">
                  <h5 className="text-lightBlue fw-500">Rating Review</h5>

                  <small className="text-grey-6 mt-1 d-block">
                    ⓘ Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </small>
                </div>
                <img
                  src={cancel}
                  alt="cancel"
                  width={30}
                  onClick={handleOpenReviewClose}
                  className="c-pointer"
                />
              </div>
            </DialogTitle>
            <hr className="hr-grey-6 my-0" />
            <DialogContent className="py-3 px-4">
              <p className="text-grey-6 mb-2">Rating</p>
              <Rating
                // size="small"
                name="half-rating-read"
                defaultValue={4}
                readOnly
              />
              <p className="text-grey-6 mt-3">Reviews</p>
              <p className="text-lightBlue mt-1">
                Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                Officiis quod delectus ex quam dolorum, obcaecati molestiae
                praesentium soluta beatae laborum. Perspiciatis iusto facere
                repellendus molestiae animi sapiente aliquam quidem quae.
              </p>
              <div className="d-flex">
                <div className="d-flex flex-column">
                  <p className="text-grey-6 mt-3">Product</p>
                  <p className="text-blue-2 mt-1 text-decoration-underline">
                    The Fringe Diamond Ring
                  </p>
                </div>
                <div className="d-flex flex-column ms-5">
                  <p className="text-grey-6 mt-3">Order ID</p>
                  <p className="text-blue-2 mt-1 text-decoration-underline">
                    #12345
                  </p>
                </div>
              </div>
              <p className="text-grey-6 mt-3">Customer</p>
              <p className="text-blue-2 mt-1 text-decoration-underline c-pointer">
                Saniya Shaikh
              </p>
              <p className="text-grey-6 mt-3">Created On</p>
              <p className="text-lightBlue mt-1 ">
                13/07/20222&nbsp;•&nbsp;09:23 am
              </p>
              <p className="text-grey-6 mt-3">Status</p>
              <div className="d-flex mt-1">
                <div className="rounded-pill d-flex table-status px-4 py-1 c-pointer">
                  <small className="text-black fw-400">Active</small>
                </div>
              </div>
            </DialogContent>
            <hr className="hr-grey-6 my-0" />
            <DialogActions className="d-flex justify-content-between px-4 py-3">
              <button
                className="button-green-outline py-2 px-4"
                onClick={handleOpenReviewClose}
              >
                <TaskAltIcon
                  sx={{
                    fontSize: 18,
                    cursor: "pointer",
                    "& :hover": { color: "green" },
                  }}
                />
                <p className="ms-2">Approve Reviews</p>
              </button>
              <button
                className="button-red-outline py-2 px-4"
                onClick={handleOpenReviewClose}
              >
                <BlockIcon
                  sx={{
                    fontSize: 18,
                    cursor: "pointer",
                    "& :hover": { color: "green" },
                  }}
                />
                <p className="ms-2">Reject Reviews</p>
              </button>
            </DialogActions>
          </Dialog> */}

          <Dialog
            open={openReview}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleOpenReviewClose}
            aria-describedby="alert-dialog-slide-description"
            maxWidth="sm"
            fullWidth={true}>
            <DialogTitle>
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex flex-column ">
                  <h5 className="text-lightBlue fw-500">Rating Review</h5>
                  <small className="text-grey-6 mt-1">13/07/2022 at 09:23am</small>
                </div>
                <img
                  src={cancel}
                  alt="cancel"
                  width={30}
                  onClick={handleOpenReviewClose}
                  className="c-pointer"
                />
              </div>
            </DialogTitle>
            <hr className="hr-grey-6 my-0" />
            <DialogContent className="py-3 px-4">
              <p className="text-grey-6 mb-2">Rating</p>
              <Rating
                // size="small"
                name="half-rating-read"
                defaultValue={4}
                readOnly
              />
              <p className="text-grey-6 mt-3">Reviews</p>
              <p className="text-lightBlue mt-1">
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Officiis quod delectus
                ex quam dolorum, obcaecati molestiae praesentium soluta beatae laborum.
                Perspiciatis iusto facere repellendus molestiae animi sapiente aliquam quidem
                quae.
              </p>
              <div className="d-flex">
                <div>
                  <p className="text-grey-6 mt-3">Product</p>
                  <p className="text-blue-2 mt-1 text-decoration-underline">
                    The Fringe Diamond Ring
                  </p>
                </div>
                <div className="ms-5">
                  <p className="text-grey-6 mt-3">Order ID</p>
                  <p className="text-blue-2 mt-1 text-decoration-underline">#12345</p>
                </div>
              </div>
              <p className="text-grey-6 mt-3">Customer</p>
              <p className="text-blue-2 mt-1 text-decoration-underline">Saniya Shaikh</p>
              <p className="text-grey-6 mt-3">Status</p>
              <div className="d-flex mt-1">
                <div className="rounded-pill d-flex table-status px-4 py-1 c-pointer">
                  <small className="text-black fw-400">Active</small>
                </div>
              </div>
            </DialogContent>
            <hr className="hr-grey-6 my-0" />
            <DialogActions className="d-flex justify-content-between px-4 py-3">
              <button
                className="button-green-outline py-2 px-4"
                onClick={handleOpenReviewClose}>
                <TaskAltIcon
                  sx={{
                    fontSize: 18,
                    cursor: "pointer",
                    "& :hover": { color: "green" },
                  }}
                />
                <p className="ms-2">Approve Reviews</p>
              </button>
              <button
                className="button-red-outline py-2 px-4"
                onClick={handleOpenReviewClose}>
                <BlockIcon
                  sx={{
                    fontSize: 18,
                    cursor: "pointer",
                    "& :hover": { color: "green" },
                  }}
                />
                <p className="ms-2">Reject Reviews</p>
              </button>
            </DialogActions>
          </Dialog>
        </React.Fragment>
      )}
      {showMediaData && (
        <React.Fragment>
          <div className="d-flex justify-content-center">
            <hr className="hr-grey-6 w-100 my-3" />
          </div>
          <div className="d-flex justify-content-between mb-3">
            <small className="text-grey-6 me-2">Media</small>
            <small
              className="text-blue-2 c-pointer"
              onClick={handleImportSecondOpen}>
              + Upload Media
            </small>
          </div>
          <div className="d-flex align-items-center flex-wrap">
            <img
              src={product2}
              alt="product2"
              className="me-1 mb-1"
              width={60}
            />
            <img
              src={product2}
              alt="product2"
              className="me-1 mb-1"
              width={60}
            />
            <img
              src={product2}
              alt="product2"
              className="me-1 mb-1"
              width={60}
            />
          </div>

          <Dialog
            open={openImportSecond}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleImportSecondClose}
            aria-describedby="alert-dialog-slide-description"
            maxWidth="sm"
            fullWidth={true}>
            <DialogTitle>
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex flex-column">
                  <h5 className="text-lightBlue fw-500">Media</h5>
                  <small className="text-grey-6 mt-1 d-block">
                    ⓘ Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </small>
                </div>
                <img
                  src={cancel}
                  alt="cancel"
                  width={30}
                  onClick={handleImportSecondClose}
                  className="c-pointer"
                />
              </div>
            </DialogTitle>
            <hr className="hr-grey-6 my-0" />
            <DialogContent className="py-4 px-4">
              <div className="d-flex flex-column">
                <UploadFile imageName={productInfoMedia2} />
              </div>
            </DialogContent>
            <hr className="hr-grey-6 my-0" />
            <DialogActions className="d-flex justify-content-between px-4 py-3">
              <button
                className="button-grey py-2 px-5"
                onClick={handleImportSecondClose}>
                <p className="text-lightBlue">Cancel</p>
              </button>
              <button
                className="button-gradient py-2 px-5"
                onClick={handleImportSecondClose}>
                <p>Save</p>
              </button>
            </DialogActions>
          </Dialog>
        </React.Fragment>
      )}
      <EditOrderAddress
        show={!!selectedAddress}
        address={selectedAddress}
        orderIsLoading={isLoadingUpdateOrder}
        onUpdate={onUpdateAddress}
        onClose={() => setSelectedAddress(null)}
      />
    </div>
  );
};

export default OrderUserDetails;
