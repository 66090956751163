import React, { forwardRef, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Popover,
  Select,
  Slide,
  Tooltip,
} from "@mui/material";
import cardIcon from "../../../assets/icons/cardIcon.svg";
import cancelIcon from "../../../assets/icons/cancel.svg";
import arrowDownIcon from "../../../assets/icons/arrowDown.svg";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import SearchBorder from "../../../components/SearchBorder/SearchBorder";

export default function CreateOrderPayment({ items = [] }) {
  return (
    <div className="bg-black-15 border-grey-5 rounded-8 p-3 row attributes mt-4">
      <div className="col-12 px-0">
        <div className="d-flex justify-content-between">
          <h6 className="fw-500 text-lightBlue">Total Order Value</h6>
          <h6 className="fw-500 text-lightBlue">{items.length} Items</h6>
          <h6 className="fw-500 text-lightBlue">₹ {items.reduce((t, i) => t + i.price, 0)}</h6>
        </div>
      </div>
    </div>
  );
}
