import { useEffect, useCallback, useReducer, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  useNavigate,
  useParams,
  useSearchParams,
  createSearchParams,
} from 'react-router-dom';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import {
  FormControl,
  OutlinedInput,
  Tooltip,
  Grid,
  FormHelperText,
} from '@mui/material';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import _ from 'lodash';

import InputBox from '../../../../components/Setting/InputBox';
import AppTextEditor from '../../../../components/AppTextEditor/AppTextEditor';
import Label from '../../../../components/Label/Label';
import { useFormikToastError } from '../../../../hooks/error';
import info from '../../../../assets/icons/info.svg';
import arrowLeft from '../../../../assets/icons/arrowLeft.svg';
import { AntSwitch } from '../../../../components/AntSwitch/AntSwitch';

import {
  showSuccess,
  showError,
} from '../../../../features/snackbar/snackbarAction';
import {
  useCreatePolicyMutation,
  useGetAllPoliciesQuery,
  useEditPolicyMutation,
} from '../../../../features/policies/policiesApiSlice';
import { SaveFooterTertiary } from '../../../../components/SaveFooter/SaveFooter';
import { omitEmptyKeys } from '../../../../utils/helper';

const policyValidationSchema = Yup.object().shape({
  name: Yup.string().trim().min(3, 'Too short').required('Required'),
  description: Yup.string()
    .trim()
    .when('isLink', {
      is: false,
      then: (schema) => schema.required('required'),
      otherwise: (schema) => schema.notRequired(),
    }),
  isLink: Yup.boolean(),
  isLinkExternal: Yup.boolean(),
  link: Yup.string()
    .trim()
    .when('isLink', {
      is: true,
      then: (schema) => schema.required('required'),
      otherwise: (schema) => schema.notRequired(),
    }),
});

const initialQueryFilterState = {
  pageSize: 1,
  pageNo: null,
  totalCount: 0,
};

const queryFilterReducer = (state, action) => {
  if (action.type === 'SET_PAGE_NO') {
    return {
      ...state,
      pageNo: +action.pageNo,
    };
  }
  if (action.type === 'SET_TOTAL_COUNT') {
    return {
      ...state,
      totalCount: action.totalCount,
    };
  }
  return initialQueryFilterState;
};

const CreateGuide = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let { id } = useParams();
  const [isEditing, setIsEditing] = useState(false);
  const [queryFilterState, dispatchQueryFilter] = useReducer(
    queryFilterReducer,
    initialQueryFilterState
  );

  const {
    data: policiesData,
    isLoading: policiesIsLoading,
    error: policiesError,
    isError: policiesIsError,
    isSuccess: policiesIsSuccess,
    isFetching: policiesDataIsFetching,
  } = useGetAllPoliciesQuery(
    { ...queryFilterState, id, type: 'guides' },
    {
      skip: id ? false : true,
    }
  );

  const [
    createPolicy,
    {
      isLoading: createPolicyIsLoading,
      isSuccess: createPolicyIsSuccess,
      error: createPolicyError,
      isError: createPolicyIsError,
    },
  ] = useCreatePolicyMutation();
  const [
    editPolicy,
    {
      isLoading: editPolicyIsLoading,
      isSuccess: editPolicyIsSuccess,
      error: editPolicyError,
      isError: editPolicyIsError,
    },
  ] = useEditPolicyMutation();

  const formik = useFormik({
    initialValues: {
      name: policiesData?.data[0].name || '',
      description: policiesData?.data[0].description || '',
      type: 'guides',
      isLink: policiesData?.data?.[0]?.isLink || false,
      isLinkExternal: policiesData?.data?.[0]?.isLinkExternal || false,
      link: policiesData?.data?.[0]?.link || '',
    },
    enableReinitialize: true,
    validationSchema: policyValidationSchema,
    onSubmit: (values) => {
      const policyValues = omitEmptyKeys(structuredClone(values));

      if (id) {
        formik.initialValues.name === formik.values.name &&
          delete policyValues.name;
        editPolicy({
          id,
          details: policyValues,
        })
          .unwrap()
          .then(() => {
            setIsEditing(false);
            dispatch(showSuccess({ message: 'Guide edited successfully' }));
          })
          .catch((error) => {
            if (error.data?.message) {
              dispatch(showError({ message: error.data.message }));
            } else {
              dispatch(
                showError({
                  message: 'Something went wrong!, please try again',
                })
              );
            }
          });
      } else {
        createPolicy(policyValues)
          .unwrap()
          .then(() => {
            formik.resetForm();
            dispatch(
              showSuccess({
                message: 'Guide created successfully',
              })
            );
            navigate({
              pathname: `/website/guides`,
            });
          })
          .catch((error) => {
            if (error.data?.message) {
              dispatch(showError({ message: error.data.message }));
            } else {
              dispatch(
                showError({
                  message: 'Something went wrong!, please try again',
                })
              );
            }
          });
      }
    },
  });

  useFormikToastError(formik);

  const backHandler = () => {
    navigate({
      pathname: `/website/guides`,
    });
  };

  const textEditorHandler = (des) => {
    if (des === '') {
      formik.setFieldValue('description', '<p></p>');
      if (!id) formik.setFieldValue('seo.description', '');
      return;
    }
    formik.setFieldValue('description', des);
  };

  // useEffect(() => {
  //   if (id) {
  //     dispatchQueryFilter({ type: 'SET_PAGE_NO', pageNo: id });
  //   }
  // }, [id]);

  useEffect(() => {
    if (policiesIsError) {
      if (policiesError?.data?.message) {
        dispatch(showError({ message: policiesError.data.message }));
      } else {
        dispatch(
          showError({ message: 'Something went wrong!, please try again' })
        );
      }
    }
    if (policiesIsSuccess) {
      dispatchQueryFilter({
        type: 'SET_TOTAL_COUNT',
        totalCount: policiesData.totalCount,
      });
    }
  }, [
    policiesData,
    policiesError,
    policiesIsError,
    policiesIsSuccess,
    dispatch,
  ]);

  useEffect(() => {
    if (id && !_.isEqual(formik.values, formik.initialValues)) {
      setIsEditing(true);
    } else if (id && _.isEqual(formik.values, formik.initialValues)) {
      setIsEditing(false);
    }
  }, [formik.initialValues, formik.values, id]);

  const isPageLoading =
    (id && policiesIsLoading) || (id && !queryFilterState.pageNo);

  console.log(formik.values);

  return (
    <div className='page container-fluid position-relative user-group product-tab-page'>
      <form className='product-form' noValidate onSubmit={formik.handleSubmit}>
        <div className='border-grey-5 mt-4 rounded-8 px-4 pt-4 bg-black-15 fit-Content'>
          <div className='d-flex justify-content-between mt-2'>
            <div className='d-flex c-pointer' onClick={backHandler}>
              <ChevronLeftIcon sx={{ color: '#c8d8ff' }} />
              <h4 className='page-heading w-auto ps-0 mr-2'>
                {formik.values.name || (id ? 'Edit Guide' : 'Create Guide')}
              </h4>
            </div>

            {/* <div className='d-flex align-items-center'>
            <InsertLinkIcon sx={{ color: '#c8d8ff' }} />
            <small className='text-lightBlue mr-1'>Copy Link</small>
          </div> */}
          </div>
          <hr className='hr-grey-6 my-4 w-100' />

          <div style={{ flex: 1 }}>
            <div className='d-flex mb-1'>
              <Label required={true}>Guide Name</Label>

              <Tooltip title='Lorem ipsum' placement='top'>
                <img
                  src={info}
                  alt='info'
                  className=' c-pointer'
                  width={13.5}
                />
              </Tooltip>
            </div>
            <FormControl className='w-100 px-0'>
              <OutlinedInput
                size='small'
                placeholder='Enter Name'
                sx={{ paddingLeft: 0 }}
                name='name'
                value={formik.values?.name}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                autoFocus={!id && true}
              />
              {!!formik.touched.name && formik.errors.name && (
                <FormHelperText error>{formik.errors.name}</FormHelperText>
              )}
            </FormControl>
          </div>
          <div className='mt-4 mb-4'>
            <p className='text-lightBlue fw-500'>Enable Link</p>

            <AntSwitch
              checked={formik.values.isLink}
              onChange={formik.handleChange}
              name='isLink'
              // inputProps={{ 'aria-label': 'controlled' }}
              // size='small'
              // style={{
              //   color: '#5C6D8E',
              // }}
            />

            {/* <Switch /> */}
          </div>
          <div className='mt-4 mb-4'>
            <p className='text-lightBlue fw-500'>Enable External</p>

            <AntSwitch
              checked={formik.values.isLinkExternal}
              onChange={formik.handleChange}
              name='isLinkExternal'
              // inputProps={{ 'aria-label': 'controlled' }}
              // size='small'
              // style={{
              //   color: '#5C6D8E',
              // }}
            />

            {/* <Switch /> */}
          </div>
          {formik.values.isLink ? (
            <div style={{ flex: 1 }} className='mb-4'>
              <div className='d-flex mb-1'>
                <Label required={formik.values.isLink}>Link</Label>

                <Tooltip title='Lorem ipsum' placement='top'>
                  <img
                    src={info}
                    alt='info'
                    className=' c-pointer'
                    width={13.5}
                  />
                </Tooltip>
              </div>
              <FormControl className='w-100 px-0'>
                <OutlinedInput
                  size='small'
                  placeholder='Enter Link'
                  sx={{ paddingLeft: 0 }}
                  name='link'
                  value={formik.values?.link}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                {!!formik.touched.link && formik.errors.link && (
                  <FormHelperText error>{formik.errors.link}</FormHelperText>
                )}
              </FormControl>
            </div>
          ) : (
            <>
              <div className='d-flex mt-4'>
                <Label required={true}>Description</Label>
                <Tooltip title='Some text' placement='top'>
                  <img
                    src={info}
                    alt='info'
                    className='ms-2 c-pointer'
                    width={13.5}
                  />
                </Tooltip>
              </div>
              <div className='mb-4'>
                <AppTextEditor
                  value={formik.values.description}
                  setFieldValue={textEditorHandler}
                  height={'20rem'}
                />
                {!!formik.touched.description && formik.errors.description && (
                  <FormHelperText error>
                    {formik.errors.description}
                  </FormHelperText>
                )}
              </div>
            </>
          )}
        </div>
        <SaveFooterTertiary
          show={id ? isEditing : true}
          onDiscard={backHandler}
          isLoading={createPolicyIsLoading}
        />
      </form>
    </div>
  );
};

export default CreateGuide;
