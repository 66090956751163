import React, { useReducer, useState } from "react";
// ! COMPONENT IMPORTS
import { EnhancedTableHead } from "../../../components/TableDependencies/TableDependencies";
// import TableEditStatusButton from "../../../components/TableEditStatusButton/TableEditStatusButton";
import TableMassActionButton from "../../../components/TableMassActionButton/TableMassActionButton";
// ! IMAGES IMPORTS
// import teamMember1 from "../../../assets/images/products/teamMember1.svg";
import sortIcon from "../../../assets/icons/sort.svg";
// ! MATERIAL IMPORTS
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Paper,
  Popover,
  Radio,
  RadioGroup,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Tabs,
  Tooltip,
} from "@mui/material";
// ! MATERIAL ICONS IMPORTS
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
// import InventoryIcon from "@mui/icons-material/Inventory";
import DeleteIcon from "@mui/icons-material/Delete";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { TableSearchSecondary } from "../../../components/TableSearch/TableSearch";
import { getSizeChartStatusColor, getSizeChartStatusFromTabIndex } from "./utils";
import {
  useBulkDeleteSizeChartMutation,
  useBulkEditSizeChartMutation,
  useDeleteSizeChartMutation,
  useGetAllSizeChartsQuery,
} from "../../../features/functionality/sizeChart/sizeChartApiSlice";
import moment from "moment/moment";
import TableLoader from "../../../components/Loader/TableLoader";
import NoDataFound from "../../../components/NoDataFound/NoDataFound";
import { useNavigate } from "react-router-dom";
// import UnArchivedModal from "../../Products/ProductReviews/UnarchiveDialog";
import { DeleteModalSecondary } from "../../../components/DeleteModal/DeleteModal";
// import { ArchiveModalSecondary } from "../../../components/ArchiveModal/ArchiveModal";
import { useDispatch } from "react-redux";
import { showError, showSuccess } from "../../../features/snackbar/snackbarAction";
import DuplicateDialog from "./DuplicateDialog";

// ? TABLE STARTS HERE

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "noOfProducts",
    numeric: false,
    disablePadding: false,
    label: "No. Of Products",
  },
  {
    id: "createdAt",
    numeric: false,
    disablePadding: false,
    label: "Modified On",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: true,
    label: "Status",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: true,
    label: "Actions",
  },
];
// ? TABLE ENDS HERE

const initialQueries = {
  name: "",
  status: "",
  alphabetical: "",
  createdAt: "-1",
  pageNo: 1,
  pageSize: 10,
  key: "",
  tabIndex: 0,
  selected: [],
  duplicating: null,
  archiving: null,
  deleting: null,
  unarchiving: null,
};

const queriesReducer = (state, action) => {
  switch (action.type) {
    case "SET_TAB_INDEX":
      return {
        ...state,
        pageNo: 1,
        name: "",
        selected: [],
        status: action.status,
        tabIndex: action.tabIndex,
      };

    case "SET_SEARCH":
      return {
        ...state,
        pageNo: 1,
        selected: [],
        name: action.name,
      };

    case "SET_PAGE_SIZE":
      return {
        ...state,
        pageNo: 1,
        selected: [],
        pageSize: +action.pageSize,
      };

    case "SET_ALPHABETICAL":
      return {
        ...state,
        pageNo: 1,
        selected: [],
        createdAt: "",
        alphabetical: action.alphabetical,
      };

    case "SET_CREATED_AT":
      return {
        ...state,
        pageNo: 1,
        selected: [],
        alphabetical: "",
        createdAt: action.createdAt,
      };

    case "SET_PAGE_NO":
      return {
        ...state,
        selected: [],
        pageNo: action.pageNo + 1,
      };

    case "SET_KEY":
      return {
        ...state,
        key: action.key,
      };

    case "SET_SELECTED":
      return {
        ...state,
        selected: action.selected,
      };

    case "SET_DUPLICATING":
      return {
        ...state,
        duplicating: action.duplicating,
      };

    case "SET_ARCHIVING":
      return {
        ...state,
        archiving: action.archiving,
      };

    case "SET_DELETING":
      return {
        ...state,
        deleting: action.deleting,
      };

    case "SET_UNARCHIVING":
      return {
        ...state,
        unarchiving: action.unarchiving,
      };

    case "SET_DEFAULT":
      return initialQueries;

    default:
      return state;
  }
};

const SizeChartTable = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [sortAnchorEl, setSortAnchorEl] = useState(null);
  const openSortMenu = Boolean(sortAnchorEl);
  const handleSortOpen = (event) => setSortAnchorEl(event.currentTarget);
  const handleSortClose = () => setSortAnchorEl(null);

  const [queries, dispatchQueries] = useReducer(queriesReducer, initialQueries);
  const { name, status, alphabetical, createdAt, pageNo, pageSize } = queries;
  const { data: sizeChartData, isLoading: sizeChartIsLoading } = useGetAllSizeChartsQuery({
    name,
    status,
    alphabetical,
    createdAt,
    pageNo,
    pageSize,
  });
  const allSizeCharts = sizeChartData?.data?.data ?? [];

  const [deleteSizeChart, { isLoading: deleteSizeChartIsLoading }] =
    useDeleteSizeChartMutation();

  const [bulkDeleteSizeChart, { isLoading: bulkDeleteSizeChartIsLoading }] =
    useBulkDeleteSizeChartMutation();
  const [bulkEditSizeChart] = useBulkEditSizeChartMutation();

  const clearSelected = () => dispatchQueries({ type: "SET_SELECTED", selected: [] });
  const setDuplicatingSizeChart = (chart) =>
    dispatchQueries({ type: "SET_DUPLICATING", duplicating: chart });
  const setDeletingSizeChart = (chart) =>
    dispatchQueries({ type: "SET_DELETING", deleting: chart });

  const onSizeChartsStatusChange = (status = "") => {
    if (queries.selected.length > 0)
      bulkEditSizeChart({ updates: queries.selected.map((rv) => ({ id: rv._id, status })) })
        .unwrap()
        .then(() => dispatch(showSuccess({ message: "SizeCharts updated successfully!" })))
        .catch((e) =>
          dispatch(showError({ message: e?.data?.message ?? "Something went wrong!" }))
        )
        .finally(() => clearSelected());
  };

  const onSizeChartsDelete = () => {
    if (queries.selected.length > 0)
      bulkDeleteSizeChart({ deletes: queries.selected.map((rv) => rv._id) })
        .unwrap()
        .then(() => dispatch(showSuccess({ message: "SizeCharts deleted successfully!" })))
        .catch((e) =>
          dispatch(showError({ message: e?.data?.message ?? "Something went wrong!" }))
        )
        .finally(() => {
          clearSelected();
          setDeletingSizeChart(null);
        });
    else
      deleteSizeChart(queries.deleting?._id)
        .unwrap()
        .then(() => dispatch(showSuccess({ message: "SizeChart deleted successfully!" })))
        .catch((e) =>
          dispatch(showError({ message: e?.data?.message ?? "Something went wrong!" }))
        )
        .finally(() => {
          clearSelected();
          setDeletingSizeChart(null);
        });
  };

  const handleMassAction = (action) => {
    switch (action) {
      case "Set Active":
        onSizeChartsStatusChange("active");
        break;
      case "Set In-Active":
        onSizeChartsStatusChange("in-active");
        break;

      default:
        break;
    }
  };

  return (
    <div className="row mt-4">
      <Paper
        sx={{ width: "100%", mb: 2, mt: 0, p: 0 }}
        className="border-grey-5 bg-black-15">
        <Box
          sx={{ width: "100%" }}
          className="d-flex justify-content-between tabs-header-box">
          {/* variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile */}
          <Tabs
            value={queries.tabIndex}
            onChange={(_, ix) =>
              dispatchQueries({
                type: "SET_TAB_INDEX",
                tabIndex: ix,
                status: getSizeChartStatusFromTabIndex(ix),
              })
            }
            aria-label="scrollable force tabs example"
            className="tabs">
            <Tab
              label="All"
              className="tabs-head"
            />
            <Tab
              label="Active"
              className="tabs-head"
            />
            <Tab
              label="In-Active"
              className="tabs-head"
            />
          </Tabs>
        </Box>
        <div className="d-flex align-items-center mt-3 mb-3 px-2 justify-content-between">
          <TableSearchSecondary
            value={queries.key}
            onSearchValueChange={(v) => dispatchQueries({ type: "SET_KEY", key: v })}
            onChange={(v) => dispatchQueries({ type: "SET_SEARCH", name: v })}
          />
          <p className="text-grey-6 mx-1">|</p>
          {/* <Button
            variant="text"
            onClick={handleSortOpen}
            startIcon={
              <img
                src={sortIcon}
                alt="icon"
                width={15}
              />
            }>
            <span className="text-grey-6">Sort</span>
          </Button> */}
          <button
            className="button-grey py-2 px-3"
            variant="contained"
            onClick={handleSortOpen}>
            <small className="text-lightBlue me-2">Sort</small>
            <img
              src={sortIcon}
              alt="sort"
              className=""
            />
          </button>
          <Popover
            className="columns"
            open={openSortMenu}
            anchorEl={sortAnchorEl}
            onClose={handleSortClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}>
            <FormControl className="px-2 py-1">
              <RadioGroup
                name="controlled-radio-buttons-group"
                onClick={handleSortClose}>
                <FormControlLabel
                  value="-1"
                  label="Newest to Oldest"
                  onChange={(e) =>
                    dispatchQueries({ type: "SET_CREATED_AT", createdAt: e.target.value })
                  }
                  control={
                    <Radio
                      size="small"
                      checked={queries.createdAt === "-1"}
                    />
                  }
                />
                <FormControlLabel
                  value="1"
                  label="Oldest to Newest"
                  onChange={(e) =>
                    dispatchQueries({ type: "SET_CREATED_AT", createdAt: e.target.value })
                  }
                  control={
                    <Radio
                      size="small"
                      checked={queries.createdAt === "1"}
                    />
                  }
                />
                <FormControlLabel
                  value="-1"
                  label="Alphabetical (Z-A)"
                  onChange={(e) =>
                    dispatchQueries({ type: "SET_ALPHABETICAL", alphabetical: e.target.value })
                  }
                  control={
                    <Radio
                      size="small"
                      checked={queries.alphabetical === "-1"}
                    />
                  }
                />
                <FormControlLabel
                  value="1"
                  label="Alphabetical (A-Z)"
                  onChange={(e) =>
                    dispatchQueries({ type: "SET_ALPHABETICAL", alphabetical: e.target.value })
                  }
                  control={
                    <Radio
                      size="small"
                      checked={queries.alphabetical === "1"}
                    />
                  }
                />
              </RadioGroup>
            </FormControl>
          </Popover>
        </div>
        {sizeChartIsLoading ? (
          <span className="d-flex justify-content-center m-3">
            <TableLoader />
          </span>
        ) : allSizeCharts.length === 0 ? (
          <span className="d-flex justify-content-center m-3">
            <NoDataFound />
          </span>
        ) : (
          <>
            {queries.selected.length > 0 && (
              <div className="d-flex align-items-center px-2 mb-3">
                <button className="button-grey py-2 px-3">
                  <small className="text-lightBlue">
                    {queries.selected.length} size chart are selected&nbsp;
                    <span
                      className="text-blue-2 c-pointer"
                      onClick={clearSelected}>
                      (Clear Selection)
                    </span>
                  </small>
                </button>
                <TableMassActionButton
                  headingName="Mass Action"
                  defaultValue={["Set Active", "Set In-Active"]}
                  onSelect={handleMassAction}
                />
                <button
                  className="button-grey py-2 px-3 ms-2"
                  variant="contained"
                  onClick={() => setDeletingSizeChart({})}>
                  <small className="text-lightBlue">Mass Delete</small>
                </button>
              </div>
            )}
            <TableContainer>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size="medium">
                <EnhancedTableHead
                  onSelectAllClick={(e) =>
                    dispatchQueries({
                      type: "SET_SELECTED",
                      selected: e.target.checked ? [...allSizeCharts] : [],
                    })
                  }
                  numSelected={queries.selected.length}
                  rowCount={allSizeCharts.length}
                  headCells={headCells}
                  onRequestSort={Date}
                  orderBy="groupName"
                  order="asc"
                />
                <TableBody>
                  {allSizeCharts.map((row) => {
                    const {
                      _id: rid = "",
                      createdAt = "",
                      name = "",
                      products = [],
                      status = "",
                    } = row ?? {};
                    const isItemSelected = queries.selected.includes(row);
                    const dateObj = moment(createdAt, moment.ISO_8601);
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={rid}
                        selected={isItemSelected}
                        className="table-rows">
                        <TableCell padding="checkbox">
                          <Checkbox
                            size="small"
                            style={{ color: "#5C6D8E" }}
                            checked={isItemSelected}
                            onClick={(e) =>
                              dispatchQueries({
                                type: "SET_SELECTED",
                                selected: e.target.checked
                                  ? queries.selected.concat(row)
                                  : queries.selected.filter((s) => !Object.is(s, row)),
                              })
                            }
                          />
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          padding="none">
                          <div className="text-decoration-none">
                            <p className="text-lightBlue rounded-circle fw-600">{name}</p>
                          </div>
                        </TableCell>
                        <TableCell style={{ width: 180 }}>
                          <p className="text-lightBlue">{products.length}</p>
                        </TableCell>
                        <TableCell style={{ width: 260 }}>
                          <div className="d-flex align-items-center">
                            <p className="text-lightBlue me-2">
                              {dateObj.format("DD/MM/YYYY at hh:mma")}
                            </p>
                            {/* <img
                              src={teamMember1}
                              alt="teamMember1"
                            /> */}
                          </div>
                        </TableCell>
                        <TableCell style={{ width: 120, padding: 0 }}>
                          <div className="d-flex align-items-center">
                            <div
                              style={{ backgroundColor: getSizeChartStatusColor(status) }}
                              className="rounded-pill d-flex table-status px-2 py-1">
                              <small className="text-capitalize text-black fw-400">
                                {status}
                              </small>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell style={{ width: 140, padding: 0 }}>
                          <div className="d-flex align-items-center">
                            <Tooltip
                              title="Edit"
                              placement="top">
                              <div
                                onClick={() => navigate(`edit/${rid}`)}
                                className="table-edit-icon rounded-4 c-pointer p-2">
                                <EditOutlinedIcon sx={{ color: "#5c6d8e", fontSize: 18 }} />
                              </div>
                            </Tooltip>
                            <Tooltip
                              title="Copy"
                              placement="top">
                              <div
                                onClick={() => setDuplicatingSizeChart(row)}
                                className="table-edit-icon rounded-4 c-pointer p-2">
                                <ContentCopyIcon sx={{ color: "#5c6d8e", fontSize: 18 }} />
                              </div>
                            </Tooltip>
                            <Tooltip
                              title="Delete"
                              placement="top">
                              <div
                                onClick={() => setDeletingSizeChart(row)}
                                className="table-edit-icon c-pointer rounded-4 p-2">
                                <DeleteIcon sx={{ color: "#5c6d8e", fontSize: 18 }} />
                              </div>
                            </Tooltip>
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 20, 30]}
              component="div"
              count={allSizeCharts.length}
              rowsPerPage={queries.pageSize}
              page={queries.pageNo - 1}
              onPageChange={(_, page) => dispatchQueries({ type: "SET_PAGE_NO", pageNo: page })}
              onRowsPerPageChange={(e) =>
                dispatchQueries({ type: "SET_PAGE_SIZE", pageSize: e.target.value })
              }
              className="table-pagination"
            />
          </>
        )}
      </Paper>

      <DuplicateDialog
        chart={queries.duplicating}
        onClose={() => setDuplicatingSizeChart(null)}
      />

      <DeleteModalSecondary
        onConfirm={onSizeChartsDelete}
        onCancel={() => setDeletingSizeChart(null)}
        show={!!queries.deleting}
        isLoading={deleteSizeChartIsLoading || bulkDeleteSizeChartIsLoading}
        message={
          queries.selected.length > 1
            ? `${queries.selected.length} size charts`
            : "the size chart"
        }
        title={queries.selected.length > 1 ? "Size Charts" : "Size Chart"}
      />
    </div>
  );
};

export default SizeChartTable;
