import apiSlice from "../../app/api/apiSlice";

export const otpApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    sendOtp: builder.mutation({
      query: (details) => ({
        url: "/storeDetails/sentOtp",
        method: "POST",
        body: details,
      }),
    }),
    verifyOtp: builder.mutation({
      query: (otp) => ({
        url: "/storeDetails/verify",
        method: "POST",
        body: otp,
      }),

    }),
  }),
  
});

export const {useSendOtpMutation, useVerifyOtpMutation } =
otpApiSlice;
