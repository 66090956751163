import React, { useCallback, useEffect, useState } from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import {
  useCreateNearStoreMutation as useCreateStoreMutation,
  useEditNearStoreMutation as useEditStoreMutation,
  useGetNearStoresQuery,
} from "../../../../features/store/useStore";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputAdornment,
  OutlinedInput,
  Tooltip,
} from "@mui/material";
import info from "../../../../assets/icons/info.svg";
import Label from "../../../../components/Label/Label";
import {
  showError,
  showSuccess,
} from "../../../../features/snackbar/snackbarAction";
import * as Yup from "yup";
import AppGenericSelect from "../../../../components/AppGenericSelect/AppGenericSelect";
import { useGetAllCountryQuery } from "../../../../features/master/country/countryApiSlice";
import ZipCodeComponent from "../../../../components/ZipCodeCityComponent";
import StoreHoursWeekDay from "../../../Products/CreateStore/StoreHoursWeekDay";
import { DiscardModalSecondary } from "../../../../components/Discard/DiscardModal";
import { LoadingButton } from "@mui/lab";
import UploadMediaBox from "../../../../components/UploadMediaBox/UploadMediaBox";
import StatusBox from "../../../../components/StatusBox/StatusBox";
import addMedia from "../../../../assets/icons/addMedia.svg";
import { AccessTime, LockClock } from "@mui/icons-material";
import { UploadMultipleMediaLarge } from "../../../../components/UploadMediaBox/UploadMedia";
import TableLoader from "../../../../components/Loader/TableLoader";
import { omitNullishKeys } from "../../../../utils/helper";

const CreateNewStoreWrapper = () => {
  const { id } = useParams();

  console.log(id, "Id Edit");

  const { data, isFetching } = useGetNearStoresQuery(
    { id },
    { skip: !Boolean(id) }
  );
  if (isFetching) {
    return <TableLoader />;
  }
  console.log(data?.data, "storeDetails");
  return <CreateNewStore data={data?.data?.[0]} isEdit={Boolean(id)} />;
};

const CreateNewStore = ({ data, isEdit = false }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let { id } = useParams();

  const [isEditing, setIsEditing] = useState(false);

  const backHandler = () => {
    navigate(-1);
  };
  const changeCountryCodeHandler = (_, value) =>
    formik.setFieldValue("countryCode", value?._id ?? "");
  const changeCityHandler = (_, value) =>
    formik.setFieldValue("address.city", value?._id ?? "");
  const changeStateHandler = (_, value) =>
    formik.setFieldValue("address.state", value?._id ?? "");
  const changeCountryHandler = (_, value) =>
    formik.setFieldValue("address.country", value?._id ?? "");
  const changeStoreStatus = (_, status) =>
    formik.setFieldValue("status", status);
  const [mediaUrl, setMediaUrl] = useState([]);
  useEffect(() => {
    if (Array.isArray(data?.mediaUrl)) {
      setMediaUrl(data?.mediaUrl);
    }
  }, [data?.mediaUrl]);

  const deleteMediaUrl = useCallback((value) => {
    setMediaUrl((prev) => prev.filter((e, i) => i !== value));
  }, []);
  const addMediaUrl = useCallback((value) => {
    if (Array.isArray(value)) {
      setMediaUrl(value.map((e) => ({ image: e, isDefault: false })));
    }
  }, []);
  const handleFormikBlur = (name) => formik.setFieldTouched(name, true);

  const onDiscardCreate = () => navigate(-1);

  const storeValidationSchema = Yup.object().shape({
    name: Yup.string().trim().required("required"),
    phone: Yup.string()
      .min(10, "Phone must be 10 digits long")
      .max(10, "Phone must be 10 digits long")
      .matches(/[0-9]/, "Phone number must only be digits")
      .required("required"),
    email: Yup.string()
      .trim()
      .email("email is not valid")
      .matches(/\.\w+$/, "email is not valid")
      .required("required"),
    countryCode: Yup.string().trim().required("required"),
    status: Yup.string().oneOf(["active", "in-active"]),
    address: Yup.object({
      country: Yup.string().required("required"),
      line1: Yup.string().required("required"),
      // line2: Yup.string(),
      city: Yup.string().required("required"),
      pincode: Yup.number().required("required"),
      state: Yup.string().required("required"),
      mapLink: Yup.string()
        .url("Invalid URL format")
        .matches(
          /^(https:\/\/www.google.com\/maps\/|https:\/\/goo.gl\/maps\/)/,
          "Must be a google maps link"
        ),
      // lattitude: Yup.string(),
      // longitude: Yup.string(),
    }),
    storeDays: Yup.string(),
    storeOpen: Yup.string(),
    storeClose: Yup.string(),
  });

  const [editStore] = useEditStoreMutation();
  const [createStore] = useCreateStoreMutation();

  console.log(data, "startData");

  const storeInitialValue = {
    name: data?.name || "",
    phone: data?.phone || "",
    email: data?.email || "",
    countryCode: data?.countryCode || "",
    isDefault: data?.isDefault || false,
    status: data?.status || "active",
    address: {
      country: data?.address?.country?._id || "",
      line1: data?.address?.line1 || "",
      line2: data?.address?.line2 || "",
      city: data?.address?.city?._id || "",
      pincode: data?.address?.pincode || "",
      state: data?.address?.state?._id || "",
      mapLink: data?.address?.mapLink || "",
      // lattitude: data?.address?.lattitude?.toString() || "",
      // longitude: data?.address?.longitude?.toString() || "",
    },
    storeDays: data?.hours?.storeDays || "",
    storeOpen: data?.hours?.storeStartTime || "",
    storeClose: data?.hours?.storeEndTime || "",
  };

  const formik = useFormik({
    initialValues: storeInitialValue,
    enableReinitialize: true,
    validationSchema: storeValidationSchema,
    onSubmit: (values) => {
      const vals = structuredClone(values);
      const hours = {
        storeDays: values.storeDays,
        storeStartTime: values.storeOpen,
        storeEndTime: values.storeClose,
      };
      const policyValues = {
        ...vals,
        mediaUrl,
        hours,
        address: omitNullishKeys({
          ...vals.address,
          longitude: vals.address?.mapLink?.split("@")[1]?.split(",")?.[0],
          lattitude: vals.address?.mapLink?.split("@")[1]?.split(",")?.[0],
        }),
      };
      if (isEdit) {
        editStore({
          id,
          details: policyValues,
        })
          .unwrap()
          .then(() => {
            setIsEditing(false);
            dispatch(showSuccess({ message: "Store edited successfully" }));
          })
          .catch((error) => {
            if (error.data?.message) {
              dispatch(showError({ message: error.data.message }));
            } else {
              dispatch(
                showError({
                  message: "Something went wrong!, please try again",
                })
              );
            }
          });
      } else {
        createStore(policyValues)
          .unwrap()
          .then(() => {
            formik.resetForm();
            dispatch(
              showSuccess({
                message: "Store created successfully",
              })
            );
            navigate(-1);
          })
          .catch((error) => {
            if (error.data?.message) {
              dispatch(showError({ message: error.data.message }));
            } else {
              dispatch(
                showError({
                  message: "Something went wrong!, please try again",
                })
              );
            }
          });
      }
    },
  });

  console.log(formik.errors, "submit");
  const setAddAnother = () => {
    formik.handleSubmit();
    // createDispatch({ type: "SET_ADD_ANOTHER", addAnother });
    // createDispatch({ type: "SET_DISCARD", discard: false });
  };

  return (
    <div className="page container-fluid position-relative user-group product-tab-page">
      <form
        className="product-form"
        noValidate
        //   onSubmit={formik.handleSubmit}
      >
        <div className="row">
          {/* <div className="col-md-9 mt-3"> */}
          <div className="col-lg-9 mt-3">
            <div className="bg-black-15 border-grey-5 rounded-8 p-3 row attributes">
              {/* <div className="border-grey-5 mt-4 rounded-8 px-4 pt-4 bg-black-15 fit-Content"> */}
              <div className="d-flex justify-content-between mt-2">
                <div className="d-flex c-pointer" onClick={backHandler}>
                  <ChevronLeftIcon sx={{ color: "#c8d8ff" }} />
                  <h4 className="page-heading w-auto ps-0 mr-2">
                    {/* {formik.values.name || (id ? "Edit Policy" : */}
                    {isEdit ? `Edit Store ${data?.name}` : "Create Store"}
                    {/* )} */}
                  </h4>
                </div>
              </div>
              <hr className="hr-grey-6 my-4 w-100" />
              <div style={{ flex: 1 }}>
                <div className="d-flex mb-1">
                  <Label required={true}>Store Name</Label>

                  <Tooltip title="Lorem ipsum" placement="top">
                    <img
                      src={info}
                      alt="info"
                      className=" c-pointer"
                      width={13.5}
                    />
                  </Tooltip>
                </div>
                <FormControl className="w-100 px-0">
                  <OutlinedInput
                    size="small"
                    placeholder="Enter Name"
                    sx={{ paddingLeft: 0 }}
                    name="name"
                    value={formik.values?.name}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    autoFocus={!id && true}
                  />
                  {!!formik.touched.name && formik.errors.name && (
                    <FormHelperText error>{formik.errors.name}</FormHelperText>
                  )}
                </FormControl>
              </div>
              <div className="col-md-12 mt-3">
                <p className="text-lightBlue mb-1">
                  Mobile Number{" "}
                  <FormHelperText className="d-inline" error>
                    *
                  </FormHelperText>
                </p>
                <FormControl className="w-100 px-0">
                  <OutlinedInput
                    placeholder="Enter Mobile Number"
                    size="small"
                    name="phone"
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    sx={{ paddingLeft: 0 }}
                    startAdornment={
                      <InputAdornment position="start">
                        <AppGenericSelect
                          hasImg
                          dataId="_id"
                          dataLabel="countryCode"
                          dataImgUrl="imageUrl"
                          name="countryCode"
                          value={formik.values.countryCode}
                          onChange={changeCountryCodeHandler}
                          onBlur={() => handleFormikBlur("countryCode")}
                          useGetQuery={useGetAllCountryQuery}
                          getQueryFilters={{ createdAt: -1 }}
                        />
                      </InputAdornment>
                    }
                  />
                  <FormHelperText error>
                    {formik.touched.countryCode && formik.errors.countryCode}{" "}
                    {formik.touched.phone && formik.errors.phone}
                  </FormHelperText>
                </FormControl>
              </div>
              <div className="col-md-12 mt-3">
                <div className="d-flex">
                  <p className="text-lightBlue mb-1">
                    Email ID{" "}
                    <FormHelperText className="d-inline" error>
                      *
                    </FormHelperText>
                  </p>

                  <Tooltip title="Lorem ipsum" placement="top">
                    <img
                      src={info}
                      alt="info"
                      className="ms-2 c-pointer"
                      width={13.5}
                    />
                  </Tooltip>
                </div>
                <FormControl className="w-100 px-0">
                  <OutlinedInput
                    placeholder="Enter Email ID"
                    size="small"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <FormHelperText error>
                    {formik.touched.email && formik.errors.email}
                  </FormHelperText>
                </FormControl>
              </div>
              <div className="col-md-12 mt-3">
                <UploadMultipleMediaLarge
                  fileSrc={mediaUrl}
                  style={{ minHeight: "20rem" }}
                  onUpload={addMediaUrl}
                  onRemove={deleteMediaUrl}
                />
              </div>
            </div>
            <div className="bg-black-15 border-grey-5 rounded-8 p-3 row attributes mt-4">
              <div className="d-flex col-12 px-0 justify-content-between">
                <h6 className="text-lightBlue me-auto text-lightBlue fw-500">
                  Addresses
                </h6>
              </div>
              <ZipCodeComponent
                errorLine1={formik.errors.address?.line1}
                touchedLine1={formik.touched.address?.line1}
                cityVal={formik.values.address?.city}
                countryVal={formik.values.address?.country}
                errorCity={formik.errors.address?.city}
                errorCountry={formik.errors.address?.country}
                errorState={formik.errors.address?.state}
                errorZip={formik.errors.address?.pincode}
                onchangeCity={changeCityHandler}
                onchangeCountry={changeCountryHandler}
                onchangeState={changeStateHandler}
                onchangeZip={(e) =>
                  formik.setFieldValue("address.pincode", e?.target?.value)
                }
                namePincode={"address.pincode"}
                nameLine2={"address.line2"}
                // onchangePincode={(e) =>
                //   formik.setFieldValue("address.pincode", e?.target?.value)
                // }
                stateVal={formik.values.address?.state}
                touchedCity={formik.touched.address?.city}
                touchedCountry={formik.touched.address?.country}
                touchedState={formik.touched.address?.state}
                touchedZip={formik.touched.address?.pincode}
                zipVal={formik.values.address?.pincode}
                onchangeLine1={(v) => formik.setFieldValue("address.line1", v)}
                onchangeLine2={(e) =>
                  formik.setFieldValue("address.line2", e?.target?.value)
                }
                valueLine2={formik.values.address?.line2}
                line1Val={formik.values.address?.line1}
              />
              {/* <div className="col-md-6 px-2 mt-3 add-user-country">
                <p className="text-lightBlue mb-1">Longitude</p>
                <FormControl className="w-100 px-0">
                  <OutlinedInput
                    placeholder="Enter Longitude"
                    size="small"
                    type="number"
                    name="address.longitude"
                    value={formik.values.address.longitude}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <FormHelperText error>
                    {formik.touched.address?.longitude &&
                      formik.errors.address?.longitude}
                  </FormHelperText>
                </FormControl>
              </div>
              <div className="col-md-6 px-2 mt-3 add-user-country">
                <p className="text-lightBlue mb-1">Latitude</p>
                <FormControl className="w-100 px-0">
                  <OutlinedInput
                    placeholder="Enter Latitude"
                    size="small"
                    type="number"
                    name="address.lattitude"
                    value={formik.values.address.lattitude}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <FormHelperText error>
                    {formik.touched.address?.lattitude &&
                      formik.errors.address?.lattitude}
                  </FormHelperText>
                </FormControl>
              </div> */}
              <div className="col-md-12 px-2 mt-3 add-user-country">
                <p className="text-lightBlue mb-1">Maps Link</p>
                <FormControl className="w-100 px-0">
                  <OutlinedInput
                    placeholder="Enter Maps Link"
                    size="small"
                    name="address.mapLink"
                    value={formik.values.address.mapLink}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <FormHelperText error>
                    {formik.touched.address?.mapLink &&
                      formik.errors.address?.mapLink}
                  </FormHelperText>
                </FormControl>
              </div>
            </div>
          </div>
          {/* </div> */}
          <div className="col-md-3 mt-3 pe-0 ps-0 ps-lg-3">
            <StatusBox
              name={"status"}
              value={formik?.values?.status}
              handleProductStatus={changeStoreStatus}
              headingName={"Store Visibility"}
              toggleDataLabel={["Visible", "Hidden"]}
            />
            <div className="bg-black-15 border-grey-5 rounded-8 p-3 m-0 mt-3 row attributes">
              <div className="d-flex col-12 px-0  justify-content-between">
                <div className="d-flex align-items-center">
                  <h6 className="text-lightBlue me-auto text-lightBlue fw-500">
                    Store Hours
                  </h6>
                  <Tooltip title="Lorem ipsum" placement="top">
                    <img
                      src={info}
                      alt="info"
                      className="ms-2 c-pointer"
                      width={13.5}
                    />
                  </Tooltip>
                </div>
              </div>
              <div className="col-md-12 mt-3 m-0">
                <p className="text-lightBlue mb-1">Days</p>
                <FormControl className="w-100 px-0">
                  <OutlinedInput
                    placeholder="Monday,Tuesday,Wednesday, etc."
                    size="small"
                    name="storeDays"
                    value={formik.values.storeDays}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <FormHelperText error>
                    {formik.touched.storeDays && formik.errors.storeDays}
                  </FormHelperText>
                </FormControl>
              </div>
              <div className="col-md-12 mt-3 m-0">
                <p className="text-lightBlue mb-1">Start time</p>
                <FormControl className="w-100 px-0">
                  <OutlinedInput
                    endAdornment={
                      <InputAdornment>
                        <AccessTime />
                      </InputAdornment>
                    }
                    placeholder="Enter start time"
                    size="small"
                    name="storeOpen"
                    value={formik.values.storeOpen}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <FormHelperText error>
                    {formik.touched.storeOpen && formik.errors.storeOpen}
                  </FormHelperText>
                </FormControl>
              </div>
              <div className="col-md-12 mt-3 m-0">
                <p className="text-lightBlue mb-1">End time</p>
                <FormControl className="w-100 px-0">
                  <OutlinedInput
                    endAdornment={
                      <InputAdornment>
                        <AccessTime />
                      </InputAdornment>
                    }
                    placeholder="Enter end time"
                    size="small"
                    name="storeClose"
                    value={formik.values.storeClose}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <FormHelperText error>
                    {formik.touched.storeClose && formik.errors.storeClose}
                  </FormHelperText>
                </FormControl>
              </div>
            </div>
          </div>
        </div>
        <div
          className="row create-buttons pt-5 pb-3 justify-content-between"
          style={{ width: "102%" }}
        >
          <div className="d-flex w-auto px-0">
            <button
              onClick={onDiscardCreate}
              className="button-red-outline py-2 px-4"
              type="button"
            >
              <p>Discard</p>
            </button>
          </div>
          <div className="d-flex w-auto px-0">
            {/* <button
              className="button-lightBlue-outline me-3 py-2 px-4 w-auto"
              type="submit"
              onClick={() => setAddAnother(true)}
            >
              <p>Save & Add Another</p>
            </button> */}
            <Button
              className="button-gradient ms-3 py-2 px-4 w-auto"
              // type="submit"
              onClick={() => setAddAnother(false)}
              // loading={createStoreIsLoading}
              // disabled={createStoreIsLoading}
            >
              <p>Save</p>
            </Button>
          </div>
        </div>
        <DiscardModalSecondary message="store tab" />
      </form>
    </div>
  );
};

export default CreateNewStoreWrapper;
