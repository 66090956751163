import { Chip, TextField } from "@mui/material";
import Heading from "../../components/Headers/DashboardHeader";
import SalesRatio from "./SalesRatio";
// import usa from "../../assets/flag/usa.svg";
// import germany from "../../assets/flag/germany.svg";
import maps from "../../assets/dashboard/Maps.svg";
import bharat from "../../assets/flag/india.svg";
import { useMemo, useState } from "react";
import { useGetGA4CountriesQuery } from "../../features/analytics/analyticsApiSlice";
import moment from "moment/moment";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import NoDataFound from "../../components/NoDataFound/NoDataFound";

const timeData = [
  { value: "week", name: "This Week" },
  { value: "month", name: "This Month" },
  { value: "year", name: "This Year" },
  { value: "", name: "Custom" },
];

const nowMoment = moment();

export default function SalesPerformance() {
  const [timeFrame, setTimeFrame] = useState("week");
  const [fromDate, setFromDate] = useState(moment().subtract(1, "week").format("YYYY-MM-DD"));
  const [toDate, setToDate] = useState(nowMoment.format("YYYY-MM-DD"));

  const filter = useMemo(() => {
    if (!timeFrame) return { to: toDate, from: fromDate };
    else return { type: timeFrame };
  }, [fromDate, timeFrame, toDate]);

  const { data } = useGetGA4CountriesQuery(filter);

  const salesData = useMemo(() => {
    const array = (data ?? []).map(({ country, currentValue, pastValue }) => ({
      percent: Math.round((currentValue / (currentValue + pastValue)) * 100),
      image: bharat,
      name: country,
      amount: currentValue,
      ratio: `+${Math.round((currentValue / (currentValue + pastValue)) * 100)}%`,
    }));
    // const lt = Math.min(dimensionValues.length, metricValues.length);
    // for (let i = 0; i < lt; i++) {
    //   const { value: name } = dimensionValues[i];
    //   const { value: amount } = metricValues[i];
    //   array.push({
    //     percent: 92,
    //     image: bharat,
    //     name,
    //     amount,
    //     ratio: "+10.5%",
    //   });
    // }
    return array;
  }, [data]);

  const onTimeFrameChange = (val) => {
    switch (val) {
      case "week":
        setTimeFrame("week");
        setFromDate(moment().subtract(1, "week").format("YYYY-MM-DD"));
        setToDate(moment().format("YYYY-MM-DD"));
        break;
      case "month":
        setTimeFrame("month");
        setFromDate(moment().subtract(1, "month").format("YYYY-MM-DD"));
        setToDate(moment().format("YYYY-MM-DD"));
        break;
      case "year":
        setTimeFrame("year");
        setFromDate(moment().subtract(1, "year").format("YYYY-MM-DD"));
        setToDate(moment().format("YYYY-MM-DD"));
        break;

      default:
        setTimeFrame("");
        break;
    }
  };

  return (
    <div className="bg-button-3 border-grey-5 px-3 py-3 rounded-8 mt-4">
      <Heading
        heading={"Top Sales Location"}
        // viewMore={"View More"}
      />
      <div className="d-flex align-items-center my-3">
        {timeData.map((item) => (
          <Chip
            key={item.value}
            onClick={() => onTimeFrameChange(item.value)}
            variant={timeFrame === item.value ? "filled" : "outlined"}
            label={item.name}
            className="mx-2"
          />
        ))}
        {!timeFrame && (
          <>
            <div className="mx-2">
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DesktopDatePicker
                  maxDate={moment()}
                  value={moment(fromDate)}
                  onChange={(newValue) => setFromDate(newValue.format("YYYY-MM-DD"))}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
            <div className="mx-2">
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DesktopDatePicker
                  maxDate={moment()}
                  value={moment(toDate)}
                  onChange={(newValue) => setToDate(newValue.format("YYYY-MM-DD"))}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
          </>
        )}
      </div>
      <div className="d-flex row justify-content-between">
        <div className="col-7">
          <img
            alt="maps"
            src={maps}
            width={430}
            height={240}
            className="mt-3"
          />
        </div>
        <div className="col-5">
          {salesData.length > 0 ? (
            salesData.map((item) => (
              <SalesRatio
                percent={item.percent}
                image={item.image}
                name={item.name}
                amount={item.amount}
                ratio={item.ratio}
              />
            ))
          ) : (
            <NoDataFound />
          )}
        </div>
      </div>
    </div>
  );
}
