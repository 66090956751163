import { useEffect, useState } from 'react';
import {
    FormControl,
    OutlinedInput,
    Tooltip,
    SwipeableDrawer
} from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Visibility } from '@mui/icons-material';
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";

import "./HelpCenter.scss";

import info from "../../assets/icons/info.svg";
import dots from "../../assets/icons/theme/ThreeDots.svg";
import arrowLeft from "../../assets/icons/arrowLeft.svg";
import info2 from "../../assets/icons/helpCenter/info.svg";
import msg from "../../assets/icons/helpCenter/message-square.svg";
import globe from "../../assets/icons/helpCenter/globe.svg";
import help from "../../assets/icons/helpCenter/help-circle.svg";
import clock from "../../assets/icons/helpCenter/clock.svg";
import help2 from "../../assets/icons/helpCenter/help2.svg";
import play from "../../assets/icons/helpCenter/play-circle.svg";
import setting from "../../assets/icons/theme/settings.svg";
import mail from "../../assets/icons/helpCenter/mail.svg";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function createData(name, img) {
    return { name, img };
}

const rows = [
    createData('Changing the login email for your Unthread account', info2),
    createData('Changing your Password', play),
    createData('How to do Scheduling', clock),
    createData('What can I sell on my site?', help2),
    createData('Choosing how to deliver your goods', globe),
    createData('Can customers from around the world purchase my...', help)
];

function createData2(name, img) {
    return { name, img };
}

const rows2 = [
    createData2('Open live chat', msg),
    createData2('Changing your Password', mail),
    createData2('Custom development Service', setting),
    createData2('Ask us about a topic', help),
];

const HelpCenter = ({ helpCenter }) => {
    const [activityDrawer, setActivityDrawer] = useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleActivityDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }
        setActivityDrawer({ ...activityDrawer, [anchor]: open });
    };

    const openHelp = () => {
        toggleActivityDrawer("right", true)()
    }

    const closeHelp = () => {
        toggleActivityDrawer("right", false)()
    }

    useEffect(() => {
        if (helpCenter) {
            openHelp();
        }
        if (!helpCenter) {
            closeHelp();
        }
    }, [helpCenter])

    return (
        <SwipeableDrawer
            anchor="right"
            open={activityDrawer["right"]}
            onClose={toggleActivityDrawer("right", false)}
            onOpen={toggleActivityDrawer("right", true)}
            className='helpCenter'
        >
            <div className='p-4' >
                <div
                    className='d-flex align-items-center c-pointer'
                    onClick={() => toggleActivityDrawer("right", false)()}
                >
                    <img src={arrowLeft} height={16} />
                    <p className='text-lightBlue fw-600 ms-4'>
                        Get Help and Learn
                    </p>
                </div>
                <div className='my-4'>
                    <div className='ex-small text-lightBlue'>
                        Everything you need to know about the product.
                    </div>
                    <div className='ex-small text-lightBlue'>
                        Can't find the answer you're looking for?
                        <span className='ex-small text-blue-gradient'>
                            {" "}Please chat to our friendly team.
                        </span>
                    </div>
                </div>
                <div className="mt-3 w-100">
                    <div className="d-flex align-items-center mb-1">
                        <small className="text-lightBlue fw-500">
                            Search our help articles
                        </small>
                        <Tooltip title={"text"} placement="top">
                            <img
                                src={info}
                                alt="info"
                                className="ms-2 c-pointer"
                                width={13.5}
                            />
                        </Tooltip>
                    </div>
                    <FormControl className="w-100 px-0">
                        <OutlinedInput
                            placeholder="Search"
                            size="small"
                        />
                    </FormControl>
                </div>
                <div className='py-4'>
                    <div className="d-flex align-items-center mb-2">
                        <small className="text-lightBlue fw-500">
                            Search our help articles
                        </small>
                        <Tooltip title={"text"} placement="top">
                            <img
                                src={info}
                                alt="info"
                                className="ms-2 c-pointer"
                                width={13.5}
                            />
                        </Tooltip>
                    </div>
                    <TableContainer component={Paper} sx={{ border: "1px solid #38395c" }}>
                        <Table aria-label="simple table">
                            <TableBody>
                                {rows.map((row) => (
                                    <TableRow
                                        key={row.name}
                                        sx={{ border: "1px solid #38395c" }}
                                    >
                                        <TableCell
                                            sx={{ display: "flex", justifyContent: "space-between" }}
                                        >
                                            <div>
                                                <img src={row.img} width={18} />
                                                <small className='ms-2 text-lightBlue'>{row.name}</small>
                                            </div>
                                            <ArrowForwardIosSharpIcon
                                                sx={{ color: '#c8d8ff', fontSize: "16px" }}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <div className='py-4'>
                    <div className="d-flex align-items-center mb-2">
                        <small className="text-lightBlue fw-500">
                            Contact Support
                        </small>
                        <Tooltip title={"text"} placement="top">
                            <img
                                src={info2}
                                alt="info"
                                className="ms-2 c-pointer"
                                width={13.5}
                            />
                        </Tooltip>
                    </div>
                    <TableContainer component={Paper} sx={{ border: "1px solid #38395c" }}>
                        <Table aria-label="simple table">
                            <TableBody>
                                {rows2.map((row) => (
                                    <TableRow
                                        key={row.name}
                                        sx={{ border: "1px solid #38395c" }}
                                    >
                                        <TableCell
                                            sx={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}
                                        >
                                            <div>
                                                <img src={row.img} width={18} />
                                                <small className='ms-2 text-lightBlue'>{row.name}</small>
                                            </div>
                                            <ArrowForwardIosSharpIcon
                                                sx={{ color: '#c8d8ff', fontSize: "16px" }}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
        </SwipeableDrawer >
    );
};

export default HelpCenter;