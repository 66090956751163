export function getReviewStatusColor(status) {
  switch (status) {
    case "pending":
      return "#FEECA8";
    case "approved":
      return "#A6FAAF";
    case "disapproved":
      return "#F67476";
    case "archived":
      return "#C8D8FF";
    default:
      return "inherit";
  }
}
export function getReviewStatusFromTabIndex(ix) {
  switch (ix) {
    case 0:
      return ["pending", "approved", "disapproved"];
    case 1:
      return ["pending"];
    case 2:
      return ["approved"];
    case 3:
      return ["disapproved"];
    case 4:
      return ["archived"];
    default:
      return [];
  }
}
