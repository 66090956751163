import apiSlice from "../../../app/api/apiSlice";

export const filemanagerApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getFiles: builder.query({
      query: (searchParams = {}) => {
        const queryString = new URLSearchParams(searchParams).toString();
        return { url: `/gallery/file?${queryString}` };
      },
      providesTags: ["FileManager"],
    }),

    createFile: builder.mutation({
      query: (fileData) => ({
        url: "/gallery/file",
        method: "POST",
        body: fileData,
      }),
      invalidatesTags: ["FileManager", "Logs"],
    }),

    editFile: builder.mutation({
      query: ({ id, fileData }) => ({
        url: `/gallery/file/${id}`,
        method: "PUT",
        body: fileData,
      }),
      invalidatesTags: ["FileManager", "Logs"],
    }),

    bulkEditFile: builder.mutation({
      query: (updates) => ({
        url: "/gallery/file/bulkUpdate",
        method: "PUT",
        body: updates,
      }),
      invalidatesTags: ["FileManager", "Logs"],
    }),

    deleteFile: builder.mutation({
      query: (id) => ({
        url: `/gallery/file/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["FileManager", "Logs"],
    }),

    bulkDeleteFiles: builder.mutation({
      query: (deletes) => ({
        url: "/gallery/file/bulkDelete",
        method: "DELETE",
        body: deletes,
      }),
      invalidatesTags: ["FileManager", "Logs"],
    }),

    getFolders: builder.query({
      query: (searchParams = {}) => {
        const queryString = new URLSearchParams(searchParams).toString();
        return { url: `/gallery/folder?${queryString}` };
      },
      providesTags: ["FileManager"],
    }),

    checkCopyFolders: builder.mutation({
      query: (data) => ({
        url: "/gallery/folder/checkCopy",
        method: "POST",
        body: data,
      }),
      transformResponse: (res) => res.data,
    }),

    checkFolderName: builder.query({
      query: (name = "") => ({
        url: `/gallery/folder?folderName=${name}`,
        method: "GET",
      }),
      transformResponse: (res) => res.data.totalCount,
    }),

    createFolder: builder.mutation({
      query: (folderData) => ({
        url: "/gallery/folder",
        method: "POST",
        body: folderData,
      }),
      invalidatesTags: ["FileManager", "Logs"],
    }),

    editFolder: builder.mutation({
      query: ({ id, folderData }) => ({
        url: `/gallery/folder/${id}`,
        method: "PUT",
        body: folderData,
      }),
      invalidatesTags: ["FileManager", "Logs"],
    }),

    deleteFolder: builder.mutation({
      query: (id) => ({
        url: `/gallery/folder/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["FileManager", "Logs"],
    }),

    bulkDeleteFolders: builder.mutation({
      query: (deletes) => ({
        url: "/gallery/folder/bulkDelete",
        method: "DELETE",
        body: deletes,
      }),
      invalidatesTags: ["FileManager", "Logs"],
    }),
  }),
});

export const {
  useGetFilesQuery,
  useCreateFileMutation,
  useEditFileMutation,
  useBulkEditFileMutation,
  useDeleteFileMutation,
  useBulkDeleteFilesMutation,
  useGetFoldersQuery,
  useCheckFolderNameQuery,
  useCheckCopyFoldersMutation,
  useCreateFolderMutation,
  useEditFolderMutation,
  useDeleteFolderMutation,
  useBulkDeleteFoldersMutation,
} = filemanagerApiSlice;
