import apiSlice from "../../app/api/apiSlice";

export const shippingApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getShipmentPricing: builder.query({
      query: (data) => ({
        url: "/products/shipping/recommendedPrice/",
        method: "POST",
        body: data,
      }),
      providesTags: ["Shipment", "Orders"],
    }),
    createShipment: builder.mutation({
      query: (data) => ({
        url: "/products/shipping/shippingOrder/",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Shipment", "Orders", "Timeline", "Logs"],
    }),
  }),
});

export const { useCreateShipmentMutation, useGetShipmentPricingQuery } = shippingApiSlice;
