import { useEffect, useCallback, useReducer, useState } from "react";
// import { useDispatch } from "react-redux";
import { useNavigate, useParams, useSearchParams, createSearchParams } from "react-router-dom";
import {
  Button,
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Tooltip,
} from "@mui/material";
// import DoneIcon from "@mui/icons-material/Done";
// import CloseIcon from "@mui/icons-material/Close";
import SettingsIcon from "@mui/icons-material/Settings";
import { styled } from "@mui/material/styles";
// import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
// import StepButton from "@mui/material/StepButton";
import StepLabel from "@mui/material/StepLabel";
// import Check from "@mui/icons-material/Check";
import StepConnector, { stepConnectorClasses } from "@mui/material/StepConnector";

import ProductInfo from "./ProductInfo/ProductInfo";
import MoreFeatures from "./MoreFeatures/MoreFeatures";
import { SEOTertiary } from "./SEO/SEOSecondary";
import Shipping from "./Shipping/Shipping";
import Variants from "./Variants/Variants";
import Options from "./Options/Options";
// import Attributes from "./Attributes/Attributes";
import PriceMaster from "./PriceMaster/PriceMaster";
import AppReactImageGallery from "../../../components/AppReactImageGallery/AppReactImageGallery";
import InfoHeader from "../../../components/Header/InfoHeader";

// import arrowLeft from "../../../assets/icons/arrowLeft.svg";
import info from "../../../assets/icons/info.svg";
// import paginationRight from "../../../assets/icons/paginationRight.svg";
// import paginationLeft from "../../../assets/icons/paginationLeft.svg";
// import gold from "../../../assets/images/products/gold.svg";
// import silver from "../../../assets/images/products/silver.svg";
// import platinum from "../../../assets/images/products/platinum.svg";

// import { omitEmptyKeys, pickExactObjKeys } from "../../../utils/helper";

import {
  useGetSingleProductQuery,
  useUpdateProductMutation,
} from "../../../features/products/product/productApiSlice";
import { useGetAllOptionSetsQuery } from "../../../features/parameters/options/optionSetsApiSlice";

import "./AddProduct.scss";
import { useDispatch } from "react-redux";
import { showError } from "../../../features/snackbar/snackbarAction";
import DuplicateDialog from "../AllProducts/ProductDuplicateDialog";

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: "linear-gradient(303.01deg,#5961f8 -4.4%,#f2d9f7 111.29%)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: "linear-gradient(303.01deg,#5961f8 -4.4%,#f2d9f7 111.29%)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => {
  return {
    backgroundColor: "#5c6d8e",
    zIndex: 1,
    color: "#fff",
    width: 20,
    height: 20,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    ...(ownerState.active &&
      !ownerState.disabled && {
        backgroundImage: "linear-gradient(303.01deg,#5961f8 -4.4%,#f2d9f7 111.29%)",
      }),
    ...(ownerState.completed &&
      !ownerState.disabled && {
        backgroundImage: "linear-gradient(303.01deg,#5961f8 -4.4%,#f2d9f7 111.29%)",
      }),
  };
});

const StepIcon = (props) => {
  const { active, completed, className, disabled } = props;

  const icons = {
    1: <SettingsIcon />,
    2: <SettingsIcon />,
    3: <SettingsIcon />,
    4: <SettingsIcon />,
    5: <SettingsIcon />,
    6: <SettingsIcon />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active, disabled }}
      className={className}>
      {/* {icons[String(props.icon)]} */}
    </ColorlibStepIconRoot>
  );
};

const STEPS = ["Product Info", "Options", "Price Master", "Variants", "More Features"];
const TOTAL_STEPS = STEPS.length;

const initialProductState = {
  productInfo: {
    title: "",
    description: "",
    status: "",
    mediaUrl: [],
    startDate: "",
    endDate: "",
    productType: {
      category: {},
      vendor: {},
      tagManager: [],
      collections: [],
      subCategory: {},
    },
    price: {
      dynamicPricing: false,
      price: "",
      discount: "",
      type: "",
      salePrice: "",
    },
    availableFor: {
      isReturnable: false,
      isCod: false,
      isLifeTimeExchange: false,
      isLifeTimeBuyBack: false,
      isNextDayShipping: false,
      isTryOn: false,
      isViewSimilarItem: false,
      returnValue: "",
      returnType: "",
    },
  },
  productOptions: null,
  firstRender: true,
  activeStep: null,
  // completed: {},
};

const productReducer = (state, action) => {
  if (action.type === "SET_PRODUCT_INFO") {
    return {
      ...state,
      productInfo: {
        ...state.productInfo,
        ...action.productInfo,
      },
    };
  }
  if (action.type === "SET_PRODUCT_OPTIONS") {
    return {
      ...state,
      productOptions: action.productOptions,
    };
  }
  if (action.type === "DISABLE_FIRST_RENDER") {
    return {
      ...state,
      firstRender: false,
    };
  }
  if (action.type === "ENABLE_FIRST_RENDER") {
    return {
      ...state,
      firstRender: true,
    };
  }
  if (action.type === "SET_ACTIVE_STEP") {
    return {
      ...state,
      activeStep: action.activeStep,
    };
  }
  return initialProductState;
};

const AddProduct = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams("");
  const [isEditing, setIsEditing] = useState(false);
  let { id } = useParams();
  const [productState, dispatchProduct] = useReducer(productReducer, initialProductState);

  const { data: productData, isSuccess: productIsSuccess } = useGetSingleProductQuery(
    { id },
    { skip: id ? false : true }
  );

  const { data: optionSetsData, isSuccess: optionSetsIsSuccess } = useGetAllOptionSetsQuery(
    { id: productData?.[0]?.setId ?? "" },
    { skip: !productData?.[0]?.setId }
  );

  const [updateProduct] = useUpdateProductMutation();

  const disableEditHandler = () => {
    setIsEditing(false);
  };
  const enableEditHandler = () => {
    setIsEditing(true);
  };

  const backHandler = () => {
    if (
      !!id &&
      productState.activeStep === 1 &&
      !productData?.[0]?.isCompleted &&
      !productData?.[0]?.price?.dynamicPricing &&
      productData?.[0]?.inventory?.inventoryOn !== "variant"
    ) {
      updateProduct({ id, details: { isCompleted: true, step: "4" } })
        .unwrap()
        .catch((e) =>
          dispatch(
            showError({ message: e?.data?.message ?? e?.message ?? "Something went wrong!" })
          )
        );
    }
    navigate({
      pathname: "/products/allProducts",
      search: `?${createSearchParams({
        filter: searchParams.get("filter"),
      })}`,
    });
  };

  const nextPageHandler = () => {
    // const { pageNo, totalCount } = queryFilterState;
    // if (pageNo + 1 > totalCount) {
    //   return;
    // }
    // navigate({
    //   pathname: `/parameters/productTabs/edit/${pageNo + 1}`,
    //   search: `?${createSearchParams({
    //     search: searchParams.get("search"),
    //   })}`,
    // });
  };

  const handleStep = (step = null) => {
    dispatchProduct({
      type: "SET_ACTIVE_STEP",
      activeStep: step,
    });
  };

  const handleNextStep = (skip = false, steps = 1) => {
    if (isEditing) {
      return;
    }
    if (productState.activeStep >= 4) {
      backHandler();
    } else {
      dispatchProduct({
        type: "SET_ACTIVE_STEP",
        activeStep: productState.activeStep + 1 + (skip ? steps : 0),
      });
    }
  };

  const handleBackStep = (skip = false, steps = 1) => {
    dispatchProduct({
      type: "SET_ACTIVE_STEP",
      activeStep: productState.activeStep - 1 - (skip ? steps : 0),
    });
  };

  const prevPageHandler = () => {
    // const { pageNo } = queryFilterState;
    // if (pageNo - 1 === 0) {
    //   return;
    // }
    // navigate({
    //   pathname: `/parameters/productTabs/edit/${pageNo - 1}`,
    //   search: `?${createSearchParams({
    //     search: searchParams.get("search"),
    //   })}`,
    // });
  };

  const productInfoHandler = useCallback((data) => {
    dispatchProduct({
      type: "SET_PRODUCT_INFO",
      productInfo: data,
    });
  }, []);

  const productOptionsHandler = useCallback((data) => {
    dispatchProduct({
      type: "SET_PRODUCT_OPTIONS",
      productOptions: data,
    });
  }, []);

  const enableFirstRender = useCallback(() => {
    dispatchProduct({ type: "ENABLE_FIRST_RENDER" });
  }, []);

  const [duplicatingProduct, setDuplicatingProduct] = useState(null);

  useEffect(() => {
    if (productIsSuccess) {
      dispatchProduct({
        type: "SET_PRODUCT_INFO",
        productInfo: productData[0],
      });
    }
  }, [productIsSuccess, productData]);

  useEffect(() => {
    if (optionSetsIsSuccess) {
      dispatchProduct({
        type: "SET_PRODUCT_OPTIONS",
        productOptions: optionSetsData?.data?.[0] ?? null,
      });
    }
  }, [optionSetsIsSuccess, optionSetsData?.data]);

  useEffect(() => {
    if (id ? productState.firstRender && productIsSuccess : productState.firstRender) {
      let step = !productData?.[0]?.isCompleted ? productData?.[0]?.step ?? 0 : 0;
      if (+step === 2 && !productData?.[0]?.isPriceMasterExist) step = 3;
      dispatchProduct({
        type: "SET_ACTIVE_STEP",
        activeStep: +step,
      });
      dispatchProduct({ type: "DISABLE_FIRST_RENDER" });
    }
  }, [id, productData, productIsSuccess, productState.firstRender]);

  useEffect(() => {
    const searchParamsObj = {
      filter: searchParams.get("filter"),
      activeStep: productState.activeStep,
    };
    if (searchParams.get("create")) {
      searchParamsObj.create = searchParams.get("create");
    }
    if (!productState.firstRender) {
      setSearchParams(searchParamsObj, { replace: true });
    }
  }, [searchParams, setSearchParams, productState.firstRender, productState.activeStep]);

  const isCreateMode = searchParams.get("create");

  let activeScreen = <></>;
  switch (productState.activeStep) {
    case 0: {
      activeScreen = (
        <ProductInfo
          isCompleted={productData?.[0]?.isCompleted ?? false}
          step={productState.activeStep + 1}
          onProductInfo={productInfoHandler}
          onNext={handleNextStep}
          enableFirstRender={enableFirstRender}
          onDisableEdit={disableEditHandler}
          onEnableEdit={enableEditHandler}
          isCreateMode={isCreateMode}
        />
      );
      break;
    }
    case 1: {
      activeScreen = (
        <Options
          step={productState.activeStep + 1}
          onOptionsChange={productOptionsHandler}
          onBack={handleBackStep}
          onNext={handleNextStep}
          isCreateMode={isCreateMode}
        />
      );
      break;
    }
    case 2: {
      activeScreen = (
        <PriceMaster
          step={productState.activeStep + 1}
          onBack={handleBackStep}
          onNext={handleNextStep}
          isCreateMode={isCreateMode}
        />
      );
      break;
    }
    case 3: {
      activeScreen = (
        <Variants
          productTitle={productData?.[0]?.title ?? ""}
          isCompleted={productData?.[0]?.isCompleted ?? false}
          step={productState.activeStep + 1}
          onBack={handleBackStep}
          onNext={handleNextStep}
          isCreateMode={isCreateMode}
          isInventoryVariants={productData?.[0]?.inventory?.inventoryOn === "variant"}
        />
      );
      break;
    }
    // case 4: {
    //   activeScreen = (
    //     <Shipping
    //       step={productState.activeStep + 1}
    //       onBack={handleBackStep}
    //       onNext={handleNextStep}
    //       onDisableEdit={disableEditHandler}
    //       onEnableEdit={enableEditHandler}
    //       optionEnabled={productState?.productInfo?.setId}
    //       isCreateMode={isCreateMode}
    //     />
    //   );
    //   break;
    // }
    case 4: {
      activeScreen = (
        <MoreFeatures
          labels={productData?.[0]?.labels?._id ?? ""}
          sizeChart={productData?.[0]?.sizeChart?._id ?? ""}
          onBack={handleBackStep}
          onNext={handleNextStep}
        />
      );
      break;
    }
    // case 6: {
    //   activeScreen = (
    //     <SEOTertiary
    //       step={"completed"}
    //       onBack={handleBackStep}
    //       onNext={handleNextStep}
    //       title={productState?.productInfo?.title}
    //       description={productState?.productInfo?.description}
    //       onDisableEdit={disableEditHandler}
    //       onEnableEdit={enableEditHandler}
    //       isCreateMode={isCreateMode}
    //       initialOpen={false}
    //     />
    //   );
    //   break;
    // }

    default:
      break;
  }

  return (
    <div className="page container-fluid position-relative">
      <InfoHeader
        product={productData?.[0]}
        title={productState?.productInfo?.title || (id ? "Edit Product" : "Create Product")}
        onBack={backHandler}
        onPreview={
          productData?.[0]?.isCompleted && productData?.[0]?.status === "active"
            ? `${process.env.REACT_APP_FE_BASE_URL}/products/description/${productData?.[0]?.seo?.slug}`
            : ""
        }
        onPrev={prevPageHandler}
        onNext={nextPageHandler}
        onDuplicate={() => setDuplicatingProduct(productData?.[0])}
        isEdit={!!id}
      />
      <div className="row">
        <div className="col-lg-9 mt-3">
          <div className="row flex-column mb-2">
            {productState.activeStep !== null && (
              <Stepper
                alternativeLabel
                activeStep={productState.activeStep}
                connector={<ColorlibConnector />}>
                {STEPS.map((label, ix) => {
                  const isDisabled =
                    (ix === 3 && !productData?.[0]?.setId) ||
                    (ix === 2 &&
                      (!productData?.[0]?.isPriceMasterExist ||
                        !productData?.[0]?.price?.dynamicPricing)) ||
                    (ix !== 3 &&
                      ix !== 2 &&
                      !productData?.[0]?.isCompleted &&
                      +productData?.[0]?.step < ix);
                  return (
                    <Step key={label}>
                      <StepLabel
                        className={`step-label${!isDisabled ? " enabled" : ""}`}
                        style={{ cursor: !isDisabled ? "pointer" : "not-allowed" }}
                        onClick={!isDisabled ? () => handleStep(ix) : null}
                        StepIconComponent={(props) =>
                          StepIcon({ ...props, disabled: isDisabled })
                        }>
                        {label}
                      </StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
            )}
            <div className="mt-3">{activeScreen}</div>
          </div>
        </div>
        <div className="col-lg-3 mt-3 pe-0 ps-0 ps-lg-3">
          <div className="bg-black-15 border-grey-5 rounded-8 p-3">
            <h6 className="text-grey-6 mb-3">Preview:</h6>
            {!!productState?.productInfo?.mediaUrl?.length && (
              <AppReactImageGallery images={productState?.productInfo?.mediaUrl} />
            )}
            <p
              className="mt-3 text-lightBlue"
              style={{
                wordBreak: "break-all",
                whiteSpace: "normal",
                fontWeight: "500",
                fontSize: "16px",
              }}>
              {productState?.productInfo?.title || ""}
            </p>
            <small className="text-grey-6 my-2">
              {`${
                productState?.productInfo?.productType?.category?.name
                  ? `${productState?.productInfo?.productType?.category?.name}`
                  : ""
              } ${
                productState?.productInfo?.productType?.subCategory?.name
                  ? `| ${productState?.productInfo?.productType?.subCategory?.name}`
                  : ""
              } ${
                productState?.productInfo?.productType?.vendor?.name
                  ? `| ${productState?.productInfo?.productType?.vendor?.name} `
                  : ""
              }`}
            </small>
            {!productState?.productInfo?.price?.dynamicPricing && (
              <div className="d-flex my-4 flex-wrap">
                <h6 className="text-lightBlue">
                  {productState?.productInfo?.price?.salePrice
                    ? `₹ ${productState?.productInfo?.price?.salePrice}`
                    : productState?.productInfo?.price?.price
                    ? `₹ ${productState?.productInfo?.price?.price}`
                    : ""}
                </h6>

                {+productState?.productInfo?.price?.discount > 0 &&
                  productState?.productInfo?.price?.price && (
                    <small className="ms-2 me-2 text-grey-6 ">
                      <s>{`₹ ${productState?.productInfo?.price?.price || ""}`}</s>
                    </small>
                  )}
                {+productState?.productInfo?.price?.discount > 0 && (
                  <small className="text-lightBlue">{`${
                    productState?.productInfo?.price?.discount
                  }${
                    productState?.productInfo?.price?.type === "percentage" ? "%" : "₹"
                  } OFF`}</small>
                )}
              </div>
            )}
            {productState?.productOptions?.option?.map((opt) => {
              return (
                <OptionComponent
                  key={opt._id}
                  attribute={opt.attribute[0]}
                />
              );
            })}
          </div>
          {/* <div className='bg-black-15 border-grey-5 rounded-8 p-3 row mx-0 mt-4'>
            <div className='col-12 px-0'>
              <div className='d-flex justify-content-between align-items-center mb-3'>
                <div className='d-flex me-1'>
                  <h6 className='text-lightBlue px-0 fw-500 me-2'>
                    Product Applicable For
                  </h6>
                  <Tooltip title='Lorem ipsum' placement='top'>
                    <img
                      src={info}
                      alt='info'
                      className='c-pointer'
                      width={13.5}
                    />
                  </Tooltip>
                </div>
                {/* <small className="c-pointer text-blue-2">Edit</small> /}
              </div>
              {productState?.productInfo?.availableFor?.isReturnable && (
                <div className='d-flex align-items-center mb-2'>
                  <p className='text-lightBlue ms-2'>Returnable</p>{' '}
                  {productState?.productInfo?.availableFor?.returnValue && (
                    <p className='text-lightBlue'>{`for ${productState?.productInfo?.availableFor?.returnValue} ${productState?.productInfo?.availableFor?.returnType}`}</p>
                  )}
                </div>
              )}
              {productState?.productInfo?.availableFor?.isCod && (
                <div className='d-flex align-items-center mb-2'>
                  <p className='text-lightBlue ms-2'>COD</p>
                </div>
              )}
              {productState?.productInfo?.availableFor?.isLifeTimeExchange && (
                <div className='d-flex align-items-center mb-2'>
                  <p className='text-lightBlue ms-2'>Lifetime Exchange</p>
                </div>
              )}
              {productState?.productInfo?.availableFor?.isLifeTimeBuyBack && (
                <div className='d-flex align-items-center mb-2'>
                  <p className='text-lightBlue ms-2'>Lifetime Buyback</p>
                </div>
              )}
              {productState?.productInfo?.availableFor?.isNextDayShipping && (
                <div className='d-flex align-items-center mb-2'>
                  <p className='text-lightBlue ms-2'>Next Day Shipping</p>
                </div>
              )}
              {productState?.productInfo?.availableFor?.isTryOn && (
                <div className='d-flex align-items-center mb-2'>
                  <p className='text-lightBlue ms-2'>Enable Try On</p>
                </div>
              )}
              {productState?.productInfo?.availableFor?.isViewSimilarItem && (
                <div className='d-flex align-items-center mb-2'>
                  <p className='text-lightBlue ms-2'>
                    Enable to View Similar Items
                  </p>
                </div>
              )}
            </div>
          </div> */}
        </div>
      </div>

      <DuplicateDialog
        product={duplicatingProduct}
        onClose={(nav) => {
          setDuplicatingProduct(null);
          if (nav)
            navigate({
              pathname: "/products/allProducts",
              search: `?${createSearchParams({
                filter: searchParams.get("filter"),
              })}`,
            });
        }}
      />
    </div>
  );
};

export default AddProduct;

function OptionComponent({ attribute }) {
  const [selected, setSelected] = useState("");
  const selectedSubAttribute =
    attribute.metaAttributes.find((mattr) => mattr._id === selected) ?? null;
  useEffect(() => {
    if (!selected) {
      setSelected(attribute.metaAttributes[0]._id);
    }
  }, [attribute.metaAttributes, selected]);
  switch (attribute.apperance) {
    case "dropDownList":
      return (
        <div className="row my-4">
          <div className="col-12">
            <p className="text-lightBlue mb-2">{attribute.frontEndTitle}:</p>
            <FormControl
              size="small"
              sx={{ m: 0, minWidth: 120, width: "100%" }}>
              <Select
                size="small"
                value={selected}
                onChange={(e) => setSelected(e.target.value)}>
                {attribute.metaAttributes.map((mattr) => (
                  <MenuItem
                    sx={{ fontSize: 13, color: "#5c6d8e" }}
                    value={mattr._id}
                    key={mattr._id}>
                    {mattr.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          {selectedSubAttribute &&
            selectedSubAttribute.metaSubAttribute.length > 0 &&
            selectedSubAttribute.metaSubAttribute.map((msattr) => (
              <NestedOptionComponent
                key={msattr._id}
                attribute={msattr}
              />
            ))}
        </div>
      );
    case "dropDownThumbnail":
      return (
        <div className="row my-4">
          <div className="col-12">
            <p className="text-lightBlue mb-2">{attribute.frontEndTitle}:</p>
            <FormControl
              size="small"
              sx={{ m: 0, minWidth: 120, width: "100%" }}>
              <Select
                size="small"
                value={selected}
                onChange={(e) => setSelected(e.target.value)}>
                {attribute.metaAttributes.map((mattr) => (
                  <MenuItem
                    sx={{ fontSize: 13, color: "#5c6d8e" }}
                    value={mattr._id}
                    key={mattr._id}>
                    <img
                      className="me-2"
                      src={mattr.imageUrl}
                      alt={mattr.title}
                      height={20}
                      width={20}
                    />
                    {mattr.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          {selectedSubAttribute &&
            selectedSubAttribute.metaSubAttribute.length > 0 &&
            selectedSubAttribute.metaSubAttribute.map((msattr) => (
              <NestedOptionComponent
                key={msattr._id}
                attribute={msattr}
              />
            ))}
        </div>
      );
    case "colorAndImageSwatches":
      return (
        <div className="row my-4">
          <div className="col-12">
            <p className="text-lightBlue mb-2">{attribute.frontEndTitle}:</p>
            <div className="d-flex align-items-center flex-wrap">
              {attribute.metaAttributes.map((mattr) => (
                <Button
                  key={mattr._id}
                  variant="text"
                  onClick={() => setSelected(mattr._id)}
                  disabled={selected === mattr._id}
                  className="d-flex align-items-center px-2 py-1 mx-2 rounded-pill c-pointer"
                  style={
                    selected === mattr._id
                      ? {
                          background:
                            "linear-gradient(303.01deg, #2f2e69 -4.4%, #514969 111.29%)",
                        }
                      : {}
                  }>
                  {mattr?.imageUrl && (
                    <img
                      src={mattr?.imageUrl}
                      alt="icon"
                      style={{
                        width: "20px",
                        height: "20px",
                        borderRadius: "50%",
                      }}
                      className="me-2"
                    />
                  )}
                  {mattr?.colour && (
                    <div
                      style={{
                        width: "20px",
                        height: "20px",
                        borderRadius: "50%",
                        backgroundColor: mattr?.colour,
                      }}
                      className="me-2"
                    />
                  )}
                  <small className="fw-400 text-lightBlue">{mattr.title}</small>
                </Button>
              ))}
            </div>
          </div>
          {selectedSubAttribute &&
            selectedSubAttribute.metaSubAttribute.length > 0 &&
            selectedSubAttribute.metaSubAttribute.map((msattr) => (
              <NestedOptionComponent
                key={msattr._id}
                attribute={msattr}
              />
            ))}
        </div>
      );
    case "radioButtons":
      return (
        <div className="row my-4">
          <div className="col-12">
            <p className="text-lightBlue mb-2">{attribute.frontEndTitle}:</p>
            <FormControl
              size="small"
              sx={{ m: 0, minWidth: 120, width: "100%" }}>
              <RadioGroup
                value={selected}
                onChange={(e) => setSelected(e.target.value)}>
                {attribute.metaAttributes.map((mattr) => (
                  <FormControlLabel
                    key={mattr._id}
                    label={mattr.title}
                    value={mattr._id}
                    control={<Radio size="small" />}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </div>
          {selectedSubAttribute &&
            selectedSubAttribute.metaSubAttribute.length > 0 &&
            selectedSubAttribute.metaSubAttribute.map((msattr) => (
              <NestedOptionComponent
                key={msattr._id}
                attribute={msattr}
              />
            ))}
        </div>
      );
    case "rectangleButtons":
      return (
        <div className="row my-4">
          <div className="col-12">
            <p className="text-lightBlue mb-2">{attribute.frontEndTitle}:</p>
            <div className="d-flex align-items-center flex-wrap">
              {attribute.metaAttributes.map((mattr) => (
                <Button
                  key={mattr._id}
                  className="mx-1"
                  onClick={() => setSelected(mattr._id)}
                  disabled={selected === mattr._id}
                  variant={selected === mattr._id ? "contained" : "outlined"}>
                  <small className="fw-400 text-lightBlue">{mattr.title}</small>
                </Button>
              ))}
            </div>
          </div>
          {selectedSubAttribute &&
            selectedSubAttribute.metaSubAttribute.length > 0 &&
            selectedSubAttribute.metaSubAttribute.map((msattr) => (
              <NestedOptionComponent
                key={msattr._id}
                attribute={msattr}
              />
            ))}
        </div>
      );
    case "circleButtons":
      return (
        <div className="row my-4">
          <div className="col-12">
            <p className="text-lightBlue mb-2">{attribute.frontEndTitle}:</p>
            <div className="d-flex align-items-center flex-wrap">
              {attribute.metaAttributes.map((mattr) => (
                <Button
                  key={mattr._id}
                  sx={{ borderRadius: "50%" }}
                  className="mx-1"
                  onClick={() => setSelected(mattr._id)}
                  disabled={selected === mattr._id}
                  variant={selected === mattr._id ? "contained" : "outlined"}>
                  <small className="fw-400 text-lightBlue">{mattr.title}</small>
                </Button>
              ))}
            </div>
          </div>
          {selectedSubAttribute &&
            selectedSubAttribute.metaSubAttribute.length > 0 &&
            selectedSubAttribute.metaSubAttribute.map((msattr) => (
              <NestedOptionComponent
                key={msattr._id}
                attribute={msattr}
              />
            ))}
        </div>
      );

    default:
      return null;
  }
}

function NestedOptionComponent({ attribute }) {
  const [selected, setSelected] = useState("");
  useEffect(() => {
    if (!selected) {
      setSelected(attribute.metaSubAttributeValue[0]._id);
    }
  }, [attribute.metaSubAttributeValue, selected]);
  switch (attribute.apperance) {
    case "dropDownList":
      return (
        <div className="col-12 mt-3">
          <p className="text-lightBlue mb-2">{attribute.title}:</p>
          <FormControl
            size="small"
            sx={{ m: 0, minWidth: 120, width: "100%" }}>
            <Select
              size="small"
              value={selected}
              onChange={(e) => setSelected(e.target.value)}>
              {attribute.metaSubAttributeValue.map((mattr) => (
                <MenuItem
                  sx={{ fontSize: 13, color: "#5c6d8e" }}
                  value={mattr._id}
                  key={mattr._id}>
                  {mattr.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      );
    case "dropDownThumbnail":
      return (
        <div className="col-12 mt-3">
          <p className="text-lightBlue mb-2">{attribute.frontEndTitle}:</p>
          <FormControl
            size="small"
            sx={{ m: 0, minWidth: 120, width: "100%" }}>
            <Select
              size="small"
              value={selected}
              onChange={(e) => setSelected(e.target.value)}>
              {attribute.metaAttributes.map((mattr) => (
                <MenuItem
                  sx={{ fontSize: 13, color: "#5c6d8e" }}
                  value={mattr._id}
                  key={mattr._id}>
                  <img
                    className="me-2"
                    src={mattr.imageUrl}
                    alt={mattr.title}
                    height={20}
                    width={20}
                  />
                  {mattr.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      );
    case "colorAndImageSwatches":
      return (
        <div className="col-12 mt-3">
          <p className="text-lightBlue mb-2">{attribute.frontEndTitle}:</p>
          <div className="d-flex align-items-center flex-wrap">
            {attribute.metaAttributes.map((mattr) => (
              <Button
                key={mattr._id}
                variant="text"
                onClick={() => setSelected(mattr._id)}
                disabled={selected === mattr._id}
                className="d-flex align-items-center px-2 py-1 mx-2 rounded-pill c-pointer"
                style={
                  selected === mattr._id
                    ? {
                        background:
                          "linear-gradient(303.01deg, #2f2e69 -4.4%, #514969 111.29%)",
                      }
                    : {}
                }>
                {mattr?.imageUrl && (
                  <img
                    src={mattr?.imageUrl}
                    alt="icon"
                    style={{
                      width: "20px",
                      height: "20px",
                      borderRadius: "50%",
                    }}
                    className="me-2"
                  />
                )}
                {mattr?.colour && (
                  <div
                    style={{
                      width: "20px",
                      height: "20px",
                      borderRadius: "50%",
                      backgroundColor: mattr?.colour,
                    }}
                    className="me-2"
                  />
                )}
                <small className="fw-400 text-lightBlue">{mattr.title}</small>
              </Button>
            ))}
          </div>
        </div>
      );
    case "radioButtons":
      return (
        <div className="col-12 mt-3">
          <p className="text-lightBlue mb-2">{attribute.title}:</p>
          <FormControl
            size="small"
            sx={{ m: 0, minWidth: 120, width: "100%" }}>
            <RadioGroup
              value={selected}
              onChange={(e) => setSelected(e.target.value)}>
              {attribute.metaSubAttributeValue.map((mattr) => (
                <FormControlLabel
                  key={mattr._id}
                  label={mattr.title}
                  value={mattr._id}
                  control={<Radio size="small" />}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </div>
      );
    case "rectangleButtons":
      return (
        <div className="col-12 mt-3">
          <p className="text-lightBlue mb-2">{attribute.frontEndTitle}:</p>
          <div className="d-flex align-items-center flex-wrap">
            {attribute.metaAttributes.map((mattr) => (
              <Button
                key={mattr._id}
                className="mx-1"
                onClick={() => setSelected(mattr._id)}
                disabled={selected === mattr._id}
                variant={selected === mattr._id ? "contained" : "outlined"}>
                <small className="fw-400 text-lightBlue">{mattr.title}</small>
              </Button>
            ))}
          </div>
        </div>
      );
    case "circleButtons":
      return (
        <div className="col-12 mt-3">
          <p className="text-lightBlue mb-2">{attribute.frontEndTitle}:</p>
          <div className="d-flex align-items-center flex-wrap">
            {attribute.metaAttributes.map((mattr) => (
              <Button
                key={mattr._id}
                sx={{ borderRadius: "50%" }}
                className="mx-1"
                onClick={() => setSelected(mattr._id)}
                disabled={selected === mattr._id}
                variant={selected === mattr._id ? "contained" : "outlined"}>
                <small className="fw-400 text-lightBlue">{mattr.title}</small>
              </Button>
            ))}
          </div>
        </div>
      );

    default:
      return null;
  }
}
