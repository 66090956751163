import React, { useState } from "react";
import { Link } from "react-router-dom";
// ! COMPONENT IMPORTS
import {
  EnhancedTableHead,
  stableSort,
  getComparator,
} from "../../../components/TableDependencies/TableDependencies";
// ! IMAGES IMPORTS
import indiaFlag from "../../../assets/images/products/indiaFlag.svg";
import ringSmall from "../../../assets/images/ringSmall.svg";
import userIcon from "../../../assets/images/users/user.svg";
import arrowDown from "../../../assets/icons/arrowDown.svg";
import deleteRed from "../../../assets/icons/delete.svg";
import ordersIcon from "../../../assets/icons/ordersIcon.svg";
// ! MATERIAL IMPORTS
import {
  Checkbox,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Tooltip,
} from "@mui/material";
// ! MATERIAL ICON IMPORTS
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ChatIcon from "@mui/icons-material/Chat";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CloseIcon from "@mui/icons-material/Close";
import ReceiptIcon from "@mui/icons-material/Receipt";
import PrintIcon from "@mui/icons-material/Print";
import invoice from "../../../assets/icons/invoice.svg";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import moment from "moment/moment";
import AsyncImage from "../../../components/AsyncImage/AsyncImage";
import { useDispatch } from "react-redux";
import { showError } from "../../../features/snackbar/snackbarAction";
import TableLoader from "../../../components/Loader/TableLoader";
import NoDataFound from "../../../components/NoDataFound/NoDataFound";
import { PaymentStatuses, TransactionStatuses } from "../AllOrders/FilterOrders";
// ? TABLE STARTS HERE
function createData(oId, time, userName, location, items, total, paymentStatus, orderStatus) {
  return {
    oId,
    time,
    userName,
    location,
    items,
    total,
    paymentStatus,
    orderStatus,
  };
}

const rows = [
  createData(
    "#12345",
    "Today at 09:23am",
    "#12345",
    "2 Items",
    "Saniya Shaikh",
    "Delhi, India",
    "₹ 1,00,000",
    "Paid",
    "Order Confirm"
  ),
  createData(
    "#12512",
    "Today at 09:23am",
    "#12345",
    "2 Items",
    "Saniya Shaikh",
    "Delhi, India",
    "₹ 1,00,000",
    "Paid",
    "Order Confirm"
  ),
  createData(
    "#13444",
    "Today at 09:23am",
    "#12345",
    "2 Items",
    "Saniya Shaikh",
    "Delhi, India",
    "₹ 1,00,000",
    "Paid",
    "Order Confirm"
  ),
];

const headCells = [
  {
    id: "orderInfo",
    numeric: false,
    disablePadding: true,
    label: "Transaction ID",
  },
  {
    id: "userName",
    numeric: false,
    disablePadding: false,
    label: "Order ID",
  },
  {
    id: "items",
    numeric: false,
    disablePadding: false,
    label: "Customer",
  },
  {
    id: "total",
    numeric: false,
    disablePadding: false,
    label: "Amount",
  },
  {
    id: "paymentStatus",
    numeric: false,
    disablePadding: false,
    label: "Payment Status",
  },
  {
    id: "orderStatus",
    numeric: false,
    disablePadding: false,
    label: "Invoice",
  },
];
// ? TABLE ENDS HERE

const AllTransaction = ({
  pageNo = 0,
  pageSize = 10,
  isLoading = false,
  allTransactions = [],
  totalTransactions = 0,
  changePage = () => {},
  changeRowsPerPage = () => {},
}) => {
  const dispatch = useDispatch();

  const [selected, setSelected] = useState([]);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.oId);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangeRowsPerPage = (event) => {
    console.log("handleChangeRowsPerPage");
    // setRowsPerPage(parseInt(event.target.value, 10));
    // setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // * EDIT STATUS POPOVERS STARTS
  const [anchorEditStatusEl, setAnchorEditStatusEl] = useState(null);
  const handleEditStatusClick = (event) => {
    setAnchorEditStatusEl(event.currentTarget);
  };
  const handleEditStatusClose = () => {
    setAnchorEditStatusEl(null);
  };
  const openEditStatus = Boolean(anchorEditStatusEl);
  const idEditStatus = openEditStatus ? "simple-popover" : undefined;
  // * EDIT STATUS POPOVERS ENDS

  // * MASS ACTION POPOVERS STARTS
  const [anchorMassActionEl, setAnchorMassActionEl] = useState(null);
  const handleMassActionClick = (event) => {
    setAnchorMassActionEl(event.currentTarget);
  };
  const handleMassActionClose = () => {
    setAnchorMassActionEl(null);
  };
  const openMassAction = Boolean(anchorMassActionEl);
  const idMassAction = openMassAction ? "simple-popover" : undefined;
  // * MASS ACTION POPOVERS ENDS

  // * USER POPOVERS STARTS
  const [viewingUser, setViewingUser] = useState(null);
  const [anchorUserEl, setAnchorUserEl] = useState(null);
  const handleUserClick = (target, user) => {
    setAnchorUserEl(target);
    setViewingUser(user);
  };
  const handleUserClose = () => {
    setAnchorUserEl(null);
    setViewingUser(null);
  };

  const downloadInvoice = async (url) => {
    try {
      const data = await fetch(`${url}?t=${Date.now().toString(36)}`);
      if (!data.ok)
        throw new Error(`Download Failed status:${data.status} message:${data.statusText}`);
      const blob = await data.blob();
      const objectUrl = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.setAttribute("href", objectUrl);
      link.setAttribute("download", "invoices.zip");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(objectUrl);
    } catch (error) {
      console.log(error);
      dispatch(showError({ message: error.message ?? "Something went wrong" }));
    }
  };

  return isLoading ? (
    <span className="d-flex justify-content-center m-3">
      <TableLoader />
    </span>
  ) : allTransactions.length === 0 ? (
    <span className="d-flex justify-content-center m-3">
      <NoDataFound />
    </span>
  ) : (
    <>
      {selected.length > 0 && (
        <div className="d-flex justify-content-between align-items-center px-2 mb-3">
          <div className="d-flex">
            <button className="button-grey py-2 px-3">
              <small className="text-lightBlue">
                {selected.length} users are selected&nbsp;
                <span
                  className="text-blue-2 c-pointer"
                  onClick={() => setSelected([])}>
                  (Clear Selection)
                </span>
              </small>
            </button>
            <button className="button-grey py-2 px-3 ms-2">
              <small className="text-lightBlue">Edit Users</small>
            </button>
            <button
              className="button-grey py-2 px-3 ms-2"
              aria-describedby={idEditStatus}
              variant="contained"
              onClick={handleEditStatusClick}>
              <small className="text-lightBlue">Edit Status</small>
              <img
                src={arrowDown}
                alt="arrowDown"
                className="ms-2"
              />
            </button>
            <Popover
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              id={idEditStatus}
              open={openEditStatus}
              anchorEl={anchorEditStatusEl}
              onClose={handleEditStatusClose}>
              <div className="py-2 px-1">
                <small className="p-2 rounded-3 text-lightBlue c-pointer font2 d-block hover-back">
                  Set as Active
                </small>
                <small className="p-2 rounded-3 text-lightBlue c-pointer font2 d-block hover-back">
                  Set as Draft
                </small>
              </div>
            </Popover>
            <button
              className="button-grey py-2 px-3 ms-2"
              aria-describedby={idMassAction}
              variant="contained"
              onClick={handleMassActionClick}>
              <small className="text-lightBlue">Mass Action</small>
              <img
                src={arrowDown}
                alt="arrowDown"
                className="ms-2"
              />
            </button>
            <Popover
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              id={idMassAction}
              open={openMassAction}
              anchorEl={anchorMassActionEl}
              onClose={handleMassActionClose}>
              <div className="py-2 px-2">
                <small className="text-grey-7 px-2">ACTIONS</small>
                <hr className="hr-grey-6 my-2" />
                <small className="p-2 rounded-3 text-lightBlue c-pointer font2 d-block hover-back">
                  Edit User
                </small>
                <small className="p-2 rounded-3 text-lightBlue c-pointer font2 d-block hover-back">
                  Edit User Group
                </small>
                <small className="p-2 rounded-3 text-lightBlue c-pointer font2 d-block hover-back">
                  Add or Remove Tags
                </small>
                <div className="d-flex justify-content-between  hover-back rounded-3 p-2 c-pointer">
                  <small className="text-lightBlue font2 d-block">Archive User</small>
                  <img
                    src={deleteRed}
                    alt="delete"
                    className=""
                  />
                </div>
              </div>
            </Popover>
          </div>
        </div>
      )}
      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size="medium">
          <EnhancedTableHead
            numSelected={selected.length}
            order="asc"
            orderBy="userName"
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={Date}
            rowCount={allTransactions.length}
            headCells={headCells}
          />
          <TableBody>
            {allTransactions.map((row) => {
              const isItemSelected = isSelected(row._id);
              const statusObj = TransactionStatuses.find(
                (ps) => ps.value === row.paymentStatus
              );
              // console.log(statusObj, row.paymentStatus);
              return (
                <TableRow
                  hover
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row._id}
                  selected={isItemSelected}
                  className="table-rows">
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={isItemSelected}
                      onClick={(event) => handleClick(event, row._id)}
                      style={{ color: "#5C6D8E" }}
                      size="small"
                    />
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    padding="none">
                    {/* <div className="d-flex align-items-center justify-content-center me-3">
                      <Tooltip
                        title="Order"
                        placement="top">
                        <img
                          src={ordersIcon}
                          alt="ordersIcon"
                          className="c-pointer"
                          width={20}
                        />
                      </Tooltip>
                    </div> */}
                    <div className="d-flex align-items-center py-2">
                      <div>
                        <span className="d-flex">
                          <p className="text-blue-2 fw-600">{row.transactionID}</p>
                          {/* <p className="text-yellow-2 fw-600">&nbsp;• Pre Order</p> */}
                        </span>
                        <small className="mt-2 text-lightBlue">
                          {moment(row.order.createdAt).format("DD-MM-YYYY [at] h:mm a")}
                        </small>
                      </div>
                    </div>
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    padding="none">
                    <div className="d-flex align-items-center py-2">
                      <div>
                        <Link
                          to={`/orders/allOrders/${row.order._id}/details`}
                          className=" text-decoration-none d-flex">
                          <p className="text-blue-2 fw-600">{row.order?.orderID || row.order?.centralID}</p>
                          {/* <p className="text-yellow-2 fw-600">&nbsp;• Pre Order</p> */}
                        </Link>
                        {/* <small className="mt-2 text-lightBlue">
                          {moment(row.order.createdAt).format("DD-MM-YYYY [at] h:mm a")}
                        </small> */}
                      </div>
                    </div>
                  </TableCell>
                  <TableCell>
                    {!row?.order?.customer ? (
                      <div className="d-flex align-items-center py-2">
                        <p className="text-blue-2 fw-600">
                          {row?.order?.address?.shipping?.firstName}{" "}
                          {row?.order?.address?.shipping?.lastName} (Guest)
                        </p>
                      </div>
                    ) : (
                      <div
                        onClick={(e) => handleUserClick(e.currentTarget, row?.order)}
                        className="flex align-items-center c-pointer py-2">
                        <p className="text-blue-2 fw-600">
                          {row?.order?.customer?.firstName} {row?.order?.customer?.lastName}
                        </p> 
                        <p className="text-grey-6 fw-400">
                          {row?.order?.customer?.phone}
                        </p>
                      </div>
                    )}
                    {console.log(viewingUser)}
                    {/* <div
                      onClick={(e) => handleUserClick(e.currentTarget, row)}
                      className="d-flex align-items-center c-pointer py-2">
                      {!row?.order?.customer ? (
                        <p className="text-blue-2 fw-600">
                          {row?.order?.address?.shipping?.customer?.firstName}{" "}
                          {row?.order?.address?.shipping?.customer?.lastName} (Guest)
                        </p>
                      ) : (
                        <p className="text-blue-2 fw-600">
                          {row?.order?.customer?.firstName} {row?.order?.customer?.lastName}
                        </p>
                      )}
                    </div> */}
                  </TableCell>
                  <TableCell>
                    <div className="d-flex align-items-center c-pointer">
                      <p
                        className="text-lightBlue"
                        // onClick={handleItemClick}
                      >
                        {/* {row.order.items} */}
                        <div className="d-flex align-items-center mt-2">
                          <p className="text-lightBlue">{row.amount}</p>
                          <img
                            src={indiaFlag}
                            alt="indiaFlag"
                            className="ms-2"
                            height={14}
                          />
                        </div>
                      </p>
                    </div>
                    <p className="text-grey-6">via {row.mode}</p>
                    <p className="text-grey-6">{row.paymentId}</p>
                  </TableCell>
                  <TableCell width="180">
                    <div className="d-flex align-items-center">
                      <div
                        className="rounded-pill d-flex px-2 py-1 statusBoxWidth"
                        style={{ background: statusObj?.color }}>
                        <small className="text-capitalize text-black fw-500">
                          {statusObj?.text}
                        </small>
                      </div>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="d-flex align-items-center">
                      <Tooltip
                        title="Download Invoice"
                        placement="top">
                        <div
                          onClick={() => downloadInvoice(row?.order?.invoiceUrl)}
                          className="c-pointer rounded-4 p-2">
                          <img
                            src={invoice}
                            alt="invoice"
                          />
                        </div>
                      </Tooltip>
                      {/* <Tooltip
                        title="Invoice"
                        placement="top">
                        <a
                          href={row.invoiceUrl}
                          target="_blank"
                          rel="noreferrer noopener"
                          className="text-decoration-none rounded-4 p-2">
                          <RemoveRedEyeIcon
                            sx={{
                              color: "#5c6d8e",
                              fontSize: 18,
                            }}
                          />
                        </a>
                      </Tooltip> */}
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={totalTransactions}
        rowsPerPage={pageSize}
        page={pageNo - 1}
        onPageChange={changePage}
        onRowsPerPageChange={changeRowsPerPage}
        className="table-pagination"
      />
      <Popover
        open={!!viewingUser}
        anchorEl={anchorUserEl}
        onClose={handleUserClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}>
        <div className="py-2 px-2">
          <div className="d-flex justify-content-between align-items-center pt-1">
            <div className="d-flex align-items-center">
              <AsyncImage
                src={viewingUser?.customer?.imageUrl}
                placeholder={userIcon}
                alt="product"
                className="me-2 rounded-4"
                height={40}
                width={40}
              />
              <div className="d-flex flex-column">
                <p className="text-lightBlue">
                  {viewingUser?.customer?.firstName} {viewingUser?.customer?.lastName}
                </p>
                {/* <small className="text-blue-2 c-pointer">View Profile</small> */}
                {/* <img
                  src={indiaFlag}
                  alt="indiaFlag"
                  width={20}
                /> */}
              </div>
            </div>
          </div>
          <hr className="hr-grey-6 my-2" />
          <div className="d-flex align-items-center mt-3">
            <small className=" text-blue-2 me-2">{viewingUser?.customer?.email}</small>
            {/* <Tooltip
              title="Copy"
              placement="top">
              <ContentCopyIcon
                sx={{ fontSize: 12, color: "#c8d8ff" }}
                className="c-pointer"
              />
            </Tooltip> */}
          </div>
          <div className="d-flex align-items-center mt-3">
            <small className="text-blue-2 me-2">
              {viewingUser?.customer?.countryCode?.countryCode}-{viewingUser?.customer?.phone}
            </small>
            {/* <Tooltip
              title="Copy"
              placement="top">
              <ContentCopyIcon
                sx={{ fontSize: 12, color: "#c8d8ff" }}
                className="c-pointer"
              />
            </Tooltip> */}
          </div>
          <div className="d-flex align-items-center mt-3">
            <div>
              <p className="my-1">
                <small className="text-lightBlue">
                  {viewingUser?.address?.shipping?.line1}{" "}
                  {viewingUser?.address?.shipping?.line2}
                </small>
              </p>
              <p className="text-lightBlue my-1">
                <small className="text-lightBlue">
                  {viewingUser?.address?.shipping?.state?.name}{" "}
                  {viewingUser?.address?.shipping?.country?.name}{" "}
                  {viewingUser?.address?.shipping?.pinCode}
                </small>
              </p>
            </div>
            {/* <Tooltip
              title="Copy"
              placement="top">
              <ContentCopyIcon
                sx={{ fontSize: 12, color: "#c8d8ff" }}
                className="c-pointer ms-3"
              />
            </Tooltip> */}
          </div>
          <div className="d-flex mt-4 mb-1">
            <Link
              to={`/users/allUsers/details/%7B"id"%3A"${viewingUser?.customer?._id}"%7D`}
              className="button-lightBlue-outline w-100 px-3 py-1">
              <small>View Profile</small>
            </Link>
            {/* <buton className="button-lightBlue-outline px-3 py-1 align-items-center">
              <ChatIcon sx={{ fontSize: 14 }} />
              <small className="ms-2">Message</small>
            </buton> */}
          </div>
        </div>
      </Popover>
    </>
  );
};

export default AllTransaction;
