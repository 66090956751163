import apiSlice from "../../app/api/apiSlice";

export const storeInfoApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllStoreDetails: builder.query({
      query: (queries) => {
        let queryString = "";
        for (const key in queries) {
          if (queries[key]) {
            queryString = `${queryString}${queryString ? "&" : "?"}${key}=${queries[key]}`;
          }
        }
        return {
          url: `/storeDetails${queryString}`,
        };
      },
      providesTags: ["StoreInfo"],
    }),
    createStoreInfo: builder.mutation({
      query: (storeDetails) => ({
        url: "/storeDetails",
        method: "POST",
        body: storeDetails,
      }),
      invalidatesTags: ["StoreInfo", "Logs"],
    }),
    editStoreInfo: builder.mutation({
      query: ({ id, details }) => ({
        url: `/storeDetails/${id}`,
        method: "PUT",
        body: details,
      }),
      invalidatesTags: ["StoreInfo", "Logs"],
    }),
  }),
});

export const {
  useGetAllStoreDetailsQuery,
  useCreateStoreInfoMutation,
  useEditStoreInfoMutation,
} = storeInfoApiSlice;
