import React from "react";
// ! MATERIAL IMPORTS
import { Checkbox, FormControlLabel,FormHelperText } from "@mui/material";

const checkboxData = [
  { name: "productDiscount", label: "Product Discount" },
  { name: "cartDiscount", label: "Cart Discount" },
  { name: "freeShipping", label: "Free Shipping" },
  { name: "buyxGety", label: "Buy X, Get Y" },
  { name: "bulk", label: "Bulk/Tiered Discount" },
];

const DiscountCombination = ({ value, field, formik,touched,error }) => {

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    const currentValues = value?.allowCombineWith || [];

    const updatedValues = checked
      ? [...currentValues, name]
      : currentValues.filter((value) => value !== name);

    formik.setFieldValue(`${field}.allowCombineWith`, updatedValues);
  };

  const filteredCheckboxData = checkboxData.filter(
    (checkbox) => checkbox.name !== formik?.values?.discountType
  );

  return (
    <div className="bg-black-15 border-grey-5 rounded-8 p-3 row attributes mt-4">
      <div className="d-flex col-12 px-0 justify-content-between">
        <div className="d-flex align-items-center">
          <FormControlLabel
            control={
              <Checkbox
                name={`${field}.allowCombineWithOthers`}   
                checked={value?.allowCombineWithOthers}
                onChange={(event) => {formik.handleChange(event);formik.setFieldValue(`${field}.allowCombineWith`, [])}}
                inputProps={{ "aria-label": "controlled" }}
                size="small"
                style={{
                  color: "#5C6D8E",
                  marginRight: 0,
                  width: "auto",
                }}
              />
            }
            label="Allow discount to be combined with other discounts"
            sx={{
              "& .MuiTypography-root": {
                fontSize: "1rem",
                color: "#c8d8ff",
                fontWeight:500
              },
            }}
            className="px-0 "
          />
                        {!!touched?.allowCombineWith && error?.allowCombineWith && (
              <FormHelperText error>( {error?.allowCombineWith} )</FormHelperText>
            )}
          {/* <h6 className="text-lightBlue me-auto text-lightBlue fw-500">
            Allow discount to be combined with other discounts
          </h6> */}
          {/* <Tooltip title="Lorem ipsum" placement="top">
  <img
    src={info}
    alt="info"
    className="ms-2 c-pointer"
    width={13.5}
  />
</Tooltip> */}
        </div>
      </div>
     
      {/* {value?.allowCombineWithOthers &&(
        <>
        <hr className="hr-grey-6 mt-3 mb-0" />
          <FormControlLabel
            control={
              <Checkbox
                name='productDiscount'
                checked={value?.allowCombineWith?.includes('productDiscount')}
                onChange={handleCheckboxChange}
                inputProps={{ "aria-label": "controlled" }}
                size="small"
                style={{
                  color: "#5C6D8E",
                  marginRight: 0,
                  width: "auto",
                }}
              />
            }
            label="Product Discount"
            sx={{
              "& .MuiTypography-root": {
                fontSize: "0.75rem",
                color: "#c8d8ff",
                // color: "#5c6d8e",
              },
            }}
            className="px-0"
          />
          <FormControlLabel
            control={
              <Checkbox
                name='cartDiscount'
                checked={value?.allowCombineWith?.includes('cartDiscount')}
                onChange={handleCheckboxChange}
                inputProps={{ "aria-label": "controlled" }}
                size="small"
                style={{
                  color: "#5C6D8E",
                  marginRight: 0,
                  width: "auto",
                }}
              />
            }
            label="Cart Discount"
            sx={{
              "& .MuiTypography-root": {
                fontSize: "0.75rem",
                color: "#c8d8ff",
                // color: "#5c6d8e",
              },
            }}
            className="px-0"
          />
          <FormControlLabel
            control={
              <Checkbox
                name ='freeShipping'
                checked={value?.allowCombineWith?.includes('freeShipping')}
                onChange={handleCheckboxChange}
                inputProps={{ "aria-label": "controlled" }}
                size="small"
                style={{
                  color: "#5C6D8E",
                  marginRight: 0,
                  width: "auto",
                }}
              />
            }
            label="Free Shipping"
            sx={{
              "& .MuiTypography-root": {
                fontSize: "0.75rem",
                color: "#c8d8ff",
                // color: "#5c6d8e",
              },
            }}
            className="px-0"
          />
          <FormControlLabel
            control={
              <Checkbox
                name ='buyxGety'
                checked={value?.allowCombineWith?.includes('buyxGety')}
                onChange={handleCheckboxChange}
                inputProps={{ "aria-label": "controlled" }}
                size="small"
                style={{
                  color: "#5C6D8E",
                  marginRight: 0,
                  width: "auto",
                }}
              />
            }
            label="Buy X, Get Y"
            sx={{
              "& .MuiTypography-root": {
                fontSize: "0.75rem",
                color: "#c8d8ff",
                // color: "#5c6d8e",
              },
            }}
            className="px-0"
          />
          <FormControlLabel
            control={
              <Checkbox
                name = 'bulk'
                checked={value?.allowCombineWith?.includes('bulk')}
                onChange={handleCheckboxChange}
                inputProps={{ "aria-label": "controlled" }}
                size="small"
                style={{
                  color: "#5C6D8E",
                  marginRight: 0,
                  width: "auto",
                }}
              />
            }
            label="Bulk/Tiered Discount"
            sx={{
              "& .MuiTypography-root": {
                fontSize: "0.75rem",
                color: "#c8d8ff",
                // color: "#5c6d8e",
              },
            }}
            className="px-0"
          />
        </>)
      } */}
      {value?.allowCombineWithOthers && (
        <>
          <hr className="hr-grey-6 mt-3 mb-0" />
          {filteredCheckboxData.map((checkbox) => (
            <FormControlLabel
              key={checkbox.name}
              control={
                <Checkbox
                  name={checkbox.name}
                  checked={value?.allowCombineWith?.includes(checkbox.name)}
                  onChange={handleCheckboxChange}
                  inputProps={{ "aria-label": "controlled" }}
                  size="small"
                  style={{
                    color: "#5C6D8E",
                    marginRight: 0,
                    width: "auto",
                  }}
                />
              }
              label={checkbox.label}
              sx={{
                "& .MuiTypography-root": {
                  fontSize: "0.75rem",
                  color: "#c8d8ff",
                },
              }}
              className="px-0"
            />
          ))}
        </>
      )}
    </div>
  );
};

export default DiscountCombination;
