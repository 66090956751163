import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import Heading from "../../components/Headers/DashboardHeader";

const RetentionTable = () => {
  const retentionData = [
    { cohort: "Oct 14 to Oct 20", size: 29, weeks: [100, 37.9, 34.5, 24.1, 27.6, 6.9, 3.4] },
    { cohort: "Oct 21 to Oct 27", size: 50, weeks: [100, 6.0, 4.0, 6.0, 4.0, 0.0, 0.0] },
    { cohort: "Oct 28 to Nov 3", size: 34, weeks: [100, 8.8, 5.9, 5.9, 0.0, 0.0, 0.0] },
    { cohort: "Nov 4 to Nov 10", size: 18, weeks: [100, 5.6, 0.0, 0.0, 0.0, 0.0, 0.0] },
    { cohort: "Nov 11 to Nov 17", size: 24, weeks: [100, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0] },
  ];

  const getCellStyle = (percentage) => {
    const intensity = Math.round((percentage / 100) * 255);
    return {
      backgroundColor: `rgba(121, 72, 255, ${intensity / 255})`, 
      color: intensity > 180 ? "#fff" : "#8877b7", 
      transition: "background-color 0.3s ease",
      cursor: "pointer",
    };
  };

  return (
    <div className="bg-button-3 border-grey-5 rounded-8 px-4 pt-4 pb-3 mt-4">
      <p
        className="text-lightBlue fw-500 me-2 my-1"
        style={{ fontSize: "12px", textTransform: "uppercase" }}
      >
        Trends • Last 7 weeks
      </p>
      <Heading
        heading={"Retention"}
        tooltipContent={
          "Weekly retention of your users."
        }
      />
      <TableContainer>
        <Table aria-label="retention table">
          <TableHead sx={{ background: "none" }}>
            <TableRow style={{ borderBottom: "1px solid #343E50" }}>
              <TableCell>
                <h6 className="text-lightBlue">Cohort</h6>
              </TableCell>
              <TableCell>
                <h6 className="text-lightBlue">Size</h6>
              </TableCell>
              {Array.from({ length: 7 }, (_, idx) => (
                <TableCell key={idx}>
                  <h6 className="text-lightBlue">{`Week ${idx+1}`}</h6>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {retentionData.map((row, index) => (
              <TableRow
                key={index}
                style={{ borderBottom: "1px solid #343E50" }}
              >
                <TableCell>
                  <p className="text-lightBlue fw-400">{row.cohort}</p>
                </TableCell>
                <TableCell>
                  <p className="text-lightBlue fw-400">{row.size}</p>
                </TableCell>
                {row.weeks.map((percentage, idx) => (
                  <TableCell
                    key={idx}
                    style={{
                      ...getCellStyle(percentage),
                      textAlign: "center",
                    }}
                  >
                    {`${percentage}%`}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default RetentionTable;
