import { useFormik } from "formik";
import PopUpForm from "../../../components/PopUp/PopUpForm";
import InputBox from "../../../components/Setting/InputBox";
import { useChangePasswordMutation } from "../../../features/myProfile/myProfileApiSlice";
import { useDispatch } from "react-redux";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import * as Yup from "yup";
// import YupPassword from "yup-password";
import { FormHelperText, IconButton, InputAdornment } from "@mui/material";
import { showError, showSuccess } from "../../../features/snackbar/snackbarAction";
import { useState } from "react";

// YupPassword(Yup);

const validationSchema = Yup.object({
  current: Yup.string().trim().required("required"),
  password: Yup.string()
    .trim()
    // .password()
    // .min(8, "must be minimum 8 characters")
    // .minLowercase(1, "must include 1 lowercase letter")
    // .minUppercase(1, "must include 1 uppercase letter")
    // .minSymbols(1, "must include 1 special letter")
    // .minNumbers(1, "must include 1 number letter")
    .required("required"),
  repassword: Yup.string()
    .trim()
    .test(
      "passwords-match",
      "Passwords must match",
      (value, context) => value === context.parent.password
    )
    .required("Please retype the password"),
});

const CloseProfile = ({ show, onConfirm, heading }) => {
  const dispatch = useDispatch();

  const [changePassword] = useChangePasswordMutation();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      current: "",
      password: "",
      repassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      changePassword({
        currentPassword: values.current,
        password: values.password,
      })
        .unwrap()
        .then(() => {
          resetForm();
          onConfirm();
          dispatch(showSuccess({ message: "Password Changed Successfully" }));
        })
        .catch((e) => {
          console.log(e);
          dispatch(
            showError({ message: e?.data?.message ?? e?.message ?? "Something went wrong" })
          );
        });
    },
  });

  const closeIt = () => {
    formik.resetForm();
    onConfirm();
  };

  const [showCurrent, setShowCurrent] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showRePassword, setShowRePassword] = useState(false);

  // console.log({ showCurrent, showPassword, showRePassword });

  return (
    <PopUpForm
      show={show}
      onConfirm={formik.handleSubmit}
      onClose={closeIt}
      heading={heading}>
      <div className="col-lg-6">
        <InputBox
          heading="Current Password"
          placeholder="Enter Password"
          name="current"
          type={!showCurrent ? "password" : "text"}
          value={formik.values.current}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowCurrent((s) => !s)}
                type="button"
                edge="end">
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
        <FormHelperText error>
          {formik?.touched?.current && formik?.errors?.current}
        </FormHelperText>
      </div>
      <div className="d-flex col-lg-12 my-3">
        <div className="col-lg-6">
          <InputBox
            heading="New Password"
            placeholder="Enter Password"
            name="password"
            type={!showPassword ? "password" : "text"}
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword((s) => !s)}
                  type="button"
                  edge="end">
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
          <FormHelperText error>
            {formik?.touched?.password && formik?.errors?.password}
          </FormHelperText>
        </div>
        <div className="col-lg-6 ms-4">
          <InputBox
            heading={"Confirm Password"}
            placeholder={"Enter Password"}
            name="repassword"
            type={!showRePassword ? "password" : "text"}
            value={formik.values.repassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowRePassword((s) => !s)}
                  type="button"
                  edge="end">
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
          <FormHelperText error>
            {formik?.touched?.repassword && formik?.errors?.repassword}
          </FormHelperText>
        </div>
      </div>
      {/* <div className="d-grid col-lg-12">
        <small className="text-lightBlue mr-2 my-1">
          • Minimum 8 characters long - the more, the better
        </small>
        <small className="text-lightBlue mr-2 my-1">• At least one lowercase character</small>
        <small className="text-lightBlue mr-2 my-1">
          • At least one number, symbol, or whitespace character
        </small>
      </div> */}
    </PopUpForm>
  );
};

export default CloseProfile;
