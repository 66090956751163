import cancel from "../../../../assets/icons/cancel.svg";
import deleteIcon from "../../../../assets/icons/newDelete.svg";
import { forwardRef } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, Slide } from "@mui/material";
import { LoadingButton } from "@mui/lab";

const Transition = forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="up"
      ref={ref}
      {...props}
    />
  );
});

export default function DeleteAlertDialog({
  show = false,
  isLoading = false,
  title = "",
  message = "",
  confirmText = "",
  moduleMessage = "",
  countMessage = "",
  onConfirm = () => {},
  onCancel = () => {},
}) {
  return (
    <Dialog
      fullWidth
      open={show}
      maxWidth="sm"
      onClose={onCancel}
      TransitionComponent={Transition}>
      <DialogTitle>
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex flex-column ">
            <h4 className="text-lightBlue fw-500">{title}</h4>
          </div>
          <button
            className="reset"
            onClick={onCancel}>
            <img
              src={cancel}
              alt="cancel"
              width={30}
              className="c-pointer"
            />
          </button>
        </div>
      </DialogTitle>

      <hr className="hr-grey-6 my-0" />

      <DialogContent className="py-3 px-4 d-flex align-items-center">
        <img
          alt="icon"
          src={deleteIcon}
          style={{ width: "80px", height: "80px" }}
        />
        <div>
          {moduleMessage && <p className="fs-6 text-grey-6 mx-4">{moduleMessage}</p>}
          <p className="fs-6 text-grey-6 m-4">{message}</p>
          {countMessage && <p className="fs-6 text-grey-6 mx-4">{countMessage}</p>}
        </div>
      </DialogContent>
      <DialogActions className="d-flex justify-content-end px-4 py-3">
        <LoadingButton
          loading={isLoading}
          disabled={isLoading}
          onClick={onConfirm}
          className="button-red-outline text capitalization py-2 px-4"
          style={{ lineHeight: 1.6 }}>
          <p>{confirmText}</p>
        </LoadingButton>
        <span className="pe-2" />
        <button
          onClick={onCancel}
          className="button-grey-outline py-2 px-4"
          style={{ lineHeight: 1.6 }}>
          <p className="text-lightBlue">Cancel</p>
        </button>
      </DialogActions>
    </Dialog>
  );
}
