import React, { useMemo, useState } from "react";
import {
  Button,
  Checkbox,
  SwipeableDrawer,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { EnhancedTableHead } from "../../components/TableDependencies/TableDependencies";
import cancelIcon from "../../assets/icons/cancel.svg";
import defaultUser from "../../assets/images/users/user_defauldp.svg";
import {
  useGetAllCustomersQuery,
  useGetCustomersByIdsQuery,
} from "../../features/customers/customer/customerApiSlice";
import { TableSearchSecondary } from "../../components/TableSearch/TableSearch";
import AsyncImage from "../AsyncImage/AsyncImage";
import NoDataFound from "../NoDataFound/NoDataFound";
import TableLoader from "../Loader/TableLoader";
import moment from "moment/moment";

const headCells = [
  {
    id: "customer",
    numeric: false,
    disablePadding: false,
    label: "Customer",
  },
  {
    id: "mobile",
    numeric: false,
    disablePadding: false,
    label: "Mobile",
  },
  {
    id: "email",
    numeric: false,
    disablePadding: false,
    label: "Email",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
];

export default function AddManualUsers({ customerIds = [], onCustomerChange = () => {} }) {
  const [showAddSideDrawer, setShowAddSideDrawer] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [debSearchKey, setDebSearchKey] = useState("");
  const [tablePageSize, setTablePageSize] = useState(10);
  const [tablePageNo, setTablePageNo] = useState(0);
  const [drawerPageSize, setDrawerPageSize] = useState(10);
  const [drawerPageNo, setDrawerPageNo] = useState(1);

  const { data: drawerCustomerData, isSuccess: drawerCustomerIsSuccess } =
    useGetAllCustomersQuery({
      pageNo: drawerPageNo,
      pageSize: drawerPageSize,
      search: debSearchKey,
      // status: ["active", "in-active", "scheduled"],
    });
  const drawerCustomers = useMemo(
    () => drawerCustomerData?.data?.data ?? [],
    [drawerCustomerData]
  );
  const totalDrawerCustomers = useMemo(
    () => drawerCustomerData?.data?.totalCount ?? 0,
    [drawerCustomerData]
  );
  // console.log("drawerCustomers", drawerCustomers);

  const {
    data: tableCustomerData,
    isSuccess: tableCustomerIsSuccess,
    isLoading: tableCustomerIsLoading,
    isFetching: tableCustomerIsFetching,
  } = useGetCustomersByIdsQuery(
    {
      customerGroupIds: customerIds.slice(
        tablePageSize * tablePageNo,
        tablePageSize + tablePageSize * tablePageNo
      ),
    },
    { skip: customerIds.length === 0 }
  );
  const tableCustomers = useMemo(() => tableCustomerData ?? [], [tableCustomerData]);

  return (
    <div className="row">
      <div className="col-12 my-2">
        <div className="bg-black-11 rounded-8 p-3 shadow-sm">
          <div className="d-flex justify-content-center">
            <button
              type="button"
              className="button-gradient py-1 px-4"
              onClick={() => setShowAddSideDrawer(true)}>
              <p>Add Customers</p>
            </button>
          </div>
        </div>
        <SwipeableDrawer
          anchor="right"
          open={showAddSideDrawer}
          onOpen={() => setShowAddSideDrawer(true)}
          onClose={() => setShowAddSideDrawer(false)}>
          <div className="d-flex justify-content-between py-3 ps-3 pe-2 me-1 align-items-center">
            <h6 className="text-lightBlue">Select Customers</h6>
            <img
              src={cancelIcon}
              alt="cancel"
              className="c-pointer add-customer-padding"
              onClick={() => setShowAddSideDrawer(false)}
            />
          </div>
          <hr className="hr-grey-6 mt-3 mb-3" />
          <div className="px-3">
            <TableSearchSecondary
              value={searchKey}
              onChange={(val) => setDebSearchKey(val)}
              onSearchValueChange={(val) => setSearchKey(val)}
            />
          </div>
          {drawerCustomerIsSuccess && (
            <TableContainer className="mt-3">
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size="medium">
                <EnhancedTableHead
                  order="asc"
                  orderBy="customer"
                  onRequestSort={Date}
                  headCells={headCells}
                  rowCount={drawerCustomers.length}
                  numSelected={customerIds.length}
                  onSelectAllClick={(e) => {
                    const drawerCustomerIds = drawerCustomers.map((p) => p._id);
                    onCustomerChange(
                      e.target.checked
                        ? [
                            ...drawerCustomerIds.filter((pid) => !customerIds.includes(pid)),
                            ...customerIds,
                          ]
                        : customerIds.filter((pid) => !drawerCustomerIds.includes(pid))
                    );
                  }}
                />
                <TableBody>
                  {drawerCustomers.map((row) => {
                    const isItemSelected = customerIds.includes(row._id);
                    const defaultImg = row?.mediaUrl;
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row._id}
                        selected={isItemSelected}>
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            size="small"
                            onClick={(event) =>
                              onCustomerChange(
                                event.target.checked
                                  ? [row._id, ...customerIds]
                                  : customerIds.filter((pid) => pid !== row._id)
                              )
                            }
                            style={{
                              color: "#5C6D8E",
                              marginRight: 0,
                            }}
                          />
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          padding="none">
                          <div className="d-flex align-items-center my-2">
                            <AsyncImage
                              src={defaultImg?.image}
                              placeholder={defaultUser}
                              alt="customer"
                              className="me-2 rounded-4"
                              height={45}
                              width={45}
                            />
                            <div>
                              <p className="text-lightBlue fw-600">
                                {row?.gender} {row?.firstName} {row?.lastName}
                              </p>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell>
                          <p className="text-lightBlue">{row?.phone}</p>
                        </TableCell>
                        <TableCell>
                          <p className="text-lightBlue">{row?.email}</p>
                        </TableCell>
                        <TableCell style={{ width: 180, padding: 0 }}>
                          <div className="d-flex align-items-center">
                            <div
                              className="rounded-pill d-flex px-2 py-1"
                              style={{
                                background:
                                  row.status === "active"
                                    ? "#A6FAAF"
                                    : row.status === "in-active"
                                    ? "#F67476"
                                    : row.status === "blocked"
                                    ? "#892628"
                                    : "#C8D8FF",
                              }}>
                              <small
                                className="fw-500"
                                style={{
                                  color: row.status === "blocked" ? "#fff" : "#202837",
                                }}>
                                {row.status === "active"
                                  ? "Active"
                                  : row.status === "in-active"
                                  ? "In-active"
                                  : row.status === "blocked"
                                  ? "Blocked"
                                  : "Archived"}
                              </small>
                            </div>
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          <TablePagination
            component="div"
            page={drawerPageNo - 1}
            count={totalDrawerCustomers}
            rowsPerPage={drawerPageSize}
            className="table-pagination"
            rowsPerPageOptions={[10, 20, 30]}
            onPageChange={(_, page) => setDrawerPageNo(page + 1)}
            onRowsPerPageChange={(e) => {
              setDrawerPageNo(1);
              setDrawerPageSize(e.target.value);
            }}
          />
        </SwipeableDrawer>
      </div>
      {tableCustomerIsSuccess &&
        (tableCustomers.length > 0 ? (
          <div className="col-12 my-2">
            <TableContainer>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size="medium">
                <TableHead>
                  <TableRow>
                    {headCells
                      .concat({
                        id: "action",
                        numeric: false,
                        disablePadding: false,
                        label: "Action",
                      })
                      .map((headCell) => {
                        return (
                          <TableCell
                            key={headCell.id}
                            align={headCell.numeric ? "right" : "left"}
                            padding={headCell.disablePadding ? "none" : "normal"}>
                            <p className="text-lightBlue">{headCell.label}</p>
                          </TableCell>
                        );
                      })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableCustomerIsLoading || tableCustomerIsFetching ? (
                    <span className="d-flex justify-content-center m-3">
                      <TableLoader />
                    </span>
                  ) : (
                    tableCustomers.map((row) => {
                      const defaultImg = row?.mediaUrl;
                      return (
                        <TableRow
                          hover
                          tabIndex={-1}
                          key={row._id}>
                          <TableCell
                            scope="row"
                            component="th"
                            padding="normal"
                            className="py-0">
                            <div className="d-flex align-items-center my-2">
                              <AsyncImage
                                src={defaultImg?.image}
                                placeholder={defaultUser}
                                alt="customer"
                                className="me-2 rounded-4"
                                height={45}
                                width={45}
                              />
                              <div>
                                <p className="text-lightBlue fw-600">
                                  {row?.gender} {row?.firstName} {row?.lastName}
                                </p>
                              </div>
                            </div>
                          </TableCell>
                          <TableCell>
                            <p className="text-lightBlue">{row?.phone}</p>
                          </TableCell>
                          <TableCell>
                            <p className="text-lightBlue">{row?.email}</p>
                          </TableCell>
                          <TableCell style={{ width: 180, padding: 0 }}>
                            <div className="d-flex align-items-center">
                              <div
                                className="rounded-pill d-flex px-2 py-1"
                                style={{
                                  background:
                                    row.status === "active"
                                      ? "#A6FAAF"
                                      : row.status === "in-active"
                                      ? "#F67476"
                                      : row.status === "blocked"
                                      ? "#892628"
                                      : "#C8D8FF",
                                }}>
                                <small
                                  className="fw-500"
                                  style={{
                                    color: row.status === "blocked" ? "#fff" : "#202837",
                                  }}>
                                  {row.status === "active"
                                    ? "Active"
                                    : row.status === "in-active"
                                    ? "In-active"
                                    : row.status === "blocked"
                                    ? "Blocked"
                                    : "Archived"}
                                </small>
                              </div>
                            </div>
                          </TableCell>
                          <TableCell>
                            <Button
                              color="primary"
                              variant="text"
                              onClick={() =>
                                onCustomerChange(customerIds.filter((p) => row._id !== p))
                              }>
                              <span className="text-red-5">Remove</span>
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              page={tablePageNo}
              count={customerIds.length}
              rowsPerPage={tablePageSize}
              className="table-pagination"
              rowsPerPageOptions={[10, 20, 30]}
              onPageChange={(_, page) => setTablePageNo(page)}
              onRowsPerPageChange={(e) => {
                setTablePageNo(0);
                setTablePageSize(e.target.value);
              }}
            />
          </div>
        ) : (
          <div className="col-12 my-2">
            <NoDataFound />
          </div>
        ))}
    </div>
  );
}
