import { Chip, TextField } from "@mui/material";
import DashBoardChart from "../../components/Chart/DashBoardChart";
import Heading from "../../components/Headers/DashboardHeader";
import bharat from "../../assets/flag/india.svg";
import { useMemo, useState } from "react";
import {
  useGetCustomerAnalyticsQuery,
  useGetEngagementAnalyticsQuery,
  useGetOrderAnalyticsQuery,
  useGetRevenueAnalyticsQuery,
  useGetTotalConversionsQuery,
} from "../../features/analytics/analyticsApiSlice";
import moment from "moment/moment";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

const timeData = [
  { value: "week", name: "This Week" },
  { value: "month", name: "This Month" },
  { value: "year", name: "This Year" },
  { value: "", name: "Custom" },
];

const currencySyms = ["", "", "K", "L", "C"];

const formatter = new Intl.NumberFormat("en-IN", {
  maximumSignificantDigits: 3,
  style: "currency",
  currency: "INR",
});

const nowMoment = moment();

export default function StorePerformance() {
  const [timeFrame, setTimeFrame] = useState("week");
  const [fromDate, setFromDate] = useState(moment().subtract(1, "week").format("YYYY-MM-DD"));
  const [toDate, setToDate] = useState(nowMoment.format("YYYY-MM-DD"));

  const filter = useMemo(() => {
    if (!timeFrame) return { to: toDate, from: fromDate };
    else return { type: timeFrame };
  }, [fromDate, timeFrame, toDate]);

  const { data: revenueData } = useGetRevenueAnalyticsQuery(filter);
  const { data: orderData } = useGetOrderAnalyticsQuery(filter);
  const { data: customerData } = useGetCustomerAnalyticsQuery(filter);
  const { data: engagementData } = useGetEngagementAnalyticsQuery(filter);
  const { data: conversionData = 0 } = useGetTotalConversionsQuery(filter);

  const storedata = useMemo(() => {
    const { lastRevenue = 0, currentRevenue = 0, ...revenueChartData } = revenueData ?? {};
    const formattedRevenue = formatter.format(currentRevenue);
    const splittedRevenue = formattedRevenue.split(",").map((s) => s.replace(/0{2,3}/g, ""));
    const revenue = {
      heading: "Revenue",
      figure: splittedRevenue.slice(0, 2).join(".") + currencySyms[splittedRevenue.length],
      percentage: `+${Math.round((lastRevenue / (currentRevenue || 1)) * 100)}%`,
      conclusion: "concluding line",
      chart: Object.keys(revenueChartData)
        .sort()
        .map((key) => ({ name: key, totalSpent: revenueChartData[key] })),
    };

    const { lastOrder = 0, currentOrder = 0, ...orderChartData } = orderData ?? {};
    const order = {
      heading: "Order",
      figure: currentOrder,
      percentage: `+${Math.round((lastOrder / (currentOrder || 1)) * 100)}%`,
      conclusion: "concluding line",
      chart: Object.keys(orderChartData)
        .sort()
        .map((key) => ({ name: key, totalSpent: orderChartData[key] })),
    };

    const { lastCustomer = 0, currentCustomer = 0, ...customerChartData } = customerData ?? {};
    const customer = {
      heading: "Customer",
      figure: currentCustomer,
      percentage: `+${Math.round((lastCustomer / (currentCustomer || 1)) * 100)}%`,
      conclusion: "concluding line",
      chart: Object.keys(customerChartData)
        .sort()
        .map((key) => ({ name: key, totalSpent: customerChartData[key] })),
    };

    const {
      lastEngagement = 0,
      currentEngagement = 0,
      ...engagementChartData
    } = engagementData ?? {};
    const engagement = {
      heading: "Engagement",
      figure: currentEngagement,
      percentage: `+${Math.round((lastEngagement / (currentEngagement || 1)) * 100)}%`,
      conclusion: "concluding line",
      chart: Object.keys(engagementChartData)
        .sort()
        .map((key) => ({ name: key, totalSpent: engagementChartData[key] })),
    };

    const conversion = {
      heading: "Conversion",
      figure: conversionData + "%",
      percentage: "+20%",
      conclusion: "concluding line",
      chart: [],
    };

    return [revenue, order, customer, engagement, conversion];
  }, [conversionData, customerData, engagementData, orderData, revenueData]);

  const extraline = useMemo(() => {
    return `${!timeFrame ? "custom" : "last"} ${!timeFrame ? "" : timeFrame + "'s"} total`;
  }, [timeFrame]);

  return (
    <div className="bg-button-2 px-3 py-3 rounded-8 mt-4">
      <Heading
        heading={"Store Performance"}
        // viewMore={"View More"}
      />
      <div className="d-flex mt-3">
        {timeData.map((item) => (
          <Chip
            key={item.value}
            onClick={() => setTimeFrame(item.value)}
            variant={timeFrame === item.value ? "filled" : "outlined"}
            label={item.name}
            className="mx-2"
          />
        ))}
        {!timeFrame && (
          <>
            <div className="mx-2">
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DesktopDatePicker
                  maxDate={moment()}
                  value={moment(fromDate)}
                  onChange={(newValue) => setFromDate(newValue.format("YYYY-MM-DD"))}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
            <div className="mx-2">
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DesktopDatePicker
                  maxDate={moment()}
                  value={moment(toDate)}
                  onChange={(newValue) => setToDate(newValue.format("YYYY-MM-DD"))}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
          </>
        )}
      </div>
      <div className="col-12 d-flex rounded-4">
        {storedata.slice(0, 3).map((item) => (
          <div
            key={item.heading}
            className="col-4 px-2">
            <div className="bg-button-2 rounded-4 mt-4 p-3">
              <Heading heading={item.heading} />
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h1 className="text-blue-gradient fw-600 mt-3 mb-1">{item.figure}</h1>
                  <small className="text-green-2 me-3 fw-400">{item.percentage}</small>
                  <small className="d-block text-grey-7 me-3 fw-500">{extraline}</small>
                </div>
                <DashBoardChart data={item.chart} />
              </div>
              {/* <small className="text-lightBlue fw-500">
                {item.conclusion}
                <small className="text-blue-2 fw-500 ms-1">
                  {item.image && (
                    <img
                      alt=""
                      src={item.image}
                      className="rounded-circle me-1"
                      width={14}
                      height={"auto"}
                    />
                  )}
                  {item.storeType}
                </small>
              </small> */}
            </div>
          </div>
        ))}
      </div>
      <div className="col-12 d-flex rounded-4">
        {storedata.slice(3).map((item) => (
          <div
            key={item.heading}
            className="col-6 px-2">
            <div className="bg-button-2 rounded-4 mt-4 p-3">
              <Heading heading={item.heading} />
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h1 className="text-blue-gradient fw-600 mt-3 mb-1">{item.figure}</h1>
                  <small className="text-green-2 me-3 fw-400">{item.percentage}</small>
                  <small className="d-block text-grey-7 me-3 fw-500">{extraline}</small>
                </div>
                <DashBoardChart data={item.chart} />
              </div>
              {/* <small className="text-lightBlue fw-500">
                {item.conclusion}
                <small className="text-blue-2 fw-500 ms-1">
                  {item.image && (
                    <img
                      alt=""
                      src={item.image}
                      className="rounded-circle me-1"
                      width={14}
                      height={"auto"}
                    />
                  )}
                  {item.storeType}
                </small>
              </small> */}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
