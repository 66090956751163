import React from "react";
import { Link } from "react-router-dom";
import "../../Products/AllProducts/AllProducts.scss";
// ! COMPONENT IMPORTS
import SizeChartTable from "./SizeChartTable";
import ViewTutorial from "../../../components/ViewTutorial/ViewTutorial";
import ViewLogsDrawer from "../../../components/ViewLogsDrawer/ViewLogsDrawer";
import parameters from "../../../assets/icons/sidenav/parameters.svg";
// ! MATERIAL IMPORTS
import { Tooltip } from "@mui/material";
// ! MATERIAL ICONS IMPORT
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ViewSizeChartSettings from "../../../components/ViewSizeChartSettings/ViewSizeChartSettings";

const SizeChart = () => {
  return (
    <div className="container-fluid page">
      <div className="row justify-content-between align-items-center">
        <div className="d-flex w-auto align-items-center px-0">
          <h4 className="page-heading w-auto ps-0 me-2">Size Chart</h4>
          <Tooltip
            title="Lorem ipsum"
            placement="top">
            <InfoOutlinedIcon
              sx={{ color: "#c8d8ff", fontSize: 20 }}
              className="c-pointer"
            />
          </Tooltip>
        </div>
        <div className="d-flex align-items-center w-auto pe-0">
          {/* <ViewTutorial /> */}
          <ViewLogsDrawer
            headingName={"Functionality / Size Chart"}
            icon={parameters}
          />
          <ViewSizeChartSettings />
          {/* <button className="button-transparent me-1 py-2 px-3">
            <p className="text-lightBlue">Settings</p>
          </button> */}
          <Link
            to="/functionality/sizeChart/create"
            className="button-gradient py-2 px-4 c-pointer">
            <p>+ Create Size Chart</p>
          </Link>
        </div>
      </div>
      <SizeChartTable />
    </div>
  );
};

export default SizeChart;
