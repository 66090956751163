import { forwardRef, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  Tooltip
} from "@mui/material";

import PopUpBox from "../../../components/PopUp/PopUpBox";
import InputBox from "../../../components/Setting/InputBox";
import ContactSupport from "./ContactSupport";
import security from "../../../assets/profile/Security2.svg";

const EnterPass = ({ onConfirm, show }) => {
    const [showContact, setContactSupport] = useState(false);

    const closeContact = () => {
        setContactSupport(false);
    }

    const handleContact = () => {
        setContactSupport(true);
    }

    return (
        <PopUpBox
            show={show}
            onConfirm={onConfirm}
            image={security}
        >
            <h4 className="d-flex justify-content-center text-lightBlue fw-600 me-2">
                Enter your account Password
            </h4>

            <div className="d-flex justify-content-center col-lg-12 my-3">
                <div className="col-lg-7">
                    <InputBox 
                        placeholder={"Enter Password"}
                    />
                </div>
            </div>

            <div className="d-flex justify-content-center px-3">
                <small className="text-grey-6 d-block py-2 mb-4 px-5">
                    Your free trial has now expired. To continue using our platform
                </small>
            </div>
            <div className="d-flex justify-content-center">
                <div className="button-gradient py-2 px-5 ms-3" onClick={handleContact}>
                    <p>Continue</p>
                </div>
            </div>
            <div className="d-flex justify-content-center">
                <p className="text-lightBlue py-4 c-pointer" onClick={onConfirm}>
                    {" "}Cancel
                </p>
            </div>
            <ContactSupport show={showContact} onConfirm={closeContact} />
        </PopUpBox>
    );
};

export default EnterPass;