import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Slide,
  TextareaAutosize,
} from "@mui/material";
import { forwardRef } from "react";
import cancel from "../../assets/icons/cancel.svg";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { showError, showSuccess } from "../../features/snackbar/snackbarAction";
import { useUpdateOrderItemMutation } from "../../features/orders/ordersApiSlice";

const Transition = forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="up"
      ref={ref}
      {...props}
    />
  );
});

const validationSchema = Yup.object({
  status: Yup.string().required(),
  holdComment: Yup.string().required(),
});

export default function OnHoldDialog({ item = null, show = false, onClose = () => {} }) {
  const dispatch = useDispatch();

  const [updateOrderItem, { isLoading }] = useUpdateOrderItemMutation();

  const formik = useFormik({
    initialValues: {
      status: "onHold",
      holdComment: "",
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // console.log(values);
      updateOrderItem({ id: item?._id, update: values })
        .unwrap()
        .then(() => {
          closeDialog();
          dispatch(showSuccess({ message: "Item Held Succesfully" }));
        })
        .catch((e) => {
          console.log(e);
          dispatch(showError({ message: e?.data?.message ?? "Something went wrong" }));
        });
    },
  });

  const closeDialog = () => {
    if (!isLoading) {
      formik.resetForm();
      onClose();
    }
  };

  return (
    <Dialog
      open={show}
      TransitionComponent={Transition}
      keepMounted
      onClose={closeDialog}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="sm"
      fullWidth={true}>
      <DialogTitle>
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex flex-column ">
            <h5 className="text-lightBlue fw-500">Holding Item</h5>
          </div>
          <img
            src={cancel}
            alt="cancel"
            width={30}
            onClick={closeDialog}
            className="c-pointer"
          />
        </div>
      </DialogTitle>
      <hr className="hr-grey-6 my-0" />
      <DialogContent className="py-3 px-4">
        <p className="text-lightBlue mb-1">Hold Note</p>
        <TextareaAutosize
          name="holdComment"
          value={formik.values.holdComment}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder="Type Something"
          style={{
            background: "#15142A",
            color: "#c8d8ff",
            borderRadius: 5,
          }}
          minRows={3}
          className="col-12 mt-3"
        />
        <FormHelperText error>
          {formik.touched.holdComment && formik.touched.holdComment}
        </FormHelperText>
      </DialogContent>
      <hr className="hr-grey-6 my-0" />
      <DialogActions className="d-flex justify-content-between px-4 py-3">
        <button
          className="button-grey py-2 px-5"
          onClick={closeDialog}
          disabled={isLoading}>
          <p className="text-lightBlue">Cancel</p>
        </button>
        <button
          className="button-gradient py-2 px-5"
          onClick={formik.handleSubmit}
          disabled={isLoading}>
          <p>Hold</p>
        </button>
      </DialogActions>
    </Dialog>
  );
}
