import profile from "../../assets/profile/profile.svg";
import store from "../../assets/profile/Store.svg";
import notice from "../../assets/profile/notice.svg";
import security from "../../assets/profile/security.svg";

export const SidebarData = [
  {
    title: "Profile Details",
    path: "/myProfile/details",
    image: profile,
  },
  {
    title: "Manage Stores",
    path: "/myProfile/store",
    image: store,
  },
  {
    title: "Security",
    path: "/myProfile/security",
    image: security,
  },
  {
    title: "My Notification",
    path: "/myProfile/notification",
    image: notice,
  }
];
