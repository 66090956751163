import apiSlice from "../../app/api/apiSlice";
import { omitEmptyKeys } from "../../utils/helper";

export const permissionApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllPermissions: builder.query({
      query: (queries = {}) => ({
        url: "/permissions",
        params: omitEmptyKeys(queries),
      }),
      transformResponse: (res) => res.data?.data ?? [],
      providesTags: ["Teams"],
    }),

    getAllModels: builder.query({
      query: (queries = {}) => ({
        url: "/models",
        params: omitEmptyKeys(queries),
      }),
      transformResponse: (res) => res.data?.data ?? [],
      providesTags: ["Teams"],
    }),
    // createPermission: builder.mutation({
    //   query: (data) => ({
    //     url: "/permissions",
    //     method: "POST",
    //     body: data,
    //   }),
    //   invalidatesTags: ["Teams","Logs"],
    // }),

    // editPermission: builder.mutation({
    //   query: ({ id, details }) => ({
    //     url: `/permissions/${id}`,
    //     method: "PUT",
    //     body: details,
    //   }),
    //   invalidatesTags: ["Teams","Logs"],
    // }),

    // bulkEditPermission: builder.mutation({
    //   query: (updates) => ({
    //     url: "/permissions/bulkUpdate",
    //     method: "PUT",
    //     body: updates,
    //   }),
    //   invalidatesTags: ["Teams","Logs"],
    // }),

    // deletePermission: builder.mutation({
    //   query: (id) => ({
    //     url: `/permissions/${id}`,
    //     method: "DELETE",
    //   }),
    //   invalidatesTags: ["Teams","Logs"],
    // }),

    // bulkDeletePermission: builder.mutation({
    //   query: (deletes) => ({
    //     url: "/permissions/bulkDelete",
    //     method: "DELETE",
    //     body: deletes,
    //   }),
    //   invalidatesTags: ["Teams","Logs"],
    // }),
  }),
});

export const {
  useGetAllPermissionsQuery,
  useGetAllModelsQuery,
  // useGetPermissionCountQuery,
  // useCreatePermissionMutation,
  // useEditPermissionMutation,
  // useBulkEditPermissionMutation,
  // useDeletePermissionMutation,
  // useBulkDeletePermissionMutation,
} = permissionApiSlice;
