import apiSlice from "../../app/api/apiSlice";
import { omitNullishKeys } from "../../utils/helper";

export const inventoryApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllOrders: builder.query({
      query: (queries = {}) => ({
        url: `/order`,
        params: omitNullishKeys(queries),
      }),
      providesTags: ["Orders"],
    }),
    getAllOrderCounts: builder.query({
      query: () => ({ url: `/order/count` }),
      transformResponse: (res) => res.data[0],
      providesTags: ["Orders"],
    }),
    getSingleOrder: builder.query({
      query: ({ id = "", queries = {} }) => ({
        url: `/order/${id}`,
        params: omitNullishKeys(queries),
      }),
      providesTags: ["Orders"],
    }),
    getOrderRange: builder.query({
      query: () => ({ url: "order/getPrice" }),
      providesTags: ["Orders"],
    }),
    getOrderAnalytics: builder.query({
      query: (queries) => ({
        url: "order/analytics",
        params: omitNullishKeys(queries),
      }),
      providesTags: ["Orders"],
    }),
    createOrder: builder.mutation({
      query: (order) => ({
        url: "/order",
        method: "POST",
        body: order,
      }),
      invalidatesTags: ["Orders", "Logs"],
    }),
    updateOrder: builder.mutation({
      query: ({ id, order }) => ({
        url: `/order/${id}`,
        method: "PUT",
        body: order,
      }),
      invalidatesTags: ["Orders", "Timeline", "Logs"],
    }),
    updateOrderItem: builder.mutation({
      query: ({ id, update }) => ({
        url: `/order/item/${id}`,
        method: "PUT",
        body: update,
      }),
      invalidatesTags: ["Orders", "Timeline", "Logs"],
    }),
    refundOrderTransaction: builder.mutation({
      query: (data) => ({
        url: "/transaction/refund",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Orders", "Timeline", "Logs"],
    }),
  }),
});

export const {
  useGetAllOrdersQuery,
  useGetAllOrderCountsQuery,
  useGetSingleOrderQuery,
  useGetOrderRangeQuery,
  useGetOrderAnalyticsQuery,
  useCreateOrderMutation,
  useUpdateOrderMutation,
  useUpdateOrderItemMutation,
  useRefundOrderTransactionMutation,
} = inventoryApiSlice;
