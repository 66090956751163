import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

const HomeToggleOption = ({ image, head, subHead, dropDown }) => {

  return (
    <div 
      className='d-flex align-items-center justify-content-between text-lightBlue p-1 my-2 hover-grey rounded-4'
    >
      <div className='d-flex align-items-center'>
        <img src={image} width={16} height={16} />
        <div className='ms-2'>
          <small>{head}</small>
          {subHead &&
            <div className='ex-small text-grey-6'>{subHead}</div>
          }
        </div>
      </div>
      <div>
        {dropDown == true &&
          <KeyboardArrowLeftIcon sx={{ fontSize: "16px", transform: "rotate(180deg)" }} />
        }
      </div>
    </div>
  );
};

export default HomeToggleOption;
