import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Popover, Tooltip } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import ViewTutorial from "../../../../components/ViewTutorial/ViewTutorial";
import NavImage from "../../../../components/ThemeComponent/NavImage/NavImage";
import ThemeDrawer from "../../../../components/ThemeComponent/ThemeDrawer/ThemeDrawer";

import "./ThemeNavbar.scss";

import tablet from "../../../../assets/icons/theme/tablet.svg";
import mobile from "../../../../assets/icons/theme/mobile.svg";
import inOut from "../../../../assets/icons/theme/inOut.svg";
import indiaFlag from "../../../../assets/images/products/indiaFlag.svg";
import usaFlag from "../../../../assets/images/products/usaFlag.svg";
import ukFlag from "../../../../assets/images/products/ukFlag.svg";
import share from "../../../../assets/icons/theme/share.svg";
import newias from "../../../../assets/icons/theme/Icon (2).svg";
import slksd from "../../../../assets/icons/theme/Icon (3).svg";
import add from "../../../../assets/icons/theme/add.svg";
import publish from "../../../../assets/icons/theme/publish.svg";
import schedule from "../../../../assets/icons/theme/schedule.svg";

const ThemeNavbar = ({ handleView }) => {
  const navigate = useNavigate();
  const [anchorFlagEl, setAnchorFlagEl] = useState(null);
  const [anchorPublishEl, setAnchorPublishEl] = useState(null);
  const openFlag = Boolean(anchorFlagEl);
  const idFlag = openFlag ? "simple-popover" : undefined;
  const openPublish = Boolean(anchorPublishEl);
  const idPulish = openPublish ? "simple-popover" : undefined;

  const handlePublishClick = (event) => {
    setAnchorPublishEl(event.currentTarget);
  };

  const handlePublishClose = () => {
    setAnchorPublishEl(null);
  };

  const handleFlagClick = (event) => {
    setAnchorFlagEl(event.currentTarget);
  };

  const handleFlagClose = () => {
    setAnchorFlagEl(null);
  };

  return (
    <div className="d-flex justify-content-between m-3">
      <div className="d-flex align-items-center gap-2">
        <NavImage
          tooltip={"Exit Editor"}
          image={inOut}
          onClick={() => navigate("../website/themes")}
        />
        <div className="d-flex align-items-center rounded-8 bg-black-13 me-3 py-1 px-3">
          <NavImage
            tooltip={"Desktop"}
            image={tablet}
            onClick={() => handleView("desktop")}
          />
          <NavImage
            tooltip={"Mobile"}
            image={mobile}
            onClick={() => handleView("mobile")}
          />
        </div>

        <div className="d-flex align-items-center rounded-8 bg-black-13 p-1">
          <NavImage
            tooltip={"Undo"}
            image={slksd}
          />
          <NavImage
            tooltip={"Redo"}
            image={newias}
          />
        </div>

        <ThemeDrawer />
      </div>

      <div className="d-flex align-items-center gap-3">
        {/* <ViewTutorial /> */}

        <button className="button-transparent p-2">
          <img
            src={share}
            alt="tutorial"
            className="me-2"
            width={20}
          />
          <p className="text-lightBlue">Share</p>
        </button>

        <div
          className="d-flex align-items-center bg-black-13 py-2 px-3 rounded-8 c-pointer"
          onClick={handleFlagClick}>
          <img
            src={indiaFlag}
            alt="indiaFlag"
            height={15}
          />
          <p className="text-lightBlue mx-2">India</p>
          <ArrowDropDownIcon sx={{ color: "#C8D8FF" }} />
        </div>
        <Popover
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          id={idFlag}
          open={openFlag}
          anchorEl={anchorFlagEl}
          onClose={handleFlagClose}>
          <div className="px-1 py-2">
            <div className="d-flex align-items-center c-pointer hover-back px-3 py-1 my-1 rounded-3">
              <img
                src={indiaFlag}
                alt="allFlag"
                height={15}
              />
              <p className="ms-2 text-lightBlue">India</p>
            </div>
            <div className="d-flex align-items-center c-pointer hover-back px-3 py-1 my-1 rounded-3">
              <img
                src={ukFlag}
                alt="usaFlag"
                height={15}
              />
              <p className="ms-2 text-lightBlue">UK</p>
            </div>
            <div className="d-flex align-items-center c-pointer hover-back px-3 py-1 my-1 rounded-3">
              <img
                src={usaFlag}
                alt="usaFlag"
                height={15}
              />
              <p className="ms-2 text-lightBlue">USA</p>
            </div>
            <div className="d-flex align-items-center c-pointer hover-back px-3 py-1 my-1 rounded-3">
              <img
                src={add}
                alt="usaFlag"
                height={15}
              />
              <small className="ms-2 text-lightBlue">Add Markets</small>
            </div>
          </div>
        </Popover>

        <button className="button-grey-outline py-2 px-4">
          <p className="text-lightBlue">Save</p>
        </button>
        <button
          className="button-gradient py-2 px-3"
          onClick={handlePublishClick}>
          <p className="me-2">Publish</p>
          <ArrowDropDownIcon />
        </button>
        <Popover
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          id={idPulish}
          open={openPublish}
          anchorEl={anchorPublishEl}
          onClose={handlePublishClose}>
          <div className="px-1 py-2">
            <div className="d-flex align-items-center c-pointer hover-back px-3 py-1 my-1 rounded-3">
              <img
                src={publish}
                alt="allFlag"
                height={20}
              />
              <small className="ms-2 text-lightBlue">Publish Now</small>
            </div>
            <div className="d-flex align-items-center c-pointer hover-back px-3 py-1 my-1 rounded-3">
              <img
                src={schedule}
                alt="usaFlag"
                height={20}
              />
              <small className="ms-2 text-lightBlue">Schedule Publish</small>
            </div>
          </div>
        </Popover>
      </div>
    </div>
  );
};
export default ThemeNavbar;
