import apiSlice from "../../app/api/apiSlice";

export const myProfileApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllProfileDetails: builder.query({
      query: (queries) => {
        let queryString = "";
        for (const key in queries) {
          if (queries[key]) {
            queryString = `${queryString}${queryString ? "&" : "?"}${key}=${queries[key]}`;
          }
        }
        return {
          url: `/user/list${queryString}`,
        };
      },
      providesTags: ["ProfileInfo"],
    }),
    getAllMembers: builder.query({
      query: (queries) => {
        let queryString = "";
        for (const key in queries) {
          if (queries[key]) {
            queryString = `${queryString}${queryString ? "&" : "?"}${key}=${queries[key]}`;
          }
        }
        return {
          url: `/user/list${queryString}`,
        };
      },
      providesTags: ["ProfileInfo"],
      keepUnusedDataFor: 0,
    }),
    createProfile: builder.mutation({
      query: (profileDetails) => ({
        url: "/storeDetails",
        method: "POST",
        body: profileDetails,
      }),
      invalidatesTags: ["ProfileInfo", "Logs"],
    }),
    editProfile: builder.mutation({
      query: ({ id, details }) => ({
        url: `/user/${id}`,
        method: "PUT",
        body: details,
      }),
      invalidatesTags: ["ProfileInfo", "MembersCount", "Logs"],
    }),
    changePassword: builder.mutation({
      query: (details) => ({
        url: "/security/updatepassword",
        method: "POST",
        body: details,
      }),
    }),
  }),
});

export const {
  useGetAllProfileDetailsQuery,
  useCreateProfileMutation,
  useEditProfileMutation,
  useGetAllMembersQuery,
  useChangePasswordMutation,
} = myProfileApiSlice;
