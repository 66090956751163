import apiSlice from "../../app/api/apiSlice";
import { omitEmptyKeys } from "../../utils/helper";

export const insightsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTopSellingCollectionAnalytics: builder.query({
      query: (queries) => ({
        url: "/analytics/topSellingCollection",
        params: omitEmptyKeys(queries),
      }),
      keepUnusedDataFor: 0,
      transformResponse: (res) => res.data,
      providesTags: ["CollectionAnalytics"],
    }),

    getTopSellingCategoryAnalytics: builder.query({
      query: (queries) => ({
        url: "/analytics/topSellingCategory",
        params: omitEmptyKeys(queries),
      }),
      keepUnusedDataFor: 0,
      transformResponse: (res) => res.data,
      providesTags: ["CategoryAnalytics"],
    }),
  }),
});

export const {
  useGetTopSellingCollectionAnalyticsQuery,
  useGetTopSellingCategoryAnalyticsQuery,
} = insightsApiSlice;
