import apiSlice from "../../app/api/apiSlice";

export const roleApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllRoles: builder.query({
      query: (queries = {}) => {
        const queryString = new URLSearchParams(queries).toString();
        return {
          url: `/roles?${queryString}`,
        };
      },
      providesTags: ["Teams"],
    }),

    getAllRolesStatusCount: builder.query({
      query: (queries) => {
        let queryString = "";
        for (const key in queries) {
          if (queries[key]) {
            queryString = `${queryString}${queryString ? "&" : "?"}${key}=${queries[key]}`;
          }
        }
        return {
          url: `/roles/count${queryString}`,
        };
      },
      providesTags: ["RolesCount"],
    }),

    createRole: builder.mutation({
      query: (data) => ({
        url: "/roles",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Teams", "RolesCount", "Logs"],
    }),

    editRole: builder.mutation({
      query: ({ id, details }) => ({
        url: `/roles/${id}`,
        method: "PUT",
        body: details,
      }),
      invalidatesTags: ["Teams", "RolesCount", "Logs"],
    }),

    bulkEditRole: builder.mutation({
      query: (updates) => ({
        url: "/roles/bulkUpdate",
        method: "PUT",
        body: updates,
      }),
      invalidatesTags: ["Teams", "RolesCount", "Logs"],
    }),

    deleteRole: builder.mutation({
      query: (id) => ({
        url: `/roles/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Teams", "RolesCount", "Logs"],
    }),

    bulkDeleteRole: builder.mutation({
      query: (deletes) => ({
        url: "/roles/bulkDelete",
        method: "DELETE",
        body: deletes,
      }),
      invalidatesTags: ["Teams", "RolesCount", "Logs"],
    }),
  }),
});

export const {
  useGetAllRolesQuery,
  useGetAllRolesStatusCountQuery,
  useCreateRoleMutation,
  useEditRoleMutation,
  useBulkEditRoleMutation,
  useDeleteRoleMutation,
  useBulkDeleteRoleMutation,
} = roleApiSlice;
