import { useState } from 'react';
import { 
  FormControl,
  FormControlLabel,
  InputAdornment,
  OutlinedInput,
  Popover,
  Radio,
  RadioGroup,
  Tooltip,  
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

import InputBox from "../../../components/Setting/InputBox";
import TableSearch from '../../../components/TableSearch/TableSearch';
import PasswordProtection from "../../../components/ThemeComponent/PasswordProtection/PasswordProtection";
import HomeToggleOption from './ThemeSideNav/HomeToggleOption';

import info from "../../../assets/icons/info.svg";
import link from "../../../assets/icons/theme/external-link.svg";
import grid from "../../../assets/icons/theme/grid.svg";
import home2 from "../../../assets/icons/theme/home-02.svg";
import landing from "../../../assets/icons/theme/file.svg";
import category from "../../../assets/icons/theme/file-branch.svg";
import collection from "../../../assets/icons/theme/Outline.svg";
import products from "../../../assets/icons/theme/Product Tag.svg";

const homeDropdownData = [
  { image: home2, head: "Homepage", subHead: "Primary", dropDown: false},
  { image: collection, head: "Collection Page", subHead: "3 Templates", dropDown: true},
  { image: category, head: "Category Page", subHead: "2 Page", dropDown: true},
  { image: products, head: "Product Page", subHead: "3 Templates", dropDown: true},
  { image: landing, head: "Landing Page", dropDown: false},
  { image: grid, head: "Blog Page", dropDown: false},
  { image: landing, head: "Static Page", dropDown: false},
  { image: grid, head: "Store Locator", dropDown: false},
  { image: grid, head: "Cart Page", dropDown: false},
  { image: grid, head: "Checkout Page", dropDown: false}
]

const GeneralSetting = () => {
  const [anchorOption, setAnchorOption] = useState(null);
  const openOption = Boolean(anchorOption);
  const idOption = openOption ? "simple-popover" : undefined;
  
  const handleOption = (event) => {
    setAnchorOption(event.currentTarget);
  };

  const handleOptionClose = () => {
    setAnchorOption(null);
  };

  return (
    <div className="px-3 py-2">
      <div>
          <InputBox 
              heading={"Page Title"}
              tooltip={"Page Title"}
              placeholder={"Kisna Jewellers"}
          />
          <span className="text-grey-6 ex-small">
              The page title appears in the top of the browser window.
          </span>
      </div>

      <div>
        <div className="mt-3">
          <div className="d-flex justify-content-between align-items-center mb-1">
            <div className="d-flex">
              <p className="text-lightBlue fw-500">URL Handle</p>
              <Tooltip title="URL Handle" placement="top">
                <img
                  src={info}
                  alt="info"
                  className="ms-2 c-pointer"
                  width={13.5}
                />
              </Tooltip>
            </div>
          <small className="text-blue-2 c-pointer">
            <img src={link} width={16} height={16} />
          </small>
          </div>
          <FormControl className="w-100 px-0">
            <OutlinedInput
                size="small"
                name="firstName"
                startAdornment={
                  <InputAdornment position="start">
                    <small className="text-grey-6">
                      http://pcjewellers.com 
                      <small className="text-lightBlue fw-600">
                        /pcj-jewellers
                      </small>
                    </small>
                  </InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="start">
                    <ContentCopyIcon sx={{ color: "#5c6d8e", fontSize: 14, cursor: "pointer" }} />
                  </InputAdornment>
                }
            />
          </FormControl>
        </div>
        <span className="text-grey-6 ex-small">
            The unique location slug for this page.
        </span>
      </div>

      <div className='mt-4'>
        <div className="d-flex justify-content-between align-items-center bg-black-15 border-grey-5 rounded-4 py-2 px-3 text-lightBlue c-pointer"
          onClick={handleOption}
        >
          <small>Homepage</small>
          <ArrowDropDownIcon />
        </div>
        <span className="text-grey-6 ex-small">
          Select the page type like homepage, category, static page etc
        </span>
        <Popover
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          id={idOption}
          open={openOption}
          anchorEl={anchorOption}
          onClose={handleOptionClose}
        >
          <div className="px-1 py-2">
            <div className='border-grey-5 rounded-4 p-1'>
              <TableSearch />
            </div>
            <div className='text-lightBlue p-2 hover-grey mt-1 rounded-4'>
              <KeyboardArrowLeftIcon sx={{fontSize: "16px"}}/>
              <small>Main Menu</small>
            </div>
            <hr className="hr-grey-6 my-1" />
            <div className='homepage-toggle'>
            {homeDropdownData.map((item) => (
              <HomeToggleOption 
                image={item.image} 
                head={item.head} 
                subHead={item.subHead} 
                dropDown={item.dropDown} 
              />
            ))}
            </div>
          </div>
        </Popover>
      </div>

      <div className="mt-4">
        <div className="d-flex justify-content-between align-items-center mb-1">
          <p className="text-grey-6 fw-500">Search Engine</p>
        </div>
        <FormControl>
            <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                // value={selectedValue}
                // onChange={handleRadioChange}
            >
                <FormControlLabel value={true} control={<Radio size="small" />}
                  label="Visible to search engines"
                  sx={{
                      "& .MuiTypography-root": {
                        color: "#C8D8FF",
                        fontSize: "14px"
                      },
                    }}
                />
                <FormControlLabel value={false} control={<Radio size="small" />}
                  label="Hidden from search engine results"
                  sx={{
                      "& .MuiTypography-root": {
                        color: "#C8D8FF",
                        fontSize: "14px"
                      },
                    }}
                />
            </RadioGroup>
        </FormControl>
      </div>
      <small className="text-blue-2 mt-2">Schedule Visibility</small>

      <div className="d-flex justify-content-between bg-black-20 rounded-8 p-3 mt-4">
        <div className="d-flex">
          <p>Password-protected page</p>
          <Tooltip title="URL Handle" placement="top">
            <img
              src={info}
              alt="info"
              className="ms-2 c-pointer"
              width={13.5}
            />
          </Tooltip>
        </div>
        <div>
          <PasswordProtection />
        </div>
      </div>

    </div>
  );
};

export default GeneralSetting;