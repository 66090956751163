import { useEffect, useReducer, useState } from "react";
import { Link, createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import "./Roles.scss";
// ! COMPONENT IMPORTS
import RolesTable from "./RolesTable";
import TabPanel from "../../../components/TabPanel/TabPanel";
import ViewTutorial from "../../../components/ViewTutorial/ViewTutorial";
import ViewLogsDrawer from "../../../components/ViewLogsDrawer/ViewLogsDrawer";
import { TableSearchSecondary } from "../../../components/TableSearch/TableSearch";
// ! IMAGES IMPORTS
import teams from "../../../assets/icons/sidenav/teams.svg";
// ! MATERIAL IMPORTS
import { Box, Paper, Tab, Tabs, Tooltip } from "@mui/material";
// ! MATERIAL ICONS IMPORT
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  useBulkDeleteRoleMutation,
  useBulkEditRoleMutation,
  useDeleteRoleMutation,
  useEditRoleMutation,
  useGetAllRolesQuery,
  useGetAllRolesStatusCountQuery,
} from "../../../features/team/roleApiSlice";
import { showError } from "../../../features/snackbar/snackbarAction";
import { useDispatch } from "react-redux";

const initialQueryFilterState = {
  pageSize: 10,
  pageNo: 1,
  name: "",
  searchValue: "",
  createdAt: "-1",
  status: "active",
};

const queryFilterReducer = (state, action) => {
  if (action.type === "SET_PAGE_SIZE") {
    return {
      ...state,
      pageNo: initialQueryFilterState.pageNo,
      pageSize: +action.value,
    };
  }
  if (action.type === "CHANGE_PAGE") {
    return {
      ...state,
      pageNo: action.pageNo + 1,
    };
  }
  if (action.type === "SEARCH") {
    return {
      ...state,
      pageNo: initialQueryFilterState.pageNo,
      name: action.name,
    };
  }
  if (action.type === "SET_SEARCH_VALUE") {
    return {
      ...state,
      pageNo: initialQueryFilterState.pageNo,
      searchValue: action.searchValue,
    };
  }
  if (action.type === "SET_ALL_FILTERS") {
    return {
      ...initialQueryFilterState,
      ...action.filters,
    };
  }

  if (action.type === "SET_STATUS") {
    return {
      ...state,
      pageNo: initialQueryFilterState.pageNo,
      status: action.status ? action.status : initialQueryFilterState.status,
    };
  }

  return initialQueryFilterState;
};

const initialRolesState = {
  data: [],
  totalCount: 0,
  rolesType: 0,
};

const rolesReducer = (state, action) => {
  if (action.type === "SET_DATA") {
    return {
      ...state,
      data: action.data,
      totalCount: action.totalCount,
    };
  }
  if (action.type === "SET_ROLES_TYPE") {
    return {
      ...state,
      rolesType: action.rolesType,
    };
  }
  return initialRolesState;
};

export default function Roles() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [rolesState, dispatchRoles] = useReducer(rolesReducer, initialRolesState);
  const [queryFilterState, dispatchQueryFilter] = useReducer(
    queryFilterReducer,
    initialQueryFilterState
  );

  const [searchParams, setSearchParams] = useSearchParams();

  const [firstRender, setFirstRender] = useState(true);

  const {
    data: rolesData,
    isLoading: rolesIsLoading,
    isSuccess: rolesIsSuccess,
    error: rolesError,
    isError: rolesIsError,
  } = useGetAllRolesQuery(queryFilterState);

  const [editRoles] = useEditRoleMutation();

  const [bulkEdit] = useBulkEditRoleMutation();

  const [deleteRole] = useDeleteRoleMutation();

  const [bulkDeleteRoles] = useBulkDeleteRoleMutation();

  const { data: rolesStatusCount } = useGetAllRolesStatusCountQuery();

  const handleRolesTypeChangeHandler = (event, tabIndex) => {
    if (tabIndex === 0) {
      dispatchQueryFilter({
        type: "SET_STATUS",
        status: "active",
      });
    } else if (tabIndex === 1) {
      dispatchQueryFilter({
        type: "SET_STATUS",
        status: "archieved",
      });
    }
    dispatchRoles({
      type: "SET_ROLES_TYPE",
      rolesType: tabIndex,
    });
    dispatchQueryFilter({ type: "SET_SEARCH_VALUE", searchValue: "" });
    dispatchQueryFilter({ type: "SEARCH", name: "" });
  };
  const handleChangeRowsPerPage = (event) => {
    dispatchQueryFilter({ type: "SET_PAGE_SIZE", value: event.target.value });
  };

  const handleChangePage = (_, pageNo) => {
    dispatchQueryFilter({ type: "CHANGE_PAGE", pageNo });
  };

  const handleSearchChange = (value) => {
    dispatchQueryFilter({ type: "SEARCH", name: value });
  };

  const handleSearchValue = (value) => {
    dispatchQueryFilter({ type: "SET_SEARCH_VALUE", searchValue: value });
  };

  const editRolesPageNavigation = (data) => {
    navigate({
      pathname: `./edit/${data ? data._id : ""}`,
      search: `?${createSearchParams({
        filter: JSON.stringify({
          ...queryFilterState,
          rolesType: rolesState.rolesType,
        }),
      })}`,
    });
  };

  useEffect(() => {
    if (rolesIsSuccess) {
      if (rolesState.rolesType === 0) {
        dispatchRoles({
          type: "SET_DATA",
          data: rolesData?.data?.data,
          totalCount: rolesData?.data?.totalCount,
        });
      }
      if (rolesState.rolesType === 1) {
        dispatchRoles({
          type: "SET_DATA",
          data: rolesData?.data?.data,
          totalCount: rolesData?.data?.totalCount,
        });
      }
    }
    if (rolesIsError) {
      if (rolesError?.data?.message) {
        dispatch(showError({ message: rolesError.data.message }));
      } else {
        dispatch(showError({ message: "Something went wrong!, please try again" }));
      }
    }
  }, [
    rolesIsSuccess,
    rolesIsError,
    rolesError,
    dispatch,
    rolesData,
    rolesState.rolesType,
    // deleteDiscountIsSuccess,
    // bulkDeleteDiscountIsSuccess,
  ]);

  useEffect(() => {
    const filterParams = JSON.parse(searchParams.get("filter")) || {
      rolesType: rolesState.rolesType,
    };
    if (firstRender && Object.keys(filterParams).length) {
      let filters = {};
      for (let key in filterParams) {
        if (key !== "rolesType") {
          if (filterParams[key] !== (null || "")) {
            filters = {
              ...filters,
              [key]: filterParams[key],
            };
          }
        } else {
          dispatchRoles({
            type: "SET_ROLES_TYPE",
            rolesType: +filterParams[key],
          });
        }
      }
      if (filterParams.rolesType === (null || "")) {
        dispatchRoles({ type: "SET_ROLES_TYPE", rolesType: 0 });
      }
      dispatchQueryFilter({
        type: "SET_ALL_FILTERS",
        filters,
      });
      setFirstRender(false);
    }
  }, [firstRender, rolesState.rolesType, searchParams]);

  useEffect(() => {
    if (!firstRender) {
      setSearchParams({
        filter: JSON.stringify({
          ...queryFilterState,
          rolesType: rolesState.rolesType,
        }),
      });
    }
  }, [queryFilterState, setSearchParams, rolesState.rolesType, firstRender]);

  return (
    <div className="container-fluid page">
      <div className="row justify-content-between align-items-center">
        <div className="d-flex w-auto align-items-center px-0">
          <h4 className="page-heading w-auto ps-0 me-2">Team Roles</h4>

          <Tooltip
            title="Lorem ipsum"
            placement="top">
            <InfoOutlinedIcon
              sx={{ color: "#c8d8ff", fontSize: 20 }}
              className="c-pointer"
            />
          </Tooltip>
        </div>
        <div className="d-flex align-items-center w-auto pe-0">
          {/* <ViewTutorial /> */}
          <ViewLogsDrawer
            headingName={"Team Role Module"}
            icon={teams}
            module="teams"
          />
          <Link
            to="/teams/roles/create"
            className="button-gradient py-2 px-4">
            <p>+ Create Roles</p>
          </Link>
        </div>
      </div>

      <div className="row">
        <Paper
          sx={{ width: "100%", mb: 0, mt: 3, p: 0 }}
          className="border-grey-5 bg-black-15">
          <Box
            sx={{ width: "100%" }}
            className="d-flex justify-content-between tabs-header-box">
            {/* variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile */}
            <Tabs
              value={rolesState?.rolesType}
              onChange={handleRolesTypeChangeHandler}
              aria-label="scrollable force tabs example"
              className="tabs">
              <Tab
                label={`Roles ${
                  rolesStatusCount?.data[0]?.active || rolesStatusCount?.data[0]?.inActive
                    ? `(${
                        rolesStatusCount?.data[0]?.active + rolesStatusCount?.data[0]?.inActive
                      })`
                    : ""
                }`}
                className="tabs-head"
              />
              <Tab
                label={`Archived ${
                  rolesStatusCount?.data[0]?.archived
                    ? `(${rolesStatusCount?.data[0]?.archived})`
                    : ""
                }`}
                className="tabs-head"
              />
              {/* <Tab label="Roles" className="tabs-head" />
              <Tab label="Archived" className="tabs-head" /> */}
            </Tabs>
          </Box>
          <div className="d-flex align-items-center mt-3 mb-3 px-2 justify-content-between">
            <TableSearchSecondary
              onSearchValueChange={handleSearchValue}
              value={queryFilterState.searchValue}
              onChange={handleSearchChange}
            />
          </div>
          <TabPanel
            value={rolesState.rolesType}
            index={0}>
            <RolesTable
              isLoading={rolesIsLoading}
              list={rolesState?.data}
              totalCount={rolesState?.totalCount}
              changeRowsPerPage={handleChangeRowsPerPage}
              rowsPerPage={queryFilterState.pageSize}
              changePage={handleChangePage}
              page={queryFilterState.pageNo}
              rolesType={rolesState?.rolesType}
              editRoles={editRoles}
              edit={editRolesPageNavigation}
              bulkEdit={bulkEdit}
              deleteData={deleteRole}
              bulkDelete={bulkDeleteRoles}
            />
          </TabPanel>
          <TabPanel
            value={rolesState.rolesType}
            index={1}>
            <RolesTable
              isLoading={rolesIsLoading}
              list={rolesState?.data}
              totalCount={rolesState?.totalCount}
              changeRowsPerPage={handleChangeRowsPerPage}
              rowsPerPage={queryFilterState.pageSize}
              changePage={handleChangePage}
              page={queryFilterState.pageNo}
              rolesType={rolesState?.rolesType}
              editRoles={editRoles}
              bulkEdit={bulkEdit}
              deleteData={deleteRole}
              bulkDelete={bulkDeleteRoles}
            />
          </TabPanel>
        </Paper>
      </div>
    </div>
  );
}
