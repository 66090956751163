import { useState } from 'react';
import { useDispatch } from 'react-redux';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ImageUpload from '../../Component/ImageUpload';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import _ from 'lodash';
import {
  useGetAllBannerQuery,
  useUpdateBannerMutation,
} from '../../../../../features/theme/content/bannerApiSlice';
import {
  showSuccess,
  showError,
} from '../../../../../features/snackbar/snackbarAction';
import SnackbarUtils from '../../../../../features/snackbar/useSnackbar';
import BestSellerImage from './BestSellerImage';

const bannerValidationSchema = Yup.object({
  type: Yup.string().trim().required('Required'),
  redirectId: Yup.string().trim().required('Required'),
  backgroundType: Yup.string().trim().required('Required'),
});

const BestSeller = () => {
  const dispatch = useDispatch();
  const [selectedBanner, setSelectedBanner] = useState(null);
  const { data: bannersData } = useGetAllBannerQuery({});
  const [updateBanner, { isLoading: updateBannerIsLoading }] =
    useUpdateBannerMutation();

  const bestSellerData = bannersData?.data?.[0]?.attributes.find(
    (item) => item._id === '65701bcecd46d649a8aeea01'
  );

  const bannerFormik = useFormik({
    initialValues: {
      id: selectedBanner?.id || '',
      type: selectedBanner?.type || 'bestSeller',
      redirectId: selectedBanner?.redirectId || '',
      backgroundType: selectedBanner?.backgroundType || '',
    },

    enableReinitialize: true,
    validationSchema: bannerValidationSchema,
    onSubmit: (values) => {
      SnackbarUtils.savingToast();
      updateBanner({
        details: values,
        structureId: bestSellerData._id,
        attributeId: bannersData?.data?.[0]?._id,
      })
        .unwrap()
        .then(() => {
          SnackbarUtils.hideToast();
          dispatch(
            showSuccess({
              message: 'Banner updated successfully',
            })
          );
        })
        .catch((error) => {
          SnackbarUtils.hideToast();
          if (error?.data?.message) {
            dispatch(showError({ message: error.data.message }));
          } else {
            dispatch(
              showError({
                message: 'Something went wrong!, please try again',
              })
            );
          }
        });
    },
  });

  const selectedBannerHandler = (banner) => {
    setSelectedBanner({
      id: banner?._id || '',
      type: banner?.type || '',
      redirectId: banner?.redirectId || '',
      backgroundType: banner?.backgroundType || '',
    });
    bannerFormik.setFieldValue('id', banner?._id);
    bannerFormik.setFieldValue('type', banner?.type);
    bannerFormik.setFieldValue('redirectId', banner?.redirectId);
    bannerFormik.setFieldValue('backgroundType', banner?.backgroundType);
  };

  return (
    <div className='py-3 px-3 rounded-8 mb-3'>
      <div className='my-2'>
        {bestSellerData?.structure?.map((item, index) => (
          <div key={index} className='bg-black-20 py-3 px-3 rounded-8 mb-3'>
            <div
              className='d-flex align-items-center justify-content-between c-pointer'
              onClick={
                selectedBanner?.id === item._id
                  ? selectedBannerHandler.bind(null, {})
                  : selectedBannerHandler.bind(null, item)
              }
            >
              <div className='d-flex align-items-center'>
                <p className='text-lightBlue fw-500'>{`Tab ${index + 1}`}</p>
              </div>
              <ArrowDropDownIcon sx={{ color: '#C8D8FF' }} />
            </div>
            {selectedBanner?.id === item._id && (
              <div>
                <BestSellerImage
                  isLoading={updateBannerIsLoading}
                  formik={bannerFormik}
                />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default BestSeller;
