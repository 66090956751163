import { forwardRef, useEffect, useMemo, useReducer, useState } from "react";
import "./AllProducts.scss";
import { useDropzone } from "react-dropzone";
import { Link, useSearchParams } from "react-router-dom";
// ! IMAGES IMPORTS
import indiaFlag from "../../../assets/images/products/indiaFlag.svg";
// import columns from "../../../assets/icons/columns.svg";
import cancel from "../../../assets/icons/cancel.svg";
import tutorial from "../../../assets/icons/tutorial.svg";
import allFlag from "../../../assets/images/products/allFlag.svg";
import usaFlag from "../../../assets/images/products/usaFlag.svg";
import ukFlag from "../../../assets/images/products/ukFlag.svg";
import arrowDown from "../../../assets/icons/arrowDown.svg";
import sort from "../../../assets/icons/sort.svg";
import uploadLineSheet from "../../../assets/images/products/uploadLineSheet.svg";
import uploadCompanySheet1 from "../../../assets/images/products/uploadCompanySheet1.svg";
import uploadCompanySheet2 from "../../../assets/images/products/uploadCompanySheet2.svg";
// import info from "../../../assets/icons/info.svg";
import filter from "../../../assets/icons/filter.svg";
import products from "../../../assets/icons/sidenav/products.svg";
// ! COMPONENT IMPORTS
import AllProductsTable from "./AllProductsTable";
import TabPanel from "../../../components/TabPanel/TabPanel";
// ! MATERIAL IMPORTS
import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  Paper,
  Popover,
  Radio,
  RadioGroup,
  Slide,
  Slider,
  SwipeableDrawer,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
// ! MATERIAL ICONS IMPORTS
// import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
// import CheckBoxIcon from "@mui/icons-material/CheckBox";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import { TableSearchSecondary } from "../../../components/TableSearch/TableSearch";
import ViewLogsDrawer from "../../../components/ViewLogsDrawer/ViewLogsDrawer";
import {
  useGetAllProductStatusCountQuery,
  useGetAllProductsQuery,
} from "../../../features/products/product/productApiSlice";
import { useGetAllVendorsQuery } from "../../../features/parameters/vendors/vendorsApiSlice";
import {
  useGetAllCategoriesQuery,
  useGetAllSubCategoriesQuery,
} from "../../../features/parameters/categories/categoriesApiSlice";
import { useGetAllCollectionsQuery } from "../../../features/parameters/collections/collectionsApiSlice";
import { useGetAllTagsQuery } from "../../../features/parameters/tagsManager/tagsManagerApiSlice";
import { useGetProductsCsvMutation } from "../../../features/importExport/order/orderApiSlice";
// import ExportDialogOrder from "../../../components/ExportDialog/ExportDialogOrder";
import ExportDialogCustom from "../../../components/ExportDialog/ExportDialogCustom";
import ExportDialogProduct from "../../../components/ExportDialog/ExportDialogProduct";
import ImportProductDialog from "./ImportProductDialog";

// ? FILTER ACCORDIAN STARTS HERE
const Accordion = styled((props) => (
  <MuiAccordion
    disableGutters
    elevation={0}
    square
    {...props}
  />
))(({ theme }) => ({
  // border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem", color: "#c8d8ff" }} />}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    padding: "0px",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: "0 16px ",
}));
// ? FILTER ACCORDIAN ENDS HERE

// ? FILE UPLOAD STARTS HERE
const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "0",
  borderWidth: 2,
  borderRadius: 8,
  borderColor: "#38395c",
  borderStyle: "dashed",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
  cursor: "pointer",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};
// ? FILE UPLOAD ENDS HERE

const initialQueryFilterState = {
  pageSize: 10,
  pageNo: 1,
  tabIndex: 0,
  minPrice: 0,
  maxPrice: 0,
  title: "",
  status: ["active", "in-active", "scheduled"],
  isCompleted: "",
  category: "",
  subCategory: "",
  vendor: "",
  collection: "",
  tagManager: "",
  alphabetical: "",
  createdAt: "-1",
  updatedAt: "",
};

const queryFilterReducer = (state, action) => {
  if (action.type === "SET_PAGE_SIZE") {
    return {
      ...state,
      pageNo: initialQueryFilterState.pageNo,
      pageSize: +action.pageSize,
    };
  }
  if (action.type === "CHANGE_PAGE") {
    return {
      ...state,
      pageNo: +action.pageNo,
    };
  }
  if (action.type === "SEARCH") {
    return {
      ...state,
      pageNo: initialQueryFilterState.pageNo,
      title: action.name,
    };
  }
  if (action.type === "SEARCH_CATEGORY") {
    return {
      ...state,
      pageNo: initialQueryFilterState.pageNo,
      category: action.data,
    };
  }
  if (action.type === "SEARCH_SUB_CATEGORY") {
    return {
      ...state,
      pageNo: initialQueryFilterState.pageNo,
      subCategory: action.data,
    };
  }
  if (action.type === "SEARCH_COLLECTION") {
    return {
      ...state,
      pageNo: initialQueryFilterState.pageNo,
      collection: action.data,
    };
  }
  if (action.type === "SEARCH_TAG") {
    return {
      ...state,
      pageNo: initialQueryFilterState.pageNo,
      tagManager: action.data,
    };
  }
  if (action.type === "SEARCH_VENDOR") {
    return {
      ...state,
      pageNo: initialQueryFilterState.pageNo,
      vendor: action.data,
    };
  }
  if (action.type === "SET_SORTING") {
    const { alphabetical, createdAt, updatedAt } = action;
    return {
      ...state,
      pageNo: initialQueryFilterState.pageNo,
      alphabetical: alphabetical ?? "",
      createdAt: createdAt ?? "",
      updatedAt: updatedAt ?? "",
    };
  }
  if (action.type === "SET_STATUS") {
    return {
      ...state,
      pageNo: initialQueryFilterState.pageNo,
      status: action.status,
    };
  }
  if (action.type === "SET_COMPLETED") {
    return {
      ...state,
      pageNo: initialQueryFilterState.pageNo,
      isCompleted: action.isCompleted,
    };
  }
  if (action.type === "SET_TAB_INDEX") {
    const { status, tabIndex } = action;
    return {
      ...state,
      status,
      tabIndex,
      title: initialQueryFilterState.title,
      pageNo: initialQueryFilterState.pageNo,
    };
  }
  if (action.type === "SET_MIN_PRICE") {
    return {
      ...state,
      pageNo: initialQueryFilterState.pageNo,
      minPrice: action.value,
    };
  }
  if (action.type === "SET_MAX_PRICE") {
    return {
      ...state,
      pageNo: initialQueryFilterState.pageNo,
      maxPrice: action.value,
    };
  }
  if (action.type === "SET_RANGE_PRICE") {
    return {
      ...state,
      pageNo: initialQueryFilterState.pageNo,
      minPrice: action.minPrice,
      maxPrice: action.maxPrice,
    };
  }

  if (action.type === "SET_ALL_FILTERS") {
    return {
      ...initialQueryFilterState,
      ...state,
      ...action.filter,
    };
  }
  return initialQueryFilterState;
};

const minDistance = 1000;

export default function AllProducts() {
  const [queryFilterState, dispatchQueryFilter] = useReducer(
    queryFilterReducer,
    initialQueryFilterState
  );
  const [selected, setSelected] = useState([]);
  // const [valueExport, setExportValue] = useState(0);
  const [importValue, setImportValue] = useState("importProducts");
  const [sortFilter, setSortFilter] = useState("createdAt");
  const [importSecondValue, setImportSecondValue] = useState("uploadLineSheet");
  const [searchValue, setSearchValue] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [chipData, setChipData] = useState([]);
  const [firstRender, setFirstRender] = useState(true);
  // const [firstRenderMaxPrice, setFirstRenderMaxPrice] = useState(true);
  // Experimental Range Slider
  // const [value1, setValue1] = useState([5000, 50000]);

  // Experimental Range Slider

  const { data: productsData, isLoading: productsIsLoading } = useGetAllProductsQuery({
    queries: queryFilterState,
    exclude: [],
  });
  const allProducts = productsData?.data ?? [];
  const totalProductCount = productsData?.totalCount ?? 0;

  const { data: vendorsData } = useGetAllVendorsQuery();
  const allVendors = vendorsData?.data?.data ?? [];

  const { data: categoriesData } = useGetAllCategoriesQuery();
  const allCategories = categoriesData?.data?.data ?? [];

  const { data: subCategoriesData } = useGetAllSubCategoriesQuery();
  const allSubCategories = subCategoriesData?.data?.data ?? [];

  const { data: tagsData } = useGetAllTagsQuery();
  const allTags = tagsData?.data?.data ?? [];

  const { data: collectionsData } = useGetAllCollectionsQuery();
  const allCollections = collectionsData?.data?.data ?? [];

  const { data: allProductStatusCount } = useGetAllProductStatusCountQuery();
  const ProductStatusCount = allProductStatusCount?.data?.[0] ?? {
    active: 0,
    archived: 0,
    inActive: 0,
    scheduled: 0,
    maxPrice: 0,
  };

  // const handleSearchChange = (event) => {
  //   setSearchValue(event.target.value);
  //   dispatchQueryFilter({ type: "SEARCH", name: event.target.value });
  // };

  const handleDelete = (item) => {
    setChipData(chipData.filter((i) => i !== item));
    if (item.startsWith("vendor")) {
      dispatchQueryFilter({
        type: "SEARCH_VENDOR",
        data: "",
      });
    }
    if (item.startsWith("Tag")) {
      dispatchQueryFilter({
        type: "SEARCH_TAG",
        data: "",
      });
    }
    if (item.startsWith("Category")) {
      dispatchQueryFilter({
        type: "SEARCH_CATEGORY",
        data: "",
      });
    }
    if (item.startsWith("Sub")) {
      dispatchQueryFilter({
        type: "SEARCH_SUB_CATEGORY",
        data: "",
      });
    }
    if (item.startsWith("Collection")) {
      dispatchQueryFilter({
        type: "SEARCH_COLLECTION",
        data: "",
      });
    }
    if (item.startsWith("title")) {
      dispatchQueryFilter({ type: "SEARCH", name: "" });
      setSearchValue("");
    }
    if (item.startsWith("Showing")) {
      dispatchQueryFilter({
        type: "SET_COMPLETED",
        isCompleted: initialQueryFilterState.isCompleted,
      });
    }
    if (item.startsWith("Status")) {
      dispatchQueryFilter({
        type: "SET_STATUS",
        status: initialQueryFilterState.status,
      });
    }
    if (item.startsWith("Price Range")) {
      dispatchQueryFilter({
        type: "SET_RANGE_PRICE",
        minPrice: 0,
        maxPrice: 0,
      });
    }
    setSelected([]);
  };

  function handleVendorChange(e, newValue) {
    dispatchQueryFilter({
      type: "SEARCH_VENDOR",
      data: newValue?.name ?? "",
    });
    setSelected([]);
    if (newValue?.name) {
      setChipData((data) => {
        // Filter out any chip that starts with
        const filteredData = data.filter((item) => !item.startsWith("vendor"));
        filteredData.push(`vendor is ${newValue?.name}`);

        return filteredData;
      });
    } else {
      setChipData((data) => {
        // Filter out any chip that starts with
        const filteredData = data.filter((item) => !item.startsWith("vendor"));

        return filteredData;
      });
    }
  }

  function handleTagChange(e, newValue) {
    dispatchQueryFilter({
      type: "SEARCH_TAG",
      data: newValue?.name ?? "",
    });
    setSelected([]);
    if (newValue?.name) {
      setChipData((data) => {
        // Filter out any chip that starts with
        const filteredData = data.filter((item) => !item.startsWith("Tag"));
        filteredData.push(`Tag is ${newValue?.name}`);

        return filteredData;
      });
    } else {
      setChipData((data) => {
        // Filter out any chip that starts with
        const filteredData = data.filter((item) => !item.startsWith("Tag"));

        return filteredData;
      });
    }
  }

  function handleCategoryChange(e, newValue) {
    dispatchQueryFilter({
      type: "SEARCH_CATEGORY",
      data: newValue?.name ?? "",
    });
    setSelected([]);
    if (newValue?.name) {
      setChipData((data) => {
        // Filter out any chip that starts with
        const filteredData = data.filter((item) => !item.startsWith("Category"));
        filteredData.push(`Category is ${newValue?.name}`);

        return filteredData;
      });
    } else {
      setChipData((data) => {
        // Filter out any chip that starts with
        const filteredData = data.filter((item) => !item.startsWith("Category"));

        return filteredData;
      });
    }
  }

  function handleSubCategoryChange(e, newValue) {
    dispatchQueryFilter({
      type: "SEARCH_SUB_CATEGORY",
      data: newValue?.name ?? "",
    });
    setSelected([]);
    if (newValue?.name) {
      setChipData((data) => {
        // Filter out any chip that starts with
        const filteredData = data.filter((item) => !item.startsWith("Sub"));
        filteredData.push(`Sub category is ${newValue?.name}`);

        return filteredData;
      });
    } else {
      setChipData((data) => {
        // Filter out any chip that starts with
        const filteredData = data.filter((item) => !item.startsWith("Sub"));

        return filteredData;
      });
    }
  }

  function handleCollectionChange(e, newValue) {
    dispatchQueryFilter({
      type: "SEARCH_COLLECTION",
      data: newValue?.title ?? "",
    });
    setSelected([]);
    if (newValue?.title) {
      setChipData((data) => {
        // Filter out any chip that starts with
        const filteredData = data.filter((item) => !item.startsWith("Collection"));
        filteredData.push(`Collection is ${newValue?.title}`);

        return filteredData;
      });
    } else {
      setChipData((data) => {
        // Filter out any chip that starts with
        const filteredData = data.filter((item) => !item.startsWith("Collection"));

        return filteredData;
      });
    }
  }

  const handlePriceRangeChange = (e, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    const filter = {
      type: "SET_RANGE_PRICE",
      minPrice: Math.max(Math.min(newValue[0], queryFilterState.maxPrice - minDistance), 0),
      maxPrice: Math.max(newValue[1], queryFilterState.minPrice + minDistance),
    };

    dispatchQueryFilter(filter);
    setSelected([]);

    setChipData((data) => {
      // Filter out any chip that starts with
      const filteredData = data.filter((item) => !item.startsWith("Price Range"));
      filteredData.push(`Price Range is ${filter.minPrice} - ${filter.maxPrice}`);

      return filteredData;
    });

    // if (activeThumb === 0) {
    //   dispatchQueryFilter({
    //     type: "SET_MIN_PRICE",
    //     value: Math.min(newValue[0], queryFilterState.maxPrice - minDistance),
    //   });
    // } else {
    //   dispatchQueryFilter({
    //     type: "SET_MAX_PRICE",
    //     value: Math.max(newValue[1], queryFilterState.minPrice + minDistance),
    //   });
    // }
  };

  const clearAllFilter = () => {
    const {
      pageNo,
      minPrice,
      maxPrice,
      title,
      status,
      isCompleted,
      category,
      subCategory,
      vendor,
      collection,
      tagManager,
      alphabetical,
      createdAt,
      updatedAt,
    } = initialQueryFilterState;
    setChipData([]);
    setSearchValue("");
    dispatchQueryFilter({
      type: "SET_ALL_FILTERS",
      filter: {
        pageNo,
        minPrice,
        maxPrice,
        title,
        status,
        isCompleted,
        category,
        subCategory,
        vendor,
        collection,
        tagManager,
        alphabetical,
        createdAt,
        updatedAt,
      },
    });
    setSelected([]);
  };

  const handleImportChange = (event, newValue) => {
    setImportValue(newValue);
  };
  const handleImportSecondChange = (event, newValue) => {
    setImportSecondValue(newValue);
  };

  const handleTabChange = (event, tabIndex) => {
    setSearchValue("");
    switch (tabIndex) {
      case 0: {
        dispatchQueryFilter({
          type: "SET_TAB_INDEX",
          status: ["active", "in-active", "scheduled"],
          tabIndex,
        });
        setSelected([]);
        break;
      }
      case 1: {
        dispatchQueryFilter({
          type: "SET_TAB_INDEX",
          status: ["active"],
          tabIndex,
        });
        setSelected([]);
        break;
      }
      case 2: {
        dispatchQueryFilter({
          type: "SET_TAB_INDEX",
          status: ["in-active"],
          tabIndex,
        });
        setSelected([]);
        break;
      }
      case 3: {
        dispatchQueryFilter({
          type: "SET_TAB_INDEX",
          status: ["archived"],
          tabIndex,
        });
        setSelected([]);
        break;
      }

      default:
        break;
    }
  };

  const handleCompletionChange = (_, v) => {
    dispatchQueryFilter({ type: "SET_COMPLETED", isCompleted: v });
    setSelected([]);
    switch (v) {
      case "":
        setChipData((cd) => cd.filter((item) => !item.startsWith("Showing")));
        break;
      default:
        setChipData((cd) =>
          cd
            .filter((item) => !item.startsWith("Showing"))
            .concat(`Showing ${v === "0" ? "Incomplete" : "Complete"} products`)
        );
        break;
    }
  };

  const handleStatusChange = (event) => {
    const { value, checked } = event.target;

    const status = checked
      ? queryFilterState.status.concat(value)
      : queryFilterState.status.filter((s) => s !== value);

    dispatchQueryFilter({
      type: "SET_STATUS",
      status: status.length === 0 ? initialQueryFilterState.status : status,
    });
    setSelected([]);

    switch (status.length) {
      case 0:
      case 3:
        setChipData((data) => {
          // Filter out any chip that starts with
          const filteredData = data.filter((item) => !item.startsWith("Status"));

          return filteredData;
        });
        break;

      default:
        setChipData((data) => {
          // Filter out any chip that starts with
          const filteredData = data.filter((item) => !item.startsWith("Status"));
          filteredData.push(`Status is ${status}`);

          return filteredData;
        });
        break;
    }
  };
  // const handleExportChange = (event, newValue) => {
  //   setExportValue(newValue);
  // };

  const handleChangeRowsPerPage = (event) => {
    dispatchQueryFilter({
      type: "SET_PAGE_SIZE",
      pageSize: event.target.value,
    });
    setSelected([]);
  };

  const handleChangePage = (_, pageNo) => {
    dispatchQueryFilter({ type: "CHANGE_PAGE", pageNo: pageNo + 1 });
    setSelected([]);
  };

  // ? FILTER DRAWER STARTS HERE
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event && event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  // ? FILTER DRAWER ENDS HERE

  // ? POPOVERS STARTS HERE

  // * FLAG POPOVERS STARTS
  const [anchorFlagEl, setAnchorFlagEl] = useState(null);
  const handleFlagClick = (event) => {
    setAnchorFlagEl(event.currentTarget);
  };
  const handleFlagClose = () => {
    setAnchorFlagEl(null);
  };
  const openFlag = Boolean(anchorFlagEl);
  const idFlag = openFlag ? "simple-popover" : undefined;
  // * FLAG POPOVERS ENDS

  // * SAVE FILTER POPOVERS STARTS
  // const [anchorSaveFilterEl, setAnchorSaveFilterEl] = useState(null);
  // const handleSaveFilterClick = (event) => {
  //   setAnchorSaveFilterEl(event.currentTarget);
  // };
  // const handleSaveFilterClose = () => {
  //   setAnchorSaveFilterEl(null);
  // };
  // const openSaveFilter = Boolean(anchorSaveFilterEl);
  // const idSaveFilter = openSaveFilter ? "simple-popover" : undefined;
  // * SAVE FILTER POPOVERS ENDS

  // * COLUMNS POPOVERS STARTS
  // const [anchorColumnsEl, setAnchorColumnsEl] = useState(null);

  // const handleColumnsClick = (event) => {
  //   setAnchorColumnsEl(event.currentTarget);
  // };

  // const handleColumnsClose = () => {
  //   setAnchorColumnsEl(null);
  // };

  // const openColumns = Boolean(anchorColumnsEl);
  // const idColumns = openColumns ? "simple-popover" : undefined;
  // * COLUMNS POPOVERS ENDS

  // * SORT POPOVERS STARTS
  const [anchorSortEl, setAnchorSortEl] = useState(null);

  const handleSortClick = (event) => {
    setAnchorSortEl(event.currentTarget);
  };

  const handleSortClose = () => {
    setAnchorSortEl(null);
  };

  const openSort = Boolean(anchorSortEl);
  const idSort = openSort ? "simple-popover" : undefined;
  // * SORT POPOVERS ENDS

  // * VENDOR POPOVERS STARTS
  const [anchorVendorEl, setAnchorVendorEl] = useState(null);
  const handleVendorClick = (event) => {
    setAnchorVendorEl(event.currentTarget);
  };
  const handleVendorClose = () => {
    setAnchorVendorEl(null);
  };
  const openVendor = Boolean(anchorVendorEl);
  const idVendor = openVendor ? "simple-popover" : undefined;
  // * VENDOR POPOVERS ENDS
  const [anchorStatusEl, setAnchorStatusEl] = useState(null);
  const handleStatusClick = (event) => {
    setAnchorStatusEl(event.currentTarget);
  };
  const handleStatusClose = () => {
    setAnchorStatusEl(null);
  };
  const openStatus = Boolean(anchorStatusEl);
  const idStatus = openStatus ? "simple-popover" : undefined;
  const [anchorCompleteEl, setAnchorCompleteEl] = useState(null);
  const handleCompleteClick = (event) => {
    setAnchorCompleteEl(event.currentTarget);
  };
  const handleCompleteClose = () => {
    setAnchorCompleteEl(null);
  };
  const openComplete = Boolean(anchorCompleteEl);
  const idComplete = openComplete ? "simple-popover" : undefined;
  // * CATEGORY POPOVERS STARTS
  const [anchorCategoryEl, setAnchorCategoryEl] = useState(null);

  const handleCategoryClick = (event) => {
    setAnchorCategoryEl(event.currentTarget);
  };

  const handleCategoryClose = () => {
    setAnchorCategoryEl(null);
  };

  const openCategory = Boolean(anchorCategoryEl);
  const idCategory = openCategory ? "simple-popover" : undefined;
  // * CATEGORY POPOVERS ENDS

  // * TAGGED WITH POPOVERS STARTS
  const [anchorTaggedWithEl, setAnchorTaggedWithEl] = useState(null);

  const handleTaggedWithClick = (event) => {
    setAnchorTaggedWithEl(event.currentTarget);
  };

  const handleTaggedWithClose = () => {
    setAnchorTaggedWithEl(null);
  };

  const openTaggedWith = Boolean(anchorTaggedWithEl);
  const idTaggedWith = openTaggedWith ? "simple-popover" : undefined;
  // * TAGGED WITH POPOVERS ENDS

  // ? POPOVERS ENDS HERE

  // ? FILTER ACCORDIAN STARTS HERE
  const [expanded, setExpanded] = useState("panel1");

  const handleAccordianChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  // ? FILTER ACCORDIAN ENDS HERE

  // ? EXPORT DIALOG STARTS HERE
  // const [openExport, setOpenExport] = useState(false);

  // const handleExportOpen = () => {
  //   setOpenExport(true);
  // };

  // const handleExportClose = () => {
  //   setOpenExport(false);
  // };
  // ? EXPORT DIALOG ENDS HERE

  // ? IMPORT DIALOG STARTS HERE
  const [openImport, setOpenImport] = useState(false);
  // ? IMPORT DIALOG ENDS HERE

  // ? IMPORT SECOND DIALOG STARTS HERE
  const [openImportSecond, setOpenImportSecond] = useState(false);

  const handleImportSecondOpen = () => {
    setOpenImport(false);
    setOpenImportSecond(true);
  };

  const handleSortRadioChange = (event) => {
    const sortType = event.target.value;
    setSortFilter(sortType);
    dispatchQueryFilter({ type: "SET_SORTING", [sortType]: "-1" });
    setSelected([]);
    // setAnchorSortEl(null);
  };

  const handleSortOrderRadioChange = (event) => {
    const sortValue = event.target.value;
    dispatchQueryFilter({ type: "SET_SORTING", [sortFilter]: sortValue });
    setSelected([]);
    // setAnchorSortEl(null);
  };

  const handleImportSecondClose = () => {
    setOpenImportSecond(false);
  };
  // ? IMPORT SECOND DIALOG ENDS HERE

  // ? FILE UPLOAD STARTS HERE
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    accept: {
      "image/*": [".jpeg", ".jpg", ".png"],
    },
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );
  // ? FILE UPLOAD ENDS HERE

  // console.log(queryFilterState);

  useEffect(() => {
    if (firstRender) {
      if (searchParams.has("filter")) {
        try {
          const filter = JSON.parse(searchParams.get("filter"));
          dispatchQueryFilter({ type: "SET_ALL_FILTERS", filter });
          setSearchValue(filter?.title ?? "");
          const chips = [];
          if (!!filter?.category) chips.push(`Category is ${filter.category}`);
          if (!!filter?.vendor) chips.push(`vendor is ${filter.vendor}`);
          if (!!filter?.tagManager) chips.push(`Tag is ${filter.tagManager}`);
          if (!!filter?.subCategory) chips.push(`Sub category is ${filter.subCategory}`);
          if (!!filter?.collection) chips.push(`Collection is ${filter.collection}`);
          if (!!filter?.maxPrice || !!filter.minPrice)
            chips.push(`Price Range is ${filter.minPrice} - ${filter.maxPrice}`);
          if (!!filter?.isCompleted)
            chips.push(
              `Showing ${filter?.isCompleted === "0" ? "Incomplete" : "Complete"} products`
            );
          if (
            !!filter?.status &&
            Array.isArray(filter.status) &&
            filter?.status.length > 0 &&
            filter?.status.length < 3
          )
            chips.push(`Status is ${filter.status}`);
          setChipData(chips);
        } catch (error) {
          console.warn(error);
          dispatchQueryFilter({ type: "SET_ALL_FILTERS", filter: {} });
        }
      }
      setSelected([]);
      setFirstRender(false);
    } else {
      setSearchParams({ filter: JSON.stringify(queryFilterState) });
    }
  }, [queryFilterState, setSearchParams, firstRender, searchParams]);

  // useEffect(() => {
  //   if (
  //     firstRenderMaxPrice &&
  //     queryFilterState.maxPrice === 0 &&
  //     allProductStatusCountIsSuccess
  //   ) {
  //     setFirstRenderMaxPrice(false);
  //     console.log(ProductStatusCount.maxPrice, queryFilterState.minPrice);
  //     dispatchQueryFilter({
  //       type: "SET_MAXX_PRICE",
  //       value: Math.max(
  //         Math.ceil(ProductStatusCount.maxPrice / minDistance) * minDistance,
  //         queryFilterState.minPrice + minDistance
  //       ),
  //     });
  //   }
  // }, [
  //   ProductStatusCount.maxPrice,
  //   queryFilterState.minPrice,
  //   queryFilterState.maxPrice,
  //   firstRenderMaxPrice,
  //   allProductStatusCountIsSuccess,
  // ]);
  return (
    <div className="container-fluid page">
      <div className="row justify-content-between align-items-center">
        <h4 className="page-heading w-auto ps-0">All Products</h4>
        <div className="d-flex align-items-center w-auto pe-0">
          {/* <button className="button-transparent me-1 py-2 px-3">
            <img
              src={tutorial}
              alt="tutorial"
              className="me-2"
              width={20}
            />
            <p className="text-blue-gradient">Tutorial</p>
          </button> */}
          <ViewLogsDrawer
            icon={products}
            module="product"
            headingName="Product Module"
          />
          <ExportDialogProduct
            queryFilters={queryFilterState}
            currentSelection={selected}
          />
          <button
            className="button-transparent me-3 py-2 px-3 me-3"
            onClick={() => setOpenImport(true)}>
            <p className="text-lightBlue">Import</p>
          </button>
          <Link
            to={`./addProduct${window.location.search}`}
            className="button-gradient py-2 px-4">
            <p>+ Add Product</p>
          </Link>
        </div>
      </div>
      <div className="row">
        <Paper
          sx={{ width: "100%", mb: 2, mt: 3, p: 0 }}
          className="border-grey-5 bg-black-15">
          <Box
            sx={{ width: "100%" }}
            className="d-flex justify-content-between tabs-header-box">
            {/* variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile */}
            <Tabs
              value={queryFilterState.tabIndex}
              onChange={handleTabChange}
              className="tabs">
              <Tab
                label={`All (${
                  ProductStatusCount.active +
                  ProductStatusCount.inActive +
                  ProductStatusCount.scheduled
                })`}
                className="tabs-head"
              />
              <Tab
                label={`Active (${ProductStatusCount.active})`}
                className="tabs-head"
              />
              <Tab
                label={`Draft (${ProductStatusCount.inActive})`}
                className="tabs-head"
              />
              <Tab
                label={`Archived (${ProductStatusCount.archived})`}
                className="tabs-head"
              />
            </Tabs>
            <div
              className="tabs-country c-pointer"
              aria-describedby={idFlag}
              variant="contained">
              <img
                src={indiaFlag}
                alt="indiaFlag"
                height={15}
              />
              <p className="mx-2 text-lightBlue">India</p>
            </div>
            {/* <Popover
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              id={idFlag}
              open={openFlag}
              anchorEl={anchorFlagEl}
              onClose={handleFlagClose}>
              <div className="px-1 py-2">
                <div className="d-flex align-items-center c-pointer hover-back px-3 py-1 my-1 rounded-3">
                  <img
                    src={allFlag}
                    alt="allFlag"
                    height={20}
                  />
                  <p className="ms-2 text-lightBlue">All</p>
                </div>
                <div className="d-flex align-items-center c-pointer hover-back px-3 py-1 my-1 rounded-3">
                  <img
                    src={ukFlag}
                    alt="usaFlag"
                    height={15}
                  />
                  <p className="ms-2 text-lightBlue">UK</p>
                </div>
                <div className="d-flex align-items-center c-pointer hover-back px-3 py-1 my-1 rounded-3">
                  <img
                    src={usaFlag}
                    alt="usaFlag"
                    height={15}
                  />
                  <p className="ms-2 text-lightBlue">USA</p>
                </div>
              </div>
            </Popover> */}
          </Box>
          <div className="d-flex align-items-center my-3 px-2 justify-content-between">
            <TableSearchSecondary
              value={searchValue}
              onSearchValueChange={(val) => setSearchValue(val)}
              onChange={(val) => {
                dispatchQueryFilter({ type: "SEARCH", name: val });
                setSelected([]);
              }}
            />
            <div className="d-flex">
              <div className="d-flex product-button__box ms-2">
                {queryFilterState.tabIndex === 0 && (
                  <>
                    <button
                      className="button-grey py-2 px-3 d-none d-md-block"
                      variant="contained"
                      onClick={handleStatusClick}>
                      <small className="text-lightBlue">Status</small>
                      <img
                        src={arrowDown}
                        alt="arrowDown"
                        className="ms-2"
                      />
                    </button>
                    <Popover
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      id={idStatus}
                      open={openStatus}
                      anchorEl={anchorStatusEl}
                      onClose={handleStatusClose}>
                      <div className="py-2">
                        <FormGroup
                          sx={{ color: "#C8D8FF" }}
                          onChange={handleStatusChange}>
                          <FormControlLabel
                            value="active"
                            label="Active"
                            // className="me-0"
                            checked={queryFilterState.status.includes("active")}
                            control={<Checkbox size="small" />}
                          />
                          <FormControlLabel
                            value="in-active"
                            label="Draft"
                            // className="me-0"
                            checked={queryFilterState.status.includes("in-active")}
                            control={<Checkbox size="small" />}
                          />
                          <FormControlLabel
                            value="scheduled"
                            label="Scheduled"
                            // className="me-0"
                            checked={queryFilterState.status.includes("scheduled")}
                            control={<Checkbox size="small" />}
                          />
                        </FormGroup>
                      </div>
                    </Popover>
                  </>
                )}
                <button
                  className="button-grey py-2 px-3 d-none d-md-block"
                  variant="contained"
                  onClick={handleCompleteClick}>
                  <small className="text-lightBlue">Completion</small>
                  <img
                    src={arrowDown}
                    alt="arrowDown"
                    className="ms-2"
                  />
                </button>
                <Popover
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  id={idComplete}
                  open={openComplete}
                  anchorEl={anchorCompleteEl}
                  onClose={handleCompleteClose}>
                  <div className="py-2">
                    <RadioGroup
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      sx={{ color: "#C8D8FF" }}
                      value={queryFilterState.isCompleted}
                      onChange={handleCompletionChange}>
                      <FormControlLabel
                        value="0"
                        control={<Radio size="small" />}
                        label="Incomplete"
                      />
                      <FormControlLabel
                        value="1"
                        control={<Radio size="small" />}
                        label="Complete"
                      />
                      <FormControlLabel
                        value=""
                        control={<Radio size="small" />}
                        label="Both"
                      />
                    </RadioGroup>
                  </div>
                </Popover>

                <button
                  className="button-grey py-2 px-3 d-none d-md-block"
                  aria-describedby={idVendor}
                  variant="contained"
                  onClick={handleVendorClick}>
                  <small className="text-lightBlue">Vendor</small>
                  <img
                    src={arrowDown}
                    alt="arrowDown"
                    className="ms-2"
                  />
                </button>
                <Popover
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  id={idVendor}
                  open={openVendor}
                  anchorEl={anchorVendorEl}
                  onClose={handleVendorClose}>
                  <div className="py-2">
                    <Autocomplete
                      id="free-solo-demo"
                      freeSolo
                      size="small"
                      value={queryFilterState.vendor}
                      isOptionEqualToValue={(option, value) => option.name === value}
                      options={allVendors}
                      onChange={handleVendorChange}
                      getOptionLabel={(option) => option.name ?? option}
                      renderOption={(props, option) => (
                        <li {...props}>
                          <small className="text-lightBlue my-1">{option.name}</small>
                        </li>
                      )}
                      sx={{
                        width: 200,
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Search"
                          inputRef={(input) => input?.focus()}
                        />
                      )}
                    />
                  </div>
                </Popover>
                <button
                  className="button-grey py-2 px-3 d-none d-md-block"
                  aria-describedby={idCategory}
                  variant="contained"
                  onClick={handleCategoryClick}>
                  <small className="text-lightBlue">Category</small>
                  <img
                    src={arrowDown}
                    alt="arrowDown"
                    className="ms-2"
                  />
                </button>
                <Popover
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  id={idCategory}
                  open={openCategory}
                  anchorEl={anchorCategoryEl}
                  onClose={handleCategoryClose}>
                  <div className="py-2">
                    <Autocomplete
                      id="free-solo-demo"
                      freeSolo
                      size="small"
                      sx={{ width: 200 }}
                      value={queryFilterState.category}
                      isOptionEqualToValue={(option, value) => option.name === value}
                      options={allCategories}
                      onChange={handleCategoryChange}
                      getOptionLabel={(option) => option.name ?? option}
                      renderOption={(props, option) => (
                        <li {...props}>
                          <small className="text-lightBlue my-1">{option.name}</small>
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Search"
                          inputRef={(input) => input?.focus()}
                        />
                      )}
                    />
                  </div>
                </Popover>

                <button
                  className="button-grey py-2 px-3 d-none d-md-block"
                  aria-describedby={idTaggedWith}
                  variant="contained"
                  onClick={handleTaggedWithClick}>
                  <small className="text-lightBlue">Tagged With</small>
                  <img
                    src={arrowDown}
                    alt="arrowDown"
                    className="ms-2"
                  />
                </button>

                <Popover
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  id={idTaggedWith}
                  open={openTaggedWith}
                  anchorEl={anchorTaggedWithEl}
                  onClose={handleTaggedWithClose}>
                  <div className="py-2">
                    <Autocomplete
                      id="free-solo-demo"
                      freeSolo
                      size="small"
                      value={queryFilterState.tagManager}
                      isOptionEqualToValue={(option, value) => option.name === value}
                      options={allTags}
                      onChange={handleTagChange}
                      getOptionLabel={(option) => option.name ?? option}
                      renderOption={(props, option) => (
                        <li {...props}>
                          <small className="text-lightBlue my-1">{option.name}</small>
                        </li>
                      )}
                      sx={{
                        width: 200,
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Search"
                          inputRef={(input) => input?.focus()}
                        />
                      )}
                    />
                  </div>
                </Popover>

                <button
                  className="button-grey py-2 px-3"
                  onClick={toggleDrawer("right", true)}>
                  <small className="text-lightBlue">More Filters</small>
                  <img
                    src={filter}
                    alt="filter"
                    className="ms-2"
                  />
                </button>
                <SwipeableDrawer
                  anchor="right"
                  open={state["right"]}
                  onClose={toggleDrawer("right", false)}
                  onOpen={toggleDrawer("right", true)}>
                  <div className="d-flex justify-content-between py-3 px-3 ms-2 me-1">
                    <h6 className="text-lightBlue">Filters</h6>
                    <img
                      src={cancel}
                      alt="cancel"
                      className="c-pointer filter-icon"
                      onClick={toggleDrawer("right", false)}
                    />
                  </div>

                  <div className="px-2">
                    <Accordion
                      expanded={expanded === "panel1"}
                      onChange={handleAccordianChange("panel1")}>
                      <AccordionSummary
                        aria-controls="panel1d-content"
                        id="panel1d-header">
                        <p className="text-lightBlue">Product Category</p>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Autocomplete
                          id="free-solo-demo"
                          freeSolo
                          size="small"
                          value={queryFilterState.category}
                          isOptionEqualToValue={(option, value) => option.name === value}
                          options={allCategories}
                          onChange={handleCategoryChange}
                          getOptionLabel={(option) => option.name ?? option}
                          renderOption={(props, option) => (
                            <li {...props}>
                              <small className="text-lightBlue my-1">{option.name}</small>
                            </li>
                          )}
                          sx={{
                            width: "100%",
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Search"
                            />
                          )}
                        />
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      expanded={expanded === "panel2"}
                      onChange={handleAccordianChange("panel2")}>
                      <AccordionSummary
                        aria-controls="panel2d-content"
                        id="panel2d-header">
                        <p className="text-lightBlue">Sub Category</p>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Autocomplete
                          id="free-solo-demo"
                          freeSolo
                          size="small"
                          value={queryFilterState.subCategory}
                          isOptionEqualToValue={(option, value) => option.name === value}
                          options={allSubCategories}
                          onChange={handleSubCategoryChange}
                          getOptionLabel={(option) => option.name ?? option}
                          renderOption={(props, option) => (
                            <li {...props}>
                              <small className="text-lightBlue my-1">{option.name}</small>
                            </li>
                          )}
                          sx={{
                            width: "100%",
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Search"
                            />
                          )}
                        />
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      expanded={expanded === "panel3"}
                      onChange={handleAccordianChange("panel3")}>
                      <AccordionSummary
                        aria-controls="panel3d-content"
                        id="panel3d-header">
                        <p className="text-lightBlue">Vendor</p>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Autocomplete
                          id="free-solo-demo"
                          freeSolo
                          size="small"
                          value={queryFilterState.vendor}
                          isOptionEqualToValue={(option, value) => option.name === value}
                          onChange={handleVendorChange}
                          options={allVendors}
                          getOptionLabel={(option) => option.name ?? option}
                          renderOption={(props, option) => (
                            <li {...props}>
                              <small className="text-lightBlue my-1">{option.name}</small>
                            </li>
                          )}
                          sx={{
                            width: "100%",
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Search"
                            />
                          )}
                        />
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      expanded={expanded === "panel4"}
                      onChange={handleAccordianChange("panel4")}>
                      <AccordionSummary
                        aria-controls="panel4d-content"
                        id="panel4d-header">
                        <p className="text-lightBlue">Collection</p>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Autocomplete
                          id="free-solo-demo"
                          freeSolo
                          size="small"
                          value={queryFilterState.collection}
                          isOptionEqualToValue={(option, value) => option.title === value}
                          onChange={handleCollectionChange}
                          options={allCollections}
                          getOptionLabel={(option) => option.title ?? option}
                          renderOption={(props, option) => (
                            <li {...props}>
                              <small className="text-lightBlue my-1">{option.title}</small>
                            </li>
                          )}
                          sx={{
                            width: "100%",
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Search"
                            />
                          )}
                        />
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      expanded={expanded === "panel5"}
                      onChange={handleAccordianChange("panel5")}>
                      <AccordionSummary
                        aria-controls="panel5d-content"
                        id="panel5d-header">
                        <p className="text-lightBlue">Tagged With</p>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Autocomplete
                          id="free-solo-demo"
                          freeSolo
                          size="small"
                          value={queryFilterState.tagManager}
                          isOptionEqualToValue={(option, value) => option.name === value}
                          options={allTags}
                          onChange={handleTagChange}
                          getOptionLabel={(option) => option.name ?? option}
                          renderOption={(props, option) => (
                            <li {...props}>
                              <small className="text-lightBlue my-1">{option.name}</small>
                            </li>
                          )}
                          sx={{
                            width: "100%",
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Search"
                            />
                          )}
                        />
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      expanded={expanded === "panel6"}
                      onChange={handleAccordianChange("panel6")}>
                      <AccordionSummary
                        aria-controls="panel6d-content"
                        id="panel6d-header">
                        <p className="text-lightBlue">Completion Status</p>
                      </AccordionSummary>
                      <AccordionDetails>
                        <RadioGroup
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          sx={{ color: "#C8D8FF" }}
                          value={queryFilterState.isCompleted}
                          onChange={handleCompletionChange}>
                          <FormControlLabel
                            value="0"
                            control={<Radio size="small" />}
                            label="Incomplete"
                          />
                          <FormControlLabel
                            value="1"
                            control={<Radio size="small" />}
                            label="Complete"
                          />
                          <FormControlLabel
                            value=""
                            control={<Radio size="small" />}
                            label="Both"
                          />
                        </RadioGroup>
                      </AccordionDetails>
                    </Accordion>
                    {queryFilterState.tabIndex === 0 && (
                      <Accordion
                        expanded={expanded === "panel7"}
                        onChange={handleAccordianChange("panel7")}>
                        <AccordionSummary
                          aria-controls="panel6d-content"
                          id="panel6d-header">
                          <p className="text-lightBlue">Product Status</p>
                        </AccordionSummary>
                        <AccordionDetails>
                          <FormGroup
                            sx={{ color: "#C8D8FF" }}
                            onChange={handleStatusChange}>
                            <FormControlLabel
                              value="active"
                              label="Active"
                              // className="me-0"
                              checked={queryFilterState.status.includes("active")}
                              control={<Checkbox size="small" />}
                            />
                            <FormControlLabel
                              value="in-active"
                              label="Draft"
                              // className="me-0"
                              checked={queryFilterState.status.includes("in-active")}
                              control={<Checkbox size="small" />}
                            />
                            <FormControlLabel
                              value="scheduled"
                              label="Scheduled"
                              // className="me-0"
                              checked={queryFilterState.status.includes("scheduled")}
                              control={<Checkbox size="small" />}
                            />
                          </FormGroup>
                        </AccordionDetails>
                      </Accordion>
                    )}
                    <Accordion
                      expanded={expanded === "panel8"}
                      onChange={handleAccordianChange("panel8")}>
                      <AccordionSummary
                        aria-controls="panel7d-content"
                        id="panel7d-header">
                        <p className="text-lightBlue">Price Range</p>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className="row text-lightBlue text-center">
                          <div className="col-2">
                            <span>{queryFilterState.minPrice}</span>
                          </div>
                          <div className="col-8">
                            <Slider
                              step={minDistance}
                              value={[queryFilterState.minPrice, queryFilterState.maxPrice]}
                              min={0}
                              max={
                                Math.ceil(ProductStatusCount.maxPrice / minDistance) *
                                minDistance
                              }
                              // onChange={handleChange1}
                              onChange={handlePriceRangeChange}
                              valueLabelDisplay="auto"
                              getAriaValueText={(v) => v}
                              disableSwap
                            />
                          </div>
                          <div className="col-2">
                            <span>{queryFilterState.maxPrice}</span>
                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </div>

                  <div className="d-flex flex-column py-3 px-4 filter-buttons">
                    <hr className="hr-grey-6 my-3 w-100" />
                    <div className="d-flex justify-content-between">
                      <button
                        onClick={clearAllFilter}
                        className="button-lightBlue-outline py-2 px-3">
                        <p>Clear all Filters</p>
                      </button>
                      <button
                        onClick={toggleDrawer("right", false)}
                        className="button-gradient py-2 px-5 w-auto ">
                        <p>Done</p>
                      </button>
                    </div>
                  </div>
                </SwipeableDrawer>
              </div>
              <button
                className="button-grey py-2 px-3 ms-2"
                aria-describedby={idSort}
                variant="contained"
                onClick={handleSortClick}>
                <small className="text-lightBlue me-2">Sort</small>
                <img
                  src={sort}
                  alt="sort"
                  className=""
                />
              </button>
              <Popover
                id={idSort}
                open={openSort}
                anchorEl={anchorSortEl}
                onClose={handleSortClose}
                className="columns"
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}>
                <FormControl className="px-2 py-1">
                  <RadioGroup onChange={handleSortRadioChange}>
                    <FormControlLabel
                      label="Product Name"
                      value="alphabetical"
                      control={
                        <Radio
                          size="small"
                          checked={!!queryFilterState.alphabetical}
                        />
                      }
                    />
                    <FormControlLabel
                      label="Created Date"
                      value="createdAt"
                      control={
                        <Radio
                          size="small"
                          checked={!!queryFilterState.createdAt}
                        />
                      }
                    />
                    <FormControlLabel
                      label="Modified Date"
                      value="updatedAt"
                      control={
                        <Radio
                          size="small"
                          checked={!!queryFilterState.updatedAt}
                        />
                      }
                    />
                  </RadioGroup>
                </FormControl>
                <hr className="hr-grey-6 my-0" />
                <FormControl className="px-2 py-1">
                  <RadioGroup onChange={handleSortOrderRadioChange}>
                    <FormControlLabel
                      label={getSortLabels(sortFilter)[0]}
                      value="-1"
                      control={
                        <Radio
                          size="small"
                          checked={queryFilterState[sortFilter] === "-1"}
                        />
                      }
                    />
                    <FormControlLabel
                      label={getSortLabels(sortFilter)[1]}
                      value="1"
                      control={
                        <Radio
                          size="small"
                          checked={queryFilterState[sortFilter] === "1"}
                        />
                      }
                    />
                  </RadioGroup>
                </FormControl>
              </Popover>
            </div>
          </div>
          {chipData.length > 0 && (
            <div
              style={{ flexWrap: "wrap", alignItems: "center" }}
              className="d-flex justify-content-between mb-3 px-2">
              <div
                style={{ flexWrap: "wrap" }}
                className="d-flex">
                {chipData.map((item, ix) => (
                  <Chip
                    key={ix}
                    label={item}
                    onDelete={() => handleDelete(item)}
                    size="small"
                    className="mt-3 me-2 px-1"
                  />
                ))}
                <div className="d-flex">
                  <small
                    onClick={clearAllFilter}
                    className="text-blue-2 me-3 mt-3 c-pointer">
                    Clear all
                  </small>
                  {/* <small
                  className="text-blue-2 mt-3 c-pointer"
                  aria-describedby={idSaveFilter}
                  variant="contained"
                  onClick={handleSaveFilterClick}>
                  Save this filter
                </small>

                <Popover
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  id={idSaveFilter}
                  open={openSaveFilter}
                  anchorEl={anchorSaveFilterEl}
                  onClose={handleSaveFilterClose}>
                  <div className="px-1 py-3">
                    <div className="d-flex mb-1">
                      <small className="text-lightBlue me-2">Filter Name</small>
                      <Tooltip
                        title="Lorem ipsum"
                        placement="top">
                        <img
                          src={info}
                          alt="info"
                          className="c-pointer ms-2"
                          width={13.5}
                        />
                      </Tooltip>
                    </div>
                    <FormControl className="px-0">
                      <OutlinedInput
                        placeholder="Enter Category Name"
                        size="small"
                      />
                    </FormControl>
                    {/ <div className="d-flex"> /}
                    <button className="ms-auto button-gradient py-1 px-4 mt-3">
                      <p>Save</p>
                    </button>
                    {/ </div> /}
                  </div>
                </Popover> */}
                </div>
              </div>
            </div>
          )}
          <TabPanel
            value={queryFilterState.tabIndex}
            index={0}>
            <AllProductsTable
              isLoading={productsIsLoading}
              list={allProducts}
              totalCount={totalProductCount}
              changeRowsPerPage={handleChangeRowsPerPage}
              rowsPerPage={queryFilterState.pageSize}
              changePage={handleChangePage}
              page={queryFilterState.pageNo}
              selected={selected}
              setSelected={setSelected}
            />
          </TabPanel>
          <TabPanel
            value={queryFilterState.tabIndex}
            index={1}>
            <AllProductsTable
              isLoading={productsIsLoading}
              list={allProducts}
              totalCount={totalProductCount}
              changeRowsPerPage={handleChangeRowsPerPage}
              rowsPerPage={queryFilterState.pageSize}
              changePage={handleChangePage}
              page={queryFilterState.pageNo}
              selected={selected}
              setSelected={setSelected}
            />
          </TabPanel>
          <TabPanel
            value={queryFilterState.tabIndex}
            index={2}>
            <AllProductsTable
              isLoading={productsIsLoading}
              list={allProducts}
              totalCount={totalProductCount}
              changeRowsPerPage={handleChangeRowsPerPage}
              rowsPerPage={queryFilterState.pageSize}
              changePage={handleChangePage}
              page={queryFilterState.pageNo}
              selected={selected}
              setSelected={setSelected}
            />
          </TabPanel>
          <TabPanel
            value={queryFilterState.tabIndex}
            index={3}>
            <AllProductsTable
              isLoading={productsIsLoading}
              list={allProducts}
              totalCount={totalProductCount}
              changeRowsPerPage={handleChangeRowsPerPage}
              rowsPerPage={queryFilterState.pageSize}
              changePage={handleChangePage}
              page={queryFilterState.pageNo}
              isArchivedTab
              selected={selected}
              setSelected={setSelected}
            />
          </TabPanel>
        </Paper>
      </div>
      <ImportProductDialog
        show={openImport}
        onClose={setOpenImport}
      />
    </div>
  );
}

function getSortLabels(sortFilter = "") {
  switch (sortFilter) {
    case "createdAt":
    case "updatedAt":
      return ["Newest to Oldest", "Oldest to Newest"];
    case "alphabetical":
      return ["Alphabetical (Z-A)", "Alphabetical (A-Z)"];

    default:
      return ["", ""];
  }
}

/* <RadioGroup
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          // value={recommendedProductRadio}
                          // onChange={handleRecommendedProductRadio}
                        >
                          <FormControlLabel
                            value="active"
                            control={
                              <Checkbox
                                size="small"
                                sx={{ color: "#C8D8FF" }}
                              />
                            }
                            label="Active"
                            onChange={handleStatusChange}
                            checked={
                              queryFilterState.firstStatus === "" &&
                              queryFilterState.status.includes("active")
                            }
                          />
                          <FormControlLabel
                            value="in-active"
                            control={
                              <Checkbox
                                size="small"
                                sx={{ color: "#C8D8FF" }}
                              />
                            }
                            label="Draft"
                            onChange={handleStatusChange}
                            checked={
                              queryFilterState.firstStatus === "" &&
                              queryFilterState.status.includes("in-active")
                            }
                          />
                          <FormControlLabel
                            value="scheduled"
                            control={
                              <Checkbox
                                size="small"
                                sx={{ color: "#C8D8FF" }}
                              />
                            }
                            label="Scheduled"
                            onChange={handleStatusChange}
                            checked={
                              queryFilterState.firstStatus === "" &&
                              queryFilterState.status.includes("scheduled")
                            }
                          />
                        </RadioGroup> */
/* <Accordion
                      expanded={expanded === "panel8"}
                      onChange={handleAccordianChange("panel8")}>
                      <AccordionSummary
                        aria-controls="panel7d-content"
                        id="panel7d-header">
                        <p className="text-lightBlue">Inventory</p>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className="row text-lightBlue text-center">
                          <div className="col-2">
                            <span>{queryFilterState.minPrice}</span>
                          </div>
                          <div className="col-8">
                            <Slider
                              step={minDistance}
                              value={value1}
                              max={minDistance ** 2}
                              onChange={handleChange1}
                              valueLabelDisplay="auto"
                              getAriaValueText={(v) => v}
                              disableSwap
                            />
                          </div>
                          <div className="col-2">
                            <span>{queryFilterState.maxPrice}</span>
                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      expanded={expanded === "panel9"}
                      onChange={handleAccordianChange("panel9")}>
                      <AccordionSummary
                        aria-controls="panel8d-content"
                        id="panel8d-header">
                        <p className="text-lightBlue">Labels</p>
                      </AccordionSummary>
                      <AccordionDetails>
                        <FormGroup className="tags-checkbox">
                          <FormControlLabel
                            control={
                              <Checkbox
                                size="small"
                                style={{
                                  color: "#5C6D8E",
                                  marginRight: 0,
                                }}
                              />
                            }
                            label="Content 1"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                size="small"
                                style={{
                                  color: "#5C6D8E",
                                  marginRight: 0,
                                }}
                              />
                            }
                            label="Content 2"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                size="small"
                                style={{
                                  color: "#5C6D8E",
                                  marginRight: 0,
                                }}
                              />
                            }
                            label="Content 3"
                          />
                        </FormGroup>
                      </AccordionDetails>
                    </Accordion> */
/* <button
                className="button-grey py-2 px-3 ms-2"
                aria-describedby={idColumns}
                variant="contained"
                onClick={handleColumnsClick}
              >
                <small className="text-lightBlue">Columns</small>
                <img src={columns} alt="columns" className="ms-2" />
              </button>
              <Popover
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                id={idColumns}
                open={openColumns}
                anchorEl={anchorColumnsEl}
                onClose={handleColumnsClose}
                className="columns"
              >
                <FormGroup className="px-2 py-1">
                  <FormControlLabel
                    control={
                      <Checkbox
                        defaultChecked
                        size="small"
                        style={{
                          color: "#5C6D8E",
                        }}
                      />
                    }
                    label="Catgory"
                    className="me-0"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        style={{
                          color: "#5C6D8E",
                        }}
                      />
                    }
                    label="Sub Catgory"
                    className="me-0"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        style={{
                          color: "#5C6D8E",
                        }}
                      />
                    }
                    label="Collection"
                    className="me-0"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        style={{
                          color: "#5C6D8E",
                        }}
                      />
                    }
                    label="Vendor"
                    className="me-0"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        style={{
                          color: "#5C6D8E",
                        }}
                      />
                    }
                    label="Price"
                    className="me-0"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        style={{
                          color: "#5C6D8E",
                        }}
                      />
                    }
                    label="Activity"
                    className="me-0"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        style={{
                          color: "#5C6D8E",
                        }}
                      />
                    }
                    label="Status"
                    className="me-0"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        style={{
                          color: "#5C6D8E",
                        }}
                      />
                    }
                    label="Action"
                    className="me-0"
                  />
                </FormGroup>
              </Popover> */
/* <Dialog
          open={openExport}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleExportClose}
          aria-describedby="alert-dialog-slide-description"
          maxWidth="sm"
          fullWidth={true}>
          <DialogTitle>
            <div className="d-flex justify-content-between align-items-center">
              <h5 className="text-lightBlue fw-500">Export Products</h5>
              <img
                src={cancel}
                alt="cancel"
                width={30}
                onClick={handleExportClose}
                className="c-pointer"
              />
            </div>
          </DialogTitle>
          <hr className="hr-grey-6 my-0" />
          <DialogContent className="py-3 px-4">
            <p className="text-lightBlue mb-2">Export</p>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={valueExport}
                onChange={handleExportChange}>
                <FormControlLabel
                  value="currentPage"
                  control={<Radio size="small" />}
                  label="Current Page"
                  sx={{
                    "& .MuiTypography-root": {
                      fontSize: 13,
                      color: "#c8d8ff",
                    },
                  }}
                />
                <FormControlLabel
                  value="allProducts"
                  control={<Radio size="small" />}
                  label="All Products"
                  sx={{
                    "& .MuiTypography-root": {
                      fontSize: 13,
                      color: "#c8d8ff",
                    },
                  }}
                />
              </RadioGroup>
            </FormControl>
            <p className="text-lightBlue mb-2 mt-3">Export as</p>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={valueExport}
                onChange={handleExportChange}>
                <FormControlLabel
                  value="csvForExcel"
                  control={<Radio size="small" />}
                  label="CSV for Excel, Number or other Spreadsheet program"
                  sx={{
                    "& .MuiTypography-root": {
                      fontSize: 13,
                      color: "#c8d8ff",
                    },
                  }}
                />
                <FormControlLabel
                  value="plainCsvFile"
                  control={<Radio size="small" />}
                  label="Plain CSV File"
                  sx={{
                    "& .MuiTypography-root": {
                      fontSize: 13,
                      color: "#c8d8ff",
                    },
                  }}
                />
              </RadioGroup>
            </FormControl>
            <p className="text-lightBlue mb-2 mt-3">HTML format</p>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={valueExport}
                onChange={handleExportChange}>
                <FormControlLabel
                  value="normalText"
                  control={<Radio size="small" />}
                  label="Normal Text"
                  sx={{
                    "& .MuiTypography-root": {
                      fontSize: 13,
                      color: "#c8d8ff",
                    },
                  }}
                />
                <FormControlLabel
                  value="htmlCodedText"
                  control={<Radio size="small" />}
                  label="HTML Coded Text"
                  sx={{
                    "& .MuiTypography-root": {
                      fontSize: 13,
                      color: "#c8d8ff",
                    },
                  }}
                />
              </RadioGroup>
            </FormControl>
          </DialogContent>
          <hr className="hr-grey-6 my-0" />
          <DialogActions className="d-flex justify-content-between px-4 py-3">
            <button
              className="button-grey py-2 px-5"
              onClick={handleExportClose}>
              <p className="text-lightBlue">Cancel</p>
            </button>
            <button
              className="button-gradient py-2 px-5"
              onClick={handleExportClose}>
              <p className="">Continue</p>
            </button>
          </DialogActions>
        </Dialog> */
