import apiSlice from "../../../app/api/apiSlice";

import { omitNullishKeys } from "../../../utils/helper";

export const bannerApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllBanner: builder.query({
      query: (queries) => {
        return {
          url: "/website/layout",
          params: omitNullishKeys(queries),
        };
      },
      transformResponse: (res) => res.data,
      providesTags: ["Banner"],
    }),

    updateBanner: builder.mutation({
      query: ({ details, structureId, attributeId }) => ({
        url: `/website/layout/structure/${attributeId}/${structureId}`,
        method: "PUT",
        body: details,
      }),
      transformResponse: (res) => res.data,
      invalidatesTags: ["Banner", "Logs"],
    }),
    updateWebsiteAttr: builder.mutation({
      query: ({ details, attributeId, layoutId }) => ({
        url: `/website/layout/editAttribute/${layoutId}/${attributeId}`,
        method: "PUT",
        body: details,
      }),
      transformResponse: (res) => res.data,
      invalidatesTags: ["WebsiteAttr", "Banner", "Logs"],
    }),
  }),
});

export const { useGetAllBannerQuery, useUpdateBannerMutation, useUpdateWebsiteAttrMutation } =
  bannerApiSlice;
