import { Tooltip } from "@mui/material";

const ViewTutorial = ({ image, tooltip, onClick }) => {
  return (
    <Tooltip title={tooltip} placement="bottom">
        <div className="button-transparent rounded-4 p-1" onClick={onClick}>
            <img src={image} className="p-2 c-pointer" />
        </div>
    </Tooltip>
  );
};

export default ViewTutorial;
