import { useState, forwardRef, useEffect, useReducer } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import _ from "lodash";
import { useDispatch } from "react-redux";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputAdornment,
  OutlinedInput,
  Checkbox,
  FormControlLabel,
  Chip,
  Radio,
  RadioGroup,
  Slide,
  Tooltip,
} from "@mui/material";
import { useCreateCustomerAddressMutation } from "../../features/customers/customerAddress/customerAddressApiSlice";
import { useGetAllCountryQuery } from "../../features/master/country/countryApiSlice";
import { useGetAllStateQuery } from "../../features/master/state/stateApiSlice";
import { useGetAllCityQuery } from "../../features/master/city/cityApiSlice";
import { showSuccess, showError } from "../../features/snackbar/snackbarAction";

import AppCountrySelect from "../../components/AppCountrySelect/AppCountrySelect";
import AppStateSelect from "../../components/AppStateSelect/AppStateSelect";
import AppMobileCodeSelect from "../../components/AppMobileCodeSelect/AppMobileCodeSelect";
import AppCitySelect from "../../components/AddCitySelect/AddCitySelect";
import { DeleteModalSecondary } from "../../components/DeleteModal/DeleteModal";

import archivedGrey from "../../assets/icons/archivedGrey.svg";
import editGrey from "../../assets/icons/editGrey.svg";
import cancel from "../../assets/icons/cancel.svg";
import ZipCodeComponent from "../../components/ZipCodeCityComponent";

const Transition = forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="up"
      ref={ref}
      {...props}
    />
  );
});

const customerAddressValidation = Yup.object({
  name: Yup.string().trim().min(3, "Name must be at least 3 characters").required("Required"),
  firstName: Yup.string()
    .trim()
    .min(3, "First name must be at least 3 characters")
    .required("Required"),
  lastName: Yup.string()
    .trim()
    .min(3, "Last name must be at least 3 characters")
    .required("Required"),
  countryCode: Yup.string().required("Required"),
  phone: Yup.string()
    .matches(/^\d{10}$/, "Mobile Number must be 10 digits numeric value only.")
    .required("Required"),
  country: Yup.string().required("Required"),
  line1: Yup.string().required("Required"),
  city: Yup.string().required("Required"),
  pinCode: Yup.string()
    .matches(/^\d{6}$/, "Pincode must be 6 digits numeric value only.")
    .required("Required"),
  state: Yup.string().required("Required"),
});

const EditOrderAddress = ({
  show = false,
  address = {},
  orderIsLoading = false,
  onUpdate = () => {},
  onClose = () => {},
}) => {
  const dispatch = useDispatch();

  console.log("address", address);

  const [createCustomerAddress, { isLoading: createAddressIsLoading }] =
    useCreateCustomerAddressMutation();

  const formik = useFormik({
    initialValues: {
      ...address,
      city: address?.city?._id,
      country: address?.country?._id,
      state: address?.state?._id,
    },
    enableReinitialize: true,
    validationSchema: customerAddressValidation,
    onSubmit: (values) => {
      // console.log("values", values);
      createCustomerAddress(values)
        .unwrap()
        .then((data) => onUpdate(values?.type, data?.data?._id))
        .catch((e) => {
          console.log(e);
          dispatch(
            showError({
              message: e?.data?.message ?? e?.message ?? "Something went wrong!",
            })
          );
        });
    },
  });

  const handleCode = (event, value) => {
    formik.setFieldValue("countryCode", value?._id);
  };

  const selectCountryName = (event, value) => {
    formik.setFieldValue("country", value?._id);
  };

  const getStateName = (event, value) => {
    formik.setFieldValue("state", value?._id);
  };

  const SelectCityName = (event, value) => {
    formik.setFieldValue("city", value?._id);
  };

  return (
    <Dialog
      fullWidth
      open={show}
      keepMounted
      maxWidth="sm"
      scroll="paper"
      onClose={onClose}
      TransitionComponent={Transition}>
      <DialogTitle>
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex flex-column ">
            <h5 className="text-lightBlue text-capitalize fw-500">
              Edit {address?.type} Address
            </h5>
            <small className="text-grey-6 mt-1 d-block">
              ⓘ Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </small>
          </div>
          <img
            src={cancel}
            alt="cancel"
            width={30}
            onClick={onClose}
            className="c-pointer"
          />
        </div>
      </DialogTitle>
      <hr className="hr-grey-6 my-0" />
      <DialogContent className="pb-4 px-4">
        <div className="row rounded-8">
          {/* <div className="col-md-12">
            <p className="text-lightBlue mb-1">
              Name <span style={{ color: "#F67476" }}>*</span>
            </p>
            <FormControl className="w-100 px-0">
              <OutlinedInput
                placeholder="Office Address, Home Address"
                size="small"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
              />
            </FormControl>
            {!!formik.touched.name && formik.errors.name && (
              <FormHelperText error>{formik.errors.name}</FormHelperText>
            )}
            <small className="text-grey-6">
              Name this address Ex. Office Address, Home Address
            </small>
          </div> */}
          <div className="col-md-6 mt-3">
            <p className="text-lightBlue mb-1">
              First Name <span style={{ color: "#F67476" }}>*</span>
            </p>
            <FormControl className="w-100 px-0">
              <OutlinedInput
                placeholder="Enter First Name"
                size="small"
                name="firstName"
                value={formik.values.firstName}
                onChange={formik.handleChange}
              />
            </FormControl>
            {!!formik.touched.firstName && formik.errors.firstName && (
              <FormHelperText error>{formik.errors.firstName}</FormHelperText>
            )}
          </div>
          <div className="col-md-6 mt-3">
            <p className="text-lightBlue mb-1">
              Last Name <span style={{ color: "#F67476" }}>*</span>
            </p>
            <FormControl className="w-100 px-0">
              <OutlinedInput
                placeholder="Enter Last Name"
                size="small"
                name="lastName"
                value={formik.values.lastName}
                onChange={formik.handleChange}
              />
            </FormControl>
            {!!formik.touched.lastName && formik.errors.lastName && (
              <FormHelperText error>{formik.errors.lastName}</FormHelperText>
            )}
          </div>
          <div className="col-md-12 mt-3">
            <p className="text-lightBlue mb-1">Company Name</p>
            <FormControl className="w-100 px-0">
              <OutlinedInput
                placeholder="Enter company name"
                size="small"
                name="companyName"
                value={formik.values.companyName}
                onChange={formik.handleChange}
              />
            </FormControl>
          </div>
          {address?.type === "billing" && (
            <div className="col-md-12 mt-3">
              <p className="text-lightBlue mb-1">GSTIN</p>
              <FormControl className="w-100 px-0">
                <OutlinedInput
                  placeholder="Enter GSTIN"
                  size="small"
                  name="gstIn"
                  value={formik.values.gstIn}
                  onChange={formik.handleChange}
                />
              </FormControl>
            </div>
          )}
          <div className="col-md-12 mt-3">
            <p className="text-lightBlue mb-1">
              Mobile Number <span style={{ color: "#F67476" }}>*</span>
            </p>
            <FormControl className="w-100 px-0">
              <OutlinedInput
                placeholder="Enter Mobile Number"
                size="small"
                sx={{ paddingLeft: 0 }}
                name="phone"
                value={formik.values.phone}
                onChange={formik.handleChange}
                startAdornment={
                  <InputAdornment position="start">
                    <AppMobileCodeSelect
                      formik={formik}
                      countryCode={formik.values.countryCode}
                      handleCode={handleCode}
                    />
                    {/* <OutlinedInput
                      placeholder="Enter Code"
                      size="small"
                      sx={{ width: 75 }}
                      name="countryCode"
                      value={formik.values.countryCode}
                      onChange={formik.handleChange}
                    /> */}
                  </InputAdornment>
                }
              />
            </FormControl>
            {(!!formik.touched.countryCode || !!formik.touched.phone) &&
              (formik.errors.countryCode || formik.errors.phone) && (
                <FormHelperText error>
                  {formik.errors.countryCode || formik.errors.phone}
                </FormHelperText>
              )}
          </div>
          <div className="col-md-12 mt-3">
            <p className="text-lightBlue mb-1">Email</p>
            <FormControl className="w-100 px-0">
              <OutlinedInput
                placeholder="Enter email"
                size="small"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
              />
            </FormControl>
            {!!formik.touched.email && formik.errors.email && (
              <FormHelperText error>{formik.errors.email}</FormHelperText>
            )}
          </div>
          {/* <div className="col-md-6 mt-3">
            <p className="text-lightBlue mb-1">
              Address Line 1 <span style={{ color: "#F67476" }}>*</span>
            </p>
            <FormControl className="w-100 px-0">
              <OutlinedInput
                placeholder="Enter Address Line 1"
                size="small"
                name="line1"
                value={formik.values.line1}
                onChange={formik.handleChange}
              />
            </FormControl>
            {!!formik.touched.line1 && formik.errors.line1 && (
              <FormHelperText error>{formik.errors.line1}</FormHelperText>
            )}
          </div>
          <div className="col-md-6 mt-3">
            <p className="text-lightBlue mb-1">Address Line 2</p>
            <FormControl className="w-100 px-0">
              <OutlinedInput
                placeholder="Enter Address Line 2"
                size="small"
                name="line2"
                value={formik.values.line2}
                onChange={formik.handleChange}
              />
            </FormControl>
          </div> */}
          {show && (
            <ZipCodeComponent
              cityVal={formik.values.city}
              onchangeCity={SelectCityName}
              errorCity={formik.errors.city}
              touchedCity={formik.touched.city}
              stateVal={formik.values.state}
              onchangeState={getStateName}
              errorState={formik.errors.state}
              touchedState={formik.touched.state}
              countryVal={formik.values.country}
              onchangeCountry={selectCountryName}
              errorCountry={formik.errors.country}
              touchedCountry={formik.touched.country}
              zipVal={formik.values.pinCode}
              onchangeZip={(e) => formik.setFieldValue("pinCode", e?.target?.value)}
              errorZip={formik.errors.pinCode}
              touchedZip={formik.touched.pinCode}
              onchangeLine1={(line) => formik.setFieldValue("line1", line)}
              onchangeLine2={(line) => formik.setFieldValue("line2", line?.target?.value)}
              touchedLine1={formik.touched.line1}
              errorLine1={formik.errors.line1}
              nameLine2="line2"
              namePincode="pinCode"
              valueLine2={formik.values.line2}
              onchangePincode={(code) => formik.setFieldValue("pinCode", code)}
              line1Val={formik.values.line1}
            />
          )}
        </div>
      </DialogContent>
      <hr className="hr-grey-6 my-0" />
      <DialogActions className="d-flex justify-content-between px-4 py-3">
        <button
          className="button-grey py-2 px-5"
          disabled={createAddressIsLoading || orderIsLoading}
          onClick={onClose}>
          <p className="text-lightBlue">Cancel</p>
        </button>
        <button
          className="button-gradient py-2 px-5"
          type="button"
          disabled={createAddressIsLoading || orderIsLoading}
          onClick={formik.handleSubmit}>
          <p>Update</p>
        </button>
      </DialogActions>
    </Dialog>
  );
};

export default EditOrderAddress;
