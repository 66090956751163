import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { logoutHandler } from "../../features/auth/authAction";

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_POSTHOG_ANALYTICS_API_URL,
  // baseUrl: "",
  prepareHeaders: (headers, { getState }) => {
    const token = process.env.REACT_APP_POSTHOG_ANALYTICS_API_KEY;
    token && headers.set("authorization", `Bearer ${token}`);
    return headers;
  },
});

const posthogAnalyticsApiSlice = createApi({
  reducerPath: "posthogAnalyticsapi",
  baseQuery: baseQuery,
  tagTypes: [],
  endpoints: (builder) => ({}),
});

export default posthogAnalyticsApiSlice;