import React, { PureComponent } from 'react';
import Heading from "../../components/Headers/DashboardHeader";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
} from 'recharts';

const data = [
  {
    name: '28/10/2024',
    new: 4000,
    returning: 2400,
    resurrecting: 1000,
    dormant: -500,
    amt: 2400,
  },
  {
    name: '04/11/2024',
    new: -3000,
    returning: 1398,
    resurrecting: 800,
    dormant: -1000,
    amt: 2210,
  },
  {
    name: '11/11/2024',
    new: -2000,
    returning: -9800,
    resurrecting: 500,
    dormant: -1200,
    amt: 2290,
  },
  {
    name: '18/11/2024',
    new: 2780,
    returning: 3908,
    resurrecting: 1200,
    dormant: -300,
    amt: 2000,
  },
  {
    name: '25/11/2024',
    new: -1890,
    returning: 4800,
    resurrecting: 600,
    dormant: -700,
    amt: 2181,
  },
  {
    name: '02/12/2024',
    new: 2390,
    returning: -3800,
    resurrecting: 900,
    dormant: -400,
    amt: 2500,
  },
];

export default class RetentionChart extends PureComponent {
  render() {
    return (
      <div className="bg-button-3 border-grey-5 rounded-8 px-4 pt-4 pb-3 mt-4" style={{ height: '500px', width: '760px' }}>
        <p
          className="text-lightBlue fw-500 me-2 my-1"
          style={{ fontSize: '12px', textTransform: 'uppercase' }}
        >
          Lifecycle • Last 30 days
        </p>

        <Heading
          heading={"Growth Accounting"}
          tooltipContent={"How many of your users are new, returning, resurrecting, or dormant each week."}
        />
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={data}
            stackOffset="sign"
            margin={{
              top: 30,
              right: 20,
              left: 20,
              bottom: 60,
            }}
            className="cursor-pointer"
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="name"
              tick={{ fontSize: 12, fill: "#ffffff" }}
              tickLine={false} 
              axisLine={false}
            />
            <YAxis
              tick={{ fontSize: 12, fill: "#ffffff" }}
              tickLine={false} 
              axisLine={false} 
            />
            <Tooltip
            cursor={false}
              contentStyle={{
                backgroundColor: '#18132E',
                border: 'grey-500',
                fontSize: '12px',
                borderRadius: "4px",
              }}
            />
            <Legend />
            <ReferenceLine y={0} stroke="#000" />
            {/* <Bar dataKey="returning" fill="#7949ff" stackId="stack" />
            <Bar dataKey="new" fill="#6bfb8a" stackId="stack" />
            <Bar dataKey="resurrecting" fill="#61e5ff" stackId="stack" />
            <Bar dataKey="dormant" fill="#ff4851" stackId="stack" /> */}
                        <Bar dataKey="returning" fill="#7949ff" stackId="stack" />
            <Bar dataKey="new" fill="#9faaff" stackId="stack" />
            <Bar dataKey="resurrecting" fill="#c67bea" stackId="stack" />
            <Bar dataKey="dormant" fill="#4157da" stackId="stack" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  }
}
