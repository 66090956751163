import { useNavigate } from "react-router-dom";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import logo from "../../../assets/unthread/logo.svg";

export default function OnBoard({ children, image, onClick, buttonName }) {
    const navigate = useNavigate();

    const handlePrevious = () => {
        navigate(-1);

    }

    return (
        <div className="bg-black-22 px-rem-6 h-vh-100">
            <div className="d-flex justify-content-center py-4" style={{ height: "10vh" }}>
                <img src={logo} width={200} height={30} />
            </div>
            <div className="col-12 d-flex login-box border-grey-2 rounded-8 p-5" style={{ height: "80vh" }}>
                <div className="d-flex align-items-end py-3" style={{ width: "45%" }}>
                    <div className='ps-4 pe-5'>
                        <div className='pb-5'>
                            {children}
                        </div>
                        <div className='d-flex justify-content-between pt-5'>
                            <button className="button-grey-outline p-2" onClick={handlePrevious}>
                                <p className="text-lightBlue">
                                    <KeyboardArrowLeftIcon />
                                </p>
                            </button>
                            <div className="button-gradient py-2 px-4"
                                onClick={onClick}
                            >
                                <p>{buttonName}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <img src={image} className="h-100" />
                </div>
            </div>
            <div className="d-flex justify-content-center pt-5" style={{ height: "10vh" }}>
                <small className="text-grey-6">
                    © Copyright 2023 &nbsp;
                    <small className="text-blue-gradient fw-500">Unthread Global</small>
                </small>
            </div>
        </div>
    );
}