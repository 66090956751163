import { useReducer, useState } from "react";
import { Link } from "react-router-dom";
import ViewLogsDrawer from "../../../components/ViewLogsDrawer/ViewLogsDrawer";
import { TableSearchSecondary } from "../../../components/TableSearch/TableSearch";
import ViewTutorial from "../../../components/ViewTutorial/ViewTutorial";
import sort from "../../../assets/icons/sort.svg";
import products from "../../../assets/icons/sidenav/products.svg";
import teamMember1 from "../../../assets/images/products/teamMember1.svg";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Paper,
  Popover,
  Radio,
  RadioGroup,
  Rating,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Tabs,
  Tooltip,
} from "@mui/material";
import {
  useBulkDeleteReviewMutation,
  useBulkEditReviewMutation,
  useDeleteReviewMutation,
  useEditReviewMutation,
  useGetReviewsQuery,
} from "../../../features/products/reviews/reviewsApiSlice";
import TableEditStatusButton from "../../../components/TableEditStatusButton/TableEditStatusButton";
import TableMassActionButton from "../../../components/TableMassActionButton/TableMassActionButton";
import { EnhancedTableHead } from "../../../components/TableDependencies/TableDependencies";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import InventoryIcon from "@mui/icons-material/Inventory";
import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment/moment";
import ArchiveModal, {
  ArchiveModalSecondary,
} from "../../../components/ArchiveModal/ArchiveModal";
import { DeleteModalSecondary } from "../../../components/DeleteModal/DeleteModal";
import ReviewDialog from "./ReviewDialog";
import { getReviewStatusColor, getReviewStatusFromTabIndex } from "./utils";
import { useDispatch } from "react-redux";
import { showError, showSuccess } from "../../../features/snackbar/snackbarAction";
import UnArchivedModal from "./UnarchiveDialog";
import { sanitize } from "dompurify";
import TableLoader from "../../../components/Loader/TableLoader";
import NoDataFound from "../../../components/NoDataFound/NoDataFound";

const headCells = [
  {
    id: "reviews",
    numeric: false,
    disablePadding: true,
    label: "Reviews",
  },
  {
    id: "customer",
    numeric: false,
    disablePadding: false,
    label: "Customer",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: true,
    label: "Action",
  },
];

const initialQueries = {
  search: "",
  tabIndex: 0,
  status: ["pending", "approved", "disapproved"],
  createdAt: "-1",
  pageNo: 1,
  pageSize: 10,
};

const queriesReducer = (state, action) => {
  switch (action.type) {
    case "SET_TAB_INDEX":
      return {
        ...state,
        pageNo: 1,
        status: action.status,
        tabIndex: action.tabIndex,
      };

    case "SET_SEARCH":
      return {
        ...state,
        pageNo: 1,
        search: action.search,
      };

    case "SET_PAGE_SIZE":
      return {
        ...state,
        pageNo: 1,
        pageSize: +action.pageSize,
      };

    case "SET_PAGE_NO":
      return {
        ...state,
        pageNo: action.pageNo + 1,
      };

    case "SET_CREATED_AT":
      return {
        ...state,
        createdAt: action.createdAt,
      };

    case "SET_DEFAULT":
      return initialQueries;

    default:
      return state;
  }
};

export default function ProductReviews({ customerId = "" }) {
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState("");
  const [sortAnchorEl, setSortAnchorEl] = useState(null);
  const openSortMenu = Boolean(sortAnchorEl);
  const handleSortClick = (event) => setSortAnchorEl(event.currentTarget);
  const handleSortClose = () => setSortAnchorEl(null);

  const [queries, dispatchQueries] = useReducer(
    queriesReducer,
    !customerId ? initialQueries : { ...initialQueries, customer: customerId }
  );
  const { data: reviewData, isLoading: reviewIsLoading } = useGetReviewsQuery(queries);
  const allReviews = reviewData?.data?.data ?? [];
  const reviewsCount = reviewData?.data?.totalCount ?? 0;

  const [deleteReview, { isLoading: deleteReviewIsLoading }] = useDeleteReviewMutation();
  const [editReview, { isLoading: editReviewIsLoading }] = useEditReviewMutation();

  const [bulkDeleteReview, { isLoading: bulkDeleteReviewIsLoading }] =
    useBulkDeleteReviewMutation();
  const [bulkEditReview, { isLoading: bulkEditReviewIsLoading }] = useBulkEditReviewMutation();

  const [selected, setSelected] = useState([]);
  const clearSelected = () => setSelected([]);
  const [viewingReview, setViewingReview] = useState(null);
  const [deletingReview, setDeletingReview] = useState(null);
  const [archivingReview, setArchivingReview] = useState(null);
  const [unarchivingReview, setUnarchivingReview] = useState(null);

  const onReviewsApprove = () => {
    if (selected.length > 0)
      bulkEditReview({ updates: selected.map((rv) => ({ id: rv._id, status: "approved" })) })
        .unwrap()
        .then(() => dispatch(showSuccess({ message: "Reviews approved successfully!" })))
        .catch((e) =>
          dispatch(showError({ message: e?.data?.message ?? "Something went wrong!" }))
        )
        .finally(() => {
          clearSelected();
          setViewingReview(null);
        });
    else
      editReview({ id: viewingReview?._id, details: { status: "approved" } })
        .unwrap()
        .then(() => dispatch(showSuccess({ message: "Review approved successfully!" })))
        .catch((e) =>
          dispatch(showError({ message: e?.data?.message ?? "Something went wrong!" }))
        )
        .finally(() => {
          clearSelected();
          setViewingReview(null);
        });
  };

  const onReviewsDisapproved = () => {
    if (selected.length > 0)
      bulkEditReview({ updates: selected.map((rv) => ({ id: rv._id, status: "disapproved" })) })
        .unwrap()
        .then(() => dispatch(showSuccess({ message: "Reviews disapproved successfully!" })))
        .catch((e) =>
          dispatch(showError({ message: e?.data?.message ?? "Something went wrong!" }))
        )
        .finally(() => {
          clearSelected();
          setViewingReview(null);
        });
    else
      editReview({ id: viewingReview?._id, details: { status: "disapproved" } })
        .unwrap()
        .then(() => dispatch(showSuccess({ message: "Review disapproved successfully!" })))
        .catch((e) =>
          dispatch(showError({ message: e?.data?.message ?? "Something went wrong!" }))
        )
        .finally(() => {
          clearSelected();
          setViewingReview(null);
        });
  };

  const onReviewsArchive = () => {
    if (selected.length > 0)
      bulkEditReview({ updates: selected.map((rv) => ({ id: rv._id, status: "archived" })) })
        .unwrap()
        .then(() => dispatch(showSuccess({ message: "Reviews archived successfully!" })))
        .catch((e) =>
          dispatch(showError({ message: e?.data?.message ?? "Something went wrong!" }))
        )
        .finally(() => {
          clearSelected();
          setArchivingReview(null);
        });
    else
      editReview({ id: archivingReview?._id, details: { status: "archived" } })
        .unwrap()
        .then(() => dispatch(showSuccess({ message: "Review archived successfully!" })))
        .catch((e) =>
          dispatch(showError({ message: e?.data?.message ?? "Something went wrong!" }))
        )
        .finally(() => {
          clearSelected();
          setArchivingReview(null);
        });
  };

  const onReviewsUnarchive = (status = "") => {
    if (selected.length > 0)
      bulkEditReview({ updates: selected.map((rv) => ({ id: rv._id, status })) })
        .unwrap()
        .then(() => dispatch(showSuccess({ message: "Reviews unarchived successfully!" })))
        .catch((e) =>
          dispatch(showError({ message: e?.data?.message ?? "Something went wrong!" }))
        )
        .finally(() => {
          clearSelected();
          setUnarchivingReview(null);
        });
    else
      editReview({ id: unarchivingReview?._id, details: { status } })
        .unwrap()
        .then(() => dispatch(showSuccess({ message: "Review unarchived successfully!" })))
        .catch((e) =>
          dispatch(showError({ message: e?.data?.message ?? "Something went wrong!" }))
        )
        .finally(() => {
          clearSelected();
          setUnarchivingReview(null);
        });
  };

  const onReviewsDelete = () => {
    if (selected.length > 0)
      bulkDeleteReview({ deletes: selected.map((rv) => rv._id) })
        .unwrap()
        .then(() => dispatch(showSuccess({ message: "Reviews deleted successfully!" })))
        .catch((e) =>
          dispatch(showError({ message: e?.data?.message ?? "Something went wrong!" }))
        )
        .finally(() => {
          clearSelected();
          setDeletingReview(null);
        });
    else
      deleteReview(deletingReview?._id)
        .unwrap()
        .then(() => dispatch(showSuccess({ message: "Review deleted successfully!" })))
        .catch((e) =>
          dispatch(showError({ message: e?.data?.message ?? "Something went wrong!" }))
        )
        .finally(() => {
          clearSelected();
          setDeletingReview(null);
        });
  };

  const onBulkActionSelected = (action) => {
    switch (action) {
      case "Approve":
        onReviewsApprove();
        break;
      case "Disapprove":
        onReviewsDisapproved();
        break;
      case "Archive":
        setArchivingReview({});
        break;
      case "Delete":
        setDeletingReview({});
        break;
      case "Un-Archive":
        setUnarchivingReview({});
        break;

      default:
        break;
    }
  };

  const onSortChange = (e) =>
    dispatchQueries({ type: "SET_CREATED_AT", createdAt: e.target.value });

  return (
    <div className="container-fluid px-0 page">
      {!customerId && (
        <div className="row justify-content-between align-items-center mb-4">
          <h4 className="page-heading w-auto ps-0">Product Reviews</h4>
          <div className="d-flex align-items-center w-auto pe-0">
            {/* <ViewTutorial /> */}
            <ViewLogsDrawer
              headingName={"Product Reviews"}
              icon={products}
              module="product"
            />
            <Link
              to="/products/reviews/create"
              className="button-gradient py-2 px-4 ms-3">
              <p>+ Create Reviews</p>
            </Link>
          </div>
        </div>
      )}

      <div className="row">
        <Paper
          sx={{ width: "100%", mb: 2, mt: 0, p: 0 }}
          className="border-grey-5 bg-black-15">
          <Box
            sx={{ width: "100%" }}
            className="d-flex justify-content-between tabs-header-box">
            <Tabs
              value={queries.tabIndex}
              onChange={(_, ix) =>
                dispatchQueries({
                  type: "SET_TAB_INDEX",
                  tabIndex: ix,
                  status: getReviewStatusFromTabIndex(ix),
                })
              }
              aria-label="scrollable force tabs example"
              className="tabs">
              <Tab
                label="All"
                className="tabs-head"
              />
              <Tab
                label="Pending"
                className="tabs-head"
              />
              <Tab
                label="Approved"
                className="tabs-head"
              />
              <Tab
                label="Disapproved"
                className="tabs-head"
              />
              <Tab
                label="Archived"
                className="tabs-head"
              />
            </Tabs>
          </Box>
          <div className="d-flex align-items-center mt-3 mb-3 px-2 justify-content-between">
            <TableSearchSecondary
              value={searchValue}
              onSearchValueChange={(v) => setSearchValue(v)}
              onChange={(v) => dispatchQueries({ type: "SET_SEARCH", search: v })}
            />

            <p className="text-grey-6 ms-2 me-1">|</p>

            <Button
              variant="text"
              onClick={handleSortClick}
              endIcon={
                <img
                  src={sort}
                  alt="icon"
                  width={15}
                />
              }>
              <span className="text-grey-6">Sort</span>
            </Button>
            <Popover
              className="columns"
              open={openSortMenu}
              anchorEl={sortAnchorEl}
              onClose={handleSortClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}>
              <FormControl className="px-2 py-1">
                <RadioGroup
                  name="controlled-radio-buttons-group"
                  onClick={handleSortClose}>
                  <FormControlLabel
                    value="1"
                    label="Oldest to Newest"
                    onChange={onSortChange}
                    control={
                      <Radio
                        size="small"
                        checked={queries.createdAt === "1"}
                      />
                    }
                  />
                  <FormControlLabel
                    value="-1"
                    label="Newest to Oldest"
                    onChange={onSortChange}
                    control={
                      <Radio
                        size="small"
                        checked={queries.createdAt === "-1"}
                      />
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Popover>
          </div>
          {reviewIsLoading ? (
            <span className="d-flex justify-content-center m-3">
              <TableLoader />
            </span>
          ) : allReviews.length > 0 ? (
            <>
              {selected.length > 0 && (
                <div className="d-flex align-items-center px-2 mb-3">
                  <button className="button-grey py-2 px-3">
                    <small className="text-lightBlue">
                      {selected.length} reviews are selected{" "}
                      <span
                        className="text-blue-2 c-pointer"
                        onClick={clearSelected}>
                        (Clear Selection)
                      </span>
                    </small>
                  </button>
                  {queries.tabIndex !== 4 && (
                    <TableEditStatusButton
                      onSelect={onBulkActionSelected}
                      defaultValue={["Approve", "Disapprove"]}
                      headingName="Edit Status"
                    />
                  )}
                  {queries.tabIndex === 4 ? (
                    /*<button
                      className="button-grey py-2 px-3 ms-2"
                      variant="contained"
                      onClick={() => onBulkActionSelected("Delete")}>
                      <small className="text-lightBlue">Delete</small>
                    </button>*/
                    <TableMassActionButton
                      onSelect={onBulkActionSelected}
                      defaultValue={["Un-Archive", "Delete"]}
                      headingName="Mass Action"
                    />
                  ) : (
                    <button
                      className="button-grey py-2 px-3 ms-2"
                      variant="contained"
                      onClick={() => onBulkActionSelected("Archive")}>
                      <small className="text-lightBlue">Archive</small>
                    </button>
                  )}
                </div>
              )}
              <TableContainer>
                <Table size="medium">
                  <EnhancedTableHead
                    onSelectAllClick={(e) =>
                      setSelected(e.target.checked ? [...allReviews] : [])
                    }
                    numSelected={selected.length}
                    rowCount={allReviews.length}
                    headCells={
                      !customerId ? headCells : headCells.filter((c) => c.id !== "customer")
                    }
                    orderBy={"groupName"}
                    onRequestSort={Date}
                    order={"asc"}
                  />
                  <TableBody>
                    {allReviews.map((row) => {
                      const {
                        _id: rid = "",
                        createdAt = "",
                        star = 0,
                        description = "",
                        product: { _id: pid = "", title = "" } = {},
                        order: { _id: oid = "", orderID = "" } = {},
                        customer: { firstName = "", lastName = "", _id: cid = "" } = {},
                        addedBy: { userName = "" } = {},
                        status = "",
                      } = row ?? {};
                      const isItemSelected = selected.includes(row);
                      const dateObj = moment(createdAt, moment.ISO_8601);
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={rid}
                          selected={isItemSelected}
                          className="table-rows">
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              onClick={(e) =>
                                setSelected((sel) =>
                                  e.target.checked
                                    ? sel.concat(row)
                                    : sel.filter((s) => !Object.is(s, row))
                                )
                              }
                              size="small"
                              style={{ color: "#5C6D8E" }}
                            />
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            padding="none">
                            <div className="d-flex flex-column text-decoration-none c-pointer py-3">
                              <Rating
                                size="small"
                                name="half-rating-read"
                                defaultValue={star}
                                readOnly
                              />
                              {/* <p
                                className="text-lightBlue rounded-circle fw-500 my-2"
                                dangerouslySetInnerHTML={{ __html: sanitize(description) }}
                              /> */}
                              <p className="text-lightBlue rounded-circle fw-500 my-2">
                                {description}
                              </p>
                              <small className="text-blue-2">
                                <Link
                                  className="text-blue-2 text-decoration-underline"
                                  to={`/products/allProducts/editProduct/${pid}`}>
                                  {title}
                                </Link>{" "}
                                <span className="text-grey-6">•</span>{" "}
                                {!orderID ? (
                                  <span className="text-blue-2 text-decoration-underline">
                                    FRONTEND
                                  </span>
                                ) : (
                                  <Link
                                    className="text-blue-2 text-decoration-underline"
                                    to={`/orders/allOrders/${oid}/details`}>
                                    {orderID}
                                  </Link>
                                )}{" "}
                                <span className="text-grey-6">
                                  • {dateObj.format("DD/MM/YYYY • hh:mma")}
                                </span>
                              </small>
                            </div>
                          </TableCell>
                          {!customerId && (
                            <TableCell style={{ width: 180 }}>
                              <div className="d-flex flex-column">
                                <Link
                                  to="/users/allUsers"
                                  className="text-blue-2 text-decoration-underline">
                                  {firstName} {lastName}
                                </Link>
                                {userName && (
                                  <div className="d-flex align-items-center mt-2">
                                    <small className="text-grey-6 me-2">
                                      Created by {userName}
                                    </small>
                                    {/* <img
                                    src={teamMember1}
                                    alt="team member"
                                  /> */}
                                  </div>
                                )}
                              </div>
                            </TableCell>
                          )}
                          <TableCell style={{ width: 140 }}>
                            <div className="d-flex align-items-center">
                              <div
                                className="rounded-pill d-flex table-status px-2 py-1"
                                style={{ backgroundColor: getReviewStatusColor(status) }}>
                                <small className="text-capitalize text-black fw-400">
                                  {status}
                                </small>
                              </div>
                            </div>
                          </TableCell>
                          <TableCell style={{ width: 160, padding: 0 }}>
                            <div
                              className="d-flex align-items-center"
                              onClick={clearSelected}>
                              {queries.tabIndex === 4 ? (
                                <>
                                  <Tooltip
                                    title="Un-Archive"
                                    placement="top">
                                    <div
                                      className="table-edit-icon c-pointer rounded-4 p-2"
                                      onClick={() => setUnarchivingReview(row)}>
                                      {/* onClick={() => setViewingReview(row)}> */}
                                      <InventoryIcon
                                        sx={{
                                          fontSize: 18,
                                          color: "#5c6d8e",
                                        }}
                                      />
                                    </div>
                                  </Tooltip>
                                  <Tooltip
                                    title="Delete"
                                    placement="top">
                                    <div
                                      className="table-edit-icon c-pointer rounded-4 p-2"
                                      onClick={() => setDeletingReview(row)}>
                                      <DeleteIcon
                                        sx={{
                                          fontSize: 18,
                                          color: "#5c6d8e",
                                        }}
                                      />
                                    </div>
                                  </Tooltip>
                                </>
                              ) : (
                                <>
                                  <Tooltip
                                    title="View"
                                    placement="top">
                                    <div className="table-edit-icon c-pointer rounded-4 p-2">
                                      <VisibilityOutlinedIcon
                                        onClick={() => setViewingReview(row)}
                                        sx={{
                                          fontSize: 18,
                                          color: "#5c6d8e",
                                        }}
                                      />
                                    </div>
                                  </Tooltip>
                                  <Tooltip
                                    title="Edit"
                                    placement="top">
                                    <Link
                                      to={`edit/${rid}`}
                                      className="table-edit-icon c-pointer rounded-4 p-2">
                                      <EditOutlinedIcon
                                        sx={{
                                          fontSize: 18,
                                          color: "#5c6d8e",
                                        }}
                                      />
                                    </Link>
                                  </Tooltip>
                                  <Tooltip
                                    title="Archive"
                                    placement="top">
                                    <div
                                      className="table-edit-icon c-pointer rounded-4 p-2"
                                      onClick={() => setArchivingReview(row)}>
                                      <InventoryIcon
                                        sx={{
                                          fontSize: 18,
                                          color: "#5c6d8e",
                                        }}
                                      />
                                    </div>
                                  </Tooltip>
                                </>
                              )}
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 20, 30]}
                component="div"
                count={reviewsCount}
                rowsPerPage={queries.pageSize}
                page={queries.pageNo - 1}
                onPageChange={(_, page) =>
                  dispatchQueries({ type: "SET_PAGE_NO", pageNo: page })
                }
                onRowsPerPageChange={(e) =>
                  dispatchQueries({ type: "SET_PAGE_SIZE", pageSize: e.target.value })
                }
                className="table-pagination"
              />
            </>
          ) : (
            <span className="d-flex justify-content-center m-3">
              <NoDataFound />
            </span>
          )}
        </Paper>
      </div>

      <ReviewDialog
        review={viewingReview}
        isOpen={!!viewingReview}
        onClose={() => setViewingReview(null)}
        onApprove={onReviewsApprove}
        onReject={onReviewsDisapproved}
      />

      <ArchiveModalSecondary
        onConfirm={onReviewsArchive}
        onCancel={() => setArchivingReview(null)}
        show={!!archivingReview}
        isLoading={editReviewIsLoading || bulkEditReviewIsLoading}
        message={
          selected.length > 1 ? `${selected.length} product reviews` : "the product review"
        }
        title={selected.length > 1 ? "Reviews" : "Review"}
      />

      <DeleteModalSecondary
        onConfirm={onReviewsDelete}
        onCancel={() => setDeletingReview(null)}
        show={!!deletingReview}
        isLoading={deleteReviewIsLoading || bulkDeleteReviewIsLoading}
        message={
          selected.length > 1 ? `${selected.length} product reviews` : "the product review"
        }
        title={selected.length > 1 ? "Reviews" : "Review"}
      />

      <UnArchivedModal
        isOpen={!!unarchivingReview}
        isLoading={editReviewIsLoading || bulkEditReviewIsLoading}
        statuses={["pending", "approved"]}
        title={selected.length > 1 ? "Un-Archive Reviews" : "Un-Archive Review"}
        message={selected.length > 1 ? `${selected.length} reviews` : "this review"}
        buttonText={"Un-Archive"}
        onClose={() => setUnarchivingReview(null)}
        onAction={(status) => onReviewsUnarchive(status)}
      />
    </div>
  );
}
