import { forwardRef, useCallback, useMemo, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Slide,
} from "@mui/material";
import cancel from "../../../assets/icons/cancel.svg";
import uploadLineSheet from "../../../assets/images/products/uploadLineSheet.svg";
import TableLoader from "../../../components/Loader/TableLoader";
import { useDropzone } from "react-dropzone";
import { useDispatch } from "react-redux";
import { showError, showSuccess } from "../../../features/snackbar/snackbarAction";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "0",
  borderWidth: 2,
  borderRadius: 8,
  borderColor: "#38395c",
  borderStyle: "dashed",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
  cursor: "pointer",
};
const focusedStyle = {
  borderColor: "#2196f3",
};
const acceptStyle = {
  borderColor: "#00e676",
};
const rejectStyle = {
  borderColor: "#ff1744",
};

const Transition = forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="up"
      ref={ref}
      {...props}
    />
  );
});

export default function ImportProductDialog({ show = false, onClose = () => {} }) {
  const dispatch = useDispatch();

  const [type, setType] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const closeDialog = () => {
    if (!isLoading) {
      setType("");
      onClose(false);
    }
  };

  const uploadCSV = useCallback(
    async (files) => {
      const file = files[0];
      // console.log(file);
      if (file) {
        setIsLoading(true);
        try {
          const fd = new FormData();
          fd.append("files", file);
          const res = await fetch(`${process.env.REACT_APP_MS_API_URL}/${type}/import`, {
            method: "POST",
            body: fd,
          });
          const payload = await res.json();
          console.log(payload, res);
          dispatch(
            showSuccess({
              message:
                payload?.message ??
                `${type} started importing, please check back in 2-5 minutes.`,
            })
          );
        } catch (error) {
          console.log(error);
          dispatch(
            showError({
              message: error?.data?.message ?? error?.message ?? "Something went wrong!",
            })
          );
        } finally {
          setIsLoading(false);
        }
      }
    },
    [dispatch, type]
  );

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    accept: {
      "text/csv": [".csv"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
    },
    onDrop: uploadCSV,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  return (
    <Dialog
      fullWidth
      keepMounted
      maxWidth="sm"
      open={show}
      onClose={closeDialog}
      TransitionComponent={Transition}
      aria-describedby="alert-dialog-slide-description">
      <DialogTitle>
        <div className="d-flex justify-content-between align-items-center">
          <h5 className="text-lightBlue fw-500">Import Products</h5>
          <img
            width={30}
            src={cancel}
            alt="cancel"
            className="c-pointer"
            onClick={closeDialog}
          />
        </div>
      </DialogTitle>
      <hr className="hr-grey-6 my-0" />
      <DialogContent className="py-2 px-4">
        {!type ? (
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={type}
              onChange={(_, v) => setType(v)}>
              <FormControlLabel
                value="products"
                control={<Radio size="small" />}
                label="Import Products"
                sx={{
                  "& .MuiTypography-root": {
                    fontSize: 13,
                    color: "#c8d8ff",
                  },
                }}
              />
              <FormControlLabel
                value="variants"
                control={<Radio size="small" />}
                label="Import Variants"
                sx={{
                  "& .MuiTypography-root": {
                    fontSize: 13,
                    color: "#c8d8ff",
                  },
                }}
              />
            </RadioGroup>
          </FormControl>
        ) : (
          <div className="d-flex flex-column">
            <small className="text-grey-6">Note :</small>
            <small className="text-grey-6">
              1. Upload the skeleton file and Map it with the Company Data.
            </small>
            <small className="text-grey-6">
              2. You can watch the Tutorial on how to do it.&nbsp;
              <span className="text-blue-gradient c-pointer">Watch here</span>
            </small>
            <small className="text-grey-6">
              3. Do not upload more than 50 products at a time.
            </small>
            <small className="text-grey-6">
              4. Select the folder containing Product Images with Product folder name equal to
              SKU
            </small>
            <small className="text-grey-6">5. Products should be uploaded successfully.</small>
            {!isLoading ? (
              <div
                {...getRootProps({ style })}
                className="mt-3">
                <input
                  id="primary"
                  {...getInputProps()}
                />
                <img
                  src={uploadLineSheet}
                  className="w-100"
                  alt=""
                />
              </div>
            ) : (
              <TableLoader />
            )}
            <small className="mt-2 text-lightBlue">
              Please make sure to leave a single row at the top of the Sheet
            </small>
          </div>
        )}
      </DialogContent>
      <hr className="hr-grey-6 my-0" />
      <DialogActions
        sx={{ justifyContent: "center" }}
        className="px-4 py-3">
        <button
          className="button-grey py-2 px-5"
          disabled={isLoading}
          onClick={closeDialog}>
          <p className="text-lightBlue">Cancel</p>
        </button>
        {/* <button className="button-gradient py-2 px-5">
          <p>Continue</p>
        </button> */}
      </DialogActions>
    </Dialog>
  );
}
