import apiSlice from "../../app/api/apiSlice";

export const membersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllMembers: builder.query({
      query: (queries = {}) => {
        const queryString = new URLSearchParams(queries).toString();
        return {
          url: `/roles?${queryString}`,
        };
      },
      providesTags: ["Members"],
    }),

    getAllMembersStatusCount: builder.query({
      query: (queries) => {
        let queryString = "";
        for (const key in queries) {
          if (queries[key]) {
            queryString = `${queryString}${queryString ? "&" : "?"}${key}=${queries[key]}`;
          }
        }
        return {
          url: `/user/count${queryString}`,
        };
      },
      providesTags: ["MembersCount"],
    }),

    createMembers: builder.mutation({
      query: (data) => ({
        url: "/user/create",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Members", "ProfileInfo", "MembersCount", "Logs"],
    }),

    // editRole: builder.mutation({
    //   query: ({ id, details }) => ({
    //     url: `/roles/${id}`,
    //     method: "PUT",
    //     body: details,
    //   }),
    //   invalidatesTags: ["Teams","Logs"],
    // }),

    bulkUpdateMember: builder.mutation({
      query: (updates) => ({
        url: "/user/bulkUpdate",
        method: "PUT",
        body: updates,
      }),
      invalidatesTags: ["Members", "ProfileInfo", "MembersCount", "Logs"],
    }),

    deleteMember: builder.mutation({
      query: (id) => ({
        url: `/user/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Members", "ProfileInfo", "MembersCount", "Logs"],
    }),

    bulkDeleteMember: builder.mutation({
      query: (deletes) => ({
        url: "/user/bulkDelete",
        method: "DELETE",
        body: deletes,
      }),
      invalidatesTags: ["Members", "ProfileInfo", "MembersCount", "Logs"],
    }),
  }),
});

export const {
  useCreateMembersMutation,
  useGetAllMembersQuery,
  useBulkUpdateMemberMutation,
  useDeleteMemberMutation,
  useBulkDeleteMemberMutation,
  useGetAllMembersStatusCountQuery,
} = membersApiSlice;
