import { useMemo, useState } from "react";
import { Chip, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import Heading from "../../components/Headers/DashboardHeader";
import {
  useGetTopSellingCategoryAnalyticsQuery,
  useGetTopSellingCollectionAnalyticsQuery,
} from "../../features/analytics/insightsApiSlice";
import NoDataFound from "../../components/NoDataFound/NoDataFound";

const timeData = [
  { value: "week", name: "This Week" },
  { value: "month", name: "This Month" },
  { value: "year", name: "This Year" },
];

export default function TopSellingParameters() {
  const [categoryTimeFrame, setCategoryTimeFrame] = useState("week");
  const [collectionTimeFrame, setCollectionTimeFrame] = useState("week");

  const { data: categoriesData } = useGetTopSellingCategoryAnalyticsQuery({
    type: categoryTimeFrame,
  });
  const { data: collectionsData } = useGetTopSellingCollectionAnalyticsQuery({
    type: collectionTimeFrame,
  });

  const categories = useMemo(
    () => (categoriesData ?? []).map((c) => ({ ...c, price: 0 })),
    [categoriesData]
  );

  const collections = useMemo(
    () => (collectionsData ?? []).map((c) => ({ ...c, price: 0 })),
    [collectionsData]
  );

  return (
    <div className="col-12 d-flex flex-wrap mt-4">
      <div className="col-lg-6 pe-3">
        <div className="bg-button-3 border-grey-5 rounded-8 px-4 pt-4 pb-3">
          <Heading
            heading={"Top Categories"}
            // viewMore={"View More"}
          />
          <div className="d-flex mt-4 mb-2">
            {timeData.map((item) => (
              <Chip
                key={item.value}
                onClick={() => setCategoryTimeFrame(item.value)}
                variant={categoryTimeFrame === item.value ? "filled" : "outlined"}
                label={item.name}
                className="mx-2"
              />
            ))}
          </div>
          <TableContainer>
            {categories.length > 0 ? (
              <Table>
                <TableBody>
                  {categories.map((row) => (
                    <TableRow key={row._id}>
                      <TableCell
                        component="th"
                        scope="row">
                        <small className="text-blue-gradient-2 fw-600">{row.title}</small>
                      </TableCell>
                      <TableCell align="right">
                        <small className="text-lightBlue fw-400">{row.price}</small>
                      </TableCell>
                      <TableCell align="right">
                        <small className="text-lightBlue fw-400">{row.count} Products</small>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              <NoDataFound />
            )}
          </TableContainer>
        </div>
      </div>
      <div className="col-lg-6 ps-3">
        <div className="bg-button-3 border-grey-5 rounded-8 px-4 pt-4 pb-3">
          <Heading
            heading={"Top Collections"}
            // viewMore={"View More"}
          />
          <div className="d-flex mt-4 mb-2">
            {timeData.map((item) => (
              <Chip
                key={item.value}
                onClick={() => setCollectionTimeFrame(item.value)}
                variant={collectionTimeFrame === item.value ? "filled" : "outlined"}
                label={item.name}
                className="mx-2"
              />
            ))}
          </div>
          <TableContainer>
            {collections.length > 0 ? (
              <Table>
                <TableBody>
                  {collections.map((row) => (
                    <TableRow key={row._id.toString()}>
                      <TableCell
                        component="th"
                        scope="row">
                        <small className="text-blue-gradient-2 fw-600">{row.title}</small>
                      </TableCell>
                      <TableCell align="right">
                        <small className="text-lightBlue fw-400">{row.price}</small>
                      </TableCell>
                      <TableCell align="right">
                        <small className="text-lightBlue fw-400">{row.count} Products</small>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              <NoDataFound />
            )}
          </TableContainer>
        </div>
      </div>
    </div>
  );
}
