import cancel from "../../../../assets/icons/cancel.svg";
import info from "../../../../assets/icons/info.svg";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  OutlinedInput,
  Slide,
  Tooltip,
  FormHelperText,
} from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import React from "react";
import { SaveFooterTertiary } from "../../../../components/SaveFooter/SaveFooter";
import Label from "../../../../components/Label/Label";
import { useCreateOptionSetMutation } from "../../../../features/parameters/options/optionSetsApiSlice";
import SnackbarUtils from "../../../../features/snackbar/useSnackbar";
import { useDispatch } from "react-redux";
import { showError, showSuccess } from "../../../../features/snackbar/snackbarAction";
import OptionSet from "../../../../components/Options/OptionSet/OptionSet";

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="up"
      ref={ref}
      {...props}
    />
  );
});

const optionSetValidationSchema = Yup.object({
  name: Yup.string().required("Required"),
  isProduct: Yup.boolean().required("Required"),
  categoryId: Yup.string().optional(),
  option: Yup.array().of(
    Yup.object({
      attribute: Yup.array().of(
        Yup.object({
          expanded: Yup.boolean().optional(),
          id: Yup.string().required("Required"),
          metaAttributes: Yup.array()
            .of(
              Yup.object({
                id: Yup.string().required("Required"),
                metaSubAttribute: Yup.array().of(
                  Yup.object({
                    id: Yup.string().required("Required"),
                    metaSubAttributeValue: Yup.array()
                      .of(Yup.string().required("Required"))
                      .when(["id"], ([id], schema) => {
                        if (id) {
                          return schema
                            .min(1, "Minimum 1 sub attribute required")
                            .required("Required");
                        }
                        return schema;
                      }),
                  })
                ),
              })
            )
            .when(["id"], ([id], schema) => {
              if (id) {
                return schema.min(1, "Minimum 1 attribute required").required("Required");
              }
              return schema;
            }),
        })
      ),
    })
  ),
});

const EMPTY_OPTION = {
  attribute: [
    {
      expanded: true,
      id: "",
      metaAttributes: [],
    },
  ],
};

export default function CreateOptionSetsDialog({
  show = false,
  onAction = () => undefined,
  onClose = () => undefined,
}) {
  const dispatch = useDispatch();

  const [createOptionSet, { isLoading: createOptionSetIsLoading }] =
    useCreateOptionSetMutation();

  const optionSetFormik = useFormik({
    initialValues: {
      name: "",
      isProduct: false,
      categoryId: "",
      option: [],
    },
    // enableReinitialize: true,
    validationSchema: optionSetValidationSchema,
    onSubmit: (values) => {
      for (const key in values)
        if (values[key] === "" || values[key] === null || values[key] === undefined)
          delete values[key];

      SnackbarUtils.savingToast();

      createOptionSet(values)
        .unwrap()
        .then((data) => {
          console.log(data);
          onAction(data._id);
          closeModal();
          dispatch(
            showSuccess({
              message: "Option set created successfully",
            })
          );
        })
        .catch((error) => {
          dispatch(
            showError({
              message:
                error?.data?.message ??
                error.message ??
                "Something went wrong!, please try again",
            })
          );
        });

      SnackbarUtils.hideToast();
    },
  });

  const addOptionHandler = () => {
    const updatedOptions = optionSetFormik.values.option?.concat(EMPTY_OPTION);
    optionSetFormik.setFieldValue("option", updatedOptions);
  };

  const closeModal = () => {
    optionSetFormik.resetForm();
    onClose();
  };

  return (
    <Dialog
      open={show}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      maxWidth="md"
      fullWidth>
      <DialogTitle>
        <div className="d-flex justify-content-between align-items-center">
          <h5 className="text-lightBlue fw-500">Create Option Sets</h5>
          <img
            src={cancel}
            alt="cancel"
            width={30}
            onClick={onClose}
            className="c-pointer"
          />
        </div>
      </DialogTitle>
      <hr className="hr-grey-6 my-0" />
      <form
        noValidate
        onSubmit={optionSetFormik.handleSubmit}>
        <DialogContent className="py-3 px-4">
          <div className="row align-items-center">
            <div className="col-12">
              <div className="bg-black-15 border-grey-5 rounded-8 p-3 row attributes mb-3">
                <div className="col-md-12 px-0">
                  <Label required>Enter Option Set Title</Label>
                  <Tooltip
                    title="Lorem ipsum"
                    placement="top">
                    <img
                      src={info}
                      alt="info"
                      className=" c-pointer"
                      width={13.5}
                    />
                  </Tooltip>
                  <FormControl className="w-100 px-0 mt-2">
                    <OutlinedInput
                      size="small"
                      sx={{ paddingLeft: 0 }}
                      placeholder="Enter Name"
                      name="name"
                      value={optionSetFormik.values.name}
                      onBlur={optionSetFormik.handleBlur}
                      onChange={optionSetFormik.handleChange}
                      autoFocus
                    />
                    <FormHelperText error>
                      {optionSetFormik.touched.name &&
                        optionSetFormik.errors.name &&
                        optionSetFormik.errors.name}
                    </FormHelperText>
                  </FormControl>
                </div>
              </div>

              <div className="bg-black-15 border-grey-5 rounded-8 p-3 row attributes">
                <div className="col-md-12 px-0 d-flex">
                  <Grid
                    container
                    spacing={2}>
                    <Grid
                      item
                      sm={6}>
                      <div className="d-flex mb-1">
                        <label className="text-lightBlue me-2">Make Option Sets</label>
                        <Tooltip
                          title="Lorem ipsum"
                          placement="top">
                          <img
                            src={info}
                            alt="info"
                            className=" c-pointer"
                            width={13.5}
                          />
                        </Tooltip>
                      </div>

                      <div className="d-flex mb-1">
                        <Tooltip
                          title="Lorem ipsum"
                          placement="top">
                          <img
                            src={info}
                            alt="info"
                            className=" c-pointer me-1"
                            width={8.5}
                          />
                        </Tooltip>
                        <small className="ex-small text-grey-6">
                          If this product has options, like size or color then add option
                        </small>
                      </div>
                    </Grid>
                    <Grid
                      item
                      sm={6}
                      sx={{ display: "flex", justifyContent: "flex-end" }}>
                      <button
                        type="button"
                        onClick={addOptionHandler}
                        className="button-gradient py-2 px-4 ms-3 c-pointer">
                        <p>+ Add Options</p>
                      </button>
                    </Grid>
                  </Grid>
                </div>
                {optionSetFormik.values.option.map((option, index) => {
                  return (
                    <OptionSet
                      key={index}
                      // isEdit={id}
                      divStyle={{ overflowY: "auto", maxHeight: "45vh" }}
                      onOptionDelete={(arg) => console.log(option, arg)}
                      index={index}
                      formik={optionSetFormik}
                      isSubmitting={optionSetFormik.isSubmitting}
                    />
                  );
                })}
              </div>
            </div>
          </div>
          {/* <SaveFooterTertiary
          // show={id ? optionSetState.isEditing : true}
          // onDiscard={backHandler}
          // isLoading={createOptionSetIsLoading}
          /> */}
        </DialogContent>
        <hr className="hr-grey-6 my-0" />
        <DialogActions className="d-flex justify-content-between px-4 pb-5">
          <SaveFooterTertiary
            show
            onDiscard={closeModal}
            isLoading={createOptionSetIsLoading}
          />
        </DialogActions>
        {/* <DialogActions className="d-flex justify-content-between px-4 py-3">
        <button
          className="button-grey py-2 px-5"
          onClick={onClose}>
          <p className="text-lightBlue">Cancel</p>
        </button>
        <button
          className="button-gradient py-2 px-4"
          onClick={onClose}>
          <p>Import</p>
        </button>
      </DialogActions> */}
      </form>
    </Dialog>
  );
}
