import {
  Autocomplete,
  FormControl,
  FormHelperText,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  SwipeableDrawer,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useCallback, useEffect, useReducer } from "react";
import AppMobileCodeSelect from "../../../components/AppMobileCodeSelect/AppMobileCodeSelect";
import UploadFileRounded from "../../../components/UploadFileRounded/UploadFileRounded";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import info from "../../../assets/icons/info.svg";
import uploadProfile from "../../../assets/icons/uploadProfile.svg";
import { useFormik } from "formik";
import DashboardBox from "../../../components/Setting/Store/DashBoardBox";
import { useGetAllRolesQuery } from "../../../features/team/roleApiSlice";
import { UploadMediaRound } from "../../../components/UploadMediaBox/UploadMedia";
import { SaveFooterTertiary } from "../../../components/SaveFooter/SaveFooter";
import { useCreateMembersMutation } from "../../../features/team/membersApiSlice";
import { useDispatch } from "react-redux";
import { showError, showSuccess } from "../../../features/snackbar/snackbarAction";
import * as Yup from "yup";
import _ from "lodash";
import { omitEmptyKeys } from "../../../utils/helper";
import { DiscardModalSecondary } from "../../../components/Discard/DiscardModal";
import {
  useEditProfileMutation,
  useGetAllMembersQuery,
  useGetAllProfileDetailsQuery,
} from "../../../features/myProfile/myProfileApiSlice";

const validationSchema = Yup.object().shape({
  fullName: Yup.string().required("Name is required"),
  phone: Yup.string()
    .matches(/^\d{10}$/, "Mobile number must be exactly 10 digits")
    .required("Mobile number is required"),
  countryCode: Yup.string().required("Country code is required"),
  email: Yup.string().email("Invalid email format").required("Email is required"),
  roles: Yup.array().of(Yup.string()).min(1, "Role is required").required("Role is required"),
});
const initialMemberState = {
  isEditing: false,
  edited: false,
};
const memberReducer = (state, action) => {
  if (action.type === "ENABLE_EDIT") {
    return {
      ...state,
      isEditing: true,
    };
  }
  if (action.type === "DISABLE_EDIT") {
    return {
      ...state,
      isEditing: false,
    };
  }
  if (action.type === "EDITED_ENABLE") {
    return {
      ...state,
      edited: true,
    };
  }
  if (action.type === "EDITED_DISABLE") {
    return {
      ...state,
      edited: false,
    };
  }
  return initialMemberState;
};

const initialMemberValues = {
  fullName: "",
  designation: "",
  imageUrl: "",
  email: "",
  countryCode: "",
  phone: "",
  userId: "",
  roles: [],
  description: "",
};

const AddMembers = ({ toggleAddMemberDrawer, addProductDrawer, setAddProductDrawer, id }) => {
  const dispatch = useDispatch();
  const [memberState, dispatchMember] = useReducer(memberReducer, initialMemberState);

  const { data: rolesData } = useGetAllRolesQuery({
    status: "active",
    pageNo: 1,
    pageSize: 1000000,
  });

  const [editMember] = useEditProfileMutation();

  const {
    data: memberData,
    isSuccess: memberDataIsSuccess,
    isFetching: memberIsFetching,
  } = useGetAllMembersQuery({ id: id }, { skip: !id });

  const [createMember] = useCreateMembersMutation();

  const formik = useFormik({
    initialValues:
      id && memberDataIsSuccess
        ? {
            fullName: memberData?.data?.data[0]?.fullName || "",
            designation: memberData?.data?.data[0]?.designation || "",
            imageUrl: memberData?.data?.data[0]?.imageUrl || "",
            email: memberData?.data?.data[0]?.email || "",
            countryCode: memberData?.data?.data[0]?.countryCode || "",
            phone: memberData?.data?.data[0]?.phone || "",
            userId: memberData?.data?.data[0]?.userId || "",
            roles: memberData?.data?.data[0]?.roles.map((item) => item._id) || [],
            description: memberData?.data?.data[0]?.description || "",
          }
        : initialMemberValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log("submit", values);
      const memberValues = structuredClone(values);
      memberValues.fullName =
        formik.values.fullName !== formik.initialValues.fullName ? memberValues.fullName : "";
      const editObj = omitEmptyKeys(memberValues);
      if (id) {
        editMember({
          id: id,
          details: editObj,
        })
          .unwrap()
          .then(() => {
            dispatch(showSuccess({ message: "Member edited Successfully" }));
            formik.resetForm();
            setAddProductDrawer({ ...addProductDrawer, right: false });
          })
          .catch((e) =>
            dispatch(
              showError({
                message: e?.data?.message ?? e?.message ?? "Something went wrong",
              })
            )
          );
      } else {
        createMember(editObj)
          .unwrap()
          .then(() => {
            dispatch(showSuccess({ message: "Member created Successfully" }));
            formik.resetForm();
            setAddProductDrawer({ ...addProductDrawer, right: false });
          })
          .catch((e) =>
            dispatch(
              showError({
                message: e?.data?.message ?? e?.message ?? "Something went wrong",
              })
            )
          );
      }
    },
  });

  const onKeyDown = (keyEvent) => {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  };

  const selectedData = rolesData?.data?.data?.find(
    (item) => item?._id === formik?.values?.roles[0]
  );

  const IconUploadHandler = useCallback((url) => {
    formik.setFieldValue("imageUrl", url);
  }, []);

  const backHandler = () => {
    formik.resetForm();
    setAddProductDrawer({ ...addProductDrawer, right: false });
  };

  useEffect(() => {
    if (!_.isEqual(formik.values, formik.initialValues)) {
      dispatchMember({ type: "ENABLE_EDIT" });
      dispatchMember({ type: "EDITED_ENABLE" });
    } else if (_.isEqual(formik.values, formik.initialValues)) {
      dispatchMember({ type: "EDITED_DISABLE" });
      dispatchMember({ type: "DISABLE_EDIT" });
    }
  }, [formik.initialValues, formik.values]);

  // console.log({ valuesMembers: formik?.values });
  //   console.log({valuesMembersInitial : formik?.initialValues})
  // console.log("fniuewd", rolesData?.data?.data[0]?.name);
  // console.log("kjdugdyewyutdvy", id )
  // console.log({memberDataIsLoading})
  // console.log({memberIsFetching})

  return (
    <SwipeableDrawer
      anchor="right"
      open={addProductDrawer["right"]}
      onClose={() => {
        toggleAddMemberDrawer("right", false);
      }}
      onOpen={() => {
        toggleAddMemberDrawer("right", true);
      }}
      className="role-drawer"
      keepMounted={false}>
      <div className="d-flex align-items-center pt-3 px-3">
        <KeyboardArrowLeftOutlinedIcon
          sx={{ fontSize: 25, color: "#c8d8ff" }}
          onClick={toggleAddMemberDrawer("right", false)}
          className="c-pointer"
        />
        <div>
          <h5 className="text-lightBlue fw-500 ms-2">
            {id ? formik?.values?.fullName : `Add Team Member`}
          </h5>
        </div>
      </div>
      <div className="px-3">
        <hr className="hr-grey-6 mt-3 mb-3" />
      </div>
      {!memberIsFetching && (
        <form
          className="myProfile-form "
          noValidate
          onKeyDown={onKeyDown}
          onSubmit={formik.handleSubmit}
          style={{ display: "grid", gridTemplateRows: "1fr auto" }}>
          <div className="px-3">
            <div className="row">
              <div className="col-md-4 pt-1">
                <UploadMediaRound
                  fileSrc={formik.values?.imageUrl}
                  error={formik.errors.imageUrl}
                  onUpload={IconUploadHandler}
                  onBlur={formik.handleBlur}
                  name="imageUrl"
                  isSubmitting={formik.isSubmitting}
                  touched={!!formik.touched.imageUrl}
                />
              </div>
              <div className="col-md-8">
                <div className="d-flex mb-1">
                  <p className="text-lightBlue">Name</p>
                  <Tooltip
                    title="Lorem ipsum"
                    placement="top">
                    <img
                      src={info}
                      alt="info"
                      className="c-pointer ms-2"
                      width={13.5}
                    />
                  </Tooltip>
                </div>
                <FormControl className="w-100 px-0">
                  <OutlinedInput
                    placeholder="Enter Name"
                    size="small"
                    name="fullName"
                    value={formik?.values?.fullName}
                    onChange={formik?.handleChange}
                    onBlur={formik?.handleBlur}
                  />
                  <FormHelperText error>
                    {formik.touched.fullName && formik.errors.fullName}
                  </FormHelperText>
                </FormControl>
                <div className="d-flex mb-1 mt-3">
                  <p className="text-lightBlue">Designation</p>
                  <Tooltip
                    title="Lorem ipsum"
                    placement="top">
                    <img
                      src={info}
                      alt="info"
                      className="c-pointer ms-2"
                      width={13.5}
                    />
                  </Tooltip>
                </div>
                <FormControl className="w-100 px-0">
                  <OutlinedInput
                    placeholder="Enter Designation"
                    size="small"
                    name="designation"
                    value={formik?.values?.designation}
                    onChange={formik?.handleChange}
                    onBlur={formik?.handleBlur}
                  />
                </FormControl>
              </div>
              <div className="col-12 mt-3">
                <div className="d-flex mb-1 justify-content-between">
                  <p className="text-lightBlue me-2">Email ID</p>
                  {/* <small className="text-blue-2">Verify</small> */}
                </div>
                <FormControl className="w-100 px-0">
                  <OutlinedInput
                    placeholder="Enter Email ID"
                    size="small"
                    name="email"
                    value={formik?.values?.email}
                    onChange={formik?.handleChange}
                    onBlur={formik?.handleBlur}
                  />
                  <FormHelperText error>
                    {formik.touched.email && formik.errors.email}
                  </FormHelperText>
                </FormControl>
              </div>
              <div className="col-12 mt-3">
                <div className="d-flex mb-1 justify-content-between">
                  <p className="text-lightBlue me-2">Mobile Number</p>
                  {/* <small className="text-blue-2">Verify</small> */}
                </div>
                <FormControl className="w-100 px-0">
                  <OutlinedInput
                    placeholder="Enter Mobile Number"
                    size="small"
                    sx={{ paddingLeft: 0 }}
                    name="phone"
                    value={formik?.values?.phone}
                    onChange={formik?.handleChange}
                    onBlur={formik?.handleBlur}
                    startAdornment={
                      <InputAdornment position="start">
                        <AppMobileCodeSelect
                          formik={formik}
                          handleCode={(e, value) => {
                            formik.setFieldValue("countryCode", value?._id);
                          }}
                          countryCode={formik?.values?.countryCode}
                          handlePhoneBlur={formik?.handleBlur}
                          freeSolo={false}
                        />
                      </InputAdornment>
                    }
                  />
                  <FormHelperText error>
                    {(formik.touched.phone || formik.touched.countryCode) &&
                      (formik.errors.phone || formik.errors.countryCode)}
                  </FormHelperText>
                </FormControl>
              </div>

              {id && (
                <div className="col-12 mt-3">
                  <div className="d-flex mb-1">
                    <p className="text-lightBlue">Member Id</p>
                    <Tooltip
                      title="Lorem ipsum"
                      placement="top">
                      <img
                        src={info}
                        alt="info"
                        className="c-pointer ms-2"
                        width={13.5}
                      />
                    </Tooltip>
                  </div>
                  <FormControl className="w-100 px-0">
                    <OutlinedInput
                      placeholder="Enter Member ID"
                      size="small"
                      name="userId"
                      value={formik?.values?.userId}
                      onChange={formik?.handleChange}
                      onBlur={formik?.handleBlur}
                      readOnly
                    />
                  </FormControl>
                </div>
              )}
              <div className="col-12 mt-3">
                <div className="d-flex mb-1">
                  <p className="text-lightBlue">Select Roles</p>
                  <Tooltip
                    title="Lorem ipsum"
                    placement="top">
                    <img
                      src={info}
                      alt="info"
                      className="c-pointer ms-2"
                      width={13.5}
                    />
                  </Tooltip>
                </div>
                <Autocomplete
                  id="role-select-field"
                  size="small"
                  disableClearable
                  options={rolesData?.data?.data ?? []}
                  getOptionLabel={(option) => option?.name}
                  value={selectedData || null}
                  onChange={(_, newValue) => {
                    formik?.setFieldValue("roles", [newValue?._id]);
                  }}
                  onBlur={formik?.handleBlur}
                  autoHighlight
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <small className="text-lightBlue">{option?.name}</small>
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select Roles"
                    />
                  )}
                />
                <FormHelperText error>
                  {formik.touched.roles && formik.errors.roles}
                </FormHelperText>
              </div>
              <div className="col-12 mt-3">
                <div className="d-flex mb-1">
                  <p className="text-lightBlue">Description</p>
                  <Tooltip
                    title="Lorem ipsum"
                    placement="top">
                    <img
                      src={info}
                      alt="info"
                      className="c-pointer ms-2"
                      width={13.5}
                    />
                  </Tooltip>
                </div>
                <FormControl className="w-100 px-0">
                  <OutlinedInput
                    placeholder="Enter some Description"
                    size="small"
                    name="description"
                    value={formik?.values?.description}
                    onChange={formik?.handleChange}
                    onBlur={formik?.handleBlur}
                  />
                </FormControl>
              </div>
            </div>
          </div>
          <div className="d-flex flex-column  px-4 role-buttons mb-1">
            <hr className="hr-grey-6 w-100" />
            <SaveFooterTertiary
              show={id ? memberState.isEditing : true}
              onDiscard={backHandler}
              saveButtonName={id ? "Save" : "Add Member"}
              //   isLoading={editProfileDataIsLoading}
              module="addMembers"
            />
          </div>
        </form>
      )}
    </SwipeableDrawer>
  );
};

export default AddMembers;
