import apiSlice from "../../app/api/apiSlice";

import { omitNullishKeys } from "../../utils/helper";

export const policiesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllPolicies: builder.query({
      query: (queries) => {
        return {
          url: "/website/policy",
          params: omitNullishKeys(queries),
        };
      },
      transformResponse: (res) => res.data,
      providesTags: ["Policy"],
    }),
    createPolicy: builder.mutation({
      query: (policyDetails) => ({
        url: "/website/policy",
        method: "POST",
        body: policyDetails,
      }),
      invalidatesTags: ["Policy", "Logs"],
    }),
    deletePolicy: builder.mutation({
      query: (policyId) => ({
        url: `/website/policy/${policyId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Policy", "Logs"],
    }),
    editPolicy: builder.mutation({
      query: ({ id, details }) => ({
        url: `/website/policy/${id}`,
        method: "PUT",
        body: details,
      }),
      invalidatesTags: ["Policy", "Logs"],
    }),
  }),
});

export const {
  useGetAllPoliciesQuery,
  useCreatePolicyMutation,
  useDeletePolicyMutation,
  useEditPolicyMutation,
} = policiesApiSlice;
