import _ from "lodash";

import { UNITS } from "./defaults";

import { showError } from "../features/snackbar/snackbarAction";
import store from "../app/store";

const isHttpValid = (str) => {
  try {
    const newUrl = new URL(str);
    return newUrl.protocol === "http:" || newUrl.protocol === "https:";
  } catch (err) {
    return false;
  }
};

const omitEmptyKeys = (obj, excludes = []) =>
  _.omitBy(obj, (value, key) => {
    return (
      !excludes.includes(key) &&
      (value === "" || value === undefined || value === null)
    );
  });

const omitNullishKeys = (obj, excludes = []) =>
  _.omitBy(obj, (value, key) => {
    return !excludes.includes(key) && !value;
  });

const pickExactObjKeys = (obj, pickObj) => _.pick(pickObj, Object.keys(obj));

function formatBytes(bytes, decimals = 2) {
  if (!+bytes) return "0B";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["B", "kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))}${sizes[i]}`;
}

function isImgUrl(url) {
  const img = new Image();
  img.src = url;
  return new Promise((resolve) => {
    img.onload = () => resolve(true);
    img.onerror = () => resolve(false);
  });
}

function isVideoUrl(url) {
  const video = document.createElement("video");
  video.setAttribute("src", url);
  return new Promise((resolve) => {
    video.addEventListener("canplay", function () {
      resolve(true);
    });
    video.addEventListener("error", function () {
      resolve(false);
    });
  });
}

function bytesFormat(x) {
  let index = 0;
  let value = parseInt(x, 10) || 0;

  while (value >= 1024 && ++index) {
    value = value / 1024;
  }

  return value.toFixed(value < 10 && index > 0 ? 1 : 0) + " " + UNITS[index];
}

const validateDropzoneSingleFile = (rejectedFiles, maxSize) => {
  const rejectedFile = rejectedFiles[0];
  if (rejectedFile) {
    const {
      errors: [{ code }],
      file: { name },
    } = rejectedFile;
    switch (code) {
      case "file-too-large": {
        store.dispatch(
          showError({
            message: `${name} is larger than ${bytesFormat(maxSize)}`,
          })
        );
        break;
      }
      default:
        break;
    }
  }
};

const validateDropzoneMultipleFiles = (rejectedFiles, maxSize) => {
  rejectedFiles.forEach((file) => {
    const {
      errors: [{ code }],
      file: { name },
    } = file;
    switch (code) {
      case "file-too-large": {
        store.dispatch(
          showError({
            message: `${name} is larger than ${bytesFormat(maxSize)}`,
          })
        );
        break;
      }
      default:
        break;
    }
  });
};

export {
  isHttpValid,
  omitEmptyKeys,
  omitNullishKeys,
  pickExactObjKeys,
  formatBytes,
  isImgUrl,
  isVideoUrl,
  validateDropzoneSingleFile,
  validateDropzoneMultipleFiles,
};
