import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { visuallyHidden } from "@mui/utils";
// ! MATERIAL IMPORTS
import { Box, Checkbox, TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material";

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const getComparator = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

const ManualEnhancedTableHead = ({
  headCells = [],
  current = [],
  selected = [],
  onSelectAllClick = () => {},
}) => {
  const includesEvery = useMemo(
    () => current.every((id) => selected.includes(id)),
    [current, selected]
  );
  const includesSome = useMemo(
    () => current.some((id) => selected.includes(id)),
    [current, selected]
  );
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={!includesEvery && includesSome}
            checked={includesEvery}
            onChange={onSelectAllClick}
            style={{ color: "#5C6D8E" }}
            size="small"
          />
        </TableCell>
        {headCells.map((headCell) => {
          return (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}>
              <p className="text-lightBlue">
                {headCell.image && (
                  <img
                    src={headCell.image}
                    alt={headCell.label}
                    className="me-2"
                    width={20}
                  />
                )}
                {headCell.label}
              </p>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

const EnhancedTableHead = (props) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
    isCheckBox = true,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {isCheckBox && (
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                "aria-label": "select all rows",
              }}
              size="small"
              style={{
                color: "#5C6D8E",
              }}
            />
          </TableCell>
        )}
        {headCells.map((headCell) => {
          return (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}>
              <p className="text-lightBlue">
                {headCell.image && (
                  <img
                    src={headCell.image}
                    className="me-2"
                    width={20}
                  />
                )}
                {headCell.label}
              </p>

              {/* {headCell.id !== "actions" ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                <p className="text-lightBlue">{headCell.label}</p>
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              <p className="text-lightBlue">{headCell.label}</p>
            )} */}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
// ? TABLE ENDS HERE

export { EnhancedTableHead, ManualEnhancedTableHead, stableSort, getComparator };
