import { useMemo, useState } from "react";
// ! IMAGES IMPORTS
import cancel from "../../../assets/icons/cancel.svg";
import filter from "../../../assets/icons/filter.svg";
// ! MATERIAL IMPORTS
import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  FormGroup,
  SwipeableDrawer,
  TextField,
  styled,
  FormControl,
  OutlinedInput,
  Slider,
} from "@mui/material";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
// ! MATERIAL ICONS IMPORTS
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import { useGetAllTagsQuery } from "../../../features/parameters/tagsManager/tagsManagerApiSlice";
import { useGetAllStateQuery } from "../../../features/master/state/stateApiSlice";
import moment from "moment/moment";
import { useGetAllVendorsQuery } from "../../../features/parameters/vendors/vendorsApiSlice";
import { useGetOrderRangeQuery } from "../../../features/orders/ordersApiSlice";

// ? FILTER ACCORDIAN STARTS HERE
const Accordion = styled((props) => (
  <MuiAccordion
    disableGutters
    elevation={0}
    square
    {...props}
  />
))(({ theme }) => ({
  // border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem", color: "#c8d8ff" }} />}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    padding: "0px",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: "0 16px ",
}));
// ? FILTER ACCORDIAN ENDS HERE

export const OrderItemStatuses = [
  { text: "Pending", value: "pending", color: "#ffea8a" },
  { text: "Processing", value: "processing", color: "#ffd79d" },
  { text: "Awaiting Shipment", value: "awaitingShipment", color: "#ffd79d" },
  { text: "Delivered", value: "delivered", color: "#a6faaf" },
  { text: "Cancelled", value: "cancelled", color: "#f67476" },
  { text: "On Hold", value: "onHold", color: "#ffea8a" },

  { text: "Pickup Pending", value: "pickupPending", color: "#ffd79d" },
  { text: "Picked Up", value: "pickedUp", color: "#ffd79d" },
  { text: "Partially Shipped", value: "partiallyShipped", color: "#ffd79d" },
  { text: "In Transit", value: "inTransit", color: "#ffd79d" },
  { text: "Out For Pickup", value: "outForPickup", color: "#ffd79d" },
  { text: "Shippment Delayed", value: "shippmentDelayed", color: "#ffd79d" },
  { text: "Out For Delivery", value: "outForDelivery", color: "#ffd79d" },
  { text: "Order Placed", value: "orderPlaced", color: "#ffd79d" },
  { text: "Exchange Delivered", value: "exchangeDelivered", color: "#ffd79d" },
  { text: "On Hold", value: "onHold", color: "#ffd79d" },
  { text: "Picked Up", value: "pickedUp", color: "#ffd79d" },
  { text: "Failed Delivery", value: "failedDelivery", color: "#ffd79d" },
  { text: "Pickup Cancelled", value: "pickupCancelled", color: "#ffd79d" },
  { text: "Damaged", value: "damaged", color: "#ffd79d" },
  { text: "Lost", value: "lost", color: "#ffd79d" },
  { text: "Order Cancelled", value: "orderCancelled", color: "#ffd79d" },
  { text: "Expired", value: "expired", color: "#ffd79d" },
  { text: "Archieved", value: "archieved", color: "#ffd79d" },
  { text: "RTO", value: "rto", color: "#ffd79d" },
  { text: "RTO Marked", value: "rtoMarked", color: "#ffd79d" },
  { text: "RTO Out For Delivery", value: "rtOutForDelivery", color: "#ffd79d" },
  { text: "RTO Delivered", value: "rtoDelivered", color: "#ffd79d" },
  { text: "RTO Failed", value: "rtoFailed", color: "#ffd79d" },
  { text: "RTO In Transit", value: "rtoInTransit", color: "#ffd79d" },
  { text: "Excahnge PickUp", value: "excahngePickUp", color: "#ffd79d" },
  { text: "Destination", value: "destination", color: "#ffd79d" },
];

export const OrderStatuses = [
  { text: "Pending", value: "pending", color: "#ffea8a" },
  { text: "Processing", value: "processing", color: "#ffd79d" },
  { text: "Partial Shipping", value: "partialShipping", color: "#ffd79d" },
  { text: "Shipping", value: "shipping", color: "#ffd79d" },
  { text: "Partially Delivered", value: "partiallyDelivered", color: "#a6faaf" },
  { text: "Delivered", value: "delivered", color: "#a6faaf" },
  { text: "Cancelled", value: "cancelled", color: "#f67476" },
  { text: "Archived", value: "archieved", color: "#c8d8ff" },
  { text: "Abandoned", value: "abandoned", color: "#FAA6A0" },

  // { text: "Completed", value: "delivered", color: "#A6FAAF" },
  // { text: "Awaiting Shipment", value: "awaitingShipment", color: "#FFEA8A" },
  // { text: "Fullfiled", value: "fullfiled", color: "#A6FAAF" },
  // { text: "Order Confirm", value: "orderConfirm", color: "#A6FAAF" },
];

export const PaymentStatuses = [
  { text: "Pending", value: "pending", color: "#FAE7A6" },
  { text: "Processing", value: "processing", color: "#C8D8FF" },
  { text: "Failed", value: "failed", color: "#FAA6A6" },
  { text: "Success", value: "done", color: "#A6FAAF" },
  { text: "Refunded", value: "refunded", color: "#FAA6A6" },
];

export const TransactionStatuses = [
  { text: "Pending", value: "pending", color: "#FAE7A6" },
  { text: "Processing", value: "processing", color: "#C8D8FF" },
  { text: "Success", value: "success", color: "#A6FAAF" },
  { text: "Failed", value: "failure", color: "#FAA6A6" },
  { text: "Refunded", value: "refunded", color: "#FAA6A6" },
];

const nowMoment = moment();
export const OrderDates = [
  { text: "Today", value: nowMoment.format("YYYY-MM-DD") },
  { text: "Yesterday", value: nowMoment.subtract(1, "day").format("YYYY-MM-DD") },
  { text: "Past Week", value: nowMoment.subtract(1, "week").format("YYYY-MM-DD") },
  { text: "Past Month", value: nowMoment.subtract(1, "month").format("YYYY-MM-DD") },
  { text: "Past Year", value: nowMoment.subtract(1, "year").format("YYYY-MM-DD") },
];

const minDistance = 1000;
// const maxPrice = 1e8;

const FilterOrders = ({
  queryFilters = {},
  onOrderStatusChange = () => {},
  onPaymentStatusChange = () => {},
  onPriceRangeChange = () => {},
  onItemsChange = () => {},
  onTagChange = () => {},
  onOrderDateChange = () => {},
  onLocationChange = () => {},
  onVendorChange = () => {},
  onResetAllFilters = () => {},
}) => {
  const { data: allStateData } = useGetAllStateQuery({ pageNo: 1, pageSize: 1e9 });
  const allStates = allStateData?.data?.data ?? [];

  const { data: allVendorData } = useGetAllVendorsQuery({ pageNo: 1, pageSize: 1e9 });
  const allVendors = allVendorData?.data?.data ?? [];

  const { data: tagsData } = useGetAllTagsQuery({ pageNo: 1, pageSize: 1e9 });
  const allTags = tagsData?.data?.data ?? [];

  const { data: orderRangeData } = useGetOrderRangeQuery();
  const [minPrice, maxPrice] = useMemo(() => {
    const { highestPrice = 0, lowestPrice = 0 } = orderRangeData?.data ?? {};
    return [
      Math.floor(lowestPrice / minDistance) * minDistance,
      Math.ceil(highestPrice / minDistance) * minDistance,
    ];
  }, [orderRangeData]);

  // ? FILTER DRAWER STARTS HERE
  const [showDrawer, setShowDrawer] = useState(false);
  const openDrawer = () => setShowDrawer(true);
  const closeDrawer = () => setShowDrawer(false);
  // ? FILTER DRAWER ENDS HERE
  // ? FILTER ACCORDIAN STARTS HERE
  const [expanded, setExpanded] = useState("");
  const handleAccordianChange = (panel) => (e, newExpanded) =>
    setExpanded(newExpanded ? panel : "");
  // ? FILTER ACCORDIAN ENDS HERE

  const handleOrderStatusChange = (event) => {
    const { value, checked } = event.target;
    onOrderStatusChange(
      checked
        ? queryFilters.status.concat(value)
        : queryFilters.status.filter((v) => v !== value)
    );
  };

  const handlePaymentStatusChange = (event) => {
    const { value, checked } = event.target;
    onPaymentStatusChange(
      checked
        ? queryFilters.paymentStatus.concat(value)
        : queryFilters.paymentStatus.filter((v) => v !== value)
    );
  };

  const handlePriceRangeChange = (e, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) return;
    onPriceRangeChange(
      Math.max(Math.min(newValue[0], queryFilters.maxPrice - minDistance), 0),
      Math.max(newValue[1], queryFilters.minPrice + minDistance)
    );
  };

  const handleItemsChange = (e) => {
    const val = e.target.value;
    if (!val) return onItemsChange(val);
    const num = Number(val);
    if (!isNaN(num)) onItemsChange(Math.max(num, 0));
  };

  const handleTagChange = (e, tag) => onTagChange(tag?.name ?? "");

  const handleOrderDateChange = (event) => {
    const { value, checked } = event.target;
    onOrderDateChange(checked ? value : "");
  };

  const handleLocationChange = (e, val) => onLocationChange(val?.name ?? "");
  const handleVendorChange = (e, val) => onVendorChange(val ?? null);

  return (
    <>
      <button
        onClick={openDrawer}
        className="button-grey py-2 px-3">
        <small className="text-lightBlue">More Filters</small>
        <img
          src={filter}
          alt="filter"
          className="ms-2"
        />
      </button>
      <SwipeableDrawer
        anchor="right"
        open={showDrawer}
        onOpen={openDrawer}
        onClose={closeDrawer}>
        <div className="d-flex justify-content-between py-3 px-3 ms-2 me-1">
          <h6 className="text-lightBlue">Filters</h6>
          <img
            src={cancel}
            alt="cancel"
            className="c-pointer filter-icon"
            onClick={closeDrawer}
          />
        </div>

        <div className="px-2">
          {queryFilters.tabIndex < 2 && (
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleAccordianChange("panel1")}>
              <AccordionSummary>
                <p className="text-lightBlue">Order Status</p>
              </AccordionSummary>
              <AccordionDetails>
                <FormGroup
                  sx={{ color: "#5C6D8E" }}
                  className="tags-checkbox"
                  onChange={handleOrderStatusChange}>
                  {OrderStatuses.map((os) => (
                    <FormControlLabel
                      key={os.value}
                      value={os.value}
                      label={os.text}
                      checked={queryFilters.status.includes(os.value)}
                      control={<Checkbox size="small" />}
                    />
                  ))}
                </FormGroup>
              </AccordionDetails>
            </Accordion>
          )}
          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleAccordianChange("panel2")}>
            <AccordionSummary>
              <p className="text-lightBlue">Payment Status</p>
            </AccordionSummary>
            <AccordionDetails>
              <FormGroup
                sx={{ color: "#5C6D8E" }}
                className="tags-checkbox"
                onChange={handlePaymentStatusChange}>
                {PaymentStatuses.map((ps) => (
                  <FormControlLabel
                    key={ps.value}
                    value={ps.value}
                    label={ps.text}
                    checked={queryFilters.paymentStatus.includes(ps.value)}
                    control={<Checkbox size="small" />}
                  />
                ))}
              </FormGroup>
            </AccordionDetails>
          </Accordion>
          {/* <Accordion
            expanded={expanded === "panel3"}
            onChange={handleAccordianChange("panel3")}>
            <AccordionSummary
              aria-controls="panel4d-content"
              id="panel4d-header">
              <p className="text-lightBlue">Delivery Status</p>
            </AccordionSummary>
            <AccordionDetails>
              <FormGroup className="tags-checkbox">
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={queryFilterState.deliveryStatus?.includes("processing")}
                      onChange={(e) => onDeliveryStatusChange("processing")}
                      style={{
                        color: "#5C6D8E",
                        marginRight: 0,
                      }}
                    />
                  }
                  label="Processing"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={queryFilterState.deliveryStatus?.includes("complete")}
                      onChange={(e) => onDeliveryStatusChange("complete")}
                      style={{
                        color: "#5C6D8E",
                        marginRight: 0,
                      }}
                    />
                  }
                  label="Complete"
                />
              </FormGroup>
              <small className="text-grey-6 mt-2 c-pointer">Clear All</small>
            </AccordionDetails>
          </Accordion> */}
          <Accordion
            expanded={expanded === "panel4"}
            onChange={handleAccordianChange("panel4")}>
            <AccordionSummary>
              <p className="text-lightBlue">Amount Spent</p>
            </AccordionSummary>
            <AccordionDetails>
              <div className="row text-lightBlue text-center">
                <div className="col-2">
                  <span>{queryFilters.minPrice}</span>
                </div>
                <div className="col-8">
                  <Slider
                    step={minDistance}
                    value={[queryFilters.minPrice, queryFilters.maxPrice]}
                    min={minPrice}
                    max={maxPrice}
                    onChange={handlePriceRangeChange}
                    valueLabelDisplay="auto"
                    getAriaValueText={(v) => v}
                    disableSwap
                  />
                </div>
                <div className="col-2">
                  <span>{queryFilters.maxPrice}</span>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel5"}
            onChange={handleAccordianChange("panel5")}>
            <AccordionSummary>
              <p className="text-lightBlue">No. of Items in Order</p>
            </AccordionSummary>
            <AccordionDetails>
              <FormControl className="w-100 px-0">
                <OutlinedInput
                  size="small"
                  type="number"
                  placeholder="Enter Value"
                  value={queryFilters.noofItem}
                  onChange={handleItemsChange}
                />
              </FormControl>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel6"}
            onChange={handleAccordianChange("panel6")}>
            <AccordionSummary>
              <p className="text-lightBlue">Tagged With</p>
            </AccordionSummary>
            <AccordionDetails>
              <Autocomplete
                freeSolo
                size="small"
                options={allTags}
                getOptionLabel={(option) => option.name ?? option}
                onChange={handleTagChange}
                value={queryFilters.tagManager}
                renderOption={(props, option) => (
                  <li {...props}>
                    <small className="text-lightBlue my-1">{option.name}</small>
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Search"
                    inputRef={(input) => input?.focus()}
                  />
                )}
              />
            </AccordionDetails>
          </Accordion>
          {queryFilters.tabIndex !== 1 && (
            <Accordion
              expanded={expanded === "panel7"}
              onChange={handleAccordianChange("panel7")}>
              <AccordionSummary
                aria-controls="panel5d-content"
                id="panel5d-header">
                <p className="text-lightBlue">Date of Order</p>
              </AccordionSummary>
              <AccordionDetails>
                <FormGroup
                  sx={{ color: "#5C6D8E" }}
                  className="tags-checkbox"
                  onChange={handleOrderDateChange}>
                  {OrderDates.map((od) => (
                    <FormControlLabel
                      key={od.value}
                      value={od.value}
                      label={od.text}
                      checked={queryFilters.orderDate === od.value}
                      control={<Checkbox size="small" />}
                    />
                  ))}
                </FormGroup>
              </AccordionDetails>
            </Accordion>
          )}
          {/* <Accordion
            expanded={expanded === "panel8"}
            onChange={handleAccordianChange("panel8")}>
            <AccordionSummary
              aria-controls="panel6d-content"
              id="panel6d-header">
              <p className="text-lightBlue">Abandoned Checkouts</p>
            </AccordionSummary>
            <AccordionDetails>
              <FormGroup className="tags-checkbox">
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={queryFilterState.checkoutDate?.includes("0d")}
                      onChange={() => onCheckoutDateChange("0d")}
                      style={{
                        color: "#5C6D8E",
                        marginRight: 0,
                      }}
                    />
                  }
                  label="Today"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={queryFilterState.checkoutDate?.includes("1d")}
                      onChange={() => onCheckoutDateChange("1d")}
                      style={{
                        color: "#5C6D8E",
                        marginRight: 0,
                      }}
                    />
                  }
                  label="Yesterday"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={queryFilterState.checkoutDate?.includes("3d")}
                      onChange={() => onCheckoutDateChange("3d")}
                      style={{
                        color: "#5C6D8E",
                        marginRight: 0,
                      }}
                    />
                  }
                  label="3 days ago"
                />
              </FormGroup>
              <small className="text-blue-gradient ps-3 ms-2 pt-2 fw-500 c-pointer">
                Custom Date
              </small>
            </AccordionDetails>
          </Accordion> */}
          <Accordion
            expanded={expanded === "panel9"}
            onChange={handleAccordianChange("panel9")}>
            <AccordionSummary>
              <p className="text-lightBlue">Destination</p>
            </AccordionSummary>
            <AccordionDetails>
              <Autocomplete
                freeSolo
                size="small"
                options={allStates}
                getOptionLabel={(option) => option.name ?? option}
                onChange={handleLocationChange}
                value={queryFilters.location}
                renderOption={(props, option) => (
                  <li {...props}>
                    <small className="text-lightBlue my-1">{option.name}</small>
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Search"
                    inputRef={(input) => input?.focus()}
                  />
                )}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel10"}
            onChange={handleAccordianChange("panel10")}>
            <AccordionSummary>
              <p className="text-lightBlue">Vendor</p>
            </AccordionSummary>
            <AccordionDetails>
              <Autocomplete
                freeSolo
                size="small"
                options={allVendors}
                getOptionLabel={(option) => option.name ?? option}
                onChange={handleVendorChange}
                value={queryFilters.vendor?.name ?? ""}
                renderOption={(props, option) => (
                  <li {...props}>
                    <small className="text-lightBlue my-1">{option.name}</small>
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Search"
                    inputRef={(input) => input?.focus()}
                  />
                )}
              />
            </AccordionDetails>
          </Accordion>
        </div>

        <div className="d-flex flex-column py-3 px-4 filter-buttons">
          <hr className="hr-grey-6 my-3 w-100" />
          <div className="d-flex justify-content-between">
            <button
              onClick={onResetAllFilters}
              className="button-lightBlue-outline py-2 px-3">
              <p>Clear all Filters</p>
            </button>
            <button
              onClick={closeDrawer}
              className="button-gradient py-2 px-5 w-auto">
              <p>Done</p>
            </button>
          </div>
        </div>
      </SwipeableDrawer>
    </>
  );
};

export default FilterOrders;
