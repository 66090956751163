import { forwardRef, useMemo } from "react";
import { useDispatch } from "react-redux";
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  OutlinedInput,
  RadioGroup,
  Slide,
} from "@mui/material";
import { showError, showSuccess } from "../../../features/snackbar/snackbarAction";
import cancel from "../../../assets/icons/cancel.svg";
import { LoadingButton } from "@mui/lab";
import uploadLineSheet from "../../../assets/images/products/uploadLineSheet.svg";
import { useDropzone } from "react-dropzone";

const Transition = forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="up"
      ref={ref}
      {...props}
    />
  );
});

// ? FILE UPLOAD STARTS HERE
const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "0",
  borderWidth: 2,
  borderRadius: 8,
  borderColor: "#38395c",
  borderStyle: "dashed",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
  cursor: "pointer",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};
// ? FILE UPLOAD ENDS HERE

export default function ImportDialog({
  title = "",
  show = false,
  onClose = () => {},
  onAction = () => {},
}) {
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    accept: { "text/csv": [".csv"] },
    multiple: false,
    onDrop: ([file]) => {
      onAction(file);
    },
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  return (
    <Dialog
      open={show}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      maxWidth="sm"
      fullWidth>
      <DialogTitle>
        <div className="d-flex justify-content-between align-items-center">
          <h5 className="text-lightBlue fw-500">Import {title}</h5>
          <img
            src={cancel}
            alt="cancel"
            width={30}
            onClick={onClose}
            className="c-pointer"
          />
        </div>
      </DialogTitle>
      <hr className="hr-grey-6 my-0" />
      <DialogContent className="py-2 px-4">
        <div className="d-flex flex-column">
          <small className="text-grey-6"> Note :</small>
          <small className="text-grey-6">
            1. Upload the skeleton file from the Export Option with the Price Master Data.
          </small>
          <small className="text-grey-6">
            2. You can watch the Tutorial on how to do it.
            <span className="text-blue-gradient c-pointer">Watch here</span>
          </small>
          <small className="text-grey-6">
            3. Do not upload more than 100 {title.toLowerCase()} at a time.
          </small>
          <small className="text-grey-6">
            4. Select the folder containing {title.toLowerCase()} Images with{" "}
            {title.toLowerCase()} folder name equal to SKU
          </small>
          <small className="text-grey-6">5. {title} should be uploaded successfully.</small>
          <div {...getRootProps({ style })}>
            <input {...getInputProps()} />
            <img
              src={uploadLineSheet}
              className="w-100"
              alt="upload-sheet"
            />
          </div>
          <small className="mt-2 text-lightBlue mb-2">
            Please make sure to leave a single row at the top of the Sheet
          </small>
        </div>
      </DialogContent>
      <hr className="hr-grey-6 my-0" />
      <DialogActions className="d-flex justify-content-between px-4 py-3">
        <button
          className="button-grey py-2 px-5"
          onClick={onClose}>
          <p className="text-lightBlue">Done</p>
        </button>
        {/* <button
          className="button-gradient py-2 px-5"
          onClick={onClose}>
          <p>Continue</p>
        </button> */}
      </DialogActions>
    </Dialog>
  );
}
