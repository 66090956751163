import apiSlice from "../../../app/api/apiSlice";

export const categoriesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllCategories: builder.query({
      query: (queries) => {
        let queryString = "";
        for (const key in queries) {
          if (queries[key] !== undefined && queries[key] !== null && queries[key] !== "") {
            queryString = `${queryString}${queryString ? "&" : "?"}${key}=${queries[key]}`;
          }
        }
        return {
          url: `/parameters/category${queryString}`,
        };
      },
      providesTags: ["Categories"],
    }),
    getAllSubCategories: builder.query({
      query: (queries) => {
        let queryString = "";
        for (const key in queries) {
          if (queries[key] !== undefined && queries[key] !== null && queries[key] !== "") {
            queryString = `${queryString}${queryString ? "&" : "?"}${key}=${queries[key]}`;
          }
        }
        return {
          url: `/parameters/subCategory${queryString}`,
        };
      },
      providesTags: ["SubCategories"],
    }),
    createCategory: builder.mutation({
      query: (categoryDetails) => ({
        url: "/parameters/category",
        method: "POST",
        body: categoryDetails,
      }),
      invalidatesTags: [
        "PriceMaster",
        "PriceManager",
        "Categories",
        "SubCategories",
        "CategoriesCount",
        "SubCategoriesCount",
        "Logs",
      ],
    }),
    categoryBulkCreateTag: builder.mutation({
      query: (tagsDetails) => ({
        url: "/parameters/category/bulkCreate",
        method: "POST",
        body: tagsDetails,
      }),
      invalidatesTags: [
        "PriceMaster",
        "PriceManager",
        "Categories",
        "SubCategories",
        "CategoriesCount",
        "SubCategoriesCount",
        "Logs",
      ],
    }),
    subCategoryBulkCreateTag: builder.mutation({
      query: (tagsDetails) => ({
        url: "/parameters/subCategory/bulkCreate",
        method: "POST",
        body: tagsDetails,
      }),
      invalidatesTags: [
        "PriceMaster",
        "Logs",
        "PriceManager",
        "Categories",
        "SubCategories",
        "CategoriesCount",
        "SubCategoriesCount",
      ],
    }),
    createSubCategory: builder.mutation({
      query: (subCategoryDetails) => ({
        url: "/parameters/subCategory",
        method: "POST",
        body: subCategoryDetails,
      }),
      invalidatesTags: [
        "PriceMaster",
        "Logs",
        "PriceManager",
        "Categories",
        "SubCategories",
        "CategoriesCount",
        "SubCategoriesCount",
      ],
    }),
    deleteCategory: builder.mutation({
      query: (categoryId) => ({
        url: `/parameters/category/hardDelete/${categoryId}`,
        method: "DELETE",
        body: categoryId,
      }),
      invalidatesTags: [
        "PriceMaster",
        "PriceManager",
        "Categories",
        "SubCategories",
        "CategoriesCount",
        "SubCategoriesCount",
        "Logs",
      ],
    }),
    bulkDeleteCategory: builder.mutation({
      query: (details) => ({
        url: `/parameters/category/bulkDelete`,
        method: "DELETE",
        body: details,
      }),
      invalidatesTags: [
        "PriceMaster",
        "PriceManager",
        "Categories",
        "SubCategories",
        "CategoriesCount",
        "SubCategoriesCount",
        "Logs",
      ],
    }),
    bulkDeleteSubCategory: builder.mutation({
      query: (details) => ({
        url: `/parameters/subCategory/bulkDelete`,
        method: "DELETE",
        body: details,
      }),
      invalidatesTags: [
        "PriceMaster",
        "PriceManager",
        "Categories",
        "SubCategories",
        "CategoriesCount",
        "SubCategoriesCount",
        "Logs",
      ],
    }),
    deleteSubCategory: builder.mutation({
      query: (subCategoryId) => ({
        url: `/parameters/subCategory/hardDelete/${subCategoryId}`,
        method: "DELETE",
        body: subCategoryId,
      }),
      invalidatesTags: [
        "PriceMaster",
        "PriceManager",
        "Categories",
        "SubCategories",
        "CategoriesCount",
        "SubCategoriesCount",
        "Logs",
      ],
    }),
    editCategory: builder.mutation({
      query: ({ id, details }) => ({
        url: `/parameters/category/${id}`,
        method: "PUT",
        body: details,
      }),
      invalidatesTags: [
        "PriceMaster",
        "PriceManager",
        "Categories",
        "SubCategories",
        "CategoriesCount",
        "SubCategoriesCount",
        "Logs",
      ],
    }),
    editSubCategory: builder.mutation({
      query: ({ id, details }) => ({
        url: `/parameters/subCategory/${id}`,
        method: "PUT",
        body: details,
      }),
      invalidatesTags: [
        "PriceMaster",
        "Logs",
        "PriceManager",
        "Categories",
        "SubCategories",
        "CategoriesCount",
        "SubCategoriesCount",
      ],
    }),
    bulkEditTagCategory: builder.mutation({
      query: (updates) => ({
        url: `/parameters/category/bulkUpdate`,
        method: "PUT",
        body: updates,
      }),
      invalidatesTags: [
        "PriceMaster",
        "PriceManager",
        "Categories",
        "SubCategories",
        "CategoriesCount",
        "SubCategoriesCount",
        "Logs",
      ],
    }),
    bulkEditTagSubCategory: builder.mutation({
      query: (updates) => ({
        url: `/parameters/subCategory/bulkUpdate`,
        method: "PUT",
        body: updates,
      }),
      invalidatesTags: [
        "PriceMaster",
        "PriceManager",
        "Categories",
        "SubCategories",
        "CategoriesCount",
        "SubCategoriesCount",
        "Logs",
      ],
    }),
    getAllCategorieStatusCount: builder.query({
      query: (queries) => {
        let queryString = "";
        for (const key in queries) {
          if (queries[key]) {
            queryString = `${queryString}${queryString ? "&" : "?"}${key}=${queries[key]}`;
          }
        }
        return {
          url: `/parameters/category/count${queryString}`,
        };
      },
      providesTags: ["CategoriesCount"],
    }),
    getAllSubCategorieStatusCount: builder.query({
      query: (queries) => {
        let queryString = "";
        for (const key in queries) {
          if (queries[key]) {
            queryString = `${queryString}${queryString ? "&" : "?"}${key}=${queries[key]}`;
          }
        }
        return {
          url: `/parameters/subCategory/count${queryString}`,
        };
      },
      providesTags: ["SubCategoriesCount"],
    }),
  }),
});

export const {
  useGetAllCategoriesQuery,
  useGetAllSubCategoriesQuery,
  useCreateCategoryMutation,
  useCreateSubCategoryMutation,
  useDeleteCategoryMutation,
  useDeleteSubCategoryMutation,
  useEditCategoryMutation,
  useEditSubCategoryMutation,
  useCategoryBulkCreateTagMutation,
  useSubCategoryBulkCreateTagMutation,
  useBulkEditTagCategoryMutation,
  useBulkEditTagSubCategoryMutation,
  useBulkDeleteCategoryMutation,
  useBulkDeleteSubCategoryMutation,
  useGetAllCategorieStatusCountQuery,
  useGetAllSubCategorieStatusCountQuery,
} = categoriesApiSlice;
