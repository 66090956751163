import { useCallback, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import _ from "lodash";
import { useFormik } from "formik";
// ! COMPONENT IMPORTS
import AddProductCondition from "../../../components/AddProductCondition/AddProductCondition";
import AppTextEditor from "../../../components/AppTextEditor/AppTextEditor";
// ! IMAGES IMPORTS
import arrowLeft from "../../../assets/icons/arrowLeft.svg";
import info from "../../../assets/icons/info.svg";
// import sizeTape from "../../../assets/icons/sizeTape.svg";
// import sizeTable from "../../../assets/icons/sizeTable.svg";
import makeSize from "../../../assets/icons/makeSize.svg";
import imageAdd from "../../../assets/icons/imageAdd.svg";
// import uploadSizeChart from "../../../assets/icons/uploadSizeChart.svg";
// ! MATERIAL IMPORTS
import {
  FormControl,
  FormHelperText,
  Tooltip,
  ToggleButton,
  ToggleButtonGroup,
  OutlinedInput,
} from "@mui/material";
// ! MATERIAL ICONS IMPORTS
// import CloseIcon from "@mui/icons-material/Close";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { LoadingButton } from "@mui/lab";
import { DiscardModalSecondary } from "../../../components/Discard/DiscardModal";
import {
  useEditSizeChartMutation,
  useGetAllSizeChartsQuery,
} from "../../../features/functionality/sizeChart/sizeChartApiSlice";
import {
  showError,
  showSuccess,
} from "../../../features/snackbar/snackbarAction";
import { UploadMediaLarge } from "../../../components/UploadMediaBox/UploadMedia";

const filterSchema = Yup.object().shape({
  type: Yup.string().required("Type is required"),
  operator: Yup.string().when(["type"], ([type], schema) => {
    return type === "allProducts" ? schema : schema.required("required");
  }),
  value: Yup.array().when(["type"], ([type], schema) => {
    return type === "allProducts"
      ? schema
      : schema.required("required").min(1, "At least one value is required");
  }),
});

const validationSchema = Yup.object({
  name: Yup.string().trim().required("required"),
  status: Yup.string()
    .oneOf(["scheduled", "active", "in-active"])
    .required("required"),
  sizeChartFor: Yup.string().oneOf(["Desktop", "Mobile"]).required("required"),
  sizeChartType: Yup.string()
    .oneOf(["image", "sizeChartOnCompany"])
    .required("required"),
  sizeChartImage: Yup.string().when("sizeChartType", {
    is: "image",
    then: (schema) => schema.required("required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  sizeChartOnCompany: Yup.string().when("sizeChartType", {
    is: "sizeChartOnCompany",
    then: (schema) => schema.required("required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  startDate: Yup.date().when("status", {
    is: "scheduled",
    then: (schema) => schema.required("required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  endDate: Yup.date().when(
    ["status", "startDate"],
    ([status, startDate], schema) => {
      if (status === "scheduled" && startDate) {
        return schema.min(
          startDate,
          "End Date must be greater than Start Date"
        );
      }
    }
  ),
  addProductType: Yup.string()
    .oneOf(["automated", "manual"])
    .required("required"),
  products: Yup.array().of(Yup.string()), //.min(1, "Min 1 Product Required"),
  filters: Yup.mixed().when(["addProductType"], ([addProductType], schema) => {
    if (addProductType === "automated") {
      return Yup.array().of(filterSchema);
    }
    return schema;
  }),
});

const EditSizeChart = () => {
  const { sid = "" } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [discardable, setDiscardable] = useState(false);
  const onDiscarded = () => navigate(-1);

  const [editSizeChart, { isLoading: editSizeChartIsLoading }] =
    useEditSizeChartMutation();
  const { data: sizeChartData, isSuccess: sizeChartIsSuccess } =
    useGetAllSizeChartsQuery({
      id: sid,
    });

  const {
    name = "",
    status = "active",
    sizeChartFor = "Desktop",
    sizeChartType = "image",
    sizeChartImage = "",
    sizeChartOnCompany = "",
    addProductType = "automated",
    startDate = "",
    endDate = "",
    products = [],
    filters,
  } = sizeChartData?.data?.data?.[0] ?? {};

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name,
      status,
      sizeChartFor,
      sizeChartType,
      sizeChartImage,
      sizeChartOnCompany,
      addProductType,
      startDate,
      endDate,
      products,
      filters: filters || [
        {
          type: "",
          operator: "",
          value: [],
          dropDownData: [],
        },
      ],
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (values.sizeChartType === "image") delete values["sizeChartOnCompany"];
      if (values.sizeChartType === "sizeChartOnCompany")
        delete values["sizeChartImage"];
      editSizeChart({ id: sid, details: values })
        .unwrap()
        .then(() => {
          setDiscardable(false);
          onDiscarded();
          dispatch(showSuccess({ message: "Size Chart updated successfully" }));
        })
        .catch((e) =>
          dispatch(
            showError({ message: e?.data?.message ?? "Something went wrong" })
          )
        );
    },
  });

  // console.log(
  //   JSON.stringify(
  //     {
  //       values: formik.values,
  //       errors: formik.errors,
  //       touched: formik.touched,
  //     },
  //     null,
  //     1
  //   )
  // );

  useEffect(() => {
    const check = _.isEqual(formik.values, formik.initialValues);
    setDiscardable(!check);
  }, [formik.initialValues, formik.values]);

  const uploadChartImage = useCallback(
    (value) => {
      formik.setFieldTouched("sizeChartImage", true);
      !!value && formik.setFieldValue("sizeChartImage", value ?? "");
    },
    // eslint-disable-next-line
    []
  );

  return (
    <div className="page container-fluid position-relative">
      <div className="row justify-content-between">
        <div className="d-flex align-items-center w-auto ps-0">
          <Link to="/functionality/sizeChart" className="d-flex">
            <img
              src={arrowLeft}
              alt="arrowLeft"
              width={9}
              className="c-pointer"
            />
          </Link>
          <h5 className="page-heading ms-2 ps-1">Edit Size Chart</h5>
        </div>
      </div>
      {sizeChartIsSuccess && (
        <form noValidate onSubmit={formik.handleSubmit}>
          <div className="row">
            <div className="col-lg-9 mt-3">
              <div className="bg-black-15 border-grey-5 rounded-8 p-3 row productInfo">
                <div className="col-12 px-0">
                  <div className="row">
                    <div className="col-8">
                      <div className="d-flex mb-1">
                        <p className="text-lightBlue px-0 me-2">
                          Size Chart Name
                        </p>
                        <Tooltip title="Lorem ipsum" placement="top">
                          <img
                            src={info}
                            alt="info"
                            className="c-pointer"
                            width={13.5}
                          />
                        </Tooltip>
                      </div>
                      <FormControl className="w-100 px-0">
                        <OutlinedInput
                          placeholder="Enter Size Chart Name"
                          size="small"
                          name="name"
                          value={formik.values.name}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        <FormHelperText error>
                          {formik.touched.name && formik.errors.name}
                        </FormHelperText>
                      </FormControl>
                    </div>
                    <div className="col-4">
                      <div className="d-flex mb-1">
                        <p className="text-lightBlue me-2">Status</p>
                        <Tooltip title="Lorem ipsum" placement="top">
                          <img
                            src={info}
                            alt="info"
                            className="c-pointer"
                            width={13.5}
                          />
                        </Tooltip>
                      </div>
                      <ToggleButtonGroup
                        value={formik.values.status}
                        onChange={(_, status) => {
                          formik.setFieldTouched("status", true);
                          formik.setFieldValue("status", status);
                        }}
                        aria-label="text formatting"
                        className="row d-flex px-2 productInfo-toggle"
                        size="small"
                        id="status"
                        exclusive
                      >
                        <ToggleButton
                          value="active"
                          aria-label="active"
                          style={{ width: "50%" }}
                          className="productInfo-toggle__active"
                        >
                          <div className="d-flex">
                            <p className="text-grey-6">Active</p>
                          </div>
                        </ToggleButton>
                        <ToggleButton
                          value="in-active"
                          aria-label="in-active"
                          style={{ width: "50%" }}
                          className="productInfo-toggle__draft"
                        >
                          <div className="d-flex">
                            <p className="text-grey-6">In-Active</p>
                          </div>
                        </ToggleButton>
                      </ToggleButtonGroup>
                      <FormHelperText error>
                        {formik.touched.status && formik.errors.status}
                      </FormHelperText>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-black-15 border-grey-5 rounded-8 p-3 row mt-4">
                <div className="col-12 px-0 d-flex align-items-center">
                  <h6 className="text-lightBlue fw-500 me-3">
                    Make Size chart for
                  </h6>
                  <ToggleButtonGroup
                    value={formik.values.sizeChartFor}
                    onChange={(_, sizeChartFor) => {
                      formik.setFieldTouched("sizeChartFor", true);
                      formik.setFieldValue("sizeChartFor", sizeChartFor);
                    }}
                    exclusive
                    aria-label="text alignment"
                    className="productDetails-toggle"
                  >
                    <ToggleButton value="Desktop" aria-label="Desktop">
                      <small className="text-capitalize text-lightBlue">
                        Desktop
                      </small>
                    </ToggleButton>
                    <ToggleButton value="Mobile" aria-label="Mobile">
                      <small className="text-capitalize text-lightBlue">
                        Mobile
                      </small>
                    </ToggleButton>
                  </ToggleButtonGroup>
                  <FormHelperText error>
                    {formik.touched.sizeChartFor && formik.errors.sizeChartFor}
                  </FormHelperText>
                </div>
                <p className="text-lightBlue px-0 me-2 mt-3">Type</p>
                <ToggleButtonGroup
                  exclusive
                  value={formik.values.sizeChartType}
                  onChange={(_, sizeChartType) => {
                    formik.setFieldTouched("sizeChartType", true);
                    formik.setFieldValue("sizeChartType", sizeChartType);
                    formik.setFieldTouched("sizeChartImage", false);
                    formik.setFieldValue("sizeChartImage", "");
                    formik.setFieldTouched("sizeChartOnCompany", false);
                    formik.setFieldValue("sizeChartOnCompany", "");
                  }}
                  aria-label="text alignment"
                  className="productDetails-toggle px-0 mt-2"
                >
                  <ToggleButton value="image" aria-label="addImage">
                    <div className="d-flex">
                      <img src={imageAdd} alt="imageAdd" width={25} />
                      <div className="ms-3 text-start">
                        <p className="text-lightBlue">Image</p>
                        <small className="text-grey-6 d-block">
                          Add Image Label
                        </small>
                      </div>
                    </div>
                  </ToggleButton>
                  <ToggleButton
                    value="sizeChartOnCompany"
                    aria-label="companyLabel"
                  >
                    <div className="d-flex">
                      <img src={makeSize} alt="makeSize" width={25} />

                      <div className="ms-3 text-start">
                        <p className="text-lightBlue">
                          Make Size chart on Company
                        </p>
                        <small className="text-grey-6 d-block">
                          Select company custom present labels
                        </small>
                      </div>
                    </div>
                  </ToggleButton>
                </ToggleButtonGroup>
                <FormHelperText error>
                  {formik.touched.sizeChartType && formik.errors.sizeChartType}
                </FormHelperText>
                {formik.values.sizeChartType === "image" && (
                  <>
                    <p className="text-lightBlue px-0 me-2 mt-3">
                      Upload your Size Chart
                    </p>
                    <UploadMediaLarge
                      fileSrc={formik.values.sizeChartImage}
                      error={formik.errors.sizeChartImage}
                      onUpload={uploadChartImage}
                      onBlur={formik.handleBlur}
                      name="sizeChartImage"
                      isSubmitting={formik.isSubmitting}
                      touched={!!formik.touched.sizeChartImage}
                      isMandatory={true}
                    />
                  </>
                )}
                {formik.values.sizeChartType === "sizeChartOnCompany" && (
                  <>
                    <div className="d-flex justify-content-between align-items-center mb-2 px-0 mt-3">
                      <div className="d-flex align-items-center px-0">
                        <p className="text-lightBlue me-2">Size Chart Table</p>
                        <Tooltip title="Lorem ipsum" placement="top">
                          <img
                            src={info}
                            alt="info"
                            className="c-pointer"
                            width={13.5}
                          />
                        </Tooltip>
                      </div>
                      <div className="hover-back bg-black-20 c-pointer rounded-8 px-2 py-2">
                        <small className="d-block mb-0">
                          Please click on table icon to add a table
                        </small>
                      </div>
                    </div>
                    <div className="col-12 px-0">
                      <AppTextEditor
                        value={formik.values.sizeChartOnCompany}
                        setFieldValue={(v) => {
                          formik.setFieldTouched("sizeChartOnCompany", true);
                          formik.setFieldValue("sizeChartOnCompany", v);
                        }}
                      />
                      <FormHelperText error>
                        {formik.touched.sizeChartOnCompany &&
                          formik.errors.sizeChartOnCompany}
                      </FormHelperText>
                    </div>
                  </>
                )}
              </div>
              <AddProductCondition formik={formik} />
            </div>
            <div className="col-lg-3 mt-3 pe-0 ps-0 ps-lg-3">
              <div className="bg-black-15 border-grey-5 rounded-8 p-3">
                <h6 className="col-12 text-grey-6 mb-3">
                  Preview on {formik.values.sizeChartFor}:
                </h6>
                {formik.values.sizeChartType === "image" &&
                  formik.values.sizeChartImage && (
                    <div className="col-12 mt-3">
                      <div className="d-flex justify-content-center">
                        <img
                          src={formik.values.sizeChartImage}
                          alt="sizechart"
                          className="w-100"
                        />
                      </div>
                    </div>
                  )}
                {/*formik.values.sizeChartType === "sizeChartOnCompany" &&
                  formik.values.description && (
                    <div
                      className="col-12 mt-3 d-flex justify-content-center text-lightBlue ck ck-editor__main"
                      dangerouslySetInnerHTML={{
                        __html: sanitize(formik.values.description),
                      }}
                    />
                    )*/}
              </div>
            </div>
          </div>
          {discardable && (
            <div className="row bottom-buttons pt-5 pb-3 justify-content-between">
              <div className="d-flex w-auto px-0">
                <button
                  type="button"
                  onClick={onDiscarded}
                  className="button-red-outline py-2 px-4"
                >
                  <p>Discard</p>
                </button>
              </div>
              <LoadingButton
                type="submit"
                loading={editSizeChartIsLoading}
                disabled={editSizeChartIsLoading}
                className="button-gradient ms-3 py-2 px-4 w-auto"
              >
                <p>Save</p>
              </LoadingButton>
            </div>
          )}
          <DiscardModalSecondary when={discardable} message="size chart tab" />
        </form>
      )}
    </div>
  );
};

export default EditSizeChart;
