import { useCallback, useReducer, useEffect } from "react";
import { useDispatch } from "react-redux";

import apiSlice from "../../app/api/apiSlice";
import { useGetS3UploadUrlQuery } from "./fileUploadApiSlice";

import { omitEmptyKeys } from "../../utils/helper";

const initialUploadState = {
  data: null,
  isLoading: false,
  isSuccess: false,
  isError: false,
  error: null,
  fileName: null,
  fileSize: 0,
  fileType: null,
  mimeType: null,
  module: null,
  folderId: null,
  isMandatory: false,
  file: null,
  skip: true,
};

const uploadReducer = (state, action) => {
  if (action.type === "GET_URL") {
    const mediaDetails = omitEmptyKeys(action);
    return {
      ...initialUploadState,
      ...mediaDetails,
      isLoading: true,
      skip: false,
    };
  }
  if (action.type === "GET_URL_ERROR") {
    return {
      ...initialUploadState,
      isError: action.isError,
      error: action.error,
    };
  }
  if (action.type === "UPLOAD_ERROR") {
    return {
      ...initialUploadState,
      isError: action.isError,
      error: action.error,
    };
  }
  if (action.type === "UPLOAD_SUCCESS") {
    return {
      ...initialUploadState,
      data: { url: action.url },
      isSuccess: true,
    };
  }
  return initialUploadState;
};

const UseFileUpload = () => {
  const dispatch = useDispatch();
  const [uploadState, dispatchUpload] = useReducer(
    uploadReducer,
    initialUploadState
  );
  const {
    data: uploadUrlData,
    isSuccess: uploadUrlIsSuccess,
    error: uploadUrlError,
  } = useGetS3UploadUrlQuery(
    {
      filename: uploadState.fileName,
      filesize: uploadState.fileSize,
      mimetype: uploadState.mimeType,
      filetype: uploadState.fileType,
      module: uploadState.module,
      folderid: uploadState.folderId,
      ismandatory: uploadState.isMandatory,
    },
    { skip: uploadState.skip }
  );

  const uploadFile = useCallback(
    ({ file, module = "others", folderId, isMandatory }) => {
      dispatchUpload({
        type: "GET_URL",
        fileName: file.name,
        fileSize: file.size,
        mimeType: file.type,
        fileType: file.type.slice(0, file.type.indexOf("/")),
        module,
        folderId,
        isMandatory,
        file,
      });
    },
    []
  );

  useEffect(() => {
    if (uploadUrlError) {
      dispatchUpload({
        type: "GET_URL_ERROR",
        error: "Something went wrong!, please try again",
        isError: true,
      });
    }
    if (uploadUrlData && uploadUrlIsSuccess) {
      const { fields, url } = uploadUrlData.data;
      const fileDetails = new FormData();
      for (const key in fields) {
        if (Object.hasOwnProperty.call(fields, key)) {
          fileDetails.append(key, fields[key]);
        }
      }
      fileDetails.append("file", uploadState.file);
      fetch(url, { method: "POST", body: fileDetails })
        .then(() => {
          dispatchUpload({
            type: "UPLOAD_SUCCESS",
            url: `${url}/${fields.key}`,
          });
        })
        .catch(() => {
          dispatchUpload({
            type: "UPLOAD_ERROR",
            error: "Something went wrong!, please try again",
            isError: true,
          });
        })
        .finally(() => dispatch(apiSlice.util.invalidateTags(["FileManager"])));
    }
  }, [
    uploadUrlData,
    uploadUrlIsSuccess,
    uploadUrlError,
    uploadState,
    dispatch,
  ]);

  return [uploadFile, uploadState];
};

export default UseFileUpload;
