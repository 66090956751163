import { useState, useMemo } from "react";
// ! IMAGES IMPORTS
import timelineIcon from "../../assets/icons/timelineIcon.svg";
// import timelineTag from "../../assets/icons/timelineTag.svg";
// import timelineMicrophone from "../../assets/icons/timelineMicrophone.svg";
// import timelineAttachment from "../../assets/icons/timelineAttachment.svg";
// import timelineSmile from "../../assets/icons/timelineSmile.svg";
// ! MATERIAL IMPORTS
import {
  FormControlLabel,
  Checkbox,
  FormControl,
  OutlinedInput,
  TablePagination,
} from "@mui/material";
import {
  useCreateTimelineMutation,
  useGetTimelineQuery,
} from "../../features/orders/timelineApiSlice";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { showError, showSuccess } from "../../features/snackbar/snackbarAction";
import moment from "moment/moment";
import TableLoader from "../../components/Loader/TableLoader";

const initialValues = {
  orderId: "",
  activity: "Custom",
  message: "",
  notifyCustomer: false,
};

const validationSchema = Yup.object({
  orderId: Yup.string().required("required"),
  activity: Yup.string().required("required"),
  message: Yup.string().required("required"),
  notifyCustomer: Yup.boolean().default(false),
});

const OrderTimelines = ({ oid = "" }) => {
  const dispatch = useDispatch();

  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [createTimeline, { isLoading: createTimelineIsLoading }] = useCreateTimelineMutation();

  const { data: timelineData, isFetching: timelineIsFetching } = useGetTimelineQuery(
    { oid, queries: { pageNo, pageSize } },
    {
      skip: !oid,
    }
  );

  // console.log(timelineData);

  const [timelines, count] = useMemo(() => {
    const { data = [], totalCount = 0 } = timelineData?.data ?? {};
    return [data, totalCount];
  }, [timelineData]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...initialValues, orderId: oid },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      // console.log(values);
      createTimeline(values)
        .unwrap()
        .then(() => {
          resetForm();
          dispatch(showSuccess({ message: "Timeline Posted Succesfully" }));
        })
        .catch((e) => {
          console.log(e);
          dispatch(
            showError({ message: e?.data?.message ?? e?.message ?? "Something went wrong" })
          );
        });
    },
  });

  return (
    <div className="bg-black-15 border-grey-5 rounded-8 p-3 row mt-4">
      <div className="d-flex justify-content-between align-items-center col-12 px-0">
        <div className="d-flex align-items-center">
          <img
            src={timelineIcon}
            alt="userIcon"
            width={16}
          />
          <h6 className="text-lightBlue fw-500 ms-2">Timelines</h6>
        </div>
      </div>

      <div className="d-flex justify-content-center col-12 px-0">
        <hr className="hr-grey-6 w-100 mt-3 mb-0" />
      </div>
      <form
        noValidate
        onSubmit={formik.handleSubmit}
        className="d-flex justify-content-center col-12 px-0 mt-3 align-items-start">
        <div className="d-flex flex-column w-100">
          <FormControl className="w-100 px-0">
            <OutlinedInput
              placeholder="Write your message here..."
              size="small"
              name="message"
              value={formik.values.message}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </FormControl>
          <FormControlLabel
            label="Send Notification to Customer"
            onChange={(_, val) => formik.setFieldValue("notifyCustomer", val)}
            control={
              <Checkbox
                size="small"
                checked={formik.values.notifyCustomer}
                style={{
                  color: "#5C6D8E",
                  marginRight: 0,
                  width: "auto",
                }}
              />
            }
            sx={{
              "& .MuiTypography-root": {
                fontSize: "0.75rem",
                color: "#c8d8ff",
                // color: "#5c6d8e",
              },
            }}
            className=" px-0"
          />
        </div>
        <button
          type="submit"
          className="button-gradient ms-2 px-4 py-2">
          <p>Post</p>
        </button>
      </form>
      {timelineIsFetching ? (
        <TableLoader />
      ) : (
        <>
          <table className="table table-borderless w-100 mt-3 mb-0 create-table">
            <thead className="">
              <tr className="bg-black-18">
                <th
                  scope="col"
                  className="">
                  <small className="text-lightBlue fw-400">Date</small>
                </th>
                <th
                  scope="col"
                  className="">
                  <small className="text-lightBlue fw-400">Team Member</small>
                </th>
                <th
                  scope="col"
                  className="">
                  <small className="text-lightBlue fw-400">Activity</small>
                </th>
              </tr>
            </thead>
            <tbody>
              {timelines.map((timeline) => (
                <tr key={timeline._id}>
                  <td width={220}>
                    <div className="d-flex flex-column py-2">
                      <p className="text-lightBlue">
                        {moment(timeline.createdAt).format("DD/MM/YYYY [at] hh:mm a")}
                      </p>
                    </div>
                  </td>
                  <td width={180}>
                    <div className="d-flex flex-column py-2">
                      <p className="text-lightBlue">
                        {!timeline.teamMember ? "Automated" : timeline.teamMember.fullName}
                      </p>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex flex-column py-2">
                      <p className="text-lightBlue">{timeline.activity}</p>
                      {!!timeline.message && (
                        <small className="text-lightBlue mt-2 text-green-2">
                          <i className="text-blue-1">{timeline.message}</i>
                        </small>
                      )}
                      {timeline.itemId.length > 0 &&
                        timeline.itemId.map((item) => (
                          <small
                            key={item._id}
                            className="text-lightBlue mt-2 text-green-2">
                            <i className="text-blue-1">
                              {item.item.productName} {item.item.variantName ?? ""}
                            </i>
                          </small>
                        ))}
                      {timeline.notifyCustomer && (
                        <small className="text-lightBlue mt-2 text-green-2">
                          <i className=" text-green-2">Customer Notified</i>
                        </small>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <TablePagination
            rowsPerPageOptions={[10, 20, 30]}
            className="table-pagination mt-2"
            component="div"
            count={count}
            // count={totalCount}
            rowsPerPage={pageSize}
            page={pageNo - 1}
            onPageChange={(_, p) => {
              console.log(p);
              setPageNo(p + 1);
            }}
            onRowsPerPageChange={(e) => {
              console.log(e.target.value);
              setPageSize(Number(e.target.value) || 10);
              setPageNo(1);
            }}
          />
        </>
      )}
    </div>
  );
};

export default OrderTimelines;

/* <img
          src={timelineSmile}
          alt="timelineSmile"
          width={18}
          className="me-2 c-pointer mt-2"
        />
        <img
          src={timelineAttachment}
          alt="timelineAttachment"
          width={18}
          className="me-2 c-pointer mt-2"
        />
        <img
          src={timelineTag}
          alt="timelineTag"
          width={18}
          className="me-2 c-pointer mt-2"
        />
        <img
          src={timelineMicrophone}
          alt="timelineMicrophone"
          width={18}
          className="me-2 c-pointer mt-2"
        /> */
