import msApiSlice from "../../../app/api/msApiSlice";
import { omitEmptyKeys } from "../../../utils/helper";

export const priceMasterApiSlice = msApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    exportPriceMaster: builder.mutation({
      query: (query = {}) => ({
        url: "/priceMaster/export",
        params: omitEmptyKeys(query),
        method: "GET",
      }),
      transformResponse: (res) => res.data,
    }),
    importPriceMaster: builder.mutation({
      query: ({ query = {}, payload }) => ({
        url: "/priceMaster/import",
        params: omitEmptyKeys(query),
        method: "POST",
        body: payload,
      }),
      transformResponse: (res) => res.data,
    }),
  }),
});

export const { useExportPriceMasterMutation, useImportPriceMasterMutation } =
  priceMasterApiSlice;
