import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Chip,
} from "@mui/material";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import {
  SortableContainer,
  SortableHandle,
  SortableElement,
  arrayMove,
} from "react-sortable-hoc";

import TableHeader from "../../../components/TableHeader/TableHeader";
import EditButton from "../../../components/EditButton/EditButton";
import RemoveIconButton from "../../../components/RemoveIconButton/RemoveIconButton";
import DeleteIconButton from "../../../components/DeleteIconButton/DeleteIconButton";
import NoDataFound from "../../../components/NoDataFound/NoDataFound";
import TableLoader from "../../../components/Loader/TableLoader";

const DragHandle = SortableHandle(() => (
  <TableCell>
    <DragIndicatorIcon
      sx={{
        color: "#5c6d8e",
        fontSize: 26,
        cursor: "pointer",
      }}
    />
  </TableCell>
));

const TableBodySortable = SortableContainer(({ children }) => (
  <TableBody>{children}</TableBody>
));

TableBodySortable.muiName = "TableBody";

const SortableRow = SortableElement(({ children }) => children);

const HEAD_CELLS = [
  {
    align: "left",
    disablePadding: false,
    label: "",
    width: "1%",
  },
  {
    align: "left",
    disablePadding: false,
    label: "Field Set Name",
    width: "34%",
    style: {
      paddingLeft: 0,
    },
  },
  {
    align: "left",
    disablePadding: false,
    label: "No. Of Fields",
    width: "50%",
  },
  {
    align: "right",
    disablePadding: false,
    label: "Action",
    width: "15%",
  },
];

const PAGINATION_ROWS = [10, 20, 30];

const ProductTabsTable = (props) => {
  const {
    error,
    isLoading,
    data,
    totalCount,
    onPageChange,
    onPageSize,
    pageSize,
    page,
    onSort,
    onEdit,
    onDelete,
  } = props;

  const onRowsPerPageChange = (e) => {
    onPageSize(e.target.value);
  };

  const sortEndHandler = ({ oldIndex, newIndex }) => {
    onSort(arrayMove(structuredClone(data), oldIndex, newIndex));
  };

  if (error) {
    return <></>;
  }

  if (isLoading) {
    return <TableLoader />;
  }

  if (!data) {
    return <></>;
  }

  if (data && !data.length) {
    return <NoDataFound />;
  }

  return (
    <>
      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          size="medium">
          <TableHeader headCells={HEAD_CELLS} />
          <TableBodySortable
            onSortEnd={sortEndHandler}
            useDragHandle>
            {data.map((item, index) => {
              return (
                <SortableRow
                  index={index}
                  key={item._id}>
                  <TableRow
                    hover
                    tabIndex={-1}
                    className="table-rows">
                    {/* <DragHandle /> */}
                    <TableCell />
                    <TableCell
                      onClick={onEdit.bind(null, index + 1)}
                      sx={{
                        textTransform: "capitalize",
                        cursor: "pointer",
                        paddingLeft: 0,
                      }}>
                      <p className="text-lightBlue fw-600">{item.title}</p>
                    </TableCell>
                    <TableCell>
                      <div className="d-flex flex-wrap align-items-center gap-2">
                        {item.customFields.length ? (
                          item.customFields.map((field) => {
                            return (
                              <Chip
                                key={field._id}
                                label={field.title}
                                size="small"
                              />
                            );
                          })
                        ) : (
                          <></>
                        )}
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="d-flex align-items-center justify-content-end">
                        <EditButton onClick={onEdit.bind(null, index + 1)} />
                        <DeleteIconButton
                          onClick={onDelete.bind(null, {
                            id: item._id,
                            message: `<span className='text-blue-2'>${item.title}</span> product tab`,
                          })}
                          title="Delete"
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                </SortableRow>
              );
            })}
          </TableBodySortable>
        </Table>
      </TableContainer>

      {/* <TablePagination
        rowsPerPageOptions={PAGINATION_ROWS}
        component="div"
        count={totalCount || 0}
        rowsPerPage={pageSize}
        page={page - 1}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        className="table-pagination"
      /> */}
    </>
  );
};

export default ProductTabsTable;
