import {
  Autocomplete,
  FormControl,
  FormHelperText,
  OutlinedInput,
  TextField,
} from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import AppCountrySelect from "../AppCountrySelect/AppCountrySelect";
import AppStateSelect from "../AppStateSelect/AppStateSelect";
import AppCitySelect from "../AddCitySelect/AddCitySelect";
import { useGetPinCodeQuery } from "../../features/master/pincode/pinCodeApiSlice";
import { useGetAddressQuery, useGetPincodeQuery } from "../../features/googlemaps/mapsApiSlice";

const ZipCodeComponent = ({
  onchangeLine1,
  onchangeLine2,
  touchedLine1,
  errorLine1,
  nameLine2,
  valueLine2,
  onchangePincode,
  namePincode,
  zipVal,
  onchangeZip,
  touchedZip,
  errorZip,
  cityVal,
  onchangeCity,
  touchedCity,
  errorCity,
  stateVal,
  onchangeState,
  touchedState,
  errorState,
  countryVal,
  onchangeCountry,
  touchedCountry,
  errorCountry,
  line1Val,
}) => {
  const [pinCode, setPinCode] = useState("");
  useEffect(() => {
    if (zipVal?.length === 6 && !isNaN(Number(zipVal))) {
      setPinCode((prev) => (prev === zipVal ? prev : zipVal));
    }
  }, [zipVal]);

  const handleChangePinCode = useCallback(
    (e) => {
      onchangeZip(e);
    },
    [onchangeZip]
  );

  const [pinCodeData, setPinCodeData] = useState(null);

  const { data: dPinCodeData, isLoading: pinLoading } = useGetPinCodeQuery(
    { pincode: pinCode },
    { skip: pinCode.length !== 6 }
  );

  useEffect(() => {
    setPinCodeData(dPinCodeData);
  }, [dPinCodeData]);

  const [stateSelect, setStateSelect] = useState(null);
  const [citySelect, setCitySelect] = useState(null);
  const [countrySelect, setCountrySelect] = useState(null);

  useEffect(() => {
    if (!pinLoading && pinCodeData?.data?.data?.[0]) {
      const masterData = pinCodeData?.data?.data?.[0];
      const cityData = masterData?.city;
      const stateData = masterData?.state;
      const countryData = masterData?.country;

      if (cityData && cityVal !== cityData._id) {
        onchangeCity(null, cityData);
      }
      setCitySelect((prev) => (prev?._id !== cityData?._id && cityData ? cityData : prev));
      if (stateData && stateVal !== stateData._id) {
        onchangeState(null, stateData);
      }
      setStateSelect((prev) => (prev?._id !== stateData?._id && stateData ? stateData : prev));
      if (countryData && countryVal !== countryData._id) {
        onchangeCountry(null, countryData);
      }
      setCountrySelect((prev) =>
        prev?._id !== countryData?._id && countryData ? countryData : prev
      );
    }
  }, [pinLoading, pinCodeData?.data]);

  const tidRef = useRef(0);
  const [addLine1, setAddLine1] = useState(line1Val);
  const [selAddr, setSelAddr] = useState({ description: line1Val });

  const { data: addrLineData } = useGetAddressQuery({ name: addLine1 }, { skip: !addLine1 });
  const suggestedAddrs = addrLineData?.data ?? [];

  const { data: addrPlaceData } = useGetPincodeQuery(
    { placeId: selAddr?.place_id },
    { skip: !selAddr?.place_id }
  );

  const onLineInputChange = (_, v) => {
    if (v) {
      onchangeLine1(v);
      clearTimeout(tidRef.current);
      tidRef.current = setTimeout(() => {
        setAddLine1(v);
      }, 300);
    }
  };

  const onLineChange = (_, v) => {
    if (v) {
      setSelAddr(v);
      onchangeLine1(v?.description);
      // customerAddressFormik.setFieldValue("line1", v.description);
    }
  };

  useEffect(
    () => {
      const pincode = addrPlaceData?.data?.data?.[0]?.pincode;
      // console.log(addrPlaceData, "pinCodeDataTarget");
      if (pincode) {
        const e = { target: { value: pincode } };
        onchangeZip(e);
      }
      // onchangePincode(pincode);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [addrPlaceData]
  );

  return (
    <>
      <div className="col-md-6 mt-3">
        <p className="text-lightBlue mb-1">
          Address Line 1 <span style={{ color: "#F67476" }}>*</span>
        </p>
        <Autocomplete
          options={suggestedAddrs}
          autoHighlight
          freeSolo
          size="small"
          getOptionLabel={(option) => option?.description}
          onInputChange={onLineInputChange}
          onChange={onLineChange}
          value={selAddr}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Type address"
            />
          )}
        />
        {!!touchedLine1 && errorLine1 && <FormHelperText error>{errorLine1}</FormHelperText>}
      </div>
      <div className="col-md-6 mt-3">
        <p className="text-lightBlue mb-1">Address Line 2</p>
        <FormControl className="w-100 px-0">
          <OutlinedInput
            placeholder="Enter Address Line 2"
            size="small"
            name={nameLine2}
            value={valueLine2}
            onChange={onchangeLine2}
          />
        </FormControl>
      </div>
      <div className="col-md-6 mt-3">
        <p className="text-lightBlue mb-1">
          Zipcode/Postalcode <span style={{ color: "#F67476" }}>*</span>
        </p>
        <FormControl className="w-100 px-0">
          <OutlinedInput
            placeholder="Enter Zipcode/Postalcode"
            size="small"
            // name="pinCode"
            name={namePincode}
            value={zipVal}
            onChange={handleChangePinCode}
          />
        </FormControl>
        {!!touchedZip && errorZip && <FormHelperText error>{errorZip}</FormHelperText>}
      </div>
      <div className="col-md-6 mt-3 add-user-country">
        <p className="text-lightBlue mb-1">
          Country <span style={{ color: "#F67476" }}>*</span>
        </p>
        <AppCountrySelect
          // pincode={pinCode}
          country={countrySelect}
          selectCountryName={onchangeCountry}
          initValue={countryVal}
        />
        {!!touchedCountry && errorCountry && (
          <FormHelperText error>{errorCountry}</FormHelperText>
        )}
      </div>
      <div className="col-md-6 mt-3  add-user-country">
        <div className="d-flex align-items-center justify-content-between">
          <p className="text-lightBlue mb-1">
            State or Region <span style={{ color: "#F67476" }}>*</span>
          </p>
          {/* <small className="text-grey-6 mb-1">(Optional)</small> */}
        </div>
        <AppStateSelect
          getStateName={onchangeState}
          initValue={stateVal}
          state={stateSelect}
        />
        {!!touchedState && errorState && <FormHelperText error>{errorState}</FormHelperText>}
      </div>
      <div className="col-md-6 mt-3 add-user-country">
        <div className="d-flex align-items-center justify-content-between">
          <p className="text-lightBlue mb-1">
            Town/City <span style={{ color: "#F67476" }}>*</span>
          </p>
        </div>
        <AppCitySelect
          // pincode={pinCode}
          selectCityName={onchangeCity}
          initVal={cityVal}
          city={citySelect}
          fullWidth
        />
        {!!touchedCity && errorCity && <FormHelperText error>{errorCity}</FormHelperText>}
      </div>
    </>
  );
};

export default ZipCodeComponent;
