import { useState, useEffect, useReducer, useCallback } from "react";
import { useFormik } from "formik";
import { useNavigate, useParams, useSearchParams, createSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import _ from "lodash";
import SearchIcon from "@mui/icons-material/Search";
import * as Yup from "yup";
import {
  FormControl,
  MenuItem,
  Select,
  InputAdornment,
  OutlinedInput,
  Checkbox,
  FormControlLabel,
  FormGroup,
  styled,
  InputBase,
  Popover,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  SwipeableDrawer,
  RadioGroup,
  Radio,
  Tooltip,
  FormHelperText,
} from "@mui/material";

import { omitEmptyKeys } from "../../../../utils/helper";
import {
  EnhancedTableHead,
  stableSort,
  getComparator,
} from "../../../../components/TableDependencies/TableDependencies";
import { showError, showSuccess } from "../../../../features/snackbar/snackbarAction";
import {
  useGetAllCollectionsQuery,
  useEditCollectionMutation,
  useAddProductsMutation,
} from "../../../../features/parameters/collections/collectionsApiSlice";
import { updateCollectionId } from "../../../../features/parameters/collections/collectionSlice";

import AppTextEditor from "../../../../components/AppTextEditor/AppTextEditor";
import SEO from "../../../Products/AddProduct/SEO/SEO";
import NotesBox from "../../../../components/NotesBox/NotesBox";
import UploadMediaBox from "../../../../components/UploadMediaBox/UploadMediaBox";
import UploadBanner from "../../../../components/UploadBanner/UploadBanner";
import StatusBox, { StatusBoxSecondary } from "../../../../components/StatusBox/StatusBox";
import VisibilityBox from "../../../../components/VisibilityBox/VisibilityBox";
import DuplicateCollection from "../DuplicateCollection/DuplicateCollectionModal";
import { DiscardModalSecondary } from "../../../../components/Discard/DiscardModal";
import { SaveFooterTertiary } from "../../../../components/SaveFooter/SaveFooter";
import InfoHeader from "../../../../components/Header/InfoHeader";

import "../../CreateCollection/CreateCollection.scss";

import info from "../../../../assets/icons/info.svg";
import cancel from "../../../../assets/icons/cancel.svg";
import arrowDown from "../../../../assets/icons/arrowDown.svg";
import featureUpload from "../../../../assets/images/products/featureUpload.svg";
import ringSmall from "../../../../assets/images/ringSmall.svg";
import deleteWhite from "../../../../assets/icons/deleteWhite.svg";
import editWhite from "../../../../assets/icons/editWhite.svg";
import deleteButton from "../../../../assets/icons/deleteButton.svg";
import addMedia from "../../../../assets/icons/addMedia.svg";
import SEOSecondary from "../../../Products/AddProduct/SEO/SEOSecondary";
import { useFormikToastError } from "../../../../hooks/error";
import AddProducts from "../../../../components/AddProducts/AddProducts";
import { useGetAllProductsQuery } from "../../../../features/products/product/productApiSlice";
import AddManualProducts from "../../../../components/AddProducts/AddManualProducts";
import AddLinkedProducts from "../../../../components/AddProducts/AddLinkedProducts";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  "&:hover": {},
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    width: "auto",
  },
  height: "30.6px",
  border: "1px solid #38395c",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 1.5),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(0.8, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    borderRadius: "5px",
  },
}));

function createData(pId, productName, category, price) {
  return { pId, productName, category, price };
}

const rows = [
  createData(1, "The Fringe Diamond Ring", "Gold Products", "₹ 20,600 - ₹ 50,000"),
  createData(2, "The Fringe Diamond Ring", "Gold Products", "₹ 20,600 - ₹ 50,000"),
  createData(3, "The Fringe Diamond Ring", "Gold Products", "₹ 20,600 - ₹ 50,000"),
  createData(4, "The Fringe Diamond Ring", "Gold Products", "₹ 20,600 - ₹ 50,000"),
  createData(5, "The Fringe Diamond Ring", "Gold Products", "₹ 20,600 - ₹ 50,000"),
];

const drawerHeadCells = [
  {
    id: "productName",
    numeric: false,
    disablePadding: true,
    label: "Product Name",
  },
  {
    id: "category",
    numeric: false,
    disablePadding: false,
    label: "Category",
  },
  {
    id: "price",
    numeric: false,
    disablePadding: false,
    label: "Price",
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: "Action",
  },
];

function createLikeProductData(pId, productName, category, price) {
  return { pId, productName, category, price };
}

const likeHeadCells = [
  {
    id: "productName",
    numeric: false,
    disablePadding: true,
    label: "Product Name",
  },
  {
    id: "category",
    numeric: false,
    disablePadding: false,
    label: "Category",
  },
  {
    id: "price",
    numeric: false,
    disablePadding: false,
    label: "Price",
  },
];

const likeProductRows = [
  createLikeProductData(1, "The Fringe Diamond Ring", "Gold Products", "₹ 25,000"),
  createLikeProductData(2, "Fringe Diamond Ring", "Gold Products", "₹ 25,000"),
  createLikeProductData(3, "The Fringe Diamond Ring", "Gold Products", "₹ 25,000"),
];

// const seoValidationSchema = Yup.object().shape({
//   title: Yup.string()
//     .required("Title is required")
//     .max(50, "Title cannot exceed 50 characters"),
//   description: Yup.string().max(500, "Description cannot exceed 500 characters"),
// urlHandle: Yup.string()
//   .matches(/^\S*$/, "URL handle cannot contain white spaces")
//   .test('is-url-handle', 'URL handle is required', (urlHandle) => {
//     if (!urlHandle) {
//       return false;
//     }
//     const parts = urlHandle.split('/');
//     const result = parts.pop();
//     return !!result;
//   })
//   .required('URL handle is required'),
// });

// const collectionValidationSchema = Yup.object({
//   title: Yup.string()
//     .trim()
//     .min(3, "Title must be at least 3 characters")
//     .max(50)
//     .required("Required"),
//   seo: seoValidationSchema,
// });

const collectionValidationSchema = Yup.object({
  title: Yup.string()
    .trim()
    .min(3, "Title must be at least 3 characters")
    .max(50, "Title must be less than 50 characters")
    .required("Required"),
  filter: Yup.boolean().default(false),
  description: Yup.string().trim(),
  status: Yup.string()
    .oneOf(["active", "in-active", "scheduled"], "Must be one of active, in-active, scheduled")
    .required("Required"),
  startDate: Yup.date(),
  endDate: Yup.date(),
  mediaUrl: Yup.string().trim(),
  notes: Yup.string(),
  type: Yup.string()
    .oneOf(["automated", "manual"], "Must be one of automated, manual")
    .required("Required"),
  shouldMatch: Yup.string().trim(),
  conditions: Yup.array().of(
    Yup.object({
      field: Yup.string().trim(),
      operator: Yup.string().oneOf([
        "gt",
        "lt",
        "equal",
        "notEqual",
        "startWith",
        "endWith",
        "contains",
      ]),
      value: Yup.string().trim(),
      itemId: Yup.string().trim(),
    })
  ),
  seo: Yup.object({
    title: Yup.string().required("Required").max(50, "Title cannot exceed 50 characters"),
    description: Yup.string().trim(),
    metaKeywords: Yup.array().of(Yup.string().required("Required")),
  }),
});

const initialQueryFilterState = {
  pageSize: 1,
  pageNo: null,
  totalCount: 0,
};

const initialCollectionInfoState = {
  confirmationMessage: "",
  isEditing: false,
  discard: false,
  initialInfo: null,
};

const queryFilterReducer = (state, action) => {
  if (action.type === "SET_PAGE_NO") {
    return {
      ...state,
      pageNo: +action.pageNo,
    };
  }
  if (action.type === "SET_TOTAL_COUNT") {
    return {
      ...state,
      totalCount: action.totalCount,
    };
  }
  return initialQueryFilterState;
};

const collectionTabReducer = (state, action) => {
  if (action.type === "ENABLE_EDIT") {
    return {
      ...initialCollectionInfoState,
      isEditing: true,
    };
  }
  if (action.type === "DISABLE_EDIT") {
    return {
      ...initialCollectionInfoState,
      isEditing: false,
    };
  }
  if (action.type === "ENABLE_DISCARD") {
    return {
      ...state,
      discard: true,
    };
  }
  if (action.type === "DISABLE_DISCARD") {
    return {
      ...state,
      discard: false,
    };
  }
  return initialCollectionInfoState;
};

const EditCollection = () => {
  let { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("productName");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [field, setField] = useState("price");
  const [operator, setOperator] = useState("equals");
  const [anchorPriceEl, setAnchorPriceEl] = useState(null);
  const [checked, setChecked] = useState(false);
  const [likeMatchRadio, setLikeMatchRadio] = useState("allCondition");
  const [likeProductRadio, setLikeProductRadio] = useState("automated");
  const [likeAddCondition, setLikeAddCondition] = useState(false);
  const [likeApplyCondition, setLikeApplyCondition] = useState(false);
  const [collectionDescription, setCollectionDescription] = useState("");
  const [decodedObject, setDecodedObject] = useState(null);
  const [duplicateModal, setDuplicateModal] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [duplicateData, setDuplicateData] = useState("");
  const [newDuplicateCollection, setDuplicateCollection] = useState();
  const [serialId, setSerialNo] = useState(null);
  const [viewAll, setViewAll] = useState(false);

  // const [queryFilterState, dispatchQueryFilter] = useReducer(
  //   queryFilterReducer,
  //   initialQueryFilterState
  // );
  const [collectionInfoState, dispatchCollectionInfo] = useReducer(
    collectionTabReducer,
    initialCollectionInfoState
  );
  const [addProductDrawer, setAddProductDrawer] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const clearDate = () => {
    formik.setFieldValue("startDate", null);
    formik.setFieldValue("endDate", null);
  };

  const backHandler = () => {
    navigate({
      pathname: "/parameters/collections",
      search: `?${createSearchParams({ filter: searchParams.get("filter") })}`,
    });
  };

  const handleDuplicateCollectionClose = () => {
    setDuplicateModal(false);
  };

  const handleDuplicateCollection = (row) => {
    setDuplicateModal(true);
    setDuplicateData(row);
  };

  useEffect(() => {
    const encodedString = searchParams.get("filter");

    const decodedString = decodeURIComponent(encodedString);
    const parsedObject = JSON.parse(decodedString);
    setDecodedObject(parsedObject);
  }, [searchParams]);

  useEffect(() => {
    if (newDuplicateCollection?.data?.srNo) {
      setSerialNo(newDuplicateCollection?.data?.srNo);
    }
  }, [newDuplicateCollection]);

  // const srNo = serialId ? serialId : id;

  const { data: collectionData } = useGetAllCollectionsQuery({ id }, { skip: !id });
  const { _id: collectionId = "" } = collectionData?.data?.data?.[0] ?? {};
  // conditions = [],
  // type = "automated",
  // shouldMatch = "all",
  // console.log("Collection we got", collectionData?.data?.data?.[0]);

  // const prevPageHandler = () => {
  //   const { pageNo } = queryFilterState;
  //   if (collectionData?.data?.nextCount === 0) {
  //     return;
  //   }
  //   decodedObject.order = 1;
  //   navigate({
  //     pathname: `/parameters/collections/edit/${pageNo}`,
  //     search: `?${createSearchParams({
  //       filter: JSON.stringify(decodedObject),
  //     })}`,
  //   });
  // };

  // const nextPageHandler = () => {
  //   const { pageNo } = queryFilterState;
  //   if (collectionData?.data?.prevCount === 0) {
  //     return;
  //   }
  //   decodedObject.order = -1;
  //   navigate({
  //     pathname: `/parameters/collections/edit/${pageNo}`,
  //     search: `?${createSearchParams({
  //       filter: JSON.stringify(decodedObject),
  //     })}`,
  //   });
  // };

  // useEffect(() => {
  //   if (collectionData?.data?.data?.[0]?.srNo) {
  //     dispatchQueryFilter({
  //       type: "SET_PAGE_NO",
  //       pageNo: collectionData?.data?.data?.[0]?.srNo,
  //     });
  //   }
  // }, [collectionData]);

  const [
    editCollection,
    {
      data: editData,
      isLoading: editCollectionIsLoading,
      isSuccess: editCollectionIsSuccess,
      error: editCollectionError,
      isError: editCollectionIsError,
    },
  ] = useEditCollectionMutation();

  const [
    addProducts,
    {
      data: addProductsData,
      isLoading: productsDataIsLoading,
      isSuccess: productsDataIsSuccess,
      error: productsDataError,
      isError: productsDataIsError,
    },
  ] = useAddProductsMutation();

  // const {
  //   data: productsData,
  //   isLoading: productsIsLoading,
  //   isSuccess: productsIsSuccess,
  //   error: productsError,
  // } = useGetAllProductsQuery(
  //   { queries: { collectionId: collectionData?.data?.data?.[0]?._id } },
  //   { skip: !collectionIsSuccess }
  // );
  // const linkedProducts = productsData?.data ?? [];
  // console.log("Products we got", productsData?.data);

  const handleLikeProductRadio = (event) => {
    setLikeProductRadio(event.target.value);
  };

  const handleLikeMatchRadio = (event) => {
    setLikeMatchRadio(event.target.value);
  };

  const toggleAddProductDrawer = (anchor, open) => (event) => {
    if (event && event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setAddProductDrawer({ ...addProductDrawer, [anchor]: open });
  };

  const handlePriceClick = (event) => {
    setAnchorPriceEl(event.currentTarget);
  };

  const handlePriceClose = () => {
    setAnchorPriceEl(null);
  };

  const openPrice = Boolean(anchorPriceEl);
  const idPrice = openPrice ? "simple-popover" : undefined;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.pId);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleLikeSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = likeProductRows.map((n) => n.pId);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleFieldChange = (event) => {
    setField(event.target.value);
  };

  const handleOperatorChange = (event) => {
    setOperator(event.target.value);
  };

  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleLikeAddCondition = () => {
    if (!likeAddCondition) {
      setLikeAddCondition(true);
    } else {
      setLikeAddCondition(false);
      setLikeApplyCondition(false);
    }
  };

  const handleLikeApplyCondition = () => {
    if (likeApplyCondition) {
      setLikeApplyCondition(false);
    } else {
      setLikeApplyCondition(true);
      setLikeAddCondition(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      title: collectionData?.data?.data[0]?.title || "",
      filter: collectionData?.data?.data[0]?.filter || false,
      description: collectionData?.data?.data?.[0]?.description || "",
      status: collectionData?.data?.data[0]?.status,
      startDate: collectionData?.data?.data?.[0]?.startDate || "",
      endDate: collectionData?.data?.data?.[0]?.endDate || "",
      mediaUrl: collectionData?.data?.data?.[0]?.mediaUrl || "",
      notes: collectionData?.data?.data[0]?.notes || "",
      type: collectionData?.data?.data[0]?.type || "automated",
      shouldMatch: collectionData?.data?.data[0]?.shouldMatch || "all",
      conditions: collectionData?.data?.data[0]?.conditions || [],
      products: collectionData?.data?.data?.[0]?.products || [],
      seo: {
        title: collectionData?.data?.data?.[0]?.seos?.title || "",
        description: collectionData?.data?.data?.[0]?.seos?.description || "",
        urlHandle: collectionData?.data?.data?.[0]?.seos?.slug || "",
        metaKeywords: "",
        multipleMetaTags: collectionData?.data?.data?.[0]?.seos?.metaKeywords || [],
        isEditedUrlHandle: false,
        isEditedTitleHandle: false,
      },
    },
    enableReinitialize: true,
    validationSchema: collectionValidationSchema,
    onSubmit: (values) => {
      const collectionValues = structuredClone(values);
      collectionValues.title =
        formik.values.title !== formik.initialValues.title ? collectionValues.title : "";
      collectionValues.status = collectionValues.status;
      collectionValues.seo.metaKeywords = collectionValues?.seo?.multipleMetaTags;
      collectionValues.seo.slug = collectionValues?.seo?.urlHandle;
      collectionValues.startDate = collectionValues.startDate
        ? new Date(collectionValues.startDate)
        : "";
      collectionValues.endDate = collectionValues.endDate
        ? new Date(collectionValues.endDate)
        : "";
      const editObj = omitEmptyKeys(collectionValues, ["mediaUrl"]);
      editObj.seo = omitEmptyKeys(collectionValues.seo);
      editObj.conditions = values.conditions.map((c) => omitEmptyKeys(c));
      // if (editObj.type === "manual") {
      //   delete editObj.shouldMatch;
      //   delete editObj.conditions;
      // } else {
      //   editObj.conditions = collectionValues.conditions.map((c) => omitEmptyKeys(c));
      // }

      if (id) {
        editCollection({
          id: collectionId,
          details: editObj,
        })
          .unwrap()
          // .then(() => addProducts({ updates: values.products }).unwrap())
          .then(() => {
            dispatchCollectionInfo({ type: "DISABLE_EDIT" });
            dispatch(showSuccess({ message: "Collection edited successfully" }));
            setViewAll(false);
          })
          // .then(() =>
          //   navigate({
          //     pathname: "/parameters/collections",
          //     search: `?${createSearchParams({ filter: searchParams.get("filter") })}`,
          //   })
          // )
          .catch((e) =>
            dispatch(
              showError({
                message: e?.data?.message ?? e?.message ?? "Something went wrong",
              })
            )
          );
      }
    },
  });

  useEffect(() => {
    const formikValuesWithoutSEOIsEdited = {
      ...formik.values,
      seo: {
        title: formik?.values?.seo?.title,
        description: formik?.values?.seo?.description,
        urlHandle: formik?.values?.seo?.urlHandle,
        metaKeywords: formik?.values?.seo?.metaKeywords,
        multipleMetaTags: formik?.values?.seo?.multipleMetaTags,
      },
    };

    const formikInitialValuesWithoutSEOIsEdited = {
      ...formik?.initialValues,
      seo: {
        title: formik?.initialValues?.seo?.title,
        description: formik?.initialValues?.seo?.description,
        urlHandle: formik?.initialValues?.seo?.urlHandle,
        metaKeywords: formik?.initialValues?.seo?.metaKeywords,
        multipleMetaTags: formik?.initialValues?.seo?.multipleMetaTags,
      },
    };
    if (
      id &&
      !_.isEqual(formikValuesWithoutSEOIsEdited, formikInitialValuesWithoutSEOIsEdited)
    ) {
      dispatchCollectionInfo({ type: "ENABLE_EDIT" });
      dispatchCollectionInfo({ type: "ENABLE_DISCARD" });
    } else if (
      id &&
      _.isEqual(formikValuesWithoutSEOIsEdited, formikInitialValuesWithoutSEOIsEdited)
    ) {
      dispatchCollectionInfo({ type: "DISABLE_EDIT" });
      dispatchCollectionInfo({ type: "DISABLE_DISCARD" });
    }
  }, [formik.initialValues, formik.values, id]);

  const uploadMediaHandler = (url) => {
    formik.setFieldValue("mediaUrl", url);
  };

  const handleTitleChange = (e) => {
    formik.handleChange(e);
    !formik?.values?.seo?.isEditedTitleHandle &&
      formik.setFieldValue("seo.title", e.target.value);
    // !formik?.values?.seo?.isEditedUrlHandle &&
    // formik.setFieldValue("seo.urlHandle", removeWhiteSpace(e.target.value))
  };

  useEffect(() => {
    if (editCollectionIsError) {
      if (editCollectionError?.data?.message) {
        dispatch(showError({ message: editCollectionError?.data?.message }));
      } else {
        dispatch(showError({ message: "Something went wrong, please try again" }));
      }
    }
  }, [editCollectionError, editCollectionIsError, dispatch]);

  const onKeyDown = (keyEvent) => {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  };

  // useFormikToastError(formik);

  return (
    <div className="page container-fluid position-relative user-group">
      <InfoHeader
        title={formik.values.title || "Edit collection"}
        onBack={backHandler}
        onPreview={
          !id
            ? ""
            : `${process.env.REACT_APP_FE_BASE_URL}/products?collectionId=${collectionId}`
        }
        onDuplicate={() => {
          dispatch(updateCollectionId(collectionData?.data?.data[0]?._id));
          handleDuplicateCollection(collectionData?.data?.data[0]);
        }}
        // onPrev={prevPageHandler}
        hasNext={collectionData?.data?.prevCount}
        hasPrev={collectionData?.data?.nextCount}
        // onNext={nextPageHandler}
        isEdit={!!id}
      />
      <form
        onSubmit={formik.handleSubmit}
        onKeyDown={onKeyDown}>
        <div className="row">
          <div className="col-lg-9 mt-4">
            <div className="bg-black-15 border-grey-5 rounded-8 p-3 row attributes">
              <div className="col-md-12 px-0 mt-1">
                <div className="d-flex mb-1">
                  <p className="text-lightBlue ">Collection Title</p>
                  <span style={{ color: "#F67476", paddingRight: 4 }}>*</span>
                  <Tooltip
                    title="Lorem ipsum"
                    placement="top">
                    <img
                      src={info}
                      alt="info"
                      className=" c-pointer"
                      width={13.5}
                    />
                  </Tooltip>
                </div>
                <FormControl className="w-100 px-0">
                  <OutlinedInput
                    placeholder="Mirosa Collection"
                    size="small"
                    name="title"
                    value={formik.values.title}
                    onChange={handleTitleChange}
                    autoFocus={true}
                  />
                </FormControl>
                {!!formik?.touched?.title && formik?.errors?.title && (
                  <FormHelperText error>{formik?.errors?.title}</FormHelperText>
                )}
                <div className="small">
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="filter"
                        value={formik.values?.filter}
                        checked={formik.values?.filter}
                        onChange={formik.handleChange}
                        // onBlur={formik.handleBlur}
                        inputProps={{ "aria-label": "controlled" }}
                        size="small"
                        style={{
                          color: "#5C6D8E",
                          marginRight: 0,
                          width: "auto",
                        }}
                      />
                    }
                    label="Include in Filters"
                    sx={{
                      "& .MuiTypography-root": {
                        fontSize: "0.875rem",
                        color: "#c8d8ff",
                      },
                    }}
                    className=" px-0 me-1"
                  />
                  <span className="text-blue-2 c-pointer">(manage)</span>
                </div>
              </div>
              <div className="col-12 mt-3 px-0">
                <div className="d-flex  mb-1">
                  <p className="text-lightBlue me-2">Description</p>
                  <Tooltip
                    title="Lorem ipsum"
                    placement="top">
                    <img
                      src={info}
                      alt="info"
                      className=" c-pointer"
                      width={13.5}
                    />
                  </Tooltip>
                </div>
                <AppTextEditor
                  value={formik.values.description}
                  setFieldValue={(val) => {
                    if (val == "") {
                      formik.setFieldValue("description", "<p></p>");
                      return;
                    }
                    formik.setFieldValue("description", val);
                  }}
                />
              </div>
            </div>

            <div className="bg-black-15 border-grey-5 rounded-8 p-3 row mt-4 attributes">
              <div className="d-flex justify-content-between mb-2 px-0">
                <h6 className="text-lightBlue me-auto text-lightBlue text-capitalize col-auto ps-0 fw-500">
                  Add Products {formik.values.type}
                </h6>
              </div>
              <AddLinkedProducts
                inEditMode
                collectionId={collectionId}
                selection={formik.values.type}
                condition={formik.values.shouldMatch}
                filters={formik.values.conditions}
                products={formik.values.products}
                onSelectionChange={(val) => formik.setFieldValue("type", val)}
                onConditionChange={(val) => formik.setFieldValue("shouldMatch", val)}
                onFiltersChange={(val) => formik.setFieldValue("conditions", val)}
                onProductsChange={(val) => formik.setFieldValue("products", val)}
              />
            </div>

            <div className="mt-4">
              <SEOSecondary
                formik={formik}
                viewAll={viewAll}
                setViewAll={setViewAll}
              />
            </div>

            <SwipeableDrawer
              anchor="right"
              open={addProductDrawer["right"]}
              onClose={toggleAddProductDrawer("right", false)}
              onOpen={toggleAddProductDrawer("right", true)}>
              <div className="d-flex justify-content-between py-3 ps-3 pe-2 me-1 align-items-center">
                <h6 className="text-lightBlue">Select Products</h6>
                <img
                  src={cancel}
                  alt="cancel"
                  className="c-pointer add-product-padding"
                  onClick={toggleAddProductDrawer("right", false)}
                />
              </div>
              <hr className="hr-grey-6 mt-3 mb-3" />
              <div className="px-3">
                <Search>
                  <SearchIconWrapper>
                    <SearchIcon sx={{ color: "#c8d8ff" }} />
                  </SearchIconWrapper>
                  <StyledInputBase
                    placeholder="Search…"
                    inputProps={{ "aria-label": "search" }}
                  />
                </Search>
              </div>

              <TableContainer className="mt-3">
                <Table
                  sx={{ minWidth: 750 }}
                  aria-labelledby="tableTitle"
                  size="medium">
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={rows.length}
                    headCells={likeHeadCells}
                  />
                  <TableBody>
                    {stableSort(rows, getComparator(order, orderBy))
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row, index) => {
                        const isItemSelected = isSelected(row.pId);
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.pId}
                            selected={isItemSelected}>
                            <TableCell padding="checkbox">
                              <Checkbox
                                color="primary"
                                checked={isItemSelected}
                                inputProps={{
                                  "aria-labelledby": labelId,
                                }}
                                size="small"
                                onClick={(event) => handleClick(event, row.pId)}
                                style={{
                                  color: "#5C6D8E",
                                  marginRight: 0,
                                }}
                              />
                            </TableCell>
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="none">
                              <div className="d-flex align-items-center my-2">
                                <img
                                  src={ringSmall}
                                  alt="ringSmall"
                                  className="me-2"
                                  height={45}
                                  width={45}
                                />
                                <div>
                                  <p className="text-lightBlue fw-600">{row.productName}</p>
                                  <small className="mt-2 text-grey-6">SKU: TFDR012345</small>
                                </div>
                              </div>
                            </TableCell>
                            <TableCell>
                              <p className="text-lightBlue">{row.category}</p>
                            </TableCell>
                            <TableCell>
                              <div
                                className="d-flex align-items-center c-pointer "
                                aria-describedby={idPrice}
                                variant="contained"
                                onClick={handlePriceClick}>
                                <p className="text-lightBlue">{row.price}</p>
                                <img
                                  className="ms-3"
                                  src={arrowDown}
                                  alt="arrowDown"
                                />
                              </div>
                              <Popover
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "center",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "center",
                                }}
                                id={idPrice}
                                open={openPrice}
                                anchorEl={anchorPriceEl}
                                onClose={handlePriceClose}>
                                <div className="px-3">
                                  <small className="text-lightBlue">
                                    Default : 12KT • Yellow • Gold • IJ-SI
                                  </small>
                                  <div className="d-flex align-items-center justify-content-between mb-2 mt-3 text-grey-6">
                                    <small>Metal Price</small>
                                    <small className="ms-2">₹&nbsp;15,000</small>
                                  </div>
                                  <div className="d-flex align-items-center justify-content-between mb-2 mt-2 text-grey-6">
                                    <small>Diamond Price</small>
                                    <small className="ms-2">₹&nbsp;4,000</small>
                                  </div>
                                  <div className="d-flex align-items-center justify-content-between mb-2 mt-2 text-grey-6">
                                    <small>Making Charges</small>
                                    <small className="ms-2">₹&nbsp;1,000</small>
                                  </div>
                                  <div className="d-flex align-items-center justify-content-between mb-3 mt-2 text-grey-6">
                                    <small>GST</small>
                                    <small className="ms-2">₹&nbsp;&nbsp;600</small>
                                  </div>
                                  <div className="d-flex align-items-center justify-content-between mb-2 mt-2">
                                    <p className="text-lightBlue">Total</p>
                                    <p className="ms-2 text-lightBlue fw-600">₹&nbsp;20,600</p>
                                  </div>
                                </div>
                              </Popover>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow
                        style={{
                          height: 53 * emptyRows,
                        }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                className="table-pagination"
              />
              <div className="d-flex flex-column py-3 px-4 feature-buttons">
                <hr className="hr-grey-6 my-3 w-100" />
                <div className="d-flex justify-content-between">
                  <button className="button-gradient py-2 px-5 w-auto ">
                    <p>Add 4 Products</p>
                  </button>
                  <button className="button-lightBlue-outline py-2 px-4">
                    <p>Cancel</p>
                  </button>
                </div>
              </div>
            </SwipeableDrawer>
          </div>
          <div className="col-lg-3 mt-4 pe-0 ps-0 ps-lg-3">
            {/* <StatusBox
              headingName={"Collection Status"}
              value={formik.values.status}
              handleProductStatus={(_, val) => {
                if (val !== null) {
                  formik.setFieldValue("status", val);
                }
              }}
              toggleData={["active", "in-active"]}
              showSchedule={true}
              startDate={formik.values.startDate}
              endDate={formik.values.endDate}
              handleStartDate={(val) => formik.setFieldValue("startDate", val)}
              handleEndDate={(val) => formik.setFieldValue("endDate", val)}
              clearDate={clearDate}
            /> */}
            <StatusBoxSecondary
              title="collection status"
              status={formik.values.status}
              onStatus={(status) => {
                formik.setFieldValue("status", status);
                formik.setFieldValue("startDate", "");
                formik.setFieldValue("endDate", "");
              }}
              startDate={formik.values.startDate}
              endDate={formik.values.endDate}
              onSchedule={({ startDate, endDate }) => {
                formik.setFieldValue("startDate", startDate);
                formik.setFieldValue("endDate", endDate);
                formik.setFieldValue("status", "scheduled");
              }}
              containerStyles="status-container"
              titleStyles="status-title"
              actionStyles="status-action"
              activeText="active"
              inActiveText="in-active"
            />
            {/* <VisibilityBox 
              value={collectionVisibility} 
              onChange={(_,val)=>setCollectionVisibility(val)}
            /> */}
            <div className="mt-4">
              <UploadMediaBox
                imageName={addMedia}
                headingName={"Media"}
                UploadChange={uploadMediaHandler}
                previousImage={formik.values.mediaUrl}
                module="collection"
                // setDelete={setDelete}
              />
            </div>
            {/* <div className="mt-4">
              <UploadBanner 
                imageName={addMedia} 
                headingName={"Up Selling Banners"} 
              />
            </div> */}
            <NotesBox
              name={"notes"}
              value={formik.values.notes}
              onChange={formik.handleChange}
            />
          </div>
        </div>
        <SaveFooterTertiary
          show={id ? collectionInfoState.isEditing : true}
          onDiscard={backHandler}
          isLoading={editCollectionIsLoading}
        />
        <DiscardModalSecondary
          when={collectionInfoState.discard}
          message="collection tab"
        />
      </form>
      <DuplicateCollection
        newDuplicateCollection={setDuplicateCollection}
        duplicateData={duplicateData}
        openDuplicateCollection={duplicateModal}
        handleDuplicateCollectionClose={handleDuplicateCollectionClose}
      />
    </div>
  );
};

export default EditCollection;
