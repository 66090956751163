import { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  OutlinedInput,
  Paper,
  Slide,
  Tab,
  Tabs,
  Select,
  MenuItem,
  Radio,
  FormControlLabel,
  Popover,
  RadioGroup,
  Tooltip,
  FormHelperText,
} from '@mui/material';
import Label from '../../../../../components/Label/Label';
import addMedia from '../../../../../assets/icons/addMedia.svg';

import DropDownbox from '../../../../../components/ThemeComponent/HomeToggle/DropDownBox';
import SearchInput from '../../../../../components/ThemeComponent/HomeToggle/SearchInput';
import UploadMediaBox from '../../../../../components/UploadMediaBox/UploadMediaBox';

import upload from '../../../../../assets/icons/theme/upload.svg';
import mobileUser from '../../../../../assets/icons/theme/mobileUser.svg';
import {
  useGetAllCategoriesQuery,
  useGetAllSubCategoriesQuery,
} from '../../../../../features/parameters/categories/categoriesApiSlice';
import { useGetAllCollectionsQuery } from '../../../../../features/parameters/collections/collectionsApiSlice';
import { LoadingButton } from '@mui/lab';
import InputBox from '../../../../../components/Setting/InputBox';

const SingleTab = (props) => {
  const { formik, isLoading } = props;

  // const {
  //   data: collectionData,
  //   isLoading: collectionIsLoading,
  //   isSuccess: collectionIsSuccess,
  //   error: collectionError,
  // } = useGetAllCollectionsQuery(
  //   {},
  //   {
  //     skip: formik.values.backgroundType === 'collection' ? false : true,
  //   }
  // );
  // const {
  //   data: categoriesData,
  //   isLoading: categoriesIsLoading,
  //   error: categoriesError,
  //   isError: categoriesIsError,
  //   isSuccess: categoriesIsSuccess,
  //   isFetching: categoriesIsFetching,
  // } = useGetAllCategoriesQuery(
  //   {},
  //   {
  //     skip: formik.values.backgroundType === 'category' ? false : true,
  //   }
  // );
  // const {
  //   data: subCategoriesData,
  //   isLoading: subCategoriesIsLoading,
  //   error: subCategoriesError,
  //   isError: subCategoriesIsError,
  //   isSuccess: subCategoriesIsSuccess,
  //   isFetching: subCategoriesIsFetching,
  // } = useGetAllSubCategoriesQuery(
  //   {},
  //   {
  //     skip: formik.values.backgroundType === 'subCategory' ? false : true,
  //   }
  // );

  // const linkTypeHandler = (e) => {
  //   formik.setFieldValue('backgroundType', e.target.value);
  //   formik.setFieldValue('redirectId', '');
  // };
  // const categoryHandler = (e) => {
  //   formik.setFieldValue('redirectId', e.target.value);
  // };
  // const collectionHandler = (e) => {
  //   formik.setFieldValue('redirectId', e.target.value);
  // };
  // const subCategoryHandler = (e) => {
  //   formik.setFieldValue('redirectId', e.target.value);
  // };

  // const uploadMediaHandler = (url) => {
  //   formik.setFieldValue('mediaUrl', url);
  // };

  const saveHandler = () => {
    formik.handleSubmit();
  };

  return (
    <div className='mt-2' style={{ display: 'grid', gap: '1rem' }}>
      {/* <div>
        <Label>Select Link Type</Label>
        <FormControl sx={{ width: '100%' }} size='small'>
          <Select
            labelId='demo-select-small'
            id='demo-select-small'
            name='backgroundType'
            value={formik.values.backgroundType}
            onBlur={formik.handleBlur}
            onChange={linkTypeHandler}
          >
            <MenuItem value=''>Select</MenuItem>
            <MenuItem value='collection'>Collection</MenuItem>
            <MenuItem value='category'>Category</MenuItem>
            <MenuItem value='subCategory'>Sub Category</MenuItem>
          </Select>
          {!!formik.touched.backgroundType && formik.errors.backgroundType && (
            <FormHelperText error>
              {formik.errors.backgroundType}
            </FormHelperText>
          )}
        </FormControl>
      </div> */}
      {/* {formik.values.backgroundType === 'collection' && (
        <div>
          <Label>Collection</Label>
          <FormControl sx={{ width: '100%' }} size='small'>
            <Select
              labelId='demo-select-small'
              id='demo-select-small'
              name='redirectId'
              value={formik.values.redirectId}
              onBlur={formik.handleBlur}
              onChange={collectionHandler}
            >
              {!!collectionData?.data?.data?.length ? (
                collectionData?.data?.data?.map((collection) => {
                  return (
                    <MenuItem key={collection?._id} value={collection?._id}>
                      {collection.title}
                    </MenuItem>
                  );
                })
              ) : (
                <span
                  style={{
                    fontSize: 13,
                    color: '#5c6d8e',
                    padding: '0 16px',
                    display: 'block',
                  }}
                >
                  No Options
                </span>
              )}
            </Select>
            {!!formik.touched.redirectId && formik.errors.redirectId && (
              <FormHelperText error>{formik.errors.redirectId}</FormHelperText>
            )}
          </FormControl>
        </div>
      )}
      {formik.values.backgroundType === 'category' && (
        <div>
          <Label>Category</Label>
          <FormControl sx={{ width: '100%' }} size='small'>
            <Select
              labelId='demo-select-small'
              id='demo-select-small'
              name='redirectId'
              value={formik.values.redirectId}
              onBlur={formik.handleBlur}
              onChange={categoryHandler}
            >
              {!!categoriesData?.data?.data?.length ? (
                categoriesData?.data?.data?.map((category) => {
                  return (
                    <MenuItem key={category?._id} value={category?._id}>
                      {category.name}
                    </MenuItem>
                  );
                })
              ) : (
                <span
                  style={{
                    fontSize: 13,
                    color: '#5c6d8e',
                    padding: '0 16px',
                    display: 'block',
                  }}
                >
                  No Options
                </span>
              )}
            </Select>
            {!!formik.touched.redirectId && formik.errors.redirectId && (
              <FormHelperText error>{formik.errors.redirectId}</FormHelperText>
            )}
          </FormControl>
        </div>
      )}
      {formik.values.backgroundType === 'subCategory' && (
        <div>
          <Label>Sub Category</Label>
          <FormControl sx={{ width: '100%' }} size='small'>
            <Select
              labelId='demo-select-small'
              id='demo-select-small'
              name='redirectId'
              value={formik.values.redirectId}
              onBlur={formik.handleBlur}
              onChange={subCategoryHandler}
            >
              {subCategoriesData?.data?.data?.map((subCat) => {
                return (
                  <MenuItem key={subCat?._id} value={subCat?._id}>
                    {subCat.name}
                  </MenuItem>
                );
              })}
            </Select>
            {!!formik.touched.redirectId && formik.errors.redirectId && (
              <FormHelperText error>{formik.errors.redirectId}</FormHelperText>
            )}
          </FormControl>
        </div>
      )}
      <UploadMediaBox
        imageName={addMedia}
        headingName={'Media'}
        UploadChange={uploadMediaHandler}
        previousImage={formik.values.mediaUrl}
      /> */}
      <div>
        <InputBox
          heading='text'
          name='text'
          value={formik.values.text}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
        />
        {!!formik.touched.text && formik.errors.text && (
          <FormHelperText error>{formik.errors.text}</FormHelperText>
        )}
      </div>
      <div>
        <InputBox
          heading='link'
          name='link'
          value={formik.values.link}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
        />
        {!!formik.touched.link && formik.errors.link && (
          <FormHelperText error>{formik.errors.link}</FormHelperText>
        )}
      </div>
      <LoadingButton
        className='button-gradient py-2 px-4 w-auto'
        type='button'
        loading={isLoading}
        disabled={isLoading}
        onClick={saveHandler}
      >
        <p>Save</p>
      </LoadingButton>
    </div>
  );
};

export default SingleTab;
