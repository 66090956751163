import apiSlice from "../../app/api/apiSlice";
import { omitEmptyKeys } from "../../utils/helper";

export const mapsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAddress: builder.query({
      query: (addr) => ({
        url: "/wrapper/getAddress",
        params: omitEmptyKeys(addr),
      }),
    }),
    getPincode: builder.query({
      query: (addr) => ({
        url: "/wrapper/getPincode",
        params: omitEmptyKeys(addr),
      }),
    }),
  }),
});

export const { useGetAddressQuery, useGetPincodeQuery } = mapsApiSlice;
