import apiSlice from "../../app/api/analyticsApiSlice";
import { omitEmptyKeys } from "../../utils/helper";

export const analyticsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getOrderAnalytics: builder.query({
      query: (queries) => ({
        url: "/analytics/order",
        params: omitEmptyKeys(queries),
      }),
      keepUnusedDataFor: 0,
      transformResponse: (res) => res.data,
      providesTags: ["OrderAnalytics"],
    }),

    getRevenueAnalytics: builder.query({
      query: (queries) => ({
        url: "/analytics/revenue",
        params: omitEmptyKeys(queries),
      }),
      keepUnusedDataFor: 0,
      transformResponse: (res) => res.data,
      providesTags: ["RevenueAnalytics"],
    }),

    getCustomerAnalytics: builder.query({
      query: (queries) => ({
        url: "/analytics/customer",
        params: omitEmptyKeys(queries),
      }),
      keepUnusedDataFor: 0,
      transformResponse: (res) => res.data,
      providesTags: ["CustomerAnalytics"],
    }),

    getEngagementAnalytics: builder.query({
      query: (queries) => ({
        url: "/analytics/engagement",
        params: omitEmptyKeys(queries),
      }),
      keepUnusedDataFor: 0,
      transformResponse: (res) => res.data,
      providesTags: ["EngagementAnalytics"],
    }),

    getAverageOrderValueAnalytics: builder.query({
      query: (queries) => ({
        url: "/analytics/avgOrderValue",
        params: omitEmptyKeys(queries),
      }),
      keepUnusedDataFor: 0,
      transformResponse: (res) => res.data,
      providesTags: ["AverageOrderValueAnalytics"],
    }),

    getTopSellingProducts: builder.query({
      query: (queries) => ({
        url: "/analytics/topSellingProduct",
        params: omitEmptyKeys(queries),
      }),
      keepUnusedDataFor: 0,
      transformResponse: (res) => res.data,
      providesTags: ["TopSellingProductsAnalytics"],
    }),

    getGA4Countries: builder.query({
      query: (queries) => ({
        url: "/googleAnalytics/country",
        params: omitEmptyKeys(queries),
      }),
      keepUnusedDataFor: 0,
      transformResponse: (res) => res.data,
      providesTags: ["AverageOrderValueAnalytics"],
    }),

    getActiveUsers: builder.query({
      query: (queries) => ({
        url: "/googleAnalytics/realTimeActive",
        params: omitEmptyKeys(queries),
      }),
      keepUnusedDataFor: 0,
      transformResponse: (res) => res.data,
      providesTags: ["ActiveUsersAnalytics"],
    }),

    getTotalConversions: builder.query({
      query: (queries) => ({
        url: "/googleAnalytics/conversion",
        params: omitEmptyKeys(queries),
      }),
      keepUnusedDataFor: 0,
      transformResponse: (res) => res.data,
      providesTags: ["TotalConversionsAnalytics"],
    }),

    getTopDevicesUsed: builder.query({
      query: (queries) => ({
        url: "/googleAnalytics/device",
        params: omitEmptyKeys(queries),
      }),
      keepUnusedDataFor: 0,
      transformResponse: (res) => res.data,
      providesTags: ["TopDevicesUsed"],
    }),

    getPageTraffic: builder.query({
      query: (queries) => ({
        url: "/googleAnalytics/pageTraffic",
        params: omitEmptyKeys(queries),
      }),
      keepUnusedDataFor: 0,
      transformResponse: (res) => res.data,
      providesTags: ["TopPageTraffic"],
    }),
  }),
});

export const {
  useGetTotalConversionsQuery,
  useGetActiveUsersQuery,
  useGetAverageOrderValueAnalyticsQuery,
  useGetCustomerAnalyticsQuery,
  useGetEngagementAnalyticsQuery,
  useGetOrderAnalyticsQuery,
  useGetRevenueAnalyticsQuery,
  useGetTopSellingProductsQuery,
  useGetGA4CountriesQuery,
  useGetTopDevicesUsedQuery,
  useGetPageTrafficQuery,
} = analyticsApiSlice;
