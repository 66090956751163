import {
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import Heading from "../../components/Headers/DashboardHeader";
import bharat from "../../assets/flag/india.svg";
import { useGetAllOrdersQuery } from "../../features/orders/ordersApiSlice";
import { useMemo, useState } from "react";
import moment from "moment/moment";
import { rupeeFormatWithSymbol } from "../../utils/format";
import NoDataFound from "../../components/NoDataFound/NoDataFound";

const rows3 = [
  {
    orderId: "#1234",
    data: "Today at 5.25pm",
    customer: "Sahil Bhutani",
    country: "India",
    flag: bharat,
    total: "₹ 5,00,000",
  },
  {
    orderId: "#1234",
    data: "Today at 5.25pm",
    customer: "Sahil Bhutani",
    country: "India",
    flag: bharat,
    total: "₹ 5,00,000",
  },
  {
    orderId: "#1234",
    data: "Today at 5.25pm",
    customer: "Sahil Bhutani",
    // country: "India",
    // flag: bharat,
    total: "₹ 5,00,000",
  },
];

const headCells = ["Order ID", "Date", "Customer", "Country", "Total"];

const statusData = [
  { value: ["pending"], name: "Pending" },
  { value: ["delivered"], name: "Completed" },
  { value: ["refunded"], name: "Refunded" },
  { value: [], name: "RMA" },
];

export default function TopOrders() {
  const [orderStatus, setOrderStatus] = useState(statusData[0].value);

  const { data: orderData } = useGetAllOrdersQuery({
    pageSize: 10,
    pageNo: 1,
    createdAt: -1,
    status: orderStatus,
  });

  const orders = useMemo(
    () =>
      (orderData?.data?.data ?? []).map(
        ({ _id, orderID, createdAt, customer, address, items }) => ({
          _id,
          orderID,
          date: moment(createdAt).format("DD-MM-YYYY [at] h:mm a"),
          customer: !customer
            ? address?.shipping?.firstName + " " + address?.shipping?.lastName
            : customer?.firstName + " " + customer?.lastName,
          total: rupeeFormatWithSymbol(
            Math.round(items.reduce((t, it) => t + it.item.price, 0))
          ),
        })
      ),
    [orderData?.data?.data]
  );

  return (
    <div className="bg-button-3 border-grey-5 rounded-8 px-4 pt-4 pb-3 mt-4">
      <Heading
        heading={"Orders"}
        // viewMore={"View More"}
      />
      <div className="d-flex mt-4 mb-2">
        {statusData.map((item) => (
          <Chip
            key={item.value.toString()}
            onClick={() => setOrderStatus(item.value)}
            variant={Object.is(orderStatus, item.value) ? "filled" : "outlined"}
            label={item.name}
            className="mx-2"
          />
        ))}
      </div>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead sx={{ background: "none" }}>
            <TableRow style={{ borderBottom: "1px solid #343E50" }}>
              {headCells.map((item) => (
                <TableCell key={item}>
                  <h6 className="text-lightBlue">{item}</h6>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {orders.length > 0 ? (
            <TableBody>
              {orders.map((row) => (
                <TableRow
                  key={row._id}
                  style={{ borderBottom: "1px solid #343E50" }}>
                  <TableCell
                    component="th"
                    scope="row">
                    <p className="text-blue-2 fw-600">{row.orderID}</p>
                  </TableCell>
                  <TableCell>
                    <p className="text-lightBlue fw-400">{row.date}</p>
                  </TableCell>
                  <TableCell>
                    <p className="text-lightBlue fw-400">{row.customer}</p>
                  </TableCell>
                  <TableCell>
                    <p className="text-lightBlue fw-400">
                      <img
                        alt="country"
                        src={bharat}
                        width={22}
                        className="me-2 rounded-4"
                      />
                      India
                    </p>
                  </TableCell>
                  <TableCell>
                    <p className="text-lightBlue fw-400">{row.total}</p>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          ) : (
            <NoDataFound />
          )}
        </Table>
      </TableContainer>
    </div>
  );
}
