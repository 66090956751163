import bharat from "../../../assets/flag/india.svg";
import Heading from "../../../components/Headers/DashboardHeader";
import SalesRatio from "../../Dashboard/SalesRatio";
import DatamapsIndia from "react-datamaps-india";

export default function TopSalesLocations({ filter = null }) {
  const salesData = [
    {
      name: "Mumbai, India",
      state: "Maharashtra",
      orders: 120,
      amount: 500000,
      percent: 40,
      image: bharat,
    },
    {
      name: "Delhi, India",
      state: "Delhi",
      orders: 95,
      amount: 400000,
      percent: 15,
      image: bharat,
    },
    {
      name: "Gujarat, India",
      state: "Gujarat",
      orders: 39,
      amount: 300000,
      percent: 13,
      image: bharat,
    },
    {
      name: "Kerala, India",
      state: "Kerala",
      orders: 35,
      amount: 230000,
      percent: 12,
      image: bharat,
    },
    // Additional states
    {
      name: "Karnataka, India",
      state: "Karnataka",
      orders: 80,
      amount: 600000,
      percent: 25,
      image: bharat,
    },
    {
      name: "Tamil Nadu, India",
      state: "Tamil Nadu",
      orders: 75,
      amount: 550000,
      percent: 22,
      image: bharat,
    },
    {
      name: "Uttar Pradesh, India",
      state: "Uttar Pradesh",
      orders: 50,
      amount: 400000,
      percent: 18,
      image: bharat,
    },
    {
      name: "Rajasthan, India",
      state: "Rajasthan",
      orders: 45,
      amount: 350000,
      percent: 17,
      image: bharat,
    },
    {
      name: "West Bengal, India",
      state: "West Bengal",
      orders: 40,
      amount: 300000,
      percent: 16,
      image: bharat,
    },
    {
      name: "Telangana, India",
      state: "Telangana",
      orders: 38,
      amount: 280000,
      percent: 15,
      image: bharat,
    },
    {
      name: "Punjab, India",
      state: "Punjab",
      orders: 30,
      amount: 200000,
      percent: 10,
      image: bharat,
    },
  ];
  

  // List of all states
  const allStates = [
    "Andaman & Nicobar Island",
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chandigarh",
    "Chhattisgarh",
    "Dadara & Nagar Haveli",
    "Daman & Diu",
    "Delhi",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jammu & Kashmir",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Lakshadweep",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Puducherry",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal"
  ];

  // Generate regionData with 0 for states with no orders
  const regionData = allStates.reduce((acc, state) => {
    const stateData = salesData.find((item) => item.state === state);
    acc[state] = {
      value: stateData ? stateData.orders : 0, // If no orders, set value to 0
      state: state, // Store the state name for hover display
    };
    return acc;
  }, {});

  return (
    <div
      className="bg-button-3 border-grey-5 px-3 py-3 rounded-8 mt-4"
      style={{
        height: "700px",
        overflow: "hidden"
      }}
    >
      <Heading heading={"Top Sales Location"} />
      <div className="d-flex row justify-content-between">
        {/* Map Section */}
        <div className="col-4">
          <div
            style={{
              position: "relative",
              width: "100%",
              height: "700px",
              overflow: "hidden",
            }}
          >
            <DatamapsIndia
  regionData={regionData}
  hoverComponent={({ value }) => {
    const orderCount = value.value || 0; 
    return (
      <div
        style={{
          backgroundColor: "#fff",
          padding: "3px",
          borderRadius: "4px",
          color: "#333",
          fontSize: "12px",
        }}
      >
        <strong>{value.state}</strong>: {orderCount} orders
      </div>
    );
  }}
  mapLayout={{
    legendTitle: "Number of Orders",
    startColor: "#9194ee",
    endColor: "#4C50B8",
    noDataColor: "#e5d7ff",
    borderColor: "#6E7497",
    hoverColor: "#7948FF",
    hoverBorderColor: "#ffffff",
  }}
/>

          </div>
        </div>
        {/* Sales Data Section */}
        <div className="col-5 mt-4">
          <div className="mx-5">
            {salesData.map((item) => (
              <SalesRatio
                key={item.name}
                percent={item.percent}
                image={item.image}
                name={item.name}
                amount={`₹${item.amount.toLocaleString()}`}
                ratio={`${item.percent}%`}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
