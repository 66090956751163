import { useState } from "react";
import SideStoreBox from "../../../components/Setting/Store/SideBox.js";
import DashboardBox from "../../../components/Setting/Store/DashBoardBox.js";
import EditCurrencyFormat from "./PopUp/EditCurrencyFormat.js";
import EditTimeZone from "./PopUp/EditTimeZon.js";

const top100Films = [
  { label: 'Danish' },
  { label: 'Dutch' },
  { label: 'English' },
  { label: 'Hindi' }
];

const DashboardSetting = () => {
  const [showCurrency, setCurrencyFormat] = useState(false);
  const [showTimeZone, setTimeZoneFormat] = useState(false);

  const handleCurrencyFormat = () => {
    setCurrencyFormat(false)
  }

  const openEditCurrency = () => {
    setCurrencyFormat(true)
  }

  const handleTimeZone = () => {
    setTimeZoneFormat(false)
  }

  const openEditTimezone = () => {
    setTimeZoneFormat(true)
  }

  return (
    <div className="col-lg-12 mt-3">
      <div className="row mb-2">
        <SideStoreBox 
          heading={"Currency"}
          details={`Lorem ipsum dolor sit amet consectetur adipisicing elit.`}
        />
        <div className="col-lg-8 d-flex align-items-center">
          <DashboardBox 
            inputName="Default Currency"
            tooltip="Some text"
            addInput="Add Currency"
            placeholder="INR (₹) - Indian Rupees"
            previewItem={"₹ 1,999.99"}
            onEdit={openEditCurrency}
          />
        </div>
      </div>
      <hr className="hr-grey-6 my-4 w-100" />

      <div className="row mb-2">
        <SideStoreBox 
          heading={"Language"}
          details={`Lorem ipsum dolor sit amet consectetur adipisicing elit.`}
        />
        <div className="col-lg-8 d-flex align-items-center">
          <DashboardBox 
            inputName="Default Language"
            tooltip="Some text"
            addInput="Add new language"
            placeholder="EN - English"
            optionList={top100Films}
            checkBox
          />
        </div>
      </div>
      <hr className="hr-grey-6 my-4 w-100" />

      <div className="row mb-2">
        <SideStoreBox 
          heading={"Time zone"}
          details={`Lorem ipsum dolor sit amet consectetur adipisicing elit.`}
        />
        <div className="col-lg-8 d-flex align-items-center">
          <DashboardBox 
            inputName="Time zone"
            tooltip="Some text"
            placeholder="Kolkata, West Bengal (GMT+5:30)"
            previewItem={"10th April, 2023 | 12:35 pm"}
            onEdit={openEditTimezone}
          />
        </div>
      </div>
      <hr className="hr-grey-6 my-4 w-100" />

      <div className="row mb-2">
        <SideStoreBox 
          heading={"Weight and Size"}
          details={`Lorem ipsum dolor sit amet consectetur adipisicing elit.`}
        />
        <div className="col-lg-8 align-items-center">
          <DashboardBox 
            inputName="Default Weight"
            tooltip="Some text"
            placeholder="Grams"
            previewItem={"1,234.00 grams"}
          />
          <DashboardBox 
            inputName="Default Dimension"
            tooltip="Some text"
            placeholder="Centimeters"
            previewItem={"45 cm"}
          />
        </div>
      </div>
      <hr className="hr-grey-6 my-4 w-100" />

      <EditCurrencyFormat
        show={showCurrency}
        onConfirm={handleCurrencyFormat}
      />

      <EditTimeZone 
        show={showTimeZone}
        onConfirm={handleTimeZone}
      />

    </div>
  );
};

export default DashboardSetting;
