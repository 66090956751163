import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { showError } from "../features/snackbar/snackbarAction";

import { SNACKBAR_DURATION } from "../utils/defaults";

let IS_THROTTLE = false;

const useFormikToastError = (formik) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!IS_THROTTLE) {
      if (formik.isSubmitting && Object.keys(formik.errors).length > 0) {
        IS_THROTTLE = true;
        dispatch(showError({ message: "Please check and enter valid values" }));
        setTimeout(() => {
          IS_THROTTLE = false;
        }, SNACKBAR_DURATION);
      }
    }
  }, [formik.isSubmitting, formik.errors, dispatch]);

  return null;
};

export { useFormikToastError };
