import { useDispatch } from "react-redux";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  IconButton,
  OutlinedInput,
  Tooltip,
} from "@mui/material";
import {
  useDeleteFileMutation,
  useEditFileMutation,
  useGetFilesQuery,
} from "../../../../features/settings/filemanager/filemanagerApiSlice";
import { showError, showSuccess } from "../../../../features/snackbar/snackbarAction";
import { formatBytes } from "../../../../utils/helper";
import cancel from "../../../../assets/icons/cancel.svg";
import info from "../../../../assets/icons/info.svg";
import downloadIcon from "../../../../assets/icons/filepopup/download.svg";
import deleteIcon from "../../../../assets/icons/filepopup/delete.svg";
import linkAngled from "../../../../assets/icons/folderdropdown/linkAngled.svg";
import { useFormik } from "formik";
import { useMemo, useState } from "react";
import * as Yup from "yup";
import apiSlice from "../../../../app/api/apiSlice";
import { CopyToClipboard } from "react-copy-to-clipboard";

const formValidationSchema = Yup.object({
  name: Yup.string().trim().required("required"),
  description: Yup.string().trim(),
});

export default function FilePreviewDialog({
  fileId = "",
  headingText = "",
  subText = "",
  buttonText = "",
  onClose = () => {},
}) {
  const dispatch = useDispatch();
  const [copied, setCopied] = useState(false);
  const [deleteFile] = useDeleteFileMutation();
  const [editFile, { isLoading: editFileIsLoading }] = useEditFileMutation();
  const { data: allFilesData } = useGetFilesQuery({ id: fileId }, { skip: !fileId });
  const fileData = allFilesData?.data?.data?.[0];
  const { fileType = "", name = "", description = "", module = "", file: url = "", filesize = 0 } = fileData ?? {};  

  const handleDownloadFile = async () => {
    try {
      const data = await fetch(`${url}?t=${Date.now().toString(36)}`);
      // const data = await fetch(url.replace(/^https:\/\//i, "http://"));
      if (!data.ok) throw new Error(`Download Failed status:${data.status} message:${data.statusText}`);
      const blob = await data.blob();
      const objectUrl = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.setAttribute("href", objectUrl);
      link.setAttribute("download", name + url.slice(url.lastIndexOf(".")));
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(objectUrl);
    } catch (error) {
      dispatch(showError({ message: error.message ?? "Something went wrong" }));
    }
  };

  const handleDeleteFile = () => {
    deleteFile(fileId)
      .unwrap()
      .then(() => {
        handleClose();
        dispatch(apiSlice.util.resetApiState());
        dispatch(showSuccess({ message: "File Deleted successfully" }));
      })
      .catch((e) => dispatch(showError({ message: e.message ?? "Something went wrong" })));
  };

  // const handleCopyLink = () => {
  //   if (!navigator.clipboard) {
  //     dispatch(showError({ message: "Clipboard API not supported in this browser." }));
  //     return;
  //   }
  //   try {
  //     navigator.clipboard.writeText(url);
  //     dispatch(showSuccess({ message: "File link copied." }));
  //   } catch (error) {
  //     console.log(error, 'error');
  //     dispatch(showError({ message: error.message ?? "Something went wrong" }));
  //   }
  // };

  const handleCopyLink = () => {
    setCopied(true);
    dispatch(showSuccess({ message: "File link copied." }));
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  const formInitialValues = useMemo(() => ({ name, description }), [name, description]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formInitialValues,
    validationSchema: formValidationSchema,
    onSubmit: (values) => {
      if (!values["description"]) delete values["description"];
      editFile({ id: fileId, fileData: values })
        .unwrap()
        .then(() => {
          handleClose();
          dispatch(showSuccess({ message: "File details updated successfully" }));
        })
        .catch((e) => dispatch(showError({ message: e.message ?? "Something went wrong" })));
    },
  });

  const handleClose = () => {
    formik.resetForm();
    onClose();
  };

  if (fileData)
    return (
      <Dialog
        fullWidth
        maxWidth="sm"
        open={!!fileId}
        onClose={handleClose}>
        <DialogTitle>
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex flex-column">
              {!!headingText && <span className="text-lightBlue fw-500">{headingText}</span>}
              {!!subText && (
                <small className="text-grey-6 fw-200">
                  <img
                    src={info}
                    alt="info"
                    className="pe-2"
                    width={20}
                  />
                  {subText}
                </small>
              )}
            </div>
            <img
              src={cancel}
              alt="cancel"
              width={24}
              onClick={handleClose}
              className="c-pointer"
            />
          </div>
        </DialogTitle>
        <hr className="hr-grey-6 m-0" />
        <DialogContent className="p-3">
          {fileType === "image" && (
            <div className="row px-2">
              <div
                style={{ height: "256px" }}
                className="d-flex align-items-center justify-content-center rounded-8 img-container">
                <img
                  src={url}
                  alt={fileType}
                  style={{ objectFit: "contain", height: "90%", width: "90%" }}
                />
              </div>
            </div>
          )}
          {fileType === "video" && (
            <div className="row px-2">
              <div
                style={{ height: "256px" }}
                className="d-flex align-items-center justify-content-center rounded-8 img-container">
                <video
                  controls
                  src={url}
                  style={{ objectFit: "contain", height: "90%", width: "90%" }}>
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          )}
          <div className="row align-items-center mt-3">
            <div className="col">
              <div className="text-grey-6 fw-200">
                {url.slice(url.lastIndexOf(".") + 1).toUpperCase()} • {formatBytes(filesize)}
                {module && module !== "others" && (
                  <>
                    <span className="text-green-2 mx-2">●</span>Linked to <span className="text-blue-2">{module}</span>
                  </>
                )}
              </div>
            </div>
            <div className="col-auto">
            <CopyToClipboard
                          onCopy={handleCopyLink}
                          text={url}
                        >
                          <Tooltip
                            title="Copy Link"
                            placement="top">
                            <img
                              src={linkAngled}
                              alt="icon"
                              width={25}
                            />
                          </Tooltip>
                        </CopyToClipboard>
              <IconButton onClick={handleDownloadFile}>
                <Tooltip
                  title="Download File"
                  placement="top">
                  <img
                    src={downloadIcon}
                    alt="icon"
                    width={25}
                  />
                </Tooltip>
              </IconButton>
              <IconButton onClick={handleDeleteFile}>
                <Tooltip
                  title="Delete File"
                  placement="top">
                  <img
                    src={deleteIcon}
                    alt="icon"
                    width={25}
                  />
                </Tooltip>
              </IconButton>
            </div>
          </div>
          <form
            id="editFileForm"
            onReset={formik.handleReset}
            onSubmit={formik.handleSubmit}
            className="row align-items-center">
            <div className="col-12 my-2">
              <p className="text-lightBlue fw-200 mb-1">
                File Name{" "}
                <Tooltip
                  title="Lorem ipsum"
                  placement="top">
                  <img
                    src={info}
                    alt="info"
                    className="c-pointer"
                    width={13.5}
                  />
                </Tooltip>
              </p>
              <FormControl className="w-100 px-0">
                <OutlinedInput
                  size="small"
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="URL handle"
                />
                <FormHelperText error>{formik.touched.name && formik.errors.name}</FormHelperText>
              </FormControl>
            </div>
            <div className="col-12 my-2">
              <p className="text-lightBlue fw-200 mb-1">
                Alt Text{" "}
                <Tooltip
                  title="Lorem ipsum"
                  placement="top">
                  <img
                    src={info}
                    alt="info"
                    className="c-pointer"
                    width={13.5}
                  />
                </Tooltip>
              </p>
              <FormControl className="w-100 px-0">
                <OutlinedInput
                  size="small"
                  name="description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Alt Text"
                />
                <FormHelperText error>{formik.touched.description && formik.errors.description}</FormHelperText>
              </FormControl>
            </div>
          </form>
        </DialogContent>
        <hr className="hr-grey-6 m-0" />
        <DialogActions className="d-flex justify-content-between px-4 py-3">
          <button
            type="reset"
            form="editFileForm"
            onClick={handleClose}
            disabled={editFileIsLoading}
            className="button-grey-outline py-2 px-4">
            <p className="text-lightBlue">Cancel</p>
          </button>
          <button
            type="submit"
            form="editFileForm"
            disabled={editFileIsLoading}
            className="button-gradient py-2 px-4">
            <p>{buttonText}</p>
          </button>
        </DialogActions>
      </Dialog>
    );
  else return null;
}