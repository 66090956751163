import { useState } from 'react';
import { Popover } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import "./ThemeSideNav.scss";

import india from "../../../../assets/flag/india.svg";
import indiaFlag from "../../../../assets/images/products/indiaFlag.svg";
import usaFlag from "../../../../assets/images/products/usaFlag.svg";
import ukFlag from "../../../../assets/images/products/ukFlag.svg";

const flagData = [
  { flag: indiaFlag, name: "India (Default)" },
  { flag: usaFlag, name: "USA" },
  { flag: ukFlag, name: "UK" }
]

const HomeCountry = () => {
  const [anchorOption, setAnchorOption] = useState(null);
  const openOption = Boolean(anchorOption);
  const idOption = openOption ? "simple-popover" : undefined;

  const handleOption = (event) => {
    setAnchorOption(event.currentTarget);
  };

  const handleOptionClose = () => {
    setAnchorOption(null);
  };

  return (
    <>
      <div
        className="d-flex align-items-center bg-black-20 py-1 px-2 rounded-8 c-pointer"
        onClick={handleOption}
      >
        <div className='d-flex align-items-center'>
          <img src={india} alt="indiaFlag" height={12} />
          <p className="text-lightBlue mx-2 fw-500">India</p>
        </div>
        <ArrowDropDownIcon sx={{ color: "#C8D8FF" }} />
      </div>
      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        id={idOption}
        open={openOption}
        anchorEl={anchorOption}
        onClose={handleOptionClose}
      >
        <div className="px-1 py-2">
          {flagData.map((item) => (
            <div className="d-flex align-items-center c-pointer hover-back px-3 py-2 my-1 rounded-3">
              <img src={item.flag} alt="allFlag" height={15} />
              <small className="ms-2 text-lightBlue">{item.name}</small>
            </div>
          ))}
        </div>
      </Popover>
    </>
  );
};

export default HomeCountry;