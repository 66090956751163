import InsertLinkIcon from "@mui/icons-material/InsertLink";
import NoDataFound from "../../components/NoDataFound/NoDataFound";
import TableLoader from "../../components/Loader/TableLoader";
import { TablePagination } from "@mui/material";

const PAGINATION_ROWS = [10, 20, 30];

const SettingTable = ({
  tableTitle,
  editItem,
  error,
  isLoading,
  data,
  totalCount,
  onPageChange,
  onPageSize,
  pageSize,
  page,
  onEdit,
  onDelete,
}) => {
  const onRowsPerPageChange = (e) => {
    onPageSize(e.target.value);
  };

  if (error) {
    return <></>;
  }

  if (isLoading) {
    return <TableLoader />;
  }

  if (!data) {
    return <></>;
  }

  if (data && !data.length) {
    return <NoDataFound />;
  }
  return (
    <div className="mb-3">
      <div className="d-flex justify-content-between rounded-8 bg-black-13 p-4 text-lightBlue w-100">
        <h6>{tableTitle}</h6>
        <h6>Action</h6>
      </div>
      {data?.map((item) => (
        <div
          key={item._id}
          className="d-flex justify-content-between p-4">
          <p className="text-lightBlue">{item.name}</p>
          <div className="d-flex">
            {/* <InsertLinkIcon
              sx={{
                color: '#c8d8ff',
                fontSize: 25,
                cursor: 'pointer',
              }}
            /> */}
            <p
              className="text-blue-2 c-pointer mx-4"
              onClick={onEdit.bind(null, item._id)}>
              Edit
            </p>
            <p
              className="text-red-5 c-pointer"
              onClick={onDelete.bind(null, {
                id: item._id,
                message: `<span className='text-blue-2'>${item.name}</span>`,
              })}>
              Delete
            </p>
          </div>
        </div>
      ))}
      <TablePagination
        rowsPerPageOptions={PAGINATION_ROWS}
        component="div"
        count={totalCount || 0}
        rowsPerPage={pageSize}
        page={page - 1}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        className="table-pagination"
      />
    </div>
  );
};

export default SettingTable;
