import React, { forwardRef, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useCreateCustomerMutation } from "../../../features/customers/customer/customerApiSlice";
import { useGetAllCustomerGroupQuery } from "../../../features/customers/customerGroup/customerGroupApiSlice";
import { useFormik } from "formik";
import { omitEmptyKeys } from "../../../utils/helper";
import { showError, showSuccess } from "../../../features/snackbar/snackbarAction";
import {
  Autocomplete,
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Slide,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import * as Yup from "yup";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CancelIcon from "@mui/icons-material/Cancel";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import userIcon from "../../../assets/icons/userIcon.svg";
import cancelIcon from "../../../assets/icons/cancel.svg";
import indiaFlagIcon from "../../../assets/images/products/indiaFlag.svg";
import cancel from "../../../assets/icons/cancel.svg";
import locationGradientIcon from "../../../assets/icons/locationGradient.svg";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import AppMobileCodeSelect from "../../../components/AppMobileCodeSelect/AppMobileCodeSelect";
import AppCountrySelect from "../../../components/AppCountrySelect/AppCountrySelect";
import AppCitySelect from "../../../components/AddCitySelect/AddCitySelect";
import AppStateSelect from "../../../components/AppStateSelect/AppStateSelect";
import ZipCodeComponent from "../../../components/ZipCodeCityComponent";
import { useCreateCustomerAddressMutation } from "../../../features/customers/customerAddress/customerAddressApiSlice";

const Transition = forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="up"
      ref={ref}
      {...props}
    />
  );
});

const initialValues = {
  // name: "",
  firstName: "",
  lastName: "",
  companyName: "",
  phone: "",
  countryCode: "",
  line1: "",
  line2: "",
  city: "",
  pinCode: "",
  state: "",
  country: "",
  customerId: "",
  isVerified: false,
  email: "",
  type: "",
  isDefaultAddress: false,
  gstIn: "",
};

const validationSchema = Yup.object({
  // name: Yup.string().trim().min(3, "Must be 3 characters"),
  firstName: Yup.string().trim().min(3, "Must be 3 characters").required("Required"),
  lastName: Yup.string().trim().min(3, "Must be 3 characters"),
  companyName: Yup.string().min(3, "Must be 3 characters"),
  email: Yup.string().email("Please enter valid email").required("Required"),
  phone: Yup.string()
    .matches(/^\d{10}$/, "Must be 10 digits")
    .required("Required"),
  countryCode: Yup.string().required("Required"),
  line1: Yup.string().required("Required"),
  line2: Yup.string(),
  city: Yup.string().required("Required"),
  pinCode: Yup.string()
    .matches(/^\d{6}$/, "Must be 6 digits")
    .required("Required"),
  state: Yup.string().required("Required"),
  country: Yup.string().required("Required"),
  customerId: Yup.string().required("Required"),
  isVerified: Yup.boolean().default(false),
  type: Yup.string().oneOf(["shipping", "billing"]).required("Required"),
  isDefaultAddress: Yup.boolean().required(),
  gstin: Yup.string().matches(
    /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/,
    "Invalid GSTIN"
  ),
});

export default function NewAddressDialog({
  show = "",
  type = "",
  customerId = "",
  onClose = () => {},
  setSelectedAddress = () => {},
}) {
  const dispatch = useDispatch();
  const [createCustomerAddress, { isLoading }] = useCreateCustomerAddressMutation();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...initialValues,
      type,
      customerId,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log(omitEmptyKeys(values));
      createCustomerAddress(omitEmptyKeys(values))
        .unwrap()
        .then((data) => setSelectedAddress(data?.data?._id ?? ""))
        .catch((e) => {
          console.log(e);
          dispatch(
            showError({
              message: e?.data?.message ?? e?.message ?? "Something went wrong!",
            })
          );
        });
    },
  });

  const closeDialog = () => {
    if (!isLoading) {
      formik.resetForm();
      onClose();
    }
  };

  return (
    <Dialog
      open={show}
      TransitionComponent={Transition}
      keepMounted
      onClose={closeDialog}
      maxWidth="sm"
      scroll="paper"
      fullWidth>
      <DialogTitle>
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex flex-column ">
            <h5 className="text-lightBlue text-capitalize fw-500">Add New {type} Address</h5>
          </div>
          <img
            src={cancel}
            alt="cancel"
            width={30}
            onClick={closeDialog}
            className="c-pointer"
          />
        </div>
      </DialogTitle>
      <hr className="hr-grey-6 my-0" />
      <DialogContent className="pb-4 px-4">
        <div className="row rounded-8">
          {/* <div className="col-md-12">
            <p className="text-lightBlue mb-1">
              Address Name <span style={{ color: "#F67476" }}>*</span>
            </p>
            <FormControl className="w-100 px-0">
              <OutlinedInput
                placeholder="Office Address, Home Address"
                size="small"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
              />
            </FormControl>
            {!!formik.touched.name && formik.errors.name && (
              <FormHelperText error>{formik.errors.name}</FormHelperText>
            )}
            <small className="text-grey-6">
              Name this address Ex. Office Address, Home Address
            </small>
          </div>
          <div className="col-12">
            <FormControlLabel
              control={
                <Checkbox
                  name="isDefaultAddress"
                  value={formik.values.isDefaultAddress}
                  onChange={formik.handleChange}
                  size="small"
                  style={{ color: "#5C6D8E" }}
                />
              }
              label="Set as Default Address"
              sx={{
                "& .MuiTypography-root": {
                  fontSize: 13,
                  color: "#c8d8ff",
                },
              }}
            />
          </div> */}
          <div className="col-md-6 mt-3">
            <p className="text-lightBlue mb-1">
              First Name <span style={{ color: "#F67476" }}>*</span>
            </p>
            <FormControl className="w-100 px-0">
              <OutlinedInput
                placeholder="Enter First Name"
                size="small"
                name="firstName"
                value={formik.values.firstName}
                onChange={formik.handleChange}
              />
            </FormControl>
            {!!formik.touched.firstName && formik.errors.firstName && (
              <FormHelperText error>{formik.errors.firstName}</FormHelperText>
            )}
          </div>
          <div className="col-md-6 mt-3">
            <p className="text-lightBlue mb-1">
              Last Name <span style={{ color: "#F67476" }}>*</span>
            </p>
            <FormControl className="w-100 px-0">
              <OutlinedInput
                placeholder="Enter Last Name"
                size="small"
                name="lastName"
                value={formik.values.lastName}
                onChange={formik.handleChange}
              />
            </FormControl>
            {!!formik.touched.lastName && formik.errors.lastName && (
              <FormHelperText error>{formik.errors.lastName}</FormHelperText>
            )}
          </div>
          {formik.values.type === "billing" && (
            <>
              <div className="col-md-12 mt-3">
                <p className="text-lightBlue mb-1">Company Name</p>
                <FormControl className="w-100 px-0">
                  <OutlinedInput
                    placeholder="Enter company name"
                    size="small"
                    name="companyName"
                    value={formik.values.companyName}
                    onChange={formik.handleChange}
                  />
                </FormControl>
              </div>
              <div className="col-md-12 mt-3">
                <p className="text-lightBlue mb-1">GSTIN</p>
                <FormControl className="w-100 px-0">
                  <OutlinedInput
                    placeholder="Enter GSTIN"
                    size="small"
                    name="gstIn"
                    value={formik.values.gstIn}
                    onChange={formik.handleChange}
                  />
                </FormControl>
              </div>
            </>
          )}
          <div className="col-md-12 mt-3">
            <p className="text-lightBlue mb-1">
              Mobile Number <span style={{ color: "#F67476" }}>*</span>
            </p>
            <FormControl className="w-100 px-0">
              <OutlinedInput
                placeholder="Enter Mobile Number"
                size="small"
                sx={{ paddingLeft: 0 }}
                name="phone"
                value={formik.values.phone}
                onChange={formik.handleChange}
                startAdornment={
                  <InputAdornment position="start">
                    <AppMobileCodeSelect
                      formik={formik}
                      countryCode={formik.values.countryCode}
                      handleCode={(_, value) => formik.setFieldValue("countryCode", value?._id)}
                    />
                    {/* <OutlinedInput
                        placeholder="CC"
                        size="small"
                        sx={{ paddingLeft: 0 }}
                        name="countryCode"
                        value={formik.values.countryCode}
                        onChange={formik.handleChange}
                      /> */}
                  </InputAdornment>
                }
              />
            </FormControl>
            {(!!formik.touched.countryCode || !!formik.touched.phone) &&
              (formik.errors.countryCode || formik.errors.phone) && (
                <FormHelperText error>
                  {formik.errors.countryCode || formik.errors.phone}
                </FormHelperText>
              )}
          </div>
          <div className="col-md-12 mt-3">
            <p className="text-lightBlue mb-1">Email</p>
            <FormControl className="w-100 px-0">
              <OutlinedInput
                placeholder="Enter email"
                size="small"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
              />
            </FormControl>
            {!!formik.touched.email && formik.errors.email && (
              <FormHelperText error>{formik.errors.email}</FormHelperText>
            )}
          </div>
          <ZipCodeComponent
            cityVal={formik.values.city}
            onchangeCity={(_, value) => formik.setFieldValue("city", value?._id)}
            errorCity={formik.errors.city}
            touchedCity={formik.touched.city}
            stateVal={formik.values.state}
            onchangeState={(_, value) => formik.setFieldValue("state", value?._id)}
            errorState={formik.errors.state}
            touchedState={formik.touched.state}
            countryVal={formik.values.country}
            onchangeCountry={(_, value) => formik.setFieldValue("country", value?._id)}
            errorCountry={formik.errors.country}
            touchedCountry={formik.touched.country}
            zipVal={formik.values.pinCode}
            onchangeZip={formik.handleChange}
            errorZip={formik.errors.pinCode}
            touchedZip={formik.touched.pinCode}
            onchangeLine1={(line) => formik.setFieldValue("line1", line)}
            onchangeLine2={(line) => formik.setFieldValue("line2", line.target.value)}
            touchedLine1={formik.touched.line1}
            errorLine1={formik.errors.line1}
            nameLine2="line2"
            namePincode="pinCode"
            valueLine2={formik.values.line2}
            onchangePincode={(code) => formik.setFieldValue("pinCode", code)}
            line1Val={formik.values.line1}
          />
        </div>
      </DialogContent>
      <hr className="hr-grey-6 my-0" />
      <DialogActions className="d-flex justify-content-between px-4 py-3">
        <button
          className="button-grey py-2 px-5"
          disabled={isLoading}
          onClick={closeDialog}>
          <p className="text-lightBlue">Cancel</p>
        </button>
        <button
          className="button-gradient py-2 px-5"
          disabled={isLoading}
          onClick={formik.handleSubmit}>
          <p>Create</p>
        </button>
      </DialogActions>
    </Dialog>
  );
}
