import { AgGridReact } from "ag-grid-react";
// import GridTitleRenderer from "../../../../components/ProductBulk/GridTitleRenderer";
// import GridCostRenderer from "../../../../components/ProductBulk/GridCostRenderer";
// import GridTaxRenderer from "../../../../components/ProductBulk/GridTaxRenderer";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  useGetVariantBulkDataQuery,
  useUpdateVariantBulkDataMutation,
} from "../../../../features/products/product/varianceApiSlice";
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import arrowLeft from "../../../../assets/icons/arrowLeft.svg";
import TableLoader from "../../../../components/Loader/TableLoader";
import NoDataFound from "../../../../components/NoDataFound/NoDataFound";
import { Checkbox, FormControlLabel, MenuItem, Select } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useDispatch } from "react-redux";
import { showError, showSuccess } from "../../../../features/snackbar/snackbarAction";

const defaultColDef = {
  flex: 1,
  minWidth: 120,
  resizable: true,
  editable: true,
  sortable: true,
  filter: true,
};

export default function VariantBulkEditor() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pid = "", sid = "" } = useParams();
  const [searchParams] = useSearchParams();
  const title = searchParams.get("title") ?? "N/A";
  const count = searchParams.get("count") ?? "0";

  const { data, isLoading, isError } = useGetVariantBulkDataQuery(
    { id: pid, queries: { store: sid } },
    { skip: !pid || !sid }
  );

  const [updateData, { isLoading: updateIsLoading }] = useUpdateVariantBulkDataMutation();

  const [initStringify, setInitStringify] = useState("[]");
  const [rowData, setRowData] = useState([]);

  const discardable = useMemo(
    () => initStringify !== JSON.stringify(rowData),
    [initStringify, rowData]
  );

  console.log({ discardable, initStringify });

  const saveData = () => {
    updateData(rowData)
      .unwrap()
      .then(() => {
        // navigate(-1);
        setInitStringify(JSON.stringify(rowData));
        dispatch(showSuccess({ message: "Variants shall be updated soon" }));
      })
      .catch((e) => {
        console.log(e);
        dispatch(
          showError({
            message: e?.data?.message ?? e?.message ?? "Something went wrong",
          })
        );
      });
  };

  const setData = useCallback(
    (data) =>
      setRowData((prev) => {
        if (prev.length === 0) setInitStringify(JSON.stringify(data));
        return data;
      }),
    []
  );

  return (
    <div className="container-fluid page">
      <div className="d-flex justify-content-between align-items-center">
        <span
          onClick={() => navigate(-1)}
          className="d-flex align-items-baseline w-auto c-pointer">
          <img
            src={arrowLeft}
            alt="arrowLeft"
            width={9}
            // className="c-pointer"
          />
          <div className="ms-2">
            <h4 className="page-heading w-auto ps-0">{title}</h4>
            <div className="d-flex ms-2 ps-1 mt-1">
              <small className="text-lightBlue me-2">Variant Bulk Editor ({count})</small>
            </div>
          </div>
        </span>
        {discardable && (
          <LoadingButton
            onClick={saveData}
            loading={updateIsLoading}
            disabled={updateIsLoading}
            className="button-gradient py-2 px-4">
            <p>Save Changes</p>
          </LoadingButton>
        )}
      </div>
      <div className="row mt-4">
        {isLoading && (
          <div className="col-12 px-0">
            <TableLoader />
          </div>
        )}
        {isError && (
          <div className="col-12 px-0">
            <NoDataFound />
          </div>
        )}
        {!isLoading && !isError && (
          <Grid
            data={data}
            setData={setData}
          />
        )}
      </div>
    </div>
  );
}

function Grid({ data = [], setData = () => {} }) {
  const columnDefs = useMemo(() => {
    const defs = [
      {
        field: "title",
        headerName: "Title",
        editable: false,
        pinned: "left",
        width: 280,
        lockPinned: true,
      },
      // { field: "mediaUrl", headerName: "mediaUrl" },
    ];

    const refData = data[0];

    if (refData.isDynamicPricing) {
      const metals = refData.priceBreakup
        .filter((pbk) => pbk.priceType === "metalPrice")
        .map((pb, ix) => ({
          field: `metal_${ix}_weight`,
          headerName: `Metal ${ix + 1} Weight`,
          cellDataType: "number",
        }));
      if (metals.length > 0)
        metals.push({
          field: "metal_discount",
          headerName: "Metal Discount",
          cellDataType: "number",
        });

      const diamonds = refData.priceBreakup
        .filter((pbk) => pbk.priceType === "diamondPrice")
        .map((pb, ix) => ({
          field: `diamond_${ix}_weight`,
          headerName: `Diamond ${ix + 1} Weight`,
          cellDataType: "number",
        }));
      if (diamonds.length > 0)
        diamonds.push({
          field: "diamond_discount",
          headerName: "Diamond Discount",
          cellDataType: "number",
        });

      defs.push(
        ...metals,
        ...diamonds,
        {
          field: "net_weight",
          headerName: "Net Weight",
          cellDataType: "number",
        },
        {
          field: "making_discount",
          headerName: "Making Discount",
          cellDataType: "number",
        }
      );
    } else {
      defs.push({
        field: "sale_price",
        headerName: "Sale Price",
        cellDataType: "number",
      });
    }

    const commonDefs = [
      {
        field: "negative_sell",
        headerName: "Sell When Out of Stock",
        cellDataType: "boolean",
        cellEditor: "negativeEditor",
        cellRendererParams: {
          checkbox: true,
        },
      },
      // { field: "total_price", headerName: "Total Price" },
      {
        field: "discount_type",
        headerName: "Discount Type",
        cellDataType: "text",
        cellEditor: "discountTypeEditor",
        cellEditorParams: {
          values: ["percentage", "fixed"],
        },
      },
      {
        field: "total_discount",
        headerName: "Total Discount",
        cellDataType: "number",
      },
      {
        field: "quantity",
        headerName: "Quantity",
        cellDataType: "number",
      },
      {
        field: "bar_code",
        headerName: "Bar Code",
        cellDataType: "text",
      },
      {
        field: "is_default",
        headerName: "Default",
        cellDataType: "boolean",
        cellEditor: "defaultEditor",
        cellRendererParams: {
          checkbox: true,
        },
      },
      {
        field: "shipping_weight",
        headerName: "Shipping Weight",
        cellDataType: "number",
      },
      {
        field: "shipping_length",
        headerName: "Shipping Length",
        cellDataType: "number",
      },
      {
        field: "shipping_width",
        headerName: "Shipping Width",
        cellDataType: "number",
      },
      {
        field: "shipping_height",
        headerName: "Shipping Height",
        cellDataType: "number",
      },
    ];

    defs.push(...commonDefs);

    return defs;
  }, [data]);

  const [rowData, setRowData] = useState([]);

  useEffect(() => {
    setRowData(
      data.map((dat) => {
        const obj = {
          _id: dat._id,
          title: dat.title,
          // mediaUrl: dat.mediaUrl,
        };

        if (dat.isDynamicPricing) {
          dat.priceBreakup
            .filter((pbk) => pbk.priceType === "metalPrice")
            .forEach((pb, ix) => {
              if (ix === 0) obj["metal_discount"] = pb.discountValue;
              obj[`metal_${ix}_weight`] = pb.weight;
            });

          dat.priceBreakup
            .filter((pbk) => pbk.priceType === "diamondPrice")
            .forEach((pb, ix) => {
              if (ix === 0) obj["diamond_discount"] = pb.discountValue;
              obj[`diamond_${ix}_weight`] = pb.weight;
              obj[`diamond_${ix}_globalPriceManagerId`] = pb.globalPriceManagerId;
            });

          const dis = dat.priceBreakup.find((pbk) => "discount" in pbk);

          if (dis) {
            obj["discount_type"] = dis.type;
            obj["total_discount"] = dis.discount;
          }

          const mds = dat.priceBreakup.find((pbk) => pbk.priceType === "makingCharge");

          if (mds) {
            obj["making_discount"] = mds.discountValue;
          }

          obj.negative_sell = dat.inventory.isNegativeSellAllowed;
          obj.net_weight = dat.netWeight;
          // obj.total_price = dat.totalPrice;
          obj.quantity = dat.quantity;
          obj.bar_code = dat.barCode;
          obj.is_default = dat.isDefault;
          obj.shipping_weight = dat.shipping.weight;
          obj.shipping_length = dat.shipping.length;
          obj.shipping_width = dat.shipping.width;
          obj.shipping_height = dat.shipping.height;
        } else {
          const refPb = dat.priceBreakup[0];
          obj.negative_sell = dat.inventory.isNegativeSellAllowed;
          // obj.total_price = refPb.price;
          obj.discount_type = refPb.type;
          obj.total_discount = refPb.discount;
          obj.sale_price = refPb.salePrice;
          obj.quantity = dat.quantity;
          obj.bar_code = dat.barCode;
          obj.is_default = dat.isDefault;
          obj.shipping_weight = dat.shipping.weight;
          obj.shipping_length = dat.shipping.length;
          obj.shipping_width = dat.shipping.width;
          obj.shipping_height = dat.shipping.height;
        }

        return obj;
      })
    );
  }, [data]);

  useEffect(() => {
    setData(rowData);
    // setDiscardable(discardable);
    // }, [discardable, rowData, setData, setDiscardable]);
  }, [rowData, setData]);

  const getRowId = useMemo(() => {
    return (params) => params.data._id;
  }, []);

  const onCellValueChanged = useCallback((params) => {
    // console.log(params.data._id, params.colDef.field, params.newValue);
    if (params.colDef.field === "is_default") {
      if (params.newValue) {
        setRowData((prevData) =>
          prevData.map((data) =>
            data._id === params.data._id
              ? { ...data, [params.colDef.field]: params.newValue }
              : { ...data, [params.colDef.field]: !params.newValue }
          )
        );
      }
    } else {
      setRowData((prevData) =>
        prevData.map((data) =>
          data._id === params.data._id
            ? { ...data, [params.colDef.field]: params.newValue }
            : data
        )
      );
    }

    const api = params.api;
    const renderedRows = api.getRenderedNodes();
    const currentRowIndex = renderedRows.findIndex((row) => row.data._id === params.data._id);

    if (currentRowIndex !== -1 && currentRowIndex !== renderedRows.length - 1) {
      const nextRowIndex = currentRowIndex + 1;
      // const nextRowNode = renderedRows[nextRowIndex];
      api.ensureIndexVisible(nextRowIndex, "bottom");
      api.setFocusedCell(nextRowIndex, params.colDef.field);
    }
  }, []);

  const components = useMemo(
    () => ({
      discountTypeEditor: DiscountTypeEditor,
      defaultEditor: CheckBoxEditor,
      negativeEditor: CheckBoxEditor,
    }),
    []
  );

  return (
    <div className="col-12 px-0">
      <div style={{ width: "100%", height: "calc(100vh - 172px)" }}>
        <div
          style={{ height: "100%", width: "100%" }}
          className="ag-theme-alpine-dark">
          <AgGridReact
            editable
            animateRows
            rowData={rowData}
            getRowId={getRowId}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            onCellValueChanged={onCellValueChanged}
            components={components}
            // onGridReady={onGridReady}
          ></AgGridReact>
        </div>
      </div>
    </div>
  );
}

const DiscountTypeEditor = forwardRef((props, ref) => {
  const [value, setValue] = useState(props.value);

  useEffect(() => {
    // Set the initial value when the component is mounted
    setValue(props.value);
  }, [props.value]);

  useImperativeHandle(ref, () => ({
    getValue: () => value,
    setValue: (newValue) => setValue(newValue),
  }));

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <Select
      size="small"
      name="field"
      sx={{ minWidth: "8rem" }}
      value={value}
      onChange={handleChange}>
      <MenuItem
        value="percentage"
        sx={{ fontSize: 13, color: "#5c6d8e" }}>
        Percentage
      </MenuItem>
      <MenuItem
        value="fixed"
        sx={{ fontSize: 13, color: "#5c6d8e" }}>
        Fixed
      </MenuItem>
    </Select>
  );
});

const CheckBoxEditor = forwardRef((props, ref) => {
  const [value, setValue] = useState(props.value);

  useEffect(() => {
    // Set the initial value when the component is mounted
    setValue(props.value);
  }, [props.value]);

  useImperativeHandle(ref, () => ({
    getValue: () => value,
    setValue: (newValue) => setValue(newValue),
  }));

  const handleChange = (event) => {
    setValue(event.target.checked);
  };

  return (
    <FormControlLabel
      control={
        <Checkbox
          size="small"
          checked={value}
          onChange={handleChange}
          style={{ color: "#5C6D8E" }}
        />
      }
    />
  );
});
