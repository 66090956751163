import apiSlice from "../../../app/api/apiSlice";

export const sizeChartApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllSizeCharts: builder.query({
      query: (queries = {}) => {
        const queryString = new URLSearchParams(queries).toString();
        return {
          url: `/functionality/sizechart?${queryString}`,
        };
      },
      providesTags: ["SizeChart"],
    }),

    // getSizeChartCount: builder.query({
    //   query: (queries = {}) => {
    //     const queryString = new URLSearchParams(queries).toString();

    //     return {
    //       url: `/store/count?${queryString}`,
    //     };
    //   },
    //   providesTags: ["SizeChart"],
    // }),

    createSizeChart: builder.mutation({
      query: (data) => ({
        url: "/functionality/sizechart",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["SizeChart", "Logs"],
    }),

    editSizeChart: builder.mutation({
      query: ({ id, details }) => ({
        url: `/functionality/sizechart/${id}`,
        method: "PUT",
        body: details,
      }),
      invalidatesTags: ["SizeChart", "Logs"],
    }),

    bulkEditSizeChart: builder.mutation({
      query: (updates) => ({
        url: "/functionality/sizechart/bulkUpdate",
        method: "PUT",
        body: updates,
      }),
      invalidatesTags: ["SizeChart", "Logs"],
    }),

    deleteSizeChart: builder.mutation({
      query: (id) => ({
        url: `/functionality/sizechart/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["SizeChart", "Logs"],
    }),

    bulkDeleteSizeChart: builder.mutation({
      query: (deletes) => ({
        url: "/functionality/sizechart/bulkDelete",
        method: "DELETE",
        body: deletes,
      }),
      invalidatesTags: ["SizeChart", "Logs"],
    }),
  }),
});

export const {
  useGetAllSizeChartsQuery,
  // useGetSizeChartCountQuery,
  useCreateSizeChartMutation,
  useEditSizeChartMutation,
  useBulkEditSizeChartMutation,
  useDeleteSizeChartMutation,
  useBulkDeleteSizeChartMutation,
} = sizeChartApiSlice;
