import { useEffect, useMemo, useReducer, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import _ from "lodash";

//MATERIAL UI IMPORTS
import {
  Tooltip,
  FormControl,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  FormHelperText,
  CircularProgress,
  Button,
} from "@mui/material";

//MATERIAL UI ICONS IMPORTS
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

//COMPONENTS IMPORT
import SideStoreBox from "../../../components/Setting/Store/SideBox.js";
import StoreSocial from "../../../components/Setting/Store/StoreSocial.js";
import { SaveFooterTertiary } from "../../../components/SaveFooter/SaveFooter.js";
import VerificationPopUp from "./PopUp/VerificationPopUp.js";
import AppMobileCodeSelect from "../../../components/AppMobileCodeSelect/AppMobileCodeSelect.js";
import { DiscardModalSecondary } from "../../../components/Discard/DiscardModal.js";

//ASSETS IMPORT
import info from "../../../assets/icons/info.svg";
import fb from "../../../assets/setting/icons/Facebook.svg";
import insta from "../../../assets/setting/icons/Instagram.svg";
import pin from "../../../assets/setting/icons/Pinterest.svg";
import tweet from "../../../assets/setting/icons/Twitter.svg";
import youtube from "../../../assets/setting/icons/Youtube.svg";

//API IMPORTS
import {
  useCreateStoreInfoMutation,
  useEditStoreInfoMutation,
  useGetAllStoreDetailsQuery,
} from "../../../features/storeDetails/storeInfoApiSlice.js";
import { useGetAllCountryQuery } from "../../../features/master/country/countryApiSlice.js";
import { useSendOtpMutation } from "../../../features/otp/otpApiSlice.js";

//UTILITY FUNCTIONS
import { socialHandleUrlReg } from "../../../utils/regex.js";
import { omitEmptyKeys } from "../../../utils/helper.js";
import { showError, showSuccess } from "../../../features/snackbar/snackbarAction.js";
import { useNavigate } from "react-router-dom";
import AddAddress from "../../../components/AddAddress/AddAddress.js";

const initialQueryFilterState = {
  userData: null,
};
const queryFilterReducer = (state, action) => {
  if (action.type === "SET_USER_DATA") {
    return {
      ...state,
      userData: action.userData,
    };
  }
  return initialQueryFilterState;
};

const initialStoreInfoState = {
  isEditing: false,
  edited: false,
};
const storeInfoReducer = (state, action) => {
  if (action.type === "ENABLE_EDIT") {
    return {
      ...state,
      isEditing: true,
    };
  }
  if (action.type === "DISABLE_EDIT") {
    return {
      ...state,
      isEditing: false,
    };
  }
  if (action.type === "EDITED_ENABLE") {
    return {
      ...state,
      edited: true,
    };
  }
  if (action.type === "EDITED_DISABLE") {
    return {
      ...state,
      edited: false,
    };
  }
  return initialStoreInfoState;
};

const contactValidationSchema = Yup.object().shape({
  isPhoneVerified: Yup.boolean(),
  isContactEmailVerified: Yup.boolean(),
  isSenderEmailVerified: Yup.boolean(),
  countryCode: Yup.string().required("Required"),
  phone: Yup.string()
    .required("Phone number is required")
    .matches(/^[0-9]{10}$/, "Invalid phone number")
    .test("is-verified", "Please validate your email", function (value) {
      return (
        this.parent.isPhoneVerified === true ||
        this.createError({ message: "Please validate your phone" })
      );
    }),

  contactEmail: Yup.string()
    .required("Email is required")
    .email("Invalid email format")
    .test("is-verified", "Please validate your email", function (value) {
      return (
        this.parent.isContactEmailVerified === true ||
        this.createError({ message: "Please validate your email" })
      );
    }),
  senderEmail: Yup.string()
    .required("Email is required")
    .email("Invalid email format")
    .test("is-verified", "Please validate your email", function (value) {
      return (
        this.parent.isSenderEmailVerified === true ||
        this.createError({ message: "Please validate your email" })
      );
    }),
});

const socialLinksSchema = Yup.object().shape({
  instagram: Yup.string()
    .matches(socialHandleUrlReg("instagram"), "Invalid Url")
    .test("no-spaces", "URL cannot contain spaces", (value) => !/\s/.test(value)),

  facebook: Yup.string()
    .matches(socialHandleUrlReg("facebook"), "Invalid Url")
    .test("no-spaces", "URL cannot contain spaces", (value) => !/\s/.test(value)),

  twitter: Yup.string()
    .matches(socialHandleUrlReg("twitter"), "Invalid Url")
    .test("no-spaces", "URL cannot contain spaces", (value) => !/\s/.test(value)),

  youtube: Yup.string()
    .matches(socialHandleUrlReg("youtube"), "Invalid Url")
    .test("no-spaces", "URL cannot contain spaces", (value) => !/\s/.test(value)),

  pinterest: Yup.string()
    .matches(socialHandleUrlReg("pinterest"), "Invalid Url")
    .test("no-spaces", "URL cannot contain spaces", (value) => !/\s/.test(value)),
});

const storeValidationSchema = Yup.object().shape({
  name: Yup.string().required("Store name is required"),
  domain: Yup.string()
    .required("Domain is required")
    .matches(/^\S*$/, "Domain cannot contain spaces")
    .min(3, "Domain must be at least 3 characters long"),
  industry: Yup.string().required("Industry is required"),
  contact: contactValidationSchema,
  socialLinks: socialLinksSchema,
});

const StoreInfo = () => {
  const dispatch = useDispatch();
  const [queryFilterState, dispatchQueryFilter] = useReducer(
    queryFilterReducer,
    initialQueryFilterState
  );
  const [storeInfoState, dispatchStoreInfo] = useReducer(
    storeInfoReducer,
    initialStoreInfoState
  );
  const contactRef = useRef(null);
  const phoneRef = useRef(null);
  const senderEmailRef = useRef(null);
  const navigate = useNavigate();
  const [showVerifyPopup, setShowVerifyPopup] = useState(false);
  const [otpVerifyType, setOtpVerifyType] = useState("");
  const [showResend, setShowResend] = useState(false);
  const [countdown, setCountdown] = useState(5);
  const [verifyData, setVerifyData] = useState("");
  const [phoneVerifyData, setPhoneVerifyData] = useState("");
  const [senderEmailVerifyData, setSenderEmailVerifyData] = useState("");
  const [showFooter, setShowFooter] = useState();

  const {
    data: storeInfoData,
    isLoading: storeInfoIsLoading,
    isSuccess: storeInfoIsSuccess,
    error: storeInfoError,
    isError: storeInfoIsError,
  } = useGetAllStoreDetailsQuery({
    userId: queryFilterState?.userData?.userId,
  });

  const {
    data: countryData,
    isLoading: countryIsLoading,
    isSuccess: countryIsSuccess,
    error: countryError,
  } = useGetAllCountryQuery({ createdAt: -1 });

  const [
    createStoreInfo,
    {
      isLoading: createStoreInfoIsLoading,
      isSuccess: createStoreInfoIsSuccess,
      error: createStoreInfoError,
      isError: createStoreInfoIsError,
    },
  ] = useCreateStoreInfoMutation();

  const [
    editStoreInfo,
    {
      isLoading: editStoreInfoIsLoading,
      isSuccess: editStoreInfoIsSuccess,
      error: editStoreInfoError,
      isError: editStoreInfoIsError,
    },
  ] = useEditStoreInfoMutation();

  const [
    sendOtp,
    {
      isLoading: sendOtpIsLoading,
      isSuccess: sendOtpIsSuccess,
      error: sendOtpError,
      isError: sendOtpIsError,
    },
  ] = useSendOtpMutation();

  const formik = useFormik({
    initialValues: {
      name: storeInfoData?.data?.data[0]?.name || "",
      domain: storeInfoData?.data?.data[0]?.domain || "",
      industry: storeInfoData?.data?.data[0]?.industry || "",
      contact: {
        countryCode: storeInfoData?.data?.data[0]?.contact?.countryCode?._id || "",
        phone:
          phoneVerifyData || storeInfoData?.data?.data[0]?.contact?.phone?.toString() || "",
        isPhoneVerified: storeInfoData?.data?.data[0]?.contact?.isPhoneVerified || false,
        contactEmail: verifyData || storeInfoData?.data?.data[0]?.contact?.contactEmail || "",
        isContactEmailVerified:
          storeInfoData?.data?.data[0]?.contact?.isContactEmailVerified || false,
        senderEmail:
          senderEmailVerifyData || storeInfoData?.data?.data[0]?.contact?.senderEmail || "",
        isSenderEmailVerified:
          storeInfoData?.data?.data[0]?.contact?.isSenderEmailVerified || false,
      },
      status: storeInfoData?.data?.data[0]?.status || "active",
      socialLinks: {
        facebook: storeInfoData?.data?.data[0]?.socialLinks?.facebook || "",
        twitter: storeInfoData?.data?.data[0]?.socialLinks?.twitter || "",
        instagram: storeInfoData?.data?.data[0]?.socialLinks?.instagram || "",
        youtube: storeInfoData?.data?.data[0]?.socialLinks?.youtube || "",
        pinterest: storeInfoData?.data?.data[0]?.socialLinks?.pinterest || "",
      },
    },
    enableReinitialize: true,
    validationSchema: storeValidationSchema,
    onSubmit: (values) => {
      setShowFooter(false);
      dispatchStoreInfo({ type: "EDITED_DISABLE" });
      const storeInfoValues = structuredClone(values);
      // storeInfoValues.contact.contactEmail = formik.values.contact.contactEmail === formik.initialValues.contact.contactEmail;
      storeInfoValues.name =
        formik.values.name !== formik.initialValues.name ? storeInfoValues.name : "";
      const editObj = omitEmptyKeys(storeInfoValues);
      editObj.socialLinks = omitEmptyKeys(storeInfoValues.socialLinks);
      // createStoreInfo(editObj);
      editStoreInfo({
        id: storeInfoData?.data?.data[0]?._id,
        details: editObj,
      });
    },
  });

  console.log(formik.errors);

  const handleOpenVerifyPhonePopup = () => {
    sendOtp({
      phone: formik?.values?.contact?.phone,
      mode: "phone ",
    });
    setShowVerifyPopup(true);
    setOtpVerifyType("phone");
    setShowResend(true);
  };

  const handleEditPhone = () => {
    formik.setFieldValue("contact.isPhoneVerified", false);
    setTimeout(() => {
      if (phoneRef.current) {
        phoneRef.current.focus();
      }
    }, 10);
  };

  const handleOpenVerifyContactEmailPopup = () => {
    sendOtp({
      email: formik?.values?.contact?.contactEmail,
      mode: "email",
    });
    setShowVerifyPopup(true);
    setOtpVerifyType("primaryEmail");
    setShowResend(true);
  };

  const handleEditContactEmail = () => {
    formik.setFieldValue("contact.isContactEmailVerified", false);
    setTimeout(() => {
      if (contactRef.current) {
        contactRef.current.focus();
      }
    }, 10);
  };

  const handleOpenVerifySenderEmailPopup = () => {
    sendOtp({
      email: formik?.values?.contact?.senderEmail,
      mode: "email",
    });
    setShowVerifyPopup(true);
    setShowResend(true);
    setOtpVerifyType("secondaryEmail");
  };

  const handleEditSenderEmail = () => {
    formik.setFieldValue("contact.isSenderEmailVerified", false);
    setTimeout(() => {
      if (senderEmailRef.current) {
        senderEmailRef.current.focus();
      }
    }, 10);
  };

  const closeVerifyPopup = () => {
    setShowVerifyPopup(false);
    setCountdown(5);
  };

  const handleResendClick = () => {
    if (otpVerifyType === "phone") {
      sendOtp({
        phone: formik?.values?.contact?.phone,
        mode: "phone ",
      });
    } else if (otpVerifyType === "primaryEmail") {
      sendOtp({
        email: formik?.values?.contact?.contactEmail,
        mode: "email ",
      });
    } else if (otpVerifyType === "secondaryEmail") {
      sendOtp({
        email: formik?.values?.contact?.senderEmail,
        mode: "email ",
      });
    }
    setShowResend(true);
    setCountdown(5);
  };

  const handleBlurContactEmail = (e) => {
    formik?.handleBlur(e);
    // if (formik?.values?.contact.contactEmail === formik?.initialValues?.contact?.contactEmail) {
    //   formik.setFieldValue("contact.isContactEmailVerified", true);
    // }
  };

  const handlePhoneBlur = (e) => {
    formik.handleBlur(e);
    // if (formik?.values?.contact.phone === formik?.initialValues?.contact?.phone) {
    //   formik.setFieldValue("contact.isPhoneVerified", true);
    // }
  };
  const handleSenderEmailBlur = (e) => {
    formik?.handleBlur(e);
    // if (formik?.values?.contact.senderEmail === formik?.initialValues?.contact?.senderEmail) {
    //   formik.setFieldValue("contact.isSenderEmailVerified", true);
    // }
  };

  const backHandler = () => {
    navigate(`/settings`);
    // window.location.reload();
    // formik?.resetForm();
  };

  const statusValue =
    formik?.values?.status === "active"
      ? "Active"
      : formik?.values?.status === "comingSoon"
      ? "Coming Soon"
      : "Under Maintenance";

  const statusColor =
    formik?.values?.status === "active"
      ? "#1AD598"
      : formik?.values?.status === "comingSoon"
      ? "#F9B959"
      : "#FC756E";

  useEffect(() => {
    const data = localStorage.getItem("user");
    dispatchQueryFilter({ type: "SET_USER_DATA", userData: JSON.parse(data) });
  }, []);

  useEffect(() => {
    if (!_.isEqual(formik.values, formik.initialValues)) {
      dispatchStoreInfo({ type: "ENABLE_EDIT" });
      dispatchStoreInfo({ type: "EDITED_ENABLE" });
    } else if (_.isEqual(formik.values, formik.initialValues)) {
      dispatchStoreInfo({ type: "EDITED_DISABLE" });
      dispatchStoreInfo({ type: "DISABLE_EDIT" });
    }
  }, [formik.initialValues, formik.values]);

  useEffect(() => {
    if (storeInfoIsSuccess) {
      dispatchStoreInfo({ type: "EDITED_DISABLE" });
    }
    if (storeInfoIsError) {
      if (storeInfoError?.data?.message) {
        dispatch(showError({ message: storeInfoError?.data?.message }));
      } else {
        dispatch(showError({ message: "Something went wrong, please try again" }));
      }
    }
  }, [storeInfoIsSuccess, storeInfoIsError, storeInfoError, editStoreInfoIsSuccess, dispatch]);

  useEffect(() => {
    if (editStoreInfoIsSuccess) {
      dispatch(
        showSuccess({
          message: `${formik?.values?.name} Edited successfully`,
        })
      );
    }
    if (editStoreInfoIsError) {
      if (editStoreInfoError?.data?.message) {
        dispatch(showError({ message: editStoreInfoError?.data?.message }));
      } else {
        dispatch(showError({ message: "Something went wrong, please try again" }));
      }
    }
  }, [
    editStoreInfoIsSuccess,
    dispatch,
    editStoreInfoIsError,
    editStoreInfoError,
    formik?.values?.name,
  ]);

  const { data, isLoading } = useGetAllStoreDetailsQuery();
  const billingAddress = useMemo(() => data?.data?.data?.[0]?.billingAddress, [data]);
  const [openAddress, setAddress] = useState(false);

  const handleAddressClose = () => {
    setAddress(false);
  };

  const openAddressModal = () => {
    setAddress(true);
  };

  return (
    <div
      className="page container-fluid position-relative"
      style={{ display: "grid", gridTemplateRows: "auto 1fr" }}>
      <form
        className="store-form "
        noValidate
        onSubmit={formik.handleSubmit}
        style={{ display: "grid", gridTemplateRows: "1fr auto" }}>
        <div className="col-lg-12 mt-3">
          <div className="row mb-2">
            <SideStoreBox
              heading={"Basic Information"}
              details="Provide basic information about your store"              
            />

            <div className="col-lg-8 d-flex align-items-center">
              <div className="row align-items-start">
                <div className="mt-4">
                  <div className="d-flex justify-content-between align-items-center mb-1">
                    <div className="d-flex">
                      <p className="text-lightBlue fw-500">Store Name</p>
                      <Tooltip
                        title="Some text"
                        placement="top">
                        <img
                          src={info}
                          alt="info"
                          className="ms-2 c-pointer"
                          width={13.5}
                        />
                      </Tooltip>
                    </div>
                  </div>
                  <FormControl className="w-100 px-0">
                    <OutlinedInput
                      name="name"
                      value={formik?.values?.name}
                      onChange={(e) => {
                        formik.handleChange(e);
                      }}
                      onBlur={formik?.handleBlur}
                      placeholder="Superthread"
                      size="small"
                    />
                  </FormControl>
                  {!!formik?.touched?.name && formik?.errors?.name && (
                    <FormHelperText error>{formik?.errors?.name}</FormHelperText>
                  )}
                </div>

                <div className="mt-4">
                  <div className="d-flex justify-content-between align-items-center mb-1">
                    <div className="d-flex">
                      <p className="text-lightBlue fw-500">Store Name Domain</p>
                      <Tooltip
                        title="Some text"
                        placement="top">
                        <img
                          src={info}
                          alt="info"
                          className="ms-2 c-pointer"
                          width={13.5}
                        />
                      </Tooltip>
                    </div>
                    <small className="text-blue-2 c-pointer">Connect new domain</small>
                  </div>
                  <FormControl className="w-100 px-0">
                    <OutlinedInput
                      name="domain"
                      value={formik?.values?.domain}
                      onChange={formik?.handleChange}
                      onBlur={formik?.handleBlur}
                      placeholder="Superthread"
                      size="small"
                      endAdornment={
                        <InputAdornment position="end">
                          <p className="text-grey-6">unthread.com</p>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                  {!!formik?.touched?.domain && formik?.errors?.domain && (
                    <FormHelperText error>{formik?.errors?.domain}</FormHelperText>
                  )}
                  <small className="text-grey-6 mt-2 d-block">
                    This is temporary domain, you can add custom domain in .
                    <small className="text-blue-2 c-pointer">Click here</small>
                  </small>
                </div>

                <div className="mt-4">
                  <p className="text-lightBlue mb-1">
                    Industry
                    <Tooltip
                      title="Some text"
                      placement="top">
                      <img
                        src={info}
                        alt="info"
                        className="c-pointer ms-2 mb-1"
                        width={13.5}
                      />
                    </Tooltip>
                  </p>
                  <FormControl
                    sx={{ m: 0, minWidth: 120, width: "100%" }}
                    size="small">
                    <Select
                      name="industry"
                      value={formik?.values?.industry}
                      onChange={formik?.handleChange}
                      onBlur={formik?.handleBlur}
                      labelId="demo-select-small"
                      id="demo-select-small"
                      size="small"
                      displayEmpty
                      renderValue={
                        formik?.values?.industry !== ""
                          ? undefined
                          : () => (
                              <span
                                style={{
                                  fontSize: "13px",
                                  color: "#5c6d8e",
                                  opacity: 0.5,
                                }}>
                                Select Industry
                              </span>
                            )
                      }>
                      <MenuItem
                        value="jewellery"
                        sx={{ fontSize: 13, color: "#5c6d8e" }}>
                        Jewellery
                      </MenuItem>
                      <MenuItem
                        value="e-commerce"
                        sx={{ fontSize: 13, color: "#5c6d8e" }}>
                        E-Commerce
                      </MenuItem>
                      <MenuItem
                        value="clothing"
                        sx={{ fontSize: 13, color: "#5c6d8e" }}>
                        Clothing
                      </MenuItem>
                      <MenuItem
                        value="watches"
                        sx={{ fontSize: 13, color: "#5c6d8e" }}>
                        Watches
                      </MenuItem>
                    </Select>
                  </FormControl>
                  {!!formik?.touched?.industry && formik?.errors?.industry && (
                    <FormHelperText error>{formik?.errors?.industry}</FormHelperText>
                  )}
                </div>
              </div>
            </div>
          </div>

          <hr className="hr-grey-6 my-4 w-100" />

          <div className="row mb-2">
            <SideStoreBox
              heading={"Contact Information"}
              details="Provide contact information for your store"
            />

            <div className="col-lg-8 d-flex align-items-center">
              <div className="row align-items-start">
                <div className="d-flex justify-content-between align-items-center mb-1">
                  <div className="d-flex">
                    <p className="text-lightBlue fw-500">Mobile Number</p>
                    <Tooltip
                      title="Some text"
                      placement="top">
                      <img
                        src={info}
                        alt="info"
                        className="ms-2 c-pointer"
                        width={13.5}
                      />
                    </Tooltip>
                  </div>
                  {formik?.values?.contact?.isPhoneVerified && (
                    <small
                      className="text-blue-2 c-pointer"
                      onClick={handleEditPhone}>
                      Edit
                    </small>
                  )}
                </div>
                <div className="col-lg-12 ">
                  <FormControl className="w-100 px-0">
                    <OutlinedInput
                      inputRef={phoneRef}
                      placeholder="Enter Mobile Number"
                      size="small"
                      sx={{ paddingLeft: 0 }}
                      disabled={formik?.values?.contact?.isPhoneVerified}
                      startAdornment={
                        <InputAdornment position="start">
                          <AppMobileCodeSelect
                            disabled={formik?.values?.contact?.isPhoneVerified}
                            formik={formik}
                            handleCode={(e, value) => {
                              formik.setFieldValue("contact.countryCode", value?._id);
                            }}
                            countryCode={formik?.values?.contact?.countryCode}
                            handlePhoneBlur={handlePhoneBlur}
                          />
                        </InputAdornment>
                      }
                      endAdornment={
                        // formik?.values?.contact?.phone !==
                        //   formik?.initialValues?.contact?.phone &&
                        !formik?.values?.contact?.isPhoneVerified &&
                        formik?.values?.contact?.phone &&
                        formik?.values?.contact?.countryCode ? (
                          <div
                            className="button-gradient"
                            onClick={handleOpenVerifyPhonePopup}>
                            <small className="px-2 py-1">Send OTP to verify</small>
                          </div>
                        ) : (
                          formik?.values?.contact?.isPhoneVerified && (
                            <CheckCircleOutlineIcon
                              style={{ color: "green", marginLeft: "4" }}
                              sx={{
                                fontSize: "medium",
                              }}
                            />
                          )
                        )
                      }
                      value={formik.values.contact.phone}
                      onBlur={handlePhoneBlur}
                      onChange={formik.handleChange}
                      name="contact.phone"
                    />
                  </FormControl>
                  {formik?.values?.contact?.phone !== formik?.initialValues?.contact?.phone &&
                    (!!formik?.touched?.contact?.countryCode ||
                      !!formik?.touched?.contact?.phone) &&
                    (formik?.errors?.contact?.countryCode ||
                      formik?.errors?.contact?.phone) && (
                      <FormHelperText error>
                        {formik?.errors?.contact?.countryCode || formik?.errors?.contact?.phone}
                      </FormHelperText>
                    )}
                </div>
                <div className="mt-4">
                  <div className="d-flex justify-content-between align-items-center mb-1">
                    <div className="d-flex">
                      <p className="text-lightBlue fw-500">Store Contact Email</p>
                      <Tooltip
                        title="Some text"
                        placement="top">
                        <img
                          src={info}
                          alt="info"
                          className="ms-2 c-pointer"
                          width={13.5}
                        />
                      </Tooltip>
                    </div>
                    {formik?.values?.contact?.isContactEmailVerified && (
                      <small
                        className="text-blue-2 c-pointer"
                        onClick={handleEditContactEmail}>
                        Edit
                      </small>
                    )}
                  </div>
                  <FormControl className="w-100 px-0">
                    <OutlinedInput
                      name="contact.contactEmail"
                      inputRef={contactRef}
                      value={formik?.values?.contact?.contactEmail}
                      onChange={formik?.handleChange}
                      onBlur={handleBlurContactEmail}
                      disabled={formik?.values?.contact?.isContactEmailVerified}
                      placeholder="Enter Email ID"
                      size="small"
                      endAdornment={
                        // formik?.values?.contact?.contactEmail !==
                        //   formik?.initialValues?.contact?.contactEmail &&
                        !formik?.values?.contact?.isContactEmailVerified &&
                        formik?.values?.contact?.contactEmail ? (
                          <div
                            className="button-gradient"
                            onClick={handleOpenVerifyContactEmailPopup}>
                            <small className="px-2 py-1">Send OTP to verify</small>
                          </div>
                        ) : (
                          formik?.values?.contact?.isContactEmailVerified && (
                            <CheckCircleOutlineIcon
                              style={{ color: "green", marginLeft: "4" }}
                              sx={{
                                fontSize: "medium",
                              }}
                            />
                          )
                        )
                      }
                    />
                  </FormControl>
                  {formik?.values?.contact?.contactEmail !==
                    formik?.initialValues?.contact?.contactEmail &&
                    !!formik?.touched?.contact?.contactEmail &&
                    formik?.errors?.contact?.contactEmail && (
                      <FormHelperText error>
                        {formik?.errors?.contact?.contactEmail}
                      </FormHelperText>
                    )}
                </div>
                <div className="mt-4">
                  <div className="d-flex justify-content-between align-items-center mb-1">
                    <div className="d-flex">
                      <p className="text-lightBlue fw-500">Sender Email</p>
                      <Tooltip
                        title="Some text"
                        placement="top">
                        <img
                          src={info}
                          alt="info"
                          className="ms-2 c-pointer"
                          width={13.5}
                        />
                      </Tooltip>
                    </div>
                    {formik?.values?.contact?.isSenderEmailVerified && (
                      <small
                        className="text-blue-2 c-pointer"
                        onClick={handleEditSenderEmail}>
                        Edit
                      </small>
                    )}
                  </div>
                  <FormControl className="w-100 px-0">
                    <OutlinedInput
                      name="contact.senderEmail"
                      inputRef={senderEmailRef}
                      value={formik?.values?.contact?.senderEmail}
                      disabled={formik?.values?.contact?.isSenderEmailVerified}
                      onChange={formik?.handleChange}
                      onBlur={handleSenderEmailBlur}
                      placeholder="Enter Email ID"
                      size="small"
                      endAdornment={
                        // formik?.values?.contact?.senderEmail !==
                        //   formik?.initialValues?.contact?.senderEmail &&
                        !formik?.values?.contact?.isSenderEmailVerified &&
                        formik?.values?.contact?.senderEmail ? (
                          <div
                            className="button-gradient"
                            onClick={handleOpenVerifySenderEmailPopup}>
                            <small className="px-2 py-1">Send OTP to verify</small>
                          </div>
                        ) : (
                          formik?.values?.contact?.isSenderEmailVerified && (
                            <CheckCircleOutlineIcon
                              style={{ color: "green", marginLeft: "4" }}
                              sx={{
                                fontSize: "medium",
                              }}
                            />
                          )
                        )
                      }
                    />
                  </FormControl>
                  {formik?.values?.contact?.senderEmail !==
                    formik?.initialValues?.contact?.senderEmail &&
                    !!formik?.touched?.contact?.senderEmail &&
                    formik?.errors?.contact?.senderEmail && (
                      <FormHelperText error>
                        {formik?.errors?.contact?.senderEmail}
                      </FormHelperText>
                    )}
                </div>
              </div>
            </div>
          </div>
          <hr className="hr-grey-6 my-4 w-100" />

          <div className="row mb-2">
            <SideStoreBox
              heading={"Billing Address"}
              details="Provide billing address for your store"
            />
            <div className="col-lg-8 d-flex align-items-center">
              {isLoading ? (
                <CircularProgress />
              ) : billingAddress ? (
                <div>
                  <div className="d-flex justify-content-between mb-3">
                    {/* <div></div> */}
                    <small className="text-lightBlue me-3 d-block">
                      {billingAddress?.firstName} {billingAddress?.lastName}
                    </small>
                    <Button
                      size="small"
                      variant="text"
                      onClick={openAddressModal}>
                      <small className="text-blue-2">Edit</small>
                    </Button>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <small className="text-lightBlue me-3">
                      {billingAddress?.line1} {billingAddress?.line2} -{" "}
                      {billingAddress?.city?.name}, {billingAddress?.state?.name} -{" "}
                      {billingAddress?.pinCode}, {billingAddress?.country?.name}
                    </small>
                  </div>

                  <div className="d-flex mt-3">
                    <small className="text-grey-6 me-3 d-block me-3">
                      GSTIN:&nbsp;
                      <span className="text-lightBlue">{billingAddress?.gstIn ?? "-"}</span>
                    </small>
                  </div>

                  <div className="d-flex mt-2 align-items-center">
                    <small className="text-grey-6 me-3 d-block me-3">
                      Email ID:&nbsp;
                      <span className="text-lightBlue">{billingAddress?.email}</span>
                    </small>
                  </div>
                  <div className="d-flex mt-2 align-items-center">
                    <small className="text-grey-6 me-3">
                      Mobile No:&nbsp;
                      <span className="text-lightBlue">
                        {billingAddress?.countryCode?.countryCode}-{billingAddress?.phone}
                      </span>
                    </small>
                  </div>
                </div>
              ) : (
                <div
                  className="button-gradient py-2 px-4 ms-3"
                  onClick={openAddressModal}>
                  <p>Add Billing Address</p>
                </div>
              )}
            </div>
          </div>

          <hr className="hr-grey-6 my-4 w-100" />

          <div className="row mb-2">
            <SideStoreBox
              heading={"Store Website Status"}
              details="Select the status for your store"
            />

            <div className="col-lg-8 d-flex align-items-center">
              <div className="row align-items-start">
                <div className="mb-1">
                  <div className="d-flex">
                    <p className="text-lightBlue fw-500">
                      Status :
                      <span
                        style={{ color: statusColor }}
                        className="mr-1">
                        {statusValue}
                      </span>
                    </p>
                    <Tooltip
                      title="Some text"
                      placement="top">
                      <img
                        src={info}
                        alt="info"
                        className="ms-2 c-pointer"
                        width={13.5}
                      />
                    </Tooltip>
                  </div>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    className="features-radio px-0 mt-2"
                    value={formik?.values?.status}
                    onChange={(e, value) => {
                      formik.setFieldValue("status", value);
                    }}>
                    <div className="w-100">
                      <FormControlLabel
                        value="active"
                        control={<Radio size="small" />}
                        label="Active"
                        sx={{
                          "& .MuiTypography-root": {
                            fontSize: 13,
                            color: "#c8d8ff",
                            marginRight: 1,
                          },
                        }}
                      />
                    </div>
                    <div className="w-100">
                      <FormControlLabel
                        value="comingSoon"
                        control={<Radio size="small" />}
                        label="Coming Soon"
                        sx={{
                          "& .MuiTypography-root": {
                            fontSize: 13,
                            color: "#c8d8ff",
                            marginRight: 1,
                          },
                        }}
                      />
                    </div>
                    <div className="w-100">
                      <FormControlLabel
                        value="under"
                        control={<Radio size="small" />}
                        label="underMaintenance"
                        sx={{
                          "& .MuiTypography-root": {
                            fontSize: 13,
                            color: "#c8d8ff",
                            marginRight: 1,
                          },
                        }}
                      />
                    </div>
                  </RadioGroup>
                </div>
              </div>
            </div>
          </div>

          <hr className="hr-grey-6 my-4 w-100" />

          <div className="row">
            <SideStoreBox
              heading={"Social Links"}
              details="Add the social links for your store"
            />

            <div className="col-lg-8 d-flex align-items-center">
              <div className="row align-items-start">
                <StoreSocial
                  image={fb}
                  placeholder={"www.facebook.com/superthread"}
                  formik={formik}
                  field="socialLinks.facebook"
                  values={formik?.values?.socialLinks?.facebook}
                  errors={formik?.errors?.socialLinks?.facebook}
                  touched={formik?.touched?.socialLinks?.facebook}
                />
                <StoreSocial
                  image={insta}
                  placeholder={"www.instagram.com/superthread"}
                  formik={formik}
                  field="socialLinks.instagram"
                  values={formik?.values?.socialLinks?.instagram}
                  errors={formik?.errors?.socialLinks?.instagram}
                  touched={formik?.touched?.socialLinks?.instagram}
                />
                <StoreSocial
                  image={tweet}
                  placeholder={"www.twitter.com/superthread"}
                  formik={formik}
                  field="socialLinks.twitter"
                  values={formik?.values?.socialLinks?.twitter}
                  errors={formik?.errors?.socialLinks?.twitter}
                  touched={formik?.touched?.socialLinks?.twitter}
                />
                <StoreSocial
                  image={youtube}
                  placeholder={"www.youtube.com/superthread"}
                  formik={formik}
                  field="socialLinks.youtube"
                  values={formik?.values?.socialLinks?.youtube}
                  errors={formik?.errors?.socialLinks?.youtube}
                  touched={formik?.touched?.socialLinks?.youtube}
                />
                <StoreSocial
                  image={pin}
                  placeholder={"www.pinterest.com/superthread"}
                  formik={formik}
                  field="socialLinks.pinterest"
                  values={formik?.values?.socialLinks?.pinterest}
                  errors={formik?.errors?.socialLinks?.pinterest}
                  touched={formik?.touched?.socialLinks?.pinterest}
                />
              </div>
            </div>
          </div>
        </div>
        <SaveFooterTertiary
          show={showFooter ? true : storeInfoState?.isEditing}
          onDiscard={backHandler}
          module="storeInfo"
          isLoading={editStoreInfoIsLoading}
        />
        <DiscardModalSecondary
          when={storeInfoState.edited}
          message={`${formik?.values?.name} store`}
        />
      </form>
      <VerificationPopUp
        show={showVerifyPopup}
        type={otpVerifyType}
        onClose={closeVerifyPopup}
        formik={formik}
        setShowVerifyPopup={setShowVerifyPopup}
        setShowResend={setShowResend}
        showResend={showResend}
        setCountdown={setCountdown}
        countdown={countdown}
        handleResendClick={handleResendClick}
        setVerifyData={setVerifyData}
        setSenderEmailVerifyData={setSenderEmailVerifyData}
        setPhoneVerifyData={setPhoneVerifyData}
        setShowFooter={setShowFooter}
        isPhoneVerified="contact.isPhoneVerified"
        isContactEmailVerified="contact.isContactEmailVerified"
        isSenderEmailVerified="contact.isSenderEmailVerified"
        phoneValue={formik?.values?.contact?.phone}
        primaryEmailValue={formik?.values?.contact?.contactEmail}
        secondaryEmailValue={formik?.values?.contact?.senderEmail}
      />

      {!isLoading && (
        <AddAddress
          openUser={openAddress}
          handleAddressClose={handleAddressClose}
          billingAddress={billingAddress}
          idData={data?.data?.data?.[0]?._id}
        />
      )}
    </div>
  );
};

export default StoreInfo;
