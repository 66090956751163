import { useCallback, useMemo } from "react";
import "./CreateOrder.scss";
import { Link, useNavigate, useParams } from "react-router-dom";
import CreateOrderHeader from "./CreateOrderHeader";
import CreateOrderUser from "./CreateOrderUser";
import CreateOrderProducts from "./CreateOrderProducts";
import CreateOrderPayment from "./CreateOrderPaymentLean";
import { useFormik } from "formik";
import TagsBoxBox from "../../../components/TagsBox/TagsBoxBox";
import {
  useCreateOrderMutation,
  useGetSingleOrderQuery,
} from "../../../features/orders/ordersApiSlice";
import { omitEmptyKeys } from "../../../utils/helper";
import { useDispatch } from "react-redux";
import { showError, showSuccess } from "../../../features/snackbar/snackbarAction";
import { LoadingButton } from "@mui/lab";
import * as Yup from "yup";
import _ from "lodash";
import PageLoader from "../../../components/Loader/PageLoader";
import { useGetGlobalGSTQuery } from "../../../features/settings/taxmanager/taxmanagerApiSlice";

// oid 65af6663b7daf60008f01d00
const orderInitialValues = {
  customer: "",
  address: {
    shipping: "",
    billing: "",
  },
  customerNote: "",
  items: [],
  tags: [],
  status: "processing",
  // state: "", // "create"/"draft"
};

const orderValidationSchema = Yup.object({
  customer: Yup.string().required(),
  address: Yup.object({
    billing: Yup.string().required(),
    shipping: Yup.string().required(),
  }),
  status: Yup.string().required(),
  customerNote: Yup.string(),
  tags: Yup.array().of(Yup.string()),
  items: Yup.array()
    .of(
      Yup.object({
        product: Yup.string().required(),
        variant: Yup.string(),
        variantName: Yup.string(),
        inventory: Yup.string(),
        price: Yup.number().typeError("Please Enter Number").required("required"),
      })
    )
    .min(1, "Minimum 1 Product Required"),
});

const CreateOrder = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { data: gstData, isLoading: gstIsLoading } = useGetGlobalGSTQuery();

  const [taxRate, includeInPrice] = useMemo(() => {
    const { taxRate = 3, includeInPrice = true } = gstData?.data?.data?.[0] ?? {};
    return [taxRate, includeInPrice];
  }, [gstData]);

  const [createOrder, { isLoading: createOrderIsLoading }] = useCreateOrderMutation();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: orderInitialValues,
    validationSchema: orderValidationSchema,
    onSubmit: (values) => {
      const payload = {
        ...omitEmptyKeys(values),
        state: "create",
        items: values.items.map((it) => omitEmptyKeys(it)),
      };
      console.log(payload);
      createOrder(payload)
        .unwrap()
        .then(() => {
          navigate("/orders/allOrders");
          dispatch(showSuccess({ message: "Order created successfully!" }));
        })
        .catch((e) => {
          console.log(e);
          dispatch(
            showError({
              message: e?.data?.message ?? e?.message ?? "Something went wrong!",
            })
          );
        });
    },
  });

  const saveOrderAsDraft = () => {
    const payload = {
      ...omitEmptyKeys(formik.values),
      address: omitEmptyKeys(formik.values.address),
      items: formik.values.items.map((it) => omitEmptyKeys(it)),
      state: "draft",
    };
    // console.log(payload);
    createOrder(payload)
      .unwrap()
      .then(() => {
        navigate("/orders/draftOrder");
        dispatch(showSuccess({ message: "Order saved as Draft!" }));
      })
      .catch((e) => {
        console.log(e);
        dispatch(
          showError({
            message: e?.data?.message ?? e?.message ?? "Something went wrong!",
          })
        );
      });
  };

  const onCustomerChange = useCallback(
    (data) => {
      const { customer = "", shipping = "", billing = "" } = data;
      formik.setFieldValue("customer", customer);
      formik.setFieldValue("address.shipping", shipping);
      formik.setFieldValue("address.billing", billing);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const onProductChange = useCallback(
    (list) =>
      formik.setFieldValue(
        "items",
        list.map((pvi) => {
          // console.log(pvi);
          const price = !pvi?.product?.price?.dynamicPricing
            ? pvi?.product?.price?.salePrice ?? 0
            : !pvi?.variant?.isDynamicPricing
            ? pvi?.variant?.priceBreakup?.[0]?.salePrice ?? 0
            : pvi?.variant?.priceBreakup?.reduce(
                (t, p) => t + ("discount" in p ? -p.discountAmount : p.price),
                0
              ) ?? 0;
          const gstPrice = includeInPrice ? price : price * (1 + taxRate / 100);
          return {
            product: pvi?.product?._id ?? "",
            variant: pvi?.variant?._id ?? "",
            variantName: pvi?.variantName ?? "",
            inventory: pvi?.inventory?.iid ?? "",
            store: pvi?.inventory?.store?._id ?? "",
            price: gstPrice,
            extraCharges: [
              {
                value: taxRate,
                type: "percentage",
                name: "GST",
              },
            ],
          };
        })
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [includeInPrice, taxRate]
  );

  const discardable = useMemo(
    () => !_.isEqual(formik.values, formik.initialValues),
    [formik.initialValues, formik.values]
  );

  return (
    <form
      noValidate
      onSubmit={formik.handleSubmit}>
      <div className="page container-fluid position-relative user-group">
        <CreateOrderHeader />
        <div className="row">
          <div className="col-lg-9 mt-4">
            <CreateOrderUser
              onChange={onCustomerChange}
              handleBlur={formik.handleBlur}
              fieldsTouched={formik.touched}
              fieldsErrors={formik.errors}
            />
            <CreateOrderProducts
              onChange={onProductChange}
              customerNote={formik.values.customerNote}
              handleChange={formik.handleChange}
              fieldsTouched={formik.touched}
              fieldsErrors={formik.errors}
            />
            <CreateOrderPayment items={formik.values.items} />
          </div>
          <div className="col-lg-3 pe-0 ps-0 ps-lg-3">
            <TagsBoxBox
              moduleName="Order"
              filterQuery="order"
              initTags={formik.values.tags}
              onTagsChange={(t) => formik.setFieldValue("tags", t)}
            />
          </div>
        </div>
        <div
          style={{ marginTop: "40vh" }}
          className="row create-buttons pt-5 pb-3 justify-content-between">
          <div className="d-flex w-auto px-0">
            <Link
              to="/orders/allOrders"
              className="button-red-outline py-2 px-4">
              <p>Discard</p>
            </Link>
            {discardable && (
              <LoadingButton
                type="button"
                loading={createOrderIsLoading}
                disabled={createOrderIsLoading}
                onClick={saveOrderAsDraft}
                className="button-gradient ms-3 py-2 px-4 w-auto">
                Save as Draft
              </LoadingButton>
            )}
          </div>
          <div className="d-flex w-auto px-0">
            <LoadingButton
              type="submit"
              loading={createOrderIsLoading}
              disabled={createOrderIsLoading}
              className="button-gradient ms-3 py-2 px-4 w-auto">
              Create Order
            </LoadingButton>
          </div>
        </div>
      </div>
    </form>
  );
};

export default CreateOrder;
