import { useState } from "react";
import { Box, Tooltip, Tab, Tabs } from "@mui/material";
import Checkbox from '@mui/material/Checkbox';

import Container from '../../../components/Container/Container';
import SideStoreBox from "../../../components/Setting/Store/SideBox";
import TabPanel from "../../../components/TabPanel/TabPanel";
import ActiveStore from "../../MyProfile/Stores/ActiveStore";

import info from "../../../assets/icons/info.svg";

const orderData = [
  { name: "New Order Received" },
  { name: "Draft Order" },
  { name: "Return Request" },
  { name: "Exchange Request" }
]

const productData = [
  { name: "New Product Added" },
  { name: "Draft Order" },
  { name: "Return Request" },
  { name: "Exchange Request" }
]

const storeData = [
  { name: "Notify Change in settings" },
  { name: "Global Store Notification" }
]

const ManageStore = () => {
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (_, index) => {
    setTabIndex(index);
  };

  return (
    <div className="border-grey-5 rounded-8 px-4 pt-4 bg-black-15 mt-5">
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <h4 className="page-heading w-auto ps-0 mr-2">Manage Store</h4>
          <Tooltip
            title="Lorem ipsum"
            placement="top"
          >
            <img
              src={info}
              alt="info"
              className="c-pointer"
              width={13.5}
            />
          </Tooltip>
        </div>
        <small className="d-flex align-items-center text-blue-2">
          + Create New Store
        </small>
      </div>
      <div className="mt-4">
        <Box
          sx={{ width: "100%" }}
          className="d-flex justify-content-between tabs-header-box"
        >
          <Tabs className="tabs" value={tabIndex} onChange={handleTabChange}>
            <Tab
              label="Active Stores"
              className="tabs-head"
            />
            <Tab
              label="Inactive Stores"
              className="tabs-head"
            />
          </Tabs>
        </Box>
        <TabPanel value={tabIndex} index={0}>
          <ActiveStore />
        </TabPanel>
        <TabPanel value={tabIndex} index={1}>
          {/* <BillingPlans /> */}
        </TabPanel>
      </div>
    </div>
  );
};

export default ManageStore;