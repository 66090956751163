import apiSlice from "../../../app/api/apiSlice";

export const labelsBadgesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllLabelsBadgess: builder.query({
      query: (queries = {}) => {
        const queryString = new URLSearchParams(queries).toString();
        return {
          url: `/functionality/labelbadge?${queryString}`,
        };
      },
      providesTags: ["LabelsBadges"],
    }),

    // getLabelsBadgesCount: builder.query({
    //   query: (queries = {}) => {
    //     const queryString = new URLSearchParams(queries).toString();

    //     return {
    //       url: `/store/count?${queryString}`,
    //     };
    //   },
    //   providesTags: ["LabelsBadges"],
    // }),

    createLabelsBadges: builder.mutation({
      query: (data) => ({
        url: "/functionality/labelbadge",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["LabelsBadges", "Logs"],
    }),

    editLabelsBadges: builder.mutation({
      query: ({ id, details }) => ({
        url: `/functionality/labelbadge/${id}`,
        method: "PUT",
        body: details,
      }),
      invalidatesTags: ["LabelsBadges", "Logs"],
    }),

    bulkEditLabelsBadges: builder.mutation({
      query: (updates) => ({
        url: "/functionality/labelbadge/bulkUpdate",
        method: "PUT",
        body: updates,
      }),
      invalidatesTags: ["LabelsBadges", "Logs"],
    }),

    deleteLabelsBadges: builder.mutation({
      query: (id) => ({
        url: `/functionality/labelbadge/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["LabelsBadges", "Logs"],
    }),

    bulkDeleteLabelsBadges: builder.mutation({
      query: (deletes) => ({
        url: "/functionality/labelbadge/bulkDelete",
        method: "DELETE",
        body: deletes,
      }),
      invalidatesTags: ["LabelsBadges", "Logs"],
    }),
  }),
});

export const {
  useGetAllLabelsBadgessQuery,
  // useGetLabelsBadgesCountQuery,
  useCreateLabelsBadgesMutation,
  useEditLabelsBadgesMutation,
  useBulkEditLabelsBadgesMutation,
  useDeleteLabelsBadgesMutation,
  useBulkDeleteLabelsBadgesMutation,
} = labelsBadgesApiSlice;
