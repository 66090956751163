import { forwardRef } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  Tooltip
} from "@mui/material";

import "./PopUpBox.scss";
import cancel from "../../../assets/icons/closeModal.svg";
import info from "../../../assets/icons/info.svg";
import trial from "../../../assets/setting/icons/3d Icons.svg"

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PopUpBox = ({ onConfirm, show, contactClick, subscribeClick }) => {
    const saveGroups = () => {
        onConfirm()
    }

    return (
        <Dialog
            open={show}
            TransitionComponent={Transition}
            keepMounted
            onClose={onConfirm}
            aria-describedby="alert-dialog-slide-description"
            maxWidth="sm"
            fullWidth={true}
        >
            <div className="bg-radiant-store py-3">
                <DialogTitle className="d-flex flex-row-reverse">
                    <img
                        src={cancel}
                        alt="cancel"
                        width={45}
                        onClick={onConfirm}
                        className="c-pointer"
                    />
                </DialogTitle>
                <DialogContent className="d-flex justify-content-center">
                    <img
                        src={trial}
                        alt="cancel"
                        width={200}
                    />
                </DialogContent>
                <div className="d-flex justify-content-center">
                    <h4 className="text-lightBlue fw-600 me-2">
                        14 days free trial has now expired
                    </h4>
                </div>
                <div className="d-flex justify-content-center px-3">
                    <small className="text-grey-6 mt-2 d-block py-4 px-5">
                        Your free trial has now expired. To continue using our platform, you'll need to upgrade to one of our paid plans. Our paid plans offer more advanced features, increased storage, and priority support from our team.
                    </small>
                </div>
                <div className="d-flex justify-content-center">
                    <div className="button-gradient py-2 px-5 ms-3" onClick={subscribeClick}>
                        <p>Subscribe</p>
                    </div>
                </div>
                <div className="d-flex justify-content-center">
                    <small className="text-blue-2 py-4 c-pointer" onClick={contactClick}>
                        {" "}Contact support
                    </small>
                </div>
            </div>
        </Dialog>
    );
};

export default PopUpBox;