import React from "react";
import { Rectangle, Layer } from "recharts";

export default function DemoSankeyNode({
  x,
  y,
  width,
  height,
  index,
  payload,
  containerWidth
}) {
  const isOut = x + width + 6 > containerWidth;
  return (
    <Layer key={`CustomNode${index}`}>
      <Rectangle
        x={x}
        y={y}
        width={width}
        height={height}
        fill={"#8b68ff"}
        fillOpacity="1"
      />
      <text
        textAnchor={isOut ? "end" : "start"}
        x={isOut ? x - 6 : x + width + 6}
        y={y + height / 2}
        fontSize="14"
        fontWeight={400}
        stroke="#fff"
        letterSpacing={'0.1em'}
      >
        {payload.name}
      </text>
      <text
        textAnchor={isOut ? "end" : "start"}
        x={isOut ? x - 6 : x + width + 6}
        y={y + height / 2 + 13}
        fontSize="12"
        stroke="#fff"
        fontWeight={400}
        letterSpacing={'0.1em'}
        strokeOpacity="0.75"
        textLength={18}
      >
        {payload.value}
      </text>
    </Layer>
  );
}