import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import tableLoader from "../../../../assets/gif/loader.gif";
import BorderLinearProgress from "../BorderLinearProgress";
import { useEffect, useState } from "react";

export default function FilesUploadingDialog({ show = false }) {
  return (
    <Dialog
      fullWidth
      open={show}
      maxWidth="sm">
      <DialogTitle>
        <div className="d-flex justify-content-center align-items-center">
          <span className="text-lightBlue fs-6 fw-500">Uploading Media</span>
        </div>
      </DialogTitle>
      <hr className="hr-grey-6 m-0" />
      <DialogContent className="p-3">
        <Content />
      </DialogContent>
    </Dialog>
  );
}

function Content() {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const tid = setTimeout(() => {
      if (progress < 100) {
        setProgress((prevProgress) => prevProgress + 5);
      }
    }, 300);

    return () => clearInterval(tid);
  }, [progress]);

  return (
    <div className="row px-2">
      <div className="col-12 d-flex justify-content-center my-2">
        <img
          src={tableLoader}
          alt="uploading"
          width={80}
        />
      </div>
      <h4 className="col-12 text-center my-2">{progress < 100 ? "Uploading" : "Taking a bit longer"}</h4>
      <div className="col-12 my-2">
        <BorderLinearProgress
          variant="determinate"
          value={progress}
        />
      </div>
    </div>
  );
}
