import apiSlice from "../../app/api/apiSlice";
import { omitEmptyKeys } from "../../utils/helper";

export const timelineApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTimeline: builder.query({
      query: ({ oid, queries }) => ({
        url: `/order/timeline/${oid}`,
        params: omitEmptyKeys(queries),
      }),
      providesTags: ["Timeline"],
    }),
    createTimeline: builder.mutation({
      query: (timeline) => ({
        url: "/order/timeline",
        method: "POST",
        body: timeline,
      }),
      invalidatesTags: ["Timeline", "Logs"],
    }),
  }),
});

export const { useGetTimelineQuery, useCreateTimelineMutation } = timelineApiSlice;
