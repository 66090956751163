import React, { useEffect, useState } from "react";
import "./Options.scss";
// ! IMAGES IMPORTS
import info from "../../../../assets/icons/info.svg";
// ! MATERIAL IMPORTS
import { Tooltip, Chip, Button } from "@mui/material";
// ! MATERIAL ICONS IMPORTS
import ImportOptionSetsDialog from "./ImportOptionSetsDialog";
import CreateOptionSetsDialog from "./CreateOptionSetsDialog";
import { useGetAllOptionSetsQuery } from "../../../../features/parameters/options/optionSetsApiSlice";
import {
  useBulkDeleteVariantByProductMutation,
  useCreateWithSetVariantMutation,
  useGetAllVariantsQuery,
} from "../../../../features/products/product/varianceApiSlice";
import { LoadingButton } from "@mui/lab";
import { useDispatch } from "react-redux";
import { showError, showSuccess } from "../../../../features/snackbar/snackbarAction";
import { useParams } from "react-router-dom";
import {
  useGetSingleProductQuery,
  useUpdateProductMutation,
} from "../../../../features/products/product/productApiSlice";
import PageLoader from "../../../../components/Loader/PageLoader";
import { SaveFooterQuaternary } from "../../../../components/SaveFooter/SaveFooter";

export default function Options({
  onOptionsChange = () => {},
  onNext = () => {},
  onBack = () => {},
}) {
  const dispatch = useDispatch();
  const { id: productId } = useParams();

  const [showImportOptionSets, setShowImportOptionSets] = useState(false);
  const openImportOptionSetsDialog = () => setShowImportOptionSets(true);
  const closeImportOptionSetsDialog = () => setShowImportOptionSets(false);

  const [showCreateOptionSets, setShowCreateOptionSets] = useState(false);
  const openCreateOptionSetsDialog = () => setShowCreateOptionSets(true);
  const closeCreateOptionSetsDialog = () => setShowCreateOptionSets(false);

  const [chosenOptionSet, setChosenOptionSet] = useState(null);
  const [createdOptionSetId, setCreatedOptionSetId] = useState("");

  useEffect(() => onOptionsChange(chosenOptionSet), [chosenOptionSet, onOptionsChange]);

  const { data: productsData, isLoading: productsIsLoading } = useGetSingleProductQuery(
    {
      id: productId,
    },
    { skip: !productId }
  );
  const productSetId = productsData?.[0]?.setId ?? "";
  const productDynamicPricing = productsData?.[0]?.price?.dynamicPricing ?? false;
  const productInventoryVariants = productsData?.[0]?.inventory?.inventoryOn === "variant";

  const { data: variantsData } = useGetAllVariantsQuery({
    productId,
    isDefault: true,
  });
  const productAttributes = variantsData?.data?.data?.[0]?.attributes ?? [];

  useEffect(() => {
    if (!productsIsLoading) setCreatedOptionSetId(productSetId ?? "");
  }, [productsIsLoading, productSetId]);

  const { data: optionSetsData, isLoading: optionSetsIsLoading } = useGetAllOptionSetsQuery(
    { id: createdOptionSetId },
    { skip: productsIsLoading || !createdOptionSetId }
  );

  useEffect(() => {
    if (!optionSetsIsLoading) setChosenOptionSet(optionSetsData?.data?.[0] ?? null);
  }, [optionSetsIsLoading, optionSetsData?.data]);

  const [deleteAllVariant, { isLoading: deleteAllVariantIsLoading }] =
    useBulkDeleteVariantByProductMutation();

  const [createVariant, { isLoading: createVariantIsLoading }] =
    useCreateWithSetVariantMutation();

  const [hasRemoved, setHasRemoved] = useState(false);

  const nextStep = () => {
    // console.log("test", chosenOptionSet?._id, productSetId);
    if (!hasRemoved && chosenOptionSet?._id === productSetId) {
      onNext(
        !productDynamicPricing ||
          !productAttributes?.some(
            (at) => at.title === "diamond" || at.title === "gemstone" || at.title === "pearl"
          )
      );
      return;
    }
    deleteAllVariant(productId)
      .unwrap()
      .then(() => {
        createVariant({ ...chosenOptionSet, product: productId })
          .unwrap()
          .then((data) => {
            const attrs = data.data?.[0]?.attributes;
            onNext(
              !productDynamicPricing ||
                !attrs?.some(
                  (at) =>
                    at.title.toLowerCase() === "diamond" ||
                    at.title.toLowerCase() === "gemstone" ||
                    at.title.toLowerCase() === "pearl"
                )
            );
            dispatch(
              showSuccess({
                message: "Option Set saved successfully",
              })
            );
          });
      })
      .catch((error) => {
        dispatch(
          showError({
            message:
              error?.data?.message ??
              error.message ??
              "Something went wrong!, please try again",
          })
        );
      });
  };

  const [updateProduct, { isLoading: updateProductIsLoading }] = useUpdateProductMutation();

  const skipStep = () => {
    updateProduct({ id: productId, details: { isCompleted: true, step: "4" } })
      .unwrap()
      .then(() => {
        onNext(true, 2);
      })
      .catch((e) =>
        dispatch(showError({ message: e?.data?.message ?? "Something went wrong!" }))
      );
  };

  return productsIsLoading || optionSetsIsLoading ? (
    <PageLoader />
  ) : (
    <div className="bg-black-15 border-grey-5 rounded-8 p-3 row attributes">
      <div className="d-flex col-12 px-0 justify-content-between align-items-center">
        <div className="d-flex flex-column">
          <div className="d-flex align-items-center">
            <h6 className="text-lightBlue fw-500 me-2">Options</h6>
            <Tooltip
              title="Lorem ipsum"
              placement="top">
              <img
                src={info}
                alt="info"
                className="c-pointer"
                width={13.5}
              />
            </Tooltip>
          </div>
          <small className="text-grey-6 mt-2">
            If this product has options, like size or color then add options
          </small>
        </div>
        {!chosenOptionSet && (
          <div className="d-flex">
            <button
              onClick={openCreateOptionSetsDialog}
              className="button-gradient py-2 px-3 mx-2">
              <p>Create Option Set</p>
            </button>
            <CreateOptionSetsDialog
              show={showCreateOptionSets}
              onAction={(id) => setCreatedOptionSetId(id)}
              onClose={closeCreateOptionSetsDialog}
            />
            <button
              onClick={openImportOptionSetsDialog}
              className="button-lightBlue-outline py-2 px-3 mx-2">
              <p>Import Option Set</p>
            </button>
            <ImportOptionSetsDialog
              show={showImportOptionSets}
              onAction={(set) => setChosenOptionSet(set)}
              onClose={closeImportOptionSetsDialog}
            />
          </div>
        )}
      </div>
      {!chosenOptionSet && !productInventoryVariants && !productDynamicPricing && (
        <SaveFooterQuaternary
          show
          onAction={skipStep}
          onDiscard={() => onBack(false)}
          isLoading={updateProductIsLoading}
          saveButtonName="Skip"
          discardButtonName="Previous"
        />
      )}
      {chosenOptionSet && (
        <>
          <hr className="hr-grey-6 mt-3 mb-3" />
          <div className="d-flex col-12 px-0">
            <div className="d-flex justify-content-between w-100">
              <div className="d-flex">
                <p className="text-grey-6">Option Set:</p>
                <p className="mx-1 text-lightBlue text-capitalize">{chosenOptionSet.name}</p>
              </div>
              <div className="d-flex">
                {/* <Button
                  onClick={openImportOptionSetsDialog}
                  variant="text"
                  size="small">
                  <span className="text-blue-2">Change Option Set</span>
                </Button>
                <p className="text-grey-6 fs-5 mx-2">|</p> */}

                <LoadingButton
                  size="small"
                  variant="text"
                  className="ms-3 py-2 px-4 w-auto"
                  loading={deleteAllVariantIsLoading}
                  disabled={deleteAllVariantIsLoading}
                  onClick={() => {
                    deleteAllVariant(productId)
                      .unwrap()
                      .then(() => {
                        setHasRemoved(true);
                        setChosenOptionSet(null);
                      })
                      .catch((error) => {
                        dispatch(
                          showError({
                            message:
                              error?.data?.message ??
                              error.message ??
                              "Something went wrong!, please try again",
                          })
                        );
                      });
                  }}>
                  <p className="text-red-5">Remove Option Set</p>
                </LoadingButton>
              </div>
            </div>
          </div>
          <hr className="hr-grey-6 mt-3 mb-0" />
          <div className="col-12">
            {chosenOptionSet.option.map((opt) => (
              <div
                key={opt._id}
                className="row border-grey-5 rounded-8 align-items-center bg-black-13 my-3">
                <div className="col-12 mt-2">
                  <div className="d-flex justify-content-between align-items-center">
                    <small className="text-grey-6 ms-2">
                      <span className="text-capitalize text-lightBlue">
                        {opt.attribute[0].frontEndTitle}
                      </span>
                    </small>
                    <small className="text-grey-6 me-2">
                      <span className="text-capitalize text-lightBlue">
                        {opt.attribute[0].apperance}
                      </span>
                    </small>
                  </div>
                </div>
                <div className="col-12 d-flex my-2">
                  {opt.attribute[0].metaAttributes.map((mattr) => (
                    <Chip
                      key={mattr._id}
                      label={`${mattr.title} (${mattr.metaSubAttribute?.length ?? 0})`}
                      size="small"
                      className="px-1 me-2"
                    />
                  ))}
                </div>
              </div>
            ))}
          </div>
          {/* <div className="row align-items-center mt-4">
            <div className="col-12 d-flex justify-content-between">
              <button
                onClick={() => onBack()}
                className="button-red-outline py-2 px-4">
                <p>Previous</p>
              </button>
              <LoadingButton
                onClick={nextStep}
                loading={createVariantIsLoading}
                disabled={createVariantIsLoading}
                className="button-gradient py-2 px-4">
                <p>Next</p>
              </LoadingButton>
            </div>
          </div> */}
          <SaveFooterQuaternary
            show
            onAction={nextStep}
            onDiscard={() => onBack(false)}
            isLoading={createVariantIsLoading}
            saveButtonName="Next"
            discardButtonName="Previous"
          />
        </>
      )}
    </div>
  );
}
