import { useEffect, useMemo, useState } from "react";
import "./Variants.scss";
import { EnhancedTableHead } from "../../../../components/TableDependencies/TableDependencies";
import {
  OutlinedInput,
  FormControl,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  MenuItem,
  Select,
  Tooltip,
  CircularProgress,
  FormHelperText,
  Popover,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { useDispatch } from "react-redux";
import infoIcon from "../../../../assets/icons/info.svg";
import arrowDown from "../../../../assets/icons/arrowDown.svg";
import info from "../../../../assets/icons/info.svg";
import cancel from "../../../../assets/icons/cancel.svg";
import {
  useBulkEditVariantMutation,
  useGetAllVariantsCountQuery,
  useGetAllVariantsQuery,
} from "../../../../features/products/product/varianceApiSlice";
import TableLoader from "../../../../components/Loader/TableLoader";
import NoDataFound from "../../../../components/NoDataFound/NoDataFound";
import AppGenericSelect from "../../../../components/AppGenericSelect/AppGenericSelect";
import { useFormik } from "formik";
import * as Yup from "yup";
import _ from "lodash";
import { useGetAllStoresQuery } from "../../../../features/products/inventory/inventoryApiSlice";
import { useGetAllCountryQuery } from "../../../../features/master/country/countryApiSlice";
import { LoadingButton } from "@mui/lab";
import { showError, showSuccess } from "../../../../features/snackbar/snackbarAction";
import { createSearchParams, useNavigate, useParams } from "react-router-dom";
import { AntSwitch } from "../../../../components/AntSwitch/AntSwitch";
import {
  useBulkAddOrUpdateMutation,
  useBulkShippingCreateMutation,
  useBulkShippingUpdateMutation,
} from "../../../../features/products/product/inventoryAPISlice";
import { useFormikToastError } from "../../../../hooks/error";
import { rupeeFormatWithSymbol } from "../../../../utils/format";
import { omitEmptyKeys } from "../../../../utils/helper";
import { useGetGlobalGSTQuery } from "../../../../features/settings/taxmanager/taxmanagerApiSlice";
import {
  UploadMediaLarge,
  UploadMultipleMediaLarge,
} from "../../../../components/UploadMediaBox/UploadMedia";
import { ReactComponent as ImagePlaceHolder } from "../../../../assets/icons/imagePlaceHolder.svg";
import { ReactComponent as UploadIcon } from "../../../../assets/icons/upload.svg";
import AsyncImage from "../../../../components/AsyncImage/AsyncImage";
import { TableSearchSecondary } from "../../../../components/TableSearch/TableSearch";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";

const priceBreakupSchema = Yup.object({
  price: Yup.number()
    .typeError("Please Enter Number")
    .min(0, "must be positive")
    .required("required"),
  type: Yup.string().oneOf(["percentage", "fixed"]).required(),
  discount: Yup.number()
    .typeError("Please Enter Number")
    .min(0, "must be positive")
    .required("required"),
  salePrice: Yup.number()
    .typeError("Please Enter Number")
    .min(0, "must be positive")
    .required("required"),
});

const priceManagerSchema = Yup.array().of(
  Yup.object({
    type: Yup.string(),
    attribute: Yup.string(),
    weight: Yup.number().typeError("Please Enter Number").min(0, "must be positive"),
    discount: Yup.number().typeError("Please Enter Number").min(0, "must be positive"),
    isDiscount: Yup.boolean().default(false),
  })
);

const variantValidationSchema = {
  id: Yup.string().required("required"),
  barCode: Yup.string().required("required"),
  bagNumber: Yup.array().of(Yup.string().min(1)).min(1, "Min 1 Required"),
  // quantity: Yup.number()
  //   .typeError("Please Enter Number")
  //   .min(0, "must be positive")
  //   .required("required"),
  netWeight: Yup.number()
    .typeError("Please Enter Number")
    .min(0, "must be positive")
    .required("required"),
  isDefault: Yup.boolean().default(false),
  isSellOutOfStock: Yup.boolean().default(false),
  shipping: Yup.object().shape({
    productId: Yup.string().trim(),
    type: Yup.string().trim().oneOf(["digital", "physical"]),
    weight: Yup.number(),
    length: Yup.number(),
    height: Yup.number(),
    width: Yup.number(),
    isAbroadAvailable: Yup.boolean(),
    country: Yup.string().trim(),
    hsCode: Yup.string().trim(),
  }),
};

const discountBreakupValidationSchema = Yup.object({
  discount: Yup.number()
    .typeError("Enter Valid Number")
    .min(0, "Minimum 0")
    .required("Required"),
  type: Yup.string().oneOf(["fixed", "percentage"]).required("Required"),
});

const storeInitialValues = { country: "", store: "" };
const storeValidationSchema = Yup.object({
  country: Yup.string().required("required"),
  store: Yup.string().required("required"),
});

export default function Variants({
  productTitle,
  step,
  isCompleted,
  onNext = () => {},
  onBack = () => {},
  isInventoryVariants = false,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id: productId = "" } = useParams();

  const [selected, setSelected] = useState([]);
  const clearSelected = () => setSelected([]);

  const [medaiIndex, setMedaiIndex] = useState(-1);
  const [bagNoIndex, setBagNoIndex] = useState(-1);
  const [search, setSearch] = useState("");
  const [searchDeb, setSearchDeb] = useState("");
  const [pageNo, setPageNo] = useState(1);
  const pageSize = 10;

  const storeformik = useFormik({
    enableReinitialize: true,
    initialValues: storeInitialValues,
    validationSchema: storeValidationSchema,
  });
  const {
    data: storeData,
    isLoading: storeIsLoading,
    isSuccess: storeIsSuccess,
  } = useGetAllStoresQuery(
    { country: storeformik.values.country, createdAt: 1 },
    { skip: !isInventoryVariants }
  );
  useEffect(
    () => {
      if (
        (!storeformik.values.country || !storeformik.values.store) &&
        storeIsSuccess &&
        isInventoryVariants
      ) {
        storeformik.setFieldValue("store", storeData?.data?.data?.[0]?._id ?? "");
        storeformik.setFieldValue("country", storeData?.data?.data?.[0]?.countryCode ?? "");
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [storeformik.values, storeData, storeIsSuccess, isInventoryVariants]
  );

  const { data: allVariantCountData, isSuccess: allVariantCountisSuccess } =
    useGetAllVariantsCountQuery(
      { productId, title: searchDeb },
      { skip: isInventoryVariants && !storeformik.values.store }
    );
  const totalVariants = allVariantCountData?.data?.[0]?.active ?? 0;
  const maxPages = Math.ceil(totalVariants / pageSize);

  const {
    data: allVariantsData,
    isLoading: allVariantsIsLoading,
    isFetching: allVariantsIsFetching,
    isSuccess: allVariantsIsSuccess,
  } = useGetAllVariantsQuery(
    { store: storeformik.values.store, productId, pageSize, pageNo, title: searchDeb },
    { skip: !allVariantCountisSuccess }
  );
  const productVariant = allVariantsData?.data?.data ?? [];
  const totalProductVariantCount = allVariantsData?.data?.totalCount ?? 0;
  const productVariantAttributes = allVariantsData?.data?.data?.[0]?.attributes ?? [];
  const productVariantIsDynamicPricing =
    allVariantsData?.data?.data?.[0]?.isDynamicPricing ?? false;
  // const productVariantGlobalPriceManager =
  //   allVariantsData?.data?.data?.[0]?.globalPriceManager ?? [];
  // const productVariantPriceBreakup = allVariantsData?.data?.data?.[0]?.priceBreakup ?? [];

  const hasStones = productVariantAttributes.some(
    (at) => at.title === "diamond" || at.title === "gemstone" || at.title === "pearl"
  );

  const headCells = allVariantCountisSuccess
    ? [
        {
          id: "variantName",
          numeric: false,
          disablePadding: false,
          label: `${totalVariants} Variants`,
        },
        {
          id: "variantMedia",
          numeric: false,
          disablePadding: false,
          label: "Medias",
        },
        ...(productVariantIsDynamicPricing
          ? [
              ...productVariantAttributes
                .filter((va) => va.title === "metal")
                .map((_, ix) => {
                  return {
                    id: "metalWeight",
                    numeric: false,
                    disablePadding: false,
                    label: `Metal Net Weight (gms)`,
                  };
                }),
              // ...productVariantGlobalPriceManager
              //   .filter((vgpm) => {
              //     const attr = productVariantAttributes.find(
              //       (va) => va._id === vgpm?.attribute
              //     );
              //     return attr?.title === "diamond";
              //   })
              //   .map((_, ix) => {
              //     return {
              //       id: `diamond${ix}Weight`,
              //       numeric: false,
              //       disablePadding: false,
              //       label: `D ${ix} Weight (ct)`,
              //     };
              //   }),
              // ...productVariantGlobalPriceManager
              //   .filter((vgpm) => {
              //     const attr = productVariantAttributes.find(
              //       (va) => va._id === vgpm?.attribute
              //     );
              //     return attr?.title === "gemstone";
              //   })
              //   .map((_, ix) => {
              //     return {
              //       id: `gemstone${ix}Weight`,
              //       numeric: false,
              //       disablePadding: false,
              //       label: `G ${ix} Weight (ct)`,
              //     };
              //   }),
              // ...productVariantGlobalPriceManager
              //   .filter((vgpm) => {
              //     const attr = productVariantAttributes.find(
              //       (va) => va._id === vgpm?.attribute
              //     );
              //     return attr?.title === "pearl";
              //   })
              //   .map((_, ix) => {
              //     return {
              //       id: `pearl${ix}Weight`,
              //       numeric: false,
              //       disablePadding: false,
              //       label: `P ${ix} Weight (ct)`,
              //     };
              //   }),
              {
                id: "netWeight",
                numeric: false,
                disablePadding: false,
                label: "Gross Weight (gms)",
              },
              {
                id: "price",
                numeric: false,
                disablePadding: false,
                label: "Price (​₹)",
              },
              {
                id: "discountType",
                numeric: false,
                disablePadding: false,
                label: "Discount Type",
              },
              {
                id: "discountValue",
                numeric: false,
                disablePadding: false,
                label: "Total Discount",
              },
              ...productVariantAttributes
                .filter(
                  (va) =>
                    va.title === "metal" ||
                    va.title === "diamond" ||
                    va.title === "gemstone" ||
                    va.title === "pearl"
                )
                .map((va) => {
                  return {
                    id: `${va.title}Discount`,
                    numeric: false,
                    disablePadding: false,
                    label: `${va.title} Discount`,
                  };
                }),
              {
                id: "makingDiscount",
                numeric: false,
                disablePadding: false,
                label: `Making Charges Discount`,
              },
            ]
          : [
              {
                id: "price",
                numeric: false,
                disablePadding: false,
                label: "Total Price (​₹)",
              },
              {
                id: "discountType",
                numeric: false,
                disablePadding: false,
                label: "Discount Type",
              },
              {
                id: "discountValue",
                numeric: false,
                disablePadding: false,
                label: "Discount Value",
              },
              {
                id: "salePrice",
                numeric: false,
                disablePadding: false,
                label: "Sell Price (​₹)",
              },
            ]),
        ...[
          {
            id: "cquantity",
            numeric: false,
            disablePadding: false,
            label: "Committed Qty",
          },
          {
            id: "aquantity",
            numeric: false,
            disablePadding: false,
            label: "Available Qty",
          },
          {
            id: "isSellOutOfStock",
            numeric: false,
            disablePadding: false,
            label: "Continue Selling",
          },
        ].filter((_) => isInventoryVariants),
        {
          id: "sku",
          numeric: false,
          disablePadding: false,
          label: "SKU",
        },
        ...[
          {
            id: "default",
            numeric: false,
            disablePadding: true,
            label: "Default",
          },
        ].filter((_) => totalProductVariantCount > 1),
        {
          id: "weight",
          numeric: false,
          disablePadding: true,
          label: "Weight",
        },
        {
          id: "length",
          numeric: false,
          disablePadding: true,
          label: "Length",
        },
        {
          id: "height",
          numeric: false,
          disablePadding: true,
          label: "Height",
        },
        {
          id: "width",
          numeric: false,
          disablePadding: true,
          label: "Width",
        },
      ]
    : [];

  // console.log(productVariant);

  const [bulkUpdateQuantity] = useBulkAddOrUpdateMutation();
  const [bulkCreateShipping] = useBulkShippingCreateMutation();
  const [bulkUpdateShipping] = useBulkShippingUpdateMutation();

  const [bulkUpdateVarians, { isLoading: bulkUpdateVariansIsLoading }] =
    useBulkEditVariantMutation();

  const variantformik = useFormik({
    initialValues: productVariant.map((variant) => {
      const formValue = {
        productId,
        id: variant._id, // inventory block missing
        mediaUrl: variant.mediaUrl ?? [],
        store: variant?.inventory?.store?._id,
        inventory: variant?.inventory?._id ?? "",
        barCode: variant?.barCode ?? "",
        netWeight: variant?.netWeight ?? "0",
        isDefault: variant?.isDefault ?? false,
        bagNumber: variant?.inventory?.store?.bagNumber ?? [],
        quantity: variant?.inventory?.store?.quantityCount ?? "0",
        comitted: variant?.inventory?.store?.committedCount ?? "0",
        isSellOutOfStock: variant?.inventory?.isNegativeSellAllowed ?? false,
        shipping: variant?.shipping ?? {
          _id: "",
          productId: variant.productId,
          variantId: variant._id,
          type: "physical",
          isAbroadAvailable: false,
          weight: "",
          length: "",
          height: "",
          width: "",
        },
      };
      if (variant.isDynamicPricing) {
        formValue.priceManager = [
          ...variant.attributes
            .filter((va) => va.title === "metal")
            .map((va) => {
              const priceBreakup = variant.priceBreakup?.find((pb) => pb?.attribute === va._id);
              return {
                title: "metal",
                attribute: va._id,
                weight: priceBreakup?.weight ?? "",
              };
            }),
          ...variant.globalPriceManager
            .filter((vgpm) => {
              const attr = variant.attributes.find((va) => va._id === vgpm?.attribute);
              return attr?.title === "diamond";
            })
            .map((vgpm) => {
              return {
                title: "diamond",
                attribute: vgpm.attribute,
                weight: vgpm.weight,
              };
            }),
          ...variant.globalPriceManager
            .filter((vgpm) => {
              const attr = variant.attributes.find((va) => va._id === vgpm?.attribute);
              return attr?.title === "gemstone";
            })
            .map((vgpm) => {
              return {
                title: "gemstone",
                attribute: vgpm.attribute,
                weight: vgpm.weight,
              };
            }),
          ...variant.globalPriceManager
            .filter((vgpm) => {
              const attr = variant.attributes.find((va) => va._id === vgpm?.attribute);
              return attr?.title === "pearl";
            })
            .map((vgpm) => {
              return {
                title: "pearl",
                attribute: vgpm.attribute,
                weight: vgpm.weight,
              };
            }),
        ];
        const pbkex = variant.priceBreakup.find(
          (pb) => pb?.extraChargeType === "Making Charges"
        );
        formValue.discountOn = variant.attributes
          .filter(
            (va) =>
              va.title === "metal" ||
              va.title === "diamond" ||
              va.title === "gemstone" ||
              va.title === "pearl"
          )
          .map((va) => {
            const pbk = variant.priceBreakup.find((pb) => pb?.attribute === va._id);
            const {
              priceType = `${va.title}Price`,
              discountValue = "0",
              isDiscount = true,
            } = pbk ?? {};
            return { type: priceType, discount: discountValue, isDiscount };
          })
          .concat({
            type: "makingCharge",
            discount: pbkex?.discountValue ?? "0",
            isDiscount: pbkex?.isDiscount ?? true,
          });
        formValue.discountBreakup = variant?.priceBreakup?.find?.((pb) => "discount" in pb) ?? {
          type: "percentage",
          discount: 0,
        };
      } else {
        formValue.priceBreakup = variant.priceBreakup[0];
      }
      return formValue;
    }),
    validationSchema: Yup.array().of(
      Yup.object({
        ...variantValidationSchema,
        ...(productVariantIsDynamicPricing
          ? {
              priceManager: priceManagerSchema,
              discountBreakup: discountBreakupValidationSchema,
            }
          : { priceBreakup: priceBreakupSchema }),
      })
    ),
    enableReinitialize: true,
    onSubmit: (values) => {
      if (isInventoryVariants) {
        bulkUpdateQuantity(
          values.map((val) => ({
            id: val.inventory,
            updates: [
              {
                id: val.store, // this will be inventory id or undefined
                store: storeformik.values.store,
                bagNumber: val.bagNumber.length > 0 ? val.bagNumber : undefined,
                quantityCount: val.quantity,
                isNegativeSellAllowed: val.isSellOutOfStock,
              },
            ],
          }))
        )
          .unwrap()
          .catch((e) =>
            dispatch(
              showError({
                message: e?.data?.message ?? e?.message ?? "Something went wrong",
              })
            )
          );
      }

      if (values.every((val) => !val.shipping._id)) {
        // console.log("Bulk Create Shipping");
        bulkCreateShipping(values.map((val) => omitEmptyKeys(val.shipping)))
          .unwrap()
          .catch((e) =>
            dispatch(
              showError({
                message: e?.data?.message ?? e?.message ?? "Something went wrong",
              })
            )
          );
      } else {
        // console.log("Bulk Update Shipping");
        bulkUpdateShipping({ updates: values.map((val) => omitEmptyKeys(val.shipping)) })
          .unwrap()
          .catch((e) =>
            dispatch(
              showError({
                message: e?.data?.message ?? e?.message ?? "Something went wrong",
              })
            )
          );
      }
      let updates = values.map((val) => ({ ...val }));

      if (productVariantIsDynamicPricing) {
        updates = updates.map((update) => {
          const exc = update.discountOn.find((don) => don.type === "makingCharge");
          const rnUpdate = {
            ...update,
            priceManager: update.priceManager
              .map((pm) => {
                const dOn = update.discountOn.find((don) => don.type === `${pm.title}Price`);
                return { ...pm, ...dOn };
              })
              .concat({ ...exc }),
          };
          return rnUpdate;
        });
      }

      bulkUpdateVarians({
        updates,
        isCompleted: isCompleted || pageNo === maxPages,
        step: pageNo === maxPages ? step.toString() : undefined,
      })
        .unwrap()
        .then(() => {
          // nextStep();
          dispatch(showSuccess({ message: "Variants updated successfully" }));
        })
        .catch((e) =>
          dispatch(
            showError({
              message: e?.data?.message ?? e?.message ?? "Something went wrong",
            })
          )
        );
    },
  });

  // console.log(variantformik.values.map((v) => v.discountOn));

  useFormikToastError(variantformik);

  const discardable = useMemo(
    () => !_.isEqual(variantformik.values, variantformik.initialValues),
    [variantformik.initialValues, variantformik.values]
  );

  const nextStep = () => {
    const values = variantformik.values;
    let updates = values.map((val) => ({ ...val }));

    if (productVariantIsDynamicPricing) {
      updates = updates.map((update) => {
        const exc = update.discountOn.find((don) => don.type === "makingCharge");
        const rnUpdate = {
          ...update,
          priceManager: update.priceManager
            .map((pm) => {
              const dOn = update.discountOn.find((don) => don.type === `${pm.title}Price`);
              return { ...pm, ...dOn };
            })
            .concat({ ...exc }),
        };
        return rnUpdate;
      });
    }

    bulkUpdateVarians({
      updates,
      isCompleted: isCompleted || pageNo === maxPages,
      step: pageNo === maxPages ? step.toString() : undefined,
    })
      .unwrap()
      .then(() => {
        if (pageNo < maxPages) setPageNo(pageNo + 1);
        else onNext();
        dispatch(showSuccess({ message: "Variants updated successfully" }));
      })
      .catch((e) =>
        dispatch(
          showError({
            message: e?.data?.message ?? e?.message ?? "Something went wrong",
          })
        )
      );
  };

  const prevStep = () => {
    if (pageNo > 1) setPageNo(pageNo - 1);
    else onBack(!productVariantIsDynamicPricing || !hasStones);
  };

  return (
    <div className="bg-black-15 border-grey-5 rounded-8 p-3 row variants">
      <div className="col-12 px-0">
        <div className="d-flex justify-content-between align-items-center">
          <h6 className="text-lightBlue fw-500">Variants</h6>
          <Button
            size="medium"
            variant="outlined"
            onClick={() =>
              navigate({
                pathname: `/products/variant/bulkEdit/${productId}/${storeformik.values.store}`,
                search: "?" + createSearchParams({ title: productTitle, count: totalVariants }),
              })
            }>
            {/* <Link to="/products/variant/bulkEdit"> */}
            <span className="text-lightBlue">Bulk Edit</span>
            {/* </Link> */}
          </Button>
        </div>
      </div>
      {isInventoryVariants && (
        <div className="col-12 px-0 mb-4">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-4 pe-md-0 mt-4">
              <div className="d-flex mb-1">
                <p className="text-lightBlue">Market</p>
                <Tooltip
                  title="Lorem ipsum"
                  placement="top">
                  <img
                    src={infoIcon}
                    alt="info"
                    className="c-pointer ms-2"
                    width={13.5}
                  />
                </Tooltip>
              </div>
              <AppGenericSelect
                name="country"
                disableClearable
                value={storeformik.values.country}
                dataId="_id"
                dataLabel="name"
                onBlur={() => storeformik.setFieldTouched("country", true)}
                onChange={(_, co) => storeformik.setFieldValue("country", co?._id ?? "")}
                useGetQuery={useGetAllCountryQuery}
                getQueryFilters={{ createdAt: -1, pageNo: 1, pageSize: 10000 }}
              />
            </div>
            <div className="col-md-8 mt-4">
              <div className="d-flex mb-1">
                <p className="text-lightBlue">Store Address</p>
                <Tooltip
                  title="Lorem ipsum"
                  placement="top">
                  <img
                    src={infoIcon}
                    alt="info"
                    className="c-pointer ms-2"
                    width={13.5}
                  />
                </Tooltip>
              </div>
              {storeIsLoading ? (
                <CircularProgress
                  color="secondary"
                  size={20}
                />
              ) : (
                <FormControl
                  sx={{ m: 0, minWidth: 120, width: "100%" }}
                  size="small">
                  <Select
                    id="demo-select-small"
                    name="store"
                    value={storeformik.values.store}
                    onChange={storeformik.handleChange}
                    onBlur={storeformik.handleBlur}
                    size="small">
                    {storeData?.data?.data?.map((store) => (
                      <MenuItem
                        key={store._id}
                        value={store._id}
                        sx={{ fontSize: 13, color: "#5c6d8e" }}>
                        {/* {console.log(store)} */}
                        {store.name}, {store.address?.city?.name} {store.address?.pincode}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </div>
          </div>
        </div>
      )}
      {allVariantsIsSuccess && (
        <>
          <div className="col-12 px-0 mt-3">
            {allVariantsIsLoading || allVariantsIsFetching || bulkUpdateVariansIsLoading ? (
              <span className="d-flex justify-content-center m-3">
                <TableLoader />
              </span>
            ) : allVariantsData?.data?.data?.length === 0 ? (
              <span className="d-flex justify-content-center m-3">
                <NoDataFound />
              </span>
            ) : (
              <>
                {selected.length > 0 && (
                  <div className="d-flex align-items-center px-2 mb-3">
                    <button className="button-grey py-2 px-3">
                      <small className="text-lightBlue">
                        {selected.length} reviews are selected{" "}
                        <span
                          className="text-blue-2 c-pointer"
                          onClick={clearSelected}>
                          (Clear Selection)
                        </span>
                      </small>
                    </button>
                  </div>
                )}
                <TableSearchSecondary
                  value={search}
                  onChange={(val) => setSearchDeb(val)}
                  onSearchValueChange={(val) => setSearch(val)}
                />
                <TableContainer className="table-container mt-3">
                  <Table size="medium">
                    <EnhancedTableHead
                      onSelectAllClick={Date}
                      numSelected={selected.length}
                      rowCount={productVariant.length}
                      headCells={headCells}
                      onRequestSort={Date}
                      isCheckBox={false}
                      orderBy=""
                      order="asc"
                    />
                    <TableBody>
                      {productVariant.map((variant, ix) => {
                        const formValue = variantformik.values?.[ix];
                        const defaultMedia = formValue?.mediaUrl?.find(
                          (media) => media.isDefault
                        );
                        const mediaCount = formValue?.mediaUrl?.length ?? 0;
                        // console.log("defaultMedia", formValue, defaultMedia);
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={variant._id}
                            className="table-rows">
                            <TableCell
                              component="th"
                              scope="row"
                              padding="none"
                              sx={{ minWidth: "150px" }}>
                              <div className="d-flex">
                                <small className="d-flex align-items-center text-lightBlue">
                                  {[
                                    ...variant.attributes
                                      .filter(
                                        (at) =>
                                          at.title !== "metal" &&
                                          at.title !== "diamond" &&
                                          at.title !== "gemstone" &&
                                          at.title !== "pearl"
                                      )
                                      .flatMap((at) => {
                                        const optns = variant.options.filter(
                                          (op) => op.metaAttribute.data?.attribute === at._id
                                        );
                                        return optns.map((op) => op.metaAttribute.data.title);
                                      }),
                                    ...variant.attributes
                                      .filter((at) => at.title === "metal")
                                      .flatMap((at) => {
                                        const optns = variant.options.filter(
                                          (op) => op.metaAttribute.data?.attribute === at._id
                                        );
                                        return optns.flatMap((op) => [
                                          op.metaAttribute.data.title,
                                          op.metaAttribute.metaSubAttribute2
                                            .metaSubAttributeValue.title,
                                          op.metaAttribute.metaSubAttribute1
                                            .metaSubAttributeValue.title,
                                        ]);
                                      }),
                                    ...variant.attributes
                                      .filter((at) => at.title === "diamond")
                                      .flatMap((at) => {
                                        const optns = variant.options.filter(
                                          (op) => op.metaAttribute.data?.attribute === at._id
                                        );
                                        return optns.map((op) => op.metaAttribute.data.title);
                                      }),
                                    ...variant.attributes
                                      .filter((at) => at.title === "gemstone")
                                      .flatMap((at) => {
                                        const optns = variant.options.filter(
                                          (op) => op.metaAttribute.data?.attribute === at._id
                                        );
                                        return optns.map((op) => op.metaAttribute.data.title);
                                      }),
                                    ...variant.attributes
                                      .filter((at) => at.title === "pearl")
                                      .flatMap((at) => {
                                        const optns = variant.options.filter(
                                          (op) => op.metaAttribute.data?.attribute === at._id
                                        );
                                        return optns.map((op) => op.metaAttribute.data.title);
                                      }),
                                  ].join(" • ")}
                                </small>
                              </div>
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              padding="none"
                              sx={{ minWidth: "150px" }}>
                              <div className="d-flex justify-content-center">
                                <Button
                                  variant="outlined"
                                  className="d-flex align-items-end"
                                  onClick={() => setMedaiIndex(ix)}>
                                  <span className="mx-1">
                                    <UploadIcon
                                      className="svg"
                                      width={20}
                                      height={20}
                                    />
                                  </span>
                                  <span className="icon-placeholder mx-1">
                                    {!defaultMedia ? (
                                      <ImagePlaceHolder
                                        className="svg"
                                        width={20}
                                        height={20}
                                      />
                                    ) : (
                                      <AsyncImage
                                        src={defaultMedia?.image}
                                        placeholder={infoIcon}
                                        alt="product"
                                        className="rounded-4"
                                        height={20}
                                        width={20}
                                      />
                                    )}
                                  </span>
                                  <span className="icon-placeholder text-lightBlue mx-1">
                                    {mediaCount}
                                  </span>
                                </Button>
                              </div>
                            </TableCell>
                            {productVariantIsDynamicPricing ? (
                              <>
                                {variantformik.values?.[ix]?.priceManager
                                  ?.filter((pm) => pm.title === "metal")
                                  ?.map((_, i) => (
                                    <TableCell
                                      key={i}
                                      sx={{ minWidth: "150px" }}>
                                      <FormControl className="w-100">
                                        <OutlinedInput
                                          placeholder="Enter Weight"
                                          size="small"
                                          name={`${ix}.priceManager.${i}.weight`}
                                          value={formValue.priceManager[i].weight ?? "0"}
                                          onBlur={variantformik.handleBlur}
                                          onChange={(e) => {
                                            const val = e.target.value;
                                            variantformik.setFieldValue(
                                              `${ix}.priceManager.${i}.weight`,
                                              val
                                            );
                                            const net = formValue.priceManager
                                              .filter((pm) => pm.type !== "makingCharge")
                                              .reduce(
                                                (t, pm, j) =>
                                                  t +
                                                  (i === j
                                                    ? Number(val) * (j === 0 ? 1 : 0.2)
                                                    : Number(pm.weight) * (j === 0 ? 1 : 0.2)),
                                                0
                                              );
                                            variantformik.setFieldValue(
                                              `${ix}.netWeight`,
                                              isNaN(net) ? "" : net
                                            );
                                          }}
                                        />
                                      </FormControl>
                                      <FormHelperText error>
                                        {variantformik.touched?.[ix]?.priceManager?.[i]
                                          ?.weight &&
                                          variantformik.errors?.[ix]?.priceManager?.[i]?.weight}
                                      </FormHelperText>
                                    </TableCell>
                                  ))}
                                <TableCell sx={{ minWidth: "150px" }}>
                                  <FormControl className="w-100">
                                    <OutlinedInput
                                      placeholder="Enter Net Weight"
                                      size="small"
                                      name={`${ix}.netWeight`}
                                      value={formValue?.netWeight ?? 0}
                                      onChange={variantformik.handleChange}
                                      onBlur={variantformik.handleBlur}
                                    />
                                    <FormHelperText error>
                                      {variantformik.touched?.[ix]?.netWeight &&
                                        variantformik.errors?.[ix]?.netWeight}
                                    </FormHelperText>
                                  </FormControl>
                                </TableCell>
                                <TableCell sx={{ minWidth: "150px" }}>
                                  <PriceBreakup variant={variant} />
                                </TableCell>
                                <TableCell sx={{ minWidth: "150px" }}>
                                  <FormControl className="w-100">
                                    <Select
                                      size="small"
                                      name={`${ix}.discountBreakup.type`}
                                      value={formValue?.discountBreakup?.type ?? ""}
                                      onBlur={variantformik.handleBlur}
                                      onChange={variantformik.handleChange}>
                                      <MenuItem
                                        value="percentage"
                                        sx={{ fontSize: 13, color: "#5c6d8e" }}>
                                        Percentage
                                      </MenuItem>
                                      <MenuItem
                                        value="fixed"
                                        sx={{ fontSize: 13, color: "#5c6d8e" }}>
                                        Fixed
                                      </MenuItem>
                                    </Select>
                                    <FormHelperText error>
                                      {variantformik.touched?.[ix]?.discountBreakup?.type &&
                                        variantformik.errors?.[ix]?.discountBreakup?.type}
                                    </FormHelperText>
                                  </FormControl>
                                </TableCell>
                                <TableCell sx={{ minWidth: "150px" }}>
                                  <FormControl className="w-100">
                                    <OutlinedInput
                                      size="small"
                                      placeholder="Enter Discount"
                                      name={`${ix}.discountBreakup.discount`}
                                      value={formValue?.discountBreakup?.discount ?? 0}
                                      onBlur={variantformik.handleBlur}
                                      onChange={variantformik.handleChange}
                                    />
                                    <FormHelperText error>
                                      {variantformik.touched?.[ix]?.discountBreakup?.discount &&
                                        variantformik.errors?.[ix]?.discountBreakup?.discount}
                                    </FormHelperText>
                                  </FormControl>
                                </TableCell>
                                {variantformik.values?.[ix]?.discountOn?.map((_, i) => (
                                  <TableCell
                                    key={i}
                                    sx={{ minWidth: "150px" }}>
                                    <FormControl className="w-100">
                                      <OutlinedInput
                                        placeholder="Enter Discount"
                                        size="small"
                                        name={`${ix}.discountOn.${i}.discount`}
                                        value={formValue.discountOn[i].discount ?? "0"}
                                        onBlur={variantformik.handleBlur}
                                        onChange={variantformik.handleChange}
                                      />
                                    </FormControl>
                                    <FormHelperText error>
                                      {variantformik.touched?.[ix]?.discountOn?.[i]?.discount &&
                                        variantformik.errors?.[ix]?.discountOn?.[i]?.discount}
                                    </FormHelperText>
                                  </TableCell>
                                ))}
                              </>
                            ) : (
                              <>
                                <TableCell sx={{ minWidth: "150px" }}>
                                  <FormControl className="w-100">
                                    <OutlinedInput
                                      size="small"
                                      placeholder="Enter Total Price"
                                      name={`${ix}.priceBreakup.price`}
                                      value={formValue?.priceBreakup?.price ?? 0}
                                      onBlur={variantformik.handleBlur}
                                      onChange={(e) => {
                                        const val = e.target.value;
                                        const num = Number(val);
                                        variantformik.setFieldValue(
                                          `${ix}.priceBreakup.price`,
                                          isNaN(num) ? val : num
                                        );
                                        variantformik.setFieldValue(
                                          `${ix}.priceBreakup.salePrice`,
                                          num -
                                            (formValue.priceBreakup.type === "fixed"
                                              ? formValue.priceBreakup.discount
                                              : num * (formValue.priceBreakup.discount / 100))
                                        );
                                      }}
                                    />
                                    <FormHelperText error>
                                      {variantformik.touched?.[ix]?.priceBreakup?.price &&
                                        variantformik.errors?.[ix]?.priceBreakup?.price}
                                    </FormHelperText>
                                  </FormControl>
                                </TableCell>
                                <TableCell sx={{ minWidth: "150px" }}>
                                  <FormControl className="w-100">
                                    <Select
                                      size="small"
                                      name={`${ix}.priceBreakup.type`}
                                      value={formValue?.priceBreakup?.type ?? ""}
                                      onBlur={variantformik.handleBlur}
                                      onChange={(e) => {
                                        const val = e.target.value;
                                        variantformik.setFieldValue(
                                          `${ix}.priceBreakup.type`,
                                          val
                                        );
                                        variantformik.setFieldValue(
                                          `${ix}.priceBreakup.salePrice`,
                                          formValue.priceBreakup.price -
                                            (val === "fixed"
                                              ? formValue.priceBreakup.discount
                                              : formValue.priceBreakup.price *
                                                (formValue.priceBreakup.discount / 100))
                                        );
                                      }}>
                                      <MenuItem
                                        value="percentage"
                                        sx={{ fontSize: 13, color: "#5c6d8e" }}>
                                        Percentage
                                      </MenuItem>
                                      <MenuItem
                                        value="fixed"
                                        sx={{ fontSize: 13, color: "#5c6d8e" }}>
                                        Fixed
                                      </MenuItem>
                                    </Select>
                                    <FormHelperText error>
                                      {variantformik.touched?.[ix]?.priceBreakup?.type &&
                                        variantformik.errors?.[ix]?.priceBreakup?.type}
                                    </FormHelperText>
                                  </FormControl>
                                </TableCell>
                                <TableCell sx={{ minWidth: "150px" }}>
                                  <FormControl className="w-100">
                                    <OutlinedInput
                                      size="small"
                                      placeholder="Enter Discount"
                                      name={`${ix}.priceBreakup.discount`}
                                      value={formValue?.priceBreakup?.discount ?? 0}
                                      onBlur={variantformik.handleBlur}
                                      onChange={(e) => {
                                        const val = e.target.value;
                                        const num = Number(val);
                                        variantformik.setFieldValue(
                                          `${ix}.priceBreakup.discount`,
                                          isNaN(num) ? val : num
                                        );
                                        variantformik.setFieldValue(
                                          `${ix}.priceBreakup.salePrice`,
                                          formValue.priceBreakup.price -
                                            (formValue.priceBreakup.type === "fixed"
                                              ? num
                                              : formValue.priceBreakup.price * (num / 100))
                                        );
                                      }}
                                    />
                                    <FormHelperText error>
                                      {variantformik.touched?.[ix]?.priceBreakup?.discount &&
                                        variantformik.errors?.[ix]?.priceBreakup?.discount}
                                    </FormHelperText>
                                  </FormControl>
                                </TableCell>
                                <TableCell sx={{ minWidth: "150px" }}>
                                  <FormControl className="w-100">
                                    <OutlinedInput
                                      size="small"
                                      placeholder="Enter Sell Price"
                                      name={`${ix}.priceBreakup.salePrice`}
                                      value={formValue?.priceBreakup?.salePrice ?? 0}
                                      onChange={variantformik.handleChange}
                                      onBlur={variantformik.handleBlur}
                                    />
                                    <FormHelperText error>
                                      {variantformik.touched?.[ix]?.priceBreakup?.salePrice &&
                                        variantformik.errors?.[ix]?.priceBreakup?.salePrice}
                                    </FormHelperText>
                                  </FormControl>
                                </TableCell>
                              </>
                            )}
                            {isInventoryVariants && (
                              <>
                                <TableCell sx={{ minWidth: "150px" }}>
                                  <div className="w-100">
                                    <p className="text-lightBlue text-center">
                                      {formValue?.comitted}
                                    </p>
                                  </div>
                                </TableCell>
                                <TableCell sx={{ minWidth: "150px" }}>
                                  <div className="d-flex justify-content-around align-items-center w-100">
                                    <span className="text-lightBlue text-center">
                                      {formValue?.bagNumber?.length ?? 0}
                                    </span>
                                    <span className="table-edit-icon rounded-4 p-2">
                                      <Tooltip
                                        title={formValue?.bagNumber?.map((bno) => (
                                          <>
                                            {bno}
                                            <br />
                                          </>
                                        ))}
                                        placement="top">
                                        <VisibilityOutlinedIcon
                                          sx={{
                                            color: "#5c6d8e",
                                            fontSize: 18,
                                            cursor: "pointer",
                                          }}
                                        />
                                      </Tooltip>
                                    </span>
                                    <span
                                      onClick={() => setBagNoIndex(ix)}
                                      className="table-edit-icon rounded-4 p-2">
                                      <EditOutlinedIcon
                                        sx={{
                                          color: "#5c6d8e",
                                          fontSize: 18,
                                          cursor: "pointer",
                                        }}
                                      />
                                    </span>
                                  </div>
                                  {/* <FormControl className="w-100">
                                    <OutlinedInput
                                      placeholder="Enter Qty"
                                      size="small"
                                      name={`${ix}.quantity`}
                                      value={formValue?.quantity ?? 0}
                                      onChange={variantformik.handleChange}
                                      onBlur={variantformik.handleBlur}
                                    />
                                    <FormHelperText error>
                                      {variantformik.touched?.[ix]?.quantity &&
                                        variantformik.errors?.[ix]?.quantity}
                                    </FormHelperText>
                                  </FormControl> */}
                                </TableCell>
                                <TableCell sx={{ minWidth: "150px" }}>
                                  <div className="d-flex align-items-center ps-1">
                                    <AntSwitch
                                      name={`${ix}.isSellOutOfStock`}
                                      checked={!!formValue?.isSellOutOfStock}
                                      onChange={variantformik.handleChange}
                                    />
                                  </div>
                                </TableCell>
                              </>
                            )}
                            <TableCell sx={{ minWidth: "150px" }}>
                              <FormControl className="w-100">
                                <OutlinedInput
                                  placeholder="Enter SKU"
                                  size="small"
                                  name={`${ix}.barCode`}
                                  value={formValue?.barCode ?? ""}
                                  onChange={variantformik.handleChange}
                                  onBlur={variantformik.handleBlur}
                                />
                                <FormHelperText error>
                                  {variantformik.touched?.[ix]?.barCode &&
                                    variantformik.errors?.[ix]?.barCode}
                                </FormHelperText>
                              </FormControl>
                            </TableCell>
                            {totalProductVariantCount > 1 && (
                              <TableCell
                                padding="none"
                                sx={{ minWidth: "150px" }}>
                                <div className="d-flex align-items-center ps-1">
                                  <AntSwitch
                                    name={`${ix}.isDefault`}
                                    checked={!!formValue?.isDefault}
                                    onChange={(e) =>
                                      variantformik.values.forEach((_, j) =>
                                        variantformik.setFieldValue(
                                          `${j}.isDefault`,
                                          e.target.checked ? j === ix : false
                                        )
                                      )
                                    }
                                  />
                                </div>
                              </TableCell>
                            )}
                            <TableCell sx={{ minWidth: "150px" }}>
                              <FormControl className="w-100">
                                <OutlinedInput
                                  placeholder="Enter Weight"
                                  size="small"
                                  endAdornment={
                                    <InputAdornment position="end">kg</InputAdornment>
                                  }
                                  type="number"
                                  name={`${ix}.shipping.weight`}
                                  value={formValue?.shipping?.weight ?? ""}
                                  onChange={variantformik.handleChange}
                                  onBlur={variantformik.handleBlur}
                                />
                                <FormHelperText error>
                                  {variantformik.touched?.[ix]?.shipping?.weight &&
                                    variantformik.errors?.[ix]?.shipping?.weight}
                                </FormHelperText>
                              </FormControl>
                            </TableCell>
                            <TableCell sx={{ minWidth: "150px" }}>
                              <FormControl className="w-100">
                                <OutlinedInput
                                  placeholder="Enter Length"
                                  size="small"
                                  endAdornment={
                                    <InputAdornment position="end">cm</InputAdornment>
                                  }
                                  type="number"
                                  name={`${ix}.shipping.length`}
                                  value={formValue?.shipping?.length ?? ""}
                                  onChange={variantformik.handleChange}
                                  onBlur={variantformik.handleBlur}
                                />
                                <FormHelperText error>
                                  {variantformik.touched?.[ix]?.shipping?.length &&
                                    variantformik.errors?.[ix]?.shipping?.length}
                                </FormHelperText>
                              </FormControl>
                            </TableCell>
                            <TableCell sx={{ minWidth: "150px" }}>
                              <FormControl className="w-100">
                                <OutlinedInput
                                  placeholder="Enter Height"
                                  size="small"
                                  endAdornment={
                                    <InputAdornment position="end">cm</InputAdornment>
                                  }
                                  type="number"
                                  name={`${ix}.shipping.height`}
                                  value={formValue?.shipping?.height ?? ""}
                                  onChange={variantformik.handleChange}
                                  onBlur={variantformik.handleBlur}
                                />
                                <FormHelperText error>
                                  {variantformik.touched?.[ix]?.shipping?.height &&
                                    variantformik.errors?.[ix]?.shipping?.height}
                                </FormHelperText>
                              </FormControl>
                            </TableCell>
                            <TableCell sx={{ minWidth: "150px" }}>
                              <FormControl className="w-100">
                                <OutlinedInput
                                  placeholder="Enter Width"
                                  size="small"
                                  endAdornment={
                                    <InputAdornment position="end">cm</InputAdornment>
                                  }
                                  type="number"
                                  name={`${ix}.shipping.width`}
                                  value={formValue?.shipping?.width ?? ""}
                                  onChange={variantformik.handleChange}
                                  onBlur={variantformik.handleBlur}
                                />
                                <FormHelperText error>
                                  {variantformik.touched?.[ix]?.shipping?.width &&
                                    variantformik.errors?.[ix]?.shipping?.width}
                                </FormHelperText>
                              </FormControl>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}
            <p className="text-lightBlue text-center fw-500 mt-2">
              {(pageNo - 1) * pageSize + 1} - {(pageNo - 1) * pageSize + pageSize} of{" "}
              {totalVariants}
            </p>
          </div>
          <div className="row align-items-center mt-4">
            {discardable ? (
              <div className="col-12 d-flex justify-content-center">
                <LoadingButton
                  onClick={variantformik.handleSubmit}
                  loading={bulkUpdateVariansIsLoading}
                  disabled={bulkUpdateVariansIsLoading}
                  className="button-gradient py-2 px-4">
                  <p>Save Changes</p>
                </LoadingButton>
              </div>
            ) : (
              <div className="col-12 d-flex justify-content-between">
                <button
                  onClick={prevStep}
                  className="button-red-outline py-2 px-4">
                  <p>Previous</p>
                </button>
                <button
                  onClick={nextStep}
                  className="button-gradient py-2 px-4">
                  <p>Next</p>
                </button>
              </div>
            )}
          </div>
        </>
      )}
      <VariantBagNumber
        index={bagNoIndex}
        variant={productVariant[bagNoIndex]}
        formik={variantformik}
        onClose={setBagNoIndex}
      />
      <VariantImages
        index={medaiIndex}
        formik={variantformik}
        onClose={setMedaiIndex}
      />
    </div>
  );
}

function VariantBagNumber({ index = -1, variant = null, formik = {}, onClose = () => {} }) {
  const formValue = formik?.values?.[index] ?? null;
  const closeDialog = () => onClose(-1);

  return (
    <Dialog
      keepMounted
      maxWidth="sm"
      fullWidth
      open={index >= 0}
      onClose={closeDialog}>
      <DialogTitle>
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex flex-column ">
            <h5 className="text-lightBlue fw-500">Add Physical Variants</h5>
            {variant && (
              <small className="text-grey-6 mt-2 d-block">
                Selected Variant -{" "}
                {[
                  ...variant?.attributes
                    .filter(
                      (at) =>
                        at.title !== "metal" &&
                        at.title !== "diamond" &&
                        at.title !== "gemstone" &&
                        at.title !== "pearl"
                    )
                    .flatMap((at) => {
                      const optns = variant?.options.filter(
                        (op) => op.metaAttribute.data?.attribute === at._id
                      );
                      return optns.map((op) => op.metaAttribute.data.title);
                    }),
                  ...variant?.attributes
                    .filter((at) => at.title === "metal")
                    .flatMap((at) => {
                      const optns = variant?.options.filter(
                        (op) => op.metaAttribute.data?.attribute === at._id
                      );
                      return optns.flatMap((op) => [
                        op.metaAttribute.data.title,
                        op.metaAttribute.metaSubAttribute2.metaSubAttributeValue.title,
                        op.metaAttribute.metaSubAttribute1.metaSubAttributeValue.title,
                      ]);
                    }),
                  ...variant?.attributes
                    .filter((at) => at.title === "diamond")
                    .flatMap((at) => {
                      const optns = variant?.options.filter(
                        (op) => op.metaAttribute.data?.attribute === at._id
                      );
                      return optns.map((op) => op.metaAttribute.data.title);
                    }),
                  ...variant?.attributes
                    .filter((at) => at.title === "gemstone")
                    .flatMap((at) => {
                      const optns = variant?.options.filter(
                        (op) => op.metaAttribute.data?.attribute === at._id
                      );
                      return optns.map((op) => op.metaAttribute.data.title);
                    }),
                  ...variant?.attributes
                    .filter((at) => at.title === "pearl")
                    .flatMap((at) => {
                      const optns = variant?.options.filter(
                        (op) => op.metaAttribute.data?.attribute === at._id
                      );
                      return optns.map((op) => op.metaAttribute.data.title);
                    }),
                ].join(" • ")}
              </small>
            )}
          </div>
          <button
            type="button"
            className="reset"
            onClick={closeDialog}>
            <img
              src={cancel}
              alt="cancel"
              width={30}
              className="c-pointer"
            />
          </button>
        </div>
      </DialogTitle>

      <hr className="hr-grey-6 my-0" />

      <DialogContent className="py-3 px-4">
        <div className="row mb-3 w-100">
          <div className="d-flex ps-1">
            <p className="text-lightBlue">Add Available Quantities Bag Number</p>
            <Tooltip
              title="Lorem ipsum"
              placement="top">
              <img
                src={info}
                alt="info"
                className="c-pointer ms-2"
                width={13.5}
              />
            </Tooltip>
          </div>
        </div>
        {formValue?.bagNumber?.map((bgno, ix) => (
          <div
            key={ix}
            className="row productInfo">
            <FormControl className="col my-2">
              <OutlinedInput
                placeholder="Enter Bag No"
                size="small"
                name={`${index}.bagNumber.${ix}`}
                value={bgno ?? ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <FormHelperText error>
                {formik.touched?.[index]?.bagNumber?.[ix] &&
                  formik.errors?.[index]?.bagNumber?.[ix]}
              </FormHelperText>
            </FormControl>
            <div className="col-auto my-2">
              <span
                onClick={() =>
                  formik.setFieldValue(
                    `${index}.bagNumber`,
                    formValue.bagNumber.filter((_, i) => i !== ix)
                  )
                }
                className="table-edit-icon rounded-4 p-2">
                <DeleteIcon
                  sx={{
                    color: "#5c6d8e",
                    fontSize: 18,
                    cursor: "pointer",
                  }}
                />
              </span>
            </div>
          </div>
        ))}
        <div className="row mt-3 w-100">
          <Button
            onClick={() =>
              formik.setFieldValue(`${index}.bagNumber`, formValue.bagNumber.concat(""))
            }
            variant="text"
            className="col-auto">
            <AddCircleOutlineOutlinedIcon
              sx={{
                color: "#5c6d8e",
                cursor: "pointer",
              }}
            />
            <span className="text-blue-2 ps-1">Add more quantity</span>
          </Button>
        </div>
      </DialogContent>

      <hr className="hr-grey-6 my-0" />

      <DialogActions className="d-flex justify-content-center px-4 py-3">
        <button
          onClick={closeDialog}
          className="button-gradient py-2 px-4 c-pointer">
          <p>Done</p>
        </button>
      </DialogActions>
    </Dialog>
  );
}

function VariantImages({ index = -1, formik = {}, onClose = () => {} }) {
  const Default = formik?.values?.[index]?.mediaUrl?.find((media) => media.isDefault) ?? {};
  const Other = formik?.values?.[index]?.mediaUrl?.filter((media) => !media.isDefault) ?? [];

  const defaultMediaHandler = (url) => {
    if (url) {
      formik?.setFieldValue(`${index}.mediaUrl`, [{ isDefault: true, image: url }, ...Other]);
      // updateVariants(index, [{ isDefault: true, image: url }, ...Other]);
    } else {
      formik?.setFieldValue(`${index}.mediaUrl`, Other);
      // updateVariants(index, Other);
    }
  };

  const mediaHandler = (urls) => {
    const medias = urls.map((url) => {
      return {
        isDefault: false,
        image: url,
      };
    });
    formik?.setFieldValue(`${index}.mediaUrl`, [Default, ...medias]);
    // updateVariants(index, [Default, ...medias]);
  };

  const removeMediaHandler = (index) => {
    const medias = Other.filter((_, i) => index !== i);
    formik?.setFieldValue(`${index}.mediaUrl`, [Default, ...medias]);
    // updateVariants(index, [Default, ...medias]);
  };

  const closeDialog = () => {
    onClose(-1);
  };

  return (
    <Dialog
      keepMounted
      aria-describedby="alert-dialog-slide-description"
      maxWidth="lg"
      fullWidth
      open={index >= 0}
      onClose={closeDialog}>
      <DialogTitle>
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex flex-column ">
            <h5 className="text-lightBlue fw-500">Variant Medias</h5>
          </div>
          <img
            src={cancel}
            alt="cancel"
            width={30}
            className="c-pointer"
            onClick={closeDialog}
          />
        </div>
      </DialogTitle>

      <hr className="hr-grey-6 my-0" />

      <DialogContent className="py-3 px-4">
        <div className="row productInfo">
          <div className="col-3 ps-0 d-flex flex-column">
            <UploadMediaLarge
              fileSrc={Default?.image}
              style={{ height: "20rem" }}
              onUpload={defaultMediaHandler}
              className="product-media"
              // onBlur={productFormik.handleBlur}
              isMandatory
            />

            <button
              type="button"
              className="primary-image py-2 w-100 mt-2">
              <p className="me-2">Primary Image</p>
              <span
                style={{ color: "#F67476" }}
                className="me-2">
                *
              </span>
            </button>
          </div>
          <div className="col-9 pe-0">
            <UploadMultipleMediaLarge
              fileSrc={Other}
              style={{ minHeight: "20rem" }}
              onUpload={mediaHandler}
              onRemove={removeMediaHandler}
            />
          </div>
        </div>
      </DialogContent>

      <hr className="hr-grey-6 my-0" />

      <DialogActions className="d-flex justify-content-center px-4 py-3">
        <button
          onClick={closeDialog}
          className="button-gradient py-2 px-4 c-pointer">
          <p>Done</p>
        </button>
      </DialogActions>
    </Dialog>
  );
}

export function PriceBreakup({ variant }) {
  // console.log("variant", variant);
  const { data: globalGSTData, isLoading: globalGSTIsLoading } = useGetGlobalGSTQuery();

  const gstData = useMemo(() => {
    const { taxRate = 0, includeInPrice = true } = globalGSTData?.data?.data?.[0] ?? {};
    return { taxRate, includeInPrice };
  }, [globalGSTData]);

  const [anchorEl, setAnchorEl] = useState(null);

  const metalStuff = variant.priceBreakup
    ?.filter((pb) => pb?.priceType === "metalPrice")
    ?.reduce(
      (ac, pb) => {
        ac.price += pb.price;
        ac.salePrice += pb.price - pb.discountAmount;
        ac.discount = pb.discountValue;
        return ac;
      },
      { price: 0, salePrice: 0, discount: 0 }
    );

  const diamondStuff = variant.priceBreakup
    ?.filter((pb) => pb?.priceType === "diamondPrice")
    ?.reduce(
      (ac, pb) => {
        ac.price += pb.price;
        ac.salePrice += pb.price - pb.discountAmount;
        ac.discount = pb.discountValue;
        return ac;
      },
      { price: 0, salePrice: 0, discount: 0 }
    );

  const gemstoneStuff = variant.priceBreakup
    ?.filter((pb) => pb?.priceType === "gemstonePrice")
    ?.reduce(
      (ac, pb) => {
        ac.price += pb.price;
        ac.salePrice += pb.price - pb.discountAmount;
        ac.discount = pb.discountValue;
        return ac;
      },
      { price: 0, salePrice: 0, discount: 0 }
    );

  const pearlStuff = variant.priceBreakup
    ?.filter((pb) => pb?.priceType === "pearlPrice")
    ?.reduce(
      (ac, pb) => {
        ac.price += pb.price;
        ac.salePrice += pb.price - pb.discountAmount;
        ac.discount = pb.discountValue;
        return ac;
      },
      { price: 0, salePrice: 0, discount: 0 }
    );

  const makingChargesStuff = variant.priceBreakup?.find(
    (pb) => pb.extraChargeType === "Making Charges"
  ) ?? { price: 0, discountAmount: 0, discountValue: 0 };

  const discountStuff = variant.priceBreakup?.find((pb) => "type" in pb) ?? {
    type: "fixed",
    discount: 0,
    discountAmount: 0,
  };

  const totalSalePrice =
    metalStuff.salePrice +
    diamondStuff.salePrice +
    gemstoneStuff.salePrice +
    pearlStuff.salePrice +
    makingChargesStuff.price -
    makingChargesStuff.discountAmount;

  const totalPrice =
    metalStuff.price +
    diamondStuff.price +
    gemstoneStuff.price +
    pearlStuff.price +
    makingChargesStuff.price;

  const gstPrice = (totalPrice * gstData.taxRate) / 100;

  // const metalArrtibute = variant?.attributes?.find((attr) => attr.title === "metal");
  // const metalPrice = metalArrtibute
  //   ? variant?.priceBreakup
  //       ?.filter((pbk) => pbk?.attribute === metalArrtibute?._id)
  //       ?.reduce((t, v) => t + v.price - v.discountAmount, 0) ?? 0
  //   : 0;
  // const diamondArrtibute = variant?.attributes?.find((attr) => attr.title === "diamond");
  // const diamondPrice = diamondArrtibute
  //   ? variant?.priceBreakup
  //       ?.filter((pbk) => pbk?.attribute === diamondArrtibute?._id)
  //       ?.reduce((t, v) => t + v.price - v.discountAmount, 0) ?? 0
  //   : 0;
  // const gemstoneArrtibute = variant?.attributes?.find((attr) => attr.title === "gemstone");
  // const gemstonePrice = gemstoneArrtibute
  //   ? variant?.priceBreakup
  //       ?.filter((pbk) => pbk?.attribute === gemstoneArrtibute?._id)
  //       ?.reduce((t, v) => t + v.price - v.discountAmount, 0) ?? 0
  //   : 0;
  // const pearlArrtibute = variant?.attributes?.find((attr) => attr.title === "pearl");
  // const pearlPrice = pearlArrtibute
  //   ? variant?.priceBreakup
  //       ?.filter((pbk) => pbk?.attribute === pearlArrtibute?._id)
  //       ?.reduce((t, v) => t + v.price - v.discountAmount, 0) ?? 0
  //   : 0;
  // const makingCharges =
  //   variant?.priceBreakup
  //     ?.filter((pbk) => pbk.extraChargeType === "Making Charges")
  //     ?.reduce((t, v) => t + v.price - v.discountAmount, 0) ?? 0;
  // const discountBreakup = variant?.priceBreakup?.find((pbk) => "discount" in pbk);
  // const GSTData = variant?.priceBreakup?.find((pbk) => "isGstInclude" in pbk);
  // console.log(GSTData);
  // ?.reduce((t, v) => t + (v.isGstInclude ? v.price : 0), 0) ?? 0;
  // Math.floor((metalPrice + diamondPrice + gemstonePrice + pearlPrice + makingCharges) * 0.03);
  // const subTotalPrice = Math.round(
  //   metalPrice + diamondPrice + gemstonePrice + pearlPrice + makingCharges
  // );
  // const gstPrice = (subTotalPrice * gstData.taxRate) / 100;
  // const totalPrice = Math.round(
  //   subTotalPrice + gstPrice - (discountBreakup?.discountAmount ?? 0)
  // );

  return (
    <>
      <div
        onClick={(e) => setAnchorEl(e.currentTarget)}
        className="d-flex align-items-center c-pointer">
        <p className="text-lightBlue">
          {rupeeFormatWithSymbol(totalSalePrice + gstPrice - discountStuff.discountAmount)}
        </p>
        <img
          className="ms-3"
          src={arrowDown}
          alt="arrowDown"
        />
      </div>
      {/*!variant?.isDynamicPricing ? (
        <div className="d-flex align-items-center">
          <p className="text-lightBlue">{rupeeFormatWithSymbol(totalPrice)}</p>
        </div>
      ) : (
        <div
          onClick={(e) => setAnchorEl(e.currentTarget)}
          className="d-flex align-items-center c-pointer">
          <p className="text-lightBlue">{rupeeFormatWithSymbol(totalPrice)}</p>
          <img
            className="ms-3"
            src={arrowDown}
            alt="arrowDown"
          />
        </div>
      )*/}
      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        PaperProps={{ style: { width: "30%" } }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}>
        <div className="row m-2">
          <p
            className="col-12 mb-2 text-lightBlue"
            style={{ fontSize: "1rem" }}>
            Price Breakup
          </p>

          {metalStuff.price > 0 && (
            <>
              <div className="col-4 text-grey-6">
                <small>
                  Metal Price{" "}
                  {!!metalStuff.discount &&
                    `(${discountStuff.type[0] === "f" ? "₹" : ""}${metalStuff.discount}${
                      discountStuff.type[0] === "p" ? "%" : ""
                    })`}
                </small>
              </div>
              <div className="col-4 text-grey-6">
                <small className=" text-lightBlue">
                  {rupeeFormatWithSymbol(metalStuff.salePrice)}
                </small>
              </div>
              <div className="col-4 text-grey-6">
                <small className=" text-lightBlue">
                  {rupeeFormatWithSymbol(metalStuff.price)}
                </small>
              </div>
            </>
          )}

          {diamondStuff.price > 0 && (
            <>
              <div className="col-4 text-grey-6">
                <small>
                  Diamond Price{" "}
                  {!!diamondStuff.discount &&
                    `(${discountStuff.type[0] === "f" ? "₹" : ""}${diamondStuff.discount}${
                      discountStuff.type[0] === "p" ? "%" : ""
                    })`}
                </small>
              </div>
              <div className="col-4 text-grey-6">
                <small className=" text-lightBlue">
                  {rupeeFormatWithSymbol(diamondStuff.salePrice)}
                </small>
              </div>
              <div className="col-4 text-grey-6">
                <small className=" text-lightBlue">
                  {rupeeFormatWithSymbol(diamondStuff.price)}
                </small>
              </div>
            </>
          )}

          {gemstoneStuff.price > 0 && (
            <>
              <div className="col-4 text-grey-6">
                <small>
                  Gemstone Price{" "}
                  {!!gemstoneStuff.discount &&
                    `(${discountStuff.type[0] === "f" ? "₹" : ""}${gemstoneStuff.discount}${
                      discountStuff.type[0] === "p" ? "%" : ""
                    })`}
                </small>
              </div>
              <div className="col-4 text-grey-6">
                <small className=" text-lightBlue">
                  {rupeeFormatWithSymbol(gemstoneStuff.salePrice)}
                </small>
              </div>
              <div className="col-4 text-grey-6">
                <small className=" text-lightBlue">
                  {rupeeFormatWithSymbol(gemstoneStuff.price)}
                </small>
              </div>
            </>
          )}

          {pearlStuff.price > 0 && (
            <>
              <div className="col-4 text-grey-6">
                <small>
                  Pearl Price{" "}
                  {!!pearlStuff.discount &&
                    `(${discountStuff.type[0] === "f" ? "₹" : ""}${pearlStuff.discount}${
                      discountStuff.type[0] === "p" ? "%" : ""
                    })`}
                </small>
              </div>
              <div className="col-4 text-grey-6">
                <small className=" text-lightBlue">
                  {rupeeFormatWithSymbol(pearlStuff.salePrice)}
                </small>
              </div>
              <div className="col-4 text-grey-6">
                <small className=" text-lightBlue">
                  {rupeeFormatWithSymbol(pearlStuff.price)}
                </small>
              </div>
            </>
          )}

          {makingChargesStuff.price > 0 && (
            <>
              <div className="col-4 text-grey-6">
                <small>
                  Making Charges{" "}
                  {!!makingChargesStuff.discountValue &&
                    `(${discountStuff.type[0] === "f" ? "₹" : ""}${
                      makingChargesStuff.discountValue
                    }${discountStuff.type[0] === "p" ? "%" : ""})`}
                </small>
              </div>
              <div className="col-4 text-grey-6">
                <small className=" text-lightBlue">
                  {rupeeFormatWithSymbol(
                    makingChargesStuff.price - makingChargesStuff.discountAmount
                  )}
                </small>
              </div>
              <div className="col-4 text-grey-6">
                <small className=" text-lightBlue">
                  {rupeeFormatWithSymbol(makingChargesStuff.price)}
                </small>
              </div>
            </>
          )}

          <div className="col-4 text-grey-6">
            <small>GST ({gstData.taxRate}%)</small>
          </div>
          <div className="col-4 text-grey-6">
            <small className=" text-lightBlue">{rupeeFormatWithSymbol(gstPrice)}</small>
          </div>
          <div className="col-4 text-grey-6">
            <small className=" text-lightBlue">{rupeeFormatWithSymbol(gstPrice)}</small>
          </div>

          <div className="col-4 text-lightBlue fw-600">
            <p>Sub Total</p>
          </div>
          <div className="col-4 text-lightBlue fw-600">
            <p className="">{rupeeFormatWithSymbol(totalSalePrice + gstPrice)}</p>
          </div>
          <div className="col-4 text-lightBlue fw-600">
            <p className="">{rupeeFormatWithSymbol(totalPrice + gstPrice)}</p>
          </div>

          {discountStuff.discount > 0 && (
            <>
              <div className="col-4 text-grey-6">
                <small>
                  Total Discount ({discountStuff.type[0] === "f" ? "₹" : ""}
                  {discountStuff.discount}
                  {discountStuff.type[0] === "p" ? "%" : ""})
                </small>
              </div>
              <div className="col-4 text-grey-6">
                <small className=" text-lightBlue">
                  {rupeeFormatWithSymbol(discountStuff.discountAmount)}
                </small>
              </div>
              <div className="col-4 text-grey-6">
                {/* <small className=" text-lightBlue">
                  {rupeeFormatWithSymbol(pearlStuff.price)}
                </small> */}
              </div>
            </>
          )}

          <div className="col-4 text-lightBlue fw-600">
            <p>Sale Price</p>
          </div>
          <div className="col-4 text-lightBlue fw-600">
            <p className="">
              {rupeeFormatWithSymbol(totalSalePrice + gstPrice - discountStuff.discountAmount)}
            </p>
          </div>

          {/* <div className="col-4 text-lightBlue fw-600">
            <p className="">{rupeeFormatWithSymbol(totalPrice + gstPrice)}</p>
          </div> */}

          {/* <div className="d-flex align-items-center justify-content-between mt-2">
            <p className="text-lightBlue">Sale Price</p>
            <p className=" text-lightBlue fw-600">{rupeeFormatWithSymbol(totalPrice)}</p>
          </div> */}
        </div>
      </Popover>
    </>
  );
}
