import info from "../../../../assets/icons/info.svg";
import video from "../../../../assets/images/dashboard/video.png";
import { Button, Table, TableBody, TableContainer, Tooltip } from "@mui/material";
import ImageIconView from "../FileManagerViews/ImageIconView";
import { useDispatch } from "react-redux";
import { useState } from "react";
import {
  useBulkDeleteFilesMutation,
  useBulkEditFileMutation,
  useDeleteFileMutation,
  useEditFileMutation,
} from "../../../../features/settings/filemanager/filemanagerApiSlice";
import TableMassActionButton from "../../../../components/TableMassActionButton/TableMassActionButton";
import { useGetFilesQuery } from "../../../../features/settings/filemanager/filemanagerApiSlice";
import DeleteAlertDialog from "../Dialogs/DeleteAlertDialog";
import { showError, showSuccess } from "../../../../features/snackbar/snackbarAction";
import NameRenameDialog from "../Dialogs/NameRenameDialog";
import VideoIconView from "../FileManagerViews/VideoIconView";
import MoveInFolderDialog from "../Dialogs/MoveInFolderDialog";
import { EnhancedTableHead } from "../../../../components/TableDependencies/TableDependencies";
import ImageListView from "../FileManagerViews/ImageListView";
import VideoListView from "../FileManagerViews/VideoListView";
import TableLoader from "../../../../components/Loader/TableLoader";
import NoDataFound from "../../../../components/NoDataFound/NoDataFound";
import apiSlice from "../../../../app/api/apiSlice";

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
  },
  {
    id: "type",
    numeric: false,
    disablePadding: false,
    label: "Type",
  },
  {
    id: "size",
    numeric: false,
    disablePadding: false,
    label: "Size",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    label: "Actions",
  },
];

export default function FilesOnly({ views = "icon", fileType = "", onPopup = () => {}, queryFilters = {} }) {
  const dispatch = useDispatch();

  const [selected, setSelected] = useState([]);

  const clearSelected = () => setSelected([]);

  const [pageSize, setPageSize] = useState(12);
  const { data: allFilesData, isLoading: allFilesIsLoading } = useGetFilesQuery({
    ...queryFilters,
    fileType,
    pageSize,
    pageNo: 1,
  });
  const allFiles = allFilesData?.data?.data ?? [];
  const totalFiles = allFilesData?.data?.totalCount ?? 0;

  const [editFile, { isLoading: editFileIsLoading }] = useEditFileMutation();
  const [deleteFile, { isLoading: deleteFileIsLoading }] = useDeleteFileMutation();
  const [bulkEditFiles, { isLoading: bulkEditFilesIsLoading }] = useBulkEditFileMutation();
  const [bulkDeleteFiles, { isLoading: bulkDeleteFilesIsLoading }] = useBulkDeleteFilesMutation();

  const [movingFile, setMovingFile] = useState(null);
  const [renamingFile, setRenamingFile] = useState(null);
  const [deletingFile, setDeletingFile] = useState(null);

  const isDeletingModuleFiles =
    (!!deletingFile?.module && deletingFile?.module !== "others") || selected.some((file) => file.module !== "others");

  const handleCopyLink = (file) => {
    try {
      navigator.clipboard.writeText(file.file);
      dispatch(showSuccess({ message: "File link copied" }));
    } catch (error) {
      dispatch(showError({ message: error.message ?? "Something went wrong" }));
    }
  };

  const handleDownloadFile = async (file) => {
    try {
      // const data = await fetch(file.file);
      const data = await fetch(`${file.file}?t=${Date.now().toString(36)}`);
      // const data = await fetch(file.file.replace(/^https:\/\//i, "http://"));
      if (!data.ok) throw new Error(`Download Failed status:${data.status} message:${data.statusText}`);
      const blob = await data.blob();
      const objectUrl = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.setAttribute("href", objectUrl);
      link.setAttribute("download", file.name + file.file.slice(file.file.lastIndexOf(".")));
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(objectUrl);
    } catch (error) {
      dispatch(showError({ message: error.message ?? "Something went wrong" }));
    }
  };

  return (
    <div className="my-3">
      <div className="row mb-3">
        <div className="col d-flex align-items-center">
          <h4 className="text-lightBlue text-capitalize fs-6 fw-500 me-2">All / {fileType}s</h4>
          <Tooltip
            title="Lorem ipsum"
            placement="top">
            <img
              src={info}
              alt="info"
              className="c-pointer"
              width={13.5}
            />
          </Tooltip>
        </div>
        {selected.length > 0 && (
          <div className="d-flex align-items-center px-2 mt-3">
            <button className="button-grey py-2 px-3">
              <small className="text-lightBlue">
                {selected.length} {fileType} are selected{" "}
                <span
                  className="text-blue-2 c-pointer"
                  onClick={clearSelected}>
                  (Clear Selection)
                </span>
              </small>
            </button>
            <TableMassActionButton
              headingName="Mass Action"
              defaultValue={["Move To Folder", "Delete"]}
              onSelect={(action) => {
                switch (action) {
                  case "Delete":
                    setDeletingFile({});
                    break;
                  case "Move To Folder":
                    setMovingFile({});
                    break;

                  default:
                    break;
                }
              }}
            />
          </div>
        )}
        {/* <div className="col-auto">
          <Button
            variant="text"
            className="me-2">
            <span className="text-blue-2">+ Some Action</span>
          </Button>
        </div> */}
      </div>

      {views === "icon" && (
        <div className="row align-items-center">
          {allFilesIsLoading ? (
            <span className="d-flex justify-content-center m-3">
              <TableLoader />
            </span>
          ) : allFiles.length === 0 ? (
            <span className="d-flex justify-content-center m-3">
              <NoDataFound />
            </span>
          ) : (
            allFiles.map((file) => (
              <div
                key={file._id}
                className="col-2 my-2">
                {fileType === "image" && (
                  <ImageIconView
                    file={file}
                    isSelected={selected.includes(file)}
                    onSelect={(check, file) =>
                      setSelected(check ? selected.concat(file) : selected.filter((sl) => !Object.is(sl, file)))
                    }
                    clearSelected={clearSelected}
                    onDoubleClick={onPopup}
                    onCopyLink={handleCopyLink}
                    onMoveToFolder={(file) => setMovingFile(file)}
                    onRename={(file) => setRenamingFile(file)}
                    onDownload={handleDownloadFile}
                    onDelete={(file) => setDeletingFile(file)}
                  />
                )}
                {fileType === "video" && (
                  <VideoIconView
                    file={file}
                    isSelected={selected.includes(file)}
                    onSelect={(check, file) =>
                      setSelected(check ? selected.concat(file) : selected.filter((sl) => !Object.is(sl, file)))
                    }
                    clearSelected={clearSelected}
                    onDoubleClick={onPopup}
                    onCopyLink={handleCopyLink}
                    onMoveToFolder={(file) => setMovingFile(file)}
                    onRename={(file) => setRenamingFile(file)}
                    onDownload={handleDownloadFile}
                    onDelete={(file) => setDeletingFile(file)}
                  />
                )}
              </div>
            ))
          )}
        </div>
      )}

      {views === "list" && (
        <TableContainer>
          {allFilesIsLoading ? (
            <span className="d-flex justify-content-center m-3">
              <TableLoader />
            </span>
          ) : allFiles.length === 0 ? (
            <span className="d-flex justify-content-center m-3">
              <NoDataFound />
            </span>
          ) : (
            <Table size="medium">
              <EnhancedTableHead
                numSelected={selected.length}
                onSelectAllClick={(e) => setSelected(e.target.checked ? [...allFiles] : [])}
                rowCount={allFiles.length}
                headCells={headCells}
              />
              <TableBody>
                {allFiles.map((file) => {
                  switch (fileType) {
                    case "image":
                      return (
                        <ImageListView
                          file={file}
                          isSelected={selected.includes(file)}
                          onSelect={(check, file) =>
                            setSelected(check ? selected.concat(file) : selected.filter((sl) => !Object.is(sl, file)))
                          }
                          clearSelected={clearSelected}
                          onDoubleClick={onPopup}
                          onCopyLink={handleCopyLink}
                          onMoveToFolder={(file) => setMovingFile(file)}
                          onRename={(file) => setRenamingFile(file)}
                          onDownload={handleDownloadFile}
                          onDelete={(file) => setDeletingFile(file)}
                        />
                      );
                    case "video":
                      return (
                        <VideoListView
                          file={file}
                          isSelected={selected.includes(file)}
                          onSelect={(check, file) =>
                            setSelected(check ? selected.concat(file) : selected.filter((sl) => !Object.is(sl, file)))
                          }
                          clearSelected={clearSelected}
                          onDoubleClick={onPopup}
                          onCopyLink={handleCopyLink}
                          onMoveToFolder={(file) => setMovingFile(file)}
                          onRename={(file) => setRenamingFile(file)}
                          onDownload={handleDownloadFile}
                          onDelete={(file) => setDeletingFile(file)}
                        />
                      );

                    default:
                      return null;
                  }
                })}
              </TableBody>
            </Table>
          )}
        </TableContainer>
      )}

      {allFiles.length < totalFiles && (
        <div className="d-flex justify-content-center">
          <Button
            variant="text"
            onClick={() => setPageSize((size) => size + 12)}>
            <span className="text-blue-2">Load More</span>
          </Button>
        </div>
      )}

      <NameRenameDialog
        isOpen={!!renamingFile}
        isLoading={editFileIsLoading}
        headingText="Rename File"
        labelText="File Name"
        folderName={renamingFile?.name ?? ""}
        buttonText="Rename"
        imageSrc={fileType === "video" ? video : renamingFile?.file ?? ""}
        onClose={() => setRenamingFile(null)}
        onAction={(name = "") => {
          editFile({ id: renamingFile._id, fileData: { name } })
            .unwrap()
            .then(() => dispatch(showSuccess({ message: "File renamed successfully" })))
            .catch((e) => dispatch(showError({ message: e.message ?? "Something went wrong" })))
            .finally(() => setRenamingFile(null));
        }}
      />

      <MoveInFolderDialog
        isOpen={!!movingFile}
        isLoading={editFileIsLoading || bulkEditFilesIsLoading}
        buttonText="Move"
        headingText={`Move ${selected.length > 0 ? `${selected.length} Files` : ""} To a Folder`}
        fileImage={movingFile?.file ?? ""}
        onClose={() => setMovingFile(null)}
        onAction={(folderId = "") => {
          if (selected.length > 0) {
            bulkEditFiles({ updates: selected.map((sl) => ({ id: sl._id, folderId })) })
              .unwrap()
              .then(() => dispatch(showSuccess({ message: `${selected.length} Files Moved successfully!` })))
              .catch((e) => dispatch(showError({ message: e.message ?? "Something went wrong!" })))
              .finally(() => {
                clearSelected();
                setMovingFile(null);
              });
          } else {
            editFile({ id: movingFile._id, fileData: { folderId } })
              .unwrap()
              .then(() => dispatch(showSuccess({ message: "File Moved successfully" })))
              .catch((e) => dispatch(showError({ message: e.message ?? "Something went wrong" })))
              .finally(() => {
                clearSelected();
                setMovingFile(null);
              });
          }
        }}
      />

      <DeleteAlertDialog
        show={!!deletingFile}
        isLoading={deleteFileIsLoading || bulkDeleteFilesIsLoading}
        title="Delete File"
        confirmText="Delete"
        message={
          <>
            Do you {`${isDeletingModuleFiles ? "still " : ""}`}want to delete{" "}
            <span className="text-blue-2">{`${
              selected.length > 0 ? `${selected.length} files` : deletingFile?.name ?? ""
            }`}</span>{" "}
            permanently?
          </>
        }
        moduleMessage={
          isDeletingModuleFiles ? (
            selected.length > 0 ? (
              <>
                There are{" "}
                <span className="text-blue-2">{`${selected.reduce(
                  (count, file) => count + (file.module !== "others"),
                  0
                )}`}</span>{" "}
                files linked to various modules. Deleting these Files will also remove them from their respective modules.
              </>
            ) : (
              <>
                This File is linked to <span className="text-blue-2">{`${deletingFile?.module}`}</span> module. Deleting this
                File will also remove it from the <span className="text-blue-2">{`${deletingFile?.module}`}</span> module.
              </>
            )
          ) : (
            ""
          )
        }
        onCancel={() => setDeletingFile(null)}
        onConfirm={() => {
          if (selected.length > 0) {
            bulkDeleteFiles({ deletes: selected.map((sl) => sl._id) })
              .unwrap()
              .then(() => {
                dispatch(apiSlice.util.resetApiState());
                dispatch(showSuccess({ message: `${selected.length} Files Deleted successfully!` }));
              })
              .catch((e) => dispatch(showError({ message: e.message ?? "Something went wrong!" })))
              .finally(() => {
                clearSelected();
                setDeletingFile(null);
              });
          } else {
            deleteFile(deletingFile._id)
              .unwrap()
              .then(() => {
                dispatch(apiSlice.util.resetApiState());
                dispatch(showSuccess({ message: "File Deleted successfully" }));
              })
              .catch((e) => dispatch(showError({ message: e.message ?? "Something went wrong" })))
              .finally(() => {
                clearSelected();
                setDeletingFile(null);
              });
          }
        }}
      />
    </div>
  );
}
