import React, { useState } from "react";
// ! COMPONENT IMPORTS
import {
  EnhancedTableHeadNoCheckbox,
  stableSortNoCheckbox,
  getComparatorNoCheckbox,
} from "../../../../../components/TableDependenciesNoCheckbox/TableDependenciesNoCheckbox";
// ! MATERIAL IMPORTS
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from "@mui/material";
import { useGetAllCustomerTimelineQuery } from "../../../../../features/customers/customer/customerTimelineApiSlice";
import moment from "moment/moment";
import NoDataFound from "../../../../../components/NoDataFound/NoDataFound";

// ? TABLE STARTS HERE
function createData(aId, date, emailId, activity) {
  return { aId, date, emailId, activity };
}

const rows = [
  createData(
    1,
    "Today at 09:23am",
    "saniya@mydesignar.com",
    "Purchased #1234 Item from the online store"
  ),
  createData(
    2,
    "Today at 09:23am",
    "saniya@mydesignar.com",
    "Purchased #1234 Item from the online store"
  ),
  createData(
    3,
    "Today at 09:23am",
    "saniya@mydesignar.com",
    "Purchased #1234 Item from the online store"
  ),
];

const headCells = [
  {
    id: "date",
    numeric: false,
    disablePadding: false,
    label: "Date",
  },
  {
    id: "activity",
    numeric: false,
    disablePadding: false,
    label: "Activity",
  },
  {
    id: "detail",
    numeric: false,
    disablePadding: false,
    label: "Detail",
  },
];

// ? TABLE ENDS HERE

const UserActivityTable = ({ customerId = "", type = "" }) => {
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(5);

  const { data: timelineData } = useGetAllCustomerTimelineQuery(
    { pageNo, pageSize, customer: customerId, type },
    {
      skip: !customerId,
    }
  );
  const timelines = timelineData?.data?.data ?? [];
  const totalCount = timelineData?.data?.totalCount ?? 0;

  return (
    <React.Fragment>
      <TableContainer>
        {timelines.length > 0 ? (
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size="medium">
            <EnhancedTableHeadNoCheckbox
              numSelected={0}
              order={"asc"}
              orderBy={"date"}
              // onSelectAllClick={Date}
              onRequestSort={Date}
              rowCount={totalCount}
              headCells={headCells}
            />
            <TableBody>
              {timelines.map((row) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.aId}
                    className="table-rows">
                    <TableCell
                      component="th"
                      scope="row">
                      <div className="d-flex align-items-center">
                        <p className="text-lightBlue">
                          {moment(row.createdAt).format("DD/MM/YYYY [at] hh:mm a")}
                        </p>
                      </div>
                    </TableCell>
                    <TableCell>
                      <p className="text-lightBlue">{row.activity}</p>
                    </TableCell>
                    <TableCell>
                      <p className="text-lightBlue">{row.notes}</p>
                      {row.notifyUser && (
                        <small className="text-lightBlue mt-2 text-green-2">
                          <i className=" text-green-2">Customer Notified</i>
                        </small>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        ) : (
          <NoDataFound />
        )}
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={totalCount}
        rowsPerPage={pageSize}
        page={pageNo - 1}
        onPageChange={(_, n) => setPageNo(n + 1)}
        onRowsPerPageChange={(e) => setPageSize(e.target.value)}
        className="table-pagination"
      />
    </React.Fragment>
  );
};

export default UserActivityTable;
