import React, { forwardRef, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useCreateCustomerMutation } from "../../../features/customers/customer/customerApiSlice";
import { useGetAllCustomerGroupQuery } from "../../../features/customers/customerGroup/customerGroupApiSlice";
import { useFormik } from "formik";
import { omitEmptyKeys } from "../../../utils/helper";
import { showError, showSuccess } from "../../../features/snackbar/snackbarAction";
import {
  Autocomplete,
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Slide,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import * as Yup from "yup";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CancelIcon from "@mui/icons-material/Cancel";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import userIcon from "../../../assets/icons/userIcon.svg";
import cancelIcon from "../../../assets/icons/cancel.svg";
import indiaFlagIcon from "../../../assets/images/products/indiaFlag.svg";
import locationGradientIcon from "../../../assets/icons/locationGradient.svg";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import AppMobileCodeSelect from "../../../components/AppMobileCodeSelect/AppMobileCodeSelect";
import AppCountrySelect from "../../../components/AppCountrySelect/AppCountrySelect";
import AppCitySelect from "../../../components/AddCitySelect/AddCitySelect";
import AppStateSelect from "../../../components/AppStateSelect/AppStateSelect";
import ZipCodeComponent from "../../../components/ZipCodeCityComponent";

const Transition = forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="up"
      ref={ref}
      {...props}
    />
  );
});

const formik1ValidationSchema = Yup.object({
  firstName: Yup.string().trim().min(3, "Must be 3 characters").required("Required"),
  lastName: Yup.string().trim().min(3, "Must be 3 characters"),
  // dob: Yup.date()
  //   .typeError("Please enter valid Date")
  //   .max(new Date(), "Please enter correct Date"),
  gender: Yup.string(),
  // user_group: Yup.array().of(Yup.string()),
  email: Yup.string().email("Please enter valid email").required("Required"),
  phone: Yup.string()
    .matches(/^\d{10}$/, "Must be 10 digits")
    .required("Required"),
  country_code: Yup.string().required("Required"),
});

const formik2ValidationSchema = Yup.object({
  shipping_firstName: Yup.string().min(3, "Must be 3 characters").required("Required"),
  shipping_lastName: Yup.string(),
  shipping_companyName: Yup.string(),
  shipping_phone: Yup.string()
    .matches(/^\d{10}$/, "Must be 10 digits")
    .required("Required"),
  shipping_country_code: Yup.string().required("Required"),
  shipping_country: Yup.string().required("Required"),
  shipping_line1: Yup.string().required("Required"),
  shipping_line2: Yup.string(),
  shipping_city: Yup.string().required("Required"),
  shipping_pinCode: Yup.string()
    .matches(/^\d{6}$/, "Must be 6 digits")
    .required("Required"),
  shipping_state: Yup.string().required("Required"),
});

const formik3ValidationSchema = Yup.object({
  firstName: Yup.string().trim().min(3, "Must be 3 characters").required("Required"),
  lastName: Yup.string().trim().min(3, "Must be 3 characters"),
  // dob: Yup.date()
  //   .typeError("Please enter valid Date")
  //   .max(new Date(), "Please enter correct Date"),
  gender: Yup.string(),
  // user_group: Yup.array().of(Yup.string()),
  email: Yup.string().email("Please enter valid email").required("Required"),
  phone: Yup.string()
    .matches(/^\d{10}$/, "Must be 10 digits")
    .required("Required"),
  country_code: Yup.string().required("Required"),
  shipping_firstName: Yup.string().min(3, "Must be 3 characters").required("Required"),
  shipping_lastName: Yup.string(),
  shipping_companyName: Yup.string(),
  shipping_phone: Yup.string()
    .matches(/^\d{10}$/, "Must be 10 digits")
    .required("Required"),
  shipping_country_code: Yup.string().required("Required"),
  shipping_country: Yup.string().required("Required"),
  shipping_line1: Yup.string().required("Required"),
  shipping_line2: Yup.string(),
  shipping_city: Yup.string().required("Required"),
  shipping_pinCode: Yup.string()
    .matches(/^\d{6}$/, "Must be 6 digits")
    .required("Required"),
  shipping_state: Yup.string().required("Required"),
  billing_firstName: Yup.string().min(3, "Must be 3 characters").required("Required"),
  billing_lastName: Yup.string(),
  billing_companyName: Yup.string(),
  billing_phone: Yup.string()
    .matches(/^\d{10}$/, "Must be 10 digits")
    .required("Required"),
  billing_country_code: Yup.string().required("Required"),
  billing_country: Yup.string().required("Required"),
  billing_line1: Yup.string().required("Required"),
  billing_line2: Yup.string(),
  billing_city: Yup.string().required("Required"),
  billing_pinCode: Yup.string()
    .matches(/^\d{6}$/, "Must be 6 digits")
    .required("Required"),
  billing_state: Yup.string().required("Required"),
  billing_gstin: Yup.string(),

  // address: Yup.array().of(
  //   Yup.object().shape({
  //     country: Yup.string().required("Required"),
  //     city: Yup.string().required("Required"),
  //     name: Yup.string(),
  //     firstName: Yup.string(),
  //     lastName: Yup.string(),
  //     companyName: Yup.string(),
  //     countryCode: Yup.string().required("Required"),
  //     phone: Yup.string()
  //       .matches(/^\d{10}$/, "Must be 10 digits")
  //       .required("Required"),
  //     line1: Yup.string().required("Required"),
  //     line2: Yup.string(),
  //     state: Yup.string().required("Required"),
  //     isDefaultAddress: Yup.boolean(),
  //     type: Yup.string().required("Required"),
  //     pinCode: Yup.string()
  //       .matches(/^\d{6}$/, "Must be 6 digits")
  //       .required("Required"),
  //   })
  // ),
});

export default function NewUserDialog({
  show = false,
  onClose = () => {},
  setSelectedUser = () => {},
}) {
  const dispatch = useDispatch();
  const [tabIndex, setTabIndex] = useState(0);
  const [createCustomer] = useCreateCustomerMutation();

  const formik1 = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      gender: "",
      email: "",
      country_code: "",
      phone: "",
      isSendMessage: false,
      isSendEmail: false,
    },
    validationSchema: formik1ValidationSchema,
    onSubmit: (val) => {
      formik3.setValues({ ...formik3.values, ...val });
      setTabIndex((ti) => ti + 1);
    },
  });

  const formik2 = useFormik({
    initialValues: {
      shipping_firstName: "",
      shipping_lastName: "",
      shipping_email: "",
      shipping_phone: "",
      shipping_line1: "",
      shipping_line2: "",
      shipping_companyName: "",
      shipping_city: "",
      shipping_state: "",
      shipping_country: "",
      shipping_country_code: "",
      shipping_pinCode: "",
    },
    validationSchema: formik2ValidationSchema,
    onSubmit: (val) => {
      formik3.setValues({ ...formik3.values, ...val });
      setTabIndex((ti) => ti + 1);
    },
  });

  const formik3 = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      gender: "",
      email: "",
      country_code: "",
      phone: "",
      isSendMessage: false,
      isSendEmail: false,
      shipping_firstName: "",
      same_address: false,
      shipping_lastName: "",
      shipping_email: "",
      shipping_phone: "",
      shipping_line1: "",
      shipping_line2: "",
      shipping_companyName: "",
      shipping_city: "",
      shipping_state: "",
      shipping_country: "",
      shipping_country_code: "",
      shipping_pinCode: "",
      billing_firstName: "",
      billing_lastName: "",
      billing_email: "",
      billing_phone: "",
      billing_line1: "",
      billing_line2: "",
      billing_companyName: "",
      billing_city: "",
      billing_state: "",
      billing_country: "",
      billing_country_code: "",
      billing_pinCode: "",
      billing_gstin: "",
    },
    validationSchema: formik3ValidationSchema,
    onSubmit: async (val, helpers) => {
      try {
        const masterValues = {
          firstName: val.firstName || "",
          lastName: val.lastName || "",
          status: "active",
          gender: val.gender || "",
          countryCode: val.billing_country_code || "",
          phone: val.phone || "",
          email: val.email || "",
          password: val.password || "",
          isSendEmail: val.isSendEmail || false,
          isSendMessage: val.isSendMessage || false,
          isTemporaryPassword: val.isTemporaryPassword || false,
          notes: val.notes || "",
          imageUrl: val.imageUrl || "",
          tags: val.tags || "",
          address: [
            omitEmptyKeys({
              country: val.billing_country,
              city: val.billing_city,
              firstName: val.billing_firstName || "",
              lastName: val.billing_lastName || "",
              companyName: val.billing_companyName || "",
              countryCode: val.billing_country_code || "",
              name: (val.billing_firstName || "") + (val.billing_lastName || ""),
              phone: val.billing_phone || "",
              line1: val.billing_line1 || "",
              line2: val.billing_line2 || "",
              pinCode: val.billing_pinCode || "",
              state: val.billing_state || "",
              isDefaultAddress: val.billing_isDefaultAddress || true,
              type: "billing",
            }),
            omitEmptyKeys({
              country: val.shipping_country,
              type: "shipping",
              name: (val.shipping_firstName || "") + (val.shipping_lastName || ""),
              city: val.shipping_city,
              firstName: val.shipping_firstName || "",
              lastName: val.shipping_lastName || "",
              companyName: val.shipping_companyName || "",
              countryCode: val.shipping_country_code || "",
              phone: val.shipping_phone || "",
              line1: val.shipping_line1 || "",
              line2: val.shipping_line2 || "",
              pinCode: val.shipping_pinCode || "",
              state: val.shipping_state || "",
              isDefaultAddress: val.shipping_isDefaultAddress || true,
            }),
          ],
        };
        const data = await createCustomer(omitEmptyKeys(masterValues)).unwrap();
        dispatch(
          showSuccess({
            message: `${val.firstName + " " + val.lastName}
          created successfully`,
          })
        );
        helpers.resetForm();
        if (setSelectedUser) setSelectedUser(data?.data?._id);
      } catch (error) {
        dispatch(showError({ message: error?.data?.message || "Something went wrong" }));
      }
    },
  });

  // const currentDate = new Date();

  const handleSetSame = (type = "billing", checked = false) => {
    formik3.setFieldValue("same_address", checked);
    if (checked) {
      if (type === "billing") {
        formik3.setValues((prev) => ({
          ...prev,
          billing_firstName: formik3.values.shipping_firstName,
          billing_lastName: formik3.values.shipping_lastName,
          billing_email: formik3.values.shipping_email,
          billing_phone: formik3.values.shipping_phone,
          billing_line1: formik3.values.shipping_line1,
          billing_line2: formik3.values.shipping_line2,
          billing_companyName: formik3.values.shipping_companyName,
          billing_city: formik3.values.shipping_city,
          billing_state: formik3.values.shipping_state,
          billing_country: formik3.values.shipping_country,
          billing_country_code: formik3.values.shipping_country_code,
          billing_pinCode: formik3.values.shipping_pinCode,
        }));
      } else {
        formik3.setValues((prev) => ({
          ...prev,
          shipping_firstName: formik3.values.billing_firstName,
          shipping_lastName: formik3.values.billing_lastName,
          shipping_email: formik3.values.billing_email,
          shipping_phone: formik3.values.billing_phone,
          shipping_line1: formik3.values.billing_line1,
          shipping_line2: formik3.values.billing_line2,
          shipping_companyName: formik3.values.billing_companyName,
          shipping_city: formik3.values.billing_city,
          shipping_state: formik3.values.billing_state,
          shipping_country: formik3.values.billing_country,
          shipping_country_code: formik3.values.billing_country_code,
          shipping_pinCode: formik3.values.billing_pinCode,
        }));
      }
    }
  };

  // const maxDate = new Date(
  //   currentDate.getFullYear() - 18,
  //   currentDate.getMonth(),
  //   currentDate.getDate()
  // );
  // const removeGroup = (groupIdToRemove) => {
  //   const updatedGroups = formik.values.user_group.filter(
  //     (groupId) => groupId !== groupIdToRemove
  //   );
  //   formik.setFieldValue("user_group", updatedGroups);
  // };

  // const selectedGroups = customerGroupData?.data?.data?.filter((group) =>
  //   formik.values.user_group?.includes(group._id)
  // );

  // const handleGroupChange = (_, group) => {
  //   const groupIds = group?.map((group) => group?._id);
  //   formik.setFieldValue("user_group", groupIds);
  // };

  return (
    <Dialog
      open={show}
      keepMounted
      TransitionComponent={Transition}
      onClose={() => onClose(false)}
      maxWidth="md"
      fullWidth>
      <DialogTitle>
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex flex-column ">
            <h5 className="text-lightBlue fw-500">Create New User</h5>

            <small className="text-grey-6 mt-1 d-block">
              ⓘ Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </small>
          </div>
          <img
            src={cancelIcon}
            alt="cancel"
            width={30}
            onClick={() => onClose(false)}
            className="c-pointer"
          />
        </div>

        <Box
          sx={{ width: "100%" }}
          className="d-flex justify-content-between tabs-header-box mt-3">
          <Tabs
            className="tabs"
            value={tabIndex}
            onChange={(_, index) => setTabIndex(index)}>
            <Tab
              label="Basic Info"
              className="tabs-head"
            />
            <Tab
              label="Shipping Address"
              className="tabs-head"
            />
            <Tab
              label="Billing Address"
              className="tabs-head"
            />
          </Tabs>
        </Box>
      </DialogTitle>
      <DialogContent className="pb-4 px-4">
        {/* <TabPanel
          index={0}
          value={tabIndex}
          className="px-0 nav-tab-panel"> */}
        {tabIndex === 0 && (
          <div className="col-12 px-0">
            <div className="row align-items-start">
              <div className="col-md-4 mt-3">
                <p className="text-lightBlue mb-1">First Name</p>
                <FormControl className="w-100 px-0">
                  <OutlinedInput
                    size="small"
                    placeholder="Enter First Name"
                    value={formik1.values.firstName}
                    onChange={(e) => formik1.setFieldValue("firstName", e.target.value)}
                  />
                  <FormHelperText error>
                    {!!formik1.touched.firstName && formik1.errors.firstName}
                  </FormHelperText>
                </FormControl>
              </div>
              <div className="col-md-4 mt-3">
                <p className="text-lightBlue mb-1">Last Name</p>
                <FormControl className="w-100 px-0">
                  <OutlinedInput
                    size="small"
                    placeholder="Enter Last Name"
                    value={formik1.values.lastName}
                    onChange={(e) => formik1.setFieldValue("lastName", e.target.value)}
                  />
                  <FormHelperText error>
                    {!!formik1.touched.lastName && formik1.errors.lastName}
                  </FormHelperText>
                </FormControl>
              </div>
              <div className="col-md-4 mt-3">
                <p className="text-lightBlue mb-1">Gender</p>
                <FormControl
                  size="small"
                  sx={{ m: 0, minWidth: 120, width: "100%" }}>
                  <Select
                    size="small"
                    onChange={(e) => formik1.setFieldValue("gender", e.target.value)}
                    value={formik1.values.gender}>
                    <MenuItem
                      value=""
                      sx={{ fontSize: 13, color: "#5c6d8e" }}>
                      None
                    </MenuItem>
                    <MenuItem
                      value="male"
                      sx={{ fontSize: 13, color: "#5c6d8e" }}>
                      Male
                    </MenuItem>
                    <MenuItem
                      value="female"
                      sx={{ fontSize: 13, color: "#5c6d8e" }}>
                      Female
                    </MenuItem>
                  </Select>
                  <FormHelperText error>
                    {!!formik1.touched.gender && formik1.errors.gender}
                  </FormHelperText>
                </FormControl>
              </div>
              {/* <div className="col-md-6 mt-3">
                <p className="text-lightBlue mb-1">
                  Date of Birth <span style={{ color: "#F67476" }}>*</span>
                </p>
                <FormControl className="w-100 px-0">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      name="dob"
                      value={formik1.values.dob}
                      onChange={(e) => formik1.setFieldValue("dob", e)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                        />
                      )}
                      maxDate={maxDate}
                    />
                  </LocalizationProvider>
                  <FormHelperText error>
                    {!!formik1.touched.dob && formik1.errors.dob}
                  </FormHelperText>
                </FormControl>
              </div> */}
              {/* <div className="col-md-12 mt-3">
                <p className="text-lightBlue mb-1">User Group</p>
                <FormControl
                  size="small"
                  sx={{ m: 0, minWidth: 120, width: "100%" }}>
                  <Autocomplete
                    multiple
                    id="checkboxes-tags-demo"
                    sx={{ width: "100%" }}
                    options={customerGroupData?.data?.data || []}
                    value={selectedGroups || []}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option?.name}
                    size="small"
                    onChange={handleGroupChange}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                          checkedIcon={<CheckBoxIcon fontSize="small" />}
                          checked={selected}
                          size="small"
                          style={{
                            color: "#5C6D8E",
                            marginRight: 0,
                          }}
                        />
                        <small className="text-lightBlue">{option.name}</small>
                      </li>
                    )}
                    renderTags={(value) =>
                      value.map((option) => (
                        <div
                          key={option?._id}
                          className={`rounded-pill d-flex align-items-center c-pointer mr-1 mt-1 px-1`}
                          style={{
                            background:
                              "linear-gradient(303.01deg, #2f2e69 -4.4%, #514969 111.29%)",
                          }}>
                          <small className="fw-400 text-lightBlue px-1">{option?.name}</small>
                          <button
                            type="button"
                            className="reset">
                            <CancelIcon
                              src={cancelIcon}
                              alt="cancel"
                              fontSize="15px"
                              className="c-pointer text-lightBlue py-1"
                              onClick={() => removeGroup(option?._id)}
                            />
                          </button>
                        </div>
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        {...params}
                        placeholder="Search"
                      />
                    )}
                  />
                  <FormHelperText error>
                    {!!formik1.touched.user_group && formik1.errors.user_group}
                  </FormHelperText>
                </FormControl>
              </div> */}
              <div className="col-md-12 mt-3">
                <p className="text-lightBlue mb-1">Email ID</p>
                <FormControl className="w-100 px-0">
                  <OutlinedInput
                    size="small"
                    placeholder="Enter Email ID"
                    value={formik1.values.email}
                    onChange={(e) => formik1.setFieldValue("email", e.target.value)}
                  />
                  <FormHelperText error>
                    {!!formik1.touched.email && formik1.errors.email}
                  </FormHelperText>
                </FormControl>
              </div>
              <div className="col-md-12">
                <FormControlLabel
                  label="User agreed to receive marketing emails."
                  sx={{
                    "& .MuiTypography-root": {
                      fontSize: 13,
                      color: "#5c6d8e",
                    },
                  }}
                  control={
                    <Checkbox
                      size="small"
                      style={{ color: "#5C6D8E" }}
                      checked={formik1.values.isSendEmail}
                      onChange={(e, checked) => formik1.setFieldValue("isSendEmail", checked)}
                    />
                  }
                />
              </div>

              <div className="col-md-12 mt-3">
                <p className="text-lightBlue mb-1">Mobile Number</p>
                <FormControl className="w-100 px-0">
                  <OutlinedInput
                    placeholder="Enter Mobile Number"
                    value={formik1.values.phone}
                    onChange={(e) => formik1.setFieldValue("phone", e.target.value)}
                    size="small"
                    sx={{ paddingLeft: 0 }}
                    startAdornment={
                      <InputAdornment position="start">
                        <AppMobileCodeSelect
                          countryCode={formik1.values.country_code}
                          handleCode={(e, v) => formik1.setFieldValue("country_code", v?._id)}
                        />
                      </InputAdornment>
                    }
                  />
                  <FormHelperText error>
                    {(!!formik1.touched.phone || !!formik1.touched.country_code) &&
                      (formik1.errors.phone ?? formik1.errors.country_code)}
                  </FormHelperText>
                </FormControl>
              </div>
              <div className="col-md-12">
                <FormControlLabel
                  label="User agreed to receive SMS marketing text messages."
                  sx={{
                    "& .MuiTypography-root": {
                      fontSize: 13,
                      color: "#5c6d8e",
                    },
                  }}
                  control={
                    <Checkbox
                      inputProps={{ "aria-label": "controlled" }}
                      size="small"
                      checked={formik1.values.isSendMessage}
                      onChange={(e, checked) => formik1.setFieldValue("isSendMessage", checked)}
                      style={{
                        color: "#5C6D8E",
                      }}
                    />
                  }
                />
              </div>
            </div>
          </div>
        )}
        {tabIndex === 1 && (
          <div className="col-12 px-0">
            <div className="row">
              <div className="col-md-6 mt-3">
                <p className="text-lightBlue mb-1">First Name</p>
                <FormControl className="w-100 px-0">
                  <OutlinedInput
                    size="small"
                    placeholder="Enter First Name"
                    value={formik2.values.shipping_firstName}
                    onChange={(e) =>
                      formik2.setFieldValue("shipping_firstName", e.target.value)
                    }
                  />
                  <FormHelperText error>
                    {!!formik2.touched.shipping_firstName && formik2.errors.shipping_firstName}
                  </FormHelperText>
                </FormControl>
              </div>
              <div className="col-md-6 mt-3">
                <p className="text-lightBlue mb-1">Last Name</p>
                <FormControl className="w-100 px-0">
                  <OutlinedInput
                    size="small"
                    placeholder="Enter Last Name"
                    value={formik2.values.shipping_lastName}
                    onChange={(e) => formik2.setFieldValue("shipping_lastName", e.target.value)}
                  />
                  <FormHelperText error>
                    {!!formik2.touched.shipping_lastName && formik2.errors.shipping_lastName}
                  </FormHelperText>
                </FormControl>
              </div>
              <div className="col-md-12 mt-3">
                <p className="text-lightBlue mb-1">Company Name</p>
                <FormControl className="w-100 px-0">
                  <OutlinedInput
                    size="small"
                    placeholder="Enter Company Name"
                    value={formik2.values.shipping_companyName}
                    onChange={(e) =>
                      formik2.setFieldValue("shipping_companyName", e.target.value)
                    }
                  />
                  <FormHelperText error>
                    {!!formik2.touched.shipping_companyName &&
                      formik2.errors.shipping_companyName}
                  </FormHelperText>
                </FormControl>
              </div>
              <div className="col-md-12 mt-3">
                <p className="text-lightBlue mb-1">Mobile Number</p>
                <FormControl className="w-100 px-0">
                  <OutlinedInput
                    placeholder="Enter Mobile Number"
                    size="small"
                    value={formik2.values.shipping_phone}
                    onChange={(e) => formik2.setFieldValue("shipping_phone", e.target.value)}
                    sx={{ paddingLeft: 0 }}
                    startAdornment={
                      <InputAdornment position="start">
                        <AppMobileCodeSelect
                          countryCode={formik2.values.shipping_country_code}
                          handleCode={(e, v) =>
                            formik2.setFieldValue("shipping_country_code", v?._id)
                          }
                        />
                      </InputAdornment>
                    }
                  />
                  <FormHelperText error>
                    {(!!formik2.touched.shipping_phone ||
                      !!formik2.touched.shipping_country_code) &&
                      (formik2.errors.shipping_phone ?? formik2.errors.shipping_country_code)}
                  </FormHelperText>
                </FormControl>
              </div>
              {/* <div className="col-md-12 mt-3 add-user-country">
                <p className="text-lightBlue mb-1">Country</p>
                <AppCountrySelect
                  initValue={formik2.values.shipping_country}
                  selectCountryName={(e, v) => {
                    formik2.setFieldValue("shipping_country", v?._id);
                  }}
                />
                <FormHelperText error>
                  {!!formik2.touched.shipping_country && formik2.errors.shipping_country}
                </FormHelperText>
              </div>
              <div className="col-md-6 mt-3">
                <p className="text-lightBlue mb-1">Address Line 1</p>
                <FormControl className="w-100 px-0">
                  <OutlinedInput
                    size="small"
                    placeholder="Enter Address Line 1"
                    value={formik2.values.shipping_line1}
                    onChange={(e) => formik2.setFieldValue("shipping_line1", e.target.value)}
                  />
                  <FormHelperText error>
                    {!!formik2.touched.shipping_line1 && formik2.errors.shipping_line1}
                  </FormHelperText>
                </FormControl>
              </div>
              <div className="col-md-6 mt-3">
                <p className="text-lightBlue mb-1">Address Line 2</p>
                <FormControl className="w-100 px-0">
                  <OutlinedInput
                    size="small"
                    placeholder="Enter Address Line 2"
                    value={formik2.values.shipping_line2}
                    onChange={(e) => formik2.setFieldValue("shipping_line2", e.target.value)}
                  />
                  <FormHelperText error>
                    {!!formik2.touched.shipping_line2 && formik2.errors.shipping_line2}
                  </FormHelperText>
                </FormControl>
              </div>
              <div className="col-md-6 mt-3">
                <p className="text-lightBlue mb-1">Town / City</p>
                <AppCitySelect
                  initVal={formik2.values.shipping_city}
                  selectCityName={(e, v) => formik2.setFieldValue("shipping_city", v?._id)}
                />
                <FormHelperText error>
                  {!!formik2.touched.shipping_city && formik2.errors.shipping_city}
                </FormHelperText>
              </div>
              <div className="col-md-6 mt-3">
                <p className="text-lightBlue mb-1">Zipcode / Postalcode</p>
                <FormControl className="w-100 px-0">
                  <OutlinedInput
                    size="small"
                    placeholder="Enter Zipcode / Postalcode"
                    value={formik2.values.shipping_pinCode}
                    onChange={(e) => formik2.setFieldValue("shipping_pinCode", e.target.value)}
                  />
                  <FormHelperText error>
                    {!!formik2.touched.shipping_pinCode && formik2.errors.shipping_pinCode}
                  </FormHelperText>
                </FormControl>
              </div>
              <div className="col-md-12 mt-3  add-user-country">
                <div className="d-flex align-items-center justify-content-between">
                  <p className="text-lightBlue mb-1">State or Region</p>
                  <small className="text-grey-6 mb-1">(Optional)</small>
                </div>
                <AppStateSelect
                  initValue={formik2.values.shipping_state}
                  getStateName={(e, v) => formik2.setFieldValue("shipping_state", v?._id)}
                />
                <FormHelperText error>
                  {!!formik2.touched.shipping_state && formik2.errors.shipping_state}
                </FormHelperText>
              </div> */}
              <ZipCodeComponent
                cityVal={formik2.values.shipping_city}
                onchangeCity={(e, v) => formik2.setFieldValue("shipping_city", v?._id)}
                errorCity={formik2.errors.shipping_city}
                touchedCity={formik2.touched.shipping_city}
                stateVal={formik2.values.shipping_state}
                onchangeState={(e, v) => formik2.setFieldValue("shipping_state", v?._id)}
                errorState={formik2.errors.shipping_state}
                touchedState={formik2.touched.shipping_state}
                countryVal={formik2.values.shipping_country}
                onchangeCountry={(e, v) => formik2.setFieldValue("shipping_country", v?._id)}
                errorCountry={formik2.errors.shipping_country}
                touchedCountry={formik2.touched.shipping_country}
                zipVal={formik2.values.shipping_pinCode}
                onchangeZip={formik2.handleChange}
                errorZip={formik2.errors.shipping_pinCode}
                touchedZip={formik2.touched.shipping_pinCode}
                onchangeLine1={(line) => formik2.setFieldValue("shipping_line1", line)}
                onchangeLine2={(line) =>
                  formik2.setFieldValue("shipping_line2", line.target.value)
                }
                touchedLine1={formik2.touched.shipping_line1}
                errorLine1={formik2.errors.shipping_line1}
                nameLine2="shipping_line2"
                namePincode="shipping_pinCode"
                valueLine2={formik2.values.shipping_line2}
                onchangePincode={(code) => formik2.setFieldValue("shipping_pinCode", code)}
                line1Val={formik2.values.shipping_line1}
              />
              {/* <div className="col-12 mt-3">
                <FormControlLabel
                  label="Same as Billing Address"
                  sx={{
                    "& .MuiTypography-root": {
                      fontSize: 13,
                      color: "#c8d8ff",
                    },
                  }}
                  control={
                    <Checkbox
                      inputProps={{ "aria-label": "controlled" }}
                      size="small"
                      checked={formik2.values.same_address}
                      onChange={
                        (e, v) => handleSetSame("shipping", v)
                        // formik2.setFieldValue("same_address", v)
                      }
                      style={{
                        color: "#5C6D8E",
                      }}
                    />
                  }
                />
              </div> */}
            </div>
          </div>
        )}
        {tabIndex === 2 && (
          <div className="col-12 px-0">
            <div className="row ">
              <div className="col-12">
                <FormControlLabel
                  label="Same as Shipping Address"
                  sx={{
                    "& .MuiTypography-root": {
                      fontSize: 13,
                      color: "#c8d8ff",
                    },
                  }}
                  control={
                    <Checkbox
                      checked={formik3.values.same_address}
                      onChange={(e, v) => {
                        // console.log(e, v);
                        if (v) handleSetSame("billing", v);
                        formik3.setFieldValue("same_address", v);
                      }}
                      inputProps={{ "aria-label": "controlled" }}
                      size="small"
                      style={{
                        color: "#5C6D8E",
                      }}
                    />
                  }
                />
              </div>
              <div className="col-md-6 mt-3">
                <p className="text-lightBlue mb-1">First Name</p>
                <FormControl className="w-100 px-0">
                  <OutlinedInput
                    size="small"
                    placeholder="Enter First Name"
                    value={formik3.values.billing_firstName}
                    onChange={(e) => formik3.setFieldValue("billing_firstName", e.target.value)}
                  />
                  <FormHelperText error>
                    {!!formik3.touched.billing_firstName && formik3.errors.billing_firstName}
                  </FormHelperText>
                </FormControl>
              </div>
              <div className="col-md-6 mt-3">
                <p className="text-lightBlue mb-1">Last Name</p>
                <FormControl className="w-100 px-0">
                  <OutlinedInput
                    size="small"
                    placeholder="Enter Last Name"
                    value={formik3.values.billing_lastName}
                    onChange={(e) => formik3.setFieldValue("billing_lastName", e.target.value)}
                  />
                  <FormHelperText error>
                    {!!formik3.touched.billing_lastName && formik3.errors.billing_lastName}
                  </FormHelperText>
                </FormControl>
              </div>
              <div className="col-md-12 mt-3">
                <p className="text-lightBlue mb-1">Company Name</p>
                <FormControl className="w-100 px-0">
                  <OutlinedInput
                    size="small"
                    placeholder="Enter Company Name"
                    value={formik3.values.billing_companyName}
                    onChange={(e) =>
                      formik3.setFieldValue("billing_companyName", e.target.value)
                    }
                  />
                  <FormHelperText error>
                    {!!formik3.touched.billing_companyName &&
                      formik3.errors.billing_companyName}
                  </FormHelperText>
                </FormControl>
              </div>
              <div className="col-md-12 mt-3">
                <p className="text-lightBlue mb-1">Mobile Number</p>
                <FormControl className="w-100 px-0">
                  <OutlinedInput
                    placeholder="Enter Mobile Number"
                    size="small"
                    value={formik3.values.billing_phone}
                    onChange={(e) => formik3.setFieldValue("billing_phone", e.target.value)}
                    sx={{ paddingLeft: 0 }}
                    startAdornment={
                      <InputAdornment position="start">
                        <AppMobileCodeSelect
                          countryCode={formik3.values.billing_country_code}
                          handleCode={(e, v) =>
                            formik3.setFieldValue("billing_country_code", v?._id)
                          }
                        />
                      </InputAdornment>
                    }
                  />
                  <FormHelperText error>
                    {(!!formik3.touched.billing_phone ||
                      !!formik3.touched.billing_country_code) &&
                      (formik3.errors.billing_phone ?? formik3.errors.billing_country_code)}
                  </FormHelperText>
                </FormControl>
              </div>

              {/* <div className="col-md-12 mt-3 add-user-country">
                <p className="text-lightBlue mb-1">Country</p>
                <AppCountrySelect
                  initValue={formik3.values.billing_country}
                  selectCountryName={(e, v) => {
                    formik3.setFieldValue("billing_country", v?._id);
                  }}
                />
                <FormHelperText error>
                  {!!formik3.touched.billing_country && formik3.errors.billing_country}
                </FormHelperText>
              </div>
              <div className="col-md-6 mt-3">
                <p className="text-lightBlue mb-1">Address Line 1</p>
                <FormControl className="w-100 px-0">
                  <OutlinedInput
                    size="small"
                    placeholder="Enter Address Line 1"
                    value={formik3.values.billing_line1}
                    onChange={(e) => formik3.setFieldValue("billing_line1", e.target.value)}
                  />
                  <FormHelperText error>
                    {!!formik3.touched.billing_line1 && formik3.errors.billing_line1}
                  </FormHelperText>
                </FormControl>
              </div>
              <div className="col-md-6 mt-3">
                <p className="text-lightBlue mb-1">Address Line 2</p>
                <FormControl className="w-100 px-0">
                  <OutlinedInput
                    size="small"
                    placeholder="Enter Address Line 2"
                    value={formik3.values.billing_line2}
                    onChange={(e) => formik3.setFieldValue("billing_line2", e.target.value)}
                  />
                  <FormHelperText error>
                    {!!formik3.touched.billing_line2 && formik3.errors.billing_line2}
                  </FormHelperText>
                </FormControl>
              </div>
              <div className="col-md-6 mt-3">
                <p className="text-lightBlue mb-1">Town / City</p>
                <AppCitySelect
                  initVal={formik3.values.billing_city}
                  selectCityName={(e, v) => formik3.setFieldValue("billing_city", v?._id)}
                />
                <FormHelperText error>
                  {!!formik3.touched.billing_city && formik3.errors.billing_city}
                </FormHelperText>
              </div>
              <div className="col-md-6 mt-3">
                <p className="text-lightBlue mb-1">Zipcode / Postalcode</p>
                <FormControl className="w-100 px-0">
                  <OutlinedInput
                    size="small"
                    placeholder="Enter Zipcode / Postalcode"
                    value={formik3.values.billing_pinCode}
                    onChange={(e) => formik3.setFieldValue("billing_pinCode", e.target.value)}
                  />
                  <FormHelperText error>
                    {!!formik3.touched.billing_pinCode && formik3.errors.billing_pinCode}
                  </FormHelperText>
                </FormControl>
              </div>
              <div className="col-md-12 mt-3  add-user-country">
                <div className="d-flex align-items-center justify-content-between">
                  <p className="text-lightBlue mb-1">State or Region</p>
                  <small className="text-grey-6 mb-1">(Optional)</small>
                </div>
                <AppStateSelect
                  initValue={formik3.values.billing_state}
                  getStateName={(e, v) => formik3.setFieldValue("billing_state", v?._id)}
                />
                <FormHelperText error>
                  {!!formik3.touched.billing_state && formik3.errors.billing_state}
                </FormHelperText>
              </div> */}

              <ZipCodeComponent
                cityVal={formik3.values.billing_city}
                onchangeCity={(e, v) => formik3.setFieldValue("billing_city", v?._id)}
                errorCity={formik3.errors.billing_city}
                touchedCity={formik3.touched.billing_city}
                stateVal={formik3.values.billing_state}
                onchangeState={(e, v) => formik3.setFieldValue("billing_state", v?._id)}
                errorState={formik3.errors.billing_state}
                touchedState={formik3.touched.billing_state}
                countryVal={formik3.values.billing_country}
                onchangeCountry={(e, v) => formik3.setFieldValue("billing_country", v?._id)}
                errorCountry={formik3.errors.billing_country}
                touchedCountry={formik3.touched.billing_country}
                zipVal={formik3.values.billing_pinCode}
                onchangeZip={formik3.handleChange}
                errorZip={formik3.errors.billing_pinCode}
                touchedZip={formik3.touched.billing_pinCode}
                onchangeLine1={(line) => formik3.setFieldValue("billing_line1", line)}
                onchangeLine2={(line) =>
                  formik3.setFieldValue("billing_line2", line.target.value)
                }
                touchedLine1={formik3.touched.billing_line1}
                errorLine1={formik3.errors.billing_line1}
                nameLine2="billing_line2"
                namePincode="billing_pinCode"
                valueLine2={formik3.values.billing_line2}
                onchangePincode={(code) => formik3.setFieldValue("billing_pinCode", code)}
                line1Val={formik3.values.billing_line1}
              />
              <div className="col-md-12 mt-3  add-user-country">
                <div className="d-flex align-items-center justify-content-between">
                  <p className="text-lightBlue mb-1">GSTIN</p>
                  <small className="text-grey-6 mb-1">(Optional)</small>
                </div>
                <FormControl className="w-100 px-0">
                  <OutlinedInput
                    size="small"
                    placeholder="Enter GSTIN"
                    value={formik3.values.billing_gstin}
                    onChange={(e) => formik3.setFieldValue("billing_gstin", e.target.value)}
                  />
                  <FormHelperText error>
                    {!!formik3.touched.billing_gstin && formik3.errors.billing_gstin}
                  </FormHelperText>
                </FormControl>
              </div>
            </div>
          </div>
        )}
        {/* </TabPanel> */}
      </DialogContent>
      <hr className="hr-grey-6 my-0" />
      <DialogActions className="d-flex justify-content-between px-4 py-3">
        <button
          className="button-grey py-2 px-5"
          onClick={() => onClose(false)}>
          <p className="text-lightBlue">Cancel</p>
        </button>
        {tabIndex === 0 && (
          <button
            className="button-gradient py-2 px-5"
            onClick={formik1.handleSubmit}>
            <p>Next</p>
          </button>
        )}
        {tabIndex === 1 && (
          <button
            className="button-gradient py-2 px-5"
            onClick={formik2.handleSubmit}>
            <p>Next</p>
          </button>
        )}
        {tabIndex === 2 && (
          <button
            className="button-gradient py-2 px-5"
            onClick={formik3.handleSubmit}>
            <p>Create</p>
          </button>
        )}
      </DialogActions>
    </Dialog>
  );
}
