import { useState } from "react";
import { Link } from "react-router-dom";
// ! COMPONENT IMPORTS
import { EnhancedTableHead } from "../../../components/TableDependencies/TableDependencies";
// ! IMAGES IMPORTS
import ringSmall from "../../../assets/images/ringSmall.svg";
// import arrowDown from "../../../assets/icons/arrowDown.svg";
// import deleteRed from "../../../assets/icons/delete.svg";
import userIcon from "../../../assets/images/users/user_defauldp.svg";
// import indiaFlag from "../../../assets/images/products/indiaFlag.svg";
// import verticalDots from "../../../assets/icons/verticalDots.svg";
// ! MATERIAL IMPORTS
import {
  Checkbox,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Tooltip,
} from "@mui/material";
// ! MATERIAL ICON IMPORTS
// import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// import CloseIcon from "@mui/icons-material/Close";
// import ReceiptIcon from "@mui/icons-material/Receipt";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
// import PrintIcon from "@mui/icons-material/Print";
// import ContentCopyIcon from "@mui/icons-material/ContentCopy";
// import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import ClearIcon from "@mui/icons-material/Clear";
// import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
// import ChatIcon from "@mui/icons-material/Chat";
import NoDataFound from "../../../components/NoDataFound/NoDataFound";
import TableLoader from "../../../components/Loader/TableLoader";
import moment from "moment";
import AsyncImage from "../../../components/AsyncImage/AsyncImage";
import { useDispatch } from "react-redux";
import { showError, showSuccess } from "../../../features/snackbar/snackbarAction";
import { useUpdateOrderMutation } from "../../../features/orders/ordersApiSlice";
import { OrderStatuses, PaymentStatuses } from "./FilterOrders";
import CancelRefundOrderDialog from "../CancelRefundOrderDialog";
import { useSendInvoiceMutation } from "../../../features/orders/emailers/emailerApiSlice";
// import ArchiveModal from "../../../components/ArchiveModal/ArchiveModal";
import OrderArchiveModal from "../OrderArchiveModal";
import { rupeeFormatWithSymbol } from "../../../utils/format";

const headCells = (totalCount) => [
  {
    id: "orderInfo",
    numeric: false,
    disablePadding: true,
    label: `Order Info (${totalCount})`,
  },
  {
    id: "userName",
    numeric: false,
    disablePadding: false,
    label: "User",
  },
  {
    id: "items",
    numeric: false,
    disablePadding: false,
    label: "Items",
  },
  {
    id: "total",
    numeric: false,
    disablePadding: false,
    label: "Total",
  },
  {
    id: "paymentStatus",
    numeric: false,
    disablePadding: false,
    label: "Payment Status",
  },
  {
    id: "orderStatus",
    numeric: false,
    disablePadding: false,
    label: "Order Status",
  },
  {
    id: "orderAction",
    numeric: false,
    disablePadding: false,
    label: "Actions",
  },
];
// ? TABLE ENDS HERE

export default function AllOrdersTable({
  tabIndex = 0,
  orderList = [],
  orderLoading = false,
  hasError = false,
  totalCount = 0,
  rowsPerPage = 10,
  changeRowsPerPage = () => {},
  changePage = () => {},
  page = 0,
  selected = [],
  setSelected = () => {},
}) {
  const dispatch = useDispatch();

  // const [selected, setSelected] = useState([]);

  // const handleSelectAllClick = (event) => {
  //   if (event.target.checked) {
  //     const newSelected = orderList?.map((n) => n?._id);
  //     setSelected(newSelected);
  //     return;
  //   }
  //   setSelected([]);
  // };

  // * EDIT STATUS POPOVERS STARTS
  // const [anchorEditStatusEl, setAnchorEditStatusEl] = useState(null);
  // const handleEditStatusClick = (event) => {
  //   setAnchorEditStatusEl(event.currentTarget);
  // };
  // const handleEditStatusClose = () => {
  //   setAnchorEditStatusEl(null);
  // };
  // const openEditStatus = Boolean(anchorEditStatusEl);
  // const idEditStatus = openEditStatus ? "simple-popover" : undefined;
  // * EDIT STATUS POPOVERS ENDS

  // * MASS ACTION POPOVERS STARTS
  // const [anchorMassActionEl, setAnchorMassActionEl] = useState(null);
  // const handleMassActionClick = (event) => {
  //   setAnchorMassActionEl(event.currentTarget);
  // };
  // const handleMassActionClose = () => {
  //   setAnchorMassActionEl(null);
  // };
  // const openMassAction = Boolean(anchorMassActionEl);
  // const idMassAction = openMassAction ? "simple-popover" : undefined;
  // * MASS ACTION POPOVERS ENDS

  // * USER POPOVERS STARTS
  const [viewingUser, setViewingUser] = useState(null);
  const [anchorUserEl, setAnchorUserEl] = useState(null);
  const handleUserClick = (target, user) => {
    setAnchorUserEl(target);
    setViewingUser(user);
  };
  const handleUserClose = () => {
    setAnchorUserEl(null);
    setViewingUser(null);
  };
  // * USER POPOVERS ENDS

  // * ITEM POPOVERS STARTS
  const [viewingItems, setViewingItems] = useState([]);
  const [anchorItemEl, setAnchorItemEl] = useState(null);
  const handleItemClick = (target, items) => {
    setAnchorItemEl(target);
    setViewingItems(items);
  };
  const handleItemClose = () => {
    setAnchorItemEl(null);
    setViewingItems([]);
  };
  // * ITEM POPOVERS ENDS

  // * ACTION POPOVERS STARTS
  // const [viewingProduct, setViewingProduct] = useState(null);
  // const [anchorActionEl, setAnchorActionEl] = useState(null);
  // const handleActionClick = (target, product) => {
  //   setAnchorActionEl(target);
  //   setViewingProduct(product);
  // };
  // const handleActionClose = () => {
  //   setAnchorActionEl(null);
  //   setViewingProduct(null);
  // };
  // * ACTION POPOVERS ENDS
  const [archivingOrder, setArchivingOrder] = useState(null);
  const [cancellingOrder, setCancellingOrder] = useState(null);
  const [viewingOrder, setViewingOrder] = useState(null);
  const [anchorMoreActionEl, setAnchorMoreActionEl] = useState(null);
  const handleMoreActionClick = (target, order) => {
    setAnchorMoreActionEl(target);
    setViewingOrder(order);
  };
  const handleMoreActionClose = () => {
    setAnchorMoreActionEl(null);
    setViewingOrder(null);
  };
  // * MOREACTION POPOVERS ENDS

  const [sendInvoice] = useSendInvoiceMutation();

  const sendInvoiceOnClick = () => {
    sendInvoice({
      order: viewingOrder?._id,
      customerEmail: viewingOrder?.address?.shipping?.email,
    })
      .unwrap()
      .then(() => {
        dispatch(showSuccess({ message: "Invoice Sent Succesfully" }));
      })
      .catch((e) => {
        dispatch(showError({ message: e?.data?.message ?? "Something went wrong" }));
      });
  };

  const [editOrder, { isLoading: editOrderIsLoading }] = useUpdateOrderMutation();

  const archiveOrder = () => {
    editOrder({ id: archivingOrder?._id, order: { status: "archieved" } })
      .unwrap()
      .then(() => {
        dispatch(showSuccess({ message: "Order Archieved Succesfully" }));
        setArchivingOrder(null);
      })
      .catch((e) =>
        dispatch(
          showError({
            message: e?.data?.message ?? e?.message ?? "Something went wrong",
          })
        )
      );
  };

  const downloadInvoice = async (url) => {
    try {
      const data = await fetch(`${url}?t=${Date.now().toString(36)}`);
      if (!data.ok)
        throw new Error(`Download Failed status:${data.status} message:${data.statusText}`);
      const blob = await data.blob();
      const objectUrl = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.setAttribute("href", objectUrl);
      link.setAttribute("download", "invoices.zip");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(objectUrl);
    } catch (error) {
      console.log(error);
      dispatch(showError({ message: error.message ?? "Something went wrong" }));
    }
  };

  return orderLoading ? (
    <span className="d-flex justify-content-center m-3">
      <TableLoader />
    </span>
  ) : orderList?.length === 0 || hasError ? (
    <span className="d-flex justify-content-center m-3">
      <NoDataFound />
    </span>
  ) : (
    <>
      {selected.length > 0 && (
        <div className="d-flex justify-content-between align-items-center px-2 mb-3">
          <div className="d-flex">
            <button className="button-grey py-2 px-3">
              <small className="text-lightBlue">
                {selected.length} orders are selected&nbsp;
                <span
                  className="text-blue-2 c-pointer"
                  onClick={() => setSelected([])}>
                  (Clear Selection)
                </span>
              </small>
            </button>
          </div>
        </div>
      )}
      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          size="medium">
          <EnhancedTableHead
            onSelectAllClick={(e) => setSelected(e.target.checked ? [...orderList] : [])}
            numSelected={selected.length}
            rowCount={orderList.length}
            headCells={headCells(totalCount)}
            orderBy={"groupName"}
            onRequestSort={Date}
            order={"asc"}
          />
          <TableBody>
            {orderList.map((row) => {
              const isItemSelected = selected.includes(row);
              const orderStatusObj = OrderStatuses.find((os) => os.value === row.status);
              const paymentStatusObj = PaymentStatuses.find(
                (ps) => ps.value === row.paymentStatus
              );
              return (
                <TableRow
                  hover
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row?._id}
                  selected={isItemSelected}
                  className="table-rows">
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={isItemSelected}
                      onClick={(e) =>
                        setSelected((sel) =>
                          e.target.checked
                            ? sel.concat(row)
                            : sel.filter((s) => !Object.is(s, row))
                        )
                      }
                      size="small"
                      style={{ color: "#5C6D8E" }}
                    />
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    padding="none">
                    <div className="d-flex align-items-center py-2">
                      <div>
                        <Link
                          to={`/orders/allOrders/${row._id}/details`}
                          className="text-decoration-none d-flex">
                          <p className="text-blue-2 fw-600">#{row.centralID || row?.orderID}</p>
                          {/* {row._id === "#12512" && (
                            <p className="text-yellow-2 fw-600">&nbsp;• Pre Order</p>
                          )} */}
                        </Link>
                        <small className="mt-2 text-lightBlue">
                        {row.salesOrderDate ?
                          (
                            moment.utc(row.salesOrderDate).format("DD-MM-YYYY [at] h:mm a")
                          ): moment(row.createdAt).format("DD-MM-YYYY [at] h:mm a")}
                        </small>
                      </div>
                    </div>
                  </TableCell>
                  <TableCell>
                    {!row?.customer ? (
                      <div className="d-flex align-items-center py-2">
                        <p className="text-blue-2 fw-600">
                          {row?.address?.shipping?.firstName} {row?.address?.shipping?.lastName}{" "}
                          (Guest)
                        </p>
                      </div>
                    ) : (
                      <div
                        onClick={(e) => handleUserClick(e.currentTarget, row)}
                        className="d-flex align-items-center c-pointer py-2">
                        <p className="text-blue-2 fw-600">
                          {row?.customer?.firstName} {row?.customer?.lastName}
                        </p>
                      </div>
                    )}
                  </TableCell>
                  <TableCell width="120">
                    <div
                      onClick={(e) => handleItemClick(e.currentTarget, row.items)}
                      className="d-flex align-items-center c-pointer">
                      <p className="text-blue-2 fw-600">{row.items?.length}</p>
                    </div>
                  </TableCell>
                  <TableCell width="180">
                    <div className="d-flex flex-column">
                      <p className="text-lightBlue">
                        {rupeeFormatWithSymbol(
                          Math.round(row?.totalOrderAmount)
                        )}
                      </p>
                      {/* {row.paymentStatus === "done" && (
                        <p className="text-grey-6">via Debit Card</p>
                      )} */}
                    </div>
                  </TableCell>
                  <TableCell width="180">
                    <div className="d-block">
                      <div
                        className="rounded-pill d-flex px-2 py-1 statusBoxWidth text-center"
                        style={{ background: paymentStatusObj?.color }}>
                        <small className="text-capitalize text-black fw-500">
                          {paymentStatusObj?.text}
                        </small>
                      </div>
                    </div>
                  </TableCell>
                  {/* <TableCell width="180">
                    <div className="d-flex align-items-center">
                      <div className="rounded-pill d-flex table-status px-2 py-1 c-pointer">
                        <small className="text-black fw-400">{row.paymentStatus}</small>
                      </div>
                    </div>
                  </TableCell> */}
                  <TableCell width="180">
                    <div className="d-flex align-items-center">
                      <div
                        className="rounded-pill d-flex px-2 py-1 statusBoxWidth text-center"
                        style={{ background: orderStatusObj?.color }}>
                        <small className="text-black fw-400">{orderStatusObj?.text}</small>
                      </div>
                    </div>
                  </TableCell>
                  <TableCell style={{ width: 80, padding: 0 }}>
                    <div className="d-flex align-items-center">
                      <Tooltip
                        title="Details"
                        placement="top">
                        <Link
                          to={`/orders/allOrders/${row._id}/details`}
                          className="table-edit-icon rounded-4 p-2">
                          <VisibilityOutlinedIcon
                            sx={{
                              color: "#5c6d8e",
                              fontSize: 18,
                            }}
                          />
                        </Link>
                      </Tooltip>
                      <Tooltip
                        title="Actions"
                        placement="top">
                        <span className="table-edit-icon c-pointer rounded-4 p-2">
                          <MoreVertIcon
                            onClick={(e) => handleMoreActionClick(e.currentTarget, row)}
                            sx={{
                              color: "#5c6d8e",
                              fontSize: 18,
                            }}
                          />
                        </span>

                        {/* <img
                          src={verticalDots}
                          alt="verticalDots"
                          className="c-pointer mx-2"
                        /> */}
                      </Tooltip>
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30, 50]}
        component="div"
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        onRowsPerPageChange={changeRowsPerPage}
        onPageChange={changePage}
        className="table-pagination"
      />
      <Popover
        open={!!viewingUser}
        anchorEl={anchorUserEl}
        onClose={handleUserClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}>
        <div className="py-2 px-2">
          <div className="d-flex justify-content-between align-items-center pt-1">
            <div className="d-flex align-items-center">
              <AsyncImage
                src={viewingUser?.customer?.imageUrl}
                placeholder={userIcon}
                alt="product"
                className="me-2 rounded-4"
                height={40}
                width={40}
              />
              <div className="d-flex flex-column">
                <p className="text-lightBlue">
                  {viewingUser?.customer?.firstName} {viewingUser?.customer?.lastName}
                </p>
                {/* <small className="text-blue-2 c-pointer">View Profile</small> */}
                {/* <img
                  src={indiaFlag}
                  alt="indiaFlag"
                  width={20}
                /> */}
              </div>
            </div>
          </div>
          <hr className="hr-grey-6 my-2" />
          <div className="d-flex align-items-center mt-3">
            <small className=" text-blue-2 me-2">{viewingUser?.customer?.email}</small>
            {/* <Tooltip
              title="Copy"
              placement="top">
              <ContentCopyIcon
                sx={{ fontSize: 12, color: "#c8d8ff" }}
                className="c-pointer"
              />
            </Tooltip> */}
          </div>
          <div className="d-flex align-items-center mt-3">
            <small className="text-blue-2 me-2">
              {viewingUser?.customer?.countryCode}-{viewingUser?.customer?.phone}
            </small>
            {/* <Tooltip
              title="Copy"
              placement="top">
              <ContentCopyIcon
                sx={{ fontSize: 12, color: "#c8d8ff" }}
                className="c-pointer"
              />
            </Tooltip> */}
          </div>
          <div className="d-flex align-items-center mt-3">
            <div>
              <p className="my-1">
                <small className="text-lightBlue">
                  {viewingUser?.address?.shipping?.line1}{" "}
                  {viewingUser?.address?.shipping?.line2}
                </small>
              </p>
              <p className="text-lightBlue my-1">
                <small className="text-lightBlue">
                  {viewingUser?.address?.shipping?.state?.name}{" "}
                  {viewingUser?.address?.shipping?.country?.name}{" "}
                  {viewingUser?.address?.shipping?.pinCode}
                </small>
              </p>
            </div>
            {/* <Tooltip
              title="Copy"
              placement="top">
              <ContentCopyIcon
                sx={{ fontSize: 12, color: "#c8d8ff" }}
                className="c-pointer ms-3"
              />
            </Tooltip> */}
          </div>
          <div className="d-flex mt-4 mb-1">
            <Link
              to={`/users/allUsers/details/%7B"id"%3A"${viewingUser?.customer?._id}"%7D`}
              className="button-lightBlue-outline w-100 px-3 py-1">
              <small>View Profile</small>
            </Link>
            {/* <buton className="button-lightBlue-outline px-3 py-1 align-items-center">
              <ChatIcon sx={{ fontSize: 14 }} />
              <small className="ms-2">Message</small>
            </buton> */}
          </div>
        </div>
      </Popover>
      <Popover
        open={!!viewingItems.length}
        anchorEl={anchorItemEl}
        onClose={handleItemClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}>
        <Table size="small">
          <TableBody>
            {viewingItems.map((item) => {
              // console.log(item);
              const imageUrl =
                item?.item?.productData?.mediaUrl?.find((mu) => mu.isDefault)?.image ?? "";
              return (
                <TableRow key={item._id}>
                  <TableCell
                    padding="normal"
                    className="ps-1 pe-3">
                    <div className="row">
                      <div className="col-auto">
                        <AsyncImage
                          src={imageUrl}
                          placeholder={ringSmall}
                          alt="product"
                          height={40}
                          width={40}
                        />
                      </div>
                      <div className="col px-1">
                        <p className="text-lightBlue">{item?.item?.productName}</p>
                        {/* <p className="text-grey-6 font0"></p> */}
                        <p className="text-grey-6 font0">
                          SKU: {item?.item?.productData?.inventory?.skuId}
                        </p>
                      </div>
                    </div>
                  </TableCell>
                  <TableCell padding="normal">
                    <div className="w-auto">
                      <small className="text-grey-6">
                        ID: <small className="text-blue-2">{item?.checkoutID}</small>
                      </small>
                      <br />
                      <small className="text-grey-6">
                        ₹ <small className="text-blue-2">{item?.item?.price}</small>
                      </small>
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Popover>
      <Popover
        open={!!viewingOrder}
        anchorEl={anchorMoreActionEl}
        onClose={handleMoreActionClose}
        onClick={handleMoreActionClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}>
        <div className="py-2 px-1">
          {(viewingOrder?.status === "pending" || viewingOrder?.status === "processing") && (
            <div
              onClick={() => setCancellingOrder(viewingOrder)}
              className="d-flex align-items-center rounded-3 p-2 hover-back c-pointer">
              <ClearIcon
                sx={{
                  color: "#c8d8ff",
                  fontSize: 16,
                  cursor: "pointer",
                  marginRight: "0.5rem",
                }}
              />
              <small className="text-lightBlue d-block">Cancel Order</small>
            </div>
          )}
          {!!viewingOrder?.invoiceUrl && (
            <>
              <div
                onClick={() => downloadInvoice(viewingOrder.invoiceUrl)}
                className="d-flex align-items-center rounded-3 p-2 hover-back c-pointer">
                <FileDownloadOutlinedIcon
                  sx={{
                    color: "#c8d8ff",
                    fontSize: 16,
                    cursor: "pointer",
                    marginRight: "0.5rem",
                  }}
                />
                <small className="text-lightBlue d-block">Download Order Invoice</small>
              </div>
              <div
                onClick={sendInvoiceOnClick}
                className="d-flex align-items-center rounded-3 p-2 hover-back c-pointer">
                <EmailOutlinedIcon
                  sx={{
                    color: "#c8d8ff",
                    fontSize: 16,
                    cursor: "pointer",
                    marginRight: "0.5rem",
                  }}
                />
                <small className="text-lightBlue d-block">Send Invoice Email</small>
              </div>
            </>
          )}
          {(viewingOrder?.status === "cancelled" || viewingOrder?.status === "delivered") && (
            <div
              onClick={() => setArchivingOrder(viewingOrder)}
              className="d-flex align-items-center rounded-3 p-2 hover-back c-pointer">
              <DeleteIcon
                sx={{
                  color: "#c8d8ff",
                  fontSize: 16,
                  cursor: "pointer",
                  marginRight: "0.5rem",
                }}
              />
              <small className="text-lightBlue d-block">Archive Order</small>
            </div>
          )}
        </div>
      </Popover>
      <CancelRefundOrderDialog
        order={cancellingOrder}
        onClose={setCancellingOrder}
      />
      <OrderArchiveModal
        show={!!archivingOrder}
        onConfirm={archiveOrder}
        onCancel={() => setArchivingOrder(null)}
        title="Archive Order"
        message="Are you sure you want to Archive this Order?"
        isLoading={editOrderIsLoading}
      />
    </>
  );
}

/* <Popover
          open={!!viewingProduct}
          anchorEl={anchorActionEl}
          onClose={handleActionClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}>
          <div className="py-2 px-1">
            <small className="text-lightBlue rounded-3 p-2 hover-back d-block c-pointer">
              <CloseIcon
                sx={{
                  fontSize: 14,
                  marginRight: 1,
                }}
              />
              Cancel Order
            </small>
            <small className="text-lightBlue rounded-3 p-2 hover-back d-block c-pointer">
              <ReceiptIcon
                sx={{
                  fontSize: 14,
                  marginRight: 1,
                }}
              />
              View Invoice
            </small>
            <small className="text-lightBlue rounded-3 p-2 hover-back d-block c-pointer">
              <PrintIcon
                sx={{
                  fontSize: 14,
                  marginRight: 1,
                }}
              />
              Print Packaging Slip
            </small>
          </div>
        </Popover> */

/* 
            <button className="button-grey py-2 px-3 ms-2">
              <small className="text-lightBlue">Edit Users</small>
            </button>
            <button
              className="button-grey py-2 px-3 ms-2"
              aria-describedby={idEditStatus}
              variant="contained"
              onClick={handleEditStatusClick}>
              <small className="text-lightBlue">Edit Status</small>
              <img
                src={arrowDown}
                alt="arrowDown"
                className="ms-2"
              />
            </button>

            <Popover
              id={idEditStatus}
              open={openEditStatus}
              anchorEl={anchorEditStatusEl}
              onClose={handleEditStatusClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}>
              <div className="py-2 px-1">
                <small className="p-2 rounded-3 text-lightBlue c-pointer font2 d-block hover-back">
                  Set as Active
                </small>
                <small className="p-2 rounded-3 text-lightBlue c-pointer font2 d-block hover-back">
                  Set as Draft
                </small>
              </div>
            </Popover>
            <button
              className="button-grey py-2 px-3 ms-2"
              aria-describedby={idMassAction}
              variant="contained"
              onClick={handleMassActionClick}>
              <small className="text-lightBlue">Mass Action</small>
              <img
                src={arrowDown}
                alt="arrowDown"
                className="ms-2"
              />
            </button>
            <Popover
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              id={idMassAction}
              open={openMassAction}
              anchorEl={anchorMassActionEl}
              onClose={handleMassActionClose}>
              <div className="py-2 px-2">
                <small className="text-grey-7 px-2">ACTIONS</small>
                <hr className="hr-grey-6 my-2" />
                <small className="p-2 rounded-3 text-lightBlue c-pointer font2 d-block hover-back">
                  Edit User
                </small>
                <small className="p-2 rounded-3 text-lightBlue c-pointer font2 d-block hover-back">
                  Edit User Group
                </small>
                <small className="p-2 rounded-3 text-lightBlue c-pointer font2 d-block hover-back">
                  Add or Remove Tags
                </small>
                <div className="d-flex justify-content-between  hover-back rounded-3 p-2 c-pointer">
                  <small className="text-lightBlue font2 d-block">Archive User</small>
                  <img
                    src={deleteRed}
                    alt="delete"
                    className=""
                  />
                </div>
              </div>
            </Popover> */
