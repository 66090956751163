import { useEffect, useState } from "react";
import "./EditReview.scss";
import { Link, useNavigate, useParams } from "react-router-dom";
import AppTextEditor from "../../../components/AppTextEditor/AppTextEditor";
import StatusBox from "../../../components/StatusBox/StatusBox";
import arrowLeft from "../../../assets/icons/arrowLeft.svg";
import info from "../../../assets/icons/info.svg";
import { FormControl, OutlinedInput, Tooltip, Rating, FormHelperText } from "@mui/material";
import { useFormik } from "formik";
import { DiscardModalSecondary } from "../../../components/Discard/DiscardModal";
import { LoadingButton } from "@mui/lab";
import * as Yup from "yup";
import _ from "lodash";
import {
  useEditReviewMutation,
  useGetReviewsQuery,
} from "../../../features/products/reviews/reviewsApiSlice";
import { useDispatch } from "react-redux";
import { showError, showSuccess } from "../../../features/snackbar/snackbarAction";
import TagsBoxBox from "../../../components/TagsBox/TagsBoxBox";
import { UploadMultipleMediaLarge } from "../../../components/UploadMediaBox/UploadMedia";

const reviewValidationSchema = Yup.object({
  star: Yup.number().integer().min(1).max(5).required("required"),
  description: Yup.string()
    .trim()
    .min(8, "Too short")
    .max(256, "Too long")
    .required("required"),
  status: Yup.string().oneOf(["approved", "pending"]),
  tags: Yup.array().of(Yup.string().trim().required("required")),
});

const EditReview = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { reviewId } = useParams();

  const { data: reviewData, isSuccess: reviewIsSuccess } = useGetReviewsQuery({ id: reviewId });
  const review = reviewData?.data?.data?.[0] ?? {};
  console.log("review", review);
  const reviewInitialValue = {
    customerFrotnendName: review?.customerFrotnendName ?? "",
    star: review?.star ?? 3,
    description: review?.description ?? "",
    status: review?.status ?? "approved",
    tags: review?.tags ?? [],
    state: review?.state ?? "created",
    imageUrl: review?.imageUrl ?? [],
  };
  console.log("reviewInitialValue", reviewInitialValue);

  const [editReview, { isLoading: editReviewIsLoading }] = useEditReviewMutation();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: reviewInitialValue,
    validationSchema: reviewValidationSchema,
    onSubmit: (values) => {
      editReview({ id: reviewId, details: values })
        .unwrap()
        .then(() => {
          setDiscard(false);
          onDiscardClick();
          dispatch(showSuccess({ message: "Review updated successfully" }));
        })
        .catch((e) =>
          dispatch(showError({ message: e?.data?.message ?? "Something went wrong" }))
        );
    },
  });

  const [discard, setDiscard] = useState(false);
  useEffect(() => {
    const check = _.isEqual(formik.values, formik.initialValues);
    setDiscard(!check);
  }, [formik.initialValues, formik.values]);

  const onDiscardClick = () => navigate(-1);

  return (
    <div className="page container-fluid position-relative user-group">
      <div className="row justify-content-between">
        <div className="d-flex align-items-center w-auto ps-0">
          <Link
            to="/products/reviews"
            className="d-flex">
            <img
              src={arrowLeft}
              alt="arrowLeft"
              width={9}
              className="c-pointer"
            />

            <h5 className="page-heading ms-2 ps-1">Edit {review?.ID ?? "Review"}</h5>
          </Link>
        </div>
      </div>
      {reviewIsSuccess && (
        <form
          noValidate
          onSubmit={formik.handleSubmit}>
          <div className="row mt-3">
            <div className="col-lg-9 mt-3">
              <div className="bg-black-15 border-grey-5 rounded-8 p-3 row attributes">
                <div className="col-12 px-0">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                      <h6 className="text-lightBlue fw-500 ">
                        Product and Customer Information
                      </h6>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center">
                    <hr className="hr-grey-6 w-100 my-3" />
                  </div>
                </div>
                <div className="col-md-12 px-0">
                  <div className="d-flex mb-1">
                    <p className="text-lightBlue me-2">Order ID</p>
                    <Tooltip
                      title="Lorem ipsum"
                      placement="top">
                      <img
                        src={info}
                        alt="info"
                        className=" c-pointer"
                        width={13.5}
                      />
                    </Tooltip>
                  </div>
                  <FormControl className="w-100 px-0">
                    <OutlinedInput
                      size="small"
                      defaultValue={review?.order?.orderID}
                      disabled
                      readOnly
                    />
                  </FormControl>
                </div>
                <div className="col-md-12 px-0 mt-3">
                  <div className="d-flex mb-1">
                    <p className="text-lightBlue me-2">Product to Review</p>
                    <Tooltip
                      title="Lorem ipsum"
                      placement="top">
                      <img
                        src={info}
                        alt="info"
                        className=" c-pointer"
                        width={13.5}
                      />
                    </Tooltip>
                  </div>
                  <FormControl className="w-100 px-0">
                    <OutlinedInput
                      size="small"
                      defaultValue={review?.product?.title ?? "Value missing in API"}
                      disabled
                      readOnly
                    />
                  </FormControl>
                </div>
                {review?.customer?._id && (
                  <div className="col-md-12 px-0 mt-3">
                    <div className="d-flex mb-1">
                      <p className="text-lightBlue me-2">Customer Name</p>
                      <Tooltip
                        title="Lorem ipsum"
                        placement="top">
                        <img
                          src={info}
                          alt="info"
                          className=" c-pointer"
                          width={13.5}
                        />
                      </Tooltip>
                    </div>
                    <FormControl className="w-100 px-0">
                      <OutlinedInput
                        size="small"
                        type="text"
                        defaultValue={
                          review?.customer?.firstName + " " + review?.customer?.lastName
                        }
                        disabled
                        readOnly
                      />
                    </FormControl>
                  </div>
                )}
                <div className="col-md-12 px-0 mt-3">
                  <div className="d-flex mb-1">
                    <p className="text-lightBlue me-2">Customer Frontend</p>
                    <Tooltip
                      title="Lorem ipsum"
                      placement="top">
                      <img
                        src={info}
                        alt="info"
                        className=" c-pointer"
                        width={13.5}
                      />
                    </Tooltip>
                  </div>
                  <FormControl className="w-100 px-0">
                    <OutlinedInput
                      placeholder="Enter Group Name"
                      size="small"
                      name="customerFrotnendName"
                      value={formik.values.customerFrotnendName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </FormControl>
                </div>
              </div>

              <div className="bg-black-9 border-grey-5 rounded-8 p-3 row features mt-4">
                <div className="d-flex justify-content-between px-0">
                  <h6 className="text-lightBlue me-auto text-lightBlue col-auto ps-0 fw-500">
                    Add Rating & Reviews
                  </h6>
                </div>

                <div className="d-flex justify-content-center col-12 px-0">
                  <hr className="hr-grey-6 w-100 my-3" />
                </div>
                <div className="col-12 px-0">
                  <p className="text-lightBlue">Add Rating</p>
                  <Rating
                    name="star"
                    value={formik.values.star}
                    onBlur={formik.handleBlur}
                    onChange={(_, v) => formik.setFieldValue("star", v)}
                  />
                  <FormHelperText error>
                    {formik.touched.star && formik.errors.star}
                  </FormHelperText>
                </div>
                <div className="col-12 mt-3 px-0">
                  <div className="d-flex mb-1">
                    <p className="text-lightBlue me-2">Media</p>
                    <Tooltip
                      title="Lorem ipsum"
                      placement="top">
                      <img
                        src={info}
                        alt="info"
                        className=" c-pointer"
                        width={13.5}
                      />
                    </Tooltip>
                  </div>
                  <UploadMultipleMediaLarge
                    fileSrc={formik.values.imageUrl.map((url) => ({ image: url }))}
                    style={{ minHeight: "20rem" }}
                    onUpload={(urls) => formik.setFieldValue("imageUrl", urls)}
                    onRemove={(index) =>
                      formik.setFieldValue(
                        "imageUrl",
                        formik.values.imageUrl.filter((_, i) => index !== i)
                      )
                    }
                  />
                </div>
                <div className="col-12 mt-3 px-0">
                  <div className="d-flex mb-1">
                    <p className="text-lightBlue me-2">Description</p>
                    <Tooltip
                      title="Lorem ipsum"
                      placement="top">
                      <img
                        src={info}
                        alt="info"
                        className=" c-pointer"
                        width={13.5}
                      />
                    </Tooltip>
                  </div>
                  {/* <AppTextEditor
                    config={{
                      toolbar: {
                        items: [
                          "undo",
                          "redo",
                          "|",
                          "heading",
                          "|",
                          "bold",
                          "italic",
                          "|",
                          "bulletedList",
                          "numberedList",
                          "outdent",
                          "indent",
                        ],
                      },
                    }}
                    value={formik.values.description}
                    setFieldValue={(v) => formik.setFieldValue("description", v)}
                  /> */}
                  <textarea
                    name="description"
                    value={formik.values.description}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  />
                  <FormHelperText error>
                    {formik.touched.description && formik.errors.description}
                  </FormHelperText>
                </div>
              </div>
            </div>
            <div className="col-lg-3 mt-3 pe-0 ps-0 ps-lg-3">
              <StatusBox
                name="status"
                toggleData={["approved", "pending"]}
                value={formik.values.status}
                handleProductStatus={(_, s) => formik.setFieldValue("status", s)}
                headingName="Review Status"
              />
              <TagsBoxBox
                moduleName="Review"
                filterQuery="productReview"
                initTags={formik.values.tags}
                onTagsChange={(t) => formik.setFieldValue("tags", t)}
              />
            </div>
          </div>
          {discard && (
            <div className="row create-buttons pt-5 pb-3 justify-content-between">
              <div className="d-flex w-auto px-0">
                <button
                  type="button"
                  onClick={onDiscardClick}
                  className="button-red-outline py-2 px-4">
                  <p>Discard</p>
                </button>
              </div>
              {/* <div className="d-flex w-auto px-0">
            <button
              type="submit"
              onClick={() => handleSetAddAnother(true)}
              className="button-lightBlue-outline py-2 px-4">
              <p>Save & Add Another</p>
            </button> */}
              <LoadingButton
                type="submit"
                className="button-gradient ms-3 py-2 px-4 w-auto"
                loading={editReviewIsLoading}
                disabled={editReviewIsLoading}>
                <p>Save</p>
              </LoadingButton>
              {/* </div> */}
            </div>
          )}
          <DiscardModalSecondary
            when={discard}
            message="review tab"
          />
        </form>
      )}
    </div>
  );
};

export default EditReview;
