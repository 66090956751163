import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Slide,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from "@mui/material";
import { DesktopDateTimePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import * as Yup from "yup";
import { useFormik } from "formik";
import dayjs from "dayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { useFormikToastError } from "../../hooks/error";

import Label from "../Label/Label";

import info from "../../assets/icons/info.svg";
import clock from "../../assets/icons/clock.svg";
import cancel from "../../assets/icons/cancel.svg";

import "./StatusBox.scss";

// ? DIALOG TRANSITION STARTS HERE
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
// ? DIALOG TRANSITION ENDS HERE
const StatusBox = ({
  headingName,
  titleName,
  showSchedule,
  value,
  handleProductStatus,
  toggleData = ["active", "in-active"],
  toggleDataLabel = ["active", "in-active"],
  startDate,
  endDate,
  handleStartDate,
  handleEndDate,
  clearDate,
}) => {
  const showScheduleData = showSchedule === undefined ? false : true;
  const [openScheduleProduct, setOpenScheduleProduct] = React.useState(false);
  const [startDateLocal, setStartDate] = React.useState(
    startDate ? moment(startDate).toDate() : null
  );
  const [endDateLocal, setEndDate] = React.useState("");
  const startDateNew = moment(startDate)
    .utcOffset("+05:30")
    .format("DD/MM/YYYY");
  const startTime = moment(startDate).utcOffset("+05:30").format("HH:mm A");
  const endDateNew = moment(endDate).utcOffset("+05:30").format("DD/MM/YYYY");
  const endTime = moment(endDate).format("HH:mm A");

  const handelScheduleProduct = () => {
    setOpenScheduleProduct(true);
  };

  const handelScheduleProductClose = () => {
    setOpenScheduleProduct(false);
  };

  const minDateTime = startDate ? moment(startDate).add(10, "minutes") : null;
  const currentTime = new Date();

  return (
    <div className={`bg-black-15 border-grey-5 rounded-8 p-3`}>
      <div className="d-flex align-items-center justify-content-between">
        <h6 className="text-lightBlue mb-3 fw-500">{headingName}</h6>
      </div>
      <ToggleButtonGroup
        value={value === "scheduled" ? "in-active" : value}
        onChange={handleProductStatus}
        aria-label="text formatting"
        className="row d-flex px-2 productInfo-toggle"
        size="small"
        exclusive
      >
        <ToggleButton
          value={toggleData[0]}
          type="button"
          aria-label="active"
          style={{ width: "50%" }}
          className="productInfo-toggle__active"
          disabled={startDate != null}
        >
          <div className="d-flex">
            <p className={`text-grey-6`}>{toggleDataLabel[0]}</p>
          </div>
        </ToggleButton>
        <ToggleButton
          value={toggleData[1]}
          type="button"
          aria-label="inactive"
          style={{ width: "50%" }}
          className="productInfo-toggle__draft"
          disabled={startDate != null}
        >
          <div className="d-flex">
            <p className="text-grey-6">{toggleDataLabel[1]}</p>
          </div>
        </ToggleButton>
      </ToggleButtonGroup>
      {showScheduleData && (
        <div className="d-flex align-items-center mt-4 c-pointer">
          <img src={clock} alt="clock" className="me-1" width={12} />
          <small className="text-blue-2" onClick={handelScheduleProduct}>
            Scheduled{" "}
            {startDate == null ? "" : `for ${startDateNew} at ${startTime}`}{" "}
            {endDate == null ? "" : `till ${endDateNew} at ${endTime}`}
          </small>
        </div>
      )}
      {startDate != null && (
        <div className="d-flex justify-content-end px-4 py-3">
          <small
            className="text-blue-2 px-3"
            style={{ cursor: "pointer" }}
            onClick={handelScheduleProduct}
          >
            Edit
          </small>
          <small
            style={{ color: "#F67476", cursor: "pointer" }}
            onClick={clearDate}
          >
            Clear
          </small>
        </div>
      )}

      <Dialog
        open={openScheduleProduct}
        TransitionComponent={Transition}
        keepMounted
        onClose={handelScheduleProductClose}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle>
          <div className="d-flex justify-content-between align-items-center">
            <h5 className="text-lightBlue fw-500">Schedule {titleName}</h5>
            <img
              src={cancel}
              alt="cancel"
              width={30}
              onClick={handelScheduleProductClose}
              className="c-pointer"
            />
          </div>
        </DialogTitle>
        <hr className="hr-grey-6 my-0" />
        <DialogContent className="py-3 px-4 schedule-product">
          <div className="d-flex mb-1">
            <p className="text-lightBlue">
              Start Date
              <span style={{ color: "#F67476" }}> *</span>
            </p>
            <Tooltip title="Lorem ipsum" placement="top">
              <img
                src={info}
                alt="info"
                className="ms-2 c-pointer"
                width={13.5}
              />
            </Tooltip>
          </div>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DesktopDateTimePicker
              value={startDate ? startDate : null}
              onChange={(newValue) => {
                // const currentTime = new Date();
                // if (newValue > currentTime) {
                // setStartDate(newValue);
                handleStartDate(newValue.format("MM/DD/YYYY hh:mm A"));
                // }
              }}
              renderInput={(params) => <TextField {...params} size="small" />}
              minDateTime={moment()}
              maxDateTime={endDate}
            />
          </LocalizationProvider>
          <div className="d-flex mb-1 mt-3">
            <p className="text-lightBlue">End Date</p>
            <p className="text-lightBlue">(Optional)</p>

            <Tooltip title="Lorem ipsum" placement="top">
              <img
                src={info}
                alt="info"
                className="ms-2 c-pointer"
                width={13.5}
              />
            </Tooltip>
          </div>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DesktopDateTimePicker
              value={endDate ? endDate : null}
              onChange={(newValue) => {
                // setEndDate(newValue);
                handleEndDate(newValue.format("MM/DD/YYYY hh:mm A"));
              }}
              renderInput={(params) => <TextField {...params} size="small" />}
              // minDate={startDate}
              // minTime={minDateTime}
              minDateTime={startDate ? moment(startDate) : null}
              disabled={startDate == null ? true : false}
            />
          </LocalizationProvider>
        </DialogContent>
        <hr className="hr-grey-6 my-0" />
        <DialogActions
          className={`d-flex flex-column justify-content-start px-4 py-3`}
        >
          <div className="d-flex justify-content-between w-100">
            <button
              className="button-grey py-2 px-5"
              onClick={handelScheduleProductClose}
            >
              <p className="text-lightBlue">Cancel</p>
            </button>
            <button
              className="button-gradient py-2 px-5"
              onClick={handelScheduleProductClose}
            >
              <p>Schedule</p>
            </button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const scheduleStatusValidationSchema = Yup.object().shape({
  startDate: Yup.date().typeError("Invalid").required("Required"),
  endDate: Yup.date()
    .typeError("Invalid")
    .test("is-greater", "Should be greater than start date", function (time) {
      if (!time) {
        return true;
      }
      return dayjs(this.from[0]?.value.startDate).isBefore(
        dayjs(time).subtract(30, "minute")
      )
        ? true
        : this.createError({
            path: `${this.path}`,
            message: `Should be greater than ${dayjs(
              this.from[0]?.value.startDate
            ).format("DD/MM/YYYY hh:mm A")}`,
          });
    }),
});

const StatusBoxSecondary = (props) => {
  const {
    title,
    status,
    onStatus,
    startDate,
    endDate,
    onSchedule,
    containerStyles,
    titleStyles,
    actionStyles,
    activeText = "active",
    inActiveText = "in-active",
  } = props;
  const [openTimeModal, setOpenTimeModal] = useState(false);
  const [isStartDateOpen, setIsStartDateOpen] = useState(false);
  const [isEndDateOpen, setIsEndDateOpen] = useState(false);

  const scheduleStatusFormik = useFormik({
    initialValues: {
      startDate: "",
      endDate: "",
    },
    enableReinitialize: true,
    validationSchema: scheduleStatusValidationSchema,
    onSubmit: (values) => {
      values.startDate && onSchedule(values);
      setOpenTimeModal(false);
    },
  });

  const statusHandler = (_, value) => {
    !isScheduled && onStatus(value);
  };

  const openTimeModalHandler = () => {
    scheduleStatusFormik.setFieldValue("startDate", startDate);
    scheduleStatusFormik.setFieldValue("endDate", endDate);
    setOpenTimeModal(true);
  };

  const closeTimeModalHandler = () => {
    setOpenTimeModal(false);
    scheduleStatusFormik.resetForm();
  };

  const clearScheduleHandler = () => {
    onStatus("in-active");
    scheduleStatusFormik.resetForm();
  };

  const startDateHandler = (value) => {
    value
      ? scheduleStatusFormik.setFieldValue("startDate", dayjs(value).format())
      : scheduleStatusFormik.setFieldValue("startDate", "");
  };

  const endDateHandler = (value) => {
    value
      ? scheduleStatusFormik.setFieldValue("endDate", dayjs(value).format())
      : scheduleStatusFormik.setFieldValue("endDate", "");
  };

  const isScheduled = status === "scheduled";
  const statusValue = status === "scheduled" ? "in-active" : status;

  return (
    <div className={`bg-black-15 rounded-8 p-3 ${containerStyles}`}>
      <div className="d-flex align-items-center justify-content-between">
        <h6
          className={`text-lightBlue mb-3 fw-500 text-capitalize ${titleStyles}`}
        >
          {title || "status"}
        </h6>
      </div>
      <ToggleButtonGroup
        value={statusValue}
        aria-label="text formatting"
        className="row d-flex px-2 productInfo-toggle"
        size="small"
        exclusive
        onChange={statusHandler}
      >
        <ToggleButton
          value="active"
          type="button"
          aria-label="active"
          style={{ width: "50%" }}
          className="productInfo-toggle__active status-btn"
          disabled={isScheduled}
          sx={{
            border: "1px solid rgba(255, 255, 255, 0.12)",
          }}
        >
          <div className="d-flex">
            <p className="text-grey-6">{activeText}</p>
          </div>
        </ToggleButton>
        <ToggleButton
          value="in-active"
          type="button"
          aria-label="inactive"
          style={{ width: "50%" }}
          className="productInfo-toggle__draft status-btn"
          disabled={isScheduled}
        >
          <div className="d-flex">
            <p className="text-grey-6">{inActiveText}</p>
          </div>
        </ToggleButton>
      </ToggleButtonGroup>

      {isScheduled ? (
        <div className={actionStyles}>
          <div className="d-flex align-items-center mt-4 c-pointer">
            <img src={clock} alt="clock" className="me-1" width={12} />
            <small className="text-blue-2">
              {`Scheduled for ${dayjs(startDate).format(
                "DD/MM/YYYY hh:mm A"
              )} ${
                endDate
                  ? `till ${dayjs(endDate).format("DD/MM/YYYY hh:mm A")}`
                  : ""
              }`}
            </small>
          </div>
          <div className="d-flex justify-content-end px-4 py-3">
            <small
              onClick={openTimeModalHandler}
              className="text-blue-2 px-3"
              style={{ cursor: "pointer" }}
            >
              Edit
            </small>
            <small
              onClick={clearScheduleHandler}
              style={{ color: "#F67476", cursor: "pointer" }}
            >
              Clear
            </small>
          </div>
        </div>
      ) : (
        <div
          onClick={openTimeModalHandler}
          className="d-flex align-items-center mt-4 c-pointer"
        >
          <img src={clock} alt="clock" className="me-1" width={12} />
          <small className="text-blue-2">Schedule</small>
        </div>
      )}

      <Dialog
        open={openTimeModal}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        maxWidth="sm"
        onClose={closeTimeModalHandler}
        fullWidth={true}
      >
        <DialogTitle>
          <div className="d-flex justify-content-between align-items-center">
            <h5 className="text-lightBlue fw-500">Schedule</h5>
            <img
              onClick={closeTimeModalHandler}
              src={cancel}
              alt="cancel"
              width={30}
              className="c-pointer"
            />
          </div>
        </DialogTitle>
        <hr className="hr-grey-6 my-0" />
        <DialogContent className="py-3 px-4 schedule-product">
          <div className="d-flex mb-1">
            <Label required={true}>Start Date</Label>
            <Tooltip title="Lorem ipsum" placement="top">
              <img
                src={info}
                alt="info"
                className="ms-2 c-pointer"
                width={13.5}
              />
            </Tooltip>
          </div>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div
              onClick={() => setIsStartDateOpen(true)}
              className="date-container"
            >
              <DateTimePicker
                value={
                  scheduleStatusFormik.values.startDate
                    ? dayjs(scheduleStatusFormik.values.startDate)
                    : null
                }
                ampm={false}
                minDateTime={dayjs()}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    disabled
                    autoComplete="off"
                  />
                )}
                name="startDate"
                onChange={startDateHandler}
                onClose={() => setIsStartDateOpen(false)}
                open={isStartDateOpen}
              />
            </div>

            {!!scheduleStatusFormik.touched.startDate &&
              scheduleStatusFormik.errors.startDate && (
                <FormHelperText error>
                  {scheduleStatusFormik.errors.startDate}
                </FormHelperText>
              )}
          </LocalizationProvider>
          <div className="d-flex mb-1 mt-3">
            <Label>End Date (Optional)</Label>
            <Tooltip title="Lorem ipsum" placement="top">
              <img
                src={info}
                alt="info"
                className="ms-2 c-pointer"
                width={13.5}
              />
            </Tooltip>
          </div>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div
              onClick={
                scheduleStatusFormik.values.startDate
                  ? () => setIsEndDateOpen(true)
                  : () => {}
              }
              className={
                scheduleStatusFormik.values.startDate ? "date-container" : ""
              }
            >
              <DateTimePicker
                value={
                  scheduleStatusFormik.values.endDate
                    ? dayjs(scheduleStatusFormik.values.endDate)
                    : null
                }
                ampm={false}
                minDateTime={dayjs(scheduleStatusFormik.values.startDate).add(
                  30,
                  "minute"
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    disabled
                    autoComplete="off"
                  />
                )}
                name="endDate"
                onChange={endDateHandler}
                disabled={!scheduleStatusFormik.values.startDate}
                onClose={() => setIsEndDateOpen(false)}
                open={isEndDateOpen}
              />
            </div>
            {!!scheduleStatusFormik.touched.endDate &&
              scheduleStatusFormik.errors.endDate && (
                <FormHelperText error>
                  {scheduleStatusFormik.errors.endDate}
                </FormHelperText>
              )}
          </LocalizationProvider>
        </DialogContent>
        <hr className="hr-grey-6 my-0" />
        <DialogActions className="d-flex flex-column justify-content-start px-4 py-3">
          <div className="d-flex justify-content-between w-100">
            <button
              onClick={closeTimeModalHandler}
              type="button"
              className="button-grey py-2 px-5"
            >
              <p className="text-lightBlue">Cancel</p>
            </button>
            <button
              type="button"
              onClick={scheduleStatusFormik.handleSubmit}
              className="button-gradient py-2 px-5"
            >
              <p>Schedule</p>
            </button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default StatusBox;
export { StatusBoxSecondary };
