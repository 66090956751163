import { NavLink, Outlet } from "react-router-dom";
import { SidebarData } from "./SettingNavData";
import { ListItem, ListItemIcon, ListItemText, ToggleButton } from "@mui/material";
import { Visibility } from "@mui/icons-material";
import { Link } from "react-router-dom";

import "./Settings.scss";

import india from "../../assets/flag/india.svg";

const Settings = () => {
  const SideNavData = SidebarData;

  return (
    <div className="page container-fluid position-relative">
      <div className="row">
        <div className="col-lg-3">
          <div className="row mx-0 mt-1">
            <div className="col-12 px-0">
              <div className="d-flex flex-column mb-3">
                <h3 className="text-lightBlue fw-600 mb-2">Settings</h3>
              </div>

              <div className="settingNav px-2">
                {SideNavData.map((data, index) => (
                  <NavLink to={data.path}>
                    <ListItem 
                      key={data.title} 
                      className="list-item"
                    >
                      <div className="settingActive" />
                      <ListItemIcon className="mx-3 list-icon">
                        <img src={data.image} />
                      </ListItemIcon>
                      <ListItemText primary={data.title} className="list-text" />
                    </ListItem>
                  </NavLink>
                ))} 
              </div>
            </div>
          </div>
        </div>
          <div className="col-lg-9">
            <div className="d-flex justify-content-between mb-3 border-grey-5 rounded-8 bg-black-15 p-1 px-4">
              <div className="d-flex align-items-center">
                <h5 className="text-blue-gradient fw-600">Kisna Diamond Jewellery</h5>
              </div>
              <div className="d-flex align-items-center">
                <ToggleButton>
                  <img src={india} className="rounded-full mr-2" width={30} height={20}/>
                  <small className="text-lightBlue mr-2"><Link to="https://kisna.com" className="text-lightBlue hover:text-lightBlue underline-0"> www.kisna.com</Link></small>
                  <Visibility className="text-lightBlue mr-2" fontSize="14px" />
                </ToggleButton>
              </div>
            </div>
            <Outlet />
          </div>
      </div>
    </div>
  );
};

export default Settings;