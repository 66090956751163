import { useCallback, useEffect, useMemo, useReducer, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  OutlinedInput,
  Paper,
  Popover,
  Radio,
  RadioGroup,
  SwipeableDrawer,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Tabs,
  Tooltip,
} from "@mui/material";

import TabPanel from "../../../components/TabPanel/TabPanel";
import ViewTutorial from "../../../components/ViewTutorial/ViewTutorial";
import ViewLogsDrawer from "../../../components/ViewLogsDrawer/ViewLogsDrawer";
import ExportDialog from "../../../components/ExportDialog/ExportDialog";
import { TableSearchSecondary } from "../../../components/TableSearch/TableSearch";

import "../../Products/AllProducts/AllProducts.scss";

import sort from "../../../assets/icons/sort.svg";
import customers from "../../../assets/icons/sidenav/customers.svg";

import {
  useDeleteSubjectMutation,
  useDownloadExcelMutation,
  useEditSubjectBulkMutation,
  useGetEnquiryListQuery,
  useGetNewsletterDonwloadMutation,
  useGetNewsletterDownloadMutation,
  useGetNewsletterListQuery,
  useGetSubjectListQuery,
  useGetAppointmentsQuery,
} from "../../../features/user/customer/enquiries/enquiriesApiSlice";
import NoDataFound from "../../../components/NoDataFound/NoDataFound";
import { Link, useSearchParams } from "react-router-dom";
import * as Xlsx from "xlsx";
import TableLoader from "../../../components/Loader/TableLoader";
import TableEditStatusButton from "../../../components/TableEditStatusButton/TableEditStatusButton";
import TableMassActionButton from "../../../components/TableMassActionButton/TableMassActionButton";
import { EnhancedTableHead } from "../../../components/TableDependencies/TableDependencies";
import {
  Close,
  Delete,
  EmailOutlined,
  KeyboardArrowLeftOutlined,
  NavigateBefore,
  Reply,
  VisibilityOutlined,
  WbAuto,
} from "@mui/icons-material";
import moment from "moment";
import AsyncImage from "../../../components/AsyncImage/AsyncImage";
import * as Yup from "yup";
import { showError, showSuccess } from "../../../features/snackbar/snackbarAction";
import { useDispatch } from "react-redux";
import DeleteIconButton from "../../../components/DeleteIconButton/DeleteIconButton";
import AlertDialog from "../../../components/AlertDialog/AlertDialog";
import ExportEnquiryDialog from "../../../components/ExportDialog/ExportEnquiryDialog";

const headCellsNews = [
  {
    id: "createdAt",
    numeric: false,
    disablePadding: false,
    label: "Created On",
  },
  {
    id: "email",
    numeric: false,
    disablePadding: true,
    label: "Email",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: true,
    label: "Action",
  },
];

const headCells = [
  {
    id: "createdAt",
    numeric: false,
    disablePadding: false,
    label: "Created On",
  },
  {
    id: "reviews",
    numeric: false,
    disablePadding: true,
    label: "Product",
  },
  {
    id: "customer",
    numeric: false,
    disablePadding: false,
    label: "Customer",
  },
  {
    id: "subject",
    numeric: false,
    disablePadding: false,
    label: "Subject",
  },
  {
    id: "message",
    numeric: false,
    disablePadding: false,
    label: "Message",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: true,
    label: "Action",
  },
];

const initialQueries = {
  search: "",
  tabIndex: 0,
  status: ["pending", "approved", "disapproved"],
  createdAt: "-1",
  pageNo: 1,
  pageSize: 10,
  type: "product",
};

const queriesReducer = (state, action) => {
  switch (action.type) {
    case "SET_TAB_INDEX":
      return {
        ...state,
        pageNo: 1,
        status: action.status,
        tabIndex: action.tabIndex,
      };
    case "SET_TYPE":
      return {
        ...state,
        type: action.tabType,
      };
    case "SET_KEY_VAL":
      return {
        ...state,
        ...action.val,
      };

    case "SET_SEARCH":
      return {
        ...state,
        pageNo: 1,
        search: action.search,
      };

    case "SET_PAGE_SIZE":
      return {
        ...state,
        pageNo: 1,
        pageSize: +action.pageSize,
      };

    case "SET_PAGE_NO":
      return {
        ...state,
        pageNo: action.pageNo + 1,
      };

    case "SET_CREATED_AT":
      return {
        ...state,
        createdAt: action.createdAt,
      };

    case "SET_DEFAULT":
      return initialQueries;

    default:
      return state;
  }
};

const UserEnquiries = () => {
  const [queries, dispatchQueries] = useReducer(queriesReducer, initialQueries);
  const [searchParams, setSearchParams] = useSearchParams();
  const [firstTime, setFirstTime] = useState(true);

  const queryString = useMemo(() => JSON.stringify(queries), [queries]);

  useEffect(() => {
    if (firstTime) {
      setFirstTime(false);
      const filter = searchParams.get("filter");
      if (filter) {
        const filterObj = JSON.parse(filter);
        if (filterObj) {
          dispatchQueries({ type: "SET_KEY_VAL", val: filterObj });
        }
      }
    } else {
      setSearchParams({ filter: queryString });
    }
  }, [queryString, searchParams, setSearchParams, firstTime]);

  const [selected, setSelected] = useState([]);
  const [dialogueId, setDialogueId] = useState("");
  const clearSelected = () => setSelected([]);
  const [state, setState] = useState({});
  const onSortChange = (e) =>
    dispatchQueries({ type: "SET_CREATED_AT", createdAt: e.target.value });

  const {
    data: enquiriesData, //
    isLoading: enquiriesIsLoading,
  } = useGetEnquiryListQuery(
    {
      ...{ ...queries, type: queries.type.replace("-", "&") },
    },
    { skip: queries?.type === "newsletter" || queries?.type === "appointment" }
  );
  const {
    data: appointmentData, //
    isLoading: appointmentLoading,
  } = useGetAppointmentsQuery(
    {
      search: queries?.search,
      createdAt: queries?.createdAt,
      pageNo: queries?.pageNo,
      pageSize: queries?.pageSize,
    },
    { skip: queries?.type !== "appointment" }
  );
  const {
    data: newsLetterData, //
    isLoading: newsLetterLoading,
  } = useGetNewsletterListQuery(
    {
      search: queries?.search,
      createdAt: queries?.createdAt,
      pageNo: queries?.pageNo,
      pageSize: queries?.pageSize,
    },
    { skip: queries?.type !== "newsletter" }
  );

  const allReviews = useMemo(
    () => enquiriesData?.data?.data ?? [],
    [enquiriesData?.data?.data]
  );

  const reviewsCount = useMemo(
    () => enquiriesData?.data?.totalCount ?? 0,
    [enquiriesData?.data?.totalCount]
  );

  const allNewsLatter = useMemo(
    () => newsLetterData?.data?.data ?? [],
    [newsLetterData?.data?.data]
  );
  const allAppointments = useMemo(
    () => appointmentData?.data?.data ?? [],
    [appointmentData?.data?.data]
  );

  const handleChange = (event, newValue) => {
    // setValue(newValue);
    dispatchQueries({ type: "SET_TYPE", tabType: newValue });
  };

  // ? POPOVERS STARTS HERE

  // * FLAG POPOVERS STARTS
  // const [anchorFlagEl, setAnchorFlagEl] = useState(null);
  // const openFlag = Boolean(anchorFlagEl);
  // const idFlag = openFlag ? "simple-popover" : undefined;
  // * FLAG POPOVERS ENDS

  // * SORT POPOVERS STARTS
  const [anchorSortEl, setAnchorSortEl] = useState(null);

  const handleSortClick = (event) => {
    setAnchorSortEl(event.currentTarget);
  };

  const handleSortClose = () => {
    setAnchorSortEl(null);
  };

  const openSort = Boolean(anchorSortEl);
  // const idSort = openSort ? "simple-popover" : undefined;

  const handleOpenDrawer = () => {
    setState((prev) => ({ ...prev, right: true }));
  };

  const handleCloseDrawer = () => {
    setState((prev) => ({ ...prev, right: false }));
  };

  const onBulkActionSelected = (action) => {
    switch (action) {
      case "Approve":
        break;
      case "Disapprove":
        break;
      case "Archive":
        break;
      case "Delete":
        break;
      case "Un-Archive":
        break;

      default:
        break;
    }
  };

  const [editBulkSubject] = useEditSubjectBulkMutation();
  const [deleteSubject] = useDeleteSubjectMutation();
  const dispatch = useDispatch();

  const { data: subjectData, isFetching: subjectFetching } = useGetSubjectListQuery();

  const dSubjectList = useMemo(() => Array.from(subjectData?.data?.data || []), [subjectData]);

  const [subjectList, setSubjectList] = useState([]);

  useEffect(() => {
    setSubjectList(dSubjectList);
  }, [dSubjectList]);

  const [addCompData, setAddCompData] = useState([]);
  const [addCompDataContact, setAddCompDataContact] = useState([]);
  const [addCompDataFq, setAddCompDataFq] = useState([]);

  const addSubjectData = useCallback((type) => {
    if (type === "product") {
      setAddCompData((prev) => [
        ...prev,
        {
          type: "product",
          subject: "",
          title: "",
        },
      ]);
    }
    if (type === "contact") {
      setAddCompDataContact((prev) => [
        ...prev,
        {
          type: "contact",
          subject: "",
          title: "",
        },
      ]);
    }
    if (type === "help&faq") {
      setAddCompDataFq((prev) => [
        ...prev,
        {
          type: "help&faq",
          subject: "",
          title: "",
        },
      ]);
    }
  }, []);

  const cancelSubjectData = useCallback((type) => {
    return (index) => {
      if (type === "product") {
        setAddCompData((prev) => prev.filter((e, i) => i !== index));
      }
      if (type === "contact") {
        setAddCompDataContact((prev) => prev.filter((e, i) => i !== index));
      }
      if (type === "help&faq") {
        setAddCompDataFq((prev) => prev.filter((e, i) => i !== index));
      }
    };
  }, []);

  const editSubjectData = useCallback((type, index) => {
    return (value) => {
      if (type === "product") {
        console.log(value, index, type, "editdata");
        setAddCompData((prev) => {
          const temp = prev.map((e, i) => (i === index ? { ...e, subject: value } : e));
          return temp;
        });
      }
      if (type === "contact") {
        setAddCompDataContact((prev) =>
          prev.map((e, i) => (i === index ? { ...e, subject: value } : e))
        );
      }
      if (type === "help&faq") {
        setAddCompDataFq((prev) =>
          prev.map((e, i) => (i === index ? { ...e, subject: value } : e))
        );
      }
    };
  }, []);

  const editSubjectDataPrev = useCallback((type, index) => {
    return (value) => {
      setSubjectList((prev) => {
        const temp = prev.map((e) =>
          e._id === index && type === e.type ? { ...e, subject: value } : e
        );
        return temp;
      });
    };
  }, []);

  const handleSubmitSave = useCallback(() => {
    try {
      const payload = [];

      const prevOptions = subjectList.map((e) => ({
        title: e.subject,
        subject: e.subject,
        type: e.type,
        id: e._id,
      }));
      payload.push(...prevOptions);
      const addOptions = addCompData.map((e) => ({
        title: e.subject,
        subject: e.subject,
        type: "product",
      }));
      const addOptionsC = addCompDataContact.map((e) => ({
        title: e.subject,
        subject: e.subject,
        type: "contact",
      }));
      const addOptionsFq = addCompDataFq.map((e) => ({
        title: e.subject,
        subject: e.subject,
        type: "help&faq",
      }));
      payload.push(...[...addOptions, ...addOptionsC, ...addOptionsFq]);

      if (payload.length <= 0) {
        dispatch(showError({ message: "Cannot save empty" }));
        return;
      }
      editBulkSubject(payload);
      dispatch(showSuccess({ message: "Subject saved successfully" }));
      setAddCompData([]);
      setAddCompDataContact([]);
      setAddCompDataFq([]);
    } catch (error) {
      dispatch(showError({ message: "Something went wrong" }));
    }
  }, [addCompData, addCompDataFq, addCompDataContact, subjectList, editBulkSubject, dispatch]);

  const [deleteId, setDeleteId] = useState("");

  const handleDeleteSubmit = () => {
    deleteSubject({ id: deleteId });
    toglleDelete();
  };

  const toglleDelete = (id = "") => {
    setDeleteId(id);
  };

  return (
    <div className="container-fluid page">
      <div className="row justify-content-between align-items-center">
        <h4 className="page-heading w-auto ps-0">Enquiries</h4>
        <div className="d-flex align-items-center w-auto pe-0">
          {/* <ViewTutorial /> */}
          <ViewLogsDrawer
            headingName={"User Enquiries"}
            icon={customers}
            module="customer"
          />
          <ExportEnquiryDialog dialogName={"Enquiries"} />
          <button
            className="button-gradient py-2 px-4"
            onClick={handleOpenDrawer}>
            <p>Subject Settings</p>
          </button>
        </div>
      </div>

      <div className="row mt-4">
        <Paper
          sx={{ width: "100%", mb: 2, mt: 0, p: 0 }}
          className="border-grey-5 bg-black-15">
          <Box
            sx={{ width: "100%" }}
            className="d-flex justify-content-between tabs-header-box">
            <Tabs
              value={queries.type}
              onChange={handleChange}
              aria-label="scrollable force tabs example"
              className="tabs">
              <Tab
                label="Product"
                className="tabs-head"
                value={"product"}
              />
              <Tab
                label="Help & FAQ"
                className="tabs-head"
                value={"help-faq"}
              />
              <Tab
                label="Contact"
                className="tabs-head"
                value={"contact"}
              />
              <Tab
                label="Newsletter"
                className="tabs-head"
                value={"newsletter"}
              />
              <Tab
                label="Appointment"
                className="tabs-head"
                value={"appointment"}
              />
              <Tab
                label="Return/Exchange"
                className="tabs-head"
                value={"return"}
              />
              <Tab
                label="Cancel Requests"
                className="tabs-head"
                value={"cancelled"}
              />
              <Tab
                label="Lead"
                className="tabs-head"
                value={"lead"}
              />
            </Tabs>
          </Box>
          <TabPanel
            value={0}
            index={0}>
            <div className="d-flex align-items-center mt-3 mb-3 px-2 justify-content-between">
              <TableSearchSecondary
                value={queries.search}
                onSearchValueChange={(v) => dispatchQueries({ type: "SET_SEARCH", search: v })}
                onChange={(v) => dispatchQueries({ type: "SET_SEARCH", search: v })}
              />

              <p className="text-grey-6 ms-2 me-1">|</p>

              <Button
                variant="text"
                onClick={handleSortClick}
                endIcon={
                  <img
                    src={sort}
                    alt="icon"
                    width={15}
                  />
                }>
                <span className="text-grey-6">Sort</span>
              </Button>
              <Popover
                className="columns"
                open={openSort}
                anchorEl={anchorSortEl}
                onClose={handleSortClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}>
                <FormControl className="px-2 py-1">
                  <RadioGroup
                    name="controlled-radio-buttons-group"
                    onClick={handleSortClose}>
                    <FormControlLabel
                      value="1"
                      label="Oldest to Newest"
                      onChange={onSortChange}
                      control={
                        <Radio
                          size="small"
                          checked={queries.createdAt === "1"}
                        />
                      }
                    />
                    <FormControlLabel
                      value="-1"
                      label="Newest to Oldest"
                      onChange={onSortChange}
                      control={
                        <Radio
                          size="small"
                          checked={queries.createdAt === "-1"}
                        />
                      }
                    />
                  </RadioGroup>
                </FormControl>
              </Popover>
            </div>
            {queries?.type === "newsletter" ? (
              newsLetterLoading ? (
                <span className="d-flex justify-content-center m-3">
                  <TableLoader />
                </span>
              ) : allNewsLatter?.length > 0 ? (
                <>
                  {selected.length > 0 && (
                    <div className="d-flex align-items-center px-2 mb-3">
                      <button className="button-grey py-2 px-3">
                        <small className="text-lightBlue">
                          {selected.length} reviews are selected{" "}
                          <span
                            className="text-blue-2 c-pointer"
                            onClick={clearSelected}>
                            (Clear Selection)
                          </span>
                        </small>
                      </button>
                      {queries.tabIndex !== 4 && (
                        <TableEditStatusButton
                          onSelect={onBulkActionSelected}
                          defaultValue={["Approve", "Disapprove"]}
                          headingName="Edit Status"
                        />
                      )}
                      {queries.tabIndex === 4 ? (
                        <TableMassActionButton
                          onSelect={onBulkActionSelected}
                          defaultValue={["Un-Archive", "Delete"]}
                          headingName="Mass Action"
                        />
                      ) : (
                        <button
                          className="button-grey py-2 px-3 ms-2"
                          variant="contained"
                          onClick={() => onBulkActionSelected("Archive")}>
                          <small className="text-lightBlue">Archive</small>
                        </button>
                      )}
                    </div>
                  )}
                  <TableContainer>
                    <Table size="medium">
                      <EnhancedTableHead
                        onSelectAllClick={(e) =>
                          setSelected(e.target.checked ? [...allReviews] : [])
                        }
                        numSelected={selected.length}
                        rowCount={allNewsLatter?.length}
                        headCells={headCellsNews}
                        orderBy={"groupName"}
                        onRequestSort={Date}
                        order={"asc"}
                      />
                      <TableBody>
                        {[...allNewsLatter].map((row) => {
                          const {
                            _id: rid,
                            email = "",
                            createdAt = new Date().toISOString(),
                          } = row ?? {};
                          const isItemSelected = selected.includes(row);
                          const today = moment();
                          const diffInDays = today.diff(moment(new Date(createdAt)), "days");
                          const formatedDay =
                            diffInDays === 0
                              ? moment(new Date(createdAt)).format("[Today]")
                              : diffInDays === 1
                              ? moment(new Date(createdAt)).format("[Yesterday]")
                              : moment(new Date(createdAt)).format("MMMM D, YYYY");
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={rid}
                              selected={isItemSelected}
                              className="table-rows">
                              <TableCell padding="checkbox">
                                <Checkbox
                                  checked={isItemSelected}
                                  onClick={(e) =>
                                    setSelected((sel) =>
                                      e.target.checked
                                        ? sel.concat(row)
                                        : sel.filter((s) => !Object.is(s, row))
                                    )
                                  }
                                  size="small"
                                  style={{ color: "#5C6D8E" }}
                                />
                              </TableCell>

                              <TableCell>
                                <p className="text-lightBlue rounded-circle fw-500 mt-2 fw-bold">
                                  {formatedDay}
                                </p>
                                <p className="text-lightBlue rounded-circle fw-500 mt-2 fw-bold">
                                  {moment(createdAt).format("hh:mm A")}
                                </p>
                              </TableCell>
                              <TableCell>
                                <p className="text-lightBlue">{email}</p>
                              </TableCell>
                              <TableCell style={{ width: 160, padding: 0 }}>
                                <div
                                  className="d-flex align-items-center"
                                  onClick={clearSelected}>
                                  <Tooltip
                                    title="Details"
                                    placement="top">
                                    <div
                                      className="table-edit-icon c-pointer rounded-4 p-2"
                                      onClick={() => setDialogueId(rid)}>
                                      <VisibilityOutlined
                                        // onClick={() => setViewingReview(row)}
                                        sx={{
                                          fontSize: 18,
                                          color: "#5c6d8e",
                                        }}
                                      />
                                    </div>
                                  </Tooltip>
                                  <Tooltip
                                    title="Send Email"
                                    placement="top">
                                    <div className="table-edit-icon c-pointer rounded-4 p-2">
                                      <EmailOutlined
                                        // onClick={() => setViewingReview(row)}
                                        sx={{
                                          fontSize: 18,
                                          color: "#5c6d8e",
                                        }}
                                      />
                                    </div>
                                  </Tooltip>
                                  <Tooltip
                                    title="Reply"
                                    placement="top">
                                    <div className="table-edit-icon c-pointer rounded-4 p-2">
                                      <Reply
                                        // onClick={() => setViewingReview(row)}
                                        sx={{
                                          fontSize: 18,
                                          color: "#5c6d8e",
                                        }}
                                      />
                                    </div>
                                  </Tooltip>
                                </div>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[10, 20, 30]}
                    component="div"
                    count={reviewsCount}
                    rowsPerPage={queries.pageSize}
                    page={queries.pageNo - 1}
                    onPageChange={(_, page) =>
                      dispatchQueries({ type: "SET_PAGE_NO", pageNo: page })
                    }
                    onRowsPerPageChange={(e) =>
                      dispatchQueries({
                        type: "SET_PAGE_SIZE",
                        pageSize: e.target.value,
                      })
                    }
                    className="table-pagination"
                  />
                </>
              ) : (
                <span className="d-flex justify-content-center m-3">
                  <NoDataFound />
                </span>
              )
            ) : enquiriesIsLoading ? (
              <span className="d-flex justify-content-center m-3">
                <TableLoader />
              </span>
            ) : allReviews?.length > 0 ? (
              <>
                {queries.type === "appointment" ? (
                  <>
                    {/* {selected.length > 0 && (
                      <div className='d-flex align-items-center px-2 mb-3'>
                        <button className='button-grey py-2 px-3'>
                          <small className='text-lightBlue'>
                            {selected.length} reviews are selected{' '}
                            <span
                              className='text-blue-2 c-pointer'
                              onClick={clearSelected}
                            >
                              (Clear Selection)
                            </span>
                          </small>
                        </button>
                        {queries.tabIndex !== 4 && (
                          <TableEditStatusButton
                            onSelect={onBulkActionSelected}
                            defaultValue={['Approve', 'Disapprove']}
                            headingName='Edit Status'
                          />
                        )}
                        {queries.tabIndex === 4 ? (
                          <TableMassActionButton
                            onSelect={onBulkActionSelected}
                            defaultValue={['Un-Archive', 'Delete']}
                            headingName='Mass Action'
                          />
                        ) : (
                          <button
                            className='button-grey py-2 px-3 ms-2'
                            variant='contained'
                            onClick={() => onBulkActionSelected('Archive')}
                          >
                            <small className='text-lightBlue'>Archive</small>
                          </button>
                        )}
                      </div>
                    )} */}
                    <TableContainer>
                      <Table size="medium">
                        <EnhancedTableHead
                          onSelectAllClick={(e) =>
                            setSelected(e.target.checked ? [...allAppointments] : [])
                          }
                          numSelected={selected.length}
                          rowCount={allAppointments?.length}
                          headCells={headCells.filter((e) =>
                            queries.type !== "product" && e.label === "Product" ? false : true
                          )}
                          orderBy={"groupName"}
                          onRequestSort={Date}
                          order={"asc"}
                        />
                        <TableBody>
                          {[...allAppointments].map((row) => {
                            const {
                              _id: rid = "",
                              appointId: enqId,
                              message: description = "",
                              email = "",
                              phone = "",
                              fullname: customerFrotnendName = "",
                              subject = "",
                              countryCode = {},
                              createdAt = new Date().toISOString(),
                            } = row ?? {};
                            const isItemSelected = selected.includes(row);
                            const today = moment();
                            const diffInDays = today.diff(moment(new Date(createdAt)), "days");
                            const formatedDate =
                              diffInDays === 0
                                ? moment(new Date(createdAt)).format("[Today], [at] hh:mm A")
                                : diffInDays === 1
                                ? moment(new Date(createdAt)).format(
                                    "[Yesterday], [at] hh:mm A"
                                  )
                                : moment(new Date(createdAt)).format(
                                    "MMMM D, YYYY, [at] hh:mm A"
                                  );
                            const formatedDay = moment(new Date(createdAt)).format(
                              "DD-MM-yyyy"
                            );
                            return (
                              <TableRow
                                hover
                                role="checkbox"
                                aria-checked={isItemSelected}
                                tabIndex={-1}
                                key={rid}
                                selected={isItemSelected}
                                className="table-rows">
                                <TableCell padding="checkbox">
                                  <Checkbox
                                    checked={isItemSelected}
                                    onClick={(e) =>
                                      setSelected((sel) =>
                                        e.target.checked
                                          ? sel.concat(row)
                                          : sel.filter((s) => !Object.is(s, row))
                                      )
                                    }
                                    size="small"
                                    style={{ color: "#5C6D8E" }}
                                  />
                                </TableCell>

                                <TableCell>
                                  <p className="text-blue-2 fw-600">
                                    {enqId ? `#${enqId}` : ""}
                                  </p>
                                  <p className="text-lightBlue rounded-circle fw-500 mt-2 fw-bold">
                                    {formatedDay}
                                    {" at "}
                                    {moment(createdAt).format("hh:mm A")}
                                  </p>
                                </TableCell>
                                <TableCell style={{ width: 200 }}>
                                  <div className="d-flex flex-column">
                                    <p className="text-lightBlue fw-500">
                                      {customerFrotnendName}
                                    </p>

                                    <div className="d-flex align-items-center mt-2">
                                      <small className="text-grey-6 me-2">{email || "-"}</small>
                                    </div>

                                    <div className="d-flex align-items-center mt-2">
                                      <small className="text-grey-6 me-2">
                                        {` ${countryCode?.countryCode || "+91"}-`}
                                        {phone || "8780808080"}
                                      </small>
                                    </div>
                                  </div>
                                </TableCell>
                                <TableCell>
                                  <p className="text-lightBlue">{subject?.subject || "-"}</p>
                                </TableCell>
                                <TableCell>
                                  <p className="text-lightBlue">{description}</p>
                                </TableCell>
                                <TableCell style={{ width: 160, padding: 0 }}>
                                  <div
                                    className="d-flex align-items-center"
                                    onClick={clearSelected}>
                                    <Tooltip
                                      title="Details"
                                      placement="top">
                                      <div
                                        className="table-edit-icon c-pointer rounded-4 p-2"
                                        onClick={() => setDialogueId(rid)}>
                                        <VisibilityOutlined
                                          sx={{
                                            fontSize: 18,
                                            color: "#5c6d8e",
                                          }}
                                        />
                                      </div>
                                    </Tooltip>
                                    <Tooltip
                                      title="Send Email"
                                      placement="top">
                                      <div className="table-edit-icon c-pointer rounded-4 p-2">
                                        <EmailOutlined
                                          sx={{
                                            fontSize: 18,
                                            color: "#5c6d8e",
                                          }}
                                        />
                                      </div>
                                    </Tooltip>
                                    <Tooltip
                                      title="Reply"
                                      placement="top">
                                      <div className="table-edit-icon c-pointer rounded-4 p-2">
                                        <Reply
                                          sx={{
                                            fontSize: 18,
                                            color: "#5c6d8e",
                                          }}
                                        />
                                      </div>
                                    </Tooltip>
                                    <Dialog
                                      open={rid === dialogueId}
                                      fullWidth>
                                      <DialogTitle>
                                        <h5 className="d-flex align-items-center">
                                          <span className="text-lightBlue fw-500">
                                            {`${
                                              queries.type === "appointment"
                                                ? "Appointment"
                                                : "Enquiry"
                                            } ID: #${enqId}`}
                                            <span
                                              className="text-grey-6"
                                              style={{
                                                marginTop: "0.3rem",
                                                display: "block",
                                                fontSize: "0.8rem",
                                                fontWeight: "400",
                                              }}>
                                              {formatedDate}
                                            </span>
                                          </span>
                                          <IconButton
                                            onClick={() => setDialogueId("")}
                                            style={{
                                              marginLeft: "auto",
                                            }}>
                                            <Close />
                                          </IconButton>
                                        </h5>
                                      </DialogTitle>
                                      <Divider />
                                      <DialogContent>
                                        <p className="text-grey-6 mb-1">Subject</p>
                                        <p className="text-lightBlue mt-1">
                                          {subject?.subject}
                                        </p>
                                        <p className="text-grey-6 mt-2 mb-1">Message</p>
                                        <p className="text-lightBlue mt-1">{description}</p>

                                        <div
                                          style={{ width: "100%", gap: "3rem" }}
                                          className="d-flex item-center">
                                          <div>
                                            <p className="text-grey-6 mt-2 mb-1">
                                              Customer Name
                                            </p>
                                            <p className="text-lightBlue mt-1">
                                              {customerFrotnendName}
                                            </p>
                                          </div>
                                          <div>
                                            <p className="text-grey-6 mt-2 mb-1">Mobile No.</p>
                                            <p className="text-lightBlue mt-1">
                                              {countryCode?.countryCode || "+91"}-{phone}
                                            </p>
                                          </div>
                                          <div>
                                            <p className="text-grey-6 mt-2 mb-1">Email ID</p>
                                            <p className="text-lightBlue mt-1">{email}</p>
                                          </div>
                                        </div>
                                      </DialogContent>
                                      <Divider />
                                      <DialogActions>
                                        <button
                                          style={{
                                            marginRight: "auto",
                                            marginLeft: "1rem",
                                            marginBlock: "0.5rem",
                                          }}
                                          className="button-grey-outline py-2 px-4"
                                          // onClick={}
                                        >
                                          <Reply
                                            sx={{
                                              fontSize: 18,
                                              cursor: "pointer",
                                              "& :hover": { color: "green" },
                                            }}
                                          />
                                          <p className="ms-2">Reply</p>
                                        </button>
                                        <button
                                          className="button-lightBlue-outline py-2 px-4"
                                          style={{
                                            marginRight: "1rem",
                                          }}>
                                          <EmailOutlined
                                            sx={{
                                              fontSize: 18,
                                              cursor: "pointer",
                                              "& :hover": { color: "green" },
                                            }}
                                          />
                                          <p className="ms-2">Send Email</p>
                                        </button>
                                      </DialogActions>
                                    </Dialog>
                                  </div>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    {/* <TablePagination
                      rowsPerPageOptions={[10, 20, 30]}
                      component='div'
                      count={reviewsCount}
                      rowsPerPage={queries.pageSize}
                      page={queries.pageNo - 1}
                      onPageChange={(_, page) =>
                        dispatchQueries({ type: 'SET_PAGE_NO', pageNo: page })
                      }
                      onRowsPerPageChange={(e) =>
                        dispatchQueries({
                          type: 'SET_PAGE_SIZE',
                          pageSize: e.target.value,
                        })
                      }
                      className='table-pagination'
                    /> */}
                  </>
                ) : (
                  <>
                    {selected.length > 0 && (
                      <div className="d-flex align-items-center px-2 mb-3">
                        <button className="button-grey py-2 px-3">
                          <small className="text-lightBlue">
                            {selected.length} reviews are selected{" "}
                            <span
                              className="text-blue-2 c-pointer"
                              onClick={clearSelected}>
                              (Clear Selection)
                            </span>
                          </small>
                        </button>
                        {queries.tabIndex !== 4 && (
                          <TableEditStatusButton
                            onSelect={onBulkActionSelected}
                            defaultValue={["Approve", "Disapprove"]}
                            headingName="Edit Status"
                          />
                        )}
                        {queries.tabIndex === 4 ? (
                          <TableMassActionButton
                            onSelect={onBulkActionSelected}
                            defaultValue={["Un-Archive", "Delete"]}
                            headingName="Mass Action"
                          />
                        ) : (
                          <button
                            className="button-grey py-2 px-3 ms-2"
                            variant="contained"
                            onClick={() => onBulkActionSelected("Archive")}>
                            <small className="text-lightBlue">Archive</small>
                          </button>
                        )}
                      </div>
                    )}
                    <TableContainer>
                      <Table size="medium">
                        <EnhancedTableHead
                          onSelectAllClick={(e) =>
                            setSelected(e.target.checked ? [...allReviews] : [])
                          }
                          numSelected={selected.length}
                          rowCount={allReviews?.length}
                          headCells={headCells.filter((e) =>
                            queries.type !== "product" && e.label === "Product" ? false : true
                          )}
                          orderBy={"groupName"}
                          onRequestSort={Date}
                          order={"asc"}
                        />
                        <TableBody>
                          {[...allReviews].map((row) => {
                            const {
                              _id: rid = "",
                              enqId,
                              message: description = "",
                              product,
                              email = "",
                              phone = "",
                              firstName: customerFrotnendName = "",
                              subject = "",
                              countryCode = {},
                              createdAt = new Date().toISOString(),
                            } = row ?? {};
                            const isItemSelected = selected.includes(row);
                            const today = moment();
                            const diffInDays = today.diff(moment(new Date(createdAt)), "days");
                            const formatedDate =
                              diffInDays === 0
                                ? moment(new Date(createdAt)).format("[Today], [at] hh:mm A")
                                : diffInDays === 1
                                ? moment(new Date(createdAt)).format(
                                    "[Yesterday], [at] hh:mm A"
                                  )
                                : moment(new Date(createdAt)).format(
                                    "MMMM D, YYYY, [at] hh:mm A"
                                  );
                            const formatedDay = moment(new Date(createdAt)).format(
                              "DD-MM-yyyy"
                            );
                            return (
                              <TableRow
                                hover
                                role="checkbox"
                                aria-checked={isItemSelected}
                                tabIndex={-1}
                                key={rid}
                                selected={isItemSelected}
                                className="table-rows">
                                <TableCell padding="checkbox">
                                  <Checkbox
                                    checked={isItemSelected}
                                    onClick={(e) =>
                                      setSelected((sel) =>
                                        e.target.checked
                                          ? sel.concat(row)
                                          : sel.filter((s) => !Object.is(s, row))
                                      )
                                    }
                                    size="small"
                                    style={{ color: "#5C6D8E" }}
                                  />
                                </TableCell>

                                <TableCell>
                                  <p className="text-blue-2 fw-600">
                                    {enqId ? `#${enqId}` : ""}
                                  </p>
                                  <p className="text-lightBlue rounded-circle fw-500 mt-2 fw-bold">
                                    {formatedDay}
                                    {" at "}
                                    {moment(createdAt).format("hh:mm A")}
                                  </p>
                                </TableCell>
                                {queries.type === "product" && (
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    padding="none">
                                    <Link
                                      to={`/products/allProducts/editProduct/${product?._id}`}
                                      className="text-decoration-none">
                                      <div className="d-flex align-items-center c-pointer py-3">
                                        <AsyncImage
                                          src={
                                            product?.mediaUrl?.find((mu) => mu.isDefault)
                                              ?.image ?? ""
                                          }
                                          placeholder={""}
                                          alt="product"
                                          className="me-2 rounded-4"
                                          height={45}
                                          width={45}
                                          style={{ objectFit: "cover" }}
                                        />
                                        <div>
                                          <p className="text-lightBlue fw-600">
                                            {product?.title}
                                          </p>
                                          <small className="mt-2 text-grey-6">
                                            SKU: {product?.inventory?.skuId}
                                          </small>
                                        </div>
                                      </div>
                                    </Link>
                                  </TableCell>
                                )}
                                <TableCell style={{ width: 200 }}>
                                  <div className="d-flex flex-column">
                                    <p className="text-lightBlue fw-500">
                                      {customerFrotnendName}
                                    </p>

                                    <div className="d-flex align-items-center mt-2">
                                      <small className="text-grey-6 me-2">
                                        {email || ""}
                                      </small>
                                    </div>

                                    <div className="d-flex align-items-center mt-2">
                                      <small className="text-grey-6 me-2">
                                        {` ${countryCode?.countryCode || "+91"}-`}
                                        {phone || "8780808080"}
                                      </small>
                                    </div>
                                  </div>
                                </TableCell>
                                <TableCell>
                                  <p className="text-lightBlue">{subject?.subject || "-"}</p>
                                </TableCell>
                                <TableCell>
                                  <p className="text-lightBlue">{description}</p>
                                </TableCell>
                                <TableCell style={{ width: 160, padding: 0 }}>
                                  <div
                                    className="d-flex align-items-center"
                                    onClick={clearSelected}>
                                    <Tooltip
                                      title="Details"
                                      placement="top">
                                      <div
                                        className="table-edit-icon c-pointer rounded-4 p-2"
                                        onClick={() => setDialogueId(rid)}>
                                        <VisibilityOutlined
                                          sx={{
                                            fontSize: 18,
                                            color: "#5c6d8e",
                                          }}
                                        />
                                      </div>
                                    </Tooltip>
                                    <Tooltip
                                      title="Send Email"
                                      placement="top">
                                      <div className="table-edit-icon c-pointer rounded-4 p-2">
                                        <EmailOutlined
                                          sx={{
                                            fontSize: 18,
                                            color: "#5c6d8e",
                                          }}
                                        />
                                      </div>
                                    </Tooltip>
                                    <Tooltip
                                      title="Reply"
                                      placement="top">
                                      <div className="table-edit-icon c-pointer rounded-4 p-2">
                                        <Reply
                                          sx={{
                                            fontSize: 18,
                                            color: "#5c6d8e",
                                          }}
                                        />
                                      </div>
                                    </Tooltip>
                                    <Dialog
                                      open={rid === dialogueId}
                                      fullWidth>
                                      <DialogTitle>
                                        <h5 className="d-flex align-items-center">
                                          <span className="text-lightBlue fw-500">
                                            Enquiry ID: #{enqId}
                                            <span
                                              className="text-grey-6"
                                              style={{
                                                marginTop: "0.3rem",
                                                display: "block",
                                                fontSize: "0.8rem",
                                                fontWeight: "400",
                                              }}>
                                              {formatedDate}
                                            </span>
                                          </span>
                                          <IconButton
                                            onClick={() => setDialogueId("")}
                                            style={{
                                              marginLeft: "auto",
                                            }}>
                                            <Close />
                                          </IconButton>
                                        </h5>
                                      </DialogTitle>
                                      <Divider />
                                      <DialogContent>
                                        <p className="text-grey-6 mb-1">Subject</p>
                                        <p className="text-lightBlue mt-1">
                                          {subject?.subject}
                                        </p>
                                        <p className="text-grey-6 mt-2 mb-1">Message</p>
                                        <p className="text-lightBlue mt-1">{description}</p>
                                        <p className="text-grey-6 mt-2 mb-1">Product</p>
                                        <p className="text-lightBlue mt-1">
                                          <Link
                                            to={`/products/allProducts/editProduct/${
                                              product?._id || ""
                                            }`}>
                                            {product?.title}
                                          </Link>
                                        </p>
                                        <div
                                          style={{ width: "100%", gap: "3rem" }}
                                          className="d-flex item-center">
                                          <div>
                                            <p className="text-grey-6 mt-2 mb-1">
                                              Customer Name
                                            </p>
                                            <p className="text-lightBlue mt-1">
                                              {customerFrotnendName}
                                            </p>
                                          </div>
                                          <div>
                                            <p className="text-grey-6 mt-2 mb-1">Mobile No.</p>
                                            <p className="text-lightBlue mt-1">
                                              {countryCode?.countryCode || "+91"}-{phone}
                                            </p>
                                          </div>
                                          <div>
                                            <p className="text-grey-6 mt-2 mb-1">Email ID</p>
                                            <p className="text-lightBlue mt-1">{email}</p>
                                          </div>
                                        </div>
                                      </DialogContent>
                                      <Divider />
                                      <DialogActions>
                                        <button
                                          style={{
                                            marginRight: "auto",
                                            marginLeft: "1rem",
                                            marginBlock: "0.5rem",
                                          }}
                                          className="button-grey-outline py-2 px-4"
                                          // onClick={}
                                        >
                                          <Reply
                                            sx={{
                                              fontSize: 18,
                                              cursor: "pointer",
                                              "& :hover": { color: "green" },
                                            }}
                                          />
                                          <p className="ms-2">Reply</p>
                                        </button>
                                        <button
                                          className="button-lightBlue-outline py-2 px-4"
                                          style={{
                                            marginRight: "1rem",
                                          }}>
                                          <EmailOutlined
                                            sx={{
                                              fontSize: 18,
                                              cursor: "pointer",
                                              "& :hover": { color: "green" },
                                            }}
                                          />
                                          <p className="ms-2">Send Email</p>
                                        </button>
                                      </DialogActions>
                                    </Dialog>
                                  </div>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={[10, 20, 30]}
                      component="div"
                      count={reviewsCount}
                      rowsPerPage={queries.pageSize}
                      page={queries.pageNo - 1}
                      onPageChange={(_, page) =>
                        dispatchQueries({ type: "SET_PAGE_NO", pageNo: page })
                      }
                      onRowsPerPageChange={(e) =>
                        dispatchQueries({
                          type: "SET_PAGE_SIZE",
                          pageSize: e.target.value,
                        })
                      }
                      className="table-pagination"
                    />
                  </>
                )}
              </>
            ) : (
              <span className="d-flex justify-content-center m-3">
                <NoDataFound />
              </span>
            )}
          </TabPanel>
        </Paper>
      </div>

      <AlertDialog
        onConfirm={() => handleDeleteSubmit()}
        onCancel={() => toglleDelete()}
        show={Boolean(deleteId)}
        title={`Delete Subject?`}
        primaryMessage={`This will Delete <span className='text-blue-2'>subject</span> permanently`}
        confirmText="Delete"
        isLoading={subjectFetching}
        // icon={<Delete />}
      />

      {/* <Dialog open={deleteId}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          Subject will not be reflected after delete.
        </DialogContent>
        <DialogActions>
          <Button onClick={() => toglleDelete()}>cancel</Button>
          <Button variant="contained" onClick={handleDeleteSubmit}>
            delete
          </Button>
        </DialogActions>
      </Dialog> */}

      {state.right && (
        <SwipeableDrawer
          anchor="right"
          open={state.right}
          onClose={handleCloseDrawer}
          onOpen={handleOpenDrawer}
          className="chatBox">
          <div style={{ width: "100%", minWidth: "500px" }}>
            <div className="d-flex align-items-center pt-3 px-3">
              <KeyboardArrowLeftOutlined
                sx={{ fontSize: 25, color: "#c8d8ff" }}
                onClick={handleCloseDrawer}
                className="c-pointer"
              />
              <div>
                <h5 className="text-lightBlue fw-500 ms-2">Subject Settings</h5>
              </div>
            </div>

            <div className="px-3">
              <hr className="hr-grey-6 mt-3 mb-3" />
            </div>
            <div
              className=""
              style={{
                padding: "20px",
                display: "flex",
                flexDirection: "column",
                gap: 20,
              }}>
              {["product", "contact", "help&faq"].map((e, i) => {
                return (
                  <div
                    key={i}
                    style={{
                      backgroundColor: "#252441",
                      borderRadius: 10,
                      padding: "10px",
                    }}>
                    <div className="d-flex justify-content-between">
                      <h6
                        className="text-lightBlue"
                        style={{
                          textTransform: "capitalize",
                          padding: "10px",
                        }}>
                        {e.replace("&", " & ")} Form
                      </h6>
                    </div>

                    <div
                      style={{
                        padding: "10px",
                        display: "flex",
                        flexDirection: "column",
                        gap: 20,
                      }}>
                      {subjectFetching
                        ? null
                        : subjectList
                            ?.filter((el) => el.type === e)
                            ?.map((el, inD) => {
                              return (
                                <EditComponent
                                  {...el}
                                  editSubjectDataPrev={editSubjectDataPrev(e, el._id)}
                                  toggleDelete={toglleDelete}
                                  key={`${i}-${inD}`}
                                />
                              );
                            })}
                    </div>
                    {(e === "contact"
                      ? addCompDataContact
                      : e === "help&faq"
                      ? addCompDataFq
                      : addCompData
                    )?.length > 0 && (
                      <div
                        style={{
                          padding: "10px",
                          display: "flex",
                          flexDirection: "column",
                          gap: 20,
                        }}>
                        {(e === "contact"
                          ? addCompDataContact
                          : e === "help&faq"
                          ? addCompDataFq
                          : addCompData
                        )?.map((el, inD) => {
                          return (
                            <AddComponent
                              {...el}
                              editName={editSubjectData(e, inD)}
                              cancelAdd={() => cancelSubjectData(e)(inD)}
                              key={`${i}-${inD}`}
                            />
                          );
                        })}
                      </div>
                    )}

                    <button
                      onClick={() => {
                        addSubjectData(e);
                      }}
                      type="button"
                      className="reset link"
                      style={{
                        margin: "10px",
                      }}>
                      <p>+ Add Subject</p>
                    </button>
                  </div>
                );
              })}
            </div>
            <div className="d-flex flex-column py-3 px-4 feature-buttons-fixed">
              <hr className="hr-grey-6 my-3 w-100" />
              <div className="d-flex justify-content-between">
                <button
                  className="button-gradient py-2 px-5 w-auto "
                  onClick={handleSubmitSave}>
                  <p>Save</p>
                </button>
                <button
                  className="button-lightBlue-outline py-2 px-4"
                  onClick={handleCloseDrawer}>
                  <p>Cancel</p>
                </button>
              </div>
            </div>
          </div>
        </SwipeableDrawer>
      )}
    </div>
  );
};

//existing code
// export const useDownLoadExcel = () => {
//   const [getDownloadData] = useDownloadExcelMutation();
//   const [getDownloadDataNews] = useGetNewsletterDownloadMutation();
//   const dispatch = useDispatch();

//   const formatDay = useCallback((createdAt) => {
//     return `${moment(createdAt).format("DD-MM-yyyy")} at ${moment(
//       createdAt
//     ).format("hh:mm A")}`;
//   }, []);

//   const downloadExcel = useCallback(
//     async (options, cb) => {
//       try {
//         if (!Boolean(options?.type)) {
//           dispatch(showError({ message: "Select type first" }));
//           return;
//         }
//         let formatedArray = [];
//         if (options?.type !== "newsletter") {
//           const data = await getDownloadData(options);
//           formatedArray = [
//             options.type === "product"
//               ? [
//                   "EnquiryId",
//                   "Type",
//                   "Created",
//                   "Product",
//                   "Customer",
//                   "Customer",
//                   "Customer",
//                   "Subject",
//                   "Message",
//                 ]
//               : [
//                   "EnquiryId",
//                   "Type",
//                   "Created",
//                   "Customer",
//                   "Customer",
//                   "Customer",
//                   "Subject",
//                   "Message",
//                 ],
//           ];
//           formatedArray.push(
//             ...data?.data?.data?.data?.map((e) =>
//               options.type !== "product"
//                 ? [
//                     e.enqId,
//                     e.type,
//                     formatDay(e.createdAt),
//                     e.product?.title || "-",
//                     e.firstName,
//                     e.phone,
//                     e.email,
//                     e.subject,
//                     e.message,
//                   ]
//                 : [
//                     e.enqId,
//                     e.type,
//                     formatDay(e.createdAt),
//                     e.firstName,
//                     e.phone,
//                     e.email,
//                     e.subject,
//                     e.message,
//                   ]
//             )
//           );
//         } else {
//           const data = await getDownloadDataNews({});
//           formatedArray = [["Type", "Created On", "Email"]];
//           formatedArray.push(
//             ...data?.data?.data?.data?.map((e) => [
//               "News Letter",
//               formatDay(e.createdAt),
//               e.email,
//             ])
//           );
//         }

//         const ws = Xlsx.utils.aoa_to_sheet(formatedArray);

//         // Create a workbook with the worksheet
//         const wb = Xlsx.utils.book_new();
//         Xlsx.utils.book_append_sheet(wb, ws, "Sheet 1");
//         const headerCellStyle = {
//           fill: {
//             fgColor: { rgb: "FF0000" }, // Fill color (red in this example)
//           },
//           font: {
//             color: { rgb: "FFFFFF" }, // Font color (white in this example)
//             bold: true, // Bold font
//           },
//         };

//         for (let col = 0; col < formatedArray[0].length; col++) {
//           const cellRef = Xlsx.utils.encode_cell({ c: col, r: 0 }); // Assuming header row is the first row
//           ws[cellRef].s = headerCellStyle;
//         }

//         // Save the workbook to a file
//         Xlsx.writeFile(wb, `customer-enquiry-${options?.type}-sheet.xlsx`);

//         dispatch(showSuccess({ message: "Sheet Downloaded successfully" }));
//         if (cb) {
//           cb();
//         }
//       } catch (error) {
//         console.log(error, "options");

//         dispatch(showError({ message: "Unable to download sheet" }));
//       }
//     },
//     [dispatch, getDownloadData, getDownloadDataNews, formatDay]
//   );

//   return {
//     downloadExcel,
//   };
// };

export const useDownLoadExcel = () => {
  const [getDownloadData] = useDownloadExcelMutation();
  const [getDownloadDataNews] = useGetNewsletterDownloadMutation();
  const dispatch = useDispatch();

  const formatDay = useCallback((createdAt) => {
    return `${moment(createdAt).format("DD-MM-yyyy")} at ${moment(createdAt).format(
      "hh:mm A"
    )}`;
  }, []);

  const downloadExcel = useCallback(
    async (options, cb) => {
      try {
        if (!Boolean(options?.type)) {
          dispatch(showError({ message: "Select type first" }));
          return;
        }
        let formatedArray = [];
        if (options?.type !== "newsletter") {
          const data = await getDownloadData(options);
          formatedArray = [
            options.type === "product"
              ? [
                  "EnquiryId",
                  "Type",
                  "Created",
                  "Product",
                  "Customer Name",
                  "Customer Phone",
                  "Customer Email",
                  "Subject",
                  "Message",
                ]
              : [
                  "EnquiryId",
                  "Type",
                  "Created",
                  "Customer Name",
                  "Customer Phone",
                  "Customer Email",
                  "Subject",
                  "Message",
                ],
          ];
          formatedArray.push(
            ...data?.data?.data?.data?.map((e) =>
              options.type === "product"
                ? [
                    e.enqId,
                    e.type,
                    formatDay(e.createdAt),
                    e.product?.title || "-",
                    e.firstName,
                    e.phone,
                    e.email,
                    e.subject?.subject,
                    e.message,
                  ]
                : [
                    e.enqId,
                    e.type,
                    formatDay(e.createdAt),
                    e.firstName,
                    e.phone,
                    e.email,
                    e.subject?.subject,
                    e.message,
                  ]
            )
          );
        } else {
          const data = await getDownloadDataNews({});
          formatedArray = [["Type", "Created On", "Email"]];
          formatedArray.push(
            ...data?.data?.data?.data?.map((e) => [
              "News Letter",
              formatDay(e.createdAt),
              e.email,
            ])
          );
        }

        // Create a worksheet
        const ws = Xlsx.utils.aoa_to_sheet(formatedArray);
        const wb = Xlsx.utils.book_new();
        Xlsx.utils.book_append_sheet(wb, ws, "Sheet 1");

        // Add styles for header cells
        const headerCellStyle = {
          fill: {
            fgColor: { rgb: "FF0000" }, // Fill color (red in this example)
          },
          font: {
            color: { rgb: "FFFFFF" }, // Font color (white in this example)
            bold: true, // Bold font
          },
        };

        // Set styles for each cell in the header row
        for (let col = 0; col < formatedArray[0].length; col++) {
          const cellRef = Xlsx.utils.encode_cell({ c: col, r: 0 }); // Assuming header row is the first row
          ws[cellRef] = Object.assign({}, ws[cellRef], { s: headerCellStyle });
        }

        // Save the workbook to a file
        Xlsx.writeFile(wb, `customer-enquiry-${options?.type}-sheet.xlsx`);

        dispatch(showSuccess({ message: "Sheet Downloaded successfully" }));
        if (cb) {
          cb();
        }
      } catch (error) {
        console.log(error, "options");

        dispatch(showError({ message: "Unable to download sheet" }));
      }
    },
    [dispatch, getDownloadData, getDownloadDataNews, formatDay]
  );

  return {
    downloadExcel,
  };
};

const EditComponent = (props) => {
  return (
    <div className="d-flex align-items-center gap-2">
      <OutlinedInput
        placeholder="Subject"
        size="small"
        name="subject"
        value={props.subject}
        onChange={(ev) => props.editSubjectDataPrev(ev.target.value)}
        style={{
          flex: 1,
        }}
      />
      <DeleteIconButton onClick={() => props.toggleDelete(props?._id)}></DeleteIconButton>
    </div>
  );
};

const AddComponent = (props) => {
  return (
    <div className="d-flex align-items-center gap-2">
      <OutlinedInput
        placeholder="Subject"
        size="small"
        name="subject"
        value={props.subject}
        onChange={(ev) => props.editName(ev.target.value)}
        style={{
          flex: 1,
        }}
      />
      <DeleteIconButton onClick={() => props?.cancelAdd()}></DeleteIconButton>
    </div>
  );
};

export default UserEnquiries;