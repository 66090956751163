import apiSlice from "../../app/api/apiSlice";
import { omitEmptyKeys } from "../../utils/helper";

export const logsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllLogs: builder.query({
      query: (queries) => ({
        url: "/logs",
        params: omitEmptyKeys(queries),
      }),
      transformResponse: (res) => res?.data,
      providesTags: ["Logs"],
    }),
  }),
});

export const { useGetAllLogsQuery } = logsApiSlice;
