const SideStoreBox = ({ heading, details }) => {

  return (
    <div className="d-flex col-lg-4 d-flex">
        <div className="col-lg-12 mt-3">
            <h6 className="text-lightBlue fw-500 me-2">{heading}</h6>
            <small className="text-grey-6 mt-3 d-block">
                {details}
            </small>
        </div>
    </div>
  );
};

export default SideStoreBox;
