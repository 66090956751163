import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import {
  Box,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  SwipeableDrawer,
  Switch,
} from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Visibility } from '@mui/icons-material';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import _ from 'lodash';
import { useDispatch } from 'react-redux';

import { useFormikToastError } from '../../../../../hooks/error';
import {
  showSuccess,
  showError,
} from '../../../../../features/snackbar/snackbarAction';
import SnackbarUtils from '../../../../../features/snackbar/useSnackbar';

import Homepage from '../HomePage';
import HomeCountry from '../HomeCountry';
import InputBox from '../../../../../components/Setting/InputBox';
import DropDownbox from '../../../../../components/ThemeComponent/HomeToggle/DropDownBox';
import TabPanel from '../../../../../components/TabPanel/TabPanel';
import SearchInput from '../../../../../components/ThemeComponent/HomeToggle/SearchInput';
import ImageUpload from '../../Component/ImageUpload';

import add from '../../../../../assets/icons/theme/add-square-02.svg';
import gradientArrow from '../../../../../assets/icons/theme/gradientArrow.svg';
import dots from '../../../../../assets/icons/theme/ThreeDots.svg';
import delete3 from '../../../../../assets/icons/theme/Delete.svg';
import {
  useGetAllBannerQuery,
  useUpdateBannerMutation,
  useUpdateWebsiteAttrMutation,
} from '../../../../../features/theme/content/bannerApiSlice';
import CuratedCollection from './CuratedCollection';
import BestSeller from './BestSeller';
import BestSeller2 from './BestSeller2';
import DynamicSection from './DynamicSection';
import LatestRelease from './latestRelease';
import { LoadingButton } from '@mui/lab';
import ThreeTabs from '../TopThemeSide/ThreeTabs';
import { AntSwitch } from '../../../../../components/AntSwitch/AntSwitch';

const bannerValidationSchema = Yup.object({
  type: Yup.string().trim().required('Required'),
  mediaUrl: Yup.string().trim().required('Required'),
  redirectId: Yup.string().trim().required('Required'),
  backgroundType: Yup.string().trim().required('Required'),
});

const middleData = [
  { id: 1, name: 'Hero Banner' },
  { id: 2, name: 'Curated Collection' },
  { id: 3, name: 'Best Seller' },
  { id: 4, name: 'Dynamic Section' },
  { id: 5, name: 'Latest Release' },
  // { id: 5, name: 'Store Locater' },
  // { id: 7, name: 'PCJ Values' },
  // { id: 8, name: 'Instagram' },
];

const product = [
  { name: 'The Fringe Diamond Ring' },
  { name: 'The Fringe Diamond Ring' },
  { name: 'The Fringe Diamond Ring' },
  { name: 'The Fringe Diamond Ring' },
  { name: 'The Fringe Diamond Ring' },
  { name: 'The Fringe Diamond Ring' },
  { name: 'The Fringe Diamond Ring' },
];

const MiddleThemeSide = () => {
  const dispatch = useDispatch();
  const [selectedBanner, setSelectedBanner] = useState(null);
  const [showSetting2, setShowSetting2] = useState(false);
  const [showId, setId] = useState('');
  const [name, setName] = useState('');
  const [activityDrawer, setActivityDrawer] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [activityDrawer2, setActivityDrawer2] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const { data: bannersData } = useGetAllBannerQuery({
    attributeId: '656ddc911854de0008b5a7ac',
  });

  let { data: allBannersData } = useGetAllBannerQuery({});

  const [updateBanner, { isLoading: updateBannerIsLoading }] =
    useUpdateBannerMutation();
  const [updateWebsiteAttr, { isLoading: updateWebsiteAttrIsLoading }] =
    useUpdateWebsiteAttrMutation();

  if (allBannersData?.data?.[0]?.attributes) {
    allBannersData = [...allBannersData?.data?.[0]?.attributes];
    allBannersData = allBannersData.sort((a, b) => {
      return a.order - b.order;
    });
  }

  let bannerSectionData = allBannersData?.data?.find((item) => item._id === showId);

  const bannerFormik = useFormik({
    initialValues: {
      id: selectedBanner?.id || '',
      type: selectedBanner?.type || 'heroBanner',
      mediaUrl: selectedBanner?.mediaUrl || '',
      redirectId: selectedBanner?.redirectId || '',
      backgroundType: selectedBanner?.backgroundType || '',
    },

    enableReinitialize: true,
    validationSchema: bannerValidationSchema,
    onSubmit: (values) => {
      SnackbarUtils.savingToast();
      updateBanner({
        details: values,
        structureId: bannerSectionData?._id,
        attributeId: bannersData?.data?.[0]?._id,
      })
        .unwrap()
        .then(() => {
          SnackbarUtils.hideToast();
          dispatch(
            showSuccess({
              message: 'Banner updated successfully',
            })
          );
        })
        .catch((error) => {
          SnackbarUtils.hideToast();
          if (error?.data?.message) {
            dispatch(showError({ message: error.data.message }));
          } else {
            dispatch(
              showError({
                message: 'Something went wrong!, please try again',
              })
            );
          }
        });
    },
  });

  const bannerAttrFormik = useFormik({
    initialValues: {
      name: bannerSectionData?.name || '',
      active: bannerSectionData?.active || true,
    },

    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string().trim().required('Required'),
    }),
    onSubmit: (values) => {
      SnackbarUtils.savingToast();
      updateWebsiteAttr({
        details: values,
        attributeId: bannerSectionData?._id,
        layoutId: bannersData?.data?.[0]?._id,
      })
        .unwrap()
        .then(() => {
          SnackbarUtils.hideToast();
          dispatch(
            showSuccess({
              message: 'Banner updated successfully',
            })
          );
        })
        .catch((error) => {
          SnackbarUtils.hideToast();
          if (error?.data?.message) {
            dispatch(showError({ message: error.data.message }));
          } else {
            dispatch(
              showError({
                message: 'Something went wrong!, please try again',
              })
            );
          }
        });
    },
  });

  const selectedBannerHandler = (banner) => {
    setSelectedBanner({
      id: banner?._id || '',
      type: banner?.type || '',
      mediaUrl: banner?.mediaUrl || '',
      redirectId: banner?.redirectId || '',
      backgroundType: banner?.backgroundType || '',
    });
    bannerFormik.setFieldValue('id', banner?._id);
    bannerFormik.setFieldValue('type', banner?.type);
    bannerFormik.setFieldValue('mediaUrl', banner?.mediaUrl);
    bannerFormik.setFieldValue('redirectId', banner?.redirectId);
    bannerFormik.setFieldValue('backgroundType', banner?.backgroundType);
  };

  const matchedId = middleData.find((item) => item?.id === showId);

  const toggleActivityDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setActivityDrawer({ ...activityDrawer, [anchor]: open });
  };

  const toggleActivityDrawer2 = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setActivityDrawer2({ ...activityDrawer2, [anchor]: open });
  };

  const handleTopBar = (id) => {
    setId(id);
    toggleActivityDrawer('left', true)();
  };

  const handleTopBar2 = (name) => {
    setName(name);
    toggleActivityDrawer2('left', true)();
  };  

  return (
    <>
      <div className='bg-black-20 p-3 rounded-8 my-2'>
        {allBannersData?.data?.map((item) => {
          return (
            <div
              key={item?._id}
              className='d-flex justify-content-between align-items-center theme-hover my-2'
              onClick={() => handleTopBar(item?._id)}
            >
              <small className='text-lightBlue fw-500'>{item.name}</small>
              <span className='edit-icon'>
                <img src={dots} />
                <Visibility
                  sx={{
                    color: '#5C6D8E',
                    fontSize: 14,
                    marginInline: 1,
                  }}
                />
                <EditOutlinedIcon
                  sx={{
                    color: '#5C6D8E',
                    fontSize: 14,
                  }}
                />
              </span>
            </div>
          );
        })}
        {/* {middleData.map((item) => (
          <div
            key={item?.id}
            className='d-flex justify-content-between align-items-center theme-hover my-2'
            onClick={() => handleTopBar(item?.id)}
          >
            <small className='text-lightBlue fw-500'>{item.name}</small>
            <span className='edit-icon'>
              <img src={dots} />
              <Visibility
                sx={{
                  color: '#5C6D8E',
                  fontSize: 14,
                  marginInline: 1,
                }}
              />
              <EditOutlinedIcon
                sx={{
                  color: '#5C6D8E',
                  fontSize: 14,
                }}
              />
            </span>
          </div>
        ))} */}
      </div>

      <SwipeableDrawer
        anchor='left'
        open={activityDrawer['left']}
        onClose={toggleActivityDrawer('left', false)}
        onOpen={toggleActivityDrawer('left', true)}
      >
        <div className='p-3' style={{ width: '320px' }}>
          <div className='d-flex'>
            <Homepage />
            <div className='d-flex align-items-center bg-black-20 py-2 px-3 rounded-8 ms-2 c-pointer'>
              <img src={add} alt='indiaFlag' height={20} />
            </div>
          </div>

          <div className='d-flex justify-content-between my-4'>
            <div className='d-flex align-items-center'>
              <img
                src={gradientArrow}
                className='c-pointer'
                onClick={() => toggleActivityDrawer('left', false)()}
              />
              <h6 className='text-lightBlue'>{bannerSectionData?.name}</h6>
            </div>

            <div className='d-flex'>
              {/* <HomeCountry /> */}
              <div className='d-flex align-items-center bg-black-20 py-2 px-3 rounded-8 ms-2 c-pointer'>
                <img src={dots} className='my-1' />
              </div>
            </div>
          </div>

          <div className='mt-2 px-3' style={{ display: 'grid', gap: '1rem' }}>
            <div>
              <InputBox
                heading='name'
                name='name'
                value={bannerAttrFormik.values.name}
                onBlur={bannerAttrFormik.handleBlur}
                onChange={bannerAttrFormik.handleChange}
              />
              {!!bannerAttrFormik.touched.name &&
                bannerAttrFormik.errors.name && (
                  <FormHelperText error>
                    {bannerAttrFormik.errors.name}
                  </FormHelperText>
                )}
            </div>
            <div>
              <p className='text-lightBlue fw-500'>Active Status</p>

              <AntSwitch
                checked={bannerAttrFormik.values.active}
                onChange={bannerAttrFormik.handleChange}
                name='active'
                // inputProps={{ 'aria-label': 'controlled' }}
                // size='small'
                // style={{
                //   color: '#5C6D8E',
                // }}
              />

              {/* <Switch /> */}
            </div>
            <LoadingButton
              className='button-gradient py-2 px-4 w-auto'
              type='button'
              loading={updateWebsiteAttrIsLoading}
              disabled={updateWebsiteAttrIsLoading}
              onClick={bannerAttrFormik.handleSubmit}
            >
              <p>Save</p>
            </LoadingButton>
          </div>

          {showId === '656ddc911854de0008b5a7ac' && (
            <>
              <div className='py-3 px-3 rounded-8 mb-3'>
                {/* <div className='d-flex justify-content-between align-items-center'>
                  <p className='text-lightBlue'>Content</p>
                  <ArrowDropDownIcon />
                </div> */}

                <div className='my-2'>
                  {bannerSectionData?.structure?.map((item, index) => (
                    <div
                      key={index}
                      className='bg-black-20 py-3 px-3 rounded-8 mb-3'
                    >
                      <div
                        className='d-flex align-items-center justify-content-between c-pointer'
                        onClick={
                          selectedBanner?.id === item._id
                            ? selectedBannerHandler.bind(null, {})
                            : selectedBannerHandler.bind(null, item)
                        }
                      >
                        <div className='d-flex align-items-center'>
                          {/* <img
                                src={item?.mediaUrl}
                                alt=''
                                width={35}
                                height={30}
                                className='rounded-4 me-2'
                                style={{ objectFit: 'cover' }}
                              /> */}
                          <p className='text-lightBlue fw-500'>
                            {`Banner Image ${index + 1}`}
                          </p>
                        </div>
                        <ArrowDropDownIcon sx={{ color: '#C8D8FF' }} />
                      </div>
                      {selectedBanner?.id === item._id && (
                        <div>
                          <ImageUpload
                            isLoading={updateBannerIsLoading}
                            formik={bannerFormik}
                          />
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>

              {/* <SwipeableDrawer
                anchor='left'
                open={activityDrawer2['left']}
                onClose={toggleActivityDrawer2('left', false)}
                onOpen={toggleActivityDrawer2('left', true)}
              >
                <div className='p-3' style={{ width: '320px' }}>
                  <div className='d-flex'>
                    <Homepage />
                    <div className='d-flex align-items-center bg-black-20 py-2 px-3 rounded-8 ms-2 c-pointer'>
                      <img src={add} alt='indiaFlag' height={20} />
                    </div>
                  </div>

                  <div className='d-flex justify-content-between my-4'>
                    <div className='d-flex align-items-center'>
                      <img
                        src={gradientArrow}
                        className='c-pointer'
                        onClick={() => toggleActivityDrawer2('left', false)()}
                      />
                      <h6 className='text-lightBlue'>{name}</h6>
                    </div>

                    <div className='d-flex'>
                      <HomeCountry />
                      <div className='d-flex align-items-center bg-black-20 py-2 px-3 rounded-8 ms-2 c-pointer'>
                        <h5 className='mb-1'>...</h5>
                      </div>
                    </div>
                  </div>

                  <div className='bg-black-20 py-3 px-3 rounded-8 mb-3'>
                    <div
                      className='d-flex align-items-center justify-content-between c-pointer'
                      onClick={() => setShowSetting2((prevState) => !prevState)}
                    >
                      <div className='d-flex align-items-center'>
                        <img
                          src={user}
                          width={35}
                          height={30}
                          className='rounded-4 me-2'
                          style={{ objectFit: 'cover' }}
                        />
                        <p className='text-lightBlue fw-500'>Banner Image</p>
                      </div>
                      <ArrowDropDownIcon sx={{ color: '#C8D8FF' }} />
                    </div>
                    {showSetting2 && (
                      <div>
                        <ImageUpload />
                      </div>
                    )}
                  </div>
                </div>
              </SwipeableDrawer> */}
            </>
          )}
          {showId == '6570199a11f0d649a8700a2d' && <CuratedCollection />}
          {showId == '65701bcecd46d649a8aeea01' && <BestSeller />}
          {showId == '65bba3682e25e600086d8778' && <BestSeller2 />}
          {showId == '65701ca60590a949a8174dfe' && <DynamicSection />}
          {showId == '65701d6e11abad49a8f8cc3c' && <LatestRelease />}
          {showId == '65b0e2e67b56580008c7feaf' && <ThreeTabs />}

          {/* {showId == 2 && (
            <>
              <div className='bg-black-20 py-3 px-3 rounded-8 mb-3'>
                <InputBox
                  heading={'Heading Name'}
                  placeholder={'Currated Collection'}
                />
                <InputBox
                  heading={'Heading Link'}
                  placeholder={'Search or paste link'}
                />
              </div>

              <div className='bg-black-20 py-3 px-3 rounded-8'>
                {bannerData?.map((item) => (
                  <>
                    <div
                      key={item.id}
                      className='d-flex justify-content-between align-items-center theme-hover my-2'
                      onClick={() => handleTopBar2(item.name)}
                    >
                      <div>
                        <DragIndicatorIcon sx={{ color: '#5C6D8E' }} />
                        <img
                          src={item.image}
                          width={35}
                          height={30}
                          className='rounded-4'
                          style={{ objectFit: 'cover' }}
                        />
                        <small className='text-lightBlue fw-500 mx-2'>
                          {item.name}
                        </small>
                      </div>
                      <span className='edit-icon'>
                        <EditOutlinedIcon
                          sx={{ color: '#c8d8ff', fontSize: 14 }}
                        />
                      </span>
                    </div>
                  </>
                ))}
              </div>

              <SwipeableDrawer
                anchor='left'
                open={activityDrawer2['left']}
                onClose={toggleActivityDrawer2('left', false)}
                onOpen={toggleActivityDrawer2('left', true)}
              >
                <div className='p-3' style={{ width: '320px' }}>
                  <div className='d-flex'>
                    <Homepage />
                    <div className='d-flex align-items-center bg-black-20 py-2 px-3 rounded-8 ms-2 c-pointer'>
                      <img src={add} alt='indiaFlag' height={20} />
                    </div>
                  </div>

                  <div className='d-flex justify-content-between my-4'>
                    <div className='d-flex align-items-center'>
                      <img
                        src={gradientArrow}
                        className='c-pointer'
                        onClick={() => toggleActivityDrawer2('left', false)()}
                      />
                      <h6 className='text-lightBlue'>{name}</h6>
                    </div>

                    <div className='d-flex'>
                      <HomeCountry />
                      <div className='d-flex align-items-center bg-black-20 py-2 px-3 rounded-8 ms-2 c-pointer'>
                        <h5 className='mb-1'>...</h5>
                      </div>
                    </div>
                  </div>

                  <div className='bg-black-20 py-3 px-3 rounded-8 mb-3'>
                    <div
                      className='d-flex align-items-center justify-content-between c-pointer'
                      onClick={() => setShowSetting2((prevState) => !prevState)}
                    >
                      <div className='d-flex align-items-center'>
                        <img
                          src={user}
                          width={35}
                          height={30}
                          className='rounded-4 me-2'
                          style={{ objectFit: 'cover' }}
                        />
                        <p className='text-lightBlue fw-500'>Banner Image</p>
                      </div>
                      <ArrowDropDownIcon sx={{ color: '#C8D8FF' }} />
                    </div>
                    {showSetting2 && (
                      <div>
                        <ImageUpload />
                      </div>
                    )}
                  </div>
                </div>
              </SwipeableDrawer>
            </>
          )} */}

          {/* {showId == 3 && (
            <>
              <div className='bg-black-20 py-3 px-3 rounded-8 mb-3'>
                <InputBox
                  heading={'Heading Name'}
                  placeholder={'Currated Collection'}
                />
                <InputBox
                  heading={'Heading Link'}
                  placeholder={'Search or paste link'}
                />
              </div>

              <div className='bg-black-20 py-3 px-3 rounded-8'>
                {sellerData?.map((item) => (
                  <>
                    <div
                      key={item.id}
                      className='d-flex justify-content-between align-items-center theme-hover my-2'
                      onClick={() => handleTopBar2(item.name)}
                    >
                      <div>
                        <DragIndicatorIcon sx={{ color: '#5C6D8E' }} />
                        <img
                          src={item.image}
                          width={35}
                          height={30}
                          className='rounded-4'
                          style={{ objectFit: 'cover' }}
                        />
                        <small className='text-lightBlue fw-500 mx-2'>
                          {item.name}
                        </small>
                      </div>
                      <span className='edit-icon'>
                        <EditOutlinedIcon
                          sx={{ color: '#c8d8ff', fontSize: 14 }}
                        />
                      </span>
                    </div>
                  </>
                ))}
              </div>

              <div className='bg-black-20 py-3 px-3 rounded-8 my-3'>
                <InputBox heading={'Button Text'} placeholder={'Enter Title'} />
                <InputBox
                  heading={'Button Link'}
                  placeholder={'Search or paste link'}
                />
              </div>

              <SwipeableDrawer
                anchor='left'
                open={activityDrawer2['left']}
                onClose={toggleActivityDrawer2('left', false)}
                onOpen={toggleActivityDrawer2('left', true)}
              >
                <div className='p-3' style={{ width: '320px' }}>
                  <div className='d-flex'>
                    <Homepage />
                    <div className='d-flex align-items-center bg-black-20 py-2 px-3 rounded-8 ms-2 c-pointer'>
                      <img src={add} alt='indiaFlag' height={20} />
                    </div>
                  </div>

                  <div className='d-flex justify-content-between my-4'>
                    <div className='d-flex align-items-center'>
                      <img
                        src={gradientArrow}
                        className='c-pointer'
                        onClick={() => toggleActivityDrawer2('left', false)()}
                      />
                      <h6 className='text-lightBlue'>{name}</h6>
                    </div>

                    <div className='d-flex'>
                      <HomeCountry />
                      <div className='d-flex align-items-center bg-black-20 py-2 px-3 rounded-8 ms-2 c-pointer'>
                        <h5 className='mb-1'>...</h5>
                      </div>
                    </div>
                  </div>

                  <div className='bg-black-20 py-3 px-3 rounded-8 my-3'>
                    <InputBox
                      heading={'Tab Title Text'}
                      placeholder={'Enter Text'}
                    />
                    <InputBox
                      heading={'Tab Title Link'}
                      placeholder={'Search or paste link'}
                    />
                  </div>

                  <div className='bg-black-20 py-3 px-3 rounded-8 mb-3'>
                    <div
                      className='d-flex align-items-center justify-content-between c-pointer'
                      onClick={() => setShowSetting2((prevState) => !prevState)}
                    >
                      <p className='text-lightBlue fw-500'>Add Products</p>
                      <ArrowDropDownIcon sx={{ color: '#C8D8FF' }} />
                    </div>
                    {showSetting2 && (
                      <div>
                        <SearchInput
                          heading={'Search Products'}
                          placeholder={'Search Products'}
                        />
                        {product.map((item) => (
                          <div className='d-flex justify-content-between align-items-center my-2'>
                            <div className='d-flex align-items-center my-2'>
                              <DragIndicatorIcon sx={{ color: '#5C6D8E' }} />
                              <img
                                src={user}
                                width={35}
                                height={30}
                                className='rounded-4'
                                style={{ objectFit: 'cover' }}
                              />
                              <div className='d-flex align-items-center mx-2'>
                                <small className='text-lightBlue fw-500'>
                                  {item.name}
                                  <div className='ex-small text-grey-6'>
                                    SKU: TFDR012345
                                  </div>
                                </small>
                              </div>
                            </div>
                            <img src={delete3} />
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </SwipeableDrawer>
            </>
          )} */}

          {/* {showId == 4 && (
            <>
              <div className='bg-black-20 py-3 px-3 rounded-8 mb-3'>
                <InputBox
                  heading={'Heading Name'}
                  placeholder={'Currated Collection'}
                />
                <InputBox
                  heading={'Heading Link'}
                  placeholder={'Search or paste link'}
                />
              </div>

              <div className='bg-black-20 py-3 px-3 rounded-8'>
                <div
                  className='d-flex justify-content-between align-items-center theme-hover my-2'
                  onClick={() => handleTopBar2()}
                >
                  <div>
                    <DragIndicatorIcon sx={{ color: '#5C6D8E' }} />
                    <img
                      src={user}
                      width={35}
                      height={30}
                      className='rounded-4'
                      style={{ objectFit: 'cover' }}
                    />
                    <small className='text-lightBlue fw-500 mx-2'>
                      Banner 1
                    </small>
                  </div>
                  <span className='edit-icon'>
                    <EditOutlinedIcon sx={{ color: '#c8d8ff', fontSize: 14 }} />
                  </span>
                </div>
              </div>

              <div className='bg-black-20 py-3 px-3 rounded-8 my-3'>
                <div
                  className='d-flex align-items-center justify-content-between c-pointer'
                  onClick={() => setShowSetting2((prevState) => !prevState)}
                >
                  <p className='text-lightBlue fw-500'>Add Products</p>
                  <ArrowDropDownIcon sx={{ color: '#C8D8FF' }} />
                </div>
                {showSetting2 && (
                  <div>
                    <SearchInput
                      heading={'Search Products'}
                      placeholder={'Search Products'}
                    />
                    {product.map((item) => (
                      <div className='d-flex justify-content-between align-items-center my-2'>
                        <div className='d-flex align-items-center my-2'>
                          <DragIndicatorIcon sx={{ color: '#5C6D8E' }} />
                          <img
                            src={user}
                            width={35}
                            height={30}
                            className='rounded-4'
                            style={{ objectFit: 'cover' }}
                          />
                          <div className='d-flex align-items-center mx-2'>
                            <small className='text-lightBlue fw-500'>
                              {item.name}
                              <div className='ex-small text-grey-6'>
                                SKU: TFDR012345
                              </div>
                            </small>
                          </div>
                        </div>
                        <img src={delete3} />
                      </div>
                    ))}
                  </div>
                )}
              </div>

              <div className='bg-black-20 py-3 px-3 rounded-8 my-3'>
                <InputBox heading={'Button Text'} placeholder={'Enter Title'} />
                <InputBox
                  heading={'Button Link'}
                  placeholder={'Search or paste link'}
                />
              </div>

              <SwipeableDrawer
                anchor='left'
                open={activityDrawer2['left']}
                onClose={toggleActivityDrawer2('left', false)}
                onOpen={toggleActivityDrawer2('left', true)}
              >
                <div className='p-3' style={{ width: '320px' }}>
                  <div className='d-flex'>
                    <Homepage />
                    <div className='d-flex align-items-center bg-black-20 py-2 px-3 rounded-8 ms-2 c-pointer'>
                      <img src={add} alt='indiaFlag' height={20} />
                    </div>
                  </div>

                  <div className='d-flex justify-content-between my-4'>
                    <div className='d-flex align-items-center'>
                      <img
                        src={gradientArrow}
                        className='c-pointer'
                        onClick={() => toggleActivityDrawer2('left', false)()}
                      />
                      <h6 className='text-lightBlue'>Banner 1</h6>
                    </div>

                    <div className='d-flex'>
                      <HomeCountry />
                      <div className='d-flex align-items-center bg-black-20 py-2 px-3 rounded-8 ms-2 c-pointer'>
                        <h5 className='mb-1'>...</h5>
                      </div>
                    </div>
                  </div>

                  <div className='bg-black-20 py-3 px-3 rounded-8 mb-3'>
                    <div
                      className='d-flex align-items-center justify-content-between c-pointer'
                      onClick={() => setShowSetting2((prevState) => !prevState)}
                    >
                      <div className='d-flex align-items-center'>
                        <img
                          src={user}
                          width={35}
                          height={30}
                          className='rounded-4 me-2'
                          style={{ objectFit: 'cover' }}
                        />
                        <p className='text-lightBlue fw-500'>Banner Image</p>
                      </div>
                      <ArrowDropDownIcon sx={{ color: '#C8D8FF' }} />
                    </div>
                    {showSetting2 && (
                      <div>
                        <ImageUpload />
                      </div>
                    )}
                  </div>
                </div>
              </SwipeableDrawer>
            </>
          )} */}

          {/* {showId == 5 && (
            <>
              <div className='bg-black-20 py-3 px-3 rounded-8 mb-3'>
                <InputBox
                  heading={'Heading Name'}
                  placeholder={'Currated Collection'}
                />
                <InputBox
                  heading={'Heading Link'}
                  placeholder={'Search or paste link'}
                />
              </div>
              <div className='bg-black-20 py-3 px-3 rounded-8 mb-3'>
                <div
                  className='d-flex align-items-center justify-content-between c-pointer'
                  onClick={() => setShowSetting2((prevState) => !prevState)}
                >
                  <div className='d-flex align-items-center'>
                    <img
                      src={user}
                      width={35}
                      height={30}
                      className='rounded-4 me-2'
                      style={{ objectFit: 'cover' }}
                    />
                    <p className='text-lightBlue fw-500'>Banner Image</p>
                  </div>
                  <ArrowDropDownIcon sx={{ color: '#C8D8FF' }} />
                </div>
                {showSetting2 && (
                  <div>
                    <ImageUpload />
                  </div>
                )}
              </div>
            </>
          )} */}

          {/* {showId == 6 && (
            <>
              <div className='bg-black-20 py-3 px-3 rounded-8 mb-3'>
                <InputBox heading={'Heading Text'} placeholder={'Enter Text'} />
                <InputBox
                  heading={'Heading Link'}
                  placeholder={'Search or paste link'}
                />
              </div>
              <div className='bg-black-20 py-3 px-3 rounded-8 mb-3'>
                <div className='d-flex justify-content-between align-items-center'>
                  <p className='text-lightBlue'>Content</p>
                  <ArrowDropDownIcon />
                </div>

                <div className='my-2'>
                  {bannerData?.map((item) => (
                    <>
                      <div
                        key={item.id}
                        className='d-flex justify-content-between align-items-center theme-hover my-2'
                        onClick={() => handleTopBar2(item.name)}
                      >
                        <div>
                          <DragIndicatorIcon sx={{ color: '#5C6D8E' }} />
                          <img
                            src={item.image}
                            width={35}
                            height={30}
                            className='rounded-4'
                            style={{ objectFit: 'cover' }}
                          />
                          <small className='text-lightBlue fw-500 mx-2'>
                            {item.name}
                          </small>
                        </div>
                        <span className='edit-icon'>
                          <EditOutlinedIcon
                            sx={{ color: '#c8d8ff', fontSize: 14 }}
                          />
                        </span>
                      </div>
                    </>
                  ))}
                </div>
              </div>
              <div className='bg-black-20 py-3 px-3 rounded-8 mb-3'>
                <InputBox heading={'Button Text'} placeholder={'Enter Title'} />
                <InputBox
                  heading={'Button Link'}
                  placeholder={'Search or paste link'}
                />
              </div>
              <SwipeableDrawer
                anchor='left'
                open={activityDrawer2['left']}
                onClose={toggleActivityDrawer2('left', false)}
                onOpen={toggleActivityDrawer2('left', true)}
              >
                <div className='p-3' style={{ width: '320px' }}>
                  <div className='d-flex'>
                    <Homepage />
                    <div className='d-flex align-items-center bg-black-20 py-2 px-3 rounded-8 ms-2 c-pointer'>
                      <img src={add} alt='indiaFlag' height={20} />
                    </div>
                  </div>

                  <div className='d-flex justify-content-between my-4'>
                    <div className='d-flex align-items-center'>
                      <img
                        src={gradientArrow}
                        className='c-pointer'
                        onClick={() => toggleActivityDrawer2('left', false)()}
                      />
                      <h6 className='text-lightBlue'>{name}</h6>
                    </div>

                    <div className='d-flex'>
                      <HomeCountry />
                      <div className='d-flex align-items-center bg-black-20 py-2 px-3 rounded-8 ms-2 c-pointer'>
                        <h5 className='mb-1'>...</h5>
                      </div>
                    </div>
                  </div>

                  <div className='bg-black-20 py-3 px-3 rounded-8 mb-3'>
                    <div
                      className='d-flex align-items-center justify-content-between c-pointer'
                      onClick={() => setShowSetting2((prevState) => !prevState)}
                    >
                      <div className='d-flex align-items-center'>
                        <img
                          src={user}
                          width={35}
                          height={30}
                          className='rounded-4 me-2'
                          style={{ objectFit: 'cover' }}
                        />
                        <p className='text-lightBlue fw-500'>Banner Image</p>
                      </div>
                      <ArrowDropDownIcon sx={{ color: '#C8D8FF' }} />
                    </div>
                    {showSetting2 && (
                      <div>
                        <ImageUpload />
                      </div>
                    )}
                  </div>
                </div>
              </SwipeableDrawer>
            </>
          )} */}

          {/* {showId == 7 && (
            <>
              <div className='bg-black-20 py-3 px-3 rounded-8 mb-3'>
                <div className='d-flex justify-content-between align-items-center'>
                  <p className='text-lightBlue'>Content</p>
                  <ArrowDropDownIcon />
                </div>

                <div className='my-2'>
                  {bannerData?.map((item) => (
                    <>
                      <div
                        key={item.id}
                        className='d-flex justify-content-between align-items-center theme-hover my-2'
                        onClick={() => handleTopBar2(item.name)}
                      >
                        <div>
                          <DragIndicatorIcon sx={{ color: '#5C6D8E' }} />
                          <img
                            src={item.image}
                            width={35}
                            height={30}
                            className='rounded-4'
                            style={{ objectFit: 'cover' }}
                          />
                          <small className='text-lightBlue fw-500 mx-2'>
                            {item.name}
                          </small>
                        </div>
                        <span className='edit-icon'>
                          <EditOutlinedIcon
                            sx={{ color: '#c8d8ff', fontSize: 14 }}
                          />
                        </span>
                      </div>
                    </>
                  ))}
                </div>
              </div>
              <SwipeableDrawer
                anchor='left'
                open={activityDrawer2['left']}
                onClose={toggleActivityDrawer2('left', false)}
                onOpen={toggleActivityDrawer2('left', true)}
              >
                <div className='p-3' style={{ width: '320px' }}>
                  <div className='d-flex'>
                    <Homepage />
                    <div className='d-flex align-items-center bg-black-20 py-2 px-3 rounded-8 ms-2 c-pointer'>
                      <img src={add} alt='indiaFlag' height={20} />
                    </div>
                  </div>

                  <div className='d-flex justify-content-between my-4'>
                    <div className='d-flex align-items-center'>
                      <img
                        src={gradientArrow}
                        className='c-pointer'
                        onClick={() => toggleActivityDrawer2('left', false)()}
                      />
                      <h6 className='text-lightBlue'>{name}</h6>
                    </div>

                    <div className='d-flex'>
                      <HomeCountry />
                      <div className='d-flex align-items-center bg-black-20 py-2 px-3 rounded-8 ms-2 c-pointer'>
                        <h5 className='mb-1'>...</h5>
                      </div>
                    </div>
                  </div>

                  <div className='bg-black-20 py-3 px-3 rounded-8 mb-3'>
                    <div
                      className='d-flex align-items-center justify-content-between c-pointer'
                      onClick={() => setShowSetting2((prevState) => !prevState)}
                    >
                      <div className='d-flex align-items-center'>
                        <img
                          src={user}
                          width={35}
                          height={30}
                          className='rounded-4 me-2'
                          style={{ objectFit: 'cover' }}
                        />
                        <p className='text-lightBlue fw-500'>Banner Image</p>
                      </div>
                      <ArrowDropDownIcon sx={{ color: '#C8D8FF' }} />
                    </div>
                    {showSetting2 && (
                      <div>
                        <ImageUpload />
                      </div>
                    )}
                  </div>
                </div>
              </SwipeableDrawer>
            </>
          )} */}
        </div>
      </SwipeableDrawer>
    </>
  );
};

export default MiddleThemeSide;
