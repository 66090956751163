import { useNavigate } from "react-router-dom";
import {
  Checkbox,
  CircularProgress,
  FormControl,
  FormHelperText,
  OutlinedInput,
  Tooltip,
} from "@mui/material";
import Container from "../../../components/Container/ContainerBG";
import SettingHeader from "../../../components/Setting/SettingHeader";
import StickyHeadTable from "../../../components/Setting/ScrollTable/ScrollTable";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import DashboardBox from "../../../components/Setting/Store/DashBoardBox";
import InputBox from "../../../components/Setting/InputBox";
import PayHeader from "../../../components/Setting/Payment/PayHeader";
import AddNewTax from "./AddNewTax";
import bharat from "../../../assets/flag/india.svg";
import usa from "../../../assets/flag/usa.svg";
import info from "../../../assets/icons/info.svg";
import viewMore from "../../../assets/setting/viewmore.svg";
import {
  useCreateOrUpdateGlobalGSTMutation,
  useGetGlobalGSTQuery,
} from "../../../features/settings/taxmanager/taxmanagerApiSlice";
import { useFormik } from "formik";
import { useMemo } from "react";
import * as Yup from "yup";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { showError, showSuccess } from "../../../features/snackbar/snackbarAction";
import { LoadingButton } from "@mui/lab";

const taxValidationSchema = Yup.object({
  _id: Yup.string().required(),
  taxRate: Yup.number()
    .min(0, "Tax has to be positive value")
    .typeError("Please Enter Number")
    .required("Required"),
  notes: Yup.string(),
  // priceMasterInclude: Yup.boolean().default(true),
  includeInPrice: Yup.boolean().default(true),
});

const TaxManager = () => {
  const dispatch = useDispatch();

  const { data: globalGSTData, isLoading: globalGSTIsLoading } = useGetGlobalGSTQuery();

  const [updateGlobalGST, { isLoading: updateGlobalGSTIsLoading }] =
    useCreateOrUpdateGlobalGSTMutation();

  const initialValues = useMemo(() => {
    const {
      _id = "",
      taxRate = 3,
      notes = "",
      // priceMasterInclude = true,
      includeInPrice = true,
    } = globalGSTData?.data?.data?.[0] ?? {};
    return {
      _id,
      taxRate,
      notes,
      // priceMasterInclude,
      includeInPrice,
    };
  }, [globalGSTData]);

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: taxValidationSchema,
    onSubmit: (values) => {
      updateGlobalGST(values)
        .unwrap()
        .then(() => {
          dispatch(showSuccess({ message: "Tax updated successfully!" }));
        })
        .catch((e) => {
          console.log(e);
          dispatch(
            showError({ message: e?.data?.message ?? e?.message ?? "Something went wrong!" })
          );
        });
    },
  });

  console.log(formik.errors);

  const isUnchanged = useMemo(
    () => _.isEqual(formik.values, formik.initialValues),
    [formik.values, formik.initialValues]
  );

  return (
    <form
      noValidate
      onSubmit={formik.handleSubmit}>
      <Container bgLevel={1}>
        {globalGSTIsLoading ? (
          <div className="row justify-content-center">
            <CircularProgress
              color="secondary"
              size={80}
            />
          </div>
        ) : (
          <div className="px-2 pt-3">
            <SettingHeader heading={"Tax and Duties"} />
            <hr className="hr-grey-6 my-4 w-100" />
            <Container bgLevel={2}>
              <div className="d-flex gap-3 px-2">
                <div className="w-100 my-2">
                  <div className="d-flex justify-content-between align-items-center mb-1">
                    <div className="d-flex">
                      <p className="text-lightBlue fw-500">GST Tax Rate</p>
                      <Tooltip
                        title="Some"
                        placement="top">
                        <img
                          src={info}
                          alt="info"
                          className="ms-2 c-pointer"
                          width={13.5}
                        />
                      </Tooltip>
                    </div>
                  </div>
                  <FormControl className="w-100 px-0">
                    <OutlinedInput
                      size="small"
                      name="taxRate"
                      value={formik.values.taxRate}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      endAdornment={<small className="bg-black-20 px-3 rounded-4">%</small>}
                    />
                    <FormHelperText error>
                      {formik.touched.taxRate && formik.errors.taxRate}
                    </FormHelperText>
                  </FormControl>
                </div>
                <div className="w-100 my-2">
                  <div className="d-flex justify-content-between align-items-center mb-1">
                    <div className="d-flex">
                      <p className="text-lightBlue fw-500">Internal Note</p>
                      <Tooltip
                        title="Some"
                        placement="top">
                        <img
                          src={info}
                          alt="info"
                          className="ms-2 c-pointer"
                          width={13.5}
                        />
                      </Tooltip>
                    </div>
                  </div>
                  <FormControl className="w-100 px-0">
                    <OutlinedInput
                      size="small"
                      name="notes"
                      value={formik.values.notes}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                    />
                    <FormHelperText error>
                      {formik.touched.notes && formik.errors.notes}
                    </FormHelperText>
                  </FormControl>
                </div>
              </div>
            </Container>
            <hr className="hr-grey-6 my-4 w-100" />
            <Container bgLevel={2}>
              <p className="text-lightBlue m-2">Tax Settings</p>

              <div className="d-flex align-items-start mb-3">
                <Checkbox
                  size="small"
                  name="includeInPrice"
                  checked={formik.values.includeInPrice}
                  onChange={(e) => formik.setFieldValue("includeInPrice", e.target.checked)}
                  sx={{ color: "#C8D8FF" }}
                />
                <div className="mt-1">
                  <small className="text-lightBlue">Include tax in prices</small>
                  <div className="ex-small text-grey-6 w-75">
                    Lorem ipsum dolor sit amet consectetur. Massa ac purus ut semper. Eget sed
                    faucibus leo auctor ipsum. Elit mauris fermentum rhoncus nisi feugiat dui.
                    Nibh mauris purus lectus velit fusce.
                  </div>
                </div>
              </div>

              {/* <div className="d-flex align-items-start mb-3">
                <Checkbox
                  size="small"
                  name="priceMasterInclude"
                  checked={formik.values.priceMasterInclude}
                  onChange={formik.handleChange}
                  sx={{ color: "#C8D8FF" }}
                />
                <div className="mt-1">
                  <small className="text-lightBlue">Include tax in price master data</small>
                  <div className="ex-small text-grey-6 w-75">
                    Lorem ipsum dolor sit amet consectetur. Massa ac purus ut semper. Eget sed
                    faucibus leo auctor ipsum. Elit mauris fermentum rhoncus nisi feugiat dui.
                    Nibh mauris purus lectus velit fusce.
                  </div>
                </div>
              </div> */}
            </Container>
          </div>
        )}

        {!isUnchanged && (
          <div className="row create-buttons pt-5 pb-3">
            <div className="col-12 d-flex justify-content-center">
              <LoadingButton
                type="submit"
                loading={updateGlobalGSTIsLoading}
                disabled={updateGlobalGSTIsLoading}
                className="button-gradient py-2 px-4">
                <p>Save Changes</p>
              </LoadingButton>
            </div>
          </div>
        )}
      </Container>
    </form>
  );
};

export default TaxManager;
