import React from "react";
import AlertDialog from "../AlertDialog/AlertDialog";
import archiveIcon from "../../assets/images/Components/Archived.png";

function ArchiveModal(props) {
  const {
    onConfirm,
    onCancel,
    show,
    isLoading,
    message,
    title,
    products,
    archiveType,
    primaryMessage,
  } = props;
  return (
    <AlertDialog
      onConfirm={onConfirm}
      onCancel={onCancel}
      show={show}
      title={title ? `Archive ${title} ?` : "Archive ?"}
      primaryMessage={
        primaryMessage
          ? primaryMessage
          : `This will Archive <span className='text-blue-2'>${products}</span> ${
              message
                ? `in <span className='text-blue-2'>${message}</span> ${archiveType}`
                : "selected"
            } will be unassigned from it. Would you like to Archive this ${title} ?`
      }
      confirmText="Archive"
      isLoading={isLoading}
      icon={archiveIcon}
    />
  );
}

function MultipleArchiveModal(props) {
  const { onConfirm, onCancel, show, isLoading, message, title, pronoun } = props;
  return (
    <AlertDialog
      onConfirm={onConfirm}
      onCancel={onCancel}
      show={show}
      title={title ? `Archive ${title} ?` : "Archive?"}
      primaryMessage={`This will Archive ${
        message ? `<span className='text-blue-2'>${message}</span>` : "selected"
      } from the dashboard. `}
      secondaryMessage={`Would you like to Archive ${pronoun} ?`}
      confirmText="Archive"
      isLoading={isLoading}
      icon={archiveIcon}
    />
  );
}

const ArchiveModalSecondary = (props) => {
  const {
    onConfirm,
    onCancel,
    show,
    isLoading,
    message,
    title,
    afterText = "from the dashboard",
  } = props;

  return (
    <AlertDialog
      onConfirm={onConfirm}
      onCancel={onCancel}
      show={show}
      title={title ? `Archive ${title} ?` : "Archive ?"}
      primaryMessage={`This will archive ${message ? `${message}` : "selected"} ${afterText}.`}
      confirmText="Archive"
      isLoading={isLoading}
      icon={archiveIcon}
    />
  );
};

const ArchiveModal2 = (props) => {
  const { onConfirm, onCancel, show, isLoading, message, title, products, archiveType } = props;
  return (
    <AlertDialog
      onConfirm={onConfirm}
      onCancel={onCancel}
      show={show}
      title={`Archive ${archiveType}`}
      primaryMessage={`Are you sure you want to archive <span className='text-blue-2'>"${message}"</span>  ${archiveType}?`}
      confirmText="Archive"
      isLoading={isLoading}
      icon={archiveIcon}
    />
  );
};

export default ArchiveModal;
export { MultipleArchiveModal, ArchiveModalSecondary, ArchiveModal2 };
