import React, { useEffect, useReducer, useState } from "react";
// ! COMPONENT IMPORTS
import { EnhancedTableHead } from "../../../../../components/TableDependencies/TableDependencies";
// ! IMAGES IMPORTS
import verticalDots from "../../../../../assets/icons/verticalDots.svg";
import ringSmall from "../../../../../assets/images/ringSmall.svg";
// import arrowDown from "../../../../../assets/icons/arrowDown.svg";
// import arrowDownBlack from "../../../../../assets/icons/arrowDownBlack.svg";
// import editButton from "../../../../../assets/icons/editButton.svg";
// import duplicateButton from "../../../../../assets/icons/duplicateButton.svg";
// import deleteRed from "../../../../../assets/icons/delete.svg";
// ! MATERIAL IMPORTS
import {
  Button,
  Checkbox,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from "@mui/material";
import {
  useGetWishlistProductsQuery,
  useUpdateProductMutation,
  useUpdateWishlistProductsMutation,
} from "../../../../../features/products/product/productApiSlice";
import TableLoader from "../../../../../components/Loader/TableLoader";
import NoDataFound from "../../../../../components/NoDataFound/NoDataFound";
import AsyncImage from "../../../../../components/AsyncImage/AsyncImage";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { showError, showSuccess } from "../../../../../features/snackbar/snackbarAction";
import { Link } from "react-router-dom";

const headCells = [
  {
    id: "productName",
    numeric: false,
    disablePadding: true,
    label: "Product Name",
  },
  {
    id: "category",
    numeric: false,
    disablePadding: false,
    label: "Category",
  },
  {
    id: "price",
    numeric: false,
    disablePadding: false,
    label: "Price",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: true,
    label: "Actions",
  },
];

const initialQueryFilterState = {
  pageSize: 5,
  pageNo: 1,
  createdAt: "-1",
  customer: "",
};

const queryFilterReducer = (state, action) => {
  if (action.type === "SET_PAGE_SIZE") {
    return {
      ...state,
      pageNo: initialQueryFilterState.pageNo,
      pageSize: +action.pageSize,
    };
  }
  if (action.type === "CHANGE_PAGE") {
    return {
      ...state,
      pageNo: +action.pageNo,
    };
  }
  if (action.type === "CUSTID") {
    return {
      ...state,
      pageNo: initialQueryFilterState.pageNo,
      customer: action.customer,
    };
  }
  if (action.type === "SET_ALL_FILTERS") {
    return {
      ...initialQueryFilterState,
      ...state,
      ...action.filter,
    };
  }
  return initialQueryFilterState;
};

const UserWishlistTable = ({ customerId = "" }) => {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState([]);
  const [queryFilterState, dispatchQueryFilter] = useReducer(
    queryFilterReducer,
    initialQueryFilterState
  );
  const [updateWishlistProducts, { isLoading: updateWishlistProductsIsLoading }] =
    useUpdateWishlistProductsMutation();
  const { data: productsData, isLoading: productsIsLoading } = useGetWishlistProductsQuery(
    queryFilterState,
    { skip: !queryFilterState.customer }
  );
  const wishlistId = productsData?.data?.[0]?._id ?? "";
  const allProducts = productsData?.data?.[0]?.items?.filter((it) => !!it?._id) ?? [];
  const totalProductCount = productsData?.totalCount ?? 0;
  const removeItemFromWishlist = (items = []) => {
    updateWishlistProducts({ id: wishlistId, details: { items } })
      .then(() => {
        dispatch(showSuccess({ message: "Items removed successfully" }));
      })
      .catch((e) =>
        dispatch(
          showError({ message: e?.data?.message ?? e?.message ?? "Something went wrong" })
        )
      );
  };
  useEffect(() => dispatchQueryFilter({ type: "CUSTID", customer: customerId }), [customerId]);
  return (
    <>
      {selected.length > 0 && (
        <div className="d-flex justify-content-between align-items-center px-2 mb-3">
          <div className="d-flex">
            <button className="button-grey py-2 px-3">
              <small className="text-lightBlue">
                {selected.length} items are selected&nbsp;
                <span
                  className="text-blue-2 c-pointer"
                  onClick={() => setSelected([])}>
                  (Clear Selection)
                </span>
              </small>
            </button>

            <button
              className="button-grey py-2 px-3 ms-2"
              variant="contained"
              onClick={() => {
                removeItemFromWishlist(selected);
                setSelected([]);
              }}>
              <small className="text-lightBlue">Remove Selected</small>
            </button>
          </div>
        </div>
      )}
      {productsIsLoading ? (
        <span className="d-flex justify-content-center m-3">
          <TableLoader />
        </span>
      ) : allProducts.length === 0 ? (
        <span className="d-flex justify-content-center m-3">
          <NoDataFound />
        </span>
      ) : (
        <>
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size="medium">
              <EnhancedTableHead
                order={"asc"}
                orderBy={"productName"}
                numSelected={selected.length}
                onSelectAllClick={(e) =>
                  setSelected(e.target.checked ? allProducts.map((p) => p._id) : [])
                }
                onRequestSort={Date}
                rowCount={allProducts.length}
                headCells={headCells}
              />
              <TableBody>
                {allProducts.map((row) => {
                  const isItemSelected = selected.includes(row._id);
                  const imageUrl =
                    row?.product?.mediaUrl?.find?.((mu) => mu.isDefault)?.image ?? "";
                  // console.log(row);
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row._id}
                      selected={isItemSelected}
                      className="table-rows">
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          onClick={(e) =>
                            setSelected((sel) =>
                              e.target.checked
                                ? sel.concat(row._id)
                                : sel.filter((s) => s !== row._id)
                            )
                          }
                          size="small"
                          style={{ color: "#5C6D8E" }}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        padding="none">
                        <Link
                          to={`/products/allProducts/editProduct/${row?.product?._id}`}
                          className="text-decoration-none">
                          <div className="d-flex align-items-center">
                            <AsyncImage
                              src={imageUrl}
                              placeholder={ringSmall}
                              alt="product"
                              className="me-2 rounded-4"
                              height={45}
                              width={45}
                              style={{ objectFit: "cover" }}
                            />
                            <div>
                              <p className="text-lightBlue fw-600">{row?.product?.title}</p>
                              <small className="mt-2 text-grey-6">{row?.variant?.title}</small>
                            </div>
                          </div>
                        </Link>
                      </TableCell>
                      <TableCell>
                        <p className="text-lightBlue">
                          {row?.product?.productType?.category?.name}
                        </p>
                      </TableCell>
                      <TableCell>
                        <div className="d-flex align-items-center">
                          <p className="text-lightBlue">
                            {row?.product?.price?.dynamicPricing
                              ? "Depends on Variant"
                              : `₹${
                                  row?.product?.price?.isGstInclude
                                    ? row?.product?.price?.salePrice +
                                        row?.product?.price?.gstPrice || 0
                                    : row?.product?.price?.salePrice
                                }`}
                          </p>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="d-flex align-items-center">
                          <div
                            className="rounded-pill d-flex table-status px-2 py-1"
                            style={{
                              background:
                                row?.product?.status === "active"
                                  ? "#A6FAAF"
                                  : row?.product?.status === "in-active"
                                  ? "#F67476"
                                  : row?.product?.status === "archived"
                                  ? "#C8D8FF"
                                  : "#FEE1A3",
                            }}>
                            <small className="text-black fw-400">
                              {row?.product?.status === "active"
                                ? "Active"
                                : row?.product?.status === "in-active"
                                ? "Draft"
                                : row?.product?.status === "archived"
                                ? "Archived"
                                : "Scheduled"}
                            </small>
                          </div>
                          {row?.product?.status === "scheduled" && (
                            <div>
                              <small className="text-blue-2">
                                {row?.product?.startDate && (
                                  <>
                                    for {moment(row?.product?.startDate).format("DD/MM/YYYY")}
                                  </>
                                )}
                                {row?.product?.startDate && row?.product?.endDate && " "}
                                {row?.product?.endDate && (
                                  <>till {moment(row?.product?.endDate).format("DD/MM/YYYY")}</>
                                )}
                              </small>
                            </div>
                          )}
                        </div>
                      </TableCell>
                      <TableCell>
                        <Button
                          color="primary"
                          variant="text"
                          disabled={updateWishlistProductsIsLoading}
                          onClick={() => removeItemFromWishlist([row._id])}>
                          <span className="text-red-5">Remove</span>
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalProductCount}
            rowsPerPage={queryFilterState.pageSize}
            page={queryFilterState.pageNo - 1}
            onPageChange={(_, pageNo) => {
              setSelected([]);
              dispatchQueryFilter({ type: "CHANGE_PAGE", pageNo: pageNo + 1 });
            }}
            onRowsPerPageChange={(e) =>
              dispatchQueryFilter({
                type: "SET_PAGE_SIZE",
                pageSize: e.target.value,
              })
            }
            className="table-pagination"
          />
        </>
      )}
    </>
  );
};

export default UserWishlistTable;
