import postAnalyticsApiSlice from "../../app/api/posthogAnalyticsApiSlice";
import { omitEmptyKeys } from "../../utils/helper";

export const insightsApiSlice = postAnalyticsApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getInsitesAnalytics: builder.query({
      query: (queries) => ({
        url: "/api/projects/97727/insights",
        params: omitEmptyKeys(queries),
      }),
      transformResponse: (response) => {
        return response;
      },
    }),

    getDeviceAnalytics: builder.query({
      query: (queries) => ({
        url: "/api/projects/97727/session_recordings",
        params: omitEmptyKeys(queries),
      }),
      transformResponse: (response) => {
        return response;
      },
    }),
  }),
});

export const {
  useGetInsitesAnalyticsQuery,
  useGetDeviceAnalyticsQuery
} = insightsApiSlice;
