import { FormControl, OutlinedInput } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

import "./Input.scss";

export default function OnBoardInput({ placeholder, startAdornment, endAdornment }) {
    return (
        <div className="onBoardInput w-100">
            <FormControl className="w-100 px-0">
                <OutlinedInput
                    placeholder={placeholder}
                    size="small"
                    startAdornment={
                        startAdornment && <div className="pe-2">
                            <CheckCircleOutlineIcon
                                style={{ color: "green" }}
                                sx={{
                                    fontSize: "medium",
                                }}
                            />
                        </div>
                    }
                    endAdornment={
                        endAdornment && <small className="text-grey-6">
                            .unthread.com
                        </small>
                    }
                />
            </FormControl>
        </div>
    );
};