import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "./AppTextEditor.css";
import UseMultipleFileUpload from "../../features/fileUpload/multipleFileUploadHook";
import { useSelector } from "react-redux";
import store from "../../app/store";

const API_URL = process.env.REACT_APP_BASE_URL;
const UPLOAD_ENDPOINT = "uploads";
const UPLOAD_FILE_ENDPOINT = "unthread-assests";

const AppTextEditor = ({
  config = {},
  value,
  setFieldValue = (_) => {},
  height = "15rem",
}) => {
  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          // const body = new FormData();
          loader.file.then((file) => {
            const token = store.getState().auth.accessToken;
            // token && headers.set("authorization", `Bearer ${token}`);
            console.log(file, "fileData");
            // body.append("files", file);

            // let headers = new Headers();
            // headers.append("Origin", "http://localhost:3000");
            //

            // mimetype: file.type,
            // folderid: file.folderId ?? "",
            // filetype: file.type.slice(0, file.type.indexOf("/")),
            // module: uploadState.module,
            // ismandatory: uploadState.isMandatory,
            const paramsUrl = `?filename=${file?.name}&filesize=${
              file?.size
            }&mimetype=${file?.type}&folderid=${
              file?.folderId || ""
            }&filetype=${file?.type?.slice(
              0,
              file?.type?.indexOf("/")
            )}&module=others&ismandatory=true`;
            console.log(API_URL, UPLOAD_ENDPOINT, paramsUrl, "uploadData Url");
            fetch(`${API_URL}/${UPLOAD_ENDPOINT}${paramsUrl}`, {
              headers: {
                authorization: `Bearer ${token}`,
              },
            })
              .then((res) => res.json())
              .then((res) => {
                const fd = new FormData();
                console.log(res, "response data upload");
                const { fields, fileId, url } = res.data;
                for (const [key, val] of Object.entries(fields)) {
                  fd.append(key, val);
                }
                fd.append("file", file);
                fetch(
                  `${API_URL.replace("/dev", "")}/${UPLOAD_FILE_ENDPOINT}`,
                  {
                    method: "post",
                    body: fd,
                    headers: {
                      authorization: `Bearer ${token}`,
                    },
                    // mode: "no-cors"
                  }
                )
                  .then((res) => res.json())
                  .then(() => {
                    resolve({
                      default: `${url}/${fields.key}`,
                    });
                  })
                  .catch((e) => {});
              })
              .catch((err) => {
                reject(err);
              });
          });
        });
      },
    };
  }
  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }
  return (
    <CKEditor
      editor={ClassicEditor}
      config={{
        ...config,
        extraPlugins: [...(config?.extraPlugins || []), uploadPlugin],
      }}
      data={value}
      onReady={(editor) => {
        editor.editing.view.change((writer) => {
          writer.setStyle(
            "height",
            height,
            editor.editing.view.document.getRoot()
          );
        });
      }}
      onChange={(_, editor) => {
        const data = editor.getData();
        setFieldValue(data);
      }}
    />
  );
};

export default AppTextEditor;
