import { useState, useEffect } from "react";

import { isImgUrl } from "../../utils/helper";

const AsyncImage = (props) => {
  const [src, setSrc] = useState(props.placeholder);
  useEffect(() => {
    props.src &&
      isImgUrl(props.src).then((isValid) => {
        isValid && setSrc(props.src);
      });
  }, [props.src]);

  return <img alt="img" {...props} src={src} />;
};

export default AsyncImage;
