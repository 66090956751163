import { useNavigate } from "react-router-dom";
import earth from "../../../assets/images/earth.svg";

export default function StepFour() {
  const navigate = useNavigate();

  // const handleContinue = () => {
  //     navigate("/auth/stepTwo");
  // }

  return (
    <div className="bg-black-22 webkit-scroll-hide stepFour">
      <div className="d-flex justify-content-center">
        <div>
          <img
            src={earth}
            width={420}
          />
          {/* <h3 className="text-blue-gradient fw-600 text-center">
                        Fetching Data
                    </h3> */}
          <div className="rotating-text-wrapper">
            <h3 className="fw-600">Fetching Data</h3>
            <h3 className="fw-600">Customizing store for you</h3>
            <h3 className="fw-600">Creating Global Store</h3>
            <h3 className="fw-600">Your Store is ready</h3>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <div>
          <p className="text-blue-gradient fw-600 mb-2 text-center">Do you know ?</p>
          <div className="d-flex justify-content-center">
            <p className="text-grey-6 text-center w-75">
              After covid 19, Total sales of international website have been increased to 50%
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
