import React from "react";
// ! IMAGES IMPORTS
import razorpay from "../../assets/icons/razorpay.png";
import payuIcon from "../../assets/icons/PayU.svg";
import cardIcon from "../../assets/icons/cardIcon.svg";
// ! MATERIAL IMPORTS
import { Popover, Tooltip } from "@mui/material";
// ! MATERIAL ICONS IMPORTS
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import moment from "moment/moment";
import { PaymentStatuses } from "./AllOrders/FilterOrders";
import { rupeeFormatWithSymbol } from "../../utils/format";
import { useDispatch } from "react-redux";
import { showInfo } from "../../features/snackbar/snackbarAction";

const OrderPayments = ({ showMarkAsPaidButton, showCollectPaymentButton, data }) => {
  // * PAYMENT POPOVERS STARTS
  const [anchorPaymentEl, setAnchorPaymentEl] = React.useState(null);
  const handlePaymentClick = (event) => {
    setAnchorPaymentEl(event.currentTarget);
  };

  const handlePaymentClose = () => {
    setAnchorPaymentEl(null);
  };

  const openPayment = Boolean(anchorPaymentEl);
  const idPayment = openPayment ? "simple-popover" : undefined;
  // * PAYMENT POPOVERS ENDS

  const tranx = data?.transactions;

  const paymentStatusObject = PaymentStatuses.find((os) => os.value === data?.paymentStatus);

  const dispatch = useDispatch();

  const handleSubmit = (message) => {
    dispatch(showInfo(message));
  }

  return (
    <div className="bg-black-15 border-grey-5 rounded-8 p-3 row attributes mt-4">
      <div className="col-12 px-0">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <img
              src={cardIcon}
              alt="userIcon"
              width={26}
            />
            <h6 className="text-lightBlue fw-500 ms-2">Payment</h6>
            <div
              className="rounded-pill d-flex px-2 py-1 ms-4 statusBoxWidth"
              style={{ background: paymentStatusObject?.color }}>
              <small className="text-black fw-400">{paymentStatusObject?.text}</small>
            </div>
          </div>
          {!tranx && paymentStatusObject?.text?.toLowerCase() !== "success" && (
            <button className="button-lightBlue-outline py-1 px-4 me-3" onClick={()=> handleSubmit("Feature Under Development")}>
              <p>Send Payment Link</p>
            </button>
          )}
        </div>
      </div>
      {!!tranx && (
        <>
          {tranx.map((transaction, index) => (
            <div className="col-12 px-0" key={transaction._id}>
              <div className="d-flex justify-content-between align-items-center mt-3">
                <h6 className="text-lightBlue">
                  Transaction {index + 1} of {tranx.length}
                </h6>
              </div>
              <div className="row">
                {/* <div className="col-md-3 col-6 mt-3">
                  <small className="text-grey-6 mb-1 d-block">Gateway</small>
                  <img
                    src={razorpay}
                    alt="razorpay"
                    height={35}
                    className="p-2"
                    style={{
                      background: "#e5c4fc",
                      borderRadius: "5px",
                    }}
                  />
                </div> */}
                <div className="col-md-3 col-6 mt-3">
                  <small className="text-grey-6 mb-1 d-block">Payment Detail</small>
                  <div className="d-flex">
                    <p className="text-lightBlue me-2">{rupeeFormatWithSymbol(transaction.amount)}</p>
                    <p className="text-[10px] text-grey-6">via {transaction.mode || "Razorpay"}</p>
                  </div>
                </div>
                <div className="col-md-3 col-6 mt-3">
                  <small className="text-grey-6 mb-1 d-block">Payment Timestamp</small>
                  <p className="text-lightBlue">
                    {moment(transaction.updatedAt).format("DD MMM, YYYY [at] hh:mm A")}
                  </p>
                </div>
                <div className="col-md-3 col-6 mt-3">
                  <small className="text-grey-6 mb-1 d-block">Transaction ID</small>
                  <div className="d-flex align-items-center">
                    <p className="text-lightBlue me-2">{transaction.paymentId || "N/A"}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </>
      )}

      {(showMarkAsPaidButton || showCollectPaymentButton) && (
        <div className="col-12 d-flex justify-content-center px-0">
          <hr className="hr-grey-6 w-100 my-3" />
        </div>
      )}
      <div className="col-12 d-flex px-0">
        {showMarkAsPaidButton && (
          <button className="button-lightBlue-outline py-2 px-4 me-3">
            <p>Mark as Paid</p>
          </button>
        )}
        {showCollectPaymentButton && (
          <React.Fragment>
            <button
              className="button-gradient py-2 px-4"
              aria-describedby={idPayment}
              variant="contained"
              onClick={handlePaymentClick}>
              <p>Collect Payment</p>
            </button>
            <Popover
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              id={idPayment}
              open={openPayment}
              anchorEl={anchorPaymentEl}
              onClose={handlePaymentClose}>
              <div className="py-2 px-2">
                <div className="d-flex align-items-center justify-content-between mb-2 text-lightBlue">
                  <small>Send Payment Link</small>
                </div>
                <div className="d-flex align-items-center justify-content-between mt-2 text-lightBlue">
                  <small>Collect Cash</small>
                </div>
              </div>
            </Popover>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default OrderPayments;
