import apiSlice from "../../app/api/apiSlice";

import { omitNullishKeys } from "../../utils/helper";

const storeApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getNearStores: builder.query({
      query: (queries) => {
        return {
          url: "/allstores",
          params: omitNullishKeys(queries),
        };
      },
      transformResponse: (res) => res.data,
      providesTags: ["NearStores"],
    }),
    createNearStore: builder.mutation({
      query: (storeDetails) => ({
        url: "/allStores",
        method: "POST",
        body: storeDetails,
      }),
      invalidatesTags: ["NearStore", "Logs"],
    }),
    deleteNearStore: builder.mutation({
      query: (policyId) => ({
        url: `/allStores/${policyId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["NearStore", "Logs"],
    }),
    editNearStore: builder.mutation({
      query: ({ id, details }) => ({
        url: `/allStores/${id}`,
        method: "PUT",
        body: details,
      }),
      invalidatesTags: ["NearStore", "Logs"],
    }),
  }),
});

export const {
  useGetNearStoresQuery,
  useCreateNearStoreMutation,
  useDeleteNearStoreMutation,
  useEditNearStoreMutation,
} = storeApiSlice;
