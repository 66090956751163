const LinearProgressBar = ({ percent }) => {
    const greyBox = 100 - percent;

    return (
        <div className="d-flex w-100">
            <div className="button-gradient me-1" style={{ width: `${percent}%`, height: "4px" }}>
            </div>
            <div className="bg-black-20 rounded-4" style={{ width: `${greyBox}%`, height: "4px" }}>
            </div>
        </div>
    );
};

export default LinearProgressBar;