import "./PriceMaster.scss";
import { useFormik } from "formik";
import * as Yup from "yup";
// import _ from "lodash";
// ! COMPONENT IMPORTS
// import RingMetal from "./RingMetal";
import RingStones from "./RingStones";
// ! IMAGES IMPORTS
import labelIcon from "../../../../assets/images/products/label.svg";
import infoIcon from "../../../../assets/icons/info.svg";
// ! MATERIAL IMPORTS
import { Tooltip, Button } from "@mui/material";
// ! MATERIAL ICONS IMPORTS
import TableLoader from "../../../../components/Loader/TableLoader";
import {
  useCreateWithPriceMasterMutation,
  useGetAllVariantsQuery,
  useGetVariantsOnCallMutation,
} from "../../../../features/products/product/varianceApiSlice";
import { LoadingButton } from "@mui/lab";
import { useDispatch } from "react-redux";
import { showError, showSuccess } from "../../../../features/snackbar/snackbarAction";
import { useParams } from "react-router-dom";
import PageLoader from "../../../../components/Loader/PageLoader";
import { useEffect } from "react";

// const metalSchema = Yup.object({
//   weight: Yup.number().typeError("Please Enter Number").min(0).required("required"),
//   netWeight: Yup.number().typeError("Please Enter Number").min(0).required("required"),
// });

const stoneSchema = Yup.object({
  metaSubAttribute1: Yup.string().required("required"),
  metaSubAttributeValue1: Yup.string().required("required"),
  metaSubAttribute2: Yup.string().required("required"),
  metaSubAttributeValue2: Yup.string().required("required"),
  size: Yup.number().typeError("Please Enter Number").min(0).required("required"),
  peices: Yup.number().typeError("Please Enter Number").min(0).required("required"),
  weight: Yup.number().typeError("Please Enter Number").min(0).required("required"),
  // caratWeight: Yup.number("Please Enter Number").required("required"),
});

const validationSchema = Yup.array().of(
  Yup.object({
    product: Yup.string().required("required"),
    attribute: Yup.string().required("required"),
    attributeTitle: Yup.string().required("required"),
    subAttributes: Yup.array().of(stoneSchema),
    // subAttributes: Yup.array().when("attributeTitle", {
    //   is: "metal",
    //   then: (schema) => schema.of(metalSchema),
    //   otherwise: (schema) => schema.of(stoneSchema),
    // }),
  })
);

export default function PriceMaster({ onNext = () => {}, onBack = () => {} }) {
  const dispatch = useDispatch();
  const { id: productId = "64a40e6af059a3fb74362f04" } = useParams();

  const [createPriceMaster, { isLoading: createPriceMasterIsLoading }] =
    useCreateWithPriceMasterMutation();

  // const { data: allVariantsData, isLoading: allVariantsIsLoading } = useGetAllVariantsQuery({
  //   productId,
  //   isDefault: true,
  // });

  const [getVarients, { data: allVariantsData, isLoading: allVariantsIsLoading }] =
    useGetVariantsOnCallMutation();

  useEffect(() => {
    getVarients({ productId });
  }, [getVarients, productId]);

  const productAttributes = allVariantsData?.data?.data?.[0]?.attributes ?? [];
  const productGlobalPriceManager = allVariantsData?.data?.data?.[0]?.globalPriceManager ?? [];

  console.log(productAttributes, productGlobalPriceManager);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: productAttributes
      .filter(
        (par) => par.title === "diamond" || par.title === "gemstone" || par.title === "pearl"
      )
      .map((par) => ({
        product: productId,
        attribute: par._id,
        attributeTitle: par.title,
        // showStoneInfo: par.title === "metal",
        // subAttributes: par.title === "metal" ? [{ weight: "", netWeight: "" }] : [],
        // subAttributes: [],
        subAttributes: productGlobalPriceManager
          .filter((pgpm) => pgpm.attribute === par._id)
          .map((pgpm) => {
            const {
              metaSubAttribute1,
              metaSubAttribute2,
              metaSubAttributeValue1,
              metaSubAttributeValue2,
              peices,
              size,
              weight,
            } = pgpm;
            return {
              metaSubAttribute1,
              metaSubAttribute2,
              metaSubAttributeValue1,
              metaSubAttributeValue2,
              peices,
              size,
              weight,
            };
          }),
      })),
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const data = values.flatMap((val) => {
        // const { product, attribute, attributeTitle, subAttributes } = val;
        // return attributeTitle !== "metal"
        //   ? subAttributes.map((sub) => ({ ...sub, weight: 1, product, attribute }))
        //   : [];
        const { product, attribute, subAttributes } = val;
        return subAttributes.map((sub) => ({ ...sub, product, attribute }));
      });

      if (data.length > 0) {
        createPriceMaster(data)
          .unwrap()
          .then(() => {
            onNext();
            dispatch(
              showSuccess({
                message: "Price Master saved successfully",
              })
            );
          })
          .catch((error) => {
            dispatch(
              showError({
                message:
                  error?.data?.message ??
                  error.message ??
                  "Something went wrong!, please try again",
              })
            );
          });
      } else {
        // onNext();
        dispatch(
          showError({
            message: "All PriceMaster data can't be deleted.",
          })
        );
      }
    },
  });

  // console.log(
  //   JSON.stringify(
  //     {
  //       // values: formik.values,
  //       // errors: formik.errors,
  //       // touched: formik.touched,
  //     },
  //     null,
  //     1
  //   )
  // );

  return allVariantsIsLoading ? (
    <PageLoader />
  ) : (
    <div className="bg-black-15 border-grey-5 rounded-8 p-3 mb-3 row price-master">
      <div className="d-flex col-12 px-0 justify-content-between align-items-center">
        <div className="d-flex flex-column">
          <div className="d-flex align-items-center">
            <h6 className="text-lightBlue fw-500 me-2">Price Master Data</h6>
            <Tooltip
              title="Lorem ipsum"
              placement="top">
              <img
                src={infoIcon}
                alt="info"
                className="c-pointer"
                width={13.5}
              />
            </Tooltip>
          </div>
          <small className="text-grey-6 mt-2">
            Enter all the data related to jewelery product
          </small>
        </div>
        {/* <div className="d-flex">
          <Button variant="text">
            <span className="text-blue-2">View Price Master</span>
          </Button>
        </div> */}
      </div>

      <hr className="hr-grey-6 mt-3 mb-3" />

      {allVariantsIsLoading ? (
        <TableLoader />
      ) : (
        <form
          noValidate
          className="col-12"
          onSubmit={formik.handleSubmit}>
          {formik.values.map((par, ix) => {
            // if (par.attributeTitle === "metal") {
            //   // Metal Section
            //   return (
            //     <RingMetal
            //       ix={ix}
            //       key={par.attribute}
            //       icon={labelIcon}
            //       formik={formik}
            //     />
            //   );
            // } else {
            // Stone Section
            return (
              <RingStones
                ix={ix}
                key={par.attribute}
                icon={labelIcon}
                formik={formik}
              />
            );
            // }
          })}
          <div className="row align-items-center mt-4">
            <div className="col-12 d-flex justify-content-between">
              <button
                type="reset"
                onClick={() => onBack(false)}
                className="button-red-outline py-2 px-4">
                <p>Previous</p>
              </button>
              <LoadingButton
                type="submit"
                // onClick={nextStep}
                loading={createPriceMasterIsLoading}
                disabled={createPriceMasterIsLoading}
                className="button-gradient py-2 px-4">
                <p>Next</p>
              </LoadingButton>
            </div>
          </div>
        </form>
      )}
    </div>
  );
}
