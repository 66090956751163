import "./AllFiles.scss";
import info from "../../../../assets/icons/info.svg";
import video from "../../../../assets/images/dashboard/video.png";
import folderLargePurple from "../../../../assets/icons/folderLargePurple.svg";
import { Table, TableBody, TableContainer, TablePagination, Tooltip } from "@mui/material";
import ImageIconView from "../FileManagerViews/ImageIconView";
import VideoIconView from "../FileManagerViews/VideoIconView";
import FolderIconView from "../FileManagerViews/FolderIconView";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import {
  useBulkDeleteFilesMutation,
  useBulkDeleteFoldersMutation,
  useBulkEditFileMutation,
  useDeleteFileMutation,
  useDeleteFolderMutation,
  useEditFileMutation,
  useEditFolderMutation,
  useGetFilesQuery,
  useGetFoldersQuery,
} from "../../../../features/settings/filemanager/filemanagerApiSlice";
import TableMassActionButton from "../../../../components/TableMassActionButton/TableMassActionButton";
import NameRenameDialog from "../Dialogs/NameRenameDialog";
import { showError, showSuccess } from "../../../../features/snackbar/snackbarAction";
import DeleteAlertDialog from "../Dialogs/DeleteAlertDialog";
import MoveInFolderDialog from "../Dialogs/MoveInFolderDialog";
import { EnhancedTableHead } from "../../../../components/TableDependencies/TableDependencies";
import FolderListView from "../FileManagerViews/FolderListView";
import ImageListView from "../FileManagerViews/ImageListView";
import VideoListView from "../FileManagerViews/VideoListView";
import TableLoader from "../../../../components/Loader/TableLoader";
import NoDataFound from "../../../../components/NoDataFound/NoDataFound";
import apiSlice from "../../../../app/api/apiSlice";
import JSZip from "jszip";

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
  },
  {
    id: "type",
    numeric: false,
    disablePadding: false,
    label: "Type",
  },
  {
    id: "size",
    numeric: false,
    disablePadding: false,
    label: "Size",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    label: "Actions",
  },
];

export default function AllFiles({
  views = "icon",
  queryFilters = {},
  onPopup = () => {},
  onExplore = () => {},
  // changeTab = () => {},
}) {
  // const seeAllFolders = () => changeTab(null, 1);
  // const seeAllImages = () => changeTab(null, 2);
  // const seeAllVideos = () => changeTab(null, 3);

  const dispatch = useDispatch();

  const [folderPageNo, setFolderPageNo] = useState(0);
  const handleFolderPageNo = (_, no) => setFolderPageNo(no);
  const [folderPageSize, setFolderPageSize] = useState(8);
  const handleFolderPageSize = (e) => setFolderPageSize(parseInt(e.target.value, 10));

  const [folderSelected, setFolderSelected] = useState([]);
  const clearfolderSelected = () => setFolderSelected([]);

  const { data: allFoldersData, isLoading: allFoldersIsLoading } = useGetFoldersQuery({
    ...queryFilters,
    pageNo: folderPageNo + 1,
    pageSize: folderPageSize,
  });
  const allFolders = allFoldersData?.data?.data ?? [];
  const foldersCount = allFoldersData?.data?.totalCount ?? 0;

  const [editFolder, { isLoading: editFolderIsLoading }] = useEditFolderMutation();
  const [deleteFolder, { isLoading: deleteFolderIsLoading }] = useDeleteFolderMutation();
  const [bulkDeleteFolders, { isLoading: bulkDeleteFoldersIsLoading }] = useBulkDeleteFoldersMutation();

  const [renamingFolder, setRenamingFolder] = useState(null);
  const [deletingFolder, setDeletingFolder] = useState(null);

  // Files Stuff

  const [filePageNo, setFilePageNo] = useState(0);
  const handleFilePageNo = (_, no) => setFilePageNo(no);
  const [filePageSize, setFilePageSize] = useState(12);
  const handleFilePageSize = (e) => setFilePageSize(parseInt(e.target.value, 10));

  const [fileSelected, setFileSelected] = useState([]);
  const clearfileSelected = () => setFileSelected([]);

  const { data: allFilesData, isLoading: allFilesIsLoading } = useGetFilesQuery({
    ...queryFilters,
    pageNo: filePageNo + 1,
    pageSize: filePageSize,
  });
  const allFiles = allFilesData?.data?.data ?? [];
  const filesCount = allFilesData?.data?.totalCount ?? 0;

  const [editFile, { isLoading: editFileIsLoading }] = useEditFileMutation();
  const [deleteFile, { isLoading: deleteFileIsLoading }] = useDeleteFileMutation();
  const [bulkEditFiles, { isLoading: bulkEditFilesIsLoading }] = useBulkEditFileMutation();
  const [bulkDeleteFiles, { isLoading: bulkDeleteFilesIsLoading }] = useBulkDeleteFilesMutation();

  const [movingFile, setMovingFile] = useState(null);
  const [renamingFile, setRenamingFile] = useState(null);
  const [deletingFile, setDeletingFile] = useState(null);

  const isDeletingModuleFiles =
    (!!deletingFile?.module && deletingFile?.module !== "others") || fileSelected.some((file) => file.module !== "others");

  const handleCopyLink = (file) => {
    try {
      navigator.clipboard.writeText(file.file);
      dispatch(showSuccess({ message: "File link copied" }));
    } catch (error) {
      dispatch(showError({ message: error.message ?? "Something went wrong" }));
    }
  };

  const handleDownloadFile = async (file) => {
    try {
      const data = await fetch(`${file.file}?t=${Date.now().toString(36)}`);
      // const data = await fetch(file.file.replace(/^https:\/\//i, "http://"));
      if (!data.ok) throw new Error(`Download Failed status:${data.status} message:${data.statusText}`);
      const blob = await data.blob();
      const objectUrl = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.setAttribute("href", objectUrl);
      link.setAttribute("download", file.name + file.file.slice(file.file.lastIndexOf(".")));
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(objectUrl);
    } catch (error) {
      dispatch(showError({ message: error.message ?? "Something went wrong" }));
    }
  };

  const handleDownloadFolder = async (folder) => {
    const zip = new JSZip();
    try {
      let ix = 0;
      for (const { file } of folder.result) {
        const res = await fetch(`${file}?t=${Date.now().toString(36)}${ix++}`);
        if (!res.ok) throw new Error(`Download Failed status:${res.status} message:${res.statusText}`);
        const blob = await res.blob();
        zip.file(file.slice(file.lastIndexOf("/") + 1), blob);
      }
      const content = await zip.generateAsync({ type: "blob" });
      const objectUrl = URL.createObjectURL(content);
      const link = document.createElement("a");
      link.setAttribute("href", objectUrl);
      link.setAttribute("download", `${folder.name}.zip`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(objectUrl);
    } catch (error) {
      dispatch(showError({ message: error.message ?? "Something went wrong" }));
    }
  };

  useEffect(() => {
    setFolderPageNo(0);
    setFilePageNo(0);
  }, [queryFilters]);

  return (
    <>
      <div className="my-3">
        <div className="row">
          <div className="col d-flex align-items-center">
            <h4 className="text-lightBlue fs-6 fw-500 me-2">Folders</h4>
            <Tooltip
              title="Lorem ipsum"
              placement="top">
              <img
                src={info}
                alt="info"
                className="c-pointer"
                width={13.5}
              />
            </Tooltip>
          </div>
          <div className="col-auto">
            {/* <Button
              variant="text"
              className="me-2"
              onClick={seeAllFolders}>
              <span className="text-lightBlue">See All</span>
            </Button> */}
            <TablePagination
              component="div"
              className="table-pagination"
              rowsPerPageOptions={[8, 16, 24]}
              page={folderPageNo}
              count={foldersCount}
              rowsPerPage={folderPageSize}
              onPageChange={handleFolderPageNo}
              onRowsPerPageChange={handleFolderPageSize}
            />
          </div>
        </div>
        {folderSelected.length > 0 && (
          <div className="d-flex align-items-center px-2 mb-3">
            <button className="button-grey py-2 px-3">
              <small className="text-lightBlue">
                {folderSelected.length} folders are selected{" "}
                <span
                  className="text-blue-2 c-pointer"
                  onClick={clearfolderSelected}>
                  (Clear Selection)
                </span>
              </small>
            </button>
            <button
              className="button-grey py-2 px-3 ms-2"
              variant="contained"
              onClick={() => setDeletingFolder({})}>
              <small className="text-lightBlue">Delete</small>
            </button>
            {/* <TableMassActionButton
              headingName="Mass Action"
              defaultValue={["Delete"]}
              onSelect={(action) => {
                if (action === "Delete") {
                  setDeletingFolder({});
                }
              }}
            /> */}
          </div>
        )}

        {views === "icon" && (
          <div className="row align-items-center">
            {allFoldersIsLoading ? (
              <span className="d-flex justify-content-center m-3">
                <TableLoader />
              </span>
            ) : allFolders.length === 0 ? (
              <span className="d-flex justify-content-center m-3">
                <NoDataFound />
              </span>
            ) : (
              allFolders.map((folder) => (
                <div
                  key={folder._id}
                  className="col-3">
                  <FolderIconView
                    folder={folder}
                    isSelected={folderSelected.includes(folder)}
                    onDoubleClick={onExplore}
                    onSelect={(check, folder) =>
                      setFolderSelected(
                        check ? folderSelected.concat(folder) : folderSelected.filter((sl) => !Object.is(sl, folder))
                      )
                    }
                    clearSelected={clearfolderSelected}
                    onRename={(folder) => setRenamingFolder(folder)}
                    onDownload={(folder) => handleDownloadFolder(folder)}
                    onDelete={(folder) => setDeletingFolder(folder)}
                  />
                </div>
              ))
            )}
          </div>
        )}

        {views === "list" && (
          <TableContainer>
            {allFoldersIsLoading ? (
              <span className="d-flex justify-content-center m-3">
                <TableLoader />
              </span>
            ) : allFolders.length === 0 ? (
              <span className="d-flex justify-content-center m-3">
                <NoDataFound />
              </span>
            ) : (
              <Table size="medium">
                <EnhancedTableHead
                  numSelected={folderSelected.length}
                  onSelectAllClick={(e) => setFolderSelected(e.target.checked ? [...allFolders] : [])}
                  rowCount={allFolders.length}
                  headCells={headCells}
                />
                <TableBody>
                  {allFolders.map((folder) => (
                    <FolderListView
                      key={folder._id}
                      folder={folder}
                      isSelected={folderSelected.includes(folder)}
                      onDoubleClick={onExplore}
                      onSelect={(check, folder) =>
                        setFolderSelected(
                          check ? folderSelected.concat(folder) : folderSelected.filter((sl) => !Object.is(sl, folder))
                        )
                      }
                      clearSelected={clearfolderSelected}
                      onRename={(folder) => setRenamingFolder(folder)}
                      onDelete={(folder) => setDeletingFolder(folder)}
                    />
                  ))}
                </TableBody>
              </Table>
            )}
          </TableContainer>
        )}
      </div>

      <hr className="hr-grey-6 my-3" />

      <div className="my-3">
        <div className="row">
          <div className="col d-flex align-items-center">
            <h4 className="text-lightBlue fs-6 fw-500 me-2">Files</h4>
            <Tooltip
              title="Lorem ipsum"
              placement="top">
              <img
                src={info}
                alt="info"
                className="c-pointer"
                width={13.5}
              />
            </Tooltip>
          </div>
          <div className="col-auto">
            {/* <Button
              variant="text"
              className="me-2"
              onClick={seeAllFolders}>
              <span className="text-lightBlue">See All</span>
            </Button> */}
            <TablePagination
              component="div"
              className="table-pagination"
              rowsPerPageOptions={[12, 24, 36]}
              page={filePageNo}
              count={filesCount}
              rowsPerPage={filePageSize}
              onPageChange={handleFilePageNo}
              onRowsPerPageChange={handleFilePageSize}
            />
          </div>
        </div>
        {fileSelected.length > 0 && (
          <div className="d-flex align-items-center px-2 mb-3">
            <button className="button-grey py-2 px-3">
              <small className="text-lightBlue">
                {fileSelected.length} files are selected{" "}
                <span
                  className="text-blue-2 c-pointer"
                  onClick={clearfileSelected}>
                  (Clear Selection)
                </span>
              </small>
            </button>
            <TableMassActionButton
              headingName="Mass Action"
              defaultValue={["Move To Folder", "Delete"]}
              onSelect={(action) => {
                switch (action) {
                  case "Delete":
                    setDeletingFile({});
                    break;
                  case "Move To Folder":
                    setMovingFile({});
                    break;

                  default:
                    break;
                }
              }}
            />
          </div>
        )}

        {views === "icon" && (
          <div className="row align-items-center">
            {allFilesIsLoading ? (
              <span className="d-flex justify-content-center m-3">
                <TableLoader />
              </span>
            ) : allFiles.length === 0 ? (
              <span className="d-flex justify-content-center m-3">
                <NoDataFound />
              </span>
            ) : (
              allFiles.map((file) => (
                <div
                  key={file._id}
                  className="col-2 my-2">
                  {file.fileType === "image" && (
                    <ImageIconView
                      file={file}
                      isSelected={fileSelected.includes(file)}
                      onSelect={(check, file) =>
                        setFileSelected(check ? fileSelected.concat(file) : fileSelected.filter((sl) => !Object.is(sl, file)))
                      }
                      clearSelected={clearfileSelected}
                      onDoubleClick={onPopup}
                      onCopyLink={handleCopyLink}
                      onMoveToFolder={(file) => setMovingFile(file)}
                      onRename={(file) => setRenamingFile(file)}
                      onDownload={handleDownloadFile}
                      onDelete={(file) => setDeletingFile(file)}
                    />
                  )}
                  {file.fileType === "video" && (
                    <VideoIconView
                      file={file}
                      isSelected={fileSelected.includes(file)}
                      onSelect={(check, file) =>
                        setFileSelected(check ? fileSelected.concat(file) : fileSelected.filter((sl) => !Object.is(sl, file)))
                      }
                      clearSelected={clearfileSelected}
                      onDoubleClick={onPopup}
                      onCopyLink={handleCopyLink}
                      onMoveToFolder={(file) => setMovingFile(file)}
                      onRename={(file) => setRenamingFile(file)}
                      onDownload={handleDownloadFile}
                      onDelete={(file) => setDeletingFile(file)}
                    />
                  )}
                </div>
              ))
            )}
          </div>
        )}

        {views === "list" && (
          <TableContainer>
            {allFilesIsLoading ? (
              <span className="d-flex justify-content-center m-3">
                <TableLoader />
              </span>
            ) : allFiles.length === 0 ? (
              <span className="d-flex justify-content-center m-3">
                <NoDataFound />
              </span>
            ) : (
              <Table size="medium">
                <EnhancedTableHead
                  numSelected={fileSelected.length}
                  onSelectAllClick={(e) => setFileSelected(e.target.checked ? [...allFiles] : [])}
                  rowCount={allFiles.length}
                  headCells={headCells}
                />
                <TableBody>
                  {allFiles.map((file) => (
                    <>
                      {file.fileType === "image" && (
                        <ImageListView
                          file={file}
                          isSelected={fileSelected.includes(file)}
                          onSelect={(check, file) =>
                            setFileSelected(
                              check ? fileSelected.concat(file) : fileSelected.filter((sl) => !Object.is(sl, file))
                            )
                          }
                          clearSelected={clearfileSelected}
                          onDoubleClick={onPopup}
                          onCopyLink={handleCopyLink}
                          onMoveToFolder={(file) => setMovingFile(file)}
                          onRename={(file) => setRenamingFile(file)}
                          onDownload={handleDownloadFile}
                          onDelete={(file) => setDeletingFile(file)}
                        />
                      )}
                      {file.fileType === "video" && (
                        <VideoListView
                          file={file}
                          isSelected={fileSelected.includes(file)}
                          onSelect={(check, file) =>
                            setFileSelected(
                              check ? fileSelected.concat(file) : fileSelected.filter((sl) => !Object.is(sl, file))
                            )
                          }
                          clearSelected={clearfileSelected}
                          onDoubleClick={onPopup}
                          onCopyLink={handleCopyLink}
                          onMoveToFolder={(file) => setMovingFile(file)}
                          onRename={(file) => setRenamingFile(file)}
                          onDownload={handleDownloadFile}
                          onDelete={(file) => setDeletingFile(file)}
                        />
                      )}
                    </>
                  ))}
                </TableBody>
              </Table>
            )}
          </TableContainer>
        )}
      </div>

      <NameRenameDialog
        isOpen={!!renamingFolder}
        isLoading={editFolderIsLoading}
        headingText="Rename Folder"
        labelText="Folder Name"
        folderName={renamingFolder?.name ?? ""}
        buttonText="Rename"
        imageSrc={folderLargePurple}
        onClose={() => setRenamingFolder(null)}
        onAction={(name = "") => {
          editFolder({ id: renamingFolder._id, folderData: { name } })
            .unwrap()
            .then(() => dispatch(showSuccess({ message: "Folder renamed successfully" })))
            .catch((e) => dispatch(showError({ message: e.message ?? "Something went wrong" })))
            .finally(() => setRenamingFolder(null));
        }}
      />

      <DeleteAlertDialog
        show={!!deletingFolder}
        isLoading={deleteFolderIsLoading || bulkDeleteFoldersIsLoading}
        title="Delete Folder"
        confirmText="Delete"
        message={
          <>
            Do you want to delete{" "}
            <span className="text-blue-2">{`${
              folderSelected.length > 0 ? `${folderSelected.length} folders` : deletingFolder?.name ?? ""
            }`}</span>{" "}
            permanently?
          </>
        }
        countMessage={
          <>
            This will also delete{" "}
            <span className="text-blue-2">{`${
              folderSelected.length > 0
                ? `${folderSelected.reduce((t, sl) => t + sl.result.length, 0)}`
                : deletingFolder?.result.length ?? ""
            }`}</span>{" "}
            files in it!
          </>
        }
        onCancel={() => setDeletingFolder(null)}
        onConfirm={() => {
          if (folderSelected.length > 0)
            bulkDeleteFolders({ deletes: folderSelected.map((sl) => sl._id) })
              .unwrap()
              .then(() => {
                dispatch(apiSlice.util.resetApiState());
                dispatch(
                  showSuccess({
                    message: `${folderSelected.length} Folders Deleted successfully!`,
                  })
                );
              })
              .catch((e) => dispatch(showError({ message: e.message ?? "Something went wrong!" })))
              .finally(() => {
                clearfolderSelected();
                setDeletingFolder(null);
              });
          else
            deleteFolder(deletingFolder._id)
              .unwrap()
              .then(() => {
                dispatch(apiSlice.util.resetApiState());
                dispatch(showSuccess({ message: "Folder deleted successfully" }));
              })
              .catch((e) => {
                console.log(e);
                dispatch(showError({ message: e.message ?? "Something went wrong" }));
              })
              .finally(() => {
                clearfolderSelected();
                setDeletingFolder(null);
              });
        }}
      />

      <NameRenameDialog
        isOpen={!!renamingFile}
        isLoading={editFileIsLoading}
        headingText="Rename File"
        labelText="File Name"
        folderName={renamingFile?.name ?? ""}
        buttonText="Rename"
        imageSrc={renamingFile?.fileType === "video" ? video : renamingFile?.file ?? ""}
        onClose={() => setRenamingFile(null)}
        onAction={(name = "") => {
          editFile({ id: renamingFile._id, fileData: { name } })
            .unwrap()
            .then(() => dispatch(showSuccess({ message: "File renamed successfully" })))
            .catch((e) => dispatch(showError({ message: e.message ?? "Something went wrong" })))
            .finally(() => setRenamingFile(null));
        }}
      />

      <MoveInFolderDialog
        isOpen={!!movingFile}
        isLoading={editFileIsLoading || bulkEditFilesIsLoading}
        buttonText="Move"
        headingText={`Move ${fileSelected.length > 0 ? `${fileSelected.length} Files` : ""} To a Folder`}
        fileImage={movingFile?.file ?? ""}
        onClose={() => setMovingFile(null)}
        onAction={(folderId = "") => {
          if (fileSelected.length > 0)
            bulkEditFiles({
              updates: fileSelected.map((sl) => ({ id: sl._id, folderId })),
            })
              .unwrap()
              .then(() =>
                dispatch(
                  showSuccess({
                    message: `${fileSelected.length} Files Moved successfully!`,
                  })
                )
              )
              .catch((e) => dispatch(showError({ message: e.message ?? "Something went wrong!" })))
              .finally(() => {
                clearfileSelected();
                setMovingFile(null);
              });
          else
            editFile({ id: movingFile._id, fileData: { folderId } })
              .unwrap()
              .then(() => dispatch(showSuccess({ message: "File moved successfully" })))
              .catch((e) => dispatch(showError({ message: e.message ?? "Something went wrong" })))
              .finally(() => {
                clearfileSelected();
                setMovingFile(null);
              });
        }}
      />

      <DeleteAlertDialog
        show={!!deletingFile}
        isLoading={deleteFileIsLoading || bulkDeleteFilesIsLoading}
        title="Delete File"
        confirmText="Delete"
        message={
          <>
            Do you {`${isDeletingModuleFiles ? "still " : ""}`}want to delete{" "}
            <span className="text-blue-2">
              {`${fileSelected.length > 0 ? `${fileSelected.length} files` : deletingFile?.name ?? ""}`}
            </span>{" "}
            permanently?
          </>
        }
        moduleMessage={
          isDeletingModuleFiles ? (
            fileSelected.length > 0 ? (
              <>
                There are{" "}
                <span className="text-blue-2">
                  {`${fileSelected.reduce((count, file) => count + (file.module !== "others"), 0)}`}
                </span>{" "}
                files linked to various modules. Deleting these Files will also remove them from their respective modules.
              </>
            ) : (
              <>
                This File is linked to <span className="text-blue-2">{`${deletingFile?.module}`}</span> module. Deleting this
                File will also remove it from the <span className="text-blue-2">{`${deletingFile?.module}`}</span> module.
              </>
            )
          ) : (
            ""
          )
        }
        onCancel={() => setDeletingFile(null)}
        onConfirm={() => {
          if (fileSelected.length > 0) {
            bulkDeleteFiles({ deletes: fileSelected.map((sl) => sl._id) })
              .unwrap()
              .then(() => {
                dispatch(apiSlice.util.resetApiState());
                dispatch(
                  showSuccess({
                    message: `${fileSelected.length} Files Deleted successfully!`,
                  })
                );
              })
              .catch((e) => dispatch(showError({ message: e.message ?? "Something went wrong!" })))
              .finally(() => {
                clearfileSelected();
                setDeletingFile(null);
              });
          } else {
            deleteFile(deletingFile._id)
              .unwrap()
              .then(() => {
                dispatch(apiSlice.util.resetApiState());
                dispatch(showSuccess({ message: "File Deleted successfully" }));
              })
              .catch((e) => dispatch(showError({ message: e.message ?? "Something went wrong" })))
              .finally(() => {
                clearfileSelected();
                setDeletingFile(null);
              });
          }
        }}
      />
    </>
  );
}
