import { createBrowserRouter, Navigate } from "react-router-dom";
// import store from "./app/store";

import Dashboard from "./pages/Dashboard/Dashboard";
import NewUserDashboard from "./pages/Dashboard/NewUserDashboard.js";

import AllProducts from "./pages/Products/AllProducts/AllProducts";
import FileManager from "./pages/Settings/FileManager/FileManager";
import AddProduct from "./pages/Products/AddProduct/AddProduct";
import AllUsers from "./pages/Users/AllUsers/AllUsers";
import AddUser from "./pages/Users/AddUser/AddUser";
import UserGroups from "./pages/Users/UserGroups/UserGroups";
import UserEnquiries from "./pages/Users/UserEnquiries/UserEnquiries";
import CreateUserGroup from "./pages/Users/CreateUserGroup/CreateUserGroup";
import UserDetails from "./pages/Users/UserDetails/UserDetails";
import AdditionalFields from "./pages/Parameters/AdditionalFields/AdditionalFields";
import CreateFieldSets from "./pages/Parameters/CreateFieldSets/CreateFieldSets";
import Categories from "./pages/Parameters/Categories/Categories";
import Collections from "./pages/Parameters/Collections/Collections";
// import VariantSets from "./pages/Parameters/VariantSets/VariantSets";
import Vendors from "./pages/Parameters/Vendors/Vendors";
import EditVendor from "./pages/Parameters/EditVendor/EditVendor";
import CreateVariantSets from "./pages/Parameters/CreateVariantSets/CreateVariantSets";
import TagsManager from "./pages/Parameters/TagsManager/TagsManager";
import EditTags from "./pages/Parameters/EditTags/EditTags";
import Roles from "./pages/Teams/Roles/Roles";
import CreateRoles from "./pages/Teams/CreateRoles/CreateRoles";
import Members from "./pages/Teams/Members/Members";
import MemberDetails from "./pages/Teams/MemberDetails/MemberDetails";
import ProductsBulkEditor from "./pages/Products/ProductsBulkEditor/ProductsBulkEditor";
import ProductReviews from "./pages/Products/ProductReviews/ProductReviews";
import CreateReview from "./pages/Products/CreateReview/CreateReview";
import ProductInventory from "./pages/Products/ProductInventory/ProductInventory";
import CreateStore from "./pages/Products/CreateStore/CreateStore";
import ProductInventoryDetails from "./pages/Products/ProductInventoryDetails/ProductInventoryDetails";
import CreateDataSets from "./pages/Parameters/CreateDataSets/CreateDataSets";
import AllOrders from "./pages/Orders/AllOrders/AllOrders";
import AllTransaction from "./pages/Orders/AllTransaction/AllTransaction";
import CreateOrder from "./pages/Orders/CreateOrder/CreateOrder";
import OrderDetails from "./pages/Orders/OrderDetails/OrderDetails";
import OmniChannelOrders from "./pages/Orders/OmniChannelOrders/OmniChannelOrders";
import DraftOrder from "./pages/Orders/DraftOrder/DraftOrder";
import AbandonedCart from "./pages/Orders/AbandonedCart/AbandonedCart";
import AbandonedCartDetails from "./pages/Orders/AbandonedCartDetails/AbandonedCartDetails";
import CreateDiscount from "./pages/Offers/CreateDiscount/CreateDiscount";
import Discounts from "./pages/Offers/Discounts/Discounts";
import BundleDiscount from "./pages/Offers/BundleDiscount/BundleDiscount";
import CreateBundleDiscount from "./pages/Offers/CreateBundleDiscount/CreateBundleDiscount";
// import PriceMasterLanding from "./pages/PriceMaster/PriceMasterLanding/PriceMasterLanding";
import PriceMaster from "./pages/PriceMaster/PriceMaster/PriceMaster";
import CreatePriceMaster from "./pages/CreatePriceMaster/CreatePriceMaster";
import MetalPriceManager from "./pages/Parameters/MetalPriceManager/MetalPriceManager";
import DiamondPriceManager from "./pages/Parameters/DiamondPriceManager/DiamondPriceManager";
import MakingChargesManager from "./pages/Parameters/MakingChargesManager/MakingChargesManager";
import AllFunctionality from "./pages/Functionality/AllFunctionality/AllFunctionality";
import LabelsBadges from "./pages/Functionality/LabelsBadges/LabelsBadges";
import PreOrder from "./pages/Functionality/PreOrder/PreOrder";
import SizeChart from "./pages/Functionality/SizeChart/SizeChart";
import CreateSizeChart from "./pages/Functionality/CreateSizeChart/CreateSizeChart";
import CreatePreOrder from "./pages/Functionality/CreatePreOrder/CreatePreOrder";
import CreateLabels from "./pages/Functionality/CreateLabels/CreateLabels";
import ReturnRefunds from "./pages/Orders/ReturnRefunds/ReturnRefunds";
import CreateRefund from "./pages/Orders/CreateRefund/CreateRefund";
import ReturnRequestDetails from "./pages/Orders/ReturnRequestDetails/ReturnRequestDetails";
import ExchangeAlterationRequests from "./pages/Orders/ExchangeAlterationRequests/ExchangeAlterationRequests";

import Login from "./pages/Auth/Login/Login";
import Signup from "./pages/Auth/Signup/Signup";
import StepOne from "./pages/Auth/Onboard/StepOne.js";
import StepTwo from "./pages/Auth/Onboard/StepTwo.js";
import StepThree from "./pages/Auth/Onboard/StepThree.js";
import StepFour from "./pages/Auth/Onboard/StepFour.js";

import CreateReturn from "./pages/Orders/CreateReturn/CreateReturn.js";
import PrimaryLayout from "./layouts/PrimaryLayout";
import ActionLayout from "./layouts/ActionLayout";
import EditCategories from "./pages/Parameters/Categories/EditCategories/EditCategories";
import EditSubCategories from "./pages/Parameters/Categories/EditSubCategories/EditSubCategories";
import EditCollection from "./pages/Parameters/Collections/EditCollection/EditCollection";
import ProductTabs from "./pages/Parameters/ProductTabs/ProductTabs";
import ProductTabInfo from "./pages/Parameters/ProductTabs/ProductTabInfo";
import Options from "./pages/Parameters/Options/Options";
import OptionsInfo from "./pages/Parameters/Options/OptionsInfo";
import Settings from "./pages/Settings/Settings";
import TaxManager from "./pages/Settings/TaxManager/TaxManager";
import EditStore from "./pages/Products/EditStore/EditStore";
import OptionSetsInfo from "./pages/Parameters/Options/OptionSetsInfo";
import PriceManager from "./pages/PriceMaster/PriceManager/PriceManager";

import StoreDetails from "./pages/Settings/StoreDetails/StoreDetails";
import Integrations from "./pages/Settings/Integrations/Integration";
import Domain from "./pages/Settings/Domain/Domain";
import Payment from "./pages/Settings/Payment/Payment";
import Currencies from "./pages/Settings/Currencies/Currencies";
import Langauge from "./pages/Settings/Language/Language";
import Policies from "./pages/Settings/Policy/Policies";
import CreatePolicy from "./pages/Settings/Policy/CreatePolicy/CreatePolicy";
import Guides from "./pages/Settings/Guide/Guides.js";
import CreateGuide from "./pages/Settings/Guide/CreateGuide/CreateGuide.js";
import CustomerDelight from "./pages/Settings/CustomerDelight/CustomerDelight.js";
import CreateCustomerDelight from "./pages/Settings/CustomerDelight/CreateCustomerDelight/CreateCustomerDelight.js";
import Logistics from "./pages/Settings/Logistics/Logistics";
// import ManageTax from "./pages/Settings/TaxManager/ManageTax";
import Notifcation from "./pages/Settings/Notification/Notification";

import MyProfile from "./pages/MyProfile/MyProfile";
import ProfileDetails from "./pages/MyProfile/ProfileDetails/ProfileDetails";
import ManageStore from "./pages/MyProfile/Stores/ManageStore";
import Security from "./pages/MyProfile/Security/Security";
import Notification from "./pages/MyProfile/Notification/Notification";

import EditReview from "./pages/Products/EditReview/EditReview";
import EditSizeChart from "./pages/Functionality/EditSizeChart/EditSizeChart";

import UnderDevelopment from "./components/UnderDevelopment/UnderDevelopment";
import EditLabels from "./pages/Functionality/EditLabels/EditLabels";

import Themes from "./pages/Website/Themes/Themes";
import CustomWeb from "./pages/Website/Themes/CustomWeb/CustomWeb";

import Market from "./pages/GlobalStore/Market/Markets";
import AddRestrictedRegion from "./pages/GlobalStore/Market/AddRestrictedRegion";
import AddMarkets from "./pages/GlobalStore/Market/AddMarkets";
import MarketDetail from "./pages/GlobalStore/Market/MarketDetail";
import ProductPricing from "./pages/GlobalStore/Market/ProductPricing/ProductPricing";
import MarketSetting from "./pages/GlobalStore/Market/MarketSetting/MarketSetting";
import AddTaxDuties from "./pages/GlobalStore/Market/MarketSetting/AddTaxDuties";

import Analytics from "./pages/Analytics/Analytics/Analytics.js";
import Store from "./pages/Settings/Store/Store.js";
import CreateNewStore from "./pages/Settings/Store/CreateStore/CreateNewStore.js";
import CreateCollection from "./pages/Parameters/Collections/EditCollection/CreateCollection.js";
import VariantBulkEditor from "./pages/Products/AddProduct/Variants/VariantBulkEditor.js";
import AbandonedOrders from "./pages/Orders/AbandonedOrders/AbandonedOrders.js";

const router = () => {
  return createBrowserRouter([
    {
      element: <ActionLayout />,
      children: [
        {
          path: "",
          children: [
            {
              index: true,
              element: (
                <Navigate
                  to="auth"
                  replace={true}
                />
              ),
            },
            {
              path: "auth",
              children: [
                {
                  index: true,
                  element: (
                    <Navigate
                      to="login"
                      replace={true}
                    />
                  ),
                },
                {
                  path: "login",
                  element: <Login />,
                },
                {
                  path: "signup",
                  element: <Signup />,
                },
                {
                  path: "stepOne",
                  element: <StepOne />,
                },
                {
                  path: "stepTwo",
                  element: <StepTwo />,
                },
                {
                  path: "stepThree",
                  element: <StepThree />,
                },
                {
                  path: "stepFour",
                  element: <StepFour />,
                },
              ],
            },
          ],
        },
        {
          element: <PrimaryLayout />,
          children: [
            {
              path: "under-development",
              element: <UnderDevelopment />,
            },
            {
              path: "google-login",
              element: (
                <Navigate
                  to="/dashboard"
                  replace={true}
                />
              ),
            },
            {
              path: "dashboard",
              children: [
                {
                  index: true,
                  element: (
                    <Navigate
                      to="homepage"
                      replace={true}
                    />
                  ),
                },
                {
                  path: "homepage",
                  element: <Dashboard />,
                },
                {
                  path: "progress",
                  element: <NewUserDashboard />,
                },
              ],
            },
            {
              path: "products",
              children: [
                {
                  index: true,
                  element: (
                    <Navigate
                      to=" "
                      replace={true}
                    />
                  ),
                },
                {
                  path: "allProducts",
                  element: <AllProducts />,
                },
                {
                  path: "allProducts/addProduct",
                  element: <AddProduct />,
                },
                {
                  path: "allProducts/editProduct/:id",
                  element: <AddProduct />,
                },
                {
                  path: "bulkEditor",
                  element: <ProductsBulkEditor />,
                },
                {
                  path: "variant/bulkEdit/:pid/:sid",
                  element: <VariantBulkEditor />,
                },
                {
                  path: "reviews",
                  children: [
                    {
                      index: true,
                      element: <ProductReviews />,
                    },
                    {
                      path: "create",
                      element: <CreateReview />,
                    },
                    {
                      path: "edit/:reviewId",
                      element: <EditReview />,
                    },
                  ],
                },
                {
                  path: "inventory",
                  children: [
                    {
                      index: true,
                      element: <ProductInventory />,
                    },
                    {
                      path: "create",
                      element: <CreateStore />,
                    },
                    {
                      path: "details/:storeId",
                      element: <ProductInventoryDetails />,
                    },
                    {
                      path: "edit/:storeId",
                      element: <EditStore />,
                    },
                  ],
                },
              ],
            },
            {
              path: "settings",
              element: <Settings />,
              children: [
                {
                  index: true,
                  element: (
                    <Navigate
                      to="storeDetails"
                      replace={true}
                    />
                  ),
                },
                {
                  path: "filemanager",
                  element: <FileManager />,
                },
                {
                  path: "taxmanager",
                  element: <TaxManager />,
                },
                // {
                //   path: "taxmanager/manage",
                //   element: <ManageTax />,
                // },
                // {
                //   path: "emailers",
                //   element: <Notifcation />,
                // },
                {
                  path: "storeDetails",
                  element: <StoreDetails />,
                },
                // {
                //   path: "integration",
                //   element: <Integrations />,
                // },
                // {
                //   path: "domains",
                //   element: <Domain />,
                // },
                // {
                //   path: "payments",
                //   element: <Payment />,
                // },
                // {
                //   path: "currency",
                //   element: <Currencies />,
                // },
                // {
                //   path: "langauge",
                //   element: <Langauge />,
                // },
                // {
                //   path: "policies",
                //   element: <Policies />,
                // },
                // {
                //   path: "policies/create",
                //   element: <CreatePolicy />,
                // },
                // {
                //   path: "logistics",
                //   element: <Logistics />,
                // },
              ],
            },
            {
              path: "myProfile",
              element: <MyProfile />,
              children: [
                {
                  index: true,
                  element: (
                    <Navigate
                      to="details"
                      replace={true}
                    />
                  ),
                },
                {
                  path: "details",
                  element: <ProfileDetails />,
                },
                {
                  path: "store",
                  element: <ManageStore />,
                },
                {
                  path: "security",
                  element: <Security />,
                },
                {
                  path: "notification",
                  element: <Notification />,
                },
              ],
            },
            {
              path: "users",
              children: [
                {
                  index: true,
                  element: (
                    <Navigate
                      to="allUsers"
                      replace={true}
                    />
                  ),
                },
                {
                  path: "allUsers",
                  element: <AllUsers />,
                },
                {
                  path: "allUsers/add",
                  element: <AddUser />,
                },
                {
                  path: "allUsers/edit/:id",
                  element: <AddUser />,
                },
                {
                  path: "allUsers/details/:id",
                  element: <UserDetails />,
                },
                {
                  path: "userGroups",
                  element: <UserGroups />,
                },
                {
                  path: "userGroups/create",
                  element: <CreateUserGroup />,
                },
                {
                  path: "userGroups/edit/:id",
                  element: <CreateUserGroup />,
                },
                {
                  path: "userEnquiries",
                  element: <UserEnquiries />,
                },
              ],
            },
            {
              path: "parameters",
              children: [
                {
                  index: true,
                  element: (
                    <Navigate
                      to="categories"
                      replace={true}
                    />
                  ),
                },
                {
                  path: "collections",
                  element: <Collections />,
                },
                {
                  path: "collections/create",
                  element: <CreateCollection />,
                },
                {
                  path: "collections/edit/:id",
                  element: <EditCollection />,
                },
                {
                  path: "productTabs",
                  element: <ProductTabs />,
                },
                {
                  path: "productTabs/create",
                  element: <ProductTabInfo />,
                },
                {
                  path: "productTabs/edit/:id",
                  element: <ProductTabInfo />,
                },
                {
                  path: "additionalFields",
                  element: <AdditionalFields />,
                },
                {
                  path: "additionalFields/createFieldSets",
                  element: <CreateFieldSets />,
                },
                {
                  path: "categories",
                  element: <Categories />,
                },
                {
                  path: "categories/edit/:id",
                  element: <EditCategories />,
                },
                {
                  path: "subCategories/edit/:id",
                  element: <EditSubCategories />,
                },
                {
                  path: "options",
                  element: <Options />,
                },
                {
                  path: "options/create",
                  element: <OptionsInfo />,
                },
                {
                  path: "options/sets/create",
                  element: <OptionSetsInfo />,
                },
                {
                  path: "options/edit/:id",
                  element: <OptionsInfo />,
                },
                {
                  path: "options/sets/edit/:id",
                  element: <OptionSetsInfo />,
                },
                {
                  path: "variantSets/edit",
                  element: <CreateVariantSets />,
                },
                {
                  path: "variantSets/dataSets/create",
                  element: <CreateDataSets />,
                },
                {
                  path: "vendors",
                  element: <Vendors />,
                },
                {
                  path: "vendors/edit/:id",
                  element: <EditVendor />,
                },
                {
                  path: "tagsManager",
                  element: <TagsManager />,
                },
                {
                  path: "tagsManager/edit/:id/:filter",
                  element: <EditTags />,
                },
              ],
            },
            {
              path: "priceMaster",
              children: [
                {
                  index: true,
                  element: <PriceMaster />,
                },
                {
                  path: "create",
                  element: <CreatePriceMaster />,
                },
                {
                  path: "edit/:id",
                  element: <CreatePriceMaster />,
                },
                {
                  path: "details/:id",
                  element: <PriceManager />,
                },
                {
                  path: "metalMaster",
                  element: <MetalPriceManager />,
                },
                {
                  path: "diamondMaster",
                  element: <DiamondPriceManager />,
                },
                {
                  path: "makingMaster",
                  element: <MakingChargesManager />,
                },
              ],
            },
            {
              path: "analytics",
              children: [
                {
                  index: true,
                  element: (
                    <Navigate
                      to="analytics"
                      replace={true}
                    />
                  ),
                },
                {
                  path: "analytics",
                  element: <Analytics />,
                },
              ],
            },
            {
              path: "teams",
              children: [
                {
                  index: true,
                  element: (
                    <Navigate
                      to="roles"
                      replace={true}
                    />
                  ),
                },
                {
                  path: "roles",
                  children: [
                    {
                      index: true,
                      element: <Roles />,
                    },
                    {
                      path: "create",
                      element: <CreateRoles />,
                    },
                    {
                      path: "edit/:id",
                      element: <CreateRoles />,
                    },
                  ],
                },
                {
                  path: "members",
                  children: [
                    {
                      index: true,
                      element: <Members />,
                    },
                    {
                      path: "details/:id",
                      element: <MemberDetails />,
                    },
                  ],
                },
              ],
            },
            {
              path: "offers",
              children: [
                {
                  index: true,
                  element: (
                    <Navigate
                      to="discounts"
                      replace={true}
                    />
                  ),
                },
                {
                  path: "discounts",
                  element: <Discounts />,
                },
                {
                  path: "discounts/edit/:id",
                  element: <CreateDiscount />,
                },
                {
                  path: "discounts/create",
                  element: <CreateDiscount />,
                },
                // {
                //   path: "bundleDiscount",
                //   element: <BundleDiscount />,
                // },
                // {
                //   path: "bundleDiscount/create",
                //   element: <CreateBundleDiscount />,
                // },
                // {
                //   path: "bundleDiscount/edit/:id",
                //   element: <CreateBundleDiscount />,
                // },
              ],
            },
            {
              path: "orders",
              children: [
                {
                  index: true,
                  element: (
                    <Navigate
                      to="allOrders"
                      replace={true}
                    />
                  ),
                },
                {
                  path: "allOrders",
                  children: [
                    {
                      index: true,
                      element: <AllOrders />,
                    },
                    {
                      path: "create",
                      element: <CreateOrder />,
                    },
                    {
                      path: ":oid/details",
                      element: <OrderDetails />,
                    },
                  ],
                },
                {
                  path: "abondantOrders",
                  children: [
                    {
                      index: true,
                      element: <AbandonedOrders />,
                    },
                  ],
                },
                {
                  path: "transaction",
                  children: [
                    {
                      index: true,
                      element: <AllTransaction />,
                    },
                  ],
                },
                // {
                //   path: "omniChannelOrders",
                //   element: <OmniChannelOrders />,
                // },
                // {
                //   path: "draftOrder",
                //   element: <DraftOrder />,
                // },
                // {
                //   path: "abandonedCart",
                //   element: <AbandonedCart />,
                //   children: [
                //     {
                //       path: "details",
                //       element: <AbandonedCartDetails />,
                //     },
                //   ],
                // },
                {
                  path: "orderDetails",
                  element: <OrderDetails />,
                },
                // {
                //   path: "returnRefunds",
                //   element: <ReturnRefunds />,
                //   children: [
                //     {
                //       path: "refunds/create",
                //       element: <CreateRefund />,
                //     },
                //     {
                //       path: "returns/create",
                //       element: <CreateReturn />,
                //     },
                //     {
                //       path: "details",
                //       element: <ReturnRequestDetails />,
                //     },
                //   ],
                // },
                // {
                //   path: "exchangeAlterationRequests",
                //   element: <ExchangeAlterationRequests />,
                // },
              ],
            },
            {
              path: "functionality",
              children: [
                {
                  index: true,
                  element: (
                    <Navigate
                      to="allFunctionality"
                      replace={true}
                    />
                  ),
                },
                {
                  path: "allFunctionality",
                  element: <AllFunctionality />,
                },
                {
                  path: "sizeChart",
                  children: [
                    {
                      index: true,
                      element: <SizeChart />,
                    },
                    {
                      path: "create",
                      element: <CreateSizeChart />,
                    },
                    {
                      path: "edit/:sid",
                      element: <EditSizeChart />,
                    },
                  ],
                },
                {
                  path: "labelsBadges",
                  children: [
                    {
                      index: true,
                      element: <LabelsBadges />,
                    },
                    {
                      path: "create/:type",
                      element: <CreateLabels />,
                    },
                    {
                      path: "edit/:sid",
                      element: <EditLabels />,
                    },
                  ],
                },
                // {
                //   path: "preOrder",
                //   element: <PreOrder />,
                //   children: [
                //     {
                //       path: "create",
                //       element: <CreatePreOrder />,
                //     },
                //   ],
                // },
              ],
            },
            {
              path: "website",
              children: [
                {
                  index: true,
                  element: (
                    <Navigate
                      to="themes"
                      replace={true}
                    />
                  ),
                },
                {
                  path: "themes",
                  element: <Themes />,
                },
                {
                  path: "policies",
                  element: <Policies />,
                },
                {
                  path: "policies/create",
                  element: <CreatePolicy />,
                },
                {
                  path: "policies/edit/:id",
                  element: <CreatePolicy />,
                },
                {
                  path: "guides",
                  element: <Guides />,
                },
                {
                  path: "guides/create",
                  element: <CreateGuide />,
                },
                {
                  path: "guides/edit/:id",
                  element: <CreateGuide />,
                },
                {
                  path: "customer-delight",
                  element: <CustomerDelight />,
                },
                {
                  path: "customer-delight/create",
                  element: <CreateCustomerDelight />,
                },
                {
                  path: "customer-delight/edit/:id",
                  element: <CreateCustomerDelight />,
                },
                {
                  path: "store",
                  element: <Store />,
                },
                {
                  path: "store/create",
                  element: <CreateNewStore />,
                },
                {
                  path: "store/edit/:id",
                  element: <CreateNewStore />,
                },
              ],
            },
            // {
            //   path: "globalStore",
            //   children: [
            //     {
            //       index: true,
            //       element: (
            //         <Navigate
            //           to="market"
            //           replace={true}
            //         />
            //       ),
            //     },
            //     {
            //       path: "market",
            //       element: <Market />,
            //     },
            //     {
            //       path: "restricted",
            //       element: <AddRestrictedRegion />,
            //     },
            //     {
            //       path: "add-markets",
            //       element: <AddMarkets />,
            //     },
            //     {
            //       path: "market-detail",
            //       element: <MarketDetail />,
            //     },
            //     {
            //       path: "product-pricing",
            //       element: <ProductPricing />,
            //     },
            //     {
            //       path: "market-setting",
            //       element: <MarketSetting />,
            //     },
            //     {
            //       path: "taxDuties",
            //       element: <AddTaxDuties />,
            //     },
            //   ],
            // },
          ],
        },
        {
          path: "",
          children: [
            {
              index: true,
              element: (
                <Navigate
                  to="/theme-Editor"
                  replace={true}
                />
              ),
            },
            {
              path: "theme-Editor",
              element: <CustomWeb />,
            },
          ],
        },
        {
          path: "*",
          element: (
            <Navigate
              to="/under-development"
              replace={true}
            />
          ),
        },
      ],
    },
  ]);
};

export default router;
