import {
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
} from "@mui/material";
import Heading from "../../../components/Headers/DashboardHeader";
import NoDataFound from "../../../components/NoDataFound/NoDataFound";

const productRows = [
  {
    sku: "SKU12345",
    name: "Diamond Necklace",
    boughtBy: "300+ customers",
    revenue: "₹ 15,00,000",
  },
  {
    sku: "SKU54321",
    name: "Gold Ring",
    boughtBy: "250+ customers",
    revenue: "₹ 10,00,000",
  },
  {
    sku: "SKU67890",
    name: "Platinum Bracelet",
    boughtBy: "200+ customers",
    revenue: "₹ 8,00,000",
  },
  {
    sku: "SKU67830",
    name: "Platinum Ring",
    boughtBy: "100+ customers",
    revenue: "₹ 6,00,000",
  },
  {
    sku: "SKU57890",
    name: "Platinum Earring",
    boughtBy: "50+ customers",
    revenue: "₹ 3,00,000",
  },
];

const productHeadCells = [
  "SKU",
  "Product Name",
  "Bought By",
  "Total Revenue",
  "Action",
];

export default function MostSellingProducts() {
  return (
    <div className="bg-button-3 border-grey-5 rounded-8 px-4 pt-4 pb-3 mt-4">
      <Heading heading={"Most Selling Products"} />
      <TableContainer>
        <Table aria-label="most-selling-products" sx={{marginTop: "20px"}}>
          <TableHead sx={{ background: "none" }}>
            <TableRow style={{ borderBottom: "1px solid #343E50" }}>
              {productHeadCells.map((item) => (
                <TableCell key={item}>
                  <h6 className="text-lightBlue">{item}</h6>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {productRows.length > 0 ? (
            <TableBody>
              {productRows.map((row, index) => (
                <TableRow
                  key={index}
                  style={{ borderBottom: "1px solid #343E50" }}>
                  <TableCell component="th" scope="row">
                    <p className="text-blue-2 fw-600">{row.sku}</p>
                  </TableCell>
                  <TableCell>
                    <p className="text-lightBlue fw-400">{row.name}</p>
                  </TableCell>
                  <TableCell>
                    <p className="text-lightBlue fw-400">{row.boughtBy}</p>
                  </TableCell>
                  <TableCell>
                    <p className="text-lightBlue fw-400">{row.revenue}</p>
                  </TableCell>
                  <TableCell sx={{ textAlign: "center", HorizontalAlign: "middle" }}>
  <div className="button-gradient py-2 px-0">
    <p>View</p>
  </div>
</TableCell>

                </TableRow>
              ))}
            </TableBody>
          ) : (
            <NoDataFound />
          )}
        </Table>
      </TableContainer>
    </div>
  );
}
