import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from "@mui/material";

import TableHeader from "../../../components/TableHeader/TableHeader";
import EditButton from "../../../components/EditButton/EditButton";
import DeleteIconButton from "../../../components/DeleteIconButton/DeleteIconButton";
import NoDataFound from "../../../components/NoDataFound/NoDataFound";
import TableLoader from "../../../components/Loader/TableLoader";
// import ArchivedIconButton from "../../../components/ArchivedIconButton/ArchivedIconButton";
import UnArchivedIconButton from "../../../components/UnArchivedIconButton/UnArchivedIconButton";
import AsyncImage from "../../../components/AsyncImage/AsyncImage";

import collection from "../../../assets/images/Components/collection.svg";

const HEAD_CELLS = [
  {
    align: "left",
    disablePadding: false,
    label: "",
    width: "1%",
  },
  {
    align: "left",
    disablePadding: false,
    label: "Master Name",
    width: "64%",
    style: {
      paddingLeft: 0,
    },
  },
  {
    align: "left",
    disablePadding: false,
    label: "Modified By",
    width: "20%",
  },
  {
    align: "right",
    disablePadding: false,
    label: "Action",
    width: "15%",
  },
];

const PAGINATION_ROWS = [10, 20, 30];

const PriceMasterActiveTable = (props) => {
  const {
    error,
    isLoading,
    data,
    totalCount,
    onPageChange,
    onPageSize,
    pageSize,
    page,
    // onEdit,
    // onDelete,
    // onArchived,
    // onUnArchived,
    onOpen,
  } = props;

  const onRowsPerPageChange = (e) => {
    onPageSize(e.target.value);
  };

  if (error) {
    return <></>;
  }

  if (isLoading) {
    return <TableLoader />;
  }

  if (!data) {
    return <></>;
  }

  if (data && !data.length) {
    return <NoDataFound />;
  }

  return (
    <>
      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          size="medium">
          <TableHeader headCells={HEAD_CELLS} />
          <TableBody>
            {data.map((item, index) => {
              return (
                <TableRow
                  hover
                  key={item._id}
                  tabIndex={-1}
                  className="table-rows">
                  <TableCell />
                  <TableCell
                    onClick={onOpen.bind(null, item.srNo)}
                    sx={{
                      textTransform: "capitalize",
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      marginBottom: 0,
                      paddingLeft: 0,
                    }}>
                    <AsyncImage
                      src={item.mediaUrl}
                      placeholder={collection}
                      alt="ringSmall"
                      className="me-2 rounded-4"
                      height={45}
                      width={45}
                      style={{ objectFit: "cover" }}
                    />
                    <p className="text-lightBlue fw-600">{item.name}</p>
                  </TableCell>
                  <TableCell sx={{ textTransform: "capitalize", cursor: "pointer" }}>
                    <p className="text-lightBlue fw-600"></p>
                  </TableCell>
                  <TableCell>
                    <div className="d-flex align-items-center justify-content-end">
                      <EditButton onClick={onOpen.bind(null, item.srNo)} />
                      {/* <ArchivedIconButton
                        onClick={onArchived.bind(null, {
                          id: item._id,
                          message: `<span className='text-blue-2'>${item.name}</span> master`,
                        })}
                        title="Archived"
                      /> */}
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={PAGINATION_ROWS}
        component="div"
        count={totalCount || 0}
        rowsPerPage={pageSize}
        page={page - 1}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        className="table-pagination"
      />
    </>
  );
};
const PriceMasterArchivedTable = (props) => {
  const {
    error,
    isLoading,
    data,
    totalCount,
    onPageChange,
    onPageSize,
    pageSize,
    page,
    onEdit,
    onDelete,
    onArchived,
    onUnArchived,
    onOpen,
  } = props;

  const onRowsPerPageChange = (e) => {
    onPageSize(e.target.value);
  };

  if (error) {
    return <></>;
  }

  if (isLoading) {
    return <TableLoader />;
  }

  if (!data) {
    return <></>;
  }

  if (data && !data.length) {
    return <NoDataFound />;
  }

  return (
    <>
      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          size="medium">
          <TableHeader headCells={HEAD_CELLS} />
          <TableBody>
            {data.map((item, index) => {
              return (
                <TableRow
                  hover
                  key={item._id}
                  tabIndex={-1}
                  className="table-rows">
                  <TableCell />
                  <TableCell
                    onClick={onOpen.bind(null, item.srNo)}
                    sx={{
                      textTransform: "capitalize",
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      marginBottom: 0,
                      paddingLeft: 0,
                    }}>
                    <AsyncImage
                      src={item.mediaUrl}
                      placeholder={collection}
                      alt="ringSmall"
                      className="me-2 rounded-4"
                      height={45}
                      width={45}
                      style={{ objectFit: "cover" }}
                    />
                    <p className="text-lightBlue fw-600">{item.name}</p>
                  </TableCell>
                  <TableCell sx={{ textTransform: "capitalize", cursor: "pointer" }}>
                    <p className="text-lightBlue fw-600"></p>
                  </TableCell>
                  <TableCell>
                    <div className="d-flex align-items-center justify-content-end">
                      <EditButton onClick={onOpen.bind(null, item.srNo)} />
                      <UnArchivedIconButton
                        onClick={onUnArchived.bind(null, {
                          id: item._id,
                          message: `<span className='text-blue-2'>${item.name}</span> master`,
                        })}
                        title="Un-Archived"
                      />
                      <DeleteIconButton
                        onClick={onDelete.bind(null, {
                          id: item._id,
                          message: `<span className='text-blue-2'>${item.name}</span> master`,
                        })}
                        title="Delete"
                      />
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={PAGINATION_ROWS}
        component="div"
        count={totalCount || 0}
        rowsPerPage={pageSize}
        page={page - 1}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        className="table-pagination"
      />
    </>
  );
};

export { PriceMasterActiveTable, PriceMasterArchivedTable };
