import { useMemo } from "react";
import DashBoardChart from "../../../components/Chart/DashBoardChart";
import Heading from "../../../components/Headers/DashboardHeader";
import { useGetRevenueAnalyticsQuery } from "../../../features/analytics/analyticsApiSlice";

const currencySyms = ["", "", "K", "L", "C"];

const formatter = new Intl.NumberFormat("en-IN", {
  maximumSignificantDigits: 3,
  style: "currency",
  currency: "INR",
});

export default function TotalSales({ filter = null }) {
  const { data: revenueData } = useGetRevenueAnalyticsQuery(filter, { skip: !filter });

  const data = useMemo(() => {
    const { lastRevenue = 0, currentRevenue = 0, ...revenueChartData } = revenueData ?? {};
    const formattedRevenue = formatter.format(currentRevenue);
    const splittedRevenue = formattedRevenue.split(",").map((s) => s.replace(/0{2,3}/g, ""));
    const revenue = {
      heading: "Revenue",
      figure: splittedRevenue.slice(0, 2).join(".") + currencySyms[splittedRevenue.length],
      percentage: `+${Math.round((lastRevenue / (currentRevenue || 1)) * 100)}%`,
      conclusion: "concluding line",
      chart: Object.keys(revenueChartData)
        .sort()
        .map((key) => ({ name: key, totalSpent: revenueChartData[key] })),
    };
    return revenue;
  }, [revenueData]);

  return (
    <div className="bg-button-3 border-grey-5 px-3 py-3 rounded-8">
      <div className="d-flex row justify-content-between">
        <div className="col-5 mt-1 p-3">
          <Heading heading={"Total Sale"} />
          <div className="fs-3 text-blue-gradient fw-600 mt-3">{data.figure}</div>
          <h6 className="text-green-2 me-3 fw-500 mt-2">{data.percentage}</h6>
          <div className="fw-600">
            <small className="text-blue-gradient">Info: &nbsp;</small>
            <small className="text-grey-7">{data.conclusion}</small>
            {/* <small className="text-blue-2">&nbsp;2 product</small> */}
          </div>
        </div>
        <div className="col-7 mt-4">
          <DashBoardChart data={data.chart} />
          {/* <DoubleLineGraph
            name={"Total Sale over time"}
            from={"Jan"}
            to={"Apr"}
            fromDate={"22"}
            toDate={"21"}
          /> */}
        </div>
      </div>
    </div>
  );
}
