import {
  Box,
  Card,
  CardActions,
  CardContent,
  Button,
  Typography,
  Divider,
} from "@mui/material";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import InsertLinkOutlinedIcon from "@mui/icons-material/InsertLinkOutlined";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import StraightenIcon from "@mui/icons-material/Straighten";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import ScaleIcon from "@mui/icons-material/Scale";

import AsyncImage from "../AsyncImage/AsyncImage";

import collection from "../../assets/images/Components/collection.svg";

const icons = {
  text: (
    <TextFieldsIcon
      sx={{
        color: "#5c6d8e",
        fontSize: 18,
      }}
    />
  ),
  image: (
    <InsertPhotoIcon
      sx={{
        color: "#5c6d8e",
        fontSize: 18,
      }}
    />
  ),
  dimension: (
    <StraightenIcon
      sx={{
        color: "#5c6d8e",
        fontSize: 18,
      }}
    />
  ),
  weight: (
    <ScaleIcon
      sx={{
        color: "#5c6d8e",
        fontSize: 18,
      }}
    />
  ),
  productField: (
    <InsertLinkOutlinedIcon
      sx={{
        color: "#5c6d8e",
        fontSize: 18,
      }}
    />
  ),
};

const ProductTabPreview = (props) => {
  const { data } = props;

  return (
    <Card
      sx={{ minWidth: 275 }}
      className="bg-black-15 border-grey-5">
      <CardContent>
        <Typography
          sx={{ fontSize: 14 }}
          className="text-grey-6"
          gutterBottom>
          Product Tab
        </Typography>
        <Box
          style={{
            display: "grid",
            alignItems: "center",
            gridTemplateColumns: "auto 1fr",
          }}>
          {!!data.mediaUrl && (
            <AsyncImage
              src={data.mediaUrl}
              placeholder={collection}
              alt="ringSmall"
              className="me-2 rounded-4"
              height={20}
              width={20}
              style={{ objectFit: "cover" }}
            />
          )}
          <Typography
            variant="h6"
            component="div"
            style={{ fontSize: "1rem" }}
            className="text-lightBlue">
            {data.title}
          </Typography>
        </Box>
        {/* <Divider style={{ backgroundColor: "#38395c", margin: "1rem 0" }} /> */}
        {/* <Card className="bg-black-13">
          <CardContent>
            <Typography
              sx={{ fontSize: 14, marginBottom: ".5rem" }}
              className="text-grey-6"
              gutterBottom
            >
              Featured Section
            </Typography>
            <Box style={{ marginBottom: ".5rem" }}>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  component="div"
                  style={{ fontSize: ".9rem" }}
                  className="text-lightBlue"
                >
                  {data.commonCustomFields[0].title}
                </Typography>
                {data.commonCustomFields[0].visibility === "show" && (
                  <VisibilityOutlinedIcon
                    sx={{
                      color: "#5c6d8e",
                      fontSize: 18,
                    }}
                  />
                )}
                {data.commonCustomFields[0].visibility === "hide" && (
                  <VisibilityOffOutlinedIcon
                    sx={{
                      color: "#5c6d8e",
                      fontSize: 18,
                    }}
                  />
                )}
              </Box>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: ".5rem",
                }}
              >
                {icons[data.commonCustomFields[0].fieldType]}
                <Typography
                  component="div"
                  style={{ fontSize: ".8rem", textTransform: "capitalize" }}
                  className="text-grey-6"
                >
                  {data.commonCustomFields[0].fieldType === "productField"
                    ? `Linked to product ${data.commonCustomFields[0].productValue}`
                    : data.commonCustomFields[0].fieldType}
                </Typography>
              </Box>
            </Box>
            <Box>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  component="div"
                  style={{ fontSize: ".9rem" }}
                  className="text-lightBlue"
                >
                  {data.commonCustomFields[1].title}
                </Typography>
                {data.commonCustomFields[1].visibility === "show" && (
                  <VisibilityOutlinedIcon
                    sx={{
                      color: "#5c6d8e",
                      fontSize: 18,
                    }}
                  />
                )}
                {data.commonCustomFields[1].visibility === "hide" && (
                  <VisibilityOffOutlinedIcon
                    sx={{
                      color: "#5c6d8e",
                      fontSize: 18,
                    }}
                  />
                )}
              </Box>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: ".5rem",
                }}
              >
                {icons[data.commonCustomFields[1].fieldType]}
                <Typography
                  component="div"
                  style={{ fontSize: ".8rem", textTransform: "capitalize" }}
                  className="text-grey-6"
                >
                  {data.commonCustomFields[1].fieldType === "productField"
                    ? `Linked to product ${data.commonCustomFields[1].productValue}`
                    : data.commonCustomFields[1].fieldType}
                </Typography>
              </Box>
            </Box>
          </CardContent>
        </Card> */}
        <Divider style={{ backgroundColor: "#38395c", margin: "1rem 0" }} />
        <Box>
          {!!data.customFields.length &&
            data.customFields.map((field, index) => {
              return (
                <Box
                  key={index}
                  style={{
                    marginBottom: index === data.customFields.length - 1 ? 0 : ".5rem",
                  }}>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}>
                    <Typography
                      component="div"
                      style={{ fontSize: ".9rem" }}
                      className="text-lightBlue">
                      {field.title}
                    </Typography>
                    {field.visibility === "show" && (
                      <VisibilityOutlinedIcon
                        sx={{
                          color: "#5c6d8e",
                          fontSize: 18,
                        }}
                      />
                    )}
                    {field.visibility === "hide" && (
                      <VisibilityOffOutlinedIcon
                        sx={{
                          color: "#5c6d8e",
                          fontSize: 18,
                        }}
                      />
                    )}
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: ".5rem",
                    }}>
                    {icons[field.fieldType]}
                    <Typography
                      component="div"
                      style={{ fontSize: ".8rem", textTransform: "capitalize" }}
                      className="text-grey-6">
                      {field.fieldType === "productField"
                        ? `Linked to product ${field.productValue}`
                        : field.fieldType}
                    </Typography>
                  </Box>
                </Box>
              );
            })}
        </Box>
      </CardContent>
    </Card>
  );
};

export default ProductTabPreview;
