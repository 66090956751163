import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  TextareaAutosize,
  Tooltip,
} from "@mui/material";
import { forwardRef, useMemo, useState } from "react";
import cancel from "../../assets/icons/cancel.svg";
import bluedart from "../../assets/icons/bluedart.png";
import { EnhancedTableHead } from "../../components/TableDependencies/TableDependencies";
import AsyncImage from "../../components/AsyncImage/AsyncImage";
import ringSmall from "../../assets/images/ringSmall.svg";
import info from "../../assets/icons/info.svg";
import * as Yup from "yup";
import { useFormik } from "formik";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import {
  useCreateShipmentMutation,
  useGetShipmentPricingQuery,
} from "../../features/orders/shippingApiSlice";
import { useDispatch } from "react-redux";
import { showError } from "../../features/snackbar/snackbarAction";

const headCells = [
  {
    id: "product",
    numeric: false,
    disablePadding: false,
    label: "Product",
  },
  {
    id: "itemId",
    numeric: false,
    disablePadding: false,
    label: "Item ID",
  },
  {
    id: "location",
    numeric: false,
    disablePadding: false,
    label: "Location",
  },
];

const Transition = forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="up"
      ref={ref}
      {...props}
    />
  );
});

const DUMMY_PACKEGES = [];

const validationSchema = Yup.object({
  items: Yup.array().of(Yup.string()).min(1, "Min 1 Product Required"),
  height: Yup.number().typeError("Please Enter Number").moreThan(0).required("required"),
  width: Yup.number().typeError("Please Enter Number").moreThan(0).required("required"),
  length: Yup.number().typeError("Please Enter Number").moreThan(0).required("required"),
  weight: Yup.number().typeError("Please Enter Number").moreThan(0).required("required"),
  dropPincode: Yup.string().required(),
  orderId: Yup.string().required(),
  pickupDate: Yup.string().required(),
  note: Yup.string(),
});

export default function CancelRefundDialog({
  order = null,
  item = null,
  show = false,
  onClose = () => {},
}) {
  const dispatch = useDispatch();

  // const formik = useFormik({
  //   initialValues: {
  //     items: [item?._id],
  //     dropPincode: order?.address?.shipping?.pinCode,
  //     orderId: order?._id,
  //     pickupDate: dayjs().format("YYYY-MM-DD"),
  //     note: "",
  //     length: "",
  //     width: "",
  //     height: "",
  //     weight: "",
  //   },
  //   enableReinitialize: true,
  //   validationSchema: validationSchema,
  //   onSubmit: (values) => {
  //     // console.log(values);
  //     createShipment(values)
  //       .unwrap()
  //       .then((data) => {
  //         setLabelData(data?.data?.returningData?.[0]);
  //       })
  //       .catch((e) => {
  //         dispatch(showError({ message: e?.data?.message ?? "Something went wrong" }));
  //       });
  //   },
  // });

  // const { data } = useGetShipmentPricingQuery(formik.values, { skip: !formik.isValid });
  // const shippingCharge = data?.data?.[0]?.[0]?.preference_array?.[0]?.shipping_charge ?? 0;

  const closeDialog = () => {
    // if (!isLoading) {
    // formik.resetForm();
    // setLabelData(null);
    onClose();
    // }
  };

  const defaultImg = item?.item?.product?.mediaUrl?.find?.((media) => media.isDefault);

  return (
    <Dialog
      open={show}
      TransitionComponent={Transition}
      keepMounted
      onClose={closeDialog}
      maxWidth="md"
      fullWidth>
      {true ? (
        <>
          <DialogTitle>
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex flex-column ">
                <h5 className="text-lightBlue fw-500">Cancel and Refund</h5>
                <small className="text-grey-6 mt-1 d-block">
                  {order?.orderID} - {item?.checkoutID}
                </small>
              </div>
              <img
                src={cancel}
                alt="cancel"
                width={30}
                onClick={closeDialog}
                className="c-pointer"
              />
            </div>
          </DialogTitle>

          <hr className="hr-grey-6 my-0" />

          <DialogContent className="py-3 px-4">
            <div className="row mb-4">
              <div className="col-12">
                <p className="text-grey-6 fw-800 mb-2">Selected Item</p>
              </div>
              <div className="col-12">
                <div className="d-flex align-items-center justify-content-between bg-black-10 border-grey-5 rounded-8 p-3">
                  <div className="d-flex align-items-center">
                    <AsyncImage
                      placeholder={ringSmall}
                      src={defaultImg?.image}
                      alt="product"
                      className="me-2 rounded-4"
                      height={45}
                      width={45}
                    />
                    <div>
                      <p className="text-lightBlue fw-600">{item?.item?.product?.title}</p>
                      <small className="mt-2 text-grey-6">{item?.item?.variant?.title}</small>
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    <p className="text-lightBlue fw-600">₹ {item?.item?.price}</p>
                  </div>
                </div>
              </div>
            </div>

            <hr className="hr-grey-6 my-2" />

            <div className="row mb-2">
              <div className="col-12 my-2">
                <p className="text-grey-6 fw-800 mb-2">Refunding Amount</p>
              </div>
              <div className="col-6 my-2">
                <p className="text-lightBlue mb-1">Enter Amount</p>
                <FormControl className="w-100 px-0">
                  <OutlinedInput
                    placeholder="Enter Amount"
                    size="small"
                    startAdornment={
                      <InputAdornment position="start">
                        <p className="text-lightBlue">₹</p>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </div>
              <div className="col-6 my-2">
                <p className="text-lightBlue mb-1">Refund via</p>
                <FormControl className="w-100 px-0">
                  <Select size="small">
                    <MenuItem
                      value="price"
                      sx={{ fontSize: 13, color: "#5c6d8e" }}>
                      Refund via Original Payment Method
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="col-12 my-2">
                <p className="text-lightBlue mb-1">Note for Customer</p>
                <FormControl className="w-100">
                  <TextareaAutosize
                    placeholder="Note for Customer ..."
                    style={{
                      background: "#15142A",
                      color: "#c8d8ff",
                      borderRadius: 5,
                    }}
                    minRows={3}
                    className="mt-1 w-100"
                  />
                </FormControl>
              </div>
              <div className="col-12 my-2">
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      style={{ color: "#5C6D8E" }}
                    />
                  }
                  label="Send notification to customer"
                  sx={{
                    "& .MuiTypography-root": {
                      fontSize: 14,
                      color: "#c8d8ff",
                    },
                  }}
                />
              </div>
            </div>
          </DialogContent>

          <hr className="hr-grey-6 my-0" />

          <DialogActions className="d-flex justify-content-between px-4 py-3">
            <button
              className="button-grey py-2 px-5"
              onClick={closeDialog}>
              <p className="text-lightBlue">Cancel</p>
            </button>
            <button
              className="button-gradient py-2 px-5"
              onClick={closeDialog}>
              <p>Refund</p>
            </button>
          </DialogActions>
        </>
      ) : (
        <>
          <DialogTitle>
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex flex-column ">
                <h5 className="text-lightBlue fw-500">
                  {order?.orderID} - {item?.checkoutID}
                </h5>
              </div>
              <img
                src={cancel}
                alt="cancel"
                width={30}
                onClick={closeDialog}
                className="c-pointer"
              />
            </div>
          </DialogTitle>

          <hr className="hr-grey-6 my-0" />

          <DialogContent className="py-3 px-4">
            <div className="row my-4">
              <div className="col-12">
                <div className="d-flex align-items-center bg-black-10 border-grey-5 rounded-8 p-3">
                  <AsyncImage
                    src={defaultImg?.image}
                    placeholder={ringSmall}
                    alt="product"
                    className="me-2 rounded-4"
                    height={45}
                    width={45}
                  />
                  <div>
                    <p className="text-lightBlue fw-600">{item?.item?.product?.title}</p>
                    <small className="mt-2 text-grey-6">{item?.item?.variant?.title}</small>
                  </div>
                </div>
              </div>
            </div>

            <hr className="hr-grey-6 my-2" />
          </DialogContent>

          <hr className="hr-grey-6 my-0" />

          <DialogActions className="d-flex justify-content-between px-4 py-3">
            <button
              className="button-grey py-2 px-5"
              onClick={closeDialog}>
              <p className="text-lightBlue">Close</p>
            </button>
            <a
              href="about:blank"
              target="_blank"
              rel="noreferrer"
              className="button-gradient py-2 px-5">
              <p>View Label</p>
            </a>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}
