import { useState } from "react";
// ! IMAGES IMPORTS
import sort from "../../../assets/icons/sort.svg";
// ! MATERIAL IMPORTS
import { FormControl, FormControlLabel, Popover, Radio, RadioGroup } from "@mui/material";

const OrderSortFilter = ({ queryFilters = {}, onSortChange = () => {} }) => {
  // * SORT POPOVERS STARTS
  const [anchorSortEl, setAnchorSortEl] = useState(null);
  const handleSortClick = (event) => {
    setAnchorSortEl(event.currentTarget);
  };
  const handleSortClose = () => {
    setAnchorSortEl(null);
  };
  const openSort = Boolean(anchorSortEl);
  // * SORT POPOVERS ENDS

  const sortFilter = !!queryFilters.srNo
    ? "srNo"
    : !!queryFilters.itemSort
    ? "itemSort"
    : !!queryFilters.priceSort
    ? "priceSort"
    : !!queryFilters.createdAt
    ? "createdAt"
    : !!queryFilters.updatedAt
    ? "updatedAt"
    : "";

  const handleSortChange = (type, value) => onSortChange({ [type]: value });

  return (
    <>
      <button
        onClick={handleSortClick}
        className="button-grey py-2 px-3 ms-2">
        <small className="text-lightBlue me-2">Sort</small>
        <img
          src={sort}
          alt="sort"
        />
      </button>
      <Popover
        open={openSort}
        anchorEl={anchorSortEl}
        onClose={handleSortClose}
        className="columns"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}>
        <FormControl className="px-2 py-1">
          <RadioGroup onChange={(e) => handleSortChange(e.target.value, "-1")}>
            <FormControlLabel
              label="Order Id"
              value="srNo"
              control={
                <Radio
                  size="small"
                  checked={!!queryFilters.srNo}
                />
              }
            />
            <FormControlLabel
              label="No of Items"
              value="itemSort"
              control={
                <Radio
                  size="small"
                  checked={!!queryFilters.itemSort}
                />
              }
            />
            <FormControlLabel
              label="Total Price"
              value="priceSort"
              control={
                <Radio
                  size="small"
                  checked={!!queryFilters.priceSort}
                />
              }
            />
            <FormControlLabel
              label="Created Date"
              value="createdAt"
              control={
                <Radio
                  size="small"
                  checked={!!queryFilters.createdAt}
                />
              }
            />
            <FormControlLabel
              label="Modified Date"
              value="updatedAt"
              control={
                <Radio
                  size="small"
                  checked={!!queryFilters.updatedAt}
                />
              }
            />
          </RadioGroup>
        </FormControl>
        <hr className="hr-grey-6 my-0" />
        <FormControl className="px-2 py-1">
          <RadioGroup onChange={(e) => handleSortChange(sortFilter, e.target.value)}>
            <FormControlLabel
              label={getSortLabels(sortFilter)[0]}
              value="-1"
              control={
                <Radio
                  size="small"
                  checked={queryFilters[sortFilter] === "-1"}
                />
              }
            />
            <FormControlLabel
              label={getSortLabels(sortFilter)[1]}
              value="1"
              control={
                <Radio
                  size="small"
                  checked={queryFilters[sortFilter] === "1"}
                />
              }
            />
          </RadioGroup>
        </FormControl>
      </Popover>
    </>
  );
};

export default OrderSortFilter;

function getSortLabels(sortFilter = "") {
  switch (sortFilter) {
    case "createdAt":
    case "updatedAt":
      return ["Newest to Oldest", "Oldest to Newest"];
    case "srNo":
      return ["Alphabetical (Z-A)", "Alphabetical (A-Z)"];
    case "itemSort":
    case "priceSort":
      return ["Higest to Lowest", "Lowest to Higest"];

    default:
      return ["", ""];
  }
}
