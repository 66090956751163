import { Autocomplete, TextField, Tooltip } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";

import info from "../../../assets/icons/info.svg";

const DashboardBox = ({
  inputName,
  tooltip,
  addInput,
  placeholder,
  optionList,
  previewItem,
  onEdit,
  checkBox,
  ...props
}) => {
  return (
    <div className="d-flex row align-items-center w-100">
      <div className="my-2">
        <div className="d-flex justify-content-between align-items-center mb-1">
          <div className="d-flex">
            <p className="text-lightBlue fw-500">{inputName}</p>
            {tooltip && (
              <Tooltip
                title={tooltip}
                placement="top">
                <img
                  src={info}
                  alt="info"
                  className="ms-2 c-pointer"
                  width={13.5}
                />
              </Tooltip>
            )}
          </div>
          {addInput && <small className="text-blue-2 c-pointer">{addInput}</small>}
        </div>
        <Autocomplete
          disablePortal
          // freeSolo
          id="combo-box-demo"
          size="small"
          options={optionList ?? []}
          // getOptionLabel={(option) => option?.label || ''}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={placeholder}
            />
          )}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <small className="text-lightBlue">{option?.label}</small>
            </li>
          )}
          // renderTags={() => null}
          // disableClearable
          {...props}
        />
        {previewItem && (
          <div className="bg-black-13 borderRadius-8">
            <div className="d-flex justify-content-between align-items-center mb-1 py-4 px-4">
              <div className="d-flex">
                <p className="text-lightBlue fw-500">Preview</p>
                <Tooltip
                  title="Some text"
                  placement="top">
                  <img
                    src={info}
                    alt="info"
                    className="ms-2 c-pointer"
                    width={13.5}
                  />
                </Tooltip>
                <h6 className="text-lightBlue fw-500 mr-1">{previewItem}</h6>
              </div>
              {onEdit && (
                <small
                  className="text-blue-2 c-pointer"
                  onClick={onEdit}>
                  Edit
                </small>
              )}
            </div>
          </div>
        )}
        {checkBox && (
          <div className="d-flex">
            <Checkbox sx={{ "& .MuiSvgIcon-root": { fontSize: 18 }, color: "#C8D8FF" }} />
            <small className="text-grey-6 mt-2 d-block">
              Enable automatic translation based on shopper's browser language
            </small>
          </div>
        )}
      </div>
    </div>
  );
};

export default DashboardBox;
