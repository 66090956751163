import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { CircularProgress } from "@mui/material";

const emptyFn = () => {};

export default function AppGenericSelect({
  sx = {},
  name = "",
  value = "",
  dataId = "",
  dataLabel = "",
  dataImgUrl = "",
  hasImg = false,
  disableClearable = false,
  onBlur = emptyFn,
  onChange = emptyFn,
  useGetQuery = () => ({ data: null, isLoading: false, isSuccess: false }),
  getQueryFilters = {},
}) {
  const {
    data: queryData,
    isLoading: queryIsLoading,
    isSuccess: queryIsSuccess,
  } = useGetQuery(getQueryFilters);

  const options = queryData?.data?.data ?? [];

  const selectedValue =
    options.find((optn) => optn?.[dataId] === value || optn?.[dataLabel] === value) ?? null;
  // console.log("selectedValue", options);

  return queryIsLoading ? (
    <CircularProgress
      color="secondary"
      size={30}
    />
  ) : queryIsSuccess ? (
    <Autocomplete
      sx={sx}
      disableClearable={disableClearable}
      options={options}
      autoHighlight
      size="small"
      name={name}
      getOptionLabel={(option) => option?.[dataLabel] || ""}
      onChange={onChange}
      onBlur={onBlur}
      value={selectedValue}
      componentsProps={{
        paper: {
          sx: {
            width: 320,
            border: "2px solid #38395c",
            marginLeft: "-7px",
            marginTop: "-1px",
          },
        },
      }}
      renderOption={(props, option) => (
        <Box
          {...props}
          component="li"
          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}>
          {hasImg && (
            <img
              loading="lazy"
              width="20"
              src={option?.[dataImgUrl] ?? ""}
              alt={option?.[dataLabel] ?? ""}
            />
          )}
          <small className="text-lightBlue my-1">{option?.[dataLabel] || ""}</small>
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          autoComplete="off"
        />
      )}
    />
  ) : (
    <></>
  );
}
