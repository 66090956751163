import React, { useCallback, useEffect, useState } from "react";
import "./MoreFeatures.scss";
// ! IMAGES IMPORTS
import preOrder from "../../../../assets/images/products/preOrder.svg";
import labelIcon from "../../../../assets/images/products/label.svg";
import sizeChartIcon from "../../../../assets/images/products/sizeChart.svg";
import featureUpload from "../../../../assets/images/products/featureUpload.svg";
import cancel from "../../../../assets/icons/cancel.svg";
import ringSmall from "../../../../assets/images/ringSmall.svg";
import arrowDown from "../../../../assets/icons/arrowDown.svg";
import deleteWhite from "../../../../assets/icons/deleteWhite.svg";
import editWhite from "../../../../assets/icons/editWhite.svg";
import deleteButton from "../../../../assets/icons/deleteButton.svg";
// ! MATERIAL ICONS IMPORTS
import SearchIcon from "@mui/icons-material/Search";
import AddProducts from "../../../../components/AddProducts/AddProducts";
import AppGenericSelect from "../../../../components/AppGenericSelect/AppGenericSelect";
import { useGetAllLabelsBadgessQuery } from "../../../../features/functionality/labelsBadges/labelsBadges";
import {
  useCreateMoreFeaturesMutation,
  useEditMoreFeaturesMutation,
  useGetAllMoreFeaturesQuery,
} from "../../../../features/products/product/morefeaturesApiSlice";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import {
  SaveFooterQuaternary,
  SaveFooterTertiary,
} from "../../../../components/SaveFooter/SaveFooter";
import { useDispatch } from "react-redux";
import { showError, showSuccess } from "../../../../features/snackbar/snackbarAction";
import { omitEmptyKeys } from "../../../../utils/helper";
import TableLoader from "../../../../components/Loader/TableLoader";
import { useGetAllSizeChartsQuery } from "../../../../features/functionality/sizeChart/sizeChartApiSlice";
import { useUpdateProductMutation } from "../../../../features/products/product/productApiSlice";

export default function MoreFeatures({
  labels = "",
  sizeChart = "",
  onBack = () => {},
  onNext = () => {},
}) {
  const { id } = useParams();
  const dispatch = useDispatch();

  const [callNext, setCallNext] = useState(true);

  const [createMoreFeature, { isLoading: createMoreFeatureIsLoading }] =
    useCreateMoreFeaturesMutation();
  const [editMoreFeature, { isLoading: editMoreFeatureIsLoading }] =
    useEditMoreFeaturesMutation();
  const [updateProduct, { isLoading: updateProductIsLoading }] = useUpdateProductMutation();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      labels: labels,
      sizeChart: sizeChart,
      mayLike: {
        product: id,
        moreFeatureType: "youMayLike",
        type: "manual",
        conditionType: "all",
        conditions: [],
        productIds: [],
      },
      recommended: {
        product: id,
        moreFeatureType: "recommended",
        type: "manual",
        conditionType: "all",
        conditions: [],
        productIds: [],
      },
    },
    onSubmit: async (values) => {
      try {
        const { mayLike, recommended, labels, sizeChart } = values;
        if (mayLike.type === "manual") {
          delete mayLike.conditionType;
          delete mayLike.conditions;
        } else {
          mayLike.conditions = mayLike.conditions.map((c) => omitEmptyKeys(c));
        }
        await (mayLikeObj
          ? editMoreFeature({
              id: mayLikeObj._id,
              details: mayLike,
            })
          : createMoreFeature({ ...mayLike })
        ).unwrap();
        if (recommended.type === "manual") {
          delete recommended.conditionType;
          delete recommended.conditions;
        } else {
          recommended.conditions = recommended.conditions.map((c) => omitEmptyKeys(c));
        }
        await (recommendedObj
          ? editMoreFeature({
              id: recommendedObj._id,
              details: recommended,
            })
          : createMoreFeature({ ...recommended })
        ).unwrap();
        const details = omitEmptyKeys({ labels, sizeChart });
        await updateProduct({ details, id }).unwrap();
        dispatch(showSuccess({ message: "All Features saved successfully" }));
        if (callNext) onNext(false);
      } catch (error) {
        console.log(error);
        dispatch(
          showError({
            message: error?.data?.message ?? error?.message ?? "Something went wrong!",
          })
        );
      }
    },
  });

  const { data: morefeaturesData, isSuccess: morefeaturesIsSuccess } =
    useGetAllMoreFeaturesQuery({ product: id }, { skip: !id });

  const mayLikeObj = morefeaturesData?.data?.find(
    (mfd) => mfd.product === id && mfd.moreFeatureType === "youMayLike"
  );
  useEffect(
    () => {
      if (morefeaturesIsSuccess && mayLikeObj)
        formik.setFieldValue("mayLike", {
          ...mayLikeObj,
          productIds: mayLikeObj.productIds.map((pid) => pid._id),
        });
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [mayLikeObj, morefeaturesIsSuccess]
  );
  const updateMayLikeSelection = useCallback(
    (selection) => formik.setFieldValue("mayLike.type", selection),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const updateMayLikeCondition = useCallback(
    (condition) => formik.setFieldValue("mayLike.conditionType", condition),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const updateMayLikeFilters = useCallback(
    (filters) => formik.setFieldValue("mayLike.conditions", filters),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const updateMayLikeProducts = useCallback(
    (products) =>
      formik.setFieldValue(
        "mayLike.productIds",
        products.map((p) => p._id)
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const recommendedObj = morefeaturesData?.data?.find(
    (mfd) => mfd.product === id && mfd.moreFeatureType === "recommended"
  );
  useEffect(
    () => {
      if (morefeaturesIsSuccess && recommendedObj)
        formik.setFieldValue("recommended", {
          ...recommendedObj,
          productIds: recommendedObj.productIds.map((pid) => pid._id),
        });
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [recommendedObj, morefeaturesIsSuccess]
  );
  const updateRecommendedSelection = useCallback(
    (selection) => formik.setFieldValue("recommended.type", selection),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const updateRecommendedCondition = useCallback(
    (condition) => formik.setFieldValue("recommended.conditionType", condition),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const updateRecommendedFilters = useCallback(
    (filters) => formik.setFieldValue("recommended.conditions", filters),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const updateRecommendedProducts = useCallback(
    (products) =>
      formik.setFieldValue(
        "recommended.productIds",
        products.map((p) => p._id)
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const onSave = (callNext) => {
    setCallNext(callNext);
    formik.handleSubmit();
  };

  return (
    <>
      <div className="bg-black-9 border-grey-5 rounded-8 p-3 features mb-4">
        <div className="d-flex justify-content-between mb-3 px-0">
          <h4 className="text-lightBlue me-auto text-lightBlue col-auto ps-0 fw-500">
            You may also like
          </h4>
          {/* <p className="text-blue-2 text-center col-auto pe-0">
            <AntSwitch
            // checked={formik.values.scheduleVisibility}
            // onChange={(e) => {
            //   formik.setFieldValue("status", e.target.checked ? "scheduled" : "in-active");
            //   formik.handleChange(e);
            // }}
            // onBlur={formik.handleBlur}
            />
          </p> */}
        </div>
        {morefeaturesIsSuccess ? (
          <AddProducts
            selection={mayLikeObj?.type}
            condition={mayLikeObj?.conditionType}
            filters={mayLikeObj?.conditions}
            products={mayLikeObj?.productIds}
            onSelectionChange={updateMayLikeSelection}
            onConditionChange={updateMayLikeCondition}
            onFiltersChange={updateMayLikeFilters}
            onProductsChange={updateMayLikeProducts}
          />
        ) : (
          <TableLoader />
        )}
      </div>

      <div className="bg-black-9 border-grey-5 rounded-8 p-3 features mb-4">
        <div className="d-flex justify-content-between mb-3 px-0">
          <h4 className="text-lightBlue me-auto text-lightBlue col-auto ps-0 fw-500">
            Recommended Products
          </h4>
          {/* <p className="text-blue-2 text-center col-auto pe-0">
            <AntSwitch
            // checked={formik.values.scheduleVisibility}
            // onChange={(e) => {
            //   formik.setFieldValue("status", e.target.checked ? "scheduled" : "in-active");
            //   formik.handleChange(e);
            // }}
            // onBlur={formik.handleBlur}
            />
          </p> */}
        </div>
        {morefeaturesIsSuccess ? (
          <AddProducts
            selection={recommendedObj?.type}
            condition={recommendedObj?.conditionType}
            filters={recommendedObj?.conditions}
            products={recommendedObj?.productIds}
            onSelectionChange={updateRecommendedSelection}
            onConditionChange={updateRecommendedCondition}
            onFiltersChange={updateRecommendedFilters}
            onProductsChange={updateRecommendedProducts}
          />
        ) : (
          <TableLoader />
        )}
      </div>

      <div className="bg-black-9 border-grey-5 rounded-8 p-3 features mb-4">
        <div className="d-flex justify-content-between mb-3 px-0">
          <h4 className="text-lightBlue me-auto text-lightBlue col-auto ps-0 fw-500">
            Functionalities
          </h4>
        </div>
        <div className="row align-items-center my-3">
          <div className="col-2 my-3">
            <div className="d-flex justify-content-center">
              <img
                src={labelIcon}
                alt="label"
                className="me-3"
                width={75}
              />
            </div>
          </div>
          <div className="col-6 my-3">
            <h5 className="text-blue-gradient fw-500 text-start">Label & Badge</h5>
            <small className="text-grey-6 mt-2 text-start">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusamus, labore neque
              cupiditate aut quia reiciendis
            </small>
          </div>
          <div className="col-4 my-3">
            <AppGenericSelect
              dataId="_id"
              dataLabel="name"
              name="labels"
              value={formik.values.labels}
              onChange={(_, sel) => formik.setFieldValue("labels", sel?._id ?? "")}
              useGetQuery={useGetAllLabelsBadgessQuery}
              getQueryFilters={{ createdAt: -1 }}
            />
          </div>
          <div className="col-2 my-3">
            <div className="d-flex justify-content-center">
              <img
                src={sizeChartIcon}
                alt="sizeChart"
                className="me-3"
                width={75}
              />
            </div>
          </div>
          <div className="col-6 my-3">
            <h5 className="text-blue-gradient fw-500 text-start">Size Chart</h5>
            <small className="text-grey-6 mt-2 text-start">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusamus, labore neque
              cupiditate aut quia reiciendis
            </small>
          </div>
          <div className="col-4 my-3">
            <AppGenericSelect
              dataId="_id"
              dataLabel="name"
              name="sizeChart"
              value={formik.values.sizeChart}
              onChange={(_, sel) => formik.setFieldValue("sizeChart", sel?._id ?? "")}
              useGetQuery={useGetAllSizeChartsQuery}
              getQueryFilters={{ createdAt: -1 }}
            />
          </div>
        </div>
      </div>

      <SaveFooterTertiary
        show
        onDiscard={() => onBack(false)}
        discardButtonName="Previous"
        isLoading={
          createMoreFeatureIsLoading || editMoreFeatureIsLoading || updateProductIsLoading
        }
        saveButtonName="Next"
        // secondarySaveButtonName="Save"
        // onSecondarySave={() => onSave(false)}
        onSave={() => onSave(true)}
        onSaveType="button"
      />

      {/* <SaveFooterQuaternary
        show
        onAction={() => formik.handleSubmit()}
        onDiscard={() => onBack(false)}
        isLoading={
          createMoreFeatureIsLoading || editMoreFeatureIsLoading || updateProductIsLoading
        }
        saveButtonName="Next"
        discardButtonName="Previous"
      /> */}
    </>
  );
}
