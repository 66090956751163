import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import './Shipping.scss';
import AppCountrySelect from '../../../../components/AppCountrySelect/AppCountrySelect';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputAdornment,
  OutlinedInput,
  ToggleButton,
  ToggleButtonGroup,
  FormHelperText,
} from '@mui/material';
import {
  useNavigate,
  useParams,
  useSearchParams,
  createSearchParams,
} from 'react-router-dom';
import * as Yup from 'yup';
import _ from 'lodash';
import { useFormik } from 'formik';
import { SaveFooterTertiary } from '../../../../components/SaveFooter/SaveFooter';
import AppGenericSelect from '../../../../components/AppGenericSelect/AppGenericSelect';
import {
  showSuccess,
  showError,
} from '../../../../features/snackbar/snackbarAction';
import SnackbarUtils from '../../../../features/snackbar/useSnackbar';
import {
  useCreateShippingMutation,
  useGetShippingQuery,
  useUpdateShippingMutation,
} from '../../../../features/products/product/productApiSlice';
import { useGetAllCountryQuery } from '../../../../features/master/country/countryApiSlice';
import { objectIdReg } from '../../../../utils/regex';
import { omitEmptyKeys } from '../../../../utils/helper';
import { useFormikToastError } from '../../../../hooks/error';

const shippingValidationSchema = Yup.object().shape({
  productId: Yup.string().trim().required('Required'),
  type: Yup.string().trim().oneOf(['digital', 'physical']).required('Required'),
  weight: Yup.number().required('Required'),
  length: Yup.number().required('Required'),
  height: Yup.number().required('Required'),
  width: Yup.number().required('Required'),
  isAbroadAvailable: Yup.boolean(),
  country: Yup.string()
    .trim()
    .when(['isAbroadAvailable'], ([isAbroadAvailable], schema) => {
      if (isAbroadAvailable) {
        return schema.required('Required');
      }
      return schema;
    }),
  hsCode: Yup.string()
    .trim()
    .when(['isAbroadAvailable'], ([isAbroadAvailable], schema) => {
      if (isAbroadAvailable) {
        return schema.required('Required');
      }
      return schema;
    }),
});

const Shipping = (props) => {
  const {
    step,
    onBack,
    onNext,
    onDisableEdit,
    onEnableEdit,
    optionEnabled,
    isCreateMode,
  } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { id } = useParams();
  const [searchParams] = useSearchParams('');
  const [productType, setProductType] = useState('physical');

  const {
    data: shippingData,
    isLoading: shippingIsLoading,
    error: shippingError,
    isError: shippingIsError,
    isSuccess: shippingIsSuccess,
    isFetching: shippingIsFetching,
  } = useGetShippingQuery(
    { productId: id },
    {
      skip: id ? false : true,
    }
  );
  const [
    createShipping,
    {
      isLoading: createShippingIsLoading,
      isSuccess: createShippingIsSuccess,
      error: createShippingError,
      isError: createShippingIsError,
    },
  ] = useCreateShippingMutation();
  const [
    updateShipping,
    {
      isLoading: updateShippingIsLoading,
      isSuccess: updateShippingIsSuccess,
      error: updateShippingError,
      isError: updateShippingIsError,
    },
  ] = useUpdateShippingMutation();

  const shippingFormik = useFormik({
    initialValues: {
      productId: id || '',
      type: shippingData?.data?.[0]?.type || 'physical',
      weight: shippingData?.data?.[0]?.weight || '',
      length: shippingData?.data?.[0]?.length || '',
      height: shippingData?.data?.[0]?.height || '',
      width: shippingData?.data?.[0]?.width || '',
      isAbroadAvailable: shippingData?.data?.[0]?.isAbroadAvailable || false,
      country: shippingData?.data?.[0]?.country || '',
      hsCode: shippingData?.data?.[0]?.hsCode || '',
    },
    enableReinitialize: true,
    validationSchema: shippingValidationSchema,
    onSubmit: (values) => {
      SnackbarUtils.savingToast();
      let clonedShipping = structuredClone(values);
      clonedShipping = omitEmptyKeys(clonedShipping);

      if (shippingData?.data?.[0]?._id) {
        updateShipping({
          id: shippingData?.data[0]?._id,
          details: { ...clonedShipping, step: step.toString() },
        })
          .unwrap()
          .then(() => {
            SnackbarUtils.hideToast();
            dispatch(showSuccess({ message: 'Shipping updated successfully' }));
            onNext();
          })
          .catch((error) => {
            SnackbarUtils.hideToast();

            if (error?.data?.message) {
              dispatch(showError({ message: error.data.message }));
            } else {
              dispatch(
                showError({
                  message: 'Something went wrong!, please try again',
                })
              );
            }
          });
      } else {
        createShipping({ ...clonedShipping, step: step.toString() })
          .unwrap()
          .then(() => {
            SnackbarUtils.hideToast();
            dispatch(
              showSuccess({
                message: 'shipping added successfully',
              })
            );
            onNext();
          })

          .catch((error) => {
            SnackbarUtils.hideToast();
            if (error?.data?.message) {
              dispatch(showError({ message: error.data.message }));
            } else {
              dispatch(
                showError({
                  message: 'Something went wrong!, please try again',
                })
              );
            }
          });
      }
    },
  });

  useFormikToastError(shippingFormik);

  const handleProductType = (event, newProductType) => {
    setProductType(newProductType);
    shippingFormik.setFieldValue('type', newProductType);
  };

  const saveFormHandler = () => {
    onEnableEdit();
    shippingFormik.handleSubmit();
  };

  const nextFormHandler = () => {
    onDisableEdit();
    shippingFormik.handleSubmit();
  };

  useEffect(() => {
    if (shippingIsSuccess && shippingData?.data?.[0]?.type) {
      setProductType(shippingData?.data?.[0]?.type);
    }
  }, [shippingIsSuccess, shippingData]);

  return (
    <form noValidate onSubmit={shippingFormik.handleSubmit}>
      <div className='bg-black-15 border-grey-5 rounded-8 p-3 row shipping'>
        <div className='d-flex col-12 px-0 justify-content-between'>
          <div className='d-flex align-items-center'>
            <h6 className='text-lightBlue me-auto text-lightBlue fw-500'>
              Shipping
            </h6>
          </div>
          {/* <p className="text-blue-2">Settings</p> */}
        </div>

        {/* <div className="col-12">
          <ToggleButtonGroup
            value={productType}
            onChange={handleProductType}
            aria-label="text formatting"
            className="row d-flex mt-4 toggle"
            size="small"
            exclusive
          >
            <ToggleButton
              value="physical"
              aria-label="physical"
              style={{ width: 200 }}
            >
              <div className="d-flex">
                <p className="text-grey-6">Physical Product</p>
              </div>
            </ToggleButton>
            <ToggleButton
              value="digital"
              aria-label="digital"
              style={{ width: 200 }}
            >
              <div className="d-flex">
                <p className="text-grey-6">Digital Product</p>
              </div>
            </ToggleButton>
          </ToggleButtonGroup>
        </div> */}
        <hr className='hr-grey-6 mt-4 mb-3 col-12' />
        {productType === 'physical' && (
          <>
            <p className='text-lightBlue col-12 px-0'>
              Enter Product's Weight and Dimensions
            </p>
            <small className='text-grey-6 mt-1 col-12 px-0'>
              Change Units in&nbsp;
              <span className='text-blue-2'>Regional settings</span>
            </small>
            <div className='d-flex my-3 align-items-center shipping-inputs col-12 px-0'>
              <FormControl className='me-5'>
                <OutlinedInput
                  placeholder='Enter Weight'
                  size='small'
                  endAdornment={
                    <InputAdornment position='end'>kg</InputAdornment>
                  }
                  type='number'
                  name='weight'
                  value={shippingFormik.values.weight}
                  onChange={shippingFormik.handleChange}
                  onBlur={shippingFormik.handleBlur}
                />
                {!!shippingFormik.touched.weight &&
                  shippingFormik.errors.weight && (
                    <FormHelperText error>
                      {shippingFormik.errors.weight}
                    </FormHelperText>
                  )}
              </FormControl>
              <FormControl className=''>
                <OutlinedInput
                  placeholder='Enter Length'
                  size='small'
                  endAdornment={
                    <InputAdornment position='end'>cm</InputAdornment>
                  }
                  type='number'
                  name='length'
                  value={shippingFormik.values.length}
                  onChange={shippingFormik.handleChange}
                  onBlur={shippingFormik.handleBlur}
                />
                {!!shippingFormik.touched.length &&
                  shippingFormik.errors.length && (
                    <FormHelperText error>
                      {shippingFormik.errors.length}
                    </FormHelperText>
                  )}
              </FormControl>
              <p className='text-lightBlue mx-2'>x</p>
              <FormControl className=''>
                <OutlinedInput
                  placeholder='Enter Height'
                  size='small'
                  endAdornment={
                    <InputAdornment position='end'>cm</InputAdornment>
                  }
                  type='number'
                  name='height'
                  value={shippingFormik.values.height}
                  onChange={shippingFormik.handleChange}
                  onBlur={shippingFormik.handleBlur}
                />
                {!!shippingFormik.touched.height &&
                  shippingFormik.errors.height && (
                    <FormHelperText error>
                      {shippingFormik.errors.height}
                    </FormHelperText>
                  )}
              </FormControl>
              <p className='text-lightBlue mx-2'>x</p>
              <FormControl className=''>
                <OutlinedInput
                  placeholder='Enter Width'
                  size='small'
                  endAdornment={
                    <InputAdornment position='end'>cm</InputAdornment>
                  }
                  name='width'
                  type='number'
                  value={shippingFormik.values.width}
                  onChange={shippingFormik.handleChange}
                  onBlur={shippingFormik.handleBlur}
                />
                {!!shippingFormik.touched.width &&
                  shippingFormik.errors.width && (
                    <FormHelperText error>
                      {shippingFormik.errors.width}
                    </FormHelperText>
                  )}
              </FormControl>
            </div>
            <FormControlLabel
              control={
                <Checkbox
                  name='isAbroadAvailable'
                  checked={shippingFormik.values.isAbroadAvailable}
                  onChange={shippingFormik.handleChange}
                  inputProps={{ 'aria-label': 'controlled' }}
                  size='small'
                  style={{
                    color: '#5C6D8E',
                    marginRight: 0,
                  }}
                />
              }
              label='Is this item available for shipping abroad?'
              sx={{
                '& .MuiTypography-root': {
                  fontSize: 13,
                  color: '#c8d8ff',
                },
              }}
              className=' col-12 px-0 my-3'
            />
            {shippingFormik.values.isAbroadAvailable && (
              <>
                <div className='col-12 px-0'>
                  <p className='text-lightBlue'>Custom Information</p>
                  <small className='text-grey-6 mt-2 w-50 d-block'>
                    Customs authorities use this information to calculate duties
                    when shipping internationally. Shown on printed customs
                    forms.
                  </small>
                </div>
                <div className='col-12 px-0 mt-4'>
                  <p className='text-lightBlue mb-2'>
                    Country/Region of Origin
                  </p>
                  <AppGenericSelect
                    sx={{ width: 300 }}
                    name='country'
                    value={shippingFormik.values.country}
                    dataId='_id'
                    dataLabel='name'
                    onBlur={() =>
                      shippingFormik.setFieldTouched('country', true)
                    }
                    onChange={(_, co) =>
                      shippingFormik.setFieldValue('country', co?._id ?? '')
                    }
                    useGetQuery={useGetAllCountryQuery}
                    getQueryFilters={{ createdAt: -1 }}
                  />
                  <small className='text-grey-6 mt-1'>
                    ⓘ Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </small>
                  {!!shippingFormik.touched.country &&
                    shippingFormik.errors.country && (
                      <FormHelperText error>
                        {shippingFormik.errors.country}
                      </FormHelperText>
                    )}
                </div>
                <div className='col-12 px-0 mt-4 mb-3'>
                  <p className='text-lightBlue mb-2'>
                    HS (Harmonized System) code
                  </p>
                  <FormControl className='px-0' sx={{ width: 300 }}>
                    <OutlinedInput
                      placeholder='Enter HS Code'
                      size='small'
                      name='hsCode'
                      value={shippingFormik.values.hsCode}
                      onChange={shippingFormik.handleChange}
                      onBlur={shippingFormik.handleBlur}
                    />
                    {!!shippingFormik.touched.hsCode &&
                      shippingFormik.errors.hsCode && (
                        <FormHelperText error>
                          {shippingFormik.errors.hsCode}
                        </FormHelperText>
                      )}
                  </FormControl>
                  <small className='text-grey-6 mt-1 d-block'>
                    ⓘ Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </small>
                </div>
              </>
            )}
          </>
        )}
        {productType === 'digital' && (
          <p className='text-lightBlue'>
            No Shipping Details and Weight will be assign to Digital
            Products,&nbsp;
            <span className='text-blue-2'>
              Learn More about Digital Products
            </span>
          </p>
        )}
      </div>
      <SaveFooterTertiary
        show={
          id
            ? !isCreateMode
              ? !_.isEqual(shippingFormik.values, shippingFormik.initialValues)
              : true
            : true
        }
        onDiscard={() =>
          onBack(optionEnabled ? false : true, !optionEnabled && 2)
        }
        discardButtonName='Previous'
        isLoading={createShippingIsLoading || updateShippingIsLoading}
        saveButtonName='Next'
        // style={{ width: "105%" }}
        showSecondarySave={!!id && !isCreateMode}
        secondarySaveButtonName='Save'
        onSecondarySave={saveFormHandler}
        onSave={nextFormHandler}
        onSaveType='button'
      />
    </form>
  );
};

export default Shipping;
