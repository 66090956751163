import { useEffect, useMemo, useReducer, useState } from "react";
import { createSearchParams, useNavigate, useSearchParams, useParams } from "react-router-dom";
import {
  Box,
  Paper,
  Tab,
  Tabs,
  Popover,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import _ from "lodash";

import TabPanel from "../../../components/TabPanel/TabPanel";
import PriceManagerTable from "./PriceManagerTable";
import { TableSearchSecondary } from "../../../components/TableSearch/TableSearch";
import PageTitleBar from "../../../components/PageTitleBar/PageTitleBar";
import { DeleteModalSecondary } from "../../../components/DeleteModal/DeleteModal";
import { ArchiveModalSecondary } from "../../../components/ArchiveModal/ArchiveModal";
import { UnArchiveModalSecondary } from "../../../components/UnArchiveModal/UnArchiveModal";
import SortDropdown from "../../../components/SortDropdown/SortDropdown";
import AddStandardModal from "./AddStandardModal";
import StoneAttrTable from "./stoneAttrTable";

import { useGetAllMastersQuery } from "../../../features/priceMaster/priceMasterApiSlice";
import {
  useGetAllOptionsQuery,
  useGetAllAttributesQuery,
  useGetAllSubOptionsQuery,
  useGetAllSubAttributesQuery,
  useGetStoneAttributeQuery,
  useGetStoneAttributeValueQuery,
} from "../../../features/parameters/options/optionsApiSlice";
import {
  useGetAllCategoriesQuery,
  useGetAllSubCategoriesQuery,
} from "../../../features/parameters/categories/categoriesApiSlice";
import {
  useGetAllManagersQuery,
  useCreateManagerMutation,
  useUpdateManagerMutation,
  useDeleteManagerMutation,
  useGetAllStonesQuery,
} from "../../../features/priceManager/priceManagerApiSlice";
import { showSuccess, showError } from "../../../features/snackbar/snackbarAction";
import SnackbarUtils from "../../../features/snackbar/useSnackbar";

import { useFormikToastError } from "../../../hooks/error";

import { omitEmptyKeys, pickExactObjKeys } from "../../../utils/helper";
import { objectIdReg, stringNum } from "../../../utils/regex";

import sort from "../../../assets/icons/sort.svg";
import arrowDown from "../../../assets/icons/arrowDown.svg";

import "../../Products/AllProducts/AllProducts.scss";
import ImportDialog from "../PriceMaster/ImportDialog";
import {
  useExportPriceMasterMutation,
  useImportPriceMasterMutation,
} from "../../../features/importExport/priceMaster/priceMasterApiSlice";

const TAB_LIST = [{ id: 1, label: "all" }];
const SORT_OPTIONS = [
  {
    id: 1,
    label: "Newest to Oldest",
    value: -1,
    key: "createdAt",
  },
  {
    id: 2,
    label: "Oldest to Newest",
    value: 1,
    key: "createdAt",
  },
  {
    id: 3,
    label: "Alphabetical (A-Z)",
    value: 1,
    key: "alphabetical",
  },
  {
    id: 4,
    label: "Alphabetical (Z-A)",
    value: -1,
    key: "alphabetical",
  },
];

const DEFAULT_ACTIVE_TAB = 1;

const priceManagerValidationSchema = Yup.object({
  masterId: Yup.string().matches(objectIdReg, "Not valid").required("Required"),
  priceType: Yup.string().oneOf(["default", "weightRange"]).required("Required"),
  isOption: Yup.boolean().required("Required"),
  metaAttributeId: Yup.string()
    .matches(objectIdReg, "Not valid")
    .when(["isOption"], ([isOption], schema) => {
      if (isOption) {
        return schema.required("Required");
      }
      return schema;
    }),
  values: Yup.array().of(
    Yup.object({
      metaSubAttributeId: Yup.string().matches(objectIdReg, "Not valid").required("Required"),
      subAttributeValueId: Yup.string().matches(objectIdReg, "Not valid").required("Required"),
    })
  ),
  categoryId: Yup.string()
    .matches(objectIdReg, "Not valid")
    .when(["isOption"], ([isOption], schema) => {
      if (!isOption) {
        return schema.required("Required");
      }
      return schema;
    }),
  subCategory: Yup.string().matches(objectIdReg, "Not valid"),
  price: Yup.mixed().when(["priceType"], ([priceType], schema) => {
    if (priceType === "weightRange") {
      return Yup.array()
        .of(
          Yup.object({
            minWeight: Yup.string()
              .trim()
              .matches(stringNum, "Not valid")
              .test("is-greater", "Less than last max wt.", function (currentMaxWeight) {
                const currentIndex = parseInt(this.path.split("[")[1].split("]")[0], 10);
                if (!currentIndex) {
                  return true;
                }
                const lastMaxWeight = this.from[1]?.value?.price[currentIndex - 1]?.maxWeight;
                const isGreater = +currentMaxWeight > +lastMaxWeight;
                return isGreater
                  ? true
                  : this.createError({
                      path: `${this.path}`,
                      message: `Should be greater than ${lastMaxWeight}`,
                    });
              })
              .required("Required"),
            maxWeight: Yup.string()
              .trim()
              .matches(stringNum, "Not valid")
              .when(["minWeight"], ([minWeight], schema) => {
                return schema.test(
                  "min",
                  `Should be greater than ${minWeight}`,
                  function (value) {
                    return +value > +minWeight;
                  }
                );
              })
              .required("Required"),
            price: Yup.string().trim().matches(stringNum, "Not valid").required("Required"),
            priceType: Yup.string().oneOf(["perGram", "fixedPricing"]).required("Required"),
          })
        )
        .required("Required")
        .min(1, "At least one price is required");
    }
    return Yup.object({
      price: Yup.string().trim().matches(stringNum, "Not valid").required("Required"),
      priceType: Yup.string().oneOf(["perGram", "fixedPricing"]).required("Required"),
    });
  }),
});

const initialQueryFilterState = {
  pageSize: 10,
  pageNo: 1,
  masterId: null,
  attribute: null,
  name: "",
  createdAt: -1,
  alphabetical: null,
};

const initialPriceManagerState = {
  totalCount: 0,
  deleteId: null,
  deleteType: "",
  confirmationMessage: "",
  showDeleteModal: false,
  showStandardModal: false,
  search: "",
  activeTab: DEFAULT_ACTIVE_TAB,
  isEdit: false,
  editManagerData: null,
};

const queryFilterReducer = (state, action) => {
  if (action.type === "SET_PAGE_SIZE") {
    return {
      ...state,
      pageNo: initialQueryFilterState.pageNo,
      pageSize: action.size,
    };
  }
  if (action.type === "CHANGE_PAGE") {
    return {
      ...state,
      pageNo: action.pageNo + 1,
    };
  }
  if (action.type === "SEARCH_TITLE") {
    return {
      ...state,
      pageNo: initialQueryFilterState.pageNo,
      name: action.value,
    };
  }
  if (action.type === "SORT") {
    return {
      ...state,
      createdAt: null,
      alphabetical: null,
      [action.name]: action.value,
    };
  }
  // if (action.type === "SET_MASTER") {
  //   return {
  //     ...state,
  //     attribute: null,
  //     masterId: action.id,
  //   };
  // }
  // if (action.type === "SET_ATTRB") {
  //   return {
  //     ...state,
  //     masterId: null,
  //     attribute: action.id,
  //   };
  // }
  if (action.type === "SET_MASTER_ATTRB") {
    return {
      ...state,
      attribute: action.eid,
      masterId: action.mid,
    };
  }
  return initialQueryFilterState;
};

const priceManagerReducer = (state, action) => {
  if (action.type === "SET_TOTAL_COUNT") {
    return {
      ...state,
      totalCount: action.totalCount,
    };
  }
  if (action.type === "SET_DELETE") {
    return {
      ...state,
      deleteId: action.id,
      confirmationMessage: action.message || "",
      showDeleteModal: true,
      deleteType: action.deleteType,
    };
  }
  if (action.type === "REMOVE_DELETE") {
    return {
      ...initialPriceManagerState,
      activeTab: state.activeTab,
      totalCount: state.totalCount,
      search: state.search,
      showStandardModal: state.showStandardModal,
      isEdit: state.isEdit,
      editManagerData: state.editManagerData,
    };
  }
  if (action.type === "SEARCH_VALUE") {
    return {
      ...state,
      search: action.search,
    };
  }
  if (action.type === "SET_ACTIVE_TAB") {
    return {
      ...state,
      activeTab: action.activeTab,
    };
  }
  if (action.type === "SHOW_STANDARD") {
    return {
      ...state,
      showStandardModal: true,
    };
  }
  if (action.type === "HIDE_STANDARD") {
    return {
      ...state,
      showStandardModal: false,
      isEdit: false,
      editManagerData: initialPriceManagerState.editManagerData,
    };
  }
  if (action.type === "SET_EDIT") {
    return {
      ...state,
      isEdit: true,
      editManagerData: action.managerData,
      showStandardModal: true,
    };
  }

  return initialPriceManagerState;
};

const PriceMaster = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [oprnImportDialog, setOprnImportDialog] = useState(false);
  const [rangeAdded, setRangeAdded] = useState(false);
  const [searchParams] = useSearchParams("");
  let { id } = useParams();
  const [queryFilterState, dispatchQueryFilter] = useReducer(
    queryFilterReducer,
    initialQueryFilterState
  );
  const [priceManagerState, dispatchPriceManager] = useReducer(
    priceManagerReducer,
    initialPriceManagerState
  );

  const {
    data: masterData,
    error: masterError,
    isSuccess: masterIsSuccess,
  } = useGetAllMastersQuery(
    {
      srNo: id,
      pageSize: 1,
      pageNo: 0,
    },
    {
      skip: !id,
    }
  );

  const { data: optionsData } = useGetAllOptionsQuery(
    {
      id: masterData?.data?.length && masterData.data[0].entityId,
    },
    {
      skip:
        masterData?.data?.length &&
        masterData.data[0].entityId &&
        masterData.data[0].type === "option"
          ? false
          : true,
    }
  );
  const { data: attributesData } = useGetAllAttributesQuery(
    {
      attribute: optionsData?.data?.length && optionsData?.data[0]?._id,
    },
    {
      skip: optionsData?.data?.length && optionsData?.data[0]?._id ? false : true,
    }
  );

  const isStone = useMemo(
    () =>
      optionsData?.data?.[0]?.title?.toLowerCase?.() === "diamond" ||
      optionsData?.data?.[0]?.title?.toLowerCase?.() === "gemstone" ||
      optionsData?.data?.[0]?.title?.toLowerCase?.() === "pearl",
    [optionsData]
  );

  const {
    data: managersData,
    isLoading: managersIsLoading,
    error: managersError,
    isSuccess: managersIsSuccess,
  } = useGetAllManagersQuery(queryFilterState, {
    skip: queryFilterState.masterId ? false : true,
  });
  const { data: stoneAttrData, isSuccess: stoneAttrIsSuccess } = useGetStoneAttributeQuery(
    {
      masterId: queryFilterState.masterId,
      attribute: queryFilterState.attribute,
    },
    {
      skip: !queryFilterState.masterId || !isStone,
    }
  );

  const sizeStoneAttrId = useMemo(
    () =>
      stoneAttrData?.data?.find((stattr) => stattr?.title?.toLowerCase()?.includes("size"))
        ?._id ?? "",
    [stoneAttrData]
  );

  const [createManager, { isLoading: createManagerIsLoading }] = useCreateManagerMutation();
  const [updateManager, { isLoading: updateManagerIsLoading }] = useUpdateManagerMutation();
  const [deleteManager, { isLoading: deleteManagerIsLoading }] = useDeleteManagerMutation();

  const initialValuesFormik = useMemo(
    () => ({
      masterId: masterData?.data[0]?._id || "",
      priceType: masterData?.data[0]?.priceType || "",
      isOption: (masterData?.data[0]?.type ?? "option") === "option",
      metaAttributeId:
        priceManagerState.isEdit && priceManagerState.editManagerData?.metaAttributeId?._id
          ? priceManagerState.editManagerData?.metaAttributeId?._id
          : "",
      values:
        masterData?.data[0]?.type === "option" &&
        priceManagerState.isEdit &&
        priceManagerState.editManagerData?.values?.length &&
        priceManagerState.editManagerData?.values[0].metaSubAttributeId
          ? priceManagerState.editManagerData?.values?.map((value) => ({
              metaSubAttributeId: value?.metaSubAttributeId?._id || "",
              subAttributeValueId: value?.subAttributeValueId?._id || "",
            }))
          : [],
      categoryId:
        priceManagerState.isEdit && priceManagerState.editManagerData?.categoryId?._id
          ? priceManagerState.editManagerData?.categoryId?._id
          : "",
      subCategory:
        priceManagerState.isEdit && priceManagerState.editManagerData?.subCategory?._id
          ? priceManagerState.editManagerData?.subCategory?._id
          : "",
      price:
        priceManagerState.isEdit && priceManagerState.editManagerData?.price
          ? priceManagerState.editManagerData?.price
          : masterData?.data[0]?.priceType === "weightRange"
          ? [
              {
                minWeight: "",
                maxWeight: "",
                price: "",
                priceType: "perGram",
                // metaSubAttributeId: sizeStoneAttrId,
                subAttributeValueId: "",
              },
            ]
          : {
              price: "",
              priceType: "perGram",
            },
    }),
    [masterData, priceManagerState]
  );

  const formik = useFormik({
    initialValues: initialValuesFormik,
    enableReinitialize: true,
    validationSchema: priceManagerValidationSchema,
    onSubmit: (values) => {
      const managerValues = omitEmptyKeys(values);
      console.log(managerValues);
      if (!managerValues?.values?.length) delete managerValues.values;
      if (Array.isArray(managerValues.price))
        for (const val of managerValues.price)
          if (!val?.subAttributeValueId) delete val.subAttributeValueId;
      SnackbarUtils.savingToast();
      console.log("managerValues", managerValues);
      if (priceManagerState.isEdit) {
        updateManager({
          id: priceManagerState.editManagerData._id,
          details: managerValues,
        })
          .unwrap()
          .then(() => {
            SnackbarUtils.hideToast();
            formik.resetForm();
            dispatchPriceManager({ type: "HIDE_STANDARD" });
            setRangeAdded(false);
            dispatch(
              showSuccess({
                message: "Manager updated successfully",
              })
            );
          })
          .catch((error) => {
            SnackbarUtils.hideToast();
            if (error?.data?.message) {
              dispatch(showError({ message: error.data.message }));
            } else {
              dispatch(
                showError({
                  message: "Something went wrong!, please try again",
                })
              );
            }
          });
        return;
      }
      createManager(managerValues)
        .unwrap()
        .then(() => {
          SnackbarUtils.hideToast();
          formik.resetForm();
          dispatchPriceManager({ type: "HIDE_STANDARD" });
          setRangeAdded(false);
          dispatch(
            showSuccess({
              message: "Manager created successfully",
            })
          );
        })
        .catch((error) => {
          SnackbarUtils.hideToast();
          if (error?.data?.message) {
            dispatch(showError({ message: error.data.message }));
          } else {
            dispatch(
              showError({
                message: "Something went wrong!, please try again",
              })
            );
          }
        });
    },
  });
  useFormikToastError(formik);
  console.log("formik", JSON.stringify(formik.errors, 0, 1));

  const { data: subOptionsData, isSuccess: subOptionsIsSuccess } = useGetAllSubOptionsQuery(
    {
      metaAttribute: formik?.values?.metaAttributeId,
      isOption: false,
    },
    {
      skip: isStone ? true : formik?.values?.metaAttributeId ? false : true,
    }
  );
  const { data: subAttributesData } = useGetAllSubAttributesQuery(
    {
      metaAttribute: formik?.values?.metaAttributeId,
    },
    {
      skip: isStone ? true : formik?.values?.metaAttributeId ? false : true,
    }
  );

  // const { data: stoneAttrData, isSuccess: stoneAttrIsSuccess } = useGetStoneAttributeQuery(
  //   {},
  //   { skip: !isStone }
  // );

  const { data: stoneAttrValueData } = useGetStoneAttributeValueQuery({}, { skip: !isStone });
  // const { data: stoneAttrDropdownValue } = useGetStoneAttributeValueQuery(
  //   {},
  //   { skip: !isStone }
  // );

  const { data: categoriesData } = useGetAllCategoriesQuery(
    { status: "active" },
    {
      skip: masterData?.data?.length && masterData.data[0].type === "category" ? false : true,
    }
  );
  const { data: subCategoriesData } = useGetAllSubCategoriesQuery(
    {
      status: "active",
      categoryId: formik.values.categoryId,
    },
    {
      skip: formik.values.categoryId ? false : true,
    }
  );

  const [exportPriceMaster] = useExportPriceMasterMutation();
  const [importPriceMaster] = useImportPriceMasterMutation();

  const pageChangeHandler = (_, pageNo) => {
    dispatchQueryFilter({ type: "CHANGE_PAGE", pageNo });
  };

  const pageSizeHandler = (size) => {
    dispatchQueryFilter({ type: "SET_PAGE_SIZE", size });
  };

  const searchHandler = (value) => {
    dispatchQueryFilter({ type: "SEARCH_TITLE", value });
  };

  const searchValueHandler = (value) => {
    dispatchPriceManager({ type: "SEARCH_VALUE", search: value });
  };

  const backHandler = () => {
    navigate({
      pathname: "/priceMaster",
      search: `?${createSearchParams({
        search: searchParams.get("search"),
      })}`,
    });
  };

  const editHandler = (data) => {
    dispatchPriceManager({ type: "SET_EDIT", managerData: data });
  };

  const editMasterHandler = () => {
    navigate({
      pathname: `/priceMaster/edit/${masterData?.data[0]?.srNo}`,
      search: `?${createSearchParams({
        search: searchParams.get("search"),
      })}`,
    });
  };

  const deleteHandler = ({ id, message }) => {
    dispatchPriceManager({
      type: "SET_DELETE",
      id,
      message,
      deleteType: "priceManager",
    });
  };

  const addRangeHandler = () => {
    const lastMaxWeight = formik?.values?.price?.length
      ? formik?.values?.price[formik?.values?.price.length - 1].maxWeight
      : 0;
    const newPriceRange = formik?.values?.price.concat({
      minWeight: !!+lastMaxWeight ? `${+lastMaxWeight + 0.001}` : "",
      maxWeight: "",
      price: "",
      priceType: "perGram",
      // metaSubAttributeId: sizeStoneAttrId,
      subAttributeValueId: "",
    });
    formik.setFieldValue("price", newPriceRange);
    setRangeAdded(true);
  };

  const deleteRangeHandler = ({ id, message }) => {
    if (formik.values.price?.length === 1) {
      dispatch(showError({ message: "At least one price range required" }));
      return;
    }
    dispatchPriceManager({
      type: "SET_DELETE",
      id,
      message,
      deleteType: "priceRange",
    });
  };

  const cancelDeleteHandler = () => {
    dispatchPriceManager({ type: "REMOVE_DELETE" });
  };

  const deleteConfirmationHandler = () => {
    const { deleteId, deleteType } = priceManagerState;
    if (deleteType === "priceRange") {
      const updatedPriceRange = formik.values.price?.filter((_, index) => {
        return index !== deleteId;
      });
      formik.setFieldValue("price", updatedPriceRange);
    } else {
      deleteManager(priceManagerState.deleteId)
        .unwrap()
        .then(() => {
          dispatchPriceManager({
            type: "REMOVE_DELETE",
          });
          dispatch(showSuccess({ message: "Manager deleted successfully" }));
        })
        .catch((error) => {
          if (error?.data?.message) {
            dispatch(showError({ message: error.data.message }));
          } else {
            dispatch(showError({ message: "Something went wrong!, please try again" }));
          }
        });
    }
    dispatchPriceManager({ type: "REMOVE_DELETE" });
  };

  const openStandardHandler = () => {
    dispatchPriceManager({
      type: "SHOW_STANDARD",
    });
  };

  const closeStandardHandler = () => {
    formik.resetForm();
    dispatchPriceManager({ type: "HIDE_STANDARD" });
    setRangeAdded(false);
  };

  const changeTabHandler = (_, tabIndex) => {
    dispatchPriceManager({
      type: "SET_ACTIVE_TAB",
      activeTab: tabIndex + 1,
    });
    dispatchPriceManager({
      type: "SEARCH_VALUE",
      search: "",
    });
  };

  const sortHandler = (e) => {
    const { name, value } = e.target;
    dispatchQueryFilter({ type: "SORT", name, value });
  };

  const titleBarExportHandler = () => {
    exportPriceMaster({ srNo: id })
      .unwrap()
      .then((dataURL) => fetch(dataURL))
      .then((res) => res.blob())
      .then((blob) => {
        const objectUrl = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.setAttribute("href", objectUrl);
        link.setAttribute("download", `${masterData?.data?.[0]?.name ?? "Price Manager"}.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(objectUrl);
      })
      .catch((error) => {
        dispatch(
          showError({
            message: error?.data?.message ?? error?.message ?? "Something Went Wrong!",
          })
        );
      });
  };

  const titleBarImportHandler = (file) => {
    const fd = new FormData();
    fd.append("files", file);
    importPriceMaster({ query: { srNo: id }, payload: fd })
      .unwrap()
      .then(() =>
        dispatch(
          showSuccess({
            message: "Price Master Data is being Imported in the background",
          })
        )
      )
      .catch((error) => {
        dispatch(
          showError({
            message: error?.data?.message ?? error?.message ?? "Something Went Wrong!",
          })
        );
      });
  };

  useEffect(() => {
    if (isStone) {
      if (stoneAttrIsSuccess && stoneAttrData?.data.length) {
        if (
          formik.values.metaAttributeId !==
          priceManagerState.editManagerData?.metaAttributeId?._id
        ) {
          const values = stoneAttrData?.data.map((stoneAttr) => {
            return {
              metaSubAttributeId: stoneAttr._id,
              subAttributeValueId: "",
            };
          });

          formik.setFieldValue("values", values);
        } else {
          const values =
            priceManagerState.editManagerData?.values?.length &&
            priceManagerState.editManagerData?.values[0].metaSubAttributeId
              ? priceManagerState.editManagerData?.values?.map((value) => ({
                  metaSubAttributeId: value?.metaSubAttributeId?._id || "",
                  subAttributeValueId: value?.subAttributeValueId?._id || "",
                }))
              : [];

          formik.setFieldValue("values", values);
        }
        return;
      }
      formik.setFieldValue("values", []);
    }
  }, [
    stoneAttrIsSuccess,
    stoneAttrData,
    priceManagerState.editManagerData,
    isStone,
    formik.values.metaAttributeId,
  ]);

  useEffect(() => {
    if (!isStone) {
      if (subOptionsIsSuccess && subOptionsData?.data.length) {
        if (
          formik.values.metaAttributeId !==
          priceManagerState.editManagerData?.metaAttributeId?._id
        ) {
          const values = subOptionsData?.data.map((subOp) => {
            return {
              metaSubAttributeId: subOp._id,
              subAttributeValueId: "",
            };
          });

          formik.setFieldValue("values", values);
        } else {
          const values =
            priceManagerState.editManagerData?.values?.length &&
            priceManagerState.editManagerData?.values[0].metaSubAttributeId
              ? priceManagerState.editManagerData?.values?.map((value) => ({
                  metaSubAttributeId: value?.metaSubAttributeId?._id || "",
                  subAttributeValueId: value?.subAttributeValueId?._id || "",
                }))
              : [];

          formik.setFieldValue("values", values);
        }
        return;
      }
      formik.setFieldValue("values", []);
    }
  }, [
    subOptionsIsSuccess,
    subOptionsData,
    priceManagerState.editManagerData,
    isStone,
    formik.values.metaAttributeId,
  ]);

  useEffect(() => {
    if (masterError) {
      if (masterError?.data?.message) {
        dispatch(showError({ message: masterError.data.message }));
      } else {
        dispatch(showError({ message: "Something went wrong!, please try again" }));
      }
    }

    if (masterIsSuccess) {
      dispatchQueryFilter({
        type: "SET_MASTER_ATTRB",
        mid: masterData?.data?.[0]?._id,
        eid: masterData?.data?.[0]?.entityId,
      });
    }
  }, [masterError, masterIsSuccess, masterData, dispatch]);

  useEffect(() => {
    if (managersError) {
      if (managersError?.data?.message) {
        dispatch(showError({ message: managersError.data.message }));
      } else {
        dispatch(showError({ message: "Something went wrong!, please try again" }));
      }
    }

    if (managersIsSuccess) {
      dispatchPriceManager({
        type: "SET_TOTAL_COUNT",
        totalCount: managersData.totalCount,
      });
    }
  }, [managersError, managersIsSuccess, managersData, dispatch]);

  return (
    <div className="container-fluid page">
      <PageTitleBar
        title={
          masterData?.data.length && masterData.data[0]?.name
            ? masterData.data[0].name
            : "Price Manager"
        }
        onBack={backHandler}
        onTutorial={() => {}}
        onExport={titleBarExportHandler}
        onImport={() => setOprnImportDialog(true)}
        onSettings={editMasterHandler}
      />
      {priceManagerState.activeTab && (
        <div className="row mt-4">
          <Paper
            sx={{ width: "100%", mb: 2, mt: 0, p: 0 }}
            className="border-grey-5 bg-black-15">
            <Box
              sx={{ width: "100%" }}
              className="d-flex justify-content-between tabs-header-box">
              <Tabs
                value={priceManagerState.activeTab - 1}
                onChange={changeTabHandler}
                aria-label="scrollable force tabs example"
                className="tabs">
                {TAB_LIST.map((tab) => {
                  return (
                    <Tab
                      key={tab.id}
                      label={
                        priceManagerState?.totalCount
                          ? `${tab.label} (${priceManagerState?.totalCount})`
                          : `${tab.label} ${managersIsSuccess ? "(0)" : ""}`
                      }
                      className="tabs-head"
                    />
                  );
                })}
                {isStone &&
                  !!stoneAttrData?.data?.length &&
                  stoneAttrData?.data.map((stone) => {
                    return (
                      <Tab
                        key={stone._id}
                        label={`${stone.title} (${stone.stoneAttributeValues.length})`}
                        className="tabs-head"
                      />
                    );
                  })}
              </Tabs>
            </Box>
            {isStone && priceManagerState.activeTab !== 1 ? (
              <></>
            ) : (
              <div className="d-flex align-items-center mt-3 mb-3 px-2 justify-content-between">
                <TableSearchSecondary
                  onChange={searchHandler}
                  onSearchValueChange={searchValueHandler}
                  value={priceManagerState.search}
                />
                <SortDropdown
                  options={SORT_OPTIONS}
                  onChange={sortHandler}
                  query={queryFilterState}
                />
                {!isStone && !formik.values.isOption && (
                  <button
                    onClick={openStandardHandler}
                    className="button-gradient py-2 px-4 ms-3 c-pointer">
                    <p>Add Standards</p>
                  </button>
                )}
              </div>
            )}

            <TabPanel
              value={priceManagerState.activeTab - 1}
              index={0}>
              <PriceManagerTable
                error={managersError}
                isLoading={!queryFilterState.masterId || managersIsLoading}
                data={managersData?.data}
                totalCount={priceManagerState?.totalCount}
                onPageChange={pageChangeHandler}
                onPageSize={pageSizeHandler}
                pageSize={queryFilterState.pageSize}
                page={queryFilterState.pageNo}
                onEdit={editHandler}
                onDelete={deleteHandler}
                masterData={masterData?.data?.length ? masterData.data[0] : {}}
                option={optionsData?.data[0]}
              />
            </TabPanel>
            {isStone &&
              !!stoneAttrData?.data?.length &&
              stoneAttrData?.data.map((stone, index) => {
                return (
                  <TabPanel
                    key={stone._id}
                    value={priceManagerState.activeTab - 1}
                    index={index + 1}>
                    <StoneAttrTable
                      stoneAttribute={stone._id}
                      attr={optionsData?.data?.[0]?._id}
                      key={stone._id}
                    />
                  </TabPanel>
                );
              })}
          </Paper>
        </div>
      )}
      <ImportDialog
        title="Price Master"
        show={oprnImportDialog}
        onAction={titleBarImportHandler}
        onClose={() => setOprnImportDialog(false)}
      />
      <DeleteModalSecondary
        onConfirm={deleteConfirmationHandler}
        onCancel={cancelDeleteHandler}
        show={priceManagerState.showDeleteModal}
        isLoading={deleteManagerIsLoading}
        message={priceManagerState.confirmationMessage}
        title={priceManagerState.deleteType === "priceRange" ? "Price Range" : "Price Manager"}
      />

      <AddStandardModal
        formik={formik}
        onCancel={closeStandardHandler}
        show={priceManagerState.showStandardModal}
        categories={categoriesData?.data?.data}
        subCategories={subCategoriesData?.data?.data}
        option={optionsData?.data[0]}
        attributes={attributesData?.data}
        subOptions={subOptionsData?.data}
        subAttributes={subAttributesData?.data}
        stoneAttributes={stoneAttrData?.data}
        stoneAttributeValues={stoneAttrValueData?.data}
        sizeStoneAttrId={sizeStoneAttrId}
        onAddRange={addRangeHandler}
        onDeleteRange={deleteRangeHandler}
        isLoading={createManagerIsLoading || updateManagerIsLoading}
        isSubOptionSuccess={subOptionsIsSuccess}
        isUpdated={!_.isEqual(formik.values, formik.initialValues)}
        isEditing={priceManagerState.isEdit}
        rangeAdded={rangeAdded}
        isStone={isStone}
      />
    </div>
  );
};

export default PriceMaster;
