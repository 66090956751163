import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// ! COMPONENT IMPORTS
import {
  EnhancedTableHead,
  stableSort,
  getComparator,
} from "../../../components/TableDependencies/TableDependencies";
// ! IMAGES IMPORTS
import indiaFlag from "../../../assets/images/products/indiaFlag.svg";
import userIcon from "../../../assets/images/users/user_defauldp.svg";
import ringSmall from "../../../assets/images/ringSmall.svg";
import user from "../../../assets/images/users/user.svg";
import arrowDown from "../../../assets/icons/arrowDown.svg";
import deleteRed from "../../../assets/icons/delete.svg";
// ! MATERIAL IMPORTS
import {
  Checkbox,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Tooltip,
} from "@mui/material";
// ! MATERIAL ICON IMPORTS
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ChatIcon from "@mui/icons-material/Chat";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CloseIcon from "@mui/icons-material/Close";
import ReceiptIcon from "@mui/icons-material/Receipt";
import PrintIcon from "@mui/icons-material/Print";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import InventoryIcon from "@mui/icons-material/Inventory";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch } from "react-redux";
import TableLoader from "../../../components/Loader/TableLoader";
import NoDataFound from "../../../components/NoDataFound/NoDataFound";
import { OrderStatuses, PaymentStatuses } from "../AllOrders/FilterOrders";
import moment from "moment/moment";
import AsyncImage from "../../../components/AsyncImage/AsyncImage";

// ? TABLE STARTS HERE
function createData(oId, time, userName, location, items, total, status) {
  return {
    oId,
    time,
    userName,
    location,
    items,
    total,
    status,
  };
}

const rows = [
  createData(
    "#D1-1234",
    "Today at 09:23am",
    "Saniya Shaikh",
    "Delhi, India",
    "2 Items",
    "₹ 1,00,000",
    "Complete"
  ),
  createData(
    "#D2-1234",
    "Today at 09:23am",
    "Saniya Shaikh",
    "Delhi, India",
    "2 Items",
    "₹ 1,00,000",
    "Complete"
  ),
  createData(
    "#D3-1234",
    "Today at 09:23am",
    "Saniya Shaikh",
    "Delhi, India",
    "2 Items",
    "₹ 1,00,000",
    "Complete"
  ),
];

const headCells = [
  {
    id: "draftOrder",
    numeric: false,
    disablePadding: true,
    label: "Draft Order",
  },
  {
    id: "userName",
    numeric: false,
    disablePadding: false,
    label: "User",
  },
  {
    id: "items",
    numeric: false,
    disablePadding: false,
    label: "Items",
  },
  {
    id: "total",
    numeric: false,
    disablePadding: false,
    label: "Total",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: true,
    label: "Actions",
  },
];
// ? TABLE ENDS HERE

const DraftOrderTable = ({
  page = 0,
  changePage = () => {},
  changeRowsPerPage = () => {},
  rowsPerPage = 10,
  totalCount = 0,
  orderList = [],
  orderLoading = false,
  hasError = false,
}) => {
  const navigate = useNavigate();

  const [selected, setSelected] = useState([]);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = orderList?.map((n) => n?._id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  // * EDIT STATUS POPOVERS STARTS
  const [anchorEditStatusEl, setAnchorEditStatusEl] = useState(null);
  const handleEditStatusClick = (event) => {
    setAnchorEditStatusEl(event.currentTarget);
  };
  const handleEditStatusClose = () => {
    setAnchorEditStatusEl(null);
  };
  const openEditStatus = Boolean(anchorEditStatusEl);
  const idEditStatus = openEditStatus ? "simple-popover" : undefined;
  // * EDIT STATUS POPOVERS ENDS

  // * MASS ACTION POPOVERS STARTS
  const [anchorMassActionEl, setAnchorMassActionEl] = useState(null);
  const handleMassActionClick = (event) => {
    setAnchorMassActionEl(event.currentTarget);
  };
  const handleMassActionClose = () => {
    setAnchorMassActionEl(null);
  };
  const openMassAction = Boolean(anchorMassActionEl);
  const idMassAction = openMassAction ? "simple-popover" : undefined;
  // * MASS ACTION POPOVERS ENDS

  // * USER POPOVERS STARTS
  const [viewingUser, setViewingUser] = useState(null);
  const [anchorUserEl, setAnchorUserEl] = useState(null);
  const handleUserClick = (target, user) => {
    setAnchorUserEl(target);
    setViewingUser(user);
  };
  const handleUserClose = () => {
    setAnchorUserEl(null);
    setViewingUser(null);
  };
  // * USER POPOVERS ENDS

  // * ITEM POPOVERS STARTS
  const [viewingItems, setViewingItems] = useState([]);
  const [anchorItemEl, setAnchorItemEl] = useState(null);
  const handleItemClick = (target, items) => {
    setAnchorItemEl(target);
    setViewingItems(items);
  };
  const handleItemClose = () => {
    setAnchorItemEl(null);
    setViewingItems([]);
  };
  // * ITEM POPOVERS ENDS

  return orderLoading ? (
    <span className="d-flex justify-content-center m-3">
      <TableLoader />
    </span>
  ) : orderList?.length === 0 || hasError ? (
    <span className="d-flex justify-content-center m-3">
      <NoDataFound />
    </span>
  ) : (
    <>
      {selected.length > 0 && (
        <div className="d-flex justify-content-between align-items-center px-2 mb-3">
          <div className="d-flex">
            <button className="button-grey py-2 px-3">
              <small className="text-lightBlue">
                {selected.length} orders are selected&nbsp;
                <span
                  className="text-blue-2 c-pointer"
                  onClick={() => setSelected([])}>
                  (Clear Selection)
                </span>
              </small>
            </button>

            <button className="button-grey py-2 px-3 ms-2">
              <small className="text-lightBlue">Edit Users</small>
            </button>
            <button
              className="button-grey py-2 px-3 ms-2"
              aria-describedby={idEditStatus}
              variant="contained"
              onClick={handleEditStatusClick}>
              <small className="text-lightBlue">Edit Status</small>
              <img
                src={arrowDown}
                alt="arrowDown"
                className="ms-2"
              />
            </button>

            <Popover
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              id={idEditStatus}
              open={openEditStatus}
              anchorEl={anchorEditStatusEl}
              onClose={handleEditStatusClose}>
              <div className="py-2 px-1">
                <small className="p-2 rounded-3 text-lightBlue c-pointer font2 d-block hover-back">
                  Set as Active
                </small>
                <small className="p-2 rounded-3 text-lightBlue c-pointer font2 d-block hover-back">
                  Set as Draft
                </small>
              </div>
            </Popover>
            <button
              className="button-grey py-2 px-3 ms-2"
              aria-describedby={idMassAction}
              variant="contained"
              onClick={handleMassActionClick}>
              <small className="text-lightBlue">Mass Action</small>
              <img
                src={arrowDown}
                alt="arrowDown"
                className="ms-2"
              />
            </button>
            <Popover
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              id={idMassAction}
              open={openMassAction}
              anchorEl={anchorMassActionEl}
              onClose={handleMassActionClose}>
              <div className="py-2 px-2">
                <small className="text-grey-7 px-2">ACTIONS</small>
                <hr className="hr-grey-6 my-2" />
                <small className="p-2 rounded-3 text-lightBlue c-pointer font2 d-block hover-back">
                  Edit User
                </small>
                <small className="p-2 rounded-3 text-lightBlue c-pointer font2 d-block hover-back">
                  Edit User Group
                </small>
                <small className="p-2 rounded-3 text-lightBlue c-pointer font2 d-block hover-back">
                  Add or Remove Tags
                </small>
                <div className="d-flex justify-content-between  hover-back rounded-3 p-2 c-pointer">
                  <small className="text-lightBlue font2 d-block">Archive User</small>
                  <img
                    src={deleteRed}
                    alt="delete"
                    className=""
                  />
                </div>
              </div>
            </Popover>
          </div>
        </div>
      )}
      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          size="medium">
          <EnhancedTableHead
            numSelected={selected.length}
            order={"asc"}
            orderBy={"groupName"}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={Date}
            rowCount={orderList.length}
            headCells={headCells}
          />
          <TableBody>
            {orderList.map((row) => {
              const isItemSelected = selected.includes(row);
              const orderStatusObj = OrderStatuses.find((os) => os.value === row.status);
              return (
                <TableRow
                  hover
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row?._id}
                  selected={isItemSelected}
                  className="table-rows">
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={isItemSelected}
                      onClick={(e) =>
                        setSelected((sel) =>
                          e.target.checked
                            ? sel.concat(row)
                            : sel.filter((s) => !Object.is(s, row))
                        )
                      }
                      size="small"
                      style={{ color: "#5C6D8E" }}
                    />
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    padding="none">
                    <div className="d-flex align-items-center py-2">
                      <div>
                        <Link
                          to={`/orders/allOrders/draft/${row?._id}`}
                          className="text-decoration-none d-flex">
                          <p className="text-blue-2 fw-600">#{row.orderID}</p>
                        </Link>
                        <small className="mt-2 text-lightBlue">
                          {moment(row.createdAt).format("DD-MM-YYYY [at] h:mm a")}
                        </small>
                      </div>
                    </div>
                  </TableCell>
                  <TableCell>
                    {!row?.customer ? (
                      <div className="d-flex align-items-center py-2">
                        <p className="text-blue-2 fw-600">
                          {row?.address?.shipping?.firstName} {row?.address?.shipping?.lastName}{" "}
                          (Guest)
                        </p>
                      </div>
                    ) : (
                      <div
                        onClick={(e) => handleUserClick(e.currentTarget, row)}
                        className="d-flex align-items-center c-pointer py-2">
                        <p className="text-blue-2 fw-600">
                          {row?.customer?.firstName} {row?.customer?.lastName}
                        </p>
                      </div>
                    )}
                  </TableCell>
                  <TableCell>
                    <div
                      onClick={(e) => handleItemClick(e.currentTarget, row.items)}
                      className="d-flex align-items-center c-pointer">
                      <p className="text-blue-2 fw-600">{row.items?.length}</p>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="d-flex flex-column">
                      <p className="text-lightBlue">
                        ₹{row.items.reduce((t, it) => t + it.item.price, 0)}
                      </p>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="d-flex align-items-center">
                      <div
                        className="rounded-pill d-flex px-2 py-1 statusBoxWidth"
                        style={{ background: orderStatusObj?.color }}>
                        <small className="text-black fw-400">{orderStatusObj?.text}</small>
                      </div>
                    </div>
                  </TableCell>
                  <TableCell style={{ width: 140, padding: 0 }}>
                    <div className="d-flex align-items-center">
                      <Tooltip
                        title="Edit"
                        placement="top">
                        <div
                          onClick={() => navigate(`/orders/allOrders/draft/${row?._id}`)}
                          className="table-edit-icon rounded-4 p-2">
                          <EditOutlinedIcon
                            sx={{
                              color: "#5c6d8e",
                              fontSize: 18,
                              cursor: "pointer",
                            }}
                          />
                        </div>
                      </Tooltip>
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30, 50]}
        component="div"
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        onRowsPerPageChange={changeRowsPerPage}
        onPageChange={changePage}
        className="table-pagination"
      />

      <Popover
        open={!!viewingUser}
        anchorEl={anchorUserEl}
        onClose={handleUserClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}>
        <div className="py-2 px-2">
          <div className="d-flex justify-content-between align-items-center pt-1">
            <div className="d-flex align-items-center">
              <AsyncImage
                src={viewingUser?.customer?.imageUrl}
                placeholder={userIcon}
                alt="product"
                className="me-2 rounded-4"
                height={40}
                width={40}
              />
              <div className="d-flex flex-column">
                <p className="text-lightBlue">
                  {viewingUser?.customer?.firstName} {viewingUser?.customer?.lastName}
                </p>
              </div>
            </div>
          </div>
          <hr className="hr-grey-6 my-2" />
          <div className="d-flex align-items-center mt-3">
            <small className=" text-blue-2 me-2">{viewingUser?.customer?.email}</small>
          </div>
          <div className="d-flex align-items-center mt-3">
            <small className="text-blue-2 me-2">
              {viewingUser?.customer?.countryCode}-{viewingUser?.customer?.phone}
            </small>
          </div>
          <div className="d-flex align-items-center mt-3">
            <div>
              <p className="my-1">
                <small className="text-lightBlue">
                  {viewingUser?.address?.shipping?.line1}{" "}
                  {viewingUser?.address?.shipping?.line2}
                </small>
              </p>
              <p className="text-lightBlue my-1">
                <small className="text-lightBlue">
                  {viewingUser?.address?.shipping?.state?.name}{" "}
                  {viewingUser?.address?.shipping?.country?.name}{" "}
                  {viewingUser?.address?.shipping?.pinCode}
                </small>
              </p>
            </div>
          </div>
          <div className="d-flex mt-4 mb-1">
            <Link
              to={`/users/allUsers/details/%7B"id"%3A"${viewingUser?.customer?._id}"%7D`}
              className="button-lightBlue-outline w-100 px-3 py-1">
              <small>View Profile</small>
            </Link>
          </div>
        </div>
      </Popover>
      <Popover
        open={!!viewingItems.length}
        anchorEl={anchorItemEl}
        onClose={handleItemClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}>
        <Table size="small">
          <TableBody>
            {viewingItems.map((item) => {
              const imageUrl =
                item?.item?.product?.mediaUrl?.find((mu) => mu.isDefault)?.image ?? "";
              return (
                <TableRow key={item._id}>
                  <TableCell
                    padding="normal"
                    className="ps-1 pe-3">
                    <div className="row">
                      <div className="col-auto">
                        <AsyncImage
                          src={imageUrl}
                          placeholder={ringSmall}
                          alt="product"
                          height={40}
                          width={40}
                        />
                      </div>
                      <div className="col px-1">
                        <p className="text-lightBlue">{item?.item?.productName}</p>
                        <p className="text-grey-6 font0">₹ {item?.item?.price}</p>
                      </div>
                    </div>
                  </TableCell>
                  <TableCell padding="normal">
                    <div className="w-auto">
                      <small className="text-grey-6">
                        SNo:&nbsp;
                        <small className="text-blue-2">{item?.checkoutID}</small>
                      </small>
                      <br />
                      <small className="text-grey-6">
                        SKU:&nbsp;
                        <small className="text-blue-2">
                          {item?.item?.product?.inventory?.skuId}
                        </small>
                      </small>
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Popover>
    </>
  );
};

export default DraftOrderTable;
