import { forwardRef, useEffect, useState } from "react";
import info from "../../assets/icons/info.svg";
import cancel from "../../assets/icons/cancel.svg";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  Checkbox,
  FormControlLabel,
  TextField,
  Autocomplete,
  Tooltip,
  Chip,
  styled,
  InputBase,
  Button,
} from "@mui/material";
import {
  useCreateTagMutation,
  useDeleteTagMutation,
  useGetAllTagsQuery,
} from "../../features/parameters/tagsManager/tagsManagerApiSlice";
import { useDispatch } from "react-redux";
import { showError } from "../../features/snackbar/snackbarAction";
import SearchIcon from "@mui/icons-material/Search";
// import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import { AddRounded } from "@mui/icons-material";

const Transition = forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="up"
      ref={ref}
      {...props}
    />
  );
});

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  "&:hover": {},
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    width: "auto",
  },
  height: "30.6px",
  border: "1px solid #38395c",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 1.5),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(0.8, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    borderRadius: "5px",
  },
}));

export default function TagsBoxBox({
  moduleName = "",
  filterQuery = "",
  initTags = [],
  onTagsChange = () => {},
}) {
  const dispatch = useDispatch();

  const [tags, setTags] = useState([]);
  useEffect(() => setTags((t) => (t.length === 0 ? initTags : t)), [initTags]);

  const [createTag, { isLoading: createTagIsLoading }] = useCreateTagMutation();
  const [deleteTag, { isLoading: deleteTagIsLoading }] = useDeleteTagMutation();

  const { data: tagsData, isLoading: tagsDataIsLoading } = useGetAllTagsQuery({
    type: filterQuery,
  });

  const allTags = tagsData?.data?.data?.map(({ name }) => name) ?? [];

  const [searchKey, setSearchKey] = useState("");
  const [inputValue, setInputValue] = useState("");

  const [show, setShow] = useState(false);
  const openDialog = () => setShow(true);
  const closeDialog = () => setShow(false);

  const onTagSelectionChange = (_, tags) => {
    setTags(tags);
    onTagsChange(tags);
  };

  const insertTag = (itag = "") => {
    createTag({
      name: itag,
      status: "active",
      showFilter: true,
      type: filterQuery,
    })
      .unwrap()
      .then(() => {
        const mergedtags = tags.concat(itag);
        onTagSelectionChange(null, mergedtags);
      })
      .catch((e) =>
        dispatch(showError({ message: e?.data?.message ?? "Something went wrong" }))
      );
  };
  const removeTag = (dtag = "") => {
    const tag = tagsData?.data?.data?.find((t) => t.name === dtag);
    if (tag) {
      deleteTag(tag._id)
        .unwrap()
        .then(() => {
          const filteredtags = tags.filter((t) => t !== dtag);
          setTags(filteredtags);
          onTagsChange(filteredtags);
        })
        .catch((e) =>
          dispatch(showError({ message: e?.data?.message ?? "Something went wrong" }))
        );
    }
  };

  const onEnterTag = (ev) => {
    const { key } = ev;
    if (key === "Enter") {
      ev.preventDefault();
      if (inputValue.length > 0) {
        insertTag(inputValue);
      }
    }
  };
  const onClickTag = () => {
    if (inputValue.length > 0) {
      insertTag(inputValue);
    }
  };

  return (
    <div className="bg-black-15 border-grey-5 rounded-8 p-3 mt-4">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div className="d-flex">
          <h6 className="text-lightBlue fw-500">Tags</h6>
          <Tooltip
            title="Lorem ipsum"
            placement="top">
            <img
              src={info}
              alt="info"
              className="ms-2 c-pointer"
              width={13.5}
            />
          </Tooltip>
        </div>
        <small
          onClick={openDialog}
          className="text-blue-2 c-pointer">
          View all Tags
        </small>
        <Dialog
          open={show}
          TransitionComponent={Transition}
          keepMounted
          onClose={closeDialog}
          aria-describedby="alert-dialog-slide-description"
          fullWidth>
          <DialogTitle>
            <div className="d-flex justify-content-between align-items-center">
              <h5 className="text-lightBlue fw-500">All {moduleName} Tags</h5>
              <img
                src={cancel}
                alt="cancel"
                width={30}
                onClick={closeDialog}
                className="c-pointer"
              />
            </div>
          </DialogTitle>
          <hr className="hr-grey-6 my-0" />
          <DialogContent>
            <div className="row align-items-center">
              <div className="col-12 text-lightBlue my-3">
                <Search>
                  <SearchIconWrapper>
                    <SearchIcon sx={{ color: "#c8d8ff" }} />
                  </SearchIconWrapper>
                  <StyledInputBase
                    placeholder="Search Tags"
                    value={searchKey}
                    onChange={(e) => setSearchKey(e.target.value)}
                  />
                </Search>
                <div className="text-grey-6 font1 mt-2">
                  {
                    allTags.filter((tag) => tag.toUpperCase().includes(searchKey.toUpperCase()))
                      .length
                  }{" "}
                  {moduleName} Tags are listed below
                </div>
              </div>
              {allTags
                .filter((tag) => tag.toUpperCase().includes(searchKey.toUpperCase()))
                .map((tl, ix) => (
                  <div
                    key={ix}
                    className="col-12 py-2">
                    <div className="d-flex justify-content-between">
                      <Chip label={tl} />
                      <Tooltip
                        title="Delete"
                        placement="top">
                        <div
                          onClick={() => removeTag(tl)}
                          className="table-edit-icon c-pointer rounded-4 p-2">
                          <DeleteIcon sx={{ fontSize: 18, color: "#5c6d8e" }} />
                        </div>
                      </Tooltip>
                    </div>
                  </div>
                ))}
            </div>
          </DialogContent>
          <hr className="hr-grey-6 my-0" />
          <DialogActions className="d-flex justify-content-center px-4 py-3">
            <button
              onClick={closeDialog}
              className="button-grey py-2 px-5">
              <p className="text-lightBlue">Done</p>
            </button>
          </DialogActions>
        </Dialog>
      </div>
      <Autocomplete
        multiple
        options={allTags}
        disableCloseOnSelect
        disabled={tagsDataIsLoading || createTagIsLoading || deleteTagIsLoading}
        onChange={onTagSelectionChange}
        value={tags}
        inputValue={inputValue}
        onInputChange={(_, val) => setInputValue(val)}
        size="small"
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Search / Add Tags"
            onKeyDown={onEnterTag}
          />
        )}
        noOptionsText={
          <Button
            fullWidth
            variant="outlined"
            onClick={onClickTag}
            startIcon={
              <AddRounded
                sx={{
                  bgcolor: "#648DED",
                  color: "#14122b",
                  fontSize: "18px",
                }}
              />
            }>
            <span
              style={{ fontSize: "14px" }}
              className="text-lightBlue">
              Add {inputValue}
            </span>
          </Button>
        }
        filterOptions={(props, { inputValue }) => {
          return props.filter((e) => e.toLowerCase().includes(inputValue.toLowerCase()));
        }}
        renderOption={(props, option, { selected }) => {
          return (
            <div
              {...props}
              className={`${props.className} rounded-4 text-lightBlue`}>
              <FormControlLabel
                label={option}
                checked={selected}
                control={
                  <Checkbox
                    size="small"
                    style={{ color: "#5C6D8E" }}
                  />
                }
              />
            </div>
          );
        }}
      />
    </div>
  );
}
