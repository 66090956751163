import { useState, forwardRef, useEffect, useReducer, useCallback, useRef } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import _ from "lodash";
import { useDispatch } from "react-redux";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputAdornment,
  OutlinedInput,
  Checkbox,
  FormControlLabel,
  Chip,
  Radio,
  RadioGroup,
  Slide,
  Tooltip,
  Autocomplete,
  TextField,
  Box,
  Tabs,
  Tab,
} from "@mui/material";

import {
  useCreateCustomerAddressMutation,
  useEditCustomerAddressMutation,
} from "../../../features/customers/customerAddress/customerAddressApiSlice";
import { useGetAllCountryQuery } from "../../../features/master/country/countryApiSlice";
import { useGetAllStateQuery } from "../../../features/master/state/stateApiSlice";
import { useGetAllCityQuery } from "../../../features/master/city/cityApiSlice";
import { showSuccess, showError } from "../../../features/snackbar/snackbarAction";

import AppCountrySelect from "../../../components/AppCountrySelect/AppCountrySelect";
import AppStateSelect from "../../../components/AppStateSelect/AppStateSelect";
import AppMobileCodeSelect from "../../../components/AppMobileCodeSelect/AppMobileCodeSelect";
import AppCitySelect from "../../../components/AddCitySelect/AddCitySelect";
import { DeleteModalSecondary } from "../../../components/DeleteModal/DeleteModal";

import archivedGrey from "../../../assets/icons/archivedGrey.svg";
import editGrey from "../../../assets/icons/editGrey.svg";
import cancel from "../../../assets/icons/cancel.svg";

import "./AddUser.scss";
import { useGetPinCodeQuery } from "../../../features/master/pincode/pinCodeApiSlice";
import ZipCodeComponent from "../../../components/ZipCodeCityComponent";
import {
  useGetAddressQuery,
  useGetPincodeQuery,
} from "../../../features/googlemaps/mapsApiSlice";

const Transition = forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="up"
      ref={ref}
      {...props}
    />
  );
});

const initialAddressState = {
  confirmationMessage: "",
  isEditing: false,
};

const addressTabReducer = (state, action) => {
  if (action.type === "ENABLE_EDIT") {
    return {
      ...initialAddressState,
      isEditing: true,
    };
  }
  if (action.type === "DISABLE_EDIT") {
    return {
      ...initialAddressState,
      isEditing: false,
    };
  }
  return initialAddressState;
};

const customerAddressValidation = Yup.object({
  name: Yup.string().trim().min(3, "Name must be at least 3 characters").required("Required"),
  firstName: Yup.string()
    .trim()
    .min(3, "First name must be at least 3 characters")
    .required("Required"),
  lastName: Yup.string()
    .trim()
    .min(3, "Last name must be at least 3 characters")
    .required("Required"),
  countryCode: Yup.string().required("Required"),
  phone: Yup.string()
    .matches(/^\d{10}$/, "Mobile Number must be 10 digits numeric value only.")
    .required("Required"),
  country: Yup.string().required("Required"),
  line1: Yup.string().required("Required"),
  city: Yup.string().required("Required"),
  pinCode: Yup.string()
    .matches(/^\d{6}$/, "Pincode must be 6 digits numeric value only.")
    .required("Required"),
  state: Yup.string().required("Required"),
});

const AddAddress = ({
  customerAddressDetails,
  deleteAddress,
  data,
  value2,
  customerId,
  setEdit,
}) => {
  const dispatch = useDispatch();
  const [address, setAddress] = useState([]);
  const [openNewUser, setOpenNewUser] = useState(false);
  const [editAdd, setEditAddress] = useState("");
  const [addressData, setAddressData] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showCode, setcode] = useState("");
  const [addressState, dispatchAddress] = useReducer(addressTabReducer, initialAddressState);
  const [addtessType, setAddtessType] = useState("shipping");

  const updateAddr = data[showCode];

  const [
    createCustomerAddress,
    {
      isLoading: createAddressIsLoading,
      isSuccess: createAddressIsSuccess,
      error: createAddressError,
    },
  ] = useCreateCustomerAddressMutation();

  const [
    editCustomerAddress,
    {
      data: editAddressData,
      isLoading: editAddressIsLoading,
      isSuccess: editAddressIsSuccess,
      error: editAddressError,
    },
  ] = useEditCustomerAddressMutation();

  const {
    data: countryData,
    isLoading: countryIsLoading,
    isSuccess: countryIsSuccess,
    error: countryError,
  } = useGetAllCountryQuery({ createdAt: -1 });

  const {
    data: stateData,
    isLoading: stateIsLoading,
    isSuccess: stateIsSuccess,
    error: stateError,
  } = useGetAllStateQuery({ createdAt: -1 });

  const {
    data: cityData,
    isLoading: cityIsLoading,
    isSuccess: cityIsSuccess,
    error: cityError,
  } = useGetAllCityQuery({ createdAt: -1 });

  const customerAddressFormik = useFormik({
    initialValues: {
      type: editAdd ? addressData?.type : "shipping",
      name: editAdd ? addressData?.name : "",
      firstName: editAdd ? addressData?.firstName : "",
      lastName: editAdd ? addressData?.lastName : "",
      companyName: editAdd ? addressData?.companyName : "",
      gstIn: editAdd ? addressData?.gstIn : "",
      countryCode: editAdd ? addressData?.countryCode : "",
      country: editAdd ? addressData?.country : "",
      phone: editAdd ? addressData?.phone : "",
      line1: editAdd ? addressData?.line1 : "",
      line2: editAdd ? addressData?.line2 : "",
      city: editAdd ? addressData?.city : "",
      pinCode: editAdd ? addressData?.pinCode : "",
      state: editAdd ? addressData?.state : "",
      isDefaultAddress: editAdd ? addressData?.isDefaultAddress : false,
      customerId: !editAdd ? customerId : "",
    },
    enableReinitialize: true,
    validationSchema: customerAddressValidation,
    onSubmit: (values, helper) => {
      for (const key in values) {
        if (values[key] === "" || values[key] === null) {
          delete values[key];
        }
      }
      customerAddressDetails([values]);
      setAddress((prevAddresses) => [...prevAddresses, values]);
      setOpenNewUser(false);
      helper.resetForm();

      if (addressData?.id) {
        editCustomerAddress({
          id: addressData?.id,
          details: values,
        })
          .unwrap()
          .then(() => {
            dispatch(
              showSuccess({
                message: `${values.firstName + " " + values.lastName} edited successfully`,
              })
            );
          });
      }
    },
  });

  const handleCode = (event, value) => {
    customerAddressFormik.setFieldValue("countryCode", value?._id);
  };

  const selectCountryName = useCallback(
    (event, value) => {
      customerAddressFormik.setFieldValue("country", value?._id);
    },
    [customerAddressFormik]
  );

  const getStateName = useCallback(
    (event, value) => {
      customerAddressFormik.setFieldValue("state", value?._id);
    },
    [customerAddressFormik]
  );

  const SelectCityName = useCallback(
    (event, value) => {
      customerAddressFormik.setFieldValue("city", value?._id);
    },
    [customerAddressFormik]
  );

  const handleNewUser = (address, index) => {
    setcode(index);
    setAddressData(address);
    setOpenNewUser(true);
    setEditAddress(true);
    setEdit(false);
  };

  const handleNewUserClose = () => {
    customerAddressFormik.resetForm();
    setOpenNewUser(false);
  };

  useEffect(() => {
    if (!_.isEqual(customerAddressFormik.values, customerAddressFormik.initialValues)) {
      dispatchAddress({ type: "ENABLE_EDIT" });
    } else if (_.isEqual(customerAddressFormik.values, customerAddressFormik.initialValues)) {
      dispatchAddress({ type: "DISABLE_EDIT" });
    }
  }, [customerAddressFormik.initialValues, customerAddressFormik.values, dispatchAddress]);

  const handleNewAddress = () => {
    setAddressData("");
    customerAddressFormik.resetForm();
    setOpenNewUser(true);
    setEditAddress(false);
    setEdit(true);
  };

  const deleteAddr = (index, addrId) => {
    setcode(index);
    setAddressData(addrId);
    setShowDeleteModal(true);
  };

  const hideAddress = () => {
    setShowDeleteModal(false);
  };

  const handleDeleteModal = () => {
    setShowDeleteModal(false);
    deleteAddress(showCode, addressData);
  };

  return (
    <div className="bg-black-15 border-grey-5 rounded-8 p-3 row attributes mt-4">
      <div className="d-flex col-12 px-0 justify-content-between">
        <div className="d-flex align-items-center">
          <h6 className="text-lightBlue me-auto text-lightBlue fw-500">Addresses</h6>
        </div>
        <p
          className="button-gradient py-2 px-3"
          onClick={handleNewAddress}>
          <p className="">+ Add Address</p>
        </p>
      </div>

      <Box
        sx={{ width: "100%" }}
        className="d-flex justify-content-between tabs-header-box mb-3">
        <Tabs
          className="tabs"
          value={addtessType}
          onChange={(_, v) => setAddtessType(v)}>
          <Tab
            value="shipping"
            label="Shipping Address"
            className="tabs-head"
          />
          <Tab
            value="billing"
            label="Billing Address"
            className="tabs-head"
          />
        </Tabs>
      </Box>

      {value2?.address
        ?.filter?.((address) => addtessType === address?.type)
        ?.map?.((address, index) => {
          const selectedCode = countryData?.data?.data.find(
            (country) => country._id === address?.countryCode
          );
          const selectedCountry = countryData?.data?.data.find(
            (country) => country._id === address?.country
          );
          const selectedState = stateData?.data?.data.find(
            (state) => state._id === address?.state
          );
          const selectedCity = cityData?.data?.data.find((city) => city._id === address?.city);

          return (
            <div
              className="col-12 mt-3"
              key={index}>
              <div
                className="row py-3 mb-3 rounded-8"
                style={{ background: "rgba(39, 40, 63, 0.5)" }}>
                <div className="col-12 d-flex justify-content-between align-items-center mb-2 px-3">
                  <p className="text-lightBlue">{address?.name}</p>
                  <div className="d-flex align-items-center">
                    {!!address?.isDefaultAddress && (
                      <Chip
                        label="Default"
                        size="small"
                        className="px-2"
                      />
                    )}
                    <Tooltip
                      title="Edit"
                      placement="top">
                      <div
                        className="table-edit-icon rounded-4 p-1"
                        onClick={() => handleNewUser(address, index)}>
                        <img
                          src={editGrey}
                          alt="editGrey"
                          className="c-pointer mx-1"
                          width={16}
                        />
                      </div>
                    </Tooltip>
                    <Tooltip
                      title="Delete"
                      placement="top">
                      <div
                        className="table-edit-icon rounded-4 p-1"
                        onClick={() => deleteAddr(index, address?.id)}>
                        <img
                          src={archivedGrey}
                          alt="archiverdGrey"
                          className="c-pointer mx-1"
                          width={16}
                        />
                      </div>
                    </Tooltip>
                  </div>
                </div>
                <div className="col-12 px-3">
                  <small className="text-lightBlue d-block">
                    {address?.firstName} {address?.lastName}
                  </small>
                  <small className="text-lightBlue d-block">{address?.line1}</small>
                  <small className="text-lightBlue d-block">{address?.companyName}</small>
                  <small className="text-lightBlue d-block">
                    {selectedCity?.name}-{address?.pinCode}, {selectedState?.name},{" "}
                    {selectedCountry?.name}
                  </small>
                  <small className="text-lightBlue d-block">
                    {selectedCode?.countryCode} {address?.phone}
                  </small>
                </div>
              </div>
            </div>
          );
        })}

      <Dialog
        open={openNewUser}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleNewUserClose}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="sm"
        fullWidth={true}
        scroll={"paper"}>
        <DialogTitle>
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex flex-column ">
              <h5 className="text-lightBlue fw-500">
                {editAdd == true
                  ? `Edit ${customerAddressFormik.values.name} `
                  : "Create New Address"}
              </h5>

              {/* <small className="text-grey-6 mt-1 d-block">
                ⓘ Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </small> */}
            </div>
            <img
              src={cancel}
              alt="cancel"
              width={30}
              onClick={handleNewUserClose}
              className="c-pointer"
            />
          </div>
        </DialogTitle>
        <hr className="hr-grey-6 my-0" />
        <DialogContent className="pb-4 px-4">
          <div className="row rounded-8">
            <div className="col-md-12">
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="type"
                className="features-radio px-0"
                value={customerAddressFormik.values.type}
                onChange={customerAddressFormik.handleChange}>
                <FormControlLabel
                  value="billing"
                  control={<Radio size="small" />}
                  label="Billing Address"
                  sx={{
                    "& .MuiTypography-root": {
                      fontSize: 13,
                      color: "#c8d8ff",
                      marginRight: 1,
                    },
                  }}
                />
                <FormControlLabel
                  value="shipping"
                  control={<Radio size="small" />}
                  label="Shipping Address"
                  sx={{
                    "& .MuiTypography-root": {
                      fontSize: 13,
                      color: "#c8d8ff",
                      marginRight: 1,
                    },
                  }}
                />
              </RadioGroup>
            </div>
            <div className="col-md-12">
              <p className="text-lightBlue mb-1">
                Address Name <span style={{ color: "#F67476" }}>*</span>
              </p>
              <FormControl className="w-100 px-0">
                <OutlinedInput
                  placeholder="Office Address, Home Address"
                  size="small"
                  name="name"
                  value={customerAddressFormik.values.name}
                  onChange={customerAddressFormik.handleChange}
                />
              </FormControl>
              {!!customerAddressFormik.touched.name && customerAddressFormik.errors.name && (
                <FormHelperText error>{customerAddressFormik.errors.name}</FormHelperText>
              )}
              <small className="text-grey-6">
                Name this address Ex. Office Address, Home Address
              </small>
            </div>
            <div className="col-12">
              <FormControlLabel
                control={
                  <Checkbox
                    name="isDefaultAddress"
                    value={customerAddressFormik.values.isDefaultAddress}
                    onChange={customerAddressFormik.handleChange}
                    inputProps={{ "aria-label": "controlled" }}
                    size="small"
                    style={{
                      color: "#5C6D8E",
                    }}
                  />
                }
                label="Set as Default Address"
                sx={{
                  "& .MuiTypography-root": {
                    fontSize: 13,
                    color: "#c8d8ff",
                  },
                }}
              />
            </div>
            <div className="col-md-6 mt-3">
              <p className="text-lightBlue mb-1">
                First Name <span style={{ color: "#F67476" }}>*</span>
              </p>
              <FormControl className="w-100 px-0">
                <OutlinedInput
                  placeholder="Enter First Name"
                  size="small"
                  name="firstName"
                  value={customerAddressFormik.values.firstName}
                  onChange={customerAddressFormik.handleChange}
                />
              </FormControl>
              {!!customerAddressFormik.touched.firstName &&
                customerAddressFormik.errors.firstName && (
                  <FormHelperText error>
                    {customerAddressFormik.errors.firstName}
                  </FormHelperText>
                )}
            </div>
            <div className="col-md-6 mt-3">
              <p className="text-lightBlue mb-1">
                Last Name <span style={{ color: "#F67476" }}>*</span>
              </p>
              <FormControl className="w-100 px-0">
                <OutlinedInput
                  placeholder="Enter Last Name"
                  size="small"
                  name="lastName"
                  value={customerAddressFormik.values.lastName}
                  onChange={customerAddressFormik.handleChange}
                />
              </FormControl>
              {!!customerAddressFormik.touched.lastName &&
                customerAddressFormik.errors.lastName && (
                  <FormHelperText error>{customerAddressFormik.errors.lastName}</FormHelperText>
                )}
            </div>
            <div className="col-md-12 mt-3">
              <p className="text-lightBlue mb-1">Company Name</p>
              <FormControl className="w-100 px-0">
                <OutlinedInput
                  placeholder="Enter company name"
                  size="small"
                  name="companyName"
                  value={customerAddressFormik.values.companyName}
                  onChange={customerAddressFormik.handleChange}
                />
              </FormControl>
            </div>
            {customerAddressFormik.values.type === "billing" && (
              <div className="col-md-12 mt-3">
                <p className="text-lightBlue mb-1">GSTIN</p>
                <FormControl className="w-100 px-0">
                  <OutlinedInput
                    placeholder="Enter GSTIN"
                    size="small"
                    name="gstIn"
                    value={customerAddressFormik.values.gstIn}
                    onChange={customerAddressFormik.handleChange}
                  />
                </FormControl>
              </div>
            )}
            <div className="col-md-12 mt-3">
              <p className="text-lightBlue mb-1">
                Mobile Number <span style={{ color: "#F67476" }}>*</span>
              </p>
              <FormControl className="w-100 px-0">
                <OutlinedInput
                  placeholder="Enter Mobile Number"
                  size="small"
                  sx={{ paddingLeft: 0 }}
                  name="phone"
                  value={customerAddressFormik.values.phone}
                  onChange={customerAddressFormik.handleChange}
                  startAdornment={
                    <InputAdornment position="start">
                      <AppMobileCodeSelect
                        formik={customerAddressFormik}
                        countryCode={customerAddressFormik.values.countryCode}
                        handleCode={handleCode}
                      />
                      {/* <OutlinedInput
                        placeholder="CC"
                        size="small"
                        sx={{ paddingLeft: 0 }}
                        name="countryCode"
                        value={customerAddressFormik.values.countryCode}
                        onChange={customerAddressFormik.handleChange}
                      /> */}
                    </InputAdornment>
                  }
                />
              </FormControl>
              {(!!customerAddressFormik.touched.countryCode ||
                !!customerAddressFormik.touched.phone) &&
                (customerAddressFormik.errors.countryCode ||
                  customerAddressFormik.errors.phone) && (
                  <FormHelperText error>
                    {customerAddressFormik.errors.countryCode ||
                      customerAddressFormik.errors.phone}
                  </FormHelperText>
                )}
            </div>
            {/* <div className="col-md-6 mt-3">
              <p className="text-lightBlue mb-1">
                Address Line 1 <span style={{ color: "#F67476" }}>*</span>
              </p>
              <Autocomplete
                options={suggestedAddrs}
                autoHighlight
                size="small"
                getOptionLabel={(option) => option?.description}
                onInputChange={onLineInputChange}
                onChange={onLineChange}
                value={selAddr}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Type address"
                  />
                )}
              />
              {!!customerAddressFormik.touched.line1 && customerAddressFormik.errors.line1 && (
                <FormHelperText error>{customerAddressFormik.errors.line1}</FormHelperText>
              )}
            </div>
            <div className="col-md-6 mt-3">
              <p className="text-lightBlue mb-1">Address Line 2</p>
              <FormControl className="w-100 px-0">
                <OutlinedInput
                  placeholder="Enter Address Line 2"
                  size="small"
                  name="line2"
                  value={customerAddressFormik.values.line2}
                  onChange={customerAddressFormik.handleChange}
                />
              </FormControl>
            </div> */}
            {openNewUser && (
              <ZipCodeComponent
                cityVal={customerAddressFormik.values.city}
                onchangeCity={SelectCityName}
                errorCity={customerAddressFormik.errors.city}
                touchedCity={customerAddressFormik.touched.city}
                stateVal={customerAddressFormik.values.state}
                onchangeState={getStateName}
                errorState={customerAddressFormik.errors.state}
                touchedState={customerAddressFormik.touched.state}
                countryVal={customerAddressFormik.values.country}
                onchangeCountry={selectCountryName}
                errorCountry={customerAddressFormik.errors.country}
                touchedCountry={customerAddressFormik.touched.country}
                zipVal={customerAddressFormik.values.pinCode}
                onchangeZip={customerAddressFormik.handleChange}
                errorZip={customerAddressFormik.errors.pinCode}
                touchedZip={customerAddressFormik.touched.pinCode}
                onchangeLine1={(line) => customerAddressFormik.setFieldValue("line1", line)}
                onchangeLine2={(line) =>
                  customerAddressFormik.setFieldValue("line2", line.target.value)
                }
                touchedLine1={customerAddressFormik.touched.line1}
                errorLine1={customerAddressFormik.errors.line1}
                nameLine2="line2"
                namePincode="pinCode"
                valueLine2={customerAddressFormik.values.line2}
                onchangePincode={(code) => customerAddressFormik.setFieldValue("pinCode", code)}
                line1Val={customerAddressFormik.values.line1}
              />
            )}
          </div>
        </DialogContent>
        <hr className="hr-grey-6 my-0" />
        <DialogActions className="d-flex justify-content-between px-4 py-3">
          <button
            className="button-grey py-2 px-5"
            onClick={handleNewUserClose}>
            <p className="text-lightBlue">Cancel</p>
          </button>
          <button
            className="button-gradient py-2 px-5"
            type="button"
            onClick={customerAddressFormik.handleSubmit}>
            <p>{editAdd == true ? "Save" : "Create"}</p>
          </button>
        </DialogActions>
      </Dialog>
      <DeleteModalSecondary
        message="customer address"
        title="customer address"
        onConfirm={handleDeleteModal}
        onCancel={hideAddress}
        show={showDeleteModal}
      />
    </div>
  );
};

export default AddAddress;
