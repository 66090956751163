import apiSlice from "../../../app/api/apiSlice";
import { omitEmptyKeys } from "../../../utils/helper";

export const customerAddressApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCustomerAddress: builder.query({
      query: (queries) => ({
        url: "/customerAddress",
        method: "GET",
        params: omitEmptyKeys(queries),
      }),
      providesTags: ["CustomersAddress"],
    }),

    createCustomerAddress: builder.mutation({
      query: (customerAddressDetails) => ({
        url: "/customerAddress",
        method: "POST",
        body: customerAddressDetails,
      }),
      invalidatesTags: ["CustomersAddress", "Customers", "Logs"],
    }),

    editCustomerAddress: builder.mutation({
      query: ({ id, details }) => ({
        url: `/customerAddress/${id}`,
        method: "PUT",
        body: details,
      }),
      invalidatesTags: ["CustomersAddress", "Logs"],
    }),

    deleteCustomerAddress: builder.mutation({
      query: (customerAddressId) => ({
        url: `/customerAddress/${customerAddressId}`,
        method: "DELETE",
        body: customerAddressId,
      }),
      invalidatesTags: ["CustomersAddress", "Logs"],
    }),
  }),
});

export const {
  useGetCustomerAddressQuery,
  useCreateCustomerAddressMutation,
  useEditCustomerAddressMutation,
  useDeleteCustomerAddressMutation,
} = customerAddressApiSlice;
