import { useState, Fragment } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Chip,
  IconButton,
  Collapse,
  TableHead,
} from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import TableHeader from "../../../components/TableHeader/TableHeader";
import EditButton from "../../../components/EditButton/EditButton";
import DeleteIconButton from "../../../components/DeleteIconButton/DeleteIconButton";
import NoDataFound from "../../../components/NoDataFound/NoDataFound";
import TableLoader from "../../../components/Loader/TableLoader";
import ArchivedIconButton from "../../../components/ArchivedIconButton/ArchivedIconButton";
import UnArchivedIconButton from "../../../components/UnArchivedIconButton/UnArchivedIconButton";

import { rupeeFormatWithSymbol } from "../../../utils/format";
import { Diversity1 } from "@mui/icons-material";

const PAGINATION_ROWS = [10, 20, 30];

const PRICE_TYPE = {
  perGram: "Per Gram",
  fixedPricing: "Fixed Price",
};

const createHeadCellsHandler = (masterData, option) => {
  if (masterData.type === "option") {
    if (masterData.priceType === "default") {
      return [
        {
          align: "left",
          disablePadding: false,
          label: "",
          width: "1%",
        },
        {
          align: "left",
          disablePadding: false,
          label: option?.title,
          width: "59%",
        },
        {
          align: "left",
          disablePadding: false,
          label: "Price",
          width: "15%",
        },
        {
          align: "left",
          disablePadding: false,
          label: "Price Type",
          width: "15%",
        },
        {
          align: "right",
          disablePadding: false,
          label: "Action",
          width: "10%",
        },
      ];
    } else {
      return [
        // {
        //   align: "left",
        //   disablePadding: false,
        //   label: "",
        //   width: "5%",
        // },
        {
          align: "left",
          disablePadding: false,
          label: "",
          width: "5%",
        },
        {
          align: "left",
          disablePadding: false,
          label: option?.title,
          width: "55%",
        },
        {
          align: "left",
          disablePadding: false,
          label: "Price",
          width: "15%",
        },
        {
          align: "left",
          disablePadding: false,
          label: "Price Type",
          width: "15%",
        },
        {
          align: "right",
          disablePadding: false,
          label: "Action",
          width: "10%",
        },
      ];
    }
  } else {
    if (masterData.priceType === "default") {
      return [
        {
          align: "left",
          disablePadding: false,
          label: "",
          width: "1%",
        },
        {
          align: "left",
          disablePadding: false,
          label: "Category",
          width: "59%",
        },
        {
          align: "left",
          disablePadding: false,
          label: "Price",
          width: "15%",
        },
        {
          align: "left",
          disablePadding: false,
          label: "Price Type",
          width: "15%",
        },
        {
          align: "right",
          disablePadding: false,
          label: "Action",
          width: "10%",
        },
      ];
    } else {
      return [
        // {
        //   align: "left",
        //   disablePadding: false,
        //   label: "",
        //   width: "5%",
        // },
        {
          align: "left",
          disablePadding: false,
          label: "",
          width: "5%",
        },
        {
          align: "left",
          disablePadding: false,
          label: "Category",
          width: "55%",
        },

        {
          align: "left",
          disablePadding: false,
          label: "Price",
          width: "15%",
        },
        {
          align: "left",
          disablePadding: false,
          label: "Price Type",
          width: "15%",
        },
        {
          align: "right",
          disablePadding: false,
          label: "Action",
          width: "10%",
        },
      ];
    }
  }
};

const PriceManagerTable = (props) => {
  const {
    error,
    isLoading,
    data,
    totalCount,
    onPageChange,
    onPageSize,
    pageSize,
    page,
    onEdit,
    onDelete,
    masterData,
    option,
  } = props;
  const [expandedRowId, setExpandedRowId] = useState(null);

  const onRowsPerPageChange = (e) => {
    onPageSize(e.target.value);
  };

  if (error) {
    return <></>;
  }

  if (isLoading) {
    return <TableLoader />;
  }

  if (!data) {
    return <></>;
  }

  if (data && !data.length) {
    return <NoDataFound />;
  }

  const HEAD_CELLS = createHeadCellsHandler(masterData, option);

  const isOption = masterData.type === "option";
  const isPriceTypeDefault = masterData.priceType === "default";

  const expandRowHandler = (id) => {
    setExpandedRowId((prevState) => (prevState === id ? null : id));
  };

  return (
    <>
      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          size="medium">
          <TableHeader headCells={HEAD_CELLS} />
          <TableBody>
            {data.map((manager) => {
              const isExpanded = expandedRowId === manager._id;

              return (
                <Fragment key={manager._id}>
                  <TableRow
                    hover
                    tabIndex={-1}
                    className="table-rows">
                    {isPriceTypeDefault && <TableCell />}
                    {!isPriceTypeDefault && (
                      <TableCell>
                        <div className="d-flex align-items-center justify-content-center">
                          <IconButton
                            onClick={expandRowHandler.bind(null, manager._id)}
                            aria-label="expand row"
                            size="small">
                            {isExpanded ? (
                              <ArrowDropDownIcon
                                sx={{
                                  color: "#5c6d8e",
                                  fontSize: 24,
                                  cursor: "pointer",
                                }}
                              />
                            ) : (
                              <ArrowRightIcon
                                sx={{
                                  color: "#c8d8ff",
                                  fontSize: 24,
                                  cursor: "pointer",
                                }}
                              />
                            )}
                          </IconButton>
                        </div>
                      </TableCell>
                    )}
                    <TableCell sx={{ textTransform: "capitalize", cursor: "pointer" }}>
                      <p className="text-lightBlue fw-600">
                        {isOption ? (
                          manager.values?.length ? (
                            <span>
                              {manager.metaAttributeId?.title}{" "}
                              {!!manager?.values?.length &&
                                manager?.values[0]?.metaSubAttributeId &&
                                manager?.values.map((value, index) => (
                                  <span key={index}>
                                    <span className="text-grey-6">&#x2022;</span>{" "}
                                    {value?.subAttributeValueId?.title}{" "}
                                  </span>
                                ))}
                            </span>
                          ) : (
                            manager?.metaAttributeId?.title
                          )
                        ) : !!manager?.subCategory?.name ? (
                          <span>
                            {manager?.categoryId?.name}{" "}
                            <span className="text-grey-6">&#x2022;</span>{" "}
                            <span>{manager?.subCategory?.name}</span>
                          </span>
                        ) : (
                          <span>{manager?.categoryId?.name}</span>
                        )}
                      </p>
                    </TableCell>
                    <TableCell sx={{ textTransform: "capitalize", cursor: "pointer" }}>
                      <p className="text-lightBlue fw-600">
                        {isPriceTypeDefault ? (
                          rupeeFormatWithSymbol(manager?.price?.price)
                        ) : (
                          <button
                            onClick={expandRowHandler.bind(null, manager._id)}
                            type="button"
                            className="reset link">
                            <p>{isExpanded ? "Hide" : "View"}</p>
                          </button>
                        )}
                      </p>
                    </TableCell>
                    <TableCell sx={{ textTransform: "capitalize", cursor: "pointer" }}>
                      <p className="text-lightBlue fw-600">
                        {isPriceTypeDefault ? PRICE_TYPE[manager.price?.priceType] : "Mixed"}
                      </p>
                    </TableCell>
                    <TableCell>
                      <div className="d-flex align-items-center justify-content-end">
                        <EditButton onClick={onEdit.bind(null, manager)} />
                        {!isOption && !!manager?.subCategory?.name && (
                          <DeleteIconButton
                            onClick={onDelete.bind(null, {
                              id: manager._id,
                              message: `${
                                isOption
                                  ? `<span className="text-blue-2">
                                  ${manager?.metaAttributeId?.title}
                                </span>`
                                  : `<span className="text-blue-2">${manager?.categoryId?.name}</span>`
                              } Price Manager`,
                            })}
                            title="Delete"
                          />
                        )}
                      </div>
                    </TableCell>
                  </TableRow>
                  {!isPriceTypeDefault && (
                    <TableRow>
                      <TableCell
                        style={{ padding: 0 }}
                        colSpan={12}>
                        <Collapse
                          in={isExpanded}
                          timeout="auto"
                          unmountOnExit>
                          <hr className="hr-grey-6 my-0" />
                          <div className="px-5 pt-2 pb-3 bg-black-15 ms-5">
                            <div className="d-flex justify-content-between">
                              <h5 className="text-lightBlue my-3 fw-500 ps-3">
                                {isOption
                                  ? manager?.metaAttributeId?.title
                                  : manager?.categoryId?.name}
                              </h5>
                              <button
                                onClick={onEdit.bind(null, manager)}
                                type="button"
                                className="ms-auto reset link">
                                <p>+ Add More Range</p>
                              </button>
                            </div>
                            <Table
                              size="small"
                              aria-label="purchases"
                              className="ms-2">
                              <TableHead>
                                <TableRow>
                                  {isOption && (
                                    <TableCell>
                                      <p className="text-lightBlue">Size</p>
                                    </TableCell>
                                  )}
                                  <TableCell>
                                    <p className="text-lightBlue">Min</p>
                                  </TableCell>
                                  <TableCell>
                                    <p className="text-lightBlue">Max</p>
                                  </TableCell>
                                  <TableCell>
                                    <p className="text-lightBlue">Price</p>
                                  </TableCell>
                                  <TableCell>
                                    <p className="text-lightBlue">Price Type</p>
                                  </TableCell>
                                  <TableCell>
                                    <p className="text-lightBlue">Action</p>
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {manager.price?.map((price, index) => (
                                  <TableRow key={index}>
                                    {isOption && (
                                      <TableCell>
                                        <p className="text-lightBlue">
                                          {price?.subAttributeValueId?.title}
                                        </p>
                                      </TableCell>
                                    )}
                                    <TableCell
                                      component="th"
                                      scope="row">
                                      <p className="text-lightBlue">{price.minWeight}</p>
                                    </TableCell>
                                    <TableCell>
                                      <p className="text-lightBlue">{price.maxWeight}</p>
                                    </TableCell>
                                    <TableCell>
                                      <p className="text-lightBlue">
                                        {rupeeFormatWithSymbol(price.price)}
                                      </p>
                                    </TableCell>
                                    <TableCell>
                                      <p className="text-ligthBlue">
                                        <span className="text-lightBlue">
                                          {PRICE_TYPE[price.priceType]}
                                        </span>
                                      </p>
                                    </TableCell>
                                    <TableCell>
                                      <EditButton onClick={onEdit.bind(null, manager)} />
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </div>
                          <hr className="hr-grey-6 my-0" />
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  )}
                </Fragment>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={PAGINATION_ROWS}
        component="div"
        count={totalCount || 0}
        rowsPerPage={pageSize}
        page={page - 1}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        className="table-pagination"
      />
    </>
  );
};

export default PriceManagerTable;
