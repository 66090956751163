import { forwardRef } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  Tooltip
} from "@mui/material";
import cancel from "../../../../assets/icons/cancel.svg";
import DashboardBox from "../../../../components/Setting/Store/DashBoardBox";
import InputBox from "../../../../components/Setting/InputBox.js";
import info from "../../../../assets/icons/info.svg";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EditTimeZone = ({ onConfirm, show }) => {
    const saveGroups = () => {
        onConfirm()
    }

    return (
        <Dialog
            open={show}
            TransitionComponent={Transition}
            keepMounted
            onClose={onConfirm}
            aria-describedby="alert-dialog-slide-description"
            maxWidth="md"
            fullWidth={true}
        >
            <DialogTitle>
                <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex flex-column ">
                        <h5 className="text-lightBlue fw-500">Edit Timezone Format</h5>

                        <small className="text-grey-6 mt-1 d-block">
                            ⓘ Lorem ipsum dolor sit amet consectetur.
                        </small>
                    </div>
                    <img
                        src={cancel}
                        alt="cancel"
                        width={30}
                        onClick={onConfirm}
                        className="c-pointer"
                    />
                </div>
            </DialogTitle>
            <hr className="hr-grey-6 my-0" />
            <DialogContent className="mx-4">
                <DashboardBox 
                    inputName={"Timezone"}
                    placeholder="Kolkata West Bengal (GMT+5:30)"
                />

                <div className="d-flex col-lg-12">
                    <div className="col-lg-6 pl-3">
                        <DashboardBox 
                            inputName={"Date"}
                            placeholder="10th April, 2023"
                        />
                    </div>
                    <div className="col-lg-6">
                        <DashboardBox 
                            inputName={"Time"}
                            placeholder="HH:MM AM/PM"
                        />
                    </div>
                </div>

            </DialogContent>
            <div className="bg-black-21 borderRadiusTop-8 mx-5">
                <div className="d-flex justify-content-between align-items-center mb-1 py-3 px-4">
                    <div className="d-flex">
                        <p className="text-lightBlue fw-500">Preview</p>
                        <Tooltip title="Some text" placement="top">
                            <img
                                src={info}
                                alt="info"
                                className="ms-2 c-pointer mr-2"
                                width={13.5}
                            />
                        </Tooltip>
                        <h6 className="text-lightBlue fw-500">10th April, 2023 | 12:35 pm</h6>
                    </div>
                </div>
            </div>
            <hr className="hr-grey-6 my-0" />
            <DialogActions className="d-flex justify-content-between px-4 py-3">
                <button
                    className="button-grey py-2 px-5"
                >
                    <p className="text-lightBlue">Cancel</p>
                </button>
                <button
                    className="button-gradient py-2 px-5"
                    onClick={() => saveGroups()}
                >
                    <p>Save</p>
                </button>
            </DialogActions>
        </Dialog>
    );
};

export default EditTimeZone;