import { useState, forwardRef, useMemo } from "react";
import { useDispatch } from "react-redux";
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Slide,
  Tab,
  Tabs,
} from "@mui/material";
import { showError, showSuccess } from "../../../features/snackbar/snackbarAction";
import { TableSearchSecondary } from "../../../components/TableSearch/TableSearch";
import cancel from "../../../assets/icons/cancel.svg";
import AppGenericSelect from "../../../components/AppGenericSelect/AppGenericSelect";
import { useFormik } from "formik";
import * as Yup from "yup";
import { values } from "lodash";
import { useGetAllLabelsBadgessQuery } from "../../../features/functionality/labelsBadges/labelsBadges";
import { useBulkLabelBadgeUpdateMutation } from "../../../features/products/product/productApiSlice";

const Transition = forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="up"
      ref={ref}
      {...props}
    />
  );
});

const validationSchema = Yup.object().shape({
  productIds: Yup.array()
    .of(Yup.string().trim())
    .min(1, "Minimum one product should be selected")
    .required("Products value is required"),
  label: Yup.string().trim().required("Label or Badge value is required"),
});

export default function BulkLabelsBadgesDialog({
  show = false,
  onClose = () => {},
  selected = [],
  setSelected = () => {},
}) {
  const dispatch = useDispatch();

  const [bulkLabelBadgeUpdate, { isLoading }] = useBulkLabelBadgeUpdateMutation();

  const initialValues = useMemo(
    () => ({
      productIds: selected.map((s) => s._id),
      label: "",
    }),
    [selected]
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      console.log(values);
      bulkLabelBadgeUpdate(values)
        .unwrap()
        .then(() => {
          dispatch(showSuccess({ message: "Products associated succesfully" }));
          resetForm();
          setSelected([]);
          onClose();
        })
        .catch((e) => {
          console.log(e);
          dispatch(
            showError({ message: e?.data?.message ?? e?.message ?? "Something went wrong!" })
          );
        });
    },
  });

  const closeDialog = () => {
    if (!isLoading) {
      formik.resetForm();
      setSelected([]);
      onClose();
    }
  };

  return (
    <Dialog
      open={show}
      TransitionComponent={Transition}
      keepMounted
      onClose={closeDialog}
      maxWidth="sm"
      fullWidth>
      <DialogTitle>
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex flex-column ">
            <h5 className="text-lightBlue fw-500">Manage Labels & Badges</h5>
            {/* 
            <small className="text-grey-6 mt-1 d-block">
              ⓘ Add or remove collection from the product.Manage collections in
            </small> */}
          </div>
          <img
            src={cancel}
            alt="cancel"
            width={30}
            onClick={closeDialog}
            className="c-pointer"
          />
        </div>
      </DialogTitle>
      <DialogContent className="pb-4 px-4">
        <div className="row align-items-center">
          <div className="col-12">
            <p className="text-lightBlue mb-1">Select Label Badge</p>
            <AppGenericSelect
              name="label"
              value={formik.values.label}
              dataId="_id"
              dataLabel="name"
              onBlur={() => formik.setFieldTouched("label", true)}
              onChange={(_, sel) => formik.setFieldValue("label", sel?._id ?? "")}
              useGetQuery={useGetAllLabelsBadgessQuery}
              getQueryFilters={{
                createdAt: "-1",
                // status: "active,in-active,scheduled",
                pageNo: "1",
                pageSize: "1000000000",
              }}
            />
            <FormHelperText error>
              {formik.touched.label && (formik.errors.label ?? formik.errors.productIds)}
            </FormHelperText>
          </div>
        </div>
      </DialogContent>
      <hr className="hr-grey-6 my-0" />
      <DialogActions className="d-flex justify-content-between px-4 py-3">
        <button
          className="button-grey py-2 px-5"
          onClick={closeDialog}
          disabled={isLoading}>
          <p className="text-lightBlue">Cancel</p>
        </button>
        <button
          className="button-gradient py-2 px-5"
          onClick={formik.handleSubmit}
          disabled={isLoading}>
          <p>Save</p>
        </button>
      </DialogActions>
    </Dialog>
  );
}
