import { forwardRef } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
} from "@mui/material";

import cancel from "../../assets/icons/closeModal.svg";
import invite from "../../assets/setting/icons/invite.svg";
import closeProfile from "../../assets/profile/closeProfile.svg";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PopUpBox = ({ children, onConfirm, show, image }) => {

    return (
        <Dialog
            open={show}
            TransitionComponent={Transition}
            keepMounted
            onClose={onConfirm}
            aria-describedby="alert-dialog-slide-description"
            maxWidth="sm"
            fullWidth={true}
        >
            <div className="bg-invite py-3">
                <DialogTitle className="d-flex flex-row-reverse">
                    <img
                        src={cancel}
                        alt="cancel"
                        width={45}
                        onClick={onConfirm}
                        className="c-pointer"
                    />
                </DialogTitle>
                <DialogContent className="d-flex justify-content-center">
                    <img
                        src={image}
                        alt="cancel"
                        width={200}
                    />
                </DialogContent>
                {children}
            </div>
        </Dialog>
    );
};

export default PopUpBox;