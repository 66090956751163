import { useState, forwardRef } from "react";
import { useDispatch } from "react-redux";
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  Tab,
  Tabs,
} from "@mui/material";
import { showError, showSuccess } from "../../../features/snackbar/snackbarAction";
import { TableSearchSecondary } from "../../../components/TableSearch/TableSearch";
import cancel from "../../../assets/icons/cancel.svg";
import { useBulkAddRemoveCollectionsMutation } from "../../../features/products/product/productApiSlice";
import { useGetAllCollectionsQuery } from "../../../features/parameters/collections/collectionsApiSlice";

const Transition = forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="up"
      ref={ref}
      {...props}
    />
  );
});

export default function AddRemoveCollections({
  show = false,
  onClose = () => {},
  productIds = [],
}) {
  const dispatch = useDispatch();

  const [tabIndex, setTabIndex] = useState(0);
  const changeTab = (_, index) => setTabIndex(index);

  const [selectedGroupIds, setSelectedGroupIds] = useState([]);
  const [searchKey, setSetsearchKey] = useState("");
  const [searchValue, setSearchValue] = useState("");

  const { data: collectionsData } = useGetAllCollectionsQuery({
    createdAt: -1,
    title: searchKey,
    status: "active,in-active,scheduled",
  });
  const [bulkAddRemoveCollection] = useBulkAddRemoveCollectionsMutation();

  const saveCollectionGroups = () => {
    bulkAddRemoveCollection({
      updates: productIds.map((pid) => ({
        product: pid,
        collection: selectedGroupIds,
        type: tabIndex === 0 ? "add" : "remove",
      })),
    })
      .unwrap()
      .then(() => {
        dispatch(
          showSuccess({
            message: `Collections ${tabIndex === 0 ? "added" : "removed"} successfully!`,
          })
        );
        setSelectedGroupIds([]);
        onClose();
      })
      .catch((e) =>
        dispatch(
          showError({
            message: e?.data?.message ?? e?.message ?? "Something went wrong!",
          })
        )
      );
  };

  return (
    <Dialog
      open={show}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      maxWidth="sm"
      fullWidth>
      <DialogTitle>
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex flex-column ">
            <h5 className="text-lightBlue fw-500">Manage Collection</h5>

            <small className="text-grey-6 mt-1 d-block">
              ⓘ Add or remove collection from the product.Manage collections in
            </small>
          </div>
          <img
            src={cancel}
            alt="cancel"
            width={30}
            onClick={onClose}
            className="c-pointer"
          />
        </div>
        <Tabs
          aria-label="scrollable force tabs example"
          className="tabs"
          value={tabIndex}
          onChange={changeTab}>
          <Tab
            label="Add"
            className="tabs-head"
          />
          <Tab
            label="Remove"
            className="tabs-head"
          />
        </Tabs>
        <hr className="hr-grey-6 my-0" />
        <div className="mt-4">
          <TableSearchSecondary
            value={searchValue}
            onChange={(v) => setSetsearchKey(v)}
            onSearchValueChange={(v) => setSearchValue(v)}
          />
        </div>
      </DialogTitle>
      <DialogContent className="pb-4 px-4">
        {collectionsData?.data?.data?.map((group) => (
          <p
            className="mt-1 text-lightBlue"
            key={group._id}>
            <Checkbox
              size="small"
              style={{
                color: "#5C6D8E",
                marginRight: 0,
              }}
              checked={selectedGroupIds.includes(group._id)}
              onChange={(e) =>
                e.target.checked
                  ? setSelectedGroupIds((prevIds) => prevIds.concat(group._id))
                  : setSelectedGroupIds((prevIds) => prevIds.filter((id) => id !== group._id))
              }
            />
            {group?.title}
          </p>
        ))}
      </DialogContent>
      <hr className="hr-grey-6 my-0" />
      <DialogActions className="d-flex justify-content-between px-4 py-3">
        <button
          className="button-grey py-2 px-5"
          onClick={onClose}>
          <p className="text-lightBlue">Cancel</p>
        </button>
        <button
          className="button-gradient py-2 px-5"
          onClick={saveCollectionGroups}>
          <p>Save</p>
        </button>
      </DialogActions>
    </Dialog>
  );
}
