import apiSlice from "../../app/api/apiSlice";
import { omitEmptyKeys } from "../../utils/helper";

export const transactionApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllTransactions: builder.query({
      query: (queries = {}) => ({
        url: "/transaction",
        params: omitEmptyKeys(queries),
      }),
      providesTags: ["Transactions"],
    }),
    getAllTransactionsForExcel: builder.mutation({
      query: (queries = {}) => ({
        url: "/transaction",
        method: "GET",
        params: omitEmptyKeys(queries),
      }),
      providesTags: ["Transactions"],
    }),
  }),
});

export const {
  useGetAllTransactionsQuery,
  useGetAllTransactionsForExcelMutation,
} = transactionApiSlice;
