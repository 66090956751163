import { forwardRef } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  Tooltip
} from "@mui/material";

import cancel from "../../../../assets/icons/closeModal.svg";
import invite from "../../../../assets/setting/icons/invite.svg";
import copy from "../../../../assets/setting/icons/copy-right.png";
import fb from "../../../../assets/setting/icons/Facebook.svg";
import insta from "../../../../assets/setting/icons/Instagram.svg";
import tweet from "../../../../assets/setting/icons/Twitter.svg";
import email from "../../../../assets/setting/icons/email.png";
import whatsapp from "../../../../assets/setting/icons/whatsapp.svg";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const InviteEntrepreneur = ({ onConfirm, show, contactClick, subscribeClick }) => {
    const saveGroups = () => {
        onConfirm()
    }

    return (
        <Dialog
            open={show}
            TransitionComponent={Transition}
            keepMounted
            onClose={onConfirm}
            aria-describedby="alert-dialog-slide-description"
            maxWidth="sm"
            fullWidth={true}
        >
            <div className="bg-invite py-3">
                <DialogTitle className="d-flex flex-row-reverse">
                    <img
                        src={cancel}
                        alt="cancel"
                        width={45}
                        onClick={onConfirm}
                        className="c-pointer"
                    />
                </DialogTitle>
                <DialogContent className="d-flex justify-content-center">
                    <img
                        src={invite}
                        alt="cancel"
                        width={200}
                    />
                </DialogContent>
                <div className="d-flex justify-content-center">
                    <h4 className="text-lightBlue fw-600 me-2">
                        Invite an entrepreneur
                    </h4>
                </div>
                <div className="d-flex justify-content-center px-5">
                    <small className="text-grey-6 mt-2 d-block py-4 px-5">
                       Invite a friend to unthread and you’ll both get a ₹10,000 subscription 
                       credit after 1 month of usage
                    </small>
                </div>
                <div className="d-flex justify-content-center px-3 mb-4 text-lightBlue">
                    www.unthread.com/superthred/referral
                    <img src={copy} width={15} height={15}className="mr-1 mt-1"/>
                </div>
                <div className="d-flex justify-content-center px-5">
                    <small className="text-grey-6 d-block px-5">
                       or Share via
                    </small>
                </div>
                <div className="d-flex justify-content-center px-5 mt-4 gap-4 mb-5">
                    <img width={45} height={45} src={email} />
                    <img width={45} height={45} src={whatsapp} />
                    <img width={45} height={45} src={fb} />
                    <img width={45} height={45} src={insta} />
                    <img width={45} height={45} src={tweet} />
                </div>
            </div>
        </Dialog>
    );
};

export default InviteEntrepreneur;