import apiSlice from "../../../app/api/apiSlice";

export const labelsBadgesSettingApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllLabelsBadgesSettings: builder.query({
      query: (queries = {}) => {
        const queryString = new URLSearchParams(queries).toString();
        return {
          url: `/functionality/settingLabelbadge?${queryString}`,
        };
      },
      providesTags: ["LabelsBadgesSetting"],
    }),

    // getLabelsBadgesSettingCount: builder.query({
    //   query: (queries = {}) => {
    //     const queryString = new URLSearchParams(queries).toString();

    //     return {
    //       url: `/store/count?${queryString}`,
    //     };
    //   },
    //   providesTags: ["LabelsBadgesSetting"],
    // }),

    createLabelsBadgesSetting: builder.mutation({
      query: (data) => ({
        url: "/functionality/settingLabelbadge",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["LabelsBadgesSetting", "Logs"],
    }),

    editLabelsBadgesSetting: builder.mutation({
      query: ({ id, details }) => ({
        url: `/functionality/settingLabelbadge/${id}`,
        method: "PUT",
        body: details,
      }),
      invalidatesTags: ["LabelsBadgesSetting", "Logs"],
    }),

    // bulkEditLabelsBadgesSetting: builder.mutation({
    //   query: (updates) => ({
    //     url: "/functionality/settingLabelsBadges/bulkUpdate",
    //     method: "PUT",
    //     body: updates,
    //   }),
    //   invalidatesTags: ["LabelsBadgesSetting","Logs"],
    // }),

    // deleteLabelsBadgesSetting: builder.mutation({
    //   query: (id) => ({
    //     url: `/functionality/settingLabelsBadges/${id}`,
    //     method: "DELETE",
    //   }),
    //   invalidatesTags: ["LabelsBadgesSetting","Logs"],
    // }),

    // bulkDeleteLabelsBadgesSetting: builder.mutation({
    //   query: (deletes) => ({
    //     url: "/functionality/settingLabelsBadges/bulkDelete",
    //     method: "DELETE",
    //     body: deletes,
    //   }),
    //   invalidatesTags: ["LabelsBadgesSetting","Logs"],
    // }),
  }),
});

export const {
  useGetAllLabelsBadgesSettingsQuery,
  // useGetLabelsBadgesSettingCountQuery,
  useCreateLabelsBadgesSettingMutation,
  useEditLabelsBadgesSettingMutation,
  // useBulkEditLabelsBadgesSettingMutation,
  // useDeleteLabelsBadgesSettingMutation,
  // useBulkDeleteLabelsBadgesSettingMutation,
} = labelsBadgesSettingApiSlice;
